import React, { FC } from "react";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons/lib";
import {
  PricingReq,
  PricingRuleRes,
} from "@n3oltd/k2.donations.sdk.donation-items/esm";
import {
  FundDimensionRes,
  FundStructureRevisionRes,
} from "@n3oltd/k2.subscriptions.sdk.funds/esm";
import { Currency } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { Col, Form, Row, Select, Switch, Tooltip } from "antd";
import { FormInstance } from "antd/es/form";
import { NamePath } from "antd/es/form/interface";

import { K2Button, K2Message } from "../k2Widgets";
import injectK2Intl from "../k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "../k2Widgets/k2Localizations/types";
import { InputCurrencyFormItem } from "./";
import GeneralFormItemV4 from "./GeneralFormItemV4";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 20 },
  },
};

export interface IAdvancePricingProps extends InjectedK2IntlProps {
  form: FormInstance;
  fieldName: NamePath;
  dataSource: FundStructureRevisionRes;
  initialValues?: PricingRuleRes[];
  currency: Currency;
}

const AdvancePricingRules: FC<IAdvancePricingProps> = (props) => {
  const { fieldName, dataSource, initialValues } = props;
  if (!dataSource || !fieldName) {
    throw new Error(
      "'dataSource' and 'fieldName' are required for the component to render!",
    );
  }

  const renderDimensionSelects = (field) => {
    return Object.keys(props.dataSource)?.map?.((key: string) => {
      const dim: FundDimensionRes = props.dataSource[key];

      if (!dim || !dim.isActive) {
        return "";
      }

      return (
        <Col span={4} key={key}>
          <Form.Item
            name={
              Array.isArray(field.name)
                ? [...field.name, "fundDimensions", key]
                : [field.name, "fundDimensions", key]
            }
            fieldKey={[field.fieldKey, "fundDimensions", key]}
            wrapperCol={{
              xs: 24,
            }}
          >
            <Select
              dropdownClassName={"n3o-min-width-200"}
              showSearch
              placeholder={dim.name}
              allowClear
              showArrow
            >
              {[...dim.restrictedOptions, dim.unrestrictedOption]
                .filter((op) => !!op)
                .map?.((dimensionOption) => {
                  return (
                    <Option key={dimensionOption} value={dimensionOption}>
                      {dimensionOption}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      );
    });
  };

  return (
    <div style={{ marginBottom: "16px" }}>
      <Form.List name={fieldName}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields?.map?.((field, index) => (
                <Row gutter={24} key={field.key} style={{ display: "flex" }}>
                  {renderDimensionSelects(field)}

                  <Col span={4}>
                    <InputCurrencyFormItem
                      errorTitle={props.k2Intl?.formatMessage({
                        localeKey: "common.amount",
                      })}
                      formItemProps={{
                        fieldKey: [
                          field.fieldKey,
                          "price",
                          "amount",
                        ],
                        name: Array.isArray(field.name)
                          ? [
                              ...field.name,
                              "price",
                              "amount",
                            ]
                          : [
                              field.name,
                              "price",
                              "amount",
                            ],
                        required: true,
                      }}
                      currency={props.currency}
                      formItemLayout={formItemLayout}
                      min={0}
                      currentForm={props.form}
                      initialValue={initialValues?.[index]?.price?.amount || 0}
                    />
                  </Col>
                  <Col span={4} style={{ paddingTop: 3 }}>
                    <Form.Item
                      valuePropName={"checked"}
                      name={
                        Array.isArray(field.name)
                          ? [
                              ...field.name,
                              "price",
                              "locked",
                            ]
                          : [
                              field.name,
                              "price",
                              "locked",
                            ]
                      }
                      fieldKey={[
                        field.fieldKey,
                        "price",
                        "locked",
                      ]}
                    >
                      <Switch
                        checkedChildren={props.k2Intl?.formatMessage({
                          localeKey: "admin.fixed",
                        })}
                        unCheckedChildren={props.k2Intl?.formatMessage({
                          localeKey: "admin.notFixed",
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={2}
                    style={{ paddingTop: 5, paddingBottom: 9, paddingLeft: 22 }}
                  >
                    <span>
                      <Tooltip
                        title={<K2Message localeKey="admin.removeRule" />}
                      >
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Tooltip>
                    </span>
                  </Col>
                </Row>
              ))}
              <Row gutter={24}>
                <Col span={6} style={{ paddingTop: 5 }}>
                  <GeneralFormItemV4 {...formItemLayout} colon={false}>
                    <K2Button type={"primary"} onClick={() => add()}>
                      <PlusOutlined />
                      <K2Message localeKey={"admin.addRule"} />
                    </K2Button>
                  </GeneralFormItemV4>
                </Col>
              </Row>
            </>
          );
        }}
      </Form.List>
    </div>
  );
};

export default injectK2Intl(AdvancePricingRules);
