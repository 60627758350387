import React from "react";

import { CalendarOutlined } from "@ant-design/icons";
import { AppointmentInfo } from "@n3oltd/k2.calls.sdk.calls";
import { Divider, Popover, Tag, Typography } from "antd";
import styled from "styled-components";

import { _communicationsDataEntryClient } from "appRedux/models/base/K2RestClients";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

import { AppointmentPreview } from "../../types";

const AppointmentContent = styled.div`
  ul {
    padding: 0px;
    padding-left: 1rem;
  }
`;

const CustomCalendar = styled(CalendarOutlined)`
  cursor: pointer;
`;
const CustomPopover = styled(Popover)`
  .anticon.anticon-calendar {
    color: ${({ theme }) => theme.grey_6} !important;
  }

  .appointment {
    font-weight: ${({ theme }) => theme.font_weight_heavy};
    color: ${({ theme }) => theme.grey_8};
    font-size: ${({ theme }) => theme.font_size_sm};
    line-height: ${({ theme }) => theme.line_height_md};
  }
  .ant-popover-title {
    font-weight: ${({ theme }) => theme.font_weight_heavy};
    font-size: ${({ theme }) => theme.h6_font_size};
    line-height: ${({ theme }) => theme.line_height_lg};
  }
`;

const Text = styled.div`
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};
  font-size: ${({ theme }) => theme.font_size_sm};
  line-height: ${({ theme }) => theme.line_height_lg};
`;

const CustomDivider = styled(Divider)`
  .heading {
    font-weight: ${({ theme }) => theme.font_weight_heavy};
    font-size: ${({ theme }) => theme.h6_font_size};
    line-height: ${({ theme }) => theme.line_height_lg};
    border-top: ${({ theme }) => theme.grey_10};
  }
`;

interface AppointmentPopoverProps extends InjectedK2IntlProps {
  appointment: AppointmentPreview;
  trigger: "click" | "hover";
  withTag?: boolean;
}

function AppointmentPopover({
  k2Intl,
  appointment,
  trigger,
  withTag = true,
}: AppointmentPopoverProps) {
  const { theme } = useAppContext();
  return (
    <CustomPopover
      overlayStyle={{ width: "380px" }}
      trigger={trigger}
      placement={"right"}
      content={
        <AppointmentContent>
          <ul>
            <li>
              <Text>
                <K2Message
                  localeKey="communication.cc.dialler.appointment.scheduledOn"
                  values={{
                    scheduledOn: k2Intl.formatDateTime(
                      new Date(appointment.scheduledOn),
                    ),
                  }}
                  withoutSpan
                />
              </Text>
            </li>

            <li>
              <Text>
                <K2Message
                  localeKey="common.assignedTo"
                  values={{
                    name: appointment.assignedTo,
                  }}
                  withoutSpan
                />
              </Text>
            </li>
          </ul>

          <CustomDivider orientation="left" className="n3o-left-divider">
            <K2Message spanClassName="heading" localeKey="common.notes" />
          </CustomDivider>
          <Text>{appointment.notes}</Text>
        </AppointmentContent>
      }
      title={<K2Message localeKey="common.appointment.detail" />}
    >
      {withTag ? (
        <Tag color={theme.grey_10}>
          <CalendarOutlined />
          <K2Message
            localeKey="communication.cc.dialler.call.isAppointment"
            spanClassName={"appointment"}
          />
        </Tag>
      ) : (
        <CustomCalendar style={{ lineHeight: 1.5715 }} />
      )}
    </CustomPopover>
  );
}

export default injectK2Intl(AppointmentPopover);
