import React, { FC, useEffect, useMemo, useState } from "react";

import { PlusOutlined } from "@ant-design/icons/lib";
import { generateUuid } from "@azure/ms-rest-js";
import { LookupType } from "@n3oltd/karakoram.tasks.sdk.lookups";
import {
  LinkedEntityType,
  MarkupLanguage,
  NoteReq,
  SecurityPrincipalProfile,
  SecurityPrincipalReq,
  TaskPriority,
  TaskReq,
  TaskStatus,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Alert, Col, Form, Input, Row, Switch } from "antd";
import { useForm } from "antd/es/form/Form";
import { RcFile } from "antd/es/upload";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import _ from "lodash";
import moment from "moment";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router-dom";
import styled from "styled-components";

import DateFormatProvider from "appRedux/models/localization/DateFormatProvider";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import BaseListModel from "appRedux/models/subscription/BaseListModel";
import { LinkedEntityTypesLookups } from "appRedux/modules/lookups/types";
import { updateTaskDrawerTasks } from "appRedux/modules/tasks/drawer/actions";
import { Attachment } from "appRedux/modules/tasks/types";
import IApplicationState from "appRedux/types";
import injectLookup from "appRedux/utils/injectLookups";
import { GeneralFormItemV4 } from "components/formItems";
import DatePickerWithoutYearSkip from "components/forms/datePickers/DatePickerWithoutYearSkip";
import FormItemTitle from "components/forms/formItemTitle";
import { K2Message, K2Modal } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import SelectAssignees from "components/tasks/SelectAssignees";
import RelatedLink, {
  ExtendedGlobalSuggestion,
} from "components/tasks/editRelatedLink";
import MarkupEditorPreviewer from "components/tasks/markupEditorPreviewer";
import { UIUtils } from "components/utils";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import usePrevious from "hooks/usePrevious";

import { useRouter } from "../../../hooks";
import { ICreateTaskFormFormProps } from "./connect";

const {
  viewAccount,
  viewAccountAttachments,
  viewAccountComms,
  viewAccountGiving,
  viewAccountHistory,
  viewAccountInsights,
  viewAccountSponsorships,
} = K2Routes;

const accountRoutes = [
  viewAccount,
  viewAccountAttachments,
  viewAccountComms,
  viewAccountGiving,
  viewAccountHistory,
  viewAccountInsights,
  viewAccountSponsorships,
];

const DateCol = styled(Col)`
  && {
    padding: 0 10px 0 0;
  }
`;

const PriorityCol = styled(Col)`
  && {
    display: flex;
    align-items: center;
    margin: 30px 0 0 15px;
    padding: 0;
    font-size: small;

    .ant-form-item {
      padding: 0;
    }
    .ant-switch-checked {
      background-color: ${({ theme }) => theme.red_6} !important;
    }
  }
`;

const SpanWithPad = styled.span`
  padding-right: 8px;
`;

const disabledDate = (current) => {
  // Can not select days before today
  return current && current < moment().startOf("day");
};

const NewTaskForm: FC<ICreateTaskFormFormProps> = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showingAttachments, setShowingAttachments] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [
    selectedEntity,
    setSelectedEntity,
  ] = useState<ExtendedGlobalSuggestion>(null);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const previouslyCreatingTask = usePrevious(props.isCreatingTask);
  const [highPriority, setHighPriority] = useState<boolean>(false);
  const [dueDate, setDueDate] = useState(null);

  const listModel = new BaseListModel(props);

  const [form] = useForm();

  const dispatch = useDispatch();
  const taskDrawerState = useSelector(
    (state: IApplicationState) => state.tasks.taskDrawer,
  );
  const { entityId, entityType } = taskDrawerState;
  const tasksDrawerShowing = !!entityId && !!entityType;

  const { setFormTouched } = props;

  const removeDetails = () => {
    props.deleteAllAttachments();
    setShowDetails(false);
    setEditorState(EditorState.createEmpty());
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        let task: TaskReq = {};

        // If someone has added a note in markup or preview format, get the markup version
        if (showDetails) {
          const rawContentState = convertToRaw(editorState.getCurrentContent());
          const noteMarkup = draftToHtml(rawContentState);

          if (noteMarkup && noteMarkup.length > 0) {
            const note: NoteReq = {
              text: {
                language: MarkupLanguage.Html,
                content: noteMarkup,
              },
            };
            task.note = note;
          }
        }

        // Add all uploaded attachments to the note, if necessary
        if (props.noteAttachments.length > 0 && task.note) {
          task.note.files = props.noteAttachments
            .filter((attachment: Attachment) => attachment.storageToken)
            ?.map?.((attachment: Attachment) => {
              return {
                storageToken: attachment.storageToken,
              };
            });
        }

        // Add other properties
        task.title = values.title;
        if (values["is-high-priority"]) {
          task.priority = TaskPriority._1High;
        } else {
          task.priority = TaskPriority._3Low;
        }
        if (values["due-date"])
          task.dueDate = values["due-date"].format(
            DateFormatProvider.serverDateFormat,
          );
        if (selectedEntity) {
          task.linkedEntity = {
            entityId: selectedEntity.id,
            type: selectedEntity.type,
          };
        }

        if (selectedAssignees.length) {
          task.assignTo = {
            principals: selectedAssignees?.map?.(
              (p): SecurityPrincipalReq => {
                return {
                  principalId: p.principalId,
                  type: p.type,
                };
              },
            ),
          };
        }

        props.createTask(task);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpload = (info: RcCustomRequestOptions) => {
    setFormTouched();
    props.uploadAttachment(generateUuid(), info.file as RcFile);
  };

  const clearForm = () => {
    props.deleteAllAttachments();
    form?.resetFields();
    setShowDetails(false);
    setEditorState(EditorState.createEmpty());
  };

  const clearFormAndCancel = () => {
    clearForm();
    props.clearFormTouched();
    props.onCancel();

    // If the tasks banner is showing, update the tasks
    if (tasksDrawerShowing) {
      dispatch(
        updateTaskDrawerTasks({
          hasLinkedEntity: true,
          linkedEntity: entityId,
          status: [TaskStatus.Pending],
          pageSize: listModel.getDefaultGridInfo().pageSize,
        }),
      );
    }
  };

  const deleteAttachment = (file: Attachment) => {
    props.deleteAttachment(file.uid);
    setFormTouched();
    return false;
  };

  // Show error message when creation error occurs
  useEffect(() => {
    if (props.taskCreationServerError) {
      UIUtils.handleServerError(
        props.k2Intl,
        props.taskCreationServerError,
        props.clearTaskCreationServerError,
      );
    }
    // eslint-disable-next-line
  }, [props.taskCreationServerError]);

  // After task is successfully saved
  useEffect(() => {
    if (
      previouslyCreatingTask &&
      !props.isCreatingTask &&
      !props.taskCreationServerError
    ) {
      props.onSuccess?.();
      clearFormAndCancel();
    }
    // eslint-disable-next-line
  }, [props.isCreatingTask, props.taskCreationServerError]);

  const router = useRouter<{ donationId?: string; accountId?: string }>();
  const {
    pathname,
    match: { params },
  } = router;

  const routeMatched = matchPath<{
    accountId?: string;
    donationId?: string;
  }>(pathname, {
    path: accountRoutes,
  });

  const { sponsorshipId, pledgeId, donationId, givingId, feedbackId } = props;
  const assigned = selectedAssignees.length > 0;
  const anyFileIsUploading = props.noteAttachments.some(
    (attachment) => attachment.status === "uploading",
  );

  const accountId = routeMatched?.params?.accountId;

  const defaultEntity: ExtendedGlobalSuggestion = useMemo(() => {
    // order matters
    if (sponsorshipId) {
      return {
        type: LinkedEntityType.Sponsorship,
        id: sponsorshipId,
        linkedId: accountId,
      };
    }
    if (feedbackId) {
      return {
        type: LinkedEntityType.Feedback,
        id: feedbackId,
        linkedId: accountId,
      };
    }
    if (pledgeId) {
      return {
        type: LinkedEntityType.Pledge,
        id: pledgeId,
        linkedId: accountId,
      };
    }

    if (donationId) {
      return {
        type: LinkedEntityType.Donation,
        id: donationId,
        linkedId: accountId,
      };
    }
    if (givingId) {
      return {
        type: LinkedEntityType.RegularGiving,
        id: givingId,
        linkedId: accountId,
      };
    }
    if (accountId) {
      return {
        type: LinkedEntityType.Account,
        id: accountId,
      };
    }

    // eslint-disable-next-line
  }, [
    sponsorshipId,
    pledgeId,
    accountId,
    donationId,
    pledgeId,
    givingId,
    feedbackId,
  ]);

  const selectedType = (): LinkedEntityType => {
    // order matters
    if (sponsorshipId) {
      return LinkedEntityType.Sponsorship;
    }
    if (pledgeId) {
      return LinkedEntityType.Pledge;
    }

    if (feedbackId) {
      return LinkedEntityType.Feedback;
    }

    if (donationId) {
      return LinkedEntityType.Donation;
    }
    if (givingId) {
      return LinkedEntityType.RegularGiving;
    }
    if (accountId) {
      return LinkedEntityType.Account;
    }
    return undefined;
    // eslint-disable-next-line
  };

  if (!props.visible) return null;

  return (
    <DirtyDataGuard dirty={props.formTouched}>
      <K2Modal
        visible={true}
        centered
        spinning={props.isCreatingTask}
        requiresCloseConfirmation={props.formTouched}
        title={<K2Message localeKey="tasks.action.newTask" />}
        okText={<K2Message localeKey="common.save" />}
        okButtonProps={{
          type: "primary",
          disabled:
            !props.formTouched || props.isCreatingTask || anyFileIsUploading,
          onClick: handleCreate,
          loading: props.isCreatingTask,
        }}
        cancelButtonProps={{
          type: "default",
          disabled: props.isCreatingTask || anyFileIsUploading,
        }}
        onCancel={clearFormAndCancel}
      >
        <Form
          form={form}
          onValuesChange={(changedValues) => {
            if (changedValues.hasOwnProperty("due-date")) {
              setDueDate(changedValues["due-date"]);
            }
            if (changedValues.hasOwnProperty("is-high-priority")) {
              setHighPriority(changedValues["is-high-priority"]);
            }
          }}
        >
          <GeneralFormItemV4
            name={"title"}
            errorLabelKey={"tasks.title"}
            rules={[
              {
                required: true,
                message: <K2Message localeKey="common.thisIsRequired" />,
              },
            ]}
            wrapperCol={{ xs: 24 }}
            labelCol={{ xs: 24 }}
            style={{ paddingBottom: 0, marginBottom: "24px" }}
            label={
              <FormItemTitle>
                <K2Message localeKey="tasks.title" />
              </FormItemTitle>
            }
          >
            <Input onChange={props.setFormTouched} />
          </GeneralFormItemV4>
          <Row style={{ margin: "0 0 24px 0" }}>
            <DateCol xs={24} sm={8} md={7}>
              <GeneralFormItemV4
                label={
                  <FormItemTitle>
                    <K2Message localeKey="tasks.action.setDueDate" />
                  </FormItemTitle>
                }
                errorLabelKey={"tasks.action.setDueDate"}
                name={"due-date"}
                labelCol={{ xs: 24 }}
                style={{ paddingBottom: 0 }}
              >
                <DatePickerWithoutYearSkip
                  onChange={setFormTouched}
                  style={{ width: "100%" }}
                  disabledDate={disabledDate}
                  format={props.dateFormat || "DD/MM/YY"}
                />
              </GeneralFormItemV4>
            </DateCol>
            <PriorityCol xs={24} sm={12} md={12} className="n3o-task-priority">
              <SpanWithPad>
                <K2Message localeKey="tasks.action.highPriority" />
              </SpanWithPad>
              <GeneralFormItemV4
                valuePropName={"checked"}
                name={"is-high-priority"}
              >
                <Switch
                  onChange={setFormTouched}
                  checkedChildren={<K2Message localeKey="common.yes" />}
                  unCheckedChildren={<K2Message localeKey="common.no" />}
                />
              </GeneralFormItemV4>
            </PriorityCol>
          </Row>
          <GeneralFormItemV4
            wrapperCol={{ xs: 24 }}
            labelCol={{ xs: 24 }}
            style={{ marginBottom: "24px", paddingBottom: 0 }}
          >
            <SelectAssignees
              title={
                <FormItemTitle>
                  <K2Message localeKey="tasks.action.assignTo" />
                </FormItemTitle>
              }
              selectedValues={selectedAssignees}
              onChange={(values: SecurityPrincipalProfile[]) => {
                setSelectedAssignees(values);
                setFormTouched();
              }}
            />
          </GeneralFormItemV4>
          <Row style={{ marginBottom: "24px", paddingBottom: "0 !important" }}>
            <RelatedLink
              linkedEntityTypes={props.linkedEntityTypes.items}
              editMode={false}
              title={
                <FormItemTitle>
                  <K2Message localeKey="tasks.action.relatedLink" />
                </FormItemTitle>
              }
              k2Intl={props.k2Intl}
              selectedType={selectedType()}
              selectedValue={selectedEntity}
              onChange={setSelectedEntity}
              setFormTouched={setFormTouched}
              defaultEntity={defaultEntity}
            />
          </Row>
          {!_.isNil(selectedEntity) && (
            <Alert
              type="warning"
              showIcon
              message={<K2Message localeKey="tasks.warnings.entity.page" />}
              className={"n3o-mb-3"}
            />
          )}
          {showDetails === false && (
            <p
              onClick={() => setShowDetails(!showDetails)}
              style={{ marginBottom: "2em" }}
            >
              <FakeLink>
                <>
                  <PlusOutlined />
                  <K2Message localeKey="tasks.action.addMoreDetails" />
                </>
              </FakeLink>
            </p>
          )}
          {showDetails && (
            <Form.Item
              labelCol={{ xs: 24 }}
              wrapperCol={{ xs: 24 }}
              label={
                <p style={{ marginBottom: 0 }}>
                  <FormItemTitle>
                    {" "}
                    <K2Message localeKey="tasks.details" />
                  </FormItemTitle>
                  <FakeLink>
                    <span onClick={removeDetails} style={{ marginLeft: "8px" }}>
                      <K2Message localeKey="common.remove" />
                    </span>
                  </FakeLink>
                </p>
              }
            >
              <MarkupEditorPreviewer
                editorState={editorState}
                onEditorStateChange={(editorState: EditorState) => {
                  setEditorState(editorState);
                  setFormTouched();
                }}
                handleUpload={handleUpload}
                attachments={props.noteAttachments}
                deleteAttachment={deleteAttachment}
                onToggleAttachments={() =>
                  setShowingAttachments(!showingAttachments)
                }
                showAttachments={showingAttachments}
              />
            </Form.Item>
          )}
          {highPriority && !assigned && !dueDate && (
            <Alert
              type="warning"
              showIcon
              message={<K2Message localeKey="tasks.warnings.highPriority" />}
              description={
                <K2Message localeKey="tasks.warningDescription.considerAssigneeDueDate" />
              }
            />
          )}
          {highPriority && !assigned && dueDate && (
            <Alert
              type="warning"
              showIcon
              message={<K2Message localeKey="tasks.warnings.highPriority" />}
              description={
                <K2Message localeKey="tasks.warningDescription.considerAssignee" />
              }
            />
          )}
          {highPriority && !dueDate && assigned && (
            <Alert
              type="warning"
              showIcon
              message={<K2Message localeKey="tasks.warnings.highPriority" />}
              description={
                <K2Message localeKey="tasks.warningDescription.considerDueDate" />
              }
            />
          )}
        </Form>
      </K2Modal>
    </DirtyDataGuard>
  );
};

export default injectLookup(injectK2Intl(NewTaskForm), [
  LookupType.LinkedEntityTypes,
]);
