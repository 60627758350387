import React, { FC } from "react";
import { Empty } from "antd";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import CaptureButton from "./captureButton";

interface IProps extends InjectedK2IntlProps {
  handleCaptureBookmark: () => void;
}

const EmptyBookmarks: FC<IProps> = (props) => {
  const { k2Intl, handleCaptureBookmark } = props;

  return (
    <Empty
      className={"n3o-mt-5 n3o-mb-2"}
      description={k2Intl?.formatMessage({
        localeKey: "reports.noBookMarksYet",
      })}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    >
      <CaptureButton
        localeKey={"reports.captureBookmark"}
        onClick={handleCaptureBookmark}
      />
    </Empty>
  );
};

export default EmptyBookmarks;
