import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const Outbox: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.031 0.836439C16.6644 0.543181 16.1295 0.602614 15.8363 0.969187C15.543 1.33576 15.6025 1.87066 15.969 2.16392L17.8269 3.65018H16.5C14.6833 3.65018 12.941 4.37187 11.6563 5.6565C10.3717 6.94112 9.65002 8.68344 9.65002 10.5002V12.0002C9.65002 12.4696 10.0306 12.8502 10.5 12.8502C10.9695 12.8502 11.35 12.4696 11.35 12.0002V10.5002C11.35 9.13431 11.8926 7.82439 12.8584 6.85858C13.8242 5.89277 15.1342 5.35018 16.5 5.35018H17.8269L15.969 6.83644C15.6025 7.1297 15.543 7.6646 15.8363 8.03117C16.1295 8.39774 16.6644 8.45717 17.031 8.16392L20.7748 5.16885C20.9729 5.01323 21.1 4.77155 21.1 4.50018C21.1 4.28868 21.0213 4.08658 20.882 3.93173C20.8655 3.91343 20.8482 3.89578 20.8301 3.87886C20.8124 3.86231 20.7939 3.84651 20.7749 3.83151L17.031 0.836439Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33832 14.8385C1.77903 14.3978 2.37677 14.1502 3.00002 14.1502H6.83202C7.13716 14.1502 7.41889 14.3137 7.57019 14.5787C8.01622 15.36 8.66091 16.0093 9.43889 16.461C10.2169 16.9127 11.1004 17.1506 12 17.1506C12.8996 17.1506 13.7832 16.9127 14.5612 16.461C15.3391 16.0093 15.9838 15.36 16.4299 14.5787C16.5812 14.3137 16.8629 14.1502 17.168 14.1502H21C21.6233 14.1502 22.221 14.3978 22.6617 14.8385C23.1024 15.2792 23.35 15.8769 23.35 16.5002V21.0002C23.35 21.6234 23.1024 22.2212 22.6617 22.6619C22.221 23.1026 21.6233 23.3502 21 23.3502H3.00002C2.37676 23.3502 1.77903 23.1026 1.33832 22.6619C0.897613 22.2212 0.650024 21.6234 0.650024 21.0002V16.5002C0.650024 15.8769 0.897613 15.2792 1.33832 14.8385ZM3.00002 15.8502C2.82763 15.8502 2.6623 15.9187 2.54041 16.0406C2.41851 16.1625 2.35002 16.3278 2.35002 16.5002V21.0002C2.35002 21.1726 2.41851 21.3379 2.54041 21.4598C2.6623 21.5817 2.82763 21.6502 3.00002 21.6502H21C21.1724 21.6502 21.3377 21.5817 21.4596 21.4598C21.5815 21.3379 21.65 21.1726 21.65 21.0002V16.5002C21.65 16.3278 21.5815 16.1625 21.4596 16.0406C21.3377 15.9187 21.1724 15.8502 21 15.8502H17.64C17.0664 16.7014 16.3062 17.4136 15.4147 17.9312C14.3775 18.5334 13.1994 18.8506 12 18.8506C10.8006 18.8506 9.62259 18.5334 8.58534 17.9312C7.69389 17.4136 6.93369 16.7014 6.36009 15.8502H3.00002Z"
      fill={fill}
    />
  </svg>
);
