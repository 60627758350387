import React, { FC } from "react";

import { SecurityPrincipalProfile } from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Menu } from "antd";
import styled from "styled-components";

import SearchUsers from "components/dashboard/editTask/components/visibleUsersList/searchUsers";
import SharedWithUser from "components/dashboard/editTask/components/visibleUsersList/sharedWithUser";
import { K2Message } from "components/k2Widgets";

const AddUserMenuItem = styled(Menu.Item)`
  border-top: 1px solid #e9e9f0;
`;

const FlexMenuItem = styled(Menu.Item)`
  && {
    color: ${({ disabled, theme }) => (disabled ? theme.grey_7 : "default")};
    display: flex;
    justify-content: space-between;
    cursor: default;
    &.ant-menu-item-active,
    &:hover {
      cursor: default;
      color: ${({ theme }) => theme.grey_8};
    }
    &.ant-menu-item-selected {
      background: transparent !important;
      color: ${({ theme }) => theme.grey_8} !important;
    }
  }
`;

const MenuWrapper = styled(Menu)`
  min-width: 190px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
`;

interface IProps {
  users: SecurityPrincipalProfile[];
}

const VisibleUsersList: FC<IProps> = ({ users }) => {
  return (
    <MenuWrapper>
      {users.length === 0 && (
        <FlexMenuItem disabled={true}>
          <K2Message localeKey="common.none" />
        </FlexMenuItem>
      )}
      {users?.map?.((u) => (
        <FlexMenuItem key={u.principalId}>
          <SharedWithUser allUsers={users} user={u} />
        </FlexMenuItem>
      ))}
      <AddUserMenuItem>
        <SearchUsers allUsers={users} showAddButton />
      </AddUserMenuItem>
    </MenuWrapper>
  );
};

export default VisibleUsersList;
