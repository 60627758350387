import { DonationItemReq } from "@n3oltd/k2.donations.sdk.donation-items/esm";

export class DonationItemAdminHelpers {
  public static priceProvidedFieldName = "priceProvided";
  public static priceLockedFieldName = [
    "pricing",
    "price",
    "locked",
  ];
  public static priceAmountFieldName = [
    "pricing",
    "price",
    "amount",
  ];
  public static pricingRulesFieldName = [
    "pricing",
    "rules",
  ];
}
