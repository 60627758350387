import React, { FC, useCallback, useEffect, useState } from "react";
import { InjectedK2IntlProps } from "../k2Localizations/types";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

import styled from "styled-components";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/es/input-number";

const InputNumberWrapper = styled(InputNumber)<{
  $showControlAlways?: boolean;
}>`
  width: 100%;
  border-radius: ${({ theme }) => theme.border_radius_sm} !important;
  .ant-input-number-input {
    min-height: ${({ theme }) => theme.dataEntryInnerHeight} !important;
  }
  ${(props) =>
    props.$showControlAlways &&
    `
    .ant-input-number-handler-wrap{
      opacity:1
    }
    `}
`;

interface IProps extends InputNumberProps, InjectedK2IntlProps {
  placeholderKey?: string;
  placeholder?: string;
  showControlAlways?: boolean;
}

const K2NumberInput: FC<IProps> = (props) => {
  const {
    value,
    onChange,
    disabled = false,
    placeholder,
    placeholderKey,
    k2Intl,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    showControlAlways,
  } = props;

  const [input, setInput] = useState(value);
  useEffect(() => {
    setInput(value);
  }, [value]);

  const handleChange = useCallback(
    (value) => {
      onChange?.(value);
      setInput(value);
    },
    [onChange],
  );

  const placeholderText: string = placeholderKey
    ? k2Intl?.formatMessage({ localeKey: placeholderKey })
    : placeholder;

  return (
    <InputNumberWrapper
      value={input}
      disabled={disabled}
      onChange={handleChange}
      placeholder={placeholderText}
      min={min}
      max={max}
      parser={(v) => {
        if (v === "-" || Number.isFinite(Number(v))) {
          return v;
        }
        return input;
      }}
      $showControlAlways={showControlAlways}
    />
  );
};

export default injectK2Intl(K2NumberInput);
