import { combineReducers } from "redux";
import { map } from "lodash";
import { createReducerFunction, createActionCreators } from "immer-reducer";
import {
  DashboardTasksReducer,
  dashboardTasksInitialState,
  dashboardTasksSagas,
} from "./dashboard";
import drawerSagas from "./drawer/saga";
import * as taskDrawerActions from "./drawer/actions";
import { all, fork } from "redux-saga/effects";
import taskDrawerReducer from "appRedux/modules/tasks/drawer/reducer";

const taskReducers = combineReducers({
  dashboardTasks: createReducerFunction(
    DashboardTasksReducer,
    dashboardTasksInitialState,
  ),
  taskDrawer: taskDrawerReducer,
});

export default taskReducers;

const dashboardTasksActions = createActionCreators(DashboardTasksReducer);

export { dashboardTasksActions, taskDrawerActions };

// All are exporting several watcher sagas
const combinedSagas = {
  dashboardTasksSagas,
  drawerSagas,
};

// combining all sagas for subscription and exporting them
function* tasksSagas() {
  yield all(map(combinedSagas, fork));
}

export { tasksSagas };
