import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { SubscriptionRes } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import AppManager from "appRedux/AppManager";
import { AppId } from "appRedux/modules/sharedTypes";
import IApplicationState from "appRedux/types";
import GlobalStyles from "common/GlobalStyles";
import tallyTheme from "routes/Q1/common/themeVariables";

import { K2AppContext } from "./types";

export const defaultContextValues: K2AppContext = {
  appId: "k2",
};

export const AppContext = createContext<K2AppContext>(defaultContextValues);

export const useAppContext = () => useContext(AppContext);

const AppProvider: FC<K2AppContext> = ({ children, appId, theme }) => {
  const [storedAppId, setStoredAppId] = useState<AppId>(appId);
  const [k2User, setK2User] = useState<CurrentUserRes>(null);
  const [k2Subscription, setK2Subscription] = useState<SubscriptionRes>(null);

  const { appId: appIdFromRedux } = useSelector(
    (state: IApplicationState) => state.settings,
  );

  const updateAppId = useCallback(
    (newAppId: AppId) => {
      if (newAppId !== storedAppId) {
        setStoredAppId(newAppId);
      }
    },
    [storedAppId],
  );

  useEffect(() => {
    AppManager.setAppId(appIdFromRedux);
    updateAppId(appIdFromRedux);
  }, [appIdFromRedux, updateAppId]);

  const updateUserAndSubscription = useCallback(
    (newK2User: CurrentUserRes, newK2Subscription: SubscriptionRes) => {
      if (newK2User !== k2User) {
        setK2User(newK2User);
      }
      if (newK2Subscription !== k2Subscription) {
        setK2Subscription(newK2Subscription);
      }
    },
    [k2Subscription, k2User],
  );

  const value: K2AppContext = {
    appId: storedAppId,
    theme: storedAppId === "k2" ? theme : { ...theme, ...tallyTheme },
    k2User,
    k2Subscription,
    updateAppId,
    updateUserAndSubscription,
  };

  return (
    <AppContext.Provider value={value}>
      <ThemeProvider theme={value.theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
