import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Region, regions } from "config/regions";
import appConfig from "config/appConfig";
import * as Sentry from "@sentry/react";
import { errorsList } from "config/sentryIgnoreErrors.json";

const env = process.env;

export type Environment = "prod" | "qa" | "development";

function getRegion(): Region {
  let selectedRegion = regions[0];

  regions.forEach((region) => {
    if (window.location.pathname.match(region)) {
      selectedRegion = region;
    }
  });

  return selectedRegion;
}

export interface IEnvironmentContext {
  loaded: boolean;
  environment: Environment;
  region: Region;
  basePath: string;
}

const defaultEnvironment: IEnvironmentContext = {
  loaded: false,
  environment: null,
  region: getRegion(),
  basePath: `/${getRegion()}/`,
};

export const EnvironmentContext = createContext<IEnvironmentContext>(
  defaultEnvironment,
);

export const useEnvironmentContext = () => useContext(EnvironmentContext);

const EnvironmentProvider: FC = ({ children }) => {
  const [loaded, setLoaded] = useState<boolean>(defaultEnvironment.loaded);

  const [storedEnvironment, setStoredEnvironment] = useState<Environment>(
    defaultEnvironment.environment,
  );
  const [storedRegion, setStoredRegion] = useState<Region>(
    defaultEnvironment.region,
  );
  const [storedBasePath, setStoredBasePath] = useState<string>(
    defaultEnvironment.basePath,
  );

  const fetchEnvironment = useCallback(async () => {
    // Get actual environment info
    fetch(`${window.location.origin}${storedBasePath}environment`)
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp?.id) {
          setStoredEnvironment(resp.id);
          setStoredBasePath(resp.pathBase);
          setStoredRegion(resp.pathBase.slice(1, -1));
          setLoaded(true);
        }
      })
      .catch(() => {
        // Response was not valid JSON: therefore we are in local environment
        setStoredEnvironment("development");
        setLoaded(true);
      });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchEnvironment();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (storedEnvironment && appConfig[storedEnvironment]) {
      const { sentryDsn } = appConfig[storedEnvironment];

      if (
        storedEnvironment === "prod" ||
        storedEnvironment === "qa" ||
        env.REACT_APP_LOG_TO_SENTRY === "true"
      ) {
        Sentry.init({
          dsn: sentryDsn,
          release: env.REACT_APP_SENTRY_RELEASE,
          ignoreErrors: errorsList,
          environment: storedEnvironment,
        });
      }
    }
  }, [storedEnvironment]);

  return (
    <EnvironmentContext.Provider
      value={{
        loaded: loaded,
        environment: storedEnvironment,
        region: storedRegion,
        basePath: storedBasePath,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentProvider;
