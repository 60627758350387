import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const Inboxes: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33826 14.8384C1.77897 14.3977 2.3767 14.1501 2.99996 14.1501H6.83196C7.1371 14.1501 7.41883 14.3136 7.57012 14.5786C8.01616 15.3598 8.66085 16.0092 9.43882 16.4609C10.2168 16.9125 11.1004 17.1504 12 17.1504C12.8995 17.1504 13.7831 16.9125 14.5611 16.4609C15.3391 16.0092 15.9838 15.3598 16.4298 14.5786C16.5811 14.3136 16.8628 14.1501 17.168 14.1501H21C21.6232 14.1501 22.221 14.3977 22.6617 14.8384C23.1024 15.2791 23.35 15.8768 23.35 16.5001V21.0001C23.35 21.6233 23.1024 22.2211 22.6617 22.6618C22.221 23.1025 21.6232 23.3501 21 23.3501H2.99996C2.3767 23.3501 1.77897 23.1025 1.33826 22.6618C0.897552 22.2211 0.649963 21.6233 0.649963 21.0001V16.5001C0.649963 15.8768 0.897552 15.2791 1.33826 14.8384ZM2.99996 15.8501C2.82757 15.8501 2.66224 15.9185 2.54034 16.0404C2.41845 16.1623 2.34996 16.3277 2.34996 16.5001V21.0001C2.34996 21.1725 2.41845 21.3378 2.54034 21.4597C2.66224 21.5816 2.82757 21.6501 2.99996 21.6501H21C21.1724 21.6501 21.3377 21.5816 21.4596 21.4597C21.5815 21.3378 21.65 21.1725 21.65 21.0001V16.5001C21.65 16.3277 21.5815 16.1623 21.4596 16.0404C21.3377 15.9185 21.1724 15.8501 21 15.8501H17.6399C17.0663 16.7013 16.3061 17.4135 15.4146 17.9311C14.3774 18.5333 13.1993 18.8504 12 18.8504C10.8006 18.8504 9.62253 18.5333 8.58528 17.9311C7.69383 17.4135 6.93363 16.7013 6.36003 15.8501H2.99996Z"
      fill={fill}
    />

    <path
      d="M16.2887 8.40604C16.5819 8.03947 16.5225 7.50457 16.1559 7.21131C15.7894 6.91806 15.2545 6.97749 14.9612 7.34406L13.4749 9.20189V7.87505C13.4749 6.05832 12.7532 4.31599 11.4686 3.03137C10.184 1.74675 8.44166 1.02505 6.62493 1.02505L5.12493 1.02505C4.65549 1.02505 4.27493 1.40561 4.27493 1.87505C4.27493 2.34449 4.65549 2.72505 5.12493 2.72505L6.62493 2.72505C7.9908 2.72505 9.30072 3.26764 10.2665 4.23345C11.2323 5.19926 11.7749 6.50919 11.7749 7.87505V9.20189L10.2887 7.34406C9.99541 6.97749 9.46051 6.91805 9.09394 7.21131C8.72737 7.50457 8.66794 8.03947 8.96119 8.40604L11.9563 12.1499C12.1119 12.3479 12.3536 12.4751 12.6249 12.4751C12.8364 12.4751 13.0385 12.3963 13.1934 12.257C13.2117 12.2406 13.2293 12.2232 13.2463 12.2051C13.2628 12.1874 13.2786 12.169 13.2936 12.1499L16.2887 8.40604Z"
      fill={fill}
    />
  </svg>
);
