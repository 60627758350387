import { UserReq, UserRes } from "@n3oltd/k2.users.sdk.users/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

import { ActionTypes, CreateEditUserAction } from "./actionTypes";

export function fetchSelectedEditUser(
  id: string,
  shouldUpdateGlobalUser?: boolean,
): CreateEditUserAction {
  return {
    type: ActionTypes.FETCH_SELECTED_EDIT_USER,
    id,
    shouldUpdateGlobalUser,
  };
}

export function fetchSelectedEditUserSuccess(
  user: UserRes,
): CreateEditUserAction {
  return {
    type: ActionTypes.FETCH_SELECTED_EDIT_USER_SUCCESS,
    user,
  };
}

export function fetchSelectedEditUserError(
  error: ServerError,
): CreateEditUserAction {
  return {
    type: ActionTypes.FETCH_SELECTED_EDIT_USER_ERROR,
    error,
  };
}

export function clearSelectedEditUserError(): CreateEditUserAction {
  return {
    type: ActionTypes.CLEAR_FETCH_SELECTED_EDIT_USER_ERROR,
  };
}

export function clearSelectedEditUser(): CreateEditUserAction {
  return {
    type: ActionTypes.CLEAR_SELECTED_EDIT_USER,
  };
}

export function clearEditStatus(): CreateEditUserAction {
  return {
    type: ActionTypes.CLEAR_EDIT_STATUS,
  };
}

export function updateSelectedEditUser(
  id: string,
  revisionId: string,
  user: UserReq,
  isCurrentUser: boolean,
): CreateEditUserAction {
  return {
    type: ActionTypes.UPDATE_SELECTED_EDIT_USER,
    revisionId,
    id,
    user,
    isCurrentUser,
  };
}

export function updateSelectedEditUserSuccess(
  editStatus: EditStatus,
): CreateEditUserAction {
  return {
    type: ActionTypes.UPDATE_SELECTED_EDIT_USER_SUCCESS,
    editStatus,
  };
}

export function updateSelectedEditUserError(
  editStatus: EditStatus,
): CreateEditUserAction {
  return {
    type: ActionTypes.UPDATE_SELECTED_EDIT_USER_ERROR,
    editStatus,
  };
}

export function createUser(user: UserReq): CreateEditUserAction {
  return {
    type: ActionTypes.CREATE_USER,
    user,
  };
}

export function createUserSuccess(
  editStatus: EditStatus,
  user: UserRes,
): CreateEditUserAction {
  return {
    type: ActionTypes.CREATE_USER_SUCCESS,
    editStatus,
    user,
  };
}

export function createUserError(editStatus: EditStatus): CreateEditUserAction {
  return {
    type: ActionTypes.CREATE_USER_ERROR,
    editStatus,
  };
}

export function activateUser(revisionId: string): CreateEditUserAction {
  return {
    type: ActionTypes.ACTIVATE_USER,
    revisionId,
  };
}

export function activateUserSuccess(
  editStatus: EditStatus,
): CreateEditUserAction {
  return {
    type: ActionTypes.ACTIVATE_USER_SUCCESS,
    editStatus,
  };
}

export function activateUserError(
  editStatus: EditStatus,
): CreateEditUserAction {
  return {
    type: ActionTypes.ACTIVATE_USER_ERROR,
    editStatus,
  };
}

export function deactivateUser(revisionId: string): CreateEditUserAction {
  return {
    type: ActionTypes.DEACTIVATE_USER,
    revisionId,
  };
}

export function deactivateUserSuccess(
  editStatus: EditStatus,
): CreateEditUserAction {
  return {
    type: ActionTypes.DEACTIVATE_USER_SUCCESS,
    editStatus,
  };
}

export function deactivateUserError(
  editStatus: EditStatus,
): CreateEditUserAction {
  return {
    type: ActionTypes.DEACTIVATE_USER_ERROR,
    editStatus,
  };
}

export function updateAdminPrivilege(
  revisionId: string,
  admin: boolean,
): CreateEditUserAction {
  return {
    type: ActionTypes.UPDATE_ADMIN_PRIVILEGE,
    revisionId,
    admin,
  };
}

export function updateAdminPrivilegeSuccess(
  editStatus: EditStatus,
  isAdmin: boolean,
): CreateEditUserAction {
  return {
    type: ActionTypes.UPDATE_ADMIN_PRIVILEGE_SUCCESS,
    isAdmin,
    editStatus,
  };
}

export function updateAdminPrivilegeError(
  editStatus: EditStatus,
): CreateEditUserAction {
  return {
    type: ActionTypes.UPDATE_ADMIN_PRIVILEGE_ERROR,
    editStatus,
  };
}
