import React, { FC } from "react";

import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { positionValues } from "react-custom-scrollbars";

import AppLayout from "containers/app/views/AppLayout";

interface Q1LayoutProps {
  isTabScreen: boolean;
  isMobileScreen: boolean;
  onScroll: (values: positionValues) => void;
  currentUser: CurrentUserRes;
  taskCount: number;
}

const Q1Layout: FC<Q1LayoutProps> = (props) => {
  return <AppLayout {...props} />;
};

export default Q1Layout;
