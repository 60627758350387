import { BreadcrumbConfig } from "appRedux/modules/breadcrumbs/types";

export enum ActionTypes {
  SET_BREADCRUMBS = "@global/set-breadcrumbs",
}

export interface SetBreadcrumbs {
  type: ActionTypes.SET_BREADCRUMBS;
  crumbs: BreadcrumbConfig[];
}
