import React, { FC, useCallback, useEffect, useState } from "react";

import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons/lib";
import {
  MergeFieldsNodeRes,
  MergeFieldsRootRes,
  TemplateModelType,
} from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { MergeFieldRootsResultsList } from "@n3oltd/karakoram.templates.sdk.lookups/src/index";
import { CompositionType } from "@n3oltd/karakoram.templates.sdk.pdf";
import {
  Collapse,
  Empty,
  Row,
  Skeleton,
  Tooltip,
  Tree,
  Typography,
} from "antd";
import { DataNode } from "antd/es/tree";
import styled from "styled-components";

import { _templatesLookupClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { K2Message, K2Search, K2Tooltip } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { CustomScrollbars, UIUtils } from "components/utils";
import { TEMPLATE_ENGINE } from "constants/appConstants";
import CommunicationAdminHelpers from "routes/admin/communication/helpers";
import { PageType } from "routes/admin/communication/modules/types";
import { VariableUsage } from "routes/admin/communication/newEditPartialOrLayout/components/shared";

const { Panel } = Collapse;
const { Text } = Typography;

interface IProps {
  templateModelTypeId: TemplateModelType;
  k2Intl: InjectedK2Intl;
  pageType: PageType;
  entityType: CompositionType;
}

const MergeFieldsTree = styled(Tree)`
  && {
    list-style: none;
    padding: 0 12px 0 0;
    min-height: 35px;

    ul.ant-tree-child-tree {
      list-style: none;
    }
    .ant-tree-node-content-wrapper:hover {
      cursor: default;
    }

    .ant-tree-title {
      font-weight: ${({ theme }) => theme.font_weight_bold};
      cursor: default;
    }
    .ant-tree-switcher {
      cursor: pointer;
    }
    .ant-tree-treenode-switcher-close {
      width: 100%;
    }
    .ant-tree-node-content-wrapper {
      flex-grow: 1;
    }
    .ant-tree-child-tree-open {
      margin-top: 18px;
    }
  }
`;

const VariableList: FC<IProps> = ({
  templateModelTypeId,
  k2Intl,
  pageType,
  entityType,
}) => {
  const [variables, setVariables] = useState<MergeFieldsRootRes[]>(null);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [loadingVariables, setLoadingVariables] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const findVariables = useCallback(
    async (templateModelTypeId: TemplateModelType) => {
      setLoadingVariables(true);
      const resp: IApiResponse<MergeFieldRootsResultsList> = await K2RestService.toResponse(
        _templatesLookupClient.findMergeFields(TEMPLATE_ENGINE, {
          modelType: templateModelTypeId ? [templateModelTypeId] : null,
        }),
      );

      setLoadingVariables(false);
      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        setVariables(resp.getResultOrDefault().items);
      }
    },
    [k2Intl],
  );

  const renderRootNode = useCallback(
    (node: MergeFieldsRootRes): React.ReactNode => {
      return node.name;
    },
    [],
  );

  const renderChildNode = useCallback(
    (node: MergeFieldsNodeRes): React.ReactNode => {
      return (
        <>
          <span>
            <span style={{ fontWeight: 600 }}>{node.name}</span>
            {node.example && (
              <span style={{ fontWeight: 300 }}>
                {" "}
                (<K2Message localeKey={"common.eg"} /> {node.example})
              </span>
            )}
          </span>
          {node.usageSyntax && (
            <VariableUsage style={{ float: "right" }}>
              <Text copyable>{node.usageSyntax}</Text>
            </VariableUsage>
          )}
        </>
      );
    },
    [],
  );

  useEffect(() => {
    if (variables) {
      setTreeData(
        CommunicationAdminHelpers.createTreeFromNodes(
          variables,
          renderRootNode,
          renderChildNode,
          searchTerm,
        ),
      );
    }
  }, [variables, renderChildNode, renderRootNode, searchTerm]);

  useEffect(() => {
    if (templateModelTypeId) {
      findVariables(templateModelTypeId);
    }
    // eslint-disable-next-line
  }, [templateModelTypeId]);

  return (
    <Collapse
      style={{ marginBottom: 20, borderRadius: "8px" }}
      bordered={true}
      expandIcon={({ isActive }) =>
        isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />
      }
      onChange={() => setExpanded(!expanded)}
    >
      <Panel
        key={1}
        header={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWeight: 600,
            }}
          >
            <K2Message
              localeKey={
                expanded
                  ? "admin.communication.mergeFields.hide"
                  : "admin.communication.mergeFields.show"
              }
            />
            <K2Tooltip
              title={k2Intl?.formatMessage({
                localeKey: "admin.communication.mergeFields.explanation",
                values: {
                  type: k2Intl?.formatMessage({
                    localeKey: `admin.communication.${pageType}`,
                  }),
                  entityType: k2Intl?.formatMessage({
                    localeKey: `communication.${entityType}`,
                  }),
                },
              })}
            >
              <QuestionCircleTwoTone />
            </K2Tooltip>
          </div>
        }
      >
        <Row>
          <K2Search
            value={searchTerm}
            placeholderKey={
              "admin.communication.mergeFields.search.placeholder"
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
          />
        </Row>

        <Skeleton active loading={loadingVariables}>
          <CustomScrollbars
            style={{ marginTop: 10 }}
            autoHeight={true}
            autoHide={false}
            autoHeightMax={300}
            autoHeightMin={10}
          >
            {treeData.length > 0 ? (
              <MergeFieldsTree
                treeData={treeData}
                showLine={{ showLeafIcon: false }}
                selectable={false}
                showIcon={false}
              />
            ) : searchTerm ? (
              <Empty
                description={k2Intl?.formatMessage({
                  localeKey: "admin.communication.mergeFields.noneMatching",
                })}
              />
            ) : (
              <Empty
                description={k2Intl?.formatMessage({
                  localeKey: "admin.communication.mergeFields.none",
                })}
              />
            )}
          </CustomScrollbars>
        </Skeleton>
      </Panel>
    </Collapse>
  );
};

export default VariableList;
