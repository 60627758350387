import React, { FC, useCallback, useEffect, useState } from "react";

import {
  CompositionType,
  PdfCompositionResultsPage,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";
import { SelectValue } from "antd/es/select";

import { _pdfCompositionsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { K2SelectV4 } from "components/k2Widgets";
import { DEFAULT_DELAY, TEMPLATE_ENGINE } from "constants/appConstants";
import useDebounce from "hooks/useDebounce";

interface IProps {
  onChange?: (id: SelectValue) => void;
  value?: SelectValue;
}

const PdfCompositionSelector: FC<IProps> = ({ onChange, value }) => {
  const [compositions, setCompositions] = useState<PdfCompositionResultsPage>({
    items: [],
    continuationToken: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DELAY);

  const fetchCompositions = useCallback(async (debouncedSearchTerm: string) => {
    setLoading(true);

    const resp: IApiResponse<PdfCompositionResultsPage> = await K2RestService.toResponse(
      _pdfCompositionsClient.findCompositions(TEMPLATE_ENGINE, null, {
        fullText: debouncedSearchTerm,
        compositionType: CompositionType.Pdf,
      }),
    );

    if (!resp.error) {
      setCompositions(resp.getResultOrDefault());
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchCompositions(debouncedSearchTerm);
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <K2SelectV4
      onChange={(id) => onChange?.(id)}
      value={value}
      placeholderKey={
        "admin.sponsorship.schemes.form.pdfComposition.placeholder"
      }
      optionLabelProp={"children"}
      optionFilterProp={"children"}
      dataSource={compositions.items}
      loading={loading}
      idField={"id"}
      nameField={"name"}
      style={{ width: "100%" }}
      showSearch
      onSearch={(e) => setSearchTerm(e)}
      focusInputOnFocus
      allowClear
      showArrow
    />
  );
};

export default PdfCompositionSelector;
