import React, { FC } from "react";
import styled from "styled-components";
import { Space, Skeleton } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { MOBILE_SIZE } from "../../../constants/ThemeSetting";
import { K2Button, K2Card, K2Message } from "components/k2Widgets";

interface IProps {
  handleClick: () => void;
}

const OverviewCard = styled(K2Card)<{
  $mobileWidth;
}>`
  border-radius: 8px;
  margin: 0;

  background-color: #fff;
  box-shadow: ${({ theme }) => theme.card_shadow};
  border: 0.5px #fff solid;
  width: 100%;

  button {
    color: ${({ theme }) => theme.link_color};
  }

  &:hover {
    border: 0.5px ${({ theme }) => theme.dark_3} dashed;
    cursor: pointer;
  }

  .ant-card-body {
    padding: 24px 18px;
  }

  .ant-skeleton-avatar {
    width: 54px !important;
    height: 54px !important;
    background: ${({ theme }) => theme.grey_2} !important;
  }

  @media screen and (max-width: ${({ $mobileWidth }) => $mobileWidth}px) {
    .ant-card-body {
      padding: 14px 20px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomSkeleton = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  @media screen and (min-width: 1200px) and (max-width: 1280px) {
    width: ${({ width }) => width - 20}px;
  }

  background: ${({ theme }) => theme.grey_2};
  border-radius: 8px;
`;

const EmptyOverviewCard: FC<IProps> = (props) => {
  const { handleClick } = props;

  return (
    <OverviewCard $mobileWidth={MOBILE_SIZE} onClick={handleClick}>
      <Wrapper>
        <Space direction="vertical" size="large">
          <CustomSkeleton width={92} height={12} />
          <CustomSkeleton width={70} height={20} />
          <CustomSkeleton width={60} height={12} />
        </Space>

        <K2Button type="link" icon={<PlusCircleOutlined />}>
          <K2Message localeKey="common.add" />
        </K2Button>

        <Skeleton.Avatar shape="circle" size="large" />
      </Wrapper>
    </OverviewCard>
  );
};

export default EmptyOverviewCard;
