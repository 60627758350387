import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const PaymentChange: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.25 1.75H1.75C1.33516 1.75 1 2.08516 1 2.5V17.5C1 17.9148 1.33516 18.25 1.75 18.25H8.2C8.64183 18.25 9 17.8918 9 17.45V17.3625C9 16.9207 8.64183 16.5625 8.2 16.5625H2.6875V8.3125H20.3125V9.7C20.3125 10.1418 20.6707 10.5 21.1125 10.5H21.2C21.6418 10.5 22 10.1418 22 9.7V2.5C22 2.08516 21.6648 1.75 21.25 1.75ZM20.3125 3.4375H2.6875V6.25H20.3125V3.4375Z"
      fill={fill}
    />
    <path
      d="M22.4329 18.4773H12.1415C12.0638 18.4773 12.0002 18.5438 12.0002 18.625V19.733C12.0002 19.8142 12.0638 19.8807 12.1415 19.8807H20.8309L18.2822 23.2599C18.2098 23.356 18.2751 23.5 18.3934 23.5H19.674C19.7605 23.5 19.8418 23.4594 19.8965 23.3874L22.878 19.4338C23.1694 19.046 22.9062 18.4773 22.4329 18.4773ZM22.8779 15.1193H14.1885L16.7372 11.7401C16.8096 11.644 16.7443 11.5 16.6259 11.5H15.3454C15.2588 11.5 15.1776 11.5406 15.1228 11.6126L12.1414 15.5662C11.85 15.954 12.1131 16.5227 12.5847 16.5227H22.8779C22.9556 16.5227 23.0192 16.4563 23.0192 16.375V15.267C23.0192 15.1858 22.9556 15.1193 22.8779 15.1193Z"
      fill={fill}
    />
  </svg>
);
