import React, { FC, useCallback, useMemo, useState } from "react";

import { QuestionCircleTwoTone } from "@ant-design/icons";
import { FundDimensionRes } from "@n3oltd/k2.subscriptions.sdk.funds/esm";
import {
  ComponentReq,
  PricingRuleReq,
} from "@n3oltd/karakoram.sponsorships.beneficiarytypes.child.sdk.child/esm";
import { Col, Row, Typography } from "antd";
import _ from "lodash";

import DeleteIcon from "components/deleteIcon";
import { GeneralFormItemV4 } from "components/formItems";
import CurrencyInput from "components/formItems/InputCurrencyFormItem";
import {
  K2Checkbox,
  K2Message,
  K2SelectV4,
  K2Tooltip,
} from "components/k2Widgets";
import { Utils } from "components/utils";
import SchemeFormHelpers from "routes/admin/sponsorships/schemes/helpers";

import { IPricingRuleBodyProps, SchemeReqType } from "../../modules/types";
import { FlexAlignCenterWrapper } from "../styled";

const { Text } = Typography;

const PricingRuleBody: FC<IPricingRuleBodyProps> = ({
  fieldIndex,
  componentFieldIndex,
  form,
  moreThen1Rule,
  defaultCurrency,
  allowedDimensions,
  fundDimensionsData,
  formErrors,

  removeRule,
}) => {
  const { getFieldValue } = form;

  const [
    showFundDimensionsError,
    setShowFundDimensionsError,
  ] = useState<boolean>(true);

  const dimensionsData = useMemo(
    () => _.omit(fundDimensionsData, "revisionId"),
    [fundDimensionsData],
  );

  const priceFieldValue = useMemo(() => {
    return getFieldValue([
      "components",
      componentFieldIndex,
      "pricing",
      "rules",
      fieldIndex,
      "price",
      "amount",
    ]);
  }, [componentFieldIndex, fieldIndex, getFieldValue]);

  const getFundDimensionsError = useMemo(() => {
    return formErrors?.find(
      (error) =>
        error?.name ===
        `components[${componentFieldIndex}].pricing.rules[${fieldIndex}].fundDimensions`,
    )?.errors[0];
  }, [componentFieldIndex, fieldIndex, formErrors]);

  const fundDimensionSelected = useCallback(() => {
    let data = getFieldValue([
      "components",
      componentFieldIndex,
      "pricing",
      "rules",
      fieldIndex,
      "fundDimensions",
    ]);

    data = _.omitBy(data, _.isNil);

    setShowFundDimensionsError(!data);
  }, [componentFieldIndex, fieldIndex, getFieldValue]);

  return (
    <>
      <Row gutter={[0, 8]} className={"n3o-mb-0"}>
        {Object.keys(dimensionsData)
          .sort((a, b) => dimensionsData[a].order - dimensionsData[b].order)
          ?.map?.((key: keyof typeof dimensionsData, index: number) => {
            const dim: FundDimensionRes = dimensionsData[key];

            if (!dim || !dim.isActive || !allowedDimensions?.[key]) {
              return "";
            }

            return (
              <Col
                span={24}
                key={
                  "PricingRuleBody_" +
                  componentFieldIndex +
                  "_" +
                  fieldIndex +
                  "_" +
                  key +
                  "_" +
                  index
                }
              >
                <GeneralFormItemV4
                  name={SchemeFormHelpers.getPricingRuleFundDimensionFieldName(
                    fieldIndex,
                    key,
                  )}
                  required
                >
                  <K2SelectV4
                    dataSource={Utils.convertStringArrayToKeyValueArray(
                      allowedDimensions[key],
                    )}
                    placeholderKey={"common.selectField"}
                    placeholderValues={{ fieldName: dim.name }}
                    onSelect={fundDimensionSelected}
                    showArrow
                    allowClear
                  />
                </GeneralFormItemV4>
              </Col>
            );
          })}
        {showFundDimensionsError && getFundDimensionsError ? (
          <Col span={24} style={{ marginTop: -16 }}>
            <Text type="danger">{getFundDimensionsError}</Text>
          </Col>
        ) : null}
      </Row>
      <Row gutter={[0, 8]} className={"n3o-mb-0"}>
        <Col span={24}>
          <FlexAlignCenterWrapper>
            <CurrencyInput
              currentForm={form}
              dontShowLabel
              currency={defaultCurrency}
              labelKey={"common.price"}
              initialValue={priceFieldValue}
              defaultValue={priceFieldValue}
              formItemProps={{
                className: "n3o-mb-0",
                required: true,
                name: SchemeFormHelpers.getPricingRuleAmountFieldName(
                  fieldIndex,
                ),
                style: {
                  width: moreThen1Rule ? 250 : "initial",
                },
              }}
            />
            <GeneralFormItemV4
              name={SchemeFormHelpers.getPricingRuleLockedFieldName(fieldIndex)}
              valuePropName={"checked"}
              className={"n3o-mb-0"}
              style={{
                width: moreThen1Rule ? 210 : "initial",
                paddingLeft: 20,
              }}
            >
              <K2Checkbox>
                <K2Message
                  localeKey={
                    "admin.sponsorship.schemes.form.pricing.fixedPrice"
                  }
                />
              </K2Checkbox>
            </GeneralFormItemV4>
            <K2Tooltip
              placement={"rightTop"}
              titleKey={
                "admin.sponsorship.schemes.form.pricing.rule.lockedPrice.tooltip"
              }
            >
              <QuestionCircleTwoTone
                style={{ paddingLeft: 10, paddingBottom: 5 }}
                className={"n3o-pointer"}
              />
            </K2Tooltip>
            {moreThen1Rule && (
              <span className={"n3o-w-100"}>
                <DeleteIcon
                  className={"n3o-float-right"}
                  tooltipKey={"common.remove"}
                  onClick={removeRule}
                />
              </span>
            )}
          </FlexAlignCenterWrapper>
        </Col>
      </Row>
    </>
  );
};

export default PricingRuleBody;
