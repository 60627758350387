import produce from "immer";
import * as actionTypes from "./actionTypes";
import { DonationItems } from "./types";

const initialState: DonationItems = {
  fetching: true,
  errorFetchingItems: null,
  data: {
    items: [],
    continuationToken: "",
  },
  editStatus: null,
};

const donationItemsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.FETCH_DONATION_ITEMS:
        draft.fetching = true;
        break;
      case actionTypes.FETCH_DONATION_ITEMS_SUCCESS:
        draft.fetching = false;
        if (action.shouldAppendToList) {
          draft.data.items = draft.data.items.concat(action.result.items);
        } else {
          draft.data.items = action.result.items;
        }
        draft.data.continuationToken = action.result.continuationToken;
        break;
      case actionTypes.FETCH_DONATION_ITEMS_ERROR:
        draft.fetching = false;
        draft.errorFetchingItems = action.error;
        break;
      case actionTypes.UPDATE_DONATION_ITEMS_STATUS:
        draft.fetching = true;
        break;
      case actionTypes.UPDATE_DONATION_ITEMS_STATUS_SUCCESS:
        draft.fetching = false;
        draft.data.items = draft.data.items?.map?.((item) => {
          if (action.ids.includes(item.id) || action.allSelected) {
            item.status = action.activeStatus;
          }
          return item;
        });
        draft.editStatus = action.editStatus;
        break;
      case actionTypes.UPDATE_DONATION_ITEMS_STATUS_ERROR:
        draft.fetching = false;
        draft.editStatus = action.editStatus;
        break;
      case actionTypes.CLEAR_EDIT_STATUS:
        draft.editStatus = null;
        break;
      case actionTypes.CLEAR_FETCH_DONATION_ITEMS_ERROR:
        draft.errorFetchingItems = null;
        break;
    }
  });

export default donationItemsReducer;
