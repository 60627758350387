import {
  BulkReq,
  BulkSelection,
  DonationItemResultsPage,
} from "@n3oltd/k2.donations.sdk.donation-items/esm";
import { all, put, takeLatest } from "redux-saga/effects";

import { _donationItemsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { DataActions, EditStatus } from "appRedux/modules/sharedTypes";

import * as actionTypes from "./actionTypes";
import * as actions from "./actions";

function* fetchDonationItems(
  action: ReturnType<typeof actions.fetchDonationItems>,
) {
  const response: IApiResponse<DonationItemResultsPage> = yield K2RestService.toResponse(
    _donationItemsClient.findDonationItems(action.criteria),
  );
  if (response.error) {
    yield put(actions.fetchDonationItemsError(response.error));
  } else {
    yield put(
      actions.fetchDonationItemsSuccess(
        response.getResultOrDefault(),
        action.shouldAppendToList,
      ),
    );
  }
}

function* updateDonationItemsStatus(
  action: ReturnType<typeof actions.updateDonationItemsStatus>,
) {
  let response: IApiResponse<void>;
  const req: BulkReq = {};
  if (action.options.all) {
    req.selection = BulkSelection.AllEntries;
  } else {
    req.selection = BulkSelection.SpecifiedEntries;
    req.ids = action.options.ids;
  }

  if (action.options.status === "active") {
    response = yield K2RestService.toResponse(
      _donationItemsClient.bulkActivateDonationItems(req),
    );
  } else {
    response = yield K2RestService.toResponse(
      _donationItemsClient.bulkDeactivateDonationItems(req),
    );
  }

  const editStatus: EditStatus<void> = {
    action: DataActions.save,
    ...response,
  };

  if (!response.error) {
    yield put(
      actions.updateDonationItemsStatusSuccess(
        action.options.ids,
        action.options.status,
        action.options.all,
        editStatus,
      ),
    );
  } else {
    yield put(actions.updateDonationItemsStatusError(editStatus));
  }
}

export default function* donationItemsAdminSaga() {
  yield all([
    takeLatest(actionTypes.FETCH_DONATION_ITEMS, fetchDonationItems),
    takeLatest(
      actionTypes.UPDATE_DONATION_ITEMS_STATUS,
      updateDonationItemsStatus,
    ),
  ]);
}
