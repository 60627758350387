import styled from "styled-components";
import { Col, Row } from "antd";
import { AppId } from "appRedux/modules/sharedTypes";

export const PageWithBreadcrumbsWrapper = styled.div<{ width: number }>`
  .ant-breadcrumb {
    padding: ${({ width, theme }) =>
      width <= Number(theme.screen_xs_max.slice(0, -2)) ? "0 20px" : "0"};
  }
`;

export const FormsWrapper = styled.div<{ width?: number }>`
  margin: 25px 0;
  padding: ${({ width, theme }) =>
    width && width <= Number(theme.screen_xs_max.slice(0, -2))
      ? "15px 0"
      : "0"};
  border-radius: 8px;
`;

export const MainFormsWrapper = styled(Row)`
  margin-top: 2rem;
`;

export const FormsColumnWrapper = styled(Col)``;

export const AnchorsColumnWrapper = styled(Col)`
  .ant-anchor-wrapper {
    width: fit-content;
    margin: 0 70px 0 auto;
    background-color: transparent;
  }
`;

export const MainPageWrapper = styled.div<{
  $width: number;
  $appId: AppId;
  $isOnDashboard;
}>`
  padding: ${({ $width, $isOnDashboard, theme }) =>
    $width <= Number(theme.screen_xs_max.slice(0, -2))
      ? "0 15px"
      : !$isOnDashboard
      ? "32px"
      : "0 32px 32px"};
  flex: 1 1;
  min-height: 79vh;
  padding-top: ${({ $width, theme }) =>
    $width <= Number(theme.screen_md_max.slice(0, -2)) ? "50px" : ""};

  position: ${({ $width, $isOnDashboard, theme }) =>
    $width <= Number(theme.screen_xs_max.slice(0, -2)) && $isOnDashboard
      ? "relative"
      : "unset"};
  top: ${({ $width, $isOnDashboard, theme }) =>
    $width <= Number(theme.screen_xs_max.slice(0, -2)) && $isOnDashboard
      ? "0px"
      : "0"};

  ${({ $appId, $isOnDashboard }) =>
    $appId === "k2" &&
    $isOnDashboard &&
    `
    background-image: url(${require("assets/images/engage/dashboard/dashboard-filler-pattern.png")});
  `}
  ${({ $appId, $isOnDashboard }) =>
    $appId === "q1" &&
    $isOnDashboard &&
    `
    background-image: url(${require("assets/images/tally/dashboard/dashboard-filler-pattern.png")});
  `}
  background-blend-mode: normal,color,normal;
  background-repeat: no-repeat;
  background-size: contain;
`;
