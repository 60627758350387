import { OrganizationInformationRes } from "@n3oltd/k2.subscriptions.sdk.organization/esm";
import { SubscriptionRes } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import {
  CurrentUserRes,
  LocalizationSettingsRes,
} from "@n3oltd/k2.users.sdk.users/esm";
import { createSelector } from "reselect";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { subsLocalizationLookups } from "appRedux/models/lookups/LookupsModel";
import { IAppSettingsProps } from "appRedux/store/types/SubscriptionTypes";

import DateFormatProvider from "../../models/localization/DateFormatProvider";
import * as lookupActions from "../../modules/lookups/actions";
import {
  CountryLookups,
  SubscriberTypesLookups,
} from "../../modules/lookups/types";
import { EditStatus, ILocalizationData } from "../../modules/sharedTypes";
import { settingsActions } from "../../modules/subscription";
import IApplicationState from "../../types";
import UsersSelectors from "./UsersSelectors";

const { invalidateLookupsAll: invalidateLookups } = lookupActions;

const {
  updateSubscriptionOrganization,
  addSubscriptionOrganizationOffice,
  updateSubscriptionOrganizationOffice,
  deleteSubscriptionOrganizationOffice,
  setSubscriptionOrganizationEditStatus,
  fetchSubscriptionOrganization,
  clearSubscriptionOrganization,
  fetchSubscriptionLocalizations,
  clearSubscriptionLocalizations,
  updateSubscriptionLocalizations,
  setSubscriptionLocalizationsEditStatus,
  clearLocalizationEditStatus,
  removeSubscriptionOrganizationError,
  setSubscriptionOfficesEditStatus,
  setLocalizationsDirty,
  clearLocalizationsDirty,
} = settingsActions;

export default class SubscriptionSelectors {
  public static currentSubscriptionSelector = ({
    subscription,
  }: IApplicationState) => subscription.users.k2Subscription;

  public static settingsDataSelector = ({ subscription }: IApplicationState) =>
    subscription.settings;

  private static getFormatSelector = (
    propName: keyof LocalizationSettingsRes,
  ) => {
    return createSelector(
      UsersSelectors.getK2SubscriptionSelector(),
      UsersSelectors.getK2UserSelector(),
      (k2Subscription: SubscriptionRes, k2User: CurrentUserRes) => {
        return (
          k2User?.localization?.[propName] ||
          k2Subscription?.localization?.[propName]
        );
      },
    );
  };

  public static getBaseCurrencySelector() {
    return createSelector(
      UsersSelectors.getK2SubscriptionSelector(),
      (k2Subscription: SubscriptionRes) => "", //TODO: NEEDS FIXING -- k2Subscription && k2Subscription.baseCurrency
    );
  }

  public static getDateFormatSelector() {
    return createSelector(
      this.getFormatSelector("dateFormat"),
      (dateFormatKey: string) => ({
        dateFormat: DateFormatProvider.getConfig(dateFormatKey).format,
      }),
    );
  }

  public static getAppSettingsSelector() {
    return createSelector(
      this.getFormatSelector("dateFormat"),
      this.getFormatSelector("timeFormat"),
      this.getFormatSelector("numberFormat"),
      this.getFormatSelector("timezone"),
      this.getFormatSelector("language"),
      this.getBaseCurrencySelector(),
      UsersSelectors.getUserLocalizationSelector(),
      (
        dateFormat: string,
        timeFormat: string,
        numberFormat: string,
        timeZone: string,
        language: string,
        baseCurrency: string,
        localizationSettings,
      ): IAppSettingsProps => {
        return {
          appSettings: {
            localizationKeys: {
              dateFormat,
              timeFormat,
              numberFormat,
              timeZone,
              language,
              baseCurrency,
            },
          },
          localizationSettings,
        };
      },
    );
  }

  public static getOrganizationSelector() {
    const loadingCompleteSelector = createSelector(
      ({ lookups }: IApplicationState) =>
        lookups.countries.loading || lookups.subscriberTypes.loading,
      this.settingsDataSelector,
      (lookupsLoading, settings) => {
        return !lookupsLoading && !!settings.organization.organizationDetails;
      },
    );

    return createSelector(
      loadingCompleteSelector,
      this.settingsDataSelector,
      (loadingComplete, settings): IOrganizationSelector => {
        const { organizationDetails, isLoading } = settings.organization;
        return {
          organizationDetails,
          loadingComplete: loadingComplete,
          isLoading,
        };
      },
    );
  }

  public static getOrganizationDispatcher(): IOrganizationDispatchers {
    return {
      fetchSubscriptionOrganization,
      clearSubscriptionOrganization,
    };
  }

  public static getLocalizationSettingsSelector() {
    const loadingCompleteSelector = createSelector(
      ({ lookups }: IApplicationState) => {
        let isLoading = false;
        subsLocalizationLookups.forEach((l) => {
          if (lookups[l].loading) isLoading = true;
        });
        return isLoading;
      },
      this.settingsDataSelector,
      (lookupsLoading, settings) => {
        return !lookupsLoading && !!settings.localizations.localizationDetails;
      },
    );

    return createSelector(
      loadingCompleteSelector,
      this.settingsDataSelector,
      this.currentSubscriptionSelector,
      UsersSelectors.getUserLocalizationSelector(),
      (
        loadingComplete,
        settings,
        currentSubscription,
        currentUserLocalizationSettings,
      ): ILocalizationSettingsSelector => {
        const {
          isLoading,
          isSaving,
          editStatus,
          localizationDetails,
          dirty,
        } = settings.localizations;
        return {
          isLoading,
          isSaving,
          editStatus,
          localizationDetails,
          currentUserLocalizationSettings,
          loadingComplete,
          currentSubscription,
          dirty,
        };
      },
    );
  }

  public static getLocalizationSettingsDispatchers(): ILocalizationSettingsDispatchers {
    return {
      fetchSubscriptionLocalizations,
      clearSubscriptionLocalizations,
      updateSubscriptionLocalizations,
      setSubscriptionLocalizationsEditStatus,
      clearLocalizationEditStatus,
      setFormDirty: setLocalizationsDirty,
      clearFormDirty: clearLocalizationsDirty,
      invalidateLookups,
    };
  }

  public static getOrganizationViewSelector() {
    return createSelector(
      this.settingsDataSelector,
      (settings): IOrganizationViewSelectors => {
        const {
          editStatus,
          officesEditStatus,
          errors,
          organizationDetails,
          isSaving,
        } = settings.organization;
        return {
          officesEditStatus,
          editStatus,
          errors,
          organizationDetails,
          isSaving,
        };
      },
    );
  }

  public static getOrganizationViewDispatchers(): IOrganizationViewDispatchers {
    return {
      updateSubscriptionOrganization,
      addSubscriptionOrganizationOffice,
      updateSubscriptionOrganizationOffice,
      deleteSubscriptionOrganizationOffice,
      setSubscriptionOrganizationEditStatus,
      removeSubscriptionOrganizationError,
      setSubscriptionOfficesEditStatus,
    };
  }
}

//#region Localization settings
type ILocalizationSettingsSelector = ILocalizationData & {
  localizationDetails: LocalizationSettingsRes;
  currentUserLocalizationSettings: LocalizationSettingsRes;
  currentSubscription: SubscriptionRes;
  loadingComplete: boolean;
  isLoading: boolean;
  isSaving: boolean;
  editStatus: EditStatus<any>;
  dirty: boolean;

  subscriptionId?: string;
  showExchangeRate?: boolean;
};

type ILocalizationSettingsDispatchers = {
  fetchSubscriptionLocalizations: typeof fetchSubscriptionLocalizations;
  clearSubscriptionLocalizations: typeof clearSubscriptionLocalizations;
  updateSubscriptionLocalizations: typeof updateSubscriptionLocalizations;
  setSubscriptionLocalizationsEditStatus: typeof setSubscriptionLocalizationsEditStatus;
  clearLocalizationEditStatus: typeof clearLocalizationEditStatus;
  clearFormDirty: typeof clearLocalizationsDirty;
  setFormDirty: typeof setLocalizationsDirty;
  invalidateLookups: typeof invalidateLookups;
};

export type ILocalizationSettingsStateProps = ILocalizationSettingsSelector &
  ILocalizationSettingsDispatchers;

//#endregion

//#region Organization View Container
type IOrganizationSelector = IOrganizationInformationLookups & {
  organizationDetails: OrganizationInformationRes;
  loadingComplete: boolean;
  isLoading: boolean;
};

type IOrganizationDispatchers = {
  fetchSubscriptionOrganization: typeof fetchSubscriptionOrganization;
  clearSubscriptionOrganization: typeof clearSubscriptionOrganization;
};

export type IOrganizationStateProps = IOrganizationSelector &
  IOrganizationDispatchers;

//#endregion

//#region Organization View
export type IOrganizationViewDispatchers = {
  updateSubscriptionOrganization: typeof updateSubscriptionOrganization;
  addSubscriptionOrganizationOffice: typeof addSubscriptionOrganizationOffice;
  updateSubscriptionOrganizationOffice: typeof updateSubscriptionOrganizationOffice;
  deleteSubscriptionOrganizationOffice: typeof deleteSubscriptionOrganizationOffice;
  setSubscriptionOrganizationEditStatus: typeof setSubscriptionOrganizationEditStatus;
  removeSubscriptionOrganizationError: typeof removeSubscriptionOrganizationError;
  setSubscriptionOfficesEditStatus: typeof setSubscriptionOfficesEditStatus;
};

export type IOrganizationViewSelectors = IOrganizationInformationLookups & {
  editStatus: EditStatus<any>;
  officesEditStatus: EditStatus;
  errors?: { id: string; error: ServerError }[];
  organizationDetails: OrganizationInformationRes;
  isSaving: boolean;
};

type IOrganizationInformationLookups = {
  countries?: CountryLookups;
  subscriberTypes?: SubscriberTypesLookups;
};
