import { addLocaleData } from "react-intl";

import { AppLocalesType } from "./types";

import enLang from "./entries/en-US";
import esLang from "./entries/en-US"; //TODO: before production build replace it with --> es_ES,
import frLang from "./entries/en-US"; //TODO: before production build replace it with --> fr_FR,

const AppLocales: AppLocalesType = {
  en: enLang,
  es: esLang,
  fr: frLang,
};

addLocaleData(AppLocales.en.data);
addLocaleData(AppLocales.es.data);
addLocaleData(AppLocales.fr.data);

export default AppLocales;
