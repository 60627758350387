import React, { FC } from "react";

import { IN3oIconProps } from "../types";

// Cannot choose colours for Diners Club brand logo
export const DinersClubCard: FC<IN3oIconProps> = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 160 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M5.15792 99C2.86544 99 1 97.1514 1 94.8793V5.12073C1 2.84875 2.8648 1 5.15792 1H154.842C157.135 1 159 2.84875 159 5.12073V94.8793C159 97.1514 157.135 99 154.842 99H5.15792Z"
        fill="white"
      />
      <path
        d="M154.582 2.52079C156.165 2.52079 157.452 3.7938 157.452 5.35921V94.6406C157.452 96.2062 156.165 97.4794 154.582 97.4794H5.41764C3.83516 97.4794 2.54827 96.2062 2.54827 94.6406V5.35921C2.54827 3.7938 3.83516 2.52079 5.41764 2.52079H154.582ZM154.582 0H5.41764C2.43806 0 0 2.41178 0 5.35921V94.6406C0 97.5881 2.43806 100 5.41764 100H154.582C157.562 100 160 97.5881 160 94.6406V5.35921C160 2.41178 157.562 0 154.582 0Z"
        fill="#004A97"
      />
      <path
        d="M71.3888 79.9996C54.1711 80.08 40 66.4078 40 49.7656C40 31.5774 54.1711 18.9967 71.3888 19H87.4578C104.471 18.9967 120 31.5716 120 49.7656C120 66.4028 104.471 79.9996 87.4578 79.9996H71.3888ZM71.4585 21.5221C55.7243 21.5278 42.9765 34.0248 42.9739 49.4465C42.9765 64.8666 55.7243 77.3612 71.4585 77.3645C87.1927 77.3612 99.9428 64.8666 99.9469 49.4465C99.9436 34.0248 87.1927 21.5278 71.4585 21.5221ZM64.992 65.9583V32.9299C58.2208 35.4855 53.4191 41.9075 53.4011 49.4457C53.4191 56.9831 58.2208 63.4059 64.992 65.9583ZM89.5176 49.4465C89.5053 41.9058 84.6963 35.474 77.9194 32.9274V65.9657C84.6954 63.4141 89.5053 56.9888 89.5176 49.4465Z"
        fill="#004A97"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="160" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
