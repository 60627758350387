import React, { FC } from "react";

import { FragmentType } from "@n3oltd/karakoram.templates.sdk.fragments/esm";
import { CompositionType } from "@n3oltd/karakoram.templates.sdk.pdf";
import styled from "styled-components";

import K2Modal from "components/k2Widgets/k2Modal";

import IFrame from "../../sharedComponents/IFrame";

interface PreviewProps {
  content: string;
  loading: boolean;
  error?: string;
  onClose: () => void;
  fragmentType: FragmentType;
  entityType: CompositionType;
}

const Error = styled.div`
  color: ${({ theme }) => theme.error_color};
`;

const Preview: FC<PreviewProps> = ({
  content,
  onClose,
  loading,
  error,
  fragmentType,
  entityType,
}) => {
  return (
    <K2Modal
      width={"70%"}
      loading={loading}
      titleKey={`admin.communication.${fragmentType}.preview`}
      onCancel={onClose}
      visible={true}
      footer={null}
    >
      {content &&
        (entityType === "sms" ? (
          <div style={{ marginTop: 20 }}>
            <IFrame content={content} height={100} />
          </div>
        ) : (
          <div style={{ marginTop: 20 }}>
            <IFrame content={content} />
          </div>
        ))}
      {error && <Error>{error}</Error>}
    </K2Modal>
  );
};

export default Preview;
