import React, { FC, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Input, Tooltip } from "antd";
import IApplicationState from "appRedux/types";
import CancelSaveOpts from "components/cancelSaveOpts";
import {
  updateTaskTitleRequest,
  clearUpdateTaskTitleError,
} from "components/dashboard/editTask/modules/actions";
import FormItemTitle from "components/forms/formItemTitle";
import EditableSection from "components/forms/editableSection";
import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { UIUtils } from "components/utils";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { K2Message } from "components/k2Widgets";
import usePrevious from "hooks/usePrevious";
import EditLinkIcon from "components/editLinkIcon";
import { editableSectionName } from "components/dashboard/editTask/modules/types";
import { useForm } from "antd/es/form/Form";
import { GeneralFormItemV4 } from "components/formItems";
import { showNotification } from "components/utils/Notification";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";

interface IProps extends InjectedK2IntlProps {
  updateTaskTitle: typeof updateTaskTitleRequest;
  clearError: typeof clearUpdateTaskTitleError;
  title: string;
  updating: boolean;
  error?: ServerError;
  taskId: string;
  setFormTouched?: (formName: editableSectionName) => void;
  clearFormTouched?: (formName: editableSectionName) => void;
}

const TaskTitle: FC<IProps> = ({
  updateTaskTitle,
  error,
  clearError,
  updating,
  taskId,
  k2Intl,
  clearFormTouched,
  setFormTouched,
  title: originalTitle,
}) => {
  const { theme } = useAppContext();

  const [form] = useForm();
  const [isEditing, setIsEditing] = useState(false);
  const prevUpdating = usePrevious(updating);

  const hasError: boolean = !!error;

  useEffect(() => {
    if (hasError) {
      UIUtils.handleServerError(k2Intl, error, clearError);
    }
    // eslint-disable-next-line
  }, [hasError]);

  // After saving a title is complete
  useEffect(() => {
    if (prevUpdating && !updating && !hasError) {
      setIsEditing(false);
      clearFormTouched?.("title");
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "tasks.taskUpdatedSuccess",
      });
    }
    // eslint-disable-next-line
  }, [updating]);

  const handleCancel = (): void => {
    setIsEditing(false);
    clearFormTouched?.("title");
    form?.setFieldsValue({
      title: originalTitle,
    });
    clearError();
  };

  return (
    <Form
      form={form}
      onValuesChange={() => setFormTouched?.("title")}
      layout="vertical"
      onFinish={() => {
        updateTaskTitle(form?.getFieldValue("title"), taskId);
      }}
      initialValues={{
        title: originalTitle,
      }}
    >
      <div>
        <FormItemTitle color={theme.grey_6}>
          <K2Message localeKey="tasks.title" />
        </FormItemTitle>
      </div>
      {isEditing ? (
        <>
          <GeneralFormItemV4
            style={{ marginBottom: "0px" }}
            name={"title"}
            rules={[
              {
                required: true,
                message: <K2Message localeKey="common.thisIsRequired" />,
              },
            ]}
            wrapperCol={{ xs: 24 }}
          >
            <Input />
          </GeneralFormItemV4>
          <CancelSaveOpts
            loading={updating}
            onCancel={handleCancel}
            onSave={() => updateTaskTitle(form?.getFieldValue("title"), taskId)}
          />
        </>
      ) : (
        <EditableSection>
          {originalTitle}
          <Tooltip title={<K2Message localeKey="common.clickToEdit" />}>
            <EditLinkIcon onClick={() => setIsEditing(true)} />
          </Tooltip>
        </EditableSection>
      )}
    </Form>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  title: state.editViewTask.task ? state.editViewTask.task.title : "",
  updating: state.editViewTask.updatingTitle,
  error: state.editViewTask.errorUpdatingTitle,
  taskId: state.editViewTask.task ? state.editViewTask.task.id : "",
});

const mapDispatchToProps = {
  updateTaskTitle: updateTaskTitleRequest,
  clearError: clearUpdateTaskTitleError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectK2Intl(TaskTitle));
