import { FundStructureRevisionRes } from "@n3oltd/k2.subscriptions.sdk.funds/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";

import { Dimension } from "./types";

export enum SharedActionTypes {
  FETCH_FUND_DIMENSIONS = "@admin/fundDimensions/FETCH_FUND_DIMENSIONS",
  FETCH_FUND_DIMENSIONS_SUCCESS = "@admin/fundDimensions/FETCH_FUND_DIMENSIONS_SUCCESS",
  FETCH_FUND_DIMENSIONS_ERROR = "@admin/fundDimensions/FETCH_FUND_DIMENSIONS_ERROR",
  CLEAR_FUND_DIMENSIONS = "@fundDimensionsAdmin/CLEAR_FUND_DIMENSIONS",
}

export interface FetchFundDimensions {
  type: SharedActionTypes.FETCH_FUND_DIMENSIONS;
}

export interface FetchFundDimensionsError {
  type: SharedActionTypes.FETCH_FUND_DIMENSIONS_ERROR;
  error: ServerError;
}

export interface FetchFundDimensionsSuccess {
  type: SharedActionTypes.FETCH_FUND_DIMENSIONS_SUCCESS;
  fundStructureRevisionId: string;
  dimensions: Dimension[];
  fundDimensionsStructure: FundStructureRevisionRes;
}

export interface ClearFundDimensions {
  type: SharedActionTypes.CLEAR_FUND_DIMENSIONS;
}

export type AdminAction =
  | FetchFundDimensions
  | FetchFundDimensionsSuccess
  | FetchFundDimensionsError
  | ClearFundDimensions;
