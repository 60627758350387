import { createGlobalStyle } from "styled-components";

import variables, { ThemeObject } from "./themeVariables";

const { classPrefix } = variables;

const GlobalStyle = createGlobalStyle<{ theme: ThemeObject }>`

html, body {
  background-color: ${({ theme }) => theme.body_background} !important;
  font-family: ${({ theme }) => theme.fontFamily} !important;
  font-weight: ${({ theme }) => theme.font_weight_normal};
  -moz-font-feature-settings:"ss04" 1;
  -ms-font-feature-settings:"ss04" 1;
  -o-font-feature-settings:"ss04" 1;
  -webkit-font-feature-settings:"ss04" 1;
  font-feature-settings:"ss04" 1;
  font-variant-numeric: tabular-nums;
}

::selection {
  color: ${({ theme }) => theme.white_color} !important;
  background-color: ${({ theme }) => theme.primaryColor} !important;
}

strong, b, .ant-menu-item-selected, h1, h2, h3, h4, h5, h6 {
  font-weight: ${({ theme }) => theme.font_weight_semi_bold} !important;
}

h1.ant-typography, h2.ant-typography, h3.ant-typography, h4.ant-typography, h5.ant-typography, h6.ant-typography {
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};
}

//TODO: It didn't added the color to progress bar. have to check
#nprogress .bar {
  background: ${({ theme }) => theme.primaryColor} !important;
}

// For sortable tables
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.custom-scrollbar-style {
  /* width */
  & ::-webkit-scrollbar {
    max-width: 6px;
  }

  /* hight */
  & ::-webkit-scrollbar:horizontal {
    max-height: 6px;
  }

  // /* Track */
  // & ::-webkit-scrollbar-track {
  //   background: #f1f1f1;
  // }

  /* Handle */
  & ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    cursor: pointer !important;
  }

  &.main-container {
    > div:first-child {
      margin-bottom: 0 !important;
    }

    > ::-webkit-scrollbar:horizontal {
      display: none;
    }

    /* Track */
    > ::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}

.${classPrefix}-display-table {
  display: table;
}

.${classPrefix}-display-table-footer-group {
  display: table-footer-group;
}

.${classPrefix}-vert-move {
  -webkit-animation: mover 0.75s infinite  alternate;
  animation: mover 0.75s infinite  alternate;
}

@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}

@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}


.${classPrefix}-left-to-right {
  animation: left-to-right 10s  ease;
}

@keyframes left-to-right {
  from {
    text-indent:70%;
  }
 
}


.ant-form-item-has-feedback {
  &.ant-form-item-has-success,
  &.ant-form-item-has-warning,
  &.ant-form-item-has-error,
  &.ant-form-item-is-validating {
    .ant-form-item-children-icon {
      svg {
        position: relative;
      }
    }
  }
}

.ant-modal-mask, .ant-image-preview-mask {
  z-index: 1001;
}

.ant-form-item-label {
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
  }

  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.red_6} !important;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    top: 3px;
    content: '*';
  }
}

.ant-picker-time-panel-column {
  padding: 0 0 200px 0;
}

.ant-layout {
  background-color: ${({ theme }) => theme.layout_background} !important;
}

.ant-alert {
  border: none !important;
}

.ant-btn:not(:last-child) {
  margin-right: 10px !important;
}

.ant-modal-wrap {
  z-index: 1002;
}

.ant-result-content:empty {
  display:none;
}

.ant-collapse-ghost {
    background-color: unset !important;
    border: none !important;
    border-radius: 0 !important;

    .ant-collapse-item {
      border: none !important;
      border-radius: 0 !important;
    }
}

button {
  letter-spacing: 0.025em;
}

.${classPrefix}-pointer {
  cursor: pointer !important;
}

.${classPrefix}-gradient-line-right {
  border-right: 1px solid;
  border-image: radial-gradient(#ffffff,${({ theme }) =>
    theme.grey_5},#ffffff) 1;
}

.${classPrefix}-gradient-line-bottom {
  border-bottom: 1px solid;
  border-image: radial-gradient(${({ theme }) => theme.grey_5},#ffffff) 1;
}

.${classPrefix}-not-allowed {
  cursor: not-allowed;
  opacity: 0.5;
}

.${classPrefix}-opacity-50 {
  opacity: 0.5;
}

.${classPrefix}-pointer-events-none {
  pointer-events: none;
}

.${classPrefix}-min-width-200 {
  min-width: 200px !important;
}

.${classPrefix}-text-white {
  color: ${({ theme }) => theme.white_color} !important;
}

.${classPrefix}-text-green-6 {
  color: ${({ theme }) => theme.green_6} !important;
}

.${classPrefix}-text-black {
  color: ${({ theme }) => theme.black_color} !important;
}

.${classPrefix}-text-light-grey {
  color: ${({ theme }) => theme.grey_5} !important;
}

.${classPrefix}-text-grey-6 {
  color: ${({ theme }) => theme.grey_6} !important;
}

.${classPrefix}-text-grey-7 {
  color: ${({ theme }) => theme.grey_7} !important;
}

.${classPrefix}-text-grey-8 {
  color: ${({ theme }) => theme.grey_8} !important;
}

.${classPrefix}-text-grey-9 {
  color: ${({ theme }) => theme.grey_9} !important;
}

.${classPrefix}-text-primary {
  color: ${({ theme }) => theme.primaryColor} !important;
}

.${classPrefix}-text-danger {
  color: ${({ theme }) => theme.error_color} !important;
}

.${classPrefix}-text-danger-hover:hover {
  color: ${({ theme }) => theme.error_color} !important;
}

.${classPrefix}-text-center {
  text-align: center !important;
}

.${classPrefix}-vertical-middle {
  vertical-align: middle !important;
}

.${classPrefix}-text-right {
  text-align: right !important;
}

.${classPrefix}-text-left {
  text-align: left !important;
}

.${classPrefix}-float-right {
  float: right !important;
}

// after scroll area
html:not([data-scroll="0"]) {
  .${classPrefix}-header-link {
    padding-right: 28px;

    transition-timing-function: ease-out;
    transition: 300ms;
  }

  .${classPrefix}-header-link.inactive:hover {
    color: ${({ theme }) => theme.grey_4};
    opacity: 0.75;
  }
}

.temp-display-none {
  display: none !important;
}

.${classPrefix}-w-100 {
  width: 100% !important;
}

.${classPrefix}-h-100 {
  height: 100% !important;
  max-height: 100% !important;
}

.${classPrefix}-hidden-field {
  padding: 0 !important;
  margin: 0 !important;
  .ant-form-item-control-input {
    height: 0px;
    min-height: 0px;
  }
}

.${classPrefix}-hidden-field:not(.ant-form-item-has-error) {
  height: 0px;
}

.${classPrefix}-vertical-middle {
  vertical-align: middle !important;
}

@media screen and (max-width: 768px) {
  .${classPrefix}-back-top {
    right: 60px;
  }
}

@media screen and (max-width: 480px) {
  .${classPrefix}-back-top {
    right: 20px;
  }
}

@media screen and (max-width: ${({ theme }) => theme.screen_xs_max}) {
  & .ant-popover-inner-content {
    & .${classPrefix}-global-search-container {
      & .${classPrefix}-global-search-query {
        width: 65vw !important;
      }

      & .${classPrefix}-global-search-box {
        .ant-select-selection--single {
          .ant-select-selection__rendered {
            margin-right: 36px;
          }

          .ant-select-arrow {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-tag-blue {
  color: color: ${({ theme }) => theme.blue_6} !important; 
  background: #f0f7ff !important;
  border-color: #c4deff !important;
}

.ant-row {
  > .ant-col {
    text-align: left;

    label::after {
      display: none;
    }
  }
}

.ant-form {
  > .ant-row {
    > .ant-col {
      padding: 0;
      text-align: left;
    }
  }
}

.ant-form-vertical .ant-form-item-label {
  padding:0 0 4px;
}
.ant-form-vertical .ant-form-item-label > label {
  height: 32px;
}

.ant-form-item-label {
  line-height: 1.5 !important;
}

@media (max-width: 575px) {
  .ant-col-sm-12 {
      width: 50% !important;
  }
}

.ant-form-item {
  margin-bottom: 16px;
  padding-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ant-form-not-last-item {
  margin-bottom: 16px;
}

.dynamic-repeater-form {
  .ant-form-item {
    margin-bottom: 16px;
    padding-bottom: 8px;
  }

  .ant-checkbox-wrapper {
    line-height: 32px !important;
    white-space: nowrap;
  }

  .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon,
  .ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon,
  .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon,
  .ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
    margin-top: -12px !important;
  }

  //// will come to this later
  // .ant-row:nth-last-child(2) {
  //   .ant-form-item {
  //     margin-bottom: 0;
  //   }
  // }
}

.flag.flag-24 {
  margin-top: -2px;
}

.ant-input-group-addon {
  background-color: transparent !important;
  text-align: left;
}

.ant-select-open .ant-select-selection {
  box-shadow: 0 0 0 2px rgba(111, 163, 252, 0.2) !important;
}

.ant-breadcrumb-separator {
  padding: 0 5px;
}

.ant-radio-group-small .ant-radio-button-wrapper {
  padding: 0 15px;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0 !important;
}

.ant-modal-footer {
  border-radius: 0 0 8px 8px !important;
}

.ant-card-head {
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(0,0,0,.85);
    font-weight: ${({ theme }) => theme.font_weight_semi_bold};
    font-size: 16px;
    background: 0 0;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;

    .ant-card-head-wrapper {
      display: flex;
      align-items: center;

      .ant-card-head-title {
        display: inline-block;
        flex: 1;
        padding: 16px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .ant-card-extra {
        float: right;
        margin-left: auto;
        padding: 16px 0;
        color: rgba(0,0,0,.65);
        font-weight: ${({ theme }) => theme.font_weight_semi_bold};
        font-size: 14px;
      }
    }
}

.ant-anchor-ink-ball.visible {
  z-index: 1;
  background-color: transparent;
}

.ant-btn-lg {
  line-height: 1 !important;
}

.ant-btn {
  > .anticon {
    line-height: 0;
  }

  > .ant-btn-loading-icon {
    .anticon {
      margin-right: 8px;
      padding-right: 0 !important;
    }
  }
}


.ant-btn {
  &.confirm_button,
  &.cancel_button {
    &:hover,
    &:focus,
    &:active {
      border-color: ${({ theme }) => theme.button_hover_color};
    }
  }

  &.confirm_button {
    color: ${({ theme }) => theme.white_color};
    background-color: ${({ theme }) => theme.primaryColor};
    border: 1px solid ${({ theme }) => theme.primaryColor};
    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.button_hover_color};
      color: ${({ theme }) => theme.white_color};
    }
  }

  &.cancel_button {
    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
}

// Fixes the style for delete icons on Upload File List underneath Antd Dragger
.ant-upload-list-item-info {
  .ant-btn-icon-only.ant-btn-sm {
      background: transparent;
      &:hover {
        background: transparent;
      }
    }
}

.${classPrefix}-border-right {
  border-right: 1px solid ${({ theme }) => theme.grey_4};
}

.${classPrefix}-left-partner-has-feedback {
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    padding-right: 30px !important;
  }

  .ant-select-arrow {
    margin-right: 15px;
  }
}

.${classPrefix}-mt-0,
.${classPrefix}-my-0 {
  margin-top: 0 !important;
}

.${classPrefix}-mt-1,
.${classPrefix}-my-1 {
  margin-top: 0.25rem !important;
}

.${classPrefix}-mt-2,
.${classPrefix}-my-2 {
  margin-top: 0.5rem !important;
}

.${classPrefix}-mt-3,
.${classPrefix}-my-3 {
  margin-top: 1rem !important;
}

.${classPrefix}-mt-4,
.${classPrefix}-my-4 {
  margin-top: 1.5rem !important;
}

.${classPrefix}-mt-5,
.${classPrefix}-my-5 {
  margin-top: 3rem !important;
}

.${classPrefix}-m-0 {
  margin: 0 !important;
}

.${classPrefix}-mr-1,
.${classPrefix}-mx-1 {
  margin-right: 0.25rem !important;
}

.${classPrefix}-mr-2,
.${classPrefix}-mx-2 {
  margin-right: 0.5rem !important;
}

.${classPrefix}-mr-3,
.${classPrefix}-mx-3 {
  margin-right: 1rem !important;
}

.${classPrefix}-mb-0,
.${classPrefix}-my-0 {
  margin-bottom: 0 !important;
}

.${classPrefix}-mb-1,
.${classPrefix}-my-1 {
  margin-bottom: 0.25rem !important;
}

.${classPrefix}-mb-2,
.${classPrefix}-my-2 {
  margin-bottom: 0.5rem !important;
}

.${classPrefix}-mb-3,
.${classPrefix}-my-3 {
  margin-bottom: 1rem !important;
}

.${classPrefix}-mb-4,
.${classPrefix}-my-4 {
  margin-bottom: 1.5rem !important;
}

.${classPrefix}-mb-5,
.${classPrefix}-my-5 {
  margin-bottom: 3rem !important;
}

.${classPrefix}-mr-0,
.${classPrefix}-mx-0 {
  margin-right: 0 !important;
}

.${classPrefix}-ml-0,
.${classPrefix}-mx-0 {
  margin-left: 0 !important;
}

.${classPrefix}-ml-1,
.${classPrefix}-mx-1 {
  margin-left: 0.25rem !important;
}

.${classPrefix}-ml-2,
.${classPrefix}-mx-2 {
  margin-left: 0.5rem !important;
}

.${classPrefix}-ml-3,
.${classPrefix}-mx-3 {
  margin-left: 1rem !important;
}

.${classPrefix}-m-16px {
  margin: 16px !important;
}

.${classPrefix}-pt-0,
.${classPrefix}-py-0 {
  padding-top: 0 !important;
}

.${classPrefix}-pt-1,
.${classPrefix}-py-1 {
  padding-top: 0.25rem !important;
}

.${classPrefix}-pt-2,
.${classPrefix}-py-2 {
  padding-top: 0.5rem !important;
}

.${classPrefix}-pt-3,
.${classPrefix}-py-3 {
  padding-top: 1rem !important;
}

.${classPrefix}-pt-4,
.${classPrefix}-py-4 {
  padding-top: 1.5rem !important;
}

.${classPrefix}-pt-5,
.${classPrefix}-py-5 {
  padding-top: 3.0rem !important;
}

.${classPrefix}-pr-0,
.${classPrefix}-px-0 {
  padding-right: 0 !important;
}

.${classPrefix}-pr-1,
.${classPrefix}-px-1 {
  padding-right: 0.25rem !important;
}

.${classPrefix}-pr-2,
.${classPrefix}-px-2 {
  padding-right: 0.5rem !important;
}

.${classPrefix}-pr-3,
.${classPrefix}-px-3 {
  padding-right: 1rem !important;
}

.${classPrefix}-pr-4,
.${classPrefix}-px-4 {
  padding-right: 1.5rem !important;
}

.${classPrefix}-pr-5,
.${classPrefix}-pr-5 {
  padding-right: 3rem !important;
}

.${classPrefix}-pb-0,
.${classPrefix}-py-0 {
  padding-bottom: 0 !important;
}

.${classPrefix}-pb-1,
.${classPrefix}-py-1 {
  padding-bottom: 0.25em !important;
}

.${classPrefix}-pb-2,
.${classPrefix}-py-2 {
  padding-bottom: 0.5rem !important;
}

.${classPrefix}-pb-3,
.${classPrefix}-py-3 {
  padding-bottom: 1rem !important;
}

.${classPrefix}-pb-4,
.${classPrefix}-py-4 {
  padding-bottom: 1.5rem !important;
}

.${classPrefix}-pb-5,
.${classPrefix}-py-5 {
  padding-bottom: 3rem !important;
}

.${classPrefix}-pl-0,
.${classPrefix}-px-0 {
  padding-left: 0 !important;
}

.${classPrefix}-pl-1,
.${classPrefix}-px-1 {
  padding-left: 0.25rem !important;
}

.${classPrefix}-pl-2,
.${classPrefix}-px-2 {
  padding-left: 0.5rem !important;
}

.${classPrefix}-pl-3,
.${classPrefix}-px-3 {
  padding-left: 1rem !important;
}

.${classPrefix}-pl-4,
.${classPrefix}-px-4 {
  padding-left: 1.5rem !important;
}

.${classPrefix}-pl-5,
.${classPrefix}-px-5 {
  padding-left: 3rem !important;
}

@media screen and (max-width: ${({ theme }) => theme.screen_tab}) {
  .${classPrefix}-pt-md-4,
  .${classPrefix}-py-md-4 {
    padding-top: 1.5rem !important;
  }
}

.ant-empty-description {
  color: ${({ theme }) => theme.grey_7};
}

// ant-card-body styles TODO: remove this and fix it later
.ant-card-body {
  padding: 24px;
}


/*---------------k2 crm custom cards----------*/

.task-group-panel .ant-collapse-content {
  overflow: visible;
}

.task-group-panel .ant-collapse-content-active .ant-collapse-content-box,
.task-group-panel .ant-collapse-content-active .ant-collapse-content-box .list {
  padding: 0 24px 0 24px;
  margin: 0 -32px -13px -24px;
}

.task-group-panel .ant-collapse-content-active .ant-collapse-content-box .list{
  background-color: #f7f7fa;
  box-shadow: inset 0px 11px 8px -10px #f0f0f5, inset 0px -11px 8px -10px #f0f0f5;
}

.${classPrefix}-hover-box-shadow {
  &:hover {
    box-shadow: ${({ theme }) => theme.card_shadow} !important;
  }
}

.${classPrefix}-card-getting-started {
  background: #fff;
  border-radius: 8px;
  margin: 24px 0px 24px 0px;
  height: 268px;
}

.ant-card-actions {
  background: unset !important;
}

.ant-checkbox-inner {
  border-radius: 2px !important;
}

.${classPrefix}-radio-like-checkbox {
  .ant-checkbox-inner {
    border-radius: 8px !important;
  }

  .ant-checkbox-checked {
    &:after {
      border-radius: 10px;
      top: -2px;
      left: -2px;
      width: 20px;
      height: 20px;
    }

    .ant-checkbox-inner {
      &:after {
        border: 2.5px solid white;
        border-radius: 25px;
        transform: none;
        top: 1px;
        left: 1px;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.${classPrefix}-disabled-row {
  background:  ${({ theme }) => theme.grey_3};
  opacity: 0.3;
}

.${classPrefix}-display-none {
  display: none !important;
}

.ant-btn-link,
.ant-btn-text {
  border-color: transparent !important;
}

.ant-btn-text {
  color: ${({ theme }) => theme.grey_7} !important;
}

.ant-select-selection__rendered,
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: ${({ theme }) => theme.dataEntryInnerHeight} !important;

  .anticon {
    vertical-align: middle;
  }
}

.ant-select-selection-search-input {
  min-height: ${({ theme }) => theme.dataEntryInnerHeight} !important;
}

input.ant-input,
.ant-picker,
.ant-select-selection--single,
.ant-select-selector,
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  min-height: ${({ theme }) => theme.dataEntryHeight} !important;
}

.ant-input-number:not(.ant-input-number-sm) {
  min-height: ${({ theme }) => theme.dataEntryHeight} !important;
  input.ant-input-number-input {
    min-height: ${({ theme }) => theme.dataEntryInnerHeight} !important;
  }
}

.ant-select-multiple .ant-select-selector {
  .ant-select-selection-overflow {
    min-height: 32px !important;
    align-items: center;
    .ant-select-selection-overflow-item-suffix {
      height: 30px !important;
      input {
        position: relative !important;
        top: -5px !important;
      }
    }
  }
}

input.ant-input-sm,
.ant-select-sm,
.ant-picker-small,
.ant-select-sm .ant-select-selector,
.ant-select-sm .ant-select-selector .ant-select-selection-placeholder,
.ant-select-sm .ant-select-selector .ant-select-selection-search-input,
.ant-select-sm .ant-select-selector .ant-select-selection-item,
.ant-select-sm .ant-select-selector .ant-select-selection-item .ant-select-selection-placeholder {
  min-height: unset !important;
  line-height: unset !important;
}

.ant-statistic-content-value {
  letter-spacing: -1px;
  font-family: ${({ theme }) => theme.fontFamily} !important;
  -moz-font-feature-settings:"ss04" 1;
  -ms-font-feature-settings:"ss04" 1;
  -o-font-feature-settings:"ss04" 1;
  -webkit-font-feature-settings:"ss04" 1;
  font-feature-settings:"ss04" 1;
  line-height: ${({ theme }) => theme.line_height_xxl};
}

.ant-form-explain {
  margin-top: 2px;
  margin-bottom: -5px;
}

.ant-form-item-label {
  label {
    height: 32px;
  }
}

.ant-picker,
.ant-btn,
.ant-input,
.ant-picker,
.ant-tag,
.ant-select-selection,
.ant-select-selector,
.ant-input-group-addon,
.ant-modal-content,
.ant-dropdown-menu,
.ant-notification-hook-holder,
.ant-notification-notice,
.ant-input-affix-wrapper {
  border-radius: ${({ theme }) => theme.border_radius_sm} !important;
}

.ant-tag {
  border: 1px solid transparent !important;
}

.ant-input-affix-wrapper {
  padding: 0 11px;

   > input.ant-input {
      min-height: ${({ theme }) => theme.dataEntryInnerHeight} !important;
   }
}

.ant-input-affix-wrapper-sm {
  padding: 0 11px;

   > input.ant-input {
      min-height: unset !important;
      line-height: unset !important;
   }
}

.ant-input-group {
  .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .ant-select-selector {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    .ant-select-selector {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0 !important;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 8px 0 0 8px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 8px 8px 0 !important;
}

.ant-radio-button-wrapper:only-child {
  border-radius: 8px !important;
}

// Antd V4 Form Error Colors
.ant-form-item-explain {
  color: ${({ theme }) => theme.error_color} !important;
  line-height: 20px;
}
.ant-cascader-menu-item{
  width:220px;
}


.${classPrefix}-hide-year button.ant-picker-header-super-prev-btn,
.${classPrefix}-hide-year button.ant-picker-header-super-next-btn,
.${classPrefix}-hide-year button.ant-picker-year-btn
{
  display: none !important;
}

.${classPrefix}-left-divider::before{
  width:0% !important
}
.${classPrefix}-left-divider span.ant-divider-inner-text{
  padding : 0 !important;
  padding-right:1em !important;
}

h3.${classPrefix}-mb-3 .${classPrefix}-left-divider{
  font-weight:${({ theme }) => theme.font_weight_semi_bold};
}

.${classPrefix}-gray-btn {
  color:${({ theme }) => theme.grey_9} !important;
  border: 1px solid ${({ theme }) => theme.grey_5} !important;
  &:hover,
  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.button_hover_color} !important;
    color: ${({ theme }) => theme.button_hover_color} !important;
  }
  &:disabled {
    border-color: ${({ theme }) => theme.grey_5} !important;
    background-color: ${({ theme }) => theme.grey_3} !important;
    color: #00000040 !important;
  }
}

.${classPrefix}-apexcharts-label{
  font-size: 8px;
  color: ${({ theme }) => theme.grey_6};
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};
  line-height: ${({ theme }) => theme.line_height_xs};
  opacity: 0.7;
  letter-spacing: 0.01em;
}

.${classPrefix}-avatar-border {
  border:2px solid #fff !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.${classPrefix}-avatar-text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.${classPrefix}-danger {
    color: ${({ theme }) => theme.danger_color} !important;
}  
// -------------------------------------

`;

export default GlobalStyle;
