import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const PaymentCheque: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4721 1.60387C16.2775 0.798709 17.5831 0.798709 18.3885 1.60387L19.3611 2.57653C19.9959 3.21148 20.1303 4.15735 19.7642 4.92388L20.3337 5.49337C21.1389 6.29877 21.139 7.60441 20.3338 8.40982L17.2986 11.445C17.0302 11.7135 16.5949 11.7135 16.3264 11.445C16.0579 11.1765 16.0579 10.7412 16.3264 10.4727L19.3614 7.43769C19.6297 7.16924 19.6297 6.73409 19.3614 6.46564L18.8751 5.97926L10.1248 14.7303C10.0367 14.8184 9.92623 14.881 9.8053 14.9112L5.91588 15.8828C5.6816 15.9414 5.43378 15.8727 5.26305 15.7019C5.09231 15.5311 5.0237 15.2833 5.0823 15.049L6.05488 11.1605C6.08511 11.0397 6.14761 10.9293 6.2357 10.8412L12.0402 5.03671C12.0495 5.02613 12.0593 5.01576 12.0694 5.00563L15.4721 1.60387ZM12.5555 6.46594L7.34259 11.6789L6.69422 14.2711L9.28723 13.6233L14.5001 8.4105L12.5555 6.46594ZM13.5269 5.49282L16.4442 2.57629C16.7127 2.30793 17.1478 2.3079 17.4163 2.57622L18.4167 3.75V4.20834L15.2083 7.43769M1 11.3127C1 10.1736 1.92341 9.25017 3.0625 9.25017H4.4375C4.8172 9.25017 5.125 9.55797 5.125 9.93767C5.125 10.3174 4.8172 10.6252 4.4375 10.6252H3.0625C2.6828 10.6252 2.375 10.933 2.375 11.3127V20.9377C2.375 21.3174 2.6828 21.6252 3.0625 21.6252H20.9375C21.3172 21.6252 21.625 21.3174 21.625 20.9377V11.3127C21.625 10.933 21.3172 10.6252 20.9375 10.6252C20.5578 10.6252 20.25 10.3174 20.25 9.93767C20.25 9.55797 20.5578 9.25017 20.9375 9.25017C22.0766 9.25017 23 10.1736 23 11.3127V20.9377C23 22.0768 22.0766 23.0002 20.9375 23.0002H3.0625C1.92341 23.0002 1 22.0768 1 20.9377V11.3127ZM12.6875 15.4377C12.6875 15.058 12.9953 14.7502 13.375 14.7502H19.5625C19.9422 14.7502 20.25 15.058 20.25 15.4377C20.25 15.8174 19.9422 16.1252 19.5625 16.1252H13.375C12.9953 16.1252 12.6875 15.8174 12.6875 15.4377ZM9.9375 18.8752C9.9375 18.4955 10.2453 18.1877 10.625 18.1877H19.5625C19.9422 18.1877 20.25 18.4955 20.25 18.8752C20.25 19.2549 19.9422 19.5627 19.5625 19.5627H10.625C10.2453 19.5627 9.9375 19.2549 9.9375 18.8752Z"
      fill={fill}
    />
  </svg>
);
