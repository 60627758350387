import React, { FC, useCallback, useEffect, useState } from "react";

import { DeleteOutlined } from "@ant-design/icons";
import {
  ReportingPeriodReq,
  ReportingPeriodRes,
} from "@n3oltd/karakoram.analytics.sdk.attribution/esm";
import { Alert, DatePicker, Form, Input, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import moment from "moment";

import DateFormatProvider from "appRedux/models/localization/DateFormatProvider";
import { GeneralFormItemV4 } from "components/formItems";
import { K2Button, K2Message } from "components/k2Widgets";
import K2Drawer from "components/k2Widgets/k2Drawer";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import K2Modal from "components/k2Widgets/k2Modal";
import K2Spin from "components/k2Widgets/k2Spin";
import { showConfirm } from "components/utils/Confirmation";
import DirtyDataGuard from "components/utils/DirtyDataGuard";

import { useGetReportingPeriodById } from "../hooks/useGetReportingPeriodById";

interface AddEditReportingPeriodModalProps extends InjectedK2IntlProps {
  form: FormInstance;
  errors?: string[];
  reportingPeriodId: string;
  saving: boolean;
  isPrivateReproting: boolean;
  onCancel: () => void;
  onSave: (data: ReportingPeriodReq, revisionId: string) => void;
  onDelete: (revisionId: string, id: string) => void;
}

const AddEditReportingPreiodModalOrDrawer = ({
  showModal,
  children,
  editMode,
  dirty,
  onCancel,
  onOk,
  saving,
  onDelete,
  k2Intl,
  loading,
}) => {
  const deleteWithConfirm = () => {
    showConfirm({
      titleKey: "common.delete",
      contentKey: "admin.reportingPeriods.delete.confirm",
      onOk: () => {
        onDelete?.();
      },
      okTextKey: "common.delete",
      okButtonProps: {
        danger: true,
      },
      k2Intl,
    });
  };
  if (showModal) {
    return (
      <K2Modal
        visible={true}
        titleKey={
          editMode
            ? "admin.reportingPeriods.edit"
            : "admin.reportingPeriods.add"
        }
        requiresCloseConfirmation={dirty}
        onCancel={onCancel}
        onOk={onOk}
        okTextKey="common.save"
        okButtonProps={{
          loading: saving,
          disabled: !dirty,
        }}
        showDelete={editMode}
        deleteConfirmationTitleKey={"common.delete"}
        deleteConfirmationContentKey={"admin.reportingPeriods.delete.confirm"}
        onDelete={onDelete}
        loading={loading}
      >
        {children}
      </K2Modal>
    );
  }
  return (
    <K2Drawer
      visible
      titleKey={
        editMode ? "admin.reportingPeriods.edit" : "admin.reportingPeriods.add"
      }
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>
            <K2Message
              localeKey={
                editMode
                  ? "admin.reportingPeriods.edit"
                  : "admin.reportingPeriods.add"
              }
            />
          </span>
          <Space>
            {editMode && (
              <DeleteOutlined
                onClick={deleteWithConfirm}
                className="n3o-danger"
              />
            )}
            <K2Button
              localeKey={"common.close"}
              type="default"
              style={{ marginRight: 8 }}
              loading={saving}
              onClick={onCancel}
            />
            <K2Button
              disabled={!dirty}
              localeKey={"common.save"}
              type="primary"
              htmlType="submit"
              loading={saving}
              onClick={onOk}
            />
          </Space>
        </div>
      }
      closable={false}
      width={"600px"}
      onClose={onCancel}
      destroyOnClose
    >
      <K2Spin spinning={loading}>
        <div
          style={{
            padding: "20px",
            paddingLeft: "0px",
          }}
        >
          {children}
        </div>
      </K2Spin>
    </K2Drawer>
  );
};

const layout = {
  labelCol: {
    xs: 24,
    sm: 6,
  },
  wrapperCol: {
    xs: 24,
    sm: 10,
  },
};

const AddEditReportingPeriodModal: FC<AddEditReportingPeriodModalProps> = ({
  saving,
  k2Intl,
  form,
  localizationSettings,
  errors,
  isPrivateReproting,
  reportingPeriodId,
  onDelete,
  onSave,
  onCancel,
}) => {
  const [dirty, setDirty] = useState<boolean>(false);

  const editMode = !!reportingPeriodId;
  const { reportingPeriod, loadingReportingPeriod } = useGetReportingPeriodById(
    reportingPeriodId,
    k2Intl,
  );

  const loading = editMode ? loadingReportingPeriod : false;

  const dateConfig = DateFormatProvider.getConfig(
    localizationSettings?.dateFormat || DateFormatProvider.defaultDateFormat,
  );

  const beforeStartDate = useCallback(
    (current) => {
      // Date disabled if before start date
      const start = form?.getFieldValue(
        "startsOn",
      );
      if (start) {
        return current < moment(start);
      } else {
        return false;
      }
    },
    [form],
  );

  const afterEndDate = useCallback(
    (current) => {
      // Date disabled if after end date
      const end = form?.getFieldValue(
        "startsOn",
      );
      if (end) {
        return current > moment(end);
      } else {
        return false;
      }
    },
    [form],
  );

  const getInitialValues = (reportingPeriod: ReportingPeriodRes) => {
    return {
      ["name"]: reportingPeriod.name,
      ["startsOn"]: moment(
        reportingPeriod.startsOn,
      ),
      ["endsOn"]: moment(
        reportingPeriod.endsOn,
      ),
    };
  };

  useEffect(() => {
    if (reportingPeriod) {
      form?.setFieldsValue(getInitialValues(reportingPeriod));
    } else {
      form?.resetFields();
    }
  }, [reportingPeriod, form]);

  return (
    <DirtyDataGuard dirty={dirty}>
      <AddEditReportingPreiodModalOrDrawer
        editMode={editMode}
        onCancel={() => {
          onCancel();
          form?.resetFields();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onSave(values, reportingPeriod?.revisionId);
            })
            .catch((err) => {
              form.scrollToField(err.errorFields?.[0]?.name);
            });
        }}
        onDelete={() =>
          onDelete(reportingPeriod.revisionId, reportingPeriod.id)
        }
        showModal={!isPrivateReproting}
        dirty={dirty}
        saving={saving}
        k2Intl={k2Intl}
        loading={loading}
      >
        <div style={{ minHeight: 300 }}>
          <Form form={form} onValuesChange={() => setDirty(true)}>
            <GeneralFormItemV4
              labelKey={"common.name"}
              name={"name"}
              required
              labelCol={layout.labelCol}
              wrapperCol={{
                xs: 24,
                sm: 18,
              }}
            >
              <Input />
            </GeneralFormItemV4>

            <GeneralFormItemV4
              labelKey={"common.startDate"}
              name={"startsOn"}
              required
              {...layout}
            >
              <DatePicker
                format={dateConfig.format}
                disabledDate={afterEndDate}
              />
            </GeneralFormItemV4>

            <GeneralFormItemV4
              labelKey={"common.endDate"}
              name={"endsOn"}
              required
              {...layout}
            >
              <DatePicker
                format={dateConfig.format}
                disabledDate={beforeStartDate}
              />
            </GeneralFormItemV4>

            {errors.length > 0 && (
              <Alert
                style={{ marginTop: 20, marginBottom: 20 }}
                type="error"
                message={errors?.map?.((e, i) => (
                  <p key={i}>{e}</p>
                ))}
              />
            )}
          </Form>
        </div>
      </AddEditReportingPreiodModalOrDrawer>
    </DirtyDataGuard>
  );
};

export default injectK2Intl(AddEditReportingPeriodModal);
