import React, { Component } from "react";

import { SorterResult, TableRowSelection } from "antd/es/table/interface";
import _ from "lodash";

import K2Grid from ".";
import GridActionsHeader from "./GridActionsHeader";
import PaginationFooter from "./PaginationFooter";
import "./k2grid.less";
import { K2ActionGridProps, K2GridSelectedState } from "./types";

interface K2ActionGridState<T> {
  selectedState: K2GridSelectedState<T>;
}

class K2ActionGrid<T> extends Component<
  K2ActionGridProps<T>,
  K2ActionGridState<T>
> {
  private headerGenerator: (
    _currentPageData: readonly Object[],
  ) => React.ReactNode;
  private sortSettings: SorterResult<T>;
  private tableLocale: Object = null; //ToDo: include this

  constructor(props: K2ActionGridProps<T>) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const { headerOptions } = this.props;
    if (headerOptions) {
      this.headerGenerator = this.renderGridActionsHeader;
    }

    return {
      selectedState: {
        keys: [],
        rows: [],
        allPagesSelected: false,
      },
    };
  }

  /**@method renderGridActionsHeader Generates JSX containing the actions/info that is to be displayed above the grid
   * @returns JSX content for actions header
   */
  renderGridActionsHeader = (): React.ReactNode => {
    const {
      headerOptions,
      columns,
      keyField,
      k2Intl,
      modalRowClassName,
    } = this.props;
    if (!headerOptions) return null;

    const { selectedState } = this.state;

    return (
      <GridActionsHeader<T>
        k2Intl={k2Intl}
        keyField={keyField}
        columns={columns}
        selectedState={selectedState}
        rowClassName={modalRowClassName}
        {...headerOptions}
      />
    );
  };

  onSelectAll = (selected: boolean, selectedRows: T[]) => {
    this.setSelectedState(
      selectedRows?.map?.((elem) => elem[this.props.keyField as string]),
      selectedRows,
      selected,
    );
  };

  /** @event onSelectChange Triggered whenever user changes the table selections
   * @param selectedRowKeys Currently selected keys
   * @param all selected rows
   */
  onSelectChange = (selectedRowKeys: string[], selectedRows: T[]) => {
    const { keyField, onSelectChange } = this.props;
    onSelectChange?.(selectedRowKeys, selectedRows);

    this.setSelectedState(
      selectedRowKeys,
      _.unionBy(
        this.state.selectedState.rows.filter(
          (x) => selectedRowKeys.indexOf(x[keyField as string]) >= 0,
        ),
        selectedRows,
        keyField as string,
      ),
      false,
    );
  };

  /** @method setSelectedState Sets component state for selection
   * @param keys Selected keys
   * @param rows Selected rows
   * @param allPagesSelected All pages selected
   */
  setSelectedState = (keys: string[], rows: T[], allPagesSelected: boolean) => {
    this.setState({
      selectedState: {
        keys,
        rows,
        allPagesSelected,
      },
    });
  };

  /** @method getSelectionConfig Generates rowselection configuration for the table
   * @returns TableRowSelection configuration
   */
  getSelectionConfig = (): TableRowSelection<T> => {
    const { headerOptions, keyField } = this.props;
    if (!headerOptions || !headerOptions.actionsOptions) return null;

    let { keys: currentlySelected } = this.state.selectedState;
    const dataSource = this.props.dataSource;

    const selectionConfig = {
      selectedRowKeys: currentlySelected,
      onChange: (keys: string[] | number[], rows: Object[]) =>
        this.onSelectChange(keys as string[], rows as T[]),
      onSelectAll: (selected: boolean) => {
        if (selected) {
          this.onSelectAll(selected, [...dataSource]);
        } else {
          this.onSelectAll(selected, []);
        }
      },
      hideDefaultSelections: true,
      selections: false,
    };

    return selectionConfig;
  };

  render() {
    const {
      keyField,
      columns,
      scroll,
      loading,
      onLoadMore,
      onChange,
      dataSource,
      shouldShowLoadMore,
      className,
      components,
      ...remainingTableProps
    } = this.props;
    return (
      <K2Grid<T>
        components={components}
        rowKey={keyField}
        className={className}
        title={this.headerGenerator}
        dataSource={dataSource?.length > 0 ? dataSource : null}
        columns={columns}
        onChange={onChange}
        scroll={scroll}
        loading={loading}
        rowSelection={this.getSelectionConfig()}
        footer={
          shouldShowLoadMore
            ? () => <PaginationFooter onLoadMore={onLoadMore} />
            : null
        }
        {...remainingTableProps}
      />
    );
  }
}

export default K2ActionGrid;
