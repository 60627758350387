import { FileResponse } from "@n3oltd/k2.users.sdk.users";
import cd from "content-disposition";

import { IApiResponse } from "appRedux/models/common/ApiResponseModel";

export default class DownloadHelpers {
  public static async downloadFile(
    response: IApiResponse<FileResponse>,
    fallbackFilename: string,
    afterDownload?: (data?: any) => void,
  ) {
    const result = response.getResultOrDefault();

    const content: Blob = result.data;
    if (!content) return;

    const blob = await content;
    if (!blob) return;

    // Attempt to get filename from Headers
    const contentDisposition = result.headers?.["content-disposition"];

    if (contentDisposition) {
      try {
        const parsed = cd.parse(contentDisposition);
        fallbackFilename = parsed?.parameters?.filename || fallbackFilename;
      } catch (e) {
        // Error parsing content disposition headers, never mind
      }
    }

    DownloadHelpers.openBlobUrl(blob, fallbackFilename, afterDownload);
  }

  public static openBlobUrl(
    blob: Blob,
    fileName: string,
    afterDownload?: (data?: any) => void,
  ): void {
    // For IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    // Other browsers
    const link = document.createElement("a");

    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);

    link.setAttribute("download", fileName);

    document.body.appendChild(link);

    link.click();

    link.remove();

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(url);
    }, 100);

    afterDownload?.();
  }
}
