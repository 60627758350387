import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const StatsDown: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5929 16.6758C21.7515 16.5214 21.85 16.3056 21.85 16.0668V10.9668C21.85 10.4974 21.4694 10.1168 21 10.1168C20.5305 10.1168 20.15 10.4974 20.15 10.9668V14.1658L14.8628 9.43519C14.0457 8.7036 12.7555 8.70327 11.938 9.43432L9.29576 11.7984C9.12389 11.9523 8.80984 11.9526 8.63777 11.7987L2.56669 6.3667C2.21684 6.05368 1.67948 6.08353 1.36646 6.43338C1.05343 6.78323 1.08329 7.32059 1.43313 7.63361L7.50462 13.066C8.32219 13.797 9.61237 13.7967 10.4295 13.0651L13.0707 10.7019C13.2428 10.5481 13.557 10.5479 13.7289 10.7017L18.7752 15.2168H15.3C14.8305 15.2168 14.45 15.5974 14.45 16.0668C14.45 16.5363 14.8305 16.9168 15.3 16.9168H21C21.2306 16.9168 21.4398 16.825 21.5929 16.6758Z"
      fill={fill}
    />
  </svg>
);
