import React, { ReactElement } from "react";

import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { K2Routes } from "appRedux/models/routes/RouteModel";
import K2Breadcrumb from "components/breadcrumb";
import { asyncComponent } from "components/utils";
import { RequireAuth } from "containers/app/views";

const {
  main: { accounts },
  createAccount,
  createFeedback,
  createAnonymousFeedback,
  createSponsorship,
  createAnonymousSponsorship,
  viewAccountWithoutProfile,
  searchAccounts,
} = K2Routes;

const accountsDashboardWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "AccountsDashboard" */ "./dashboard"),
  ),
  "accounts",
);

const createAccountWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "AccountCreate" */ "./create"),
  ),
  "createAccount",
);

const viewAccountWithAuth = RequireAuth(
  asyncComponent(() => import(/* webpackChunkName: "AccountView" */ "./view")),
  "viewAccount",
);

const searchAccountsWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "AccountSearch" */ "./search"),
  ),
  "searchAccounts",
);

const CreateSponsorshipsWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "CreateSponsorship" */ "../sponsorships/createSponsorship"
      ),
  ),
  "sponsorships",
);

const CreateFeedbacksWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "CreateSponsorship" */ "../feedbacks/createFeedback"
      ),
  ),
  "viewAccountFeedback",
);

const AccountRoutes = ({ match }: RouteComponentProps) => (
  <>
    <K2Breadcrumb />
    <Switch>
      <Route exact path={accounts} component={accountsDashboardWithAuth} />
      <Route exact path={createAccount} component={createAccountWithAuth} />
      <Route exact path={searchAccounts} component={searchAccountsWithAuth} />
      <Route
        exact
        path={createAnonymousSponsorship}
        render={(props) => {
          const newProps = {
            ...props,
            anonymous: true,
          };

          return <CreateSponsorshipsWithAuth {...newProps} />;
        }}
      />
      <Route
        exact
        path={createSponsorship}
        render={(props) => {
          const newProps = {
            ...props,
            anonymous: false,
          };

          return <CreateSponsorshipsWithAuth {...newProps} />;
        }}
      />
      <Route
        exact
        path={createAnonymousFeedback}
        render={(props) => {
          const newProps = {
            ...props,
            anonymous: true,
          };

          return <CreateFeedbacksWithAuth {...newProps} />;
        }}
      />
      <Route
        exact
        path={createFeedback}
        render={(props) => {
          const newProps = {
            ...props,
            anonymous: false,
          };

          return <CreateFeedbacksWithAuth {...newProps} />;
        }}
      />
      <Route path={viewAccountWithoutProfile} component={viewAccountWithAuth} />
    </Switch>
  </>
);

export default AccountRoutes;
