import React, { FC } from "react";

import { TeamOutlined, UserOutlined } from "@ant-design/icons/lib";
import { SecurityPrincipalProfile } from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Avatar, Badge } from "antd";
import { connect } from "react-redux";

import K2Client from "appRedux/models/base/K2RestClients";
import IApplicationState from "appRedux/types";
import { API_VERSION } from "constants/appConstants";

interface IProps {
  subscriptionId: string;
  profile: SecurityPrincipalProfile;
  count?: number;
  presetId: string;
  size?: number | "small" | "large" | "default";
}

// This avatar can be used with any SecurityPrincipalProfile, displays an optional count badge, and will show a "Users" icon if the SecurityPrincipalProfile is a role
const SecurityPrincipalAvatar: FC<IProps> = ({
  profile,
  count,
  subscriptionId,
  presetId,
  size,
}) => {
  let pictureUrl;
  if (profile.type === "user" && profile.hasPicture) {
    const usersBaseUrl: string = K2Client.baseUrlFor("users");
    pictureUrl = `${usersBaseUrl}/${API_VERSION}/${subscriptionId}/${profile.principalId}/picture/${presetId}`;
  }

  return typeof count === "number" ? (
    <Badge count={count} showZero={false}>
      <Avatar
        size={size || "default"}
        icon={
          pictureUrl ? null : profile.type === "role" ? (
            <TeamOutlined />
          ) : (
            <UserOutlined />
          )
        }
        style={pictureUrl ? null : { backgroundColor: `${profile.color}` }}
        src={pictureUrl}
        alt={profile.name}
      >
        {pictureUrl ? "" : profile.type === "user" ? profile.initials : ""}
      </Avatar>
    </Badge>
  ) : (
    <Avatar
      size={size || "default"}
      icon={
        pictureUrl ? null : profile.type === "role" ? (
          <TeamOutlined />
        ) : (
          <UserOutlined />
        )
      }
      style={pictureUrl ? null : { backgroundColor: `${profile.color}` }}
      src={pictureUrl}
      alt={profile.name}
    >
      {pictureUrl ? "" : profile.type === "user" ? profile.initials : ""}
    </Avatar>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  subscriptionId: state.subscription?.users?.k2Subscription?.id,
});

export default connect(mapStateToProps)(SecurityPrincipalAvatar);
