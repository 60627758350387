import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import {
  DashboardTasksRes,
  SecurityPrincipalProfile,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { createSelector } from "reselect";

import { K2SecurityPrincipalsManagement } from "appRedux/modules/securityPrincipals/types";
import { FilterCriteria } from "appRedux/modules/tasks/dashboard/types";

import { ServerError } from "../../models/common/ApiResponseModel";
import securityPrincipalsActions from "../../modules/securityPrincipals/actions";
import { dashboardTasksActions } from "../../modules/tasks";
import IApplicationState from "../../types";
import SecurityPrincipalsSelectors from "./SecurityPrincipalsSelectors";
import UsersSelectors from "./UsersSelectors";

export default class TaskSelectors {
  public static selectTasksData = (state: IApplicationState) => {
    return state.tasks;
  };

  public static getTaskCountForUserSelector() {
    return createSelector(
      this.selectTasksData,
      (tasks) => tasks.dashboardTasks.taskCountForUser,
    );
  }

  public static getDashboardTaskListSelector() {
    return createSelector(
      this.selectTasksData,
      (tasks): IDashboardTasksListSelectorProps => ({
        tasksData: tasks.dashboardTasks.dashboardListData,
        tasksLoading: tasks.dashboardTasks.isLoading,
        serverError: tasks.dashboardTasks.serverError,
        filtersApplied: tasks.dashboardTasks.filtersApplied,
        search: tasks.dashboardTasks.search,
        saving: tasks.dashboardTasks.isMarkingComplete,
      }),
    );
  }

  public static getSelectTaskAssigneesSelector() {
    return createSelector(
      SecurityPrincipalsSelectors.selectSecurityPrincipals,
      UsersSelectors.getK2UserSelector(),
      (
        securityPrincipals: K2SecurityPrincipalsManagement,
        user: CurrentUserRes,
      ) => {
        return {
          securityPrincipals: securityPrincipals.items,
          principalsLoading: securityPrincipals.isLoading,
          principalsError: !!securityPrincipals.serverError,
          userHasRoles: user.roleProfiles && user.roleProfiles.length > 0,
          currentUser: user,
        };
      },
    );
  }

  public static getDashboardTaskListDispatchers(): IDashboardTaskListDispatcherProps {
    return {
      fetchDashboardTasks,
      clearDashboardTasks,
      clearServerError,
      updateDashboardFilters,
      updateSearch,
    };
  }

  public static getDashboardTaskActionDispatchers(): IDashboardTaskActionsDispatcherProps {
    return { markComplete };
  }

  public static getSelectAssigneesDispatchers(): ISelectAssigneeDispatcherProps {
    return { fetchSecurityPrincipals };
  }
}

const {
  fetchDashboardTasks,
  clearDashboardTasks,
  clearServerError,
  markComplete,
  updateDashboardFilters,
  updateSearch,
} = dashboardTasksActions;

// ------- Task List on Dashboard -----------
type IDashboardTasksListSelectorProps = {
  tasksData: DashboardTasksRes;
  tasksLoading: boolean;
  saving: boolean;
  serverError?: ServerError;
  filtersApplied: FilterCriteria;
  search: string;
};

type IDashboardTaskListDispatcherProps = {
  fetchDashboardTasks: typeof fetchDashboardTasks;
  clearDashboardTasks: typeof clearDashboardTasks;
  clearServerError: typeof clearServerError;
  updateDashboardFilters: typeof updateDashboardFilters;
  updateSearch: typeof updateSearch;
};

export type IDashboardTaskListStateProps = IDashboardTasksListSelectorProps &
  IDashboardTaskListDispatcherProps;

export type IDashboardTaskActionsDispatcherProps = {
  markComplete: typeof markComplete;
};

// ------- Select Assignee for Task -----------

const { fetchSecurityPrincipals } = securityPrincipalsActions;

type ISelectAssigneeSelectorProps = {
  securityPrincipals: SecurityPrincipalProfile[];
  principalsLoading: boolean;
  principalsError: boolean;
  userHasRoles: boolean;
  currentUser: CurrentUserRes;
};

type ISelectAssigneeDispatcherProps = {
  fetchSecurityPrincipals: typeof fetchSecurityPrincipals;
};

export type ISelectAssigneeStateProps = ISelectAssigneeSelectorProps &
  ISelectAssigneeDispatcherProps;
