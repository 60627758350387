import {
  ReleaseNotesRes,
  SubscriptionRes,
} from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import {
  CurrentUserRes,
  UserSubscriptionsRes,
} from "@n3oltd/k2.users.sdk.users/esm";
import {
  RouteIdResultsList,
  RoutesClient,
} from "@n3oltd/karakoram.navigation.sdk.routes/esm";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { usersActions } from "..";
import AuthManager from "../../../models/auth/AuthManager";
import K2RestClients, {
  _subscriptionsClient,
  _usersClient,
} from "../../../models/base/K2RestClients";
import K2Service from "../../../models/base/K2RestService";
import { IApiResponse } from "../../../models/common/ApiResponseModel";
import * as globalActions from "../../global/actions";

const fetchStaticData =
  (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
  // eslint-disable-next-line
  process.env.REACT_APP_FETCH_LIVE_DATA == "false";

//#region WORKERS

// SUBSCRIPTIONS LIST
function* getCurrentUserSubscriptions(
  action: ReturnType<typeof usersActions.getCurrentUserSubscriptions>,
) {
  if (fetchStaticData) {
    yield put(usersActions.setStaticK2UserSubscriptions());
    yield put(usersActions.generalLoading(false));
  } else {
    const response: IApiResponse<UserSubscriptionsRes> = yield K2Service.toResponse(
      _usersClient.getSubscriptions(),
    );
    yield put(usersActions.setCurrentUserSubscriptions(response));
  }
}

// CURRENT USER
function* getAppResources(
  action: ReturnType<typeof usersActions.getAppResources>,
) {
  const routesClient = K2RestClients.initRestClient<RoutesClient>(
    RoutesClient,
    "navigation",
    {
      getN3OServiceCache: () => (action.payload ? "disable" : null),
    },
  );

  if (fetchStaticData) {
    yield put(usersActions.setStaticK2Subscription());
    yield put(usersActions.setStaticK2User());
    yield put(usersActions.setStaticSubscriptionUserRoutes());
  } else {
    const [subscriptionRes, userRes, routesRes] = yield all([
      call(() => K2Service.toResponse(_subscriptionsClient.getCurrent())),
      call(() => K2Service.toResponse(_usersClient.getCurrentUser())),
      call(() => K2Service.toResponse(routesClient.list())),
    ]);

    const subscriptionResponse: IApiResponse<SubscriptionRes> = subscriptionRes;
    const userResponse: IApiResponse<CurrentUserRes> = userRes;
    const routesResponse: IApiResponse<RouteIdResultsList> = routesRes;

    if (subscriptionResponse && subscriptionResponse.result)
      AuthManager.setSubscriptionId(subscriptionResponse.result.id);

    if (userResponse && userResponse.result)
      AuthManager.setUserId(userResponse.result.id);

    yield put(usersActions.setCurrentK2Subscription(subscriptionResponse));
    yield put(usersActions.setCurrentUser(userResponse));
    yield put(usersActions.setSubscriptionUserRoutes(routesResponse));

    yield put(
      globalActions.localeChange(
        userResponse.getResultOrDefault().localization,
        subscriptionResponse.getResultOrDefault().localization,
      ),
    );
  }

  yield put(usersActions.setCurrentUserLocalizationSettings());
  yield put(usersActions.resourcesLoading(false));
}

// CURRENT SUBSCRIPTION
function* getCurrentSubscription(
  action: ReturnType<typeof usersActions.getCurrentSubscription>,
) {
  if (fetchStaticData) {
    yield put(usersActions.setStaticK2Subscription());
  } else {
    const response: IApiResponse<SubscriptionRes> = yield K2Service.toResponse(
      _subscriptionsClient.getCurrent(),
    );

    yield put(usersActions.setCurrentK2Subscription(response));
  }

  yield put(usersActions.setCurrentUserLocalizationSettings());
  yield put(usersActions.resourcesLoading(false));
}

// CURRENT SUBSCRIPTION ROUTES
function* getSubscriptionUserRoutes(
  action: ReturnType<typeof usersActions.getSubscriptionUserRoutes>,
) {
  if (fetchStaticData) {
    yield put(usersActions.setStaticSubscriptionUserRoutes());
  } else {
    const routesClient = K2RestClients.initRestClient<RoutesClient>(
      RoutesClient,
      "navigation",
      {
        getN3OServiceCache: () => (action.payload ? "disable" : null),
      },
    );
    const response: IApiResponse<RouteIdResultsList> = yield K2Service.toResponse(
      routesClient.list(),
    );

    yield put(usersActions.setSubscriptionUserRoutes(response));
  }

  yield put(usersActions.routesLoading(false));
}

// CURRENT SUBSCRIPTION RELEASE NOTES
function* getCurrentSubscriptionRelease(
  action: ReturnType<typeof usersActions.getCurrentSubscriptionRelease>,
) {
  const response: IApiResponse<ReleaseNotesRes> = yield K2Service.toResponse(
    _subscriptionsClient.getReleaseNotes(),
  );

  yield put(usersActions.setCurrentSubscriptionRelease(response));
}

const dummyResponse = {
  status: 200,
  statusText: "",
  data: "",
  headers: "",
  config: null,
};

//#endregion

//#region WATCHERS
export default function* usersSaga() {
  yield all([
    yield takeLatest(
      usersActions.getCurrentUserSubscriptions.type,
      getCurrentUserSubscriptions,
    ),
    yield takeLatest(usersActions.getAppResources.type, getAppResources),
    yield takeLatest(
      usersActions.getCurrentSubscription.type,
      getCurrentSubscription,
    ),
    yield takeLatest(
      usersActions.getCurrentSubscriptionRelease.type,
      getCurrentSubscriptionRelease,
    ),
    yield takeLatest(
      usersActions.getSubscriptionUserRoutes.type,
      getSubscriptionUserRoutes,
    ),
  ]);
}
//#endregion
