import { Anchor } from "antd";
import styled from "styled-components";

const K2Anchor = styled(Anchor)`
  && {
    .ant-anchor-link-active > .ant-anchor-link-title {
      color ${({ theme }) => theme.link_color};
    }
    .ant-anchor-ink-ball {
      border: 2px solid ${({ theme }) => theme.link_color};
    }
    a:hover {
      color ${({ theme }) => theme.link_hover_color};
    }
    a:active, a:visited {
    color ${({ theme }) => theme.link_color};
    }
  }
`;

export default K2Anchor;
