import React, { FC, useCallback, useEffect, useState } from "react";

import {
  AssetRes,
  AssetType,
} from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { AssetResultsPage } from "@n3oltd/karakoram.templates.sdk.assets/src/index";
import { Tooltip } from "antd";
import { useDebounce } from "hooks";

import { _assetsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Search, MultiSelector } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";
import { UIUtils } from "components/utils";
import { DEFAULT_DELAY, TEMPLATE_ENGINE } from "constants/appConstants";

interface SharedAssetsSelectListProps extends InjectedK2IntlProps {
  currentlySelectedAssets: AssetRes[];
  previouslySelectedAssets: AssetRes[];
  onValueChange: (assets: AssetRes[]) => void;
  assetType?: AssetType;
  maxAssets?: number;
}

interface MultiSelectAssetItem {
  key: string;
  title: string;
  notes: string;
}

const SharedAssetsSelectList: FC<SharedAssetsSelectListProps> = ({
  k2Intl,
  currentlySelectedAssets,
  previouslySelectedAssets,
  assetType,
  maxAssets,
  onValueChange,
}) => {
  const { theme } = useAppContext();

  const currentlySelectedAssetIds = currentlySelectedAssets?.map?.((a) => a.id);
  const previouslySelectedAssetIds = previouslySelectedAssets?.map?.(
    (a) => a.id,
  );

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [assets, setAssets] = useState<AssetRes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DELAY);

  const findAssets = useCallback(
    async (term: string) => {
      setLoading(true);

      const resp: IApiResponse<AssetResultsPage> = await K2RestService.toResponse(
        _assetsClient.find(TEMPLATE_ENGINE, null, {
          shared: true,
          fullText: term,
          type: assetType,
        }),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        setAssets(
          resp.getResultOrDefault().items.filter((asset) => {
            return !previouslySelectedAssetIds.includes(asset.id);
          }),
        );
      }
      setLoading(false);
    },
    [k2Intl, previouslySelectedAssetIds, assetType],
  );

  useEffect(() => {
    findAssets(searchTerm);
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const reconcileAssets = useCallback(
    (newlySelectedAssetIds: string[]): AssetRes[] => {
      const selectedAssets = [];

      newlySelectedAssetIds.forEach((assetId) => {
        // If it is in the original list
        const alreadySelected = currentlySelectedAssets.find(
          (a) => a.id === assetId,
        );
        if (alreadySelected) {
          selectedAssets.push(alreadySelected);
        } else {
          selectedAssets.push(assets.find((a) => a.id === assetId));
        }
      });

      return selectedAssets;
    },
    [currentlySelectedAssets, assets],
  );

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <K2Search
          loading={loading}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          placeholderKey={"admin.communication.mediaAssets.shared.search"}
        />
      </div>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <MultiSelector
          maxSelections={maxAssets}
          showSelectAll={false}
          initialValues={currentlySelectedAssetIds}
          dataSource={assets?.map?.(
            (a): MultiSelectAssetItem => ({
              key: a.id,
              title: a.file.name,
              notes: a.notes,
            }),
          )}
          onChange={(ids: string[]) => {
            onValueChange(reconcileAssets(ids));
          }}
          showSearch={false}
          renderSuffix={(item: MultiSelectAssetItem) => {
            if (item.notes) {
              return (
                <Tooltip title={item.notes}>
                  <N3oIcon
                    icontype={"n3o-task-note"}
                    style={{ position: "relative", top: 5 }}
                    width="16px"
                    height="16px"
                    fill={theme.icon_grey_light}
                  />
                </Tooltip>
              );
            } else {
              return null;
            }
          }}
        />
      </div>
    </div>
  );
};

export default injectK2Intl(SharedAssetsSelectList);
