import { AgentRes, SignInAgentReq } from "@n3oltd/k2.calls.sdk.agents/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

export enum ActionTypes {
  FETCH_AGENT = "@engage/calls/main/fetch-agent/request",
  FETCH_AGENT_SUCCESS = "@engage/calls/main/fetch-agent/success",
  FETCH_AGENT_ERROR = "@engage/calls/main/fetch-agent/error",

  AGENT_SIGN_IN = "@engage/calls/main/sign-in/request",
  AGENT_SIGN_IN_SUCCESS = "@engage/calls/main/sign-in/success",
  AGENT_SIGN_IN_ERROR = "@engage/calls/main/sign-in/error",
  AGENT_SIGN_IN_CLEAR_ERROR = "@engage/calls/main/sign-in/clear-error",
  AGENT_SIGN_IN_CLEAR_EDIT_STATUS = "@engage/calls/main/sign-in/clear-edit-status",

  AGENT_SIGN_OUT = "@engage/calls/main/sign-out/request",
  AGENT_SIGN_OUT_SUCCESS = "@engage/calls/main/sign-out/success",
  AGENT_SIGN_OUT_ERROR = "@engage/calls/main/sign-out/error",
  AGENT_SIGN_OUT_CLEAR_ERROR = "@engage/calls/main/sign-out/clear-error",
  AGENT_SIGN_OUT_CLEAR_EDIT_STATUS = "@engage/calls/main/sign-out/clear-edit-status",

  AGENT_UPDATE_TEAM_AND_CAMPAIGN = "@engage/calls/main/update-team-campaign/request",
  AGENT_UPDATE_TEAM_AND_CAMPAIGN_SUCCESS = "@engage/calls/main/update-team-campaign/success",
  AGENT_UPDATE_TEAM_AND_CAMPAIGN_ERROR = "@engage/calls/main/update-team-campaign/error",
  AGENT_UPDATE_CLEAR_ERROR = "@engage/calls/main/update-team-campaign/clear-error",

  AGENT_TAKE_BREAK = "@engage/calls/main/take-break/request",
  AGENT_TAKE_BREAK_SUCCESS = "@engage/calls/main/take-break/success",
  AGENT_TAKE_BREAK_ERROR = "@engage/calls/main/take-break/error",
  AGENT_TAKE_BREAK_CLEAR_ERROR = "@engage/calls/main/take-break/clear-error",
  AGENT_TAKE_BREAK_CLEAR_EDIT_STATUS = "@engage/calls/main/take-break/clear-edit-status",

  AGENT_RESUME_SHIFT = "@engage/calls/main/resume-shift/request",
  AGENT_RESUME_SHIFT_SUCCESS = "@engage/calls/main/resume-shift/success",
  AGENT_RESUME_SHIFT_ERROR = "@engage/calls/main/resume-shift/error",
  AGENT_RESUME_SHIFT_CLEAR_ERROR = "@engage/calls/main/resume-shift/clear-error",
  AGENT_RESUME_SHIFT_CLEAR_EDIT_STATUS = "@engage/calls/main/resume-shift/clear-edit-status",

  CLEAR_AGENT_ERROR = "@engage/calls/main/fetch-agent/clear-error",
  CLEAR_AGENT = "@engage/calls/main/fetch-agent/clear",
}

export interface FetchAgent {
  type: ActionTypes.FETCH_AGENT;
}

export interface FetchAgentSuccess {
  type: ActionTypes.FETCH_AGENT_SUCCESS;
  agent: AgentRes;
}

export interface FetchAgentError {
  type: ActionTypes.FETCH_AGENT_ERROR;
  error: ServerError;
}

export interface AgentSignIn {
  type: ActionTypes.AGENT_SIGN_IN;
  agentId: string;
  request: SignInAgentReq;
}

export interface AgentSignInSuccess {
  type: ActionTypes.AGENT_SIGN_IN_SUCCESS;
  editStatus: EditStatus<any>;
  agent: AgentRes;
}

export interface AgentSignInError {
  type: ActionTypes.AGENT_SIGN_IN_ERROR;
  error: ServerError;
}

export interface AgentSignInClearError {
  type: ActionTypes.AGENT_SIGN_IN_CLEAR_ERROR;
}

export interface AgentSignInClearEditStatus {
  type: ActionTypes.AGENT_SIGN_IN_CLEAR_EDIT_STATUS;
}

export interface AgentSignOut {
  type: ActionTypes.AGENT_SIGN_OUT;
  agentId: string;
}

export interface AgentSignOutSuccess {
  type: ActionTypes.AGENT_SIGN_OUT_SUCCESS;
  editStatus: EditStatus<any>;
  agent: AgentRes;
}

export interface AgentSignOutError {
  type: ActionTypes.AGENT_SIGN_OUT_ERROR;
  error: ServerError;
}

export interface AgentSignOutClearError {
  type: ActionTypes.AGENT_SIGN_OUT_CLEAR_ERROR;
}

export interface AgentUpdateTeamAndCampaign {
  type: ActionTypes.AGENT_UPDATE_TEAM_AND_CAMPAIGN;
  agentId: string;
  campaignId: string;
  teamId: string;
}

export interface AgentUpdateTeamAndCampaignSuccess {
  type: ActionTypes.AGENT_UPDATE_TEAM_AND_CAMPAIGN_SUCCESS;
  updatedAgent: AgentRes;
}

export interface AgentUpdateTeamAndCampaignError {
  type: ActionTypes.AGENT_UPDATE_TEAM_AND_CAMPAIGN_ERROR;
  error: ServerError;
}

export interface AgentUpdateClearError {
  type: ActionTypes.AGENT_UPDATE_CLEAR_ERROR;
}

export interface AgentSignOutClearEditStatus {
  type: ActionTypes.AGENT_SIGN_OUT_CLEAR_EDIT_STATUS;
}

export interface AgentTakeBreak {
  type: ActionTypes.AGENT_TAKE_BREAK;
  agentId: string;
}

export interface AgentTakeBreakSuccess {
  type: ActionTypes.AGENT_TAKE_BREAK_SUCCESS;
  updatedAgent: AgentRes;
  editStatus: EditStatus<any>;
}

export interface AgentTakeBreakError {
  type: ActionTypes.AGENT_TAKE_BREAK_ERROR;
  error: ServerError;
}

export interface AgentTakeBreakClearError {
  type: ActionTypes.AGENT_TAKE_BREAK_CLEAR_ERROR;
}

export interface AgentTakeBreakClearEditStatus {
  type: ActionTypes.AGENT_TAKE_BREAK_CLEAR_EDIT_STATUS;
}

export interface AgentResumeShift {
  type: ActionTypes.AGENT_RESUME_SHIFT;
  agentId: string;
}

export interface AgentResumeShiftSuccess {
  type: ActionTypes.AGENT_RESUME_SHIFT_SUCCESS;
  updatedAgent: AgentRes;
  editStatus: EditStatus<any>;
}

export interface AgentResumeShiftError {
  type: ActionTypes.AGENT_RESUME_SHIFT_ERROR;
  error: ServerError;
}

export interface AgentResumeShiftClearError {
  type: ActionTypes.AGENT_RESUME_SHIFT_CLEAR_ERROR;
}

export interface AgentResumeShiftClearEditStatus {
  type: ActionTypes.AGENT_RESUME_SHIFT_CLEAR_EDIT_STATUS;
}

export interface ClearAgentError {
  type: ActionTypes.CLEAR_AGENT_ERROR;
}

export interface ClearAgent {
  type: ActionTypes.CLEAR_AGENT;
}

export type CallCenterAction =
  | FetchAgent
  | FetchAgentSuccess
  | FetchAgentError
  | AgentSignIn
  | AgentSignInSuccess
  | AgentSignInError
  | AgentSignInClearError
  | AgentSignInClearEditStatus
  | AgentSignOut
  | AgentSignOutError
  | AgentSignOutSuccess
  | AgentSignOutClearError
  | AgentSignOutClearEditStatus
  | AgentTakeBreak
  | AgentTakeBreakSuccess
  | AgentTakeBreakError
  | AgentTakeBreakClearError
  | AgentTakeBreakClearEditStatus
  | AgentResumeShift
  | AgentResumeShiftSuccess
  | AgentResumeShiftError
  | AgentResumeShiftClearError
  | AgentResumeShiftClearEditStatus
  | ClearAgent
  | ClearAgentError
  | AgentUpdateTeamAndCampaign
  | AgentUpdateTeamAndCampaignSuccess
  | AgentUpdateTeamAndCampaignError
  | AgentUpdateClearError;
