import { useScroll } from "hooks";
import React, { FC } from "react";
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars";

interface IProps extends ScrollbarProps {
  renderViewProps?: any;
  renderTrackHorizontalProps?: any;
}

const CustomScrollbars: FC<IProps> = (props) => {
  const {
    renderTrackHorizontalProps,
    onScrollStart,
    onScrollStop,
    ...remainingProps
  } = props;
  const scroll = useScroll();

  return (
    <Scrollbars
      onScrollStart={() => {
        scroll?.onScroll(true);
        onScrollStart?.();
      }}
      onScrollStop={() => {
        scroll?.onScroll(false);
        onScrollStop?.();
      }}
      {...remainingProps}
      renderView={(renderViewProps) => <div {...renderViewProps} />}
      renderTrackHorizontal={(renderTrackHorizontalProps) => (
        <div {...renderTrackHorizontalProps} />
      )}
    />
  );
};

export default CustomScrollbars;
