import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const CustomCorrespondenceData: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.15 19C14.15 18.5306 14.5306 18.15 15 18.15H21C21.4695 18.15 21.85 18.5306 21.85 19C21.85 19.4694 21.4695 19.85 21 19.85H15C14.5306 19.85 14.15 19.4694 14.15 19Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.15002 19C9.15002 18.5306 9.53058 18.15 10 18.15V18.15C10.4695 18.15 10.85 18.5306 10.85 19C10.85 19.4694 10.4695 19.85 10 19.85V19.85C9.53058 19.85 9.15002 19.4694 9.15002 19Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 17.85C12.3649 17.85 11.85 18.3649 11.85 19C11.85 19.6351 12.3649 20.15 13 20.15C13.6352 20.15 14.15 19.6351 14.15 19C14.15 18.3649 13.6352 17.85 13 17.85ZM10.15 19C10.15 17.426 11.426 16.15 13 16.15C14.574 16.15 15.85 17.426 15.85 19C15.85 20.574 14.574 21.85 13 21.85C11.426 21.85 10.15 20.574 10.15 19Z"
      fill={fill}
    />
    <path
      d="M21 14L20 14"
      stroke="black"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.15002 14C9.15002 13.5306 9.53058 13.15 10 13.15H16C16.4695 13.15 16.85 13.5306 16.85 14C16.85 14.4694 16.4695 14.85 16 14.85H10C9.53058 14.85 9.15002 14.4694 9.15002 14Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 12.85C17.3649 12.85 16.85 13.3649 16.85 14C16.85 14.6351 17.3649 15.15 18 15.15C18.6352 15.15 19.15 14.6351 19.15 14C19.15 13.3649 18.6352 12.85 18 12.85ZM15.15 14C15.15 12.426 16.426 11.15 18 11.15C19.574 11.15 20.85 12.426 20.85 14C20.85 15.574 19.574 16.85 18 16.85C16.426 16.85 15.15 15.574 15.15 14Z"
      fill={fill}
    />
    <path
      d="M16.125 7.3125V6.1875C16.125 6.08437 16.0406 6 15.9375 6H6.9375C6.83437 6 6.75 6.08437 6.75 6.1875V7.3125C6.75 7.41563 6.83437 7.5 6.9375 7.5H15.9375C16.0406 7.5 16.125 7.41563 16.125 7.3125ZM6.9375 9.375C6.83437 9.375 6.75 9.45937 6.75 9.5625V10.6875C6.75 10.7906 6.83437 10.875 6.9375 10.875H11.25C11.3531 10.875 11.4375 10.7906 11.4375 10.6875V9.5625C11.4375 9.45937 11.3531 9.375 11.25 9.375H6.9375ZM7.3125 19.9688H4.875V3.46875H18V8.53125C18 8.63437 18.0844 8.71875 18.1875 8.71875H19.5C19.6031 8.71875 19.6875 8.63437 19.6875 8.53125V2.53125C19.6875 2.11641 19.3523 1.78125 18.9375 1.78125H3.9375C3.52266 1.78125 3.1875 2.11641 3.1875 2.53125V20.9062C3.1875 21.3211 3.52266 21.6562 3.9375 21.6562H7.3125C7.41563 21.6562 7.5 21.5719 7.5 21.4688V20.1562C7.5 20.0531 7.41563 19.9688 7.3125 19.9688Z"
      fill={fill}
    />
  </svg>
);
