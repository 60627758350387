import { useEffect, useState } from "react";

import { ReportingPeriodRes } from "@n3oltd/karakoram.analytics.sdk.attribution";

import { _attributionClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

export function useGetReportingPeriodById(
  reportingPeriodId: string,
  k2Intl: InjectedK2Intl,
): {
  reportingPeriod: ReportingPeriodRes;
  loadingReportingPeriod: boolean;
} {
  const [reportingPeriod, setReportingPeriod] = useState<ReportingPeriodRes>();
  const [loadingReportingPeriod, setLoadingReportingPeriod] = useState<boolean>(
    true,
  );

  useEffect(() => {
    const getReportingPeriodById = async () => {
      setLoadingReportingPeriod(true);

      const response: IApiResponse<ReportingPeriodRes> = await K2RestService.toResponse(
        _attributionClient.getReportingPeriodById(reportingPeriodId),
      );

      if (!response.error) {
        setReportingPeriod(response.getResultOrDefault());
      } else {
        UIUtils.handleServerError(k2Intl, response.error);
      }
      setLoadingReportingPeriod(false);
    };
    if (reportingPeriodId) getReportingPeriodById();
    //TODO: Remove afer refactoring/updating  k2Intl and UIUtils
    // eslint-disable-next-line
  }, [reportingPeriodId]);

  return {
    reportingPeriod,
    loadingReportingPeriod,
  };
}
