import React, { FC } from "react";

import { RightOutlined } from "@ant-design/icons";
import { LocalizationSettingsRes } from "@n3oltd/k2.users.sdk.users/esm";
import { ActivityLogRes } from "@n3oltd/karakoram.activities.sdk.activities/esm";
import { List, Tag, Tooltip } from "antd";
import moment from "moment";
import styled from "styled-components";

import DateHelpers from "common/helpers/dates";
import { K2DateTime, K2Link } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";
import { IN3OIconType } from "components/n3oIcon/types";

interface ActivityItemProps {
  item: ActivityLogRes;
  currentUserLocalization: LocalizationSettingsRes;
  k2Intl: InjectedK2Intl;
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  height: 48px;

  & a {
    align-self: flex-end;
  }
`;

const Time = styled.span`
  cursor: default;
  font-size: smaller;
  color: ${({ theme }) => theme.grey_6};
`;

//Added AntDesign's presets for Tag Colors. Keys of this object are received from API
const colorsObj = {
  "#F5222D": "red",
  "#52C41A": "green",
  "#2F54EB": "blue",
  "#FA8C16": "orange",
  "#722ED1": "purple",
};

const ActivityItem: FC<ActivityItemProps> = ({
  item,
  currentUserLocalization,
  k2Intl,
}) => {
  const color = colorsObj[item.type.color] || item.type.color;

  return (
    <List.Item key={item.id}>
      <List.Item.Meta
        title={
          <Tag
            color={color}
            icon={
              <N3oIcon
                icontype={item.type.icon as IN3OIconType}
                width={12}
                height={12}
                fill={"#fff"}
              />
            }
          >
            <span>{item.type.name}</span>
          </Tag>
        }
        description={item.summary}
      />
      <Content>
        <Tooltip
          title={
            <K2DateTime
              value={item.timestamp}
              mode={"dateAndTime"}
              timeZone={currentUserLocalization.timezone}
              {...currentUserLocalization}
            />
          }
        >
          <Time className="n3o-pointer">
            {DateHelpers.fromNowOrDate(
              moment(item.timestamp),
              k2Intl,
              "dateAndTime",
            )}
          </Time>
        </Tooltip>
        {!item.type.name.match(/delete/i) && item.link && (
          <K2Link to={item.link ? `${item.link}` : ""}>
            <RightOutlined />
          </K2Link>
        )}
      </Content>
    </List.Item>
  );
};

export default ActivityItem;
