import React from "react";

import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/src/index";
import { FragmentRes } from "@n3oltd/karakoram.templates.sdk.fragments/src/index";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  _assetsClient,
  _fragmentsClient,
} from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { DataActions } from "appRedux/modules/sharedTypes";
import { TEMPLATE_ENGINE } from "constants/appConstants";

import {
  ActionTypes,
  CreatePartialOrLayout,
  DeletePartialOrLayout,
  FetchPartialOrLayout,
  RemoveTemporaryAsset,
  UpdatePartialOrLayout,
  UploadAsset,
} from "./actionTypes";
import * as actions from "./actions";

//#region WORKERS

//#endregion

function* fetchTemplate(action: FetchPartialOrLayout) {
  const response: IApiResponse<FragmentRes> = yield K2RestService.toResponse(
    _fragmentsClient.getFragmentById(action.id, TEMPLATE_ENGINE, null),
  );

  if (response.error) {
    yield put(actions.fetchPartialOrLayoutError(response.error));
  } else {
    yield put(
      actions.fetchPartialOrLayoutSuccess(response.getResultOrDefault()),
    );
  }
}

function* createTemplate(action: CreatePartialOrLayout) {
  const response: IApiResponse<FragmentRes> = yield K2RestService.toResponse(
    _fragmentsClient.createFragment(TEMPLATE_ENGINE, null, action.data),
  );

  if (response.error) {
    yield put(
      actions.createPartialOrLayoutError({
        action: DataActions.add,
        ...response,
      }),
    );
  } else {
    yield put(
      actions.createPartialOrLayoutSuccess({
        action: DataActions.add,
        ...response,
      }),
    );
  }
}

function* updateTemplate(action: UpdatePartialOrLayout) {
  const response: IApiResponse<void> = yield K2RestService.toResponse(
    _fragmentsClient.updateFragment(
      action.revisionId,
      TEMPLATE_ENGINE,
      null,
      action.data,
    ),
  );

  if (response.error) {
    yield put(
      actions.updatePartialOrLayoutError({
        action: DataActions.update,
        ...response,
      }),
    );
  } else {
    yield put(
      actions.updatePartialOrLayoutSuccess({
        action: DataActions.update,
        ...response,
      }),
    );
  }
}

function* deleteTemplate(action: DeletePartialOrLayout) {
  const response: IApiResponse<void> = yield K2RestService.toResponse(
    _fragmentsClient.deleteFragment(action.revisionId, TEMPLATE_ENGINE, null),
  );

  if (response.error) {
    yield put(
      actions.deletePartialOrLayoutError({
        action: DataActions.delete,
        ...response,
      }),
    );
  } else {
    yield put(
      actions.deletePartialOrLayoutSuccess({
        action: DataActions.delete,
        ...response,
      }),
    );
  }
}

function* uploadAsset(action: UploadAsset) {
  try {
    yield put(actions.updateTemporaryAssets(action.upload));

    const resp: IApiResponse<string> = yield K2RestService.toResponse(
      _assetsClient.upload(null, {
        fileName: action.originalFile.name,
        data: action.originalFile,
      }),
    );

    if (resp.error) {
      yield put(
        actions.updateTemporaryAssets({
          ...action.upload,
          status: "error",
          response:
            resp.error.data.status === K2StatusCodes.preconditionFailed
              ? resp.error.data.errors?.map?.((e) => e.error)?.join(". ")
              : resp.error.data.title,
        }),
      );
    } else {
      const assetResp: IApiResponse<AssetRes> = yield K2RestService.toResponse(
        _assetsClient.createAsset(TEMPLATE_ENGINE, null, {
          file: resp.getResultOrDefault(),
          shared: action.upload.shared,
        }),
      );

      if (assetResp.error) {
        const errors: string =
          assetResp.error.data.status === K2StatusCodes.preconditionFailed
            ? assetResp.error.data.errors?.map?.((e) => e.error)?.join(". ")
            : assetResp.error.data.title;

        yield put(
          actions.updateTemporaryAssets({
            ...action.upload,
            status: "error",
            response: errors,
          }),
        );
      } else {
        yield put(
          actions.updateTemporaryAssets({
            ...action.upload,
            status: "success",
            savedAsset: assetResp.getResultOrDefault(),
          }),
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* deleteAsset(action: RemoveTemporaryAsset) {
  // If the asset has already been saved to the backend, delete it.
  if (action.temporaryAsset.savedAsset) {
    yield K2RestService.toResponse(
      _assetsClient.deleteAsset(
        action.temporaryAsset.savedAsset.revisionId,
        TEMPLATE_ENGINE,
        null,
      ),
    );
  }
}

//#region WATCHERS

export default function* partialOrLayoutSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_PARTIAL_OR_LAYOUT, fetchTemplate),
    takeLatest(ActionTypes.CREATE_PARTIAL_OR_LAYOUT, createTemplate),
    takeLatest(ActionTypes.UPDATE_PARTIAL_OR_LAYOUT, updateTemplate),
    takeLatest(ActionTypes.DELETE_PARTIAL_OR_LAYOUT, deleteTemplate),
    takeEvery(ActionTypes.UPLOAD_ASSET, uploadAsset),
    takeEvery(ActionTypes.REMOVE_TEMPORARY_ASSET, deleteAsset),
  ]);
}

//#endregion
