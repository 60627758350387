import React, { FC, useCallback, useMemo } from "react";

import { EllipsisOutlined } from "@ant-design/icons";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons/lib";
import { Trend } from "@n3oltd/karakoram.analytics.sdk.measures/esm";
import { Dropdown, Menu, Statistic } from "antd";
import { StatisticProps } from "antd/es/statistic/Statistic";
import { useWindowWidth } from "hooks";
import styled from "styled-components";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Card, K2Message } from "components/k2Widgets";
import { N3oIcon } from "components/n3oIcon";
import { IN3OIconType } from "components/n3oIcon/types";
import { MOBILE_SIZE } from "constants/ThemeSetting";

const ResponsiveStatisticWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ExtraBtn = styled(EllipsisOutlined)`
  position: absolute;
  right: 12px;
  top: 12px;
  visibility: hidden;

  background: #f9f9fe;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
`;

const OverviewCard = styled(K2Card)<{
  $mobileWidth;
  $loading;
}>`
  border-radius: 8px;
  margin: 0;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.card_shadow};
  width: 100%;

  .ant-card-body {
    padding: 24px;
  }

  @media screen and (max-width: ${({ $mobileWidth }) => $mobileWidth}px) {
    .ant-card-body {
      padding: 14px 20px;
    }
  }

  &:hover {
    ${ExtraBtn} {
      visibility: visible;
    }
  }
`;

const ResponsiveStatistic = styled(Statistic)`
  && {
    @media (max-width: 768px) {
      .ant-statistic-content {
        font-size: 16px;
        .anticon {
          font-size: 20px !important;
        }
      }
    }
  }
`;

export const StatisticsTitle = styled.div`
  color: ${({ theme }) => theme.grey_6} !important;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const RedCaretDown = styled(CaretDownFilled)`
  && {
    font-size: ${({ theme }) => theme.font_size_xl};
    margin-right: 5px;
    color: ${({ theme }) => theme.red_5};
  }
`;

const GreenCaretUp = styled(CaretUpFilled)`
  && {
    font-size: ${({ theme }) => theme.font_size_xl};
    margin-right: 5px;
    color: ${({ theme }) => theme.green_7};
  }
`;

export const StatisticsSubTitle = styled.span`
  color: ${({ theme }) => theme.grey_6} !important;
  font-size: 12px;
  line-height: 20px;
  opacity: 0.67;

  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 16px;
  }
`;

interface IProps extends StatisticProps {
  name: string;
  description: string;
  icon: IN3OIconType;
  value: string;
  trend: Trend;
  loading?: boolean;
  remove?: () => void;
}

const StatisticOverviewCard: FC<IProps> = ({
  name,
  value,
  description,
  icon,
  trend,
  loading,
  remove,
}) => {
  const { theme } = useAppContext();

  const getSuffix = useCallback((trend: Trend) => {
    switch (trend) {
      case "down":
        return <RedCaretDown />;
      case "up":
        return <GreenCaretUp />;
      case "flat":
        return null;
      default:
        return null;
    }
  }, []);

  const width: number = useWindowWidth();

  const isMobileScreen = width <= MOBILE_SIZE;

  const valueStyle = useMemo(() => {
    return {
      fontWeight: (theme.font_weight_heavy as unknown) as number,
      fontSize: isMobileScreen ? theme.font_size_lg : theme.font_size_xl,
      color: theme.grey_8,
    };
  }, [
    isMobileScreen,
    theme.font_size_lg,
    theme.font_size_xl,
    theme.font_weight_heavy,
    theme.grey_8,
  ]);

  return (
    <OverviewCard $mobileWidth={MOBILE_SIZE} $loading={loading}>
      <ResponsiveStatisticWrapper>
        <div>
          {remove && (
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      remove();
                    }}
                  >
                    <K2Message localeKey="common.remove" />
                  </Menu.Item>
                </Menu>
              }
            >
              <ExtraBtn />
            </Dropdown>
          )}
          <ResponsiveStatistic
            title={<StatisticsTitle>{name}</StatisticsTitle>}
            valueStyle={valueStyle}
            value={value}
            suffix={getSuffix(trend)}
          />

          <StatisticsSubTitle>{description}</StatisticsSubTitle>
        </div>
        <N3oIcon icontype={icon} fill={theme.grey_5} />
      </ResponsiveStatisticWrapper>
    </OverviewCard>
  );
};

export default StatisticOverviewCard;
