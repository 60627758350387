import React, { FC, useEffect, useState } from "react";

import {
  ChangelogRes,
  WelcomeInfoRes,
} from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { UserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { Tag, Typography } from "antd";
import styled from "styled-components";

import { _subscriptionsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { K2Link, K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import helpCenterConfig from "config/helpCenterConfig";

import { UIUtils } from "../../utils";

interface IGreetingsProps extends InjectedK2IntlProps {
  user: UserRes;
  taskCountForUser: number;
}

const GreetingsContainer = styled.div`
  padding-left: 0;

  .ant-tag {
    text-transform: uppercase;
  }

  .text {
    margin-right: 8px;
  }

  @media screen and (min-width: ${({ theme }) => theme.screen_tab}) {
    margin-top: 32px;
  }
`;

const DashboardGreeting = styled.div`
  color: ${({ theme }) => theme.grey_8};
  transition-delay: 500ms;
  transition-duration: 500ms;
  transition-timing-function: ease-out;
  font-size: ${({ theme }) => theme.h2_font_size};
  line-height: ${({ theme }) => theme.line_height_xl};

  strong {
    font-weight: ${({ theme }) => theme.font_weight_heavy};
  }

  @media screen and (max-width: ${({ theme }) => theme.screen_tab}) {
    padding-top: 0 !important;
      margin-top: -10px;
    }
  }
`;

const Message = styled.div`
  transition: 0.2s;
  color: ${({ theme }) => theme.grey_7};
  font-size: small;
  font-weight: ${({ theme }) => theme.font_weight_normal};
  // opacity: 0.5;
  padding: 8px 0;

  transition-timing-function: ease-in;
  transition-delay: 700ms;
  transition-duration: 500ms;
`;

const Greetings: FC<IGreetingsProps> = (props) => {
  const { user, k2Intl } = props;
  const [changelog, setChangelog] = useState<ChangelogRes>(null);

  useEffect(() => {
    const fetch = async () => {
      const response: IApiResponse<WelcomeInfoRes> = await K2RestService.toResponse(
        _subscriptionsClient.getWelcomeInfo(),
      );

      if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else {
        setChangelog(response?.result?.changelog);
      }
    };

    fetch();
    // eslint-disable-next-line
  }, []);

  return (
    <GreetingsContainer className="n3o-pl-0">
      <DashboardGreeting className={"n3o-dashboard-greeting"}>
        <K2Message localeKey="common.welcome" />,{" "}
        <strong>{user.firstName}</strong>!
      </DashboardGreeting>

      <Message className={"n3o-dashboard-message"}>
        <Tag color={changelog?.badge?.color}>{changelog?.badge?.text}</Tag>
        <Typography.Text className="text">{changelog?.text}</Typography.Text>
        <K2Link
          to={changelog?.link.url}
          target="_blank"
          rel="noopener noreferrer"
          external
        >
          {changelog?.link?.text}
        </K2Link>
      </Message>

      <Message className={"n3o-dashboard-message extra-message"}>
        {k2Intl.formatMessage({ localeKey: "common.welcome.extra.p1" })}
        <K2Link
          to={`${helpCenterConfig.rootUrl}${helpCenterConfig.pages.docs}`}
          external={true}
        >
          {k2Intl
            .formatMessage({ localeKey: "common.clickHere" })
            .toLowerCase()}
        </K2Link>
        &#46;
        <br />
        {k2Intl.formatMessage({ localeKey: "common.welcome.extra.p2" })}
        <K2Link
          to={`${helpCenterConfig.rootUrl}${helpCenterConfig.pages.contactUs}`}
          external={true}
        >
          {k2Intl
            .formatMessage({ localeKey: "common.submitATicket" })
            .toLowerCase()}
        </K2Link>
        <br />
        {k2Intl
          .formatMessage({ localeKey: "common.welcome.extra.p3" })
          .toLowerCase()}
      </Message>
    </GreetingsContainer>
  );
};

export default injectK2Intl(Greetings);
