import React from "react";
import styled from "styled-components";

export const ListColTitle = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.grey_7};
  padding-right: 5px;
  line-height: 20px;
  text-transform: uppercase;
`;
