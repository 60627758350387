import K2Variables from "common/themeVariables";
export interface ThemeObject {
  [key: string]: string;
}

const variables: ThemeObject = {
  ...K2Variables,
};

variables.primary_1 = "#fff7e6";
variables.primary_2 = "#ffe7ba";
variables.primary_3 = "#ffd591";
variables.primary_4 = "#ffc069";
variables.primary_5 = "#ffa940";
variables.primary_6 = "#faad14";
variables.primaryColor = "#fa8c16";
variables.primaryColorDarkened = "#d46b08";
variables.primaryColorDarkest = "#873800";

variables.link_color = variables.primaryColor;
variables.link_hover_color = "#fadb14";
variables.link_active_color = "#fadb14";

variables.tile_hover_color = variables.primary_2;

variables.shadow_color = "rgba(240, 234, 187, 0.256)";
variables.border_color_base = "#d4b106"; // base border outline a component
variables.border_color_split = "#d4b106"; // split border inside a component

// BUTTON
variables.button_hover_color = variables.primary_5;

variables.icon_grey_light = "#b8b9cc";

export default variables;
