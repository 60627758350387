import React, { FC, useCallback, useEffect, useState } from "react";

import { CallSorts, CallSummaryRes } from "@n3oltd/k2.calls.sdk.calls";
import { CallResultsPage } from "@n3oltd/k2.calls.sdk.calls/src/index";
import { Divider, Empty, Typography } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { _callsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import {
  CallDispositionsLookups,
  CallEndedReasonsLookups,
} from "appRedux/modules/lookups/types";
import IApplicationState from "appRedux/types";
import DateHelpers from "common/helpers/dates";
import { TimesHelpers } from "common/helpers/times";
import UserAvatar from "components/avatars/userAvatar";
import { K2Tooltip } from "components/k2Widgets";
import PaginationFooter from "components/k2Widgets/k2Grid/PaginationFooter";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import K2Spin from "components/k2Widgets/k2Spin";
import { UIUtils } from "components/utils";
import { DEFAULT_PAGE_SIZE } from "constants/appConstants";

import CallDetails from "./CallDetails";

const Container = styled.div`
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.n3o_card_shadow};
  min-width: 250px;
`;

const CallItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 15px;
  padding-right: 10px;
  .ant-typography {
    color: ${({ theme }) => theme.grey_9};
    font-size: ${({ theme }) => theme.font_size_base};
    line-height: ${({ theme }) => theme.line_height_md};
  }

  .ant-typography.ant-typography-secondary {
    color: ${({ theme }) => theme.grey_8};
    font-size: ${({ theme }) => theme.font_size_sm};
    line-height: ${({ theme }) => theme.line_height_md};
  }

  & > div:first-child {
  }

  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    gap: 10px;
  }
`;
const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  flex: 1 1 0;

  .tooltip-children-body.n3o-pointer {
    margin-left: auto;
  }
`;

const { Text } = Typography;

const TooltipText = styled(Text)`
  color: ${({ theme }) => theme.white_color} !important;
`;

const RecentCalls: FC<{
  k2Intl: InjectedK2Intl;
  callDispositions?: CallDispositionsLookups;
  callEndedReasons?: CallEndedReasonsLookups;
  openCallList: boolean;
}> = ({ k2Intl, callDispositions, callEndedReasons, openCallList }) => {
  const subscriptionId = useSelector(
    (state: IApplicationState) => state.subscription.users.k2Subscription.id,
  );
  const userId = useSelector(
    (state: IApplicationState) => state.subscription.users.k2User.id,
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [recentCalls, setRecentCalls] = useState<CallResultsPage>({
    items: [],
  });

  const fetchRecentCalls = useCallback(
    async (continuationToken?: string) => {
      setLoading(true);

      const resp: IApiResponse<CallResultsPage> = await K2RestService.toResponse(
        _callsClient.findCalls({
          by: [userId],
          sort: CallSorts.Date_desc,
          continuationToken: continuationToken,
          pageSize: DEFAULT_PAGE_SIZE,
        }),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        let updatedRecentCalls = resp.getResultOrDefault();
        if (continuationToken) {
          updatedRecentCalls = {
            items: [...recentCalls.items, ...updatedRecentCalls.items],
            continuationToken: updatedRecentCalls.continuationToken,
          };
        }
        setRecentCalls(updatedRecentCalls);
      }

      setLoading(false);
    },
    [k2Intl, recentCalls, userId],
  );

  const onLoadMore = useCallback(() => {
    fetchRecentCalls(recentCalls?.continuationToken);
  }, [fetchRecentCalls, recentCalls]);

  const renderCallHeader = (item: CallSummaryRes) => {
    const duration = item.duration
      ? `${TimesHelpers.formatSecondsAsMS(
          item.duration?.seconds,
          k2Intl,
          true,
        )}`
      : undefined;
    const durationWithDate = `${DateHelpers.fromNowOrDate(
      moment(item.begunAt),
      k2Intl,
      "date",
    )}`;
    return (
      <NameRow>
        <Text strong>{item.account.name}</Text>
        <K2Tooltip
          style={{
            marginLeft: "auto",
          }}
          title={
            <>
              <TooltipText type="secondary">
                {k2Intl.formatDate(new Date(item.begunAt))}
              </TooltipText>
              <br />
              <TooltipText type="secondary">
                {k2Intl.formatTime(new Date(item.begunAt))}
              </TooltipText>
              <br />
              {duration && (
                <TooltipText type="secondary">{duration}</TooltipText>
              )}
            </>
          }
          placement="left"
        >
          <Text type="secondary">
            {durationWithDate}
            {" - "}
            {duration}
          </Text>
        </K2Tooltip>
      </NameRow>
    );
  };

  useEffect(() => {
    if (openCallList) {
      fetchRecentCalls();
    }
    // eslint-disable-next-line
  }, [openCallList]);

  return (
    <K2Spin spinning={loading}>
      <Container>
        {recentCalls?.items?.length === 0 ? (
          <Empty
            description={k2Intl.formatMessage({
              localeKey: "communication.cc.noRecentCalls",
            })}
          />
        ) : (
          <>
            {recentCalls?.items?.map((item) => (
              <CallItem key={item.id}>
                <div>
                  <UserAvatar
                    size={40}
                    presetId={"large"}
                    subscriptionId={subscriptionId}
                    user={item.account}
                    className="n3o-avatar-border n3o-avatar-text-center"
                  />
                </div>
                <div>
                  {renderCallHeader(item)}
                  <CallDetails
                    callInfo={item}
                    callDispositions={callDispositions}
                    callEndedReasons={callEndedReasons}
                    k2Intl={k2Intl}
                  />
                </div>
              </CallItem>
            ))}

            {recentCalls?.continuationToken && (
              <>
                <Divider />
                <PaginationFooter onLoadMore={onLoadMore} />
              </>
            )}
          </>
        )}
      </Container>
    </K2Spin>
  );
};

export default RecentCalls;
