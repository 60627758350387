import { DataScope } from "@n3oltd/karakoram.analytics.sdk.attribution";
import { ConnectedProps, connect } from "react-redux";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import IApplicationState from "appRedux/types";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

import {
  clearEditStatus,
  clearReportingPeriodsError,
  clearSelectedEditStatus,
  createReportingPeriod,
  deleteReportingPeriod,
  findReportingPeriods,
  setSelectedReportingPeriod,
  updateReportingPeriod,
  updateSortOrder,
} from "./modules/actions";
import reducer from "./modules/reducer";
import saga from "./modules/saga";

const mapStateToProps = (state: IApplicationState) => {
  return state.reportingPeriods;
};

const mapDispatchToProps = {
  findReportingPeriods,
  clearReportingPeriodsError,
  setSelectedReportingPeriod,
  updateSortOrder,
  createReportingPeriod,
  clearEditStatus,
  updateReportingPeriod,
  clearSelectedEditStatus,
  deleteReportingPeriod,
};

const withSaga = injectSaga({ key: "reportingPeriods", saga });
const withReducer = injectReducer({ key: "reportingPeriods", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof withConnect>;

export type ReportingPeriodProps = PropsFromRedux &
  InjectedK2IntlProps & {
    reportingInfo: {
      reportingScope: DataScope;
      showAddReportingModal?: boolean;
      setShowAddReportingModal?: (value: boolean) => void;
      refetch?: () => void;
    };
  };

export default compose(withSaga, withReducer, withConnect);
