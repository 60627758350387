import React, { FC } from "react";

import { GlobalSuggestion } from "@n3oltd/k2.accounts.sdk.global-suggestions/esm";
import { Typography } from "antd";
import styled from "styled-components";

import { K2Message } from "components/k2Widgets";

const { Text } = Typography;

const SearchResult = styled.div<{ $isLink?: boolean }>`
  padding: 8px;
  pointer-events: ${({ $isLink }) => ($isLink ? "none" : "initial")};
  & div {
    margin: 4px 0;
  }
  .title {
    font-size: medium;
    color: ${({ theme }) => theme.grey_8};
  }
  .isInactive {
    color: ${({ theme }) => theme.grey_7};
  }
  .id {
    font-size: smaller;
    color: ${({ theme }) => theme.grey_6};
    float: right;
    &::before {
      content: "Ref No. ";
      color: ${({ theme }) => theme.grey_5};
    }
  }
  .line1,
  .line2 {
    font-size: small;
    color: ${({ theme }) => theme.grey_7};
  }
`;

interface GlobalSearchResultOptionProps {
  item: GlobalSuggestion;
  shouldLink?: boolean;
  onClick?: () => void;
  isInactive?: boolean;
}

const GlobalSearchResultOption: FC<GlobalSearchResultOptionProps> = ({
  item,
  onClick,
  isInactive,
  shouldLink = false,
}) => {
  return (
    <SearchResult $isLink={shouldLink} onClick={() => onClick?.()}>
      <div className={`title ${isInactive ? "isInactive" : ""}`}>
        {item.title}
      </div>
      <div className="line1">{item.line1}</div>
      <div className="line2">{item.line2}</div>
    </SearchResult>
  );
};

export default GlobalSearchResultOption;
