import React, { FC } from "react";
import Form from "antd/lib/form";
import { NamePath } from "rc-field-form/lib/interface";
import { FormItemProps } from "antd/es";

interface IHiddenFormItemV4Props extends FormItemProps {
  /**name of the field for data binding */
  fieldName: NamePath;
  /**Initial value for hidden field */
  initialValue?: any;
}

const HiddenFormItemV4: FC<IHiddenFormItemV4Props> = ({
  fieldName,
  initialValue,
  ...rest
}) => {
  return (
    <Form.Item
      name={fieldName}
      initialValue={initialValue}
      className="n3o-hidden-field"
      {...rest}
    >
      <></>
    </Form.Item>
  );
};

export default HiddenFormItemV4;
