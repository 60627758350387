import { applyMiddleware, compose, createStore } from "redux";
import createReducer, { rootSaga } from "../modules";
import { routerMiddleware } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import history from "../utils/history";
import { createInjectorsEnhancer, forceReducerReload } from "redux-injectors";

import IApplicationState from "../types";

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState?: IApplicationState) {
  const store = createStore(
    createReducer({}),
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares),
      createInjectorsEnhancer({
        createReducer,
        runSaga: sagaMiddleware.run,
      }),
    ),
  );

  const runSaga = sagaMiddleware.run(rootSaga);
  if (!runSaga.isRunning()) {
    console.error("Saga Failed to Run - ", runSaga.error());
  }

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../modules/index", () => {
      forceReducerReload(store);

      store.replaceReducer(createReducer({}));
    });
  }

  return store;
}
