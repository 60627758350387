import React from "react";
import styled from "styled-components";
import { MenuProps } from "antd/lib/menu";
import { FilterCriteria } from "appRedux/modules/tasks/dashboard/types";
import { dashboardTasksActions } from "appRedux/modules/tasks";
import { K2Message, K2Card, K2Checkbox } from "components/k2Widgets";

const FilterMenuCard = styled(K2Card)`
  & {
    width: 200px;
    text-align: left;
    padding: 12px 0;

    p {
      color: ${({ theme }) => theme.grey_6};
      margin: 0;
    }

    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    .filter-opt {
      padding: 8px 0;
    }
    .ant-card-body {
      padding: 16px;
    }
  }
`;

interface IProps extends MenuProps {
  updateFilters: typeof dashboardTasksActions.updateDashboardFilters;
  filtersApplied: FilterCriteria;
}

const FilterMenu = (props: IProps) => {
  const { updateFilters, filtersApplied } = props;
  return (
    <FilterMenuCard>
      <p>
        <K2Message localeKey={"common.filterBy"} />
      </p>
      <div className={"filter-opt"}>
        <K2Checkbox
          checked={filtersApplied.assignedToMe}
          onChange={() =>
            updateFilters("assignedToMe", !filtersApplied.assignedToMe)
          }
        >
          <K2Message localeKey="tasks.filterOpt.assignedToMe" />
        </K2Checkbox>
      </div>

      <div className={"filter-opt"}>
        <K2Checkbox
          checked={filtersApplied.highPriority}
          onChange={() =>
            updateFilters("highPriority", !filtersApplied.highPriority)
          }
        >
          <K2Message localeKey="tasks.filterOpt.highPriority" />
        </K2Checkbox>
      </div>

      <div className={"filter-opt"}>
        <K2Checkbox
          checked={filtersApplied.overdue}
          onChange={() => updateFilters("overdue", !filtersApplied.overdue)}
        >
          <K2Message localeKey="tasks.filterOpt.overdue" />
        </K2Checkbox>
      </div>
    </FilterMenuCard>
  );
};

export default FilterMenu;
