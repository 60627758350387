import React, { FC, useCallback, useEffect, useState } from "react";

import {
  AssetRes,
  AssetType,
} from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { AssetTypeRes } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { Alert, Col, Divider, Form, Row, Tabs } from "antd";
import { useForm } from "antd/es/form/Form";
import Dragger from "antd/es/upload/Dragger";
import { UploadFile } from "antd/es/upload/interface";
import TextArea from "antd/lib/input/TextArea";
import { RcFile } from "antd/lib/upload";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import styled from "styled-components";

import { GeneralFormItemV4 } from "components/formItems";
import MultiFileUploadExplanation from "components/forms/uploading/multiFileUploadExplanation";
import SingleFileUploadExplanation from "components/forms/uploading/singleFileUploadExplanation";
import { K2Message } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import K2Modal from "components/k2Widgets/k2Modal";
import {
  PageType,
  TemporaryUploadFile,
} from "routes/admin/communication/modules/types";
import PartialOrLayoutHelpers from "routes/admin/communication/newEditPartialOrLayout/helpers";
import SharedAssetsSelectList from "routes/admin/communication/sharedComponents/markup/assets/SharedAssetsSelectList";

import { SharedAssetsHelpers } from "../sharedAssets/helpers";

const { TabPane } = Tabs;

interface AssetModalProps extends InjectedK2IntlProps {
  onSave: (
    tempAssets: TemporaryUploadFile[],
    existingAssets: AssetRes[],
  ) => void;
  onCancel: () => void;
  pageType: PageType;
  loading: boolean;
  assetTypes: AssetTypeRes[];
  onUpload: (upload: TemporaryUploadFile, originalFile: RcFile) => void;
  updateTemporaryAssets: (upload: TemporaryUploadFile) => void;
  removeTemporaryAsset: (tempAsset: TemporaryUploadFile) => void;
  temporaryUploadFiles: TemporaryUploadFile[];
  currentlySelectedAssets: AssetRes[];
  maxAssets?: number;
  assetType?: AssetType;
  titleKey?: string;
}

const Title = styled.p`
  font-weight: ${({ theme }) => theme.font_weight_bold};
  font-size: 16px;
`;

const StyledFormItem = styled(Form.Item)`
  && {
    line-height: unset;
  }
`;

const FileTypeList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const AssetModal: FC<AssetModalProps> = (props) => {
  const {
    onSave,
    onCancel,
    k2Intl,
    pageType,
    assetTypes,
    onUpload,
    removeTemporaryAsset,
    temporaryUploadFiles,
    currentlySelectedAssets,
    titleKey,
    assetType,
    maxAssets,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [tabVisible, setTabVisible] = useState<"private" | "shared">("private");
  const [dirty, setDirty] = useState<boolean>(false);
  const [selectedExistingAssets, setSelectedExistingAssets] = useState<
    AssetRes[]
  >([]);

  const [privateFileList, setPrivateFileList] = useState<UploadFile<any>[]>(
    temporaryUploadFiles.filter((f) => !f.shared),
  );
  const [sharedFileList, setSharedFileList] = useState<UploadFile<any>[]>(
    temporaryUploadFiles.filter((f) => f.shared),
  );

  const upload = useCallback(
    async (info: RcCustomRequestOptions) => {
      const file = info.file as RcFile;
      const tempUpload: TemporaryUploadFile = {
        uid: file.uid,
        fileName: file.name,
        size: file.size,
        name: file.name,
        type: file.type,
        status: "uploading",
        originFileObj: file,
        shared: tabVisible === "shared",
        rcFile: file,
      };
      onUpload(tempUpload, file);
    },
    [onUpload, tabVisible],
  );

  const removeFile = (file: TemporaryUploadFile) => {
    removeTemporaryAsset(file);
  };
  const [form] = useForm();

  const uploading = temporaryUploadFiles.some(
    (file) => file.status === "uploading",
  );

  const haveErrors = temporaryUploadFiles.some(
    (file) => file.status === "error",
  );

  useEffect(() => {
    setDirty(temporaryUploadFiles.length + selectedExistingAssets.length > 0);
  }, [temporaryUploadFiles, selectedExistingAssets]);

  const maxItemsAlreadySelected =
    temporaryUploadFiles.length + selectedExistingAssets.length === maxAssets;

  const nothingSelected =
    temporaryUploadFiles.length + selectedExistingAssets.length === 0;

  useEffect(() => {
    setPrivateFileList(temporaryUploadFiles.filter((f) => !f.shared));
    setSharedFileList(temporaryUploadFiles.filter((f) => f.shared));
  }, [temporaryUploadFiles]);

  return (
    <K2Modal
      visible={true}
      requiresCloseConfirmation={dirty}
      titleKey={titleKey || "admin.communication.mediaAssets.add"}
      loading={loading}
      width={"50%"}
      okButtonProps={{
        children: k2Intl?.formatMessage({ localeKey: "common.save" }),
        onClick: () => onSave(temporaryUploadFiles, selectedExistingAssets),
        disabled: uploading || !dirty || nothingSelected || haveErrors,
      }}
      cancelButtonProps={{
        onClick: onCancel,
      }}
      onCancel={onCancel}
    >
      <Form form={form}>
        <Tabs
          defaultActiveKey={tabVisible}
          onChange={(key: "private" | "shared") => setTabVisible(key)}
        >
          <TabPane
            tab={
              <K2Message
                localeKey={`admin.communication.${pageType}s.mediaAssets.uploadNew`}
              />
            }
            key={"private"}
          >
            <p>
              <K2Message
                localeKey={`admin.communication.${pageType}s.mediaAssets.thisOneOnly`}
              />
            </p>

            <GeneralFormItemV4
              wrapperCol={{
                xs: 24,
              }}
              labelCol={{
                xs: 24,
              }}
            >
              <GeneralFormItemV4
                valuePropName="fileList"
                wrapperCol={{
                  xs: 24,
                }}
                labelCol={{
                  xs: 24,
                }}
              >
                <Dragger
                  name={PartialOrLayoutHelpers.newlyUploadedAssetsFieldName}
                  disabled={maxItemsAlreadySelected}
                  multiple={maxAssets > 1}
                  customRequest={upload}
                  onRemove={removeFile}
                  fileList={privateFileList}
                  accept={assetTypes
                    .reduce((acc, e) => acc.concat(e.allowedExtensions), [])
                    .join(",")}
                >
                  {maxAssets > 1 ? (
                    <MultiFileUploadExplanation />
                  ) : (
                    <SingleFileUploadExplanation />
                  )}
                </Dragger>
              </GeneralFormItemV4>

              <Alert
                style={{ marginTop: 20 }}
                type={"info"}
                showIcon={true}
                message={k2Intl?.formatMessage({
                  localeKey: "admin.communication.assets.allowedTypesTitle",
                })}
                description={
                  <FileTypeList>
                    {assetTypes?.map?.((type) => (
                      <li key={type.id}>
                        <strong>{type.name}</strong> (
                        {k2Intl?.formatMessage({ localeKey: "common.maxSize" })}
                        : {type.maxSize.text})
                      </li>
                    ))}
                  </FileTypeList>
                }
              />
            </GeneralFormItemV4>
          </TabPane>
          <TabPane
            tab={<K2Message localeKey={"admin.communication.assets.title"} />}
            key={"shared"}
          >
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={selectedExistingAssets.length ? 12 : 24}>
                {selectedExistingAssets.length ? (
                  <K2Message
                    localeKey={`admin.communication.mediaAssets.shared.count`}
                    values={{ count: selectedExistingAssets.length }}
                  />
                ) : (
                  <K2Message
                    localeKey={`admin.communication.mediaAssets.shared`}
                  />
                )}
              </Col>
              {selectedExistingAssets.length ? (
                <Col xs={24} sm={12} style={{ textAlign: "right" }}>
                  <FakeLink
                    onClick={() => {
                      setSelectedExistingAssets([]);
                      form.setFields([
                        {
                          name: PartialOrLayoutHelpers.existingAssetsFieldName,
                          value: [],
                        },
                      ]);
                    }}
                  >
                    <K2Message localeKey={`common.clearSelection`} />
                  </FakeLink>
                </Col>
              ) : null}
            </Row>

            <StyledFormItem
              wrapperCol={{
                xs: 24,
              }}
              labelCol={{
                xs: 24,
              }}
              name={PartialOrLayoutHelpers.existingAssetsFieldName}
            >
              <SharedAssetsSelectList
                previouslySelectedAssets={currentlySelectedAssets}
                currentlySelectedAssets={[...selectedExistingAssets]}
                onValueChange={(assetRes: AssetRes[]) => {
                  setDirty(true);
                  setSelectedExistingAssets(assetRes);
                }}
                assetType={assetType}
                maxAssets={maxAssets}
              />
            </StyledFormItem>

            <Divider />

            <Title>
              <K2Message
                localeKey={"admin.communication.mediaAssets.shared.uploadNew"}
              />
            </Title>
            <p>
              <K2Message
                localeKey={"admin.communication.mediaAssets.shared.explanation"}
              />
            </p>

            <GeneralFormItemV4
              wrapperCol={{
                xs: 24,
              }}
              labelCol={{
                xs: 24,
              }}
            >
              <GeneralFormItemV4
                valuePropName="fileList"
                wrapperCol={{
                  xs: 24,
                }}
                labelCol={{
                  xs: 24,
                }}
              >
                <Dragger
                  name={
                    PartialOrLayoutHelpers.newlyUploadedSharedAssetsFieldName
                  }
                  disabled={maxItemsAlreadySelected}
                  multiple={maxAssets > 1}
                  customRequest={upload}
                  onRemove={removeFile}
                  fileList={sharedFileList}
                  accept={assetTypes
                    .reduce((acc, e) => acc.concat(e.allowedExtensions), [])
                    .join(",")}
                >
                  {maxAssets > 1 ? (
                    <MultiFileUploadExplanation />
                  ) : (
                    <SingleFileUploadExplanation />
                  )}
                </Dragger>
              </GeneralFormItemV4>
            </GeneralFormItemV4>
          </TabPane>
        </Tabs>
      </Form>
    </K2Modal>
  );
};

export default injectK2Intl(AssetModal);
