import {
  FragmentRes,
  FragmentType,
} from "@n3oltd/karakoram.templates.sdk.fragments/esm";

export default class PartialOrLayoutHelpers {
  public static getNameFieldName = (type: FragmentType) => [
    type,
    "template",
    "name",
  ];

  public static getNotesFieldName = (type: FragmentType) => [
    type,
    "template",
    "notes",
  ];

  public static getModelTypeFieldName = (type: FragmentType) => [
    type,
    "template",
    "modelType",
  ];

  public static getContentFieldName = (type: FragmentType) => [
    type,
    "template",
    "content",
    "markup",
  ];

  public static getStylesheetAssetsFieldName = (type: FragmentType) => [
    type,
    "template",
    "stylesheetAssetIds",
  ];

  public static getAliasFieldName = () => [
    "partial",
    "alias",
  ];

  public static newlyUploadedAssetsFieldName = "newFileUpload";
  public static newlyUploadedSharedAssetsFieldName = "newSharedFileUpload";
  public static existingAssetsFieldName = "existingAssets";
}
