import React, { FC } from "react";
import { K2Message } from "components/k2Widgets";
import K2Button from "components/k2Widgets/k2Buttons/K2Button";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { Result } from "antd";
import { N3oIcon } from "components/n3oIcon";
import * as queryString from "querystring";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import { useRouter } from "hooks";

const NewVersion: FC = () => {
  const { appId } = useAppContext();

  const router = useRouter();
  const search = router.location.search?.slice?.(1);

  let originalLocation =
    appId === "k2" ? K2Routes.main.dashboard : Q1Routes.main.q1Dashboard;

  if (search) {
    const parsed = queryString.parse(search);
    if (parsed?.fromLocation) {
      originalLocation = parsed.fromLocation as string;
    }
  }

  return (
    <Result
      icon={<N3oIcon icontype={"n3o-app-suite"} height={50} width={50} />}
      style={{ textAlign: "center" }}
      title={<K2Message localeKey="extraPages.newAppVersionAvailable" />}
      extra={
        <K2Button onClick={() => window.location.replace(originalLocation)}>
          <K2Message localeKey="common.reloadPage" />
        </K2Button>
      }
    />
  );
};

export default NewVersion;
