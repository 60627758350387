import { connect } from "react-redux";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import reducer from "./modules/reducer";
import saga from "./modules/saga";

import { GettingStartedState } from "./modules/types";
import {
  fetchGettingStartedProgress,
  completeGettingStarted,
} from "./modules/actions";

import GettingStartedAndRecentActivities from "./gettingStartedAndRecentActivities";
import IApplicationState from "appRedux/types";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

const mapStateToProps = (state: IApplicationState): GettingStartedState => {
  const {
    fetching,
    currentStep,
    totalSteps,
    completedSteps,
    image,
    isAllCompleted,
  }: GettingStartedState = state.gettingStarted;

  return {
    fetching,
    currentStep,
    totalSteps,
    completedSteps,
    image,
    isAllCompleted,
  };
};

const mapDispatchToProps = {
  fetchGettingStartedProgress,
  completeGettingStarted,
};

const withSaga = injectSaga({ key: "gettingStarted", saga });
const withReducer = injectReducer({ key: "gettingStarted", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withSaga,
  withReducer,
  withConnect,
)(injectK2Intl(GettingStartedAndRecentActivities));
