import {
  PdfCompositionReq,
  PdfCompositionRes,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";
import { RcFile } from "antd/lib/upload";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";
import { TemporaryUploadFile } from "routes/admin/communication/modules/types";

export enum ActionTypes {
  FETCH_PDF_TEMPLATE = "@engage/admin/communication/pdf-template/request",
  FETCH_PDF_TEMPLATE_SUCCESS = "@engage/admin/communication/pdf-template/success",
  FETCH_PDF_TEMPLATE_ERROR = "@engage/admin/communication/pdf-template/error",
  CLEAR_PDF_TEMPLATE = "@engage/admin/communication/pdf-template/clear",
  CLEAR_PDF_TEMPLATE_ERROR = "@engage/admin/communication/pdf-template/clear-error",

  UPDATE_PDF_TEMPLATE = "@engage/admin/communication/pdf-template/update/request",
  UPDATE_PDF_TEMPLATE_SUCCESS = "@engage/admin/communication/pdf-template/update/success",
  UPDATE_PDF_TEMPLATE_ERROR = "@engage/admin/communication/pdf-template/update/error",

  DELETE_PDF_TEMPLATE = "@engage/admin/communication/pdf-template/delete/request",
  DELETE_PDF_TEMPLATE_SUCCESS = "@engage/admin/communication/pdf-template/delete/success",
  DELETE_PDF_TEMPLATE_ERROR = "@engage/admin/communication/pdf-template/delete/error",

  CREATE_PDF_TEMPLATE = "@engage/admin/communication/pdf-template/create/request",
  CREATE_PDF_TEMPLATE_SUCCESS = "@engage/admin/communication/pdf-template/create/success",
  CREATE_PDF_TEMPLATE_ERROR = "@engage/admin/communication/pdf-template/create/error",

  CLEAR_PDF_TEMPLATE_EDIT_STATUS = "@engage/admin/communication/pdf-template/clear-edit-status",

  UPLOAD_ASSET = "@engage/admin/communication/pdf-template/upload-asset",
  REMOVE_TEMPORARY_ASSET = "@engage/admin/communication/pdf-template/remove-asset",
  UPDATE_TEMPORARY_ASSETS = "@engage/admin/communication/pdf-template/update-temp-assets",
  CLEAR_TEMPORARY_ASSETS = "@engage/admin/communication/pdf-template/clear-temp-assets",

  UPLOAD_EPILOGUE_OR_PROLOGUE = "@engage/admin/communication/pdf-template/upload-epilogue-prologue",
  UPDATE_EPILOGUE_OR_PROLOGUE = "@engage/admin/communication/pdf-template/update-epilogue-prologue",
  REMOVE_EPILOGUE_OR_PROLOGUE = "@engage/admin/communication/pdf-template/remove-epilogue-prologue",
  CLEAR_EPILOGUE_OR_PROLOGUE = "@engage/admin/communication/pdf-template/clear-epilogue-prologue",

  UPDATE_DIRTY = "@engage/admin/communication/pdf-template/update-dirty",
}

export interface FetchPdfTemplate {
  type: ActionTypes.FETCH_PDF_TEMPLATE;
  id: string;
}

export interface FetchPdfTemplateSuccess {
  type: ActionTypes.FETCH_PDF_TEMPLATE_SUCCESS;
  data: PdfCompositionRes;
}

export interface FetchPdfTemplateError {
  type: ActionTypes.FETCH_PDF_TEMPLATE_ERROR;
  error: ServerError;
}

export interface ClearPdfTemplateError {
  type: ActionTypes.CLEAR_PDF_TEMPLATE_ERROR;
}

export interface ClearPdfTemplate {
  type: ActionTypes.CLEAR_PDF_TEMPLATE;
}

export interface UpdatePdfTemplate {
  type: ActionTypes.UPDATE_PDF_TEMPLATE;
  revisionId: string;
  data: PdfCompositionReq;
  scopeId?: string;
  ignoreWarning?: boolean;
}

export interface UpdatePdfTemplateSuccess {
  type: ActionTypes.UPDATE_PDF_TEMPLATE_SUCCESS;
  editStatus: EditStatus<void>;
}

export interface UpdatePdfTemplateError {
  type: ActionTypes.UPDATE_PDF_TEMPLATE_ERROR;
  editStatus: EditStatus<void>;
}

export interface DeletePdfTemplate {
  type: ActionTypes.DELETE_PDF_TEMPLATE;
  revisionId: string;
}

export interface DeletePdfTemplateSuccess {
  type: ActionTypes.DELETE_PDF_TEMPLATE_SUCCESS;
  editStatus: EditStatus<void>;
}

export interface DeletePdfTemplateError {
  type: ActionTypes.DELETE_PDF_TEMPLATE_ERROR;
  editStatus: EditStatus<void>;
}

export interface CreatePdfTemplate {
  type: ActionTypes.CREATE_PDF_TEMPLATE;
  data: PdfCompositionReq;
  scopeId?: string;
  ignoreWarning?: boolean;
}

export interface CreatePdfTemplateSuccess {
  type: ActionTypes.CREATE_PDF_TEMPLATE_SUCCESS;
  editStatus: EditStatus;
}

export interface CreatePdfTemplateError {
  type: ActionTypes.CREATE_PDF_TEMPLATE_ERROR;
  editStatus: EditStatus;
}

export interface ClearEditStatus {
  type: ActionTypes.CLEAR_PDF_TEMPLATE_EDIT_STATUS;
}

export interface UploadEpilogueOrPrologue {
  type: ActionTypes.UPLOAD_EPILOGUE_OR_PROLOGUE;
  logueType: EpiloguePrologueType;
  upload: TemporaryUploadFile;
  originalFile: RcFile;
}

export interface UpdateEpilogueOrPrologue {
  type: ActionTypes.UPDATE_EPILOGUE_OR_PROLOGUE;
  logueType: EpiloguePrologueType;
  upload: TemporaryUploadFile;
}

export interface RemoveEpilogueOrPrologue {
  type: ActionTypes.REMOVE_EPILOGUE_OR_PROLOGUE;
  logueType: EpiloguePrologueType;
  temporaryAsset: TemporaryUploadFile;
}

export interface ClearEpilogueOrPrologue {
  type: ActionTypes.CLEAR_EPILOGUE_OR_PROLOGUE;
  logueType: EpiloguePrologueType;
}

export interface UploadAsset {
  type: ActionTypes.UPLOAD_ASSET;
  upload: TemporaryUploadFile;
  originalFile: RcFile;
}

export interface UpdateTemporaryAssets {
  type: ActionTypes.UPDATE_TEMPORARY_ASSETS;
  upload: TemporaryUploadFile;
}

export interface RemoveTemporaryAsset {
  type: ActionTypes.REMOVE_TEMPORARY_ASSET;
  temporaryAsset: TemporaryUploadFile;
}

export interface ClearTemporaryAssets {
  type: ActionTypes.CLEAR_TEMPORARY_ASSETS;
}

export interface UpdateDirty {
  type: ActionTypes.UPDATE_DIRTY;
  dirty: boolean;
}

export type PdfTemplateAction =
  | FetchPdfTemplate
  | FetchPdfTemplateError
  | FetchPdfTemplateSuccess
  | ClearPdfTemplate
  | ClearPdfTemplateError
  | ClearEditStatus
  | CreatePdfTemplate
  | CreatePdfTemplateError
  | CreatePdfTemplateSuccess
  | UpdatePdfTemplate
  | UpdatePdfTemplateError
  | UpdatePdfTemplateSuccess
  | DeletePdfTemplate
  | DeletePdfTemplateError
  | DeletePdfTemplateSuccess
  | UploadEpilogueOrPrologue
  | UpdateEpilogueOrPrologue
  | RemoveEpilogueOrPrologue
  | ClearEpilogueOrPrologue
  | UploadAsset
  | UpdateTemporaryAssets
  | RemoveTemporaryAsset
  | ClearTemporaryAssets
  | UpdateDirty;

export type EpiloguePrologueType = "epilogue" | "prologue";
