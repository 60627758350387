import { createSelector } from "reselect";
import * as lookupActions from "../../modules/lookups/actions";
import { Lookups } from "../../modules/lookups/types";
import IApplicationState from "../../types";
const { fetchLookups, clearError } = lookupActions;

export default class LookupsSelectors {
  public static selectLookups = ({ lookups }: IApplicationState) => lookups;

  public static getInjectLookupsSelector() {
    return createSelector(
      this.selectLookups,
      (lookups): IInjectLookupsSelectorProps => {
        return { lookups };
      },
    );
  }

  public static getInjectLookupsDispatchers(): IInjectLookupsDispatcherProps {
    return { fetchLookups, clearLookupError: clearError };
  }
}

type IInjectLookupsSelectorProps = {
  lookups: Lookups;
};

type IInjectLookupsDispatcherProps = {
  fetchLookups: typeof fetchLookups;
  clearLookupError: typeof clearError;
};

export type IInjectLookupsStateProps = IInjectLookupsSelectorProps &
  IInjectLookupsDispatcherProps;
