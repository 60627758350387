import {
  DashboardActivityLogCriteria,
  DashboardActivityLogResultsList,
} from "@n3oltd/karakoram.activities.sdk.activities/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import {
  ActionTypes,
  ActivitiesAction,
} from "appRedux/modules/recentActivities/actionTypes";

export const fetchDashboardActivities = (
  criteria: DashboardActivityLogCriteria,
): ActivitiesAction => ({
  type: ActionTypes.FETCH_DASHBOARD_ACTIVITIES,
  criteria,
});

export const fetchDashboardActivitiesSuccess = (
  data: DashboardActivityLogResultsList,
): ActivitiesAction => ({
  type: ActionTypes.FETCH_DASHBOARD_ACTIVITIES_SUCCESS,
  data,
});

export const fetchDashboardActivitiesError = (
  error: ServerError,
): ActivitiesAction => ({
  type: ActionTypes.FETCH_DASHBOARD_ACTIVITIES_ERROR,
  error,
});

export const clearError = () => ({
  type: ActionTypes.CLEAR_ERROR,
});
