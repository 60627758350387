import React, { FC, useCallback } from "react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import { InjectedK2IntlProps } from "../k2Localizations/types";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

export interface K2InputProps extends InputProps, InjectedK2IntlProps {
  /**when user changes the value control */
  onAfterChange?: any;

  /**placeholder key to localize */
  placeholderKey?: any;
}

const K2InputV4: FC<K2InputProps> = (props) => {
  const {
    className,
    placeholder,
    placeholderKey,
    onChange,
    onAfterChange,
    k2Intl,
    localizationSettings,
    ...rest
  } = props;

  const onChangeHandler = useCallback(
    (e) => {
      onChange?.(e);
      onAfterChange?.(e);
    },
    [onAfterChange, onChange],
  );

  const placeholderText = placeholderKey
    ? k2Intl?.formatMessage({ localeKey: placeholderKey })
    : placeholder;

  return (
    <Input
      className={`k2-input ${className}`}
      {...rest}
      onChange={onChangeHandler}
      autoComplete="off"
      placeholder={placeholderText}
    />
  );
};

export default injectK2Intl(K2InputV4);
