import {
  ReleaseNotesRes,
  SubscriptionRes,
} from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import {
  CurrentUserRes,
  SubscriptionProfile,
  UserRes,
  UserSubscriptionsRes,
} from "@n3oltd/k2.users.sdk.users/esm";
import { RouteIdResultsList } from "@n3oltd/karakoram.navigation.sdk.routes/esm";
import { ImmerReducer } from "immer-reducer";
import _ from "lodash";

import LocaleHelpers from "common/helpers/locales";
import staticSubscriptionUserRoutesState from "samples/data/sample_routes_list.json";
import staticK2SubscriptionState from "samples/data/sample_subscription.json";
import staticK2UserSubscriptionsState from "samples/data/sample_subscriptions.json";
import staticK2UserState from "samples/data/sample_user.json";

import { IApiResponse } from "../../../models/common/ApiResponseModel";
import { IUsersManagement } from "./types";

export const usersInitialState: IUsersManagement = {
  areResourcesLoading: true,
  k2User: null,
  k2Subscription: null,
  currentUserLocalizationSettings: null,
  currentUserSubscriptions: null,

  isRoutesLoading: true,
  k2UserRoutes: {
    items: [],
  },

  isReleaseNotesLoading: true,
  k2SubscriptionReleaseNotes: null,

  isLoading: true,

  editStatus: null,
  serverError: null,
  releaseNotesServerError: null,
};

export class SubscriptionUsersReducer extends ImmerReducer<IUsersManagement> {
  generalLoading(loading: boolean) {
    this.draftState.isLoading = loading;
  }

  //#region USER SUBSCRIPTIONS

  getCurrentUserSubscriptions() {
    this.generalLoading(true);
  }

  setCurrentUserSubscriptions(response: IApiResponse<UserSubscriptionsRes>) {
    const result = response.getResultOrDefault();
    this.draftState.currentUserSubscriptions = result
      ? result.subscriptions
      : [];

    if (!_.isNull(response.error)) {
      this.draftState.serverError = response.error;
    }

    this.generalLoading(false);
  }

  setStaticK2UserSubscriptions() {
    this.draftState.currentUserSubscriptions = staticK2UserSubscriptionsState.subscriptions as SubscriptionProfile[];
  }

  //#endregion

  //#region USER & SUBSCRIPTION

  resourcesLoading(loading: boolean) {
    this.draftState.areResourcesLoading = loading;
  }

  getAppResources() {
    this.resourcesLoading(true);
  }

  setCurrentUser(response: IApiResponse<CurrentUserRes>) {
    this.draftState.k2User = response.getResultOrDefault();
    if (!_.isNull(response.error)) {
      this.draftState.serverError = response.error;
    }
  }

  getCurrentSubscription() {
    this.resourcesLoading(true);
  }

  setCurrentK2Subscription(response: IApiResponse<SubscriptionRes>) {
    this.draftState.k2Subscription = response.getResultOrDefault();
    if (!_.isNull(response.error)) {
      this.draftState.serverError = response.error;
    }
  }

  setStaticK2User() {
    this.draftState.k2User = staticK2UserState as UserRes;
  }

  setStaticK2Subscription() {
    this.draftState.k2Subscription = staticK2SubscriptionState as SubscriptionRes;
  }

  /* For setting Current User Localization
   *
   * Priority here is to first load the user localization
   * then get it from subscription
   *
   * so here we are first getting values from subscription
   * then overriding / merging them from user
   *
   * only the new values or missing values will be taken from user
   */
  setCurrentUserLocalizationSettings() {
    this.draftState.currentUserLocalizationSettings = LocaleHelpers.combineLocalization(
      this.draftState.k2User?.localization,
      this.draftState.k2Subscription?.localization,
    );
  }

  //#endregion

  //#region ROUTES

  routesLoading(loading: boolean) {
    this.draftState.isRoutesLoading = loading;
  }

  getSubscriptionUserRoutes(_forceCacheRefresh?: boolean) {
    this.routesLoading(true);
  }

  setStaticSubscriptionUserRoutes() {
    this.draftState.k2UserRoutes = staticSubscriptionUserRoutesState as RouteIdResultsList;
  }

  setSubscriptionUserRoutes(response: IApiResponse<RouteIdResultsList>) {
    this.draftState.k2UserRoutes = response.getResultOrDefault();

    if (!_.isNull(response.error)) {
      this.draftState.serverError = response.error;
    }
  }

  //#endregion

  //#region RELEASE NOTES

  releaseNotesLoading(loading: boolean) {
    this.draftState.isReleaseNotesLoading = loading;
  }

  getCurrentSubscriptionRelease() {
    this.releaseNotesLoading(true);
  }

  setCurrentSubscriptionRelease(response: IApiResponse<ReleaseNotesRes>) {
    this.draftState.k2SubscriptionReleaseNotes = response.getResultOrDefault();

    if (!_.isNull(response.error)) {
      this.draftState.releaseNotesServerError = response.error;
    }

    this.releaseNotesLoading(false);
  }

  //#endregion

  clearServerError() {
    this.draftState.serverError = null;
  }
}
