import { connect } from "react-redux";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import DateFormatProvider from "appRedux/models/localization/DateFormatProvider";
import { LinkedEntityTypesLookups } from "appRedux/modules/lookups/types";
import { dashboardTasksActions } from "appRedux/modules/tasks";
import { Attachment } from "appRedux/modules/tasks/types";
import IApplicationState from "appRedux/types";
import * as actions from "components/dashboard/newTask/modules/actions";
import reducer from "components/dashboard/newTask/modules/reducers";
import saga from "components/dashboard/newTask/modules/sagas";
import { NewTaskState } from "components/dashboard/newTask/modules/types";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

export interface INewTaskState extends NewTaskState {
  taskCreationServerError?: ServerError;
  isCreatingTask: boolean;
  dateFormat?: string;
  sponsorshipId?: string;
  pledgeId?: string;
  donationId?: string;
  givingId?: string;
  feedbackId?: string;
}

const mapStateToProps = (state: IApplicationState): INewTaskState => {
  return {
    noteAttachments: state.newTask.noteAttachments,
    taskCreationServerError: state.tasks.dashboardTasks.taskCreationServerError,
    isCreatingTask: state.tasks.dashboardTasks.isCreatingTask,
    dateFormat: state.subscription?.users?.currentUserLocalizationSettings
      ?.dateFormat
      ? DateFormatProvider.getConfig(
          state.subscription.users.currentUserLocalizationSettings.dateFormat,
        ).format
      : null,
    formTouched: state.newTask.formTouched,
  };
};

interface INewTaskDispatchProps {
  uploadAttachment: typeof actions.uploadAttachment;
  deleteAttachment: typeof actions.deleteAttachment;
  deleteAllAttachments: typeof actions.deleteAllAttachments;
  clearTaskCreationServerError: typeof dashboardTasksActions.clearTaskCreationServerError;
  createTask: typeof dashboardTasksActions.createTask;
  setFormTouched: typeof actions.setFormTouched;
  clearFormTouched: typeof actions.clearFormTouched;
}

const mapDispatchToProps: INewTaskDispatchProps = {
  uploadAttachment: actions.uploadAttachment,
  deleteAttachment: actions.deleteAttachment,
  deleteAllAttachments: actions.deleteAllAttachments,
  clearTaskCreationServerError:
    dashboardTasksActions.clearTaskCreationServerError,
  createTask: dashboardTasksActions.createTask,
  setFormTouched: actions.setFormTouched,
  clearFormTouched: actions.clearFormTouched,
};

export interface ICreateTaskFormFormProps
  extends INewTaskState,
    INewTaskDispatchProps,
    InjectedK2IntlProps {
  onSuccess?: () => void;
  onCancel: () => void;
  visible: boolean;
  noteAttachments: Attachment[];
  linkedEntityTypes: LinkedEntityTypesLookups;
}

const withSaga = injectSaga({ key: "newTask", saga });
const withReducer = injectReducer({ key: "newTask", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withSaga, withReducer, withConnect);
