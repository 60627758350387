import React, { PropsWithChildren } from "react";

import { Cascader, CascaderProps } from "antd";
import { CascaderOptionType } from "antd/lib/cascader";
import _ from "lodash";

// todo: we don't have option to load more in case of Cascader.
interface k2CascaderProps extends CascaderProps {}

const k2Cascader = (props: PropsWithChildren<k2CascaderProps>) => {
  const { options, onChange, showSearch, ...rest } = props;

  const filter = (inputValue: string, path: CascaderOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1,
    );

  return (
    <Cascader
      options={options}
      popupClassName="custom-scrollbar-style"
      showSearch={showSearch ? { filter } : false}
      onChange={onChange}
      getPopupContainer={(trigger: HTMLElement) => {
        const element = document.getElementById("route-section");
        return !_.isNull(element)
          ? element
          : (trigger.parentNode as HTMLElement);
      }}
      {...rest}
    />
  );
};

export default k2Cascader;
