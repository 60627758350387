import React, { FC } from "react";

import { ReportCategory as ReportCategoryType } from "@n3oltd/karakoram.analytics.sdk.reports/esm";

import { K2Routes } from "appRedux/models/routes/RouteModel";

import { K2Card, K2Link, K2Message } from "../../k2Widgets";
import { N3oIcon } from "../../n3oIcon";
import { IN3OIconType } from "../../n3oIcon/types";

const { reports } = K2Routes;

interface IProps {
  category: ReportCategoryType;
  iconType: IN3OIconType;
  titleLocaleKey: string;
}

const ReportCategory: FC<IProps> = (props) => {
  const { category, iconType, titleLocaleKey } = props;

  return (
    <K2Link to={reports.replace(":category", category)}>
      <K2Card
        className="ant-card-hoverable n3o-mb-0"
        style={{
          textAlign: "center",
        }}
      >
        <N3oIcon
          icontype={iconType}
          width="30px"
          height="30px"
          style={{ paddingLeft: "6px" }}
        />

        <h4 className="n3o-mt-2">
          <K2Message localeKey={titleLocaleKey} />
        </h4>
      </K2Card>
    </K2Link>
  );
};

export default ReportCategory;
