import React, { FC, useEffect, useMemo, useState } from "react";

import { PlusOutlined } from "@ant-design/icons/lib";
import { PricingRuleReq } from "@n3oltd/karakoram.sponsorships.beneficiarytypes.child.sdk.child/esm";
import { Col, Form, Row } from "antd";
import _ from "lodash";

import { K2Button } from "components/k2Widgets";
import SchemeFormHelpers from "routes/admin/sponsorships/schemes/helpers";

import { IPricingRulesProps } from "../../modules/types";
import { RuleCardWrapper } from "../styled";
import PricingRuleBody from "./pricingRuleBody";

const PricingRules: FC<IPricingRulesProps> = (props) => {
  const { componentFieldIndex, form, editing, rulesFieldValue } = props;
  const { setFields } = form;

  const [dataAdded, setDataAdded] = useState<boolean>(false);

  const defaultRuleData: PricingRuleReq = useMemo(() => {
    return {
      price: {
        locked: false,
      },
    };
  }, []);

  const rulesFieldName = SchemeFormHelpers.getComponentRulesFieldName(
    componentFieldIndex,
  );

  useEffect(() => {
    if (!editing) {
      setFields([
        {
          name: rulesFieldName,
          value: defaultRuleData,
        },
      ]);
    }
  }, [
    defaultRuleData,
    componentFieldIndex,
    editing,
    rulesFieldName,
    setFields,
  ]);

  return (
    <Row gutter={[12, 18]} className={"n3o-mt-2 n3o-mb-0"}>
      <Col span={24}>
        <Form.List
          name={SchemeFormHelpers.getComponentRulesFieldName(
            componentFieldIndex,
          )}
        >
          {(ruleFields, { add: addRule, remove: removeRule }) => {
            const moreThen1Rule = ruleFields.length > 1;

            if (editing && !dataAdded) {
              if (_.isNil(rulesFieldValue) || _.isEmpty(rulesFieldValue)) {
                addRule(defaultRuleData);
              }
              setDataAdded(true);
            }

            return (
              <>
                {ruleFields?.map?.((field) => {
                  return (
                    <RuleCardWrapper
                      key={
                        "PricingRule_" + componentFieldIndex + "_" + field.name
                      }
                      size="small"
                      bordered={true}
                      className={"n3o-mb-4"}
                    >
                      <PricingRuleBody
                        {...props}
                        fieldIndex={field.name}
                        moreThen1Rule={moreThen1Rule}
                        removeRule={() => removeRule(field.name)}
                      />
                    </RuleCardWrapper>
                  );
                })}
                <K2Button
                  type="link"
                  onClick={() => addRule(defaultRuleData)}
                  htmlType="button"
                  localeKey={"common.addPrice"}
                  icon={<PlusOutlined />}
                  className={"n3o-pl-0"}
                />
              </>
            );
          }}
        </Form.List>
      </Col>
    </Row>
  );
};

export default PricingRules;
