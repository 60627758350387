import React, { FC, useCallback } from "react";

import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { Col, Descriptions, Menu, Row, Skeleton, Tag, Typography } from "antd";
import { useRouter } from "hooks";
import _ from "lodash";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import AppManager from "appRedux/AppManager";
import AuthManager from "appRedux/models/auth/AuthManager";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import { K2Routes } from "appRedux/models/routes/RouteModel";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2DateTime, K2Message } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { showConfirm } from "components/utils/Confirmation";

const Label = styled.span`
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};
`;

const DescriptionContent = styled.span`
  display: inline-block;
  white-space: normal;
  font-size: 12px;
  padding-right: 7px;
`;

const UserRole = styled.div`
  white-space: pre-wrap;
  width: 320px;
`;

const UserTimestamp = styled.div`
  width: 320px;
`;

const ColWithBorder = styled(Col)`
  white-space: initial;

  && {
    font-weight: ${({ theme }) => theme.font_weight_bold};
    border-right: 1px solid ${({ theme }) => theme.grey_5};
  }
`;

const NameAndSubscriptionRow = styled(Row)`
  && {
    color: ${({ theme }) => theme.text_color};
    padding: 15px 0;
  }
`;

interface IProps extends InjectedK2IntlProps {
  user: CurrentUserRes;
  menuClick: Function;
}

const UserDropdownMenu: FC<IProps> = ({ user, k2Intl, menuClick }) => {
  const { appId, k2User } = useAppContext();
  const history = useHistory();

  const timezones = useSelector(
    (state: IApplicationState) => state.lookups.timezones,
  );
  const languages = useSelector(
    (state: IApplicationState) => state.lookups.languages,
  );
  const currentUserLocalization = useSelector(
    (state: IApplicationState) =>
      state.subscription.users.currentUserLocalizationSettings,
  );

  const currentSubscription = useSelector(
    (state: IApplicationState) => state.subscription.users.k2Subscription,
  );

  const allUserSubscriptions = useSelector(
    (state: IApplicationState) =>
      state.subscription.users.currentUserSubscriptions,
  );

  const [cookies, __, removeCookie] = useCookies();

  const lookupsLoading =
    timezones.items.length === 0 || languages.items.length === 0;

  const isLoading = lookupsLoading || _.isNull(currentUserLocalization);

  const takeToLogout = () => history.push(K2Routes.logout);

  const takeToPreferences = () =>
    history.push(
      appId === "k2" ? K2Routes.preferences : Q1Routes.q1Preferences,
    );

  const confirmChangeSubscription = useCallback(() => {
    showConfirm({
      titleKey: "userPicker.ChangeSub.confirm",
      onOk: () => {
        removeCookie(AppManager.DefaultLocaleKey, {
          path: AuthManager.getCookieSetOptions().path,
          domain: AuthManager.getCookieSetOptions().domain,
        });
        removeCookie(AuthManager.keys.cookies.subscriptionId, {
          path: AuthManager.getCookieSetOptions().path,
          domain: AuthManager.getCookieSetOptions().domain,
        });

        // Because the SubscriptionID might have been set when the user was logged into
        // either Prod/QA application (i.e. with or without beta. at the beginning of the domain,
        // We don't know which, so we remove cookies from either possible domain.
        removeCookie(AppManager.DefaultLocaleKey, {
          path: AuthManager.getCookieSetOptions().path,
          domain: AuthManager.getCookieSetOptions().prodDomain,
        });
        removeCookie(AuthManager.keys.cookies.subscriptionId, {
          path: AuthManager.getCookieSetOptions().path,
          domain: AuthManager.getCookieSetOptions().prodDomain,
        });

        window.location.reload();
      },
      okTextKey: "common.yes",
      k2Intl,
    });
  }, [k2Intl, removeCookie]);

  return (
    <Menu prefixCls={"ant-dropdown-menu"} selectedKeys={[]}>
      <Menu.Item disabled>
        <NameAndSubscriptionRow>
          <ColWithBorder xs={24} sm={24} md={10}>
            {user.fullName}
          </ColWithBorder>
          <Col xs={24} sm={24} md={14} style={{ paddingLeft: 10 }}>
            <Typography.Text ellipsis style={{ display: "block" }}>
              {currentSubscription.name}
            </Typography.Text>
            {allUserSubscriptions.length > 1 && (
              <FakeLink
                onClick={() => {
                  confirmChangeSubscription();
                  menuClick();
                }}
                style={{ fontSize: 11 }}
              >
                <K2Message localeKey={"userPicker.ChangeSub"} />
              </FakeLink>
            )}
          </Col>
        </NameAndSubscriptionRow>
      </Menu.Item>
      <Menu.Item disabled>
        <UserRole>
          {user.admin && (
            <Tag color="#f50" style={{ marginBottom: 5 }}>
              <K2Message localeKey={"userPicker.Administrator"} />
            </Tag>
          )}
          {user.roleProfiles?.map?.((profile) => (
            <Tag
              key={profile.principalId}
              color={profile.color}
              style={{ marginBottom: 5 }}
            >
              {profile.name}
            </Tag>
          ))}
        </UserRole>
      </Menu.Item>
      <Menu.Item disabled>
        <Skeleton loading={isLoading} paragraph={{ rows: 2 }}>
          <UserTimestamp>
            <Descriptions colon={false} title="" layout="vertical" column={2}>
              <Descriptions.Item
                label={
                  <Label>
                    <K2Message localeKey={"userPicker.LastLogin"} />
                  </Label>
                }
              >
                <DescriptionContent style={{ whiteSpace: "nowrap" }}>
                  <K2DateTime
                    value={user?.statistics?.lastLogin}
                    mode={"dateAndTime"}
                    timeZone={currentUserLocalization.timezone}
                    {...currentUserLocalization}
                  />
                </DescriptionContent>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Label>
                    <K2Message localeKey={"common.timezone"} />
                  </Label>
                }
              >
                <DescriptionContent>
                  {timezones.items.length > 0 &&
                    timezones.items.find(
                      (item) => item.id === currentUserLocalization.timezone,
                    ).name}
                </DescriptionContent>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Label>
                    <K2Message localeKey={"userPicker.Language"} />
                  </Label>
                }
              >
                <DescriptionContent>
                  {languages.items.length > 0 &&
                    languages.items.find(
                      (item) => item.id === currentUserLocalization.language,
                    ).name}
                </DescriptionContent>
              </Descriptions.Item>
            </Descriptions>
          </UserTimestamp>
        </Skeleton>
      </Menu.Item>

      <Menu.Divider />
      {appId === "k2" && !k2User.admin && (
        <Menu.Item
          key="0"
          onClick={() => {
            history.push(K2Routes.recentActivities);
          }}
        >
          <a>
            <K2Message localeKey={"userPicker.myActivity"} />
          </a>
        </Menu.Item>
      )}

      <Menu.Item
        key="1"
        onClick={() => {
          takeToPreferences();
          menuClick();
        }}
      >
        <a>
          <K2Message localeKey={"userPicker.Preferences"} />
        </a>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          takeToLogout();
          menuClick();
        }}
      >
        <a>
          <K2Message localeKey={"userPicker.Logout"} />
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default injectK2Intl(UserDropdownMenu);
