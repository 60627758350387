import React, { FC } from "react";
import { Result } from "antd";
import { K2Button, K2Message } from "components/k2Widgets";
import { ResultStatusType } from "antd/lib/result";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import { FormsWrapper } from "components/layout/wrappers";

const Error500: FC = () => {
  const { appId } = useAppContext();

  return (
    <FormsWrapper className={"n3o-m-0 n3o-py-5"}>
      <Result
        style={{ textAlign: "center" }}
        status={"500" as ResultStatusType}
        title="500"
        subTitle={<K2Message localeKey="extraPages.500Msg" />}
        extra={
          <K2Button
            toUrl={
              appId === "k2"
                ? K2Routes.main.dashboard
                : Q1Routes.main.q1Dashboard
            }
          >
            <K2Message localeKey="extraPages.goHome" />
          </K2Button>
        }
      />
    </FormsWrapper>
  );
};

export default Error500;
