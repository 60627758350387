import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const SponsorshipBeneficiary: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.86001 10.1611C7.91492 10.1306 7.97409 10.1058 8.03698 10.0876C8.20618 10.0385 8.37689 9.99615 8.54871 9.96042C7.87912 9.74189 7.26349 9.3677 6.75613 8.86035C5.90909 8.01331 5.43323 6.86447 5.43323 5.66658C5.43323 4.46868 5.90909 3.31985 6.75613 2.47281C7.60317 1.62576 8.752 1.1499 9.9499 1.1499C9.95827 1.1499 9.96663 1.14993 9.975 1.14997C9.98336 1.14993 9.99172 1.1499 10.0001 1.1499C11.198 1.1499 12.3468 1.62576 13.1939 2.47281C14.0409 3.31985 14.5168 4.46868 14.5168 5.66658C14.5168 6.86447 14.0409 8.01331 13.1939 8.86035C12.6865 9.3677 12.0709 9.74189 11.4013 9.96042C11.5731 9.99615 11.7438 10.0385 11.913 10.0876C11.9759 10.1058 12.0351 10.1306 12.09 10.1611C12.7963 10.3939 13.4622 10.7407 14.0603 11.1897C14.5018 11.5212 14.9002 11.9036 15.2477 12.3279C16 13 15 14.5 14.0085 13.5C13.7298 13.1429 13.4043 12.823 13.0397 12.5492C12.4005 12.0695 11.6586 11.7448 10.8725 11.601C10.5754 11.5466 10.2751 11.5187 9.975 11.5169C9.67488 11.5187 9.37455 11.5466 9.07746 11.601C8.29135 11.7448 7.54944 12.0695 6.91033 12.5492C6.27121 13.029 5.75239 13.6508 5.39482 14.3655C5.03726 15.0802 4.85075 15.8683 4.85002 16.6674C4.8496 17.1369 4.46869 17.5171 3.99925 17.5167C3.52981 17.5162 3.1496 17.1353 3.15002 16.6659C3.15099 15.6032 3.399 14.5553 3.87448 13.6049C4.34995 12.6545 5.03986 11.8277 5.88972 11.1897C6.48784 10.7407 7.15365 10.3939 7.86001 10.1611ZM9.975 8.48314C10.713 8.47657 11.4193 8.18058 11.9416 7.65827C12.4698 7.13004 12.7666 6.4136 12.7666 5.66658C12.7666 4.91955 12.4698 4.20312 11.9416 3.67489C11.4193 3.15258 10.713 2.85658 9.975 2.85001C9.23703 2.85658 8.53071 3.15258 8.0084 3.67489C7.48017 4.20312 7.18342 4.91955 7.18342 5.66658C7.18342 6.4136 7.48017 7.13004 8.0084 7.65827C8.53071 8.18058 9.23703 8.47657 9.975 8.48314Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.15 13.3499C20.6806 13.3499 20.3 13.7304 20.3 14.1999V14.8165H16.0167C15.0133 14.8165 14.051 15.2151 13.3414 15.9246C12.8359 16.4302 12.4882 17.0642 12.33 17.7499H9.41667C8.41326 17.7499 7.45096 18.1485 6.74145 18.858C6.03193 19.5675 5.63333 20.5298 5.63333 21.5332C5.63333 22.0026 6.01389 22.3832 6.48333 22.3832H20.3V22.9999C20.3 23.4693 20.6806 23.8499 21.15 23.8499C21.6194 23.8499 22 23.4693 22 22.9999V14.1999C22 13.7304 21.6194 13.3499 21.15 13.3499ZM20.3 16.5165V20.6832H7.51462C7.61739 20.4532 7.76197 20.2416 7.94353 20.06C8.33423 19.6693 8.86413 19.4499 9.41667 19.4499H16.0167C16.4861 19.4499 16.8667 19.0693 16.8667 18.5999C16.8667 18.1304 16.4861 17.7499 16.0167 17.7499H14.1146C14.2174 17.5199 14.362 17.3083 14.5435 17.1267C14.9342 16.736 15.4641 16.5165 16.0167 16.5165H20.3Z"
      fill={fill}
    />
  </svg>
);
