import React, { FC } from "react";

import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { Col, Popover, Row } from "antd";
import moment from "moment";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import DateHelpers from "common/helpers/dates";
import DeleteIcon from "components/deleteIcon";
import { K2Message, K2Tooltip } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";

interface CurrentAssetDisplayProps extends InjectedK2IntlProps {
  asset: AssetRes;
  onRemove: (assetId: string) => void;
  onDownload: (assetId: string, filename: string) => void;
}

const CurrentAssetDisplay: FC<CurrentAssetDisplayProps> = ({
  asset,
  onRemove,
  onDownload,
  k2Intl,
}) => {
  const { theme } = useAppContext();

  return (
    <div>
      {asset ? (
        <Row style={{ marginBottom: 20 }}>
          <Col span={17}>
            <FakeLink
              onClick={() => {
                onDownload(asset.id, asset.file?.name);
              }}
            >
              {asset.file.name}
            </FakeLink>
          </Col>
          <Col span={3}>{asset.file.size.text}</Col>
          {asset.notes ? (
            <Col span={1}>
              {asset.notes ? (
                <Col span={1}>
                  <K2Tooltip title={asset.notes}>
                    <N3oIcon
                      icontype="n3o-task-note"
                      width="16px"
                      height="16px"
                      fill={theme.icon_grey_light}
                    />
                  </K2Tooltip>
                </Col>
              ) : (
                <Col span={1} />
              )}
            </Col>
          ) : (
            <Col span={1} />
          )}
          <Col span={1}>
            <DeleteIcon onClick={() => onRemove(asset.id)} />
          </Col>
        </Row>
      ) : (
        <div style={{ marginBottom: 25 }}>
          <K2Message localeKey={"common.none"} />
        </div>
      )}
    </div>
  );
};

export default injectK2Intl(CurrentAssetDisplay);
