import {
  LetterheadReq,
  LetterheadRes,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";
import {
  ActionTypes,
  AddEditPdfStationeryAction,
} from "routes/admin/communication/newEditPdfStationery/modules/actionTypes";

export const fetchLetterhead = (id: string): AddEditPdfStationeryAction => ({
  type: ActionTypes.FETCH_LETTERHEAD,
  id,
});

export const fetchLetterheadSuccess = (
  data: LetterheadRes,
): AddEditPdfStationeryAction => ({
  type: ActionTypes.FETCH_LETTERHEAD_SUCCESS,
  data,
});

export const fetchLetterheadError = (
  error: ServerError,
): AddEditPdfStationeryAction => ({
  type: ActionTypes.FETCH_LETTERHEAD_ERROR,
  error,
});

export const clearLetterheadError = (): AddEditPdfStationeryAction => ({
  type: ActionTypes.CLEAR_LETTERHEAD_ERROR,
});

export const clearLetterhead = (): AddEditPdfStationeryAction => ({
  type: ActionTypes.CLEAR_LETTERHEAD,
});

export const updateLetterhead = (
  revisionId: string,
  data: LetterheadReq,
): AddEditPdfStationeryAction => ({
  type: ActionTypes.UPDATE_LETTERHEAD,
  revisionId,
  data,
});

export const updateLetterheadSuccess = (
  editStatus: EditStatus,
): AddEditPdfStationeryAction => ({
  type: ActionTypes.UPDATE_LETTERHEAD_SUCCESS,
  editStatus,
});

export const updateLetterheadError = (
  editStatus: EditStatus,
): AddEditPdfStationeryAction => ({
  type: ActionTypes.UPDATE_LETTERHEAD_ERROR,
  editStatus,
});

export const createLetterhead = (
  data: LetterheadReq,
): AddEditPdfStationeryAction => ({
  type: ActionTypes.CREATE_LETTERHEAD,
  data,
});

export const createLetterheadSuccess = (
  editStatus: EditStatus,
): AddEditPdfStationeryAction => ({
  type: ActionTypes.CREATE_LETTERHEAD_SUCCESS,
  editStatus,
});

export const createLetterheadError = (
  editStatus: EditStatus,
): AddEditPdfStationeryAction => ({
  type: ActionTypes.CREATE_LETTERHEAD_ERROR,
  editStatus,
});

export const clearEditStatus = (): AddEditPdfStationeryAction => ({
  type: ActionTypes.CLEAR_LETTERHEAD_EDIT_STATUS,
});

export const updateDirty = (dirty: boolean): AddEditPdfStationeryAction => ({
  type: ActionTypes.UPDATE_DIRTY,
  dirty,
});
