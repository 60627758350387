import produce from "immer";
import { ActionTypes, TaskDrawerAction } from "./actionTypes";
import { DrawerTasksState } from "./types";

const initialState: DrawerTasksState = {
  entityId: null,
  entityType: null,
  wrapperId: null,
  tasks: {
    items: [],
    continuationToken: "",
  },
  error: null,
};

const taskDrawerReducer = (
  state: DrawerTasksState = initialState,
  action: TaskDrawerAction,
): DrawerTasksState =>
  produce(state, (draft: DrawerTasksState) => {
    switch (action.type) {
      case ActionTypes.UPDATE_TASK_DRAWER_ENTITY_ID:
        draft.entityId = action.entityId;
        draft.tasks = initialState.tasks;
        break;
      case ActionTypes.UPDATE_TASK_DRAWER_ENTITY_TYPE:
        draft.entityType = action.entityType;
        draft.tasks = initialState.tasks;
        break;
      case ActionTypes.UPDATE_TASK_DRAWER_WRAPPER_ID:
        draft.wrapperId = action.wrapperId;
        draft.tasks = initialState.tasks;
        break;

      case ActionTypes.UPDATE_TASK_DRAWER_TASKS:
        draft.tasks = initialState.tasks;
        draft.error = null;
        break;
      case ActionTypes.UPDATE_TASK_DRAWER_TASKS_SUCCESS:
        draft.tasks = action.data;
        draft.error = null;
        break;
      case ActionTypes.UPDATE_TASK_DRAWER_TASKS_ERROR:
        draft.error = action.error;
        break;
      case ActionTypes.CLEAR_TASK_DRAWER_ERROR:
        draft.error = null;
        break;
    }
  });

export default taskDrawerReducer;
