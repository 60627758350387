import React, { FC } from "react";

import { QuestionCircleTwoTone } from "@ant-design/icons/lib";
import { RenderFunction } from "antd/lib/tooltip";

import { K2Tooltip } from "components/k2Widgets";

interface IProps {
  label: React.ReactNode;
  labelTooltipKey: string;
  overlayStyle?: React.CSSProperties;
  tooltip?: React.ReactNode | RenderFunction;
}

const LabelWithHelp: FC<IProps> = ({
  labelTooltipKey,
  label,
  overlayStyle,
  tooltip,
}) => {
  return (
    <>
      {label}
      {(labelTooltipKey || tooltip) && (
        <K2Tooltip
          placement={"rightTop"}
          titleKey={labelTooltipKey}
          overlayStyle={overlayStyle}
          title={tooltip}
        >
          <QuestionCircleTwoTone
            style={{ paddingLeft: 10 }}
            className={"n3o-pointer"}
          />
        </K2Tooltip>
      )}
    </>
  );
};

export default LabelWithHelp;
