import { GettingStartedProgressRes } from "@n3oltd/karakoram.navigation.sdk.getting-started/src/index";
import { all, put, select, takeLatest } from "redux-saga/effects";

import K2Client, {
  _gettingStartedClient,
} from "appRedux/models/base/K2RestClients";
import IApplicationState from "appRedux/types";
import { API_VERSION } from "constants/appConstants";

import * as actionTypes from "./actionTypes";
import { Step } from "./types";

function* fetchGettingStarted() {
  try {
    const response: GettingStartedProgressRes = yield _gettingStartedClient.getProgress();

    const steps: [string, Step][] = Object.entries(response.steps);

    const completedSteps: number = steps.reduce((acc, step) => {
      if (step[1].isCompleted && !step[1].isFinalStep) acc++;
      return acc;
    }, 0);

    const totalSteps: number = steps.length - 1;

    const subsId = yield select(
      (state: IApplicationState) => state.subscription.users.k2Subscription.id,
    );

    const currentStep =
      steps.find((step) => step[1].isCompleted === false) ||
      steps[steps.length - 1];

    const imageToken = currentStep[1]?.graphics["2x"]?.file.storageToken;
    const navBaseUrl: string = K2Client.baseUrlFor("navigation");

    let image = "";
    if (imageToken) {
      image = `${navBaseUrl}/gettingstarted/${API_VERSION}/images/${subsId}/${imageToken}`;
    }

    yield put({
      type: actionTypes.FETCH_GETTING_STARTED_PROGRESS_SUCCESS,
      currentStep,
      totalSteps,
      completedSteps,
      image,
      isAllCompleted: response.isCompleted,
    });
  } catch (error) {
    console.log("error", error);
  }
}

function* completeGettingStarted() {
  try {
    yield _gettingStartedClient.markStepComplete("final");
  } catch (error) {
    console.log("error", error);
  }
}

export default function* watcherSaga() {
  yield all([
    takeLatest(actionTypes.FETCH_GETTING_STARTED_PROGRESS, fetchGettingStarted),
    takeLatest(actionTypes.COMPLETE_GETTING_STARTED_, completeGettingStarted),
  ]);
}
