import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Language } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { SubscriptionProfile } from "@n3oltd/k2.users.sdk.users/esm";
import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import { LOCATION_CHANGE } from "connected-react-router";
import { usePrevious, useRouter } from "hooks";
import { StatusCodes } from "http-status-codes";
import AppLocales from "lngProvider";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import qs from "qs";
import { useCookies } from "react-cookie";
import { IntlProvider } from "react-intl";
import { connect, useDispatch } from "react-redux";
import {
  Redirect,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router";
import { Route } from "react-router-dom";
import RoutesList from "routes";

import AppManager from "appRedux/AppManager";
import AuthManager from "appRedux/models/auth/AuthManager";
import { FlatRoutes, K2Routes } from "appRedux/models/routes/RouteModel";
import { ILocationChangeAction } from "appRedux/modules/location/action";
import AuthSelectors, {
  IK2SubscriptionViewStateProps,
} from "appRedux/store/selectors/AuthSelectors";
import AppProvider from "common/contexts/AppProvider/AppProvider";
import { ColorsType } from "common/contexts/AppProvider/types";
import { useEnvironmentContext } from "common/contexts/EnvironmentProvider/EnvironmentProvider";
import ColourHelpers from "common/helpers/colours";
import theme from "common/themeVariables";
import CenteredLogo from "components/centeredLogo";
import { K2ErrorKey } from "components/errors/Error";
import Q1RoutesList from "routes/Q1/routes";
import Logout from "routes/logout";

import SeedingSubscriptionView from "./SeedingSubscriptionView";
import SubscriptionPicker from "./SubscriptionPicker";
import UserErrorsView from "./UserErrorsView";

interface IProps
  extends RouteComponentProps<{ subscription?: string }>,
    IK2SubscriptionViewStateProps {
  authenticated: boolean;
}

/**
 * This class manages the subscriptions view screen
 * for user if there are multiple subscriptions
 * otherwise just takes the user to his / her
 * dashboard by selecting their subscription automatically
 */
const K2SubscriptionView: FC<IProps> = (props) => {
  const {
    authenticated,
    usersManagement,
    k2Subscription,
    getCurrentSubscription,
    location,
    localizationSettings,
    getAppResources,
  } = props;

  const [cookies, setCookie, removeCookie] = useCookies();

  const {
    currentUserSubscriptions,
    currentUserSubscriptionsLoaded,
    serverError,
    areResourcesLoading,
    currentUserLocalizationSettings,
  } = usersManagement;

  const router = useRouter();
  const dispatch = useDispatch();
  const currentAppId = AppManager.getAppId();
  const { region } = useEnvironmentContext();

  const userIsLoggingOut = router.location.pathname === K2Routes.logout;

  useEffect(() => {
    if (authenticated) {
      // Dispatch a location change event because the initial dispatch event was fired before
      // setting & auth configured, and we can't do much at that point.
      const action: ILocationChangeAction = {
        type: LOCATION_CHANGE,
        payload: {
          location: router.location,
          action: "PUSH",
        },
      };
      dispatch(action);
    }
    // eslint-disable-next-line
  }, [authenticated]);

  const [themeColors, setThemeColors] = useState<ColorsType>({
    ...theme,
  });

  const prevK2Subscription = usePrevious(k2Subscription);
  const prevIsUserAndSubscriptionLoading = usePrevious(areResourcesLoading);

  const getSubscriptionCookie = useMemo(() => {
    return cookies[AuthManager.keys.cookies.subscriptionId];
  }, [cookies]);

  const [subscriptionId, setSubscriptionId] = useState<string>(
    getSubscriptionCookie || "",
  );

  const findUserSubscription = useCallback(
    (subscriptionId: string) => {
      return currentUserSubscriptions?.find(
        (x) => x.subscriptionId === subscriptionId,
      );
    },
    [currentUserSubscriptions],
  );

  // for selecting the subscription based in subscription id present in url
  const getSubscriptionFromUrl = useMemo(() => {
    if (location?.search) {
      const subscriptionId = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })?.subscriptionId as string;

      return findUserSubscription(subscriptionId);
    }

    return null;
  }, [findUserSubscription, location]);

  const hasMultipleSubscriptions = useMemo(() => {
    return currentUserSubscriptions?.length > 1;
  }, [currentUserSubscriptions]);

  // if user opens a url that contains a subscription id
  const hasSubscriptionInUrl = useMemo(() => {
    return !!getSubscriptionFromUrl;
  }, [getSubscriptionFromUrl]);

  const [fetchedInitialData, setFetchedInitialData] = useState<boolean>(false);

  /**
   * setting the selected active subscription and updating the headers
   * after that fetch the current user based on subscription selected
   * then also set the subscription in base url
   * then redirect the user to that particular path
   */
  const setSelectedSubscription = useCallback(
    (subscription: SubscriptionProfile, updateCookieAndRedirect?: boolean) => {
      if (_.isEmpty(subscription) || _.isNil(subscription)) {
        return;
      }

      const { subscriptionId } = subscription;

      if (
        (_.isNull(usersManagement?.k2Subscription) ||
          !_.isEqual(usersManagement?.k2Subscription.id, subscriptionId)) &&
        _.isNull(serverError) &&
        !fetchedInitialData
      ) {
        AuthManager.setSubscriptionId(subscriptionId); //for rest clients header
        getAppResources();
        setFetchedInitialData(true);
      }

      if (updateCookieAndRedirect) {
        setFetchedInitialData(false);

        setCookie(
          AuthManager.keys.cookies.subscriptionId,
          subscription.subscriptionId,
          AuthManager.getCookieSetOptions(),
        );
        setSubscriptionId(subscription?.subscriptionId);

        // Currently URL is, for example: "https://beta.n3o.cloud/"
        if (process.env.NODE_ENV !== "development") {
          window.location.href = subscription.url;
        }
      }
    },
    [
      setCookie,
      fetchedInitialData,
      getAppResources,
      serverError,
      usersManagement,
    ],
  );

  const clearCookieToRedirectToSubscriptionPicker = useCallback(() => {
    removeCookie(AppManager.DefaultLocaleKey);
    removeCookie(AuthManager.keys.cookies.subscriptionId);
    window.location.reload();
  }, [removeCookie]);

  useEffect(() => {
    /**IF
     * Subscription is available in the url
     * Then check IF the user subscriptions list have it
     * ELSE then give an not authorized error
     * If found then set it in global state
     * And Start using the App
     *
     * ELSE
     * Check for the cookie to see IF a subscription exists
     * IF yes then check IF the user subscriptions list have it
     * ELSE then give an not authorized error
     * If found then set it in global state
     * And Start using the App
     */
    if (hasSubscriptionInUrl) {
      setSelectedSubscription(getSubscriptionFromUrl, false);
    } else {
      const sub = findUserSubscription(subscriptionId);

      // The subscription in the cookie is outdated and no longer exists in the user's list of subscriptions, remove it
      if (subscriptionId && currentUserSubscriptionsLoaded && !sub) {
        removeCookie(AuthManager.keys.cookies.subscriptionId, {
          path: AuthManager.getCookieSetOptions().path,
          domain: AuthManager.getCookieSetOptions().domain,
        });

        setSubscriptionId(null);
      } else if (!_.isEmpty(subscriptionId) && !_.isNil(sub)) {
        setSelectedSubscription(sub, false);
      } else if (
        _.isNull(usersManagement?.k2Subscription) &&
        !hasMultipleSubscriptions
      ) {
        setSelectedSubscription(currentUserSubscriptions?.[0], true);
      }
    }

    return;
  }, [
    removeCookie,
    currentUserSubscriptionsLoaded,
    currentUserSubscriptions,
    findUserSubscription,
    getSubscriptionFromUrl,
    hasMultipleSubscriptions,
    hasSubscriptionInUrl,
    setSelectedSubscription,
    subscriptionId,
    usersManagement,
  ]);

  useEffect(() => {
    if (prevK2Subscription !== k2Subscription && k2Subscription?.branding) {
      const colors = ColourHelpers.getThemeColors(
        themeColors,
        k2Subscription.branding,
      );
      setThemeColors(colors);

      window.less
        .modifyVars({
          "@primary-color": colors?.primaryColor,
        })
        .then(() => {
          // console.log("ant modified", res);
        })
        .catch(() => {
          // console.log("error", error);
        });
    }

    // We are sure user & subscription localization data has been retrieved
    if (prevIsUserAndSubscriptionLoading && !areResourcesLoading) {
      // Configure moment to localize all dates
      moment.tz.setDefault(currentUserLocalizationSettings?.timezone);

      // Set locale in cookies
      setCookie(
        AppManager.DefaultLocaleKey,
        currentUserLocalizationSettings?.language,
        AuthManager.getCookieSetOptions(),
      );
    }
  }, [
    setCookie,
    currentUserLocalizationSettings,
    areResourcesLoading,
    k2Subscription,
    prevIsUserAndSubscriptionLoading,
    prevK2Subscription,
    themeColors,
  ]);

  useEffect(() => {
    if (subscriptionId && usersManagement?.k2User?.id) {
      // Configure Sentry so it sends UserId and Subscription with events
      Sentry.configureScope(function (scope) {
        scope.setTag("subscriptionId", subscriptionId);
        scope.setTag("userId", usersManagement.k2User.id);
      });
    }
  }, [subscriptionId, usersManagement]);

  const localeLanguage = useMemo(() => {
    let language =
      localizationSettings?.language ||
      (cookies[AppManager.DefaultLocaleKey] as Language) ||
      AppManager.defaultLocale;

    if (language === "xx") {
      language = AppManager.defaultLocale;
    }

    moment.locale(language);

    return language;
  }, [cookies, localizationSettings]);

  const currentAppLocale = useMemo(() => AppLocales[localeLanguage], [
    localeLanguage,
  ]);

  const haveSubscription = !_.isEmpty(k2Subscription);
  const haveSubInUrl = hasSubscriptionInUrl;
  const urlSubscription = getSubscriptionFromUrl;

  const showSubscriptionPicker = hasMultipleSubscriptions && !haveSubscription;

  const k2SubscriptionHasBadStatus =
    !!k2Subscription &&
    k2Subscription.status !== "seeding" &&
    k2Subscription.status !== "active";

  const showUserErrors =
    !userIsLoggingOut &&
    (!_.isEmpty(serverError) ||
      _.isUndefined(currentUserSubscriptions) ||
      _.isEmpty(currentUserSubscriptions) ||
      currentUserSubscriptions.length === 0 ||
      (haveSubInUrl && _.isNil(urlSubscription)) ||
      k2SubscriptionHasBadStatus);

  const stillLoading =
    !userIsLoggingOut &&
    ((!hasMultipleSubscriptions &&
      !haveSubscription &&
      _.isEmpty(serverError)) ||
      areResourcesLoading);

  const showSeedSubscription =
    haveSubscription && k2Subscription?.status === "seeding";

  const getErrorCodeAndKey = useMemo(() => {
    let errorCode: StatusCodes = StatusCodes.UNAUTHORIZED;
    let errorKey: K2ErrorKey = k2SubscriptionHasBadStatus
      ? k2Subscription?.status
      : null;

    if (!_.isEmpty(serverError)) {
      //Case when subscription service crashes...
      const { status } = serverError;

      if (_.isUndefined(status) || _.isNull(status)) {
        errorCode = StatusCodes.INTERNAL_SERVER_ERROR;
        errorKey = "unresponsive";
      } else {
        errorCode = status;
        errorKey = status;
      }
    } else if (currentUserSubscriptions?.length === 0) {
      errorKey = "no-subscription";
      errorCode = StatusCodes.UNAUTHORIZED;
    }

    return {
      errorCode,
      errorKey,
    };
  }, [
    k2Subscription,
    serverError,
    currentUserSubscriptions,
    k2SubscriptionHasBadStatus,
  ]);

  if (
    authenticated &&
    !showSubscriptionPicker &&
    !showUserErrors &&
    !stillLoading &&
    !showSeedSubscription &&
    (location.pathname === K2Routes.baseRoute ||
      location.pathname === K2Routes.baseRouteEmpty)
  ) {
    return <Redirect to={FlatRoutes.dashboard} />;
  }

  return (
    <ConfigProvider
      locale={currentAppLocale?.antd}
      getPopupContainer={(node) => {
        if (node) {
          return node.parentNode as HTMLElement;
        }
        return document.body as HTMLElement;
      }}
    >
      <IntlProvider
        locale={currentAppLocale?.locale}
        messages={currentAppLocale?.messages}
      >
        <AppProvider appId={currentAppId} theme={themeColors}>
          {!authenticated ? (
            <CenteredLogo logoSrc={"n3o.png"} className={"n3o-vert-move"} />
          ) : showSubscriptionPicker ? (
            /**If there are multiple subscriptions available
             * and subscription is not selected yet */
            subscriptionId ? (
              <CenteredLogo
                showLoading
                logoSrc={"n3o.png"}
                className={"n3o-vert-move"}
              />
            ) : (
              <SubscriptionPicker
                subscriptions={currentUserSubscriptions}
                onSubscriptionSelected={setSelectedSubscription}
              />
            )
          ) : showUserErrors ? (
            /**If there is any kind of error or subscriptions are not
             * available or the subscription is cancelled or suspended */
            <UserErrorsView
              serverError={serverError}
              {...getErrorCodeAndKey}
              onClick={clearCookieToRedirectToSubscriptionPicker}
            />
          ) : stillLoading ? (
            /**While loading show circular progress */
            <CenteredLogo
              showLoading
              logoSrc={"n3o.png"}
              className={"n3o-vert-move"}
            />
          ) : showSeedSubscription ? (
            /**If subscription is selected but its status is seeding */
            <SeedingSubscriptionView
              getCurrentSubscription={getCurrentSubscription}
            />
          ) : (
            <Switch>
              {/* Log Out of Engage and Tally */}
              <Route exact path={`/${region}/logout`} component={Logout} />
              <Route path={`/${region}`}>
                {/* Attempting to keep separation here between Tally and Engage */}
                <RoutesList key={"engage"} />
                <Q1RoutesList key={"tally"} />
              </Route>
            </Switch>
          )}
        </AppProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};

const routerWrapped = withRouter(K2SubscriptionView);

export default connect(
  AuthSelectors.getK2SubscriptionViewSelector(),
  AuthSelectors.getK2SubscriptionViewDispatchers(),
)(routerWrapped);
