import React, { FC } from "react";
import { Spin } from "antd";
import styled from "styled-components";
import { K2Message } from "components/k2Widgets";
import { CloseOutlined } from "@ant-design/icons/lib";

const OptsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  width: 100%;
`;

const CancelOpt = styled.a`
  padding: 2px;
  font-size: smaller;
  color: ${({ theme }) => theme.grey_7};
  font-weight: ${({ theme }) => theme.font_weight_normal};
  cursor: pointer;
  transition: ease-in-out 0.15s;
  margin-right: 8px;
  border-radius: ${({ theme }) => theme.border_radius_base};
  &:hover {
    color: ${({ theme }) => theme.grey_8};
  }
  &:focus {
    box-shadow: 0px 0px 2px 1px ${({ theme }) => theme.primaryColor};
  }
`;

const SaveOpt = styled.a<{ disabled: boolean }>`
  padding: 2px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  margin-left: 8px;
  font-size: smaller;
  color: ${(props) =>
    props.disabled ? props.theme.grey_6 : props.theme.primaryColor};
  font-weight: ${({ theme }) => theme.font_weight_bold};
  transition: ease-in-out 0.15s;
  border-radius: ${({ theme }) => theme.border_radius_base};
  &:hover {
    color: ${(props) =>
      props.disabled ? props.theme.grey_6 : props.theme.primaryColorDarkened};
  }
  &:focus {
    box-shadow: 0px 0px 2px 1px ${({ theme }) => theme.primaryColor};
  }
`;

const CustomSpin = styled(Spin)`
  margin-right: 0 !important;
  margin-bottom: 0 !important;
`;

interface IProps {
  onCancel: (any) => void;
  onSave: (any) => void;
  loading?: boolean;
  disabled?: boolean;
  saveMessageKey?: string;
  cancelMessageKey?: string;
  showCancel?: boolean;
  showSave?: boolean;
}

const CancelSaveOpts: FC<IProps> = ({
  onCancel,
  onSave,
  loading,
  disabled,
  saveMessageKey,
  cancelMessageKey,
  showCancel = true,
  showSave = true,
}) => (
  <OptsContainer>
    {loading ? (
      <CustomSpin size="small" />
    ) : (
      <>
        {showCancel && (
          <CancelOpt onClick={onCancel} tabIndex={0}>
            <CloseOutlined />{" "}
            <K2Message localeKey={cancelMessageKey || "common.cancel"} />
          </CancelOpt>
        )}
        {showSave && (
          <SaveOpt
            tabIndex={0}
            disabled={disabled || false}
            onClick={disabled ? () => {} : onSave}
          >
            <K2Message localeKey={saveMessageKey || "common.save"} />
          </SaveOpt>
        )}
      </>
    )}
  </OptsContainer>
);

export default CancelSaveOpts;
