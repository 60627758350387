import {
  LinkEntityReq,
  LinkedEntityRes,
  NoteFileReq,
  NoteRes,
  SecurityPrincipalProfile,
  SecurityPrincipalReq,
  TaskPriority,
  TaskRes,
  TaskStatus,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { Attachment } from "appRedux/modules/tasks/types";
import * as types from "components/dashboard/editTask/modules/actionTypes";
import * as actionReturnTypes from "components/dashboard/editTask/modules/types";
import { editableSectionName } from "components/dashboard/editTask/modules/types";

export function fetchTask(
  taskId: string,
  revisionIdOnly?: boolean,
): actionReturnTypes.fetchTaskAction {
  return {
    type: types.FETCH_TASK,
    taskId,
    revisionIdOnly,
  };
}

export function fetchTaskSuccess(
  task: TaskRes,
  revisionIdOnly?: boolean,
): actionReturnTypes.fetchTaskSuccessAction {
  return {
    type: types.FETCH_TASK_SUCCESS,
    task,
    revisionIdOnly,
  };
}

export function fetchTaskError(
  error: ServerError,
): actionReturnTypes.fetchTaskErrorAction {
  return {
    type: types.FETCH_TASK_ERROR,
    error,
  };
}

export function clearFetchingTaskError(): actionReturnTypes.clearFetchingTaskErrorAction {
  return {
    type: types.CLEAR_FETCHING_TASK_ERROR,
  };
}

export function updateTaskStatusRequest(
  taskId: string,
  status: TaskStatus,
): actionReturnTypes.updateTaskStatusRequestAction {
  return {
    type: types.UPDATE_TASK_STATUS_REQUEST,
    taskId,
    status,
  };
}

export function markTaskComplete(
  taskId: string,
): actionReturnTypes.markTaskCompleteAction {
  return {
    type: types.MARK_TASK_COMPLETE,
    taskId,
  };
}

export function markTaskPending(
  taskId: string,
): actionReturnTypes.markTaskPendingAction {
  return {
    type: types.MARK_TASK_PENDING,
    taskId,
  };
}

export function errorUpdatingStatus(
  error: ServerError,
): actionReturnTypes.errorUpdatingStatusAction {
  return {
    type: types.UPDATE_TASK_STATUS_ERROR,
    error,
  };
}

export function clearErrorUpdatingStatus(): actionReturnTypes.clearErrorUpdatingStatusAction {
  return { type: types.CLEAR_UPDATE_TASK_STATUS_ERROR };
}

export function deleteTaskRequest(
  taskRevisionId: string,
): actionReturnTypes.deleteTaskRequestAction {
  return {
    type: types.DELETE_TASK_REQUEST,
    taskRevisionId,
  };
}

export function deleteTaskSuccess(): actionReturnTypes.deleteTaskSuccessAction {
  return {
    type: types.DELETE_TASK_SUCCESS,
  };
}

export function deleteTaskError(
  error: ServerError,
): actionReturnTypes.deleteTaskErrorAction {
  return {
    type: types.DELETE_TASK_ERROR,
    error,
  };
}

export function clearDeleteTaskError(): actionReturnTypes.clearDeleteTaskErrorAction {
  return {
    type: types.CLEAR_DELETE_TASK_ERROR,
  };
}

export function updateTaskTitleRequest(
  title: string,
  taskId: string,
): actionReturnTypes.updateTaskTitleRequestAction {
  return {
    type: types.UPDATE_TASK_TITLE_REQUEST,
    title,
    taskId,
  };
}

export function updateTaskTitleSuccess(
  title: string,
): actionReturnTypes.updateTaskTitleSuccessAction {
  return {
    type: types.UPDATE_TASK_TITLE_SUCCESS,
    title,
  };
}

export function updateTaskTitleError(
  error: ServerError,
): actionReturnTypes.updateTaskTitleErrorAction {
  return {
    type: types.UPDATE_TASK_TITLE_ERROR,
    error,
  };
}

export function clearUpdateTaskTitleError(): actionReturnTypes.clearUpdateTaskTitleErrorAction {
  return {
    type: types.CLEAR_UPDATE_TASK_TITLE_ERROR,
  };
}

export function updateTaskDueDateRequest(
  dueDate: string,
  taskId: string,
): actionReturnTypes.updateTaskDueDateRequestAction {
  return {
    type: types.UPDATE_TASK_DUE_DATE_REQUEST,
    dueDate,
    taskId,
  };
}

export function updateTaskDueDateSuccess(
  dueDate: string,
): actionReturnTypes.updateTaskDueDateSuccessAction {
  return {
    type: types.UPDATE_TASK_DUE_DATE_SUCCESS,
    dueDate,
  };
}

export function updateTaskDueDateError(
  error: ServerError,
): actionReturnTypes.updateTaskDueDateErrorAction {
  return {
    type: types.UPDATE_TASK_DUE_DATE_ERROR,
    error,
  };
}

export function clearTaskDueDateError(): actionReturnTypes.clearTaskDueDateErrorAction {
  return {
    type: types.CLEAR_UPDATE_TASK_DUE_DATE_ERROR,
  };
}

export function updateTaskPriorityRequest(
  priority: TaskPriority,
  taskId: string,
): actionReturnTypes.updateTaskPriorityRequestAction {
  return {
    type: types.UPDATE_TASK_PRIORITY_REQUEST,
    priority,
    taskId,
  };
}

export function updateTaskPrioritySuccess(
  priority: TaskPriority,
): actionReturnTypes.updateTaskPrioritySuccessAction {
  return {
    type: types.UPDATE_TASK_PRIORITY_SUCCESS,
    priority,
  };
}

export function updateTaskPriorityError(
  error: ServerError,
): actionReturnTypes.updateTaskPriorityErrorAction {
  return {
    type: types.UPDATE_TASK_PRIORITY_ERROR,
    error,
  };
}

export function clearTaskPriorityError(): actionReturnTypes.clearUpdateTaskPriorityErrorAction {
  return {
    type: types.CLEAR_UPDATE_TASK_PRIORITY_ERROR,
  };
}

export function updateTaskAssigneesRequest(
  taskId: string,
  taskRevisionId: string,
  principals: SecurityPrincipalReq[],
  principalProfiles: SecurityPrincipalProfile[],
): actionReturnTypes.updateTaskAssigneesRequestAction {
  return {
    type: types.UPDATE_TASK_ASSIGNEES_REQUEST,
    principals,
    principalProfiles,
    taskId,
    taskRevisionId,
  };
}

export function updateTaskAssigneesSuccess(
  principalProfiles: SecurityPrincipalProfile[],
): actionReturnTypes.updateTaskAssigneesSuccessAction {
  return {
    type: types.UPDATE_TASK_ASSIGNEES_SUCCESS,
    principalProfiles,
  };
}

export function updateTaskAssigneesError(
  error: ServerError,
): actionReturnTypes.updateTaskAssigneesErrorAction {
  return {
    type: types.UPDATE_TASK_ASSIGNEES_ERROR,
    error,
  };
}

export function clearTaskAssigneesError(): actionReturnTypes.clearUpdateTaskAssigneesErrorAction {
  return {
    type: types.CLEAR_UPDATE_TASK_ASSIGNEES_ERROR,
  };
}

export function updateLinkedEntityRequest(
  taskId: string,
  linkedEntity?: LinkEntityReq,
): actionReturnTypes.updateLinkedEntityRequestAction {
  return {
    type: types.UPDATE_LINKED_ENTITY_REQUEST,
    linkedEntity,
    taskId,
  };
}

export function updateLinkedEntitySuccess(
  linkedEntity: LinkedEntityRes,
): actionReturnTypes.updateLinkedEntitySuccessAction {
  return {
    type: types.UPDATE_LINKED_ENTITY_SUCCESS,
    linkedEntity,
  };
}

export function updateLinkedEntityError(
  error: ServerError,
): actionReturnTypes.updateLinkedEntityErrorAction {
  return {
    type: types.UPDATE_LINKED_ENTITY_ERROR,
    error,
  };
}

export function clearLinkedEntityError(): actionReturnTypes.clearUpdateLinkedEntityErrorAction {
  return {
    type: types.CLEAR_UPDATE_LINKED_ENTITY_ERROR,
  };
}

export function deleteTaskNoteRequest(
  taskId: string,
  taskRevisionId: string,
  noteId: string,
): actionReturnTypes.deleteNoteRequestAction {
  return {
    type: types.DELETE_TASK_NOTE_REQUEST,
    noteId,
    taskId,
    taskRevisionId,
  };
}

export function deleteTaskNoteSuccess(
  noteId: string,
): actionReturnTypes.deleteNoteSuccessAction {
  return {
    type: types.DELETE_TASK_NOTE_SUCCESS,
    noteId,
  };
}

export function deleteTaskNoteError(
  noteId: string,
  error: ServerError,
): actionReturnTypes.deleteNoteErrorAction {
  return {
    type: types.DELETE_TASK_NOTE_ERROR,
    error,
    noteId,
  };
}

export function clearDeleteTaskNoteError(
  noteId: string,
): actionReturnTypes.clearDeleteTaskNoteErrorAction {
  return {
    type: types.CLEAR_DELETE_TASK_NOTE_ERROR,
    noteId,
  };
}

export function clearNoteEditStatus(
  noteId: string,
): actionReturnTypes.clearNoteEditStatusAction {
  return {
    type: types.CLEAR_NOTE_EDIT_STATUS,
    noteId,
  };
}

export function updateNoteRequest(
  taskId: string,
  taskRevisionId: string,
  noteId: string,
  noteText: string,
  attachmentsToSave: Attachment[],
  attachmentsToDelete: Attachment[],
): actionReturnTypes.updateNoteRequestAction {
  return {
    type: types.UPDATE_NOTE_REQUEST,
    noteId,
    taskId,
    taskRevisionId,
    noteText,
    attachmentsToSave,
    attachmentsToDelete,
  };
}

export function updateNoteSuccess(
  noteId: string,
): actionReturnTypes.updateNoteSuccessAction {
  return {
    type: types.UPDATE_NOTE_SUCCESS,
    noteId,
  };
}

export function updateNoteError(
  error: ServerError,
  noteId: string,
): actionReturnTypes.updateNoteErrorAction {
  return {
    type: types.UPDATE_NOTE_ERROR,
    error,
    noteId,
  };
}

export function clearUpdateNoteError(
  noteId: string,
): actionReturnTypes.clearUpdateNoteErrorAction {
  return {
    type: types.CLEAR_UPDATE_NOTE_ERROR,
    noteId,
  };
}

export function saveNewNoteRequest(
  taskId: string,
  noteText: string,
  noteFiles?: NoteFileReq[],
): actionReturnTypes.saveNewNoteRequestAction {
  return {
    type: types.SAVE_NEW_NOTE_REQUEST,
    taskId,
    noteText,
    noteFiles,
  };
}

export function saveNewNoteSuccess(
  note: NoteRes,
): actionReturnTypes.saveNewNoteSuccessAction {
  return {
    type: types.SAVE_NEW_NOTE_SUCCESS,
    note,
  };
}

export function saveNewNoteError(
  error: ServerError,
): actionReturnTypes.saveNewNoteErrorAction {
  return {
    type: types.SAVE_NEW_NOTE_ERROR,
    error,
  };
}

export function clearSaveNewNoteError(): actionReturnTypes.clearSaveNewNoteErrorAction {
  return {
    type: types.CLEAR_SAVE_NEW_NOTE_ERROR,
  };
}

export function updateSharedWithUsers(
  sharedWith: SecurityPrincipalProfile[],
): actionReturnTypes.updateSharedWithUsersAction {
  return {
    type: types.UPDATE_SHARED_WITH_USERS,
    sharedWith,
  };
}

export function setFormTouched(
  editableSectionName: editableSectionName,
): actionReturnTypes.setFormTouched {
  return {
    type: types.SET_FORM_TOUCHED,
    editableSectionName,
  };
}

export function clearFormTouched(
  editableSectionName: editableSectionName,
): actionReturnTypes.clearFormTouched {
  return {
    type: types.CLEAR_FORM_TOUCHED,
    editableSectionName,
  };
}

export function clearAllFormsTouched(): actionReturnTypes.clearAllFormsTouched {
  return {
    type: types.CLEAR_ALL_FORMS_TOUCHED,
  };
}

export function deleteLinkedEntity(taskId: string) {
  return {
    type: types.DELETE_LINKED_ENTITY_REQUEST,
    taskId,
  };
}

export function deleteLinkedEntityError(error: ServerError) {
  return {
    type: types.DELETE_LINKED_ENTITY_ERROR,
    error,
  };
}

export function deleteLinkedEntitySuccess() {
  return {
    type: types.DELETE_LINKED_ENTITY_SUCCESS,
  };
}

export function clearDeleteLinkedEntityError() {
  return {
    type: types.CLEAR_DELETING_LINKED_ENTITY_ERROR,
  };
}

export function clearDeletingLinkedEntity() {
  return {
    type: types.CLEAR_DELETING_LINKED_ENTITY,
  };
}
