import React, { FC, useCallback, useState } from "react";

import {
  NamedLookupRes,
  NamedLookupResLookupResultsList,
} from "@n3oltd/k2.subscriptions.sdk.lookups/esm";
import { Col, Empty, Row } from "antd";
import _ from "lodash";

import AppManager from "appRedux/AppManager";
import { K2Card, K2Message, K2SelectV4, K2Spin } from "components/k2Widgets";
import { showNotification } from "components/utils/Notification";
import { GenericObject } from "components/utils/Utils";

interface IProps {
  apiUrls: GenericObject[];
  callApi: (api: string, post?: boolean) => Promise<Response>;
}

const TestData: FC<IProps> = (props) => {
  const { apiUrls, callApi } = props;

  const [noDataTestData, setNoDataTestData] = useState<boolean>(false);
  const [loadingTestData, setLoadingTestData] = useState<boolean>(false);
  const [testDataServiceId, setTestDataServiceId] = useState<string>("");
  const [testDataCategoryId, setTestDataCategoryId] = useState<string>("");
  const [testDataId, setTestDataId] = useState<string>("");
  const [categories, setCategories] = useState<NamedLookupRes[]>([]);
  const [testData, setTestData] = useState<NamedLookupRes[]>([]);
  const [finalTestData, setFinalTestData] = useState<Object>({});

  const onTestDataServiceChangeHandler = useCallback(
    async (service: string) => {
      setLoadingTestData(true);

      try {
        const response = await callApi(
          `${AppManager.baseUrls[service]}/testing/v1.0/data/categories`,
        );
        const result: NamedLookupResLookupResultsList = await response.json();
        const items = result?.items;

        setTestDataServiceId(service);
        setCategories(items || []);
        setTestData([]);
        setFinalTestData({});
        setNoDataTestData(items?.length <= 0);
        setLoadingTestData(false);
      } catch (err) {
        showNotification({
          type: "error",
          title: err.toString(),
        });
        setLoadingTestData(false);
      }
    },
    [callApi],
  );

  const onTestDataCategoryChangeHandler = useCallback(
    async (category: string) => {
      setLoadingTestData(true);

      try {
        const response = await callApi(
          `${AppManager.baseUrls[testDataServiceId]}/testing/v1.0/data/categories/${category}`,
        );
        const result: NamedLookupResLookupResultsList = await response.json();
        const items = result?.items;

        setTestDataCategoryId(category);
        setTestData(items || []);
        setNoDataTestData(items?.length <= 0);
        setLoadingTestData(false);
      } catch (err) {
        showNotification({
          type: "error",
          title: err.toString(),
        });
        setLoadingTestData(false);
      }
    },
    [callApi, testDataServiceId],
  );

  const onTestDataChangeHandler = useCallback(
    async (dataId: string) => {
      setLoadingTestData(true);

      try {
        const response = await callApi(
          `${AppManager.baseUrls[testDataServiceId]}/testing/v1.0/data/categories/${testDataCategoryId}/data/${dataId}`,
        );
        const result: Object = await response.json();

        setTestDataId(dataId);
        setFinalTestData(result);
        setNoDataTestData(_.isEmpty(result));
        setLoadingTestData(false);
      } catch (err) {
        showNotification({
          type: "error",
          title: err.toString(),
        });
        setLoadingTestData(false);
      }
    },
    [callApi, testDataServiceId, testDataCategoryId],
  );

  return (
    <K2Card bordered={false} shadow={true} localeKey={"admin.testing.testDate"}>
      <K2Spin
        spinning={loadingTestData}
        className={"n3o-h-100 n3o-not-allowed"}
        tipKey={"common.loading"}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <K2SelectV4
              placeholderKey={"admin.testing.selectService"}
              dataSource={apiUrls}
              idField={"id"}
              nameField={"name"}
              style={{ width: "100%" }}
              onChange={onTestDataServiceChangeHandler}
              focusInputOnFocus
              showArrow
              showSearch
            />
          </Col>
          {categories?.length > 0 && (
            <Col span={24}>
              <K2SelectV4
                placeholderKey={"admin.testing.selectCategory"}
                dataSource={categories}
                style={{ width: "100%" }}
                onChange={onTestDataCategoryChangeHandler}
                showArrow
                focusInputOnFocus
                allowClear
                showSearch
              />
            </Col>
          )}
          {categories?.length > 0 && testData?.length > 0 && (
            <Col span={24}>
              <K2SelectV4
                placeholderKey={"admin.testing.selectTestData"}
                dataSource={testData}
                style={{ width: "100%" }}
                onChange={onTestDataChangeHandler}
                showArrow
                focusInputOnFocus
                allowClear
                showSearch
              />
            </Col>
          )}
          {!_.isEmpty(testDataId) &&
            !_.isNil(finalTestData) &&
            !_.isEmpty(finalTestData) && (
              <Col span={24}>
                <K2Card
                  bordered={false}
                  shadow={true}
                  localeKey={"common.requestModel"}
                >
                  <code>
                    <pre className={"n3o-mb-0"}>
                      {JSON.stringify(finalTestData, null, 4)}
                    </pre>
                  </code>
                </K2Card>
              </Col>
            )}
          {noDataTestData && (
            <Col span={24}>
              <Empty
                description={<K2Message localeKey={"common.noRecordsFound"} />}
              />
            </Col>
          )}
        </Row>
      </K2Spin>
    </K2Card>
  );
};

export default TestData;
