import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const Task: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66669 2.8499C4.18488 2.8499 3.72281 3.0413 3.38211 3.38199C3.04142 3.72268 2.85002 4.18476 2.85002 4.66657V19.3332C2.85002 19.815 3.04142 20.2771 3.38211 20.6178C3.7228 20.9585 4.18488 21.1499 4.66669 21.1499H14.4833V17.9997C14.4833 17.0671 14.8538 16.1726 15.5133 15.5131C16.1728 14.8536 17.0673 14.4831 18 14.4831H21.15V4.66657C21.15 4.18476 20.9586 3.72268 20.6179 3.38199C20.2772 3.0413 19.8152 2.8499 19.3334 2.8499H4.66669ZM20.6148 16.1831L16.1833 20.6154V17.9997C16.1833 17.5179 16.3747 17.0558 16.7154 16.7152C17.0561 16.3745 17.5182 16.1831 18 16.1831H20.6148ZM14.8948 22.8499H4.66669C3.73401 22.8499 2.83954 22.4794 2.18003 21.8199C1.52053 21.1604 1.15002 20.2659 1.15002 19.3332V4.66657C1.15002 3.73389 1.52053 2.83941 2.18003 2.17991C2.83954 1.52041 3.73401 1.1499 4.66669 1.1499H19.3334C20.266 1.1499 21.1605 1.52041 21.82 2.17991C22.4795 2.83941 22.85 3.73389 22.85 4.66657V14.8959C22.8498 15.8284 22.4792 16.7229 21.8198 17.3822L17.3825 21.8204C17.0557 22.1469 16.6677 22.4061 16.2409 22.5827C15.8142 22.7593 15.3565 22.8501 14.8948 22.8499C14.8946 22.8499 14.8949 22.8499 14.8948 22.8499ZM16.4348 7.54889C16.7668 7.88084 16.7668 8.41903 16.4348 8.75097L11.4642 13.7216L11.4633 13.7225C11.1674 14.0176 10.7664 14.1834 10.3485 14.1834C9.93051 14.1834 9.52959 14.0176 9.23362 13.7225L7.56658 12.0581C7.23437 11.7264 7.23394 11.1883 7.56562 10.856C7.8973 10.5238 8.43549 10.5234 8.7677 10.8551L10.3484 12.4333L15.2328 7.54889C15.5647 7.21695 16.1029 7.21695 16.4348 7.54889Z"
      fill={fill}
    />
  </svg>
);
