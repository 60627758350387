import React, { FC, useEffect } from "react";

import { CheckCircleOutlined } from "@ant-design/icons/lib";
import { TaskStatus } from "@n3oltd/karakoram.tasks.sdk.tasks";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { connect } from "react-redux";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import IApplicationState from "appRedux/types";
import {
  clearErrorUpdatingStatus,
  updateTaskStatusRequest,
} from "components/dashboard/editTask/modules/actions";
import { K2Button, K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

interface IProps extends InjectedK2IntlProps {
  completed: boolean;
  updateTaskStatus: typeof updateTaskStatusRequest;
  taskId: string;
  size?: SizeType;
  updatingStatus: boolean;
  error?: ServerError;
  clearError: typeof clearErrorUpdatingStatus;
  disabled: boolean;
}

// Can connect to the Task state and mark complete
const MarkTaskCompleteButton: FC<IProps> = ({
  completed,
  updateTaskStatus,
  taskId,
  size,
  updatingStatus,
  error,
  clearError,
  k2Intl,
  disabled,
}) => {
  const hasError: boolean = !!error;

  useEffect(() => {
    if (hasError) {
      UIUtils.handleServerError(k2Intl, error, clearError);
    }
    // eslint-disable-next-line
  }, [hasError]);

  return completed ? (
    <K2Button
      disabled={disabled}
      loading={updatingStatus}
      className="n3o-m-0"
      size={size}
      type="primary"
      icon={<CheckCircleOutlined />}
      onClick={() => updateTaskStatus(taskId, TaskStatus.Pending)}
    >
      <K2Message localeKey="common.completed" />
    </K2Button>
  ) : (
    <K2Button
      disabled={disabled}
      loading={updatingStatus}
      className="n3o-m-0"
      size={size}
      onClick={() => updateTaskStatus(taskId, TaskStatus.Completed)}
    >
      <K2Message localeKey="tasks.action.markComplete" />
    </K2Button>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    completed:
      state.editViewTask.task && state.editViewTask.task.status === "completed",
    taskId: state.editViewTask.task ? state.editViewTask.task.id : null,
    updatingStatus: state.editViewTask.updatingStatus,
    error: state.editViewTask.errorUpdatingStatus,
  };
};

const mapDispatchToProps = {
  updateTaskStatus: updateTaskStatusRequest,
  clearError: clearErrorUpdatingStatus,
};

export default injectK2Intl(
  connect(mapStateToProps, mapDispatchToProps)(MarkTaskCompleteButton),
);
