import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/src/index";
import { EmailClient } from "@n3oltd/karakoram.templates.sdk.email";
import { EmailCompositionRes } from "@n3oltd/karakoram.templates.sdk.email/src/index";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

import K2RestClients, {
  _assetsClient,
  _emailCompositionsClient,
} from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { DataActions } from "appRedux/modules/sharedTypes";
import { TEMPLATE_ENGINE } from "constants/appConstants";

import {
  ActionTypes,
  CreateEmailTemplate,
  DeleteEmailTemplate,
  FetchEmailTemplate,
  RemoveTemporaryAsset,
  UpdateEmailTemplate,
  UploadAsset,
} from "./actionTypes";
import * as actions from "./actions";

//#region WORKERS

//#endregion

function* fetchComposition(action: FetchEmailTemplate) {
  const response: IApiResponse<EmailCompositionRes> = yield K2RestService.toResponse(
    _emailCompositionsClient.getCompositionById(
      action.id,
      TEMPLATE_ENGINE,
      null,
    ),
  );

  if (response.error) {
    yield put(actions.fetchEmailTemplateError(response.error));
  } else {
    yield put(actions.fetchEmailTemplateSuccess(response.getResultOrDefault()));
  }
}

function* uploadAsset(action: UploadAsset) {
  try {
    yield put(actions.updateTemporaryAssets(action.upload, action.assetType));

    const resp: IApiResponse<string> = yield K2RestService.toResponse(
      _assetsClient.upload(null, {
        fileName: action.originalFile.name,
        data: action.originalFile,
      }),
    );

    if (resp.error) {
      yield put(
        actions.updateTemporaryAssets(
          {
            ...action.upload,
            status: "error",
            response:
              resp.error.data.status === K2StatusCodes.preconditionFailed
                ? resp.error.data.errors?.map?.((e) => e.error)?.join(". ")
                : resp.error.data.title,
          },
          action.assetType,
        ),
      );
    } else {
      const assetResp: IApiResponse<AssetRes> = yield K2RestService.toResponse(
        _assetsClient.createAsset(TEMPLATE_ENGINE, null, {
          file: resp.getResultOrDefault(),
          shared: action.upload.shared,
        }),
      );

      if (assetResp.error) {
        const errors: string =
          assetResp.error.data.status === K2StatusCodes.preconditionFailed
            ? assetResp.error.data.errors?.map?.((e) => e.error)?.join(". ")
            : assetResp.error.data.title;

        yield put(
          actions.updateTemporaryAssets(
            {
              ...action.upload,
              status: "error",
              response: errors,
            },
            action.assetType,
          ),
        );
      } else {
        yield put(
          actions.updateTemporaryAssets(
            {
              ...action.upload,
              status: "success",
              savedAsset: assetResp.getResultOrDefault(),
            },
            action.assetType,
          ),
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* deleteAsset(action: RemoveTemporaryAsset) {
  // If the asset has already been saved to the backend, delete it.
  if (action.temporaryAsset.savedAsset) {
    yield K2RestService.toResponse(
      _assetsClient.deleteAsset(
        action.temporaryAsset.savedAsset.revisionId,
        TEMPLATE_ENGINE,
        null,
      ),
    );
  }
}

function* createTemplate(action: CreateEmailTemplate) {
  try {
    let client = _emailCompositionsClient;
    if (action.ignoreWarning) {
      client = K2RestClients.initRestClient(EmailClient, "templates", {
        getN3OIgnoreValidationWarnings: () => "true",
      });
    }
    const resp: IApiResponse<EmailCompositionRes> = yield K2RestService.toResponse(
      client.createComposition(
        TEMPLATE_ENGINE,
        action.scopeId || undefined,
        action.data,
      ),
    );

    if (resp.error) {
      yield put(
        actions.createEmailTemplateError({
          action: DataActions.add,
          ...resp,
        }),
      );
    } else {
      yield put(
        actions.createEmailTemplateSuccess({
          action: DataActions.add,
          ...resp,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateTemplate(action: UpdateEmailTemplate) {
  try {
    let client = _emailCompositionsClient;
    if (action.ignoreWarning) {
      client = K2RestClients.initRestClient(EmailClient, "templates", {
        getN3OIgnoreValidationWarnings: () => "true",
      });
    }
    const resp: IApiResponse<void> = yield K2RestService.toResponse(
      client.updateComposition(
        action.revisionId,
        TEMPLATE_ENGINE,
        action.scopeId || undefined,
        action.data,
      ),
    );

    if (resp.error) {
      yield put(
        actions.updateEmailTemplateError({
          action: DataActions.update,
          ...resp,
        }),
      );
    } else {
      yield put(
        actions.updateEmailTemplateSuccess({
          action: DataActions.update,
          ...resp,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* deleteTemplate(action: DeleteEmailTemplate) {
  // If the asset has already been saved to the backend, delete it.

  const resp: IApiResponse<void> = yield K2RestService.toResponse(
    _emailCompositionsClient.deleteComposition(
      action.revisionId,
      TEMPLATE_ENGINE,
      null,
    ),
  );

  if (resp.error) {
    yield put(
      actions.deleteEmailTemplateError({
        action: DataActions.delete,
        ...resp,
      }),
    );
  } else {
    yield put(
      actions.deleteEmailTemplateSuccess({
        action: DataActions.delete,
        ...resp,
      }),
    );
  }
}

//#region WATCHERS

export default function* partialOrLayoutSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_EMAIL_TEMPLATE, fetchComposition),
    takeEvery(ActionTypes.UPLOAD_ASSET, uploadAsset),
    takeEvery(ActionTypes.REMOVE_TEMPORARY_ASSET, deleteAsset),
    takeEvery(ActionTypes.CREATE_EMAIL_TEMPLATE, createTemplate),
    takeEvery(ActionTypes.UPDATE_EMAIL_TEMPLATE, updateTemplate),
    takeEvery(ActionTypes.DELETE_EMAIL_TEMPLATE, deleteTemplate),
  ]);
}

//#endregion
