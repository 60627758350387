import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const DeleteAccount: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00006 1.1499C6.80217 1.1499 5.65334 1.62576 4.8063 2.4728C3.95926 3.31984 3.4834 4.46868 3.4834 5.66657C3.4834 6.86447 3.95926 8.0133 4.8063 8.86034C5.31502 9.36906 5.93261 9.7439 6.6043 9.96217C5.75846 10.1385 4.95118 10.4737 4.22626 10.9525C3.28135 11.5765 2.50582 12.425 1.96899 13.422C1.43217 14.4191 1.1508 15.5336 1.15002 16.666C1.14971 17.1354 1.53 17.5162 1.99945 17.5166C2.46889 17.5169 2.8497 17.1366 2.85002 16.6671C2.8506 15.8157 3.06218 14.9776 3.46582 14.2279C3.86947 13.4782 4.45261 12.8403 5.16311 12.371C5.8736 11.9018 6.68931 11.6159 7.53728 11.539C8.38526 11.4621 9.23908 11.5966 10.0224 11.9303C10.4543 12.1143 10.9535 11.9134 11.1376 11.4815C11.3216 11.0496 11.1206 10.5504 10.6888 10.3664C10.2713 10.1885 9.83887 10.0532 9.39788 9.96151C10.0688 9.74314 10.6856 9.36855 11.1938 8.86034C12.0409 8.0133 12.5167 6.86447 12.5167 5.66657C12.5167 4.46868 12.0409 3.31984 11.1938 2.4728C10.3468 1.62576 9.19796 1.1499 8.00006 1.1499ZM6.00838 3.67489C6.53661 3.14666 7.25304 2.8499 8.00006 2.8499C8.74709 2.8499 9.46352 3.14666 9.99175 3.67489C10.52 4.20311 10.8167 4.91954 10.8167 5.66657C10.8167 6.4136 10.52 7.13003 9.99175 7.65826C9.46352 8.18649 8.74709 8.48324 8.00006 8.48324C7.25304 8.48324 6.53661 8.18649 6.00838 7.65826C5.48015 7.13003 5.1834 6.4136 5.1834 5.66657C5.1834 4.91954 5.48015 4.20311 6.00838 3.67489Z"
      fill={fill}
    />
    <path
      d="M15.3787 14.1767C15.0467 13.8448 14.5085 13.8448 14.1766 14.1767C13.8447 14.5087 13.8447 15.0469 14.1766 15.3788L15.4612 16.6634L14.1766 17.9479C13.8447 18.2799 13.8447 18.8181 14.1766 19.15C14.5086 19.482 15.0468 19.482 15.3787 19.15L16.6633 17.8655L17.9478 19.15C18.2798 19.482 18.818 19.482 19.1499 19.15C19.4819 18.8181 19.4819 18.2799 19.1499 17.948L17.8653 16.6634L19.15 15.3788C19.4819 15.0468 19.4819 14.5086 19.15 14.1767C18.818 13.8447 18.2798 13.8447 17.9479 14.1767L16.6633 15.4613L15.3787 14.1767Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.291 12.291C11.1314 13.4506 10.48 15.0234 10.48 16.6633C10.48 18.3032 11.1314 19.876 12.291 21.0356C13.4506 22.1952 15.0234 22.8467 16.6633 22.8467C18.3032 22.8467 19.876 22.1952 21.0356 21.0356C22.1952 19.876 22.8467 18.3032 22.8467 16.6633C22.8467 15.0234 22.1952 13.4506 21.0356 12.291C19.876 11.1314 18.3032 10.48 16.6633 10.48C15.0234 10.48 13.4506 11.1314 12.291 12.291ZM12.18 16.6633C12.18 15.4743 12.6523 14.3339 13.4931 13.4931C14.3339 12.6523 15.4743 12.18 16.6633 12.18C17.8524 12.18 18.9927 12.6523 19.8335 13.4931C20.6743 14.3339 21.1467 15.4743 21.1467 16.6633C21.1467 17.8524 20.6743 18.9927 19.8335 19.8335C18.9927 20.6743 17.8524 21.1467 16.6633 21.1467C15.4743 21.1467 14.3339 20.6743 13.4931 19.8335C12.6523 18.9927 12.18 17.8524 12.18 16.6633Z"
      fill={fill}
    />
  </svg>
);
