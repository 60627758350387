import { TaskSummaryResultsPage } from "@n3oltd/karakoram.tasks.sdk.tasks/src/index";
import { all, put, takeLatest } from "redux-saga/effects";

import { _tasksClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";

import { ActionTypes, UpdateTaskDrawerTasks } from "./actionTypes";
import * as actions from "./actions";

function* fetchTasks(action: UpdateTaskDrawerTasks) {
  const response: IApiResponse<TaskSummaryResultsPage> = yield K2RestService.toResponse(
    _tasksClient.find({
      ...action.criteria,
    }),
  );
  if (response.error) {
    yield put(actions.updateTaskDrawerTasksError(response.error));
  } else {
    yield put(
      actions.updateTaskDrawerTasksSuccess(response.getResultOrDefault()),
    );
  }
}

export default function* watcherSaga() {
  yield all([takeLatest(ActionTypes.UPDATE_TASK_DRAWER_TASKS, fetchTasks)]);
}
