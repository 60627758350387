import React, { FC } from "react";

import { LookupType as SubscriptionLookupType } from "@n3oltd/k2.subscriptions.sdk.lookups/esm";
import { StatusCodes } from "http-status-codes/build/es";
import styled from "styled-components";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { SubscriptionStatusesLookups } from "appRedux/modules/lookups/types";
import injectLookup from "appRedux/utils/injectLookups";

import ErrorPage, { K2ErrorKey } from "../../../../components/errors/Error";

const ContentWrapper = styled.div`
  .ant-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

interface UserErrorsViewProps {
  errorKey?: K2ErrorKey;
  errorCode: StatusCodes;
  serverError?: ServerError;
  onClick?: () => void;
  subscriptionStatuses?: SubscriptionStatusesLookups;
}

const UserErrorsView: FC<UserErrorsViewProps> = ({
  errorKey,
  errorCode,
  serverError,
  onClick,
  subscriptionStatuses,
}) => {
  return (
    <ContentWrapper className="n3o-main-content-wrapper">
      <ErrorPage
        errorKey={errorKey}
        errorCode={errorCode}
        serverError={serverError}
        onClick={onClick}
        subscriptionStatuses={subscriptionStatuses.items}
      />
    </ContentWrapper>
  );
};

export default injectLookup(UserErrorsView, [
  SubscriptionLookupType.SubscriptionStatuses,
]);
