import React, { FC, useCallback, useState } from "react";

import { ShareAltOutlined } from "@ant-design/icons/lib";
import {
  AccessReq,
  AccessRes,
  SecurityPrincipalProfile,
} from "@n3oltd/karakoram.analytics.sdk.reports";
import { Col, Row } from "antd";
import Form, { FormInstance } from "antd/es/form/Form";
import useForm from "antd/lib/form/hooks/useForm";
import styled from "styled-components";

import { K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import K2Modal from "components/k2Widgets/k2Modal";
import SharedAccess, { AccessOption } from "components/sharedAccess";
import DirtyDataGuard from "components/utils/DirtyDataGuard";

interface EditPermissionsModalProps extends InjectedK2IntlProps {
  shared: boolean;
  sharedAccess: AccessRes;
  titleKey: string;

  onShare: (form: FormInstance, access: AccessReq, onDone: () => void) => void;
  onUnshare: (form: FormInstance, onDone: () => void) => void;

  onCancel: () => void;
}

const BlueShareAltOutlined = styled(ShareAltOutlined)`
  && {
    color: ${({ theme }) => theme.primaryColor};
    font-size: 32px;
  }
`;

const Title = styled.p`
  font-size: 15px;
  font-weight: ${({ theme }) => theme.font_weight_bold};
  color: ${({ theme }) => theme.grey_9};
`;

const EditPermissionsModal: FC<EditPermissionsModalProps> = ({
  titleKey,
  shared,
  sharedAccess,
  onShare,
  onUnshare,
  onCancel,
}) => {
  const [form] = useForm();

  const [touched, setTouched] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const getAccessOption = (): AccessOption => {
    if (shared && sharedAccess?.everyone) return "all";
    else if (shared && !sharedAccess?.everyone) return "selected";
    else return "none";
  };

  const [accessOption, setAccessOption] = useState<AccessOption>(
    getAccessOption(),
  );

  const [limitedTo, setLimitedTo] = useState<SecurityPrincipalProfile[]>(
    sharedAccess?.limitedTo || [],
  );

  const handleSave = useCallback(
    async (isShared: boolean, access?: AccessReq) => {
      setSaving(true);

      if (!isShared) {
        onUnshare(form, () => setSaving(false));
      } else {
        onShare(form, access, () => setSaving(false));
      }
    },
    [onShare, onUnshare, form],
  );

  return (
    <K2Modal
      title={null}
      visible={true}
      spinning={saving}
      onCancel={onCancel}
      okTextKey={"common.save"}
      style={{ minHeight: 500 }}
      cancelTextKey={"common.cancel"}
      okButtonProps={{ disabled: saving || !touched }}
      cancelButtonProps={{ disabled: saving }}
      requiresCloseConfirmation={touched}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            const isShared = accessOption !== "none";
            handleSave(
              isShared,
              isShared
                ? {
                    everyone: accessOption === "all",
                    limitedTo:
                      accessOption === "all"
                        ? null
                        : {
                            principals: limitedTo,
                          },
                  }
                : null,
            );
          })
          .catch(() => {
            // Validation error, nothing to do
          });
      }}
      width="600px"
    >
      <DirtyDataGuard dirty={touched}>
        <Row style={{ marginTop: 30 }}>
          <Col xs={2}>
            <BlueShareAltOutlined />
          </Col>
          <Col xs={22}>
            <Title>
              <K2Message localeKey={titleKey} />
            </Title>

            <Form
              form={form}
              onValuesChange={() => setTouched(true)}
              initialValues={{
                accessOption: getAccessOption(),
              }}
            >
              <SharedAccess
                initialValues={{
                  accessOption: accessOption,
                  limitedTo: sharedAccess?.limitedTo,
                }}
                onChange={(
                  accessOption: AccessOption,
                  limitedTo?: SecurityPrincipalProfile[],
                ) => {
                  setAccessOption(accessOption);
                  setLimitedTo(limitedTo);
                }}
              />
            </Form>
          </Col>
        </Row>
      </DirtyDataGuard>
    </K2Modal>
  );
};

export default injectK2Intl(EditPermissionsModal);
