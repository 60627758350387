import React, { FC } from "react";

import styled from "styled-components";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Message } from "components/k2Widgets";
import { N3oIcon } from "components/n3oIcon";

const Section = styled.div`
  padding: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.grey_2};
  border-bottom-right-radius: 16px;
  .text-sm {
    font-size: 8px;
    font-weight: ${({ theme }) => theme.font_weight_semi_bold};
    line-height: 12px;
    color: ${({ theme }) => theme.grey_7};
  }
  .hoverable:hover {
    cursor: pointer;
    span,
    path {
      fill: ${({ theme }) => theme.primaryColor} !important;
      color: ${({ theme }) => theme.primaryColor};
    }
  }
  .hoverable-endshift:hover {
    cursor: pointer;
    span,
    path {
      fill: ${({ theme }) => theme.red_6} !important;
      color: ${({ theme }) => theme.red_6};
    }
  }
  .hoverable-break:hover {
    cursor: pointer;
    span,
    path {
      fill: ${({ theme }) => theme.gold_6} !important;
      color: ${({ theme }) => theme.gold_6};
    }
  }
`;

interface BottomSectionProps {
  setOpenCallList: (value: boolean) => void;
  confirmBreak: () => void;
  confirmSignOut: () => void;
}
const BottomSection: FC<BottomSectionProps> = ({
  setOpenCallList,
  confirmBreak,
  confirmSignOut,
}) => {
  const { theme } = useAppContext();
  return (
    <Section>
      <div className={"hoverable"} onClick={() => setOpenCallList(true)}>
        <div>
          <N3oIcon icontype={"n3o-call-history"} fill={theme.grey_7} />
        </div>
        <div className={"text-sm"}>
          <K2Message localeKey={"communication.cc.calls"} />
        </div>
      </div>

      <div className={"hoverable-break"} onClick={confirmBreak}>
        <div>
          <N3oIcon icontype={"n3o-on-break"} fill={theme.grey_7} />
        </div>
        <div className={"text-sm"}>
          <K2Message localeKey={"communication.cc.takeBreak"} />
        </div>
      </div>

      <div className={"hoverable-endshift"} onClick={confirmSignOut}>
        <div>
          <N3oIcon icontype={"n3o-end-shift"} fill={theme.grey_7} />
        </div>
        <div className={"text-sm"}>
          <K2Message localeKey={"communication.cc.signOut"} />
        </div>
      </div>
    </Section>
  );
};

export default BottomSection;
