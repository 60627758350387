import React, { FC } from "react";

import { IN3oIconProps } from "../types";

// Cannot choose colours for Discover brand logo
export const DiscoverCard: FC<IN3oIconProps> = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 160 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0.00136366 6.0097C0.000610567 2.69558 2.68692 0.00852057 6.00104 0.00834107L154 0.000324996C157.314 0.000145508 160 2.68649 160 6.00032V93.997C160 97.3107 157.314 99.997 154 99.997L6.02148 99.9999C2.70826 99.9999 0.0221115 97.3145 0.0213586 94.0012L0.00136366 6.0097Z"
        fill="white"
      />
      <path
        d="M160.18 55.8594C160.18 55.8594 116.044 86.6675 35.2097 100.456H160.18V55.8594Z"
        fill="#F48120"
      />
      <path
        d="M83.8781 54.2898C90.7937 54.2898 96.4006 48.7436 96.4006 41.902C96.4006 35.0604 90.7937 29.5142 83.8781 29.5142C76.9618 29.5142 71.355 35.0604 71.355 41.902C71.355 48.7436 76.9618 54.2898 83.8781 54.2898Z"
        fill="#F48120"
      />
      <path
        d="M12.8845 30.1776H6.14429V53.4641H12.8488C16.4139 53.4641 18.9882 52.6322 21.2479 50.7757C23.9332 48.5776 25.5214 45.2639 25.5214 41.8369C25.5221 34.9652 20.3312 30.1776 12.8845 30.1776ZM18.2486 47.6707C16.8056 48.9594 14.932 49.5216 11.9652 49.5216H10.7331V34.1233H11.9652C14.932 34.1233 16.7324 34.6482 18.2486 36.0063C19.8368 37.4047 20.7918 39.5726 20.7918 41.8029C20.7918 44.0388 19.8368 46.2729 18.2486 47.6707Z"
        fill="#231F20"
      />
      <path
        d="M32.2265 30.1776H27.6345V53.4641H32.2265V30.1776Z"
        fill="#231F20"
      />
      <path
        d="M43.4542 39.1137C40.6989 38.1054 39.8899 37.4399 39.8899 36.1821C39.8899 34.7149 41.3315 33.6007 43.3102 33.6007C44.6864 33.6007 45.8165 34.1597 47.0129 35.4863L49.4153 32.3744C47.441 30.6659 45.0787 29.7925 42.498 29.7925C38.3329 29.7925 35.1558 32.6536 35.1558 36.465C35.1558 39.6734 36.6351 41.3157 40.9474 42.8508C42.7452 43.4779 43.66 43.8957 44.1219 44.1767C45.0393 44.7692 45.4986 45.6086 45.4986 46.586C45.4986 48.4728 43.9824 49.8706 41.9335 49.8706C39.7433 49.8706 37.9792 48.7873 36.9224 46.765L33.9562 49.5908C36.0719 52.6624 38.6125 54.0242 42.1062 54.0242C46.8779 54.0242 50.225 50.8859 50.225 46.3781C50.225 42.6782 48.6775 41.0031 43.4542 39.1137Z"
        fill="#231F20"
      />
      <path
        d="M51.6737 41.8371C51.6737 48.6816 57.1072 53.9892 64.0989 53.9892C66.0757 53.9892 67.7689 53.6047 69.8564 52.6324V47.2851C68.0201 49.1026 66.3945 49.8356 64.3133 49.8356C59.6887 49.8356 56.4065 46.5188 56.4065 41.803C56.4065 37.3324 59.7925 33.8052 64.0995 33.8052C66.2901 33.8052 67.9476 34.5784 69.857 36.4256V31.0808C67.8414 30.0694 66.1845 29.6502 64.2083 29.6502C57.2506 29.6515 51.6737 35.0668 51.6737 41.8371Z"
        fill="#231F20"
      />
      <path
        d="M106.262 45.8191L99.985 30.1776H94.9687L104.959 54.0615H107.429L117.599 30.1776H112.622L106.262 45.8191Z"
        fill="#231F20"
      />
      <path
        d="M119.676 53.4641H132.698V49.5222H124.264V43.2366H132.387V39.2922H124.264V34.1233H132.698V30.1776H119.676V53.4641Z"
        fill="#231F20"
      />
      <path
        d="M150.878 37.0524C150.878 32.6927 147.843 30.1776 142.547 30.1776H135.739V53.4641H140.325V44.1094H140.924L147.279 53.4641H152.926L145.516 43.6538C148.974 42.9581 150.878 40.6181 150.878 37.0524ZM141.666 40.8986H140.326V33.8454H141.74C144.599 33.8454 146.153 35.0307 146.153 37.297C146.153 39.6382 144.599 40.8986 141.666 40.8986Z"
        fill="#231F20"
      />
      <path
        d="M153.809 31.0526C153.809 30.6442 153.525 30.4211 153.026 30.4211H152.361V32.468H152.855V31.674L153.434 32.468H154.037L153.356 31.6242C153.649 31.5467 153.809 31.3368 153.809 31.0526ZM152.939 31.3324H152.856V30.7974H152.944C153.188 30.7974 153.316 30.8843 153.316 31.0608C153.316 31.2411 153.187 31.3324 152.939 31.3324Z"
        fill="#231F20"
      />
      <path
        d="M153.126 29.657C152.121 29.657 151.323 30.4497 151.323 31.4411C151.323 32.4317 152.13 33.2258 153.126 33.2258C154.104 33.2258 154.906 32.4229 154.906 31.4411C154.906 30.4636 154.104 29.657 153.126 29.657ZM153.118 32.9069C152.327 32.9069 151.681 32.2559 151.681 31.4436C151.681 30.6287 152.319 29.9784 153.118 29.9784C153.904 29.9784 154.546 30.6439 154.546 31.4436C154.546 32.249 153.905 32.9069 153.118 32.9069Z"
        fill="#231F20"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="160" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
