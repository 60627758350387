import produce from "immer";
import {
  NewTaskAction,
  NewTaskState,
} from "components/dashboard/newTask/modules/types";
import { ActionTypes } from "components/dashboard/newTask/modules/actionTypes";

const newTaskInitialState: NewTaskState = {
  noteAttachments: [],
  formTouched: false,
};

const newTaskReducer = (
  state: NewTaskState = newTaskInitialState,
  action: NewTaskAction,
): NewTaskState => {
  return produce(state, (draft: NewTaskState) => {
    switch (action.type) {
      case ActionTypes.SET_ATTACHMENT_AS_UPLOADING: {
        draft.noteAttachments.push({
          status: "uploading",
          name: action.file.name,
          uid: action.uid,
          size: action.file.size,
          type: action.file.type,
          originFileObj: action.file,
        });
        break;
      }
      case ActionTypes.SET_UPLOAD_COMPLETE: {
        const uid = action.uid;
        const storageToken = action.response.getResultOrDefault();
        draft.noteAttachments = draft.noteAttachments?.map?.((attachment) => {
          if (attachment.uid === uid) {
            attachment.status = "done";
            attachment.storageToken = storageToken;
          }
          return attachment;
        });
        break;
      }
      case ActionTypes.SET_UPLOAD_ERROR: {
        const uid = action.uid;
        draft.noteAttachments = draft.noteAttachments?.map?.((attachment) => {
          if (attachment.uid === uid) {
            attachment.status = "error";
          }
          return attachment;
        });
        break;
      }
      case ActionTypes.DELETE_ATTACHMENT: {
        const uid = action.uid;
        draft.noteAttachments = draft.noteAttachments.filter((item) => {
          return item.uid !== uid;
        });
        break;
      }
      case ActionTypes.DELETE_ALL_ATTACHMENTS: {
        draft.noteAttachments = [];
        break;
      }
      case ActionTypes.SET_FORM_TOUCHED: {
        draft.formTouched = true;
        break;
      }
      case ActionTypes.CLEAR_FORM_TOUCHED: {
        draft.formTouched = false;
        break;
      }
    }
  });
};

export { newTaskReducer, newTaskInitialState };
export default newTaskReducer;
