import React, { FC } from "react";
import { Menu } from "antd";
import { K2Message } from "components/k2Widgets";
import { useRouter } from "hooks";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import { AutoLoadOption } from "containers/tasksSearch/modules/types";

interface IProps {
  openTaskModal: () => void;
}

const TaskMenu: FC<IProps> = (props): JSX.Element => {
  const router = useRouter();
  const { appId } = useAppContext();

  const navigateToTasksPage = (option: AutoLoadOption) =>
    router.history.push(
      `${
        appId === "k2" ? K2Routes.tasks : Q1Routes.q1Tasks
      }?autoLoad=${option}`,
    );

  return (
    <Menu prefixCls={"ant-dropdown-menu"}>
      <Menu.Item onClick={props.openTaskModal}>
        <K2Message localeKey={"tasksPicker.CreateNewTask"} />
      </Menu.Item>
      <Menu.Item onClick={() => navigateToTasksPage("assignedToMe")}>
        <K2Message localeKey={"tasksPicker.ViewAssignedTasks"} />
      </Menu.Item>
      <Menu.Item onClick={() => navigateToTasksPage("all")}>
        <K2Message localeKey={"tasksPicker.ViewAllTasks"} />
      </Menu.Item>
    </Menu>
  );
};

export default TaskMenu;
