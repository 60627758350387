import React, { FC, useCallback, useEffect, useState } from "react";

import { PlusOutlined, QuestionCircleTwoTone } from "@ant-design/icons/lib";
import {
  AssetRes,
  FileResponse,
} from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { AssetResultsList } from "@n3oltd/karakoram.templates.sdk.assets/src/index";
import { AssetTypeRes } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { Skeleton, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RcFile } from "antd/lib/upload";

import { _assetsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import DownloadHelpers from "common/helpers/downloads";
import FileList from "components/communications/SelectedDocumentsList/FileList";
import { K2Message, K2Tooltip } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { TEMPLATE_ENGINE } from "constants/appConstants";
import {
  PageType,
  TemporaryUploadFile,
} from "routes/admin/communication/modules/types";
import CreateEditAssetModal from "routes/admin/communication/sharedAssets/components/CreateEditAssetModal";
import AddAssetModal from "routes/admin/communication/sharedComponents/AddAssetModal";
import AssetTable from "routes/admin/communication/sharedComponents/markup/assets/AssetTable";

interface IProps extends InjectedK2IntlProps {
  pageType: PageType;
  templateId?: string;
  assetTypes: AssetTypeRes[];
  onUpload: (upload: TemporaryUploadFile, originalFile: RcFile) => void;
  updateTemporaryAssets: (upload: TemporaryUploadFile) => void;
  removeTemporaryAsset: (tempAsset: TemporaryUploadFile) => void;
  temporaryUploadFiles: TemporaryUploadFile[];
  clearTemporaryAssets: () => void;
  updateDirty: (dirty: boolean) => void;
  tempAssets: AssetRes[];
  setTempAssets: (tempAssets: AssetRes[]) => void;
  originalAssetIds: string[];
}

// If templateId provided, fetch all the assets which are referenced by this template
// These become the default list
// If an asset is removed, remove from temp assets list
// If a new asset is added, add it to temp assets list
// When saving the asset, just take the IDs from the temp assets list

const MediaAssetsSelector: FC<IProps> = ({
  pageType,
  assetTypes,
  onUpload,
  templateId,
  updateTemporaryAssets,
  removeTemporaryAsset,
  temporaryUploadFiles,
  clearTemporaryAssets,
  updateDirty,
  k2Intl,
  tempAssets,
  setTempAssets,
  originalAssetIds,
}) => {
  const [modalShowing, setModalShowing] = useState<boolean>(false);
  const [form] = useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [currentEditAssetId, setCurrentEditAssetId] = useState<string>(null);
  const [updatingAsset, setUpdatingAsset] = useState<boolean>(false);
  const fetchRelatedAssets = useCallback(
    async (templateId: string) => {
      setLoading(true);

      const resp: IApiResponse<AssetResultsList> = await K2RestService.toResponse(
        _assetsClient.findLinkedToTemplate(templateId, TEMPLATE_ENGINE, null),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        setTempAssets(
          checkAgainstMediaAssetIds(
            resp.getResultOrDefault().items,
            originalAssetIds,
          ),
        );
      }

      setLoading(false);
    },
    // eslint-disable-next-line
    [k2Intl, setTempAssets, originalAssetIds],
  );

  const downloadMediaAsset = useCallback(
    async (assetId: string, filename: string) => {
      const resp: IApiResponse<FileResponse> = await K2RestService.toResponse(
        _assetsClient.downloadFile(assetId, TEMPLATE_ENGINE, null),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        DownloadHelpers.downloadFile(resp, filename);
      }
    },
    [k2Intl],
  );
  const checkAgainstMediaAssetIds = useCallback(
    (mediaAssets: AssetRes[], mediaAssetIds: string[]) => {
      return mediaAssets.filter((asset) => {
        return mediaAssetIds.includes(asset.id);
      });
    },
    [],
  );

  const removeAsset = useCallback(
    async (assetId: string) => {
      setTempAssets(tempAssets.filter((t) => t.id !== assetId));
      updateDirty(true);
    },
    [tempAssets, updateDirty, setTempAssets],
  );

  useEffect(() => {
    if (templateId) {
      fetchRelatedAssets(templateId);
    }
    // eslint-disable-next-line
  }, [templateId]);

  const updateMediaAsset = async (
    assetId: string,
    assetRevisionId: string,
    notes?: string,
    storageToken?: string,
  ) => {
    setUpdatingAsset(true);
    try {
      const resp: IApiResponse<void> = await K2RestService.toResponse(
        _assetsClient.updateAsset(assetRevisionId, TEMPLATE_ENGINE, null, {
          notes: notes,
          file: storageToken,
        }),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
        setUpdatingAsset(false);

        return;
      } else {
        const response: IApiResponse<AssetRes> = await K2RestService.toResponse(
          _assetsClient.getAssetById(assetId, TEMPLATE_ENGINE, null),
        );
        if (resp.error) {
          UIUtils.handleServerError(k2Intl, resp.error);
          setUpdatingAsset(false);

          return;
        } else {
          let updatedAssetRes = response.getResultOrDefault();
          setTempAssets(
            tempAssets.map((x) => {
              return x.id === updatedAssetRes.id ? updatedAssetRes : x;
            }),
          );
        }
      }
      setUpdatingAsset(false);
      setShowEditModal(false);
      setCurrentEditAssetId(null);
    } catch (e) {
      setUpdatingAsset(false);
      setShowEditModal(false);
    }
  };

  return (
    <FileList>
      {modalShowing && (
        <AddAssetModal
          loading={assetTypes.length === 0}
          assetTypes={assetTypes}
          onSave={(
            newAssets: TemporaryUploadFile[],
            existingAssets: AssetRes[],
          ) => {
            clearTemporaryAssets();
            setModalShowing(false);
            const newExistingAssets = existingAssets.filter(
              (x) => !tempAssets.find((a) => a.id === x.id),
            );
            setTempAssets([
              ...tempAssets,
              ...newAssets?.map?.((f) => f.savedAsset),
              ...newExistingAssets,
            ]);
            updateDirty(true);
          }}
          onCancel={() => {
            setModalShowing(false);
            clearTemporaryAssets();
          }}
          pageType={pageType}
          onUpload={onUpload}
          updateTemporaryAssets={updateTemporaryAssets}
          removeTemporaryAsset={removeTemporaryAsset}
          temporaryUploadFiles={temporaryUploadFiles}
          currentlySelectedAssets={tempAssets}
        />
      )}
      {showEditModal && (
        <CreateEditAssetModal
          k2Intl={k2Intl}
          form={form}
          onCancel={() => {
            form?.resetFields();
            setShowEditModal(false);
            clearTemporaryAssets();
          }}
          onUpdate={updateMediaAsset}
          editMode
          asset={tempAssets.find((x) => x.id === currentEditAssetId)}
          saving={updatingAsset}
          assetTypes={assetTypes}
          errors={[]}
          titleKey="admin.communication.assets.edit"
          selectedAsset={tempAssets.find((x) => x.id === currentEditAssetId)}
          hideDelete
        />
      )}
      <Skeleton loading={loading} active>
        <div className={"header"}>
          <h3>
            <K2Message localeKey={"admin.communication.mediaAssets"} />
          </h3>
          <K2Tooltip
            title={
              <K2Message
                localeKey={`admin.communication.${pageType}s.mediaAssets.explanation`}
              />
            }
          >
            <QuestionCircleTwoTone />
          </K2Tooltip>
        </div>

        <AssetTable
          assets={tempAssets}
          onRemove={(assetId: string) => removeAsset(assetId)}
          pageType={pageType}
          showUsage={true}
          onDownload={downloadMediaAsset}
          onEdit={(assetId: string) => {
            setCurrentEditAssetId(assetId);
            setShowEditModal(true);
          }}
        />

        <FakeLink>
          <div className={"add-button"} onClick={() => setModalShowing(true)}>
            <PlusOutlined />
            <K2Message localeKey={"admin.communication.mediaAssets.add"} />
          </div>
        </FakeLink>
      </Skeleton>
    </FileList>
  );
};

export default injectK2Intl(MediaAssetsSelector);
