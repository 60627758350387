import React, { FC } from "react";

import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { Col, Popover, Row, Typography } from "antd";
import moment from "moment";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import DateHelpers from "common/helpers/dates";
import DeleteIcon from "components/deleteIcon";
import EditLinkIcon from "components/editLinkIcon";
import { K2Message, K2Tooltip } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";
import { PageType } from "routes/admin/communication/modules/types";
import {
  ListRowDivider,
  VariableUsage,
} from "routes/admin/communication/newEditPartialOrLayout/components/shared";
import { ListColTitle } from "routes/admin/communication/sharedComponents/ListColTitle";

const { Text } = Typography;

interface AssetTableProps extends InjectedK2IntlProps {
  assets: AssetRes[];
  onRemove: (assetId: string) => void;
  onEdit?: (assetId: string) => void;
  onDownload: (assetId: string, filename: string) => void;
  pageType: PageType;
  showUsage: boolean;
}

const AssetTable: FC<AssetTableProps> = ({
  assets,
  k2Intl,
  pageType,
  onRemove,
  onDownload,
  onEdit,
  showUsage,
}) => {
  const nonSharedAssets = assets.filter((a) => !a.shared);
  const sharedAssets = assets.filter((a) => a.shared);

  const { theme } = useAppContext();
  return (
    <div>
      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col span={showUsage ? 9 : 17}>
          <ListColTitle>
            {k2Intl?.formatMessage({
              localeKey: "common.filename",
            })}
          </ListColTitle>
        </Col>
        <Col span={3}>
          <ListColTitle>
            {k2Intl?.formatMessage({
              localeKey: "common.fileSize",
            })}
          </ListColTitle>
        </Col>
        {showUsage && (
          <Col span={8}>
            <ListColTitle>
              {k2Intl?.formatMessage({
                localeKey: "admin.communication.usage",
              })}
            </ListColTitle>
          </Col>
        )}
        <Col span={1} />
        <Col span={1} />
        <Col span={1} />
      </Row>
      <Row style={{ marginTop: 20, marginBottom: 14, display: "block" }}>
        <ListRowDivider
          text={k2Intl?.formatMessage({
            localeKey: `admin.communication.${pageType}s.mediaAssets.uploadedNew`,
          })}
        />
        <div>
          {nonSharedAssets.length === 0 && (
            <div style={{ marginBottom: 25 }}>
              <K2Message localeKey={"common.none"} />
            </div>
          )}
          {nonSharedAssets?.map?.((asset) => (
            <Row gutter={16} key={asset.id} style={{ marginBottom: 20 }}>
              <Col span={showUsage ? 9 : 17}>
                <FakeLink
                  onClick={() => {
                    onDownload(asset.id, asset.file?.name);
                  }}
                >
                  {asset.file.name}
                </FakeLink>
              </Col>
              <Col span={3}>{asset.file.size.text}</Col>
              {showUsage && (
                <Col span={8}>
                  <VariableUsage>
                    <Text copyable>{asset.usageSyntax}</Text>
                  </VariableUsage>
                </Col>
              )}

              {asset.notes ? (
                <Col span={1}>
                  <K2Tooltip title={asset.notes}>
                    <N3oIcon
                      icontype="n3o-task-note"
                      width="16px"
                      height="16px"
                      fill={theme.icon_grey_light}
                    />
                  </K2Tooltip>
                </Col>
              ) : (
                <Col span={1} />
              )}

              <Col span={1}>
                <DeleteIcon onClick={() => onRemove(asset.id)} />
              </Col>
              <Col span={1}>
                <FakeLink onClick={() => onEdit(asset.id)}>
                  <EditLinkIcon />
                </FakeLink>
              </Col>
            </Row>
          ))}
        </div>

        <ListRowDivider
          text={k2Intl?.formatMessage({
            localeKey: "admin.communication.assets.title",
          })}
        />
        <div>
          {sharedAssets.length === 0 && (
            <div style={{ marginBottom: 25 }}>
              <K2Message localeKey={"common.none"} />
            </div>
          )}
          {sharedAssets
            .filter((a) => a.shared)
            ?.map?.((asset) => (
              <Row gutter={16} style={{ marginBottom: 20 }} key={asset.id}>
                <Col span={showUsage ? 9 : 17}>
                  <FakeLink
                    onClick={() => {
                      onDownload(asset.id, asset.file?.name);
                    }}
                  >
                    {asset.file.name}
                  </FakeLink>
                </Col>
                <Col span={3}>{asset.file.size.text}</Col>
                {showUsage && (
                  <Col span={8}>
                    <VariableUsage>
                      <Text copyable>{asset.usageSyntax}</Text>
                    </VariableUsage>
                  </Col>
                )}
                {asset.notes ? (
                  <Col span={1}>
                    <K2Tooltip title={asset.notes}>
                      <N3oIcon
                        icontype="n3o-task-note"
                        width="16px"
                        height="16px"
                        fill={theme.icon_grey_light}
                      />
                    </K2Tooltip>
                  </Col>
                ) : (
                  <Col span={1} />
                )}

                <Col span={1}>
                  <DeleteIcon
                    onClick={() => onRemove(asset.id)}
                    tooltipKey={"common.remove"}
                  />
                </Col>
              </Row>
            ))}
        </div>
      </Row>
    </div>
  );
};

export default injectK2Intl(AssetTable);
