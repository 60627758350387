import { LookupType as CallsLookupType } from "@n3oltd/k2.calls.sdk.lookups";
import { LookupType as SubscriptionLookups } from "@n3oltd/k2.subscriptions.sdk.lookups/esm";
import { compose } from "redux";

import injectLookups from "appRedux/utils/injectLookups";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

import Dialler from "./Dialler";
import { withConnect } from "./connect";

const withLookups = injectLookups(Dialler, [
  CallsLookupType.CallSkipReasons,
  CallsLookupType.CallEndedReasons,
  CallsLookupType.CallDispositions,
  SubscriptionLookups.Timezones,
]);

export default injectK2Intl(compose(withConnect)(withLookups));
