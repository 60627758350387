import produce from "immer";

import { ActionTypes, ReportingPeriodAction } from "./actionTypes";
import { ReportingPeriodsState } from "./types";

const initialState: ReportingPeriodsState = {
  data: {
    items: [],
    continuationToken: "",
  },
  loadError: null,
  loading: false,
  savingNew: false,
  savingSortOrder: false,
  editStatus: null,
  selectedReportingPeriod: {
    data: null,
    saving: false,
    editStatus: null,
    deleteing: false,
  },
};

const reportingPeriodsReducer = (
  state = initialState,
  action: ReportingPeriodAction,
) =>
  produce(state, (draft: ReportingPeriodsState) => {
    switch (action.type) {
      case ActionTypes.FIND_REPORTING_PERIODS:
        draft.loading = true;
        break;

      case ActionTypes.FIND_REPORTING_PERIODS_SUCCESS:
        draft.loading = false;
        draft.savingSortOrder = false;
        if (action.shouldAppendToList) {
          draft.data = {
            continuationToken: action.data.continuationToken,
            items: [...draft.data.items, ...action.data.items],
          };
        } else {
          draft.data = action.data;
        }
        break;

      case ActionTypes.FIND_REPORTING_PERIODS_ERROR:
        draft.loading = false;
        draft.loadError = action.error;
        break;

      case ActionTypes.SET_SELECTED_REPORTING_PERIOD:
        draft.selectedReportingPeriod.data = action.selectedReportingPeriod;
        break;

      case ActionTypes.UPDATE_SORT_ORDER:
        draft.savingSortOrder = true;
        break;
      case ActionTypes.UPDATE_SORT_ORDER_SUCCESS:
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.UPDATE_SORT_ORDER_ERROR:
        draft.savingSortOrder = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.CREATE_REPORTING_PERIOD:
        draft.savingNew = true;
        break;
      case ActionTypes.CREATE_REPORTING_PERIOD_SUCCESS:
        draft.data = {
          items: [...draft.data.items, action.data],
        };
        draft.savingNew = false;
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.CREATE_REPORTING_PERIOD_ERROR:
        draft.savingNew = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.UPDATE_REPORTING_PERIOD:
        draft.selectedReportingPeriod.saving = true;
        break;
      case ActionTypes.UPDATE_REPORTING_PERIOD_SUCCESS:
      case ActionTypes.UPDATE_REPORTING_PERIOD_ERROR:
        draft.selectedReportingPeriod.saving = false;
        draft.selectedReportingPeriod.editStatus = action.editStatus;
        break;

      case ActionTypes.DELETE_REPORTING_PERIOD:
        draft.selectedReportingPeriod.deleteing = true;
        break;
      case ActionTypes.DELETE_REPORTING_PERIOD_SUCCESS:
        draft.selectedReportingPeriod.deleteing = false;
        draft.selectedReportingPeriod.editStatus = action.editStatus;
        draft.data = {
          items: draft.data.items.filter((i) => i.id !== action.id),
          continuationToken: draft.data.continuationToken,
        };
        break;
      case ActionTypes.DELETE_REPORTING_PERIOD_ERROR:
        draft.selectedReportingPeriod.deleteing = false;
        draft.selectedReportingPeriod.editStatus = action.editStatus;
        break;

      case ActionTypes.CLEAR_SELECTED_REPORTING_PERIODS_EDIT_STATUS:
        draft.selectedReportingPeriod.editStatus = null;
        draft.editStatus = null;
        break;
      case ActionTypes.CLEAR_REPORTING_PERIODS_EDIT_STATUS:
        draft.selectedReportingPeriod.editStatus = null;
        draft.editStatus = null;
        break;
    }
  });

export default reportingPeriodsReducer;
