import React, { FC, PureComponent } from "react";

import { SubscriptionProfile } from "@n3oltd/k2.users.sdk.users/esm";
import styled from "styled-components";

import { default as k2Variables } from "common/themeVariables";
import { K2TypePicker } from "components/k2Widgets";
import { K2TypePickerData } from "components/k2Widgets/k2TypePicker";
import { N3oIcon } from "components/n3oIcon";
import { default as q1Variables } from "routes/Q1/common/themeVariables";

const Container = styled.div`
  height: 100vh;
  padding-bottom: 32px;
`;

interface IK2PickerProps extends ISubscriptionPickerProps {}

class K2Picker extends PureComponent<IK2PickerProps> {
  render() {
    const { subscriptions, onSubscriptionSelected } = this.props;

    const types: K2TypePickerData[] = subscriptions?.map?.((sub) => {
      return {
        key: sub.subscriptionId,
        icon: (
          <N3oIcon
            icontype={
              sub.application.id === "k2" ? "n3o-engage-icon" : "n3o-tally-icon"
            }
            width={50}
            height={50}
            fill={
              sub.application.id === "k2"
                ? k2Variables.primaryColor
                : q1Variables.primaryColor
            }
          />
        ),
        title: `${sub.subscriptionName} (${sub.application.name})`,
      };
    });

    return (
      <K2TypePicker
        titleKey="admin.subscriptionSelectMessage"
        items={types}
        onItemSelected={(item) => {
          onSubscriptionSelected(
            subscriptions.find((x) => x.subscriptionId === item.key),
            true,
          );
        }}
      />
    );
  }
}

interface ISubscriptionPickerProps {
  subscriptions: SubscriptionProfile[];
  onSubscriptionSelected: (
    subscription: SubscriptionProfile,
    updateCookieAndRedirect?: boolean,
  ) => void;
}

const SubscriptionPicker: FC<ISubscriptionPickerProps> = (props) => {
  return (
    <Container>
      <K2Picker {...props} />
    </Container>
  );
};

export default SubscriptionPicker;
