import { useEffect, useState } from "react";

import { ListRes } from "@n3oltd/karakoram.lists.sdk.lists";

import { _listsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

export function useGetListById(
  k2Intl: InjectedK2Intl,
  listId: string,
): {
  list: ListRes;
  loadingList: boolean;
} {
  const [list, setList] = useState<ListRes>(null);
  const [loadingList, setLoadingList] = useState<boolean>(true);
  useEffect(() => {
    const getListById = async () => {
      setLoadingList(true);
      const resp: IApiResponse<ListRes> = await K2RestService.toResponse(
        _listsClient.getListById(listId),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        setList(resp.getResultOrDefault());
      }
      setLoadingList(false);
    };
    if (listId) {
      getListById();
    }
    //TODO: Remove afer refactoring/updating  k2Intl and UIUtils
    // eslint-disable-next-line
  }, [listId]);

  return {
    list,
    loadingList,
  };
}
export default useGetListById;
