import {
  SmsCompositionReq,
  SmsCompositionRes,
} from "@n3oltd/karakoram.templates.sdk.sms/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

export enum ActionTypes {
  FETCH_SMS_TEMPLATE = "@engage/admin/communication/sms-template/request",
  FETCH_SMS_TEMPLATE_SUCCESS = "@engage/admin/communication/sms-template/success",
  FETCH_SMS_TEMPLATE_ERROR = "@engage/admin/communication/sms-template/error",
  CLEAR_SMS_TEMPLATE = "@engage/admin/communication/sms-template/clear",
  CLEAR_SMS_TEMPLATE_ERROR = "@engage/admin/communication/sms-template/clear-error",

  UPDATE_SMS_TEMPLATE = "@engage/admin/communication/sms-template/update/request",
  UPDATE_SMS_TEMPLATE_SUCCESS = "@engage/admin/communication/sms-template/update/success",
  UPDATE_SMS_TEMPLATE_ERROR = "@engage/admin/communication/sms-template/update/error",

  CREATE_SMS_TEMPLATE = "@engage/admin/communication/sms-template/create/request",
  CREATE_SMS_TEMPLATE_SUCCESS = "@engage/admin/communication/sms-template/create/success",
  CREATE_SMS_TEMPLATE_ERROR = "@engage/admin/communication/sms-template/create/error",

  DELETE_SMS_TEMPLATE = "@engage/admin/communication/sms-template/delete/request",
  DELETE_SMS_TEMPLATE_SUCCESS = "@engage/admin/communication/sms-template/delete/success",
  DELETE_SMS_TEMPLATE_ERROR = "@engage/admin/communication/sms-template/delete/error",

  CLEAR_SMS_TEMPLATE_EDIT_STATUS = "@engage/admin/communication/sms-template/clear-edit-status",

  UPDATE_DIRTY = "@engage/admin/communication/sms-template/update-dirty",
}

export interface FetchSMSTemplate {
  type: ActionTypes.FETCH_SMS_TEMPLATE;
  id: string;
}

export interface FetchSMSTemplateSuccess {
  type: ActionTypes.FETCH_SMS_TEMPLATE_SUCCESS;
  data: SmsCompositionRes;
}

export interface FetchSMSTemplateError {
  type: ActionTypes.FETCH_SMS_TEMPLATE_ERROR;
  error: ServerError;
}

export interface ClearSMSTemplateError {
  type: ActionTypes.CLEAR_SMS_TEMPLATE_ERROR;
}

export interface ClearSMSTemplate {
  type: ActionTypes.CLEAR_SMS_TEMPLATE;
}

export interface UpdateSMSTemplate {
  type: ActionTypes.UPDATE_SMS_TEMPLATE;
  revisionId: string;
  data: SmsCompositionReq;
  scopeId?: string;
  ignoreWarning?: boolean;
}

export interface UpdateSMSTemplateSuccess {
  type: ActionTypes.UPDATE_SMS_TEMPLATE_SUCCESS;
  editStatus: EditStatus<void>;
}

export interface UpdateSMSTemplateError {
  type: ActionTypes.UPDATE_SMS_TEMPLATE_ERROR;
  editStatus: EditStatus<void>;
}

export interface DeleteSMSTemplate {
  type: ActionTypes.DELETE_SMS_TEMPLATE;
  revisionId: string;
}

export interface DeleteSMSTemplateSuccess {
  type: ActionTypes.DELETE_SMS_TEMPLATE_SUCCESS;
  editStatus: EditStatus<void>;
}

export interface DeleteSMSTemplateError {
  type: ActionTypes.DELETE_SMS_TEMPLATE_ERROR;
  editStatus: EditStatus<void>;
}

export interface CreateSMSTemplate {
  type: ActionTypes.CREATE_SMS_TEMPLATE;
  data: SmsCompositionReq;
  scopeId?: string;
  ignoreWarning?: boolean;
}

export interface CreateSMSTemplateSuccess {
  type: ActionTypes.CREATE_SMS_TEMPLATE_SUCCESS;
  editStatus: EditStatus;
}

export interface CreateSMSTemplateError {
  type: ActionTypes.CREATE_SMS_TEMPLATE_ERROR;
  editStatus: EditStatus;
}

export interface ClearEditStatus {
  type: ActionTypes.CLEAR_SMS_TEMPLATE_EDIT_STATUS;
}

export interface UpdateDirty {
  type: ActionTypes.UPDATE_DIRTY;
  dirty: boolean;
}

export type SMSTemplateAction =
  | FetchSMSTemplate
  | FetchSMSTemplateError
  | FetchSMSTemplateSuccess
  | ClearSMSTemplate
  | ClearSMSTemplateError
  | ClearEditStatus
  | CreateSMSTemplate
  | CreateSMSTemplateError
  | CreateSMSTemplateSuccess
  | UpdateSMSTemplate
  | UpdateSMSTemplateError
  | UpdateSMSTemplateSuccess
  | DeleteSMSTemplate
  | DeleteSMSTemplateError
  | DeleteSMSTemplateSuccess
  | UpdateDirty;
