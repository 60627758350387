import produce from "immer";
import { ActionTypes } from "./actionTypes";

import { MeasuresAction, MeasuresState } from "../types";

const initialState: MeasuresState = {
  loading: false,
  loadingPreview: false,
  error: null,
  measures: [],
  pinnedMeasures: [],
  measurePreview: null,
  open: false,
};

const dashboardMeasuresReducer = (
  state = initialState,
  action: MeasuresAction,
): MeasuresState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.FETCH_MEASURES:
        draft.loading = true;
        break;
      case ActionTypes.FETCH_MEASURES_SUCCESS:
        draft.loading = false;
        draft.measures = action.data;
        break;
      case ActionTypes.FETCH_PINNED_MEASURES:
        draft.loading = true;
        break;
      case ActionTypes.FETCH_PINNED_MEASURES_SUCCESS:
        draft.loading = false;
        draft.pinnedMeasures = action.data;
        break;
      case ActionTypes.FETCH_MEASURE_PREVIEW:
        draft.loadingPreview = true;
        break;
      case ActionTypes.DELETE_PINNED_MEASURE:
        draft.loading = true;
        break;
      case ActionTypes.FETCH_MEASURES_PREVIEW_SUCCESS:
        draft.loadingPreview = false;
        draft.measurePreview = action.data;
        break;
      case ActionTypes.PIN_MEASURE:
        draft.loading = true;
        break;
      case ActionTypes.PIN_MEASURE_SUCCESS:
        draft.loading = false;
        draft.open = false;
        break;
      case ActionTypes.TOGGLE_MODAL:
        draft.open = !state.open;
        break;
    }
  });

export default dashboardMeasuresReducer;
