import { BrandingRes } from "@n3oltd/k2.subscriptions.sdk.subscriptions/src/index";
import chroma from "chroma-js";

import { ColorsType } from "common/contexts/AppProvider/types";
import theme from "common/themeVariables";

export default class ColourHelpers {
  public static getPrimary1(primary: string): string {
    return chroma(primary).brighten(3).desaturate(0.3).hex();
  }
  public static getPrimary2(primary: string): string {
    return chroma(primary).brighten(2).desaturate(0.8).hex();
  }
  public static getPrimary3(primary: string): string {
    return chroma(primary).brighten(1.5).desaturate(0).hex();
  }
  public static getPrimary4(primary: string): string {
    return chroma(primary).brighten(0.7).desaturate(0).hex();
  }
  public static getPrimary5(primary: string): string {
    return chroma(primary).brighten(0.5).desaturate(0).hex();
  }
  public static getPrimary6(primary: string): string {
    return primary;
  }
  public static getPrimary7(primary: string): string {
    return chroma(primary).darken(0.6).desaturate(0).hex();
  }
  public static getPrimary8(primary: string): string {
    return chroma(primary).darken(1.2).desaturate(0.4).hex();
  }
  public static getPrimary9(primary: string): string {
    return chroma(primary).darken(2).desaturate(0.3).hex();
  }
  public static getPrimary10(primary: string): string {
    return chroma(primary).darken(3).desaturate(0.3).hex();
  }
  public static getThemeColors(
    colors: ColorsType,
    branding: BrandingRes,
  ): ColorsType {
    // Replacing
    const res: ColorsType = { ...colors };
    const isOriginal = branding.primaryColor === "#1890FF";

    // Replacing any Hex values which match the Primary/Secondary colours
    Object.keys(res).forEach((key) => {
      if (res[key] === colors?.primaryColor) {
        res[key] = branding?.primaryColor;
      } else if (res[key] === colors?.secondaryColor) {
        res[key] = branding?.secondaryColor;
      }
    });

    // Replacing the Primary 1-10 variants
    res.primary_1 = isOriginal
      ? theme.primary_1
      : this.getPrimary1(branding.primaryColor);
    res.primary_2 = isOriginal
      ? theme.primary_2
      : this.getPrimary2(branding.primaryColor);
    res.primary_3 = isOriginal
      ? theme.primary_3
      : this.getPrimary3(branding.primaryColor);
    res.primary_4 = isOriginal
      ? theme.primary_4
      : this.getPrimary4(branding.primaryColor);
    res.primary_5 = isOriginal
      ? theme.primary_5
      : this.getPrimary5(branding.primaryColor);
    res.primary_6 = isOriginal
      ? theme.primary_6
      : this.getPrimary6(branding.primaryColor);
    res.primary_7 = isOriginal
      ? theme.primary_7
      : this.getPrimary7(branding.primaryColor);
    res.primary_8 = isOriginal
      ? theme.primary_8
      : this.getPrimary8(branding.primaryColor);
    res.primary_9 = isOriginal
      ? theme.primary_9
      : this.getPrimary9(branding.primaryColor);
    res.primary_10 = isOriginal
      ? theme.primary_10
      : this.getPrimary10(branding.primaryColor);

    res.link_color = isOriginal ? theme.link_color : branding.primaryColor;
    res.link_hover_color = isOriginal
      ? theme.link_hover_color
      : this.getPrimary7(branding.primaryColor);
    res.link_active_color = isOriginal
      ? theme.link_active_color
      : this.getPrimary8(branding.primaryColor);
    res.button_hover_color = isOriginal
      ? theme.button_hover_color
      : res.primary_4;
    res.tile_hover_color = isOriginal ? theme.tile_hover_color : res.primary_2;
    res.processing_color = isOriginal
      ? theme.processing_color
      : branding.primaryColor;

    return res;
  }
}
