import React from "react";
import { K2Message } from "components/k2Widgets";
import { InboxOutlined } from "@ant-design/icons";
import styled from "styled-components";

const InboxOutlinedWithThemeColour = styled(InboxOutlined)`
  && {
  color: ${({ theme }) => theme.primaryColor};
  font-size: 52px;
  margin-b24px;
  }
`;

const SingleFileUploadExplanation = () => {
  return (
    <>
      <InboxOutlinedWithThemeColour />

      <p className="ant-upload-text">
        <K2Message localeKey="common.uploadMessage" />
      </p>
    </>
  );
};

export default SingleFileUploadExplanation;
