const env = process.env;
const prefix = env.REACT_APP_REGION_PREFIX;

export const Q1Routes = {
  baseRouteEmpty: "/",
  baseRoute: `/${prefix}/tally`,

  // ========== Used for testing purposes ==================
  temporary: `/${prefix}/tally/temporary`,

  // =================== Error Routes ======================
  errors: {
    error: `/${prefix}/tally/error`,
    _401: `/${prefix}/tally/error/401`,
    _404: `/${prefix}/tally/error/404`,
    _500: `/${prefix}/tally/error/500`,
    unresponsive: `/${prefix}/tally/error/unresponsive`,
    accessRevoked: `/${prefix}/tally/error/access-revoked`,
  },

  main: {
    q1Dashboard: `/${prefix}/tally/dashboard`,
    q1TaxRelief: `/${prefix}/tally/tax-relief`,
    q1PayingIn: `/${prefix}/tally/paying-in/deposits`,
    q1DirectDebits: `/${prefix}/tally/direct-debits`,
    q1Analytics: `/${prefix}/tally/analytics`,
    q1Payments: `/${prefix}/tally/payments`,
    q1admin: `/${prefix}/tally/admin`,
  },

  q1ViewDirectDebitCollectionBatch: `/${prefix}/tally/direct-debits/collections/:submissionBatchId`,
  q1ViewDirectDebitInstructionBatch: `/${prefix}/tally/direct-debits/instructions/:submissionBatchId`,

  q1ViewDeposit: `/${prefix}/tally/paying-in/deposits/:depositId`,
  q1ViewDepositPayment: `/${prefix}/tally/paying-in/deposits/:depositId/payments/:paymentId`,

  q1Preferences: `/${prefix}/tally/preferences`,

  //#region PAYMENTS
  q1PaymentsSearch: `/${prefix}/tally/payments/search`,
  q1ViewPayment: `/${prefix}/tally/payments/:paymentId`,
  q1Refunds: `/${prefix}/tally/payments/refunds`,
  q1Reconciliation: `/${prefix}/tally/payments/reconciliation`,
  //#endregion

  //#region ANALYTICS
  q1AnalyticsReportDashboard: `/${prefix}/tally/analytics/reports`,
  q1ReportCategories: `/${prefix}/tally/analytics/reports/categories`,
  q1Reports: `/${prefix}/tally/analytics/reports/:category`,
  q1EmbedReport: `/${prefix}/tally/analytics/reports/:category/:reportId`,
  q1AnalyticsAnalysis: `/${prefix}/tally/analytics/analysis`,
  q1DonationIncomeAnalysis: `/${prefix}/tally/analytics/analysis/donation-income`,

  //#endregion

  //#region TASKS
  q1Tasks: `/${prefix}/tally/tasks`,
  //#endregion

  //#region RECENT ACTIVITIES
  q1RecentActivities: `/${prefix}/tally/recent-activities`,
  //#endregion

  //#region TAX RELIEF
  q1TaxReliefClaims: `/${prefix}/tally/tax-relief/claims`,
  q1ViewEditClaim: `/${prefix}/tally/tax-relief/claims/:claimId`,
  q1TaxReliefDonations: `/${prefix}/tally/tax-relief/donations`,
  q1TaxReliefStatements: `/${prefix}/tally/tax-relief/statements`,

  //#endregion

  q1NewVersion: `/${prefix}/tally/new-version-available`,

  //#region Admin
  q1AdminViewTaxSchemes: `/${prefix}/tally/admin/tax-relief/scheme`,
  q1AdminEditTaxSchemes: `/${prefix}/tally/admin/tax-relief/scheme/:schemeId/edit`,

  //#endregion
};
