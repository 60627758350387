import React, { FC } from "react";

import { UserStatus } from "@n3oltd/k2.users.sdk.users/esm";

import { NamedLookup } from "appRedux/modules/lookups/types";
import K2StatusTag, { K2StatusTagSize } from "components/statusTag/StatusTag";

interface StatusTagProps {
  status: UserStatus;
  userStatuses: NamedLookup[];
  size?: K2StatusTagSize;
}

const UserStatusTag: FC<StatusTagProps> = ({
  status,
  userStatuses,
  ...rest
}) => {
  let color;
  let title;
  const statusesById: { [key: string]: string } = userStatuses.reduce(
    (acc: { [key: string]: string }, item: NamedLookup) => {
      acc[item.id] = item.name;
      return acc;
    },
    {},
  );

  switch (status) {
    case "active":
      title = statusesById[status];
      color = "green";
      break;
    case "blocked":
      title = statusesById[status];
      color = "red";
      break;
    case "deactivated":
      title = statusesById[status];
      color = "default";
      break;
    case "invited":
      title = statusesById[status];
      color = "magenta";
      break;
    default:
      color = "default";
      break;
  }

  return (
    <K2StatusTag color={color} {...rest}>
      {title}
    </K2StatusTag>
  );
};

export default UserStatusTag;
