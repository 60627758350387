import { LookupType as TemplateLookupTypes } from "@n3oltd/karakoram.templates.sdk.lookups";

import injectLookups from "appRedux/utils/injectLookups";

import NewEditPdfStationery from "./NewEditPdfStationery";
import composed from "./connect";

const withLookups = injectLookups(NewEditPdfStationery, [
  TemplateLookupTypes.AssetTypes,
  TemplateLookupTypes.PageSizes,
  TemplateLookupTypes.PageOrientations,
]);

export default composed(withLookups);
