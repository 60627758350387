import React, { FC, useMemo } from "react";
import { matchPath, Redirect, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import Preferences from "routes/preferences";
import MainApp from "containers/app/views/MainApp";
import { MainPageWrapper } from "components/layout/wrappers";
import useWindowWidth from "hooks/useWindowWidth";
import RequireTallyAuth from "routes/Q1/containers/auth/RequireTallyAuth";
import { asyncComponent } from "components/utils";
import _404Error from "components/errors/404";
import ErrorsSubRouter from "routes/Q1/routes/errors";
import PayingInRoutes from "routes/Q1/routes/payingIn";
import moment from "moment";
import LocalizationHelpers from "appRedux/models/localization/helpers";
import { useSelector } from "react-redux";
import IApplicationState from "appRedux/types";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { withRouter, RouteComponentProps } from "react-router";
import {
  FlatRoutes as K2FlatRoutes,
  FlatRoutes as FlatK2Routes,
} from "appRedux/models/routes/RouteModel";
import {
  Q1Routes,
  FlatRoutes as FlatQ1Routes,
  FlatRoutes as Q1FlatRoutes,
} from "appRedux/models/routes/Q1RouteModel";
import AnalyticsSubRouter from "./analytics";
import PaymentsSubRouter from "./payments";
import NewVersion from "containers/otherPages/NewVersion";
import AppManager from "appRedux/AppManager";
import { useEnvironmentContext } from "common/contexts/EnvironmentProvider/EnvironmentProvider";
import Q1Admin from "./admin";

const {
  main: { q1Analytics, q1admin },
} = Q1Routes;

const DashboardWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyDashboard" */ "routes/Q1/routes/dashboard"
      ),
  ),
  "q1Dashboard",
);

const TaxReliefWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyTaxRelief" */ "routes/Q1/routes/taxRelief"
      ),
  ),
  "q1TaxRelief",
);

const DirectDebitsWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyDirectDebits" */ "routes/Q1/routes/directDebits"
      ),
  ),
  "q1DirectDebits",
);

const DirectDebitCollectionWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyViewDirectDebitCollection" */ "routes/Q1/routes/viewDirectDebitCollectionBatch"
      ),
  ),
  "q1ViewDirectDebitCollectionBatch",
);

const DirectDebitInstructionWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyViewDirectDebitInstruction" */ "routes/Q1/routes/viewDirectDebitInstructionBatch"
      ),
  ),
  "q1ViewDirectDebitInstructionBatch",
);

const TasksWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyPaymentReconciliation" */ "containers/tasksSearch"
      ),
  ),
  "q1Tasks",
);

const RecentActivitiesWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallySearchActivities" */ "containers/searchActivities"
      ),
  ),
  "q1RecentActivities",
);

interface IProps extends InjectedK2IntlProps, RouteComponentProps {}

const Routes: FC<IProps> = (props: IProps) => {
  const { location, k2Intl } = props;
  const width: number = useWindowWidth();
  const { pathname } = location;

  const { appId } = useAppContext();
  const { region } = useEnvironmentContext();

  const isOnDashboard =
    location.pathname === FlatK2Routes.dashboard ||
    location.pathname === FlatQ1Routes.q1Dashboard;

  const pageTitle = useMemo(() => {
    const appName = AppManager.getAppName();
    const route = Object.entries(
      appId === "k2" ? K2FlatRoutes : Q1FlatRoutes,
    ).find((r) => {
      if (
        matchPath(pathname, {
          path: r[1],
          exact: true,
          strict: false,
        })
      )
        return r;
      else return null;
    });

    return route?.[0]
      ? k2Intl.formatMessage({ localeKey: route[0] }) + " - " + appName
      : appName;
  }, [pathname, appId, k2Intl]);

  // TODO: This needs to be done once, after the app is wrapped in IntlProvider, but this is not the best place for it.
  const currentUserLocalizationSettings = useSelector(
    (state: IApplicationState) =>
      state.subscription.users.currentUserLocalizationSettings,
  );

  const users = useSelector(
    (state: IApplicationState) => state.subscription.users,
  );
  const currentUser = users.k2User;

  moment.defineLocale(
    currentUserLocalizationSettings.language,
    LocalizationHelpers.getRelativeTimes(
      k2Intl,
      currentUserLocalizationSettings.language,
    ),
  );
  moment.defineLocale(
    `${currentUserLocalizationSettings.language}-short`,
    LocalizationHelpers.getRelativeShortTimes(
      k2Intl,
      currentUserLocalizationSettings.language,
    ),
  );

  moment.locale(currentUserLocalizationSettings.language);

  return (
    <Route path={Q1Routes.baseRoute}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Switch>
        {/* Full Page Components without the usual Navigation etc. */}
        <Route path={Q1Routes.q1NewVersion} exact component={NewVersion} />

        <Route path={`/*`}>
          <MainApp>
            <MainPageWrapper
              id={"route-section"}
              $width={width}
              $appId={appId}
              $isOnDashboard={isOnDashboard}
            >
              <Switch>
                <Route
                  exact
                  path={Q1Routes.main.q1Dashboard}
                  component={DashboardWithAuth}
                />
                <Route
                  path={Q1Routes.main.q1TaxRelief}
                  component={TaxReliefWithAuth}
                />
                <Route
                  path={`/${region}/tally/paying-in`}
                  component={PayingInRoutes}
                />
                <Route
                  exact
                  path={Q1Routes.main.q1DirectDebits}
                  component={DirectDebitsWithAuth}
                />
                <Route
                  exact
                  path={Q1Routes.q1ViewDirectDebitCollectionBatch}
                  component={DirectDebitCollectionWithAuth}
                />
                <Route
                  exact
                  path={Q1Routes.q1ViewDirectDebitInstructionBatch}
                  component={DirectDebitInstructionWithAuth}
                />
                <Route
                  path={Q1Routes.main.q1Payments}
                  component={PaymentsSubRouter}
                />

                <Route path={q1Analytics} component={AnalyticsSubRouter} />

                <Route path={Q1Routes.q1Preferences} component={Preferences} />

                <Route
                  path={Q1Routes.errors.error}
                  component={ErrorsSubRouter}
                />

                <Route path={Q1Routes.q1Tasks} component={TasksWithAuth} />

                <Route
                  path={Q1Routes.q1RecentActivities}
                  component={RecentActivitiesWithAuth}
                />
                <Route
                  path={q1admin}
                  render={(routerProps) => (
                    <Q1Admin
                      {...routerProps}
                      {...props}
                      currentUser={currentUser}
                    />
                  )}
                />

                <Redirect
                  exact
                  path={`/${region}/tally`}
                  to={Q1Routes.main.q1Dashboard}
                />

                <Route path={`/${region}/tally/*`} component={_404Error} />
              </Switch>
            </MainPageWrapper>
          </MainApp>
        </Route>
      </Switch>
    </Route>
  );
};

export default injectK2Intl(withRouter(Routes));
