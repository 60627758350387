import React, { FC } from "react";

import { DonationItemRes } from "@n3oltd/k2.donations.sdk.donation-items/esm";
import { FundStructureRevisionRes } from "@n3oltd/k2.subscriptions.sdk.funds/esm";
import { Currency } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { Alert } from "antd";
import { FormInstance } from "antd/es/form";
import styled from "styled-components";

import { K2Routes } from "appRedux/models/routes/RouteModel";
import {
  DonationItemStatusesLookups,
  DonationTypesLookups,
  TaxReliefRatesLookups,
} from "appRedux/modules/lookups/types";
import { DimensionsForm, PricingForm } from "components/admin/shared";
import { ActionsFormItemV4 } from "components/formItems";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

import BasicInfoForm from "./DonationItemBasicInfo";
import DonationItemDetailsPanel from "./DonationItemDetailsPanel/index";

interface DonationItemFormProps extends InjectedK2IntlProps {
  isLoading: boolean;
  onDelete: () => void;
  fundDimensionsData: FundStructureRevisionRes;
  pricingFundDimensions: FundStructureRevisionRes;
  editMode: boolean;
  donationItem?: DonationItemRes;
  baseCurrency: Currency;
  form: FormInstance;
  overallFormErrors: string[];
  dirty: boolean;
  fetchSelectedDonationItem: (id: string) => void;
  pricingRuleErrors?: string[];
  taxReliefRates: TaxReliefRatesLookups;
  donationItemStatuses: DonationItemStatusesLookups;
  donationTypes: DonationTypesLookups;
}

const OverallFormErrors = styled(Alert)`
  && {
    margin-bottom: 20px;
  }
`;

const DonationItemForm: FC<DonationItemFormProps> = (props) => {
  const {
    isLoading,
    fundDimensionsData,
    pricingFundDimensions,
    editMode,
    onDelete,
    donationItem,
    baseCurrency,
    form,
    overallFormErrors,
    fetchSelectedDonationItem,
    dirty,
    pricingRuleErrors,
    taxReliefRates,
    donationItemStatuses,
    donationTypes,
  } = props;

  const values = form.getFieldsValue(true);

  return (
    <>
      {editMode && (
        <DonationItemDetailsPanel
          donationItem={donationItem}
          baseCurrency={baseCurrency}
          onDelete={onDelete}
          fetchSelectedDonationItem={fetchSelectedDonationItem}
          donationItemStatuses={donationItemStatuses.items}
        />
      )}

      <BasicInfoForm
        editMode={editMode}
        initialValues={donationItem}
        taxReliefRates={taxReliefRates.items}
        donationTypes={donationTypes.items}
      />

      <DimensionsForm
        isLoading={isLoading}
        fundDimensionsData={fundDimensionsData}
        initialValues={donationItem?.fundDimensionOptions}
      />

      <PricingForm
        form={form}
        baseCurrency={baseCurrency}
        fundDimensionsData={pricingFundDimensions}
        initialValues={donationItem}
        pricingRuleErrors={pricingRuleErrors}
      />

      {overallFormErrors.length > 0 && (
        <OverallFormErrors
          type="error"
          message={overallFormErrors?.map?.((e, i) => (
            <p key={i}>{e}</p>
          ))}
        />
      )}

      <ActionsFormItemV4
        disabled={!dirty}
        isLoading={isLoading}
        cancelUrl={K2Routes.donationItems}
        type={"submit"}
      />
    </>
  );
};

export default DonationItemForm;
