import React, { FC } from "react";
import { Spin } from "antd";
import { SpinProps } from "antd/lib/spin";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import styled from "styled-components";

interface IProps extends SpinProps, InjectedK2IntlProps {
  tipKey?: string;
}

const StyledSpin = styled(Spin)`
  && {
    .ant-spin-dot-item {
      background-color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const K2Spin: FC<IProps> = (props) => {
  const {
    k2Intl,
    localizationSettings,
    tipKey,
    tip,
    ...remainingProps
  } = props;
  const tipText = tipKey
    ? k2Intl?.formatMessage({
        localeKey: tipKey,
      })
    : tip;

  return <StyledSpin {...remainingProps} tip={tipText} />;
};

export default injectK2Intl(K2Spin);
