import React, { FC, useEffect } from "react";

import { LocalizationSettingsRes } from "@n3oltd/k2.users.sdk.users/esm";
import { DashboardActivityLogResultsList } from "@n3oltd/karakoram.activities.sdk.activities/esm";
import { connect } from "react-redux";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import { fetchDashboardActivities } from "appRedux/modules/recentActivities/actions";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { TitleWrapper } from "components/dashboard";
import { K2Link, K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import RecentActivityList from "components/recentActivities/ActivityList";

interface IActivityProps extends InjectedK2IntlProps {
  fetchDashboardActivities: typeof fetchDashboardActivities;
  recentActivities: {
    data: DashboardActivityLogResultsList;
    fetching: boolean;
    error?: ServerError;
  };
  maxHeight?: number;
  currentUserLocalization: LocalizationSettingsRes;
}

const RecentActivities: FC<IActivityProps> = ({
  fetchDashboardActivities,
  recentActivities,
  currentUserLocalization,
  maxHeight,
  k2Intl,
}) => {
  useEffect(() => {
    // Sending empty criteria here will return all the activity types.
    // But the backend should only send activities that are relevant to the app-id
    fetchDashboardActivities({});
    // eslint-disable-next-line
  }, []);

  const { appId } = useAppContext();

  return (
    <>
      <TitleWrapper level={4}>
        <K2Message localeKey="dashboardTitle.recentActivities" />
      </TitleWrapper>
      <RecentActivityList
        k2Intl={k2Intl}
        loading={recentActivities.fetching}
        currentUserLocalization={currentUserLocalization}
        data={recentActivities?.data?.items}
        maxHeight={maxHeight}
        cardProps={{
          shadow: true,
          actions: !recentActivities.fetching && [
            <K2Link
              to={
                appId === "q1"
                  ? Q1Routes.q1RecentActivities
                  : K2Routes.recentActivities
              }
            >
              <K2Message
                localeKey={"dashboardTitle.recentActivities.viewAll"}
              />
            </K2Link>,
          ],
        }}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    recentActivities: state.activities.dashboardActivities,
    currentUserLocalization:
      state.subscription.users.currentUserLocalizationSettings,
  };
};

const mapDispatchToProps = {
  fetchDashboardActivities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectK2Intl(RecentActivities));
