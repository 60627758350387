import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const Feedbacks: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1900_3524)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4593 1.3093C4.13755 0.631041 5.05747 0.25 6.01667 0.25C6.97587 0.25 7.89578 0.63104 8.57404 1.3093C9.25229 1.98755 9.63333 2.90747 9.63333 3.86667C9.63333 4.82587 9.25229 5.74578 8.57404 6.42404C8.02411 6.97396 7.31533 7.3285 6.55555 7.44298C7.12953 7.49674 7.69598 7.6366 8.23527 7.86122C9 8.17974 9.92451 8.73555 10.5571 9.83755C10.7633 10.1968 10.6393 10.6552 10.28 10.8614C9.92081 11.0676 9.46243 10.9436 9.25621 10.5843C8.91295 9.83755 8.23527 9.48613 7.65852 9.24591C6.79059 8.8844 5.82758 8.81963 4.91906 9.06165C4.01054 9.30368 3.20738 9.83895 2.63432 10.5843C2.06133 11.3296 1.75046 12.2433 1.75 13.1833V14.5833H3.15C3.53531 14.5833 3.85794 14.8753 3.89628 15.2587L4.54541 21.75H7.48786L8.09211 16.7551C8.13329 16.3429 8.50079 16.0422 8.91295 16.0833C9.32511 16.1245 9.62585 16.492 9.58468 16.9042L8.91295 22.5746C8.87464 22.958 8.55201 23.25 8.16667 23.25H3.86667C3.48135 23.25 3.15873 22.958 3.12039 22.5746L2.47126 16.0833H1C0.585786 16.0833 0.25 15.7476 0.25 15.3333V13.1833C0.250534 11.9127 0.67067 10.6774 1.44515 9.67007C2.21962 8.66271 3.30509 7.93929 4.53294 7.6122C4.84448 7.52921 5.16076 7.47291 5.47848 7.44308C4.71844 7.32872 4.00939 6.97413 3.4593 6.42404C2.78104 5.74578 2.4 4.82587 2.4 3.86667C2.4 2.90747 2.78104 1.98755 3.4593 1.3093ZM6.01667 1.75C5.45529 1.75 4.91691 1.97301 4.51996 2.36996C4.12301 2.76691 3.9 3.30529 3.9 3.86667C3.9 4.42804 4.12301 4.96642 4.51996 5.36338C4.91691 5.76033 5.45529 5.98333 6.01667 5.98333C6.57804 5.98333 7.11642 5.76033 7.51338 5.36338C7.91033 4.96642 8.13333 4.42804 8.13333 3.86667C8.13333 3.30529 7.91033 2.76691 7.51338 2.36996C7.11642 1.97301 6.57804 1.75 6.01667 1.75Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6667 6.4834C13.0877 6.4834 12.5323 6.71343 12.1229 7.12288C11.7134 7.53234 11.4834 8.08768 11.4834 8.66673V14.0001C11.4834 14.5791 11.7134 15.1345 12.1229 15.5439C12.5323 15.9534 13.0877 16.1834 13.6667 16.1834H14.1501V18.0001C14.1501 18.3135 14.3226 18.6016 14.599 18.7495C14.8754 18.8974 15.2107 18.8812 15.4716 18.7073L19.2574 16.1834H21.6667C22.2458 16.1834 22.8011 15.9534 23.2106 15.5439C23.62 15.1345 23.8501 14.5791 23.8501 14.0001V8.66673C23.8501 8.08768 23.62 7.53234 23.2106 7.12288C22.8011 6.71343 22.2458 6.4834 21.6667 6.4834H13.6667ZM13.6667 8.1834C13.5385 8.1834 13.4156 8.23432 13.325 8.32496C13.2343 8.41561 13.1834 8.53854 13.1834 8.66673V14.0001C13.1834 14.1283 13.2343 14.2512 13.325 14.3418C13.4156 14.4325 13.5385 14.4834 13.6667 14.4834H15.0001C15.4695 14.4834 15.8501 14.864 15.8501 15.3334V16.4118L18.5286 14.6262C18.6682 14.5331 18.8323 14.4834 19.0001 14.4834H21.6667C21.7949 14.4834 21.9179 14.4325 22.0085 14.3418C22.0991 14.2512 22.1501 14.1283 22.1501 14.0001V8.66673C22.1501 8.53854 22.0991 8.41561 22.0085 8.32496C21.9179 8.23432 21.7949 8.1834 21.6667 8.1834H13.6667ZM15 9.15005C14.5306 9.15005 14.15 9.53061 14.15 10C14.15 10.4695 14.5306 10.85 15 10.85H20.3334C20.8028 10.85 21.1834 10.4695 21.1834 10C21.1834 9.53061 20.8028 9.15005 20.3334 9.15005H15ZM15 11.8167C14.5306 11.8167 14.15 12.1973 14.15 12.6667C14.15 13.1362 14.5306 13.5167 15 13.5167H18.3334C18.8028 13.5167 19.1834 13.1362 19.1834 12.6667C19.1834 12.1973 18.8028 11.8167 18.3334 11.8167H15Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1900_3524">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
