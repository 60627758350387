import React, { FC } from "react";

import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { Layout } from "antd";
import { positionValues } from "react-custom-scrollbars";
import styled from "styled-components";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import AppFooter from "components/footer";
import { K2ErrorBoundary } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import Navigation from "components/navigation";
import Header from "components/navigation/mainAppHeader/Header";
import MainNavigation from "components/navigation/mainNavigation";
import { CustomScrollbars } from "components/utils";
import Sidebar from "containers/sidebar";
import SidebarMenu from "containers/sidebar/SidebarMenu";
import { footerText } from "util/Config";

const { Content } = Layout;

const StyledContent = styled(Content)`
  && {
    background: ${({ theme }) => theme.inner_layout_background};
    width: 92vw;
    max-width: ${({ theme }) => theme.max_content_width};
    margin: auto;
    border-radius: 8px;

    @media screen and (max-width: ${({ theme }) => theme.screen_lg_min}) {
      width: 100vw;
    }

    @media screen and (max-width: ${({ theme }) => theme.screen_tab}) {
      border-radius: 0;
    }
  }

  .ant-layout-footer {
    padding: 14px 4%;
    text-align: center;
  }
`;

const CustomScrollbarsWithBackground = styled(CustomScrollbars)`
  && {
    background-color: ${({ theme }) => theme.outer_layout_background};
  }
`;

interface AppLayoutProps extends InjectedK2IntlProps {
  isTabScreen: boolean;
  isMobileScreen: boolean;
  onScroll: (values: positionValues) => void;
  currentUser: CurrentUserRes;
  taskCount: number;
}

const AppLayout: FC<AppLayoutProps> = ({
  isTabScreen,
  isMobileScreen,
  onScroll,
  currentUser,
  taskCount,
  k2Intl,
  children,
}) => {
  const { appId } = useAppContext();

  return (
    <>
      {isTabScreen && (
        <Sidebar>
          <SidebarMenu>
            <MainNavigation sidebarMode={true} />
          </SidebarMenu>
        </Sidebar>
      )}
      <Layout style={{ height: "100vh" }}>
        <CustomScrollbarsWithBackground
          autoHide
          onScrollFrame={onScroll}
          className={`custom-scrollbar-style main-container ${appId}-scroll`}
          id={"custom-scrollbar"}
        >
          <Header />
          <StyledContent id={"n3o-container"} className={"n3o-container"}>
            <Navigation
              user={currentUser}
              taskCountForUser={taskCount}
              isTabScreen={isTabScreen}
              isMobileScreen={isMobileScreen}
            />

            <K2ErrorBoundary appId={appId} k2Intl={k2Intl}>
              {children}
            </K2ErrorBoundary>
            <AppFooter>{footerText}</AppFooter>
          </StyledContent>
        </CustomScrollbarsWithBackground>
      </Layout>
    </>
  );
};

export default injectK2Intl(AppLayout);
