import React, { Component } from "react";

import { Empty, Spin } from "antd";
import ScriptLoader from "react-script-loader-hoc";

import K2Message from "../k2Localizations/K2Message";

//#region LOADER CHILD
type K2ScriptLoaderInnerProps = K2ScriptLoaderState;

class LoaderChild extends Component<K2ScriptLoaderInnerProps & DelegateProps> {
  componentDidUpdate() {
    const {
      scriptsLoaded,
      scriptsLoadedSuccessfully,
      asyncOnScriptsLoaded,
      asyncOnScriptsLoadedSuccessfully,
    } = this.props;

    if (scriptsLoaded) asyncOnScriptsLoaded();
    if (scriptsLoadedSuccessfully) asyncOnScriptsLoadedSuccessfully();
  }

  render() {
    return <span />; //Don't need this but have to return something
  }
}
//#endregion

//#region SCRIPT LOADER INTERNAL
type DelegateProps = {
  asyncOnScriptsLoaded: () => Promise<void>;
  asyncOnScriptsLoadedSuccessfully: () => Promise<void>;
};

const K2ScriptLoaderInternal = ({
  url,
  asyncOnScriptsLoaded,
  asyncOnScriptsLoadedSuccessfully,
}: DelegateProps & K2ScriptLoaderProps) => {
  const AsyncScriptComponent = ScriptLoader(url)(LoaderChild);

  return (
    <AsyncScriptComponent
      asyncOnScriptsLoaded={asyncOnScriptsLoaded}
      asyncOnScriptsLoadedSuccessfully={asyncOnScriptsLoadedSuccessfully}
    />
  );
};
//#endregion

//#region K2 SCRIPT LOADER
type K2ScriptLoaderProps = {
  /** script URL */
  url: string;

  /** here we can just do whatever is needed after script(s) are loaded */
  onLoad?: () => void;
};

interface K2ScriptLoaderState {
  scriptsLoaded: boolean;
  scriptsLoadedSuccessfully: boolean;
}

class K2ScriptLoader extends Component<
  K2ScriptLoaderProps,
  K2ScriptLoaderState
> {
  constructor(props: K2ScriptLoaderProps) {
    super(props);

    this.state = {
      scriptsLoaded: false,
      scriptsLoadedSuccessfully: false,
    };
  }

  asyncOnScriptsLoaded = async () => {
    if (!this.state.scriptsLoaded) {
      this.setState({ scriptsLoaded: true });
    }
  };

  asyncOnScriptsLoadedSuccessfully = async () => {
    if (!this.state.scriptsLoadedSuccessfully)
      this.setState({ scriptsLoadedSuccessfully: true }, () => {
        this.props.onLoad?.();
      });
  };

  render() {
    const { children, ...rest } = this.props;
    const { scriptsLoaded, scriptsLoadedSuccessfully } = this.state;

    return (
      <Spin spinning={!scriptsLoaded}>
        {!scriptsLoaded && (
          <K2ScriptLoaderInternal
            {...rest}
            asyncOnScriptsLoaded={this.asyncOnScriptsLoaded}
            asyncOnScriptsLoadedSuccessfully={
              this.asyncOnScriptsLoadedSuccessfully
            }
          />
        )}
        {scriptsLoaded && !scriptsLoadedSuccessfully && (
          <Empty description="Unable to load script(s)" />
        )}
        {!scriptsLoadedSuccessfully && (
          <Empty description={<K2Message localeKey={"common.loading"} />} />
        )}
        {scriptsLoadedSuccessfully && children}
      </Spin>
    );
  }
}
//#endregion

export default K2ScriptLoader;
