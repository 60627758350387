import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const Post: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7 4.20208V6H18.35C19.0048 6 20.1756 6.24047 21.2051 6.94499C22.2801 7.68071 23.2 8.92863 23.2 10.85V18.8C23.2 19.0209 23.0209 19.2 22.8 19.2H13.7V23.45C13.7 23.6709 13.5209 23.85 13.3 23.85H11.4C11.1791 23.85 11 23.6709 11 23.45V19.2H1.4C1.17909 19.2 1 19.0209 1 18.8V10.85C1 9.07143 1.81529 7.83067 2.83789 7.05875C3.8254 6.31331 5.00705 6 5.85 6H14L14 1L15.7 1L18.35 2.5L15.7 4.20208ZM14.4 11.35C14.1791 11.35 14 11.1709 14 10.95V7.7H10.711C10.7459 7.74164 10.7799 7.78371 10.8132 7.82618C11.5907 8.81985 11.8981 9.99371 11.9483 10.797L11.95 10.8235V17.5H21.1C21.3209 17.5 21.5 17.3209 21.5 17.1V10.85C21.5 9.56189 20.9199 8.80981 20.2449 8.34791C19.5244 7.85479 18.6952 7.7 18.35 7.7H15.7V10.95C15.7 11.1709 15.5209 11.35 15.3 11.35H14.4ZM3.86211 8.41557C3.21804 8.90176 2.7 9.66099 2.7 10.85V17.1C2.7 17.3209 2.87909 17.5 3.1 17.5H10.25V10.8784C10.2118 10.3476 9.99288 9.53649 9.47434 8.87382C8.97547 8.23629 8.17604 7.7 6.85 7.7H5.85C5.35962 7.7 4.54126 7.9029 3.86211 8.41557Z"
      fill={fill}
    />
  </svg>
);
