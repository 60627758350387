import React, { Component } from "react";

import { Modal, Skeleton, Spin } from "antd";
import { ModalProps } from "antd/es/modal";
import _ from "lodash";
import styled from "styled-components";

import DeleteIcon from "components/deleteIcon";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import CustomButtons from "components/k2Widgets/k2Modal/CustomButtons";
import { CustomScrollbars } from "components/utils";
import { showConfirm } from "components/utils/Confirmation";

import { InjectedK2IntlProps } from "../k2Localizations/types";

const ModalWrapper = styled(Modal)`
  .ant-modal-body {
    padding-right: 10px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .footer-custom-buttons {
    .ant-btn:not(:last-child) {
      margin-right: 0 !important;
    }
  }
`;

const ScrollBodyWrapper = styled.div`
  padding: 0 20px 10px 3px;
`;

export interface IModalProps extends ModalProps {
  /** (required) locale key for Ok button, default 'common.ok' */
  okTextKey?: string;
  /** (required) locale key for Cancel button, default 'common.cancel' */
  cancelTextKey?: string;
  /** (required) locale key for Title */
  titleKey?: string;
  /** Extra Text Key to add after Title */
  titleExtraTextKey?: string;
  /** If content is loading then show skeleton */
  loading?: boolean;
  /** If content is disabled then show spinner */
  spinning?: boolean;
  /** For UI reasons, if you want to disable vertical scrolling within the modal */
  disableVerticalScroll?: boolean;
  /** If the modal requires confirmation  before closing, for use on touched forms */
  requiresCloseConfirmation?: boolean;
  confirmationTitleKey?: string;
  confirmationContentKey?: string;
  showDelete?: boolean;
  onDelete?: () => void;
  deleteConfirmationTitleKey?: string;
  deleteConfirmationContentKey?: string;
  hideFooter?: boolean;
  /** this will remove the scroll and min height checks  */
  withOutScroll?: boolean;
  hideCancel?: boolean;
}

class K2Modal extends Component<IModalProps & InjectedK2IntlProps, any> {
  render() {
    const {
      k2Intl,
      okTextKey,
      cancelTextKey,
      titleKey,
      titleExtraTextKey,
      loading,
      spinning,
      children,
      requiresCloseConfirmation,
      confirmationTitleKey = "common.areYouSure",
      confirmationContentKey = "common.changesNotSaved",
      showDelete,
      deleteConfirmationTitleKey = "common.areYouSure",
      deleteConfirmationContentKey,
      disableVerticalScroll,
      maskClosable,
      hideFooter,
      withOutScroll,
      hideCancel = false,
      onDelete,
      onCancel,
      ...rest
    } = this.props;

    let title: string = titleKey
      ? k2Intl?.formatMessage({ localeKey: titleKey })
      : "";
    const titleExtra: string = titleExtraTextKey
      ? k2Intl?.formatMessage({ localeKey: titleExtraTextKey })
      : "";
    const okText: string = okTextKey
      ? k2Intl?.formatMessage({ localeKey: okTextKey })
      : "";
    const cancelText: string = cancelTextKey
      ? k2Intl?.formatMessage({ localeKey: cancelTextKey })
      : "";

    if (titleExtra) {
      title = title + ` (${titleExtra})`;
    }

    const cancelWithConfirm = () => {
      showConfirm({
        titleKey: confirmationTitleKey,
        contentKey: confirmationContentKey,
        onOk: onCancel,
        okTextKey: "common.yes",
        k2Intl,
      });
    };

    const deleteWithConfirm = () => {
      showConfirm({
        titleKey: deleteConfirmationTitleKey,
        contentKey: deleteConfirmationContentKey,
        onOk: () => {
          onDelete?.();
        },
        okTextKey: "common.delete",
        okButtonProps: {
          danger: true,
        },
        k2Intl,
      });
    };

    return (
      <ModalWrapper
        title={title}
        okText={okText}
        cancelText={cancelText}
        destroyOnClose={true}
        maskClosable={maskClosable || false}
        onCancel={
          onCancel
            ? requiresCloseConfirmation
              ? cancelWithConfirm
              : onCancel
            : undefined
        }
        footer={
          !hideFooter && (
            <Footer>
              <div>
                {showDelete && <DeleteIcon onClick={deleteWithConfirm} />}
              </div>
              <CustomButtons
                okTextKey={okTextKey}
                okButtonProps={{
                  ...this.props.okButtonProps,
                  onClick: this.props.onOk || this.props.okButtonProps?.onClick,
                }}
                cancelTextKey={cancelTextKey}
                cancelButtonProps={{
                  onClick: onCancel,
                  ...(this.props.cancelButtonProps || {}),
                }}
                requiresCloseConfirmation={requiresCloseConfirmation}
                hideCancel={hideCancel}
              />
            </Footer>
          )
        }
        {...rest}
      >
        {!withOutScroll && (
          <CustomScrollbars
            autoHide={false}
            autoHeight={true}
            autoHeightMin={rest?.style?.minHeight || 250}
            autoHeightMax={disableVerticalScroll ? "100vh" : "60vh"}
          >
            <ScrollBodyWrapper>
              {loading ? (
                <Skeleton active />
              ) : !_.isUndefined(spinning) ? (
                <Spin spinning={spinning}>{children}</Spin>
              ) : (
                children
              )}
            </ScrollBodyWrapper>
          </CustomScrollbars>
        )}
        {withOutScroll && (
          <ScrollBodyWrapper>
            {loading ? (
              <Skeleton active />
            ) : !_.isUndefined(spinning) ? (
              <Spin spinning={spinning}>{children}</Spin>
            ) : (
              children
            )}
          </ScrollBodyWrapper>
        )}
      </ModalWrapper>
    );
  }
}

export default injectK2Intl(K2Modal);
