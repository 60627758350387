import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import {
  deleteTaskRequest,
  clearDeleteTaskError,
} from "components/dashboard/editTask/modules/actions";
import IApplicationState from "appRedux/types";
import usePrevious from "hooks/usePrevious";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { ServerError } from "appRedux/models/common/ApiResponseModel";
import DeleteTaskIcon from "components/deleteIcon";
import styled from "styled-components";
import { showNotification } from "components/utils/Notification";
import { showConfirm } from "components/utils/Confirmation";

const Container = styled.div<{ disabled: boolean }>`
  pointer-events: ${({ disabled }) =>
    disabled ? "none !important" : "default"};
  && {
    .anticon-delete {
      color: ${({ disabled, theme }) =>
        disabled ? theme.grey_6 : ""} !important;
    }
  }
`;

interface IProps extends InjectedK2IntlProps {
  taskRevisionId: string;
  deleteTask: typeof deleteTaskRequest;
  error?: ServerError;
  deleting: boolean;
  afterDelete: () => void;
  clearError: typeof clearDeleteTaskError;
}

const DeleteTask: FC<IProps> = ({
  taskRevisionId,
  deleteTask,
  error,
  deleting,
  afterDelete,
  k2Intl,
  clearError,
}) => {
  const previouslyDeleting = usePrevious(deleting);

  useEffect(() => {
    if (error) {
      UIUtils.handleServerError(k2Intl, error, clearError);
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (previouslyDeleting && !deleting && !error) {
      showNotification({
        type: "success",
        k2Intl,
        titleKey: "common.success.title",
        messageKey: "tasks.taskDeletedSuccessfully",
      });
      afterDelete();
    }
  }, [previouslyDeleting, deleting, afterDelete, error, k2Intl]);

  const confirmDelete = () => {
    showConfirm({
      titleKey: "tasks.taskDeleteConfirmTitle",
      k2Intl,
      okTextKey: "common.delete",
      okButtonProps: {
        danger: true,
      },
      centered: true,
      onOk: () => {
        deleteTask(taskRevisionId);
      },
    });
  };

  return (
    <Container disabled={deleting}>
      <DeleteTaskIcon
        onClick={confirmDelete}
        tooltipKey={"tasks.deleteTooltip"}
      />
    </Container>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  taskRevisionId: state.editViewTask.task
    ? state.editViewTask.task.revisionId
    : null,
  error: state.editViewTask.errorDeletingTask,
  deleting: state.editViewTask.deleting,
});

const mapDispatchToProps = {
  deleteTask: deleteTaskRequest,
  clearError: clearDeleteTaskError,
};

export default injectK2Intl(
  connect(mapStateToProps, mapDispatchToProps)(DeleteTask),
);
