import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Skeleton, Empty, Col, Divider } from "antd";
import TasksSelectors, {
  IDashboardTaskListStateProps,
} from "appRedux/store/selectors/TasksSelectors";

import TopSection from "components/dashboard/tasksList/components/topSection";
import TaskListItem from "components/dashboard/tasksList/components/item";
import { CustomScrollbars, UIUtils } from "components/utils";
import { K2Message, K2Card, K2Link } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import useDebounce from "hooks/useDebounce";
import styled from "styled-components";
import K2Spin from "components/k2Widgets/k2Spin";
import { TitleWrapper } from "..";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { DEFAULT_DELAY } from "constants/appConstants";
import ExpandableTaskGroupRow from "components/dashboard/tasksList/components/expandableTaskGroupRow";

const TasksWrapper = styled.div`
  padding-right: 12px;

  .ant-empty {
    padding: 140px 0 !important;
  }
`;

const TasksListCard = styled(K2Card)`
  background: #fff;
  border-radius: 8px;
  margin: 24px 0px 24px 0px;
  .ant-badge-count {
    background: #848599;
  }
  .ant-card-extra .ant-row {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .width-100 {
    width: 100%;
  }
  .ant-card-extra {
    .ant-collapse-header {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 8px;
    }

    width: 100%;
    padding: 24px 0 !important;
  }
  .ant-empty {
    padding-top: 20px;
  }

  .ant-card-body {
    padding: 0 12px 0 24px;
    div {
      margin-bottom: 0 !important;
    }
  }
`;

const CustomScrollbarsStyled = styled(CustomScrollbars)`
  && {
    max-height: 100% !important;
    margin-bottom: 50px;
    > div:first-child {
      overflow-x: hidden !important;
      max-height: 413px;
    }
  }
`;

interface ITasksListProps
  extends IDashboardTaskListStateProps,
    InjectedK2IntlProps {}

export const TasksList: FC<ITasksListProps> = ({
  filtersApplied,
  updateDashboardFilters,
  tasksLoading,
  serverError,
  clearServerError,
  tasksData,
  search,
  fetchDashboardTasks,
  updateSearch,
  saving,
  k2Intl,
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const showModal = (): void => {
    setModalVisible(true);
  };

  const debouncedSearchTerm = useDebounce(search, DEFAULT_DELAY);
  const { theme } = useAppContext();

  // This effect runs whenever debouncedSearchTerm changes
  useEffect(() => {
    fetchDashboardTasks();

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const updateSearchTerm = (event: ChangeEvent<HTMLInputElement>): void => {
    updateSearch(event.target.value);
  };

  useEffect(() => {
    fetchDashboardTasks();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (serverError) {
      UIUtils.handleServerError(k2Intl, serverError, clearServerError);
    }
    // eslint-disable-next-line
  }, [serverError]);

  return (
    <Col xs={24} sm={24} md={24} lg={16} xl={16}>
      <TitleWrapper level={4}>
        <K2Message localeKey="dashboardTitle.tasks" />
      </TitleWrapper>
      <TasksListCard
        shadow={true}
        extra={
          <TopSection
            filtersApplied={filtersApplied}
            canCreateTask={true}
            updateFilters={updateDashboardFilters}
            updateSearch={updateSearchTerm}
            searchValue={search}
            showModal={showModal}
            closeModal={() => setModalVisible(false)}
            modalVisible={modalVisible}
            k2Intl={k2Intl}
          />
        }
        actions={[
          <K2Link to={"/tasks"}>
            <K2Message localeKey="tasks.viewAll" />
          </K2Link>,
        ]}
      >
        <CustomScrollbarsStyled
          autoHide
          autoHeight={true}
          autoHeightMax={426}
          autoHeightMin={426}
        >
          {!serverError &&
            (tasksLoading ? (
              <Skeleton active />
            ) : (
              <TasksWrapper>
                <K2Spin spinning={saving}>
                  {tasksData.tasks.length === 0 &&
                    tasksData.lists.length === 0 && (
                      <Empty
                        description={<K2Message localeKey="tasks.noTasks" />}
                      />
                    )}
                  {tasksData.tasks?.map?.((task, i) => (
                    <div key={task.id}>
                      <TaskListItem
                        task={task}
                        isOverdue={task.isOverdue}
                        searchValue={search}
                        showEditOptions={true}
                        onCancelEdit={() => {
                          fetchDashboardTasks();
                        }}
                      />
                      {i !== tasksData.tasks.length - 1 && (
                        <Divider
                          style={{ margin: 0, background: theme.grey_3 }}
                        />
                      )}
                    </div>
                  ))}

                  {tasksData?.lists?.length > 0 && (
                    <Divider style={{ margin: 0, background: theme.grey_3 }} />
                  )}

                  {tasksData?.lists?.map?.((taskList, i) => (
                    <div key={taskList.listId}>
                      <ExpandableTaskGroupRow
                        k2Intl={k2Intl}
                        listId={taskList.listId}
                        listTitle={taskList.listTitle}
                        searchValue={search}
                        showDividerAfter={i !== tasksData.lists.length - 1}
                      />
                    </div>
                  ))}
                </K2Spin>
              </TasksWrapper>
            ))}
        </CustomScrollbarsStyled>
      </TasksListCard>
    </Col>
  );
};

export default connect(
  TasksSelectors.getDashboardTaskListSelector(),
  TasksSelectors.getDashboardTaskListDispatchers(),
)(injectK2Intl(TasksList));
