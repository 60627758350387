import styled from "styled-components";

interface K2DisabledProps {
  disabled: boolean;
}

export default styled.div<K2DisabledProps>`
  pointer-events: ${({ disabled }) =>
    disabled ? "none !important" : "default"};

  opacity: ${({ disabled }) => (disabled ? "0.4 !important" : "1")};
`;
