import React, { FC, useEffect, useState } from "react";

import {
  SecurityPrincipalProfile,
  SecurityPrincipalReq,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Form, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import { connect } from "react-redux";
import styled from "styled-components";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import SecurityPrincipalAvatar from "components/avatars/securityPrincipalAvatar";
import CancelSaveOpts from "components/cancelSaveOpts";
import AddTaskDataLink from "components/dashboard/editTask/components/addTaskDataLink";
import {
  clearTaskAssigneesError,
  updateTaskAssigneesRequest,
} from "components/dashboard/editTask/modules/actions";
import { editableSectionName } from "components/dashboard/editTask/modules/types";
import EditLinkIcon from "components/editLinkIcon";
import { GeneralFormItemV4 } from "components/formItems";
import EditableSection from "components/forms/editableSection";
import FormItemTitle from "components/forms/formItemTitle";
import { K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { SmallTagWithAvatar } from "components/tagWithAvatar";
import SelectAssignees from "components/tasks/SelectAssignees";
import { UIUtils } from "components/utils";
import { showNotification } from "components/utils/Notification";
import usePrevious from "hooks/usePrevious";

const OptsContainer = styled.div`
  position: relative;
  top: -15px;
`;

const AssigneesList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const SpacedTagWithAvatar = styled(SmallTagWithAvatar)`
  && {
    margin-bottom: 10px;
  }
`;

interface ISelectEditAssigneesProps extends InjectedK2IntlProps {
  assignees: SecurityPrincipalProfile[];
  updating: boolean;
  error?: ServerError;
  taskId: string;
  taskRevisionId: string;
  clearError: typeof clearTaskAssigneesError;
  updateAssignees: typeof updateTaskAssigneesRequest;
  setFormTouched?: (formName: editableSectionName) => void;
  clearFormTouched?: (formName: editableSectionName) => void;
}

const SelectEditAssignees: FC<ISelectEditAssigneesProps> = ({
  updating,
  assignees,
  clearError,
  taskId,
  taskRevisionId,
  error,
  updateAssignees,
  setFormTouched,
  clearFormTouched,
  k2Intl,
}) => {
  const { theme } = useAppContext();

  const [form] = useForm();
  const [editing, setIsEditing] = useState(false);
  const [touched, setTouched] = useState(false);
  const [selectedAssignees, setSelectedAssignees] = useState(assignees || []);
  const prevUpdating = usePrevious(updating);

  const handleSave = () => {
    // The Update Assignees API expects an array of SecurityPrincipals, not SecurityPrincipalProfiles
    const principals: SecurityPrincipalReq[] = selectedAssignees?.map?.(
      (p): SecurityPrincipalReq => {
        return {
          principalId: p.principalId,
          type: p.type,
        };
      },
    );
    updateAssignees(taskId, taskRevisionId, principals, selectedAssignees);
  };

  const handleCancel = () => {
    clearFormTouched?.("assignees");
    setIsEditing(false);
    setTouched(false);
    setSelectedAssignees(assignees || []);
  };

  const isTouched = form?.isFieldTouched("assign-to") || touched;
  useEffect(() => {
    if (isTouched) {
      setIsEditing(true);
    }
  }, [isTouched]);

  useEffect(() => {
    if (error) {
      UIUtils.handleServerError(k2Intl, error, clearError);
    }
    // eslint-disable-next-line
  }, [error]);

  // After new assignees have been saved
  useEffect(() => {
    if (prevUpdating && !updating && !error) {
      clearFormTouched?.("assignees");
      setTouched(false);
      setIsEditing(false);
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "tasks.taskUpdatedSuccess",
      });
    }
    //eslint-disable-next-line
  }, [updating, prevUpdating, error]);

  return (
    <Form layout="vertical" form={form}>
      {editing ? (
        <>
          <GeneralFormItemV4 wrapperCol={{ xs: 24 }} labelCol={{ xs: 24 }}>
            <SelectAssignees
              title={
                <FormItemTitle color={theme.grey_6}>
                  <K2Message localeKey="tasks.assignees" />
                </FormItemTitle>
              }
              onChange={setSelectedAssignees}
              selectedValues={selectedAssignees}
              setTouched={() => {
                setFormTouched?.("assignees");
                setTouched(true);
              }}
            />
          </GeneralFormItemV4>
          <OptsContainer>
            <CancelSaveOpts
              loading={updating}
              onCancel={handleCancel}
              onSave={handleSave}
            />
          </OptsContainer>
        </>
      ) : (
        <>
          <FormItemTitle color={theme.grey_6}>
            <K2Message localeKey="tasks.assignees" />
          </FormItemTitle>
          <EditableSection>
            <AssigneesList>
              {assignees?.length ? (
                <>
                  {assignees?.map?.((assignee) => (
                    <SpacedTagWithAvatar key={assignee.principalId}>
                      <SecurityPrincipalAvatar
                        profile={assignee}
                        presetId="small"
                        size="small"
                      />
                      <span className={"name"}>{assignee.name}</span>
                    </SpacedTagWithAvatar>
                  ))}
                  <Tooltip title={<K2Message localeKey="common.clickToEdit" />}>
                    <EditLinkIcon onClick={() => setIsEditing(!editing)} />
                  </Tooltip>
                </>
              ) : (
                <AddTaskDataLink onClick={() => setIsEditing(!editing)}>
                  <K2Message localeKey="tasks.action.addAssignees" />
                </AddTaskDataLink>
              )}
            </AssigneesList>
          </EditableSection>
        </>
      )}
    </Form>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  assignees: state.editViewTask.task?.assignedTo,
  updating: state.editViewTask.updatingAssignees,
  error: state.editViewTask.errorUpdatingAssignees,
  taskId: state.editViewTask.task?.id,
  taskRevisionId: state.editViewTask.task?.revisionId,
});

const mapDispatchToProps = {
  clearError: clearTaskAssigneesError,
  updateAssignees: updateTaskAssigneesRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectK2Intl(SelectEditAssignees));
