import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const PaymentCreditCard: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.8125 1C4.67341 1 3.75 1.92341 3.75 3.0625V6.5C3.75 6.8797 4.0578 7.1875 4.4375 7.1875C4.8172 7.1875 5.125 6.8797 5.125 6.5H21.625V13.375C21.625 13.7547 21.9328 14.0625 22.3125 14.0625C22.6922 14.0625 23 13.7547 23 13.375V3.0625C23 1.92341 22.0766 1 20.9375 1H5.8125ZM21.625 5.125V3.0625C21.625 2.6828 21.3172 2.375 20.9375 2.375H5.8125C5.4328 2.375 5.125 2.6828 5.125 3.0625V5.125H21.625Z"
      fill={fill}
    />
    <path
      d="M3.75 17.5C3.75 17.1203 4.0578 16.8125 4.4375 16.8125H6.5C6.8797 16.8125 7.1875 17.1203 7.1875 17.5C7.1875 17.8797 6.8797 18.1875 6.5 18.1875H4.4375C4.0578 18.1875 3.75 17.8797 3.75 17.5Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0189 16.4001C16.2387 15.3598 14.7779 15.1312 13.7194 15.8687C12.8906 15.2913 11.7523 15.2797 10.9001 15.9189C9.83695 16.7162 9.62149 18.2245 10.4189 19.2876C11.199 20.3279 12.6598 20.5565 13.7183 19.819C14.5471 20.3964 15.6854 20.408 16.5376 19.7689C17.6008 18.9715 17.8162 17.4633 17.0189 16.4001ZM14.2689 18.4626L13.7189 18.8751L13.1689 18.4626C13.1102 18.5408 13.0408 18.6102 12.9626 18.6689C12.507 19.0106 11.8606 18.9182 11.5189 18.4626C11.1771 18.007 11.2695 17.3606 11.7251 17.0189C12.1807 16.6771 12.8271 16.7695 13.1689 17.2251L13.7189 16.8126L14.2689 17.2251C14.3275 17.1469 14.3969 17.0775 14.4751 17.0189C14.9307 16.6771 15.5771 16.7695 15.9189 17.2251C16.2606 17.6807 16.1682 18.3271 15.7126 18.6689C15.257 19.0106 14.6106 18.9182 14.2689 18.4626Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 20.9375C1 22.0766 1.92342 23 3.0625 23H18.1875C19.3266 23 20.25 22.0766 20.25 20.9375V10.625C20.25 9.48592 19.3266 8.5625 18.1875 8.5625H3.0625C1.92342 8.5625 1 9.48592 1 10.625V20.9375ZM3.0625 9.9375C2.68281 9.9375 2.375 10.2453 2.375 10.625V12.6875H18.875V10.625C18.875 10.2453 18.5672 9.9375 18.1875 9.9375H3.0625ZM18.875 14.0625H2.375V20.9375C2.375 21.3172 2.68281 21.625 3.0625 21.625H18.1875C18.5672 21.625 18.875 21.3172 18.875 20.9375V14.0625Z"
      fill={fill}
    />
  </svg>
);
