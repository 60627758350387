import React, { FC } from "react";

import { CalendarOutlined } from "@ant-design/icons/lib";
import { CallSummaryRes } from "@n3oltd/k2.calls.sdk.calls";
import { Alert, Badge, Divider } from "antd";
import styled from "styled-components";

import { _callsClient } from "appRedux/models/base/K2RestClients";
import {
  CallDispositionsLookups,
  CallEndedReasonsLookups,
} from "appRedux/modules/lookups/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import {
  K2Collapse,
  K2Message,
  K2PhoneNumber,
  K2Tooltip,
} from "components/k2Widgets";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";

const K2CollapseWrapper = styled(K2Collapse)`
  line-height: 0px;
  &.ant-collapse {
    border: 0px;
    border-radius: 15px !important;
    background-color: ${({ theme }) => theme.grey_2};
  }

  .ant-collapse-item.ant-collapse-no-arrow {
    border: 0px;
    border-radius: 15px !important;
    background-color: ${({ theme }) => theme.grey_2};
  }
  .ant-collapse-header {
    cursor: pointer !important;
    color: ${({ theme }) => theme.grey_8} !important;
  }
`;

const TitleStats = styled.div`
  margin-left: auto;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  .anticon {
    color: ${({ theme }) => theme.grey_6};
  }
`;

const AppointmentAlert = styled(Alert)`
  background-color: ${({ theme }) => theme.grey_4} !important;

  .heavy-font {
    font-weight: ${({ theme }) => theme.font_weight_heavy};
    font-size: ${({ theme }) => theme.h6_font_size};
    line-height: ${({ theme }) => theme.line_height_lg};
  }
  .anticon.anticon-calendar.ant-alert-icon {
    color: ${({ theme }) => theme.grey_6};
  }
`;
const SimpleList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
`;

export const DispostionBadge = styled(Badge)`
  && {
    .ant-badge-count {
      background-color: ${({ theme }) => theme.grey_10};
      color: ${({ theme }) => theme.grey_9};
      font-weight: ${({ theme }) => theme.font_weight_semi_bold};
      font-size: 13px;
      cursor: pointer;
      box-shadow: none;
    }
  }
`;

const CustomDivider = styled(Divider)`
  .heading {
    font-weight: ${({ theme }) => theme.font_weight_heavy};
    font-size: ${({ theme }) => theme.h6_font_size};
    line-height: ${({ theme }) => theme.line_height_lg};
    border-top: ${({ theme }) => theme.grey_10};
  }
`;

const Text = styled.div`
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};
  font-size: ${({ theme }) => theme.font_size_sm};
  line-height: ${({ theme }) => theme.line_height_lg};
`;

export const SmallBadge = styled(Badge)`
  .ant-badge-status-dot {
    width: 4px;
    height: 4px;
  }
`;

const titleStyle = {
  display: "flex",
  gap: "5px",
  alignItems: "center",
};

const marginTop = {
  marginTop: "4px",
};
interface CallDetailsProps extends InjectedK2IntlProps {
  callInfo: CallSummaryRes;
  callDispositions?: CallDispositionsLookups;
  callEndedReasons?: CallEndedReasonsLookups;
}

const CallDetails: FC<CallDetailsProps> = ({
  callInfo,
  callDispositions,
  k2Intl,
  callEndedReasons,
}) => {
  const { theme } = useAppContext();

  const {
    phoneNumber,
    endedReason,
    appointment,
    notes,
    dispositions,
    hasAppointment,
  } = callInfo || {};

  const isCallConnected = callEndedReasons?.items?.find(
    (x) => x.id === endedReason,
  )?.completesCall;
  return (
    <K2CollapseWrapper
      expandIcon={null}
      bordered={false}
      showArrow={false}
      title={
        <div style={titleStyle}>
          <Badge
            color={isCallConnected ? "green" : "red"}
            text={<K2PhoneNumber contact={phoneNumber} formatForTel={false} />}
          />
          <TitleStats>
            {hasAppointment && (
              <K2Tooltip titleKey="communication.cc.calls.wasAnAppointment">
                <CalendarOutlined />
              </K2Tooltip>
            )}
            {notes && (
              <K2Tooltip title={notes}>
                <N3oIcon
                  icontype={"n3o-task-note"}
                  width={16}
                  height={16}
                  fill={theme.grey_6}
                  style={marginTop}
                />
              </K2Tooltip>
            )}
            {dispositions?.length > 0 && (
              <K2Tooltip
                titleKey={
                  dispositions?.length === 1
                    ? "communication.cc.callDisposition.count"
                    : "communication.cc.callDispositions.count"
                }
                titleValues={{
                  count: dispositions?.length,
                }}
              >
                <DispostionBadge count={dispositions?.length} />
              </K2Tooltip>
            )}
          </TitleStats>
        </div>
      }
      collapsible={
        hasAppointment || notes || dispositions?.length > 0
          ? "header"
          : "disabled"
      }
    >
      {hasAppointment && (
        <>
          <AppointmentAlert
            icon={<CalendarOutlined style={{ color: theme.grey_6 }} />}
            type="info"
            message={
              <K2Message
                spanClassName="heavy-font"
                localeKey="communication.cc.calls.wasAnAppointment"
              />
            }
            showIcon
          />
          <CustomDivider orientation="left" className="n3o-left-divider">
            <K2Message
              spanClassName="heading"
              localeKey={"communication.cc.calls.appointments.details"}
            />
          </CustomDivider>
          <SimpleList>
            <li>
              <SmallBadge color="black" size="small" />
              <Text>
                <K2Message
                  localeKey="communication.cc.dialler.appointment.scheduledOn"
                  values={{
                    scheduledOn: k2Intl.formatDateTime(
                      new Date(callInfo.modifiedInfo?.created?.timestamp),
                    ),
                  }}
                  withoutSpan
                />
              </Text>
            </li>
            <li>
              <SmallBadge color="black" />
              <Text>
                <K2Message
                  localeKey="common.assignedTo"
                  values={{
                    name: appointment.assignedTo.name,
                  }}
                  withoutSpan
                />
              </Text>
            </li>
          </SimpleList>
        </>
      )}

      {callDispositions?.items?.length > 0 && dispositions?.length > 0 && (
        <>
          <CustomDivider orientation="left" className="n3o-left-divider">
            <K2Message
              spanClassName="heading"
              localeKey="communication.cc.callDispositions"
            />
          </CustomDivider>
          <SimpleList>
            {callDispositions?.items
              ?.filter((x) => dispositions?.includes(x.id))
              ?.map((item) => {
                return (
                  <li key={item.id}>
                    <SmallBadge color="black" />
                    <Text>{item.name}</Text>
                  </li>
                );
              })}
          </SimpleList>
        </>
      )}

      {notes && (
        <>
          <CustomDivider orientation="left" className="n3o-left-divider">
            <K2Message spanClassName="heading" localeKey="common.notes" />
          </CustomDivider>
          <Text>{notes}</Text>
        </>
      )}
    </K2CollapseWrapper>
  );
};

export default CallDetails;
