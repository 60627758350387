import { Radio } from "antd";
import styled from "styled-components";

const { Group } = Radio;

const K2RadioGroup = styled(Group)`
  && {
    // All Buttons
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: ${({ theme }) => theme.primaryColor};
      background-color: ${({ theme }) => theme.white_color};
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      box-shadow: -1px 0 0 0 ${({ theme }) => theme.primaryColor};
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: ${({ theme }) => theme.primaryColor};
    }

    .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
      border-right-color: ${({ theme }) => theme.primaryColor};
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: ${({ theme }) => theme.primaryColor};
    }

    .ant-radio-button-wrapper:hover {
      color: ${({ theme }) => theme.primaryColor};
    }

    // Solid Buttons Override
    &.ant-radio-group-solid .ant-radio-button-wrapper {
      background-color: ${({ theme }) => theme.white_color};
      color: ${({ theme }) => theme.primaryColor};
    }
    &.ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: ${({ theme }) => theme.primaryColor};
    }

    &.ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${({ theme }) => theme.primaryColor};
      color: ${({ theme }) => theme.white_color};
      border: 1px solid ${({ theme }) => theme.primaryColor};
      &:hover {
        background-color: ${({ theme }) => theme.button_hover_color};
      }
    }

    // Normal Radios
    .ant-radio-checked .ant-radio-inner {
      border-color: ${({ theme }) => theme.primaryColor};
    }
    .ant-radio-checked::after {
      border: 1px solid ${({ theme }) => theme.primaryColor};
    }
    .ant-radio-inner::after {
      background-color: ${({ theme }) => theme.primaryColor};
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

export default K2RadioGroup;
