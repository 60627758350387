import React from "react";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { CallEndedReason } from "@n3oltd/k2.calls.sdk.calls";
import { Button } from "antd";
import styled from "styled-components";

import { CallEndedReasonsLookups } from "appRedux/modules/lookups/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Message } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import { N3oIcon } from "components/n3oIcon";
import { CustomScrollbars } from "components/utils";

import TitleDivider from "../TitleDivider";

const EndCallContainer = styled.div`
  position: relative;
`;

const EndCallHeader = styled.div`
  background-color: ${({ theme }) => theme.red_5};
  color: ${({ theme }) => theme.white_color};
  padding: 10px 15px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  font-weight: 500;

  & > span:first-child span span:last-child {
    margin-left: 10px;
    color: ${({ theme }) => theme.white_color};
  }
`;

const EndCallBody = styled.div`
  padding: 20px;
  padding-top: 10px;
  text-align: center;

  p {
    color: ${({ theme }) => theme.grey_6};
  }

  button {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    padding: 7px 20px;
    height: auto;

    span:first-child {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: ${({ theme }) => theme.grey_6};
    }
  }
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
`;

interface EndCallProps {
  callEndedReasons: CallEndedReasonsLookups;
  callDuration: string;
  onBack: () => void;
  confirmEndCall: (completesCall: boolean, reasonId: CallEndedReason) => void;
}

function EndCallView({
  callDuration,
  onBack,
  callEndedReasons,
  confirmEndCall,
}: EndCallProps) {
  const { theme } = useAppContext();

  return (
    <EndCallContainer>
      <EndCallHeader>
        <span>
          <FakeLink onClick={onBack}>
            <ArrowLeftOutlined style={{ color: theme.white_color }} />
            <K2Message localeKey="common.back" />
          </FakeLink>
        </span>
        <Icon>
          <span style={{ marginRight: 10 }}>{callDuration}</span>
          <span style={{ lineHeight: 0 }}>
            <N3oIcon
              height={20}
              width={20}
              icontype={"n3o-outbound-call"}
              fill="white"
            />
          </span>
        </Icon>
      </EndCallHeader>
      <CustomScrollbars
        autoHeight={true}
        autoHide={true}
        autoHeightMax={400}
        autoHeightMin={10}
      >
        <EndCallBody>
          <h3>
            <K2Message localeKey="communication.cc.dialler.endingCall" />
          </h3>
          <TitleDivider localeKey="communication.cc.dialler.howDidEnd" />
          {callEndedReasons?.items
            ?.filter((item) => item.userSelectable)
            .map((item, i) => (
              <Button
                onClick={() =>
                  confirmEndCall(item.completesCall, item.id as CallEndedReason)
                }
                key={item.id}
                block
              >
                <span>{i + 1}</span>
                <span>{item.name}</span>
              </Button>
            ))}
        </EndCallBody>
      </CustomScrollbars>
    </EndCallContainer>
  );
}

export default EndCallView;
