import React, { FC, useEffect, useMemo, useState } from "react";

import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { generateUuid } from "@azure/ms-rest-js";
import { Collapse } from "antd";
import { CollapseProps } from "antd/lib/collapse";
import { usePrevious } from "hooks";
import _ from "lodash";
import styled from "styled-components";

import { K2Message } from "components/k2Widgets";

const { Panel } = Collapse;

const K2MessageWrapper = styled.div`
  color: ${({ theme }) => theme.grey_7};
  font-weight: ${({ theme }) => theme.font_weight_bold};
`;

interface IProps extends CollapseProps {
  title?: string | JSX.Element;
  titleKey?: string;
  children?: JSX.Element[] | JSX.Element;
  panelExtra?: React.ReactNode;
  defaultOpen?: boolean;
  forceCollapse?: boolean;
  showArrow?: boolean;
}

const K2Collapse: FC<IProps> = ({
  title,
  titleKey,
  children,
  panelExtra,
  defaultOpen,
  forceCollapse,
  showArrow,
  ...remainingProps
}) => {
  const panelKey = useMemo(() => generateUuid(), []);
  const defaultActiveKey = useMemo(() => (defaultOpen ? panelKey : undefined), [
    defaultOpen,
    panelKey,
  ]);

  const [activeKey, setActiveKey] = useState<string | string[]>(
    defaultActiveKey,
  );

  const prevForceCollapse = usePrevious(forceCollapse);

  useEffect(() => {
    if (
      prevForceCollapse !== forceCollapse &&
      forceCollapse &&
      !_.isNil(activeKey) &&
      !_.isEmpty(activeKey)
    ) {
      setActiveKey(undefined);
    }
  }, [activeKey, forceCollapse, prevForceCollapse]);

  return (
    <Collapse
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      onChange={setActiveKey}
      expandIcon={({ isActive }) =>
        isActive ? (
          <MinusSquareOutlined style={{ fontSize: 16 }} />
        ) : (
          <PlusSquareOutlined style={{ fontSize: 16 }} />
        )
      }
      {...remainingProps}
    >
      <Panel
        extra={panelExtra}
        showArrow={showArrow}
        header={
          titleKey ? (
            <K2MessageWrapper>
              <K2Message localeKey={titleKey} withoutSpan />
            </K2MessageWrapper>
          ) : (
            title
          )
        }
        key={panelKey}
      >
        {children}
      </Panel>
    </Collapse>
  );
};

export const K2CollapseGhost = styled(K2Collapse)`
  &.ant-collapse-ghost {
    .ant-collapse-header {
      padding: 12px 0px !important;
      padding-right: 24px !important;
    }

    &.ant-collapse-icon-position-right {
      .ant-collapse-arrow {
        right: 0px !important;
      }
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 12px 0 !important;
      }
    }
  }
`;

export default K2Collapse;
