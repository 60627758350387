import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const FeedbackData: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1900_3537)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4593 1.3093C4.13755 0.631041 5.05747 0.25 6.01667 0.25C6.97587 0.25 7.89578 0.63104 8.57404 1.3093C9.25229 1.98755 9.63333 2.90747 9.63333 3.86667C9.63333 4.82587 9.25229 5.74578 8.57404 6.42404C8.02481 6.97327 7.31711 7.32761 6.55842 7.44254C6.67135 7.45318 6.78416 7.46717 6.89671 7.48452C7.99587 7.65404 9.02259 8.13773 9.85318 8.87733C10.1625 9.15279 10.19 9.62687 9.91454 9.93622C9.63909 10.2456 9.16501 10.273 8.85566 9.99757C8.24108 9.45032 7.48139 9.09243 6.66808 8.967C5.85478 8.84157 5.02256 8.95396 4.27166 9.29063C3.52076 9.62731 2.88321 10.1739 2.43582 10.8646C1.98846 11.5552 1.75029 12.3604 1.75 13.1832V14.5832H3.15C3.53531 14.5832 3.85794 14.8752 3.89628 15.2586L4.54541 21.7499H7.48806L7.66789 19.955C7.70919 19.5429 8.07678 19.2422 8.48893 19.2835C8.90108 19.3248 9.20171 19.6924 9.16042 20.1046L8.91293 22.5747C8.87452 22.958 8.55193 23.2499 8.16667 23.2499H3.86667C3.48135 23.2499 3.15873 22.9579 3.12039 22.5745L2.47126 16.0832H1C0.585786 16.0832 0.25 15.7474 0.25 15.3332V13.1832C0.250339 12.0711 0.572219 10.9825 1.17687 10.0491C1.78152 9.11563 2.64315 8.37692 3.65798 7.92191C4.23652 7.66252 4.85078 7.50164 5.47566 7.44266C4.71668 7.32784 4.00871 6.97345 3.4593 6.42404C2.78104 5.74578 2.4 4.82587 2.4 3.86667C2.4 2.90747 2.78104 1.98755 3.4593 1.3093ZM6.01667 1.75C5.45529 1.75 4.91691 1.97301 4.51996 2.36996C4.12301 2.76691 3.9 3.30529 3.9 3.86667C3.9 4.42804 4.12301 4.96642 4.51996 5.36338C4.91691 5.76033 5.45529 5.98333 6.01667 5.98333C6.57804 5.98333 7.11642 5.76033 7.51338 5.36338C7.91033 4.96642 8.13333 4.42804 8.13333 3.86667C8.13333 3.30529 7.91033 2.76691 7.51338 2.36996C7.11642 1.97301 6.57804 1.75 6.01667 1.75Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11.85C11 11.3806 11.3806 11 11.85 11H22.5167C22.9861 11 23.3667 11.3806 23.3667 11.85V22.5167C23.3667 22.9861 22.9861 23.3667 22.5167 23.3667H11.85C11.3806 23.3667 11 22.9861 11 22.5167V11.85ZM12.7 12.7V21.6667H21.6667V12.7H12.7ZM13.6667 15.1833C13.6667 14.7139 14.0472 14.3333 14.5167 14.3333H19.85C20.3194 14.3333 20.7 14.7139 20.7 15.1833C20.7 15.6528 20.3194 16.0333 19.85 16.0333H14.5167C14.0472 16.0333 13.6667 15.6528 13.6667 15.1833ZM13.6667 17.85C13.6667 17.3806 14.0472 17 14.5167 17H16.5167C16.9861 17 17.3667 17.3806 17.3667 17.85C17.3667 18.3195 16.9861 18.7 16.5167 18.7H14.5167C14.0472 18.7 13.6667 18.3195 13.6667 17.85Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6667 0.316406C13.0877 0.316406 12.5323 0.546434 12.1229 0.95589C11.7134 1.36535 11.4834 1.92068 11.4834 2.49974V5C11.4834 5.57906 11.7134 6.13439 12.1229 6.54385C12.5323 6.9533 13.0877 7.18333 13.6667 7.18333H14.1501V9C14.1501 9.31348 14.3226 9.60151 14.599 9.74942C14.8754 9.89734 15.2107 9.88113 15.4716 9.70724L19.2574 7.18333H20.6667C21.2458 7.18333 21.8011 6.9533 22.2106 6.54385C22.62 6.13439 22.8501 5.57906 22.8501 5V2.49974C22.8501 1.92068 22.62 1.36535 22.2106 0.95589C21.8011 0.546435 21.2458 0.316406 20.6667 0.316406H13.6667ZM13.6667 2.01641C13.5385 2.01641 13.4156 2.06733 13.325 2.15797C13.2343 2.24861 13.1834 2.37155 13.1834 2.49974V5C13.1834 5.12819 13.2343 5.25113 13.325 5.34177C13.4156 5.43241 13.5385 5.48333 13.6667 5.48333H15.0001C15.4695 5.48333 15.8501 5.86389 15.8501 6.33333V7.41176L18.5286 5.62609C18.6682 5.53301 18.8323 5.48333 19.0001 5.48333H20.6667C20.7949 5.48333 20.9179 5.43241 21.0085 5.34177C21.0991 5.25113 21.1501 5.12819 21.1501 5V2.49974C21.1501 2.37155 21.0991 2.24861 21.0085 2.15797C20.9179 2.06733 20.7949 2.01641 20.6667 2.01641H13.6667Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1900_3537">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
