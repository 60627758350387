import React, { Component } from "react";
import { K2Grid, K2Modal } from "..";
import { Row, Col } from "antd";
import { ColumnProps } from "antd/es/table";
import { GetRowKey } from "antd/es/table/interface";

interface ActionModalProps<T> {
  /** If true only messageContent will be displayed and grid will be hidden */
  hideGrid: boolean;
  /** Tracks visibility of the Modal*/
  visible: boolean;
  /** Loading state*/
  isLoading: boolean;
  /**@interface React.ReactNode containing Modal title */
  titleContent: React.ReactNode;
  /**@interface React.ReactNode containing Modal message */
  messageContent: React.ReactNode;
  /**@interface ColumnProps<T>  Columns configuration for Modal grid */
  modalGridColumns: ColumnProps<T>[];
  /**Function for calculating the class name of the row */
  rowClassName?: (record: T) => string;
  /**Datasource for Modal grid */
  modalGridDataSource: T[];
  /**Page size for the grid */
  modalGridPageSize: number;
  /**Unique key/ID field name within the grid data */
  modalGridKeyField: string | GetRowKey<T>;
  /** Total records in the grid */
  totalRecords: number;

  /**@event onOkClicked Callback for when OK button is clicked  */
  onOkClicked: () => void;
  /**@event onCancelClicked Callback for when Cancel button is clicked  */
  onCancelClicked: () => void;
}

class ActionConfirmModal<T> extends Component<ActionModalProps<T>> {
  /** @method Generates JSX containing content for the Action Modal */
  renderActionModalContent = () => {
    const {
      modalGridColumns,
      modalGridDataSource,
      modalGridKeyField,
      modalGridPageSize,
      totalRecords,
      hideGrid,
      rowClassName,
    } = this.props;

    return (
      <Row>
        <Col span={24}>
          {<h5 className="n3o-mb-10">{this.props.messageContent}</h5>}
          {!hideGrid && modalGridDataSource.length > 0 && (
            <K2Grid<T>
              className="n3o-mt-10 k2grid-no-min-height"
              columns={modalGridColumns}
              dataSource={modalGridDataSource}
              rowKey={modalGridKeyField}
              rowClassName={rowClassName}
              pagination={{
                className: "k2-next-prev-pager",
                defaultPageSize: modalGridPageSize,
                total: totalRecords,
                hideOnSinglePage: true,
              }}
            />
          )}
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <K2Modal
        title={this.props.titleContent}
        okTextKey="common.ok"
        cancelTextKey="common.cancel"
        visible={this.props.visible}
        onOk={this.props.onOkClicked}
        confirmLoading={this.props.isLoading}
        onCancel={this.props.onCancelClicked}
        width="600px"
      >
        {this.renderActionModalContent()}
      </K2Modal>
    );
  }
}

export default ActionConfirmModal;
