import {
  LinkedEntityRes,
  MarkupLanguage,
  NoteReq,
  NoteRes,
  TaskRes,
  TaskStatus,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { _tasksClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { dashboardTasksActions } from "appRedux/modules/tasks";
import * as actionTypes from "components/dashboard/editTask/modules/actionTypes";
import * as actions from "components/dashboard/editTask/modules/actions";

function fetchTaskNoActions(taskId: string): Promise<IApiResponse<TaskRes>> {
  return K2Service.toResponse(_tasksClient.getById(taskId));
}

function* fetchTask(action: ReturnType<typeof actions.fetchTask>) {
  try {
    const response: IApiResponse<TaskRes> = yield K2Service.toResponse(
      _tasksClient.getById(action.taskId),
    );
    if (response.error) {
      yield put(actions.fetchTaskError(response.error));
    } else {
      yield put(actions.fetchTaskSuccess(response.getResultOrDefault()));
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
  }
}

function* updateTaskStatus(
  action: ReturnType<typeof actions.updateTaskStatusRequest>,
) {
  try {
    let response: IApiResponse<void>;
    if (action.status === "completed") {
      response = yield K2Service.toResponse(
        _tasksClient.markCompleted(action.taskId),
      );
    } else if (action.status === "pending") {
      response = yield K2Service.toResponse(
        _tasksClient.markPending(action.taskId),
      );
    }

    if (response.error) {
      yield put(actions.errorUpdatingStatus(response.error));
    } else {
      // Fetch new task again to update the RevisionID
      const newTask: IApiResponse<TaskRes> = yield fetchTaskNoActions(
        action.taskId,
      );

      if (newTask.error) {
        yield put(actions.fetchTaskError(newTask.error));
        if (action.status === "completed") {
          yield put(actions.markTaskComplete(action.taskId));
        } else {
          yield put(actions.markTaskPending(action.taskId));
        }
      } else {
        yield put(actions.fetchTaskSuccess(newTask.getResultOrDefault(), true));
        if (action.status === "completed") {
          yield put(actions.markTaskComplete(action.taskId));
        } else {
          yield put(actions.markTaskPending(action.taskId));
        }

        // Re-fetch the count of tasks assigned to user, as it will have changed
        yield put(
          dashboardTasksActions.fetchTaskCountForUser({
            assignedToMe: true,
            status: [TaskStatus.Pending],
          }),
        );
      }
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
    yield put({
      type: actionTypes.CLEAR_UPDATING_TASK_STATUS,
    });
  }
}

function* deleteTask(action: ReturnType<typeof actions.deleteTaskRequest>) {
  try {
    const response: IApiResponse<void> = yield K2Service.toResponse(
      _tasksClient.deleteTask(action.taskRevisionId),
    );
    if (response.error) {
      yield put(actions.deleteTaskError(response.error));
    } else {
      yield put(actions.deleteTaskSuccess());

      // Re-fetch the count of tasks assigned to user, as it will have changed
      yield put(
        dashboardTasksActions.fetchTaskCountForUser({
          assignedToMe: true,
          status: [TaskStatus.Pending],
        }),
      );
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
    yield put({
      type: actionTypes.CLEAR_DELETING_TASK,
    });
  }
}

function* updateTaskTitle(
  action: ReturnType<typeof actions.updateTaskTitleRequest>,
) {
  try {
    const response: IApiResponse<void> = yield K2Service.toResponse(
      _tasksClient.updateTitle(action.taskId, { title: action.title }),
    );
    if (response.error) {
      yield put(actions.updateTaskTitleError(response.error));
    } else {
      // Fetch new task again to update the RevisionID
      const newTask: IApiResponse<TaskRes> = yield fetchTaskNoActions(
        action.taskId,
      );
      if (newTask.error) {
        yield put(actions.fetchTaskError(newTask.error));
        yield put(actions.updateTaskTitleSuccess(action.title));
      } else {
        yield put(actions.fetchTaskSuccess(newTask.getResultOrDefault(), true));
        yield put(actions.updateTaskTitleSuccess(action.title));
      }
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
    yield put({
      type: actionTypes.CLEAR_UPDATING_TASK_TITLE,
    });
  }
}

function* updateTaskDueDate(
  action: ReturnType<typeof actions.updateTaskDueDateRequest>,
) {
  try {
    const response: IApiResponse<void> = yield K2Service.toResponse(
      _tasksClient.setDueDate(action.taskId, {
        dueDate: action.dueDate,
      }),
    );
    if (response.error) {
      yield put(actions.updateTaskDueDateError(response.error));
    } else {
      // Fetch new task again to update the RevisionID
      const newTask: IApiResponse<TaskRes> = yield fetchTaskNoActions(
        action.taskId,
      );
      if (newTask.error) {
        yield put(actions.fetchTaskError(newTask.error));
        yield put(actions.updateTaskDueDateSuccess(action.dueDate));
      } else {
        yield put(actions.fetchTaskSuccess(newTask.getResultOrDefault(), true));
        yield put(actions.updateTaskDueDateSuccess(action.dueDate));
      }
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
    yield put({
      type: actionTypes.CLEAR_UPDATING_TASK_DUE_DATE,
    });
  }
}

function* updateTaskPriority(
  action: ReturnType<typeof actions.updateTaskPriorityRequest>,
) {
  try {
    const response: IApiResponse<void> = yield K2Service.toResponse(
      _tasksClient.setPriority(action.taskId, {
        priority: action.priority,
      }),
    );
    if (response.error) {
      yield put(actions.updateTaskPriorityError(response.error));
    } else {
      // Fetch new task again to update the RevisionID
      const newTask: IApiResponse<TaskRes> = yield fetchTaskNoActions(
        action.taskId,
      );
      if (newTask.error) {
        yield put(actions.fetchTaskError(newTask.error));
        yield put(actions.updateTaskPrioritySuccess(action.priority));
      } else {
        yield put(actions.fetchTaskSuccess(newTask.getResultOrDefault(), true));
        yield put(actions.updateTaskPrioritySuccess(action.priority));
      }
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
    yield put({ type: actionTypes.CLEAR_UPDATING_TASK_PRIORITY });
  }
}

function* updateTaskAssignees(
  action: ReturnType<typeof actions.updateTaskAssigneesRequest>,
) {
  try {
    const response: IApiResponse<void> = yield K2Service.toResponse(
      _tasksClient.assignTo(action.taskRevisionId, {
        principals: action.principals,
      }),
    );
    if (response.error) {
      yield put(actions.updateTaskAssigneesError(response.error));
    } else {
      // Fetch new task again to update the RevisionID
      const newTask: IApiResponse<TaskRes> = yield fetchTaskNoActions(
        action.taskId,
      );

      if (newTask.error) {
        yield put(actions.fetchTaskError(newTask.error));
        yield put(actions.updateTaskAssigneesSuccess(action.principalProfiles));
      } else {
        yield put(actions.fetchTaskSuccess(newTask.getResultOrDefault(), true));
        yield put(actions.updateTaskAssigneesSuccess(action.principalProfiles));
      }
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
    yield put({
      type: actionTypes.CLEAR_UPDATING_TASK_ASSIGNEES,
    });
  }
}

function* updateLinkedEntity(
  action: ReturnType<typeof actions.updateLinkedEntityRequest>,
) {
  try {
    const response: IApiResponse<LinkedEntityRes> = yield K2Service.toResponse(
      _tasksClient.linkEntity(action.taskId, action.linkedEntity),
    );
    if (response.error) {
      yield put(actions.updateLinkedEntityError(response.error));
    } else {
      // Fetch new task again to update the RevisionID
      const newTask: IApiResponse<TaskRes> = yield fetchTaskNoActions(
        action.taskId,
      );
      if (newTask.error) {
        yield put(actions.fetchTaskError(newTask.error));
        yield put(
          actions.updateLinkedEntitySuccess(response.getResultOrDefault()),
        );
      } else {
        yield put(actions.fetchTaskSuccess(newTask.getResultOrDefault(), true));
        yield put(
          actions.updateLinkedEntitySuccess(response.getResultOrDefault()),
        );
      }
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
    yield put({
      type: actionTypes.CLEAR_UPDATING_LINKED_ENTITY,
    });
  }
}

function* deleteNote(action: ReturnType<typeof actions.deleteTaskNoteRequest>) {
  try {
    const response: IApiResponse<void> = yield K2Service.toResponse(
      _tasksClient.removeNote(action.taskRevisionId, action.noteId),
    );
    if (response.error) {
      yield put(actions.deleteTaskNoteError(action.noteId, response.error));
    } else {
      yield put(actions.deleteTaskNoteSuccess(action.noteId));

      // Fetching the task again for the new revision ID
      yield put(actions.fetchTask(action.taskId));
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
  }
}

function* updateNote(action: ReturnType<typeof actions.updateNoteRequest>) {
  try {
    let success = true;

    let revisionId = action.taskRevisionId;
    // Pose each new attachment
    // Posting a new attachment results in a new revision ID, so we have to post-then-fetch
    const postAttachmentResponses: IApiResponse<void>[] = [];
    const postCalls = action.attachmentsToSave?.map?.((attachment) =>
      call(async () => {
        return await K2Service.toResponse(
          _tasksClient.addNoteFile(action.taskId, action.noteId, {
            storageToken: attachment.storageToken,
          }),
        )
          .then((response: IApiResponse<void>) => {
            postAttachmentResponses.push(response);

            return K2RestService.toResponse(
              _tasksClient.getById(action.taskId),
            );
          })
          .then((response: IApiResponse<TaskRes>) => {
            revisionId = response.getResultOrDefault().revisionId;
          });
      }),
    );
    for (let c of postCalls) {
      yield c;
    }

    for (let i = 0; i < postAttachmentResponses.length; i++) {
      if (postAttachmentResponses[i].error) {
        success = false;
        yield put(
          actions.updateNoteError(
            postAttachmentResponses[i].error,
            action.noteId,
          ),
        );
      }
    }

    // Delete each attachment that needs deleting
    // Deleting an attachment results in a new revision ID, so we have to delete-then-fetch
    const deleteAttachmentResponses: IApiResponse<void>[] = [];
    const calls = action.attachmentsToDelete?.map?.((attachment) =>
      call(() => {
        return K2Service.toResponse(
          _tasksClient.removeNoteFile(
            revisionId,
            action.noteId,
            attachment.uid,
          ),
        )
          .then((response: IApiResponse<void>) => {
            deleteAttachmentResponses.push(response);

            return K2RestService.toResponse(
              _tasksClient.getById(action.taskId),
            );
          })
          .then((response: IApiResponse<TaskRes>) => {
            revisionId = response.getResultOrDefault().revisionId;
          });
      }),
    );
    for (let c of calls) {
      yield c;
    }

    for (let i = 0; i < deleteAttachmentResponses.length; i++) {
      if (deleteAttachmentResponses[i].error) {
        success = false;
        yield put(
          actions.updateNoteError(
            deleteAttachmentResponses[i].error,
            action.noteId,
          ),
        );
      }
    }

    // Update the note text
    const noteTextResponse: IApiResponse<void> = yield K2Service.toResponse(
      _tasksClient.updateNoteText(revisionId, action.noteId, {
        language: MarkupLanguage.Html,
        content: action.noteText,
      }),
    );
    if (noteTextResponse.error) {
      success = false;
      yield put(actions.updateNoteError(noteTextResponse.error, action.noteId));
    }

    if (success) {
      yield put(actions.updateNoteSuccess(action.noteId));
      yield put(actions.fetchTask(action.taskId));
    } else {
      yield put({
        type: actionTypes.CLEAR_UPDATING_NOTE,
      });
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
  }
}

function* saveNewNote(action: ReturnType<typeof actions.saveNewNoteRequest>) {
  try {
    const reqBody: NoteReq = {
      text: {
        language: MarkupLanguage.Html,
        content: action.noteText,
      },
    };
    if (action.noteFiles) {
      reqBody.files = action.noteFiles;
    }
    const response: IApiResponse<NoteRes> = yield K2Service.toResponse(
      _tasksClient.addNote(action.taskId, reqBody),
    );
    if (response.error) {
      yield put(actions.saveNewNoteError(response.error));
    } else {
      yield put(actions.saveNewNoteSuccess(response.getResultOrDefault()));

      // Fetching the task again for the new revision ID
      yield put(actions.fetchTask(action.taskId, true));
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
  }
}

function* deleteLinkedEntity(action) {
  const response: IApiResponse<any> = yield K2RestService.toResponse(
    _tasksClient.removeLinkedEntity(action.taskId),
  );
  if (response.error) {
    yield put(actions.deleteLinkedEntityError(response.error));
  } else {
    yield put(actions.deleteLinkedEntitySuccess());
  }
}

export default function* editViewTaskSaga() {
  yield all[
    (yield takeLatest(actionTypes.FETCH_TASK, fetchTask),
    yield takeLatest(actionTypes.UPDATE_TASK_STATUS_REQUEST, updateTaskStatus),
    yield takeLatest(actionTypes.DELETE_TASK_REQUEST, deleteTask),
    yield takeLatest(actionTypes.UPDATE_TASK_TITLE_REQUEST, updateTaskTitle),
    yield takeLatest(
      actionTypes.UPDATE_TASK_DUE_DATE_REQUEST,
      updateTaskDueDate,
    ),
    yield takeLatest(
      actionTypes.UPDATE_TASK_PRIORITY_REQUEST,
      updateTaskPriority,
    ),
    yield takeLatest(
      actionTypes.UPDATE_TASK_ASSIGNEES_REQUEST,
      updateTaskAssignees,
    ),
    yield takeLatest(
      actionTypes.UPDATE_LINKED_ENTITY_REQUEST,
      updateLinkedEntity,
    ),
    yield takeEvery(actionTypes.DELETE_TASK_NOTE_REQUEST, deleteNote),
    yield takeLatest(actionTypes.UPDATE_NOTE_REQUEST, updateNote),
    yield takeLatest(actionTypes.SAVE_NEW_NOTE_REQUEST, saveNewNote),
    yield takeLatest(
      actionTypes.DELETE_LINKED_ENTITY_REQUEST,
      deleteLinkedEntity,
    ))
  ];
}
