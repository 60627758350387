import React, { FC } from "react";

import { Card, Empty, Skeleton } from "antd";
import { SkeletonParagraphProps } from "antd/es/skeleton/Paragraph";
import { CardProps } from "antd/lib/card";
import chroma from "chroma-js";
import _ from "lodash";
import styled from "styled-components";

import { IK2MessageProps } from "components/k2Widgets/k2Localizations/types";

import K2Message from "../k2Localizations/K2Message";

const WrappedCard = styled(Card)<{ $isEmpty?: boolean }>`
  border-radius: 8px !important;
  background-color: white;

  .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
    font-weight: ${({ theme }) => theme.font_weight_bold};
  }

  .ant-card-body {
    height: ${({ $isEmpty }) => ($isEmpty ? "100%" : "auto")};
  }

  .emptyContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -24px;
  }

  .ant-card-actions {
    padding: 14px 24px;
    margin-bottom: 0;

    li {
      list-style-type: none;
      text-align: center;
    }
  }

  &.shadow-card {
    box-shadow: ${({ theme }) => theme.card_shadow};

    & .ant-card-head {
      background-color: ${({ theme }) => chroma(theme.grey_2).alpha(0.6)};
      border-bottom: none;
    }
  }

  &.showing-top-action {
    .ant-card-body {
      padding-top: 20px;
    }
  }

  & {
    .ant-card-small {
      h2 {
        font-size: 1.1rem;
      }
      .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
        padding: 10px 0 0 0;
      }
      .ant-card-head {
        background-color: transparent;
      }
    }
  }
`;

export const TopRightAction = styled.span`
  position: relative;
  top: -30px;
  padding: 0 7px;
  background: #fff;
  color: ${({ theme }) => theme.grey_7};
  cursor: pointer;
  float: right;
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export interface TopRightActionProps {
  action: () => void;
  actionLocaleKey: string;
  actionIcon: React.ReactNode;
}

export interface IK2CardProps extends CardProps, IK2MessageProps {
  paragraph?: SkeletonParagraphProps | boolean;
  shadow?: boolean;
  className?: string;
  showTopRightAction?: boolean;
  topRightActionProps?: TopRightActionProps;
  emptyMessage?: React.ReactNode;
}

const K2Card: FC<IK2CardProps> = ({
  localeKey,
  values,
  bordered,
  loading,
  paragraph,
  children,
  shadow,
  className,
  title,
  showTopRightAction,
  topRightActionProps,
  emptyMessage,
  ...cardProps
}) => {
  if (showTopRightAction && !topRightActionProps) {
    throw new Error("topRightActionProps must be provided");
  }

  return (
    <WrappedCard
      className={`${shadow ? "shadow-card" : ""} ${
        className ? className : ""
      } ${showTopRightAction ? "showing-top-action" : ""}`}
      bordered={!_.isUndefined(bordered) ? bordered : false}
      $isEmpty={!!emptyMessage}
      title={
        title ||
        (!!localeKey && <K2Message localeKey={localeKey} values={values} />)
      }
      {...cardProps}
    >
      {showTopRightAction && (
        <TopRightAction onClick={topRightActionProps.action}>
          <span style={{ marginRight: "8px" }}>
            {topRightActionProps.actionIcon}
          </span>
          <span>
            <K2Message localeKey={topRightActionProps.actionLocaleKey} />
          </span>
        </TopRightAction>
      )}
      {loading ? (
        <Skeleton active paragraph={paragraph} />
      ) : emptyMessage ? (
        <div className={"emptyContainer"}>
          <Empty description={emptyMessage} />
        </div>
      ) : (
        children
      )}
    </WrappedCard>
  );
};

export default K2Card;
