import produce from "immer";
import { LOCATION_CHANGE } from "react-router-redux";

import { BreadcrumbState } from "appRedux/modules/breadcrumbs/types";

import { ActionTypes } from "./actionTypes";

const initialState: BreadcrumbState = {
  crumbs: [],
  location: {
    currentLocation: null,
    previousLocation: null,
  },
};

// todo: update type while updating react-router-redux;
// todo: we can map action LocationActionPayload from redux;
const breadcrumbReducer = (state = initialState, action) => {
  return produce(state, (draft: BreadcrumbState) => {
    switch (action.type) {
      case ActionTypes.SET_BREADCRUMBS:
        return {
          ...draft,
          crumbs: action.crumbs,
        };
      case LOCATION_CHANGE:
        if (
          draft.location.currentLocation !== action.payload.location.pathname
        ) {
          return {
            crumbs: [],
            location: {
              previousLocation: draft.location.currentLocation,
              currentLocation: action.payload.location.pathname,
            },
          };
        }

        return {
          ...draft,
          location: {
            previousLocation: draft.location.currentLocation,
            currentLocation: action.payload.location.pathname,
          },
        };
    }
  });
};

export default breadcrumbReducer;
