import React, { FC } from "react";

import { Tag, TagProps } from "antd";

export type K2StatusTagSize = "default" | "large";

interface StatusTagProps extends TagProps {
  size?: K2StatusTagSize;
  color: string;
  icon?: React.ReactNode;
}

const K2StatusTag: FC<StatusTagProps> = ({
  size = "default",
  color,
  icon,
  children,
  style,
}) => {
  const largeSizeStyle: React.CSSProperties = {};
  if (size === "large") {
    largeSizeStyle.fontSize = 16;
    largeSizeStyle.padding = "4px 80x";
  }
  return (
    <Tag
      style={{
        ...largeSizeStyle,
        ...style,
      }}
      color={color}
      icon={icon}
    >
      {children}
    </Tag>
  );
};

export default K2StatusTag;
