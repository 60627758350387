import React, { Component } from "react";

import { Col, Row } from "antd";
import { GetRowKey } from "antd/es/table/interface";
import { ColumnProps } from "antd/lib/table";

import ActionGridModel, {
  K2GridAction,
} from "appRedux/models/grid/ActionGridModel";
import { IK2GridColumn } from "appRedux/models/grid/K2GridColumn";

import ActionDropDown from "../k2Dropdowns/ActionsDropDown";
import K2Message from "../k2Localizations/K2Message";
import { InjectedK2IntlProps } from "../k2Localizations/types";
import ActionConfirmModal from "./ActionConfirmModal";
import { GridActionsHeaderOptions, K2GridSelectedState } from "./types";

interface GridActionsHeaderProps<T>
  extends GridActionsHeaderOptions,
    InjectedK2IntlProps {
  selectedState: K2GridSelectedState<T>;
  columns: IK2GridColumn<T>[];
  rowClassName?: (record: T, action: string) => string;
  keyField: string | GetRowKey<T>;
}

interface GridActionHeaderState {
  confirmModelVisible: boolean;
  currentAction: K2GridAction;
}

class GridActionsHeader<T> extends Component<
  GridActionsHeaderProps<T>,
  GridActionHeaderState
> {
  state: GridActionHeaderState = {
    confirmModelVisible: false,
    currentAction: null,
  };

  setModalState = (visible: boolean, action: K2GridAction) => {
    this.setState({ confirmModelVisible: visible, currentAction: action });
  };

  onActionExecuted = (key: string) => {
    const { actionsOptions, selectedState, onActionClick } = this.props;
    const item = actionsOptions.find((x) => x.key === key);
    if (item.showConfirm) {
      item.onConfirmOpen?.();
      this.setModalState(true, item);
    } else {
      onActionClick(key, selectedState.keys, selectedState.allPagesSelected);
    }
  };

  /** @event onActionModalOkClicked Callback for action Modal's OK button
   * Performs the operation action Modal was opened for
   */
  onActionModalOkClicked = () => {
    const { selectedState, onActionClick } = this.props;
    const { currentAction } = this.state;
    currentAction.onConfirmOK?.();
    onActionClick(
      currentAction.key,
      selectedState.keys,
      selectedState.allPagesSelected,
    );
    this.setModalState(false, null);
  };

  /** @event onActionModalCancelClicked Cancel callback for the action Modal
   * Closes the action Modal
   */
  onActionModalCancelClicked = () => {
    this.state.currentAction?.onConfirmCancel?.();
    this.setModalState(false, null);
  };

  renderActionConfirmModal = () => {
    const { currentAction, confirmModelVisible } = this.state;
    const {
      selectedState,
      columns,
      keyField,
      k2Intl,
      rowClassName,
    } = this.props;
    let title = k2Intl?.formatMessage({ localeKey: currentAction.titleKey }),
      message = currentAction.confirmMessage;

    if (!message) {
      if (selectedState.allPagesSelected) {
        message = (
          <K2Message
            localeKey="common.actionAllRecordsConfirmMessage"
            values={{ actionType: title }}
          />
        );
      } else {
        message = (
          <K2Message
            localeKey="common.actionConfirmMessage"
            values={{ actionType: title, total: selectedState.keys.length }}
          />
        );
      }
    }

    const modalGridColumns: ColumnProps<T>[] = columns
      .filter((c) => c.showInActionModal)
      ?.map?.((x) => ({
        ...x,
        sorter: false,
        render: x.plainRender || x.render,
      }));

    return (
      <ActionConfirmModal<T>
        key="actionModal"
        hideGrid={
          selectedState.rows.length === 0 || modalGridColumns.length === 0
        }
        rowClassName={(record: T) =>
          rowClassName?.(record, this.state.currentAction.key)
        }
        modalGridKeyField={keyField}
        totalRecords={selectedState.rows.length}
        titleContent={title}
        messageContent={message}
        visible={confirmModelVisible}
        onOkClicked={this.onActionModalOkClicked}
        isLoading={false}
        onCancelClicked={this.onActionModalCancelClicked}
        modalGridColumns={modalGridColumns}
        modalGridDataSource={selectedState.rows}
        modalGridPageSize={10}
      />
    );
  };

  renderActions = () => {
    const {
      selectedState,
      actionsOptions,
      additionalActionButton,
    } = this.props;
    const { allPagesSelected, keys } = selectedState;
    const showActions = actionsOptions && (keys.length > 0 || allPagesSelected);

    return (
      <>
        {showActions && (
          <ActionDropDown
            key="action-dropdown"
            items={ActionGridModel.reconcileActions(actionsOptions)}
            handleItemClick={this.onActionExecuted}
            className="n3o-mb-0"
            titleKey="common.selectAction"
          />
        )}
        {additionalActionButton && (
          <div style={{ marginLeft: 12, display: "inline-block" }}>
            {additionalActionButton}
          </div>
        )}
      </>
    );
  };

  render() {
    const {
      selectedState,
      actionsOptions,
      additionalActionButton,
    } = this.props;
    const { confirmModelVisible } = this.state;
    const { allPagesSelected, keys } = selectedState;
    const showActions = actionsOptions && (keys.length > 0 || allPagesSelected);

    return (
      showActions && [
        <Row key="actionRow">
          <Col xs={24} sm={12}>
            {this.renderActions()}
          </Col>
        </Row>,
        confirmModelVisible && this.renderActionConfirmModal(),
      ]
    );
  }
}

export default GridActionsHeader;
