import React, { PureComponent } from "react";
import { ConfigProvider, Empty, Table } from "antd";
import { TableProps } from "antd/es/table";
import styled from "styled-components";
import { K2Message } from "components/k2Widgets/index";
import { generateUuid } from "@azure/ms-rest-js";

export interface K2GridProps<T extends Object> extends TableProps<T> {
  emptyDataMessage?: React.ReactNode;
  emptyDataMessageKey?: string;
  hoverStyling?: boolean;
}

const TableWithStyle = styled(Table)`
  && {
    &.ant-table.ant-table-small {
      font-size: 11px !important;
    }
    .ant-spin-dot-item {
      background-color: ${({ theme }) => theme.primaryColor};
    }
    .ant-checkbox:hover,
    .ant-checkbox-checked:hover {
      .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.primaryColor} !important;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.primaryColor} !important;
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }

    &.withoutHoverStyle {
      .ant-table-row {
        &:hover {
          td {
            background: unset !important;
          }
        }
      }
    }
  }
`;

class K2Grid<T extends Object> extends PureComponent<K2GridProps<T>> {
  state = {
    tableId: generateUuid(),
    scrollConfig: {
      ...(this.props.scroll || {}),
      y: this.props.scroll?.y || 600,
    },
  };

  componentDidUpdate(
    prevProps: Readonly<K2GridProps<T>>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    if (
      this.props.scroll?.y !== prevProps.scroll?.y ||
      this.props.scroll?.x !== prevProps.scroll?.x
    ) {
      this.setState({
        scrollConfig: {
          ...(this.props.scroll || {}),
          y: this.props.scroll?.y || 600,
        },
      });
    }
  }

  getPopUpContainer = (elem) => {
    return document.getElementById(this.state.tableId) || elem;
  };

  render() {
    const {
      className,
      emptyDataMessage,
      emptyDataMessageKey,
      hoverStyling = true,
      ...rest
    } = this.props;
    return (
      <ConfigProvider getPopupContainer={this.getPopUpContainer}>
        <div id={this.state.tableId}>
          <TableWithStyle
            className={`k2grid custom-scrollbar-style ${
              className ? className : ""
            } ${hoverStyling ? "" : "withoutHoverStyle"}`}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  className={"n3o-my-5"}
                  description={
                    emptyDataMessage ? (
                      emptyDataMessage
                    ) : (
                      <K2Message
                        localeKey={
                          emptyDataMessageKey || "common.noRecordsFound"
                        }
                      />
                    )
                  }
                />
              ),
            }}
            {...rest}
            scroll={this.state.scrollConfig}
          />
        </div>
      </ConfigProvider>
    );
  }
}

export default K2Grid;
