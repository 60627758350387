import React, { Component } from "react";

import { LocalizationSettingsRes } from "@n3oltd/k2.users.sdk.users/esm";
import { Col, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";

import { NamedLookup } from "appRedux/modules/lookups/types";
import { ILocalizationData } from "appRedux/modules/sharedTypes";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { ILabelWrapperLayout } from "constants/FormLayouts";

import { GeneralFormItemV4 } from "../../formItems";

interface LocalizationFormFieldsProps
  extends ILocalizationData,
    LocalizationSettingsRes,
    InjectedK2IntlProps {
  form: FormInstance;
  areFieldsRequired: boolean;
  fieldsLayout: ILabelWrapperLayout;
  languageKey?: string;
  timeZoneKey?: string;
  dateFormatKey?: string;
  timeFormatKey?: string;
  numberFormatKey?: string;
}

const defaults = {
  languageKey: "admin.localization.defaultLanguage",
  timeZoneKey: "admin.localization.defaultTimeZone",
  dateFormatKey: "admin.localization.defaultDateFormat",
  timeFormatKey: "admin.localization.defaultTimeFormat",
  numberFormatKey: "admin.localization.defaultNumberFormat",
};

class LocalizationFormFields extends Component<LocalizationFormFieldsProps> {
  private excludedTimeZones: string[] = [
    "Antarctica/DumontDUrville",
    "Antarctica/McMurdo",
  ];
  constructor(props: LocalizationFormFieldsProps) {
    super(props);
  }

  lookupToDataSourceObject(lookup: NamedLookup) {
    return { id: lookup.id, name: lookup.name };
  }

  getLocalizedTimezones() {
    return this.props.timezones.items
      .filter((t) => !this.excludedTimeZones.includes(t.id))
      ?.map?.(this.lookupToDataSourceObject);
  }

  getLocalizedTimeFormats() {
    return this.props.timeFormats.items?.map?.(this.lookupToDataSourceObject);
  }

  getLocalizedDateFormats() {
    return this.props.dateFormats.items?.map?.(this.lookupToDataSourceObject);
  }

  getLocalizedNumberFormats() {
    return this.props.numberFormats.items?.map?.(this.lookupToDataSourceObject);
  }

  render() {
    const props = this.props;
    return (
      <Row>
        <Col span={24}>
          <GeneralFormItemV4
            colon
            formItemLayout={props.fieldsLayout}
            required={props.areFieldsRequired}
            labelKey={props.languageKey || defaults.languageKey}
            name={"language"}
          >
            <Select
              showSearch
              optionLabelProp={"children"}
              optionFilterProp={"children"}
              showArrow
            >
              {(props.languages?.items || [])?.map?.((language) => (
                <Select.Option key={language.id} value={language.id}>
                  {language.name}
                </Select.Option>
              ))}
            </Select>
          </GeneralFormItemV4>

          <GeneralFormItemV4
            colon
            formItemLayout={props.fieldsLayout}
            required={props.areFieldsRequired}
            labelKey={props.timeZoneKey || defaults.timeZoneKey}
            name={"timezone"}
          >
            <Select
              showSearch
              optionLabelProp={"children"}
              optionFilterProp={"children"}
              showArrow
            >
              {(props.timezones?.items || [])?.map?.((timezone) => (
                <Select.Option key={timezone.id} value={timezone.id}>
                  {timezone.name}
                </Select.Option>
              ))}
            </Select>
          </GeneralFormItemV4>

          <GeneralFormItemV4
            colon
            formItemLayout={props.fieldsLayout}
            required={props.areFieldsRequired}
            labelKey={props.dateFormatKey || defaults.dateFormatKey}
            name={"dateFormat"}
          >
            <Select
              showSearch
              optionLabelProp={"children"}
              optionFilterProp={"children"}
              showArrow
            >
              {this.getLocalizedDateFormats()?.map?.((dateFormat) => (
                <Select.Option key={dateFormat.id} value={dateFormat.id}>
                  {dateFormat.name}
                </Select.Option>
              ))}
            </Select>
          </GeneralFormItemV4>

          <GeneralFormItemV4
            colon
            formItemLayout={props.fieldsLayout}
            required={props.areFieldsRequired}
            labelKey={props.timeFormatKey || defaults.timeFormatKey}
            name={"timeFormat"}
          >
            <Select
              showSearch
              optionLabelProp={"children"}
              optionFilterProp={"children"}
              showArrow
            >
              {this.getLocalizedTimeFormats()?.map?.((timeFormat) => (
                <Select.Option key={timeFormat.id} value={timeFormat.id}>
                  {timeFormat.name}
                </Select.Option>
              ))}
            </Select>
          </GeneralFormItemV4>

          <GeneralFormItemV4
            colon
            formItemLayout={props.fieldsLayout}
            required={props.areFieldsRequired}
            labelKey={props.numberFormatKey || defaults.numberFormatKey}
            name={"numberFormat"}
          >
            <Select
              showSearch
              optionLabelProp={"children"}
              optionFilterProp={"children"}
              showArrow
            >
              {this.getLocalizedNumberFormats()?.map?.((numberFormat) => (
                <Select.Option key={numberFormat.id} value={numberFormat.id}>
                  {numberFormat.name}
                </Select.Option>
              ))}
            </Select>
          </GeneralFormItemV4>
        </Col>
      </Row>
    );
  }
}

export default injectK2Intl(LocalizationFormFields);
