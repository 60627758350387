import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const Comms: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M14.0004 8.31309C14.0004 7.20105 13.2987 6.2093 12.2497 5.84009V5.39642C12.2497 2.49693 9.8992 0.146423 6.9997 0.146423C4.10021 0.146423 1.7497 2.49693 1.7497 5.39642V5.84034C0.382497 6.32255 -0.334933 7.82179 0.147279 9.18899C0.411563 9.93832 1.00103 10.5276 1.75043 10.7917C2.20604 10.9528 2.70597 10.714 2.86705 10.2584C2.90055 10.1636 2.91748 10.0638 2.91709 9.96334V5.39642C2.91709 3.14126 4.74526 1.31309 7.00043 1.31309C9.25559 1.31309 11.0838 3.14126 11.0838 5.39642V9.96334C11.0863 10.2088 11.1922 10.4417 11.3754 10.605V10.9363C11.3754 11.7962 10.6223 12.103 9.91709 12.103H8.87992C8.55776 11.545 7.84423 11.3538 7.28623 11.676C6.72822 11.9981 6.53703 12.7117 6.8592 13.2697C7.18136 13.8277 7.89489 14.0189 8.45289 13.6967C8.63025 13.5943 8.77753 13.447 8.87992 13.2697H9.91709C11.4623 13.2697 12.5421 12.3101 12.5421 10.9363V10.6575C13.4331 10.2154 13.9977 9.30774 14.0004 8.31309Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
