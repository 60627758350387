import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";

export class TimesHelpers {
  public static secondsToString(
    s: number,
    alwaysShowHours: boolean = true,
  ): string {
    let hours = Math.floor(s / 3600);
    let hours_s = String(Math.floor(s / 3600));
    let minutes = Math.floor((s - hours * 3600) / 60);
    let minutes_s = String(Math.floor((s - hours * 3600) / 60));
    let seconds = s - hours * 3600 - minutes * 60;
    let seconds_s = String(Math.floor(s - hours * 3600 - minutes * 60));

    if (hours < 10) {
      hours_s = "0" + hours;
    }
    if (Number(minutes) < 10) {
      minutes_s = "0" + minutes;
    }
    if (Number(seconds) < 10) {
      seconds_s = "0" + seconds;
    }
    const res = hours_s + ":" + minutes_s + ":" + seconds_s;
    if (!alwaysShowHours && res.slice(0, 3) === "00:") {
      return res.slice(0, 3);
    } else {
      return res;
    }
  }

  // Takes seconds as a number, e.g. 125 and returns "2m 5s"
  public static formatSecondsAsMS(
    seconds: number,
    k2Intl: InjectedK2Intl,
    dontShowMS?: boolean,
  ): string {
    const mins = Math.floor(seconds / 60);
    const s = Math.round(seconds - mins * 60); // Don't care about precision here

    if (!dontShowMS)
      return `${mins}${k2Intl
        .formatMessage({ localeKey: "common.mForMinute" })
        .toLowerCase()} ${s}${k2Intl
        .formatMessage({
          localeKey: "common.sForSecond",
        })
        .toLowerCase()}`;
    if (dontShowMS) {
      const formatMins = mins < 10 ? `0${mins}` : `${mins}`;
      const formatSec = s < 10 ? `0${s}` : `${s}`;
      return `${formatMins}:${formatSec}`;
    }
  }

  // Takes minutes as a number, e.g. 130 and returns "2h 10m"
  public static formatMinutesAsHM(
    mins: number,
    k2Intl: InjectedK2Intl,
  ): string {
    const hours = Math.floor(mins / 60);
    const minutes = mins - hours * 60;

    return `${hours}${k2Intl
      .formatMessage({ localeKey: "common.hForHour" })
      .toLowerCase()} ${minutes}${k2Intl
      .formatMessage({
        localeKey: "common.mForMinute",
      })
      .toLowerCase()}`;
  }
}
