import React, { FC, useCallback, useEffect, useState } from "react";

import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { EmailCompositionRes } from "@n3oltd/karakoram.templates.sdk.email";
import { TemplateModelType } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { CompositionType } from "@n3oltd/karakoram.templates.sdk.pdf";
import { Alert, Col, Form, Input, Row, Select, Spin } from "antd";
import { FormInstance } from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import { RcFile } from "antd/lib/upload";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import {
  AssetTypesLookups,
  CategoriesLookups,
} from "appRedux/modules/lookups/types";
import { DataActions } from "appRedux/modules/sharedTypes";
import { GeneralFormItemV4 } from "components/formItems";
import ModifiedInfoDetailsPanel from "components/forms/ModifiedInfoDetailsPanel";
import { K2Button, K2Message } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import { TemporaryUploadFile } from "routes/admin/communication/modules/types";
import FileAttachmentsSelector from "routes/admin/communication/newEditEmailTemplate/components/FileAttachmentsSelector";
import { PropsFromRedux } from "routes/admin/communication/newEditEmailTemplate/connect";
import EmailTemplateHelpers from "routes/admin/communication/newEditEmailTemplate/helpers";
import LayoutSelector from "routes/admin/communication/sharedComponents/LayoutSelector";
import { PreviewLink } from "routes/admin/communication/sharedComponents/PreviewLink";
import TemplateModelsSelector from "routes/admin/communication/sharedComponents/TemplateModelsSelector";
import StylesheetSelector from "routes/admin/communication/sharedComponents/markup/StylesheetSelector";
import VariableList from "routes/admin/communication/sharedComponents/markup/VariableList";
import MediaAssetsSelector from "routes/admin/communication/sharedComponents/markup/assets/MediaAssetsSelector";

type EmailTemplatesProps = PropsFromRedux &
  InjectedK2IntlProps & {
    assetTypes: AssetTypesLookups;
    onSaveSuccess?: (action: DataActions) => void;
    setForm?: (form: FormInstance) => void;
    initialData?: EmailCompositionRes;
  };

interface IProps extends EmailTemplatesProps {
  form: FormInstance;
  editMode: boolean;
  onSubmit: (values) => void;
  onPreview: (values) => void;
  onInvalidPreviewRequest: () => void;
  errors?: string[];
  tempAssets: AssetRes[];
  setTempAssets: (tempAssets: AssetRes[]) => void;
  tempAttachmentAssets: AssetRes[];
  setTempAttachmentAssets: (tempAssets: AssetRes[]) => void;
  showCancelSaveOpts?: boolean;
  categories: CategoriesLookups;
  scopeId?: string;
  fromOutboxes: boolean;
}

const layout = {
  labelCol: {
    xs: 24,
  },
  wrapperCol: {
    xs: 24,
    sm: 24,
    md: 18,
    lg: 12,
  },
};

const CreateEditEmailTemplateForm: FC<IProps> = ({
  form,
  scopeId,
  onSubmit,
  errors,
  k2Intl,
  assetTypes,
  updateTemporaryAssets,
  uploadAsset,
  removeTemporaryAsset: deleteAssetAndRemove,
  clearTemporaryAssets,
  updateDirty,
  dirty,
  tempAssets,
  setTempAssets,
  temporaryMediaUploadFiles,
  temporaryAttachmentUploadFiles,
  tempAttachmentAssets,
  setTempAttachmentAssets,
  saving,
  data,
  onPreview,
  onInvalidPreviewRequest,
  editMode,
  deleteTemplate,
  categories,
  showCancelSaveOpts = true,
  initialData,
  fromOutboxes,
}) => {
  data = data || initialData;

  const [
    selectedTemplateModelType,
    setSelectedTemplateModelType,
  ] = useState<TemplateModelType>();

  const onUploadMediaAsset = useCallback(
    (upload: TemporaryUploadFile, originalFile: RcFile) => {
      uploadAsset(upload, originalFile, "media");
      updateDirty(true);
    },
    [uploadAsset, updateDirty],
  );

  const onUpdateMediaAsset = useCallback(
    (upload: TemporaryUploadFile) => {
      updateTemporaryAssets(upload, "media");
      updateDirty(true);
    },
    [updateTemporaryAssets, updateDirty],
  );

  const removeTemporaryMediaAsset = useCallback(
    (tempAsset: TemporaryUploadFile) =>
      deleteAssetAndRemove(tempAsset, "media"),
    [deleteAssetAndRemove],
  );

  const clearTemporaryMediaAssets = useCallback(() => {
    clearTemporaryAssets("media");
  }, [clearTemporaryAssets]);

  const onUploadAttachmentAsset = useCallback(
    (upload: TemporaryUploadFile, originalFile: RcFile) => {
      uploadAsset(upload, originalFile, "attachment");
      updateDirty(true);
    },
    [uploadAsset, updateDirty],
  );

  const onUpdateAttachmentAsset = useCallback(
    (upload: TemporaryUploadFile) => {
      updateTemporaryAssets(upload, "attachment");
      updateDirty(true);
    },
    [updateTemporaryAssets, updateDirty],
  );

  const removeTemporaryAttachmentAsset = useCallback(
    (tempAsset: TemporaryUploadFile) =>
      deleteAssetAndRemove(tempAsset, "attachment"),
    [deleteAssetAndRemove],
  );

  const clearTemporaryAttachmentAssets = useCallback(() => {
    clearTemporaryAssets("attachment");
  }, [clearTemporaryAssets]);

  useEffect(() => {
    setSelectedTemplateModelType(data?.modelType || null);
    form?.setFieldsValue(data);
  }, [data, form]);

  return (
    <DirtyDataGuard dirty={dirty} onNavigate={() => updateDirty(false)}>
      <Spin spinning={saving}>
        {editMode && (
          <ModifiedInfoDetailsPanel
            modifiedInfo={data?.modifiedInfo}
            onDelete={deleteTemplate}
            saving={saving}
            canDelete={data?.canDelete}
            revisionId={data?.revisionId}
            confirmationTitleKey={"admin.communication.template.delete"}
          />
        )}

        <Form
          form={form}
          onFinish={onSubmit}
          onFinishFailed={(err: ValidateErrorEntity) => {
            UIUtils.scrollToFirstErrorWithOffset(form, err);
          }}
          onValuesChange={() => updateDirty(true)}
          initialValues={initialData || data}
        >
          <GeneralFormItemV4
            name={EmailTemplateHelpers.nameFieldName}
            {...layout}
            labelKey={"common.name"}
            errorLabelKey={"common.name"}
            required
          >
            <Input />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={EmailTemplateHelpers.layoutIdFieldName}
            labelKey={"admin.communication.layout"}
            errorLabelKey={"admin.communication.layout"}
            {...layout}
          >
            <LayoutSelector
              entity={CompositionType.Email}
              k2Intl={k2Intl}
              scopeId={scopeId}
            />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={EmailTemplateHelpers.templateModelTypesFieldName}
            {...layout}
            labelKey={"admin.communication.mergeModelTypes"}
            errorLabelKey={"admin.communication.mergeModelTypes"}
          >
            <TemplateModelsSelector
              fromOutboxes={fromOutboxes}
              onChange={setSelectedTemplateModelType}
            />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={EmailTemplateHelpers.categoryFieldName}
            {...layout}
            errorLabelKey={"admin.communication.category"}
            labelKey={"admin.communication.category"}
            labelTooltipKey={"admin.communication.category.helper"}
          >
            <Select
              placeholder={k2Intl?.formatMessage({
                localeKey: "admin.communication.category.placeholder",
              })}
              optionLabelProp={"children"}
              optionFilterProp={"children"}
              showArrow
            >
              {categories.items?.map?.((category) => {
                return (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                );
              })}
            </Select>
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={EmailTemplateHelpers.subjectFieldName}
            {...layout}
            labelKey={"communication.subject"}
            errorLabelKey={"communication.subject"}
            required
          >
            <Input />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={EmailTemplateHelpers.senderNameFieldName}
            {...layout}
            labelKey={"communication.senderName"}
            errorLabelKey={"communication.senderName"}
            required
          >
            <Input />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={EmailTemplateHelpers.senderEmailFieldName}
            {...layout}
            labelKey={"communication.senderEmail"}
            errorLabelKey={"communication.senderEmail"}
            required
          >
            <Input />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={EmailTemplateHelpers.stylesheetIdsFieldName}
            {...layout}
            labelKey={"admin.communication.stylesheets"}
            errorLabelKey={"admin.communication.stylesheets"}
          >
            <StylesheetSelector />
          </GeneralFormItemV4>

          <PreviewLink>
            <FakeLink
              onClick={() =>
                form
                  ?.validateFields()
                  .then(onPreview)
                  .catch(onInvalidPreviewRequest)
              }
            >
              <K2Message localeKey={"common.preview"} />
            </FakeLink>
          </PreviewLink>

          <GeneralFormItemV4
            name={EmailTemplateHelpers.markupFieldName}
            labelCol={layout.labelCol}
            wrapperCol={{ xs: 24 }}
            labelKey={"admin.communication.email.body"}
            errorLabelKey={"admin.communication.email.body"}
            required
          >
            <TextArea rows={8} />
          </GeneralFormItemV4>

          <VariableList
            templateModelTypeId={selectedTemplateModelType}
            k2Intl={k2Intl}
            pageType={"template"}
            entityType={CompositionType.Email}
          />

          <MediaAssetsSelector
            pageType={"template"}
            assetTypes={assetTypes.items}
            onUpload={onUploadMediaAsset}
            updateTemporaryAssets={onUpdateMediaAsset}
            removeTemporaryAsset={removeTemporaryMediaAsset}
            temporaryUploadFiles={temporaryMediaUploadFiles}
            clearTemporaryAssets={clearTemporaryMediaAssets}
            updateDirty={updateDirty}
            tempAssets={tempAssets}
            setTempAssets={setTempAssets}
            templateId={data?.id}
            originalAssetIds={data?.mediaAssetIds || []}
          />

          <FileAttachmentsSelector
            tempAssets={tempAttachmentAssets}
            setTempAssets={setTempAttachmentAssets}
            updateDirty={updateDirty}
            assetTypes={assetTypes.items}
            onUpload={onUploadAttachmentAsset}
            updateTemporaryAssets={onUpdateAttachmentAsset}
            removeTemporaryAsset={removeTemporaryAttachmentAsset}
            temporaryUploadFiles={temporaryAttachmentUploadFiles}
            clearTemporaryAssets={clearTemporaryAttachmentAssets}
            k2Intl={k2Intl}
            templateId={data?.id}
            originalAssetIds={data?.attachmentAssetIds || []}
          />

          <GeneralFormItemV4
            name={EmailTemplateHelpers.notesFieldName}
            labelCol={layout.labelCol}
            wrapperCol={{ xs: 24 }}
            labelKey={"common.notes"}
            errorLabelKey={"common.notes"}
          >
            <Input />
          </GeneralFormItemV4>

          {errors && errors.length > 0 && (
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <Alert
                  showIcon
                  type="error"
                  message={errors?.map?.((e, i) => (
                    <p key={i}>{e}</p>
                  ))}
                />
              </Col>
            </Row>
          )}

          {showCancelSaveOpts && (
            <Row>
              <K2Button type={"primary"} htmlType={"submit"} disabled={!dirty}>
                <K2Message localeKey={"common.save"} />
              </K2Button>
              <K2Button toUrl={K2Routes.communicationsEmailTemplates}>
                <K2Message localeKey={"common.cancel"} />
              </K2Button>
            </Row>
          )}
        </Form>
      </Spin>
    </DirtyDataGuard>
  );
};

export default CreateEditEmailTemplateForm;
