import React, { FC, useEffect, useMemo, useState } from "react";

import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";

import AppManager from "appRedux/AppManager";
import history from "appRedux/utils/history";
import "assets/vendors/style";
import { useEnvironmentContext } from "common/contexts/EnvironmentProvider/EnvironmentProvider";
import { useAuth0 } from "containers/app/views/auth/Auth0Wrapper";
import SubscriptionsHandler from "containers/app/views/auth/SubscriptionsHandler";

import "./App.css";
import "./styles/k2.less";

interface IProps {
  store: any;
}

const App: FC<IProps> = ({ store }) => {
  const auth0 = useAuth0();
  const { isLoading, isAuthenticated, loginWithRedirect } = auth0;
  const env = useEnvironmentContext();
  const { loaded } = env;

  /**
   * if the user is logged out then this useEffect
   * hook will redirects the user to login again
   */
  useEffect(() => {
    const checkAuth = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.href },
      });
    };

    if (!isAuthenticated && !isLoading) checkAuth();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (loaded) {
      AppManager.initialize(env);
    }
  }, [loaded, env]);

  const appInitialized = useMemo(() => isAuthenticated && !isLoading, [
    isAuthenticated,
    isLoading,
  ]);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SubscriptionsHandler auth0={auth0} appInitialized={appInitialized} />
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
