import { LookupType } from "appRedux/models/lookups/LookupsModel";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { K2LookupsResponse } from "appRedux/modules/lookups/types";
import {
  ActionTypes,
  LookupAction,
} from "appRedux/modules/lookups/actionTypes";

export const setLookupsLoading = (
  loading: boolean,
  lookups?: string[],
): LookupAction => ({
  type: ActionTypes.SET_LOOKUPS_LOADING,
  loading,
  lookups,
});

export const fetchLookups = (lookupTypes: LookupType[]): LookupAction => ({
  type: ActionTypes.FETCH_LOOKUPS,
  lookupTypes,
});

export const setLookups = (
  lookups: IApiResponse<K2LookupsResponse>[],
): LookupAction => ({
  type: ActionTypes.SET_LOOKUPS,
  lookups,
});

export const invalidateLookupsFew = (
  lookupTypes: LookupType[],
): LookupAction => ({
  type: ActionTypes.INVALIDATE_LOOKUPS_FEW,
  lookupTypes,
});

export const invalidateLookupsAll = (): LookupAction => ({
  type: ActionTypes.INVALIDATE_LOOKUPS_All,
});

export const clearError = (): LookupAction => ({
  type: ActionTypes.CLEAR_LOOKUPS_ERROR,
});
