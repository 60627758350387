import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";

const Frame = styled.iframe<{ $height?: number }>`
  display: block;
  width: 97%;
  height: ${({ $height }) => ($height ? `${$height}px` : "400px")};
  margin-right: 3%;
  border: 1px solid ${({ theme }) => theme.border_color_base};
  border-radius: ${({ theme }) => theme.border_radius_base};
`;

const IFrame: FC<{ content: string; height?: number }> = ({
  content,
  height,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (content) {
      if (iframeRef?.current) {
        iframeRef.current.contentWindow.document.write(content);
      }
    }
  }, [content, iframeRef]);

  return <Frame ref={iframeRef} title={"Preview"} $height={height} />;
};

export default IFrame;
