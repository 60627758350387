import { ConnectedProps, connect } from "react-redux";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import IApplicationState from "appRedux/types";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

import * as actions from "./modules/actions";
import reducer from "./modules/reducer";
import saga from "./modules/saga";
import { MeasuresState } from "./types";

const mapStateToProps = (state: IApplicationState): MeasuresState => {
  return state.overviewMeasures;
};

const mapDispatchToProps = {
  fetchMeasures: actions.fetchMeasures,
  fetchPinnedMeasures: actions.fetchPinnedMeasures,
  pinMeasure: actions.pinMeasure,
  fetchMeasurePreview: actions.fetchMeasuresPreview,
  deletePinnedMeasure: actions.deletePinnedMeasure,
  toggleModal: actions.toggleModal,
};

const withSaga = injectSaga({
  key: "overviewMeasures",
  saga,
});

const withReducer = injectReducer({ key: "overviewMeasures", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof withConnect>;

export interface MeasuresOverviewProps
  extends PropsFromRedux,
    InjectedK2IntlProps {}

export default compose(withSaga, withReducer, withConnect);
