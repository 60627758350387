import React, { FC, useEffect, useMemo, useState } from "react";

import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  AggregationFunction,
  UnitOfTimeRes,
} from "@n3oltd/karakoram.analytics.sdk.measures/esm";
import {
  Col,
  Empty,
  Form,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { FormInstance } from "antd/es/form";
import styled from "styled-components";

import { measuresLookups } from "appRedux/models/lookups/LookupsModel";
import {
  AggregationFunctionsLookups,
  MeasureCategoriesLookups,
  TimePeriodTypeLookups,
  UnitsOfTimeLookups,
} from "appRedux/modules/lookups/types";
import injectLookups from "appRedux/utils/injectLookups";
import { TitleWrapper } from "components/dashboard";
import {
  K2Button,
  K2Card,
  K2Message,
  K2Modal,
  K2NumberInputV4,
  K2RadioGroup,
} from "components/k2Widgets";
import { N3oIcon } from "components/n3oIcon";
import { IN3OIconType } from "components/n3oIcon/types";

import EmptyOverviewCard from "./components/Empty";
import MeasureCard from "./components/MeasureCard";
import { MeasuresOverviewProps } from "./connect";
import { PINNED_MEASURE_LENGTH } from "constants/appConstants";

interface IProps extends MeasuresOverviewProps {
  measureCategories?: MeasureCategoriesLookups;
  unitsOfTime?: UnitsOfTimeLookups;
  aggregationFunctions?: AggregationFunctionsLookups;
  timePeriodTypes?: TimePeriodTypeLookups;
}

const MeasureContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatisticsOverview: FC<IProps> = (props) => {
  const {
    measureCategories,
    unitsOfTime,
    aggregationFunctions,
    fetchMeasures,
    fetchPinnedMeasures,
    fetchMeasurePreview,
    deletePinnedMeasure,
    measurePreview,
    pinMeasure,
    measures,
    loading,
    loadingPreview,
    pinnedMeasures,
    k2Intl,
    open,
    toggleModal,
    timePeriodTypes,
  } = props;

  const [form]: [FormInstance] = Form.useForm();

  const [level, setLevel] = useState(1);
  const [category, setCategory] = useState<string>(null);
  const [measureId, setMeasureId] = useState<string>(null);

  useEffect(() => {
    fetchPinnedMeasures();
    // eslint-disable-next-line
  }, []);

  const selectedMeasure = useMemo(() => {
    return measures?.find((m) => m.id === measureId);
  }, [measures, measureId]);

  useEffect(() => {
    fetchMeasures(measureCategories?.items[0]?.id);
    setCategory(measureCategories?.items[0]?.id);

    form?.setFields([
      { name: "periodType", value: "current" },
      {
        name: "period",
        value: unitsOfTime?.items.filter((unit) => unit.isDateUnit)[0]?.id,
      },
      {
        name: "multiplier",
        value: 1,
      },
    ]);
  }, [fetchMeasures, measureCategories, unitsOfTime, setCategory, form]);

  useEffect(() => {
    const values = form.getFieldsValue();
    if (measureId) {
      fetchMeasurePreview(measureId, {
        periodType: values.periodType,
        period: values.period,
        multiplier: values.multiplier,
        aggregation: values.aggregation,
      });
    }
  }, [measureId, form, fetchMeasurePreview]);

  const pinnedMeasureslength = pinnedMeasures?.length || 0;
  const emptyMeasures = pinnedMeasureslength > PINNED_MEASURE_LENGTH ? 0 : PINNED_MEASURE_LENGTH - pinnedMeasureslength;
  return (
    <>
      <Col
        span={24}
        className={"n3o-px-0"}
        style={{ paddingTop: 0, marginTop: "-12px" }}
      >
        <TitleWrapper level={4}>
          <K2Message localeKey="dashboardTitle.overview" />
        </TitleWrapper>
      </Col>
      <Col span={24} style={{ padding: 0 }}>
        <Row gutter={[24, 24]}>
          {pinnedMeasures?.slice(0, PINNED_MEASURE_LENGTH)?.map((measure) => {
            return (
              <Col
                key={measure?.id}
                xs={24}
                md={12}
                xl={6}
                style={{ display: "flex" }}
              >
                <MeasureCard
                  name={measure?.measureType?.name}
                  description={measure?.options?.summary}
                  icon={measure?.measureType?.icon as IN3OIconType}
                  value={measure?.value?.text}
                  trend={measure?.value?.trend}
                  loading={loading}
                  remove={() => deletePinnedMeasure(measure?.revisionId)}
                />
              </Col>
            );
          })}
          {new Array(emptyMeasures).fill(1).map((v, i) => (
            <Col key={i} xs={24} md={12} xl={6}>
              <EmptyOverviewCard handleClick={() => toggleModal()} />
            </Col>
          ))}
        </Row>
      </Col>
      <K2Modal
        centered
        width={level === 1 ? "50%" : "372px"}
        style={{ minWidth: "372px" }}
        title={k2Intl.formatMessage({ localeKey: "dashboard.measures.title" })}
        visible={open}
        destroyOnClose
        afterClose={() => setLevel(1)}
        onCancel={() => {
          toggleModal();
          setLevel(1);
          setCategory(measureCategories?.items[0]?.id);
        }}
        footer={
          level === 1 ? null : (
            <CustomFooter>
              <K2Button
                type="link"
                icon={<ArrowLeftOutlined />}
                onClick={() => setLevel(1)}
              >
                {k2Intl.formatMessage({
                  localeKey: "dashboard.measures.back",
                })}
              </K2Button>
              <K2Button
                type="primary"
                loading={loading}
                disabled={loading || !measureId || loadingPreview}
                onClick={() => {
                  form.submit();
                }}
              >
                {k2Intl.formatMessage({
                  localeKey: "dashboard.measures.addMetric",
                })}
              </K2Button>
            </CustomFooter>
          )
        }
      >
        {level === 1 ? (
          <div>
            <div style={{ marginBottom: "4px" }}>
              <Typography.Text>
                {k2Intl.formatMessage({
                  localeKey: "dashboard.measures.select.category",
                })}
              </Typography.Text>
            </div>
            <Select<string>
              value={category}
              style={{ width: 200 }}
              placeholder={k2Intl.formatMessage({
                localeKey: "dashboard.measures.select.category",
              })}
              onSelect={(value) => {
                fetchMeasures(value);
                setCategory(value);
              }}
            >
              {measureCategories?.items?.map((category) => (
                <Select.Option key={category.id} value={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>

            {loading ? (
              <Skeleton active />
            ) : measures?.length === 0 ? (
              <Empty style={{ marginTop: "32px" }} />
            ) : (
              <Row style={{ marginTop: "12px" }} gutter={[16, 16]}>
                {category &&
                  measures?.map((measure) => (
                    <Col xs={24} lg={12} key={measure.id}>
                      <K2Card
                        shadow
                        style={{ height: "100%" }}
                        className="n3o-pointer"
                        onClick={() => {
                          form?.setFields([
                            {
                              name: "aggregation",
                              value: measure?.type?.allowedAggregations[0],
                            },
                          ]);
                          setMeasureId(measure.id);
                          setLevel(2);
                        }}
                      >
                        <MeasureContent>
                          <Space direction="vertical">
                            <Typography.Text>
                              {measure?.type?.name}
                            </Typography.Text>
                            <Typography.Text
                              type="secondary"
                              style={{ fontSize: "12px", marginRight: "24px" }}
                            >
                              {measure.type?.description}
                            </Typography.Text>
                          </Space>

                          <N3oIcon
                            icontype={measure.type?.icon as IN3OIconType}
                          />
                        </MeasureContent>
                      </K2Card>
                    </Col>
                  ))}
              </Row>
            )}
          </div>
        ) : (
          <div>
            <Form
              form={form}
              layout="vertical"
              onValuesChange={(change, values) => {
                fetchMeasurePreview(measureId, {
                  periodType: values.periodType,
                  period: values.period,
                  multiplier: values.multiplier ? values.multiplier : 1,
                  aggregation: values.aggregation,
                });
              }}
              onFinish={(values) => {
                pinMeasure(measureId, {
                  periodType: values.periodType,
                  period: values.period,
                  multiplier: values.multiplier,
                  aggregation: values.aggregation,
                });
              }}
            >
              <Form.Item name={"periodType"}>
                <K2RadioGroup buttonStyle="solid">
                  {timePeriodTypes?.items?.map((type) => (
                    <Radio.Button key={type.id} value={type.id}>
                      {type.name}
                    </Radio.Button>
                  ))}
                </K2RadioGroup>
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {() => {
                  const isRolling =
                    form.getFieldValue("periodType") === "rolling";

                  return isRolling ? (
                    <Form.Item
                      name={"multiplier"}
                      label={k2Intl.formatMessage({
                        localeKey: "dashboard.measures.select.last",
                      })}
                      initialValue={1}
                      rules={[{ type: "number", min: 1 }]}
                    >
                      <K2NumberInputV4 min={1} />
                    </Form.Item>
                  ) : null;
                }}
              </Form.Item>

              <Form.Item shouldUpdate>
                {() => {
                  const isRolling =
                    form.getFieldValue("periodType") === "rolling";
                  return (
                    <Form.Item name={"period"}>
                      <K2RadioGroup buttonStyle="solid">
                        {unitsOfTime?.items
                          ?.filter((unit) => unit.isDateUnit)
                          .map((period: UnitOfTimeRes) => (
                            <Radio.Button key={period.id} value={period.id}>
                              {isRolling
                                ? period.pluralName
                                : period.singularName}
                            </Radio.Button>
                          ))}
                      </K2RadioGroup>
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="aggregation"
                label={k2Intl.formatMessage({
                  localeKey: "dashboard.measures.select.show",
                })}
                hidden={selectedMeasure?.type?.allowedAggregations.length === 1}
              >
                <Select>
                  {aggregationFunctions.items
                    .filter((aggregation) =>
                      selectedMeasure?.type?.allowedAggregations.includes(
                        aggregation.id as AggregationFunction,
                      ),
                    )
                    .map((aggregation) => (
                      <Select.Option
                        key={aggregation?.id}
                        value={aggregation?.id}
                      >
                        {aggregation?.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
            {loadingPreview ? (
              <Skeleton active />
            ) : (
              measurePreview && (
                <MeasureCard
                  name={selectedMeasure.type.name}
                  description={selectedMeasure.type.description}
                  icon={selectedMeasure.type.icon as IN3OIconType}
                  value={measurePreview.text}
                  trend={measurePreview.trend}
                  loading={loading}
                />
              )
            )}
          </div>
        )}
      </K2Modal>
    </>
  );
};

export default injectLookups(StatisticsOverview, measuresLookups);
