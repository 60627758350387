import React from "react";
import styled from "styled-components";
import { Tag } from "antd";

const { CheckableTag } = Tag;

const K2CheckableTag = styled(CheckableTag)`
  && {
    &.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
      color: ${({ theme }) => theme.link_color};
    }
    &.ant-tag-checkable-checked {
      background-color: ${({ theme }) => theme.link_color};
    }
    &.ant-tag-checkable:active {
      background-color: ${({ theme }) => theme.primary_9};
    }
  }
`;

export default K2CheckableTag;
