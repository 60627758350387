import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const PaymentCardDeactivate: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.25 1.75H1.75C1.33516 1.75 1 2.08516 1 2.5V17.5C1 17.9148 1.33516 18.25 1.75 18.25H8.2C8.64183 18.25 9 17.8918 9 17.45V17.3625C9 16.9207 8.64183 16.5625 8.2 16.5625H2.6875V8.3125H20.3125V9.7C20.3125 10.1418 20.6707 10.5 21.1125 10.5H21.2C21.6418 10.5 22 10.1418 22 9.7V2.5C22 2.08516 21.6648 1.75 21.25 1.75ZM20.3125 3.4375H2.6875V6.25H20.3125V3.4375Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 11.75C14.3247 11.75 11.75 14.3247 11.75 17.5C11.75 20.6753 14.3247 23.25 17.5 23.25C20.6753 23.25 23.25 20.6753 23.25 17.5C23.25 14.3247 20.6753 11.75 17.5 11.75ZM21.0167 20.0038L14.9962 13.9833C15.7021 13.4794 16.5667 13.183 17.5 13.183C19.8836 13.183 21.817 15.1164 21.817 17.5C21.817 18.4333 21.5206 19.2979 21.0167 20.0038ZM13.183 17.5C13.183 16.5667 13.4794 15.7021 13.9833 14.9962L20.0038 21.0167C19.2979 21.5206 18.4333 21.817 17.5 21.817C15.1164 21.817 13.183 19.8836 13.183 17.5Z"
      fill={fill}
    />
  </svg>
);
