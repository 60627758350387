import { RoleCriteria, RoleResultsPage } from "@n3oltd/k2.users.sdk.roles/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

import { ActionTypes } from "./actionTypes";

export function fetchRoles(
  criteria: RoleCriteria = {},
  shouldAppendToList?: boolean,
) {
  return {
    type: ActionTypes.FETCH_ROLES,
    criteria,
    shouldAppendToList,
  };
}

export function fetchRolesError(error: ServerError) {
  return {
    type: ActionTypes.FETCH_ROLES_ERROR,
    error,
  };
}

export function fetchRolesSuccess(
  data: RoleResultsPage,
  shouldAppendToList: boolean,
) {
  return {
    type: ActionTypes.FETCH_ROLES_SUCCESS,
    data,
    shouldAppendToList,
  };
}

export function clearServerError() {
  return {
    type: ActionTypes.CLEAR_FETCH_ROLES_ERROR,
  };
}

export function clearEditStatus() {
  return {
    type: ActionTypes.CLEAR_EDIT_STATUS,
  };
}

export function deleteRole(id: string, revisionId: string) {
  return {
    type: ActionTypes.DELETE_ROLE,
    id,
    revisionId,
  };
}

export function deleteRoleSuccess(id: string, editStatus: EditStatus<void>) {
  return {
    type: ActionTypes.DELETE_ROLE_SUCCESS,
    id,
    editStatus,
  };
}

export function deleteRoleError(editStatus: EditStatus<void>) {
  return {
    type: ActionTypes.DELETE_ROLE_ERROR,
    editStatus,
  };
}
