export const CREATE_DONATION_ITEM: string =
  "@donationItemsAdmin/create-donation-item/request";

export const CREATE_DONATION_ITEM_SUCCESS: string =
  "@donationItemsAdmin/create-donation-item/success";

export const CREATE_DONATION_ITEM_ERROR: string =
  "@donationItemsAdmin/create-donation-item/error";

export const CLEAR_CREATE_DONATION_ITEM_ERROR: string =
  "@donationItemsAdmin/create-donation-item/clear-error";

export const CLEAR_DONATION_ITEM_EDIT_STATUS: string =
  "@donationItemsAdmin/create-donation-item/clear-edit-status";

export const UPDATE_DONATION_ITEM: string =
  "@donationItemsAdmin/update-donation-item/request";

export const UPDATE_DONATION_ITEM_SUCCESS: string =
  "@donationItemsAdmin/update-donation-item/success";

export const UPDATE_DONATION_ITEM_ERROR: string =
  "@donationItemsAdmin/update-donation-item/error";

export const CLEAR_UPDATE_DONATION_ITEM_ERROR: string =
  "@donationItemsAdmin/update-donation-item/clear-error";

export const FETCH_SELECTED_DONATION_ITEM: string =
  "@donationItemsAdmin/selected-donation-item/request";

export const FETCH_SELECTED_DONATION_ITEM_SUCCESS: string =
  "@donationItemsAdmin/selected-donation-item/success";

export const FETCH_SELECTED_DONATION_ITEM_ERROR: string =
  "@donationItemsAdmin/selected-donation-item/error";

export const CLEAR_FETCH_SELECTED_DONATION_ITEM_ERROR: string =
  "@donationItemsAdmin/selected-donation-item/clear-error";

export const CLEAR_SELECTED_DONATION_ITEM: string =
  "@donationItemsAdmin/selected-donation-item/clear-item";

export const DELETE_DONATION_ITEM: string =
  "@donationItemsAdmin/delete-donation-item/request";

export const DELETE_DONATION_ITEM_SUCCESS: string =
  "@donationItemsAdmin/delete-donation-item/success";

export const DELETE_DONATION_ITEM_ERROR: string =
  "@donationItemsAdmin/delete-donation-item/error";

export const CLEAR_DELETE_DONATION_ITEM_ERROR: string =
  "@donationItemsAdmin/delete-donation-item/clear-error";

export const SET_FORM_DIRTY: string = "@donationItemsAdmin/set-form-dirty";
export const CLEAR_FORM_DIRTY: string = "@donationItemsAdmin/clear-form-dirty";
