import { LookupType as CommsLookupType } from "@n3oltd/k2.communications.sdk.lookups";

import injectLookups from "appRedux/utils/injectLookups";

import NewEditSMSTemplate from "./NewEditSMSTemplate";
import composed from "./connect";

const withLookups = injectLookups(NewEditSMSTemplate, [
  CommsLookupType.Categories,
]);

export default composed(withLookups);
