import {
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  TOGGLE_QUICK_NAV_VISIBILITY,
  UPDATE_SCROLL_POSITION,
} from "./actionTypes";
import { LAYOUT_TYPE, NAV_STYLE } from "constants/ThemeSetting";
import { positionValues } from "react-custom-scrollbars";

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function updateScrollPosition(values: positionValues) {
  return {
    type: UPDATE_SCROLL_POSITION,
    values,
  };
}

export function onNavStyleChange(navStyle) {
  return { type: NAV_STYLE, navStyle };
}

export function onLayoutTypeChange(layoutType) {
  return { type: LAYOUT_TYPE, layoutType };
}

export function toggleQuickNavVisibility(visible: boolean) {
  return {
    type: TOGGLE_QUICK_NAV_VISIBILITY,
    visible,
  };
}
