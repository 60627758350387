import React, { useCallback } from "react";

import { Spin } from "antd";
import styled from "styled-components";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Tooltip } from "components/k2Widgets";
import { N3oIcon } from "components/n3oIcon";

export enum CallIconStatus {
  Outbound = "outbound",
  Inbound = "inbound",
  Break = "break",
  Ready = "ready",
}
interface ICallIconProps {
  status: CallIconStatus;
  content: string | React.ReactNode;
  spinning: boolean;
  isOnBreak: boolean;
  onClick: () => void;
}
const StyledCallIcon = styled.button<{ $status }>`
  line-height: 53px !important;
  background-color: ${({ theme, $status }) =>
    $status === CallIconStatus.Inbound
      ? theme.primary_6
      : $status === CallIconStatus.Outbound
      ? theme.green_6
      : $status === CallIconStatus.Break
      ? theme.gold_6
      : theme.white_color} !important;
  border: 2px solid white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  height: 60px;
  border-radius: 1000px;
  min-width: 60px;
  color: ${({ theme }) => theme.white_color};
  &:hover {
    opacity: 0.7;
  }

  * + * {
    margin-left: 10px;
  }
`;

const Container = styled.div`
  .ant-spin-container {
    box-shadow: 0px 10px 50px 0px rgba(174, 184, 194, 0.5);
  }
`;

function CallIcon({
  status,
  content,
  spinning,
  isOnBreak,
  onClick,
}: ICallIconProps) {
  const { theme } = useAppContext();

  const getIcon = useCallback(
    (s) => {
      switch (s) {
        case CallIconStatus.Break:
          return <N3oIcon icontype={"n3o-on-break"} fill={theme.white_color} />;
        case CallIconStatus.Inbound:
          return (
            <N3oIcon icontype={"n3o-inbound-call"} fill={theme.white_color} />
          );
        case CallIconStatus.Outbound:
          return (
            <N3oIcon icontype={"n3o-outbound-call"} fill={theme.white_color} />
          );
        case CallIconStatus.Ready:
          return (
            <N3oIcon
              icontype={"n3o-callcenter-headphone"}
              fill={theme.green_6}
            />
          );
      }
    },
    [theme],
  );

  const getIconToRender = () => {
    return (
      <Container id={"iconSpin"} onClick={onClick}>
        <Spin spinning={spinning}>
          <StyledCallIcon $status={status}>
            {getIcon(status)}
            {content}
          </StyledCallIcon>
        </Spin>
      </Container>
    );
  };
  return isOnBreak ? (
    <K2Tooltip titleKey={"communication.cc.resumeShift"}>
      {getIconToRender()}
    </K2Tooltip>
  ) : (
    getIconToRender()
  );
}

export default CallIcon;
