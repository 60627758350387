import React, { FC, useCallback, useEffect, useState } from "react";

import { QuestionCircleTwoTone } from "@ant-design/icons";
import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { TemplateModelType } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { PdfCompositionRes } from "@n3oltd/karakoram.templates.sdk.pdf";
import {
  CompositionType,
  LetterheadRes,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";
import { Alert, Form, Input, InputNumber, Row, Select, Spin } from "antd";
import { FormInstance } from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import { RcFile } from "antd/lib/upload";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import {
  AssetTypesLookups,
  BatchQueuesLookups,
  CategoriesLookups,
  PageOrientationsLookups,
  PageSizedLookups,
} from "appRedux/modules/lookups/types";
import { DataActions } from "appRedux/modules/sharedTypes";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { GeneralFormItemV4 } from "components/formItems";
import ModifiedInfoDetailsPanel from "components/forms/ModifiedInfoDetailsPanel";
import {
  K2Button,
  K2Checkbox,
  K2Message,
  K2Tooltip,
} from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import { TemporaryUploadFile } from "routes/admin/communication/modules/types";
import LetterheadSelector from "routes/admin/communication/newEditPdfTemplate/components/LetterheadSelector";
import PrologueEpilogueSelector from "routes/admin/communication/newEditPdfTemplate/components/PrologueEpilogueSelector";
import PdfTemplateHelpers from "routes/admin/communication/newEditPdfTemplate/helpers";
import LayoutSelector from "routes/admin/communication/sharedComponents/LayoutSelector";
import { PreviewLink } from "routes/admin/communication/sharedComponents/PreviewLink";
import TemplateModelsSelector from "routes/admin/communication/sharedComponents/TemplateModelsSelector";
import StylesheetSelector from "routes/admin/communication/sharedComponents/markup/StylesheetSelector";
import VariableList from "routes/admin/communication/sharedComponents/markup/VariableList";
import MediaAssetsSelector from "routes/admin/communication/sharedComponents/markup/assets/MediaAssetsSelector";

import { PropsFromRedux } from "../connect";

interface IProps extends PropsFromRedux, InjectedK2IntlProps {
  assetTypes: AssetTypesLookups;
  queues: BatchQueuesLookups;
  onSaveSuccess?: (action: DataActions) => void;
  setForm?: (form: FormInstance) => void;
  initialData?: PdfCompositionRes;
  form: FormInstance;
  editMode: boolean;
  onSubmit: (values) => void;
  onPreview: (values) => void;
  onInvalidPreviewRequest: () => void;
  previewLoading: boolean;
  errors?: string[];
  tempAssets: AssetRes[];
  setTempAssets: (tempAssets: AssetRes[]) => void;
  tempPrologue?: AssetRes;
  tempEpilogue?: AssetRes;
  setTempPrologue: (tempPrologue: AssetRes) => void;
  setTempEpilogue: (tempEpilogue: AssetRes) => void;
  showCancelSaveOpts?: boolean;
  categories: CategoriesLookups;
  scopeId?: string;
  pageOrientations: PageOrientationsLookups;
  pageSizes: PageSizedLookups;
}

const layout = {
  labelCol: {
    xs: 24,
  },
  wrapperCol: {
    xs: 24,
    sm: 18,
    md: 12,
  },
};

const CreateEditPdfTemplateForm: FC<IProps> = ({
  form,
  scopeId,
  editMode,
  onSubmit,
  errors,
  k2Intl,
  assetTypes,
  updateTemporaryAssets,
  uploadAsset,
  removeTemporaryAsset: deleteAssetAndRemove,
  onInvalidPreviewRequest,
  removeEpilogueOrPrologue,
  clearTemporaryAssets,
  updateDirty,
  dirty,
  tempAssets,
  setTempAssets,
  temporaryMediaUploadFiles,
  saving,
  data,
  queues,
  tempEpilogue,
  tempPrologue,
  setTempEpilogue,
  setTempPrologue,
  clearEpilogueOrPrologue,
  temporaryEpilogueUploadFile,
  temporaryPrologueUploadFile,
  updateEpilogueOrPrologue,
  uploadEpilogueOrPrologue,
  onPreview,
  previewLoading,
  deletePdfTemplate,
  categories,
  showCancelSaveOpts = true,
  initialData,
  pageSizes,
  pageOrientations,
}) => {
  data = data || initialData;

  const { theme } = useAppContext();

  const [
    selectedTemplateModelType,
    setSelectedTemplateModelType,
  ] = useState<TemplateModelType>();
  const [selectedLetterhead, setSelectedLetterhead] = useState<LetterheadRes>(
    null,
  );

  useEffect(() => {
    if (data) {
      setSelectedTemplateModelType(data.modelType);
    }
  }, [data]);

  const onUploadMediaAsset = useCallback(
    (upload: TemporaryUploadFile, originalFile: RcFile) => {
      uploadAsset(upload, originalFile);
      updateDirty(true);
    },
    [uploadAsset, updateDirty],
  );

  const onUpdateMediaAsset = useCallback(
    (upload: TemporaryUploadFile) => {
      updateTemporaryAssets(upload);
      updateDirty(true);
    },
    [updateTemporaryAssets, updateDirty],
  );

  const removeTemporaryMediaAsset = useCallback(
    (tempAsset: TemporaryUploadFile) => deleteAssetAndRemove(tempAsset),
    [deleteAssetAndRemove],
  );

  const clearTemporaryMediaAssets = useCallback(() => {
    clearTemporaryAssets();
  }, [clearTemporaryAssets]);

  const removePrologue = useCallback(
    (temp: TemporaryUploadFile) => {
      removeEpilogueOrPrologue("prologue", temp);
    },
    [removeEpilogueOrPrologue],
  );

  const removeEpilogue = useCallback(
    (temp: TemporaryUploadFile) => {
      removeEpilogueOrPrologue("epilogue", temp);
    },
    [removeEpilogueOrPrologue],
  );

  const clearPrologue = useCallback(() => {
    clearEpilogueOrPrologue("prologue");
  }, [clearEpilogueOrPrologue]);

  const clearEpilogue = useCallback(() => {
    clearEpilogueOrPrologue("epilogue");
  }, [clearEpilogueOrPrologue]);

  const updatePrologue = useCallback(
    (upload: TemporaryUploadFile) => {
      updateEpilogueOrPrologue("prologue", upload);
      updateDirty(true);
    },
    [updateEpilogueOrPrologue, updateDirty],
  );

  const updateEpilogue = useCallback(
    (upload: TemporaryUploadFile) => {
      updateEpilogueOrPrologue("epilogue", upload);
      updateDirty(true);
    },
    [updateEpilogueOrPrologue, updateDirty],
  );

  const onUploadPrologue = useCallback(
    (upload: TemporaryUploadFile, originalFile: RcFile) => {
      uploadEpilogueOrPrologue("prologue", upload, originalFile);
      updateDirty(true);
    },
    [uploadEpilogueOrPrologue, updateDirty],
  );

  const onUploadEpilogue = useCallback(
    (upload: TemporaryUploadFile, originalFile: RcFile) => {
      uploadEpilogueOrPrologue("epilogue", upload, originalFile);
      updateDirty(true);
    },
    [uploadEpilogueOrPrologue, updateDirty],
  );

  useEffect(() => {
    if (data) {
      form?.setFieldsValue({
        ...data,
        filename: {
          markup: PdfTemplateHelpers.ensureDoesNotEndWithPdf(
            data?.filename?.markup,
          ),
        },
        printQueue: data?.printQueue || "n3o__default",
        collationOrder: data?.collationOrder || "",
      });
    }
  }, [data, form]);

  return (
    <DirtyDataGuard dirty={dirty} onNavigate={() => updateDirty(false)}>
      <Spin spinning={saving}>
        {editMode && (
          <ModifiedInfoDetailsPanel
            modifiedInfo={data?.modifiedInfo}
            onDelete={deletePdfTemplate}
            saving={saving}
            canDelete={data?.canDelete}
            revisionId={data?.revisionId}
            confirmationTitleKey={`admin.communication.template.delete`}
          />
        )}

        <Form
          form={form}
          onFinish={onSubmit}
          onFinishFailed={(err: ValidateErrorEntity) => {
            UIUtils.scrollToFirstErrorWithOffset(form, err);
          }}
          onValuesChange={() => updateDirty(true)}
          initialValues={{
            ...(initialData || data),
            filename: {
              markup: PdfTemplateHelpers.ensureDoesNotEndWithPdf(
                initialData?.filename?.markup || data?.filename?.markup,
              ),
            },
            printQueue: data?.printQueue || "n3o__default",
            collationOrder: data?.collationOrder || "",
          }}
        >
          <GeneralFormItemV4
            name={PdfTemplateHelpers.nameFieldName}
            {...layout}
            labelKey={"common.name"}
            errorLabelKey={"common.name"}
            required
          >
            <Input />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={PdfTemplateHelpers.filenameFieldName}
            {...layout}
            labelKey={"common.filename"}
            errorLabelKey={"common.filename"}
            required
          >
            <Input
              placeholder={k2Intl?.formatMessage({
                localeKey:
                  "admin.communication.pdf.template.filename.placeholder",
              })}
              suffix={".pdf"}
            />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={PdfTemplateHelpers.docTitleFieldName}
            {...layout}
            labelKey={"admin.communication.pdfTemplates.docTitle"}
            errorLabelKey={"admin.communication.pdfTemplates.docTitle"}
            labelTooltipKey={"admin.communication.pdfTemplates.metadata.expl"}
          >
            <Input />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={PdfTemplateHelpers.authorFieldName}
            {...layout}
            labelKey={"admin.communication.pdfTemplates.docAuthor"}
            errorLabelKey={"admin.communication.pdfTemplates.docAuthor"}
            labelTooltipKey={"admin.communication.pdfTemplates.metadata.expl"}
          >
            <Input />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={PdfTemplateHelpers.layoutIdFieldName}
            labelKey={"admin.communication.layout"}
            errorLabelKey={"admin.communication.layout"}
            {...layout}
          >
            <LayoutSelector
              entity={CompositionType.Pdf}
              k2Intl={k2Intl}
              scopeId={scopeId}
            />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={PdfTemplateHelpers.templateModelTypesFieldName}
            {...layout}
            labelKey={"admin.communication.mergeModelTypes"}
            errorLabelKey={"admin.communication.mergeModelTypes"}
          >
            <TemplateModelsSelector onChange={setSelectedTemplateModelType} />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={PdfTemplateHelpers.categoryFieldName}
            {...layout}
            errorLabelKey={"admin.communication.category"}
            labelKey={"admin.communication.category"}
            labelTooltipKey={"admin.communication.category.helper"}
          >
            <Select
              placeholder={k2Intl?.formatMessage({
                localeKey: "admin.communication.category.placeholder",
              })}
              optionLabelProp={"children"}
              optionFilterProp={"children"}
              showArrow
              allowClear
            >
              {categories.items?.map?.((category) => {
                return (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                );
              })}
            </Select>
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={PdfTemplateHelpers.printQueueFieldName}
            {...layout}
            errorLabelKey={"communication.printQueue"}
            required
            labelKey={"communication.printQueue"}
            labelTooltipKey={"communication.printQueue.tl"}
          >
            <Select
              placeholder={k2Intl?.formatMessage({
                localeKey: "communication.printQueue.pl",
              })}
              optionLabelProp={"children"}
              optionFilterProp={"children"}
              showArrow
            >
              <Select.Option value={"n3o__default"} key={"n3o__default"}>
                <K2Message localeKey={"communication.printQueue.default"} />
              </Select.Option>
              {queues.items?.map?.((queue) => {
                return (
                  <Select.Option key={queue} value={queue}>
                    {queue}
                  </Select.Option>
                );
              })}
            </Select>
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={PdfTemplateHelpers.stylesheetIdsFieldName}
            {...layout}
            labelKey={"admin.communication.stylesheets"}
            errorLabelKey={"admin.communication.stylesheets"}
          >
            <StylesheetSelector />
          </GeneralFormItemV4>

          <LetterheadSelector
            k2Intl={k2Intl}
            onLetterheadSelect={(letterhead: LetterheadRes) =>
              setSelectedLetterhead(letterhead)
            }
            initialLetterheadId={data?.letterheadId}
          />
          <GeneralFormItemV4
            shouldUpdate
            name={PdfTemplateHelpers.preventPdfEditingFieldName}
            valuePropName={"checked"}
            labelTooltipKey="admin.communication.pdf.preventPdfEditing.tooltip"
            initialValue={false}
          >
            <K2Checkbox>
              <K2Message
                localeKey={"admin.communication.pdf.preventPdfEditing"}
              />
              <K2Tooltip
                placement={"rightTop"}
                titleKey={"admin.communication.pdf.preventPdfEditing.tooltip"}
              >
                <QuestionCircleTwoTone
                  style={{ paddingLeft: 10 }}
                  className={"n3o-pointer"}
                />
              </K2Tooltip>
            </K2Checkbox>
          </GeneralFormItemV4>
          <GeneralFormItemV4
            name={PdfTemplateHelpers.collationOrderFieldName}
            {...layout}
            labelKey={"admin.communication.collationOrder"}
            labelTooltipKey={"admin.communication.collationOrderTooltip"}
            errorLabelKey={"admin.communication.collationOrder"}
          >
            <InputNumber min="-999999" max="999999" step="1" />
          </GeneralFormItemV4>

          {selectedLetterhead && (
            <Alert
              message={UIUtils.messageToHtml(
                k2Intl,
                "admin.communication.pdfTemplates.sizeExplanation",
                {
                  orientation: pageOrientations?.items?.filter(
                    (x) => x.id === selectedLetterhead.pageLayout.orientation,
                  )?.[0]?.name,
                  size: pageSizes?.items?.filter(
                    (x) => x.id === selectedLetterhead.pageLayout.size,
                  )?.[0]?.name,
                },
              )}
              style={{ marginTop: 10, marginBottom: 20 }}
              type={"info"}
              showIcon
            />
          )}

          <PrologueEpilogueSelector
            type={"prologue"}
            pageType={"template"}
            initialAssetId={data?.prologueAssetId}
            assetTypes={assetTypes.items}
            tempAsset={tempPrologue}
            setTempAsset={setTempPrologue}
            removeTemporaryAsset={removePrologue}
            clearTemporaryAsset={clearPrologue}
            temporaryUploadFile={temporaryPrologueUploadFile}
            updateTemporaryAsset={updatePrologue}
            updateDirty={updateDirty}
            onUpload={onUploadPrologue}
          />

          <PrologueEpilogueSelector
            type={"epilogue"}
            pageType={"template"}
            initialAssetId={data?.epilogueAssetId}
            assetTypes={assetTypes.items}
            tempAsset={tempEpilogue}
            setTempAsset={setTempEpilogue}
            removeTemporaryAsset={removeEpilogue}
            clearTemporaryAsset={clearEpilogue}
            temporaryUploadFile={temporaryEpilogueUploadFile}
            updateTemporaryAsset={updateEpilogue}
            updateDirty={updateDirty}
            onUpload={onUploadEpilogue}
          />

          <PreviewLink>
            {previewLoading ? (
              <K2Message
                style={{ color: theme.grey_5 }}
                localeKey={"common.pleaseWait"}
              />
            ) : (
              <FakeLink
                onClick={() =>
                  form
                    ?.validateFields()
                    .then(onPreview)
                    .catch(onInvalidPreviewRequest)
                }
              >
                <K2Message localeKey={"common.preview"} />
              </FakeLink>
            )}
          </PreviewLink>

          <GeneralFormItemV4
            name={PdfTemplateHelpers.markupFieldName}
            labelCol={layout.labelCol}
            wrapperCol={{ xs: 24 }}
            labelKey={"admin.communication.pdf.body"}
            errorLabelKey={"admin.communication.pdf.body"}
            required
          >
            <TextArea rows={8} />
          </GeneralFormItemV4>

          <VariableList
            templateModelTypeId={selectedTemplateModelType}
            k2Intl={k2Intl}
            pageType={"template"}
            entityType={CompositionType.Pdf}
          />

          <MediaAssetsSelector
            pageType={"template"}
            assetTypes={assetTypes.items}
            onUpload={onUploadMediaAsset}
            updateTemporaryAssets={onUpdateMediaAsset}
            removeTemporaryAsset={removeTemporaryMediaAsset}
            temporaryUploadFiles={temporaryMediaUploadFiles}
            clearTemporaryAssets={clearTemporaryMediaAssets}
            updateDirty={updateDirty}
            tempAssets={tempAssets}
            setTempAssets={setTempAssets}
            templateId={data?.id}
            originalAssetIds={data?.mediaAssetIds || []}
          />

          <GeneralFormItemV4
            name={PdfTemplateHelpers.notesFieldName}
            labelCol={layout.labelCol}
            wrapperCol={{ xs: 24 }}
            labelKey={"common.notes"}
            errorLabelKey={"common.notes"}
          >
            <Input />
          </GeneralFormItemV4>

          {errors && errors.length > 0 && (
            <Row style={{ marginBottom: 20 }}>
              <Alert
                showIcon
                type="error"
                message={errors?.map?.((e, i) => (
                  <p key={i}>{e}</p>
                ))}
              />
            </Row>
          )}

          {showCancelSaveOpts && (
            <Row>
              <K2Button type={"primary"} htmlType={"submit"} disabled={!dirty}>
                <K2Message localeKey={"common.save"} />
              </K2Button>
              <K2Button toUrl={K2Routes.communicationsPdfTemplates}>
                <K2Message localeKey={"common.cancel"} />
              </K2Button>
            </Row>
          )}
        </Form>
      </Spin>
    </DirtyDataGuard>
  );
};

export default CreateEditPdfTemplateForm;
