import produce from "immer";
import { SMSTemplateState } from "./types";
import { ActionTypes, SMSTemplateAction } from "./actionTypes";

const initialState: SMSTemplateState = {
  data: null,
  editStatus: null,
  error: null,
  loading: false,
  saving: false,
  dirty: false,
};

const smsTemplatesReducer = (state = initialState, action: SMSTemplateAction) =>
  produce(state, (draft: SMSTemplateState) => {
    switch (action.type) {
      case ActionTypes.FETCH_SMS_TEMPLATE:
        draft.loading = true;
        break;
      case ActionTypes.FETCH_SMS_TEMPLATE_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case ActionTypes.FETCH_SMS_TEMPLATE_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        break;
      case ActionTypes.CLEAR_SMS_TEMPLATE:
        return { ...initialState };
      case ActionTypes.CLEAR_SMS_TEMPLATE_EDIT_STATUS:
        draft.editStatus = null;
        break;
      case ActionTypes.CLEAR_SMS_TEMPLATE_ERROR:
        draft.error = null;
        break;

      case ActionTypes.CREATE_SMS_TEMPLATE:
        draft.saving = true;
        break;
      case ActionTypes.CREATE_SMS_TEMPLATE_ERROR:
      case ActionTypes.CREATE_SMS_TEMPLATE_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.UPDATE_SMS_TEMPLATE:
        draft.saving = true;
        break;
      case ActionTypes.UPDATE_SMS_TEMPLATE_ERROR:
      case ActionTypes.UPDATE_SMS_TEMPLATE_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.DELETE_SMS_TEMPLATE:
        draft.saving = true;
        break;
      case ActionTypes.DELETE_SMS_TEMPLATE_ERROR:
      case ActionTypes.DELETE_SMS_TEMPLATE_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.UPDATE_DIRTY:
        draft.dirty = action.dirty;
        break;
    }
  });

export default smsTemplatesReducer;
