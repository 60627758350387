import {
  GlobalSuggestionsEntity as AccountGlobalSuggestionsEntity,
  GlobalSuggestionsResultsList,
} from "@n3oltd/k2.accounts.sdk.global-suggestions/esm";
import { GlobalSuggestionsEntity as DonationGlobalSuggestionsEntity } from "@n3oltd/k2.donations.sdk.global-suggestions/esm";
import { GlobalSuggestionsEntity as PledgeGlobalSuggestionsEntity } from "@n3oltd/k2.pledges.sdk.global-suggestions";
import { GlobalSuggestionsEntity as FeedbackGlobalSuggestionsEntity } from "@n3oltd/karakoram.feedbacks.sdk.global-suggestions";
import { GlobalSuggestionsEntity as NavigationGlobalSuggestionsEntity } from "@n3oltd/karakoram.navigation.sdk.global-suggestions";
import { GlobalSuggestionsEntity as PaymentsGlobalSuggestionsEntity } from "@n3oltd/karakoram.payments.sdk.global-suggestions";
import { GlobalSuggestionsEntity as SponsorshipGlobalSuggestionsEntity } from "@n3oltd/karakoram.sponsorships.sdk.global-suggestions/esm";
import { GlobalSuggestionsEntity as TasksGlobalSuggestionsEntity } from "@n3oltd/karakoram.tasks.sdk.global-suggestions";

import {
  _accountsGlobalSuggestionsClient,
  _donationsGlobalSuggestionsClient,
  _feedbacksGlobalSuggestionsClient,
  _navGlobalSuggestionsClient,
  _paymentsGlobalSuggestionsClient,
  _pledgesGlobalSuggestionsClient,
  _sponsorshipsGlobalSuggestionsClient,
  _taskGlobalSuggestionsClient,
} from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";

import { GlobalSearchQueryObject } from "./types";

export class GlobalSearchFetchers {
  public static search(
    query: GlobalSearchQueryObject,
  ): Promise<IApiResponse<GlobalSuggestionsResultsList>> {
    if (query.searchType === "accounts") {
      return K2Service.toResponse(
        _accountsGlobalSuggestionsClient.suggest({
          searchText: query.searchText,
          entity: AccountGlobalSuggestionsEntity.Account,
        }),
      );
    } else if (query.searchType === "sponsorships") {
      return K2Service.toResponse(
        _sponsorshipsGlobalSuggestionsClient.suggest({
          searchText: query.searchText,
          entity: SponsorshipGlobalSuggestionsEntity.Sponsorship,
        }),
      );
    } else if (query.searchType === "tasks") {
      return K2Service.toResponse(
        _taskGlobalSuggestionsClient.suggest({
          searchText: query.searchText,
          entity: TasksGlobalSuggestionsEntity.Task,
        }),
      );
    } else if (query.searchType === "navigation") {
      return K2Service.toResponse(
        _navGlobalSuggestionsClient.suggest({
          searchText: query.searchText,
          entity: NavigationGlobalSuggestionsEntity.Route,
        }),
      );
    } else if (query.searchType === "donations") {
      return K2RestService.toResponse(
        _donationsGlobalSuggestionsClient.suggest({
          searchText: query.searchText,
          entity: DonationGlobalSuggestionsEntity.Donation,
        }),
      );
    } else if (query.searchType === "givings") {
      return K2RestService.toResponse(
        _donationsGlobalSuggestionsClient.suggest({
          searchText: query.searchText,
          entity: DonationGlobalSuggestionsEntity.Giving,
        }),
      );
    } else if (query.searchType === "payments") {
      return K2RestService.toResponse(
        _paymentsGlobalSuggestionsClient.suggest({
          searchText: query.searchText,
          entity: PaymentsGlobalSuggestionsEntity.Payments,
        }),
      );
    } else if (query.searchType === "pledges") {
      return K2RestService.toResponse(
        _pledgesGlobalSuggestionsClient.suggest({
          searchText: query.searchText,
          entity: PledgeGlobalSuggestionsEntity.Pledge,
        }),
      );
    } else if (query.searchType === "feedbacks") {
      return K2RestService.toResponse(
        _feedbacksGlobalSuggestionsClient.suggest({
          searchText: query.searchText,
          entity: FeedbackGlobalSuggestionsEntity.Feedbacks,
        }),
      );
    }
  }
}
