import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const OnBreak: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.44531 6.58594C6.91172 6.58594 7.28906 6.20859 7.28906 5.74219V0.84375C7.28906 0.377344 6.91172 0 6.44531 0C5.97891 0 5.60156 0.377344 5.60156 0.84375V5.74219C5.60156 6.20859 5.97891 6.58594 6.44531 6.58594ZM20.8125 9.96094H18C18 9.02812 17.2453 8.27344 16.3125 8.27344H4.6875C3.75469 8.27344 3 9.02812 3 9.96094V15.7734C3 15.8531 3.00469 15.9305 3.01641 16.0055C3.00469 16.1695 3 16.3336 3 16.5C3 20.6414 6.35859 24 10.5 24C14.2523 24 17.3602 21.2461 17.9133 17.6484H20.8125C21.7453 17.6484 22.5 16.8937 22.5 15.9609V11.6484C22.5 10.7156 21.7453 9.96094 20.8125 9.96094ZM16.3125 15.9609H16.2867C16.3031 16.1391 16.3125 16.3172 16.3125 16.5C16.3125 19.7109 13.7109 22.3125 10.5 22.3125C7.28906 22.3125 4.6875 19.7109 4.6875 16.5C4.6875 16.3172 4.69688 16.1391 4.71328 15.9609H4.6875V9.96094H16.3125V15.9609ZM20.8125 15.7734H18.1875V11.6484H20.8125V15.7734ZM14.3672 6.58594C14.8336 6.58594 15.2109 6.20859 15.2109 5.74219V0.84375C15.2109 0.377344 14.8336 0 14.3672 0C13.9008 0 13.5234 0.377344 13.5234 0.84375V5.74219C13.5234 6.20859 13.9008 6.58594 14.3672 6.58594ZM10.3828 6.58594C10.8492 6.58594 11.2266 6.20859 11.2266 5.74219V0.84375C11.2266 0.377344 10.8492 0 10.3828 0C9.91641 0 9.53906 0.377344 9.53906 0.84375V5.74219C9.53906 6.20859 9.91641 6.58594 10.3828 6.58594Z"
      fill={fill}
    />
  </svg>
);
