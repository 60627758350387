import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const DonorCare: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4155 4.74889C8.63136 3.53303 10.2804 2.84996 11.9999 2.84996C13.7194 2.84996 15.3685 3.53303 16.5843 4.74889C17.6025 5.76711 18.2471 7.08914 18.4298 8.5019C18.1153 8.55162 17.8222 8.69952 17.5942 8.92751C17.3098 9.21195 17.15 9.59772 17.15 9.99996V16.6666C17.15 17.0689 17.3097 17.4546 17.5942 17.7391C17.8331 17.978 18.1435 18.129 18.4752 18.1712C18.4355 18.5902 18.2513 18.9844 17.9512 19.2845C17.6105 19.6252 17.1484 19.8166 16.6666 19.8166H15.3443C15.2358 19.5598 15.0781 19.3237 14.8771 19.1228C14.4677 18.7133 13.9123 18.4833 13.3333 18.4833H11.9999C11.4209 18.4833 10.8655 18.7133 10.4561 19.1228C10.0466 19.5322 9.81659 20.0876 9.81659 20.6666C9.81659 21.2457 10.0466 21.801 10.4561 22.2105C10.8655 22.6199 11.4209 22.85 11.9999 22.85H13.3333C13.9123 22.85 14.4677 22.6199 14.8771 22.2105C15.0781 22.0095 15.2358 21.7734 15.3443 21.5166H16.6666C17.5993 21.5166 18.4937 21.1461 19.1532 20.4866C19.794 19.8458 20.162 18.9832 20.1824 18.0793C20.7971 17.9263 21.3645 17.6087 21.82 17.1533C22.4795 16.4938 22.85 15.5993 22.85 14.6666V12C22.85 11.0673 22.4795 10.1728 21.82 9.5133C21.3562 9.04953 20.7762 8.72867 20.1485 8.57907C19.9733 6.68535 19.1423 4.90267 17.7864 3.54681C16.2517 2.01213 14.1703 1.14996 11.9999 1.14996C9.82955 1.14996 7.74809 2.01213 6.21342 3.54681C4.85753 4.90269 4.02664 6.68537 3.85144 8.57907C3.2237 8.72867 2.64374 9.04953 2.17997 9.5133C1.52047 10.1728 1.14996 11.0673 1.14996 12V14.6666C1.14996 15.5993 1.52047 16.4938 2.17997 17.1533C2.83947 17.8128 3.73395 18.1833 4.66663 18.1833H5.3333C5.73554 18.1833 6.12131 18.0235 6.40574 17.7391C6.69017 17.4546 6.84996 17.0689 6.84996 16.6666V9.99996C6.84996 9.59772 6.69017 9.21194 6.40574 8.92751C6.17775 8.69952 5.88464 8.55161 5.57013 8.50189C5.75277 7.08911 6.39729 5.76709 7.4155 4.74889Z"
      fill={fill}
    />
  </svg>
);
