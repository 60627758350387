import { SecurityPrincipalResultsList } from "@n3oltd/k2.users.sdk.security-principals/src/index";
import { all, put, takeLatest } from "redux-saga/effects";

import { _securityPrincipalsClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";

import securityPrincipalsActions from "./actions";

function* getSecurityPrincipals(
  action: ReturnType<typeof securityPrincipalsActions.fetchSecurityPrincipals>,
) {
  try {
    const response: IApiResponse<SecurityPrincipalResultsList> = yield K2Service.toResponse(
      _securityPrincipalsClient.find(action.payload),
    );
    yield put(securityPrincipalsActions.setSecurityPrincipals(response));
  } catch (e) {}
}

export default function* securityPrincipalsSaga() {
  yield all[
    yield takeLatest(
      securityPrincipalsActions.fetchSecurityPrincipals.type,
      getSecurityPrincipals,
    )
  ];
}
