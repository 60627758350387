import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import RequireTallyAuth from "routes/Q1/containers/auth/RequireTallyAuth";
import { asyncComponent } from "components/utils";
import { useRouter } from "hooks";
import _404Error from "components/errors/404";

const ViewTaxSchemeWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyViewTaxSchemes" */ "routes/Q1/routes/admin/taxScheme/viewTaxScheme"
      ),
  ),
  "q1AdminViewTaxSchemes",
);

const ViewEditSubmissionWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyEditTaxSchemes" */ "routes/Q1/routes/admin/taxScheme/editTaxScheme"
      ),
  ),
  "q1AdminEditTaxSchemes",
);

const TaxSchemeRoutes = () => {
  const router = useRouter<{}>();
  const { match } = router;
  return (
    <Switch>
      <Route
        exact
        path={Q1Routes.q1AdminViewTaxSchemes}
        component={ViewTaxSchemeWithAuth}
      />

      <Route
        exact
        path={Q1Routes.q1AdminEditTaxSchemes}
        component={ViewEditSubmissionWithAuth}
      />

      <Redirect exact from={match.path} to={Q1Routes.q1AdminViewTaxSchemes} />
      <Redirect
        exact
        path={`${match.path}/tax-relief`}
        to={Q1Routes.q1AdminViewTaxSchemes}
      />
      <Route path={`${match.path}/*`} component={_404Error} />
    </Switch>
  );
};

export default TaxSchemeRoutes;
