import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import RequireTallyAuth from "routes/Q1/containers/auth/RequireTallyAuth";
import { asyncComponent } from "components/utils";

const DepositsListWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyDepositsList" */ "routes/Q1/routes/payingIn/depositsList"
      ),
  ),
  "q1PayingIn",
);

const ViewDepositWithWith = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyViewDeposit" */ "routes/Q1/routes/payingIn/viewDeposit"
      ),
  ),
  "q1PayingIn",
);

const ViewDepositPaymentWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyViewDepositPayment" */ "containers/viewPayment"
      ),
  ),
  "q1PayingIn",
);

const PayingInRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={Q1Routes.main.q1PayingIn}
        component={DepositsListWithAuth}
      />

      <Route
        exact
        path={Q1Routes.q1ViewDepositPayment}
        component={ViewDepositPaymentWithAuth}
      />

      <Route
        exact
        path={Q1Routes.q1ViewDeposit}
        component={ViewDepositWithWith}
      />

      <Redirect exact path={"/tally/paying-in"} to={Q1Routes.main.q1PayingIn} />
    </Switch>
  );
};

export default PayingInRoutes;
