import React, { FC, useCallback, useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons/lib";
import {
  FileResponse,
  OverlayRes,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";
import {
  Alert,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import styled from "styled-components";

import { _pdfCompositionsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import DownloadHelpers from "common/helpers/downloads";
import { GeneralFormItemV4 } from "components/formItems";
import { K2Button, K2Message, K2RadioGroup } from "components/k2Widgets";
import AdminActionGrid from "components/k2Widgets/k2Grid/actionGrid";
import { UIUtils } from "components/utils";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import { TEMPLATE_ENGINE } from "constants/appConstants";
import OverlaysListModel, {
  NewEditPdfStationeryFormProps,
} from "routes/admin/communication/newEditPdfStationery/components/OverlaysModel";
import PdfStationeryHelpers from "routes/admin/communication/newEditPdfStationery/helpers";

const { Button } = Radio;

const layout = {
  labelCol: {
    xs: 24,
  },
  wrapperCol: {
    xs: 24,
    sm: 18,
    md: 13,
  },
};

const OrientationSwitchWrapper = styled.div`
  margin-top: 8px;
`;

const Title = styled.h3`
  font-weight: ${({ theme }) => theme.font_weight_bold};
  font-size: 16px;
  color: ${({ theme }) => theme.grey_8};
  margin: 8px 0 32px;
`;

const CreateEditStationeryForm: FC<NewEditPdfStationeryFormProps> = (props) => {
  const {
    form,
    onSubmit,
    errors,
    k2Intl,
    data,
    loading,
    updateDirty,
    dirty,
    tempOverlays,
    setTempOverlays,
    saving,
    toggleModalShowing,
    letterheadId,
    pageOrientations,
    pageSizes,
  } = props;

  const [orientation, setOrientation] = useState<string>("portrait");

  useEffect(() => {
    if (data) {
      setOrientation(data.pageLayout.orientation);
      form?.setFieldsValue(PdfStationeryHelpers.getInitialValues(data));
    }
  }, [data, form]);

  const onDeleteOverlay = useCallback(
    (index: number) => {
      // If an overlay is deleted, this will shift the values of the screenOnly checkboxes
      // So we must reset the form fields with the new overlays
      const filteredOverlays = tempOverlays.filter((_, i) => i !== index);
      setTempOverlays(filteredOverlays);
      form?.setFieldsValue({
        overlays: filteredOverlays,
      });
      updateDirty(true);
    },
    [setTempOverlays, tempOverlays, form, updateDirty],
  );
  const downloadOverlayFile = async (overlayFilename: string) => {
    const resp: IApiResponse<FileResponse> = await K2RestService.toResponse(
      _pdfCompositionsClient.getOverlayFileByNameQuery(
        letterheadId,
        overlayFilename,
        TEMPLATE_ENGINE,
        null,
      ),
    );

    if (resp.error) {
      UIUtils.handleServerError(k2Intl, resp.error);
    } else {
      DownloadHelpers.downloadFile(resp, overlayFilename);
    }
  };
  const listModel = new OverlaysListModel(props);
  const columns = listModel.getColumns(onDeleteOverlay, downloadOverlayFile);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // When overlay positions change, this changes the values of the screenOnly checkboxes
    // So we must reset the form fields
    if (oldIndex !== newIndex) {
      const updatedOrderTempOverlays = arrayMoveImmutable(
        tempOverlays,
        oldIndex,
        newIndex,
      );
      setTempOverlays(updatedOrderTempOverlays);
      form?.setFieldsValue({
        overlays: updatedOrderTempOverlays,
      });
      updateDirty(true);
    }
  };

  return (
    <DirtyDataGuard dirty={dirty} onNavigate={() => updateDirty(false)}>
      <Spin spinning={saving}>
        <Form
          form={form}
          onFinish={onSubmit}
          onFinishFailed={(err: ValidateErrorEntity) => {
            UIUtils.scrollToFirstErrorWithOffset(form, err);
          }}
          onValuesChange={() => updateDirty(true)}
          initialValues={PdfStationeryHelpers.getInitialValues(data)}
        >
          <Row>
            <Col xs={24}>
              <GeneralFormItemV4
                name={PdfStationeryHelpers.nameFieldName}
                {...layout}
                labelKey={"common.name"}
                errorLabelKey={"common.name"}
                required
              >
                <Input />
              </GeneralFormItemV4>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col xs={24} sm={10} md={6} style={{ marginBottom: 20 }}>
              <GeneralFormItemV4
                name={PdfStationeryHelpers.paperSizeFieldName}
                labelKey={"admin.communication.stationery.size"}
                errorLabelKey={"admin.communication.stationery.size"}
                required
                labelCol={{ xs: 24 }}
                wrapperCol={{ xs: 24 }}
              >
                <Select
                  optionLabelProp="children"
                  optionFilterProp="children"
                  showArrow
                >
                  {pageSizes?.items?.map?.((size) => {
                    return (
                      <Select.Option key={size.id} value={size.id}>
                        {size.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </GeneralFormItemV4>
            </Col>

            <Col
              xs={24}
              sm={{ span: 13, offset: 1 }}
              md={{
                span: 12,
                offset: 1,
              }}
            >
              <GeneralFormItemV4
                name={PdfStationeryHelpers.paperOrientationFieldName}
                labelKey={"admin.communication.stationery.orientation"}
                errorLabelKey={"admin.communication.stationery.orientation"}
                required
                labelCol={{ xs: 24 }}
                wrapperCol={{ xs: 24 }}
                style={{ marginTop: -8 }}
              >
                <OrientationSwitchWrapper>
                  <K2RadioGroup
                    defaultValue="individual"
                    buttonStyle="solid"
                    size="small"
                    value={orientation}
                    onChange={(e) => {
                      setOrientation(e.target.value);
                    }}
                  >
                    {pageOrientations?.items?.map?.((orientation) => (
                      <Button value={orientation.id} key={orientation.id}>
                        {orientation.name}
                      </Button>
                    ))}
                  </K2RadioGroup>
                </OrientationSwitchWrapper>
              </GeneralFormItemV4>
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col xs={24}>
              <Title>
                <K2Message
                  localeKey={"admin.communication.stationery.overlays"}
                />
              </Title>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <AdminActionGrid<OverlayRes>
                sortable={true}
                onSortEnd={onSortEnd}
                columns={columns}
                isLoading={loading}
                keyField={"uuid"}
                dataSource={{
                  items: tempOverlays,
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <K2Button
              type="link"
              onClick={() => toggleModalShowing(true)}
              icon={<PlusOutlined />}
            >
              <K2Message
                localeKey={"admin.communication.stationery.addOverlay"}
              />
            </K2Button>
          </Row>

          {errors && errors.length > 0 && (
            <Row style={{ marginTop: 20 }}>
              <Alert
                showIcon
                type="error"
                message={errors?.map?.((e, i) => (
                  <p key={i}>{e}</p>
                ))}
              />
            </Row>
          )}

          <Row style={{ marginTop: 20 }}>
            <K2Button type={"primary"} htmlType={"submit"} disabled={!dirty}>
              <K2Message localeKey={"common.save"} />
            </K2Button>
            <K2Button toUrl={K2Routes.communicationsPdfStationery}>
              <K2Message localeKey={"common.cancel"} />
            </K2Button>
          </Row>
        </Form>
      </Spin>
    </DirtyDataGuard>
  );
};

export default CreateEditStationeryForm;
