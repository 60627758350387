import {
  createActionCreators,
  createReducerFunction,
  setPrefix,
} from "immer-reducer";
import _ from "lodash";
import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";

import {
  settingSagas,
  settingsInitialState,
  SubscriptionSettingsReducer,
} from "./settings";

import {
  SubscriptionUsersReducer,
  usersInitialState,
  usersSaga,
} from "./users";

//Set ActionCreators Name Prefix
setPrefix("K2Web");

//Subscription Reducer combined
const subscriptionReducers = combineReducers({
  settings: createReducerFunction(
    SubscriptionSettingsReducer,
    settingsInitialState,
  ),
  users: createReducerFunction(SubscriptionUsersReducer, usersInitialState),
});

//Combined Reducers
export default subscriptionReducers;

//Individual reducer actions
const settingsActions = createActionCreators(SubscriptionSettingsReducer),
  usersActions = createActionCreators(SubscriptionUsersReducer);

//Action Creators
export { settingsActions, usersActions };
//All sagas
export { subscriptionSagas };

// All are exporting several watcher sagas
const combinedSagas = {
  settingSagas,
  usersSaga,
};

// combining all sagas for subscription and exporting them
function* subscriptionSagas() {
  yield all(_?.map?.(combinedSagas, fork));
}
