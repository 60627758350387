import moment, { Moment } from "moment";

import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";

export default class DateHelpers {
  public static futureDates(current: Moment) {
    // Can not select days after today
    return current && current > moment().endOf("day");
  }

  public static pastDates(current: Moment) {
    // Can not select yesterday and before
    return current && current < moment().endOf("day").subtract(1, "day");
  }

  public static todayAndBefore(current: Moment) {
    // Can not select today and before that
    return current && current <= moment().endOf("day");
  }

  public static fromNowOrDate(
    dateTime: Moment,
    k2Intl: InjectedK2Intl,
    mode: "date" | "time" | "dateAndTime",
  ) {
    if (!k2Intl) return null;
    const threeDaysInMs = 86400000 * 3;
    if (mode === "date") {
      return moment().diff(dateTime) > threeDaysInMs
        ? k2Intl.formatDate(new Date(dateTime.format("YYYY-MM-DD")))
        : dateTime.fromNow();
    } else if (mode === "time") {
      return moment().diff(dateTime) > threeDaysInMs
        ? k2Intl.formatTime(new Date(dateTime.format("YYYY-MM-DDTHH:mm:ss")))
        : dateTime.fromNow();
    } else {
      return moment().diff(dateTime) > threeDaysInMs
        ? k2Intl.formatDateTime(
            new Date(dateTime.format("YYYY-MM-DDTHH:mm:ss")),
          )
        : dateTime.fromNow();
    }
  }

  public static lastUpdatedFromNowOrDate(
    dateTime: Moment,
    k2Intl: InjectedK2Intl,
    mode: "date" | "dateAndTime",
  ) {
    const threeDaysInMs = 86400000 * 3;
    const isMoreThanThreeDaysAgo = moment().diff(dateTime) > threeDaysInMs;

    if (isMoreThanThreeDaysAgo) {
      return k2Intl.formatMessage({
        localeKey: "common.lastUpdatedAtTime",
        values: {
          time:
            mode === "date"
              ? k2Intl.formatTime(new Date(dateTime.format("YYYY-MM-DD")))
              : k2Intl.formatDateTime(new Date(dateTime.format("YYYY-MM-DD"))),
        },
      });
    } else {
      return k2Intl.formatMessage({
        localeKey: "common.lastUpdatedRelative",
        values: {
          relativeTime: dateTime.fromNow(),
        },
      });
    }
  }
  // todo Need To check this Again.
  public static todayTomorrowOrPastDue(
    dateTime: Moment,
    k2Intl: InjectedK2Intl,
  ) {
    const formatedTime = k2Intl.formatTime(new Date(dateTime.format()));
    const formatedDateTime = `[${k2Intl.formatDateTime(
      new Date(dateTime.format()),
    )}]`;
    const pastDue = k2Intl.formatMessage({
      localeKey: "common.pastDueWithTime",
      values: {
        time: formatedTime,
      },
    });
    const dueTodayWithTime = k2Intl.formatMessage({
      localeKey: "common.dueTodayWithTime",
      values: {
        time: formatedTime,
      },
    });
    const tomorrowWithTime = k2Intl.formatMessage({
      localeKey: "common.tomorrowWithTime",
      values: {
        time: formatedTime,
      },
    });

    let value = dateTime.calendar(null, {
      // when the date is closer, specify custom values
      lastWeek: pastDue,
      lastDay: pastDue,
      sameDay: dueTodayWithTime,
      nextDay: tomorrowWithTime,
      nextWeek: formatedDateTime,
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return moment().isAfter(dateTime) ? pastDue : formatedDateTime;
      },
    });

    return value;
  }
}
