import { LocalizationSettingsRes } from "@n3oltd/k2.users.sdk.users/esm";

export enum ActionTypes {
  LOCALE_CHANGE = "@global/locale-change",
}

export interface LocaleChange {
  type: ActionTypes.LOCALE_CHANGE;
  userSettings: LocalizationSettingsRes;
  subscriptionSettings: LocalizationSettingsRes;
}

export type GlobalAction = LocaleChange;
