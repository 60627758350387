import { ConnectedProps, connect } from "react-redux";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import IApplicationState from "appRedux/types";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import * as actions from "containers/searchActivities/modules/actions";
import reducer from "containers/searchActivities/modules/reducer";
import saga from "containers/searchActivities/modules/sagas";
import { IActivitiesAdvancedSearchState } from "containers/searchActivities/modules/types";

const mapStateToProps = (
  state: IApplicationState,
): IActivitiesAdvancedSearchState => {
  return state.activitiesSearch;
};

const mapDispatchToProps = {
  search: actions.searchActivities,
  clear: actions.clearSearch,
  clearServerError: actions.clearError,
};

const withSaga = injectSaga({
  key: "activitiesSearch",
  saga,
});

const withReducer = injectReducer({ key: "activitiesSearch", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof withConnect>;

export interface ActivitiesProps extends PropsFromRedux, InjectedK2IntlProps {}

export default compose(withSaga, withReducer, withConnect);
