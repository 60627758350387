import { useEffect, useState } from "react";

import {
  DatumRes,
  DatumResultsList,
} from "@n3oltd/karakoram.lists.sdk.lookups";

import { _listsLookupsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

export function useGetDatumsForListType(
  k2Intl: InjectedK2Intl,
  type: string,
): {
  datums: DatumRes[];
  loadingDatums: boolean;
} {
  const [datums, setDatums] = useState<DatumRes[]>([]);
  const [loadingDatums, setLoadingDatums] = useState<boolean>(true);
  useEffect(() => {
    const fetchDatumsForListType = async () => {
      setLoadingDatums(true);
      const resp: IApiResponse<DatumResultsList> = await K2RestService.toResponse(
        _listsLookupsClient.findDatums({
          entityType: type,
        }),
      );

      if (!resp.error) {
        setDatums(resp.getResultOrDefault().items);
      } else {
        UIUtils.handleServerError(k2Intl, resp.error);
      }
      setLoadingDatums(false);
    };

    if (type) {
      fetchDatumsForListType();
    }
    //TODO: Remove afer refactoring/updating  k2Intl and UIUtils
    // eslint-disable-next-line
  }, [type]);

  return {
    datums,
    loadingDatums,
  };
}
export default useGetDatumsForListType;
