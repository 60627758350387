import React, { FC, useCallback, useMemo } from "react";

import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import { RoleRes } from "@n3oltd/k2.users.sdk.roles/esm";
import { UserReq, UserRes, UserStatus } from "@n3oltd/k2.users.sdk.users/esm";
import { Alert, Col, Form, Input, Row, Spin } from "antd";
import { FormInstance } from "antd/lib/form";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";

import { userFormLookups } from "appRedux/models/lookups/LookupsModel";
import { K2Routes } from "appRedux/models/routes/RouteModel";
import { UserStatusesLookups } from "appRedux/modules/lookups/types";
import { ILocalizationData } from "appRedux/modules/sharedTypes";
import LocalizationFormFields from "components/admin/shared/LocalizationFormFields";
import { ActionsFormItemV4, GeneralFormItemV4 } from "components/formItems";
import {
  K2Card,
  K2Link,
  K2Message,
  K2RouteHeading,
  MultiSelector,
} from "components/k2Widgets";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { FormsWrapper, MainFormsWrapper } from "components/layout/wrappers";
import { UIUtils } from "components/utils";
import { showConfirm } from "components/utils/Confirmation";
import { usersFormLayouts } from "constants/FormLayouts";

import { withConnect } from "../connect";
import CurrentUserStatus from "./CurrentUserStatus";
import ImageUpload from "./UserImageUpload";

const { fieldsLayout } = usersFormLayouts;

export type IEditUsersViewProps = InjectedK2IntlProps &
  ConnectedProps<typeof withConnect> &
  RouteComponentProps<{ userId?: string }> &
  ILocalizationData & {
    userStatuses: UserStatusesLookups;
    onStatusUpdated: (active: boolean) => void;
    onAdminUpdated: (active: boolean) => void;
    handleSubmit: () => void;
    onUnblockUser: () => void;
    onPasswordReset: () => void;
    onBlockUser: () => void;
    onUnlockUser: () => void;
    onValuesChange: () => void;
    onDeleteUser: () => void;
    touched: boolean;
    editMode: boolean;
    overallFormErrors?: string[];
    form: FormInstance;
    updating: boolean;
  };

const EditUsersView: FC<IEditUsersViewProps> = (props) => {
  const {
    handleSubmit,
    onStatusUpdated,
    onAdminUpdated,
    onUnblockUser,
    onBlockUser,
    onUnlockUser,
    onPasswordReset,
    onValuesChange,
    onDeleteUser,
    fetchSelectedEditUser,
    currentUser,
    touched,
    overallFormErrors,
    k2Intl,
    allSubscriptionRoles,
    allSubscriptionRolesLoading,
    offices,
    loading: selectedK2UserLoading,
    saving,
    lookupsLoadingComplete,
    editMode,
    user: selectedK2User,
    updating,
    ...localizationLookups
  } = props;

  const isCurrentUser = currentUser.id === selectedK2User?.id;

  let canChangeStatus, canBlock;

  if (selectedK2User) {
    const { status } = selectedK2User;

    canChangeStatus =
      (status === "active" || status === "deactivated") && !isCurrentUser;
    canBlock = status === "active" && !isCurrentUser;
  }

  const requiredLookupsAvailable = () => {
    let available = true;
    userFormLookups.forEach((lookup) => {
      if (localizationLookups[lookup].items.length < 1) available = false;
    });
    if (props.userStatuses.items.length < 1) available = false;
    return available;
  };

  const loadingComplete =
    lookupsLoadingComplete &&
    requiredLookupsAvailable() &&
    !allSubscriptionRolesLoading &&
    (editMode ? !!selectedK2User : true);

  // If a user's list of roles contains a role ID that has been deleted, we remove it.
  const getCurrentRoles = (
    userRoleIds: string[],
    availableRoles: RoleRes[],
  ) => {
    const availableRoleIds: string[] = availableRoles?.map?.((r) => r.id);
    return userRoleIds.filter((id) => availableRoleIds.includes(id));
  };

  const confirmStatusChange = useCallback(
    (status: UserStatus) => {
      showConfirm({
        k2Intl,
        okTextKey:
          status === "active" ? "common.activate" : "common.deactivate",
        cancelTextKey: "common.cancel",
        titleKey:
          status === "active"
            ? "common.activate.confirm"
            : "common.deactivate.confirm",
        titleKeyValues: {
          entity: k2Intl.formatMessage({ localeKey: "common.entity.user" }),
        },
        onOk: () => {
          onStatusUpdated(status === "active");
        },
      });
    },
    [onStatusUpdated, k2Intl],
  );

  const confirmBlockUser = useCallback(() => {
    showConfirm({
      k2Intl,
      okTextKey: "admin.users.block",
      cancelTextKey: "common.cancel",
      titleKey: "admin.users.block.confirm",
      contentKey: "admin.users.block.confirm.message",
      onOk: () => {
        onBlockUser();
      },
    });
  }, [onBlockUser, k2Intl]);

  const confirmUnblockUser = useCallback(() => {
    showConfirm({
      k2Intl,
      okTextKey: "admin.users.unblock",
      cancelTextKey: "common.cancel",
      titleKey: "admin.users.unblock.confirm",
      contentKey: "admin.users.unblock.confirm.message",
      onOk: () => {
        onUnblockUser();
      },
    });
  }, [onUnblockUser, k2Intl]);

  const confirmUnlockUser = useCallback(() => {
    showConfirm({
      k2Intl,
      okTextKey: "admin.users.unlock",
      cancelTextKey: "common.cancel",
      titleKey: "admin.users.unlock.confirm",
      contentKey: "admin.users.unlock.confirm.message",
      onOk: () => {
        onUnlockUser();
      },
    });
  }, [k2Intl, onUnlockUser]);

  const confirmSendPasswordResetEmail = useCallback(() => {
    showConfirm({
      k2Intl,
      okTextKey: "common.send",
      cancelTextKey: "common.cancel",
      titleKey: "admin.users.resetPassword.confirm",
      contentKey: "admin.users.resetPassword.confirm.message",
      onOk: () => {
        onPasswordReset();
      },
    });
  }, [k2Intl, onPasswordReset]);

  const confirmAdminChange = useCallback(
    (admin: boolean) => {
      showConfirm({
        k2Intl,
        okTextKey: admin ? "admin.users.makeAdmin" : "admin.users.revokeAdmin",
        cancelTextKey: "common.cancel",
        titleKey: admin
          ? "admin.users.makeAdmin.confirm"
          : "admin.users.revokeAdmin.confirm",
        contentKey: admin
          ? "admin.users.makeAdmin.confirm.message"
          : "admin.users.revokeAdmin.confirm.message",
        onOk: () => {
          onAdminUpdated(admin);
        },
      });
    },
    [onAdminUpdated, k2Intl],
  );
  const confirmDeleteUser = useCallback(() => {
    showConfirm({
      k2Intl,
      okTextKey: "common.delete",
      cancelTextKey: "common.cancel",
      titleKey: "admin.users.delete.confirm",
      contentKey: "admin.users.delete.confirm.message",
      onOk: () => {
        onDeleteUser();
      },
    });
  }, [onDeleteUser, k2Intl]);

  const initialAssignRoles = useMemo(
    () =>
      selectedK2User
        ? getCurrentRoles(selectedK2User.roleIds, allSubscriptionRoles)
        : [],
    [allSubscriptionRoles, selectedK2User],
  );

  return (
    <FormsWrapper>
      <K2RouteHeading
        backToLink={
          <K2Link to={K2Routes.users}>
            <ArrowLeftOutlined />
            <K2Message localeKey={"admin.users.edit.goBack"} />
          </K2Link>
        }
        headingKey={editMode ? "admin.users.edit" : "admin.users.create"}
      />
      <MainFormsWrapper>
        <Col span={24}>
          {!loadingComplete ? (
            <K2Card loading shadow />
          ) : (
            <Spin spinning={saving || updating}>
              {loadingComplete && (
                <Form
                  onValuesChange={(values) => {
                    // Updating picture is atomic when in edit mode, shouldn't change the form dirty state
                    if (
                      !(
                        editMode &&
                        Object.values(values).length === 1 &&
                        values.hasOwnProperty("storageToken")
                      )
                    ) {
                      onValuesChange();
                    }
                  }}
                  form={props.form}
                  onFinish={() => {
                    handleSubmit();
                  }}
                  onFinishFailed={(err: ValidateErrorEntity) => {
                    UIUtils.scrollToFirstErrorWithOffset(props.form, err);
                  }}
                  initialValues={{
                    ["firstName"]:
                      selectedK2User?.firstName || "",
                    ["jobTitle"]:
                      selectedK2User?.jobTitle || "",
                    ["lastName"]:
                      selectedK2User?.lastName || "",
                    ["email"]:
                      selectedK2User?.email || "",
                    ["language"]: selectedK2User?.localization?.language,
                    ["timezone"]: selectedK2User?.localization?.timezone,
                    ["timeFormat"]: selectedK2User?.localization?.timeFormat,
                    ["numberFormat"]: selectedK2User?.localization?.numberFormat,
                    ["dateFormat"]: selectedK2User?.localization?.dateFormat,
                  }}
                >
                  {editMode && selectedK2User && (
                    <CurrentUserStatus
                      user={selectedK2User}
                      isCurrentUser={isCurrentUser}
                      onActivateUser={() =>
                        canChangeStatus
                          ? confirmStatusChange(UserStatus.Active)
                          : null
                      }
                      onDeactivateUser={() =>
                        canChangeStatus
                          ? confirmStatusChange(UserStatus.Deactivated)
                          : null
                      }
                      onBlockUser={canBlock ? confirmBlockUser : null}
                      onUnblockUser={
                        selectedK2User.status === UserStatus.Blocked
                          ? confirmUnblockUser
                          : null
                      }
                      onMakeAdmin={
                        selectedK2User.admin
                          ? null
                          : () => confirmAdminChange(true)
                      }
                      onRevokeAdmin={
                        selectedK2User.admin
                          ? () => confirmAdminChange(false)
                          : null
                      }
                      onUnlockUser={confirmUnlockUser}
                      onSendPasswordResetEmail={
                        selectedK2User.status !== UserStatus.Blocked &&
                        selectedK2User.status !== UserStatus.Deactivated
                          ? confirmSendPasswordResetEmail
                          : null
                      }
                      userStatuses={props.userStatuses.items}
                      onDeleteUser={confirmDeleteUser}
                    />
                  )}
                  <K2Card
                    bordered={false}
                    shadow={true}
                    localeKey={"admin.general"}
                    style={{ marginBottom: 25 }}
                  >
                    <GeneralFormItemV4
                      shouldUpdate
                      formItemLayout={fieldsLayout}
                      name={"storageToken"}
                    >
                      <div style={{ marginBottom: 20 }}>
                        <ImageUpload
                          k2User={selectedK2User}
                          afterUploadSuccess={() =>
                            editMode &&
                            props.fetchSelectedEditUser(
                              selectedK2User.id,
                              selectedK2User.id === currentUser.id,
                            )
                          }
                          form={props.form}
                        />
                      </div>
                    </GeneralFormItemV4>
                    <GeneralFormItemV4
                      colon
                      required
                      formItemLayout={fieldsLayout}
                      labelKey="admin.users.firstName"
                      name={"firstName"}
                    >
                      <Input />
                    </GeneralFormItemV4>

                    <GeneralFormItemV4
                      required
                      formItemLayout={fieldsLayout}
                      labelKey="admin.users.lastName"
                      name={"lastName"}
                    >
                      <Input />
                    </GeneralFormItemV4>

                    <GeneralFormItemV4
                      required
                      formItemLayout={fieldsLayout}
                      labelKey="admin.users.email"
                      name={"email"}
                    >
                      <Input disabled={editMode} />
                    </GeneralFormItemV4>

                    <GeneralFormItemV4
                      formItemLayout={fieldsLayout}
                      labelKey="admin.jobTitle"
                      name={"jobTitle"}
                    >
                      <Input />
                    </GeneralFormItemV4>
                  </K2Card>

                  <K2Card
                    bordered={false}
                    shadow={true}
                    localeKey={"admin.users.assignRoles"}
                    style={{ marginBottom: 25 }}
                  >
                    <Form.Item name={"roles"}>
                      <MultiSelector
                        showSearch
                        initialValues={initialAssignRoles}
                        dataSource={allSubscriptionRoles?.map?.((p) => ({
                          key: p.id,
                          title: p.name,
                        }))}
                        name={props.k2Intl?.formatMessage({
                          localeKey: "common.entity.role.plural",
                        })}
                      />
                    </Form.Item>
                  </K2Card>

                  <K2Card
                    bordered={false}
                    shadow={true}
                    localeKey={"admin.localization.title"}
                    style={{ marginBottom: 25 }}
                  >
                    {lookupsLoadingComplete && (
                      <LocalizationFormFields
                        fieldsLayout={fieldsLayout}
                        form={props.form}
                        {...(selectedK2User ? selectedK2User.localization : {})}
                        timeFormats={localizationLookups.timeFormats}
                        languages={localizationLookups.languages}
                        timezones={localizationLookups.timezones}
                        dateFormats={localizationLookups.dateFormats}
                        numberFormats={localizationLookups.numberFormats}
                        areFieldsRequired={false}
                        languageKey="admin.localization.language"
                        timeZoneKey="common.timezone"
                        dateFormatKey="admin.localization.dateFormat"
                        timeFormatKey="admin.localization.timeFormat"
                        numberFormatKey="admin.localization.numberFormat"
                      />
                    )}
                  </K2Card>
                  {overallFormErrors && overallFormErrors.length > 0 && (
                    <Row style={{ marginBottom: 20 }}>
                      <Col span={24}>
                        <Alert
                          showIcon
                          type="error"
                          message={overallFormErrors?.map?.((e, i) => (
                            <p key={i}>{e}</p>
                          ))}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={24}>
                      <ActionsFormItemV4
                        isLoading={!loadingComplete}
                        cancelUrl={K2Routes.users}
                        type={"submit"}
                        disabled={!touched}
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            </Spin>
          )}
        </Col>
      </MainFormsWrapper>
    </FormsWrapper>
  );
};

export default EditUsersView;
