import { PdfCompositionReq } from "@n3oltd/karakoram.templates.sdk.pdf/esm";

export default class PdfTemplateHelpers {
  public static nameFieldName = ["name"];
  public static notesFieldName = ["notes"];

  public static markupFieldName = [
    "content",
    "markup",
  ];

  public static stylesheetIdsFieldName = [
    "stylesheetAssetIds",
  ];

  public static categoryFieldName = [
    "categoryId",
  ];

  public static templateModelTypesFieldName = [
    "modelType",
  ];

  public static filenameFieldName = [
    "filename",
    "markup",
  ];

  public static authorFieldName = [
    "documentInfo",
    "author",
  ];

  public static docTitleFieldName = [
    "documentInfo",
    "title",
  ];

  public static printQueueFieldName = [
    "printQueue",
  ];

  public static layoutIdFieldName = [
    "layoutId",
  ];

  public static letterheadFieldName = [
    "letterheadId",
  ];

  public static collationOrderFieldName = [
    "collationOrder",
  ];

  public static preventPdfEditingFieldName = [
    "preventPdfEditing",
  ];

  public static ensureEndsWithPdf(filename: string) {
    if (!filename) return filename;
    if (filename.endsWith(".pdf")) return filename;
    else return `${filename}.pdf`;
  }

  public static ensureDoesNotEndWithPdf(filename: string) {
    if (!filename) return filename;

    if (filename.endsWith(".pdf")) return filename.slice(0, -4);
    else return filename;
  }
}
