import React, { FC, useEffect, useState } from "react";

import {
  AssetRes,
  AssetResultsPage,
  AssetType,
} from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { Select } from "antd";

import { _assetsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { DEFAULT_DELAY, TEMPLATE_ENGINE } from "constants/appConstants";
import useDebounce from "hooks/useDebounce";

interface StylesheetSelectorProps {
  onChange?: (ids: string[]) => void;
  value?: string[];
}

const StylesheetSelector: FC<StylesheetSelectorProps> = ({
  onChange,
  value,
}) => {
  const [stylesheets, setStylesheets] = useState<AssetRes[]>([]);
  const [stylesheetsLoading, setStylesheetsLoading] = useState(true);
  const [stylesheetsSearch, setStylesheetsSearch] = useState<string>("");
  const debouncedSearchTerm = useDebounce(stylesheetsSearch, DEFAULT_DELAY);

  const fetchStylesheets = async (search: string) => {
    setStylesheetsLoading(true);
    const stylesheetsRes: IApiResponse<AssetResultsPage> = await K2RestService.toResponse(
      _assetsClient.find(TEMPLATE_ENGINE, null, {
        type: AssetType.Stylesheet,
        fullText: search,
        shared: true,
      }),
    );

    if (!stylesheetsRes.error) {
      setStylesheets(stylesheetsRes.getResultOrDefault().items);
    }

    setStylesheetsLoading(false);
  };

  useEffect(() => {
    fetchStylesheets(debouncedSearchTerm);

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <Select
      mode={"multiple"}
      loading={stylesheetsLoading}
      showArrow
      allowClear
      showSearch
      optionLabelProp={"children"}
      optionFilterProp={"children"}
      onSearch={(v: string) => setStylesheetsSearch(v)}
      onChange={(ids: string[]) => onChange?.(ids)}
      value={value}
    >
      {stylesheets?.map?.((sheet) => (
        <Select.Option key={sheet.id} value={sheet.id}>
          {sheet.file.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default StylesheetSelector;
