import { ActionTypes } from "components/dashboard/newTask/modules/actionTypes";
import { NewTaskAction } from "components/dashboard/newTask/modules/types";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { RcFile } from "antd/es/upload";

export function uploadAttachment(uid: string, file: RcFile): NewTaskAction {
  return {
    type: ActionTypes.UPLOAD_ATTACHMENT,
    uid,
    file,
  };
}

export function setUploadComplete(
  uid: string,
  response: IApiResponse<string>,
): NewTaskAction {
  return {
    type: ActionTypes.SET_UPLOAD_COMPLETE,
    uid,
    response,
  };
}

export function setUploadError(uid: string): NewTaskAction {
  return {
    type: ActionTypes.SET_UPLOAD_ERROR,
    uid,
  };
}

export function deleteAttachment(uid: string): NewTaskAction {
  return {
    type: ActionTypes.DELETE_ATTACHMENT,
    uid,
  };
}

export function setAttachmentAsUploading(
  uid: string,
  file: RcFile,
): NewTaskAction {
  return {
    type: ActionTypes.SET_ATTACHMENT_AS_UPLOADING,
    uid,
    file,
  };
}

export function deleteAllAttachments(): NewTaskAction {
  return {
    type: ActionTypes.DELETE_ALL_ATTACHMENTS,
  };
}

export function setFormTouched(): NewTaskAction {
  return {
    type: ActionTypes.SET_FORM_TOUCHED,
  };
}

export function clearFormTouched(): NewTaskAction {
  return {
    type: ActionTypes.CLEAR_FORM_TOUCHED,
  };
}
