import React, { FC, useCallback } from "react";

import {
  ExclamationCircleFilled,
  NotificationOutlined,
} from "@ant-design/icons";
import {
  AppointmentRes,
  NextForDialerRes,
  NextForDialerType,
} from "@n3oltd/k2.calls.sdk.agents";
import { CallRes } from "@n3oltd/k2.calls.sdk.calls/src";
import { Button } from "antd";
import styled from "styled-components";

import {
  _callsAgentsClient,
  _callsAppointmentsClient,
} from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import NextAccountToCallSummary from "components/callCenter/dialler/components/NextAccountToCallSummary";
import { K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

import { AccountInfo, AppointmentPreview } from "../../types";

interface PreviewCallProps {
  k2Intl?: InjectedK2Intl;
  recordToPreview: NextForDialerRes;
  onProcedToCall: (value: CallRes) => void;
  setLoading: (value: boolean) => void;
  setOpenSkipModal: (value: boolean) => void;
  setOpenCancelModal: (value: boolean) => void;
  setAppointment: (value: AppointmentRes) => void;
}

const GreenButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.green_6} !important;
  border: none !important;
  color: ${({ theme }) => theme.white_color} !important;

  &:hover {
    opacity: 0.7;
  }
`;

const Title = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.grey_7};
  font-size: ${({ theme }) => theme.font_size_sm};
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};
  padding: 3px 0;
  margin-top: 14px;
  white-space: nowrap;
  overflow: hidden;
  .text {
    padding: 7px;
  }
`;

const Conatiner = styled.div`
  padding: 16px;
  padding-top: 0px;
`;

const Warning = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: ${({ theme }) => theme.font_size_sm};
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};
  line-height: ${({ theme }) => theme.line_height_md};
  color: ${({ theme }) => theme.grey_7} !important;

  .anticon {
    margin-top: 5px;
    color: ${({ theme }) => theme.warning_color};
  }
`;

export const SkipButton = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.white_color};
    border: 1px solid ${({ theme }) => theme.grey_5};
    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme }) => theme.white_color};
      border-color: ${({ theme }) => theme.button_hover_color};
      color: ${({ theme }) => theme.button_hover_color};
    }
  }
`;
const PreviewCall: FC<PreviewCallProps> = ({
  k2Intl,
  recordToPreview,
  setAppointment,
  setOpenSkipModal,
  onProcedToCall,
  setLoading,
  setOpenCancelModal,
}) => {
  const {
    type,
    record = {},
    appointment = {},
    recentCallsSummary,
  } = recordToPreview;

  /**
   * Call the contact
   */
  const proceedToCall = useCallback(async () => {
    setLoading(true);

    const response: IApiResponse<CallRes> = await K2Service.toResponse(
      _callsAgentsClient.proceedToCall(),
    );

    if (response.error) {
      if (response.error.status === K2StatusCodes.preconditionFailed) {
        // There will be no form field errors, so show notification
        UIUtils.showValidationErrorsNotification(k2Intl, response.error);
      } else {
        UIUtils.handleServerError(k2Intl, response.error);
      }
      setLoading(false);
      return;
    }

    const callRes = response.getResultOrDefault();
    if (callRes.hasAppointment) {
      const appointmentRes: IApiResponse<AppointmentRes> = await K2Service.toResponse(
        _callsAppointmentsClient.getAppointmentById(callRes.appointment.id),
      );
      if (!appointmentRes.error) {
        setAppointment(appointmentRes.getResultOrDefault());
      }
    }

    onProcedToCall(callRes);
    setLoading(false);
  }, [k2Intl, onProcedToCall, setLoading, setAppointment]);

  const cancelOrSkip = useCallback(async () => {
    type === NextForDialerType.Appointment
      ? setOpenCancelModal(true)
      : setOpenSkipModal(true);
    // eslint-disable-next-line
  }, [type]);

  const nextAccountInfo: AccountInfo =
    type !== NextForDialerType.Appointment
      ? {
          ...record?.account,
          phoneNumber: record?.phoneNumber,
        }
      : {
          ...appointment?.account,
          phoneNumber: appointment?.phoneNumber,
        };

  const appointmentInfo: AppointmentPreview =
    type === NextForDialerType.Appointment
      ? {
          scheduledOn: appointment.dueAt,
          notes: appointment.notes,
          assignedTo: appointment.assignedTo.name,
        }
      : null;
  const campaignName =
    type === NextForDialerType.Appointment
      ? appointment?.campaign?.name
      : record?.campaign?.name;
  return (
    <Conatiner>
      <NextAccountToCallSummary
        isTransparent={false}
        account={nextAccountInfo}
        appointmentInfo={appointmentInfo}
      />
      {type === NextForDialerType.Preview &&
        Boolean(recentCallsSummary?.last30DaysCallsCount) && (
          <Warning>
            <ExclamationCircleFilled />
            <div>
              <K2Message
                localeKey={"communication.cc.dialler.contancted.alert"}
                values={{
                  times: recentCallsSummary?.last30DaysCallsCount,
                }}
              />
            </div>
          </Warning>
        )}

      <GreenButton onClick={proceedToCall}>
        <K2Message localeKey={"communication.cc.dialler.start"} />
      </GreenButton>
      <SkipButton
        style={{
          marginTop: 16,
          width: "100%",
        }}
        onClick={cancelOrSkip}
      >
        <K2Message
          localeKey={
            type === NextForDialerType.Appointment
              ? "common.cancel"
              : "common.skip"
          }
        />
      </SkipButton>

      {campaignName && (
        <Title>
          <NotificationOutlined />
          <K2Message spanClassName="text">{campaignName}</K2Message>
        </Title>
      )}
    </Conatiner>
  );
};

export default injectK2Intl(PreviewCall);
