import React, { FC } from "react";

import { Result } from "antd";
import { ReactCookieProps, useCookies } from "react-cookie";
import styled from "styled-components";

import AppManager from "appRedux/AppManager";
import AuthManager from "appRedux/models/auth/AuthManager";
import { K2Button } from "components/k2Widgets";

const ContentWrapper = styled.div`
  .ant-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

interface ErrorAccessRevokedProps extends ReactCookieProps {}

const ErrorAccessRevoked: FC<void> = (props) => {
  const [_, __, removeCookie] = useCookies();
  const logout = () => {
    const options = AuthManager.getCookieSetOptions();

    removeCookie(AuthManager.keys.cookies.subscriptionId, options);
    removeCookie(AppManager.DefaultLocaleKey, options);

    AuthManager.logout();
  };

  // User is blocked or deactivated, they cannot interact with any APIs so we don't know their localization preferences.
  return (
    <ContentWrapper className="n3o-main-content-wrapper">
      <Result
        style={{ textAlign: "center" }}
        status={"403"}
        title={"This user does not currently have access to this system."}
        subTitle={"Please contact your organization"}
        extra={
          <K2Button type={"primary"} onClick={logout}>
            Logout
          </K2Button>
        }
      />
    </ContentWrapper>
  );
};

export default ErrorAccessRevoked;
