import { InjectedIntl } from "react-intl";

export default abstract class AFormatProvider<T, TOptions> {
  protected intl: InjectedIntl;
  protected options: TOptions;

  constructor(intl?: InjectedIntl, options?: TOptions) {
    this.intl = intl;
    this.options = options;
  }

  public format(key: string, value: T) {
    const format = this.getFormatConfig(key);
    if (!format) {
      throw new Error(`Specified format key not found, format key: ${key}`);
    }

    if (this.intl && (!format.culture || this.intl.locale === format.culture))
      return this.formatIntl(format, value);

    return this.formatNative(format, value);
  }

  protected getOptions(defaultOptions?: TOptions) {
    let allOptions = {};

    if (defaultOptions) allOptions = defaultOptions;

    if (this.options) allOptions = { ...allOptions, ...this.options };

    return allOptions;
  }

  abstract formatIntl(key: IK2FormatConfig, value: T): string;
  abstract formatNative(key: IK2FormatConfig, value: T): string;
  abstract getFormatConfig(key: string): IK2FormatConfig;
}

export interface IK2FormatConfig {
  oldKey?: string; //Temp
  key: string;
  culture: string;
  format?: string; //e.g. MM/DD/YYYY
  timeFormatDisplay?: string; //e.g. MM/DD/YYYY HH:mm A
  timeFormat?: string; //e.g. MM/DD/YYYY HH:mm:ss
  formatter?: (strDate: string) => string;
  options?: any;
}
