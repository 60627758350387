import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const Snooze: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3334 1.85C18.3334 1.38056 18.7139 1 19.1834 1H21.8501C22.1768 1 22.4746 1.18729 22.6162 1.48179C22.7577 1.77629 22.7179 2.12585 22.5138 2.38099L20.9519 4.33332H21.8501C22.3195 4.33332 22.7001 4.71388 22.7001 5.18332C22.7001 5.65276 22.3195 6.03332 21.8501 6.03332H19.1834C18.8566 6.03332 18.5588 5.84603 18.4173 5.55153C18.2757 5.25703 18.3155 4.90747 18.5197 4.65233L20.0815 2.7H19.1834C18.7139 2.7 18.3334 2.31944 18.3334 1.85ZM13 7.18346C13 6.71402 13.3806 6.33346 13.85 6.33346H17.1834C17.5132 6.33346 17.8132 6.52425 17.9531 6.82293C18.093 7.12161 18.0475 7.47425 17.8364 7.72762L15.6648 10.3334H17.1834C17.6528 10.3334 18.0334 10.714 18.0334 11.1834C18.0334 11.6529 17.6528 12.0334 17.1834 12.0334H13.85C13.5202 12.0334 13.2202 11.8427 13.0803 11.544C12.9404 11.2453 12.9859 10.8927 13.1971 10.6393L15.3686 8.03346H13.85C13.3806 8.03346 13 7.6529 13 7.18346ZM7.83099 19.7011C8.28142 19.5688 8.75376 19.8268 8.88599 20.2772C8.94716 20.4856 9.07418 20.6686 9.24804 20.7987C9.42189 20.9289 9.63323 20.9992 9.85041 20.9992C10.0676 20.9992 10.2789 20.9289 10.4528 20.7987C10.6266 20.6686 10.7537 20.4856 10.8148 20.2772C10.9471 19.8268 11.4194 19.5688 11.8698 19.7011C12.3203 19.8333 12.5782 20.3056 12.446 20.7561C12.2814 21.3169 11.9395 21.8093 11.4716 22.1596C11.0037 22.5099 10.4349 22.6992 9.85041 22.6992C9.26591 22.6992 8.69713 22.5099 8.22922 22.1596C7.76132 21.8093 7.41946 21.3169 7.25482 20.7561C7.12259 20.3056 7.38055 19.8333 7.83099 19.7011Z"
      fill={fill}
    />
    <path
      d="M10.7 1.85C10.7 1.38056 10.3194 1 9.84995 1C9.38051 1 8.99995 1.38056 8.99995 1.85V3.04817C8.91398 3.05795 8.82828 3.0692 8.74289 3.08192C7.15897 3.31771 5.68098 4.05532 4.53492 5.20138C3.12527 6.61103 2.33333 8.52291 2.33333 10.5165C2.33333 13.0604 2.0094 14.8156 1.69872 15.9145C1.5433 16.4643 1.39079 16.8513 1.28281 17.092C1.25172 17.1613 1.2243 17.2185 1.20151 17.2641C1.18471 17.2977 1.17044 17.3249 1.15908 17.346C1.14929 17.3641 1.13977 17.3825 1.12878 17.3999C0.966079 17.6608 0.95684 17.9894 1.10508 18.2591C1.25442 18.5309 1.53991 18.6998 1.85 18.6998H17.85C18.1536 18.6998 18.4342 18.5378 18.5861 18.2749C18.7379 18.012 18.738 17.688 18.5863 17.425C18.063 16.5179 17.7216 15.5176 17.5812 14.4798C17.5183 14.0146 17.0902 13.6885 16.625 13.7514C16.1598 13.8144 15.8336 14.2425 15.8966 14.7077C16.0031 15.495 16.2051 16.2646 16.497 16.9998H3.14116C3.20469 16.8134 3.26984 16.6061 3.33461 16.377C3.62089 15.3643 3.89859 13.9299 3.99638 12.0081C4.02019 11.5401 4.03333 11.0432 4.03333 10.5165C4.03333 8.97378 4.64616 7.4943 5.737 6.40347C6.29007 5.8504 6.94305 5.42024 7.65353 5.13055C8.34366 4.84914 9.08806 4.70027 9.84784 4.69999C10.7102 4.70459 11.5604 4.90295 12.3355 5.28059C12.7575 5.48622 13.2663 5.3108 13.4719 4.88879C13.6775 4.46678 13.5021 3.95798 13.0801 3.75235C12.3296 3.38667 11.5249 3.15077 10.7 3.05237V1.85Z"
      fill={fill}
    />
  </svg>
);
