import React from "react";

import { Redirect, Route, Switch } from "react-router";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import { asyncComponent } from "components/utils";
import { RequireAuth } from "containers/app/views";

const {
  callPurposes,
  callDispositions,
  jobRoles,
  skipReasons,
  compliance,
} = K2Routes;

const AgentRolesWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "AgentRoles" */ "./jobRoles"),
  ),
  "jobRoles",
);

const CallDispositionsWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "CallDispositions" */ "../callCenter/callDispositions"
      ),
  ),
  "callDispositions",
);

const CallPurposesWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "CallPurposes" */ "../callCenter/callPurposes"
      ),
  ),
  "callPurposes",
);

const SkipReasonsWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "SkipReasons" */ "../callCenter/skipReasons"),
  ),
  "skipReasons",
);

const ComplianceWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "Compliance" */ "../callCenter/compliance"),
  ),
  "compliance",
);

const CallsSubRouter = () => (
  <Switch>
    <Route exact path={jobRoles} component={AgentRolesWithAuth} />
    <Route exact path={callDispositions} component={CallDispositionsWithAuth} />
    <Route exact path={callPurposes} component={CallPurposesWithAuth} />
    <Route exact path={skipReasons} component={SkipReasonsWithAuth} />
    <Route exact path={compliance} component={ComplianceWithAuth} />
    <Redirect to={jobRoles} />
  </Switch>
);

export default CallsSubRouter;
