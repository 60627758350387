import React, { FC, useCallback, useEffect, useState } from "react";

import {
  AgentRes,
  AgentResultsPage,
  AssigneeType,
  TeamRes,
  TeamResultsList,
} from "@n3oltd/k2.calls.sdk.agents";
import { CreateAppointmentReq } from "@n3oltd/k2.calls.sdk.appointments";
import { UpdateAppointmentReq } from "@n3oltd/k2.calls.sdk.appointments/src";
import { AccountInfoReq, CallRes } from "@n3oltd/k2.calls.sdk.calls";
import { Col, DatePicker, Form, FormInstance, Input, Row } from "antd";
import moment from "moment";

import {
  _callsAgentsClient,
  _callsDataEntryClient,
} from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import DateFormatProvider from "appRedux/models/localization/DateFormatProvider";
import TimeFormatProvider from "appRedux/models/localization/TimeFormatProvider";
import { TimezoneLookups } from "appRedux/modules/lookups/types";
import DateHelpers from "common/helpers/dates";
import { GeneralFormItemV4, HiddenFormItemV4 } from "components/formItems";
import { K2Message, K2Modal, K2SelectV4 } from "components/k2Widgets";
import K2Cascader from "components/k2Widgets/k2Dropdowns/k2Cascader";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

import ModalAlert from "./ModalAlert";

interface AppointmentProps extends InjectedK2IntlProps {
  creatingOrUpdating?: boolean;
  visible: boolean;
  account?: CallRes;
  form: FormInstance;
  timezones?: TimezoneLookups;
  isUpdate?: boolean;
  revisionId?: string;
  onSchedule?: (values: CreateAppointmentReq) => void;
  onUpdate?: (revisionId: string, req: UpdateAppointmentReq) => void;
  setOpenModal: (open: boolean) => void;
  onCancel?: () => void;
  errors?: string[];
}

export const ASSINGEE_INFO = "assignee_info";

const COL_LAYOUT = { xs: 24 };
const AppointmentModal: FC<AppointmentProps> = (props) => {
  const {
    k2Intl,
    visible,
    account,
    localizationSettings,
    form,
    timezones,
    revisionId,
    isUpdate = false,
    creatingOrUpdating = false,
    errors,
    setOpenModal,
    onCancel,
    onSchedule,
    onUpdate,
  } = props;

  if (!isUpdate && !account) {
    throw new Error("Create Appointment Required Calling Account");
  }

  if (isUpdate && !onUpdate) {
    throw new Error("Update Appointment Requires onEdit ");
  }
  if (!isUpdate && !onSchedule) {
    throw new Error("Create Appointment Requires onSchedule");
  }
  const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState<AgentRes[]>([]);
  const [teams, setTeams] = useState<TeamRes[]>([]);

  const accountInfo: AccountInfoReq = {
    ...account?.account,
  };

  const { dateFormat, timeFormat, timezone } = localizationSettings || {};
  const dateConfig = DateFormatProvider.getConfig(
    dateFormat || DateFormatProvider.defaultDateFormat,
  );

  const timeConfig = TimeFormatProvider.getConfig(
    timeFormat || TimeFormatProvider.defaultTimeFormat,
  );

  const dateTimeFormatToDisplay = `${dateConfig?.format} ${timeConfig?.timeFormatDisplay}`;

  // todo: Need to Change this to hooks.
  const fetchTeamsAndAgents = useCallback(async () => {
    setLoading(true);
    let teamRes: IApiResponse<TeamResultsList> = await K2RestService.toResponse(
      _callsDataEntryClient.find({ isActive: true }),
    );

    if (teamRes.error) {
      UIUtils.handleError(k2Intl, teamRes.error);
      return;
    }
    setTeams(teamRes.getResultOrDefault().items);

    let agentRes: IApiResponse<AgentResultsPage> = await K2RestService.toResponse(
      _callsAgentsClient.find({}),
    );

    if (agentRes.error) {
      UIUtils.handleError(k2Intl, agentRes.error);
      return;
    }
    setAgents(agentRes.getResultOrDefault().items);
    setLoading(false);
  }, [k2Intl]);

  // Get all the agents
  useEffect(() => {
    if (visible) {
      fetchTeamsAndAgents();
    }
    // eslint-disable-next-line
  }, [visible]);

  const scheduleAppointment = useCallback(async () => {
    form
      ?.validateFields()
      .then((values: CreateAppointmentReq | UpdateAppointmentReq) => {
        values = {
          ...values,
          assignee: {
            id: values[ASSINGEE_INFO][1],
            type: values[ASSINGEE_INFO][0],
          },
        };
        let dueAt = moment(values.due.at.dateTime);
        values = {
          ...values,
          due: {
            at: {
              ...values.due.at,
              dateTime: `${dueAt.format(
                DateFormatProvider.serverDateTimeFormat,
              )}`,
            },
          },
        };
        isUpdate ? onUpdate(revisionId, values) : onSchedule(values);
      });
    // eslint-disable-next-line
  }, [form, teams, agents, isUpdate, onSchedule, onUpdate, revisionId]);

  return (
    <K2Modal
      titleKey={
        !isUpdate
          ? "communication.cc.dialler.scheduleAppointment"
          : "communication.cc.dialler.updateAppointment"
      }
      visible={visible}
      onOk={() => {
        form?.submit();
      }}
      cancelTextKey="common.cancel"
      okTextKey={
        !isUpdate
          ? "common.ok"
          : "communication.cc.dialler.updateAppointment.savebtn"
      }
      onCancel={() => {
        form?.resetFields();
        setOpenModal(false);
        onCancel?.();
      }}
      okButtonProps={{
        loading: creatingOrUpdating,
        disabled: creatingOrUpdating,
      }}
      cancelButtonProps={{
        loading: creatingOrUpdating,
        disabled: creatingOrUpdating,
      }}
      withOutScroll
      centered
      loading={loading}
    >
      <Form form={form} onFinish={scheduleAppointment}>
        <Row gutter={[12, 0]}>
          <Col md={12} sm={24}>
            <GeneralFormItemV4
              name={[
                "due",
                "at",
                "dateTime",
              ]}
              required
            >
              <DatePicker
                disabledDate={DateHelpers.pastDates}
                format={dateTimeFormatToDisplay}
                showTime
                placeholder={k2Intl?.formatMessage({
                  localeKey: "common.selectDateTime",
                })}
              />
            </GeneralFormItemV4>
          </Col>
          <Col md={12} sm={24}>
            <GeneralFormItemV4
              name={[
                "due",
                "at",
                "timezone",
              ]}
              initialValue={timezone}
              required
            >
              <K2SelectV4
                dataSource={timezones?.items}
                focusInputOnFocus
                showSearch
                allowClear
                placeholder={k2Intl?.formatMessage({
                  localeKey: "common.selectTimeZone",
                })}
              />
            </GeneralFormItemV4>
          </Col>
        </Row>

        <GeneralFormItemV4
          label={
            <>
              <K2Message
                localeKey="communication.cc.dialler.scheduleAppointment.assign"
                spanClassName="ant-col ant-col-xs-12"
              />
            </>
          }
          name={ASSINGEE_INFO}
          labelCol={COL_LAYOUT}
          wrapperCol={COL_LAYOUT}
          required
        >
          <K2Cascader
            options={[
              {
                label: k2Intl.formatMessage({
                  localeKey: "communication.cc.agent",
                }),
                value: AssigneeType.Agent,
                children: agents?.map((agentItem) => ({
                  value: agentItem.id,
                  label: agentItem.profile.name,
                })),
              },
              {
                label: k2Intl.formatMessage({
                  localeKey: "communication.cc.team",
                }),
                value: AssigneeType.Team,
                children: teams?.map((teamItem) => ({
                  value: teamItem.id,
                  label: teamItem.name,
                })),
              },
            ]}
            showSearch={true}
            getPopupContainer={(trigger: HTMLElement) => {
              return trigger.parentNode as HTMLElement;
            }}
          />
        </GeneralFormItemV4>

        <GeneralFormItemV4
          name={[
            "notes",
            "text",
          ]}
          labelKey="common.notes"
          labelCol={COL_LAYOUT}
          wrapperCol={COL_LAYOUT}
          className="n3o-mb-0"
          required
        >
          <Input.TextArea
            rows={4}
            placeholder={k2Intl.formatMessage({ localeKey: "common.notes" })}
          />
        </GeneralFormItemV4>

        {!isUpdate && (
          <>
            <HiddenFormItemV4
              fieldName={["phoneNumber"]}
              initialValue={account?.phoneNumber}
            />
            <HiddenFormItemV4
              fieldName={["account"]}
              initialValue={accountInfo}
            />
          </>
        )}
      </Form>
      {errors?.length > 0 && <ModalAlert errors={errors} />}
    </K2Modal>
  );
};

export default injectK2Intl(AppointmentModal);
