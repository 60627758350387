import antdEn from "antd/lib/locale-provider/en_US";
import appLocaleData from "react-intl/locale-data/en";

import accountsMessages from "../locales/accounts/en_US.json";
import adminMessages from "../locales/admin/en_US.json";
import analyticsMessages from "../locales/analytics/en_US.json";
import appMessages from "../locales/app/en_US.json";
import commonMessages from "../locales/common/en_US.json";
import callCenterMessages from "../locales/communication/callcenter/en_US.json";
import communicationMessages from "../locales/communication/en_US.json";
import dashboardMessages from "../locales/dashboard/en_US.json";
import dataMessages from "../locales/data/en_US.json";
import donationMessages from "../locales/donation/en_US.json";
import feedbacks from "../locales/feedbacks/en_US.json";
import firstTimeSetupMessages from "../locales/firstTimeSetup/en_US.json";
import pageTitles from "../locales/pageTitles/en-US.json";
import paymentMessages from "../locales/payment/en_US.json";
import pledgesMessages from "../locales/pledges/en_US.json";
import q1Messages from "../locales/q1/en_US.json";
import q1TaxReliefMessages from "../locales/q1/taxRelief/en_Us.json";
import reportsMessages from "../locales/reports/en_US.json";
import sponsorshipsMessages from "../locales/sponsorships/en_US.json";
import sponsorshipMessages from "../locales/sponsorships/en_US.json";
import taskMessages from "../locales/tasks/en_US.json";
import { AppLocale } from "../types";

const EnLang: AppLocale = {
  messages: {
    ...appMessages,
    ...dashboardMessages,
    ...adminMessages,
    ...firstTimeSetupMessages,
    ...paymentMessages,
    ...commonMessages,
    ...accountsMessages,
    ...communicationMessages,
    ...donationMessages,
    ...taskMessages,
    ...dataMessages,
    ...sponsorshipsMessages,
    ...q1Messages,
    ...sponsorshipMessages,
    ...pledgesMessages,
    ...pageTitles,
    ...feedbacks,
    ...q1TaxReliefMessages,
    ...analyticsMessages,
    ...reportsMessages,
    ...callCenterMessages,
  },
  antd: antdEn,
  locale: "en-US",
  data: appLocaleData,
};

export default EnLang;
