import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const RefundPayment: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.25 1.75H1.75C1.33516 1.75 1 2.08516 1 2.5V17.5C1 17.9148 1.33516 18.25 1.75 18.25H8.2C8.64183 18.25 9 17.8918 9 17.45V17.3625C9 16.9207 8.64183 16.5625 8.2 16.5625H2.6875V8.3125H20.3125V9.7C20.3125 10.1418 20.6707 10.5 21.1125 10.5H21.2C21.6418 10.5 22 10.1418 22 9.7V2.5C22 2.08516 21.6648 1.75 21.25 1.75ZM20.3125 3.4375H2.6875V6.25H20.3125V3.4375Z"
      fill={fill}
    />
    <path
      d="M14.3725 15.9018C14.3725 16.18 14.053 16.3195 13.8446 16.1584L11.5814 14.4089C11.5423 14.3789 11.51 14.34 11.488 14.2956C11.4658 14.251 11.4542 14.2018 11.4542 14.1518C11.4542 14.1018 11.4658 14.0526 11.488 14.008C11.51 13.9636 11.542 13.925 11.581 13.895L13.8446 12.1451C14.053 11.9841 14.3725 12.1236 14.3725 12.4018V13.3581H21.9798C22.6492 13.3581 23.2 13.8923 23.2 14.558V21.0001C23.2 21.6659 22.6492 22.2001 21.9798 22.2001H11.1276C10.9508 22.2001 10.8 22.0581 10.8 21.8751V20.9376C10.8 20.7546 10.9508 20.6126 11.1276 20.6126H21.5885V14.9455H14.3725V15.9018Z"
      fill={fill}
    />
  </svg>
);
