import React, { FC, useCallback, useEffect, useState } from "react";

import { QuestionCircleOutlined } from "@ant-design/icons/lib";
import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { Badge, Dropdown, Row } from "antd";
import { useRouter } from "hooks";
import { useSelector } from "react-redux";
import styled from "styled-components";

import AppManager from "appRedux/AppManager";
import { userNavLookups } from "appRedux/models/lookups/LookupsModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import IApplicationState from "appRedux/types";
import injectLookups from "appRedux/utils/injectLookups";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import UserAvatar from "components/avatars/userAvatar";
import NewTask from "components/dashboard/newTask";
import { K2Link } from "components/k2Widgets";
import FakeIconLink from "components/k2Widgets/k2FakeIconLink";
import { N3oIcon } from "components/n3oIcon";
import UserDropdownMenu from "components/navigation/mainAppHeader/headerActions/UserDropdownMenu";
import TaskMenu from "components/tasks/taskMenu";
import helpCenterConfig from "config/helpCenterConfig";

const NavUserAreaWrapper = styled.div`
  margin: 4px 12px;
  padding: 4px;

  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;

  border-radius: 50%;
  display: inline-block;
`;

interface IParams {
  donationId?: string;
  givingId?: string;
  feedbackId?: string;
  pledgeId?: string;
}
interface IProps {
  currentUser: CurrentUserRes;
}

const NavUserArea: FC<IProps> = ({ currentUser }) => {
  const subscriptionId = useSelector(
    (state: IApplicationState) => state.subscription.users.k2Subscription.id,
  );
  const taskCountForUser = useSelector(
    (state: IApplicationState) => state.tasks.dashboardTasks.taskCountForUser,
  );

  const [showNewTaskModal, setShowNewTaskModal] = useState<boolean>(false);
  const [showTasksDropdown, setShowTasksDropdown] = useState<boolean>(false);
  const [userMenuVisible, setUserMenuVisible] = useState<boolean>(false);
  const [currentParams, setCurrentParams] = useState<IParams>();
  const { location } = useRouter();

  useEffect(() => {
    const matchingPath = AppManager.findMatchPath<IParams>(location.pathname, {
      path: [
        K2Routes.viewDonation,
        K2Routes.viewAccountEditRegularDonation,
        K2Routes.viewAccountEditDonation,
        K2Routes.viewFeedback,
        K2Routes.viewAccountFeedback,
        K2Routes.viewPledge,
        K2Routes.viewAccountPledge,
      ],
      exact: true,
      strict: false,
    });
    if (matchingPath?.isExact) setCurrentParams(matchingPath.params);
    else setCurrentParams(null);
  }, [location.pathname]);
  const getPopupContainer = useCallback((triggerNode: HTMLElement) => {
    const element = document.getElementById("n3o-main-header");

    return element !== null ? element : triggerNode;
  }, []);

  const { theme } = useAppContext();

  const handleUserMenuVisibleChange = (flag) => {
    setUserMenuVisible(flag);
  };

  const handleUserMenuClick = () => {
    setUserMenuVisible(false);
  };

  return (
    <div>
      {showNewTaskModal && (
        <NewTask
          onCancel={() => setShowNewTaskModal(false)}
          visible={showNewTaskModal}
          donationId={currentParams?.donationId}
          givingId={currentParams?.givingId}
          feedbackId={currentParams?.feedbackId}
          pledgeId={currentParams?.pledgeId}
        />
      )}
      <Row
        style={{
          display: "flex",
          alignItems: "middle",
          justifyContent: "flex-end",
        }}
      >
        <NavUserAreaWrapper>
          <K2Link
            to={`${helpCenterConfig.rootUrl}${helpCenterConfig.pages.docs}`}
            external
            hideExternalIcon
            customLinkColor={theme.white_color}
          >
            <FakeIconLink onHoverOnly={true}>
              <QuestionCircleOutlined
                style={{
                  color: theme.white_color,
                  fontSize: 24,
                  marginTop: 4,
                }}
              />
            </FakeIconLink>
          </K2Link>
        </NavUserAreaWrapper>
        <NavUserAreaWrapper style={{ paddingTop: 8 }}>
          <Dropdown
            trigger={["click"]}
            visible={showNewTaskModal ? false : showTasksDropdown}
            onVisibleChange={() => setShowTasksDropdown(!showTasksDropdown)}
            overlay={
              <TaskMenu
                openTaskModal={() => {
                  setShowNewTaskModal(true);
                  setShowTasksDropdown(false);
                }}
              />
            }
            placement="bottomRight"
            getPopupContainer={getPopupContainer}
          >
            {taskCountForUser === null || taskCountForUser === 0 ? (
              <span>
                <FakeIconLink onHoverOnly={true}>
                  <N3oIcon
                    icontype="n3o-task"
                    width="24px"
                    height="24px"
                    fill={theme.white_color}
                  />
                </FakeIconLink>
              </span>
            ) : (
              <Badge
                count={taskCountForUser}
                overflowCount={99}
                offset={[5, 5]}
                style={{ boxShadow: "none" }}
                className={"n3o-pointer"}
              >
                <FakeIconLink onHoverOnly={true}>
                  <N3oIcon
                    icontype="n3o-task"
                    width="24px"
                    height="24px"
                    fill={theme.white_color}
                  />
                </FakeIconLink>
              </Badge>
            )}
          </Dropdown>
        </NavUserAreaWrapper>

        <NavUserAreaWrapper>
          <Dropdown
            overlay={
              <UserDropdownMenu
                user={currentUser}
                menuClick={handleUserMenuClick}
              />
            }
            placement="bottomRight"
            getPopupContainer={getPopupContainer}
            trigger={["click"]}
            onVisibleChange={handleUserMenuVisibleChange}
            visible={userMenuVisible}
          >
            <span>
              <UserAvatar
                subscriptionId={subscriptionId}
                user={currentUser}
                presetId={"small"}
                className={"n3o-pointer ant-dropdown-link"}
              />
            </span>
          </Dropdown>
        </NavUserAreaWrapper>
      </Row>
    </div>
  );
};

export default injectLookups(NavUserArea, userNavLookups);
