import React, { FC, useEffect, useState } from "react";

import { CloseCircleFilled } from "@ant-design/icons/lib";
import { Currency } from "@n3oltd/karakoram.payments.sdk.payments/esm";
import { Form } from "antd";
import { FormInstance, FormItemProps } from "antd/es/form";
import { InputNumberProps } from "antd/lib/input-number";
import IntlCurrencyInput from "react-intl-currency-input";
import styled from "styled-components";

import { errorKeys } from "appRedux/models/common/Constants";

import { K2Message } from "../k2Widgets";
import injectK2Intl from "../k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "../k2Widgets/k2Localizations/types";

const ClearButton = styled.div`
  width: 15px;
  text-align: right;
  margin-top: -31px;
  z-index: 100;
  position: relative;
  right: 13px;
  float: right;
`;

const CloseCircleFilledWithStyle = styled(CloseCircleFilled)`
  && {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    &:hover {
      color: rgba(0, 0, 0, 0.45);
      cursor: pointer;
    }
  }
`;

export interface IInputCurrencyItemProps
  extends Omit<InputNumberProps, "onBlur"> {
  formItemProps: Omit<FormItemProps, "children">;

  allowClear?: boolean;

  /**antd form form parent component props */
  currentForm: FormInstance;

  dontShowLabel?: boolean;

  /**locale key for label */
  labelKey?: string;

  /**locale key for error */
  errorLabelKey?: string;

  /**extra locale key for label */
  extraLabelKey?: string;

  /**initial value for input */
  initialValue?: number;

  /**allow Minimum Zero for input */
  allowMinimumZero?: boolean;

  /**Form.Item layout object */
  formItemLayout?: { wrapperCol: Object; labelCol?: Object };

  /**Event to trigger the validation on
   * default: onBlur
   */
  validateTrigger?: string; //default onBlur

  /** The currency the current item should be displayed in */
  currency: Currency;

  errorTitle?: string;

  onBlur?: (e: FocusEvent, value: number, maskedValue: string) => void;
}

const toNumber = (value: string | number) => {
  if (typeof value === "string") {
    return Number(value.replace(/[^0-9,.]/g, ""));
  }
  return value;
};

interface CustomCurrencyProps extends InjectedK2IntlProps {
  currency: Currency;
  initialValue: string;
  allowMinimumZero?: boolean;
  onChange?: (any) => void;
  value?: number;
  controlledValue?: number;
  onBlur?: (e: FocusEvent, value: number, maskedValue: string) => void;
  disabled: boolean;
  allowClear?: boolean;
}

let CustomCurrencyInput: FC<CustomCurrencyProps> = ({
  currency,
  onChange,
  initialValue,
  controlledValue,
  k2Intl,
  onBlur,
  disabled,
  allowClear,
}) => {
  const [masked, setMasked] = useState<string | number>(initialValue);
  const handleChange = (event, value, masked) => {
    event.preventDefault();
    if (controlledValue === undefined) {
      setMasked(masked);
    }

    onChange?.(value);
  };

  useEffect(() => {
    if (controlledValue !== undefined) {
      setMasked(controlledValue);
    }
    // eslint-disable-next-line
  }, [controlledValue]);

  const currencyConfig = {
    formats: {
      number: {
        [currency]: {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  return (
    <>
      <span>
        <IntlCurrencyInput
          disabled={disabled ? "disabled" : null}
          className={"ant-input"}
          currency={currency}
          config={currencyConfig}
          onChange={handleChange}
          value={masked}
          onBlur={onBlur}
        />
      </span>
      {allowClear && (
        /* Slightly hacky way of triggering reset on this field when the x is clicked */
        <ClearButton>
          <CloseCircleFilledWithStyle
            onClick={() =>
              handleChange(
                {
                  target: { value: 0 },
                  preventDefault: () => {},
                },
                0,
                k2Intl?.formatCurrency(0, currency),
              )
            }
          />
        </ClearButton>
      )}
    </>
  );
};

const InputCurrencyFormItem = ({
  currentForm,
  dontShowLabel,
  labelKey,
  extraLabelKey,
  initialValue = 0,
  allowMinimumZero,
  formItemLayout,
  validateTrigger,
  k2Intl,
  errorTitle,
  currency,
  value,
  formItemProps,
  disabled,
  errorLabelKey,
  allowClear,
  ...rest
}: IInputCurrencyItemProps & InjectedK2IntlProps) => {
  const localeLabel = k2Intl?.formatHtmlMessage({
    localeKey: labelKey,
  });

  let formattedDataWithExtra: JSX.Element = null;

  if (extraLabelKey) {
    const extraFormattedLabel = k2Intl?.formatHtmlMessage({
      localeKey: extraLabelKey,
    });

    formattedDataWithExtra = (
      <span>
        {localeLabel || formItemProps?.label}{" "}
        <span className={"n3o-text-grey-7"}>({extraFormattedLabel})</span>
      </span>
    );
  }

  const rules = formItemProps.rules || [];
  if (formItemProps.required) {
    rules.push(
      {
        required: formItemProps.required || false,
        message: (
          <K2Message
            localeKey={errorKeys.requiredValidationError}
            values={{
              fieldName:
                errorTitle ||
                k2Intl?.formatMessage({
                  localeKey: errorLabelKey || labelKey,
                }),
            }}
          />
        ),
      },
      () => ({
        validator(rule, value) {
          if (!allowMinimumZero && value === 0) {
            return Promise.reject(
              k2Intl?.formatMessage({
                localeKey: errorKeys.requiredValidationError,
                values: {
                  fieldName:
                    errorTitle ||
                    k2Intl?.formatMessage({
                      localeKey: errorLabelKey || labelKey,
                    }),
                },
              }),
            );
          } else {
            return Promise.resolve();
          }
        },
      }),
    );
  }

  return (
    <Form.Item
      {...formItemProps}
      {...formItemLayout}
      label={
        !dontShowLabel &&
        (formattedDataWithExtra || localeLabel || formItemProps?.label)
      }
      rules={rules}
      initialValue={initialValue}
    >
      <CustomCurrencyInput
        {...rest}
        disabled={disabled}
        controlledValue={isNaN(toNumber(value)) ? undefined : toNumber(value)}
        currency={currency}
        initialValue={k2Intl?.formatCurrency(initialValue, currency)}
        k2Intl={k2Intl}
        allowClear={allowClear}
      />
    </Form.Item>
  );
};

export default injectK2Intl(InputCurrencyFormItem);
