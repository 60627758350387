import React, { FC } from "react";

import { QuestionCircleTwoTone } from "@ant-design/icons/lib";

import K2Checkbox from "../K2Checkbox";
import K2Tooltip from "../k2Tooltip/K2Tooltip";
import { MultiSelectorDataItem } from "./types";

interface MultiSelectorItemProps {
  dataItem: MultiSelectorDataItem;
  isSelected: boolean;
  onChange: (checked: boolean, item: MultiSelectorDataItem) => void;
  forwardedRef: any;

  renderSuffix?: (item: any) => React.ReactNode;
  disabled?: boolean;
}

const MultiSelectorItem: FC<MultiSelectorItemProps> = ({
  dataItem,
  isSelected,
  onChange,
  renderSuffix,
  forwardedRef,
  disabled,
}) => (
  <div
    className={`k2-multiselector-item ${
      !dataItem.hideItemBorder ? "k2-multiselector-item-with-border" : ""
    }`}
  >
    <K2Checkbox
      disabled={disabled || false}
      ref={forwardedRef}
      className="k2-multiselector-item-check"
      checked={isSelected}
      defaultChecked={isSelected}
      onChange={(e) => onChange(e.target.checked, dataItem)}
    >
      <span style={{ display: "flex" }}>
        <span className={"k2-multiselector-item-title"}>{dataItem.title}</span>
        {renderSuffix ? (
          <span className="k2-multiselector-item-info">
            {renderSuffix(dataItem)}
          </span>
        ) : (
          dataItem.description && (
            <span
              className="k2-multiselector-item-info"
              id={`k2-multiselector-item-info-${dataItem.key}`}
            >
              <K2Tooltip title={dataItem.description}>
                <QuestionCircleTwoTone />
              </K2Tooltip>
            </span>
          )
        )}
      </span>
    </K2Checkbox>
  </div>
);

export default MultiSelectorItem;
