import {
  SecurityPrincipalCriteria,
  SecurityPrincipalResultsList,
} from "@n3oltd/k2.users.sdk.security-principals/esm";
import { ImmerReducer } from "immer-reducer";
import { isNull } from "lodash";

import { IApiResponse } from "appRedux/models/common/ApiResponseModel";

import { K2SecurityPrincipalsManagement } from "./types";

export const initialState: K2SecurityPrincipalsManagement = {
  isLoading: false,
  serverError: null,
  items: [],
};

export class SecurityPrincipalsReducer extends ImmerReducer<K2SecurityPrincipalsManagement> {
  itemsLoading(loading: boolean) {
    this.draftState.isLoading = loading;
  }

  fetchSecurityPrincipals(action: SecurityPrincipalCriteria) {
    this.itemsLoading(true);
    this.draftState.items = [];
    this.draftState.serverError = null;
  }

  setSecurityPrincipals(response: IApiResponse<SecurityPrincipalResultsList>) {
    this.draftState.items = response.getResultOrDefault({ items: [] }).items;
    this.itemsLoading(false);
    if (!isNull(response.error)) {
      this.draftState.serverError = response.error;
    }
  }
}
