import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { Q1Routes } from "appRedux/models/routes/Q1RouteModel";
import { asyncComponent } from "components/utils";
import { K2Card, K2Link } from "components/k2Widgets";

const {
  errors: { error, _401, _404, _500, unresponsive },
} = Q1Routes;

const TempErrorPage: FC = () => {
  return (
    <div className="n3o-main-content-wrapper n3o-holding-page-wrapper">
      <K2Card title={"This page does not exist yet"} shadow>
        <h3>Routes available:</h3>
        <ul>
          <li>
            <K2Link to={_401}>{_401}</K2Link>
          </li>
          <li>
            <K2Link to={_404}>{_404}</K2Link>
          </li>
          <li>
            <K2Link to={_500}>{_500}</K2Link>
          </li>
          <li>
            <K2Link to={unresponsive}>{unresponsive}</K2Link>
          </li>
        </ul>
      </K2Card>
    </div>
  );
};

const ErrorRoutes: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={error} component={TempErrorPage} />
      <Route
        exact
        path={_401}
        component={asyncComponent(
          () =>
            import(
              /* webpackChunkName: "Error401Component" */ "components/errors/401"
            ),
        )}
      />
      <Route
        exact
        path={_404}
        component={asyncComponent(
          () =>
            import(
              /* webpackChunkName: "Error404Component" */ "components/errors/404"
            ),
        )}
      />
      <Route
        exact
        path={_500}
        component={asyncComponent(
          () =>
            import(
              /* webpackChunkName: "Error500Component" */ "components/errors/500"
            ),
        )}
      />
      <Route
        exact
        path={unresponsive}
        component={asyncComponent(
          () =>
            import(
              /* webpackChunkName: "ErrorUnresponsiveComponent" */ "components/errors/unresponsive"
            ),
        )}
      />
    </Switch>
  );
};

export default ErrorRoutes;
