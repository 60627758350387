import React, { FC } from "react";

import styled from "styled-components";

const Span = styled.span<{ $onHoverOnly: boolean; $skipPathHover: boolean }>`
  path {
    color: ${({ theme, $onHoverOnly }) =>
      $onHoverOnly ? "" : theme.link_color};
  }

  &:hover {
    cursor: pointer;
    path {
      color: ${({ theme, $skipPathHover }) =>
        $skipPathHover ? "" : theme.link_hover_color};
      fill: ${({ theme, $skipPathHover }) =>
        $skipPathHover ? "" : theme.link_hover_color};
    }
  }
`;

interface FakeIconLinkProps {
  onHoverOnly?: boolean;
  skipPathHover?: boolean;
}

const FakeIconLink: FC<FakeIconLinkProps> = ({
  children,
  onHoverOnly = false,
  skipPathHover = false,
}) => {
  return (
    <Span
      className={"fake_link"}
      $onHoverOnly={onHoverOnly}
      $skipPathHover={skipPathHover}
    >
      {children}
    </Span>
  );
};

export default FakeIconLink;
