import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";

export default class LocalizationHelpers {
  /**
   * Returns the configuration object for MomentJS relative time display.
   * @param k2Intl
   * @param parentLocale
   */
  public static getRelativeTimes(k2Intl: InjectedK2Intl, parentLocale: string) {
    return {
      parentLocale,
      relativeTime: {
        future: k2Intl?.formatMessage({ localeKey: "common.moment.future" }),
        past: k2Intl?.formatMessage({ localeKey: "common.moment.past" }),
        s: k2Intl?.formatMessage({ localeKey: "common.moment.s" }),
        ss: k2Intl?.formatMessage({ localeKey: "common.moment.ss" }),
        m: k2Intl?.formatMessage({ localeKey: "common.moment.m" }),
        mm: k2Intl?.formatMessage({ localeKey: "common.moment.mm" }),
        h: k2Intl?.formatMessage({ localeKey: "common.moment.h" }),
        hh: k2Intl?.formatMessage({ localeKey: "common.moment.hh" }),
        d: k2Intl?.formatMessage({ localeKey: "common.moment.d" }),
        dd: k2Intl?.formatMessage({ localeKey: "common.moment.dd" }),
        w: k2Intl?.formatMessage({ localeKey: "common.moment.w" }),
        ww: k2Intl?.formatMessage({ localeKey: "common.moment.ww" }),
        M: k2Intl?.formatMessage({ localeKey: "common.moment.M" }),
        MM: k2Intl?.formatMessage({ localeKey: "common.moment.MM" }),
        y: k2Intl?.formatMessage({ localeKey: "common.moment.y" }),
        yy: k2Intl?.formatMessage({ localeKey: "common.moment.yy" }),
      },
    };
  }

  public static getRelativeShortTimes(
    k2Intl: InjectedK2Intl,
    parentLocale: string,
  ) {
    return {
      parentLocale,
      relativeTime: {
        future: k2Intl?.formatMessage({ localeKey: "common.moment.future" }),
        past: k2Intl?.formatMessage({ localeKey: "common.moment.past" }),
        s: k2Intl?.formatMessage({ localeKey: "common.moment.s.short" }),
        ss: k2Intl?.formatMessage({ localeKey: "common.moment.ss.short" }),
        m: k2Intl?.formatMessage({ localeKey: "common.moment.m.short" }),
        mm: k2Intl?.formatMessage({ localeKey: "common.moment.mm.short" }),
        h: k2Intl?.formatMessage({ localeKey: "common.moment.h.short" }),
        hh: k2Intl?.formatMessage({ localeKey: "common.moment.hh.short" }),
        d: k2Intl?.formatMessage({ localeKey: "common.moment.d.short" }),
        dd: k2Intl?.formatMessage({ localeKey: "common.moment.dd.short" }),
        w: k2Intl?.formatMessage({ localeKey: "common.moment.w.short" }),
        ww: k2Intl?.formatMessage({ localeKey: "common.moment.ww.short" }),
        M: k2Intl?.formatMessage({ localeKey: "common.moment.M.short" }),
        MM: k2Intl?.formatMessage({ localeKey: "common.moment.MM.short" }),
        y: k2Intl?.formatMessage({ localeKey: "common.moment.y.short" }),
        yy: k2Intl?.formatMessage({ localeKey: "common.moment.yy.short" }),
      },
    };
  }
}
