import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/src/index";
import { PdfClient } from "@n3oltd/karakoram.templates.sdk.pdf";
import { PdfCompositionRes } from "@n3oltd/karakoram.templates.sdk.pdf/src/index";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

import K2RestClients, {
  _assetsClient,
  _pdfCompositionsClient,
} from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { DataActions } from "appRedux/modules/sharedTypes";
import { TEMPLATE_ENGINE } from "constants/appConstants";

import {
  ActionTypes,
  CreatePdfTemplate,
  DeletePdfTemplate,
  FetchPdfTemplate,
  RemoveEpilogueOrPrologue,
  RemoveTemporaryAsset,
  UpdatePdfTemplate,
  UploadAsset,
  UploadEpilogueOrPrologue,
} from "./actionTypes";
import * as actions from "./actions";

//#region WORKERS

//#endregion

function* fetchComposition(action: FetchPdfTemplate) {
  const response: IApiResponse<PdfCompositionRes> = yield K2RestService.toResponse(
    _pdfCompositionsClient.getCompositionById(action.id, TEMPLATE_ENGINE, null),
  );

  if (response.error) {
    yield put(actions.fetchPdfTemplateError(response.error));
  } else {
    yield put(actions.fetchPdfTemplateSuccess(response.getResultOrDefault()));
  }
}

function* uploadAsset(action: UploadAsset) {
  try {
    yield put(actions.updateTemporaryAssets(action.upload));

    const resp: IApiResponse<string> = yield K2RestService.toResponse(
      _assetsClient.upload(null, {
        fileName: action.originalFile.name,
        data: action.originalFile,
      }),
    );

    if (resp.error) {
      yield put(
        actions.updateTemporaryAssets({
          ...action.upload,
          status: "error",
          response:
            resp.error.data.status === K2StatusCodes.preconditionFailed
              ? resp.error.data.errors?.map?.((e) => e.error)?.join(". ")
              : resp.error.data.title,
        }),
      );
    } else {
      const assetResp: IApiResponse<AssetRes> = yield K2RestService.toResponse(
        _assetsClient.createAsset(TEMPLATE_ENGINE, null, {
          file: resp.getResultOrDefault(),
          shared: action.upload.shared,
        }),
      );

      if (assetResp.error) {
        const errors: string =
          assetResp.error.data.status === K2StatusCodes.preconditionFailed
            ? assetResp.error.data.errors?.map?.((e) => e.error)?.join(". ")
            : assetResp.error.data.title;

        yield put(
          actions.updateTemporaryAssets({
            ...action.upload,
            status: "error",
            response: errors,
          }),
        );
      } else {
        yield put(
          actions.updateTemporaryAssets({
            ...action.upload,
            status: "success",
            savedAsset: assetResp.getResultOrDefault(),
          }),
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* uploadEpiloguePrologue(action: UploadEpilogueOrPrologue) {
  try {
    yield put(
      actions.updateEpilogueOrPrologue(action.logueType, action.upload),
    );

    const resp: IApiResponse<string> = yield K2RestService.toResponse(
      _assetsClient.upload(null, {
        fileName: action.originalFile.name,
        data: action.originalFile,
      }),
    );

    if (resp.error) {
      yield put(
        actions.updateEpilogueOrPrologue(action.logueType, {
          ...action.upload,
          status: "error",
          response:
            resp.error.data.status === K2StatusCodes.preconditionFailed
              ? resp.error.data.errors?.map?.((e) => e.error)?.join(". ")
              : resp.error.data.title,
        }),
      );
    } else {
      const assetResp: IApiResponse<AssetRes> = yield K2RestService.toResponse(
        _assetsClient.createAsset(TEMPLATE_ENGINE, null, {
          file: resp.getResultOrDefault(),
          shared: action.upload.shared,
        }),
      );

      if (assetResp.error) {
        yield put(
          actions.updateEpilogueOrPrologue(action.logueType, {
            ...action.upload,
            status: "error",
            response:
              assetResp.error.data.status === K2StatusCodes.preconditionFailed
                ? assetResp.error.data.errors?.map?.((e) => e.error)?.join(". ")
                : assetResp.error.data.title,
          }),
        );
      } else {
        yield put(
          actions.updateEpilogueOrPrologue(action.logueType, {
            ...action.upload,
            status: "success",
            savedAsset: assetResp.getResultOrDefault(),
          }),
        );
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* deleteAsset(action: RemoveTemporaryAsset | RemoveEpilogueOrPrologue) {
  // If the asset has already been saved to the backend, delete it.
  if (action.temporaryAsset.savedAsset) {
    yield K2RestService.toResponse(
      _assetsClient.deleteAsset(
        action.temporaryAsset.savedAsset.revisionId,
        TEMPLATE_ENGINE,
        null,
      ),
    );
  }
}

function* createTemplate(action: CreatePdfTemplate) {
  try {
    let client = _pdfCompositionsClient;
    if (action.ignoreWarning) {
      client = K2RestClients.initRestClient(PdfClient, "templates", {
        getN3OIgnoreValidationWarnings: () => "true",
      });
    }
    const resp: IApiResponse<PdfCompositionRes> = yield K2RestService.toResponse(
      client.createComposition(
        TEMPLATE_ENGINE,
        action.scopeId || undefined,
        action.data,
      ),
    );

    if (resp.error) {
      yield put(
        actions.createPdfTemplateError({
          action: DataActions.add,
          ...resp,
        }),
      );
    } else {
      yield put(
        actions.createPdfTemplateSuccess({
          action: DataActions.add,
          ...resp,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateTemplate(action: UpdatePdfTemplate) {
  try {
    let client = _pdfCompositionsClient;
    if (action.ignoreWarning) {
      client = K2RestClients.initRestClient(PdfClient, "templates", {
        getN3OIgnoreValidationWarnings: () => "true",
      });
    }
    const resp: IApiResponse<void> = yield K2RestService.toResponse(
      client.updateComposition(
        action.revisionId,
        TEMPLATE_ENGINE,
        action.scopeId || undefined,
        action.data,
      ),
    );

    if (resp.error) {
      yield put(
        actions.updatePdfTemplateError({
          action: DataActions.update,
          ...resp,
        }),
      );
    } else {
      yield put(
        actions.updatePdfTemplateSuccess({
          action: DataActions.update,
          ...resp,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* deleteTemplate(action: DeletePdfTemplate) {
  try {
    const resp: IApiResponse<void> = yield K2RestService.toResponse(
      _pdfCompositionsClient.deleteComposition(
        action.revisionId,
        TEMPLATE_ENGINE,
        null,
      ),
    );

    if (resp.error) {
      yield put(
        actions.deletePdfTemplateError({
          action: DataActions.delete,
          ...resp,
        }),
      );
    } else {
      yield put(
        actions.deletePdfTemplateSuccess({
          action: DataActions.delete,
          ...resp,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

//#region WATCHERS

export default function* partialOrLayoutSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_PDF_TEMPLATE, fetchComposition),
    takeEvery(ActionTypes.UPLOAD_ASSET, uploadAsset),
    takeEvery(ActionTypes.REMOVE_TEMPORARY_ASSET, deleteAsset),
    takeEvery(ActionTypes.REMOVE_EPILOGUE_OR_PROLOGUE, deleteAsset),
    takeEvery(ActionTypes.CREATE_PDF_TEMPLATE, createTemplate),
    takeEvery(ActionTypes.UPDATE_PDF_TEMPLATE, updateTemplate),
    takeEvery(ActionTypes.DELETE_PDF_TEMPLATE, deleteTemplate),
    takeEvery(ActionTypes.UPLOAD_EPILOGUE_OR_PROLOGUE, uploadEpiloguePrologue),
  ]);
}

//#endregion
