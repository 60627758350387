import React, { FC, useState } from "react";

import { InboxOutlined } from "@ant-design/icons/lib";
import { generateUuid } from "@azure/ms-rest-js";
import {
  AttachmentRes,
  ContentVisibility,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";
import { Alert, Form, Row } from "antd";
import { FormInstance } from "antd/es/form";
import Dragger from "antd/es/upload/Dragger";
import { UploadFile } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";

import { _pdfCompositionsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { GeneralFormItemV4 } from "components/formItems";
import { K2Message } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import K2Modal from "components/k2Widgets/k2Modal";
import { UIUtils } from "components/utils";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import PdfStationeryHelpers from "routes/admin/communication/newEditPdfStationery/helpers";
import { OverlayResWithScreenOnlyAndUUID } from "routes/admin/communication/newEditPdfStationery/modules/types";

interface UploadOverlayModalProps {
  onCancel: () => void;
  form: FormInstance;
  onSave: (newOverlay: OverlayResWithScreenOnlyAndUUID) => void;
  k2Intl: InjectedK2Intl;
}

const UploadOverlayModal: FC<UploadOverlayModalProps> = ({
  onCancel,
  form,
  onSave,
  k2Intl,
}) => {
  const [uploadedOverlay, setUploadedOverlay] = useState<AttachmentRes>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [tempUploadedFile, setTempUploadedFile] = useState<UploadFile>(null);
  const [uploadErrors, setUploadErrors] = useState<string[]>(null);

  const upload = async (info: RcCustomRequestOptions) => {
    setUploading(true);

    const file = info.file as RcFile;
    const tempUpload: UploadFile = {
      uid: generateUuid(),
      fileName: file.name,
      size: file.size,
      name: file.name,
      type: file.type,
      status: "uploading",
      originFileObj: file,
    };

    setTempUploadedFile(tempUpload);
    form?.setFields([
      {
        name: PdfStationeryHelpers.uploadOverlayFieldName,
        errors: [],
      },
    ]);

    const resp: IApiResponse<AttachmentRes> = await K2RestService.toResponse(
      _pdfCompositionsClient.upload(null, { fileName: file.name, data: file }),
    );

    if (resp.error) {
      if (resp.error.status === K2StatusCodes.preconditionFailed) {
        setTempUploadedFile(null);

        // Handle field-level validation errors and set them on the form
        UIUtils.handleValidationErrors(resp.error, form);

        const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
          resp.error,
          "error",
        );

        if (nonFieldRelatedErrors.length) {
          setUploadErrors(nonFieldRelatedErrors?.map?.((err) => err.error));
        }
      } else {
        UIUtils.handleServerError(k2Intl, resp.error);
      }
    } else {
      setUploadErrors(null);
      setTempUploadedFile({
        ...tempUpload,
        status: "done",
      });
      setUploadedOverlay(resp.getResultOrDefault());
    }

    setUploading(false);
  };

  return (
    <DirtyDataGuard dirty={uploading || !!uploadedOverlay}>
      <K2Modal
        visible={true}
        centered
        titleKey={"admin.communication.stationery.addOverlay"}
        requiresCloseConfirmation={uploading}
        onCancel={() => {
          onCancel();
          form?.setFields([
            {
              name: PdfStationeryHelpers.uploadOverlayFieldName,
              errors: [],
            },
          ]);
        }}
        onOk={() => {
          form?.setFields([
            {
              name: PdfStationeryHelpers.uploadOverlayFieldName,
              errors: [],
            },
          ]);
          onSave({
            attachment: uploadedOverlay,
            visibility: ContentVisibility.Both,
            screenOnly: false,
            uuid: generateUuid(),
          });
        }}
        okText={k2Intl?.formatMessage({
          localeKey: "common.save",
        })}
        okButtonProps={{
          loading: uploading,
          disabled: !uploadedOverlay || uploading,
        }}
        width={"50%"}
      >
        <Form form={form}>
          <Form.Item
            label={k2Intl?.formatMessage({
              localeKey: "admin.communication.stationery.uploadOverlay",
            })}
            required
            name={PdfStationeryHelpers.uploadOverlayFieldName}
            wrapperCol={{
              xs: 24,
            }}
            labelCol={{
              xs: 24,
            }}
          >
            <GeneralFormItemV4
              valuePropName="fileList"
              required
              name={PdfStationeryHelpers.uploadOverlayFieldName}
              wrapperCol={{
                xs: 24,
              }}
              labelCol={{
                xs: 24,
              }}
            >
              <Dragger
                multiple={false}
                customRequest={upload}
                onRemove={() => {
                  setUploadErrors(null);
                  setUploadedOverlay(null);
                  setUploadedOverlay(null);
                }}
                fileList={tempUploadedFile ? [tempUploadedFile] : []}
                accept={".pdf"}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  <K2Message
                    localeKey={
                      "admin.communication.stationery.uploadOverlay.explanation"
                    }
                  />
                </p>
              </Dragger>

              {uploadErrors?.length > 0 && (
                <Row style={{ marginBottom: 20, marginTop: 10 }}>
                  <Alert
                    showIcon
                    type="error"
                    message={uploadErrors?.map?.((e, i) => (
                      <p key={i}>{e}</p>
                    ))}
                  />
                </Row>
              )}
            </GeneralFormItemV4>
          </Form.Item>
        </Form>
      </K2Modal>
    </DirtyDataGuard>
  );
};

export default UploadOverlayModal;
