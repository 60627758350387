import React, { FC, useEffect, useState } from "react";

import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/esm";
import {
  CreateFragmentReq,
  FragmentRes,
  PreviewFragmentReq,
} from "@n3oltd/karakoram.templates.sdk.fragments/esm";
import { FragmentPreviewRes } from "@n3oltd/karakoram.templates.sdk.fragments/src/index";
import { useForm } from "antd/es/form/Form";
import { useRouter } from "hooks";
import styled from "styled-components";

import { _fragmentsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { DataActions } from "appRedux/modules/sharedTypes";
import NotFoundWrapper from "components/NotFoundWrapper";
import { K2RouteHeading } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { FormsWrapper } from "components/layout/wrappers";
import { UIUtils } from "components/utils";
import { showError } from "components/utils/Confirmation";
import { showNotification } from "components/utils/Notification";
import {
  REDIRECT_AFTER_SAVE_MS,
  TEMPLATE_ENGINE,
} from "constants/appConstants";
import useBreadcrumb from "hooks/useBreadcrumb";
import CommunicationAdminHelpers from "routes/admin/communication/helpers";

import CreateEditPartialLayoutForm from "./components/CreateEditPartialLayoutForm";
import Preview from "./components/Preview";
import { PartialOrLayoutProps } from "./connect";

const CardWrapper = styled.div`
  margin-top: 2rem;
`;

const PartialOrLayout: FC<PartialOrLayoutProps> = (props) => {
  const {
    fetchPartialOrLayout,
    clearPartialOrLayout,
    match,
    data,
    error,
    editStatus,
    k2Intl,
    clearPartialOrLayoutError,
    createPartialOrLayout,
    updatePartialOrLayout,
    clearEditStatus,
    dirty,
    updateDirty,
  } = props;

  const router = useRouter();

  const { partialId, layoutId } = match.params;
  const editMode = !!partialId || !!layoutId;
  const entityId: string = partialId || layoutId || null;
  const fragmentType = CommunicationAdminHelpers.getFragmentType(match.path);
  const entityType = CommunicationAdminHelpers.getEntityType(match.path);

  // These are the assets that are currently visible on the Media Assets list,
  // including new assets that have been added
  const [tempAssets, setTempAssets] = useState<AssetRes[]>([]);

  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [previewContent, setPreviewContent] = useState<string>(null);
  const [previewError, setPreviewError] = useState<string>(null);

  const [form] = useForm();

  const [overallFormErrors, setOverallFormErrors] = useState<string[]>(null);

  useEffect(() => {
    if (error) {
      if (error.status === K2StatusCodes.notFound) {
        setNotFound(true);
        clearPartialOrLayoutError();
      } else {
        UIUtils.handleServerError(k2Intl, error, clearPartialOrLayoutError);
      }
    }
  }, [error, clearPartialOrLayoutError, k2Intl]);

  useEffect(() => {
    if (editStatus) {
      switch (editStatus.action) {
        case DataActions.add:
          if (editStatus.error) {
            if (editStatus.error.status === K2StatusCodes.preconditionFailed) {
              // Handle field-level validation errors and set them on the form
              UIUtils.handleValidationErrors(editStatus.error, form);

              const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
                editStatus.error,
                "error",
              );

              // Errors take precedence, show them at the foot of the form
              if (nonFieldRelatedErrors.length) {
                setOverallFormErrors(
                  nonFieldRelatedErrors?.map?.((err) => err.error),
                );
              }
            } else {
              UIUtils.handleServerError(k2Intl, editStatus.error);
            }
          } else {
            showNotification({
              type: "success",
              titleKey: "common.success.title",
              messageKey: `admin.communication.${fragmentType}s.created`,
              k2Intl,
            });

            updateDirty(false);

            setTimeout(() => {
              // Redirect back to main page
              router.history.push(
                CommunicationAdminHelpers.getK2ListRouteForEntityTypeAndPageType(
                  fragmentType,
                  entityType,
                ),
              );
            }, REDIRECT_AFTER_SAVE_MS);
          }
          break;
        case DataActions.update:
          if (editStatus.error) {
            if (editStatus.error.status === K2StatusCodes.preconditionFailed) {
              // Handle field-level validation errors and set them on the form
              UIUtils.handleValidationErrors(editStatus.error, form);

              const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
                editStatus.error,
                "error",
              );

              // Errors take precedence, show them at the foot of the form
              if (nonFieldRelatedErrors.length) {
                setOverallFormErrors(
                  nonFieldRelatedErrors?.map?.((err) => err.error),
                );
              }
            } else {
              UIUtils.handleServerError(k2Intl, editStatus.error);
            }
          } else {
            showNotification({
              type: "success",
              titleKey: "common.success.title",
              messageKey: `admin.communication.${fragmentType}s.updated`,
              k2Intl,
            });

            updateDirty(false);

            setTimeout(() => {
              // Redirect back to main page
              router.history.push(
                CommunicationAdminHelpers.getK2ListRouteForEntityTypeAndPageType(
                  fragmentType,
                  entityType,
                ),
              );
            }, REDIRECT_AFTER_SAVE_MS);
          }
          break;
        case DataActions.delete:
          if (editStatus.error?.status === K2StatusCodes.preconditionFailed) {
            const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
              editStatus.error,
              "error",
            );
            if (nonFieldRelatedErrors.length) {
              showError({
                titleKey: "common.nonDismissableFormErrorTitle",
                error: nonFieldRelatedErrors.map((e) => <p>{e.error}</p>),
                okTextKey: "common.ok",
                k2Intl,
                onOk: () => {
                  clearEditStatus();
                },
                onCancel: () => {
                  clearEditStatus();
                },
              });
            }
          } else {
            UIUtils.handleDataUpdateResult({
              k2Intl,
              status: editStatus,
              successTitle: "common.success.title",
              successMessage: `admin.communication.${fragmentType}.delete.success`,
              onSuccess: () => {
                updateDirty(false);

                setTimeout(() => {
                  router.history.push(
                    CommunicationAdminHelpers.getK2ListRouteForEntityTypeAndPageType(
                      fragmentType,
                      entityType,
                    ),
                  );
                }, REDIRECT_AFTER_SAVE_MS);
              },
            });
          }
          break;
      }

      clearEditStatus();
    }
  }, [
    editStatus,
    k2Intl,
    fragmentType,
    clearEditStatus,
    form,
    router,
    entityType,
    updateDirty,
  ]);

  const setBreadcrumbs = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbs([
      {
        path: K2Routes.main.admin,
        titleKey: "mainNav.admin",
        active: false,
      },
      {
        path: CommunicationAdminHelpers.getK2ListRouteForEntityTypeAndPageType(
          fragmentType,
          entityType,
        ),
        titleKey: `admin.communication.${entityType}.${fragmentType}s`,
        active: false,
      },
      {
        path: CommunicationAdminHelpers.getK2EditRouteForEntityTypeAndPageType(
          fragmentType,
          entityType,
          editMode,
        ),
        title:
          editMode && data
            ? fragmentType === "layout"
              ? data.layout.template.name
              : data.partial.template.name
            : null,
        titleKey: `admin.communication.${fragmentType}s.${
          editMode ? "edit" : "new"
        }`,
        active: true,
      },
    ]);
  }, [setBreadcrumbs, fragmentType, editMode, entityType, data]);

  useEffect(() => {
    setNotFound(false);
    if (entityId) {
      fetchPartialOrLayout(entityId);
    }

    return () => clearPartialOrLayout();
  }, [fetchPartialOrLayout, entityId, clearPartialOrLayout]);

  const generateReq = (): PreviewFragmentReq => {
    const assetIds = tempAssets?.map?.((a) => a.id);

    const req: PreviewFragmentReq = {
      type: fragmentType,
      ...form?.getFieldsValue(),
    };

    req[fragmentType].template.mediaAssetIds = assetIds;

    return req;
  };

  const generatePreviewReq = (): PreviewFragmentReq => {
    const req: PreviewFragmentReq = {
      ...generateReq(),
      compositionType: entityType,
    };
    if (req.layout) {
      req.layout.template.name = "test-fragment-12332432";
    }
    if (req.partial) {
      req.partial.template.name = "test-fragment-12332432";
      req.partial.alias = "t843290483294";
    }
    return req;
  };

  const handleSubmit = () => {
    setOverallFormErrors(null);

    const req = generateReq();

    if (editMode) {
      updatePartialOrLayout(data[fragmentType].template.revisionId, req);
    } else {
      const createReq: CreateFragmentReq = {
        ...req,
        compositionType: entityType,
      };
      createPartialOrLayout(createReq);
    }
  };

  const handlePreview = async (values) => {
    setShowPreviewModal(true);
    setPreviewLoading(true);

    const req: PreviewFragmentReq = generatePreviewReq();
    try {
      const resp: IApiResponse<FragmentPreviewRes> = await K2RestService.toResponse(
        _fragmentsClient.previewFragment(TEMPLATE_ENGINE, null, req),
      );

      if (resp.error) {
        setPreviewError(
          k2Intl?.formatMessage({
            localeKey: `admin.communication.${fragmentType}.preview.error`,
          }),
        );
      } else {
        setPreviewContent(resp.getResultOrDefault().content);
      }
    } catch (e) {
      setPreviewError(
        k2Intl?.formatMessage({
          localeKey: `admin.communication.${fragmentType}.preview.error`,
        }),
      );
    }

    setPreviewLoading(false);
  };

  return (
    <FormsWrapper>
      <NotFoundWrapper
        showNotFound={notFound}
        entityNameKey={`common.entity.action.${fragmentType}`}
      >
        <K2RouteHeading
          headingKey={`admin.communication.${fragmentType}s.${
            editMode ? "edit" : "new"
          }`}
        />
        {showPreviewModal && (
          <Preview
            fragmentType={fragmentType}
            entityType={entityType}
            content={previewContent}
            loading={previewLoading}
            error={previewError}
            onClose={() => {
              setPreviewError(null);
              setPreviewLoading(false);
              setPreviewContent(null);
              setShowPreviewModal(false);
            }}
          />
        )}
        <CardWrapper>
          <CreateEditPartialLayoutForm
            {...props}
            editMode={editMode}
            form={form}
            entityType={entityType}
            fragmentType={fragmentType}
            onSubmit={handleSubmit}
            onPreview={handlePreview}
            errors={overallFormErrors}
            dirty={dirty}
            onValuesChange={() => updateDirty(true)}
            setTempAssets={setTempAssets}
            tempAssets={tempAssets}
          />
        </CardWrapper>
      </NotFoundWrapper>
    </FormsWrapper>
  );
};

export default injectK2Intl(PartialOrLayout);
