import React from "react";

import { MenuOutlined } from "@ant-design/icons/lib";
import { ReportingPeriodRes } from "@n3oltd/karakoram.analytics.sdk.attribution/esm";
import { SortableHandle } from "react-sortable-hoc";

import { IK2GridColumn } from "appRedux/models/grid/K2GridColumn";
import BaseListModel from "appRedux/models/subscription/BaseListModel";
import EditLinkIcon from "components/editLinkIcon";
import { K2DateTime, K2Message } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";

import { ReportingPeriodProps } from "./connect";

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

export default class ReportingPeriodsListModel extends BaseListModel {
  constructor(config: ReportingPeriodProps) {
    super(config);
  }

  getColumns(
    onEdit: (item: ReportingPeriodRes) => void,
  ): IK2GridColumn<ReportingPeriodRes>[] {
    const columns: IK2GridColumn<ReportingPeriodRes>[] = [
      {
        title: <K2Message localeKey={"common.order"} />,
        dataIndex: "sort",
        width: "150px",
        className: "drag-visible",
        render: () => <DragHandle />,
      },
      {
        dataIndex: "name",
        key: "name",
        width: "250px",
        title: <K2Message localeKey={"common.name"} />,
      },
      {
        dataIndex: "startsOn",
        key: "startsOn",
        title: <K2Message localeKey={"common.startDate"} />,
        render: (date: string) => <K2DateTime value={date} mode={"date"} />,
      },
      {
        dataIndex: "endsOn",
        key: "endsOn",
        title: <K2Message localeKey={"common.endDate"} />,
        render: (date: string) => <K2DateTime value={date} mode={"date"} />,
      },
      {
        dataIndex: "edit",
        key: "edit",
        render: (_, item: ReportingPeriodRes) => (
          <div style={{ textAlign: "right" }}>
            <FakeLink onClick={() => onEdit(item)}>
              <EditLinkIcon />
            </FakeLink>
          </div>
        ),
      },
    ];

    return columns;
  }
}
