import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const CreateDonation: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.34556 2.90908C7.6944 2.69873 7.00256 2.64614 6.32708 2.75566C5.6516 2.86517 5.01183 3.13365 4.46049 3.53896C3.90915 3.94427 3.46205 4.47481 3.15602 5.08683C2.75058 5.89763 2.61055 6.81547 2.7558 7.71027C2.90104 8.60507 3.32419 9.43148 3.96528 10.0724L3.97806 10.0855L9.27271 15.6113C9.59748 15.9503 9.58599 16.4884 9.24702 16.8132C8.90806 17.1379 8.37 17.1264 8.04522 16.7875L2.75664 11.2679C1.86695 10.3757 1.27968 9.22657 1.07776 7.98265C0.875341 6.73561 1.07047 5.4565 1.63551 4.32654C2.06202 3.47356 2.68517 2.73413 3.45356 2.16926C4.22196 1.60438 5.11361 1.2302 6.05501 1.07757C6.99642 0.924942 7.96062 0.99823 8.86814 1.29139C9.77545 1.58449 10.6001 2.08897 11.2742 2.76326L12.1032 3.5896L12.9296 2.76374C13.6038 2.0892 14.4287 1.58456 15.3362 1.29139C16.2437 0.99823 17.2079 0.924942 18.1493 1.07757C19.0907 1.2302 19.9824 1.60438 20.7508 2.16926C21.5192 2.73414 22.1423 3.47355 22.5688 4.32654C22.9896 5.16969 23.2069 6.10039 23.2027 7.04271C23.1985 7.98502 22.9731 8.91318 22.5447 9.75251C22.3313 10.1706 21.8193 10.3366 21.4012 10.1232C20.9831 9.90978 20.8171 9.39781 21.0305 8.97968C21.3379 8.37736 21.4997 7.71129 21.5027 7.03506C21.5058 6.35884 21.35 5.69134 21.048 5.08627C20.742 4.47448 20.295 3.94415 19.7439 3.53896C19.1925 3.13365 18.5527 2.86517 17.8773 2.75566C17.2018 2.64614 16.5099 2.69873 15.8588 2.90908C15.2076 3.11943 14.6158 3.48153 14.132 3.96551L12.7048 5.39175C12.3732 5.72313 11.8359 5.72347 11.5039 5.39252L10.0723 3.96551C9.58857 3.48153 8.99673 3.11943 8.34556 2.90908Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8911 10.566C15.2158 10.566 13.6091 11.2315 12.4245 12.4161C11.2398 13.6007 10.5743 15.2074 10.5743 16.8827C10.5743 18.558 11.2398 20.1647 12.4245 21.3493C13.6091 22.5338 15.2158 23.1993 16.8911 23.1993C18.5665 23.1993 20.1732 22.5338 21.3578 21.3493C22.5425 20.1647 23.208 18.558 23.208 16.8827C23.208 15.2074 22.5425 13.6007 21.3578 12.4161C20.1732 11.2315 18.5665 10.566 16.8911 10.566ZM16.8911 12.266C15.6667 12.266 14.4923 12.7524 13.6265 13.6182C12.7607 14.484 12.2743 15.6583 12.2743 16.8827C12.2743 18.1071 12.7607 19.2814 13.6265 20.1471C14.4923 21.0129 15.6667 21.4993 16.8911 21.4993C18.1156 21.4993 19.2899 21.0129 20.1558 20.1471C21.0216 19.2814 21.508 18.1071 21.508 16.8827C21.508 15.6583 21.0216 14.484 20.1558 13.6182C19.2899 12.7524 18.1156 12.266 16.8911 12.266ZM17.7411 14.1494C17.7411 13.68 17.3606 13.2994 16.8911 13.2994C16.4217 13.2994 16.0411 13.68 16.0411 14.1494V16.0327H14.1577C13.6882 16.0327 13.3077 16.4133 13.3077 16.8827C13.3077 17.3522 13.6882 17.7327 14.1577 17.7327H16.0411V19.6161C16.0411 20.0855 16.4217 20.4661 16.8911 20.4661C17.3606 20.4661 17.7411 20.0855 17.7411 19.6161V17.7327H19.6245C20.094 17.7327 20.4745 17.3522 20.4745 16.8827C20.4745 16.4133 20.094 16.0327 19.6245 16.0327H17.7411V14.1494Z"
      fill={fill}
    />
  </svg>
);
