import React, { FC, useEffect, useState } from "react";

import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import {
  SecurityPrincipalProfile,
  SecurityPrincipalType,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Divider, Select, Tooltip } from "antd";
import { connect } from "react-redux";
import styled from "styled-components";

import TaskSelectors, {
  ISelectAssigneeStateProps,
} from "appRedux/store/selectors/TasksSelectors";
import SecurityPrincipalAvatar from "components/avatars/securityPrincipalAvatar";
import { K2Message } from "components/k2Widgets";
import CursorNotAllowed from "components/k2Widgets/k2CursorNotAllowed";
import FakeLink from "components/k2Widgets/k2FakeLink";
import { DEFAULT_DELAY } from "constants/appConstants";
import useDebounce from "hooks/useDebounce";
import usePrevious from "hooks/usePrevious";

import { SmallTagWithAvatar } from "../../tagWithAvatar";
import "./styles.less";

const { Option } = Select;

const CustomSelect = styled(Select)`
  && {
    .ant-select-selection--multiple > ul > li,
    .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
      height: 32px;
    }
    .ant-select-selection-item {
      height: 29px;
    }
    .ant-select-item-option {
      .ant-select-item-option-content .ant-tag {
        background: none !important;
      }
    }

    .ant-select-selection-item-remove {
      margin-top: 3px;
      padding-right: 2px;
    }
  }
`;

interface IProps extends ISelectAssigneeStateProps {
  setTouched?: () => void;
  onChange: (values: SecurityPrincipalProfile[]) => void;
  selectedValues: SecurityPrincipalProfile[];
  title?: React.ReactNode;
}

const SelectAssignees: FC<IProps> = (props) => {
  const {
    principalsLoading,
    securityPrincipals,
    fetchSecurityPrincipals,
    userHasRoles,
    currentUser,
  } = props;

  const [searchTerm, updateSearch] = useState<string>("");
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  // this is to prevent dropdown from opening when first fetchSecurityPrincipals is called.
  const [
    initialPrincipalsLoading,
    setInitialPrincipalsLoading,
  ] = useState<boolean>(true);

  const prevLoading = usePrevious(principalsLoading);

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DELAY);

  useEffect(
    () => {
      fetchSecurityPrincipals({});
    },
    // eslint-disable-next-line
    [],
  );
  // This effect runs whenever debouncedSearchTerm changes
  useEffect(() => {
    if (debouncedSearchTerm) {
      setInitialPrincipalsLoading(false);
      fetchSecurityPrincipals({ name: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm, fetchSecurityPrincipals]);

  // Opens the select options after search results come back
  useEffect(() => {
    if (prevLoading && !principalsLoading && !initialPrincipalsLoading) {
      setSelectOpen(true);
    }
    // eslint-disable-next-line
  }, [principalsLoading]);

  // Calls the onChange method if the chosen assignee is not already selected
  const handleChangeIfNotExists = (assignees: SecurityPrincipalProfile[]) => {
    if (!props.selectedValues || props.selectedValues.length === 0) {
      props.onChange([...assignees]);
    } else {
      const newAssignees = assignees.filter((assignee) => {
        const assigneeExists = props.selectedValues.find(
          (item) => item.principalId === assignee.principalId,
        );
        return !assigneeExists;
      });

      props.onChange([...props.selectedValues, ...newAssignees]);
    }
  };

  // Quick Select the current user's roles
  const setUserRolesValues = () => {
    handleChangeIfNotExists(currentUser.roleProfiles);
    if (props.setTouched) {
      props.setTouched();
    }
  };

  const getCurrentUserAsSecurityProfile = (
    currentUser: CurrentUserRes,
  ): SecurityPrincipalProfile => {
    return {
      principalId: currentUser.id,
      type: SecurityPrincipalType.User,
      initials: currentUser.initials,
      color: currentUser.color,
      hasPicture: !!currentUser.picture,
      name: currentUser.fullName,
    };
  };

  const currentUserAsSecurityProfile = getCurrentUserAsSecurityProfile(
    currentUser,
  );

  // Quick Select the current user
  const setUserValue = () => {
    // Here we transform the user object into a valid SecurityPrincipalProfile
    handleChangeIfNotExists([currentUserAsSecurityProfile]);

    if (props.setTouched) {
      props.setTouched();
    }
  };

  const handleSelect = (id: string): void => {
    // Add to list of selected values
    const updatedValues = [...props.selectedValues];
    const principal = securityPrincipals.find((p) => p.principalId === id);
    if (principal) {
      updatedValues.push(principal);
    }
    props.onChange(updatedValues);
    if (props.setTouched) {
      props.setTouched();
    }
  };

  const handleDeselect = (id: string): void => {
    // Remove from list of selected values
    const updatedValues = [...props.selectedValues];
    const i = updatedValues.findIndex((p) => p.principalId === id);
    if (i > -1) {
      updatedValues.splice(i, 1);
    }
    props.onChange(updatedValues);
    if (props.setTouched) {
      props.setTouched();
    }

    if (securityPrincipals.length === 1) {
      setSelectOpen(false);
    }
  };

  const searchResultsIncludeUser = securityPrincipals.find(
    (p) => p.principalId === currentUser.id,
  );

  const selectedValueKeys = props.selectedValues?.map?.((p) => p.principalId);

  const roleProfilesNotInSearchResults = currentUser.roleProfiles.filter(
    (rp) => {
      const inSearchResults = securityPrincipals.find(
        (p) => p.principalId === rp.principalId,
      );
      return !inSearchResults;
    },
  );

  const selectedItemsNotInSearchResultsOrQuickSelectOpts = props.selectedValues.filter(
    (p) => {
      const alreadyInSearchResults = securityPrincipals.find(
        (sp) => sp.principalId === p.principalId,
      );
      const alreadyQuickSelectMeOption = p.principalId === currentUser.id;
      const alreadyInQuickSelectTeamsOption =
        currentUser.roleProfiles &&
        currentUser.roleProfiles.find((rp) => rp.principalId === p.principalId);
      return (
        !alreadyInSearchResults &&
        !alreadyQuickSelectMeOption &&
        !alreadyInQuickSelectTeamsOption
      );
    },
  );

  return (
    <>
      <div
        style={{
          height: "32px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {props.title}
        <span style={{ textAlign: "right", padding: 0, float: "right" }}>
          <Tooltip title={<K2Message localeKey="common.assignToMe" />}>
            <FakeLink onClick={setUserValue}>
              <K2Message localeKey="tasks.me" />
            </FakeLink>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title={<K2Message localeKey="tasks.action.assignToTeam" />}>
            {userHasRoles ? (
              <FakeLink onClick={setUserRolesValues}>
                <K2Message localeKey="tasks.myTeams" />
              </FakeLink>
            ) : (
              <CursorNotAllowed>
                <K2Message localeKey="tasks.myTeams" />
              </CursorNotAllowed>
            )}
          </Tooltip>
        </span>
      </div>
      <CustomSelect
        showSearch
        loading={principalsLoading}
        value={selectedValueKeys}
        dropdownClassName={principalsLoading ? "n3o-dropdown-hidden" : ""}
        onSelect={handleSelect}
        onDeselect={handleDeselect}
        mode="multiple"
        onFocus={() => setSelectOpen(true)}
        onBlur={() => setSelectOpen(false)}
        open={selectOpen}
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={updateSearch}
        listHeight={150}
      >
        {securityPrincipals?.map?.((p: SecurityPrincipalProfile) => (
          <Option key={p.principalId} value={p.principalId}>
            <SmallTagWithAvatar>
              <SecurityPrincipalAvatar
                presetId="small"
                size="small"
                profile={p}
              />{" "}
              <span className={"name"}>{p.name}</span>
            </SmallTagWithAvatar>
          </Option>
        ))}

        {/* Here we create a "fake" hidden option so that when a user uses the quick-select "Assign to me" options, Antd Select can render these selections correctly with their avatars */}
        {!searchResultsIncludeUser && (
          <Option
            value={currentUser.id}
            key={currentUser.id}
            style={{ display: "none !important" }}
          >
            <SmallTagWithAvatar>
              <SecurityPrincipalAvatar
                presetId="small"
                size="small"
                profile={currentUserAsSecurityProfile}
              />
              <span className={"name"}>{currentUser.fullName}</span>
            </SmallTagWithAvatar>
          </Option>
        )}

        {/* Here we create n number of "fake" hidden options so that when a user uses the quick-select "Assign to my teams" option, Antd select can render these options correctly with their avatars */}
        {userHasRoles &&
          roleProfilesNotInSearchResults?.map?.(
            (profile: SecurityPrincipalProfile) => (
              <Option
                key={profile.principalId}
                value={profile.principalId}
                style={{ display: "none !important" }}
              >
                <SmallTagWithAvatar>
                  <SecurityPrincipalAvatar
                    presetId="small"
                    size="small"
                    profile={profile}
                  />{" "}
                  <span className={"name"}>{profile.name}</span>
                </SmallTagWithAvatar>
              </Option>
            ),
          )}

        {/* Here we create 2 "fake" hidden options so that the initially selected entities display correctly. */}
        {selectedItemsNotInSearchResultsOrQuickSelectOpts?.map?.((p) => (
          <Option
            key={p.principalId}
            value={p.principalId}
            style={{ display: "none !important" }}
          >
            <SmallTagWithAvatar>
              <SecurityPrincipalAvatar
                presetId="small"
                size="small"
                profile={p}
              />{" "}
              <span className={"name"}>{p.name}</span>
            </SmallTagWithAvatar>
          </Option>
        ))}

        {securityPrincipals.length === 0 && !principalsLoading && (
          <Option key="fake-key" value="fake-key" disabled>
            {searchTerm.length < 4 ? (
              <K2Message localeKey="common.keepTyping" />
            ) : (
              <K2Message
                localeKey="common.noResults"
                values={{ value: searchTerm }}
              />
            )}
          </Option>
        )}
      </CustomSelect>
    </>
  );
};

export default connect(
  TaskSelectors.getSelectTaskAssigneesSelector(),
  TaskSelectors.getSelectAssigneesDispatchers(),
)(SelectAssignees);
