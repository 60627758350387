import React, { useState } from "react";

import { AddOrRemoveDispositionReq } from "@n3oltd/k2.calls.sdk.calls";
import { Button } from "antd";
import styled from "styled-components";

import { CallDispositionsLookups } from "appRedux/modules/lookups/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Message } from "components/k2Widgets";
import { CustomScrollbars } from "components/utils";

import TitleDivider from "../TitleDivider";
import DispositionsHeader from "./DispositionsHeader";

const DispositionsContainer = styled.div`
  position: relative;
  padding-bottom: 50px;
`;

const DispositionsBody = styled.div`
  padding: 20px;
  padding-top: 0px;
  text-align: center;

  p {
    color: ${({ theme }) => theme.grey_6};
  }

  button {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    padding: 7px 20px;
    height: auto;
  }
`;
const DispositionsFooter = styled.div`
  background-color: ${({ theme }) => theme.white_color};
  border-top: 1px solid ${({ theme }) => theme.grey_4};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  border-radius: 0 0 10px 10px;

  button {
    width: 100%;
  }
`;

interface IDispositionSelector {
  children?: React.ReactNode;
  add: () => void;
  remove: () => void;
}
function DispositionSelector({ children, add, remove }: IDispositionSelector) {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const { theme } = useAppContext();

  return (
    <Button
      block
      onClick={() => {
        const previousSelected = isSelected;
        setIsSelected(!previousSelected);
        if (previousSelected) remove();
        else add();
      }}
      style={{
        border: isSelected
          ? `1px solid ${theme.blue_7}`
          : `1px solid ${theme.grey_5}`,
        color: isSelected ? `${theme.blue_7}` : `${theme.grey_9}`,
      }}
    >
      {children}
    </Button>
  );
}

interface IDispositionsViewProps {
  callDuration: string;
  callDispositions: CallDispositionsLookups;
  setOpenNotesModal: (value: boolean) => void;
  addDispositions: (value: AddOrRemoveDispositionReq) => void;
  removeDispositions: (value: AddOrRemoveDispositionReq) => void;
  onDone: () => void;
}

function DispositionsView({
  callDispositions,
  callDuration,
  setOpenNotesModal,
  addDispositions,
  removeDispositions,
  onDone,
}: IDispositionsViewProps) {
  return (
    <DispositionsContainer>
      <DispositionsHeader callDuration={callDuration} />
      <CustomScrollbars
        autoHeight={true}
        autoHide={true}
        autoHeightMax={400}
        autoHeightMin={10}
      >
        <DispositionsBody>
          <TitleDivider localeKey="communication.cc.outcomes" />
          {callDispositions.items?.map((item, i) => (
            <DispositionSelector
              remove={() => removeDispositions({ disposition: item.id })}
              add={() => addDispositions({ disposition: item.id })}
              key={item.id}
            >
              <span>{item.name}</span>
            </DispositionSelector>
          ))}
        </DispositionsBody>
      </CustomScrollbars>

      <DispositionsFooter>
        <Button onClick={() => setOpenNotesModal(true)}>
          <K2Message localeKey="communication.cc.addNotes" />
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onDone();
          }}
        >
          <K2Message localeKey="common.done" />
        </Button>
      </DispositionsFooter>
    </DispositionsContainer>
  );
}

export default DispositionsView;
