import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const AnalyticsGraph: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <circle cx="35" cy="28" r="24" fill="white" />
    </g>
    <path
      d="M42.4749 20.5251C43.8417 21.892 43.8417 24.108 42.4749 25.4749C41.108 26.8417 38.892 26.8417 37.5251 25.4749C36.1583 24.108 36.1583 21.892 37.5251 20.5251C38.892 19.1583 41.108 19.1583 42.4749 20.5251"
      stroke={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M26.5 36.5L28.7 33.417V33.417C28.8605 33.1923 29.1728 33.1403 29.3975 33.3008C29.4003 33.3028 29.4032 33.3049 29.406 33.307L30.6 34.2H30.6C30.8209 34.3657 31.1343 34.3209 31.3 34.1L34 30.5"
      stroke={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M40.751 19.5811L40 23.0001L43.474 22.5751"
      stroke={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M38 36.5C37.7239 36.5 37.5 36.2761 37.5 36V30C37.5 29.7239 37.7239 29.5 38 29.5H43C43.2761 29.5 43.5 29.7239 43.5 30V36C43.5 36.2761 43.2761 36.5 43 36.5H38Z"
      stroke={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M39.5 32.5H41.5"
      stroke={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M39.5 34.5H40.5"
      stroke={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M27 26.5C26.7239 26.5 26.5 26.2761 26.5 26V20C26.5 19.7239 26.7239 19.5 27 19.5H32C32.2761 19.5 32.5 19.7239 32.5 20V26C32.5 26.2761 32.2761 26.5 32 26.5H27Z"
      stroke={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M28.5 21.5H30.5"
      stroke={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M28.5 23.5H29.5"
      stroke={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M24.5 39.5C23.9477 39.5 23.5 39.0523 23.5 38.5V17.5C23.5 16.9477 23.9477 16.5 24.5 16.5H45.5C46.0523 16.5 46.5 16.9477 46.5 17.5V38.5C46.5 39.0523 46.0523 39.5 45.5 39.5H24.5Z"
      stroke={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0.333334"
        y="0"
        width="69.3333"
        height="69.3333"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="6.66667" />
        <feGaussianBlur stdDeviation="5.33333" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.658824 0 0 0 0 0.666667 0 0 0 0 0.74902 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
