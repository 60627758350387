import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const ResumeCall: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
      fill={fill}
    />
    <path
      d="M8 8.5C8 7.94772 8.44772 7.5 9 7.5C9.55228 7.5 10 7.94772 10 8.5V15.5C10 16.0523 9.55228 16.5 9 16.5C8.44772 16.5 8 16.0523 8 15.5V8.5Z"
      fill={fill}
    />
    <path
      d="M17.5625 11.134C18.1458 11.5189 18.1458 12.4811 17.5625 12.866L12.3125 16.3301C11.7292 16.715 11 16.2339 11 15.4641V8.53592C11 7.76612 11.7292 7.28499 12.3125 7.66989L17.5625 11.134Z"
      fill={fill}
    />
  </svg>
);
