import React, { Component } from "react";

import { UserReq, UserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { Alert, Col, Input, Row, Skeleton, Spin } from "antd";
import { Tag } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import styled from "styled-components";

import { userPreferencesLookups } from "appRedux/models/lookups/LookupsModel";
import LocalizationFormFields from "components/admin/shared/LocalizationFormFields";
import { GeneralFormItemV4 } from "components/formItems";
import { ActionFormItemsContainer } from "components/formItems/ActionsFormItemV4";
import { K2Button, K2Message, K2RouteHeading } from "components/k2Widgets";
import { FormsWrapper, MainFormsWrapper } from "components/layout/wrappers";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import { ILabelWrapperLayout } from "constants/FormLayouts";
import ImageUpload from "routes/admin/userCreateEdit/components/UserImageUpload";
import { PreferencesStateProps } from "routes/preferences/modules/types";

const NameAndRoles = styled.div`
  .roles-list {
    display: flex;
    flex-wrap: wrap;
    .ant-tag {
      margin-right: 8px;
      margin-bottom: 12px;
    }
  }
`;

const layout: ILabelWrapperLayout = {
  wrapperCol: {
    xs: 24,
    md: 12,
    lg: 10,
  },
  labelCol: {
    xs: 24,
    md: 12,
    lg: 10,
  },
};

interface PreferencesViewProps extends PreferencesStateProps {
  handleSubmit: (form: FormInstance) => void;
  form: FormInstance;
  saving: boolean;
  overallFormErrors: string[];
  saveDisabled: boolean;
  dirty: boolean;
  setDirty: (b: boolean) => void;
  resetPasswordUrl: string;
}

class PreferencesView extends Component<PreferencesViewProps> {
  render() {
    const {
      loading,
      currentUser,
      k2Intl,
      form,
      handleSubmit,
      subscriptions,
      saving,
      overallFormErrors,
      saveDisabled,
      dirty,
      setDirty,
      resetPasswordUrl,
      ...localizationLookups
    } = this.props;

    const { firstName, lastName, id, ...localizationFields } = currentUser;

    const requiredLookupsAvailable = () => {
      let available = true;
      userPreferencesLookups.forEach((lookup) => {
        if (localizationLookups[lookup].items.length < 1) available = false;
      });
      return available;
    };

    const lookupsLoaded = requiredLookupsAvailable();

    return (
      <DirtyDataGuard dirty={dirty}>
        <FormsWrapper>
          <K2RouteHeading headingKey={"admin.userSettings"} />
          <MainFormsWrapper style={{ padding: "0 10px" }}>
            <Skeleton active loading={loading || !lookupsLoaded}>
              <Col span={24}>
                <Spin spinning={saving}>
                  <Form
                    onValuesChange={(values) => {
                      // Updating profile picture does not make the form dirty
                      if (
                        Object.keys(values).length > 1 ||
                        !Object.keys(values).includes(
                          "storageToken",
                        )
                      ) {
                        setDirty(true);
                      }
                    }}
                    initialValues={{
                      ["id"]: id,
                      ["numberFormat"]: currentUser.localization?.numberFormat,
                      ["timeFormat"]: currentUser.localization?.timeFormat,
                      ["timezone"]: currentUser.localization?.timezone,
                      ["dateFormat"]: currentUser.localization?.dateFormat,
                      ["language"]: currentUser.localization?.language,
                      ["jobTitle"]:
                        currentUser.jobTitle || "",
                    }}
                    form={form}
                    onFinish={() => {
                      handleSubmit(form);
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <NameAndRoles>
                          <h3>{currentUser.fullName}</h3>
                          <div className={"roles-list"}>
                            {currentUser.admin && (
                              <Tag color="#f50">
                                <K2Message
                                  localeKey={"userPicker.Administrator"}
                                />
                              </Tag>
                            )}
                            {currentUser.roleProfiles?.map?.((r) => {
                              return (
                                <Tag key={r.principalId} color={r.color}>
                                  {r.name}
                                </Tag>
                              );
                            })}
                          </div>
                        </NameAndRoles>

                        <GeneralFormItemV4
                          shouldUpdate
                          formItemLayout={layout}
                          name={"storageToken"}
                        >
                          <div style={{ marginBottom: 20, marginTop: 20 }}>
                            <ImageUpload
                              currentUser={currentUser}
                              form={form}
                              currentUserPage
                            />
                          </div>
                        </GeneralFormItemV4>

                        <GeneralFormItemV4
                          {...layout}
                          labelKey="admin.jobTitle"
                          name={"jobTitle"}
                        >
                          <Input />
                        </GeneralFormItemV4>

                        <LocalizationFormFields
                          fieldsLayout={layout}
                          form={form}
                          {...localizationLookups}
                          {...localizationFields}
                          areFieldsRequired={false}
                        />
                      </Col>
                    </Row>
                    {overallFormErrors.length > 0 && (
                      <Row style={{ marginTop: 20 }}>
                        <Alert
                          showIcon
                          type="error"
                          message={overallFormErrors?.map?.((e, i) => (
                            <p key={i}>{e}</p>
                          ))}
                        />
                      </Row>
                    )}
                    <Row style={{ marginTop: 20 }}>
                      <Col span={24}>
                        <ActionFormItemsContainer align={"left"}>
                          {resetPasswordUrl && (
                            <a
                              href={resetPasswordUrl}
                              target={"_blank"}
                              rel="noopener noreferrer"
                            >
                              <K2Button loading={loading} danger>
                                <K2Message
                                  localeKey={"admin.preferences.changePassword"}
                                />
                              </K2Button>
                            </a>
                          )}
                          <K2Button
                            loading={loading}
                            htmlType={"submit"}
                            type={"primary"}
                            disabled={saveDisabled}
                          >
                            <K2Message localeKey={"common.save"} />
                          </K2Button>
                        </ActionFormItemsContainer>
                      </Col>
                    </Row>
                  </Form>
                </Spin>
              </Col>
            </Skeleton>
          </MainFormsWrapper>
        </FormsWrapper>
      </DirtyDataGuard>
    );
  }
}

export default PreferencesView;
