import React, { FC } from "react";
import { K2Message } from "components/k2Widgets";
import { InboxOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";

const InboxOutlinedWithThemeColour = styled(InboxOutlined)`
  && {
  color: ${({ theme }) => theme.primaryColor};
  font-size: 52px;
  margin-b24px;
  }
`;

const MultiFileUploadExplanation: FC = () => {
  const { theme } = useAppContext();

  return (
    <>
      <InboxOutlinedWithThemeColour />

      <p className="ant-upload-text">
        <K2Message localeKey="common.uploadMessage" />
      </p>
      <p style={{ color: theme.grey_7 }}>
        <K2Message localeKey="common.multipleUploadMessage" />
      </p>
    </>
  );
};

export default MultiFileUploadExplanation;
