import { None, UserReq } from "@n3oltd/k2.users.sdk.users/esm";
import { UserRes } from "@n3oltd/k2.users.sdk.users/src/index";
import { all, put, takeLatest } from "redux-saga/effects";

import { _usersClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { DataActions, EditStatus } from "appRedux/modules/sharedTypes";
import { usersActions } from "appRedux/modules/subscription";

import { ActionTypes as RoleActionTypes } from "../../rolesList/modules/actionTypes";
import { fetchRoles } from "../../rolesList/modules/sagas";
import {
  ActionTypes,
  ActivateUser,
  CreateUser,
  DeactivateUser,
  FetchSelectedEditUser,
  UpdateAdminPrivilege,
  UpdateSelectedEditUser,
} from "./actionTypes";
import * as actions from "./actions";

function* fetchSelectedUser(action: FetchSelectedEditUser) {
  const response: IApiResponse<UserRes> = yield K2RestService.toResponse(
    _usersClient.getUserById(action.id),
  );

  if (response.error) {
    yield put(actions.fetchSelectedEditUserError(response.error));
  } else {
    yield put(
      actions.fetchSelectedEditUserSuccess(response.getResultOrDefault()),
    );
    if (action.shouldUpdateGlobalUser) {
      yield put(usersActions.setCurrentUser(response));
      yield put(usersActions.setCurrentUserLocalizationSettings());
    }
  }
}

function* updateSelectedUser(action: UpdateSelectedEditUser) {
  const userReq: UserReq = action.user;
  const response: IApiResponse<None> = yield K2RestService.toResponse(
    _usersClient.updateUser(action.revisionId, userReq),
  );

  const editStatus: EditStatus<None> = {
    action: DataActions.update,
    ...response,
  };

  if (response.error) {
    yield put(actions.updateSelectedEditUserError(editStatus));
  } else {
    yield put(actions.updateSelectedEditUserSuccess(editStatus));
    yield put(actions.fetchSelectedEditUser(action.id, action.isCurrentUser));
  }
}

function* createUser(action: CreateUser) {
  const response: IApiResponse<UserRes> = yield K2RestService.toResponse(
    _usersClient.createUser(action.user),
  );

  const editStatus: EditStatus<UserRes> = {
    action: DataActions.add,
    ...response,
  };

  if (response.error) {
    yield put(actions.createUserError(editStatus));
  } else {
    yield put(
      actions.createUserSuccess(editStatus, response.getResultOrDefault()),
    );
  }
}

function* activateSubscriptionUser(action: ActivateUser) {
  const response: IApiResponse<any> = yield K2RestService.toResponse(
    _usersClient.activateUser(action.revisionId),
  );
  const editStatus: EditStatus = {
    action: DataActions.save,
    ...response,
  };

  if (response.error) {
    yield put(actions.activateUserError(editStatus));
  } else {
    yield put(actions.activateUserSuccess(editStatus));
  }
}

function* deactivateSubscriptionUser(action: DeactivateUser) {
  const response: IApiResponse<any> = yield K2RestService.toResponse(
    _usersClient.deactivateUser(action.revisionId),
  );
  const editStatus: EditStatus = {
    action: DataActions.save,
    ...response,
  };

  if (response.error) {
    yield put(actions.deactivateUserError(editStatus));
  } else {
    yield put(actions.deactivateUserSuccess(editStatus));
  }
}

function* updateAdminPrivilege(action: UpdateAdminPrivilege) {
  let response;
  if (action.admin) {
    response = yield K2RestService.toResponse(
      _usersClient.makeAdmin(action.revisionId),
    );
  } else {
    response = yield K2RestService.toResponse(
      _usersClient.removeAsAdmin(action.revisionId),
    );
  }

  const editStatus: EditStatus = {
    action: DataActions.save,
    ...response,
  };

  if (response.error) {
    yield put(actions.updateAdminPrivilegeError(editStatus));
  } else {
    yield put(actions.updateAdminPrivilegeSuccess(editStatus, action.admin));
  }
}

export default function* createEditUserSaga() {
  yield all([
    yield takeLatest(ActionTypes.FETCH_SELECTED_EDIT_USER, fetchSelectedUser),
    yield takeLatest(ActionTypes.UPDATE_SELECTED_EDIT_USER, updateSelectedUser),
    yield takeLatest(ActionTypes.CREATE_USER, createUser),
    yield takeLatest(ActionTypes.ACTIVATE_USER, activateSubscriptionUser),
    yield takeLatest(ActionTypes.DEACTIVATE_USER, deactivateSubscriptionUser),
    yield takeLatest(RoleActionTypes.FETCH_ROLES, fetchRoles),
    yield takeLatest(ActionTypes.UPDATE_ADMIN_PRIVILEGE, updateAdminPrivilege),
  ]);
}
