import { AgentRes } from "@n3oltd/k2.calls.sdk.agents/src/index";
import { all, put, takeLatest } from "redux-saga/effects";

import { _callsAgentsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { DataActions } from "appRedux/modules/sharedTypes";

import {
  ActionTypes,
  AgentResumeShift,
  AgentSignIn,
  AgentSignOut,
  AgentTakeBreak,
  AgentUpdateTeamAndCampaign,
  FetchAgent,
} from "./actionTypes";
import {
  agentResumeShiftError,
  agentResumeShiftSuccess,
  agentSignInError,
  agentSignInSuccess,
  agentSignOutError,
  agentSignOutSuccess,
  agentTakeBreakError,
  agentTakeBreakSuccess,
  agentUpdateTeamAndCampaignError,
  agentUpdateTeamAndCampaignSuccess,
  fetchAgentError,
  fetchAgentSuccess,
} from "./actions";

function* fetchCurrentAgent(action: FetchAgent) {
  const resp: IApiResponse<AgentRes> = yield K2RestService.toResponse(
    _callsAgentsClient.getCurrent(),
  );

  if (resp.error) {
    yield put(fetchAgentError(resp.error));
  } else {
    yield put(fetchAgentSuccess(resp.getResultOrDefault()));
  }
}

function* agentSignIn(action: AgentSignIn) {
  const resp: IApiResponse<void> = yield K2RestService.toResponse(
    _callsAgentsClient.signIn(action.agentId, action.request),
  );

  if (resp.error) {
    yield put(agentSignInError(resp.error));
  } else {
    const agentResp: IApiResponse<AgentRes> = yield K2RestService.toResponse(
      _callsAgentsClient.getCurrent(),
    );

    if (agentResp.error) {
      yield put(agentSignInError(agentResp.error));
    } else {
      yield put(
        agentSignInSuccess(agentResp.getResultOrDefault(), {
          action: DataActions.statusUpdate,
        }),
      );
    }
  }
}

function* agentSignOut(action: AgentSignOut) {
  const resp: IApiResponse<void> = yield K2RestService.toResponse(
    _callsAgentsClient.signOut(action.agentId),
  );

  if (resp.error) {
    yield put(agentSignOutError(resp.error));
  } else {
    const agentResp: IApiResponse<AgentRes> = yield K2RestService.toResponse(
      _callsAgentsClient.getCurrent(),
    );

    if (agentResp.error) {
      yield put(agentSignOutError(agentResp.error));
    } else {
      yield put(
        agentSignOutSuccess(agentResp.getResultOrDefault(), {
          action: DataActions.statusUpdate,
        }),
      );
    }
  }
}

function* agentUpdateTeamAndCampaign(action: AgentUpdateTeamAndCampaign) {
  const resp1: IApiResponse<void> = yield K2RestService.toResponse(
    _callsAgentsClient.setCampaign(action.agentId, {
      id: action.campaignId,
    }),
  );

  if (resp1.error) {
    yield put(agentUpdateTeamAndCampaignError(resp1.error));
    return;
  } else {
    const resp2: IApiResponse<void> = yield K2RestService.toResponse(
      _callsAgentsClient.setTeam(action.agentId, {
        id: action.teamId,
      }),
    );

    if (resp2.error) {
      yield put(agentUpdateTeamAndCampaignError(resp1.error));
      return;
    } else {
      const agentResp: IApiResponse<AgentRes> = yield K2RestService.toResponse(
        _callsAgentsClient.getCurrent(),
      );

      if (!agentResp.error) {
        yield put(
          agentUpdateTeamAndCampaignSuccess(agentResp.getResultOrDefault()),
        );
      } else {
        yield put(agentUpdateTeamAndCampaignError(resp1.error));
      }
    }
  }
}

function* takeBreak(action: AgentTakeBreak) {
  const resp1: IApiResponse<void> = yield K2RestService.toResponse(
    _callsAgentsClient.takeBreak(action.agentId),
  );

  if (resp1.error) {
    yield put(agentTakeBreakError(resp1.error));
    return;
  } else {
    const agentResp: IApiResponse<AgentRes> = yield K2RestService.toResponse(
      _callsAgentsClient.getCurrent(),
    );

    if (!agentResp.error) {
      yield put(
        agentTakeBreakSuccess(agentResp.getResultOrDefault(), {
          action: DataActions.statusUpdate,
        }),
      );
    } else {
      yield put(agentTakeBreakError(resp1.error));
    }
  }
}

function* resumeShift(action: AgentResumeShift) {
  const resp1: IApiResponse<void> = yield K2RestService.toResponse(
    _callsAgentsClient.resumeShift(action.agentId),
  );

  if (resp1.error) {
    yield put(agentResumeShiftError(resp1.error));
    return;
  } else {
    const agentResp: IApiResponse<AgentRes> = yield K2RestService.toResponse(
      _callsAgentsClient.getCurrent(),
    );

    if (!agentResp.error) {
      yield put(
        agentResumeShiftSuccess(agentResp.getResultOrDefault(), {
          action: DataActions.statusUpdate,
        }),
      );
    } else {
      yield put(agentResumeShiftError(resp1.error));
    }
  }
}

export default function* watcherSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_AGENT, fetchCurrentAgent),
    takeLatest(ActionTypes.AGENT_SIGN_IN, agentSignIn),
    takeLatest(ActionTypes.AGENT_SIGN_OUT, agentSignOut),
    takeLatest(
      ActionTypes.AGENT_UPDATE_TEAM_AND_CAMPAIGN,
      agentUpdateTeamAndCampaign,
    ),
    takeLatest(ActionTypes.AGENT_TAKE_BREAK, takeBreak),
    takeLatest(ActionTypes.AGENT_RESUME_SHIFT, resumeShift),
  ]);
}
