import produce from "immer";
import { EmailTemplateState } from "./types";
import { ActionTypes, EmailTemplateAction } from "./actionTypes";

const initialState: EmailTemplateState = {
  data: null,
  editStatus: null,
  error: null,
  loading: false,
  saving: false,
  temporaryAttachmentUploadFiles: [],
  temporaryMediaUploadFiles: [],
  dirty: false,
};

const emailTemplatesReducer = (
  state = initialState,
  action: EmailTemplateAction,
) =>
  produce(state, (draft: EmailTemplateState) => {
    switch (action.type) {
      case ActionTypes.FETCH_EMAIL_TEMPLATE:
        draft.loading = true;
        break;
      case ActionTypes.FETCH_EMAIL_TEMPLATE_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case ActionTypes.FETCH_EMAIL_TEMPLATE_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        break;
      case ActionTypes.CLEAR_EMAIL_TEMPLATE:
        return { ...initialState };
      case ActionTypes.CLEAR_EMAIL_TEMPLATE_EDIT_STATUS:
        draft.editStatus = null;
        break;
      case ActionTypes.CLEAR_EMAIL_TEMPLATE_ERROR:
        draft.error = null;
        break;

      case ActionTypes.CREATE_EMAIL_TEMPLATE:
        draft.saving = true;
        break;
      case ActionTypes.CREATE_EMAIL_TEMPLATE_ERROR:
      case ActionTypes.CREATE_EMAIL_TEMPLATE_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.UPDATE_EMAIL_TEMPLATE:
        draft.saving = true;
        break;
      case ActionTypes.UPDATE_EMAIL_TEMPLATE_ERROR:
      case ActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.DELETE_EMAIL_TEMPLATE:
        draft.saving = true;
        break;
      case ActionTypes.DELETE_EMAIL_TEMPLATE_ERROR:
      case ActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.UPDATE_TEMPORARY_ASSETS:
        switch (action.assetType) {
          case "attachment":
            if (
              draft.temporaryAttachmentUploadFiles.find(
                (u) => u.uid === action.upload.uid,
              )
            ) {
              draft.temporaryAttachmentUploadFiles = draft.temporaryAttachmentUploadFiles?.map?.(
                (u) => {
                  if (u.uid === action.upload.uid) return action.upload;
                  else return u;
                },
              );
            } else {
              draft.temporaryAttachmentUploadFiles = [
                ...draft.temporaryAttachmentUploadFiles,
                action.upload,
              ];
            }
            break;
          case "media":
            if (
              draft.temporaryMediaUploadFiles.find(
                (u) => u.uid === action.upload.uid,
              )
            ) {
              draft.temporaryMediaUploadFiles = draft.temporaryMediaUploadFiles?.map?.(
                (u) => {
                  if (u.uid === action.upload.uid) return action.upload;
                  else return u;
                },
              );
            } else {
              draft.temporaryMediaUploadFiles = [
                ...draft.temporaryMediaUploadFiles,
                action.upload,
              ];
            }
            break;
        }
        break;
      case ActionTypes.REMOVE_TEMPORARY_ASSET:
        switch (action.assetType) {
          case "attachment":
            draft.temporaryAttachmentUploadFiles = draft.temporaryAttachmentUploadFiles.filter(
              (f) => f.uid !== action.temporaryAsset.uid,
            );
            break;
          case "media":
            draft.temporaryMediaUploadFiles = draft.temporaryMediaUploadFiles.filter(
              (f) => f.uid !== action.temporaryAsset.uid,
            );
            break;
        }
        break;
      case ActionTypes.CLEAR_TEMPORARY_ASSETS:
        switch (action.assetType) {
          case "attachment":
            draft.temporaryAttachmentUploadFiles = [];
            break;
          case "media":
            draft.temporaryMediaUploadFiles = [];
            break;
        }
        break;

      case ActionTypes.UPDATE_DIRTY:
        draft.dirty = action.dirty;
        break;
    }
  });

export default emailTemplatesReducer;
