import React, { PureComponent } from "react";

import { Col, Row } from "antd";

import K2Service from "appRedux/models/base/K2RestService";

interface IProps {}

class ErrorsList extends PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  generateError(code: number) {
    // TODO: Is this working?
    let errorPromise = new Promise(function (resolve, reject) {
      fetch("https://beta.n3o.cloud/debug/problem-details?code=" + code, {
        method: "GET",
      }).then((res) => {
        reject(res);
      });
    });
    K2Service.toResponse(errorPromise);
  }

  generateException(type: any) {
    if (type === "access") {
      try {
        //Exception will be generated here...
        console.log(type.someProperty.unknownProperty);
      } catch (exp) {
        //Catching exception and logging it...
        console.error(exp);
      }
    }
  }

  generateUncaughtException(type: any) {
    console.log(type.someProperty.unknownProperty);
  }

  render() {
    return (
      <div>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <h2>API Errors Testing</h2>
            <label>Generate Error: </label>
            <input
              type="button"
              onClick={this.generateError.bind(this, 401)}
              value="401"
            />
          </Col>

          <Col span={24}>
            <label>Generate Error: </label>
            <input
              type="button"
              onClick={this.generateError.bind(this, 404)}
              value="404"
            />
          </Col>

          <Col span={24}>
            <label>Generate Error: </label>
            <input
              type="button"
              onClick={this.generateError.bind(this, 500)}
              value="500"
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "30px" }} gutter={[24, 24]}>
          <Col span={24}>
            <h2>Client Side Exceptions Testing</h2>
            <label>Generate, Catch Exception & Log to Azure: </label>
            <input
              type="button"
              onClick={this.generateException.bind(this, "access")}
              value="Access an Unknown Property"
            />
          </Col>

          <Col span={24}>
            <label>Generate Uncaught Exception & Log to Sentry: </label>
            <input
              type="button"
              onClick={this.generateUncaughtException.bind(this)}
              value="Access an Unknown Property"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ErrorsList;
