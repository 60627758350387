import React, { FC } from "react";

import { Alert } from "antd";
import styled from "styled-components";

import { _credentialsClient } from "appRedux/models/base/K2RestClients";
import { K2Message } from "components/k2Widgets";

interface IK2DocumentViewerProps {
  src: string;
}

const IframeContainer = styled.div`
  position: relative;

  &:after {
    content: "";
    padding-bottom: 141.4%;
    display: block;
  }

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const K2DocumentViewer: FC<IK2DocumentViewerProps> = (props) => {
  const { src } = props;

  return (
    <IframeContainer>
      <iframe title="PDF" src={src} frameBorder="0">
        <Alert
          type="info"
          message={
            <>
              <K2Message localeKey="reports.pdfNotSupported" />{" "}
              <a href={src} download>
                <K2Message localeKey="common.downloadHere" />
              </a>
            </>
          }
          showIcon
        />
      </iframe>
    </IframeContainer>
  );
};

export default K2DocumentViewer;
