import {
  ReportingPeriodCriteria,
  ReportingPeriodReq,
  ReportingPeriodRes,
  ReportingPeriodSummaryResultsPage,
} from "@n3oltd/karakoram.analytics.sdk.attribution/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

export enum ActionTypes {
  FIND_REPORTING_PERIODS = "@engage/admin/reporting-periods/request",
  FIND_REPORTING_PERIODS_SUCCESS = "@engage/admin/reporting-periods/success",
  FIND_REPORTING_PERIODS_ERROR = "@engage/admin/reporting-periods/error",
  CLEAR_REPORTING_PERIODS_ERROR = "@engage/admin/reporting-periods/clear-error",

  CREATE_REPORTING_PERIOD = "@engage/admin/reporting-periods/create/request",
  CREATE_REPORTING_PERIOD_SUCCESS = "@engage/admin/reporting-periods/create/success",
  CREATE_REPORTING_PERIOD_ERROR = "@engage/admin/reporting-periods/create/error",
  CLEAR_REPORTING_PERIODS_EDIT_STATUS = "@engage/admin/reporting-periods/clear-edit-status",

  SET_SELECTED_REPORTING_PERIOD = "@engage/admin/reporting-periods/set-selected",

  UPDATE_REPORTING_PERIOD = "@engage/admin/reporting-periods/update/request",
  UPDATE_REPORTING_PERIOD_SUCCESS = "@engage/admin/reporting-periods/update/success",
  UPDATE_REPORTING_PERIOD_ERROR = "@engage/admin/reporting-periods/update/error",
  CLEAR_SELECTED_REPORTING_PERIODS_EDIT_STATUS = "@engage/admin/reporting-periods/clear-selected-edit-status",

  UPDATE_SORT_ORDER = "@engage/admin/reporting-periods/sort-order/request",
  UPDATE_SORT_ORDER_SUCCESS = "@engage/admin/reporting-periods/sort-order/success",
  UPDATE_SORT_ORDER_ERROR = "@engage/admin/reporting-periods/sort-order/error",

  DELETE_REPORTING_PERIOD = "@engage/admin/reporting-periods/delete/request",
  DELETE_REPORTING_PERIOD_SUCCESS = "@engage/admin/reporting-periods/delete/success",
  DELETE_REPORTING_PERIOD_ERROR = "@engage/admin/reporting-periods/delete/error",
}

export interface FindReportingPeriods {
  type: ActionTypes.FIND_REPORTING_PERIODS;
  criteria: ReportingPeriodCriteria;
}

export interface FindReportingPeriodsSuccess {
  type: ActionTypes.FIND_REPORTING_PERIODS_SUCCESS;
  data: ReportingPeriodSummaryResultsPage;
  shouldAppendToList: boolean;
}

export interface FindReportingPeriodsError {
  type: ActionTypes.FIND_REPORTING_PERIODS_ERROR;
  error: ServerError;
}

export interface ClearReportingPeriodsError {
  type: ActionTypes.CLEAR_REPORTING_PERIODS_ERROR;
}

export interface CreateReportingPeriod {
  type: ActionTypes.CREATE_REPORTING_PERIOD;
  data: ReportingPeriodReq;
}

export interface CreateReportingPeriodSuccess {
  type: ActionTypes.CREATE_REPORTING_PERIOD_SUCCESS;
  data: ReportingPeriodRes;
  editStatus: EditStatus;
}

export interface CreateReportingPeriodError {
  type: ActionTypes.CREATE_REPORTING_PERIOD_ERROR;
  editStatus: EditStatus;
}

export interface ClearEditStatus {
  type: ActionTypes.CLEAR_REPORTING_PERIODS_EDIT_STATUS;
}

export interface SetSelectedReportingPeriod {
  type: ActionTypes.SET_SELECTED_REPORTING_PERIOD;
  selectedReportingPeriod?: ReportingPeriodRes;
}

export interface UpdateReportingPeriod {
  type: ActionTypes.UPDATE_REPORTING_PERIOD;
  revisionId: string;
  data: ReportingPeriodReq;
}

export interface UpdateReportingPeriodSuccess {
  type: ActionTypes.UPDATE_REPORTING_PERIOD_SUCCESS;
  editStatus: EditStatus<void>;
}

export interface UpdateReportingPeriodError {
  type: ActionTypes.UPDATE_REPORTING_PERIOD_ERROR;
  editStatus: EditStatus<void>;
}

export interface ClearSelectedEditStatus {
  type: ActionTypes.CLEAR_SELECTED_REPORTING_PERIODS_EDIT_STATUS;
}

export interface UpdateSortOrder {
  type: ActionTypes.UPDATE_SORT_ORDER;
  revisionId: string;
  newOrder: number;
}

export interface UpdateSortOrderSuccess {
  type: ActionTypes.UPDATE_SORT_ORDER_SUCCESS;
  editStatus: EditStatus<void>;
}

export interface UpdateSortOrderError {
  type: ActionTypes.UPDATE_SORT_ORDER_ERROR;
  editStatus: EditStatus<void>;
}

export interface DeleteReportingPeriod {
  type: ActionTypes.DELETE_REPORTING_PERIOD;
  revisionId: string;
  id: string;
}

export interface DeleteReportingPeriodSuccess {
  type: ActionTypes.DELETE_REPORTING_PERIOD_SUCCESS;
  editStatus: EditStatus<void>;
  id: string;
}

export interface DeleteReportingPeriodError {
  type: ActionTypes.DELETE_REPORTING_PERIOD_ERROR;
  editStatus: EditStatus<void>;
}

export type ReportingPeriodAction =
  | FindReportingPeriods
  | FindReportingPeriodsError
  | FindReportingPeriodsSuccess
  | ClearReportingPeriodsError
  | CreateReportingPeriod
  | CreateReportingPeriodError
  | CreateReportingPeriodSuccess
  | ClearEditStatus
  | SetSelectedReportingPeriod
  | UpdateReportingPeriod
  | UpdateReportingPeriodError
  | UpdateReportingPeriodSuccess
  | ClearSelectedEditStatus
  | UpdateSortOrder
  | UpdateSortOrderError
  | UpdateSortOrderSuccess
  | DeleteReportingPeriod
  | DeleteReportingPeriodSuccess
  | DeleteReportingPeriodError;
