import styled from "styled-components";
import Dragger from "antd/es/upload/Dragger";

const K2Dragger = styled(Dragger)`
  && {
    &:hover {
      border-color: ${({ theme }) => theme.button_hover_color} !important;
    }
  }
`;

export default K2Dragger;
