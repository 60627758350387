import React, { FC, useEffect, useMemo, useState } from "react";

import Nprogress from "nprogress";
import { DispatchProp, connect } from "react-redux";
import { Redirect } from "react-router";

import AuthManager from "appRedux/models/auth/AuthManager";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { Q1RouteKeys, Q1Routes } from "appRedux/models/routes/Q1RouteModel";
import FullPageSpin from "components/fullPageSpin";
import AuthSelectors, {
  IRequireAuthStateProps,
} from "routes/Q1/appRedux/selectors/AuthSelectors";

interface IProps extends IRequireAuthStateProps, DispatchProp<any> {}

export default function (ComposedComponent: any, routeKey: Q1RouteKeys) {
  const Authentication: FC<IProps> = (props) => {
    const {
      permittedRouteKeys,
      authenticated,
      hasTallyPermission,
      ...componentProps
    } = props;

    const [cancelled, setCancelled] = useState<boolean>(false);

    useEffect(() => {
      return () => {
        setCancelled(true);
      };
    }, []);

    const isAuthorized = useMemo(() => {
      return permittedRouteKeys?.includes(routeKey);
    }, [permittedRouteKeys]);

    useEffect(() => {
      const authenticateUser = async () => {
        await AuthManager.authenticate();
      };

      if (!cancelled) {
        authenticateUser();
      }
    }, [cancelled]);

    useEffect(() => {
      if (!cancelled) {
        if (!authenticated) {
          Nprogress.start();
        } else {
          Nprogress.done();
        }
      }

      return () => {
        Nprogress.done();
      };
    }, [cancelled, authenticated]);

    if (authenticated) {
      if (isAuthorized) {
        return <ComposedComponent {...componentProps} />;
      } else if (hasTallyPermission) {
        return <Redirect to={Q1Routes.errors._401} />;
      } else {
        return <Redirect to={K2Routes.main.dashboard} />;
      }
    } else {
      return <FullPageSpin />;
    }
  };

  return connect(AuthSelectors.getRequireAuthSelector(), null)(Authentication);
}
