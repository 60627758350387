import React from "react";

import { Redirect, Route, Switch } from "react-router";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import { asyncComponent } from "components/utils";
import { RequireAuth } from "containers/app/views";

const {
  recordAbandonedReasons,
  recordOnHoldReasons,
  viewWebhooks,
  createWebhook,
  editWebhook,
  customForms,
  editCustomForms,
} = K2Routes;

const RecordAbandonedReasons = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "RecordAbandonedReasonsAdmin" */ "./recordAbandonedReasons"
      ),
  ),
  "recordAbandonedReasons",
);

const RecordOnHoldReasons = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "RecordOnHoldReasonsAdmin" */ "./recordOnHoldReasons"
      ),
  ),
  "recordOnHoldReasons",
);
const WebHooks = RequireAuth(
  asyncComponent(() => import(/* webpackChunkName: "webHooks" */ "./webHooks")),
  "viewWebhooks",
);

const AddEditWebhook = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "webHooksCreateEdit" */ "./webHooksCreateEdit"
      ),
  ),
  "createWebhook",
);

const CreateEditForms = asyncComponent(
  () => import(/* webpackChunkName: "CustomFormsAdmin" */ "./createEditForms"),
);

const CreateEditFormFeilds = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "CreateEditFormFeilds" */ "./createEditFormFields"
    ),
);

const DataSubRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path={recordAbandonedReasons}
        component={RecordAbandonedReasons}
      />
      <Route exact path={recordOnHoldReasons} component={RecordOnHoldReasons} />
      <Route exact path={viewWebhooks} component={WebHooks} />
      <Route exact path={createWebhook} component={AddEditWebhook} />
      <Route exact path={editWebhook} component={AddEditWebhook} />
      <Route exact path={customForms} component={CreateEditForms} />
      <Route exact path={editCustomForms} component={CreateEditFormFeilds} />
      <Redirect to={recordAbandonedReasons} />
    </Switch>
  );
};

export default DataSubRouter;
