import styled from "styled-components";
import { Tabs } from "antd";

const K2Tabs = styled(Tabs)`
  && {
    .ant-tabs-nav .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.primaryColor};
      }
      path {
        fill: ${({ theme }) => theme.primaryColor} !important;
      }
      color: ${({ theme }) => theme.primaryColor};
    }
    .ant-tabs-ink-bar {
      background-color: ${({ theme }) => theme.primaryColor};
    }

    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab:hover,
    .ant-tabs-tab:active,
    .ant-tabs-tab:focus {
      color: ${({ theme }) => theme.primary_5};
    }
  }
`;

export default K2Tabs;
