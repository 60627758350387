import { UserStatus } from "@n3oltd/k2.users.sdk.users";
import produce from "immer";

import {
  FetchRoles,
  FetchRolesSuccess,
  ActionTypes as RoleActionTypes,
} from "routes/admin/rolesList/modules/actionTypes";

import { ActionTypes, CreateEditUserAction } from "./actionTypes";
import { CreateEditUserState } from "./types";

const initialState: CreateEditUserState = {
  loading: false,
  saving: false,
  serverError: null,
  editStatus: null,
  user: null,
  allSubscriptionRoles: [],
  allSubscriptionRolesLoading: false,
};

const createEditUserReducer = (
  state = initialState,
  action: CreateEditUserAction | FetchRoles | FetchRolesSuccess,
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.FETCH_SELECTED_EDIT_USER:
        draft.loading = true;
        break;
      case ActionTypes.FETCH_SELECTED_EDIT_USER_SUCCESS:
        draft.loading = false;
        draft.serverError = null;
        draft.user = action.user;
        break;
      case ActionTypes.FETCH_SELECTED_EDIT_USER_ERROR:
        draft.loading = false;
        draft.serverError = action.error;
        break;
      case ActionTypes.CLEAR_FETCH_SELECTED_EDIT_USER_ERROR:
        draft.serverError = null;
        break;
      case ActionTypes.CLEAR_SELECTED_EDIT_USER:
        draft.user = null;
        break;
      case ActionTypes.CLEAR_EDIT_STATUS:
        draft.editStatus = null;
        break;
      case ActionTypes.UPDATE_SELECTED_EDIT_USER:
        draft.saving = true;
        break;
      case ActionTypes.UPDATE_SELECTED_EDIT_USER_ERROR:
      case ActionTypes.UPDATE_SELECTED_EDIT_USER_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.CREATE_USER:
        draft.saving = true;
        break;
      case ActionTypes.CREATE_USER_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        draft.user = action.user;
        break;
      case ActionTypes.CREATE_USER_ERROR:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.ACTIVATE_USER_SUCCESS:
        draft.saving = false;
        draft.user.status = UserStatus.Active;
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.DEACTIVATE_USER_SUCCESS:
        draft.saving = false;
        draft.user.status = UserStatus.Deactivated;
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.ACTIVATE_USER_ERROR:
      case ActionTypes.DEACTIVATE_USER_ERROR:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;
      case RoleActionTypes.FETCH_ROLES:
        draft.allSubscriptionRolesLoading = true;
        break;
      case RoleActionTypes.FETCH_ROLES_SUCCESS:
        draft.allSubscriptionRolesLoading = false;
        draft.allSubscriptionRoles = action.data.items;
        break;
      case ActionTypes.UPDATE_ADMIN_PRIVILEGE:
        draft.saving = true;
        break;
      case ActionTypes.UPDATE_ADMIN_PRIVILEGE_SUCCESS:
        draft.saving = false;
        draft.user.admin = action.isAdmin;
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.UPDATE_ADMIN_PRIVILEGE_ERROR:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;
    }
  });
};

export default createEditUserReducer;
