import { createSelector } from "reselect";

import { K2MainRouteKeys, K2Routes } from "../../models/routes/RouteModel";
import IApplicationState from "../../types";

export class NavigationSelectors {
  public static selectRouteKeysData({ subscription }: IApplicationState) {
    return [...subscription.users.k2UserRoutes.items];
  }

  /**
   * Gets the permitted navigation route keys, in an array
   * e.g. ["createAccount", "data", "analytics"]
   */
  public static getPermittedRouteKeysSelector() {
    return createSelector(this.selectRouteKeysData, (keys) => keys);
  }

  /**
   * Get the permitted navigation routes in the format:
   * { data: "/data", createAccount: "/accounts/new" }
   * and the current K2User, and the total tasks assigned to them
   */
  public static getMainNavigationDataSelector() {
    return createSelector(
      this.selectRouteKeysData,
      (permittedRoutes): INavigationSelectors => {
        return {
          permittedRoutes: this.extractPermittedMainNavRoutes(
            permittedRoutes as K2MainRouteKeys[],
          ),
        };
      },
    );
  }

  // Helpers
  public static extractPermittedMainNavRoutes(
    permittedRoutes: K2MainRouteKeys[],
  ): PermittedRoutes {
    const permitted = Object.assign({}, K2Routes.main);
    Object.keys(permitted).forEach((routeKey) => {
      if (!permittedRoutes.includes(routeKey as K2MainRouteKeys)) {
        delete permitted[routeKey];
      }
    });
    return permitted;
  }
}

type PermittedRoutes = {
  [key in K2MainRouteKeys]: string;
};

type INavigationSelectors = {
  permittedRoutes: PermittedRoutes;
};

export type INavigationSelectorProps = INavigationSelectors;
