import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { PinningLocation } from "@n3oltd/karakoram.analytics.sdk.measures";
import {
  PinnedVisualEmbedRes,
  PinnedVisualEmbedResultsList,
} from "@n3oltd/karakoram.analytics.sdk.reports/esm";
import { Carousel, Col, Divider, Skeleton } from "antd";
import { CarouselProps, CarouselRef } from "antd/lib/carousel";
import _ from "lodash";
import { connect } from "react-redux";
import styled from "styled-components";

import { _reportsClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  ServerError,
} from "appRedux/models/common/ApiResponseModel";
import { analyticsActions } from "appRedux/modules/analytics";
import IApplicationState from "appRedux/types";
import { K2Button, K2Card } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { EmptyVisual, SingleVisual } from "components/statisticsGraphs";
import { UIUtils } from "components/utils";
import { showNotification } from "components/utils/Notification";

const {
  clearServerError,
  clearReportsAndVisuals,
  resetUnPinnedVisual,
} = analyticsActions;

const CarouselWrapper = styled(Carousel)`
  && {
    .slick-slide {
      padding: 0px 12px 24px;
    }
  }
  &.card-statistics-carousel {
    overflow: hidden;
    width: calc(100% + 36px);
    padding: 0 0 0 12px;
    margin: 0 0 0 -24px !important;

    .slick-list {
      overflow: visible;
    }
  }
`;

const Shadowed = styled.div`
  box-shadow: ${({ theme }) => theme.card_shadow};
  border-radius: 8px !important;
`;

const K2CardWrapper = styled(K2Card)`
  margin-top: 24px !important;
  margin-bottom: 24px !important;
`;

const DividerWrapper = styled(Divider)`
  .ant-btn {
    padding: 5px 10px !important;
  }

  &:after {
    width: 0% !important;
  }
`;

interface IProps extends InjectedK2IntlProps {
  unpinnedVisual: boolean;
  unpinnedVisualId: string;
  serverError: ServerError;
  clearServerError: typeof clearServerError;
  clearReportsAndVisuals: typeof clearReportsAndVisuals;
  resetUnPinnedVisual: typeof resetUnPinnedVisual;
}

const maxVisuals: number = 6;

const settings: CarouselProps = {
  className: "card-statistics-carousel",
  accessibility: true,
  focusOnSelect: false,
  infinite: false,
  speed: 800, //slide speed
  slidesToShow: 3,
  slidesToScroll: 1,
  lazyLoad: "ondemand",
  arrows: true,
  dots: false,
  draggable: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const renderPinnedVisual = (
  dashboardVisuals: PinnedVisualEmbedRes[],
  unpinnedVisualId: string,
  unPinningVisual: boolean,
  setUnPinningVisual: (unpin: boolean) => void,
) => {
  return dashboardVisuals?.map?.((visual) => (
    <Shadowed key={visual.reportConfig.id}>
      <SingleVisual
        visual={visual}
        unpinnedVisualId={unpinnedVisualId}
        unPinningVisual={unPinningVisual}
        setUnPinningVisual={setUnPinningVisual}
      />
    </Shadowed>
  ));
};

const renderEmptyVisual = (emptyVisualsCount: number) => {
  return [...Array(emptyVisualsCount)]?.map?.((e, i) => (
    <EmptyVisual key={i} shadow={true} />
  ));
};

const StatisticsGraphs: FC<IProps> = (props) => {
  const {
    unpinnedVisual,
    unpinnedVisualId,
    serverError,
    k2Intl,
    clearServerError,
    clearReportsAndVisuals,
    resetUnPinnedVisual,
  } = props;

  const slider = useRef<CarouselRef>(null);

  const [unPinningVisual, setUnPinningVisual] = useState<boolean>(false);
  const [
    fetchingDashboardVisuals,
    setFetchingDashboardVisuals,
  ] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const [
    dashboardVisuals,
    setDashboardVisuals,
  ] = useState<PinnedVisualEmbedResultsList>(null);

  useEffect(() => {
    if (serverError) {
      UIUtils.handleServerError(k2Intl, serverError, clearServerError);
    }

    return () => {
      clearReportsAndVisuals();
    };
  }, [clearReportsAndVisuals, clearServerError, k2Intl, serverError]);

  useEffect(() => {
    if (unpinnedVisual && unpinnedVisualId) {
      const visualToDelete = dashboardVisuals?.items?.find(
        (x) => x.revisionId === unpinnedVisualId,
      );

      if (!_.isNil(visualToDelete)) {
        const filtered = dashboardVisuals?.items?.filter(
          (x) => x.revisionId !== unpinnedVisualId,
        );

        const final = { ...dashboardVisuals, items: filtered };

        if (!_.isEqual(final, dashboardVisuals)) {
          showNotification({
            type: "success",
            title: k2Intl?.formatMessage({
              localeKey: "common.success.title",
            }),
            message: UIUtils.messageToHtml(
              k2Intl,
              "reports.unpinFromDashboard.success",
              { report: visualToDelete.title, pinnedTo: "dashboard" },
            ),
          });

          setUnPinningVisual(false);
          setDashboardVisuals(final);
          resetUnPinnedVisual();
        }
      }
    }
  }, [
    dashboardVisuals,
    k2Intl,
    resetUnPinnedVisual,
    unpinnedVisual,
    unpinnedVisualId,
  ]);

  useEffect(() => {
    let isCancelled = false;
    setFetchingDashboardVisuals(true);

    const fetchDashboardVisuals = async () => {
      try {
        const response: IApiResponse<PinnedVisualEmbedResultsList> = await K2Service.toResponse(
          _reportsClient.getPinnedVisualsEmbedByLocation(
            PinningLocation.Dashboard,
          ),
        );

        if (!isCancelled) {
          setFetchingDashboardVisuals(false);

          if (response.error) {
            UIUtils.handleServerError(k2Intl, response.error, clearServerError);
          }

          setDashboardVisuals(response.getResultOrDefault());
        }
      } catch (e) {
        if (!isCancelled) {
          setFetchingDashboardVisuals(false);
        }
        console.log("error", e);
      }
    };

    fetchDashboardVisuals();

    return () => {
      isCancelled = true;
    };
  }, [clearServerError, k2Intl]);

  const afterChange = useCallback((curSlide: number) => {
    setCurrentSlide(curSlide);
  }, []);

  const next = useCallback(() => {
    slider?.current?.next();
  }, []);

  const previous = useCallback(() => {
    slider?.current?.prev();
  }, []);

  const pinnedItems = dashboardVisuals?.items?.slice(0, 6) || [];
  const havePinnedVisuals =
    !_.isNull(pinnedItems) &&
    !_.isUndefined(pinnedItems) &&
    !_.isEmpty(pinnedItems);
  const dashboardVisualsCount = havePinnedVisuals ? pinnedItems.length : 0;
  const emptyVisualsCount = maxVisuals - dashboardVisualsCount;

  return (
    <Col className={"n3o-px-0 n3o-py-0"} span={24}>
      {fetchingDashboardVisuals ? (
        <K2CardWrapper shadow>
          <Skeleton active paragraph={{ rows: 6 }} />
        </K2CardWrapper>
      ) : (
        <>
          <CarouselWrapper ref={slider} {...settings} afterChange={afterChange}>
            {havePinnedVisuals &&
              renderPinnedVisual(
                pinnedItems,
                unpinnedVisualId,
                unPinningVisual,
                setUnPinningVisual,
              )}
            {emptyVisualsCount > 0 && renderEmptyVisual(emptyVisualsCount)}
          </CarouselWrapper>
          <DividerWrapper orientation="right" plain className={"n3o-my-0"}>
            <K2Button
              className={"n3o-mr-4"}
              onClick={previous}
              disabled={currentSlide === 0}
            >
              <LeftOutlined />
            </K2Button>
            <K2Button onClick={next} disabled={currentSlide >= 3}>
              <RightOutlined />
            </K2Button>
          </DividerWrapper>
        </>
      )}
    </Col>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    unpinnedVisual: state.analytics?.unpinnedVisual,
    unpinnedVisualId: state.analytics?.unpinnedVisualId,
    serverError: state.analytics?.serverError,
  };
};

const mapDispatchToProps = {
  clearServerError,
  clearReportsAndVisuals,
  resetUnPinnedVisual,
};

export default injectK2Intl(
  connect(mapStateToProps, mapDispatchToProps)(StatisticsGraphs),
);
