import React, { Component, ComponentClass, FC, Ref } from "react";
import { connect } from "react-redux";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { UIUtils } from "components/utils";
import { ServerError } from "../models/common/ApiResponseModel";
import LookupsModel, { LookupType } from "../models/lookups/LookupsModel";
import LookupsSelectors, {
  IInjectLookupsStateProps,
} from "../store/selectors/LookupsSelectors";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

export default function injectLookup<ComposedComponentProps extends {}>(
  ComposedComponent:
    | ComponentClass<ComposedComponentProps>
    | FC<ComposedComponentProps>,
  lookupTypes: LookupType[],
  options?: { forwardRef: boolean },
) {
  // @ts-ignore
  type ComposedComponentInstance = InstanceType<typeof ComposedComponent>;

  type WrapperComponentProps = ComposedComponentProps;

  type WrapperComponentPropsWithForwardedRef = WrapperComponentProps &
    IInjectLookupsStateProps & {
      forwardedRef: Ref<ComposedComponentInstance>;
    };

  class LookupInject extends Component<WrapperComponentPropsWithForwardedRef> {
    private requestedLookups: LookupType[] = [];
    constructor(props: WrapperComponentPropsWithForwardedRef) {
      super(props);

      /**Determine the lookups requested, input could be a LookupType or LookupGroup */
      this.requestedLookups = lookupTypes;
    }

    /** Triggers actions to retrieve lookups that are required */
    componentDidMount() {
      const requiredLookups = LookupsModel.determineLookupsToFetch(
        this.requestedLookups,
        this.props.lookups,
      );

      if (requiredLookups.length) {
        this.props.fetchLookups(requiredLookups);
      }
    }

    render() {
      const {
        lookups,
        clearLookupError,
        forwardedRef,
        ...composedComponentProps
      } = this.props;
      const lookupsToInject = LookupsModel.getLookupsToInject(
        this.requestedLookups,
        lookups,
      );

      return (
        <>
          <GenericErrorHandler
            serverError={lookups.serverError}
            clearError={clearLookupError}
          />
          <ComposedComponent
            {...(composedComponentProps as ComposedComponentProps)}
            {...lookupsToInject}
            ref={forwardedRef}
          />
        </>
      );
    }
  }

  const LookupInjectConnected = connect(
    LookupsSelectors.getInjectLookupsSelector(),
    LookupsSelectors.getInjectLookupsDispatchers(),
    null,
    { forwardRef: true },
    // @ts-ignore
  )(LookupInject);

  const forwardRef = !options || options.forwardRef === true;

  if (forwardRef) {
    return React.forwardRef<ComposedComponentInstance, WrapperComponentProps>(
      (props, ref) => {
        // @ts-ignore
        return <LookupInjectConnected {...props} forwardedRef={ref} />;
      },
    );
  } else {
    return (props: WrapperComponentProps) => (
      // @ts-ignore
      <LookupInjectConnected {...props} />
    );
  }
}

//#region Component to handle generic error
interface IGenericErrorHandler extends InjectedK2IntlProps {
  serverError: ServerError;
  clearError?: () => void;
}

const GenericErrorHandler = injectK2Intl(
  class GenericErrorHandlerInternal extends Component<IGenericErrorHandler> {
    handleServerError() {
      const { k2Intl, serverError, clearError } = this.props;
      if (serverError)
        UIUtils.handleServerError(k2Intl, serverError, clearError);
    }

    componentDidUpdate() {
      this.handleServerError();
    }

    render() {
      return <></>;
    }
  },
);

//#endregion
