import React, { FC } from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { RenderSmsRes } from "@n3oltd/karakoram.templates.sdk.sms/esm";
import { Button, Row } from "antd";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

import ItemSummaryRow from "components/admin/shared/ItemSummaryRow";
import { K2Message } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import K2Modal from "components/k2Widgets/k2Modal";
import IFrame from "routes/admin/communication/sharedComponents/IFrame";

interface SmsPreviewProps {
  sms: RenderSmsRes;
  loading: boolean;
  onClose: () => void;
  k2Intl: InjectedK2Intl;
  error?: string;
  onNext?: () => void;
  onPrevious?: () => void;
  showControls?: boolean;
  isNextAvailable?: boolean;
  isPreviousAvailable?: boolean;
}

const Error = styled.div`
  color: ${({ theme }) => theme.error_color};
`;

const SmsContainer = styled.div`
  margin-top: 20px;
`;

const SenderDetails = styled.div`
  span.sender {
    color: ${({ theme }) => theme.grey_6};
    font-size: 14px;
    font-weight: ${({ theme }) => theme.font_weight_normal};
  }
`;

const SmsPreview: FC<SmsPreviewProps> = ({
  sms,
  onClose,
  loading,
  error,
  onNext,
  onPrevious,
  isNextAvailable,
  isPreviousAvailable,
  showControls = false,
}) => {
  return (
    <K2Modal
      width={"70%"}
      loading={loading}
      titleKey={"admin.communication.sms.preview"}
      onCancel={onClose}
      visible={true}
      footer={null}
    >
      {!showControls && (
        <h3>
          <K2Message localeKey={"admin.communication.sms.details"} />
        </h3>
      )}

      {showControls && (
        <Row justify="space-between" align="middle">
          <Button
            type="link"
            disabled={!isPreviousAvailable}
            onClick={onPrevious}
          >
            <LeftOutlined /> <K2Message localeKey={"common.previous"} />
          </Button>
          <div style={{ textAlign: "center" }}>
            <h3>
              <K2Message localeKey={"admin.communication.sms.details"} />
            </h3>
          </div>
          <Button type="link" disabled={!isNextAvailable} onClick={onNext}>
            <K2Message localeKey={"common.next"} /> <RightOutlined />
          </Button>
        </Row>
      )}
      {sms && (
        <div>
          <SenderDetails>
            <ItemSummaryRow
              value={
                <>
                  {sms?.sender ? (
                    <>
                      <span>{sms.sender}</span>
                    </>
                  ) : null}
                </>
              }
              labelKey={"communication.sender"}
            />
          </SenderDetails>
          <SmsContainer>
            <IFrame content={sms.body} height={100} />
          </SmsContainer>
        </div>
      )}
      {error && <Error>{ReactHtmlParser(error)}</Error>}
    </K2Modal>
  );
};

export default SmsPreview;
