import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TaskStatus } from "@n3oltd/karakoram.tasks.sdk.tasks";
import { Layout } from "antd";
import _ from "lodash";
import { positionValues } from "react-custom-scrollbars";
import { hot } from "react-hot-loader";
import { connect, useDispatch } from "react-redux";

import { updateScrollPosition } from "appRedux/modules/settings/actions";
import AppSelectors, {
  IMainAppStateProps,
} from "appRedux/store/selectors/AppSelectors";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { MOBILE_SIZE, TAB_SIZE } from "constants/ThemeSetting";
import K2Layout from "containers/app/views/K2Layout";
import Q1Layout from "routes/Q1/components/Q1Layout";

import useWindowWidth from "../../../hooks/useWindowWidth";

const debounce = (fn: any) => {
  let frame: number;

  return (...params: any) => {
    if (frame) {
      cancelAnimationFrame(frame);
    }
    frame = requestAnimationFrame(() => {
      fn(...params);
    });
  };
};

const storeScroll = (scrollTop: number) => {
  document.documentElement.dataset.scroll = scrollTop.toString();
};

interface MainAppProps extends IMainAppStateProps {}

export const MainApp: FC<PropsWithChildren<MainAppProps>> = (props) => {
  const {
    k2Subscription,
    k2User,
    taskCountForUser,
    fetchTaskCountForUser,
    K2UserRouteList,
    fetchAgent,
    children,
  } = props;

  const dispatch = useDispatch();

  const updateGlobalScrollPosition = useMemo(
    () => _.throttle((v) => dispatch(updateScrollPosition(v)), 100),
    [dispatch],
  );

  const handleScrollFrame = (values: positionValues) => {
    const { scrollTop } = values;

    debounce(storeScroll(scrollTop));
    updateGlobalScrollPosition(values);
  };

  const { appId, updateUserAndSubscription } = useAppContext();

  const width: number = useWindowWidth();

  const isTabScreen = width < TAB_SIZE;
  const isMobileScreen = width <= MOBILE_SIZE;

  useEffect(() => {
    if (k2User && k2Subscription) {
      updateUserAndSubscription(k2User, k2Subscription);
    }
  }, [k2User, k2Subscription, updateUserAndSubscription]);

  useEffect(() => {
    if (K2UserRouteList?.items?.includes("callCenter")) {
      fetchAgent();
    }
  }, [fetchAgent, K2UserRouteList]);
  useEffect(() => {
    storeScroll(0);
    fetchTaskCountForUser({
      assignedToMe: true,
      status: [TaskStatus.Pending],
    });
  }, [fetchTaskCountForUser]);

  const AppLayout = useMemo(() => (appId === "k2" ? K2Layout : Q1Layout), [
    appId,
  ]);

  return (
    <Layout style={{ height: isTabScreen ? "100vh" : "auto" }}>
      <AppLayout
        isTabScreen={isTabScreen}
        isMobileScreen={isMobileScreen}
        onScroll={handleScrollFrame}
        taskCount={taskCountForUser}
        currentUser={k2User}
      >
        <>{children}</>
      </AppLayout>
    </Layout>
  );
};

export default connect(
  AppSelectors.getMainAppSelector(),
  AppSelectors.getMainAppDispatchers(),
)(hot(module)(MainApp));
