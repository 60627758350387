import { SubscriptionRes } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { LocalizationSettingsRes } from "@n3oltd/k2.users.sdk.users/esm";
import { createSelector } from "reselect";

import authActions from "../../modules/auth/actions";
import { usersActions } from "../../modules/subscription";
import { IUsersManagement } from "../../modules/subscription/users/types";
import { NavigationSelectors } from "./NavigationSelectors";
import UsersSelectors from "./UsersSelectors";

const { userSignedIn } = authActions;
const { getAppResources, getCurrentSubscription } = usersActions;

export default class AuthSelectors {
  //#region K2View

  public static getK2ViewSelector() {
    return createSelector(
      UsersSelectors.selectUsersData,
      (usersManagement): IK2ViewSelectorProps => {
        return { usersManagement };
      },
    );
  }

  public static getK2ViewDispatchers(): IK2ViewDispatchers {
    return {
      userSignedIn,
    };
  }

  //#endregion

  //#region K2SubscriptionView

  public static getK2SubscriptionViewSelector() {
    return createSelector(
      UsersSelectors.selectUsersData,
      UsersSelectors.getK2SubscriptionSelector(),
      UsersSelectors.getUserLocalizationSelector(),
      (
        usersManagement,
        k2Subscription,
        localizationSettings,
      ): IK2SubscriptionViewSelectorProps => {
        return {
          usersManagement: {
            ...usersManagement,
            currentUserSubscriptionsLoaded: !!usersManagement.currentUserSubscriptions,
          },
          k2Subscription,
          localizationSettings,
        };
      },
    );
  }

  public static getK2SubscriptionViewDispatchers(): IK2SubscriptionViewDispatchers {
    return {
      getAppResources,
      getCurrentSubscription,
    };
  }

  //#endregion

  public static k2UserPermissionsSelector() {
    return createSelector(UsersSelectors.getK2UserSelector(), (k2User) => {
      return k2User ? k2User.permissionIds : [];
    });
  }

  public static getRequireAuthSelector() {
    return createSelector(
      UsersSelectors.getK2UserSelector(),
      this.k2UserPermissionsSelector(),
      NavigationSelectors.getPermittedRouteKeysSelector(),
      (k2User, userPermissions, permittedRouteKeys): IRequireAuthStateProps => {
        return {
          authenticated: !!k2User,
          userPermissions,
          permittedRouteKeys,
        };
      },
    );
  }
}

export type IRequireAuthStateProps = {
  authenticated: boolean;
  userPermissions?: string[];
  permittedRouteKeys: string[];
};

type IK2ViewSelectorProps = {
  usersManagement: IUsersManagement;
};

type IK2ViewDispatchers = {
  userSignedIn: typeof userSignedIn;
};

export type IK2ViewStateProps = IK2ViewSelectorProps & IK2ViewDispatchers;

type IK2SubscriptionViewSelectorProps = {
  usersManagement: IUsersManagement & {
    currentUserSubscriptionsLoaded: boolean;
  };
  k2Subscription: SubscriptionRes;
  localizationSettings: LocalizationSettingsRes;
};

type IK2SubscriptionViewDispatchers = {
  getAppResources: typeof getAppResources;
  getCurrentSubscription: typeof getCurrentSubscription;
};

export type IK2SubscriptionViewStateProps = IK2SubscriptionViewSelectorProps &
  IK2SubscriptionViewDispatchers;
