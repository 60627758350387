import React, { FC } from "react";

import { UserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";

import { FlatRoutes as FlatQ1Routes } from "appRedux/models/routes/Q1RouteModel";
import { FlatRoutes as FlatK2Routes } from "appRedux/models/routes/RouteModel";
import MainNavigation from "components/navigation/mainNavigation";

interface IStyledProps {
  isOnDashboard: boolean;
  isTabScreen: boolean;
}

const Wrapper = styled.div<{ $isMobileScreen }>`
  padding: 0;
  flex: 1 1;
  margin-top: ${({ $isMobileScreen }) => ($isMobileScreen ? "0" : "-25px")};
  position: sticky;
  top: 0;
  z-index: 33;
  border-radius: 8px;

  @media screen and (max-width: ${({ theme }) => theme.screen_tab}) {
    margin-top: 0;
  }
`;

const MainNavContainer = styled.div<IStyledProps>`
  border-radius: 8px;
  display: ${({ isOnDashboard, isTabScreen }) =>
    !isOnDashboard && isTabScreen ? "none" : "block"};
  transition: 300ms;
  transition-timing-function: ease-out;

  @media screen and (max-width: ${({ theme }) => theme.screen_tab}) {
    height: auto;
  }

  @media screen and (min-width: ${({ theme }) => theme.screen_tab}) {
    margin-bottom: ${({ isOnDashboard }) => (!isOnDashboard ? "0" : "25px")};
  }
`;

interface IProps extends RouteComponentProps {
  user: UserRes;
  taskCountForUser: number;
  isMobileScreen: boolean;
  isTabScreen: boolean;
}

const Navigation: FC<IProps> = (props) => {
  const { location, isMobileScreen, isTabScreen } = props;

  const isOnDashboard =
    location.pathname === FlatK2Routes.dashboard ||
    location.pathname === FlatQ1Routes.q1Dashboard;

  const styledProps: IStyledProps = {
    isOnDashboard,
    isTabScreen,
  };

  return (
    <Wrapper $isMobileScreen={isMobileScreen}>
      <MainNavContainer {...styledProps} className="n3o-nav-header-main-menu">
        {!isTabScreen && <MainNavigation isOnDashboard={isOnDashboard} />}
      </MainNavContainer>
    </Wrapper>
  );
};

export default withRouter(Navigation);
