import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const Mailroom: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6 4.69499L22.6 4.66743C22.6001 4.5185 22.6001 4.35105 22.5866 4.21114C22.5736 4.07772 22.5364 3.78206 22.3144 3.5278C22.0712 3.24932 21.7591 3.18779 21.6013 3.16771C21.4603 3.14979 21.2964 3.14993 21.1712 3.15004L21.1431 3.15006H6.30691L6.27469 3.15005C6.08895 3.14999 5.8873 3.14992 5.72014 3.16788C5.55207 3.18593 5.23025 3.23661 4.96545 3.49256C4.69478 3.75419 4.63784 4.07962 4.61792 4.25285C4.59879 4.41929 4.59887 4.61874 4.59895 4.79665L4.59896 4.82935V5.64453H3.10793L3.07571 5.64453C2.88997 5.64447 2.68833 5.6444 2.52116 5.66235C2.3531 5.6804 2.03127 5.73108 1.76648 5.98703C1.4958 6.24867 1.43886 6.57409 1.41895 6.74732C1.39981 6.91377 1.3999 7.11322 1.39997 7.29113L1.39998 7.32383V19.1707C1.39998 20.1251 2.19206 20.85 3.10793 20.85H18.7472L18.7794 20.85C18.9651 20.8501 19.1668 20.8502 19.3339 20.8322C19.502 20.8142 19.8238 20.7635 20.0886 20.5075C20.3593 20.2459 20.4162 19.9205 20.4361 19.7472C20.4553 19.5808 20.4552 19.3814 20.4551 19.2034L20.4551 19.1707V17.2895H20.8921L20.9243 17.2895C21.11 17.2895 21.3117 17.2896 21.4788 17.2717C21.6469 17.2536 21.9687 17.2029 22.2335 16.947C22.5042 16.6853 22.5611 16.3599 22.5811 16.1867C22.6002 16.0202 22.6001 15.8208 22.6 15.6429L22.6 15.6102V4.69499ZM4.59896 7.34453L3.10793 7.34453H3.09998V19.1493C3.10197 19.1497 3.10457 19.15 3.10793 19.15H18.7472H18.7551V17.2895H6.30691C5.39104 17.2895 4.59896 16.5645 4.59896 15.6102V7.34453ZM20.9 5.79474L14.7111 10.4475C14.0526 10.9426 13.1462 10.9426 12.4877 10.4475L6.29896 5.79486V15.5888C6.30095 15.5892 6.30355 15.5895 6.30691 15.5895H20.8921H20.9V5.79474ZM7.87124 4.85006L13.5093 9.08871C13.5627 9.12885 13.6362 9.12885 13.6895 9.08871L19.3276 4.85006H7.87124Z"
      fill={fill}
    />
  </svg>
);
