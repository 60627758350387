import React, { FC, useCallback, useEffect, useState } from "react";

import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import {
  DonationItemReq,
  PriceReq,
  PricingReq,
  PricingRuleReq,
} from "@n3oltd/k2.donations.sdk.donation-items/esm";
import { FundStructureRevisionRes } from "@n3oltd/k2.subscriptions.sdk.funds";
import { Col, Form, Skeleton, Spin } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { K2StatusCodes } from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/RouteModel";
import { DataActions } from "appRedux/modules/sharedTypes";
import NotFoundWrapper from "components/NotFoundWrapper";
import { K2Link, K2Message, K2RouteHeading } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { FormsWrapper, MainFormsWrapper } from "components/layout/wrappers";
import { UIUtils } from "components/utils";
import { showConfirm } from "components/utils/Confirmation";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import { REDIRECT_AFTER_SAVE_MS } from "constants/appConstants";
import useBreadcrumb from "hooks/useBreadcrumb";
import { DonationItemAdminHelpers } from "routes/admin/donationItemsCreateEdit/helpers";

import DonationItemForm from "./components/DonationItemForm";
import { DonationItemProps } from "./connect";

const AddEditDonationItemMain: FC<DonationItemProps> = (props) => {
  const {
    match,
    history,
    fetchSelectedDonationItem,
    fetchSubscriptionFundDimensions,
    clearDonationItemEditStatus,
    clearCreateDonationItemError,
    clearSelectedDonationItem,
    editStatus,
    k2Intl,
    deleteDonationItem,
    updateDonationItem,
    loadingFundDimensions,
    selectedDonationItem,
    fundDimensions,
    createDonationItem,
    serverError,
    loading,
    baseCurrency,
    dirty,
    setFormDirty,
    clearFormDirty,
    taxReliefRates,
    donationItemStatuses,
    donationTypes,
  } = props;
  const [overallFormErrors, setOverallFormErrors] = useState<string[]>([]);
  const [pricingRuleErrors, setPricingRuleErrors] = useState<string[]>([]);
  const [
    pricingFundDimensions,
    setPricingFundDimensions,
  ] = useState<FundStructureRevisionRes>({});
  const [notFound, setNotFound] = useState<boolean>(false);

  const donationItemId: string | null =
    match.params && match.params.donationItemId
      ? match.params.donationItemId
      : null;

  const editMode = donationItemId !== null;

  const getBreadcrumbConfig = () => [
    {
      path: K2Routes.main.admin,
      titleKey: "mainNav.admin",
      active: false,
    },
    {
      path: K2Routes.donationItems,
      titleKey: "admin.donationItems",
      active: false,
    },
    {
      path: editMode
        ? K2Routes.editDonationItem.replace(
            ":donationItemId",
            props.match.params.donationItemId,
          )
        : K2Routes.createDonationItem,
      title: editMode ? selectedDonationItem?.name : undefined,
      titleKey: editMode
        ? selectedDonationItem
          ? undefined
          : "admin.editDonationItem"
        : "admin.createDonationItem",
      active: true,
    },
  ];

  const setBreadcrumbs = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbs(getBreadcrumbConfig());
    // eslint-disable-next-line
  }, [selectedDonationItem, editMode]);

  const fullyLoaded =
    (donationItemId
      ? !loadingFundDimensions && selectedDonationItem
      : !loadingFundDimensions) &&
    props.taxReliefRates.items.length > 0 &&
    props.donationItemStatuses.items.length > 0;

  const [form] = Form.useForm();

  useEffect(() => {
    if (donationItemId) {
      fetchSelectedDonationItem(donationItemId);
    }
    fetchSubscriptionFundDimensions();
    return clearSelectedDonationItem;
    // eslint-disable-next-line
  }, [donationItemId]);

  useEffect(() => {
    fetchSubscriptionFundDimensions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (serverError) {
      if (serverError.status === K2StatusCodes.notFound) {
        setNotFound(true);
        clearCreateDonationItemError();
      } else {
        UIUtils.handleServerError(
          k2Intl,
          serverError,
          clearCreateDonationItemError,
        );
        setNotFound(false);
      }
    }
    // eslint-disable-next-line
  }, [serverError]);

  useEffect(() => {
    if (editStatus) {
      switch (editStatus.action) {
        // New item created or full entity updated
        case DataActions.save:
          if (editStatus.error?.status === K2StatusCodes.preconditionFailed) {
            const pricingRuleErrs = editStatus.error.data.errors
              .filter((err) => err.property === "pricing.rules")
              ?.map?.((e) => e.error);
            setPricingRuleErrors(pricingRuleErrs);

            const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
              editStatus.error,
              "error",
            );

            const overallFormErrorsToSet = nonFieldRelatedErrors?.map?.(
              (err) => err.error,
            );

            const fieldErrors = editStatus.error.data.errors.filter((e) =>
              e.hasOwnProperty("property"),
            );
            if (fieldErrors.length) {
              setOverallFormErrors([
                ...overallFormErrorsToSet,
                k2Intl?.formatMessage({
                  localeKey: "admin.donationItems.generalFormErrors",
                }),
              ]);
            } else if (overallFormErrorsToSet?.length) {
              setOverallFormErrors(overallFormErrorsToSet);
            }
          }

          UIUtils.handleDataUpdateResult({
            form,
            k2Intl,
            status: editStatus,
            successTitle: "admin.donationItemSaveSuccessTitle",
            successMessage: "admin.donationItemSaveSuccessMessage",
            onError: clearDonationItemEditStatus,
            onSuccess: () => {
              clearFormDirty();
              setOverallFormErrors([]);
              form?.resetFields();
              setTimeout(() => {
                history.push(K2Routes.donationItems);
              }, REDIRECT_AFTER_SAVE_MS);
            },
          });
          break;
        case DataActions.delete:
          UIUtils.handleDataUpdateResult({
            k2Intl,
            status: editStatus,
            successTitle: "admin.donationItemDeleteSuccessTitle",
            successMessage: "admin.donationItemsDeleteSuccessMessage",
            onSuccess: () => {
              if (editStatus.result) {
                history.push(K2Routes.donationItems);
              }
            },
          });
          break;
      }

      clearDonationItemEditStatus();
    }
    // eslint-disable-next-line
  }, [editStatus]);

  const mapToPricingFundDimensions = useCallback(
    (selectedFundDimensionOptions) => {
      const pricingFundDimensions: FundStructureRevisionRes = {};
      Object.keys(selectedFundDimensionOptions).forEach((dimensionKey) => {
        pricingFundDimensions[dimensionKey] = {
          ...fundDimensions[dimensionKey],
          restrictedOptions: getSelectedFundDimensionOptions(dimensionKey),
          unrestrictedOption: selectedFundDimensionOptions[dimensionKey].find(
            (op) => fundDimensions[dimensionKey].unrestrictedOption === op,
          ),
        };
      });

      pricingFundDimensions.baseCurrency = fundDimensions.baseCurrency;
      pricingFundDimensions.revisionId = fundDimensions.revisionId;

      setPricingFundDimensions(pricingFundDimensions);

      function getSelectedFundDimensionOptions(dimensionKey: string) {
        return fundDimensions[dimensionKey].restrictedOptions?.filter((op) =>
          selectedFundDimensionOptions[dimensionKey]?.includes(op),
        );
      }
    },
    [fundDimensions, setPricingFundDimensions],
  );

  useEffect(() => {
    const hasFundDimensions = Object.keys(fundDimensions).length > 0;
    if (selectedDonationItem && hasFundDimensions) {
      const selectedFundDimensionOptions = Object.keys(
        selectedDonationItem.fundDimensionOptions,
      ).reduce((acc, key) => {
        acc[key] = selectedDonationItem["fundDimensionOptions"][key];
        return acc;
      }, {});

      mapToPricingFundDimensions(selectedFundDimensionOptions);
    }
  }, [selectedDonationItem, fundDimensions, mapToPricingFundDimensions]);

  const onDeleteDonationItem = (): void => {
    const { k2Intl } = props;

    showConfirm({
      titleKey: "admin.donationItemDeleteConfirmTitle",
      k2Intl,
      okTextKey: "common.delete",
      okButtonProps: {
        danger: true,
      },
      centered: true,
      onOk: () => {
        deleteDonationItem(selectedDonationItem.revisionId);
      },
    });
  };

  const onCreateEdit = (values: { [name: string]: any }): void => {
    setOverallFormErrors([]);
    setPricingRuleErrors([]);

    const fundDimensionOptions = {
      dimension1: values["fundDimensionOptions.dimension1"],
      dimension2: values["fundDimensionOptions.dimension2"],
      dimension3: values["fundDimensionOptions.dimension3"],
      dimension4: values["fundDimensionOptions.dimension4"],
    };

    const priceProvided = form?.getFieldValue(
      DonationItemAdminHelpers.priceProvidedFieldName,
    );
    const donationItemReq: DonationItemReq = {
      name: values.name,
      notes: values.notes,
      allowedDonationTypes: values.allowedDonationTypes,
      fundDimensionOptions: getFundDimensions(fundDimensionOptions),
      taxReliefRate: values.taxReliefRate,
    };
    if (priceProvided) {
      donationItemReq.pricing = getPricingData();
    }

    clearCreateDonationItemError();
    if (editMode) {
      updateDonationItem(selectedDonationItem.revisionId, donationItemReq);
    } else {
      createDonationItem(donationItemReq);
    }
  };

  const getFundDimensions = (values: {
    [key: string]: any;
  }): { [key: string]: any } => {
    const res = {};
    Object.keys(values).forEach((key) => {
      if (values[key]) res[key] = values[key];
    });
    return res;
  };

  const getPricingData = (): PricingReq => {
    const data: PricingReq = {};
    const price: PriceReq = {
      locked:
        Boolean(
          form?.getFieldValue(DonationItemAdminHelpers.priceLockedFieldName),
        ) || false,
      amount:
        Number(
          form?.getFieldValue(DonationItemAdminHelpers.priceAmountFieldName),
        ) || 0,
    };
    if (price.amount > 0) {
      data.price = {
        ...price,
      };
    }
    const rules = form?.getFieldValue(
      DonationItemAdminHelpers.pricingRulesFieldName,
    );
    if (rules) {
      const r = rules?.map?.((rule: PricingRuleReq) => {
        return {
          fundDimensions: Object.keys(rule.fundDimensions).reduce(
            (acc, key) => ({
              ...acc,
              [key]: { value: rule.fundDimensions[key] },
            }),
            {},
          ),
          price: {
            ...rule.price,
            locked: !!rule.price.locked,
          },
        };
      });
      data.rules = r || [];
    } else {
      data.rules = [];
    }

    return data;
  };

  const handlePricingFundDimensions = (values: any) => {
    let dimensionIndex = 1;

    const selectedFundDimensionOptions = Object.keys(values).reduce(
      (acc, key) => {
        if (key.includes("dimension")) {
          acc[`dimension${dimensionIndex}`] = values[key];
          dimensionIndex += 1;
        }
        return acc;
      },
      {},
    );

    mapToPricingFundDimensions(selectedFundDimensionOptions);
  };

  return (
    <FormsWrapper>
      <NotFoundWrapper
        showNotFound={notFound}
        entityNameKey={"admin.donationItem"}
      >
        <K2RouteHeading
          backToLink={
            <K2Link to={K2Routes.donationItems}>
              <ArrowLeftOutlined />
              <K2Message localeKey={"admin.editDonationItem.back"} />
            </K2Link>
          }
          headingKey={
            editMode ? "admin.editDonationItem" : "admin.createDonationItem"
          }
        />
        <MainFormsWrapper>
          <Col span={24}>
            <Skeleton active loading={!fullyLoaded}>
              <Spin spinning={loading}>
                <DirtyDataGuard dirty={dirty} onNavigate={clearFormDirty}>
                  {fullyLoaded && (
                    <Form
                      onFinishFailed={(err: ValidateErrorEntity) => {
                        UIUtils.scrollToFirstErrorWithOffset(form, err);
                      }}
                      layout={"vertical"}
                      initialValues={{
                        ...selectedDonationItem,
                        [DonationItemAdminHelpers.priceProvidedFieldName]: selectedDonationItem
                          ? selectedDonationItem.hasPrice
                          : false,
                      }}
                      form={form}
                      onFinish={(values) => {
                        onCreateEdit(values);
                      }}
                      onValuesChange={(_, values) => {
                        setFormDirty();
                        handlePricingFundDimensions(values);
                      }}
                    >
                      <DonationItemForm
                        form={form}
                        baseCurrency={baseCurrency}
                        fetchSelectedDonationItem={fetchSelectedDonationItem}
                        onDelete={onDeleteDonationItem}
                        isLoading={!fullyLoaded}
                        editMode={editMode}
                        fundDimensionsData={fundDimensions}
                        pricingFundDimensions={pricingFundDimensions}
                        donationItem={selectedDonationItem}
                        overallFormErrors={overallFormErrors}
                        dirty={dirty}
                        pricingRuleErrors={pricingRuleErrors}
                        taxReliefRates={taxReliefRates}
                        donationItemStatuses={donationItemStatuses}
                        donationTypes={donationTypes}
                      />
                    </Form>
                  )}
                </DirtyDataGuard>
              </Spin>
            </Skeleton>
          </Col>
        </MainFormsWrapper>
      </NotFoundWrapper>
    </FormsWrapper>
  );
};

export default injectK2Intl(AddEditDonationItemMain);
