import React from "react";

import { Avatar } from "antd";
import { AvatarProps } from "antd/es";
import styled from "styled-components";

const K2AvatarComponent = styled(Avatar)`
  .ant-avatar-string {
    line-height: ${(props) => (props.size as number) - 4}px !important;
  }
`;

function K2Avatar(props: AvatarProps) {
  return <K2AvatarComponent {...props}>{props.children}</K2AvatarComponent>;
}

export default K2Avatar;
