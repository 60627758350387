// These are the required entities that may be searched from Tally at the moment.
import { CheckSquareOutlined } from "@ant-design/icons/lib";
import React from "react";
import { SearchOptions } from "components/navigation/mainAppHeader/globalSearch/modules/types";
import { N3oIcon } from "components/n3oIcon";
import variables from "common/themeVariables";

export const searchOptions: SearchOptions = {
  payments: {
    titleLocaleKey: "common.entity.payment.plural",
    apiRoot: "payments",
    icon: (
      <N3oIcon
        icontype={"n3o-payments"}
        width={14}
        height={14}
        fill={variables.grey_6}
      />
    ),
  },
  tasks: {
    titleLocaleKey: "entities.tasks",
    apiRoot: "tasks",
    icon: <CheckSquareOutlined />,
  },
};
