import React, { Component } from "react";
import { InjectedK2IntlProps } from "../k2Localizations/types";
import { UIUtils } from "components/utils";
import { Tooltip } from "antd";
import { AbstractTooltipProps, RenderFunction } from "antd/es/tooltip";
import { K2MessageValue } from "../k2Localizations/types";
import _ from "lodash";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

export interface K2TooltipProps
  extends AbstractTooltipProps,
    InjectedK2IntlProps {
  titleKey?: string;
  titleValues?: { [key: string]: K2MessageValue };
  hidden?: boolean;
  disabled?: boolean;
  title?: React.ReactNode | RenderFunction;
  overlay?: React.ReactNode | RenderFunction;
}

class K2Tooltip extends Component<K2TooltipProps> {
  render() {
    const {
      hidden,
      disabled,
      children,
      title,
      titleKey,
      titleValues,
      k2Intl,
      ...rest
    } = this.props;
    const titleNode = titleKey
      ? UIUtils.messageToHtml(k2Intl, titleKey, titleValues)
      : title;

    if (hidden) {
      return <>{children}</>;
    } else {
      return (
        <Tooltip
          title={titleNode}
          children={
            <span
              className={`tooltip-children-body n3o-pointer ${
                disabled && "n3o-opacity-50"
              }`}
            >
              {children}
            </span>
          }
          getPopupContainer={(triggerNode: HTMLElement) => {
            const element = document.getElementById("route-section");
            return !_.isNull(element) ? element : triggerNode;
          }}
          {...rest}
        />
      );
    }
  }
}

export default injectK2Intl(K2Tooltip);
