import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const SearchDonation: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.24388 5.84317C2.5218 7.35538 2.81408 9.18454 3.94201 10.3553L3.94555 10.359L8.61567 15.2652C8.93933 15.6053 8.92607 16.1433 8.58604 16.467C8.24601 16.7906 7.70799 16.7774 7.38432 16.4373L2.71588 11.5329C1.08558 9.83898 0.689269 7.24393 1.71157 5.10694L1.71405 5.10175C3.06935 2.31725 6.34617 1.17451 9.04015 2.64249C9.52296 2.90558 9.96392 3.2427 10.349 3.64152L10.9998 4.26943L11.6507 3.64142C13.7897 1.42763 17.2396 1.46204 19.3382 3.71874C19.719 4.12815 20.0377 4.59452 20.2847 5.10151L20.2869 5.10603C20.787 6.1482 21.0612 7.26456 20.8363 8.5031C20.7525 8.96499 20.3101 9.27146 19.8482 9.18761C19.3863 9.10377 19.0798 8.66136 19.1637 8.19946C19.306 7.41512 19.1509 6.66895 18.7554 5.84392C18.581 5.48638 18.3574 5.16043 18.0933 4.87644C16.6549 3.32962 14.3256 3.31057 12.8651 4.83117L12.8539 4.84283L10.9998 6.6317L9.14566 4.84272L9.13435 4.83093C8.86513 4.55043 8.55889 4.31625 8.22673 4.13525C6.43389 3.15831 4.2004 3.88137 3.24388 5.84317ZM11.4324 11.2837C12.467 10.2491 13.8702 9.66791 15.3333 9.66791C16.7964 9.66791 18.1996 10.2491 19.2342 11.2837C20.2688 12.3183 20.85 13.7215 20.85 15.1846C20.85 16.3613 20.474 17.4993 19.7886 18.4379L22.601 21.2504C22.933 21.5824 22.933 22.1206 22.601 22.4525C22.2691 22.7845 21.7309 22.7844 21.3989 22.4525L18.5866 19.64C17.6479 20.3253 16.51 20.7013 15.3333 20.7013C13.8702 20.7013 12.467 20.12 11.4324 19.0855C10.3978 18.0509 9.81662 16.6477 9.81662 15.1846C9.81662 13.7215 10.3978 12.3183 11.4324 11.2837ZM15.3333 11.3679C14.3211 11.3679 13.3503 11.77 12.6345 12.4858C11.9187 13.2016 11.5166 14.1723 11.5166 15.1846C11.5166 16.1968 11.9187 17.1676 12.6345 17.8834C13.3503 18.5991 14.3211 19.0013 15.3333 19.0013C16.3455 19.0013 17.3163 18.5991 18.0321 17.8834C18.7479 17.1676 19.15 16.1968 19.15 15.1846C19.15 14.1723 18.7479 13.2016 18.0321 12.4858C17.3163 11.77 16.3455 11.3679 15.3333 11.3679Z"
      fill={fill}
    />
  </svg>
);
