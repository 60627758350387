import React, { FC } from "react";

import {
  DonationItemReq,
  DonationItemRes,
} from "@n3oltd/k2.donations.sdk.donation-items/esm";
import { Input, Radio, Select } from "antd";

import { errorKeys } from "appRedux/models/common/Constants";
import { NamedLookup } from "appRedux/modules/lookups/types";
import GeneralFormItemV4 from "components/formItems/GeneralFormItemV4";
import { K2Card, K2Message, K2RadioGroup } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { donationItemFormLayouts } from "constants/FormLayouts";

const { Button } = Radio;
const { fieldsLayout } = donationItemFormLayouts;

interface IProps extends InjectedK2IntlProps {
  editMode: boolean;
  initialValues?: DonationItemRes;
  taxReliefRates: NamedLookup[];
  donationTypes: NamedLookup[];
}

const DonationItemBasicInfoForm: FC<IProps> = (props) => {
  const { k2Intl, taxReliefRates, donationTypes } = props;

  return (
    <K2Card
      bordered={false}
      shadow={true}
      localeKey={"admin.general"}
      style={{ marginBottom: 25 }}
    >
      <div>
        <GeneralFormItemV4
          {...fieldsLayout}
          labelKey="admin.donationItemName"
          colon
          name={"name"}
          rules={[
            {
              required: true,
              message: (
                <K2Message
                  localeKey={errorKeys.requiredValidationError}
                  values={{
                    fieldName: k2Intl?.formatMessage({
                      localeKey: "admin.donationItemName",
                    }),
                  }}
                />
              ),
            },
          ]}
        >
          <Input
            placeholder={k2Intl?.formatMessage({
              localeKey: "admin.donationItemNamePlaceholder",
            })}
          />
        </GeneralFormItemV4>

        <GeneralFormItemV4
          name={"notes"}
          colon
          {...fieldsLayout}
          labelKey="admin.donationItemNotes"
        >
          <Input
            placeholder={k2Intl?.formatMessage({
              localeKey: "admin.donationItemNotesPlaceholder",
            })}
          />
        </GeneralFormItemV4>

        <GeneralFormItemV4
          className="n3o-mb-0"
          {...fieldsLayout}
          colon
          name={"allowedDonationTypes"}
          labelKey="admin.allowedDonationTypes"
          rules={[{ type: "array" }]}
        >
          <Select
            mode="multiple"
            placeholder={k2Intl?.formatMessage({
              localeKey: "admin.allowedDonationTypes.select",
            })}
          >
            {donationTypes.map((donationType) => (
              <Select.Option value={donationType.id} key={donationType.id}>
                {donationType.name}
              </Select.Option>
            ))}
          </Select>
        </GeneralFormItemV4>

        <GeneralFormItemV4
          {...fieldsLayout}
          required
          name={"taxReliefRate"}
          labelKey={"donation.taxReliefRate"}
        >
          <K2RadioGroup buttonStyle="solid" size="small">
            {taxReliefRates?.map?.((status) => (
              <Button value={status.id} key={status.id}>
                {status.name}
              </Button>
            ))}
          </K2RadioGroup>
        </GeneralFormItemV4>
      </div>
    </K2Card>
  );
};

export default injectK2Intl(DonationItemBasicInfoForm);
