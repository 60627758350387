import { LookupType as DonationLookups } from "@n3oltd/k2.donations.sdk.lookups/esm";
import { LookupType as TaxReliefLookups } from "@n3oltd/karakoram.taxrelief.sdk.lookups";

import injectLookups from "appRedux/utils/injectLookups";

import AddEditDonationItem from "./AddEditDonationItem";
import composed from "./connect";

const withLookups = injectLookups(AddEditDonationItem, [
  TaxReliefLookups.TaxReliefRates,
  DonationLookups.DonationItemStatuses,
  DonationLookups.DonationTypes,
]);

export default composed(withLookups);
