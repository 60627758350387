import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const SendGrid: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 127.3 127.3"
  >
    <path fill={fill} d="M127.3 0H42.4v42.4H0v84.9h84.9V84.9h42.4z" />
    <path opacity=".4" fill={fill} d="M0 42.4v42.5h42.4v42.4h42.5V42.4z" />
    <path fill={fill} d="M0 84.9h42.4v42.4H0z" />
    <path fill={fill} d="M84.9 42.4V0H42.4v84.9h84.9V42.4z" />
    <path fill={fill} d="M84.9 0h42.4v42.4H84.9z" />
  </svg>
);
