import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import reducer from "./modules/reducer";
import saga from "./modules/saga";
import {
  fetchEmailTemplate,
  clearEditStatus,
  clearEmailTemplateError,
  updateEmailTemplate,
  createEmailTemplate,
  clearEmailTemplate,
  updateTemporaryAssets,
  uploadAsset,
  removeTemporaryAsset,
  clearTemporaryAssets,
  updateDirty,
  deleteEmailTemplate,
} from "./modules/actions";
import IApplicationState from "appRedux/types";

const mapStateToProps = (state: IApplicationState) => {
  return state.emailTemplate;
};

const mapDispatchToProps = {
  fetchEmailTemplate,
  clearEditStatus,
  clearEmailTemplateError,
  updateEmailTemplate,
  createEmailTemplate,
  clearEmailTemplate,
  updateTemporaryAssets,
  uploadAsset,
  removeTemporaryAsset,
  clearTemporaryAssets,
  updateDirty,
  deleteTemplate: deleteEmailTemplate,
};

const withSaga = injectSaga({ key: "emailTemplate", saga });
const withReducer = injectReducer({ key: "emailTemplate", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof withConnect>;

export default compose(withSaga, withReducer, withConnect);
