import { AccountInfoRes, PhoneNumber } from "@n3oltd/k2.calls.sdk.agents";

export enum CallType {
  Out = "out",
  In = "in",
}

export enum OutBoundViewType {
  Ready = "readyforcall",
  Preview = "preview",
  Calling = "calling",
  EndCall = "endcall",
  Dispositions = "dispositions",
}

export interface AppointmentPreview {
  scheduledOn: string;
  assignedTo: string;
  notes: string;
}

export interface AccountInfo extends AccountInfoRes {
  phoneNumber: PhoneNumber;
}
