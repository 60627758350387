import { ColProps } from "antd/lib/col";

interface ILWInput {
  lbl: number;
  wpr: number;
  lblOffset: number;
  wprOffset: number;
}

type SizeType = "xxl" | "xl" | "lg" | "md" | "sm" | "xs";

export interface ILabelWrapperLayout {
  labelCol: ColProps;
  wrapperCol: ColProps;
}

class LWLayout implements ILabelWrapperLayout {
  public labelCol: ColProps = {};
  public wrapperCol: ColProps = {};

  private constructor() {}

  public static create() {
    return new LWLayout();
  }

  private add(sizeType: SizeType, input: ILWInput) {
    this.labelCol[sizeType] = { span: input.lbl, offset: input.lblOffset };
    this.wrapperCol[sizeType] = { span: input.wpr, offset: input.wprOffset };
    return this;
  }

  addXXL(lbl: number, wpr: number, lblOffset?: number, wprOffset?: number) {
    return this.add("xxl", { lbl, wpr, lblOffset, wprOffset });
  }

  addXL(lbl: number, wpr: number, lblOffset?: number, wprOffset?: number) {
    return this.add("xl", { lbl, wpr, lblOffset, wprOffset });
  }

  addLG(lbl: number, wpr: number, lblOffset?: number, wprOffset?: number) {
    return this.add("lg", { lbl, wpr, lblOffset, wprOffset });
  }

  addMD(lbl: number, wpr: number, lblOffset?: number, wprOffset?: number) {
    return this.add("md", { lbl, wpr, lblOffset, wprOffset });
  }

  addSM(lbl: number, wpr: number, lblOffset?: number, wprOffset?: number) {
    return this.add("sm", { lbl, wpr, lblOffset, wprOffset });
  }
  addXS(lbl: number, wpr: number, lblOffset?: number, wprOffset?: number) {
    return this.add("xs", { lbl, wpr, lblOffset, wprOffset });
  }
}

export const rolesFormLayouts = {
  fieldsLayout: LWLayout.create().addMD(24, 12).addSM(24, 14).addXS(24, 24),
  multiSelectorLayout: LWLayout.create()
    .addMD(5, 19)
    .addSM(10, 14)
    .addXS(24, 24),
};

export const usersFormLayouts = {
  fieldsLayout: LWLayout.create().addMD(24, 14).addXS(24, 24),
  settingsLabelsLayout: LWLayout.create()
    .addMD(5, 6)
    .addSM(10, 6)
    .addXS(24, 24),
  formLabelsLayout: LWLayout.create().addMD(6, 14).addSM(10, 14).addXS(24, 24),
  multiSelectorLayout: LWLayout.create()
    .addMD(5, 19)
    .addSM(10, 14)
    .addXS(24, 24),
};

export const SubscriptionSettingsLayouts = {
  generalSettingsLayout: LWLayout.create()
    .addMD(6, 12)
    .addSM(8, 24)
    .addXS(24, 24),
  localizationLayout: LWLayout.create()
    .addXS(24, 24)
    .addMD(24, 10)
    .addXL(24, 8),
};

export const accountFormLayouts = {
  fieldsLayout: LWLayout.create().addMD(24, 14).addSM(24, 24),
  fieldsLayoutOneFourth: LWLayout.create().addMD(24, 6).addSM(24, 24),
  fieldsLayoutFull: LWLayout.create().addMD(24, 24).addSM(24, 24),
  addressFieldsLayout: LWLayout.create().addMD(24, 12).addSM(24, 24),
  labelFieldsLayout: LWLayout.create().addMD(6, 14).addSM(10, 14).addXS(24, 24),
  switchFieldLayout: LWLayout.create()
    .addMD(12, 12)
    .addSM(12, 12)
    .addXS(24, 24),
  phoneEmailLayout: LWLayout.create()
    .addXXL(24, 18)
    .addXL(24, 18)
    .addLG(24, 16)
    .addMD(24, 24)
    .addSM(24, 24),
  consentLayout: LWLayout.create().addMD(0, 24).addSM(0, 24).addXS(0, 24),
  formsContainer: LWLayout.create()
    .addMD(0, 24, 0, 0)
    .addSM(0, 24, 0, 0)
    .addXS(0, 24, 0, 0),
  GDPRFieldsLayout: LWLayout.create().addMD(24, 24),
};

export const donationItemFormLayouts = {
  fieldsLayout: LWLayout.create().addMD(24, 12).addSM(24, 12).addXS(24, 24),
  labelFieldsLayout: LWLayout.create()
    .addMD(24, 12)
    .addSM(24, 12)
    .addXS(24, 24),
  switchFieldLayout: LWLayout.create()
    .addMD(12, 12)
    .addSM(12, 12)
    .addXS(24, 24),
};

export const donationFormLayouts = {
  fieldsLayout: LWLayout.create().addMD(6, 10).addSM(10, 14).addXS(24, 24),
  labelFieldsLayout: LWLayout.create().addMD(6, 14).addSM(10, 14).addXS(24, 24),
  switchFieldLayout: LWLayout.create()
    .addMD(12, 12)
    .addSM(12, 12)
    .addXS(24, 24),
};

export const donationPaymentFormLayouts = {
  fieldsLayout: LWLayout.create().addMD(6, 14).addSM(10, 14).addXS(24, 24),
  labelFieldsLayout: LWLayout.create().addMD(6, 14).addSM(10, 14).addXS(24, 24),
  switchFieldLayout: LWLayout.create()
    .addMD(12, 12)
    .addSM(12, 12)
    .addXS(24, 24),
};

export const allocationsFormLayouts = {
  allocationAmountLayout: LWLayout.create()
    .addMD(0, 24)
    .addSM(0, 24)
    .addXS(0, 24),
  fund: {
    selectorsLayout: LWLayout.create().addMD(0, 24).addSM(0, 24).addXS(0, 24),
  },
  sponsorship: {
    selectors: LWLayout.create().addMD(0, 24).addSM(0, 24).addXS(0, 24),
    components: LWLayout.create().addMD(0, 24).addSM(0, 24).addXS(0, 24),
  },
  childSearchLayout: LWLayout.create().addMD(6, 10).addSM(10, 14).addXS(24, 24),
};

export const cardsFormLayouts = {
  fieldsLayout: LWLayout.create().addMD(6, 18).addSM(10, 14).addXS(24, 24),
};

const RowItemLayout = LWLayout.create().addMD(0, 24).addSM(0, 24).addXS(0, 24);

export const ActionsItemLayout = RowItemLayout;

export const tailFormItemLayout = RowItemLayout;

export const formItemLayout = LWLayout.create()
  .addMD(4, 20)
  .addSM(8, 16)
  .addXS(24, 24);

export const formItemLayoutExtended = LWLayout.create()
  .addMD(6, 18)
  .addSM(8, 16)
  .addXS(24, 24);

export const tailFormItemLayoutWithoutLabel = LWLayout.create()
  .addXL(0, 24, 0, 0)
  .addLG(0, 16, 0, 8)
  .addMD(0, 18, 0, 6)
  .addSM(0, 18, 0, 6)
  .addXS(0, 18, 0, 6);

export const generalLayout = {
  halfWidthFieldsLayout: LWLayout.create().addMD(24, 12).addSM(24, 24),
  fullWidthFieldsLayout: LWLayout.create().addMD(24, 24),
};

export const editTaxSchemeLayout = {
  fieldsLayout: LWLayout.create().addMD(24, 14).addSM(24, 24),
  feildsLayoutOneLessHalf: LWLayout.create().addMD(24, 10).addSM(24, 24),
  fieldspostalCode: LWLayout.create().addMD(24, 6).addSM(24, 24),
};
