import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const Bookmark: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16.5455L18 22V3C18 2.44772 17.5523 2 17 2H7C6.44772 2 6 2.44772 6 3V22L12 16.5455ZM7.7 18.1571L12 14.248L16.3 18.1571V3.7H7.7V18.1571Z"
      fill={fill}
    />
  </svg>
);
