import { LookupType as CommsLookupType } from "@n3oltd/k2.communications.sdk.lookups";
import { LookupType as TemplateLookupTypes } from "@n3oltd/karakoram.templates.sdk.lookups";

import injectLookups from "appRedux/utils/injectLookups";

import NewEditEmailTemplate from "./NewEditEmailTemplate";
import composed from "./connect";

const withLookups = injectLookups(NewEditEmailTemplate, [
  TemplateLookupTypes.AssetTypes,
  CommsLookupType.Categories,
]);

export default composed(withLookups);
