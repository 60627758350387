import {
  OfficeReq,
  OrganizationInformationReq,
  OrganizationInformationRes,
} from "@n3oltd/k2.subscriptions.sdk.organization/esm";
import { OfficeRes } from "@n3oltd/k2.subscriptions.sdk.organization/src/index";
import {
  LocalizationSettingsReq,
  LocalizationSettingsRes,
} from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { ImmerReducer } from "immer-reducer";

import ApiResponseModel, {
  IApiResponse,
  ServerError,
} from "../../../models/common/ApiResponseModel";
import { EditStatus } from "../../sharedTypes";
import { SettingsManagement } from "../types";

export const settingsInitialState: SettingsManagement = {
  organization: {
    organizationDetails: null,
    isLoading: false,
    isSaving: false,
    editStatus: null,
    officesEditStatus: null,
  },
  localizations: {
    localizationDetails: null,
    isLoading: false,
    isSaving: false,
    editStatus: null,
    dirty: false,
  },
  addresses: {
    countryList: [],
    countrySelected: "GB", // TODO: this needs to be set by default country of operation from subscription every time subscription is get
    isLoading: false,
  },
};

export class SubscriptionSettingsReducer extends ImmerReducer<SettingsManagement> {
  //#region organization
  subscriptionOrganizationLoading(isLoading: boolean) {
    this.draftState.organization.isLoading = isLoading;
  }

  fetchSubscriptionOrganization() {
    this.subscriptionOrganizationLoading(true);
  }

  setSubscriptionOrganization(
    response: IApiResponse<OrganizationInformationRes>,
  ) {
    this.draftState.organization.organizationDetails = response.getResultOrDefault();
    this.subscriptionOrganizationLoading(false);
  }

  updateSubscriptionOrganization(
    revisionId: string,
    _req: OrganizationInformationReq,
  ) {
    this.draftState.organization.isSaving = true;
  }

  setSubscriptionOrganizationEditStatus(editStatus: EditStatus<void>) {
    this.draftState.organization.editStatus = editStatus;
    this.draftState.organization.isSaving = false;
  }

  setSubscriptionOfficesEditStatus(editStatus: EditStatus<any>) {
    this.draftState.organization.officesEditStatus = editStatus;
    this.draftState.organization.isSaving = false;
  }

  addSubscriptionOrganizationOffice(revisionId: string, _req: OfficeReq) {
    this.draftState.organization.isSaving = true;
  }

  updateSubscriptionOrganizationOffice(
    revisionId: string,
    _officeId: string,
    _req: OfficeReq,
  ) {
    this.draftState.organization.isSaving = true;
  }

  deleteSubscriptionOrganizationOffice(revisionId: string, _officeId: string) {
    this.draftState.organization.isSaving = true;
  }

  updateSubscriptionOrganizationOfficesListAfterDelete(
    _deletedOfficeId: string,
  ) {
    this.draftState.organization.organizationDetails.offices = this.draftState.organization.organizationDetails.offices.filter(
      (office) => _deletedOfficeId !== office.id,
    );
    this.draftState.organization.isSaving = false;
  }

  setError(errorInfo: { id: string; error: ServerError }) {
    this.draftState.organization.errors.push(errorInfo);
  }

  removeSubscriptionOrganizationError(id: string) {
    this.draftState.organization.errors = this.draftState.organization.errors.filter(
      (e) => {
        return e.id !== id;
      },
    );
  }

  clearSubscriptionOrganization() {
    this.draftState.organization.organizationDetails = null;
  }
  //#endregion

  //#region localization
  subscriptionLocalizationsLoading(isLoading: boolean) {
    this.draftState.localizations.isLoading = isLoading;
  }

  fetchSubscriptionLocalizations() {
    this.subscriptionLocalizationsLoading(true);
  }

  setLocalizationsDirty() {
    this.draftState.localizations.dirty = true;
  }

  clearLocalizationsDirty() {
    this.draftState.localizations.dirty = false;
  }

  setSubscriptionLocalizations(
    response: IApiResponse<LocalizationSettingsRes>,
  ) {
    this.draftState.localizations.localizationDetails = response.getResultOrDefault();
    this.subscriptionLocalizationsLoading(false);
  }

  clearSubscriptionLocalizations() {
    this.setSubscriptionLocalizations(new ApiResponseModel(null));
  }

  updateSubscriptionLocalizations(
    subscriptionId: string,
    _localizationDetails: LocalizationSettingsReq,
  ) {
    this.draftState.localizations.isSaving = true;
  }

  setSubscriptionLocalizationsEditStatus(editStatus: EditStatus<any>) {
    this.draftState.localizations.editStatus = editStatus;
    this.draftState.localizations.isSaving = false;
  }

  clearLocalizationEditStatus() {
    this.setSubscriptionLocalizationsEditStatus(null);
  }
  //#endregion
}
