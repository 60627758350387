import React from "react";

import {
  FacebookOutlined,
  GlobalOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons/lib";
import { FormInstance } from "antd/es/form";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import ReactHtmlParser from "react-html-parser";
import { CustomScrollAction } from "scroll-into-view-if-needed/typings/types";

import { ServerErrorHandler } from "appRedux/models";
import {
  IValidationErrorWithSeverity,
  K2StatusCodes,
  ServerError,
} from "appRedux/models/common/ApiResponseModel";
import {
  IValidationErrorV4,
  IValidationErrors,
  ServerErrorModel,
} from "appRedux/models/common/ServerErrorHandler";
import { EditStatus } from "appRedux/modules/sharedTypes";
import { K2MessageValue } from "components/k2Widgets/k2Localizations/types";
import { showNotification } from "components/utils/Notification";

import { InjectedK2Intl } from "../k2Widgets/k2Localizations/types";

export const scrollToFirstErrorWithOffset = (
  form: FormInstance,
  err: ValidateErrorEntity,
) => {
  form.scrollToField(err.errorFields?.[0]?.name, {
    behavior: (actions: CustomScrollAction[]) => {
      return actions.forEach(({ el, top, left }) => {
        // -100 accounts for the sticky header
        el.scrollTop = top - 100;
        el.scrollLeft = left;
      });
    },
  });
};

export const messageToHtml = (
  k2Intl: InjectedK2Intl,
  localeKey: string,
  values?: { [key: string]: K2MessageValue },
) => {
  const formattedData = k2Intl?.formatHtmlMessage({ localeKey, values });
  return ReactHtmlParser(formattedData);
};

interface DataUpdateResultInput<T> {
  onError?: () => void;
  k2Intl: InjectedK2Intl;
  status: EditStatus<T>;
  successTitle: string;
  successMessage: string;
  messageValues?: { [key: string]: string };
  form?: FormInstance;
  onSuccess?: () => void;
}

function handleDataUpdateResult<T>(options: DataUpdateResultInput<T>) {
  const {
    k2Intl,
    status,
    successTitle: titleKey,
    successMessage: messageKey,
    messageValues,
    onSuccess,
    form,
    onError,
  } = options;
  if (!status.error) {
    showNotification({
      k2Intl,
      titleKey,
      messageKey,
      type: "success",
      messageValues,
    });
    if (onSuccess) {
      onSuccess();
    }
  } else {
    if (form && status.error.status === K2StatusCodes.preconditionFailed) {
      handleValidationErrors(status.error, form);
    } else {
      handleServerError(k2Intl, status.error, onError);
    }
  }
}

function handleServerError(
  k2Intl: InjectedK2Intl,
  error: ServerError,
  clearServerError?: () => void,
) {
  if (error) {
    const errorHandler = new ServerErrorHandler(k2Intl, error);
    errorHandler.handleGeneric();
    clearServerError?.();
  }
}

function showValidationErrorsNotification(
  k2Intl: InjectedK2Intl,
  error: ServerError,
  clearServerError?: () => void,
) {
  if (
    error.status !== K2StatusCodes.preconditionFailed ||
    !error.data ||
    !error.data.errors
  )
    return;

  showNotification({
    type: "error",
    message: (error.data.errors as Array<IValidationErrorWithSeverity>)
      ?.map?.((error) => error.error)
      ?.map?.((e, i) => <p key={i}>{e}</p>),
    duration: 100,
    k2Intl,
    titleKey: "common.nonDismissableFormErrorTitle",
  });
  if (clearServerError) {
    clearServerError();
  }
}

function handleValidationErrors(
  error: ServerError,
  form: FormInstance,
  formName?: string,
): IValidationErrorV4[] | IValidationErrors {
  const errorFields = ServerErrorModel.getV4ValidationErrors(error);
  form?.setFields(errorFields);

  // //TODO: this is just for testing purposes.
  // form
  //   ?.validateFields()
  //   .then((nameList?: NamePath[]) => {
  //     console.log({ formName, nameList });
  //   })
  //   .catch((errorInfo: ValidateErrorEntity) => {
  //     console.error({ formName, errorInfo });
  //   });

  return errorFields;
}

/**
 *
 * @param error The Error
 * @param type What type of errors we want to extract (error, warning etc)
 * @param errorType Should we only get the errors that do not have a property (nonFieldRelated)? Errors without a property
 * represent form-level errors such as Idempotency Key exceptions, or mid air collisions
 * Errors with a property represent errors related to a specific field.
 * @param specificKeys Should we extract errors with specific properties only?
 */
function getOverallValidationErrors(
  error: ServerError,
  type: "warning" | "error" | "all" = "all",
  errorType: "fieldRelated" | "nonFieldRelated" | "all" = "fieldRelated",
  specificKeys?: string[],
): IValidationErrorWithSeverity[] {
  if (
    error.status !== K2StatusCodes.preconditionFailed ||
    !error.data ||
    !error.data.errors
  )
    return [];

  // Generic/overall validation errors, that cannot be linked to a specific field, have no "property" key
  return (error.data.errors as Array<IValidationErrorWithSeverity>).filter(
    (error) => {
      let shouldInclude =
        errorType === "all"
          ? true
          : errorType === "fieldRelated"
          ? Boolean(error.property)
          : !Boolean(error.property);

      if (specificKeys && shouldInclude) {
        shouldInclude = specificKeys.includes(error.property);
      }

      switch (type) {
        case "warning":
          return shouldInclude && error.severity === "warning";
        case "error":
          return shouldInclude && error.severity === "error";
        default:
          return shouldInclude;
      }
    },
  );
}

function getSocialIcon(iconId: string) {
  switch (iconId) {
    case "website":
      return <GlobalOutlined />;
    case "linkedIn":
      return <LinkedinOutlined />;
    case "instagram":
      return <InstagramOutlined />;
    case "facebook":
      return <FacebookOutlined />;
    case "youtube":
      return <YoutubeOutlined />;
    case "twitter":
      return <TwitterOutlined />;
  }
}

// this function is used when you don't have form element to show validation
function handleError(
  k2Intl: InjectedK2Intl,
  error: ServerError,
  clearServerError?: () => void,
) {
  if (error.status === K2StatusCodes.preconditionFailed)
    showValidationErrorsNotification(k2Intl, error, clearServerError);
  else handleServerError(k2Intl, error, clearServerError);
}

export default {
  messageToHtml,
  handleDataUpdateResult,
  handleServerError,
  handleValidationErrors,
  showValidationErrorsNotification,
  getOverallValidationErrors,
  getSocialIcon,
  scrollToFirstErrorWithOffset,
  handleError,
};
