import {
  LetterheadReq,
  LetterheadRes,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

export enum ActionTypes {
  FETCH_LETTERHEAD = "@engage/admin/communication/pdfStationery/request",
  FETCH_LETTERHEAD_SUCCESS = "@engage/admin/communication/pdfStationery/success",
  FETCH_LETTERHEAD_ERROR = "@engage/admin/communication/pdfStationery/error",
  CLEAR_LETTERHEAD = "@engage/admin/communication/pdfStationery/clear",
  CLEAR_LETTERHEAD_ERROR = "@engage/admin/communication/pdfStationery/clear-error",

  UPDATE_LETTERHEAD = "@engage/admin/communication/pdfStationery/update/request",
  UPDATE_LETTERHEAD_SUCCESS = "@engage/admin/communication/pdfStationery/update/success",
  UPDATE_LETTERHEAD_ERROR = "@engage/admin/communication/pdfStationery/update/error",

  CREATE_LETTERHEAD = "@engage/admin/communication/pdfStationery/create/request",
  CREATE_LETTERHEAD_SUCCESS = "@engage/admin/communication/pdfStationery/create/success",
  CREATE_LETTERHEAD_ERROR = "@engage/admin/communication/pdfStationery/create/error",

  CLEAR_LETTERHEAD_EDIT_STATUS = "@engage/admin/communication/pdfStationery/clear-edit-status",

  UPDATE_DIRTY = "@engage/admin/communication/pdfStationery/update-dirty",
}

export interface FetchLetterhead {
  type: ActionTypes.FETCH_LETTERHEAD;
  id: string;
}

export interface FetchLetterheadSuccess {
  type: ActionTypes.FETCH_LETTERHEAD_SUCCESS;
  data: LetterheadRes;
}

export interface FetchLetterheadError {
  type: ActionTypes.FETCH_LETTERHEAD_ERROR;
  error: ServerError;
}

export interface ClearLetterheadError {
  type: ActionTypes.CLEAR_LETTERHEAD_ERROR;
}

export interface ClearLetterhead {
  type: ActionTypes.CLEAR_LETTERHEAD;
}

export interface UpdateLetterhead {
  type: ActionTypes.UPDATE_LETTERHEAD;
  revisionId: string;
  data: LetterheadReq;
}

export interface UpdateLetterheadSuccess {
  type: ActionTypes.UPDATE_LETTERHEAD_SUCCESS;
  editStatus: EditStatus;
}

export interface UpdateLetterheadError {
  type: ActionTypes.UPDATE_LETTERHEAD_ERROR;
  editStatus: EditStatus;
}

export interface CreateLetterhead {
  type: ActionTypes.CREATE_LETTERHEAD;
  data: LetterheadReq;
}

export interface CreateLetterheadSuccess {
  type: ActionTypes.CREATE_LETTERHEAD_SUCCESS;
  editStatus: EditStatus;
}

export interface CreateLetterheadError {
  type: ActionTypes.CREATE_LETTERHEAD_ERROR;
  editStatus: EditStatus;
}

export interface ClearEditStatus {
  type: ActionTypes.CLEAR_LETTERHEAD_EDIT_STATUS;
}

export interface UpdateDirty {
  type: ActionTypes.UPDATE_DIRTY;
  dirty: boolean;
}

export type AddEditPdfStationeryAction =
  | FetchLetterhead
  | FetchLetterheadError
  | FetchLetterheadSuccess
  | ClearLetterhead
  | ClearLetterheadError
  | ClearEditStatus
  | CreateLetterhead
  | CreateLetterheadError
  | CreateLetterheadSuccess
  | UpdateLetterhead
  | UpdateLetterheadError
  | UpdateLetterheadSuccess
  | UpdateDirty;
