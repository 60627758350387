import React, { FC, useCallback } from "react";

import { InfoCircleTwoTone } from "@ant-design/icons/lib";
import { ModifiedInfoRes } from "@n3oltd/k2.calls.sdk.calls";
import { Divider } from "antd";
import styled from "styled-components";

import { K2DateTime, K2Tooltip } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

interface IProps {
  modifiedInfo: ModifiedInfoRes;
  showAdditionalInfoWithIcon?: boolean;
  k2Intl: InjectedK2Intl;
  timeMode?: "date" | "dateAndTime";
  /*
  What property to display as text. The createdAt and updatedAt details will be displayed in the tooltip
  */
  mode: "createdAt" | "updatedAt" | "createdBy" | "updatedBy";
}

const WhiteDivider = styled(Divider)`
  margin: 12px 0;
  background-color: ${({ theme }) => theme.white_color};
`;

const ModifiedInfo: FC<IProps> = ({
  modifiedInfo,
  showAdditionalInfoWithIcon,
  k2Intl,
  mode,
  timeMode = "dateAndTime",
}) => {
  const renderText = useCallback(() => {
    switch (mode) {
      case "createdAt":
        return (
          <K2DateTime value={modifiedInfo.created.timestamp} mode={timeMode} />
        );
      case "updatedAt":
        return (
          <K2DateTime value={modifiedInfo.updated.timestamp} mode={timeMode} />
        );
      case "createdBy":
        return modifiedInfo.created.name;
      case "updatedBy":
        return modifiedInfo.updated.name;
    }
  }, [modifiedInfo, mode, timeMode]);

  const renderTooltip = useCallback(
    (children: React.ReactNode) => {
      return (
        <K2Tooltip
          title={
            <div>
              {UIUtils.messageToHtml(k2Intl, "common.createdBy.long", {
                name: modifiedInfo.created.name,
                date: k2Intl.formatDateTime(
                  new Date(modifiedInfo.created?.timestamp),
                ),
              })}
              <WhiteDivider />
              {UIUtils.messageToHtml(k2Intl, "common.updatedBy.long", {
                name: modifiedInfo.updated?.name,
                date: k2Intl.formatDateTime(
                  new Date(modifiedInfo.updated?.timestamp),
                ),
              })}
            </div>
          }
        >
          {children}
        </K2Tooltip>
      );
    },
    [k2Intl, modifiedInfo],
  );

  if (!modifiedInfo?.created) return null;

  if (showAdditionalInfoWithIcon) {
    return (
      <>
        {renderText()}
        <span style={{ marginLeft: 8 }}>
          {renderTooltip(<InfoCircleTwoTone />)}
        </span>
      </>
    );
  } else {
    return renderTooltip(renderText());
  }
};

export default ModifiedInfo;
