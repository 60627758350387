import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { K2Routes } from "appRedux/models/routes/RouteModel";
import ErrorsList from "./errorsList";

const TemporaryRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Route path={K2Routes.temporaryErrorsList} component={ErrorsList} />
    </Switch>
  );
};

export default TemporaryRoutes;
