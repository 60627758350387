import React, { FC, useCallback } from "react";

import { DeleteTwoTone, EllipsisOutlined } from "@ant-design/icons/lib";
import { ModifiedInfoRes } from "@n3oltd/k2.users.sdk.roles/esm";
import { Col, Dropdown, Menu, Row } from "antd";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import ItemSummaryRow from "components/admin/shared/ItemSummaryRow";
import { K2Button, K2Card, K2DateTime, K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { showConfirm } from "components/utils/Confirmation";

interface ModifiedInfoDetailsPanelProps extends InjectedK2IntlProps {
  modifiedInfo: ModifiedInfoRes;
  onDelete: (revisionId: string) => void;
  saving: boolean;
  confirmationTitleKey: string;
  confirmationMessageKey?: string;
  revisionId: string;
  canDelete?: boolean;
}

const ModifiedInfoDetailsPanel: FC<ModifiedInfoDetailsPanelProps> = ({
  modifiedInfo,
  onDelete,
  k2Intl,
  saving,
  confirmationTitleKey,
  confirmationMessageKey,
  canDelete,
  revisionId,
}) => {
  const { theme } = useAppContext();

  const confirmDelete = useCallback((): void => {
    showConfirm({
      titleKey: confirmationTitleKey,
      contentKey: confirmationMessageKey,
      k2Intl,
      okTextKey: "common.delete",
      okButtonProps: {
        danger: true,
      },
      centered: true,
      onOk: () => {
        onDelete(revisionId);
      },
    });
  }, [
    k2Intl,
    revisionId,
    onDelete,
    confirmationTitleKey,
    confirmationMessageKey,
  ]);

  return (
    <K2Card
      bordered={false}
      shadow={true}
      loading={!modifiedInfo}
      localeKey={"common.details"}
      style={{ marginBottom: 25 }}
      extra={
        modifiedInfo ? (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={confirmDelete} disabled={!canDelete}>
                  <DeleteTwoTone twoToneColor={theme.error_color} />
                  <K2Message localeKey={"common.delete"} />
                </Menu.Item>
              </Menu>
            }
            placement={"bottomRight"}
            trigger={["click"]}
            disabled={!modifiedInfo || saving}
          >
            <K2Button>
              <EllipsisOutlined />
            </K2Button>
          </Dropdown>
        ) : null
      }
    >
      <Row>
        <Col xs={24}>
          <ItemSummaryRow
            labelKey={"common.created"}
            value={
              <>
                <K2DateTime value={modifiedInfo?.created?.timestamp} />{" "}
                {k2Intl
                  .formatMessage({
                    localeKey: "common.by",
                  })
                  .toLowerCase()}
                <span className="n3o-ml-1">{modifiedInfo?.created?.name}</span>
              </>
            }
          />
          {modifiedInfo?.updated && (
            <ItemSummaryRow
              labelKey={"common.lastUpdated"}
              value={
                <>
                  <K2DateTime value={modifiedInfo?.updated?.timestamp} />{" "}
                  {k2Intl
                    .formatMessage({
                      localeKey: "common.by",
                    })
                    .toLowerCase()}
                  <span className="n3o-ml-1">
                    {modifiedInfo?.updated?.name}
                  </span>
                </>
              }
            />
          )}
        </Col>
      </Row>
    </K2Card>
  );
};

export default injectK2Intl(ModifiedInfoDetailsPanel);
