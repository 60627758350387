import React, { useEffect, useCallback, useState } from "react";
import { Skeleton, Button, Progress, Col } from "antd";
import styled from "styled-components";
import { GettingStartedProps } from "./modules/types";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import history from "appRedux/utils/history";
import { K2Card, K2Message } from "components/k2Widgets";
import RecentActivities from "../recentActivities";
import merge from "lodash/merge";
import { TitleWrapper } from "components/dashboard";

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.grey_7};
  margin-bottom: 34px;
  text-align: center;

  img {
    margin-bottom: 16px;
  }

  span {
    margin: 32px 0;
    text-align: center;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.grey_6};
  font-weight: ${({ theme }) => theme.font_weight_semi_bold};

  .completed {
    color: ${({ theme }) => theme.grey_7};
  }
`;

const ActionButton = styled(Button)`
  width: 100%;
  margin-top: 22px;
`;

const GettingStartedCard = styled(K2Card)`
  background: #fff;
  border-radius: 8px;
  margin: 24px 0;

  .ant-progress-bg {
    background: ${({ theme }) => theme.link_color} !important;
  }
`;

const GettingStarted: React.FC<GettingStartedProps> = (props) => {
  const {
    fetchGettingStartedProgress,
    completeGettingStarted,
    currentStep,
    fetching,
    totalSteps,
    completedSteps,
    image,
    isAllCompleted,
  } = props;

  const [showActivities, setShowActivities] = useState(false);

  useEffect((): void => {
    fetchGettingStartedProgress();
  }, [fetchGettingStartedProgress]);

  const handleAction = useCallback(() => {
    const routes = merge(K2Routes, K2Routes.main);

    if (!isAllCompleted) {
      if (currentStep[0] !== "final") {
        history.push(routes[currentStep[0]]);
      } else {
        completeGettingStarted();
        setShowActivities(true);
      }
    }
  }, [currentStep, completeGettingStarted, isAllCompleted]);

  return (
    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
      {fetching ? (
        <K2Card shadow>
          <Skeleton active loading />
        </K2Card>
      ) : isAllCompleted || showActivities ? (
        <RecentActivities maxHeight={467} k2Intl={props.k2Intl} />
      ) : (
        <>
          <TitleWrapper level={4}>
            <K2Message localeKey="dashboardTitle.gettingStarted" />
          </TitleWrapper>
          <GettingStartedCard
            shadow={true}
            className="n3o-card-recent-activities"
          >
            <DescriptionContainer>
              <img src={image} alt="sparks background" />
              <p style={{ fontSize: 16 }}>{currentStep[1].title}</p>
            </DescriptionContainer>

            <ProgressContainer>
              <K2Message localeKey="dashboard.getting.started.your.progress" />
              <span className="completed">
                {completedSteps}/{totalSteps}{" "}
                <K2Message localeKey="common.completed" />
              </span>
            </ProgressContainer>
            <Progress
              percent={(completedSteps / totalSteps) * 100}
              showInfo={false}
            />
            <ActionButton onClick={handleAction} style={{ height: 40 }}>
              {currentStep[1].buttonText}
            </ActionButton>
          </GettingStartedCard>
        </>
      )}
    </Col>
  );
};

export default GettingStarted;
