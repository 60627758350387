import React, { FC, useEffect, useRef, useState } from "react";

import { generateUuid } from "@azure/ms-rest-js";
import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { EmailCompositionRes } from "@n3oltd/karakoram.templates.sdk.email";
import {
  EmailCompositionReq,
  PreviewEmailCompositionReq,
  RenderEmailRes,
} from "@n3oltd/karakoram.templates.sdk.email/esm";
import { Skeleton } from "antd";
import { FormInstance } from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import { useRouter } from "hooks";
import styled from "styled-components";

import { _emailCompositionsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import {
  AssetTypesLookups,
  CategoriesLookups,
} from "appRedux/modules/lookups/types";
import { DataActions } from "appRedux/modules/sharedTypes";
import NotFoundWrapper from "components/NotFoundWrapper";
import { K2RouteHeading } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { FormsWrapper } from "components/layout/wrappers";
import { UIUtils } from "components/utils";
import { showConfirm, showError } from "components/utils/Confirmation";
import { showNotification } from "components/utils/Notification";
import {
  REDIRECT_AFTER_SAVE_MS,
  TEMPLATE_ENGINE,
} from "constants/appConstants";
import useBreadcrumb from "hooks/useBreadcrumb";
import EmailPreview from "routes/admin/communication/newEditEmailTemplate/components/EmailPreview";

import CreateEditEmailTemplateForm from "./components/CreateEditEmailTemplateForm";
import { PropsFromRedux } from "./connect";

const CardWrapper = styled.div`
  margin-top: 2rem;
`;

type EmailTemplatesProps = PropsFromRedux &
  InjectedK2IntlProps & {
    assetTypes: AssetTypesLookups;
    categories: CategoriesLookups;
    scopeId?: string;
    onSaveSuccess?: (action: DataActions) => void;
    setForm?: (form: FormInstance) => void;
    initialData?: EmailCompositionRes;
    fromOutboxes: boolean;
  };

const NewEditEmailTemplate: FC<EmailTemplatesProps> = (props) => {
  const {
    updateEmailTemplate,
    createEmailTemplate,
    data,
    fetchEmailTemplate,
    loading,
    error,
    clearEmailTemplateError,
    clearEmailTemplate,
    k2Intl,
    editStatus,
    updateDirty,
    clearEditStatus,
    scopeId,
    onSaveSuccess,
    setForm,
    initialData,
    fromOutboxes,
  } = props;
  const router = useRouter<{ templateId?: string }>();

  const templateId = router.match.params.templateId;
  const editMode = !!templateId || !!initialData;

  const templateData = initialData || data;

  // These are the assets that are currently visible on the Media Assets list,
  // including new assets that have been added
  const [tempAssets, setTempAssets] = useState<AssetRes[]>([]);

  // These are the attachment assets that are currently visible on the Attachments list,
  // including new assets that have been added
  const [tempAttachmentAssets, setTempAttachmentAssets] = useState<AssetRes[]>(
    [],
  );

  const [overallErrors, setOverallErrors] = useState<string[]>([]);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [preview, setPreview] = useState<RenderEmailRes>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [previewError, setPreviewError] = useState<string[]>([]);
  const ignoreWarningsRef = useRef(false);

  const [form] = useForm();
  if (setForm) {
    setForm(form);
  }

  useEffect(() => {
    return () => {
      form.resetFields();
      clearEmailTemplate();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      if (error.status === K2StatusCodes.notFound) {
        setNotFound(true);
        clearEmailTemplateError();
      } else {
        UIUtils.handleServerError(k2Intl, error, clearEmailTemplateError);
      }
    }
  }, [error, clearEmailTemplateError, k2Intl]);

  const setBreadcrumbs = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbs([
      {
        path: K2Routes.main.admin,
        titleKey: "mainNav.admin",
        active: false,
      },
      {
        path: K2Routes.communicationsEmailTemplates,
        titleKey: `admin.communication.email.templates`,
        active: false,
      },
      {
        path: editMode
          ? K2Routes.communicationsEditEmailTemplate
          : K2Routes.communicationsNewEmailTemplate,
        title: editMode && templateData ? templateData.name : null,
        titleKey: `admin.communication.templates.${editMode ? "edit" : "new"}`,
        active: true,
      },
    ]);
  }, [setBreadcrumbs, editMode, templateData]);

  useEffect(() => {
    if (editStatus) {
      switch (editStatus.action) {
        case DataActions.add:
        case DataActions.update:
          if (editStatus.error) {
            if (editStatus.error.status === K2StatusCodes.preconditionFailed) {
              // Handle field-level validation errors and set them on the form
              UIUtils.handleValidationErrors(editStatus.error, form);

              const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
                editStatus.error,
                "error",
                "all",
              );
              const nonFieldRelatedWarnings = UIUtils.getOverallValidationErrors(
                editStatus.error,
                "warning",
                "all",
              );

              if (
                nonFieldRelatedErrors.length === 0 &&
                nonFieldRelatedWarnings.length === 1
              ) {
                showConfirm({
                  titleKey: "common.warning",
                  content: nonFieldRelatedWarnings[0].error,
                  centered: true,
                  okTextKey: "common.proceed",
                  onOk: () => {
                    ignoreWarningsRef.current = true;
                    form?.submit();
                  },
                  k2Intl,
                });
              }

              // Errors take precedence, show them at the foot of the form
              if (nonFieldRelatedErrors.length) {
                setOverallErrors(
                  nonFieldRelatedErrors?.map?.((err) => err.error),
                );
              }
            } else {
              UIUtils.handleServerError(k2Intl, editStatus.error);
            }
          } else {
            if (onSaveSuccess) {
              onSaveSuccess(editStatus.action);
            } else {
              showNotification({
                type: "success",
                titleKey: "common.success.title",
                messageKey: `admin.communication.emailTemplates.${
                  editStatus.action === DataActions.update
                    ? "updated"
                    : "created"
                }`,
                k2Intl,
              });

              updateDirty(false);

              setTimeout(() => {
                // Redirect back to main page
                router.history.push(K2Routes.communicationsEmailTemplates);
              }, REDIRECT_AFTER_SAVE_MS);
            }
          }

          clearEditStatus();
          break;
        case DataActions.delete:
          if (editStatus.error?.status === K2StatusCodes.preconditionFailed) {
            const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
              editStatus.error,
              "error",
            );
            if (nonFieldRelatedErrors.length) {
              showError({
                titleKey: "common.nonDismissableFormErrorTitle",
                error: nonFieldRelatedErrors.map((e) => <p>{e.error}</p>),
                okTextKey: "common.ok",
                k2Intl,
                onOk: () => {
                  clearEditStatus();
                },
                onCancel: () => {
                  clearEditStatus();
                },
              });
            }
          } else {
            UIUtils.handleDataUpdateResult({
              k2Intl,
              status: editStatus,
              successTitle: "common.success.title",
              successMessage: "admin.communication.templates.delete.success",
              onSuccess: () => {
                if (editStatus.result) {
                  setTimeout(() => {
                    router.history.push(K2Routes.communicationsEmailTemplates);
                  }, REDIRECT_AFTER_SAVE_MS);
                }
              },
            });
          }
          break;
      }
    }
  }, [
    editStatus,
    form,
    k2Intl,
    router,
    updateDirty,
    clearEditStatus,
    onSaveSuccess,
  ]);

  useEffect(() => {
    setNotFound(false);
    if (templateId) {
      fetchEmailTemplate(templateId);
    }
  }, [templateId, fetchEmailTemplate]);

  const handleCreateEditTemplate = (values) => {
    const req: EmailCompositionReq = {
      ...values,
      attachmentAssetIds: tempAttachmentAssets?.map?.((asset) => asset.id),
      mediaAssetIds: tempAssets?.map?.((asset) => asset.id),
    };

    if (editMode) {
      updateEmailTemplate(
        templateData.revisionId,
        req,
        scopeId,
        ignoreWarningsRef.current,
      );
    } else {
      createEmailTemplate(req, scopeId, ignoreWarningsRef.current);
    }
  };

  const handleInvalidPreviewRequest = () => {
    showError({
      titleKey: "common.nonDismissableFormErrorTitle",
      k2Intl,
      okTextKey: "common.ok",
      error: k2Intl.formatMessage({
        localeKey: "admin.communication.previewValidationError",
      }),
    });
  };

  const handlePreview = async (values) => {
    setPreviewLoading(true);
    setShowPreviewModal(true);

    const req: PreviewEmailCompositionReq = {
      composition: {
        ...values,
        name: generateUuid(),
        attachmentAssetIds: tempAttachmentAssets?.map?.((asset) => asset.id),
        mediaAssetIds: tempAssets?.map?.((asset) => asset.id),
      },
    };

    const resp: IApiResponse<RenderEmailRes> = await K2RestService.toResponse(
      _emailCompositionsClient.previewEmail(TEMPLATE_ENGINE, null, req),
    );

    if (resp.error) {
      if (resp.error.status === K2StatusCodes.preconditionFailed) {
        const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
          resp.error,
          "error",
          "all",
        );

        if (nonFieldRelatedErrors.length) {
          setPreviewError(nonFieldRelatedErrors.map((err) => err.error));
        }
      } else {
        UIUtils.handleServerError(k2Intl, resp.error);
      }
    } else {
      const email = resp.getResultOrDefault();
      setPreview(email);
    }

    setPreviewLoading(false);
  };

  return (
    <>
      {showPreviewModal && (
        <EmailPreview
          k2Intl={k2Intl}
          email={preview}
          loading={previewLoading}
          errors={previewError}
          onClose={() => {
            setPreviewError(null);
            setPreviewLoading(false);
            setPreview(null);
            setShowPreviewModal(false);
          }}
        />
      )}
      {/* If scopeId is provided, then this form is being used within a modal to create a new template for an Outbox */}
      {scopeId ? (
        <CreateEditEmailTemplateForm
          form={form}
          editMode={!!templateId}
          errors={overallErrors}
          onSubmit={handleCreateEditTemplate}
          onPreview={handlePreview}
          onInvalidPreviewRequest={handleInvalidPreviewRequest}
          setTempAssets={setTempAssets}
          tempAssets={tempAssets}
          tempAttachmentAssets={tempAttachmentAssets}
          setTempAttachmentAssets={setTempAttachmentAssets}
          showCancelSaveOpts={false}
          {...props}
        />
      ) : (
        <FormsWrapper>
          <NotFoundWrapper
            showNotFound={notFound}
            entityNameKey={"common.entity.action.template"}
          >
            <K2RouteHeading
              headingKey={`admin.communication.templates.${
                editMode ? "edit" : "new"
              }`}
            />
            <CardWrapper>
              <Skeleton active loading={loading}>
                {!loading && (
                  <CreateEditEmailTemplateForm
                    form={form}
                    scopeId={scopeId}
                    editMode={!!templateId}
                    errors={overallErrors}
                    onSubmit={handleCreateEditTemplate}
                    onPreview={handlePreview}
                    onInvalidPreviewRequest={handleInvalidPreviewRequest}
                    setTempAssets={setTempAssets}
                    tempAssets={tempAssets}
                    tempAttachmentAssets={tempAttachmentAssets}
                    setTempAttachmentAssets={setTempAttachmentAssets}
                    {...props}
                  />
                )}
              </Skeleton>
            </CardWrapper>
          </NotFoundWrapper>
        </FormsWrapper>
      )}
    </>
  );
};

export default injectK2Intl(NewEditEmailTemplate);
