export const FETCH_DONATION_ITEMS: string =
  "@donationItemsAdmin/fetch-donation-items/request";
export const FETCH_DONATION_ITEMS_SUCCESS: string =
  "@donationItemsAdmin/fetch-donation-items/success";
export const FETCH_DONATION_ITEMS_ERROR: string =
  "@donationItemsAdmin/fetch-donation-items/error";
export const CLEAR_FETCH_DONATION_ITEMS_ERROR: string =
  "@donationItemsAdmin/fetch-donation-items/clear-error";

export const UPDATE_DONATION_ITEMS_STATUS: string =
  "@donationItemsAdmin/update-items-status/request";

export const UPDATE_DONATION_ITEMS_STATUS_SUCCESS: string =
  "@donationItemsAdmin/update-items-status/success";

export const UPDATE_DONATION_ITEMS_STATUS_ERROR: string =
  "@donationItemsAdmin/update-items-status/error";

export const CLEAR_EDIT_STATUS: string =
  "@donationItemsAdmin/clear-edit-status";
