import React, { FC } from "react";
import { Row, Col } from "antd";
import { DimensionsForm } from "components/admin/shared";
import { IFundDimensionsFormProps, SchemeReqType } from "../../modules/types";
import { HiddenFormItemV4 } from "components/formItems";
import SchemeFormHelpers from "routes/admin/sponsorships/schemes/helpers";

const FundDimensions: FC<IFundDimensionsFormProps> = ({
  fetching,
  form,
  fundDimensionsData,
  initialValues,
  fundDimensionFields,
  beneficiaryFields,
}) => {
  return (
    <Row gutter={[12, 18]} className={"n3o-mb-0"}>
      <Col span={24}>
        {fundDimensionsData?.dimension1?.isActive && (
          <HiddenFormItemV4
            fieldName={SchemeFormHelpers.getFundDimensionsFieldName(
              "dimension1",
            )}
          />
        )}
        {fundDimensionsData?.dimension2?.isActive && (
          <HiddenFormItemV4
            fieldName={SchemeFormHelpers.getFundDimensionsFieldName(
              "dimension2",
            )}
          />
        )}
        {fundDimensionsData?.dimension3?.isActive && (
          <HiddenFormItemV4
            fieldName={SchemeFormHelpers.getFundDimensionsFieldName(
              "dimension4",
            )}
          />
        )}
        {fundDimensionsData?.dimension4?.isActive && (
          <HiddenFormItemV4
            fieldName={SchemeFormHelpers.getFundDimensionsFieldName(
              "dimension4",
            )}
          />
        )}

        <DimensionsForm
          isLoading={fetching}
          fundDimensionsData={fundDimensionsData}
          initialValues={initialValues}
          getFieldName={SchemeFormHelpers.getFundDimensionsFieldName}
          beneficiaryFields={beneficiaryFields}
          fundDimensionFields={fundDimensionFields}
          formV4={form}
          forSponsorship
        />
      </Col>
    </Row>
  );
};

export default FundDimensions;
