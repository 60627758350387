import React, { FC } from "react";

import { EditOutlined } from "@ant-design/icons/lib";
import styled from "styled-components";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Permission } from "components/k2Widgets";
import { IN3oIconProps } from "components/n3oIcon/types";

import * as defaultProps from "../n3oIcon/svgs/defaultProps";

const EditLink = styled(EditOutlined)`
  && {
    color: ${({ theme }) => theme.link_color};
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.link_hover_color};
    }
  }
`;

interface EditLinkIconProps extends IN3oIconProps {
  tooltipKey?: string;
  permission?: string;

  onClick?: () => void;
}

export const EditLinkIcon: FC<EditLinkIconProps> = ({
  tooltipKey,
  permission,
  onClick,
  height = defaultProps.height,
  width = defaultProps.width,
  disabled,
  fill,
}) => {
  const { theme: appTheme } = useAppContext();

  if (!fill) {
    fill = appTheme.primaryColor;
  }

  return (
    <K2Permission
      titleKey={tooltipKey || "common.editThis"}
      permission={permission}
      disabled={disabled}
    >
      <EditLink
        style={{ height, width }}
        twoToneColor={fill}
        onClick={() => {
          if (!disabled) onClick?.();
        }}
        disabled={disabled}
      />
    </K2Permission>
  );
};

export default EditLinkIcon;
