import {
  ComponentReq,
  FundDimensionValueReq,
  PricingDimensionsReq,
  PricingReq,
  PricingRuleReq,
} from "@n3oltd/karakoram.sponsorships.beneficiarytypes.child.sdk.child/esm";
import {
  BeneficiaryAssignment,
  FeedType,
  SponsorshipCommitmentDuration,
} from "@n3oltd/karakoram.sponsorships.sdk.schemes/esm";
import _ from "lodash";

import {
  SchemeReqType,
  SchemeResType,
} from "routes/admin/sponsorships/schemes/modules/types";

export default class SchemeFormHelpers {
  // Field names
  public static nameFieldName = ["name"];

  public static beneficiaryAssignmentFieldName = [
    "beneficiaryAssignment",
  ];

  public static accessToEveryOneFieldName = [
    "entryAccess",
    "everyone",
  ];

  public static restrictEntryToFieldName = [
    "entryAccess",
    "limitedTo",
    "principals",
  ];

  public static durationsFieldName = [
    "durations",
  ];

  public static includePhotosFieldName = [
    "feed",
    "includePhotos",
  ];

  public static compositionIdFieldName = [
    "profileTemplates",
    "pdfCompositionId",
  ];

  public static deactivationNotificationsPdfCompositionFieldName = [
    "deactivationNotifications",
    "pdfCompositionId",
  ];

  public static deactivationNotificationsEmailCompositionIdFieldName = [
    "deactivationNotifications",
    "emailCompositionId",
  ];

  public static getFundDimensionsFieldName = (key: string) => [
    "fundDimensionOptions",
    key,
  ];

  public static getFundDimensionForFeedFieldName = (key: string) => [
    "feed",
    "fundDimensionFields",
    key,
  ];

  public static componentsListFieldName = [
    "components",
  ];

  public static getComponentNameFieldName = (index: number) => [
    index,
    "name",
  ];

  public static getComponentRequiredFieldName = (index: number) => [
    index,
    "required",
  ];

  public static getComponentCapacityFieldName = (index: number) => [
    index,
    "capacity",
  ];

  public static getComponentAmountFieldName = (index: number) => [
    index,
    "pricing",
    "price",
    "amount",
  ];

  public static getComponentLockedFieldName = (index: number) => [
    index,
    "pricing",
    "price",
    "locked",
  ];

  public static getComponentRulesFieldName = (index: number) => [
    index,
    "pricing",
    "rules",
  ];

  public static getPricingRuleFundDimensionFieldName = (
    index: number,
    dimensionKey: string,
  ) => [
    index,
    "fundDimensions",
    dimensionKey,
    "value",
  ];

  public static getPricingRuleAmountFieldName = (index: number) => [
    index,
    "price",
    "amount",
  ];

  public static getPricingRuleLockedFieldName = (index: number) => [
    index,
    "price",
    "locked",
  ];

  public static convertSchemeResToReq = (
    schemeRes: SchemeResType,
  ): SchemeReqType => {
    const omittedRes = _.omit(schemeRes, [
      "id",
      "revisionId",
      "isValid",
      "status",
      "isActive",
      "allowedDurations",
      "beneficiaryAssignment",
      "entryAccess",
      "modifiedInfo",
      "components",
    ]);

    const principals =
      schemeRes?.entryAccess?.limitedTo
        ?.filter((x) => !_.isNil(x))
        ?.map((x) => x?.principalId) || undefined;

    const formData: SchemeReqType = {
      ...omittedRes,
      activate: schemeRes?.isActive,
      beneficiaryAssignment: schemeRes?.beneficiaryAssignment
        .id as BeneficiaryAssignment,
      fundDimensionOptions: schemeRes?.fundDimensionOptions,
      components: schemeRes?.components
        ?.filter((x) => !_.isNil(x))
        ?.map((component) => {
          const rul: PricingRuleReq[] = component.pricing?.rules?.map((r) => {
            const fd: PricingDimensionsReq = {
              dimension1: {
                value: r.fundDimensions.dimension1,
              },
              dimension2: {
                value: r.fundDimensions.dimension2,
              },
              dimension3: {
                value: r.fundDimensions.dimension3,
              },
              dimension4: {
                value: r.fundDimensions.dimension4,
              },
            };

            const ru: PricingRuleReq = {
              price: r.price,
              fundDimensions: fd,
            };

            return ru;
          });

          const pri: PricingReq = {
            price: component.pricing?.price,
            rules: rul,
          };

          const comp: ComponentReq = {
            capacity: component.capacity,
            name: component.name,
            required: component.required,
            pricing: pri,
          };

          return comp;
        }),
      durations: schemeRes?.durations
        ?.filter((x) => !_.isNil(x))
        ?.map((x) => x?.id as SponsorshipCommitmentDuration),
      entryAccess: {
        everyone: schemeRes?.entryAccess?.everyone || false,
        limitedTo:
          schemeRes?.entryAccess?.limitedTo?.length > 0
            ? {
                principals: principals as any,
              }
            : undefined,
      },
      feed: {
        ...schemeRes.feed,
      },
      deactivationNotifications: {
        ...schemeRes?.deactivationNotifications,
      },
    };

    return _.omitBy(formData, _.isNil);
  };
}
