import { ModalFuncProps } from "antd/es/modal/Modal";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import React from "react";
import { K2MessageValue } from "components/k2Widgets/k2Localizations/types";
import { Modal } from "antd";
import _ from "lodash";
import { messageToHtml } from "components/utils/UIUtils";

const defaultOkTextKey = "common.ok",
  defaultCancelTextKey = "common.cancel";

export interface IErrorModalProps extends ModalFuncProps {
  /** (optional) locale key for Ok button, default 'common.ok' */
  okTextKey?: string;
  /** (required) locale key for Title */
  titleKey: string;
  /** (optional) error */
  error?: React.ReactNode;
  /** (optional) locale key for error */
  errorKey?: string;
  /** error key values for Content */
  errorKeyValues?: { [key: string]: K2MessageValue };
  /** (required) InjectedIntl */
  k2Intl: InjectedK2Intl;
}

export interface IConfirmModalProps
  extends Partial<
    Omit<IErrorModalProps, "error" | "errorKey" | "contentKeyValues">
  > {
  /** (optional) locale key for Cancel button, default 'common.cancel' */
  cancelTextKey?: string;
  /** locale key values for Title */
  titleKeyValues?: { [key: string]: K2MessageValue };
  /** locale key for Content */
  contentKey?: string;
  /** locale key values for Content */
  contentKeyValues?: { [key: string]: K2MessageValue };
  /** Extra Content */
  extendedContent?: React.ReactNode;
}

export interface IInfoModalProps extends IConfirmModalProps {}

export interface IExtendModalFuncProps {
  destroy: () => void;
  update: (newConfig: ModalFuncProps) => void;
}

export const showError = ({
  okTextKey = defaultOkTextKey,
  titleKey,
  error,
  errorKey,
  k2Intl,
  errorKeyValues = {},
  ...rest
}: IErrorModalProps) => {
  const title = k2Intl?.formatMessage({
    localeKey: titleKey,
  });
  const errorContent = errorKey
    ? k2Intl?.formatMessage({
        localeKey: errorKey,
        values: errorKeyValues,
      })
    : error;

  const okText = k2Intl?.formatMessage({ localeKey: okTextKey });
  return Modal.error({
    title,
    okText,
    content: errorContent,
    centered: true,
    ...rest,
  });
};

const getOptions = (
  props: IConfirmModalProps | IInfoModalProps,
): ModalFuncProps => {
  const {
    okTextKey = defaultOkTextKey,
    cancelTextKey = defaultCancelTextKey,
    titleKey,
    content,
    contentKey,
    contentKeyValues,
    extendedContent,
    k2Intl,
    titleKeyValues = {},
    ...rest
  } = props;

  const okText = k2Intl?.formatMessage({ localeKey: okTextKey }),
    cancelText = k2Intl?.formatMessage({ localeKey: cancelTextKey }),
    title = k2Intl?.formatMessage({
      localeKey: titleKey,
      values: titleKeyValues,
    });

  const contentBody = (
    <>
      {!_.isEmpty(contentKey)
        ? messageToHtml(k2Intl, contentKey, contentKeyValues)
        : content}
      {extendedContent}
    </>
  );

  const options: ModalFuncProps = {
    okText,
    cancelText,
    title,
    content: contentBody,
    centered: true,
    ...rest,
  };

  return options;
};

export const showConfirm = (
  props: IConfirmModalProps,
): IExtendModalFuncProps => {
  const options = getOptions(props);
  return Modal.confirm(options);
};

export const showInfo = (props: IInfoModalProps): IExtendModalFuncProps => {
  const options = getOptions(props);
  return Modal.info(options);
};

interface ShowConfirmLeavePageProps extends IConfirmModalProps {
  navigate: () => void;
}

export const showConfirmLeavePage = ({
  navigate,
  ...props
}: ShowConfirmLeavePageProps) => {
  showConfirm({
    onOk: () => navigate(),
    okTextKey: "common.yes",
    ...props,
  });
};
