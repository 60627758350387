import React, { FC } from "react";

import { IN3oIconProps } from "../types";

// Cannot choose colours for Visa brand logo
export const VisaCard: FC<IN3oIconProps> = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 160 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M160 0.466919H-0.000244141V101.007H160V0.466919Z" fill="white" />
      <path
        d="M157.333 84.6719H2.66455V98.3887H157.333V84.6719Z"
        fill="#F7B600"
      />
      <path
        d="M157.333 3.10425H2.66455V16.8218H157.333V3.10425Z"
        fill="#1A1F71"
      />
      <path
        d="M64.5747 31.3041L48.5979 69.0075H38.1754L30.3127 38.9175C29.8362 37.0672 29.4208 36.3872 27.9708 35.6051C25.5997 34.3309 21.6849 33.1392 18.2434 32.3981L18.476 31.3041H35.2551C37.3925 31.3041 39.3152 32.7106 39.8038 35.1457L43.9575 56.9651L54.2155 31.3041H64.5747ZM105.413 56.6986C105.457 46.7483 91.5091 46.1969 91.6016 41.7509C91.6322 40.3997 92.9341 38.9609 95.7822 38.5929C97.1941 38.4127 101.09 38.2671 105.507 40.28L107.234 32.2815C104.862 31.4326 101.809 30.6152 98.0116 30.6152C88.2628 30.6152 81.406 35.7369 81.351 43.0781C81.2885 48.5066 86.2491 51.5328 89.9791 53.3409C93.8247 55.1886 95.1141 56.374 95.0953 58.0251C95.0685 60.5547 92.0285 61.6746 89.1985 61.7168C84.2372 61.7937 81.3628 60.3909 79.071 59.3341L77.2816 67.6C79.5885 68.6444 83.841 69.5537 88.2435 69.6006C98.6066 69.6 105.383 64.5375 105.413 56.6986ZM131.156 69.0075H140.273L132.308 31.3041H123.897C122.001 31.3041 120.404 32.3911 119.696 34.0681L104.9 69.0081H115.256L117.312 63.3775H129.96L131.156 69.0075ZM120.148 55.6499L125.339 41.4906L128.325 55.6499H120.148ZM78.6641 31.3041L70.5116 69.0075H60.6512L68.8047 31.3041H78.6641Z"
        fill="#1A1F71"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="160" height="100" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
