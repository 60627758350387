import {
  ActivityLogCriteria,
  ActivityLogResultsPage,
} from "@n3oltd/karakoram.activities.sdk.activities/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { ActionTypes } from "containers/searchActivities/modules/actionTypes";
import { SearchActivitiesAction } from "containers/searchActivities/modules/types";

export const searchActivities = (
  loadMore: boolean,
  criteria: ActivityLogCriteria,
): SearchActivitiesAction => ({
  type: ActionTypes.SEARCH_ACTIVITIES,
  loadMore,
  criteria,
});

export const searchActivitiesComplete = (
  loadMore: boolean,
  results: ActivityLogResultsPage,
  serverError?: ServerError,
): SearchActivitiesAction => ({
  type: ActionTypes.SEARCH_ACTIVITIES_COMPLETE,
  loadMore,
  results,
  serverError,
});

export const clearSearch = (): SearchActivitiesAction => ({
  type: ActionTypes.CLEAR_SEARCH_ACTIVITIES,
});

export const clearError = (): SearchActivitiesAction => ({
  type: ActionTypes.CLEAR_SERVER_ERROR,
});
