import React, { FC } from "react";

import { CheckCircleOutlined, DownOutlined } from "@ant-design/icons/lib";
import { TaskSummaryRes } from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Dropdown, Menu } from "antd";
import { connect } from "react-redux";

import TasksSelectors, {
  IDashboardTaskActionsDispatcherProps,
} from "appRedux/store/selectors/TasksSelectors";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Link, K2Message } from "components/k2Widgets";
import { N3oIcon } from "components/n3oIcon";

type ITaskItemOptionsProps = IDashboardTaskActionsDispatcherProps & {
  task: TaskSummaryRes;
  onAddNotesClick: () => void;
};

const TaskItemOptions: FC<ITaskItemOptionsProps> = ({
  task,
  markComplete,
  onAddNotesClick,
}) => {
  const { theme } = useAppContext();

  const hasLinkedAction =
    task.linkedActions && task.linkedActions[0] && task.linkedActions[0].url;
  return (
    <Dropdown
      trigger={["click"]}
      overlay={() => (
        <Menu>
          <Menu.Item onClick={markComplete.bind(this, task.id)}>
            <CheckCircleOutlined />{" "}
            <K2Message localeKey="tasks.action.markComplete" />
          </Menu.Item>
          {hasLinkedAction && (
            <Menu.Item>
              <K2Link
                target="_blank"
                rel="noopener noreferrer"
                to={task.linkedActions[0].url}
                disableDefaultLinkColor
              >
                <N3oIcon
                  icontype={"n3o-external-link"}
                  width="16px"
                  height="16px"
                  className="n3o-p-2"
                  fill={theme.icon_grey_normal}
                  style={{ marginRight: 8 }}
                />{" "}
                <K2Message localeKey="tasks.action.viewLinkedEntity" />
              </K2Link>
            </Menu.Item>
          )}
          <Menu.Item onClick={onAddNotesClick}>
            <N3oIcon
              icontype="n3o-task-note"
              width="16px"
              height="16px"
              className="n3o-p-2"
              fill={theme.icon_grey_normal}
            />{" "}
            <K2Message localeKey="tasks.action.addNotes" />
          </Menu.Item>
        </Menu>
      )}
    >
      <DownOutlined />
    </Dropdown>
  );
};

export default connect(
  null,
  TasksSelectors.getDashboardTaskActionDispatchers(),
)(TaskItemOptions);
