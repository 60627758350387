import React from "react";

import { Form } from "antd";
import { FormItemProps } from "antd/es/form";
import { Rule } from "antd/lib/form";
import { NamePath } from "rc-field-form/lib/interface";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

import LabelWithHelp from "components/forms/labelHelp/LabelHelp";

import injectK2Intl from "../k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "../k2Widgets/k2Localizations/types";

const FormItem = styled(Form.Item)`
  &.control-input-fit-content {
    .ant-form-item-control-input {
      min-height: fit-content;
    }
  }
  label span:first-of-type {
    font-weight: ${({ theme }) => theme.font_weight_semi_bold};
  }
`;

export interface GeneralItemProps extends FormItemProps {
  rules?: Rule[];
  name?: NamePath;

  /**locale key for label */
  labelKey?: string;

  /** key for tooltip help icon */
  labelTooltipKey?: string;

  /**locale key for label */
  labelAdditionalChildren?: React.ReactNode;

  /**extra locale key for label */
  extraLabelKey?: string;

  /**error locale key for label */
  errorLabelKey?: string;

  errorLabel?: string;

  /** form layout object */
  formItemLayout?: { wrapperCol: Object; labelCol?: Object };
  /** className for styling */
  className?: string;
}

const GeneralFormItemV4 = (props: GeneralItemProps & InjectedK2IntlProps) => {
  let {
    labelKey,
    labelAdditionalChildren,
    extraLabelKey,
    formItemLayout,
    children,
    label,
    k2Intl,
    className,
    rules = [],
    name,
    errorLabelKey,
    errorLabel,
    labelTooltipKey,
    localizationSettings,
    required,
    tooltip,
    ...rest
  } = props;

  const localeLabel = k2Intl?.formatHtmlMessage({
    localeKey: labelKey,
  });

  let formattedDataWithExtra: JSX.Element = null;

  if (extraLabelKey) {
    const extraFormattedLabel = k2Intl?.formatHtmlMessage({
      localeKey: extraLabelKey,
    });

    formattedDataWithExtra = (
      <span>
        {localeLabel || label}{" "}
        <span className={"n3o-text-grey-7"}>({extraFormattedLabel})</span>
      </span>
    );
  }

  if (required) {
    rules.push({
      required: true,
      message: `${
        errorLabel ||
        k2Intl?.formatMessage({
          localeKey: errorLabelKey || labelKey || "common.this",
        })
      } ${k2Intl?.formatMessage({
        localeKey: "common.isRequiredField",
      })}`,
    });
  }

  const labelToRender =
    formattedDataWithExtra ||
    (localeLabel ? ReactHtmlParser(localeLabel) : undefined) ||
    label;

  const hasHelp = !!labelTooltipKey || !!tooltip;

  return (
    <FormItem
      {...formItemLayout}
      label={
        labelToRender ? (
          hasHelp ? (
            <LabelWithHelp
              label={
                <span>
                  {labelToRender}
                  {labelAdditionalChildren}
                </span>
              }
              labelTooltipKey={labelTooltipKey}
              tooltip={tooltip}
            />
          ) : (
            <span>
              {labelToRender}
              {labelAdditionalChildren}
            </span>
          )
        ) : undefined
      }
      className={className}
      rules={rules}
      name={name}
      required={required}
      {...rest}
    >
      {children}
    </FormItem>
  );
};

export default injectK2Intl(GeneralFormItemV4);
