export const FETCH_TASK: string = "@dashboardTasks/fetch-tasks/request";
export const FETCH_TASK_SUCCESS: string = "@dashboardTasks/fetch-tasks/success";
export const FETCH_TASK_ERROR: string = "@dashboardTasks/fetch-tasks/error";
export const CLEAR_FETCHING_TASK_ERROR: string =
  "@dashboardTasks/fetch-tasks/clear-error";

export const UPDATE_TASK_STATUS_REQUEST: string =
  "@dashboardTasks/update-status/request";
export const MARK_TASK_COMPLETE: string = "@dashboardTasks/mark-complete";
export const MARK_TASK_PENDING: string = "@dashboardTasks/mark-pending";
export const UPDATE_TASK_STATUS_ERROR: string =
  "@dashboardTasks/update-status/error";
export const CLEAR_UPDATING_TASK_STATUS: string =
  "@dashboardTasks/update-status/clear";
export const CLEAR_UPDATE_TASK_STATUS_ERROR: string =
  "@dashboardTasks/update-status/clear-error";

export const DELETE_TASK_REQUEST: string =
  "@dashboardTasks/delete-task/request";
export const DELETE_TASK_SUCCESS: string =
  "@dashboardTasks/delete-task/success";
export const DELETE_TASK_ERROR: string = "@dashboardTasks/delete-task/error";
export const CLEAR_DELETING_TASK: string = "@dashboardTasks/delete-task/clear";
export const CLEAR_DELETE_TASK_ERROR: string =
  "@dashboardTasks/delete-task/clear-error";

export const UPDATE_TASK_TITLE_REQUEST: string =
  "@dashboardTasks/update-title/request";
export const UPDATE_TASK_TITLE_SUCCESS: string =
  "@dashboardTasks/update-title/success";
export const UPDATE_TASK_TITLE_ERROR: string =
  "@dashboardTasks/update-title/error";
export const CLEAR_UPDATE_TASK_TITLE_ERROR: string =
  "@dashboardTasks/update-title/clear-error";
export const CLEAR_UPDATING_TASK_TITLE: string =
  "@dashboardTasks/update-title/clear";

export const UPDATE_TASK_DUE_DATE_REQUEST: string =
  "@dashboardTasks/update-duedate/request";
export const UPDATE_TASK_DUE_DATE_SUCCESS: string =
  "@dashboardTasks/update-duedate/success";
export const UPDATE_TASK_DUE_DATE_ERROR: string =
  "@dashboardTasks/update-duedate/error";
export const CLEAR_UPDATE_TASK_DUE_DATE_ERROR: string =
  "@dashboardTasks/update-duedate/clear-error";
export const CLEAR_UPDATING_TASK_DUE_DATE: string =
  "@dashboardTasks/update-duedate/clear";

export const UPDATE_TASK_PRIORITY_REQUEST: string =
  "@dashboardTasks/update-priority/request";
export const UPDATE_TASK_PRIORITY_SUCCESS: string =
  "@dashboardTasks/update-priority/success";
export const UPDATE_TASK_PRIORITY_ERROR: string =
  "@dashboardTasks/update-priority/error";
export const CLEAR_UPDATE_TASK_PRIORITY_ERROR: string =
  "@dashboardTasks/update-priority/clear-error";
export const CLEAR_UPDATING_TASK_PRIORITY: string =
  "@dashboardTasks/update-priority/clear";

export const UPDATE_TASK_ASSIGNEES_REQUEST: string =
  "@dashboardTasks/update-assignees/request";
export const UPDATE_TASK_ASSIGNEES_SUCCESS: string =
  "@dashboardTasks/update-assignees/success";
export const UPDATE_TASK_ASSIGNEES_ERROR: string =
  "@dashboardTasks/update-assignees/error";
export const CLEAR_UPDATE_TASK_ASSIGNEES_ERROR: string =
  "@dashboardTasks/update-assignees/clear-error";
export const CLEAR_UPDATING_TASK_ASSIGNEES: string =
  "@dashboardTasks/update-assignees/clear";

export const UPDATE_LINKED_ENTITY_REQUEST: string =
  "@dashboardTasks/update-linked-entity/request";
export const UPDATE_LINKED_ENTITY_SUCCESS: string =
  "@dashboardTasks/update-linked-entity/success";
export const UPDATE_LINKED_ENTITY_ERROR: string =
  "@dashboardTasks/update-linked-entity/error";
export const CLEAR_UPDATE_LINKED_ENTITY_ERROR: string =
  "@dashboardTasks/update-linked-entity/clear-error";
export const CLEAR_UPDATING_LINKED_ENTITY: string =
  "@dashboardTasks/update-linked-entity/clear";

export const DELETE_LINKED_ENTITY_REQUEST: string =
  "@dashboardTasks/delete-linked-entity/request";
export const DELETE_LINKED_ENTITY_SUCCESS: string =
  "@dashboardTasks/delete-linked-entity/success";
export const DELETE_LINKED_ENTITY_ERROR: string =
  "@dashboardTasks/delete-linked-entity/error";
export const CLEAR_DELETING_LINKED_ENTITY_ERROR: string =
  "@dashboardTasks/delete-linked-entity/clear-error";
export const CLEAR_DELETING_LINKED_ENTITY: string =
  "@dashboardTasks/delete-linked-entity/clear";

export const DELETE_TASK_NOTE_REQUEST: string =
  "@dashboardTasks/delete-note/request";
export const DELETE_TASK_NOTE_SUCCESS: string =
  "@dashboardTasks/delete-note/success";
export const DELETE_TASK_NOTE_ERROR: string =
  "@dashboardTasks/delete-note/error";
export const CLEAR_DELETING_TASK_NOTE: string =
  "@dashboardTasks/delete-note/clear";
export const CLEAR_DELETE_TASK_NOTE_ERROR: string =
  "@dashboardTasks/delete-note/clear-error";

export const UPDATE_NOTE_REQUEST: string =
  "@dashboardTasks/update-note/request";
export const UPDATE_NOTE_SUCCESS: string =
  "@dashboardTasks/update-note/success";
export const UPDATE_NOTE_ERROR: string = "@dashboardTasks/update-note/error";
export const CLEAR_UPDATING_NOTE: string = "@dashboardTasks/update-note/clear";
export const CLEAR_UPDATE_NOTE_ERROR: string =
  "@dashboardTasks/update-note/clear-error";
export const CLEAR_NOTE_EDIT_STATUS: string =
  "@dashboardTasks/update-note/clear-edit-status";

export const SAVE_NEW_NOTE_REQUEST: string =
  "@dashboardTasks/save-new-note/request";
export const SAVE_NEW_NOTE_SUCCESS: string =
  "@dashboardTasks/save-new-note/success";
export const SAVE_NEW_NOTE_ERROR: string =
  "@dashboardTasks/save-new-note/error";
export const CLEAR_SAVE_NEW_NOTE_ERROR: string =
  "@dashboardTasks/save-new-note/clear-error";
export const CLEAR_SAVING_NEW_NOTE: string =
  "@dashboardTasks/save-new-note/clear";

export const UPDATE_SHARED_WITH_USERS: string =
  "@dashboardTasks/update-shared-with-users";

export const SET_FORM_TOUCHED: string = "@dashboardTasks/set-form-touched";

export const CLEAR_FORM_TOUCHED: string = "@dashboardTasks/clear-form-touched";

export const CLEAR_ALL_FORMS_TOUCHED: string =
  "@dashboardTasks/clear-all-forms-touched";
