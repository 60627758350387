import React, { FC, useCallback, useMemo, useState } from "react";

import {
  DonationItemReq,
  FundDimensionOptionsRes,
} from "@n3oltd/k2.donations.sdk.donation-items/esm";
import {
  FundDimensionRes,
  FundStructureRevisionRes,
} from "@n3oltd/k2.subscriptions.sdk.funds/esm";
import {
  FundDimensionOptionsRes as ChildFundDimensionOptionsRes,
  FeedFundDimensionFieldsReq,
} from "@n3oltd/karakoram.sponsorships.beneficiarytypes.child.sdk.child/esm";
import { FundDimensionOptionsRes as OtherFundDimensionOptionsRes } from "@n3oltd/karakoram.sponsorships.beneficiarytypes.other.sdk.other/esm";
import { Col, Form, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { NamePath } from "rc-field-form/lib/interface";
import styled from "styled-components";

import { donationItemFormLayouts } from "constants/FormLayouts";
import { BeneficiaryFields } from "routes/admin/sponsorships/schemes/components/forms";
import { IFeedFieldsDataState } from "routes/admin/sponsorships/schemes/modules/types";

import { K2Card, MultiSelector } from "../../k2Widgets";

type FundDimensionOptionRes =
  | ChildFundDimensionOptionsRes
  | OtherFundDimensionOptionsRes;

const { fieldsLayout } = donationItemFormLayouts;

interface IProps {
  formV4?: FormInstance;
  fundDimensionsData: FundStructureRevisionRes;
  initialValues?: FundDimensionOptionsRes;
  fundDimensionFields?: FeedFundDimensionFieldsReq;
  beneficiaryFields?: IFeedFieldsDataState;
  isLoading: boolean;
  forSponsorship?: boolean;
  forFeedback?: boolean;
  getFieldName?: (key: string) => NamePath;
}

const CardHeader = styled.h2`
  margin-bottom: 0;
`;

const DonationItemDimensionsFormBody: FC<IProps> = (props) => {
  const {
    formV4,
    fundDimensionsData,
    initialValues,
    isLoading,
    beneficiaryFields,
    forSponsorship,
    forFeedback,
    fundDimensionFields,
    getFieldName,
  } = props;

  const [dimensionsUpdated, setDimensionsUpdated] = useState<boolean>(false);

  const getName = useCallback(
    (key: string) => {
      return (
        getFieldName?.(key) || [
          `${"fundDimensionOptions"}.${key}`,
        ]
      );
    },
    [getFieldName],
  );

  /**
   * Gets the initial fundDimension values for a specific key, filtering out
   * any values that may have been removed from the fund dimension in the meantime
   */
  const getInitialValues = useCallback(
    (key: string): string[] => {
      if (!initialValues?.[key]) return [];
      else {
        return initialValues[key].filter((value) => {
          return (
            fundDimensionsData[key].restrictedOptions.includes(value) ||
            fundDimensionsData[key].unrestrictedOption === value
          );
        });
      }
    },
    [initialValues, fundDimensionsData],
  );

  const renderFundDimensionOptions = useMemo(
    () =>
      fundDimensionsData &&
      !isLoading &&
      Object.keys(fundDimensionsData)
        .sort(
          (a, b) => fundDimensionsData[a].order - fundDimensionsData[b].order,
        )
        ?.map?.((key: string, index: number) => {
          const dim: FundDimensionRes = fundDimensionsData[key];
          if (!dim || !dim.isActive) {
            return "";
          }

          const dimensionTransfer = [
            ...dim.restrictedOptions,
            dim.unrestrictedOption,
          ]
            .filter(Boolean)
            ?.map?.((item) => ({
              key: item,
              title: item,
            }));

          const fieldName = getName(key);
          const initialValuesList = getInitialValues(key);

          return (
            <Row
              style={{ marginBottom: "20px" }}
              key={"FundDimension_" + key + "_" + index}
            >
              <Col span={24} sm={forSponsorship || forFeedback ? 24 : 18}>
                <K2Card
                  size="small"
                  title={<CardHeader>{dim.name}</CardHeader>}
                  bordered={true}
                >
                  <Form.Item
                    key={"FundDimension_Field_" + key + "_" + index}
                    name={fieldName}
                    {...fieldsLayout}
                    wrapperCol={{ md: 24 }}
                    initialValue={initialValuesList}
                  >
                    <MultiSelector
                      initialValues={initialValuesList}
                      dataSource={dimensionTransfer}
                      searchFieldName={dim.name}
                      onChange={(keys, dataUpdated) =>
                        setDimensionsUpdated(dataUpdated)
                      }
                      showSearch
                    />
                  </Form.Item>
                  {forSponsorship && (
                    <BeneficiaryFields
                      key={key}
                      form={formV4}
                      dimensionKey={key as keyof FundDimensionOptionRes}
                      dimensionName={dim.name}
                      dimensionsUpdated={dimensionsUpdated}
                      beneficiaryFields={beneficiaryFields}
                      feedFieldInitialValue={fundDimensionFields?.[key]}
                    />
                  )}
                </K2Card>
              </Col>
            </Row>
          );
        }),
    [
      isLoading,
      fundDimensionsData,
      beneficiaryFields,
      dimensionsUpdated,
      forSponsorship,
      forFeedback,
      formV4,
      fundDimensionFields,
      getInitialValues,
      getName,
    ],
  );

  return <div>{renderFundDimensionOptions}</div>;
};

const DonationItemDimensionsForm: FC<IProps> = (props) => {
  const { forSponsorship, forFeedback } = props;

  if (forSponsorship || forFeedback) {
    return <DonationItemDimensionsFormBody {...props} />;
  } else {
    return (
      <K2Card
        bordered={false}
        shadow
        localeKey={"admin.fundDimensions"}
        style={{ marginBottom: 25 }}
      >
        <DonationItemDimensionsFormBody {...props} />
      </K2Card>
    );
  }
};

export default DonationItemDimensionsForm;
