import { None } from "@n3oltd/karakoram.templates.sdk.pdf";
import { LetterheadRes } from "@n3oltd/karakoram.templates.sdk.pdf/src/index";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

import { _pdfCompositionsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { DataActions } from "appRedux/modules/sharedTypes";
import { TEMPLATE_ENGINE } from "constants/appConstants";

import {
  ActionTypes,
  CreateLetterhead,
  FetchLetterhead,
  UpdateLetterhead,
} from "./actionTypes";
import * as actions from "./actions";

//#region WORKERS

//#endregion

function* fetchLetterhead(action: FetchLetterhead) {
  try {
    const resp: IApiResponse<LetterheadRes> = yield K2RestService.toResponse(
      _pdfCompositionsClient.getLetterheadById(
        action.id,
        TEMPLATE_ENGINE,
        null,
      ),
    );

    if (resp.error) {
      yield put(actions.fetchLetterheadError(resp.error));
    } else {
      yield put(actions.fetchLetterheadSuccess(resp.getResultOrDefault()));
    }
  } catch (e) {
    console.log(e);
  }
}

function* createLetterhead(action: CreateLetterhead) {
  try {
    const resp: IApiResponse<LetterheadRes> = yield K2RestService.toResponse(
      _pdfCompositionsClient.createLetterhead(null, action.data),
    );

    if (resp.error) {
      yield put(
        actions.createLetterheadError({
          action: DataActions.add,
          ...resp,
        }),
      );
    } else {
      yield put(
        actions.createLetterheadSuccess({
          action: DataActions.add,
          ...resp,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateLetterhead(action: UpdateLetterhead) {
  try {
    const resp: IApiResponse<None> = yield K2RestService.toResponse(
      _pdfCompositionsClient.updateLetterhead(
        action.revisionId,
        null,
        action.data,
      ),
    );

    if (resp.error) {
      yield put(
        actions.updateLetterheadError({
          action: DataActions.update,
          ...resp,
        }),
      );
    } else {
      yield put(
        actions.updateLetterheadSuccess({
          action: DataActions.update,
          ...resp,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

//#region WATCHERS

export default function* partialOrLayoutSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_LETTERHEAD, fetchLetterhead),
    takeEvery(ActionTypes.CREATE_LETTERHEAD, createLetterhead),
    takeEvery(ActionTypes.UPDATE_LETTERHEAD, updateLetterhead),
  ]);
}

//#endregion
