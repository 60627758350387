import React, { FC, useCallback, useEffect, useState } from "react";

import { RoleRes } from "@n3oltd/k2.users.sdk.roles";
import { RoleResultsPage } from "@n3oltd/k2.users.sdk.roles/src/index";
import { Form } from "antd";
import { FormInstance, FormItemProps } from "antd/es/form";
import { NamePath } from "antd/es/form/interface";
import { omit } from "lodash";

import { _rolesClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { DEFAULT_DELAY } from "constants/appConstants";
import useDebounce from "hooks/useDebounce";

import { K2Message, K2MultiSelectV4Styled } from "../../k2Widgets";

interface RolesSelectProps
  extends InjectedK2IntlProps,
    Omit<FormItemProps, "children"> {
  form: FormInstance;

  /**
   * Required so that we can check whether Roles must be selected or not
   */
  availableToAllFieldName?: NamePath;
  labelKey?: string;
}

const RolesSelect: FC<RolesSelectProps> = ({
  form,
  k2Intl,
  availableToAllFieldName,
  labelKey,
  ...rest
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [roles, setRoles] = useState<RoleRes[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DELAY);

  const fetchRoles = useCallback(
    async (isCancelled: boolean) => {
      try {
        if (!isCancelled) {
          setLoading(true);

          const response: IApiResponse<RoleResultsPage> = await K2RestService.toResponse(
            _rolesClient.find({
              fullText: debouncedSearchTerm,
            }),
          );

          setLoading(false);
          if (response.error) {
            UIUtils.handleServerError(k2Intl, response.error);
          } else {
            setRoles(response.getResultOrDefault().items);
          }
        }
      } catch (e) {
        if (!isCancelled) {
          setLoading(false);
        }
        console.log("error", e);
      }
    },
    [debouncedSearchTerm, k2Intl],
  );

  // This effect runs whenever debouncedSearchTerm changes
  useEffect(() => {
    let isCancelled = false;

    if (debouncedSearchTerm) {
      fetchRoles(isCancelled);
    }

    return () => {
      isCancelled = true;
    };
  }, [debouncedSearchTerm, fetchRoles]);

  // This effect runs once
  useEffect(() => {
    let isCancelled = false;

    fetchRoles(isCancelled);

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item
      shouldUpdate={true}
      rules={[
        {
          validator: (rule, value) => {
            if (availableToAllFieldName) {
              const isAvailableToAll = form?.getFieldValue(
                availableToAllFieldName,
              );
              if (isAvailableToAll) {
                return Promise.resolve();
              } else {
                if (!value || value.length === 0) {
                  return Promise.reject(
                    k2Intl?.formatMessage({
                      localeKey: "admin.mustSelectRoles",
                    }),
                  );
                } else {
                  return Promise.resolve();
                }
              }
            }
            return Promise.resolve();
          },
        },
      ]}
      label={
        <K2Message localeKey={labelKey ? labelKey : "admin.restrictToRoles"} />
      }
      {...omit(rest, ["localizationSettings"])}
    >
      <K2MultiSelectV4Styled
        loading={loading}
        mode={"multiple"}
        dataSource={roles}
        idField={"id"}
        nameField={"name"}
        optionLabelProp={"children"}
        optionFilterProp={"children"}
        showArrow
        showSearch
        onSearch={setSearchTerm}
      />
    </Form.Item>
  );
};

export default injectK2Intl(RolesSelect);
