import React, { FC } from "react";

import Highlight from "react-highlighter";
import styled from "styled-components";

const TaskText = styled.span`
  cursor: pointer;
  span {
    display: inline-block;
    transition: ease-in-out 0.25s;
    color: ${(props) => props.theme.grey_7};
    &:hover {
      color: ${(props) => props.theme.grey_8};
      transform: scale(1.01);
    }
  }
`;

interface IDescriptionColumnProps {
  title: string;
  searchValue?: string;
  onClick?: () => void;
}

const TaskDescription: FC<IDescriptionColumnProps> = (
  { title, searchValue, onClick },
  props: IDescriptionColumnProps,
): JSX.Element => {
  let displayText = title.substring(0, 80);
  if (displayText.length < title.length) displayText += "...";

  if (searchValue) {
    const searchValIndex = title.indexOf(searchValue);
    // If the search term appears in what would normally be truncated text, we truncate the middle of the string instead.
    if (searchValIndex > 79) {
      displayText =
        title.substring(0, searchValIndex - searchValue.length) +
        "..." +
        searchValue;
    }
  }
  return (
    <TaskText onClick={onClick} {...props}>
      {searchValue ? (
        <Highlight search={searchValue}>{displayText}</Highlight>
      ) : (
        <span>{displayText}</span>
      )}
    </TaskText>
  );
};

export default TaskDescription;
