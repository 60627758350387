import React, { FC, useState, useEffect } from "react";
import moment, { Moment } from "moment";
import { Divider } from "antd";
import { connect } from "react-redux";
import styled from "styled-components";
import CancelSaveOpts from "components/cancelSaveOpts";
import IApplicationState from "appRedux/types";
import {
  updateTaskDueDateRequest,
  clearTaskDueDateError,
} from "components/dashboard/editTask/modules/actions";
import usePrevious from "hooks/usePrevious";
import EditableSection from "components/forms/editableSection";
import { UIUtils } from "components/utils";
import { ServerError } from "appRedux/models/common/ApiResponseModel";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import DateFormatProvider from "appRedux/models/localization/DateFormatProvider";
import DatePickerWithoutYearSkip from "components/forms/datePickers/DatePickerWithoutYearSkip";
import { K2Message } from "components/k2Widgets";
import { editableSectionName } from "components/dashboard/editTask/modules/types";
import { showNotification } from "components/utils/Notification";

const QuickOptsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  color: ${({ theme }) => theme.primary_6} !important;
  font-size: 0.88em;
  cursor: pointer;
`;
const CancelSaveOptsContainer = styled.div`
  margin: -16px 0 8px 0;
`;

const RelativePositionedContainer = styled.div`
  position: relative;
  height: 0;
`;

interface IProps extends InjectedK2IntlProps {
  date: Moment;
  dateFormat?: string;
  updateDueDate: typeof updateTaskDueDateRequest;
  taskId: string;
  updating: boolean;
  error?: ServerError;
  clearError: typeof clearTaskDueDateError;
  setFormTouched?: (formName: editableSectionName) => void;
  clearFormTouched?: (formName: editableSectionName) => void;
}

const disabledDate = (current: moment.Moment): boolean => {
  return current && current < moment().startOf("day");
};

const DueDate: FC<IProps> = ({
  date: originalDate,
  dateFormat,
  updateDueDate,
  taskId,
  error,
  updating,
  k2Intl,
  clearError,
  clearFormTouched,
  setFormTouched,
}) => {
  const [editing, setEditing] = useState(false);
  const [date, setDate] = useState(
    originalDate
      ? moment(originalDate, DateFormatProvider.serverDateFormat)
      : null,
  );
  const [tempDate, setTempDate] = useState(null);
  const [dueDateRemoved, setDueDateRemoved] = useState(false);

  const previouslyUpdating = usePrevious(updating);

  const closeEditor = (): void => {
    clearFormTouched?.("dueDate");
    setTempDate(null);
    setEditing(false);
  };

  const cancelDueDateRemoval = (): void => {
    clearFormTouched?.("dueDate");
    setTempDate(originalDate);
    setEditing(false);
    setDueDateRemoved(false);
  };

  const openEditor = (): void => !editing && setEditing(true);

  const setTempDueDate = (e?: moment.Moment): void => {
    setFormTouched?.("dueDate");
    if (e) {
      setTempDate(moment(e));
      setDueDateRemoved(false);
    } else {
      // Someone has removed the due date
      setTempDate(null);
      setDueDateRemoved(true);
    }
  };

  useEffect(() => {
    if (previouslyUpdating && !updating && !error) {
      clearFormTouched?.("dueDate");
      setDate(tempDate);
      closeEditor();
      setDueDateRemoved(false);
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "tasks.taskUpdatedSuccess",
      });
    }
    // eslint-disable-next-line
  }, [updating]);

  useEffect(() => {
    if (error) {
      UIUtils.handleServerError(k2Intl, error, clearError);
    }
  }, [error, k2Intl, clearError, clearFormTouched]);

  return (
    <div>
      <EditableSection>
        <DatePickerWithoutYearSkip
          // @ts-ignore
          showToday={false}
          onOpenChange={openEditor}
          value={dueDateRemoved ? null : tempDate || date}
          onChange={setTempDueDate}
          disabledDate={disabledDate}
          open={editing}
          format={dateFormat}
          renderExtraFooter={() => (
            <div>
              <QuickOptsContainer>
                <span onClick={() => setTempDate(moment())}>
                  <K2Message localeKey="common.today" />
                </span>
                <span onClick={() => setTempDate(moment().add(1, "day"))}>
                  <K2Message localeKey="common.tomorrow" />
                </span>
                <span onClick={() => setTempDate(moment().add(1, "week"))}>
                  <K2Message localeKey="common.nextWeek" />
                </span>
              </QuickOptsContainer>
              <Divider />
              <CancelSaveOptsContainer>
                <CancelSaveOpts
                  loading={updating}
                  onCancel={closeEditor}
                  onSave={() =>
                    updateDueDate(
                      tempDate.format(DateFormatProvider.serverDateFormat),
                      taskId,
                    )
                  }
                />
              </CancelSaveOptsContainer>
            </div>
          )}
        />
        {dueDateRemoved && (
          <RelativePositionedContainer>
            <CancelSaveOpts
              loading={updating}
              onCancel={cancelDueDateRemoval}
              onSave={() => updateDueDate(null, taskId)}
            />
          </RelativePositionedContainer>
        )}
      </EditableSection>
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  date:
    state.editViewTask.task && state.editViewTask.task.dueDate
      ? moment(
          state.editViewTask.task.dueDate,
          DateFormatProvider.serverDateFormat,
        )
      : null,
  taskId: state.editViewTask.task ? state.editViewTask.task.id : null,
  updating: state.editViewTask.updatingDueDate,
  error: state.editViewTask.errorUpdatingDueDate,
  dateFormat: state.subscription?.users?.currentUserLocalizationSettings
    ?.dateFormat
    ? DateFormatProvider.getConfig(
        state.subscription.users.currentUserLocalizationSettings.dateFormat,
      ).format
    : null,
});

const mapDispatchToProps = {
  updateDueDate: updateTaskDueDateRequest,
  clearError: clearTaskDueDateError,
};

export default injectK2Intl(
  connect(mapStateToProps, mapDispatchToProps)(DueDate),
);
