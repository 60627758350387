import { LookupType as CommsLookupType } from "@n3oltd/k2.communications.sdk.lookups";
import { LookupType as TemplateLookupTypes } from "@n3oltd/karakoram.templates.sdk.lookups";

import injectLookups from "appRedux/utils/injectLookups";

import NewEditPdfTemplate from "./NewEditPdfTemplate";
import composed from "./connect";

const withLookups = injectLookups(NewEditPdfTemplate, [
  TemplateLookupTypes.AssetTypes,
  TemplateLookupTypes.PageSizes,
  TemplateLookupTypes.PageOrientations,
  CommsLookupType.Categories,
  "queues",
]);

export default composed(withLookups);
