import React, { FC, ReactText, useCallback, useEffect, useState } from "react";

import { MenuOutlined, SearchOutlined } from "@ant-design/icons/lib";
import { TaskStatus } from "@n3oltd/karakoram.tasks.sdk.tasks";
import { Col, Dropdown, Popover, Row } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BaseListModel from "appRedux/models/subscription/BaseListModel";
import { toggleCollapsedSideNav } from "appRedux/modules/settings/actions";
import { usersActions } from "appRedux/modules/subscription";
import { dashboardTasksActions } from "appRedux/modules/tasks";
import { updateTaskDrawerTasks } from "appRedux/modules/tasks/drawer/actions";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import AppHelpers from "common/helpers";
import EditTask from "components/dashboard/editTask/editTask";
import { K2Message } from "components/k2Widgets";
import FakeIconLink from "components/k2Widgets/k2FakeIconLink";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";
import GlobalSearch from "components/navigation/mainAppHeader/globalSearch";
import { SearchEntityType } from "components/navigation/mainAppHeader/globalSearch/modules/types";
import NavigationActionIcons from "components/navigation/mainAppHeader/headerActions/HeaderActionIcons";
import SubscriptionMenu from "components/subscriptionMenu";
import { DEFAULT_GLOBAL_SEARCH_OPTIONS } from "constants/appConstants";

import { searchOptions as k2SearchOptions } from "./globalSearch/searchableEntities/k2";
import { searchOptions as q1SearchOptions } from "./globalSearch/searchableEntities/q1";

const { fetchDashboardTasks } = dashboardTasksActions;

const Container = styled.div`
  width: 100%;
  padding: 18px 4% 12px;
  height: 112px;
  background-image: url(${require("assets/images/shared/top-header-background-blend-multiply.png")});
  background-color: ${({ theme }) => theme.primaryColor};
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply, normal;

  .anticon-menu svg {
    fill: ${({ theme }) => theme.white_color};
  }

  @media screen and (max-width: 991px) {
    height: 64px;
    position: sticky;
    top: 0;
    padding: 10px 18px;
    z-index: 99;
  }
`;

const ContentRow = styled(Row)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: ${({ theme }) => theme.max_content_width};
    margin: auto;
  }
`;

const Logo = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  i {
    margin-right: 10px;
  }
`;

const AppNameArea = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.white_color};
  .app-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
  }

  > span {
    line-height: 1;
  }

  @media screen and (max-width: 768px) {
    .app-name {
      display: none;
    }
  }
`;

interface IProps extends InjectedK2IntlProps {}

const Header: FC<IProps> = (props) => {
  const { appId, theme } = useAppContext();

  const [editingTaskId, setEditingTaskId] = useState<string>(null);
  const dispatch = useDispatch();
  const taskDrawerState = useSelector(
    (state: IApplicationState) => state.tasks.taskDrawer,
  );
  const { entityId, entityType } = taskDrawerState;
  const tasksDrawerShowing = !!entityId && !!entityType;
  const listModel = new BaseListModel(props);

  const getPopupContainer = useCallback((triggerNode: HTMLElement) => {
    const element = document.getElementById("n3o-main-header");

    return element !== null ? element : triggerNode;
  }, []);

  useEffect(() => {
    dispatch(usersActions.getCurrentSubscriptionRelease());
    // eslint-disable-next-line
  }, []);

  const currentUser = useSelector(
    (state: IApplicationState) => state.subscription.users.k2User,
  );
  const releaseNotes = useSelector(
    (state: IApplicationState) =>
      state.subscription.users.k2SubscriptionReleaseNotes,
  );
  const releaseNotesLoading = useSelector(
    (state: IApplicationState) =>
      state.subscription.users.isReleaseNotesLoading,
  );
  const navCollapsed = useSelector(
    (state: IApplicationState) => state.settings.navCollapsed,
  );

  const renderSearchBar = useCallback(
    (classNames?: string, isMobileVersion?: boolean, width?: ReactText) => {
      return (
        <GlobalSearch
          className={classNames}
          onSearchResultSelect={(id: string, searchType: SearchEntityType) => {
            if (searchType === "tasks") {
              setEditingTaskId(id);
            }
          }}
          placeholder={
            <div className={"n3o-text-white"}>
              <SearchOutlined style={{ fontSize: 18 }} />
              <span style={{ marginLeft: 10 }}>
                <K2Message localeKey={"common.searchFor"} />
              </span>
            </div>
          }
          isMobileVersion={isMobileVersion}
          width={width}
          searchOptions={appId === "k2" ? k2SearchOptions : q1SearchOptions}
          initialEntitySelection={DEFAULT_GLOBAL_SEARCH_OPTIONS[appId]}
        />
      );
    },
    [appId],
  );

  return (
    <Container id={"n3o-main-header"}>
      {editingTaskId && (
        <EditTask
          onCancel={() => {
            // Update dashboard tasks
            dispatch(fetchDashboardTasks());

            // If the tasks banner is showing, update the tasks
            if (tasksDrawerShowing) {
              dispatch(
                updateTaskDrawerTasks({
                  hasLinkedEntity: true,
                  linkedEntity: entityId,
                  status: [TaskStatus.Pending],
                  pageSize: listModel.getDefaultGridInfo().pageSize,
                }),
              );
            }

            setEditingTaskId(null);
          }}
          taskId={editingTaskId}
        />
      )}

      <ContentRow>
        {/* Hamburger Icon */}
        <Col xs={3} sm={3} md={2} lg={0} className="">
          <FakeIconLink onHoverOnly={true}>
            <MenuOutlined
              style={{
                color: theme.white_color,
                fontSize: 24,
              }}
              onClick={() => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}
            />
          </FakeIconLink>
        </Col>

        {/* App Name */}
        <Col xs={3} sm={3} md={4} lg={4} xl={3} xxl={3}>
          <Logo>
            <Dropdown
              trigger={["click"]}
              overlay={
                <SubscriptionMenu
                  k2User={currentUser}
                  isReleaseNotesLoading={releaseNotesLoading}
                  k2SubscriptionReleaseNotes={releaseNotes}
                />
              }
              placement="bottomRight"
              getPopupContainer={getPopupContainer}
            >
              <AppNameArea>
                <FakeIconLink onHoverOnly skipPathHover>
                  <N3oIcon
                    icontype={
                      appId === "k2" ? "n3o-engage-icon" : "n3o-tally-icon"
                    }
                    width={36}
                    height={36}
                    fill={theme.white_color}
                    className={"n3o-mr-3"}
                  />
                </FakeIconLink>
                <span className={"app-name"}>
                  {AppHelpers.renderAppName(appId)}
                </span>
              </AppNameArea>
            </Dropdown>
          </Logo>
        </Col>

        {/* Search Popover Icon for Small Screens */}
        <Col xs={3} sm={3} md={0} order={2} lg={0}>
          <Popover
            placement="bottomRight"
            content={
              <div className="n3o-d-flex">
                {renderSearchBar(null, true, 400)}
              </div>
            }
            getPopupContainer={(triggerNode: HTMLElement) => {
              const element = document.getElementById("n3o-main-header");
              return !_.isNull(element) ? element : triggerNode;
            }}
            trigger="click"
          >
            <span>
              <FakeIconLink onHoverOnly={true}>
                <SearchOutlined
                  style={{
                    color: theme.white_color,
                    fontSize: 24,
                  }}
                />
              </FakeIconLink>
            </span>
          </Popover>
        </Col>

        {/* Full Size Search Bar */}
        <Col
          xs={0}
          sm={0}
          md={12}
          lg={15}
          xl={17}
          xxl={18}
          order={3}
          className="n3o-p-0"
        >
          {renderSearchBar()}
        </Col>

        {/* Help, Tasks & Profile Icons */}
        <Col
          xs={15}
          sm={15}
          md={6}
          lg={5}
          xl={4}
          xxl={3}
          order={4}
          className="n3o-p-0"
        >
          <NavigationActionIcons currentUser={currentUser} />
        </Col>
      </ContentRow>
    </Container>
  );
};

export default injectK2Intl(Header);
