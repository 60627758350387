import { StatusCodes } from "http-status-codes";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";

export default class ApiResponseModel<T> implements IApiResponse<T> {
  result: T;
  error: ServerError;

  constructor(res: T, err: ServerError = null) {
    this.result = res;
    this.error = err;

    // Forbidden status encountered during normal use of the application indicates
    // that a user has been blocked or deactivated from the system by an administrator.
    // The simplest thing to do is handle this error immediately here by
    // redirecting to the error page.
    if (this.error?.status === K2StatusCodes.forbidden) {
      window.location.replace(
        window.location.pathname.includes("tally")
          ? Q1Routes.errors.accessRevoked
          : K2Routes.errors.accessRevoked,
      );
    }

    // Unauthorized status from an API endpoint may mean someone's privileges have been
    // revoked (maybe their admin privileges) so we redirect to Unauthorized page
    if (this.error?.status === K2StatusCodes.unAuthorized) {
      window.location.replace(
        window.location.pathname.includes("tally")
          ? Q1Routes.errors._401
          : K2Routes.errors._401,
      );
    }
  }

  getResultOrDefault(defaultValue: T = null) {
    return this.result !== undefined ? this.result : defaultValue;
  }
}

export interface IValidationFailure {
  property: string;
  error: string;
}

export interface IValidationErrorWithSeverity extends IValidationFailure {
  severity: string;
}

export class ProblemDetails {
  type?: string;
  response?: string;

  constructor(
    public title?: React.ReactNode,
    public detail?: React.ReactNode,
    public instance?: string,
    public status?: number,
    public errors?:
      | Record<string, string[]>
      | Array<IValidationFailure>
      | Array<IValidationErrorWithSeverity>
      | any,
  ) {}
}

export class ServerError {
  status?: number;
  statusText?: string;

  constructor(public data: ProblemDetails, statusCode?: number) {
    this.status = statusCode;
  }
}

export class K2StatusCodes {
  public static readonly badRequest = StatusCodes.BAD_REQUEST;
  public static readonly unAuthorized = StatusCodes.UNAUTHORIZED;
  public static readonly notFound = StatusCodes.NOT_FOUND;
  public static readonly preconditionFailed = StatusCodes.PRECONDITION_FAILED;
  public static readonly internalServerError =
    StatusCodes.INTERNAL_SERVER_ERROR;
  public static readonly paymentRequired = StatusCodes.PAYMENT_REQUIRED;
  public static readonly forbidden = StatusCodes.FORBIDDEN;

  public static isErrorStatus = (status: number): boolean => {
    return (
      status === K2StatusCodes.badRequest ||
      status === K2StatusCodes.unAuthorized ||
      status === K2StatusCodes.notFound ||
      status === K2StatusCodes.preconditionFailed ||
      status === K2StatusCodes.internalServerError ||
      status === K2StatusCodes.paymentRequired ||
      status === K2StatusCodes.forbidden
    );
  };
}

export interface IApiErrorResponse {
  error?: ServerError;
}

export interface IApiResponse<T> extends IApiErrorResponse {
  result?: T;
  getResultOrDefault(defaultValue?: T): T;
}
