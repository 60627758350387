import React, { FC, useCallback, useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons/lib";
import { UserRes, UserStatus } from "@n3oltd/k2.users.sdk.users/esm";
import {
  SecurityPrincipalProfile,
  SecurityPrincipalReq,
  SecurityPrincipalType,
  TaskRes,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Select } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import styled from "styled-components";

import { _tasksClient, _usersClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import IApplicationState from "appRedux/types";
import UserAvatar from "components/avatars/userAvatar";
import {
  fetchTaskError,
  fetchTaskSuccess,
  updateSharedWithUsers,
} from "components/dashboard/editTask/modules/actions";
import { K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import K2Spin from "components/k2Widgets/k2Spin";
import { UIUtils } from "components/utils";
import { DEFAULT_DELAY } from "constants/appConstants";
import useDebounce from "hooks/useDebounce";

const { Option } = Select;

const SpanWithSpace = styled.span`
  margin-left: 5px;
`;

const UserOptionItem = styled.span`
  display: flex;
  align-items: center;
  .ant-avatar-sm {
    min-width: 24px;
  }
`;

const Updating = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  cursor: default;
`;

interface IProps extends InjectedK2IntlProps {
  updateSharedWith: typeof updateSharedWithUsers;
  fetchTaskSuccess: typeof fetchTaskSuccess;
  fetchTaskError: typeof fetchTaskError;
  subscriptionId: string;
  allUsers?: SecurityPrincipalProfile[];
  taskId: string;
  taskRevisionId: string;
  showAddButton: boolean;

  onValueSelect?: (idSelected: string, name?: string) => void;
}

const SearchUsers: FC<IProps> = ({
  k2Intl,
  subscriptionId,
  allUsers,
  taskId,
  taskRevisionId,
  updateSharedWith,
  showAddButton,
  onValueSelect,
  fetchTaskError,
  fetchTaskSuccess,
}) => {
  const [showSearch, setShowSearch] = useState<boolean>(!showAddButton);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<UserRes[]>([]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<string | null>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DELAY);

  const currentlySelectedUserIds = allUsers?.map?.((u) => u.principalId);

  // This effect runs whenever debouncedSearchTerm changes
  useEffect(() => {
    let isCancelled = false;

    if (debouncedSearchTerm) {
      setSearching(true);

      const fetchUsers = async () => {
        try {
          const response = await K2Service.toResponse(
            _usersClient.find({
              name: debouncedSearchTerm,
              status: [UserStatus.Active],
            }),
          );

          if (!isCancelled) {
            setSearching(false);
            if (response.error) {
              UIUtils.handleServerError(k2Intl, response.error);
            } else {
              const results = response.getResultOrDefault().items;
              const resultsWithoutCurrentlySelected = results.filter(
                (result) => {
                  return !currentlySelectedUserIds?.includes(result.id);
                },
              );

              setSearchResults(resultsWithoutCurrentlySelected);
            }
          }
        } catch (e) {
          if (!isCancelled) {
            setSearching(false);
          }
          console.log("error", e);
        }
      };

      fetchUsers();
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line
  }, [debouncedSearchTerm, k2Intl]);

  const getSelectedUser = useCallback(
    (idSelected: string) => {
      return searchResults.find((elem) => elem.id === idSelected);
    },
    [searchResults],
  );

  const addSharedWithUser = useCallback(
    async (idSelected: string) => {
      const userSelected = getSelectedUser(idSelected);

      const newSharedWithUser: SecurityPrincipalProfile = {
        name: userSelected.fullName,
        initials: userSelected.initials,
        color: userSelected.color,
        hasPicture: !!userSelected.picture,
        principalId: userSelected.id,
        type: SecurityPrincipalType.User,
      };

      const newSharedWith: SecurityPrincipalProfile[] = [
        ...allUsers,
        newSharedWithUser,
      ];

      const securityPrincipals: SecurityPrincipalReq[] = newSharedWith?.map?.(
        (item) => {
          return {
            principalId: item.principalId,
            type: item.type,
          };
        },
      );

      setUpdating(true);

      const response: IApiResponse<void> = await K2Service.toResponse(
        _tasksClient.share(taskRevisionId, {
          principals: securityPrincipals,
        }),
      );

      if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else if (showAddButton) {
        const newTask: IApiResponse<TaskRes> = await K2Service.toResponse(
          _tasksClient.getById(taskId),
        );

        if (!newTask.error) {
          fetchTaskSuccess(newTask.getResultOrDefault(), true);
        } else {
          fetchTaskError(newTask.error);
        }

        // Update global state
        updateSharedWith(newSharedWith);
        setShowSearch(false);
      }

      setUpdating(false);
      setSelectedResult(null);
    },
    [
      allUsers,
      getSelectedUser,
      k2Intl,
      showAddButton,
      taskId,
      taskRevisionId,
      updateSharedWith,
      fetchTaskError,
      fetchTaskSuccess,
    ],
  );

  const handleOnSelect = useCallback(
    (idSelected: string) => {
      if (showAddButton) {
        addSharedWithUser(idSelected);
      }

      const userName = getSelectedUser(idSelected)?.fullName;
      onValueSelect?.(idSelected, userName);
    },
    [addSharedWithUser, getSelectedUser, onValueSelect, showAddButton],
  );

  if (showSearch && !updating) {
    return (
      <Select
        showSearch
        className={"usersSearch"}
        autoFocus={showAddButton}
        showAction={!_.isEmpty(searchTerm) ? ["focus", "click"] : ["click"]}
        onSearch={setSearchTerm}
        showArrow={!showAddButton}
        allowClear={!showAddButton}
        dropdownMatchSelectWidth={false}
        optionLabelProp={"children"}
        getPopupContainer={(triggerNode: HTMLElement) => {
          const element = document.getElementById("route-section");
          return !_.isNull(element) ? element : triggerNode;
        }}
        onBlur={() => {
          if (showAddButton) {
            setSearchResults([]);
            setShowSearch(false);
          }
        }}
        notFoundContent={
          searching ? (
            <K2Spin size="small" />
          ) : searchTerm ? (
            <K2Message
              localeKey="common.noResults"
              values={{ value: searchTerm }}
            />
          ) : (
            <K2Message localeKey="tasks.action.searchForUser" />
          )
        }
        placeholder={<K2Message localeKey="tasks.action.searchForUser" />}
        loading={searching}
        onSelect={handleOnSelect}
        value={selectedResult}
        onChange={(idSelected: string) => {
          setSelectedResult(idSelected);

          const userName = getSelectedUser(idSelected)?.fullName;
          onValueSelect?.(idSelected, userName);
        }}
        filterOption={false}
        style={{ width: "100%" }}
      >
        {searchResults?.map?.((user) => {
          return (
            <Option key={user.id} value={user.id}>
              <UserOptionItem>
                <UserAvatar
                  presetId="small"
                  size="small"
                  user={user}
                  subscriptionId={subscriptionId}
                />{" "}
                <SpanWithSpace>{user.fullName}</SpanWithSpace>
              </UserOptionItem>
            </Option>
          );
        })}
      </Select>
    );
  } else if (updating) {
    return (
      <Updating>
        <K2Spin size="small" />
      </Updating>
    );
  } else {
    return (
      <div onClick={() => setShowSearch(true)}>
        <PlusOutlined />
        <K2Message localeKey="tasks.action.addUser" />
      </div>
    );
  }
};

const mapStateToProps = (state: IApplicationState) => ({
  subscriptionId: state.subscription.users.k2Subscription?.id,
  taskId: state.editViewTask.task?.id,
  taskRevisionId: state.editViewTask.task?.revisionId,
});

const mapDispatchToProps = {
  updateSharedWith: updateSharedWithUsers,
  fetchTaskSuccess: fetchTaskSuccess,
  fetchTaskError: fetchTaskError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectK2Intl(SearchUsers));
