import React, { FC } from "react";
import { Col, Row } from "antd";
import { K2Message } from "components/k2Widgets";
import styled from "styled-components";
import { ColProps } from "antd/es/col";

const SummaryRow = styled(Row)`
  & {
    margin-bottom: 10px;
  }
`;

const ItemSummaryLabel = styled.span`
  color: ${({ theme }) => theme.grey_7};
`;

const ItemSummaryValue = styled.span`
  font-weight: ${({ theme }) => theme.font_weight_bold};
`;

interface ItemSummaryRow {
  labelKey?: string;
  label?: string | React.ReactNode | JSX.Element;
  value: string | React.ReactNode | JSX.Element;
  labelColumnProps?: ColProps;
  valueColumnProps?: ColProps;
}

const ItemSummaryRow: FC<ItemSummaryRow> = ({
  labelKey,
  value,
  label,
  labelColumnProps = {},
  valueColumnProps = {},
}) => {
  return (
    <SummaryRow>
      <Col
        {...{
          xs: 24,
          sm: 6,
          ...labelColumnProps,
        }}
      >
        <ItemSummaryLabel>
          {label || <K2Message localeKey={labelKey} />}
        </ItemSummaryLabel>
      </Col>
      <Col
        {...{
          xs: 24,
          sm: 18,
          ...valueColumnProps,
        }}
      >
        <ItemSummaryValue>{value}</ItemSummaryValue>
      </Col>
    </SummaryRow>
  );
};

export default ItemSummaryRow;
