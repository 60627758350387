import produce from "immer";
import { PartialOrLayoutState } from "./types";
import { ActionTypes, PartialOrLayoutAction } from "./actionTypes";

const initialState: PartialOrLayoutState = {
  data: null,
  editStatus: null,
  error: null,
  loading: false,
  saving: false,
  temporaryUploadFiles: [],
};

const partialsLayoutsReducer = (
  state = initialState,
  action: PartialOrLayoutAction,
) =>
  produce(state, (draft: PartialOrLayoutState) => {
    switch (action.type) {
      case ActionTypes.FETCH_PARTIAL_OR_LAYOUT:
        draft.loading = true;
        break;
      case ActionTypes.FETCH_PARTIAL_OR_LAYOUT_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case ActionTypes.CLEAR_PARTIAL_OR_LAYOUT:
        draft.data = null;
        break;
      case ActionTypes.FETCH_PARTIAL_OR_LAYOUT_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        break;
      case ActionTypes.CLEAR_PARTIAL_OR_LAYOUT_ERROR:
        draft.error = null;
        break;

      case ActionTypes.CREATE_PARTIAL_OR_LAYOUT:
        draft.saving = true;
        break;
      case ActionTypes.CREATE_PARTIAL_OR_LAYOUT_SUCCESS:
      case ActionTypes.CREATE_PARTIAL_OR_LAYOUT_ERROR:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.UPDATE_PARTIAL_OR_LAYOUT:
        draft.saving = true;
        break;
      case ActionTypes.UPDATE_PARTIAL_OR_LAYOUT_ERROR:
      case ActionTypes.UPDATE_PARTIAL_OR_LAYOUT_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.DELETE_PARTIAL_OR_LAYOUT:
        draft.saving = true;
        break;
      case ActionTypes.DELETE_PARTIAL_OR_LAYOUT_ERROR:
      case ActionTypes.DELETE_PARTIAL_OR_LAYOUT_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.UPDATE_TEMPORARY_ASSETS:
        if (
          draft.temporaryUploadFiles.find((u) => u.uid === action.upload.uid)
        ) {
          draft.temporaryUploadFiles = draft.temporaryUploadFiles?.map?.(
            (u) => {
              if (u.uid === action.upload.uid)
                return {
                  ...action.upload,
                };
              else return u;
            },
          );
        } else {
          draft.temporaryUploadFiles = [
            ...draft.temporaryUploadFiles,
            { ...action.upload },
          ];
        }
        break;
      case ActionTypes.REMOVE_TEMPORARY_ASSET:
        draft.temporaryUploadFiles = draft.temporaryUploadFiles.filter(
          (f) => f.uid !== action.temporaryAsset.uid,
        );
        break;
      case ActionTypes.CLEAR_TEMPORARY_ASSETS:
        draft.temporaryUploadFiles = [];
        break;

      case ActionTypes.CLEAR_PARTIAL_OR_LAYOUT_EDIT_STATUS:
        draft.editStatus = null;
        break;
      case ActionTypes.UPDATE_DIRTY:
        draft.dirty = action.dirty;
        break;
    }
  });

export default partialsLayoutsReducer;
