import { TaskStatus } from "@n3oltd/karakoram.tasks.sdk.tasks";
import produce from "immer";
import { omit } from "lodash";

import { DataActions } from "appRedux/modules/sharedTypes";
import * as actionTypes from "components/dashboard/editTask/modules/actionTypes";
import { EditViewTaskState } from "components/dashboard/editTask/modules/types";

const editViewTaskInitialState: EditViewTaskState = {
  task: null,
  loading: false,
  errorFetchingTask: null,
  taskNotes: [],
  savingNote: false,
  errorSavingNote: null,
  updatingStatus: false,
  errorUpdatingStatus: null,
  deleting: false,
  errorDeletingTask: null,
  updatingTitle: false,
  errorUpdatingTitle: null,
  updatingDueDate: false,
  errorUpdatingDueDate: null,
  updatingPriority: false,
  errorUpdatingPriority: null,
  updatingAssignees: false,
  errorUpdatingAssignees: null,
  updatingLinkedEntity: false,
  errorUpdatingLinkedEntity: null,
  deletingLinkedEntity: false,
  errorDeletingLinkedEntity: null,
  dirty: {
    title: false,
    date: false,
    assignees: false,
    relatedLink: false,
    newNote: false,
  },
};

const editViewTaskReducer = (
  state: EditViewTaskState = editViewTaskInitialState,
  action,
): EditViewTaskState => {
  return produce(state, (draft: EditViewTaskState) => {
    switch (action.type) {
      // Fetching a task
      case actionTypes.FETCH_TASK:
        draft.loading = true;
        draft.errorFetchingTask = null;
        break;
      case actionTypes.FETCH_TASK_ERROR:
        draft.loading = false;
        draft.errorFetchingTask = action.error;
        break;
      case actionTypes.FETCH_TASK_SUCCESS:
        draft.loading = false;
        draft.errorFetchingTask = null;
        if (action.revisionIdOnly) {
          draft.task.revisionId = action.task.revisionId;
        } else {
          if (action.task.notes) {
            draft.taskNotes = action.task.notes;
          }
          draft.task = omit(action.task, ["notes"]);
        }
        break;
      case actionTypes.CLEAR_FETCHING_TASK_ERROR:
        draft.errorFetchingTask = null;
        break;

      // Updating task status
      case actionTypes.UPDATE_TASK_STATUS_REQUEST:
        draft.updatingStatus = true;
        break;
      case actionTypes.MARK_TASK_COMPLETE:
        draft.task.status = TaskStatus.Completed;
        draft.updatingStatus = false;
        break;
      case actionTypes.MARK_TASK_PENDING:
        draft.task.status = TaskStatus.Pending;
        draft.updatingStatus = false;
        break;
      case actionTypes.UPDATE_TASK_STATUS_ERROR:
        draft.updatingStatus = false;
        draft.errorUpdatingStatus = action.error;
        break;
      case actionTypes.CLEAR_UPDATING_TASK_STATUS:
        draft.updatingStatus = false;
        break;

      // Deleting a task
      case actionTypes.DELETE_TASK_REQUEST:
        draft.deleting = true;
        draft.errorDeletingTask = null;
        break;
      case actionTypes.DELETE_TASK_SUCCESS:
        draft.deleting = false;
        draft.errorDeletingTask = null;
        break;
      case actionTypes.DELETE_TASK_ERROR:
        draft.deleting = false;
        draft.errorDeletingTask = action.error;
        break;
      case actionTypes.CLEAR_DELETING_TASK:
        draft.deleting = false;
        break;
      case actionTypes.CLEAR_DELETE_TASK_ERROR:
        draft.errorDeletingTask = null;
        break;

      // Updating task title
      case actionTypes.UPDATE_TASK_TITLE_REQUEST:
        draft.updatingTitle = true;
        draft.errorUpdatingTitle = null;
        break;
      case actionTypes.UPDATE_TASK_TITLE_SUCCESS:
        draft.updatingTitle = false;
        draft.errorUpdatingTitle = null;
        draft.task.title = action.title;
        break;
      case actionTypes.UPDATE_TASK_TITLE_ERROR:
        draft.updatingTitle = false;
        draft.errorUpdatingTitle = action.error;
        break;
      case actionTypes.CLEAR_UPDATE_TASK_TITLE_ERROR:
        draft.errorUpdatingTitle = null;
        break;
      case actionTypes.CLEAR_UPDATING_TASK_TITLE:
        draft.updatingTitle = false;
        break;

      // Updating task due date
      case actionTypes.UPDATE_TASK_DUE_DATE_REQUEST:
        draft.updatingDueDate = true;
        draft.errorUpdatingDueDate = null;
        break;
      case actionTypes.UPDATE_TASK_DUE_DATE_SUCCESS:
        draft.updatingDueDate = false;
        draft.task.dueDate = action.dueDate;
        draft.errorUpdatingDueDate = null;
        break;
      case actionTypes.UPDATE_TASK_DUE_DATE_ERROR:
        draft.updatingDueDate = false;
        draft.errorUpdatingDueDate = action.error;
        break;
      case actionTypes.CLEAR_UPDATE_TASK_DUE_DATE_ERROR:
        draft.errorUpdatingDueDate = null;
        break;
      case actionTypes.CLEAR_UPDATING_TASK_DUE_DATE:
        draft.updatingDueDate = false;
        break;

      // Updating task priority
      case actionTypes.UPDATE_TASK_PRIORITY_REQUEST:
        draft.updatingPriority = true;
        draft.errorUpdatingPriority = null;
        break;
      case actionTypes.UPDATE_TASK_PRIORITY_SUCCESS:
        draft.updatingPriority = false;
        draft.task.priority = action.priority;
        draft.errorUpdatingPriority = null;
        break;
      case actionTypes.UPDATE_TASK_PRIORITY_ERROR:
        draft.updatingPriority = false;
        draft.errorUpdatingPriority = action.error;
        break;
      case actionTypes.CLEAR_UPDATE_TASK_PRIORITY_ERROR:
        draft.errorUpdatingPriority = null;
        break;
      case actionTypes.CLEAR_UPDATING_TASK_PRIORITY:
        draft.updatingPriority = false;
        break;

      // Updating task assignees
      case actionTypes.UPDATE_TASK_ASSIGNEES_REQUEST:
        draft.updatingAssignees = true;
        draft.errorUpdatingAssignees = null;
        break;
      case actionTypes.UPDATE_TASK_ASSIGNEES_SUCCESS:
        draft.updatingAssignees = false;
        draft.errorUpdatingAssignees = null;
        draft.task.assignedTo = action.principalProfiles;
        break;
      case actionTypes.UPDATE_TASK_ASSIGNEES_ERROR:
        draft.updatingAssignees = false;
        draft.errorUpdatingAssignees = action.error;
        break;
      case actionTypes.CLEAR_UPDATE_TASK_ASSIGNEES_ERROR:
        draft.errorUpdatingAssignees = null;
        break;
      case actionTypes.CLEAR_UPDATING_TASK_ASSIGNEES:
        draft.updatingAssignees = false;
        break;

      // Updating linked entity
      case actionTypes.UPDATE_LINKED_ENTITY_REQUEST:
        draft.updatingLinkedEntity = true;
        draft.errorUpdatingLinkedEntity = null;
        break;
      case actionTypes.UPDATE_LINKED_ENTITY_SUCCESS:
        draft.updatingLinkedEntity = false;
        draft.errorUpdatingLinkedEntity = null;
        draft.task.linkedEntity = action.linkedEntity;
        break;
      case actionTypes.UPDATE_LINKED_ENTITY_ERROR:
        draft.updatingLinkedEntity = false;
        draft.errorUpdatingLinkedEntity = action.error;
        break;
      case actionTypes.CLEAR_UPDATE_LINKED_ENTITY_ERROR:
        draft.errorUpdatingLinkedEntity = null;
        break;
      case actionTypes.CLEAR_UPDATING_LINKED_ENTITY:
        draft.updatingLinkedEntity = false;
        break;

      // Deleting linked entity
      case actionTypes.DELETE_LINKED_ENTITY_REQUEST:
        draft.deletingLinkedEntity = true;
        break;
      case actionTypes.DELETE_LINKED_ENTITY_ERROR:
        draft.deletingLinkedEntity = false;
        draft.errorDeletingLinkedEntity = action.error;
        break;
      case actionTypes.DELETE_LINKED_ENTITY_SUCCESS:
        draft.deletingLinkedEntity = false;
        draft.task.linkedEntity = null;
        break;
      case actionTypes.CLEAR_DELETING_LINKED_ENTITY:
        draft.deletingLinkedEntity = false;
        break;
      case actionTypes.CLEAR_DELETING_LINKED_ENTITY_ERROR:
        draft.errorDeletingLinkedEntity = null;
        break;

      // Deleting a note
      case actionTypes.DELETE_TASK_NOTE_REQUEST:
        draft.taskNotes = draft.taskNotes?.map?.((note) => {
          if (note.id === action.noteId) {
            note.deleting = true;
          }
          return note;
        });
        break;
      case actionTypes.DELETE_TASK_NOTE_SUCCESS:
        draft.taskNotes = draft.taskNotes?.map?.((note) => {
          if (note.id === action.noteId) {
            note.deleting = false;
            note.editStatus = {
              action: DataActions.delete,
            };
          }
          return note;
        });
        break;
      case actionTypes.DELETE_TASK_NOTE_ERROR:
        draft.taskNotes = draft.taskNotes?.map?.((note) => {
          if (note.id === action.noteId) {
            note.deleting = false;
            note.errorDeleting = action.error;
          }
          return note;
        });
        break;
      case actionTypes.CLEAR_DELETE_TASK_NOTE_ERROR:
        draft.taskNotes = draft.taskNotes?.map?.((note) => {
          if (note.id === action.noteId) {
            note.errorDeleting = action.null;
          }
          return note;
        });
        break;
      case actionTypes.CLEAR_DELETING_TASK_NOTE:
        draft.taskNotes = draft.taskNotes?.map?.((note) => {
          if (note.id === action.noteId) {
            note.deleting = action.false;
            note.errorDeleting = null;
          }
          return note;
        });
        break;
      case actionTypes.CLEAR_NOTE_EDIT_STATUS: {
        const notes = draft.taskNotes.reduce((newNotes, note) => {
          if (note.id === action.noteId) {
            // Remove the note if it was deleted, otherwise clear its edit status
            if (note.editStatus.action === DataActions.delete) return newNotes;
            else {
              note.editStatus = {
                action: null,
              };
            }
          }
          newNotes.push(note);
          return newNotes;
        }, []);
        draft.taskNotes = notes;
        break;
      }

      // Updating a note
      case actionTypes.UPDATE_NOTE_REQUEST:
        draft.taskNotes = draft.taskNotes?.map?.((note) => {
          if (note.id === action.noteId) {
            note.updating = true;
            note.errorsUpdating = [];
          }
          return note;
        });
        break;
      case actionTypes.UPDATE_NOTE_SUCCESS:
        draft.taskNotes = draft.taskNotes?.map?.((note) => {
          if (note.id === action.noteId) {
            note.updating = false;
            note.errorsUpdating = [];
          }
          return note;
        });
        break;
      case actionTypes.UPDATE_NOTE_ERROR:
        draft.taskNotes = draft.taskNotes?.map?.((note) => {
          if (note.id === action.noteId) {
            note.errorsUpdating = [...note.errorsUpdating, action.error];
            note.updating = false;
          }
          return note;
        });
        break;
      case actionTypes.CLEAR_UPDATE_NOTE_ERROR:
        // Clear only the error that has been cleared
        draft.taskNotes = draft.taskNotes?.map?.((note) => {
          if (note.id === action.noteId) {
            note.errorsUpdating = [];
          }
          return note;
        });
        break;
      case actionTypes.CLEAR_UPDATING_NOTE:
        draft.taskNotes = draft.taskNotes?.map?.((note) => {
          if (note.id === action.noteId) {
            note.updating = false;
          }
          return note;
        });
        break;

      // Saving a new note
      case actionTypes.SAVE_NEW_NOTE_REQUEST:
        draft.savingNote = true;
        draft.errorSavingNote = null;
        break;
      case actionTypes.SAVE_NEW_NOTE_SUCCESS:
        draft.savingNote = false;
        draft.errorSavingNote = null;
        draft.taskNotes.push(action.note);
        break;
      case actionTypes.SAVE_NEW_NOTE_ERROR:
        draft.savingNote = false;
        draft.errorSavingNote = action.error;
        break;
      case actionTypes.CLEAR_SAVE_NEW_NOTE_ERROR:
        draft.errorSavingNote = null;
        break;
      case actionTypes.CLEAR_SAVING_NEW_NOTE:
        draft.savingNote = false;
        break;

      // Updating the list of Shared With users
      case actionTypes.UPDATE_SHARED_WITH_USERS:
        draft.task.sharedWith = action.sharedWith;
        break;

      case actionTypes.SET_FORM_TOUCHED:
        draft.dirty[action.editableSectionName] = true;
        break;
      case actionTypes.CLEAR_FORM_TOUCHED:
        draft.dirty[action.editableSectionName] = false;
        break;
      case actionTypes.CLEAR_ALL_FORMS_TOUCHED:
        draft.dirty = editViewTaskInitialState.dirty;
        break;
    }
  });
};

export { editViewTaskReducer, editViewTaskInitialState };
