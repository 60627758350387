import React, { FC } from "react";

import { useRouter } from "hooks";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { K2Routes } from "appRedux/models/routes/RouteModel";
import K2Breadcrumb from "components/breadcrumb";
import _404Error from "components/errors/404";
import { asyncComponent } from "components/utils";
import { RequireAuth } from "containers/app/views";

const {
  searchFeedback,
  viewFeedback,
  main: { feedbacks },
} = K2Routes;

const FeedbacksDashboardWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "FeedbacksDashboard" */ "./dashboard"),
  ),
  "feedbacks",
);

const SearchFeedbacksWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "FeedbacksSearch" */ "./search"),
  ),
  "searchFeedback",
);

const ViewFeedbackWithAuth = RequireAuth(
  asyncComponent(() => import(/* webpackChunkName: "test" */ "./view")),
  "viewFeedback",
);

const FeedbacksRoutes: FC<RouteComponentProps> = () => {
  const router = useRouter();
  const { match } = router;

  return (
    <>
      <K2Breadcrumb />
      <Switch>
        <Route exact path={feedbacks} component={FeedbacksDashboardWithAuth} />
        <Route path={searchFeedback} component={SearchFeedbacksWithAuth} />
        <Route exact path={viewFeedback} component={ViewFeedbackWithAuth} />
        <Route path={`${match.path}/*`} component={_404Error} />
      </Switch>
    </>
  );
};

export default RequireAuth(FeedbacksRoutes, "feedbacks");
