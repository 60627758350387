import React, { FC } from "react";

import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { positionValues } from "react-custom-scrollbars";
import { useSelector } from "react-redux";

import IApplicationState from "appRedux/types";
import Dialler from "components/callCenter/dialler";

import AppLayout from "./AppLayout";

interface K2LayoutProps {
  isTabScreen: boolean;
  isMobileScreen: boolean;
  onScroll: (values: positionValues) => void;
  currentUser: CurrentUserRes;
  taskCount: number;
}

const K2Layout: FC<K2LayoutProps> = (props) => {
  const callCenterAgentSignedIn = useSelector(
    (state: IApplicationState) => state.callCenter?.signedIn || false,
  );

  return (
    <>
      {callCenterAgentSignedIn && <Dialler />}

      <AppLayout {...props} />
    </>
  );
};

export default K2Layout;
