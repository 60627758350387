import React, { FC, useEffect, useMemo } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Col, Form, Row, Spin } from "antd";

import { GeneralFormItemV4 } from "components/formItems";
import { K2Checkbox, K2Message } from "components/k2Widgets";
import SchemeFormHelpers from "routes/admin/sponsorships/schemes/helpers";

import { IComponentsFormProps, SchemeReqType } from "../../modules/types";
import { AddComponentButton, ComponentCardWrapper } from "../styled";
import ComponentBody from "./componentBody";

const formItemLayout = {
  labelCol: {
    xs: 12,
    sm: 12,
    md: 9,
    lg: 8,
    xl: 7,
    xxl: 4,
  },
  wrapperCol: {
    xs: 12,
    sm: 12,
    md: 15,
    lg: 16,
    xl: 17,
    xxl: 20,
  },
};

const Components: FC<IComponentsFormProps> = (props) => {
  const { form, isLoading, editing } = props;
  const { setFieldsValue } = form;

  const defaultComponentData = useMemo(() => {
    return {
      pricingType: "simple",
      required: true,
      capacity: 1,
      pricing: {
        price: {
          amount: undefined,
          locked: false,
        },
      },
    };
  }, []);

  useEffect(() => {
    if (!editing) {
      setFieldsValue({
        ["components"]: [
          defaultComponentData,
        ],
      });
    }
  }, [defaultComponentData, editing, setFieldsValue]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[12, 18]} className={"n3o-mb-0"}>
        <Col span={24}>
          <Form.List name={SchemeFormHelpers.componentsListFieldName}>
            {(
              componentFields,
              { add: addComponent, remove: removeComponent },
            ) => {
              const moreThan1Component = componentFields.length > 1;

              return (
                <>
                  {componentFields?.map?.((field) => {
                    return (
                      <ComponentCardWrapper
                        key={"Component_" + field.name}
                        size="small"
                        bordered={true}
                        className={`n3o-mb-4 ${
                          moreThan1Component ? "moreThan1Component" : ""
                        }`}
                        showTopRightAction={moreThan1Component}
                        topRightActionProps={
                          moreThan1Component
                            ? {
                                action: () => removeComponent(field.name),
                                actionIcon: <CloseOutlined />,
                                actionLocaleKey: "common.remove",
                              }
                            : null
                        }
                      >
                        <ComponentBody
                          moreThan1Component={moreThan1Component}
                          fieldIndex={field.name}
                          {...props}
                        />
                      </ComponentCardWrapper>
                    );
                  })}
                  <AddComponentButton
                    localeKey={
                      "admin.sponsorship.schemes.steps.button.addComponent"
                    }
                    onClick={() => addComponent(defaultComponentData)}
                  />
                </>
              );
            }}
          </Form.List>
        </Col>
        <Col span={24}>
          <GeneralFormItemV4
            name={"activate"}
            valuePropName={"checked"}
            {...formItemLayout}
          >
            <K2Checkbox>
              <K2Message
                localeKey={"admin.sponsorship.schemes.scheme.activate"}
              />
            </K2Checkbox>
          </GeneralFormItemV4>
        </Col>
      </Row>
    </Spin>
  );
};

export default Components;
