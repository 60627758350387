import React, { FC } from "react";
import styled from "styled-components";
import { K2Tooltip } from "components/k2Widgets/index";

const NotAllowed = styled.span`
  cursor: not-allowed;
  color: ${({ theme }) => theme.grey_7};
  .anticon {
    color: ${({ theme }) => theme.grey_7};
    opacity: 0.5;
  }
`;

interface IProps {
  children: string | JSX.Element;
  allowed?: boolean;
  tooltipKey?: string;
}

const CursorNotAllowed: FC<IProps> = ({
  children,
  tooltipKey,
  allowed = false,
  ...props
}) =>
  allowed ? (
    <>{children}</>
  ) : tooltipKey ? (
    <K2Tooltip titleKey={tooltipKey}>
      <NotAllowed {...props}>
        <span className={"n3o-pointer-events-none"}>{children}</span>
      </NotAllowed>
    </K2Tooltip>
  ) : (
    <NotAllowed {...props}>{children}</NotAllowed>
  );

export default CursorNotAllowed;
