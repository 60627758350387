import React, { FC } from "react";

import { TemplateEngineRes } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { Alert, Skeleton } from "antd";
import Text from "antd/es/typography/Text";

import { K2Message } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";

interface LayoutExplanationProps {
  loading: boolean;
  template: TemplateEngineRes;
  k2Intl: InjectedK2Intl;
}

const LayoutExplanation: FC<LayoutExplanationProps> = ({
  loading,
  template,
  k2Intl,
}) => {
  return (
    <Skeleton loading={loading} active>
      <Alert
        style={{ marginBottom: 20 }}
        type={"info"}
        showIcon
        message={
          <div>
            {k2Intl?.formatMessage({
              localeKey: "admin.communication.content.explanation.pt1",
            })}{" "}
            <Text style={{ padding: "0 9px" }} copyable>
              {template?.layoutBodyUsageSyntax}
            </Text>{" "}
            {k2Intl?.formatMessage({
              localeKey: "admin.communication.content.explanation.pt2",
            })}
          </div>
        }
      />
    </Skeleton>
  );
};

export default LayoutExplanation;
