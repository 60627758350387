import { LOCATION_CHANGE } from "react-router-redux";

import {
  NAV_STYLE_BELOW_HEADER,
  THEME_TYPE_LITE,
} from "constants/ThemeSetting";

import {
  TOGGLE_COLLAPSED_NAV,
  TOGGLE_QUICK_NAV_VISIBILITY,
  UPDATE_SCROLL_POSITION,
  WINDOW_WIDTH,
} from "./actionTypes";
import { ApplicationSettings } from "./types";

const initialSettings: ApplicationSettings = {
  navCollapsed: true,
  quickNavVisible: true,
  navStyle: NAV_STYLE_BELOW_HEADER,
  themeType: THEME_TYPE_LITE,
  scrollPosition: {
    top: 0,
    left: 0,
    clientWidth: document.documentElement.clientWidth,
    clientHeight: document.documentElement.clientHeight,
    scrollWidth: document.documentElement.scrollWidth,
    scrollHeight: document.documentElement.scrollHeight,
    scrollLeft: document.documentElement.scrollLeft,
    scrollTop: document.documentElement.scrollTop,
  },
  appId: "k2",
  pathname: "",
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        pathname: action.payload.location?.pathname,
        navCollapsed: false,
        appId: action.payload.location?.pathname?.includes?.("tally")
          ? "q1"
          : "k2",
      };
    case TOGGLE_QUICK_NAV_VISIBILITY:
      return {
        ...state,
        quickNavVisible: action.visible,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case UPDATE_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.values,
      };
    default:
      return state;
  }
};

export default settings;
