import React from "react";

import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";

import { K2GridAction } from "appRedux/models/grid/ActionGridModel";

import K2Button from "../k2Buttons/K2Button";
import K2Message from "../k2Localizations/K2Message";

export interface ActionDropDownItem extends K2GridAction {}

export interface ActionsDropDownOptions {
  /** @interface ActionDropDownItem items array */
  items: ActionDropDownItem[];

  /** @event Click callback for the items
   * @param @interface ClickParam
   */
  handleItemClick: (key: React.ReactText) => void;
}

export interface ActionsDropDownProps extends ActionsDropDownOptions {
  /** locale key for title to be displayed */
  titleKey: string;

  /**CSS class for the button */
  className?: string;
}

const ActionsDropDown = (props: ActionsDropDownProps) => {
  const menu = (
    <Menu onClick={(info) => props.handleItemClick(info.key as string)}>
      {props.items?.map?.((item) =>
        item.isDivider ? (
          <Menu.Divider key={item.key} />
        ) : (
          <Menu.Item key={item.key}>
            {item.icon}
            <K2Message localeKey={item.titleKey} />
          </Menu.Item>
        ),
      )}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <K2Button className={props.className}>
        <K2Message localeKey={props.titleKey} />
        <DownOutlined />
      </K2Button>
    </Dropdown>
  );
};

export default ActionsDropDown;
