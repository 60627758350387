import {
  DashboardTasksRes,
  TaskCriteria,
  TaskRes,
  TaskStatus,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { _tasksClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import IApplicationState from "appRedux/types";

import { dashboardTasksActions } from "../index";
import { FilterCriteria } from "./types";

// redux-saga selector

const getTaskCriteria = (
  state: IApplicationState,
): { search: string; filters: FilterCriteria } => {
  return {
    search: state.tasks.dashboardTasks.search,
    filters: state.tasks.dashboardTasks.filtersApplied,
  };
};

//workers

function* getTaskCountForUser(
  action: ReturnType<typeof dashboardTasksActions.fetchTaskCountForUser>,
) {
  try {
    const response: IApiResponse<number> = yield K2Service.toResponse(
      _tasksClient.count(action.payload),
    );
    if (!response.error) {
      yield put(dashboardTasksActions.setTaskCountForUser(response));
    }
  } catch {}
}

function* getDashboardTasks(
  action: ReturnType<typeof dashboardTasksActions.fetchDashboardTasks>,
) {
  const criteria = yield select(getTaskCriteria);
  let fetchCriteria: TaskCriteria = {
    status: [TaskStatus.Pending],
  };
  if (criteria.search.length > 0) {
    fetchCriteria.fullText = criteria.search;
  }
  if (criteria.filters.assignedToMe) {
    fetchCriteria.assignedToMe = true;
  }
  if (criteria.filters.highPriority) {
    fetchCriteria.highPriority = true;
  }
  if (criteria.filters.overdue) {
    fetchCriteria.isOverdue = true;
  }
  try {
    const response: IApiResponse<DashboardTasksRes> = yield K2Service.toResponse(
      _tasksClient.dashboardFind(fetchCriteria),
    );
    if (response.error) {
      yield put(
        dashboardTasksActions.setDashboardTasksServerError(response.error),
      );
    } else {
      yield put(dashboardTasksActions.setDashboardTasks(response));
    }
  } catch (e) {
    console.log(e);
  }
}

function* markComplete(
  action: ReturnType<typeof dashboardTasksActions.markComplete>,
) {
  try {
    const response = yield K2Service.toResponse(
      _tasksClient.markCompleted(action.payload),
    );
    yield put(dashboardTasksActions.setTaskComplete(action.payload));
    if (!response.error) {
      yield put(
        dashboardTasksActions.fetchTaskCountForUser({
          assignedToMe: true,
          status: [TaskStatus.Pending],
        }),
      );
    }
  } catch (e) {
    yield put(dashboardTasksActions.clearIsMarkingComplete());
  }
}

function* createTask(
  action: ReturnType<typeof dashboardTasksActions.createTask>,
) {
  try {
    const response: IApiResponse<TaskRes> = yield K2Service.toResponse(
      _tasksClient.createTask(action.payload),
    );
    if (!response.error) {
      yield put(
        dashboardTasksActions.fetchTaskCountForUser({
          assignedToMe: true,
          status: [TaskStatus.Pending],
        }),
      );
      yield put(dashboardTasksActions.createTaskSuccess());
      yield put(dashboardTasksActions.fetchDashboardTasks());
    } else {
      yield put(dashboardTasksActions.createTaskError(response.error));
    }
  } catch (e) {
    console.log(e);
    // Non-API related error
    yield put(dashboardTasksActions.tasksLoading(false));
  }
}

//watcher

export default function* tasksSaga() {
  yield all[
    (yield takeEvery(
      dashboardTasksActions.fetchDashboardTasks.type,
      getDashboardTasks,
    ),
    yield takeLatest(
      dashboardTasksActions.updateDashboardFilters.type,
      getDashboardTasks,
    ),
    yield takeLatest(dashboardTasksActions.markComplete.type, markComplete),
    yield takeLatest(dashboardTasksActions.createTask.type, createTask),
    yield takeLatest(
      dashboardTasksActions.fetchTaskCountForUser.type,
      getTaskCountForUser,
    ))
  ];
}
