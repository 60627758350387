import React, { FC } from "react";
import styled from "styled-components";
import K2Message from "../k2Localizations/K2Message";
import { IN3OIconType } from "components/n3oIcon/types";
import { N3oIcon } from "components/n3oIcon";
import {
  TopRightAction,
  TopRightActionProps,
} from "components/k2Widgets/k2Card";

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.grey_4};
  border-radius: 8px;
  margin-top: 25px;
  padding: 0px 15px 25px 15px;

  &.showing-top-action {
    padding-top: 20px;
  }
`;

const Title = styled.h3`
  position: relative;
  top: -17px;
  left: 0;
  font-size: 15px;
  background: white;
  display: inline-block;
  padding: 5px;
  margin-bottom: 0;
  &.showing-top-action {
    top: -37px;
  }
`;

interface IProps {
  title?: string | JSX.Element;
  titleKey?: string;
  icon?: React.ReactNode;
  iconKey?: IN3OIconType;
  children?: JSX.Element[] | JSX.Element;
  className?: string;

  showTopRightAction?: boolean;
  topRightActionProps?: TopRightActionProps;
}

const K2BorderedSection: FC<IProps> = ({
  title,
  titleKey,
  iconKey,
  children,
  className,
  icon,
  showTopRightAction,
  topRightActionProps,
}) => {
  if (showTopRightAction && !topRightActionProps) {
    throw new Error("topRightActionProps must be providd");
  }

  return (
    <Container
      className={`${className} ${
        showTopRightAction ? "showing-top-action" : ""
      }`}
    >
      <Title className={`${showTopRightAction ? "showing-top-action" : ""}`}>
        {icon ? (
          <span style={{ marginRight: 4 }}>{icon}</span>
        ) : (
          iconKey && (
            <N3oIcon
              style={{ marginRight: 4 }}
              icontype={iconKey}
              width={14}
              height={14}
              className={"n3o-pr-1"}
            />
          )
        )}
        {titleKey ? <K2Message localeKey={titleKey} /> : title}
      </Title>

      {showTopRightAction && (
        <TopRightAction onClick={topRightActionProps.action}>
          <span style={{ marginRight: "8px" }}>
            {topRightActionProps.actionIcon}
          </span>
          <span>
            <K2Message localeKey={topRightActionProps.actionLocaleKey} />
          </span>
        </TopRightAction>
      )}

      {children}
    </Container>
  );
};

export default K2BorderedSection;
