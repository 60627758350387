import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Currency } from "@n3oltd/karakoram.payments.sdk.payments/esm";
import IntlCurrencyInput from "react-intl-currency-input";

import { InjectedK2IntlProps } from "../k2Localizations/types";

interface CustomCurrencyProps extends InjectedK2IntlProps {
  currency?: Currency;
  onChange?: (any) => void;
  value?: number;
  onBlur?: (e: FocusEvent, value: number, maskedValue: string) => void;
  disabled?: boolean;
}

const CustomCurrencyInput: FC<CustomCurrencyProps> = ({
  currency,
  onChange,
  k2Intl,
  onBlur,
  disabled,
  value,
}) => {
  const [masked, setMasked] = useState(value || 0);

  const handleChange = useCallback(
    (event, value, masked) => {
      onChange?.(value);
      setMasked(value);
    },
    [onChange],
  );

  useEffect(() => {
    setMasked(value);
  }, [value]);

  const currencyConfig = useMemo(
    () => ({
      formats: {
        number: {
          [currency]: {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
      },
    }),
    [currency],
  );

  return (
    <span>
      <IntlCurrencyInput
        disabled={disabled}
        className={"ant-input"}
        currency={currency}
        config={currencyConfig}
        onChange={handleChange}
        value={masked}
        onBlur={onBlur}
      />
    </span>
  );
};

export default CustomCurrencyInput;
