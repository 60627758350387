import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const FeedbackRenew: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.4593 1.3093C4.13755 0.631041 5.05747 0.25 6.01667 0.25C6.97587 0.25 7.89578 0.63104 8.57404 1.3093C9.25229 1.98755 9.63333 2.90747 9.63333 3.86667C9.63333 4.82587 9.25229 5.74578 8.57404 6.42404C8.02411 6.97396 7.31533 7.3285 6.55555 7.44298C7.12953 7.49674 7.69598 7.6366 8.23527 7.86122C9.40825 8.34979 10.3871 9.21204 11.0197 10.314C11.2259 10.6733 11.1018 11.1317 10.7426 11.3379C10.3834 11.5441 9.92499 11.4201 9.71877 11.0608C9.2507 10.2454 8.52644 9.60742 7.65852 9.24591C6.79059 8.8844 5.82758 8.81963 4.91906 9.06165C4.01054 9.30368 3.20738 9.83895 2.63432 10.5843C2.06133 11.3296 1.75046 12.2433 1.75 13.1833V14.5833H3.15C3.53531 14.5833 3.85794 14.8753 3.89628 15.2587L4.54541 21.75H7.48786L7.72903 19.3361C7.7702 18.924 8.13771 18.6232 8.54987 18.6644C8.96203 18.7056 9.26277 19.0731 9.2216 19.4853L8.91295 22.5746C8.87464 22.958 8.55201 23.25 8.16667 23.25H3.86667C3.48135 23.25 3.15873 22.958 3.12039 22.5746L2.47126 16.0833H1C0.585786 16.0833 0.25 15.7476 0.25 15.3333V13.1833C0.250534 11.9127 0.67067 10.6774 1.44515 9.67007C2.21962 8.66271 3.30509 7.93929 4.53294 7.6122C4.84448 7.52921 5.16076 7.47291 5.47848 7.44308C4.71844 7.32872 4.00939 6.97413 3.4593 6.42404C2.78104 5.74578 2.4 4.82587 2.4 3.86667C2.4 2.90747 2.78104 1.98755 3.4593 1.3093ZM6.01667 1.75C5.45529 1.75 4.91691 1.97301 4.51996 2.36996C4.12301 2.76691 3.9 3.30529 3.9 3.86667C3.9 4.42804 4.12301 4.96642 4.51996 5.36338C4.91691 5.76033 5.45529 5.98333 6.01667 5.98333C6.57804 5.98333 7.11642 5.76033 7.51338 5.36338C7.91033 4.96642 8.13333 4.42804 8.13333 3.86667C8.13333 3.30529 7.91033 2.76691 7.51338 2.36996C7.11642 1.97301 6.57804 1.75 6.01667 1.75Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8883 12.2372C15.4736 11.4469 12.8463 12.6786 12.02 14.9883C11.8915 15.3475 11.4828 15.5391 11.1073 15.4162C10.7317 15.2933 10.5314 14.9024 10.6599 14.5431C11.7432 11.5149 15.1879 9.90004 18.3537 10.9363C19.7117 11.3808 20.8369 12.2624 21.5625 13.3983V12.0001C21.5625 11.6204 21.8843 11.3126 22.2812 11.3126C22.6782 11.3126 23 11.6204 23 12.0001V15.4376C23 15.8173 22.6782 16.1251 22.2812 16.1251H21.6456C21.6363 16.1253 21.627 16.1253 21.6178 16.1251H18.6875C18.2905 16.1251 17.9688 15.8173 17.9688 15.4376C17.9688 15.0579 18.2905 14.7501 18.6875 14.7501H20.6712C20.1724 13.5799 19.1653 12.6552 17.8883 12.2372ZM12.3913 18.8752H14.375C14.772 18.8752 15.0937 18.5674 15.0937 18.1877C15.0937 17.808 14.772 17.5001 14.375 17.5001H11.4447C11.4355 17.5 11.4262 17.5 11.4169 17.5001H10.7812C10.3843 17.5001 10.0625 17.808 10.0625 18.1877V21.6252C10.0625 22.0049 10.3843 22.3127 10.7812 22.3127C11.1782 22.3127 11.5 22.0049 11.5 21.6252V20.227C12.2256 21.3629 13.3508 22.2445 14.7088 22.689C17.8746 23.7252 21.3193 22.1104 22.4026 19.0822C22.5311 18.7229 22.3308 18.332 21.9552 18.2091C21.5797 18.0862 21.171 18.2777 21.0425 18.637C20.2162 20.9467 17.5889 22.1784 15.1742 21.3881C13.8972 20.9701 12.8901 20.0453 12.3913 18.8752Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6667 0.316406C13.0877 0.316406 12.5323 0.546434 12.1229 0.95589C11.7134 1.36535 11.4834 1.92068 11.4834 2.49974V5C11.4834 5.57906 11.7134 6.13439 12.1229 6.54385C12.5323 6.9533 13.0877 7.18333 13.6667 7.18333H14.1501V9C14.1501 9.31348 14.3226 9.60151 14.599 9.74942C14.8754 9.89734 15.2107 9.88113 15.4716 9.70724L19.2574 7.18333H20.6667C21.2458 7.18333 21.8011 6.9533 22.2106 6.54385C22.62 6.13439 22.8501 5.57906 22.8501 5V2.49974C22.8501 1.92068 22.62 1.36535 22.2106 0.95589C21.8011 0.546435 21.2458 0.316406 20.6667 0.316406H13.6667ZM13.6667 2.01641C13.5385 2.01641 13.4156 2.06733 13.325 2.15797C13.2343 2.24861 13.1834 2.37155 13.1834 2.49974V5C13.1834 5.12819 13.2343 5.25113 13.325 5.34177C13.4156 5.43241 13.5385 5.48333 13.6667 5.48333H15.0001C15.4695 5.48333 15.8501 5.86389 15.8501 6.33333V7.41176L18.5286 5.62609C18.6682 5.53301 18.8323 5.48333 19.0001 5.48333H20.6667C20.7949 5.48333 20.9179 5.43241 21.0085 5.34177C21.0991 5.25113 21.1501 5.12819 21.1501 5V2.49974C21.1501 2.37155 21.0991 2.24861 21.0085 2.15797C20.9179 2.06733 20.7949 2.01641 20.6667 2.01641H13.6667Z"
      fill={fill}
    />
  </svg>
);
