import { RoleCriteria, RoleResultsPage } from "@n3oltd/k2.users.sdk.roles/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

export enum ActionTypes {
  FETCH_ROLES = "@rolesAdmin/fetch-roles/request",
  FETCH_ROLES_SUCCESS = "@rolesAdmin/fetch-roles/success",
  FETCH_ROLES_ERROR = "@rolesAdmin/fetch-roles/error",
  CLEAR_FETCH_ROLES_ERROR = "@rolesAdmin/fetch-roles/clear-error",
  CLEAR_EDIT_STATUS = "@rolesAdmin/clear-edit-status",
  DELETE_ROLE = "@rolesAdmin/delete/request",
  DELETE_ROLE_SUCCESS = "@rolesAdmin/delete/success",
  DELETE_ROLE_ERROR = "@rolesAdmin/delete/error",
}

export interface FetchRoles {
  type: ActionTypes.FETCH_ROLES;
  criteria: RoleCriteria;
  shouldAppendToList?: boolean;
}

export interface FetchRolesSuccess {
  type: ActionTypes.FETCH_ROLES_SUCCESS;
  data: RoleResultsPage;
  shouldAppendToList: boolean;
}

export interface FetchRolesError {
  type: ActionTypes.FETCH_ROLES_ERROR;
  error: ServerError;
}

export interface ClearServerError {
  type: ActionTypes.CLEAR_FETCH_ROLES_ERROR;
}

export interface ClearEditStatus {
  type: ActionTypes.CLEAR_EDIT_STATUS;
}

export interface DeleteRole {
  type: ActionTypes.DELETE_ROLE;
  id: string;
  revisionId: string;
}

export interface DeleteRoleSuccess {
  type: ActionTypes.DELETE_ROLE_SUCCESS;
  id: string;
  editStatus: EditStatus<void>;
}

export interface DeleteRoleError {
  type: ActionTypes.DELETE_ROLE_ERROR;
  editStatus: EditStatus<void>;
}
