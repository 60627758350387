import React, { FC, useCallback, useEffect, useState } from "react";

import { DownOutlined } from "@ant-design/icons/lib/icons";
import { LookupType } from "@n3oltd/karakoram.tasks.sdk.lookups";
import {
  LinkedEntityRes,
  LinkedEntityType,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Divider, Dropdown, Form, Menu, Skeleton, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import { useRouter } from "hooks";
import { connect } from "react-redux";
import styled from "styled-components";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { LinkedEntityTypesLookups } from "appRedux/modules/lookups/types";
import IApplicationState from "appRedux/types";
import injectLookup from "appRedux/utils/injectLookups";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { StringHelpers } from "common/helpers/strings";
import variables from "common/themeVariables";
import CancelSaveOpts from "components/cancelSaveOpts";
import AddTaskDataLink from "components/dashboard/editTask/components/addTaskDataLink";
import {
  clearDeleteLinkedEntityError,
  clearLinkedEntityError,
  deleteLinkedEntity,
  updateLinkedEntityRequest,
} from "components/dashboard/editTask/modules/actions";
import { editableSectionName } from "components/dashboard/editTask/modules/types";
import EditLinkIcon from "components/editLinkIcon";
import EditableSection from "components/forms/editableSection";
import FormItemTitle from "components/forms/formItemTitle";
import { K2Link, K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";
import { IN3OIconType } from "components/n3oIcon/types";
import EditRelatedLink, {
  ExtendedGlobalSuggestion,
} from "components/tasks/editRelatedLink";
import { UIUtils } from "components/utils";
import { showNotification } from "components/utils/Notification";
import usePrevious from "hooks/usePrevious";

const CancelSaveOptsContainer = styled.div`
  margin-top: -24px;
`;

const RelatedLinkView = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;

  .ant-dropdown-trigger {
    height: 36px;
    border-radius: 4px !important;
    border: 0;
    background-color: ${({ theme }) => theme.grey_4};
    padding: 0 8px 0 4px;
    font-size: smaller;

    &:hover {
      color: ${({ theme }) => theme.grey_9};
      background-color: ${({ theme }) => theme.grey_5};
    }
  }
`;

const RelatedLinkEditSection = styled.div`
  .ant-form-vertical .ant-form-item-label {
    display: none;
  }
  .ant-form-item-label {
    display: none !important;
  }
`;

const LinkedEntityTitle = styled.span`
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  top: 4px;
  position: relative;
  display: contents;
`;

const RelatedLinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  .anticon {
    padding-right: 5px;
  }
`;

interface IProps extends InjectedK2IntlProps {
  updateLinkedEntity: typeof updateLinkedEntityRequest;
  clearError: typeof clearLinkedEntityError;
  deleteLinkedEntity: typeof deleteLinkedEntity;
  clearErrorDeleting: typeof clearDeleteLinkedEntityError;
  linkedEntity?: LinkedEntityRes;
  taskId: string;
  updating: boolean;
  error?: ServerError;
  deleting: boolean;
  closeTask: () => void;
  errorDeleting?: ServerError;
  setFormTouched?: (formName: editableSectionName) => void;
  clearFormTouched?: (formName: editableSectionName) => void;
  linkedEntityTypes?: LinkedEntityTypesLookups;
}

const RelatedLink: FC<IProps> = ({
  linkedEntity,
  updateLinkedEntity,
  taskId,
  error,
  updating,
  clearError,
  clearFormTouched,
  setFormTouched,
  k2Intl,
  deleteLinkedEntity,
  deleting,
  errorDeleting,
  clearErrorDeleting,
  linkedEntityTypes,
  closeTask,
}) => {
  const { theme } = useAppContext();
  const [form] = useForm();

  const router = useRouter();

  // Show editing state if there is no current linked entity
  const [isEditing, setIsEditing] = useState(false);
  const [
    selectedEntity,
    setSelectedEntity,
  ] = useState<ExtendedGlobalSuggestion>(
    linkedEntity
      ? {
          ...linkedEntity,
          type: linkedEntity.type,
        }
      : null,
  );

  const prevUpdating = usePrevious(updating);
  const prevDeleting = usePrevious(deleting);

  const handleSave = (): void => {
    if (selectedEntity) {
      updateLinkedEntity(taskId, {
        entityId: selectedEntity.id,
        type: selectedEntity.type,
      });
    } else if (linkedEntity && !selectedEntity) {
      deleteLinkedEntity(taskId);
    }
  };

  // After new linked entity has been saved
  useEffect(() => {
    if (prevUpdating && !updating && !error) {
      clearFormTouched("relatedLink");
      setIsEditing(false);
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "tasks.taskUpdatedSuccess",
      });
    }
    // eslint-disable-next-line
  }, [updating]);

  // After linked entity has been deleted
  useEffect(() => {
    if (prevDeleting && !deleting && !errorDeleting) {
      clearFormTouched("relatedLink");
      setIsEditing(false);
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "tasks.taskUpdatedSuccess",
      });
    }
    // eslint-disable-next-line
  }, [deleting]);

  useEffect(() => {
    if (linkedEntity) {
      setSelectedEntity({
        title: linkedEntity.title,
        type: linkedEntity.type,
        id: linkedEntity.entityId,
      });
    } else {
      setSelectedEntity(null);
    }
  }, [linkedEntity]);

  useEffect(() => {
    if (error) {
      UIUtils.handleServerError(k2Intl, error, clearError);
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (errorDeleting) {
      UIUtils.handleServerError(k2Intl, errorDeleting, clearErrorDeleting);
    }
    // eslint-disable-next-line
  }, [errorDeleting]);

  const renderLinkTitle = (title: string): React.ReactNode => {
    switch (title) {
      case "View Account":
        return <K2Message localeKey="account.viewAccount" />;
      case "View Donation":
        return <K2Message localeKey="donation.viewDonation" />;
      default:
        return title;
    }
  };

  const isOnActionUrl = useCallback(
    (url: string) => {
      return router.location.pathname.includes(url);
    },
    [router.location.pathname],
  );

  const isLoading = linkedEntityTypes.items.length === 0;
  const initialEntityType = linkedEntityTypes.items[0] || null;

  return (
    <Form layout="vertical" form={form}>
      <Skeleton active loading={isLoading}>
        <FormItemTitle color={theme.grey_6}>
          <K2Message localeKey="tasks.relatedLink" />
        </FormItemTitle>
        {isEditing ? (
          <RelatedLinkEditSection>
            <EditableSection>
              <EditRelatedLink
                linkedEntityTypes={linkedEntityTypes.items}
                editMode={true}
                selectedValue={selectedEntity || null}
                selectedType={
                  selectedEntity?.type ||
                  (initialEntityType?.id as LinkedEntityType)
                }
                onChange={(e?: ExtendedGlobalSuggestion) => {
                  setSelectedEntity(e || null);
                  setFormTouched("relatedLink");
                }}
              />
              <CancelSaveOptsContainer>
                <CancelSaveOpts
                  onCancel={() => {
                    clearFormTouched("relatedLink");
                    setIsEditing(!isEditing);
                    setSelectedEntity(
                      linkedEntity
                        ? {
                            ...linkedEntity,
                            type: linkedEntity.type,
                          }
                        : null,
                    );
                  }}
                  onSave={handleSave}
                  loading={updating}
                />
              </CancelSaveOptsContainer>
            </EditableSection>
          </RelatedLinkEditSection>
        ) : (
          <>
            <EditableSection>
              <RelatedLinkView>
                {linkedEntity ? (
                  <>
                    <Dropdown
                      trigger={["click"]}
                      overlay={() => (
                        <Menu>
                          {linkedEntity.actions &&
                            linkedEntity.actions?.map?.((action, i) => (
                              <Menu.Item
                                key={i}
                                onClick={
                                  isOnActionUrl(action.url)
                                    ? closeTask
                                    : undefined
                                }
                              >
                                <K2Link to={action.url}>
                                  {renderLinkTitle(action.title)}
                                </K2Link>
                              </Menu.Item>
                            ))}
                        </Menu>
                      )}
                    >
                      <RelatedLinkButtonContainer>
                        <N3oIcon
                          fill={variables.grey_7}
                          width={20}
                          height={20}
                          icontype={linkedEntity.icon as IN3OIconType}
                        />
                        <LinkedEntityTitle>
                          {StringHelpers.truncate(linkedEntity.title, 50)}
                        </LinkedEntityTitle>
                        <Divider type="vertical" />
                        <DownOutlined style={{ color: theme.grey_7 }} />
                      </RelatedLinkButtonContainer>
                    </Dropdown>
                    <span>
                      <Tooltip
                        title={<K2Message localeKey="common.clickToEdit" />}
                      >
                        <EditLinkIcon
                          onClick={() => setIsEditing(!isEditing)}
                        />
                      </Tooltip>
                    </span>
                  </>
                ) : (
                  <AddTaskDataLink onClick={() => setIsEditing(!isEditing)}>
                    <K2Message localeKey="tasks.action.addLink" />
                  </AddTaskDataLink>
                )}
              </RelatedLinkView>
            </EditableSection>
          </>
        )}
      </Skeleton>
    </Form>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  linkedEntity: state.editViewTask.task?.linkedEntity || null,
  taskId: state.editViewTask.task?.id,
  updating: state.editViewTask.updatingLinkedEntity,
  error: state.editViewTask.errorUpdatingLinkedEntity,
  deleting: state.editViewTask.deletingLinkedEntity,
  errorDeleting: state.editViewTask.errorDeletingLinkedEntity,
});

const mapDispatchToProps = {
  updateLinkedEntity: updateLinkedEntityRequest,
  clearError: clearLinkedEntityError,
  deleteLinkedEntity: deleteLinkedEntity,
  clearErrorDeleting: clearDeleteLinkedEntityError,
};

export default injectLookup(
  injectK2Intl(connect(mapStateToProps, mapDispatchToProps)(RelatedLink)),
  [LookupType.LinkedEntityTypes],
);
