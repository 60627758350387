import React, { FC, useState, useCallback } from "react";
import { K2Button, K2Card, K2Message } from "components/k2Widgets";
import seeders from "config/seeders.json";
import { List, Divider, Row, Col, Typography } from "antd";
import { showNotification } from "components/utils/Notification";
import AppManager from "appRedux/AppManager";

interface IProps {
  callApi: (
    api: string,
    post?: boolean,
    addData?: boolean,
    clock?: string,
  ) => Promise<Response>;
}

const Seeding: FC<IProps> = (props) => {
  const { callApi } = props;
  const { order: seedersOrder } = seeders; //TODO: make sure to keep the order updated
  let order = 0;

  const [seeding, setSeeding] = useState<boolean>(false);
  const [seededServices, setSeededServices] = useState<string[]>([]);

  const callSeedingApi = useCallback(
    async (path: string) => {
      if (order >= seedersOrder.length) {
        setSeeding(false);
        return;
      }

      setSeeding(true);

      let seeded: string[] = seededServices;

      try {
        const response = await callApi(
          `${
            AppManager.baseUrls[seedersOrder[order].toLowerCase()]
          }/devtools/v1.0/framework/${path}/seed`,
          true,
          true,
        );

        seeded.push(seedersOrder[order]);
        setSeededServices(seeded);

        order++;

        await callSeedingApi(path);
      } catch (err) {
        showNotification({
          type: "error",
          title: err.toString(),
        });
      }

      setSeeding(false);
    },
    [callApi, order, seededServices, seedersOrder],
  );

  return (
    <K2Card bordered={false} shadow={true} localeKey={"admin.testing.seeders"}>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <K2Button
            localeKey={"admin.testing.seeders.seedSubscription"}
            onClick={() => {
              order = 0;
              setSeededServices([]);
              callSeedingApi("subscription");
            }}
            type={"primary"}
            loading={seeding}
            block
          />
        </Col>
        <Col span={12}>
          <K2Button
            localeKey={"admin.testing.seeders.seedDemoSubscription"}
            onClick={() => {
              order = 0;
              setSeededServices([]);
              callSeedingApi("demo");
            }}
            type={"dashed"}
            loading={seeding}
            block
          />
        </Col>
        <Col span={24}>
          <Divider />
          <List
            header={
              <b>
                <K2Message localeKey={"admin.testing.seeders.seededServices"} />
              </b>
            }
            bordered
            dataSource={seededServices}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text>{item}</Typography.Text>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </K2Card>
  );
};

export default Seeding;
