import { Drawer, Layout, Divider } from "antd";
import { SiderTheme } from "antd/es/layout/Sider";
import React, { Component } from "react";
import { connect } from "react-redux";
import MenuSelectors, {
  ISidebarStateProps,
} from "appRedux/store/selectors/AppSelectors";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "constants/ThemeSetting";
import styled from "styled-components";
import { AppContext } from "common/contexts/AppProvider/AppProvider";
import AppHelpers from "common/helpers";

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  flex: none !important;
  min-width: auto !important;
  width: auto !important;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    // height: 100%;
    padding: 0;

    .n3o-sidebar-content {
      // height: 100%;
    }
  }
`;

const CompanyContainer = styled.strong`
  border-radius: 8px;
  border: 1px solid black;
  padding: 1px 6px;
`;

interface IProps extends ISidebarStateProps {
  children: React.ReactNode;
}

export class Sidebar extends Component<IProps> {
  static contextType = AppContext;

  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedSideNav(!this.props.navCollapsed);
  };

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.props.updateWindowWidth(window.innerWidth);
    });
  }

  getDrawerStyle = () => {
    const { width, navStyle } = this.props;

    let drawerStyle = "n3o-collapsed-sidebar";

    if (navStyle === NAV_STYLE_FIXED) {
      drawerStyle = "";
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      drawerStyle = "n3o-mini-sidebar n3o-mini-custom-sidebar";
    } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      drawerStyle = "n3o-custom-sidebar";
    } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
      drawerStyle = "n3o-mini-sidebar";
    } else if (navStyle === NAV_STYLE_DRAWER) {
      drawerStyle = "n3o-collapsed-sidebar";
    }
    if (
      (navStyle === NAV_STYLE_FIXED ||
        navStyle === NAV_STYLE_MINI_SIDEBAR ||
        navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) &&
      width < TAB_SIZE
    ) {
      drawerStyle = "n3o-collapsed-sidebar";
    }

    return drawerStyle;
  };

  render() {
    const { themeType, navCollapsed, width, navStyle, children } = this.props;
    const drawerStyle = this.getDrawerStyle();

    return (
      <StyledSider
        className={`n3o-app-sidebar ${drawerStyle} ${
          themeType !== THEME_TYPE_LITE ? "n3o-layout-sider-dark" : null
        }`}
        trigger={null}
        collapsed={
          width < TAB_SIZE
            ? false
            : navStyle === NAV_STYLE_MINI_SIDEBAR ||
              navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
        }
        theme={(themeType === THEME_TYPE_LITE ? "lite" : "dark") as SiderTheme}
        collapsible
      >
        {navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? (
          <StyledDrawer
            className={`n3o-drawer-sidebar ${
              themeType !== THEME_TYPE_LITE ? "n3o-drawer-sidebar-dark" : null
            }`}
            placement="left"
            closable={true}
            onClose={this.onToggleCollapsedNav.bind(this)}
            visible={navCollapsed}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "x-small",
                }}
              >
                <CompanyContainer>N3O</CompanyContainer>{" "}
                <Divider type="vertical" />{" "}
                {AppHelpers.renderAppName(this.context)}
              </div>
            }
          >
            {children}
          </StyledDrawer>
        ) : (
          <>{children}</>
        )}
      </StyledSider>
    );
  }
}

export default connect(
  MenuSelectors.getSidebarSelector(),
  MenuSelectors.getSidebarDispatchers(),
)(Sidebar);
