import React, { FC } from "react";

import { useGetIsProdEnvironment } from "hooks";
import { useSelector } from "react-redux";
import styled from "styled-components";

import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { IN3OIconType } from "components/n3oIcon/types";
import QuickNavTile from "components/navigation/quickNavigation/QuickNavTile";

import { k2QuickCreateLinks, q1QuickCreateLinks } from "./quickCreateConfig";

interface IProps {
  k2Intl: InjectedK2Intl;

  onMenuItemClick: () => void;
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.white_color};
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;

  a {
    margin: 8px;
  }
`;

const QuickCreateMenu: FC<IProps> = ({ k2Intl, onMenuItemClick }) => {
  const { appId } = useAppContext();

  const k2UserRoutes = useSelector(
    (state: IApplicationState) => state.subscription.users.k2UserRoutes,
  );
  const isProdEnv = useGetIsProdEnvironment();

  const links =
    appId === "k2"
      ? isProdEnv
        ? k2QuickCreateLinks.filter((x) => !x.hideFromProd)
        : k2QuickCreateLinks
      : q1QuickCreateLinks;

  return (
    <Container>
      {links?.map?.((link, index) => (
        <span
          key={"link_" + index}
          style={{ width: 150 }}
          onClick={() => {
            onMenuItemClick();
          }}
        >
          <QuickNavTile
            link={{
              icon: link.icon as IN3OIconType,
              titleKey: link.titleKey,
              havePermission: k2UserRoutes?.items?.includes(link.linkKey),
              routeKey: link.linkKey,
            }}
            k2Intl={k2Intl}
            key={link.linkKey}
          />
        </span>
      ))}
    </Container>
  );
};

export default QuickCreateMenu;
