import React, { FC } from "react";

import { Menu } from "antd";
import { useGetIsProdEnvironment, useRouter } from "hooks";
import styled from "styled-components";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";
import { IN3OIconType } from "components/n3oIcon/types";

import { k2QuickFindLinks, q1QuickFindLinks } from "./quickFindConfig";

const Container = styled.div`
  background-color: ${({ theme }) => theme.white_color};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  .ant-menu {
    border-radius: 8px;
  }
`;

const MenuItem = styled(Menu.Item)`
  && {
    &:hover {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

interface IProps {
  k2Intl: InjectedK2Intl;

  onMenuItemClick: () => void;
}

const QuickFindMenu: FC<IProps> = ({ k2Intl, onMenuItemClick }) => {
  const router = useRouter();
  const { appId, k2User } = useAppContext();
  const isProdEnv = useGetIsProdEnvironment();

  const links =
    appId === "k2"
      ? isProdEnv
        ? k2QuickFindLinks.filter(
            (x) => !x.hideFromProd && !(x.hideFromAdmin && k2User.admin),
          )
        : k2QuickFindLinks.filter((x) => !(x.hideFromAdmin && k2User.admin))
      : q1QuickFindLinks;

  const navigateTo = (location: string) => router.history.push(location);

  return (
    <Container>
      <Menu
        selectedKeys={[]}
        style={{
          width: 175,
          border: "none",
        }}
      >
        {links?.map?.((link) => {
          return (
            <MenuItem
              key={link.link}
              icon={
                <N3oIcon
                  width={16}
                  height={16}
                  style={
                    link.isAntIcon
                      ? {
                          marginRight: "0px",
                        }
                      : undefined
                  }
                  icontype={`n3o-${link.key}-search` as IN3OIconType}
                  fill={"currentColor"}
                />
              }
              onClick={() => {
                onMenuItemClick();
                navigateTo(link.link);
              }}
            >
              {k2Intl?.formatMessage({ localeKey: link.titleKey })}
            </MenuItem>
          );
        })}
      </Menu>
    </Container>
  );
};

export default QuickFindMenu;
