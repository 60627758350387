import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import {
  NamedLookupRes,
  NamedLookupResLookupResultsList,
} from "@n3oltd/k2.subscriptions.sdk.lookups/esm";
import { Col, Tabs } from "antd";
import _ from "lodash";
import { Redirect } from "react-router";

import AppManager from "appRedux/AppManager";
import AuthManager from "appRedux/models/auth/AuthManager";
import { K2StatusCodes } from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { useEnvironmentContext } from "common/contexts/EnvironmentProvider/EnvironmentProvider";
import { K2Card, K2RouteHeading, K2Spin } from "components/k2Widgets";
import { FormsWrapper, MainFormsWrapper } from "components/layout/wrappers";
import { showNotification } from "components/utils/Notification";
import { GenericObject } from "components/utils/Utils";
import apiConfig from "config/apiConfig";
import useBreadcrumb from "hooks/useBreadcrumb";

import Helpers, { HeadersFields } from "./Helpers";
import Seeding from "./Seeding";
import TestData from "./TestData";

const { TabPane } = Tabs;

interface IProps {
  isCompanyUser: boolean;
}

const Testing: FC<IProps> = (props) => {
  const { isCompanyUser } = props;

  const [activeTabKey, setActiveTabKey] = useState<string>("1");
  const [noDataHelpers, setNoDataHelpers] = useState<boolean>(false);
  const [loadingHelpers, setLoadingHelpers] = useState<boolean>(false);
  const [helpersServiceId, setHelpersServiceId] = useState<string>("");
  const [helperId, setHelperId] = useState<string>("");
  const [helpers, setHelpers] = useState<NamedLookupRes[]>([]);
  const [finalHelperData, setFinalHelperData] = useState<Object>({});

  const { environment } = useEnvironmentContext();

  const apiUrls: GenericObject[] = Object.keys(
    apiConfig[environment].endpoints,
  ).reduce((acc, key) => {
    return acc.concat([
      {
        id: key,
        name: key[0].toUpperCase() + key.slice(1),
      },
    ]);
  }, []);

  const setBreadcrumbs = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbs([
      {
        path: K2Routes.main.admin,
        titleKey: "mainNav.admin",
        active: false,
      },
      {
        path: K2Routes.testing,
        titleKey: "admin.testing",
        active: true,
      },
    ]);
  }, [setBreadcrumbs]);

  const postData = useMemo(() => {
    return {
      country: "GB",
      currency: "GBP",
      gettingStartedData: true,
    };
  }, []);

  const callApi = useCallback(
    async (api: string, post?: boolean, addData?: boolean, clock?: string) => {
      const headers = {
        "content-type": "application/json; charset=utf-8",
        authorization: "Bearer " + AuthManager.getToken(),
        accept: "*/*",
        [AuthManager.keys.requestHeaders.appId]: AppManager.getAppId(),
        [AuthManager.keys.requestHeaders
          .subscriptionId]: AuthManager.getSubscriptionId(),
      };
      if (clock) {
        headers[AuthManager.keys.requestHeaders.clockAdjustment] = clock;
      }
      return await fetch(api, {
        method: post ? "POST" : "GET",
        headers,
        body: post && addData ? JSON.stringify(postData) : undefined,
      });
    },
    [postData],
  );

  const onHelpersDataServiceChangeHandler = useCallback(
    async (service: string) => {
      setLoadingHelpers(true);
      try {
        const response = await callApi(
          `${AppManager.baseUrls[service]}/testing/v1.0/helpers`,
        );
        const result: NamedLookupResLookupResultsList = await response.json();
        const items = result?.items;

        setHelpersServiceId(service);
        setHelpers(items || []);
        setHelperId("");
        setNoDataHelpers(items?.length <= 0);
        setLoadingHelpers(false);
      } catch (err) {
        showNotification({
          type: "error",
          title: err.toString(),
        });
        setLoadingHelpers(false);
      }
    },
    [callApi],
  );

  const onHelperRun = useCallback(
    async (values: HeadersFields) => {
      setLoadingHelpers(true);

      try {
        const response = await callApi(
          `${AppManager.baseUrls[helpersServiceId]}/testing/v1.0/helpers/${helperId}/run`,
          true,
          false,
          values.clock,
        );
        const result: Object = await response.json();

        if (
          result?.["errors"]?.[0] ||
          (result?.["status"] &&
            K2StatusCodes.isErrorStatus(result?.["status"]))
        ) {
          if (result["status"] === K2StatusCodes.notFound) {
            showNotification({
              type: "error",
              title: `${result["title"]} ${result["detail"]} not found`,
            });
          } else {
            showNotification({
              type: "error",
              title: result?.["errors"]?.[0]?.error || result?.["description"],
            });
          }

          setFinalHelperData({});
          setNoDataHelpers(false);
        } else {
          setFinalHelperData(result);
          setNoDataHelpers(_.isEmpty(result));
        }

        setLoadingHelpers(false);
      } catch (err) {
        showNotification({
          type: "error",
          title: err.toString(),
        });
        setLoadingHelpers(false);
      }
    },
    [callApi, helpersServiceId, helperId],
  );

  if (!isCompanyUser) {
    return <Redirect to={K2Routes.errors._404} />;
  }

  return (
    <FormsWrapper>
      <K2RouteHeading headingKey={"admin.testing"} />
      <MainFormsWrapper gutter={[24, 24]}>
        <Col span={24} style={{ marginTop: 24 }}>
          <K2Card
            bordered={false}
            shadow={true}
            localeKey={"admin.testing.helpers"}
          >
            <K2Spin
              spinning={loadingHelpers}
              className={"n3o-h-100 n3o-not-allowed"}
              tipKey={"common.loading"}
            >
              <Tabs
                defaultActiveKey={activeTabKey}
                onChange={(activeKey) => {
                  setHelpers([]);
                  setActiveTabKey(activeKey);
                }}
              >
                <TabPane tab="Subscriptions" key="1">
                  <Helpers
                    isSubscription
                    activeTabKey={activeTabKey}
                    apiUrls={apiUrls}
                    helpers={helpers}
                    finalHelperData={finalHelperData}
                    noDataHelpers={noDataHelpers}
                    loadingHelpers={loadingHelpers}
                    helperId={helperId}
                    setHelperId={setHelperId}
                    onHelpersDataServiceChangeHandler={
                      onHelpersDataServiceChangeHandler
                    }
                    onHelperRun={onHelperRun}
                  />
                </TabPane>
                <TabPane tab="Other Services" key="2">
                  <Helpers
                    activeTabKey={activeTabKey}
                    apiUrls={apiUrls}
                    helpers={helpers}
                    finalHelperData={finalHelperData}
                    noDataHelpers={noDataHelpers}
                    loadingHelpers={loadingHelpers}
                    helperId={helperId}
                    setHelperId={setHelperId}
                    setFinalHelperData={setFinalHelperData}
                    onHelpersDataServiceChangeHandler={
                      onHelpersDataServiceChangeHandler
                    }
                    onHelperRun={onHelperRun}
                  />
                </TabPane>
              </Tabs>
            </K2Spin>
          </K2Card>
        </Col>
        <Col span={24}>
          <TestData apiUrls={apiUrls} callApi={callApi} />
        </Col>
        <Col span={24}>
          <Seeding callApi={callApi} />
        </Col>
      </MainFormsWrapper>
    </FormsWrapper>
  );
};

export default Testing;
