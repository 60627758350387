import { Skeleton } from "antd";
import Nprogress from "nprogress";
import React, { FC, useEffect, useState } from "react";
import { K2Card } from "components/k2Widgets";

export default function asyncComponent(
  importComponent: () => any,
  shadow: boolean = true,
) {
  const AsyncFunc: FC<any> = (props) => {
    const [mounting, setMounting] = useState<boolean>(false);
    const [mounted, setMounted] = useState<boolean>(false);
    const [component, setComponent] = useState<JSX.Element | null>(null);

    useEffect(() => {
      Nprogress.start();

      return () => {
        Nprogress.done();
      };
    }, []);

    useEffect(() => {
      if (mounting) {
        Nprogress.inc();
      }

      if (mounted) {
        Nprogress.done();
      }
    }, [mounted, mounting]);

    useEffect(() => {
      const mountComponent = async () => {
        setMounting(true);

        const { default: Component } = await importComponent();

        setComponent(<Component {...props} />);
        setMounted(true);
        Nprogress.done();
      };

      if (!mounted && !mounting) {
        mountComponent();
      }
    }, [mounted, mounting, props]);

    return component !== null ? (
      component
    ) : (
      <K2Card shadow={shadow} className={"async-component"}>
        <Skeleton active paragraph={{ rows: 10 }} />
      </K2Card>
    );
  };

  return AsyncFunc;
}
