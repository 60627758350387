import { LookupType } from "appRedux/models/lookups/LookupsModel";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { K2LookupsResponse } from "appRedux/modules/lookups/types";

export enum ActionTypes {
  SET_LOOKUPS_LOADING = "@global/lookups/set-loading",
  FETCH_LOOKUPS = "@global/lookups/fetch",
  SET_LOOKUPS = "@global/lookups/set",
  INVALIDATE_LOOKUPS_FEW = "@global/lookups/invalidate/few",
  INVALIDATE_LOOKUPS_All = "@global/lookups/invalidate/all",
  CLEAR_LOOKUPS_ERROR = "@global/lookups/clear-error",
}

export interface SetLookupsLoading {
  type: ActionTypes.SET_LOOKUPS_LOADING;
  loading: boolean;
  lookups?: string[];
}

export interface FetchLookups {
  type: ActionTypes.FETCH_LOOKUPS;
  lookupTypes: LookupType[];
}

export interface SetLookups {
  type: ActionTypes.SET_LOOKUPS;
  lookups: IApiResponse<K2LookupsResponse>[];
}

export interface InvalidateLookupsFew {
  type: ActionTypes.INVALIDATE_LOOKUPS_FEW;
  lookupTypes: LookupType[];
}

export interface InvalidateLookupsAll {
  type: ActionTypes.INVALIDATE_LOOKUPS_All;
}

export interface ClearError {
  type: ActionTypes.CLEAR_LOOKUPS_ERROR;
}

export type LookupAction =
  | SetLookupsLoading
  | FetchLookups
  | SetLookups
  | InvalidateLookupsFew
  | InvalidateLookupsAll
  | ClearError;
