import React, { FC, useEffect, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styled from "styled-components";

import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

const Icon = styled(LoadingOutlined)`
  font-size: 18px !important;
  color: ${({ theme }) => theme.primaryColor} !important;
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: height 0.5s ease-in-out;
  background-color: ${({ theme }) => theme.white_color} !important;

  h3 {
    color: ${({ theme }) => theme.primaryColor} !important;
    margin-top: 10px;
  }

  img {
    box-shadow: ${({ theme }) => theme.card_shadow} !important;
    border-radius: 25px;
  }
`;

interface IProps extends InjectedK2IntlProps {
  className?: string;
  logoSrc: string;
  showLoading?: boolean;
}

const CenteredLogo: FC<IProps> = (props) => {
  const {
    className,
    showLoading,
    logoSrc,
    k2Intl,
    localizationSettings,
    ...remainingProps
  } = props;

  const [loading, setLoading] = useState<boolean>(showLoading || false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!loading) {
        setLoading(true);
      }
    }, 3000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [loading]);

  return (
    <Container>
      {logoSrc && (
        <img
          className={className}
          src={require(`assets/images/${logoSrc}`)}
          alt={"logo"}
        />
      )}
      <h3>
        {k2Intl?.formatMessage({
          localeKey: loading ? "common.loadingResources" : "common.welcomeText",
        })}{" "}
        {loading && <Spin indicator={<Icon />} />}
      </h3>
    </Container>
  );
};

export default injectK2Intl(CenteredLogo);
