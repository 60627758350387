import { ImmerReducer } from "immer-reducer";
import { Auth } from "./types";

export const authInitialState: Auth = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: ""
};

export class AuthReducer extends ImmerReducer<Auth> {
  setInitUrl(url: string) {
    this.draftState.initURL = url;
  }

  userSignedIn() {}
}
