import React, { FC } from "react";
import styled from "styled-components";
import K2Message from "../k2Localizations/K2Message";

const Container = styled.div`
  cursor: pointer;
`;

const P = styled.p`
  margin: 10px;
  text-align: center;
  span {
    color: ${({ theme }) => theme.link_color};
    &:hover {
      color: ${({ theme }) => theme.primaryColorDarkened};
    }
  }
`;

interface IProps {
  onLoadMore: () => void;
  titleKey?: string;
}

const PaginationFooter: FC<IProps> = ({ onLoadMore, titleKey }) => {
  return (
    <Container onClick={onLoadMore}>
      <P>
        <K2Message localeKey={titleKey || "common.loadMore"} />
      </P>
    </Container>
  );
};

export default PaginationFooter;
