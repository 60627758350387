import { useEffect, useState } from "react";

import {
  DataEntryFormRes,
  DataEntryFormResultsList,
  FormType,
} from "@n3oltd/karakoram.forms.sdk.data-entry";

import { _formsDataEntryClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

export function useGetListFormsOfType(
  formTypeId: FormType,
  k2Intl: InjectedK2Intl,
): {
  forms: DataEntryFormRes[];
  loading: boolean;
  isError: boolean;
} {
  const [forms, setForms] = useState<DataEntryFormRes[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const listFormsOfType = async () => {
      setLoading(true);
      const response: IApiResponse<DataEntryFormResultsList> = await K2RestService.toResponse(
        _formsDataEntryClient.listFormsOfType(formTypeId),
      );
      if (!response.error) {
        setForms(response.getResultOrDefault()?.items || []);
        setIsError(false);
      } else if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
        setIsError(true);
      }
      setLoading(false);
    };
    if (formTypeId) {
      listFormsOfType();
    }
    //TODO: Remove afer refactoring/updating  k2Intl and UIUtils
    // eslint-disable-next-line
  }, [formTypeId]);

  return {
    forms,
    loading,
    isError,
  };
}
