import { connect } from "react-redux";
import { userPreferencesLookups } from "appRedux/models/lookups/LookupsModel";
import Preferences from "./Preferences";
import ApplicationState from "appRedux/types";
import { PreferencesState } from "./modules/types";
import injectLookups from "appRedux/utils/injectLookups";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { usersActions } from "appRedux/modules/subscription";

const mapStateToProps = (state: ApplicationState): PreferencesState => {
  return {
    timeFormats: state.lookups.timeFormats,
    dateFormats: state.lookups.dateFormats,
    timezones: state.lookups.timezones,
    languages: state.lookups.languages,
    numberFormats: state.lookups.numberFormats,
    currentUser: state.subscription.users.k2User,
    subscriptions: state.subscription.users.currentUserSubscriptions,
    loading:
      state.lookups.languages.items.length > 0 &&
      state.lookups.timezones.items.length > 0 &&
      state.lookups.dateFormats.items.length > 0 &&
      state.lookups.numberFormats.items.length > 0 &&
      state.lookups.timeFormats.items.length > 0 &&
      state.lookups.countries.items.length > 0 &&
      !state.subscription.users.k2User &&
      !state.subscription.users.currentUserSubscriptions,
  };
};

const mapDispatchToProps = {
  getAppResources: usersActions.getAppResources,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectLookups(injectK2Intl(Preferences), userPreferencesLookups));
