import { useEffect, useState } from "react";

import {
  CustomCorrespondenceLinkRes,
  CustomCorrespondenceLinkResultsList,
} from "@n3oltd/k2.communications.sdk.data-entry";

import { _communicationsDataEntryClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

export function useGetCustomCorrespondenceLinks(
  accountId: string,
  k2Intl: InjectedK2Intl,
): [CustomCorrespondenceLinkRes[], boolean] {
  const [customCorrespondenceLinks, setCustomCorrespondenceLinks] = useState<
    CustomCorrespondenceLinkRes[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const listCustomCorrespondenceLinks = async () => {
      setLoading(true);
      const response: IApiResponse<CustomCorrespondenceLinkResultsList> = await K2RestService.toResponse(
        _communicationsDataEntryClient.listCustomCorrespondenceLinks(accountId),
      );

      if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else {
        setCustomCorrespondenceLinks(response.getResultOrDefault().items);
      }
      setLoading(false);
    };

    if (accountId) listCustomCorrespondenceLinks();
    //TODO: Remove afer refactoring/updating  k2Intl and UIUtils
    // eslint-disable-next-line
  }, [accountId]);

  return [customCorrespondenceLinks, loading];
}
export default useGetCustomCorrespondenceLinks;
