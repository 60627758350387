import React, { FC } from "react";

import { PhoneNumberRes } from "@n3oltd/k2.accounts.sdk.accounts";

interface IK2PhoneNumberProps {
  contact: PhoneNumberRes;
  formatForTel?: boolean;
}

const K2PhoneNumber: FC<IK2PhoneNumberProps> = (props) => {
  const { contact, formatForTel = false } = props;
  const isNationalAnde16Format = contact?.nationalFormat && contact?.e164Format;
  const isNationalAnde16FormatWithFormatForTel =
    isNationalAnde16Format && formatForTel;
  return (
    <>
      {isNationalAnde16FormatWithFormatForTel && (
        <a href={`tel:${contact.e164Format}`}>
          {contact.isInternational
            ? contact.e164Format
            : contact.nationalFormat}
        </a>
      )}
      {isNationalAnde16Format &&
        !isNationalAnde16FormatWithFormatForTel &&
        (contact.isInternational ? contact.e164Format : contact.nationalFormat)}

      {contact?.number && !isNationalAnde16Format && contact?.number}
    </>
  );
};

export default K2PhoneNumber;
