import React, { FC } from "react";
import { Spin } from "antd";
import styled from "styled-components";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { SpinProps } from "antd/lib/spin";

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps extends SpinProps, InjectedK2IntlProps {
  tipKey?: string;
}

const FullPageSpin: FC<IProps> = (props) => {
  let {
    tip,
    tipKey,
    k2Intl,
    localizationSettings,
    spinning,
    size,
    ...remainingProps
  } = props;

  if (tipKey) {
    tip = k2Intl?.formatMessage({ localeKey: tipKey });
  }

  return (
    <Container>
      <Spin
        spinning={spinning || true}
        size={size || "default"}
        tip={tip}
        {...remainingProps}
      />
    </Container>
  );
};

export default injectK2Intl(FullPageSpin);
