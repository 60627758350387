import React, { FC, useEffect, useState } from "react";

import { SecurityPrincipalProfile } from "@n3oltd/k2.users.sdk.security-principals/esm";
import { AccessReq } from "@n3oltd/karakoram.analytics.sdk.reports";
import { Select } from "antd";
import _ from "lodash";
import styled from "styled-components";

import { GeneralFormItemV4 } from "components/formItems";
import { K2Message } from "components/k2Widgets";
import UsersAndRolesSelect from "components/selects/usersAndRolesSelect";

export type AccessOption = "none" | "selected" | "all";

interface SharedAccessProps {
  initialValues?: {
    accessOption: AccessOption;
    limitedTo?: SecurityPrincipalProfile[];
  };
  onChange?: (
    accessOption: AccessOption,
    limitedTo?: SecurityPrincipalProfile[],
  ) => void;
}

const accessOptions: { id: AccessOption; titleKey: string }[] = [
  {
    id: "all",
    titleKey: "common.access.all",
  },
  {
    id: "selected",
    titleKey: "common.access.selected",
  },
  {
    id: "none",
    titleKey: "common.access.none",
  },
];

const AccessDropdownArea = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.grey_4};
`;

const SharedAccess: FC<SharedAccessProps> = ({ initialValues, onChange }) => {
  const [accessOption, setAccessOption] = useState<AccessOption>(
    initialValues?.accessOption,
  );

  const [limitedTo, setLimitedTo] = useState<SecurityPrincipalProfile[]>(
    initialValues?.limitedTo || [],
  );

  useEffect(() => {
    onChange?.(accessOption, limitedTo);
    // eslint-disable-next-line
  }, [accessOption, limitedTo]);

  return (
    <div>
      <AccessDropdownArea>
        <GeneralFormItemV4
          name={"accessOption"}
          required
          labelCol={{ xs: 24, md: 12 }}
          wrapperCol={{ xs: 24, md: 12 }}
          labelKey={"common.access.title"}
        >
          <Select
            showArrow
            value={accessOption}
            onChange={(e: AccessOption) => {
              if (e === "all" || e === "none") setLimitedTo([]);
              else if (e === "selected")
                setLimitedTo(initialValues?.limitedTo || []);
              setAccessOption(e);
            }}
            getPopupContainer={(triggerNode: HTMLElement) => {
              const element = document.getElementById("route-section");
              return !_.isNull(element) ? element : triggerNode;
            }}
          >
            {accessOptions.map((opt) => (
              <Select.Option key={opt.id} value={opt.id}>
                <K2Message localeKey={opt.titleKey} />
              </Select.Option>
            ))}
          </Select>
        </GeneralFormItemV4>
      </AccessDropdownArea>

      {accessOption === "selected" && (
        <GeneralFormItemV4
          name={["limitedTo"]}
          wrapperCol={{ xs: 24 }}
        >
          <UsersAndRolesSelect
            selectedValues={limitedTo}
            onValueChange={setLimitedTo}
          />
        </GeneralFormItemV4>
      )}
    </div>
  );
};

export default SharedAccess;
