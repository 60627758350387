import React from "react";
import { Tag } from "antd";
import moment from "moment";
import { N3oIcon } from "../n3oIcon";

interface IProps {
  overdue?: boolean;
  timestamp: string;
}

const DateBadge = ({ overdue = false, timestamp }: IProps): JSX.Element => (
  <Tag color={overdue ? "red" : "blue"}>
    <N3oIcon
      icontype="n3o-due-date"
      width={12}
      height={12}
      fill={overdue ? "red" : "blue"}
      className={"n3o-mr-1"}
    />
    {moment(timestamp).format("D MMM")}
  </Tag>
);

export default DateBadge;
