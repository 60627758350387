const config = {
  development: {
    sentryDsn:
      "https://328eff6a72e541fcba4ecdad77a7f233@o373343.ingest.sentry.io/5530667",
    defaultRetryIntervalForDuplicates: 10000,
  },
  qa: {
    sentryDsn:
      "https://328eff6a72e541fcba4ecdad77a7f233@o373343.ingest.sentry.io/5530667",
    defaultRetryIntervalForDuplicates: 5000,
  },
  prod: {
    sentryDsn:
      "https://328eff6a72e541fcba4ecdad77a7f233@o373343.ingest.sentry.io/5530667",
    defaultRetryIntervalForDuplicates: 5000,
  },
};

export default config;
