import React, { Component, ReactText } from "react";
import { match } from "react-router-dom";
import { IK2GridColumn } from "appRedux/models/grid/K2GridColumn";
import { ResultsPage } from "appRedux/modules/sharedTypes";
import { ActionDropDownItem } from "components/k2Widgets/k2Dropdowns/ActionsDropDown";
import K2ActionGrid from "components/k2Widgets/k2Grid/K2ActionGrid";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { TablePaginationConfig } from "antd/es/table";
import { SorterResult, TableCurrentDataSource } from "antd/es/table/interface";

const SortableRow = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

export interface AdminActionGridProps<T> {
  keyField: string;
  k2Intl: InjectedK2Intl;
  columns: IK2GridColumn<T>[];
  dataSource: ResultsPage<T>;
  isLoading: boolean;
  sortable?: boolean;
  match?: match<{ page?: string }>;
  gridActions?: ActionDropDownItem[];
  onLoadMore?: () => void;
  onSortEnd?: (data: { oldIndex: number; newIndex: number }) => void;
  className?: string;
  modalRowClassName?: (record: T, action: string) => string;
  additionalActionButton?: React.ReactNode;
  onActionExecuted?: (
    action: string,
    keys: string[],
    allPagesSelected: boolean,
    onDone?: () => void,
  ) => void;
  onSelectChange?: (selectedRowKeys: string[], selectedRows: T[]) => void;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, ReactText[]>,
    sorter: SorterResult<T>,
    extra: TableCurrentDataSource<T>,
  ) => void;
  scroll?: {
    x?: true | number;
    y?: number;
    scrollToFirstRowOnChange?: boolean;
  };
}

interface ViewStateProps {
  importModal: {
    visible: boolean;
  };
}

class AdminActionGrid<T> extends Component<
  AdminActionGridProps<T>,
  ViewStateProps
> {
  constructor(props: AdminActionGridProps<T>) {
    super(props);

    this.state = {
      importModal: {
        visible: false,
      },
    };
  }

  applySortableClass = (columns: IK2GridColumn<T>[]): IK2GridColumn<T>[] => {
    return columns?.map?.((column) => {
      if (!column.className) column.className = "drag-visible";
      else column.className = `${column.className} drag-visible`;
      return column;
    });
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const dataSource = this.props.dataSource.items;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x[this.props.keyField] === restProps["data-row-key"],
    );
    return <SortableRow index={index} {...restProps} />;
  };

  DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={this.props.onSortEnd}
      {...props}
    />
  );

  /** @event Render event of the component */
  render() {
    const {
      columns,
      isLoading,
      dataSource,
      keyField,
      gridActions,
      onActionExecuted,
      k2Intl,
      onLoadMore,
      onChange,
      scroll,
      className,
      modalRowClassName,
      sortable,
      additionalActionButton,
      onSelectChange,
    } = this.props;

    return (
      <K2ActionGrid<T>
        onLoadMore={onLoadMore}
        shouldShowLoadMore={!!dataSource.continuationToken}
        keyField={keyField}
        onSelectChange={onSelectChange}
        modalRowClassName={modalRowClassName}
        className={className}
        headerOptions={
          gridActions && onActionExecuted
            ? {
                actionsOptions: gridActions,
                onActionClick: onActionExecuted,
                additionalActionButton,
              }
            : null
        }
        loading={isLoading}
        dataSource={dataSource.items}
        columns={sortable ? this.applySortableClass(columns) : columns}
        k2Intl={k2Intl}
        onChange={onChange}
        scroll={scroll}
        components={
          sortable
            ? {
                body: {
                  wrapper: this.DraggableContainer,
                  row: this.DraggableBodyRow,
                },
              }
            : undefined
        }
      />
    );
  }
}

export default AdminActionGrid;
