import React, { FC, useState } from "react";

import { CloseOutlined } from "@ant-design/icons/lib";
import {
  SecurityPrincipalProfile,
  SecurityPrincipalReq,
  TaskRes,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { connect } from "react-redux";
import styled from "styled-components";

import { _tasksClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import IApplicationState from "appRedux/types";
import SecurityPrincipalAvatar from "components/avatars/securityPrincipalAvatar";
import {
  fetchTaskError,
  fetchTaskSuccess,
  updateSharedWithUsers,
} from "components/dashboard/editTask/modules/actions";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import K2Spin from "components/k2Widgets/k2Spin";
import { UIUtils } from "components/utils";

const RemoveUserIcon = styled(CloseOutlined)`
  font-size: 0.8em;
  margin-left: 8px;
`;

const RemoveUserSection = styled.span`
  cursor: pointer;
  width: 32px;
  &:hover {
    color: ${({ theme }) => theme.link_color};
  }
  .ant-spin {
    .ant-spin-dot {
      font-size: 12px;
      margin-left: 8px;
    }
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
`;

interface IProps extends InjectedK2IntlProps {
  user: SecurityPrincipalProfile;
  allUsers: SecurityPrincipalProfile[];
  taskId: string;
  taskRevisionId: string;
  updateSharedWith: typeof updateSharedWithUsers;
  fetchTaskSuccess: typeof fetchTaskSuccess;
  fetchTaskError: typeof fetchTaskError;
}

const SharedWithUser: FC<IProps> = ({
  user,
  allUsers,
  updateSharedWith,
  taskId,
  taskRevisionId,
  fetchTaskSuccess,
  fetchTaskError,
  k2Intl,
}) => {
  const [removing, setRemoving] = useState(false);

  const removeSharedWith = async (principalId: string) => {
    const remainingSharedWith: SecurityPrincipalProfile[] = allUsers.filter(
      (user) => {
        return user.principalId !== principalId;
      },
    );

    const securityPrincipals: SecurityPrincipalReq[] = remainingSharedWith?.map?.(
      (item) => {
        return {
          principalId: item.principalId,
          type: item.type,
        };
      },
    );

    setRemoving(true);

    const response: IApiResponse<void> = await K2Service.toResponse(
      _tasksClient.share(taskRevisionId, {
        principals: securityPrincipals,
      }),
    );
    if (response.error) {
      UIUtils.handleServerError(k2Intl, response.error);
    } else {
      const newTask: IApiResponse<TaskRes> = await K2Service.toResponse(
        _tasksClient.getById(taskId),
      );

      if (!newTask.error) {
        fetchTaskSuccess(newTask.getResultOrDefault(), true);
      } else {
        fetchTaskError(newTask.error);
      }

      setRemoving(false);

      updateSharedWith(remainingSharedWith);
    }
  };

  return (
    <>
      <User>
        <div style={{ paddingRight: 10 }}>
          <SecurityPrincipalAvatar
            size="small"
            profile={user}
            presetId="small"
          />
        </div>
        <div>{user.name}</div>
      </User>
      <RemoveUserSection onClick={() => removeSharedWith(user.principalId)}>
        {removing ? <K2Spin size="small" /> : <RemoveUserIcon />}
      </RemoveUserSection>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  taskId: state.editViewTask.task?.id,
  taskRevisionId: state.editViewTask.task?.revisionId,
});

const mapDispatchToProps = {
  updateSharedWith: updateSharedWithUsers,
  fetchTaskSuccess: fetchTaskSuccess,
  fetchTaskError: fetchTaskError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectK2Intl(SharedWithUser));
