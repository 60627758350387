import React, { FC, useMemo } from "react";

import { generateUuid } from "@azure/ms-rest-js";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";
import { Result } from "antd";
import { useGetIsProdEnvironment } from "hooks";
import { useSelector } from "react-redux";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import { AppId } from "appRedux/modules/sharedTypes";
import IApplicationState from "appRedux/types";
import { K2Message } from "components/k2Widgets/index";
import K2Button from "components/k2Widgets/k2Buttons/K2Button";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

const ErrorResult: FC<{
  error: Error;
  k2Intl: InjectedK2Intl;
  appId: AppId;
}> = ({ error, k2Intl, appId }) => {
  const isProdEnv = useGetIsProdEnvironment();

  const user = useSelector(
    (state: IApplicationState) => state.subscription.users.k2User,
  );

  const subscriptionId = useSelector(
    (state: IApplicationState) => state.subscription.users.k2Subscription.id,
  );

  const correlationId = useMemo(() => generateUuid(), []);

  Sentry.captureException(error, {
    level: Severity.Error,
    user: {
      id: user.id,
      email: user.email,
    },
    tags: {
      userId: user.id,
      subscriptionId,
      correlationId,
    },
  });

  return (
    <Result
      style={{ textAlign: "center" }}
      status={"warning"}
      title={<K2Message localeKey="extraPages.warningMsg" />}
      subTitle={
        <>
          <h4>{error.name}</h4>
          <h4>{error.message}</h4>
          {isProdEnv ? (
            <>
              <h4>
                {UIUtils.messageToHtml(k2Intl, "common.error.production", {
                  ref: correlationId,
                })}
              </h4>
            </>
          ) : (
            <h4>{error.stack}</h4>
          )}
        </>
      }
      extra={
        <K2Button
          toUrl={
            appId === "k2" ? K2Routes.main.dashboard : Q1Routes.main.q1Dashboard
          }
        >
          <K2Message localeKey="extraPages.goHome" />
        </K2Button>
      }
    />
  );
};

export default ErrorResult;
