import React from "react";

import styled from "styled-components";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Message } from "components/k2Widgets";
import { N3oIcon } from "components/n3oIcon";

const Header = styled.div`
  background-color: ${({ theme }) => theme.grey_2};
  color: ${({ theme }) => theme.grey_8};
  padding: 10px 15px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
`;

interface IDispositionsHeader {
  callDuration: string;
}
const marginRight = { marginRight: 10 };
const lineHeight = { lineHeight: 0 };

function DispositionsHeader({
  callDuration,
}: IDispositionsHeader): JSX.Element {
  const { theme } = useAppContext();

  return (
    <Header>
      <span>
        <K2Message localeKey="communication.cc.dialler.callEnded" />
      </span>
      <Icon>
        <span style={marginRight}>
          <K2Message localeKey="communication.cc.dialler.logged" />{" "}
          {callDuration}
        </span>
        <span style={lineHeight}>
          <N3oIcon
            height={20}
            width={20}
            icontype={"n3o-outbound-call"}
            fill={theme.grey_8}
          />
        </span>
      </Icon>
    </Header>
  );
}

export default DispositionsHeader;
