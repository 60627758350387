import React, { FC } from "react";
import { LinkProps, Link } from "react-router-dom";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { AppId } from "appRedux/modules/sharedTypes";
import styled from "styled-components";
import _ from "lodash";
import { N3oIcon } from "components/n3oIcon";

export interface K2LinkProps extends LinkProps {
  disabled?: boolean;
  app?: AppId;
  to: string;
  external?: boolean;
  hideExternalIcon?: boolean;
  disableDefaultLinkColor?: boolean;
  customLinkColor?: string;
}

const StyledA = styled.a<{
  $disableDefaultLinkColor: boolean;
  $customLinkColor?: string;
}>`
  color: ${({ theme, $disableDefaultLinkColor, $customLinkColor }) =>
    $customLinkColor
      ? $customLinkColor
      : $disableDefaultLinkColor
      ? "inherit"
      : theme.link_color};
  &:link,
  &:visited {
    color: ${({ theme, $disableDefaultLinkColor, $customLinkColor }) =>
      $customLinkColor
        ? $customLinkColor
        : $disableDefaultLinkColor
        ? "inherit"
        : theme.link_color};
  }
  &:hover {
    color: ${({ theme }) => theme.link_hover_color} !important;
  }
  &:active {
    color: ${({ theme }) => theme.link_active_color} !important;
  }
  * {
    color: ${({ theme, $disableDefaultLinkColor, $customLinkColor }) =>
      $customLinkColor
        ? $customLinkColor
        : $disableDefaultLinkColor
        ? "inherit"
        : theme.link_color};
  }
  *:hover {
    color: ${({ theme }) => theme.link_hover_color} !important;
  }
`;

const StyledLink = styled(Link)<{
  $disableDefaultLinkColor: boolean;
  $customLinkColor?: string;
}>`
  && {
    color: ${({ theme, $disableDefaultLinkColor, $customLinkColor }) =>
      $customLinkColor
        ? $customLinkColor
        : $disableDefaultLinkColor
        ? "inherit"
        : theme.link_color};
    &:link,
    &:visited {
      color: ${({ theme, $disableDefaultLinkColor, $customLinkColor }) =>
        $customLinkColor
          ? $customLinkColor
          : $disableDefaultLinkColor
          ? "inherit"
          : theme.link_color};
    }
    &:hover {
      color: ${({ theme }) => theme.link_hover_color} !important;
    }
  }
`;

const renderLink = (
  props: Omit<LinkProps, "to" | "active">,
  disableDefaultLinkColor: boolean = false,
  customLinkColor: string = undefined,
  to?: string,
) => {
  const remainingProps = _.omit(props, ["to", "active"]);

  return (
    <StyledLink
      to={to}
      {...remainingProps}
      $disableDefaultLinkColor={disableDefaultLinkColor}
      $customLinkColor={customLinkColor}
    />
  );
};

const K2LinkStyledAShared: FC<K2LinkProps> = (props) => {
  const {
    to,
    disableDefaultLinkColor,
    customLinkColor,
    hideExternalIcon,
  } = props;

  return (
    <StyledA
      href={to}
      target={"_blank"}
      rel="noopener noreferrer"
      $disableDefaultLinkColor={disableDefaultLinkColor}
      $customLinkColor={customLinkColor}
    >
      <span>
        {props.children || props.title}{" "}
        {!hideExternalIcon && (
          <N3oIcon
            icontype={"n3o-external-link"}
            width="16px"
            height="16px"
            style={{ marginLeft: 4 }}
            fill={customLinkColor}
          />
        )}
      </span>
    </StyledA>
  );
};

/**
 * K2Link will ensure the correct prefix (/engage or /tally) is prefixed to the beginning of the route
 * You should manually pass the app ("k2" or "q1") where K2Link is used outside of the main app context,
 * or where you explicitly want to create a link to the other application.
 */
const K2Link: FC<K2LinkProps> = (props) => {
  const {
    disabled,
    to,
    app,
    disableDefaultLinkColor,
    customLinkColor,
    external,
    hideExternalIcon,
    ...remainingProps
  } = props;

  const { appId } = useAppContext();
  const selectedApp = app || appId;

  // Add the correct basePath for /engage or /tally if not already appended

  let path = to;
  let pathWithBase;
  if (!external && path && to) {
    if (to[0] === "/") path = to.slice(1);

    // Remove initial baseBath if already provided, including region
    if (path.includes("tally")) {
      path = path.slice(path.indexOf("tally") + 5);
    } else if (path.includes("engage")) {
      path = path.slice(path.indexOf("engage") + 6);
    }

    if (path[0] === "/") path = path.slice(1);

    pathWithBase = `${
      selectedApp === "k2" ? K2Routes.baseRoute : Q1Routes.baseRoute
    }/${path}`;
  }

  if (disabled) {
    return (
      <span className={"n3o-not-allowed"}>
        <span className={"n3o-pointer-events-none"}>
          {external ? (
            <K2LinkStyledAShared {...props} />
          ) : (
            renderLink(
              remainingProps,
              disableDefaultLinkColor,
              customLinkColor,
              pathWithBase,
            )
          )}
        </span>
      </span>
    );
  } else {
    return external ? (
      <K2LinkStyledAShared {...props} />
    ) : (
      renderLink(
        remainingProps,
        disableDefaultLinkColor,
        customLinkColor,
        external ? to : pathWithBase,
      )
    );
  }
};

export default K2Link;
