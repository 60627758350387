import { FundStructureRevisionRes } from "@n3oltd/k2.subscriptions.sdk.funds/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";

import { SharedActionTypes } from "./actionTypes";
import { Dimension } from "./types";

export const fetchFundDimensions = () => ({
  type: SharedActionTypes.FETCH_FUND_DIMENSIONS,
});

export const fetchFundDimensionsError = (error: ServerError) => ({
  type: SharedActionTypes.FETCH_FUND_DIMENSIONS_ERROR,
  error,
});

export const fetchFundDimensionsSuccess = (
  fundStructureRevisionId: string,
  dimensions: Dimension[],
  fundDimensionsStructure: FundStructureRevisionRes,
) => ({
  type: SharedActionTypes.FETCH_FUND_DIMENSIONS_SUCCESS,
  fundStructureRevisionId,
  dimensions,
  fundDimensionsStructure,
});

export const clearFundDimensions = () => ({
  type: SharedActionTypes.CLEAR_FUND_DIMENSIONS,
});
