import React, { FC, useEffect, useState } from "react";

import { Empty, Input } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import _ from "lodash";

import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

import "./MultiSelector.less";
import MultiSelectorHeader from "./MultiSelectorHeader";
import MultiSelectorItem from "./MultiSelectorItem";
import { MultiSelectorDataItem, MultiSelectorProps } from "./types";

const MultiSelector: FC<MultiSelectorProps> = React.forwardRef(
  (
    {
      initialValues,
      dataSource,
      showSearch,
      name,
      onChange,
      searchFieldName,
      renderSuffix,
      maxSelections,
      showSelectAll = true,
      k2Intl,
    },
    ref,
  ) => {
    const [targetKeys, setTargetKeys] = useState<string[]>(initialValues || []);
    const [filteredDataSource, setFilteredDataSource] = useState<
      MultiSelectorDataItem[]
    >([...dataSource]);

    useEffect(() => {
      setFilteredDataSource(dataSource);
    }, [dataSource]);

    const onSelectAllChanged = (e: CheckboxChangeEvent) => {
      let allAvailableKeys = filteredDataSource
        ?.filter((x) => x.isHeading !== true)
        ?.map?.((item) => item.key);

      let tk: string[];
      if (e.target.checked) {
        tk = _.union(targetKeys, allAvailableKeys);
      } else {
        tk = targetKeys.filter((x) => allAvailableKeys.indexOf(x) < 0);
      }

      onChange?.(tk, !_.isEqual(targetKeys, tk));
      setTargetKeys(tk);
    };

    const onSearch = (value: string) => {
      value = value.trim().toLowerCase();
      let filtered = value
        ? dataSource.filter(
            (item) =>
              !item.isHeading && item.title.toLowerCase().indexOf(value) >= 0,
          )
        : dataSource;

      setFilteredDataSource(filtered);
    };

    const isSelected = (item: MultiSelectorDataItem) => {
      return targetKeys.indexOf(item.key) >= 0;
    };

    const onSelectChange = (checked: boolean, item: MultiSelectorDataItem) => {
      const newKeys = targetKeys.filter((tk) => tk !== item.key);

      if (checked) {
        newKeys.push(item.key);
      }

      onChange?.(newKeys, !_.isEqual(targetKeys, newKeys));
      setTargetKeys(newKeys);
    };

    return (
      <div className={"k2-multiselector"}>
        {showSelectAll && (
          <MultiSelectorHeader
            dataSource={dataSource}
            selectedKeys={targetKeys}
            name={name}
            onSelectAllChanged={onSelectAllChanged}
          />
        )}
        <div className="k2-multiselector-body custom-scrollbar-style">
          {showSearch && (
            <Input
              onChange={(e) => onSearch(e.target.value)}
              placeholder={k2Intl.formatMessage({
                localeKey: "common.searchField",
                values: {
                  field: searchFieldName || name,
                },
              })}
              style={{ marginBottom: 10 }}
            />
          )}
          <div className="k2-multiselector-items">
            {filteredDataSource?.length > 0 ? (
              filteredDataSource?.map?.((item) =>
                !item.isHeading ? (
                  <MultiSelectorItem
                    forwardedRef={ref}
                    key={item.key + "_" + item.title}
                    isSelected={isSelected(item)}
                    dataItem={item}
                    onChange={onSelectChange}
                    renderSuffix={renderSuffix}
                    disabled={
                      maxSelections === undefined
                        ? false
                        : targetKeys.length >= maxSelections &&
                          !isSelected(item)
                    }
                  />
                ) : (
                  <h4 key={`${item.key}_${item.title}`} className="k2-divider">
                    <span className="k2-divider-left-text">{item.title}</span>
                  </h4>
                ),
              )
            ) : (
              <Empty className={"n3o-my-2"} />
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default injectK2Intl(MultiSelector);
