import React, { FC } from "react";

import { DonationItemStatus } from "@n3oltd/k2.donations.sdk.donation-items/esm";

import { NamedLookup } from "appRedux/modules/lookups/types";
import K2StatusTag, { K2StatusTagSize } from "components/statusTag/StatusTag";

interface StatusTagProps {
  status: DonationItemStatus;
  donationItemStatuses: NamedLookup[];
  size?: K2StatusTagSize;
}

const DonationItemStatusTag: FC<StatusTagProps> = ({
  status,
  donationItemStatuses,
  ...rest
}) => {
  let color;
  let title;
  const statusesById: { [key: string]: string } = donationItemStatuses.reduce(
    (acc: { [key: string]: string }, item: NamedLookup) => {
      acc[item.id] = item.name;
      return acc;
    },
    {},
  );

  switch (status) {
    case "active":
      title = statusesById["active"];
      color = "green";
      break;
    case "inactive":
      title = statusesById["inactive"];
      color = "default";
      break;
    default:
      color = "default";
      break;
  }

  return (
    <K2StatusTag color={color} {...rest}>
      {title}
    </K2StatusTag>
  );
};

export default DonationItemStatusTag;
