import React, { FC, forwardRef, Ref } from "react";
import { Checkbox } from "antd";
import styled from "styled-components";
import { CheckboxProps } from "antd/lib/checkbox/Checkbox";

const CustomCheckbox = styled(Checkbox)`
  && {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.primaryColor} !important;
      }
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.primaryColor} !important;
    }
    .ant-checkbox:hover,
    .ant-checkbox-checked:hover {
      .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.primaryColor} !important;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.primaryColor} !important;
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }
    .ant-checkbox-checked::after {
      border-color: ${({ theme }) => theme.primaryColor} !important;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }
  }
`;

const K2Checkbox = forwardRef(
  <T extends string>(props: CheckboxProps, ref: Ref<any>) => {
    return <CustomCheckbox {...props} ref={ref} />;
  },
);

export default K2Checkbox;
