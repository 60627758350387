import { ActionTypes } from "./actionTypes";
import { MeasuresAction } from "../types";

export const fetchMeasures = (category: string): MeasuresAction => ({
  type: ActionTypes.FETCH_MEASURES,
  category,
});

export const fetchMeasuresPreview = (
  measureId: string,
  options,
): MeasuresAction => ({
  type: ActionTypes.FETCH_MEASURE_PREVIEW,
  measureId,
  options,
});

export const fetchPinnedMeasures = (): MeasuresAction => ({
  type: ActionTypes.FETCH_PINNED_MEASURES,
});

export const pinMeasure = (measureId, options): MeasuresAction => ({
  type: ActionTypes.PIN_MEASURE,
  measureId,
  options,
});

export const deletePinnedMeasure = (measureId: string): MeasuresAction => ({
  type: ActionTypes.DELETE_PINNED_MEASURE,
  measureId,
});

export const toggleModal = () => ({
  type: ActionTypes.TOGGLE_MODAL,
});
