import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

import { Menu } from "antd";
import { MenuMode } from "antd/es/menu";
import { useRouter, useWindowWidth } from "hooks";
import { matchPath } from "react-router-dom";
import styled from "styled-components";

import { FlatRoutes, K2RouteKeys } from "appRedux/models/routes/RouteModel";
import { K2Menu, K2MenuItem } from "appRedux/modules/sharedTypes";

import { useEnvironmentContext } from "../../../common/contexts/EnvironmentProvider/EnvironmentProvider";
import K2Link from "../k2Link";
import K2Message from "../k2Localizations/K2Message";

const { SubMenu } = Menu;

const StyledMenu = styled(Menu)`
  //background: transparent !important;
  background: #fff !important;
  border: none !important;
  margin-top: 25px;
  margin-bottom: 32px;

  .ant-menu-submenu > .ant-menu {
    background: #fff;
  }

  .ant-menu.item {
    border-radius: 8px;
  }

  li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected,
  .ant-menu-item-selected {
    color: ${({ theme }) => theme.link_color} !important;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: ${({ theme }) => theme.link_color} !important;
  }

  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu-submenu-selected {
    color: ${({ theme }) => theme.link_color} !important;
  }

  .ant-menu-item-selected {
    border: none !important;
    background: ${({ theme }) => theme.primary_2} !important;
    border-radius: 8px;
    a {
      font-weight: ${({ theme }) => theme.font_weight_heavy} !important;
    }
  }

  .ant-menu-item-selected::after {
    display: none;
  }
`;

interface IProps<T extends string> {
  menuOptions: K2Menu<T>;
}

const K2SidebarMenu = <T extends string>(
  props: PropsWithChildren<IProps<T>>,
) => {
  const { menuOptions } = props;
  const router = useRouter();
  const { environment } = useEnvironmentContext();

  const findMatch = useCallback(
    (currentPath: string, menuItems: K2MenuItem[]) => {
      return menuItems.find((menuItem) => {
        return menuItem.matchingPaths.some((path) => {
          return matchPath(currentPath, {
            path,
            exact: true,
            strict: false,
          });
        });
      });
    },
    [],
  );

  /**
   * Gets the currently open menu item
   */
  const getMenuItemFromPath = useCallback(
    (currentPath: string) => {
      let menuItem: K2MenuItem;
      Object.entries(menuOptions).forEach((tupple: [string, K2MenuItem[]]) => {
        const [key, menuItems] = tupple;

        const foundMatch = findMatch(currentPath, menuItems);

        if (foundMatch) {
          menuItem = foundMatch;
        }
      });

      return menuItem;
    },
    [findMatch, menuOptions],
  );

  /**
   * Gets the currently open menu item, e.g. "usersAndRoles" or "sponsorships"
   */
  const getMenuKeyFromPath = useCallback(
    (currentPath: string) => {
      let openKey: T;
      Object.entries(menuOptions).forEach((tupple: [string, K2MenuItem[]]) => {
        const [key, menuItems] = tupple;

        const foundMatch = findMatch(currentPath, menuItems);

        if (foundMatch) {
          openKey = key as T;
        }
      });

      return openKey;
    },
    [findMatch, menuOptions],
  );

  /**
   * Gets the submenu key from the path, e.g. "attachmentTypes" or "findDimensions"
   */
  const getSubMenuKeyFromPath = useCallback(
    (currentPath: string) => {
      let openKey: K2RouteKeys;
      Object.entries(menuOptions).forEach((tupple: [string, K2MenuItem[]]) => {
        const [key, value] = tupple;

        value.forEach((menuItem) => {
          menuItem.matchingPaths.forEach((path) => {
            const matches = matchPath(currentPath, {
              path,
              exact: true,
              strict: false,
            });

            if (matches) {
              Object.entries(FlatRoutes).forEach(([routeKey, ppath]) => {
                if (ppath === path) openKey = routeKey as K2RouteKeys;
              });
            }
          });
        });
      });
      return openKey;
    },
    [menuOptions],
  );

  const [openKeys, setOpenKeys] = useState<T[]>([
    getMenuKeyFromPath(router.location.pathname),
  ]);
  const [selectedPath, setSelectedPath] = useState<string>(null);

  const width: number = useWindowWidth();
  const mode: MenuMode = width <= 992 ? "horizontal" : "inline";

  const onOpenChange = useCallback(
    (openedKeys: T[]) => {
      const latestOpenKey = openedKeys.find(
        (key) => openKeys.indexOf(key) === -1,
      );
      if (Object.keys(menuOptions).indexOf(latestOpenKey) === -1) {
        setOpenKeys(openedKeys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    },
    [menuOptions, openKeys, setOpenKeys],
  );

  useEffect(() => {
    const currentPath = router.location.pathname;
    const currentOpenMenuKey = getMenuKeyFromPath(currentPath);
    const currentOpenMenuItem = getMenuItemFromPath(currentPath);

    if (selectedPath !== currentOpenMenuItem?.principalPath) {
      setSelectedPath(currentOpenMenuItem?.principalPath);
    }

    if (currentOpenMenuKey) {
      setOpenKeys([currentOpenMenuKey]);
    }
  }, [
    getSubMenuKeyFromPath,
    getMenuKeyFromPath,
    router.location.pathname,
    setSelectedPath,
    setOpenKeys,
    selectedPath,
    getMenuItemFromPath,
  ]);

  const shouldShow =
    Object.values(menuOptions).filter((e: K2MenuItem) => !e.hideFromProd)
      ?.length > 0;

  if (shouldShow) {
    return (
      <StyledMenu
        mode={mode}
        selectedKeys={[selectedPath]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {Object.entries(menuOptions)?.map?.(
          (tupple: [string, K2MenuItem[]]) => {
            const [key, value] = tupple;

            return (
              <SubMenu
                key={key}
                title={<K2Message localeKey={`sidebarMenuTitle.${key}`} />}
              >
                {value?.map?.((subMenu) =>
                  subMenu.hideFromProd && environment === "prod" ? null : (
                    <Menu.Item key={subMenu.principalPath}>
                      <K2Link
                        to={subMenu.principalPath}
                        disableDefaultLinkColor
                      >
                        <K2Message localeKey={subMenu.titleKey} />
                      </K2Link>
                    </Menu.Item>
                  ),
                )}
              </SubMenu>
            );
          },
        )}
      </StyledMenu>
    );
  } else {
    return null;
  }
};

export default K2SidebarMenu;
