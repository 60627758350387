import { LocalizationSettingsRes } from "@n3oltd/k2.users.sdk.users/esm";

import { ActionTypes, GlobalAction } from "./actionTypes";

export const localeChange = (
  userSettings: LocalizationSettingsRes,
  subscriptionSettings: LocalizationSettingsRes,
): GlobalAction => ({
  type: ActionTypes.LOCALE_CHANGE,
  userSettings,
  subscriptionSettings,
});
