import { SmsClient } from "@n3oltd/karakoram.templates.sdk.sms";
import { SmsCompositionRes } from "@n3oltd/karakoram.templates.sdk.sms/src/index";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

import K2RestClients, {
  _smsCompositionsClient,
} from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { DataActions } from "appRedux/modules/sharedTypes";
import { TEMPLATE_ENGINE } from "constants/appConstants";

import {
  ActionTypes,
  CreateSMSTemplate,
  DeleteSMSTemplate,
  FetchSMSTemplate,
  UpdateSMSTemplate,
} from "./actionTypes";
import * as actions from "./actions";

//#region WORKERS

//#endregion

function* fetchComposition(action: FetchSMSTemplate) {
  const response: IApiResponse<SmsCompositionRes> = yield K2RestService.toResponse(
    _smsCompositionsClient.getCompositionById(action.id, TEMPLATE_ENGINE, null),
  );

  if (response.error) {
    yield put(actions.fetchSMSTemplateError(response.error));
  } else {
    yield put(actions.fetchSMSTemplateSuccess(response.getResultOrDefault()));
  }
}

function* createTemplate(action: CreateSMSTemplate) {
  try {
    let client = _smsCompositionsClient;
    if (action.ignoreWarning) {
      client = K2RestClients.initRestClient(SmsClient, "templates", {
        getN3OIgnoreValidationWarnings: () => "true",
      });
    }
    const resp: IApiResponse<SmsCompositionRes> = yield K2RestService.toResponse(
      client.createComposition(
        TEMPLATE_ENGINE,
        action.scopeId || undefined,
        action.data,
      ),
    );

    if (resp.error) {
      yield put(
        actions.createSMSTemplateError({
          action: DataActions.add,
          ...resp,
        }),
      );
    } else {
      yield put(
        actions.createSMSTemplateSuccess({
          action: DataActions.add,
          ...resp,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateTemplate(action: UpdateSMSTemplate) {
  try {
    let client = _smsCompositionsClient;
    if (action.ignoreWarning) {
      client = K2RestClients.initRestClient(SmsClient, "templates", {
        getN3OIgnoreValidationWarnings: () => "true",
      });
    }
    const resp: IApiResponse<void> = yield K2RestService.toResponse(
      client.updateComposition(
        action.revisionId,
        TEMPLATE_ENGINE,
        action.scopeId || undefined,
        action.data,
      ),
    );

    if (resp.error) {
      yield put(
        actions.updateSMSTemplateError({
          action: DataActions.update,
          ...resp,
        }),
      );
    } else {
      yield put(
        actions.updateSMSTemplateSuccess({
          action: DataActions.update,
          ...resp,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* deleteTemplate(action: DeleteSMSTemplate) {
  // If the asset has already been saved to the backend, delete it.

  const resp: IApiResponse<void> = yield K2RestService.toResponse(
    _smsCompositionsClient.deleteComposition(
      action.revisionId,
      TEMPLATE_ENGINE,
      null,
    ),
  );

  if (resp.error) {
    yield put(
      actions.deleteSMSTemplateError({
        action: DataActions.delete,
        ...resp,
      }),
    );
  } else {
    yield put(
      actions.deleteSMSTemplateSuccess({
        action: DataActions.delete,
        ...resp,
      }),
    );
  }
}

//#region WATCHERS

export default function* partialOrLayoutSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_SMS_TEMPLATE, fetchComposition),
    takeEvery(ActionTypes.CREATE_SMS_TEMPLATE, createTemplate),
    takeEvery(ActionTypes.UPDATE_SMS_TEMPLATE, updateTemplate),
    takeEvery(ActionTypes.DELETE_SMS_TEMPLATE, deleteTemplate),
  ]);
}

//#endregion
