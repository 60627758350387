import React from "react";

import TextArea from "antd/es/input/TextArea";
import { NamePath } from "rc-field-form/lib/interface";

import injectK2Intl from "../k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "../k2Widgets/k2Localizations/types";
import GeneralFormItemV4 from "./GeneralFormItemV4";

/**
 * !imp this form should be used for property JSONQUERYREQ
 * !imp we can add other properties similar to GeneralFormItemV4
 **/

export interface JsonQueryItemProps extends InjectedK2IntlProps {
  /**form item name */
  name?: NamePath;

  /** form layout object */
  formItemLayout?: { wrapperCol: Object; labelCol?: Object };

  /** className for styling */
  className?: string;
  /**email of the current logged in user */
  userEmail: string;
}

const JsonQueryFormItemV4 = (props: JsonQueryItemProps) => {
  let { name, userEmail, k2Intl, formItemLayout } = props;

  return (
    <GeneralFormItemV4
      name={name}
      labelKey={"common.conditions"}
      labelTooltipKey={"common.conditions.tooltip"}
      rules={[
        {
          validator(_, value) {
            if (value?.length > 0) {
              try {
                JSON.parse(value);
                return Promise.resolve();
              } catch (error) {
                return Promise.reject(
                  k2Intl?.formatMessage({
                    localeKey: "common.conditions.err",
                  }),
                );
              }
            }
            return Promise.resolve();
          },
        },
      ]}
      {...formItemLayout}
    >
      <TextArea
        disabled={
          !(userEmail.endsWith("@n3o.ltd") || userEmail.endsWith("@n3o.dev"))
        }
        rows={5}
      />
    </GeneralFormItemV4>
  );
};

export default injectK2Intl(JsonQueryFormItemV4);
