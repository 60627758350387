import React, { FC, useCallback, useEffect, useState } from "react";

import { UserReq, UserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { useForm } from "antd/es/form/Form";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { _usersClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { userFormLookups } from "appRedux/models/lookups/LookupsModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { UserStatusesLookups } from "appRedux/modules/lookups/types";
import { DataActions, EditStatus } from "appRedux/modules/sharedTypes";
import injectLookups from "appRedux/utils/injectLookups";
import NotFoundWrapper from "components/NotFoundWrapper";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { showInfo } from "components/utils/Confirmation";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import { showNotification } from "components/utils/Notification";
import { REDIRECT_AFTER_SAVE_MS } from "constants/appConstants";
import useBreadcrumb from "hooks/useBreadcrumb";

import EditUsersView from "./components/EditUsersView";
import { withConnect } from "./connect";

interface CreateEditUserProps
  extends InjectedK2IntlProps,
    ConnectedProps<typeof withConnect>,
    RouteComponentProps<{ userId?: string }> {
  userStatuses: UserStatusesLookups;
}

const AddEditUsersMain: FC<CreateEditUserProps> = (props) => {
  const hasIdParam = props.match.params.hasOwnProperty("userId");
  const editMode = hasIdParam;

  const {
    user,
    k2Intl,
    serverError,
    history,
    fetchSelectedEditUser,
    clearSelectedEditUserError,
    clearEditStatus,
    activateUser,
    deactivateUser,
    updateAdminPrivilege,
  } = props;

  const [touched, setTouched] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [overallFormErrors, setOverallFormErrors] = useState<string[]>(null);
  const [updating, setUpdating] = useState<boolean>(false);

  let selectedUserId: string;

  if (hasIdParam) {
    selectedUserId = props.match.params.userId;
  }

  const currentUserId: string = props.currentUser?.id;

  const [form] = useForm();
  const getBreadcrumbConfig = () => [
    {
      path: K2Routes.main.admin,
      titleKey: "mainNav.admin",
      active: false,
    },
    {
      path: K2Routes.users,
      titleKey: "admin.users.manage",
      active: false,
    },
    {
      path: editMode
        ? K2Routes.editUser.replace(":userId", selectedUserId)
        : K2Routes.createUser,
      title: (editMode && props?.user?.fullName) || undefined,
      titleKey: editMode
        ? props.user
          ? props.user.fullName
          : "admin.users.edit"
        : "admin.users.create",
      active: true,
    },
  ];

  const setBreadcrumbs = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbs(getBreadcrumbConfig());
    // eslint-disable-next-line
  }, [user, editMode]);

  useEffect(() => {
    if (serverError) {
      if (serverError.status === K2StatusCodes.notFound) {
        setNotFound(true);
        clearSelectedEditUserError();
      } else {
        UIUtils.handleServerError(
          k2Intl,
          serverError,
          clearSelectedEditUserError,
        );
        setNotFound(false);
      }
    }
  }, [serverError, clearSelectedEditUserError, k2Intl]);

  useEffect(() => {
    props.fetchRoles();
    props.fetchSubscriptionOrganization();
    if (editMode) {
      props.fetchSelectedEditUser(selectedUserId);
    }

    return () => {
      props.clearSelectedEditUser();
      props.clearEditStatus();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const editStatusValue =
      props.editStatus && props.editStatus.action !== undefined;
    if (editStatusValue) {
      handleEditStatus(props.editStatus);
    }
    // eslint-disable-next-line
  }, [props.editStatus]);

  const handleEditStatus = (editStatus: EditStatus): void => {
    switch (editStatus.action) {
      case DataActions.add:
      case DataActions.save: // Using "save" for small saves, e.g. activate/deactivate
      case DataActions.update: // Using "update" for updating a full user object
        if (
          editStatus.error &&
          editStatus.error.status === K2StatusCodes.preconditionFailed
        ) {
          // Handle field-level validation errors and set them on the form
          UIUtils.handleValidationErrors(editStatus.error, form);
          const firstNamedError = editStatus.error.data.errors.find((e) =>
            e.hasOwnProperty("property"),
          );
          if (firstNamedError) {
            setOverallFormErrors([
              k2Intl.formatMessage({
                localeKey: "admin.users.generalFormErrors",
              }),
            ]);
          }

          const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
            editStatus.error,
            "error",
          );

          if (nonFieldRelatedErrors.length) {
            setOverallFormErrors(
              nonFieldRelatedErrors?.map?.((err) => err.error),
            );
          }
          clearEditStatus();
        } else {
          const createdUser: UserRes = editMode ? null : editStatus.result;
          if (createdUser && createdUser.status === "invited") {
            clearEditStatus();
            setTouched(false);
            showInfo({
              k2Intl: props.k2Intl,
              titleKey: "admin.saveSuccessTitle",
              contentKey: "admin.userSaveSuccessMessage.invited",
              contentKeyValues: { name: createdUser.fullName },
              onOk: () => {
                history.push(K2Routes.users);
              },
              cancelButtonProps: {
                style: {
                  display: "none",
                },
              },
            });
          } else {
            UIUtils.handleDataUpdateResult({
              k2Intl: props.k2Intl,
              status: editStatus,
              successTitle: "admin.saveSuccessTitle",
              successMessage: "admin.userSaveSuccessMessage",
              onSuccess: () => {
                clearEditStatus();
                setTouched(false);

                if (editStatus.action === DataActions.save) {
                  // Refetch the user for new revisionID
                  props.fetchSelectedEditUser(selectedUserId);
                } else {
                  // Full user update so redirect back to main users list

                  setTimeout(() => {
                    // Creating a new user
                    if (!hasIdParam) {
                      history.push(K2Routes.users);
                    } else {
                      if (selectedUserId === currentUserId) {
                        props.getAppResources();
                        props.getSubscriptionUserRoutes(true);
                      }
                      history.push(K2Routes.users);
                    }
                  }, REDIRECT_AFTER_SAVE_MS);
                }
              },
              onError: () => {
                clearEditStatus();
              },
              form,
            });
          }
        }
        break;
    }
  };

  const handleSubmit = () => {
    setOverallFormErrors(null);

    form
      .validateFields()
      .then((values) => {
        const request: UserReq = {};
        request.localizationSettings = {};
        const localizationFields = [
          "language",
          "timezone",
          "dateFormat",
          "timeFormat",
          "numberFormat",
        ];
        if (values.jobTitle !== undefined) request.jobTitle = values.jobTitle;
        if (values.firstName !== undefined)
          request.firstName = values.firstName;
        if (values.lastName !== undefined) request.lastName = values.lastName;
        if (values.email !== undefined) request.email = values.email;
        if (values.roles !== undefined) {
          request.roles = values.roles;
        } else if (props.user) {
          request.roles = props.user.roleIds;
        }
        localizationFields.forEach((field) => {
          if (values[field] !== undefined) {
            request.localizationSettings[field] = values[field];
          }
        });
        if (values.storageToken !== undefined) {
          request.picture = {
            storageToken: values.storageToken,
          };
        }
        if (editMode) {
          props.updateSelectedEditUser(
            user.id,
            user.revisionId,
            request,
            user.id === currentUserId,
          );
        } else {
          props.createUser(request);
        }
      })
      .catch((err: ValidateErrorEntity) => {
        form.scrollToField(err.errorFields?.[0]?.name);
      });
  };

  const onUnblockUser = useCallback(async () => {
    setUpdating(true);
    const response: IApiResponse<void> = await K2Service.toResponse(
      _usersClient.unblockUser(user.revisionId),
    );
    if (!response.error) {
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "admin.users.unblock.success",
      });
      fetchSelectedEditUser(selectedUserId);
    } else {
      UIUtils.handleServerError(k2Intl, response.error);
    }
    setUpdating(false);
  }, [k2Intl, user, fetchSelectedEditUser, selectedUserId]);

  const onBlockUser = useCallback(async () => {
    setUpdating(true);
    const response: IApiResponse<void> = await K2Service.toResponse(
      _usersClient.blockUser(user.revisionId),
    );
    if (!response.error) {
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "admin.users.block.success",
      });
      fetchSelectedEditUser(selectedUserId);
    } else {
      UIUtils.handleServerError(k2Intl, response.error);
    }
    setUpdating(false);
  }, [k2Intl, user, fetchSelectedEditUser, selectedUserId]);

  const onDeleteUser = useCallback(async () => {
    setUpdating(true);
    const response: IApiResponse<void> = await K2Service.toResponse(
      _usersClient.deleteUser(user.revisionId),
    );
    if (!response.error) {
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "admin.users.delete.success",
      });

      setTimeout(() => {
        history.push(K2Routes.users);
      }, REDIRECT_AFTER_SAVE_MS);
    } else {
      UIUtils.handleServerError(k2Intl, response.error);
    }
    setUpdating(false);
  }, [k2Intl, user, history]);

  const onUnlockUser = useCallback(async () => {
    setUpdating(true);
    const response: IApiResponse<string> = await K2Service.toResponse(
      _usersClient.unlockUser(user.revisionId),
    );

    if (!response.error) {
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "admin.users.unlock.success",
      });

      fetchSelectedEditUser(selectedUserId);
    } else {
      UIUtils.handleServerError(k2Intl, response.error);
    }
    setUpdating(false);
  }, [k2Intl, user, fetchSelectedEditUser, selectedUserId]);

  const onPasswordReset = useCallback(async () => {
    setUpdating(true);
    const response: IApiResponse<void> = await K2Service.toResponse(
      _usersClient.sendPasswordResetEmail(user.id),
    );
    if (!response.error) {
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "admin.users.resetPassword.success",
      });
      fetchSelectedEditUser(selectedUserId);
    } else {
      UIUtils.handleServerError(k2Intl, response.error);
    }
    setUpdating(false);
  }, [k2Intl, user, fetchSelectedEditUser, selectedUserId]);

  const onStatusUpdated = useCallback(
    (active: boolean) => {
      active ? activateUser(user.revisionId) : deactivateUser(user.revisionId);
    },
    [activateUser, deactivateUser, user],
  );

  const onAdminUpdated = useCallback(
    (admin: boolean) => {
      updateAdminPrivilege(user.revisionId, admin);
    },
    [updateAdminPrivilege, user],
  );

  return (
    <NotFoundWrapper
      showNotFound={notFound}
      entityNameKey={"common.entity.user"}
    >
      <DirtyDataGuard dirty={touched}>
        <EditUsersView
          handleSubmit={handleSubmit}
          onStatusUpdated={onStatusUpdated}
          onAdminUpdated={onAdminUpdated}
          onUnblockUser={onUnblockUser}
          onPasswordReset={onPasswordReset}
          onUnlockUser={onUnlockUser}
          onBlockUser={onBlockUser}
          onDeleteUser={onDeleteUser}
          editMode={editMode}
          onValuesChange={() => setTouched(true)}
          form={form}
          touched={touched}
          overallFormErrors={overallFormErrors}
          updating={updating}
          {...props}
        />
      </DirtyDataGuard>
    </NotFoundWrapper>
  );
};

export default injectLookups(injectK2Intl(AddEditUsersMain), userFormLookups);
