import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const PaymentBankTransfer: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2492 2.37472V2.37472L6.94495 5.04179H17.5551L12.2508 2.37472V2.37472L12.2492 2.37472ZM19.7667 6.41632H4.73333V7.79085H19.7667V6.41632ZM18.4 9.16538H17.0333V16.038H18.4V9.16538ZM19.7667 16.038V9.16538C20.5215 9.16538 21.1333 8.54998 21.1333 7.79085V5.72905C21.1333 5.46873 20.9871 5.23074 20.7556 5.11433L12.8608 1.14469L12.8596 1.14412C12.4757 0.951961 12.0243 0.951961 11.6404 1.14412L3.74443 5.11433C3.51291 5.23074 3.36667 5.46873 3.36667 5.72905V7.79085C3.36667 8.54998 3.97854 9.16538 4.73333 9.16538V16.038C3.97854 16.038 3.36667 16.6534 3.36667 17.4125V18.7862H2.68333C2.30594 18.7862 2 19.0939 2 19.4734C2 19.853 2.30594 20.1607 2.68333 20.1607H4.01441C4.0262 20.1613 4.03806 20.1616 4.05 20.1616H20.45C20.4619 20.1616 20.4738 20.1613 20.4856 20.1607H21.8167C22.1941 20.1607 22.5 19.853 22.5 19.4734C22.5 19.0939 22.1941 18.7862 21.8167 18.7862H21.1333V17.4125C21.1333 16.6534 20.5215 16.038 19.7667 16.038ZM19.7667 18.7862V17.4125H4.73333V18.7862H19.7667ZM8.83333 16.038H10.2V9.16538H8.83333V16.038ZM7.46667 9.16538H6.1V16.038H7.46667V9.16538ZM11.5667 9.16538V16.038H12.9333V9.16538H11.5667ZM14.3 9.16538V16.038H15.6667V9.16538H14.3ZM2 22.2225C2 21.8429 2.30594 21.5352 2.68333 21.5352H21.8167C22.1941 21.5352 22.5 21.8429 22.5 22.2225C22.5 22.602 22.1941 22.9097 21.8167 22.9097H2.68333C2.30594 22.9097 2 22.602 2 22.2225Z"
      fill={fill}
    />
  </svg>
);
