import React, { FC, useEffect, useState } from "react";

import { InboxOutlined } from "@ant-design/icons/lib";
import { generateUuid } from "@azure/ms-rest-js";
import { AssetReq, AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { AssetTypeRes } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { Alert, Col, Form, Row, Upload } from "antd";
import { FormInstance } from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import { UploadFile } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import styled from "styled-components";

import { _assetsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { GeneralFormItemV4 } from "components/formItems";
import { K2Message } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import K2Modal from "components/k2Widgets/k2Modal";
import { UIUtils } from "components/utils";
import { showError } from "components/utils/Confirmation";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import { SharedAssetsHelpers } from "routes/admin/communication/sharedAssets/helpers";

const { Dragger } = Upload;

interface CreateEditAssetModalProps {
  editMode?: boolean;
  asset: AssetRes;
  saving: boolean;
  k2Intl: InjectedK2Intl;
  onCreate?: (assetReq: any) => void;
  onUpdate?: (
    assetId: string,
    assetRevisionId: string,
    notes?: string,
    storageToken?: string,
  ) => void;
  onCancel: () => void;
  assetTypes: AssetTypeRes[];
  form: FormInstance;
  errors?: string[];
  selectedAsset?: AssetRes;
  onDelete?: (revisionId: string, id: string) => void;
  titleKey?: string;
  hideDelete?: boolean;
}

const FileTypeList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const CreateEditAssetModal: FC<CreateEditAssetModalProps> = ({
  editMode,
  saving,
  k2Intl,
  onCreate,
  onUpdate,
  onCancel,
  asset,
  assetTypes,
  form,
  errors,
  selectedAsset,
  onDelete,
  titleKey,
  hideDelete,
}) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [tempUploadedFile, setTempUploadedFile] = useState<UploadFile>(null);
  const [tempStorageToken, setTempStorageToken] = useState<string>(null);

  const handleSaveCreate = () => {
    const notes = form?.getFieldValue(SharedAssetsHelpers.notesFieldName);
    if (editMode) {
      onUpdate(
        asset.id,
        asset.revisionId,
        notes,
        tempStorageToken || asset.file.storageToken,
      );
    } else {
      const req: AssetReq = {
        notes: notes || null,
        file: tempStorageToken || asset.file.storageToken,
      };
      onCreate(req);
    }
  };

  const upload = async (info: RcCustomRequestOptions) => {
    const file = info.file as RcFile;
    const tempUpload: UploadFile = {
      uid: generateUuid(),
      fileName: file.name,
      size: file.size,
      name: file.name,
      type: file.type,
      status: "uploading",
      originFileObj: file,
    };

    if (editMode && tempUpload.fileName !== selectedAsset.file.name) {
      showError({
        titleKey: "common.nonDismissableFormErrorTitle",
        error: k2Intl?.formatMessage({
          localeKey: "admin.communication.assets.filenameError",
        }),
        k2Intl,
      });
      return;
    }

    setDirty(true);
    setTempUploadedFile(tempUpload);
    form?.setFields([
      {
        name: SharedAssetsHelpers.fileFieldName,
        errors: [],
      },
    ]);

    const resp: IApiResponse<string> = await K2RestService.toResponse(
      _assetsClient.upload(null, { fileName: file.name, data: file }),
    );

    if (resp.error) {
      if (resp.error.status === K2StatusCodes.preconditionFailed) {
        setTempUploadedFile({
          ...tempUpload,
          status: "error",
          response: resp.error?.data?.errors
            ?.map((e, i) => e.error)
            ?.join(". "),
        });
      } else {
        UIUtils.handleServerError(k2Intl, resp.error);
        setTempUploadedFile(null);
      }
    } else {
      setTempUploadedFile({
        ...tempUpload,
        status: "done",
      });
      setTempStorageToken(resp.getResultOrDefault());
    }
  };

  useEffect(() => {
    if (selectedAsset) {
      form?.setFields([
        {
          name: SharedAssetsHelpers.notesFieldName,
          value: selectedAsset.notes,
        },
      ]);
    }
  }, [form, selectedAsset]);

  return (
    <DirtyDataGuard dirty={dirty}>
      <K2Modal
        visible={true}
        requiresCloseConfirmation={dirty}
        titleKey={
          titleKey ??
          (editMode
            ? "admin.communication.assets.editShared"
            : "admin.communication.assets.newShared")
        }
        spinning={saving}
        loading={assetTypes.length === 0}
        onCancel={onCancel}
        okButtonProps={{
          children: k2Intl?.formatMessage({ localeKey: "common.save" }),
          onClick: () => {
            form
              .validateFields()
              .then(() => {
                handleSaveCreate();
              })
              .catch((err: ValidateErrorEntity) => {
                form.scrollToField(err.errorFields?.[0]?.name);
              });
          },
          disabled:
            tempUploadedFile?.status === "uploading" ||
            (!editMode && !tempUploadedFile) ||
            tempUploadedFile?.status === "error",
        }}
        cancelButtonProps={{
          disabled: tempUploadedFile?.status === "uploading",
          onClick: onCancel,
        }}
        width={"50%"}
        showDelete={
          !hideDelete && selectedAsset && selectedAsset.linksCount === 0
        }
        onDelete={() => onDelete?.(selectedAsset.revisionId, selectedAsset.id)}
        deleteConfirmationContentKey={"admin.communication.assets.delete"}
      >
        <Form
          form={form}
          onValuesChange={() => setDirty(true)}
          initialValues={
            selectedAsset
              ? editMode
                ? { text: selectedAsset.notes }
                : { notes: { text: selectedAsset.notes } }
              : null
          }
        >
          <GeneralFormItemV4
            {...layout}
            name={SharedAssetsHelpers.notesFieldName}
            labelKey={"common.notes"}
            errorLabelKey={"common.notes"}
          >
            <TextArea />
          </GeneralFormItemV4>
          <Form.Item
            label={k2Intl?.formatMessage({
              localeKey: editMode
                ? "admin.communication.assets.replaceFile"
                : "admin.communication.assets.uploadFile",
            })}
            wrapperCol={{
              xs: 24,
            }}
            labelCol={{
              xs: 24,
            }}
          >
            <GeneralFormItemV4
              valuePropName="fileList"
              wrapperCol={{
                xs: 24,
              }}
              labelCol={{
                xs: 24,
              }}
            >
              <Dragger
                name={SharedAssetsHelpers.fileFieldName}
                multiple={false}
                customRequest={upload}
                onRemove={() => {
                  setTempUploadedFile(null);
                  setTempStorageToken(null);
                }}
                fileList={tempUploadedFile ? [tempUploadedFile] : []}
                accept={assetTypes
                  .reduce((acc, e) => acc.concat(e.allowedExtensions), [])
                  .join(",")}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  <K2Message localeKey={"common.upload.singleMessage"} />
                </p>
              </Dragger>
            </GeneralFormItemV4>
          </Form.Item>

          {editMode ? (
            <Alert
              type={"warning"}
              showIcon={true}
              message={k2Intl?.formatHtmlMessage({
                localeKey: "admin.communication.assets.fileNameWarningTitle",
                values: {
                  filename: asset.file.name,
                },
              })}
              description={k2Intl?.formatMessage({
                localeKey: "admin.communication.assets.fileNameWarningDesc",
              })}
            />
          ) : (
            <Alert
              style={{ marginTop: 20 }}
              type={"info"}
              showIcon={true}
              message={k2Intl?.formatMessage({
                localeKey: "admin.communication.assets.allowedTypesTitle",
              })}
              description={
                <FileTypeList>
                  {assetTypes?.map?.((type) => (
                    <li key={type.id}>
                      <strong>{type.name}</strong> (
                      {k2Intl?.formatMessage({ localeKey: "common.maxSize" })}:{" "}
                      {type.maxSize.text})
                    </li>
                  ))}
                </FileTypeList>
              }
            />
          )}

          {errors?.length > 0 && (
            <Row style={{ marginTop: 20 }}>
              <Col xs={24}>
                <Alert
                  showIcon
                  type="error"
                  message={errors?.map?.((e, i) => (
                    <p key={i}>{e}</p>
                  ))}
                />
              </Col>
            </Row>
          )}
        </Form>
      </K2Modal>
    </DirtyDataGuard>
  );
};

export default CreateEditAssetModal;
