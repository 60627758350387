import React, { FC, useEffect, useState } from "react";

import { TemplateModelType } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { CompositionType } from "@n3oltd/karakoram.templates.sdk.pdf";
import { SmsCompositionRes } from "@n3oltd/karakoram.templates.sdk.sms";
import { Alert, Col, Form, Input, Row, Select, Spin } from "antd";
import { FormInstance } from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { ConnectedProps } from "react-redux";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import { CategoriesLookups } from "appRedux/modules/lookups/types";
import { DataActions } from "appRedux/modules/sharedTypes";
import { GeneralFormItemV4 } from "components/formItems";
import ModifiedInfoDetailsPanel from "components/forms/ModifiedInfoDetailsPanel";
import { K2Button, K2Message } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import LayoutSelector from "routes/admin/communication/sharedComponents/LayoutSelector";
import { PreviewLink } from "routes/admin/communication/sharedComponents/PreviewLink";
import TemplateModelsSelector from "routes/admin/communication/sharedComponents/TemplateModelsSelector";
import VariableList from "routes/admin/communication/sharedComponents/markup/VariableList";

import { withConnect } from "../connect";
import SmsTemplateHelpers from "../helpers";

interface IProps
  extends ConnectedProps<typeof withConnect>,
    InjectedK2IntlProps {
  categories: CategoriesLookups;
  scopeId?: string;
  onSaveSuccess?: (action: DataActions) => void;
  setForm?: (form: FormInstance) => void;
  initialData?: SmsCompositionRes;
  form: FormInstance;
  editMode: boolean;
  onSubmit: (values) => void;
  onPreview: (values) => void;
  onInvalidPreviewRequest: () => void;
  errors?: string[];
  showCancelSaveOpts?: boolean;
}

const layout = {
  labelCol: {
    xs: 24,
  },
  wrapperCol: {
    xs: 24,
    sm: 24,
    md: 18,
    lg: 12,
  },
};

const CreateEditSmsTemplateForm: FC<IProps> = ({
  form,
  scopeId,
  onSubmit,
  errors,
  k2Intl,
  updateDirty,
  dirty,
  saving,
  data,
  onPreview,
  onInvalidPreviewRequest,
  editMode,
  deleteTemplate,
  categories,
  showCancelSaveOpts = true,
  initialData,
}) => {
  data = data || initialData;

  const [
    selectedTemplateModelType,
    setSelectedTemplateModelType,
  ] = useState<TemplateModelType>();

  useEffect(() => {
    setSelectedTemplateModelType(data?.modelType || null);
    form?.setFieldsValue(data);
  }, [data, form]);

  return (
    <DirtyDataGuard dirty={dirty} onNavigate={() => updateDirty(false)}>
      <Spin spinning={saving}>
        {editMode && (
          <ModifiedInfoDetailsPanel
            modifiedInfo={data?.modifiedInfo}
            onDelete={deleteTemplate}
            saving={saving}
            canDelete={data?.canDelete}
            revisionId={data?.revisionId}
            confirmationTitleKey={"admin.communication.template.delete"}
          />
        )}

        <Form
          form={form}
          onFinish={onSubmit}
          onFinishFailed={(err: ValidateErrorEntity) => {
            UIUtils.scrollToFirstErrorWithOffset(form, err);
          }}
          onValuesChange={() => updateDirty(true)}
          initialValues={initialData || data}
        >
          <GeneralFormItemV4
            name={SmsTemplateHelpers.nameFieldName}
            {...layout}
            labelKey={"common.name"}
            errorLabelKey={"common.name"}
            required
          >
            <Input />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={SmsTemplateHelpers.layoutIdFieldName}
            labelKey={"admin.communication.layout"}
            errorLabelKey={"admin.communication.layout"}
            {...layout}
          >
            <LayoutSelector
              entity={CompositionType.Sms}
              k2Intl={k2Intl}
              scopeId={scopeId}
            />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={SmsTemplateHelpers.templateModelTypesFieldName}
            {...layout}
            labelKey={"admin.communication.mergeModelTypes"}
            errorLabelKey={"admin.communication.mergeModelTypes"}
          >
            <TemplateModelsSelector onChange={setSelectedTemplateModelType} />
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={SmsTemplateHelpers.categoryFieldName}
            {...layout}
            errorLabelKey={"admin.communication.category"}
            labelKey={"admin.communication.category"}
            labelTooltipKey={"admin.communication.category.helper"}
          >
            <Select
              placeholder={k2Intl?.formatMessage({
                localeKey: "admin.communication.category.placeholder",
              })}
              optionLabelProp={"children"}
              optionFilterProp={"children"}
              showArrow
            >
              {categories.items?.map?.((category) => {
                return (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                );
              })}
            </Select>
          </GeneralFormItemV4>

          <GeneralFormItemV4
            name={SmsTemplateHelpers.senderFieldName}
            {...layout}
            labelKey={"admin.communication.smsProvider.sender"}
            errorLabelKey={"admin.communication.smsProvider.sender"}
            required
          >
            <Input type={"sms"} />
          </GeneralFormItemV4>

          <PreviewLink>
            <FakeLink
              onClick={() =>
                form
                  ?.validateFields()
                  .then(onPreview)
                  .catch(onInvalidPreviewRequest)
              }
            >
              <K2Message localeKey={"common.preview"} />
            </FakeLink>
          </PreviewLink>

          <GeneralFormItemV4
            name={SmsTemplateHelpers.markupFieldName}
            labelCol={layout.labelCol}
            wrapperCol={{ xs: 24 }}
            labelKey={"admin.communication.smsProvider.body"}
            errorLabelKey={"admin.communication.smsProvider.body"}
            extraLabelKey={"admin.communication.content.help.sms"}
            required
          >
            <TextArea rows={8} />
          </GeneralFormItemV4>

          <VariableList
            templateModelTypeId={selectedTemplateModelType}
            k2Intl={k2Intl}
            pageType={"template"}
            entityType={CompositionType.Sms}
          />

          <GeneralFormItemV4
            name={SmsTemplateHelpers.notesFieldName}
            labelCol={layout.labelCol}
            wrapperCol={{ xs: 24 }}
            labelKey={"common.notes"}
            errorLabelKey={"common.notes"}
          >
            <Input />
          </GeneralFormItemV4>

          {errors && errors.length > 0 && (
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <Alert
                  showIcon
                  type="error"
                  message={errors?.map?.((e, i) => (
                    <p key={i}>{e}</p>
                  ))}
                />
              </Col>
            </Row>
          )}

          {showCancelSaveOpts && (
            <Row>
              <K2Button type={"primary"} htmlType={"submit"} disabled={!dirty}>
                <K2Message localeKey={"common.save"} />
              </K2Button>
              <K2Button toUrl={K2Routes.communicationsSMSTemplates}>
                <K2Message localeKey={"common.cancel"} />
              </K2Button>
            </Row>
          )}
        </Form>
      </Spin>
    </DirtyDataGuard>
  );
};

export default CreateEditSmsTemplateForm;
