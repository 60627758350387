import React, { PureComponent } from "react";
import K2InputV4, { K2InputProps } from "./K2InputV4";
import { Utils } from "components/utils";

export interface K2NumberInputProps extends K2InputProps {
  onValueChanged?: (value: string) => boolean;
}

interface IState {
  value: string | number | string[];
  prevValue: number;
}

class K2NumberInput extends PureComponent<K2NumberInputProps, IState> {
  state = {
    value: undefined,
    prevValue: undefined,
  };

  static getDerivedStateFromProps(
    nextProps: K2NumberInputProps,
    prevState: IState,
  ) {
    if (nextProps.value && nextProps.value !== prevState.prevValue) {
      return {
        value: nextProps.value,
        prevValue: nextProps.value,
      };
    }

    return null;
  }

  handleValue = (value: string) => {
    if (!Utils.isValidNumber(value)) {
      return;
    }

    let shouldUpdate = true;

    if (this.props.onValueChanged) {
      shouldUpdate = this.props.onValueChanged(value);
    }

    if (shouldUpdate) {
      this.setState((_prevState) => ({ value }));
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange?.(e);
    const { value } = e.target;
    e.preventDefault();

    this.handleValue(value);
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    e.preventDefault();

    this.handleValue(value);
    this.props.onBlur?.(e);
  };

  render() {
    const { className, onValueChanged, ...rest } = this.props;
    const { value } = this.state;

    return (
      <K2InputV4
        className={`k2-number-input ${className}`}
        {...rest}
        value={value}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
    );
  }
}

export default K2NumberInput;
