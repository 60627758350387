import {
  CreateFragmentReq,
  FragmentRes,
} from "@n3oltd/karakoram.templates.sdk.fragments/esm";
import { RcFile } from "antd/es/upload";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";
import { TemporaryUploadFile } from "routes/admin/communication/modules/types";
import {
  ActionTypes,
  PartialOrLayoutAction,
} from "routes/admin/communication/newEditPartialOrLayout/modules/actionTypes";

export const fetchPartialOrLayout = (id: string): PartialOrLayoutAction => ({
  type: ActionTypes.FETCH_PARTIAL_OR_LAYOUT,
  id,
});

export const fetchPartialOrLayoutSuccess = (
  data: FragmentRes,
): PartialOrLayoutAction => ({
  type: ActionTypes.FETCH_PARTIAL_OR_LAYOUT_SUCCESS,
  data,
});

export const fetchPartialOrLayoutError = (
  error: ServerError,
): PartialOrLayoutAction => ({
  type: ActionTypes.FETCH_PARTIAL_OR_LAYOUT_ERROR,
  error,
});

export const clearPartialOrLayoutError = (): PartialOrLayoutAction => ({
  type: ActionTypes.CLEAR_PARTIAL_OR_LAYOUT_ERROR,
});

export const clearPartialOrLayout = (): PartialOrLayoutAction => ({
  type: ActionTypes.CLEAR_PARTIAL_OR_LAYOUT,
});

export const updatePartialOrLayout = (
  revisionId: string,
  data: FragmentRes,
): PartialOrLayoutAction => ({
  type: ActionTypes.UPDATE_PARTIAL_OR_LAYOUT,
  revisionId,
  data,
});

export const updatePartialOrLayoutSuccess = (
  editStatus: EditStatus<void>,
): PartialOrLayoutAction => ({
  type: ActionTypes.UPDATE_PARTIAL_OR_LAYOUT_SUCCESS,
  editStatus,
});

export const updatePartialOrLayoutError = (
  editStatus: EditStatus<void>,
): PartialOrLayoutAction => ({
  type: ActionTypes.UPDATE_PARTIAL_OR_LAYOUT_ERROR,
  editStatus,
});

export const deletePartialOrLayout = (
  revisionId: string,
): PartialOrLayoutAction => ({
  type: ActionTypes.DELETE_PARTIAL_OR_LAYOUT,
  revisionId,
});

export const deletePartialOrLayoutSuccess = (
  editStatus: EditStatus<void>,
): PartialOrLayoutAction => ({
  type: ActionTypes.DELETE_PARTIAL_OR_LAYOUT_SUCCESS,
  editStatus,
});

export const deletePartialOrLayoutError = (
  editStatus: EditStatus<void>,
): PartialOrLayoutAction => ({
  type: ActionTypes.DELETE_PARTIAL_OR_LAYOUT_ERROR,
  editStatus,
});

export const createPartialOrLayout = (
  data: CreateFragmentReq,
): PartialOrLayoutAction => ({
  type: ActionTypes.CREATE_PARTIAL_OR_LAYOUT,
  data,
});

export const createPartialOrLayoutSuccess = (
  editStatus: EditStatus<FragmentRes>,
): PartialOrLayoutAction => ({
  type: ActionTypes.CREATE_PARTIAL_OR_LAYOUT_SUCCESS,
  editStatus,
});

export const createPartialOrLayoutError = (
  editStatus: EditStatus<FragmentRes>,
): PartialOrLayoutAction => ({
  type: ActionTypes.CREATE_PARTIAL_OR_LAYOUT_ERROR,
  editStatus,
});

export const clearEditStatus = (): PartialOrLayoutAction => ({
  type: ActionTypes.CLEAR_PARTIAL_OR_LAYOUT_EDIT_STATUS,
});

export const uploadAsset = (
  upload: TemporaryUploadFile,
  originalFile: RcFile,
): PartialOrLayoutAction => ({
  type: ActionTypes.UPLOAD_ASSET,
  upload,
  originalFile,
});

export const updateTemporaryAssets = (
  upload: TemporaryUploadFile,
): PartialOrLayoutAction => ({
  type: ActionTypes.UPDATE_TEMPORARY_ASSETS,
  upload,
});

export const removeTemporaryAsset = (
  temporaryAsset: TemporaryUploadFile,
): PartialOrLayoutAction => ({
  type: ActionTypes.REMOVE_TEMPORARY_ASSET,
  temporaryAsset,
});

export const clearTemporaryAssets = (): PartialOrLayoutAction => ({
  type: ActionTypes.CLEAR_TEMPORARY_ASSETS,
});

export const updateDirty = (dirty: boolean): PartialOrLayoutAction => ({
  type: ActionTypes.UPDATE_DIRTY,
  dirty,
});
