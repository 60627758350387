import React, { FC } from "react";

import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import IApplicationState from "appRedux/types";
import UserAvatar from "components/avatars/userAvatar";
import { K2Link, K2Message, K2PhoneNumber } from "components/k2Widgets";

import { AccountInfo, AppointmentPreview } from "../types";
import AppointmentPopover from "./Outbound/AppointmentPopover";

interface NextAccountToCallSummaryProps {
  account: AccountInfo;
  isTransparent: boolean;
  appointmentInfo?: AppointmentPreview;
}

const Container = styled.div<{ $isTransparent }>`
  background-color: ${({ theme, $isTransparent }) =>
    $isTransparent ? "trasnparent" : theme.grey_2};
  border-radius: 8px;
  padding: 10px;
  .name {
    font-size: 15px;
    font-weight: ${({ theme }) => theme.font_weight_bold};
    color: ${({ theme }) => theme.primary_9};
  }

  .refSm {
    font-size: 13px;
    color: ${({ theme }) => theme.grey_7};
    padding-right: 5px;
  }

  .refText {
    font-size: 15px;
    color: ${({ theme }) => theme.primary_9};
  }
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;

  .phoneNumber {
    font-weight: ${({ theme }) => theme.font_weight_semi_bold};
    color: ${({ theme }) => theme.primary_9};
    font-size: ${({ theme }) => theme.font_size_base};
    line-height: ${({ theme }) => theme.line_height_xl};
  }
`;

const NextAccountToCallSummary: FC<NextAccountToCallSummaryProps> = ({
  account,
  isTransparent,
  appointmentInfo,
}) => {
  const subscriptionId = useSelector(
    (state: IApplicationState) => state.subscription.users.k2Subscription.id,
  );
  return (
    <Container $isTransparent={isTransparent}>
      <Row gutter={[12, 12]}>
        <Col xs={8}>
          <UserAvatar
            size={72}
            className="n3o-avatar-border"
            presetId={"large"}
            subscriptionId={subscriptionId}
            user={account}
          />
        </Col>
        <Col xs={16}>
          <ProfileInfo>
            <K2Link
              to={K2Routes.viewAccount.replace(":accountId", account?.id)}
            >
              <div className="name">{account?.name}</div>
            </K2Link>
            <div className="ref">
              <K2Message spanClassName="refSm" localeKey={"common.refShort"} />
              <span className={"refText"}>{account?.reference?.text}</span>
            </div>
            <span className="phoneNumber">
              <K2PhoneNumber
                contact={account?.phoneNumber}
                formatForTel={true}
              />
              &nbsp;
            </span>

            {appointmentInfo?.scheduledOn && (
              <AppointmentPopover
                appointment={appointmentInfo}
                trigger="click"
              />
            )}
          </ProfileInfo>
        </Col>
      </Row>
    </Container>
  );
};

export default NextAccountToCallSummary;
