import {
  PdfCompositionReq,
  PdfCompositionRes,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";
import { RcFile } from "antd/es/upload";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";
import { TemporaryUploadFile } from "routes/admin/communication/modules/types";

import {
  ActionTypes,
  EpiloguePrologueType,
  PdfTemplateAction,
} from "./actionTypes";

export const fetchPdfTemplate = (id: string): PdfTemplateAction => ({
  type: ActionTypes.FETCH_PDF_TEMPLATE,
  id,
});

export const fetchPdfTemplateSuccess = (
  data: PdfCompositionRes,
): PdfTemplateAction => ({
  type: ActionTypes.FETCH_PDF_TEMPLATE_SUCCESS,
  data,
});

export const fetchPdfTemplateError = (
  error: ServerError,
): PdfTemplateAction => ({
  type: ActionTypes.FETCH_PDF_TEMPLATE_ERROR,
  error,
});

export const clearPdfTemplateError = (): PdfTemplateAction => ({
  type: ActionTypes.CLEAR_PDF_TEMPLATE_ERROR,
});

export const clearPdfTemplate = (): PdfTemplateAction => ({
  type: ActionTypes.CLEAR_PDF_TEMPLATE,
});

export const updatePdfTemplate = (
  revisionId: string,
  data: PdfCompositionReq,
  scopeId?: string,
  ignoreWarning?: boolean,
): PdfTemplateAction => ({
  type: ActionTypes.UPDATE_PDF_TEMPLATE,
  revisionId,
  data,
  scopeId,
  ignoreWarning,
});

export const updatePdfTemplateSuccess = (
  editStatus: EditStatus<void>,
): PdfTemplateAction => ({
  type: ActionTypes.UPDATE_PDF_TEMPLATE_SUCCESS,
  editStatus,
});

export const updatePdfTemplateError = (
  editStatus: EditStatus<void>,
): PdfTemplateAction => ({
  type: ActionTypes.UPDATE_PDF_TEMPLATE_ERROR,
  editStatus,
});

export const deletePdfTemplate = (revisionId: string): PdfTemplateAction => ({
  type: ActionTypes.DELETE_PDF_TEMPLATE,
  revisionId,
});

export const deletePdfTemplateSuccess = (
  editStatus: EditStatus<void>,
): PdfTemplateAction => ({
  type: ActionTypes.DELETE_PDF_TEMPLATE_SUCCESS,
  editStatus,
});

export const deletePdfTemplateError = (
  editStatus: EditStatus<void>,
): PdfTemplateAction => ({
  type: ActionTypes.DELETE_PDF_TEMPLATE_ERROR,
  editStatus,
});

export const createPdfTemplate = (
  data: PdfCompositionReq,
  scopeId?: string,
  ignoreWarning?: boolean,
): PdfTemplateAction => ({
  type: ActionTypes.CREATE_PDF_TEMPLATE,
  data,
  scopeId,
  ignoreWarning,
});

export const createPdfTemplateSuccess = (
  editStatus: EditStatus,
): PdfTemplateAction => ({
  type: ActionTypes.CREATE_PDF_TEMPLATE_SUCCESS,
  editStatus,
});

export const createPdfTemplateError = (
  editStatus: EditStatus,
): PdfTemplateAction => ({
  type: ActionTypes.CREATE_PDF_TEMPLATE_ERROR,
  editStatus,
});

export const clearEditStatus = (): PdfTemplateAction => ({
  type: ActionTypes.CLEAR_PDF_TEMPLATE_EDIT_STATUS,
});

export const uploadEpilogueOrPrologue = (
  logueType: EpiloguePrologueType,
  upload: TemporaryUploadFile,
  originalFile: RcFile,
): PdfTemplateAction => ({
  type: ActionTypes.UPLOAD_EPILOGUE_OR_PROLOGUE,
  logueType,
  upload,
  originalFile,
});

export const updateEpilogueOrPrologue = (
  logueType: EpiloguePrologueType,
  upload: TemporaryUploadFile,
): PdfTemplateAction => ({
  type: ActionTypes.UPDATE_EPILOGUE_OR_PROLOGUE,
  logueType,
  upload,
});

export const removeEpilogueOrPrologue = (
  logueType: EpiloguePrologueType,
  temporaryAsset: TemporaryUploadFile,
): PdfTemplateAction => ({
  type: ActionTypes.REMOVE_EPILOGUE_OR_PROLOGUE,
  logueType,
  temporaryAsset,
});

export const clearEpilogueOrPrologue = (
  logueType: EpiloguePrologueType,
): PdfTemplateAction => ({
  type: ActionTypes.CLEAR_EPILOGUE_OR_PROLOGUE,
  logueType,
});

export const uploadAsset = (
  upload: TemporaryUploadFile,
  originalFile: RcFile,
): PdfTemplateAction => ({
  type: ActionTypes.UPLOAD_ASSET,
  upload,
  originalFile,
});

export const updateTemporaryAssets = (
  upload: TemporaryUploadFile,
): PdfTemplateAction => ({
  type: ActionTypes.UPDATE_TEMPORARY_ASSETS,
  upload,
});

export const removeTemporaryAsset = (
  temporaryAsset: TemporaryUploadFile,
): PdfTemplateAction => ({
  type: ActionTypes.REMOVE_TEMPORARY_ASSET,
  temporaryAsset,
});

export const clearTemporaryAssets = (): PdfTemplateAction => ({
  type: ActionTypes.CLEAR_TEMPORARY_ASSETS,
});

export const updateDirty = (dirty: boolean): PdfTemplateAction => ({
  type: ActionTypes.UPDATE_DIRTY,
  dirty,
});
