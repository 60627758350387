import {
  DonationItemReq,
  DonationItemRes,
} from "@n3oltd/k2.donations.sdk.donation-items/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

import * as actionTypes from "./actionTypes";

export function createDonationItem(donationItem: DonationItemReq) {
  return {
    type: actionTypes.CREATE_DONATION_ITEM,
    donationItem,
  };
}

export function createDonationItemSuccess(editStatus: EditStatus) {
  return {
    type: actionTypes.CREATE_DONATION_ITEM_SUCCESS,
    editStatus,
  };
}

export function createDonationItemError(editStatus: EditStatus) {
  return {
    type: actionTypes.CREATE_DONATION_ITEM_ERROR,
    editStatus,
  };
}

export function clearCreateDonationItemError() {
  return {
    type: actionTypes.CLEAR_CREATE_DONATION_ITEM_ERROR,
  };
}

export function clearDonationItemEditStatus() {
  return {
    type: actionTypes.CLEAR_DONATION_ITEM_EDIT_STATUS,
  };
}

export function updateDonationItem(
  revisionId: string,
  donationItem: DonationItemReq,
) {
  return {
    type: actionTypes.UPDATE_DONATION_ITEM,
    revisionId,
    donationItem,
  };
}

export function updateDonationItemSuccess(editStatus: EditStatus) {
  return {
    type: actionTypes.UPDATE_DONATION_ITEM_SUCCESS,
    editStatus,
  };
}

export function updateDonationItemError(editStatus: EditStatus) {
  return {
    type: actionTypes.UPDATE_DONATION_ITEM_ERROR,
    editStatus,
  };
}

export function fetchSelectedDonationItem(id: string) {
  return {
    type: actionTypes.FETCH_SELECTED_DONATION_ITEM,
    id,
  };
}

export function fetchSelectedDonationItemSuccess(
  donationItem: DonationItemRes,
) {
  return {
    type: actionTypes.FETCH_SELECTED_DONATION_ITEM_SUCCESS,
    donationItem,
  };
}

export function fetchSelectedDonationItemError(error: ServerError) {
  return {
    type: actionTypes.FETCH_SELECTED_DONATION_ITEM_ERROR,
    error,
  };
}

export function clearSelectedDonationItem() {
  return {
    type: actionTypes.CLEAR_SELECTED_DONATION_ITEM,
  };
}

export function deleteDonationItem(id: string) {
  return {
    type: actionTypes.DELETE_DONATION_ITEM,
    id,
  };
}

export function deleteDonationItemSuccess(
  id: string,
  editStatus: EditStatus<void>,
) {
  return {
    type: actionTypes.DELETE_DONATION_ITEM_SUCCESS,
    id,
    editStatus,
  };
}

export function deleteDonationItemError(editStatus: EditStatus<void>) {
  return {
    type: actionTypes.DELETE_DONATION_ITEM_ERROR,
    editStatus,
  };
}

export function clearDeleteDonationItemError() {
  return {
    type: actionTypes.CLEAR_DELETE_DONATION_ITEM_ERROR,
  };
}

export function setFormDirty() {
  return {
    type: actionTypes.SET_FORM_DIRTY,
  };
}

export function clearFormDirty() {
  return {
    type: actionTypes.CLEAR_FORM_DIRTY,
  };
}
