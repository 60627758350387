import { generateUuid } from "@azure/ms-rest-js";
import {
  LetterheadReq,
  LetterheadRes,
  PageOrientation,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";

import { LetterheadReqWithScreenOnly } from "routes/admin/communication/newEditPdfStationery/modules/types";

export default class PdfStationeryHelpers {
  public static nameFieldName = "name";
  public static paperOrientationFieldName = [
    "pageLayout",
    "orientation",
  ];
  public static paperSizeFieldName = [
    "pageLayout",
    "size",
  ];

  public static getScreenOnlyFieldName(index: number) {
    return ["overlays", index, "screenOnly"];
  }

  public static uploadOverlayFieldName = "uploadOverlay";

  public static getInitialValues(
    data: LetterheadRes,
  ): LetterheadReqWithScreenOnly {
    if (!data) {
      return {
        name: "",
        pageLayout: {
          orientation: PageOrientation.Portrait,
        },
        overlays: [],
      };
    } else {
      return {
        ...data,
        overlays: data.overlays?.map?.((o) => {
          return {
            ...o,
            screenOnly: o.visibility === "screen",
            uuid: generateUuid(),
          };
        }),
      };
    }
  }
}
