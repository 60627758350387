import {
  LocalizationSettingsRes as SubscriptionLocalizationSettingsRes,
  LocalizationSettingsRes as UserLocalizationSettingsRes,
} from "@n3oltd/k2.users.sdk.users/esm";

export default class LocaleHelpers {
  public static combineLocalization(
    userLocalization: UserLocalizationSettingsRes,
    subscriptionLocalization: SubscriptionLocalizationSettingsRes,
  ): UserLocalizationSettingsRes {
    // User settings have priority
    return {
      ...subscriptionLocalization,
      ...userLocalization,
    };
  }
}
