import React, { FC, useCallback, useEffect, useState } from "react";

import {
  CompositionType,
  EmailCompositionResultsPage,
} from "@n3oltd/karakoram.templates.sdk.email/esm";
import { SelectValue } from "antd/es/select";

import {
  _emailCompositionsClient,
  _pdfCompositionsClient,
} from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { K2SelectV4 } from "components/k2Widgets";
import { DEFAULT_DELAY, TEMPLATE_ENGINE } from "constants/appConstants";
import useDebounce from "hooks/useDebounce";

interface IProps {
  onChange?: (id: SelectValue) => void;
  value?: SelectValue;
}

const EmailCompositionSelector: FC<IProps> = ({ onChange, value }) => {
  const [compositions, setCompositions] = useState<EmailCompositionResultsPage>(
    {
      items: [],
      continuationToken: "",
    },
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DELAY);

  const fetchCompositions = useCallback(async (debouncedSearchTerm: string) => {
    setLoading(true);

    const resp: IApiResponse<EmailCompositionResultsPage> = await K2RestService.toResponse(
      _emailCompositionsClient.findCompositions(TEMPLATE_ENGINE, null, {
        fullText: debouncedSearchTerm,
        compositionType: CompositionType.Email,
      }),
    );

    if (!resp.error) {
      setCompositions(resp.getResultOrDefault());
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchCompositions(debouncedSearchTerm);
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <K2SelectV4
      onChange={(id) => onChange?.(id)}
      value={value}
      placeholderKey={
        "admin.feedback.schemes.form.emailComposition.placeholder"
      }
      optionLabelProp={"children"}
      optionFilterProp={"children"}
      dataSource={compositions.items}
      loading={loading}
      idField={"id"}
      nameField={"name"}
      style={{ width: "100%" }}
      showSearch
      onSearch={(e) => setSearchTerm(e)}
      focusInputOnFocus
      allowClear
      showArrow
    />
  );
};

export default EmailCompositionSelector;
