import { Environment } from "common/contexts/EnvironmentProvider/EnvironmentProvider";

export type ApiConfig = {
  host: string;
  endpoints: {
    [key: string]: string;
  };
};

export type ApiConfigs = {
  [key in Environment]: ApiConfig;
};

const apiConfig: ApiConfigs = {
  prod: {
    host: window.location.origin,
    endpoints: {
      accounts: "api/accounts",
      activities: "api/activities",
      analytics: "api/analytics",
      communications: "api/communications",
      dataimport: "api/dataimport",
      datawarehouse: "api/datawarehouse",
      donations: "api/donations",
      emails: "api/emails",
      feedbacks: "api/feedbacks",
      forms: "api/forms",
      lists: "api/lists",
      mail: "api/mail",
      navigation: "api/navigation",
      payments: "api/payments",
      pledges: "api/pledges",
      print: "api/print",
      sms: "api/sms",
      sponsorships: "api/sponsorships",
      subscriptions: "api/subscriptions",
      tasks: "api/tasks",
      taxrelief: "api/taxrelief",
      templates: "api/templates",
      timeclock: "api/timeclock",
      users: "api/users",
      hooks: "hooks",
      calls: "api/calls",
      refunds: "api/payments",
      reporting: "api/reporting",
      crowdfunding:"api/crowdfunding",
    },
  },
  qa: {
    host: window.location.origin,
    endpoints: {
      accounts: "api/accounts",
      activities: "api/activities",
      analytics: "api/analytics",
      communications: "api/communications",
      dataimport: "api/dataimport",
      datawarehouse: "api/datawarehouse",
      donations: "api/donations",
      emails: "api/emails",
      feedbacks: "api/feedbacks",
      forms: "api/forms",
      lists: "api/lists",
      mail: "api/mail",
      navigation: "api/navigation",
      payments: "api/payments",
      pledges: "api/pledges",
      print: "api/print",
      sms: "api/sms",
      sponsorships: "api/sponsorships",
      subscriptions: "api/subscriptions",
      tasks: "api/tasks",
      taxrelief: "api/taxrelief",
      templates: "api/templates",
      timeclock: "api/timeclock",
      users: "api/users",
      calls: "api/calls",
      hooks: "hooks",
      refunds: "api/payments",
      reporting: "api/reporting",
      crowdfunding:"api/crowdfunding",

    },
  },
  development: {
    host: "https://beta.n3o.cloud",
    endpoints: {
      accounts: "api/accounts",
      // accounts: "http://localhost:11206",
      activities: "api/activities",
      analytics: "api/analytics",
      // analytics: "http://localhost:20710",
      communications: "api/communications",
      // communications: "http://localhost:14261",
      dataimport: "api/dataimport",
      //dataimport: "http://localhost:17843",
      datawarehouse: "api/datawarehouse",
      donations: "api/donations",
      // donations: "http://localhost:13619",
      emails: "api/emails",
      feedbacks: "api/feedbacks",
      forms: "api/forms",
      lists: "api/lists",
      // lists: "http://localhost:18705",
      mail: "api/mail",
      // mail: "http://localhost:28775",
      navigation: "api/navigation",
      payments: "api/payments",
      // payments: "http://localhost:25713",
      pledges: "api/pledges",
      // pledges: "23076",
      print: "api/print",
      // print: "25973",
      sms: "api/sms",
      // sms: "http://localhost:25501",
      sponsorships: "api/sponsorships",
      // sponsorships: "http://localhost:25119",
      subscriptions: "api/subscriptions",
      // subscriptions: "http://localhost:11908",
      tasks: "api/tasks",
      // tasks: "http://localhost:17556",
      taxrelief: "api/taxrelief",
      templates: "api/templates",
      // templates: "http://localhost:19189",
      timeclock: "api/timeclock",
      users: "api/users",
      // users: "http://localhost:16618",
      calls: "api/calls",
      // calls: "http://localhost:59878",
      hooks: "hooks",
      refunds: "api/payments",
      reporting: "api/reporting",
      crowdfunding:"api/crowdfunding",
    }
   
  },
};

export default apiConfig;
