import {
  EmailCompositionReq,
  EmailCompositionRes,
} from "@n3oltd/karakoram.templates.sdk.email/esm";
import { RcFile } from "antd/es/upload";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";
import { TemporaryUploadFile } from "routes/admin/communication/modules/types";
import {
  ActionTypes,
  EmailTemplateAction,
} from "routes/admin/communication/newEditEmailTemplate/modules/actionTypes";
import { AssetType } from "routes/admin/communication/newEditEmailTemplate/modules/types";

export const fetchEmailTemplate = (id: string): EmailTemplateAction => ({
  type: ActionTypes.FETCH_EMAIL_TEMPLATE,
  id,
});

export const fetchEmailTemplateSuccess = (
  data: EmailCompositionRes,
): EmailTemplateAction => ({
  type: ActionTypes.FETCH_EMAIL_TEMPLATE_SUCCESS,
  data,
});

export const fetchEmailTemplateError = (
  error: ServerError,
): EmailTemplateAction => ({
  type: ActionTypes.FETCH_EMAIL_TEMPLATE_ERROR,
  error,
});

export const clearEmailTemplateError = (): EmailTemplateAction => ({
  type: ActionTypes.CLEAR_EMAIL_TEMPLATE_ERROR,
});

export const clearEmailTemplate = (): EmailTemplateAction => ({
  type: ActionTypes.CLEAR_EMAIL_TEMPLATE,
});

export const updateEmailTemplate = (
  revisionId: string,
  data: EmailCompositionReq,
  scopeId?: string,
  ignoreWarning?: boolean,
): EmailTemplateAction => ({
  type: ActionTypes.UPDATE_EMAIL_TEMPLATE,
  revisionId,
  data,
  scopeId,
  ignoreWarning,
});

export const updateEmailTemplateSuccess = (
  editStatus: EditStatus<void>,
): EmailTemplateAction => ({
  type: ActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS,
  editStatus,
});

export const updateEmailTemplateError = (
  editStatus: EditStatus<void>,
): EmailTemplateAction => ({
  type: ActionTypes.UPDATE_EMAIL_TEMPLATE_ERROR,
  editStatus,
});

export const deleteEmailTemplate = (
  revisionId: string,
): EmailTemplateAction => ({
  type: ActionTypes.DELETE_EMAIL_TEMPLATE,
  revisionId,
});

export const deleteEmailTemplateSuccess = (
  editStatus: EditStatus<void>,
): EmailTemplateAction => ({
  type: ActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS,
  editStatus,
});

export const deleteEmailTemplateError = (
  editStatus: EditStatus<void>,
): EmailTemplateAction => ({
  type: ActionTypes.DELETE_EMAIL_TEMPLATE_ERROR,
  editStatus,
});

export const createEmailTemplate = (
  data: EmailCompositionReq,
  scopeId?: string,
  ignoreWarning?: boolean,
): EmailTemplateAction => ({
  type: ActionTypes.CREATE_EMAIL_TEMPLATE,
  data,
  scopeId,
  ignoreWarning,
});

export const createEmailTemplateSuccess = (
  editStatus: EditStatus,
): EmailTemplateAction => ({
  type: ActionTypes.CREATE_EMAIL_TEMPLATE_SUCCESS,
  editStatus,
});

export const createEmailTemplateError = (
  editStatus: EditStatus,
): EmailTemplateAction => ({
  type: ActionTypes.CREATE_EMAIL_TEMPLATE_ERROR,
  editStatus,
});

export const clearEditStatus = (): EmailTemplateAction => ({
  type: ActionTypes.CLEAR_EMAIL_TEMPLATE_EDIT_STATUS,
});

export const uploadAsset = (
  upload: TemporaryUploadFile,
  originalFile: RcFile,
  assetType: AssetType,
): EmailTemplateAction => ({
  type: ActionTypes.UPLOAD_ASSET,
  upload,
  originalFile,
  assetType,
});

export const updateTemporaryAssets = (
  upload: TemporaryUploadFile,
  assetType: AssetType,
): EmailTemplateAction => ({
  type: ActionTypes.UPDATE_TEMPORARY_ASSETS,
  upload,
  assetType,
});

export const removeTemporaryAsset = (
  temporaryAsset: TemporaryUploadFile,
  assetType: AssetType,
): EmailTemplateAction => ({
  type: ActionTypes.REMOVE_TEMPORARY_ASSET,
  temporaryAsset,
  assetType,
});

export const clearTemporaryAssets = (
  assetType: AssetType,
): EmailTemplateAction => ({
  type: ActionTypes.CLEAR_TEMPORARY_ASSETS,
  assetType,
});

export const updateDirty = (dirty: boolean): EmailTemplateAction => ({
  type: ActionTypes.UPDATE_DIRTY,
  dirty,
});
