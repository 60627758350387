import React, { FC, useCallback, useEffect, useState } from "react";

import { CallDirection, SignInAgentReq } from "@n3oltd/k2.calls.sdk.agents";
import { CallRes } from "@n3oltd/k2.calls.sdk.calls";
import { Tabs } from "antd";
import { usePrevious } from "hooks";
import { useSelector } from "react-redux";
import styled from "styled-components";

import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import UserAvatar from "components/avatars/userAvatar";
import CallIcon, {
  CallIconStatus,
} from "components/callCenter/dialler/components/CallIcon";
import { K2Message, K2Spin, K2Tabs } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { N3oIcon } from "components/n3oIcon";
import { UIUtils } from "components/utils";
import { showNotification } from "components/utils/Notification";

import BottomSection from "./components/BottomSection";
import CallList from "./components/CallList";
import Outbound, { CALL_DUARTION_START } from "./components/Outbound";
import { DiallerProps } from "./connect";
import { CallType } from "./types";

const DiallerContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 30px;
  z-index: 9999;

  #iconSpin .ant-spin-container {
    border-radius: 50% !important;
  }
`;

const DiallerPopover = styled.div`
  width: 300px;
  position: absolute;
  bottom: 110px;
  left: 25px;
  background: white;
  box-shadow: 0 10px 50px rgba(174, 184, 194, 0.5);
  border-radius: 16px 16px 16px 0;
  .callcenter-popover-arrow {
    width: 48px;
    height: 45px;
    overflow: hidden;
    position: absolute;
    bottom: -44px;
  }
  .callcenter-popover-arrow:before {
    top: 0;
    left: 0;
    box-shadow: -50px -50px 0 0 ${({ theme }) => theme.grey_2};
    content: "";
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    border-radius: 50%;
  }
`;

// !imp Uncomment this When Adding Inbound
// const BodySection = styled.div`
//   padding: 16px;
//   padding-top: 0;
//   padding-bottom: 0px;
//   .ant-tabs-tab {
//     width: 50% !important;
//   }
// `;
const BodySection = styled.div`
  padding: 16px;
  padding-top: 0;
  padding-bottom: 0px;

  .ant-tabs-nav-list {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
  }
`;

const CustomAvatar = styled(UserAvatar)`
  .ant-avatar-string {
    line-height: ${({ theme }) => theme.line_height_md};
    font-size: ${({ theme }) => theme.font_size_sm};
  }
`;

const TabTitle = styled.div``;

const { TabPane } = Tabs;

const Dialler: FC<DiallerProps> = ({
  agent,
  agentLoading,
  k2Intl,
  updatingAgent,
  signingOut,
  signOutEditStatus,
  signOutError,
  takingBreak,
  takeBreakError,
  takeBreakEditStatus,
  resumingShift,
  resumeShiftError,
  resumeShiftEditStatus,
  callSkipReasons,
  callEndedReasons,
  callDispositions,
  updatingAgentError,
  timezones,
  agentSignOut,
  agentSignOutClearError,
  agentSignOutClearEditStatus,
  agentTakeBreak,
  agentTakeBreakClearError,
  agentTakeBreakClearEditStatus,
  agentResumeShift,
  agentResumeShiftClearError,
  agentResumeShiftClearEditStatus,
  agentUpdateTeamAndCampaign,
  agentUpdateClearError,
  fetchAgent,
}) => {
  const { theme } = useAppContext();
  const [callType, setCallType] = useState<CallType>(CallType.Out);
  const [showTabsAndBottom, setShowTabsAndBottom] = useState<boolean>(true);
  const [callingAccount, setCallingAccount] = useState<CallRes>(null);
  const [showingPopover, setShowingPopover] = useState<boolean>(true);
  const [callDuration, setCallDuration] = useState<string>(CALL_DUARTION_START);

  const [loading, setLoading] = useState<boolean>(false);
  const [openCallList, setOpenCallList] = useState<boolean>(false);

  const subscriptionId = useSelector(
    (state: IApplicationState) => state.subscription.users.k2Subscription.id,
  );
  const { id: agentId, isOnBreak, isOnCall, onCall } = agent;

  const isOnOutboundCall =
    (isOnCall && onCall?.direction === CallDirection.Outbound) ||
    callingAccount?.direction === CallDirection.Outbound;
  const isOnInboundCall =
    (isOnCall && onCall?.direction === CallDirection.Inbound) ||
    callingAccount?.direction === CallDirection.Inbound;

  const previouslyIsOnBreak = usePrevious(isOnBreak);

  const { inProgress: callInProgress } = callingAccount || {};

  const confirmSignOut = useCallback(() => {
    agentSignOut(agentId);
  }, [agentId, agentSignOut]);

  const confirmBreak = useCallback(() => {
    agentTakeBreak(agentId);
  }, [agentId, agentTakeBreak]);

  const confirmResume = useCallback(() => {
    agentResumeShift(agentId);
  }, [agentId, agentResumeShift]);
  const onIconClick = useCallback(() => {
    if (isOnBreak) {
      confirmResume();
    } else if (!(signingOut || takingBreak || resumingShift)) {
      setShowingPopover(!showingPopover);
    }
  }, [
    confirmResume,
    isOnBreak,
    signingOut,
    takingBreak,
    resumingShift,
    showingPopover,
  ]);

  const callIconStatus = isOnBreak
    ? CallIconStatus.Break
    : isOnInboundCall
    ? CallIconStatus.Inbound
    : isOnOutboundCall
    ? CallIconStatus.Outbound
    : CallIconStatus.Ready;
  const callIconSpin = resumingShift || (signingOut && !showingPopover);
  const showCallIconContent = (isOnCall || callInProgress) && !showingPopover;

  useEffect(() => {
    if (isOnBreak && !previouslyIsOnBreak) {
      setShowingPopover(false);
    } else if (!isOnBreak && previouslyIsOnBreak && !showingPopover) {
      setShowingPopover(true);
    }
  }, [isOnBreak, previouslyIsOnBreak, showingPopover]);

  useEffect(() => {
    if (takeBreakError) {
      UIUtils.handleError(k2Intl, takeBreakError, agentTakeBreakClearError);
    }
    // eslint-disable-next-line
  }, [takeBreakError]);

  useEffect(() => {
    if (resumeShiftError) {
      UIUtils.handleError(k2Intl, resumeShiftError, agentResumeShiftClearError);
    }
    // eslint-disable-next-line
  }, [resumeShiftError]);

  useEffect(() => {
    if (updatingAgentError) {
      UIUtils.handleError(k2Intl, updatingAgentError, agentUpdateClearError);
    }
    // eslint-disable-next-line
  }, [updatingAgentError]);

  useEffect(() => {
    if (signOutError) {
      UIUtils.handleError(k2Intl, signOutError, agentSignOutClearError);
    }
    // eslint-disable-next-line
  }, [signOutError]);

  useEffect(() => {
    if (signOutEditStatus) {
      showNotification({
        type: "success",
        k2Intl,
        titleKey: "common.success.title",
        message: k2Intl.formatMessage({
          localeKey: "communication.cc.signOut.success",
        }),
      });

      agentSignOutClearEditStatus();
    }
    // eslint-disable-next-line
  }, [signOutEditStatus]);
  useEffect(() => {
    if (resumeShiftEditStatus) {
      showNotification({
        type: "success",
        k2Intl,
        titleKey: "common.success.title",
        message: k2Intl.formatMessage({
          localeKey: "communication.cc.resumeShift.success",
        }),
      });

      agentResumeShiftClearEditStatus();
    }
    // eslint-disable-next-line
  }, [resumeShiftEditStatus]);
  useEffect(() => {
    if (takeBreakEditStatus) {
      showNotification({
        type: "success",
        k2Intl,
        titleKey: "common.success.title",
        message: k2Intl.formatMessage({
          localeKey: "communication.cc.takeBreak.success",
        }),
      });

      agentTakeBreakClearEditStatus();
    }
    // eslint-disable-next-line
  }, [takeBreakEditStatus]);
  const refecthAgent = useCallback(() => {
    fetchAgent();
  }, [fetchAgent]);

  return (
    <DiallerContainer id={"callCenterDialler"}>
      <DiallerPopover hidden={!showingPopover}>
        {showTabsAndBottom && (
          <BodySection>
            <K2Tabs
              activeKey={callType}
              onChange={(key) => setCallType(key as CallType)}
            >
              <TabPane
                tab={
                  <TabTitle>
                    <N3oIcon
                      height={20}
                      width={20}
                      icontype={"n3o-outbound-call"}
                      fill={
                        callType === CallType.Out
                          ? theme.primaryColor
                          : theme.grey_7
                      }
                    />
                    <K2Message localeKey={"communication.cc.outbound"} />
                  </TabTitle>
                }
                key={CallType.Out}
              />

              {/* <TabPane
                tab={
                  <TabTitle>
                    <N3oIcon
                      height={20}
                      width={20}
                      icontype={"n3o-inbound-call"}
                      fill={
                        callType === CallType.In
                          ? theme.primaryColor
                          : theme.grey_7
                      }
                    />
                    <K2Message localeKey={"communication.cc.inbound"} />
                  </TabTitle>
                }
                key={CallType.In}
                disabled={true}
              >
                <InboundDiallerContents />
              </TabPane> */}
            </K2Tabs>
          </BodySection>
        )}
        <K2Spin
          spinning={
            signingOut ||
            takingBreak ||
            loading ||
            updatingAgent ||
            agentLoading
          }
        >
          {callType === CallType.Out && (
            <Outbound
              agent={agent}
              updatingAgent={updatingAgent}
              callingAccount={callingAccount}
              callDuration={callDuration}
              callSkipReasons={callSkipReasons}
              callDispositions={callDispositions}
              timezones={timezones}
              callEndedReasons={callEndedReasons}
              agentLoading={agentLoading}
              setCallDuration={setCallDuration}
              onUpdateTeamAndCampaign={agentUpdateTeamAndCampaign}
              setCallingAccount={setCallingAccount}
              refecthAgent={refecthAgent}
              setLoading={setLoading}
              setShowTabsAndBottom={setShowTabsAndBottom}
              setShowingPopover={setShowingPopover}
            />
          )}
        </K2Spin>
        {showTabsAndBottom && (
          <BottomSection
            setOpenCallList={setOpenCallList}
            confirmBreak={confirmBreak}
            confirmSignOut={confirmSignOut}
          />
        )}

        <div className={"callcenter-popover-arrow"}>{""}</div>
      </DiallerPopover>
      <CallIcon
        isOnBreak={isOnBreak}
        spinning={callIconSpin}
        status={callIconStatus}
        content={
          showCallIconContent ? (
            <>
              <strong>{callDuration}</strong>
              <CustomAvatar
                size={"small"}
                className="n3o-avatar-border"
                presetId={"large"}
                subscriptionId={subscriptionId}
                user={{
                  ...callingAccount?.account,
                }}
              />
            </>
          ) : null
        }
        onClick={onIconClick}
      />
      <CallList
        openCallList={openCallList}
        callDispositions={callDispositions}
        callEndedReasons={callEndedReasons}
        setOpenCallList={setOpenCallList}
        agentId={agentId}
        timezones={timezones}
      />
    </DiallerContainer>
  );
};

export default injectK2Intl(Dialler);
