import React, { FC } from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import FakeLink from "components/k2Widgets/k2FakeLink";
import { K2Message } from "components/k2Widgets";
import { PlusOutlined } from "@ant-design/icons/lib";

interface IProps {
  children?: JSX.Element | string;
  onClick?: (any?) => any;
}

const Span = styled.span`
  margin-left: 5px;
`;

const AddTaskDataLink: FC<IProps> = ({ children, onClick }) => {
  return (
    <FakeLink onClick={onClick}>
      <Tooltip
        title={<K2Message localeKey="tasks.action.clickToAddAssignees" />}
      >
        <span>
          <PlusOutlined />
          <Span>{children}</Span>
        </span>
      </Tooltip>
    </FakeLink>
  );
};

export default AddTaskDataLink;
