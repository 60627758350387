import React, { FC, useState } from "react";

import {
  SecurityPrincipalProfile,
  UserRes,
} from "@n3oltd/k2.users.sdk.users/esm";
import { Avatar } from "antd";
import { AvatarProps } from "antd/es/avatar";
import _ from "lodash";

import K2RestClients from "appRedux/models/base/K2RestClients";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { API_VERSION } from "constants/appConstants";

interface IProps extends AvatarProps {
  subscriptionId: string;
  user?: UserRes;
  profile?: SecurityPrincipalProfile;
  presetId: string;
  className?: string;
  imageUrl?: string;
}

const UserAvatar: FC<IProps> = ({
  subscriptionId,
  user,
  profile,
  presetId,
  imageUrl,
  className,
  ...rest
}) => {
  const { theme } = useAppContext();

  const hasPicture =
    (!_.isNil(user?.picture) && !_.isEmpty(user?.picture)) ||
    profile?.hasPicture;
  const userId = user?.id || profile?.principalId;
  const color = user?.color || profile?.color;
  const name = user?.fullName || profile?.name;
  const initials = user?.initials || profile?.initials;

  let pictureUrl = "";
  if (hasPicture) {
    const usersBaseUrl: string = K2RestClients.baseUrlFor("users");
    pictureUrl = `${usersBaseUrl}/${API_VERSION}/${subscriptionId}/${userId}/picture/${presetId}`;
  }

  const [hasError, setHasError] = useState<boolean>(false);

  return (
    <Avatar
      {...rest}
      src={
        !_.isEmpty(imageUrl) && !hasError
          ? imageUrl
          : hasPicture && !hasError && pictureUrl
      }
      style={
        !hasPicture && {
          color: "#fff",
          backgroundColor: color || theme.orange_6,
        }
      }
      alt={name}
      onError={() => {
        setHasError(true);
        return false;
      }}
      className={className}
    >
      {((_.isEmpty(imageUrl) && !hasPicture && !rest.icon) || hasError) &&
        initials}
      {rest.icon}
    </Avatar>
  );
};

export default UserAvatar;
