import { Q1Routes } from "./Q1Routes";
import { omit } from "lodash";

const omittedRoutes = omit(
  Q1Routes,
  "baseRouteEmpty",
  "baseRoute",
  "main",
  "errors",
);
const FlatRoutes = { ...omittedRoutes, ...Q1Routes.main };

export { Q1Routes, FlatRoutes };

export type Q1RouteKeys = keyof typeof FlatRoutes;
export type Q1MainRouteKeys = keyof typeof Q1Routes.main;
export type Q1ErrorRouteKeys = keyof typeof Q1Routes.errors;
