import React from "react";

import { Divider } from "antd";
import styled from "styled-components";

import { K2Message } from "components/k2Widgets";

interface ITitleDividerProps {
  localeKey: string;
}

const Title = styled.div`

  
  .ant-divider-horizontal.ant-divider-with-text{
    margin:0px;
    margin-top:16px;
  }
  .ant-divider-inner-text {
    color: ${({ theme }) => theme.grey_6};
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    padding: 3px 0;
}

  .text {
    position: relative;
    padding: 7px;
  }

}
  }
`;

function TitleDivider({ localeKey }: ITitleDividerProps) {
  return (
    <Title>
      <Divider>
        <K2Message spanClassName="text" localeKey={localeKey} />
      </Divider>
    </Title>
  );
}

export default TitleDivider;
