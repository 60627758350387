import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const FeedbackStopCancel: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.4593 1.3093C4.13755 0.631041 5.05747 0.25 6.01667 0.25C6.97587 0.25 7.89578 0.63104 8.57404 1.3093C9.25229 1.98755 9.63333 2.90747 9.63333 3.86667C9.63333 4.82587 9.25229 5.74578 8.57404 6.42404C8.02411 6.97396 7.31533 7.3285 6.55555 7.44298C7.12953 7.49674 7.69598 7.6366 8.23527 7.86122C9.40825 8.34979 10.3871 9.21204 11.0197 10.314C11.2259 10.6733 11.1018 11.1317 10.7426 11.3379C10.3834 11.5441 9.92499 11.4201 9.71877 11.0608C9.2507 10.2454 8.52644 9.60742 7.65852 9.24591C6.79059 8.8844 5.82758 8.81963 4.91906 9.06165C4.01054 9.30368 3.20738 9.83895 2.63432 10.5843C2.06133 11.3296 1.75046 12.2433 1.75 13.1833V14.5833H3.15C3.53531 14.5833 3.85794 14.8753 3.89628 15.2587L4.54541 21.75H7.48786L7.72903 19.3361C7.7702 18.924 8.13771 18.6232 8.54987 18.6644C8.96203 18.7056 9.26277 19.0731 9.2216 19.4853L8.91295 22.5746C8.87464 22.958 8.55201 23.25 8.16667 23.25H3.86667C3.48135 23.25 3.15873 22.958 3.12039 22.5746L2.47126 16.0833H1C0.585786 16.0833 0.25 15.7476 0.25 15.3333V13.1833C0.250534 11.9127 0.67067 10.6774 1.44515 9.67007C2.21962 8.66271 3.30509 7.93929 4.53294 7.6122C4.84448 7.52921 5.16076 7.47291 5.47848 7.44308C4.71844 7.32872 4.00939 6.97413 3.4593 6.42404C2.78104 5.74578 2.4 4.82587 2.4 3.86667C2.4 2.90747 2.78104 1.98755 3.4593 1.3093ZM6.01667 1.75C5.45529 1.75 4.91691 1.97301 4.51996 2.36996C4.12301 2.76691 3.9 3.30529 3.9 3.86667C3.9 4.42804 4.12301 4.96642 4.51996 5.36338C4.91691 5.76033 5.45529 5.98333 6.01667 5.98333C6.57804 5.98333 7.11642 5.76033 7.51338 5.36338C7.91033 4.96642 8.13333 4.42804 8.13333 3.86667C8.13333 3.30529 7.91033 2.76691 7.51338 2.36996C7.11642 1.97301 6.57804 1.75 6.01667 1.75Z"
      fill={fill}
    />
    <path
      d="M15.3787 14.1767C15.0467 13.8448 14.5085 13.8448 14.1766 14.1767C13.8447 14.5087 13.8447 15.0469 14.1766 15.3788L15.4612 16.6634L14.1766 17.9479C13.8447 18.2799 13.8447 18.8181 14.1766 19.15C14.5086 19.482 15.0468 19.482 15.3787 19.15L16.6633 17.8655L17.9478 19.15C18.2798 19.482 18.818 19.482 19.1499 19.15C19.4819 18.8181 19.4819 18.2799 19.1499 17.948L17.8653 16.6634L19.15 15.3788C19.4819 15.0468 19.4819 14.5086 19.15 14.1767C18.818 13.8447 18.2798 13.8447 17.9479 14.1767L16.6633 15.4613L15.3787 14.1767Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.291 12.291C11.1314 13.4506 10.48 15.0234 10.48 16.6633C10.48 18.3032 11.1314 19.876 12.291 21.0356C13.4506 22.1952 15.0234 22.8467 16.6633 22.8467C18.3032 22.8467 19.876 22.1952 21.0356 21.0356C22.1952 19.876 22.8467 18.3032 22.8467 16.6633C22.8467 15.0234 22.1952 13.4506 21.0356 12.291C19.876 11.1314 18.3032 10.48 16.6633 10.48C15.0234 10.48 13.4506 11.1314 12.291 12.291ZM12.18 16.6633C12.18 15.4743 12.6523 14.3339 13.4931 13.4931C14.3339 12.6523 15.4743 12.18 16.6633 12.18C17.8524 12.18 18.9927 12.6523 19.8335 13.4931C20.6743 14.3339 21.1467 15.4743 21.1467 16.6633C21.1467 17.8524 20.6743 18.9927 19.8335 19.8335C18.9927 20.6743 17.8524 21.1467 16.6633 21.1467C15.4743 21.1467 14.3339 20.6743 13.4931 19.8335C12.6523 18.9927 12.18 17.8524 12.18 16.6633Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6667 0.316406C13.0877 0.316406 12.5323 0.546434 12.1229 0.95589C11.7134 1.36535 11.4834 1.92068 11.4834 2.49974V5C11.4834 5.57906 11.7134 6.13439 12.1229 6.54385C12.5323 6.9533 13.0877 7.18333 13.6667 7.18333H14.1501V9C14.1501 9.31348 14.3226 9.60151 14.599 9.74942C14.8754 9.89734 15.2107 9.88113 15.4716 9.70724L19.2574 7.18333H20.6667C21.2458 7.18333 21.8011 6.9533 22.2106 6.54385C22.62 6.13439 22.8501 5.57906 22.8501 5V2.49974C22.8501 1.92068 22.62 1.36535 22.2106 0.95589C21.8011 0.546435 21.2458 0.316406 20.6667 0.316406H13.6667ZM13.6667 2.01641C13.5385 2.01641 13.4156 2.06733 13.325 2.15797C13.2343 2.24861 13.1834 2.37155 13.1834 2.49974V5C13.1834 5.12819 13.2343 5.25113 13.325 5.34177C13.4156 5.43241 13.5385 5.48333 13.6667 5.48333H15.0001C15.4695 5.48333 15.8501 5.86389 15.8501 6.33333V7.41176L18.5286 5.62609C18.6682 5.53301 18.8323 5.48333 19.0001 5.48333H20.6667C20.7949 5.48333 20.9179 5.43241 21.0085 5.34177C21.0991 5.25113 21.1501 5.12819 21.1501 5V2.49974C21.1501 2.37155 21.0991 2.24861 21.0085 2.15797C20.9179 2.06733 20.7949 2.01641 20.6667 2.01641H13.6667Z"
      fill={fill}
    />
  </svg>
);
