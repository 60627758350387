import React, { ChangeEvent, FC } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { K2InputV4 } from "components/k2Widgets";
import styled from "styled-components";

const K2InputV4Wrapper = styled(K2InputV4)`
  border: none !important;
  margin-top: 2px;
`;

interface IProps {
  width: number;
  placeholder: string;
  searchValue: string;
  height: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TaskSearch: FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <K2InputV4Wrapper
      prefix={
        <SearchOutlined
          style={{ color: "rgba(0,0,0,.25)" }}
          width={16}
          height={16}
        />
      }
      className="n3o-global-search-box"
      onChange={props.onChange}
      value={props.searchValue}
      placeholder={props.placeholder}
      size="large"
      style={{
        height: props.height,
      }}
    />
  );
};

export default TaskSearch;
