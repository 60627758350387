import React, { FC, PropsWithChildren, useEffect, useMemo } from "react";

import { LockFilled } from "@ant-design/icons/lib/icons";
import _ from "lodash";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";

import K2Tooltip from "../k2Tooltip/K2Tooltip";

interface IProps {
  checkAdmin?: boolean;
  permission?: string;
  titleKey?: string;
  locked?: boolean;
  disabled?: boolean;

  checkPermission?: (havePermission: boolean) => void;
}

const K2Permission: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    children,
    checkAdmin,
    permission,
    titleKey,
    locked,
    disabled,
    checkPermission,
  } = props;

  const { k2User } = useAppContext();
  const permissionIds = k2User?.permissionIds || [];
  const isAdmin = k2User?.admin || false;

  const havePermission = useMemo(
    () =>
      !_.isNil(permission)
        ? permissionIds?.includes(permission)
        : checkAdmin
        ? isAdmin
        : true,
    [checkAdmin, isAdmin, permission, permissionIds],
  );

  useEffect(() => {
    checkPermission?.(havePermission);
  }, [checkPermission, havePermission]);

  return (
    <K2Tooltip
      hidden={_.isNil(titleKey) && havePermission}
      disabled={!havePermission || disabled}
      titleKey={
        !havePermission
          ? "common.generalPermission"
          : titleKey || "common.generalPermission"
      }
    >
      {!havePermission ? (
        <span className={"n3o-pointer-events-none"}>
          {children}
          {locked && <LockFilled />}
        </span>
      ) : (
        <>{children}</>
      )}
    </K2Tooltip>
  );
};

export default K2Permission;
