import React, { FC, useEffect, useState, useContext } from "react";
import {
  LogoutOptions,
  RedirectLoginOptions,
  IdToken,
  Auth0ClientOptions,
} from "@auth0/auth0-spa-js/dist/typings/global";
import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";

export interface IAuth0 {
  isAuthenticated: boolean;
  user?: IAuth0User;
  isLoading: boolean;
  isPopupOpen: boolean;
  loginWithPopup?: (params?: object) => Promise<void>;
  handleRedirectCallback?: () => Promise<void>;
  getIdTokenClaims?: (...p: any[]) => Promise<IdToken>;
  loginWithRedirect?: (options?: RedirectLoginOptions) => Promise<void>;
  getTokenSilently?: (...p: any[]) => Promise<any>;
  getTokenWithPopup?: (...p: any[]) => Promise<string>;
  logout?: (options?: LogoutOptions) => void;
  targetUrl?: string;
}

export interface IAuth0User {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

interface IAuth0WrapperProps extends Auth0ClientOptions {}

export const Auth0Context = React.createContext<IAuth0>(undefined);
export const useAuth0 = () => useContext(Auth0Context);

const { Provider } = Auth0Context;

// A function that routes the user to the right place
// after login
const OnRedirectCallback = (appState: IAuth0) => {
  if (appState && appState.targetUrl) {
    window.location.replace(appState.targetUrl);
  }
};

const Auth0Wrapper: FC<IAuth0WrapperProps> = (props) => {
  const {
    children,
    onRedirectCallback = OnRedirectCallback,
    ...initOptions
  } = props;

  const [auth0Client, setAuth0] = useState<Auth0Client>();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<IAuth0User>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (e) {
          // Code/State query params not provided by Auth0 - ignore
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser<IAuth0User>();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);

    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    const user = await auth0Client.getUser<IAuth0User>();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);

    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser<IAuth0User>();

    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        isPopupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (options?: RedirectLoginOptions) => {
          const { redirect_uri } = initOptions;
          options.redirect_uri = redirect_uri;
          return auth0Client.loginWithRedirect(options);
        },
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (options?: LogoutOptions) => {
          const { client_id } = initOptions;
          options.client_id = client_id;

          auth0Client.logout(options);
        },
      }}
    >
      {children}
    </Provider>
  );
};

export default Auth0Wrapper;
