import produce from "immer";
import { PdfStationeryState } from "./types";
import { ActionTypes, AddEditPdfStationeryAction } from "./actionTypes";

const initialState: PdfStationeryState = {
  data: null,
  editStatus: null,
  error: null,
  loading: false,
  saving: false,
  dirty: false,
};

const pdfStationeryItemReducer = (
  state = initialState,
  action: AddEditPdfStationeryAction,
) =>
  produce(state, (draft: PdfStationeryState) => {
    switch (action.type) {
      case ActionTypes.FETCH_LETTERHEAD:
        draft.loading = true;
        break;
      case ActionTypes.FETCH_LETTERHEAD_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case ActionTypes.FETCH_LETTERHEAD_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        break;
      case ActionTypes.CLEAR_LETTERHEAD:
        return { ...initialState };
      case ActionTypes.CLEAR_LETTERHEAD_EDIT_STATUS:
        draft.editStatus = null;
        break;
      case ActionTypes.CLEAR_LETTERHEAD_ERROR:
        draft.error = null;
        break;

      case ActionTypes.CREATE_LETTERHEAD:
        draft.saving = true;
        break;
      case ActionTypes.CREATE_LETTERHEAD_ERROR:
      case ActionTypes.CREATE_LETTERHEAD_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.UPDATE_LETTERHEAD:
        draft.saving = true;
        break;
      case ActionTypes.UPDATE_LETTERHEAD_ERROR:
      case ActionTypes.UPDATE_LETTERHEAD_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.UPDATE_DIRTY:
        draft.dirty = action.dirty;
        break;
    }
  });

export default pdfStationeryItemReducer;
