import { all, put, takeEvery } from "redux-saga/effects";
import * as actions from "components/dashboard/newTask/modules/actions";
import {
  UploadAttachmentAction,
  ActionTypes,
} from "components/dashboard/newTask/modules/actionTypes";
import K2Service from "appRedux/models/base/K2RestService";
import { _tasksClient } from "appRedux/models/base/K2RestClients";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";

function* uploadAttachment(action: UploadAttachmentAction) {
  yield put(actions.setAttachmentAsUploading(action.uid, action.file));
  const file = action.file;

  const response: IApiResponse<string> = yield K2Service.toResponse(
    _tasksClient.uploadFile({ data: file, fileName: file.name }),
  );
  if (response.error) {
    yield put(actions.setUploadError(action.uid));
  } else {
    yield put(actions.setUploadComplete(action.uid, response));
  }
}

export default function* newTaskSaga() {
  yield all[yield takeEvery(ActionTypes.UPLOAD_ATTACHMENT, uploadAttachment)];
}
