import React, { FC } from "react";

import { Breadcrumb } from "antd";
import { connect } from "react-redux";
import styled from "styled-components";

import { BreadcrumbState } from "appRedux/modules/breadcrumbs/types";
import IApplicationState from "appRedux/types";
import { K2Link, K2Message } from "components/k2Widgets";

const { Item } = Breadcrumb;

interface K2BreadcrumbProps extends StateProps {}

const K2BreadcrumbLink = styled(K2Link)<{ active?: boolean }>`
  span {
    color: ${({ theme, active }) =>
      active ? theme.grey_7 : theme.primaryColor};
  }
  pointer-events: ${({ active }) => (active ? "none" : "auto")};
  cursor: ${({ active }) => (active ? "default" : "pointer")};
`;

const K2Breadcrumb: FC<K2BreadcrumbProps> = ({ breadcrumbsState }) => {
  return (
    <Breadcrumb>
      {breadcrumbsState?.crumbs?.map?.((crumb, i) => {
        return (
          <Item key={i}>
            <K2BreadcrumbLink to={crumb.path} active={crumb.active}>
              {crumb.title ? (
                <span>{crumb.title}</span>
              ) : (
                <K2Message
                  localeKey={crumb.titleKey}
                  values={crumb.titleValues}
                />
              )}
            </K2BreadcrumbLink>
          </Item>
        );
      })}
    </Breadcrumb>
  );
};

interface StateProps {
  breadcrumbsState: BreadcrumbState;
}

const mapStateToProps = (state: IApplicationState): StateProps => ({
  breadcrumbsState: state.breadcrumbs,
});

export default connect(mapStateToProps)(K2Breadcrumb);
