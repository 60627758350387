import { useState } from "react";

import {
  SecurityPrincipalCriteria,
  SecurityPrincipalResultsList,
} from "@n3oltd/k2.users.sdk.security-principals";
import { SecurityPrincipalProfile } from "@n3oltd/k2.users.sdk.users";

import { _securityPrincipalsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

interface IGetSecurityPrincipalsProps {
  criteria: SecurityPrincipalCriteria;
  omittedIds: string[];
  permissionsRequired: string[];
}

function useGetSecurityPrincipals(
  k2Intl: InjectedK2Intl,
): [
  SecurityPrincipalProfile[],
  boolean,
  (params: IGetSecurityPrincipalsProps) => void,
  React.Dispatch<React.SetStateAction<SecurityPrincipalProfile[]>>,
] {
  const [loading, setLoading] = useState<boolean>(false);
  const [securityPrincipals, setSecurityPrincipals] = useState<
    SecurityPrincipalProfile[]
  >([]);

  const fetchSecurityPrincipals = async (
    params: IGetSecurityPrincipalsProps,
  ) => {
    const { omittedIds, criteria, permissionsRequired } = params;
    setLoading(true);
    let oIds = omittedIds || [];
    const req: SecurityPrincipalCriteria = {
      ...criteria,
    };
    if (permissionsRequired?.length > 0) {
      req.permission = [...permissionsRequired];
    }
    const response: IApiResponse<SecurityPrincipalResultsList> = await K2RestService.toResponse(
      _securityPrincipalsClient.find(req),
    );

    if (!response.error) {
      setSecurityPrincipals(
        response
          .getResultOrDefault()
          .items.filter((x) => !oIds.includes(x.principalId)),
      );
    } else {
      UIUtils.handleServerError(k2Intl, response.error);
    }
    setLoading(false);
  };

  return [
    securityPrincipals,
    loading,
    fetchSecurityPrincipals,
    setSecurityPrincipals,
  ];
}

export default useGetSecurityPrincipals;
