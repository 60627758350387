import moment from "moment";
import { CookieSetOptions } from "universal-cookie";

import { redirectRouteStorageKey } from "appRedux/models/routes/K2Routes";
import SecurityHelpers from "common/helpers/security";
import { IAuth0 } from "containers/app/views/auth/Auth0Wrapper";

import K2RestClients from "../base/K2RestClients";

interface CookieSetOptionsExtended extends CookieSetOptions {
  prodDomain: string;
}

class AuthManager {
  public static readonly keys = {
    cookies: {
      subscriptionId: "N3O-Subscription-ID",
    },
    requestHeaders: {
      appId: "N3O-App-Id",
      ignoreValidationWarnings: "N3O-Ignore-Validation-Warnings",
      routeKey: "N3O-Route-Key",
      serviceCache: "N3O-Service-Cache",
      clockAdjustment: "N3O-Clock-Adjustment",
      subscriptionId: "N3O-Subscription-ID",
    },
    responseHeaders: {
      lookupsModified: "N3O-lookups-modified",
    },
  };

  private static redirectUrlStorageKey: string;
  private static auth0: IAuth0;
  private static token: string;
  private static subscriptionId: string;
  private static userId: string;

  public static initialize(auth0: IAuth0): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.subscriptionId = "";
      this.redirectUrlStorageKey = redirectRouteStorageKey;
      this.auth0 = auth0;
      this.authenticationSetup()
        .then((res) => {
          K2RestClients.initialize();
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static getCookieSetOptions = (): CookieSetOptionsExtended => {
    return {
      path: "/",
      expires: moment(new Date()).add(90, "day").toDate(),
      secure: true,
      sameSite: "none",
      domain: SecurityHelpers.getCookieDomain(window.location.hostname),
      prodDomain: ".n3o.cloud",
    };
  };

  public static authenticationSetup = async () => {
    try {
      const { getTokenSilently } = AuthManager.auth0;
      const token = await getTokenSilently();

      AuthManager.setToken(token); //for autorest
    } catch (error) {
      console.error(error);
    }
  };

  public static setToken(token: string) {
    this.token = token;
  }

  public static getToken() {
    return this.token;
  }

  public static setSubscriptionId(id: string) {
    this.subscriptionId = id;
  }

  public static getSubscriptionId() {
    return this.subscriptionId || "";
  }

  public static setUserId(id: string) {
    this.userId = id;
  }

  public static getUserId() {
    return this.userId;
  }

  public static logout() {
    this.clearPreservedUrl();
    this.auth0.logout({ returnTo: window.location.origin });
  }

  public static async authenticate() {
    this.preserveCurrentURL();
    const response = await this.authenticationSetup();
    this.clearPreservedUrl();
    return response;
  }

  public static clearPreservedUrl() {
    localStorage.removeItem(this.redirectUrlStorageKey);
  }

  public static preserveCurrentURL() {
    localStorage.setItem(
      AuthManager.redirectUrlStorageKey,
      window.location.pathname,
    ); //ToDo: pass-in from route
  }
}

export default AuthManager;
