import React, { FC, useEffect, useState } from "react";
import { unloadListener } from "util/unloadListener";
import { Prompt } from "react-router-dom";
import injectK2Intl from "../k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "../k2Widgets/k2Localizations/types";
import { useRouter } from "../../hooks/index";
import { showConfirmLeavePage } from "components/utils/Confirmation";

interface DirtyDataGuardProps extends InjectedK2IntlProps {
  dirty: boolean;
  tempDisabled?: boolean;
  children: string | JSX.Element | JSX.Element[];
  onNavigate?: () => void;
}

/**
 * Use this component to wrap any pages/forms which require confirmation before
 * navigating away or closing the page when the data in the form is dirty.
 *
 * NB You should only use a maximum of one DirtyDataGuard per page,
 * to avoid multiple confirmation prompts being rendered.
 * If you have multiple forms on a page, be sure to wrap them all in a single DirtyDataGuard.
 */
const DirtyDataGuard: FC<DirtyDataGuardProps> = ({
  dirty,
  tempDisabled,
  k2Intl,
  children,
  onNavigate,
}) => {
  const [prompted, setPrompted] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    if (!tempDisabled) {
      // Handling the page unload event, i.e. browser window or tab is closed.
      if (dirty) {
        window.addEventListener("beforeunload", unloadListener);
      } else {
        window.removeEventListener("beforeunload", unloadListener);
      }
    }
  }, [dirty, tempDisabled]);

  useEffect(() => {
    return () => window.removeEventListener("beforeunload", unloadListener);
  }, []);

  return (
    <>
      {/* Handling an in-app navigation event */}
      <Prompt
        message={(nextLocation) => {
          if (nextLocation.pathname === router.location.pathname) {
            // Was an in-page navigation event
            return;
          }
          if (!tempDisabled && dirty && !prompted) {
            setPrompted(true);
            showConfirmLeavePage({
              navigate: () => {
                onNavigate?.();
                router.history.push(nextLocation);
              },
              onCancel: () => setPrompted(false),
              k2Intl,
              titleKey: "common.areYouSure",
              contentKey: "common.changesNotSaved",
            });
            return false;
          }
          return true;
        }}
      />
      {children}
    </>
  );
};

export default injectK2Intl(DirtyDataGuard);
