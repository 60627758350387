import {
  BookmarkRes,
  BookmarkResultsPage,
  PinnedVisualRes,
  ReportCategory,
  ReportEmbedRes,
  ReportSummaryRes,
} from "@n3oltd/karakoram.analytics.sdk.reports/esm";
import { createSelector } from "reselect";

import { ServerError } from "appRedux/models/common/ApiResponseModel";

import { analyticsActions } from "../../modules/analytics";
import IApplicationState from "../../types";

const {
  fetchAllReports,
  fetchAllBookmarks,
  fetchEmbeddedReport,
  captureBookmark,
  deleteBookmark,
  pinVisualToDashboard,
  unpinVisualFromDashboard,
  clearReportsAndVisuals,
  clearServerError,
} = analyticsActions;

export default class AnalyticsSelectors {
  public static selectedAnalytics = ({ analytics }: IApplicationState) =>
    analytics;

  static getPinnedReportsDispatchers(): IGetPinnedReportsDispatchers {
    return {
      fetchAllReports,
      fetchAllBookmarks,
      fetchEmbeddedReport,
      captureBookmark,
      deleteBookmark,
      pinVisualToDashboard,
      unpinVisualFromDashboard,
      clearReportsAndVisuals,
      clearServerError,
    };
  }

  static getPinnedReportsSelector() {
    return createSelector(
      this.selectedAnalytics,
      (k2Analytics): IGetPinnedReportsSelectorProps => {
        return {
          loading: k2Analytics.loading,
          pinToDashboardLoading: k2Analytics.pinToDashboardLoading,
          captureBookmarkLoading: k2Analytics.captureBookmarkLoading,
          deleteBookmarkLoading: k2Analytics.deleteBookmarkLoading,
          bookmarksLoading: k2Analytics.bookmarksLoading,
          allReports: k2Analytics.allReports,
          allBookmarks: k2Analytics.allBookmarks,
          embedReportRes: k2Analytics.embedReport,
          pinnedVisual: k2Analytics.pinnedVisual,
          capturedBookmark: k2Analytics.capturedBookmark,
          serverError: k2Analytics.serverError,
        };
      },
    );
  }
}

type IGetPinnedReportsDispatchers = {
  fetchAllReports: typeof fetchAllReports;
  fetchAllBookmarks: typeof fetchAllBookmarks;
  fetchEmbeddedReport: typeof fetchEmbeddedReport;
  captureBookmark: typeof captureBookmark;
  deleteBookmark: typeof deleteBookmark;
  pinVisualToDashboard: typeof pinVisualToDashboard;
  unpinVisualFromDashboard: typeof unpinVisualFromDashboard;
  clearReportsAndVisuals: typeof clearReportsAndVisuals;
  clearServerError: typeof clearServerError;
};

type IGetPinnedReportsSelectorProps = {
  loading: boolean;
  allReports: {
    items: {
      reports: ReportSummaryRes[];
      category: ReportCategory;
    }[];
    loading: boolean;
  };
  allBookmarks: BookmarkResultsPage;
  embedReportRes: ReportEmbedRes;
  pinToDashboardLoading: boolean;
  bookmarksLoading: boolean;
  captureBookmarkLoading: boolean;
  deleteBookmarkLoading: boolean;
  pinnedVisual: PinnedVisualRes;
  capturedBookmark: BookmarkRes;
  serverError: ServerError;
};

export type IGetPinnedReportsStateProps = IGetPinnedReportsSelectorProps &
  IGetPinnedReportsDispatchers;
