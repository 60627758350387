import { connect } from "react-redux";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import reducer from "./modules/reducer";
import saga from "./modules/saga";
import {
  fetchSMSTemplate,
  clearEditStatus,
  clearSMSTemplateError,
  updateSMSTemplate,
  createSMSTemplate,
  clearSMSTemplate,
  updateDirty,
  deleteSMSTemplate,
} from "./modules/actions";
import IApplicationState from "appRedux/types";

const mapStateToProps = (state: IApplicationState) => {
  return state.smsTemplate;
};

const mapDispatchToProps = {
  fetchSMSTemplate,
  clearEditStatus,
  clearSMSTemplateError,
  updateSMSTemplate,
  createSMSTemplate,
  clearSMSTemplate,
  updateDirty,
  deleteSMSTemplate,
  deleteTemplate: deleteSMSTemplate,
};

const withSaga = injectSaga({ key: "smsTemplate", saga });
const withReducer = injectReducer({ key: "smsTemplate", reducer });
export const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withSaga, withReducer, withConnect);
