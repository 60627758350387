import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const EmptyChart: FC<IN3oIconProps> = ({
  height = 106,
  width = 48,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 106 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M0 12.4783L20.5 23.5L43.5 11.4783L65.0252 18.1739L83 0.5L106 8.5V48H0V12.4783Z"
      fill={fill}
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="53"
        y1="-22"
        x2="53"
        y2="48"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#838599" />
        <stop offset="1" stopColor="#F0F0F5" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
