import React, { FC } from "react";

import {
  TemplateModelTypeResLookupResultsList,
  LookupType as TemplatesLookups,
} from "@n3oltd/karakoram.templates.sdk.lookups";
import { TemplateModelType } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { Select } from "antd";

import { TemplateModelTypesLookups } from "appRedux/modules/lookups/types";
import injectLookups from "appRedux/utils/injectLookups";

interface TemplateModelsSelectorProps {
  templateModelTypes?: TemplateModelTypeResLookupResultsList;
  onChange?: (id: TemplateModelType) => void;
  value?: TemplateModelType;
  fromOutboxes?: boolean;
}

const TemplateModelsSelector: FC<TemplateModelsSelectorProps> = ({
  templateModelTypes,
  onChange,
  value,
  fromOutboxes = false,
}) => {
  const loading = templateModelTypes.items.length === 0;

  const modelTypes = fromOutboxes
    ? templateModelTypes.items?.filter((t) => t.allowedForOutboxes)
    : templateModelTypes.items;

  return (
    <Select
      loading={loading}
      showArrow
      allowClear
      showSearch
      optionLabelProp={"children"}
      optionFilterProp={"children"}
      onChange={(id: TemplateModelType) => onChange?.(id)}
      value={value}
    >
      {modelTypes?.map?.((field) => (
        <Select.Option key={field.id} value={field.id}>
          {field.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default injectLookups(TemplateModelsSelector, [
  TemplatesLookups.TemplateModelTypes,
]);
