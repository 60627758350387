import React, { FC } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons/lib";
import { K2Button, K2Message } from "../../k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { FilterDropdownProps } from "antd/es/table/interface";

interface ColumnSearchProps extends FilterDropdownProps, InjectedK2IntlProps {
  placeholderFieldName: string;
}

const ColumnSearch: FC<ColumnSearchProps> = ({
  selectedKeys,
  placeholderFieldName,
  setSelectedKeys,
  clearFilters,
  confirm,
  k2Intl,
}) => {
  return (
    <div style={{ padding: 8, minWidth: 210 }}>
      <Input
        placeholder={k2Intl?.formatMessage({
          localeKey: "common.searchField",
          values: { field: placeholderFieldName },
        })}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => {
          confirm();
        }}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <K2Button
        type="primary"
        onClick={() => {
          // confirm sets the selected filters for the table, e.g. if searching for "Water" on the `name` column,
          // filters will be { name: ["Water"] }
          // In the parent component, listen for changes in the filters and re-fetch table items accordingly.
          // See DonationItemsList for an example.
          confirm();
        }}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        <SearchOutlined />
        <K2Message localeKey={"common.search"} />
      </K2Button>
      <K2Button
        onClick={() => {
          clearFilters();
        }}
        size="small"
        style={{ width: 90 }}
      >
        <K2Message localeKey={"common.reset"} />
      </K2Button>
    </div>
  );
};

export default injectK2Intl(ColumnSearch);
