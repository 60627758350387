import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { RcFile } from "antd/es/upload";

export enum ActionTypes {
  UPLOAD_ATTACHMENT = "@dashboard/newTask/upload-attachment/request",
  SET_UPLOAD_COMPLETE = "@dashboard/newTask/upload-attachment/success",
  SET_UPLOAD_ERROR = "@dashboard/newTask/upload-attachment/error",
  DELETE_ATTACHMENT = "@dashboard/newTask/delete-attachment/request",
  SET_ATTACHMENT_AS_UPLOADING = "@dashboard/newTask/set-attachment-uploading",
  DELETE_ALL_ATTACHMENTS = "@dashboard/newTask/delete-all-attachments",
  SET_FORM_TOUCHED = "@dashboard/newTask/set-form-touched",
  CLEAR_FORM_TOUCHED = "@dashboard/newTask/clear-form-touched",
}

// Action creator types

export interface UploadAttachmentAction {
  type: ActionTypes.UPLOAD_ATTACHMENT;
  uid: string;
  file: RcFile;
}

export interface SetUploadCompleteAction {
  type: ActionTypes.SET_UPLOAD_COMPLETE;
  uid: string;
  response: IApiResponse<string>;
}

export interface SetUploadErrorAction {
  type: ActionTypes.SET_UPLOAD_ERROR;
  uid: string;
}

export interface DeleteAttachmentAction {
  type: ActionTypes.DELETE_ATTACHMENT;
  uid: string;
}

export interface DeleteAllAttachments {
  type: ActionTypes.DELETE_ALL_ATTACHMENTS;
}

export interface SetAttachmentAsUploadingAction {
  type: ActionTypes.SET_ATTACHMENT_AS_UPLOADING;
  uid: string;
  file: RcFile;
}

export interface SetFormTouched {
  type: ActionTypes.SET_FORM_TOUCHED;
}

export interface ClearFormTouched {
  type: ActionTypes.CLEAR_FORM_TOUCHED;
}
