import React, { FC } from "react";
import { matchPath } from "react-router-dom";
import { K2Link, K2Message } from "components/k2Widgets";
import styled from "styled-components";

interface IMainNavLinkProps {
  route: string;
  currentPathname: string;
  routeKey: string;
}

interface ICurrentRoute {
  matched: boolean;
  exact: boolean;
}

const isCurrentRoute = (
  route: string,
  currentPathname: string,
): ICurrentRoute => {
  const matched = matchPath(currentPathname, {
    path: route,
    exact: false,
    strict: false,
  });

  return {
    matched: !!matched,
    exact: matched?.isExact,
  };
};

interface ILinkProps {
  routeKey: string;
}

const HeaderLink = styled.li<{ $active: boolean }>`
  color: ${({ theme, $active }) =>
    $active ? theme.primaryColor : theme.grey_7};
  font-size: small;
  cursor: ${({ $active }) => ($active ? "default" : "pointer")};
  margin-right: 16px;
  background: ${({ theme, $active }) => ($active ? theme.grey_2 : "none")};
  border-radius: 8px;
  font-weight: ${({ theme, $active }) =>
    $active ? theme.font_weight_heavy : theme.font_weight_bold};
  padding: 4px 10px;
  transition: 0.3s;

  a {
    display: block;
  }

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
    opacity: 0.75;
  }

  @media screen and (max-width: 991px) {
    span {
      color: ${({ theme, $active }) =>
        $active ? theme.grey_7 : theme.primaryColor} !important;
    }
    font-size: medium;
    margin-left: 12px;
    background: none;

    &:hover {
      color: ${({ theme, $active }) =>
        $active ? theme.grey_7 : theme.primaryColor};
      opacity: 1;
    }
  }
`;

const LinkBody: FC<ILinkProps> = ({ routeKey }) => {
  return (
    <span className={"link"}>
      <K2Message localeKey={routeKey} withoutSpan={true} />
    </span>
  );
};

const MainNavLink: FC<IMainNavLinkProps> = ({
  route,
  currentPathname,
  routeKey,
}) => {
  const userIsOnRoute = isCurrentRoute(route, currentPathname);
  return (
    <HeaderLink $active={userIsOnRoute?.matched}>
      {userIsOnRoute?.matched && userIsOnRoute?.exact ? (
        <LinkBody routeKey={routeKey} />
      ) : (
        <K2Link to={route}>
          <LinkBody routeKey={routeKey} />
        </K2Link>
      )}
    </HeaderLink>
  );
};

export default MainNavLink;
