import { IActivityState } from "./types";
import produce from "immer";
import { ActivitiesAction, ActionTypes } from "./actionTypes";

const initialState: IActivityState = {
  dashboardActivities: {
    data: {
      items: [],
    },
    fetching: false,
    error: null,
  },
};

const recentActivitiesReducer = (
  state: IActivityState = initialState,
  action: ActivitiesAction,
): IActivityState =>
  produce(state, (draft: IActivityState) => {
    switch (action.type) {
      case ActionTypes.FETCH_DASHBOARD_ACTIVITIES:
        draft.dashboardActivities.fetching = true;
        break;
      case ActionTypes.FETCH_DASHBOARD_ACTIVITIES_ERROR:
        draft.dashboardActivities.fetching = false;
        draft.dashboardActivities.error = action.error;
        break;
      case ActionTypes.FETCH_DASHBOARD_ACTIVITIES_SUCCESS:
        draft.dashboardActivities.fetching = false;
        draft.dashboardActivities.data = action.data;
        break;
      case ActionTypes.CLEAR_ERROR:
        draft.dashboardActivities.error = null;
        break;
    }
  });

export default recentActivitiesReducer;
