import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { FeedType } from "@n3oltd/karakoram.sponsorships.sdk.schemes/esm";
import { Col, Input, Radio, Row, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { GeneralFormItemV4 } from "components/formItems";
import {
  K2Checkbox,
  K2Message,
  K2MultiSelectV4Styled,
  K2RadioGroup,
} from "components/k2Widgets";
import RolesSelect from "components/selects/rolesSelect";
import { asyncComponent } from "components/utils";
import EmailCompositionSelector from "routes/admin/feedbacks/schemes/components/forms/EmailCompositionSelector";
import PdfCompositionSelector from "routes/admin/sponsorships/schemes/components/forms/PdfCompositionSelector";
import SchemeFormHelpers from "routes/admin/sponsorships/schemes/helpers";

import { ISchemeSetupFormProps, SchemeReqType } from "../../modules/types";

const { Button } = Radio;

const SchemeSetup: FC<ISchemeSetupFormProps> = (props) => {
  const {
    form,
    k2Intl,
    isLoading,
    beneficiaryAssignments,
    feedTypes,
    sponsorshipCommitmentDurations,
    initialFeedType,
  } = props;

  const formItemLayout = {
    labelCol: {
      xs: 12,
      sm: 12,
      md: 9,
      lg: 8,
      xl: 7,
      xxl: 4,
    },
    wrapperCol: {
      xs: 12,
      sm: 12,
      md: 15,
      lg: 16,
      xl: 17,
      xxl: 20,
    },
  };

  const [feedType, setFeedType] = useState<FeedType>(FeedType.Webhook);
  const [availableToAll, setAvailableToAll] = useState<boolean>(
    form?.getFieldValue(SchemeFormHelpers.accessToEveryOneFieldName),
  );

  const getAvailableToAll = useCallback(() => {
    return form?.getFieldValue(
      SchemeFormHelpers.accessToEveryOneFieldName,
    ) as boolean;
  }, [form]);

  useEffect(() => {
    setFeedType(initialFeedType);
  }, [initialFeedType, form]);

  useEffect(() => {
    if (feedType === FeedType.Webhook) {
      const initialVals = form.getFieldValue([
        "feed",
        "webhook",
        "ipWhitelist",
      ]);

      if (!initialVals?.length) {
        form.setFields([
          {
            name: [
              "feed",
              "webhook",
              "ipWhitelist",
            ],
            value: [""],
          },
        ]);
      }
    }
  }, [feedType, form]);

  const FeedTypeComponent = useMemo(() => {
    return feedType
      ? asyncComponent(
          () =>
            import(
              /* webpackChunkName: "SchemeFeedTypeComponent" */ `./feedType/${feedType}`
            ),
          false,
        )
      : null;
  }, [feedType]);

  /* TODO: This needs to be enabled in V2 */
  // const BeneficiaryComponent = useMemo(() => {
  //   return beneficiaryType
  //     ? asyncComponent(
  //         () =>
  //           import(
  //             /* webpackChunkName: "SchemeBeneficiaryComponent" */ `./beneficiary/${beneficiaryType}`
  //           ),
  //         false,
  //       )
  //     : null;
  // }, [beneficiaryType]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[12, 18]} className={"n3o-mb-0"}>
        <Col span={24}>
          <GeneralFormItemV4
            labelKey="common.name"
            name={SchemeFormHelpers.nameFieldName}
            required
          >
            <Input />
          </GeneralFormItemV4>
        </Col>
        <Col span={24}>
          <GeneralFormItemV4
            name={SchemeFormHelpers.beneficiaryAssignmentFieldName}
            labelKey={"admin.sponsorship.schemes.form.beneficiaryAssignment"}
          >
            <K2RadioGroup buttonStyle="solid" size="small">
              {beneficiaryAssignments?.items?.map?.((x, index) => (
                <Button key={"BeneficiaryAssignment_" + index} value={x.id}>
                  {x.name}
                </Button>
              ))}
            </K2RadioGroup>
          </GeneralFormItemV4>
        </Col>
        <FeedTypeComponent />
        <Col span={24}>
          <GeneralFormItemV4
            name={SchemeFormHelpers.accessToEveryOneFieldName}
            valuePropName={"checked"}
            {...formItemLayout}
          >
            <K2Checkbox
              checked={availableToAll}
              onChange={(e: CheckboxChangeEvent) =>
                setAvailableToAll(e.target.checked)
              }
            >
              <K2Message
                localeKey={
                  "admin.sponsorship.schemes.form.availableForAllUsers"
                }
              />
            </K2Checkbox>
          </GeneralFormItemV4>
        </Col>
        {!getAvailableToAll() && !availableToAll && (
          <Col span={24}>
            <RolesSelect
              availableToAllFieldName={
                SchemeFormHelpers.accessToEveryOneFieldName
              }
              form={form}
              k2Intl={k2Intl}
              name={SchemeFormHelpers.restrictEntryToFieldName}
            />
          </Col>
        )}
        <Col span={24}>
          <GeneralFormItemV4
            name={SchemeFormHelpers.durationsFieldName}
            labelKey={"admin.sponsorship.schemes.form.allowedDurations"}
            rules={[
              {
                required: true,
                message: (
                  <K2Message
                    localeKey={
                      "admin.sponsorship.schemes.form.allowedDurations.required"
                    }
                  />
                ),
                type: "array",
              },
            ]}
          >
            <K2MultiSelectV4Styled
              mode={"multiple"}
              dataSource={sponsorshipCommitmentDurations?.items}
              optionFilterProp={"children"}
              optionLabelProp={"children"}
              showArrow
            />
          </GeneralFormItemV4>
        </Col>
        <Col span={24}>
          <GeneralFormItemV4
            name={SchemeFormHelpers.includePhotosFieldName}
            valuePropName={"checked"}
            {...formItemLayout}
          >
            <K2Checkbox>
              <K2Message
                localeKey={"admin.sponsorship.schemes.form.includePhoto"}
              />
            </K2Checkbox>
          </GeneralFormItemV4>
        </Col>

        <Col span={24}>
          <GeneralFormItemV4
            labelKey={"admin.sponsorship.schemes.form.pdfComposition"}
            labelTooltipKey={
              "admin.sponsorship.schemes.form.pdfComposition.explanation"
            }
            name={SchemeFormHelpers.compositionIdFieldName}
          >
            <PdfCompositionSelector />
          </GeneralFormItemV4>
        </Col>

        <Col span={24}>
          <GeneralFormItemV4
            labelKey={"admin.feedback.schemes.form.pdfComposition"}
            labelTooltipKey={
              "admin.feedback.schemes.form.pdfComposition.explanation"
            }
            name={
              SchemeFormHelpers.deactivationNotificationsPdfCompositionFieldName
            }
          >
            <PdfCompositionSelector />
          </GeneralFormItemV4>
        </Col>
        <Col span={24}>
          <GeneralFormItemV4
            labelKey={"admin.feedback.schemes.form.emailComposition"}
            labelTooltipKey={
              "admin.feedback.schemes.form.emailComposition.explanation"
            }
            name={
              SchemeFormHelpers.deactivationNotificationsEmailCompositionIdFieldName
            }
          >
            <EmailCompositionSelector />
          </GeneralFormItemV4>
        </Col>
      </Row>
      {/* TODO: This needs to be enabled in V2 */}
      {/* {beneficiaryType && (
        <K2BorderedSection className={"n3o-mt-0 n3o-pb-2"}>
          <Row>
            <Col span={24}>
              <h2>
                <K2Message
                  localeKey={
                    "admin.sponsorship.schemes.form.schemeSettingsHeading"
                  }
                />
              </h2>
            </Col>
            <BeneficiaryComponent form={form} />
          </Row>
        </K2BorderedSection>
      )} */}
    </Spin>
  );
};

export default SchemeSetup;
