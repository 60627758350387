import React, { FC } from "react";

import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { K2Routes } from "appRedux/models/routes/RouteModel";
import K2Breadcrumb from "components/breadcrumb";
import { asyncComponent } from "components/utils";
import { RequireAuth } from "containers/app/views";

const DonationsDashboardWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "DonationsDashboard" */ "./dashboard"),
  ),
  "giving",
);

const CreateDonationWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "DonationCreate" */ "./createDonation"),
  ),
  "enterDonation",
);

const SearchDonationsWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "DonationSearch" */ "./search"),
  ),
  "searchDonations",
);

const ViewDonationWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "DonationView" */ "./viewDonation"),
  ),
  "viewDonation",
);

const CreateAnonymousPledgeWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "AnonymousPledgeCreate" */ "./pledges/createAnonymousPledge"
      ),
  ),
  "createAnonymousPledge",
);

const CreatePledgeWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "PledgeCreate" */ "./pledges/createPledge"),
  ),
  "createPledge",
);

const SearchPledgesWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "PledgesSearch" */ "./pledges/search"),
  ),
  "searchPledges",
);

const SearchGivingsWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "GivingsSearch" */ "./giving/search"),
  ),
  "searchGivings",
);

const ViewPledgeWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "ViewPledge" */ "./pledges/viewPledge"),
  ),
  "viewPledge",
);

const {
  main: { giving },
  enterDonation,
  searchDonations,
  viewDonation,
  createPledge,
  createAnonymousPledge,
  searchPledges,
  searchGivings,
  viewPledge,
} = K2Routes;

const DonationsRoutes: FC<RouteComponentProps> = () => (
  <>
    <K2Breadcrumb />
    <Switch>
      <Route exact path={giving} component={DonationsDashboardWithAuth} />
      <Route exact path={enterDonation} component={CreateDonationWithAuth} />
      <Route exact path={searchDonations} component={SearchDonationsWithAuth} />
      <Route exact path={viewDonation} component={ViewDonationWithAuth} />
      <Route
        exact
        path={createAnonymousPledge}
        component={CreateAnonymousPledgeWithAuth}
      />
      <Route exact path={createPledge} component={CreatePledgeWithAuth} />
      <Route exact path={searchPledges} component={SearchPledgesWithAuth} />
      <Route exact path={searchGivings} component={SearchGivingsWithAuth} />
      <Route exact path={viewPledge} component={ViewPledgeWithAuth} />
      <Redirect to={giving} />
    </Switch>
  </>
);

export default RequireAuth(DonationsRoutes, "giving");
