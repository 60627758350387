import produce from "immer";
import { ActionTypes } from "containers/searchActivities/modules/actionTypes";
import {
  IActivitiesAdvancedSearchState,
  SearchActivitiesAction,
} from "containers/searchActivities/modules/types";

const initialState: IActivitiesAdvancedSearchState = {
  fetching: false,
  results: {
    continuationToken: "",
    items: [],
  },
  serverError: null,
};

const ActivitiesSearchReducer = (
  state: IActivitiesAdvancedSearchState = initialState,
  action: SearchActivitiesAction,
): IActivitiesAdvancedSearchState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SEARCH_ACTIVITIES:
        draft.fetching = true;
        break;
      case ActionTypes.SEARCH_ACTIVITIES_COMPLETE:
        draft.results.continuationToken = action.results?.continuationToken;
        draft.results.items = action.loadMore
          ? state.results.items.concat(action.results?.items)
          : action.results?.items;
        draft.fetching = false;
        draft.serverError = action.serverError;
        break;
      case ActionTypes.CLEAR_SERVER_ERROR:
        draft.serverError = null;
        break;
      case ActionTypes.CLEAR_SEARCH_ACTIVITIES:
        draft.fetching = false;
        draft.results = {
          continuationToken: "",
          items: [],
        };
        draft.serverError = null;
        break;
    }
  });

export default ActivitiesSearchReducer;
