import React, { FC, useCallback, useEffect, useState } from "react";

import {
  FragmentSummaryRes,
  FragmentType,
  TextMatchingMode,
} from "@n3oltd/karakoram.templates.sdk.fragments/esm";
import { FragmentSummaryResultsPage } from "@n3oltd/karakoram.templates.sdk.fragments/src/index";
import { CompositionType } from "@n3oltd/karakoram.templates.sdk.pdf";
import { Select } from "antd";

import { _fragmentsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { TEMPLATE_ENGINE } from "constants/appConstants";

interface LayoutSelectorProps {
  onChange?: (id: string) => void;
  value?: string;
  k2Intl: InjectedK2Intl;
  entity: CompositionType;
  scopeId?: string;
}

const LayoutSelector: FC<LayoutSelectorProps> = ({
  onChange,
  value,
  k2Intl,
  entity,
  scopeId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [layouts, setLayouts] = useState<FragmentSummaryRes[]>([]);

  const fetchLayouts = useCallback(
    async (searchTerm: string) => {
      const resp: IApiResponse<FragmentSummaryResultsPage> = await K2RestService.toResponse(
        _fragmentsClient.findFragment(TEMPLATE_ENGINE, scopeId, {
          name: searchTerm
            ? {
                mode: TextMatchingMode.Contains,
                value: searchTerm,
              }
            : null,
          type: FragmentType.Layout,
          compositionType: entity,
        }),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        setLayouts(resp.getResultOrDefault().items);
      }

      setLoading(false);
    },
    [k2Intl, entity, scopeId],
  );

  useEffect(() => {
    fetchLayouts(searchTerm);
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <Select
      showArrow
      allowClear
      showSearch
      optionLabelProp={"children"}
      optionFilterProp={"children"}
      onSearch={(v: string) => setSearchTerm(v)}
      loading={loading}
      onChange={(e: string) => onChange?.(e)}
      value={value}
    >
      {layouts?.map?.((layout) => (
        <Select.Option key={layout.id} value={layout.id}>
          {layout.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LayoutSelector;
