import React, { FC } from "react";

import { Alert, Col, Row } from "antd";
import styled from "styled-components";

import { N3oIcon } from "components/n3oIcon";
import { IN3OIconType } from "components/n3oIcon/types";

interface IProps {
  icontype: string;
  title: string;
  description?: string;
  onSelect: () => void;
  warning?: string;
  selected?: boolean;
  disabled?: boolean;
}

interface ContainerProps {
  $selected: boolean;
  $disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  border: 1px solid
    ${({ theme, $selected }) => ($selected ? theme.primary_6 : theme.grey_4)};
  color: ${({ theme, $selected, $disabled }) =>
    $selected ? theme.primary_6 : $disabled ? theme.grey_6 : ""};
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.grey_2 : "transparent"};
  h4 {
    color: ${({ theme, $selected, $disabled }) =>
      $selected ? theme.primary_6 : $disabled ? theme.grey_6 : ""};
  }
  &:hover {
    background-color: ${({ theme, $disabled }) =>
      $disabled ? "transparent" : theme.grey_2};
  }

  box-shadow: ${({ theme }) => theme.shadow_thin};
  margin: 12px 5px;
  border-radius: 8px;
`;

const IconCol = styled.div<{ $disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  .circle {
    flex-shrink: 0;
    margin: 24px;
    border-radius: 50%;
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.grey_2 : theme.primary_2};
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      fill: ${({ theme, $disabled }) =>
        $disabled ? theme.grey_6 : theme.primaryColor};
    }
    span span {
      line-height: 0;
    }
  }
`;

const WarningAlert = styled(Alert)`
  && {
    border: none;
    border-radius: 0 0 8px 8px;
  }
`;

const TitleDescriptionAndIconListItem: FC<IProps> = ({
  onSelect,
  icontype,
  title,
  description,
  selected,
  disabled,
  warning,
}) => {
  return (
    <Container
      onClick={disabled ? undefined : onSelect}
      $disabled={disabled || false}
      $selected={selected || false}
    >
      <Row>
        <Col xs={6} sm={4}>
          <IconCol $disabled={disabled || false}>
            <div className="circle">
              <N3oIcon
                icontype={icontype as IN3OIconType}
                width="28px"
                height="28px"
              />
            </div>
          </IconCol>
        </Col>
        <Col xs={18} sm={20}>
          <div style={{ paddingTop: 24 }}>
            <h4>{title}</h4>
            {description && <p>{description}</p>}
          </div>
        </Col>
      </Row>
      {warning && (
        <WarningAlert message={warning} showIcon={true} type={"warning"} />
      )}
    </Container>
  );
};

export default TitleDescriptionAndIconListItem;
