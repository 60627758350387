import React from "react";

import { Form } from "antd";
import { FormInstance, Rule } from "antd/es/form";
import { omit } from "lodash";

import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import {
  MultiSelectorDataItem,
  MultiSelectorProps,
} from "components/k2Widgets/multiSelector/types";

import { MultiSelector } from "../k2Widgets";
import {
  InjectedK2Intl,
  InjectedK2IntlProps,
} from "../k2Widgets/k2Localizations/types";

export interface IMultiSelectorItemProps extends MultiSelectorProps {
  /**antd form form parent component props */
  form: FormInstance<any>;
  /**name of the field for data binding */
  fieldName: string;
  /**data source, {idField: string, nameField: string}[] */
  dataSource: MultiSelectorDataItem[];
  /**locale key for label */
  labelKey?: string;
  /**required */
  required?: boolean;
  /**Form.Item layout object */
  formItemLayout?: { wrapperCol: Object; labelCol?: Object };
  /**React.ReactNode for label */
  label?: React.ReactNode;
  /**Whether to display colon after label */
  colon?: boolean;

  onSelectionChange?: (keys: string[]) => void;
}

const errorMessageKey = "common.requiredValidationError";
const getLocale = (k2Intl: InjectedK2Intl) => {
  return {
    itemUnit: k2Intl?.formatMessage({ localeKey: "common.selected" }),
    itemsUnit: k2Intl?.formatMessage({ localeKey: "common.available" }),
    notFoundContent: k2Intl?.formatMessage({ localeKey: "common.notFound" }),
    searchPlaceholder: k2Intl?.formatMessage({ localeKey: "common.search" }),
  };
};

const MultiSelectorItem = ({
  form,
  dataSource,
  labelKey,
  fieldName,
  initialValues,
  required,
  k2Intl,
  formItemLayout,
  colon,
  onSelectionChange,
  ...multiSelectorProps
}: IMultiSelectorItemProps & InjectedK2IntlProps) => {
  if (!form || !dataSource || !fieldName) {
    throw new Error(
      "'form', 'dataSource' and 'fieldName' are required for the component to render!",
    );
  }

  const filterOption = (inputValue, option) =>
    option.title.toLowerCase().indexOf(inputValue) > -1;

  let locale = getLocale(k2Intl);
  let label: string = k2Intl?.formatMessage({ localeKey: labelKey });

  let validationRules: Rule[] = [];
  if (required) {
    validationRules.push({
      required: true,
      message: k2Intl?.formatMessage({
        localeKey: errorMessageKey,
        values: { fieldName: label || fieldName },
      }),
      type: "array",
    });
  }

  if (!initialValues) {
    initialValues = [];
  }

  const onChange = (targetKeys) => {
    let obj = {};
    obj[fieldName] = targetKeys;
    form?.setFieldsValue(obj);
    if (onSelectionChange) {
      onSelectionChange(targetKeys);
    }
  };

  return (
    <Form.Item
      {...formItemLayout}
      label={multiSelectorProps.label || label}
      colon={colon}
      name={fieldName}
      initialValue={initialValues}
      valuePropName={"targetKeys"}
      rules={validationRules}
      trigger={"onBlur"}
    >
      <MultiSelector
        dataSource={dataSource}
        initialValues={initialValues}
        onChange={onChange}
        {...omit(multiSelectorProps, ["localizationSettings"])}
      />
    </Form.Item>
  );
};

export default injectK2Intl(MultiSelectorItem);
