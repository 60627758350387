import React, { FC, ReactEventHandler } from "react";
import { Form } from "antd";
import { ActionsItemLayout } from "constants/FormLayouts";
import { K2Message, K2Button } from "../k2Widgets";
import { SizeType } from "antd/es/config-provider/SizeContext";
import styled from "styled-components";

export const ActionFormItemsContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${({ align }) =>
    align === "left" ? "flex-start" : "flex-end"};
  button:first-of-type {
    margin-right: 10px;
  }
  margin-bottom: 20px;
`;

interface ContainerProps {
  align?: "left" | "right";
}

interface ActionsFormItemProps extends ContainerProps {
  isLoading: boolean;
  disabled?: boolean;
  cancelUrl?: string;
  onSaveClick?: ReactEventHandler;
  type?: "button" | "submit";
  saveLocalKey?: string;
  spanClassName?: string;
  block?: boolean;
  icon?: React.ReactNode;
  size?: SizeType;
}

const ActionsFormItemV4: FC<ActionsFormItemProps> = ({
  cancelUrl,
  isLoading,
  disabled,
  type,
  saveLocalKey,
  spanClassName,
  block,
  icon,
  size,
  align,
  onSaveClick,
}) => (
  <ActionFormItemsContainer align={align || "left"}>
    <Form.Item {...ActionsItemLayout} className="n3o-text-right">
      {cancelUrl && (
        <div style={{ marginRight: 10, display: "inline-block" }}>
          <K2Button toUrl={cancelUrl} type="default" disabled={isLoading}>
            <K2Message
              localeKey="common.cancel"
              spanClassName={spanClassName}
            />
          </K2Button>
        </div>
      )}
      <K2Button
        type="primary"
        htmlType={type || "button"}
        loading={isLoading}
        disabled={disabled}
        block={block}
        size={size}
        icon={icon}
        onClick={onSaveClick}
      >
        <K2Message
          spanClassName={spanClassName}
          localeKey={saveLocalKey ? saveLocalKey : "common.save"}
        />
      </K2Button>
    </Form.Item>
  </ActionFormItemsContainer>
);

export default ActionsFormItemV4;
