import {
  DashboardActivityLogCriteria,
  DashboardActivityLogResultsList,
} from "@n3oltd/karakoram.activities.sdk.activities/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";

export enum ActionTypes {
  FETCH_DASHBOARD_ACTIVITIES = "@engage/dashboard/recent-activities/fetch",
  FETCH_DASHBOARD_ACTIVITIES_SUCCESS = "@engage/dashboard/recent-activities/success",
  FETCH_DASHBOARD_ACTIVITIES_ERROR = "@engage/dashboard/recent-activities/error",
  CLEAR_ERROR = "@engage/dashboard/recent-activities/clear-error",
}

export interface FetchDashboardActivities {
  type: ActionTypes.FETCH_DASHBOARD_ACTIVITIES;
  criteria: DashboardActivityLogCriteria;
}

export interface FetchDashboardActivitiesSuccess {
  type: ActionTypes.FETCH_DASHBOARD_ACTIVITIES_SUCCESS;
  data: DashboardActivityLogResultsList;
}

export interface FetchDashboardActivitiesError {
  type: ActionTypes.FETCH_DASHBOARD_ACTIVITIES_ERROR;
  error: ServerError;
}

export interface ClearError {
  type: ActionTypes.CLEAR_ERROR;
}

export type ActivitiesAction =
  | FetchDashboardActivities
  | FetchDashboardActivitiesSuccess
  | FetchDashboardActivitiesError
  | ClearError;
