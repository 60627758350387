import React, { FC, useMemo, useState } from "react";

import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { AgentRes, NextForDialerRes } from "@n3oltd/k2.calls.sdk.agents/esm";
import { Button, Select } from "antd";
import styled from "styled-components";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";

interface ReadyForCallProps {
  agent: AgentRes;
  k2Intl?: InjectedK2Intl;
  updatingAgent: boolean;
  getNextRecord: () => void;
  onUpdateTeamAndCampaign: (
    agentId: string,
    teamId: string,
    campaignId: string,
  ) => void;
  isDataExhausted: boolean;
  setNextRecord: (value: NextForDialerRes) => void;
}

const GreenButton = styled(Button)`
  margin-top: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.green_6} !important;
  border: none !important;
  color: ${({ theme }) => theme.white_color} !important;

  &:hover {
    opacity: 0.7;
  }
`;

const GrayedButton = styled(GreenButton)`
  background-color: ${({ theme }) => theme.grey_3} !important;
  color: ${({ theme }) => theme.grey_6} !important;
  &:hover {
    opacity: 1;
  }
`;

const TeamCampaignChooser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-top: 0px;
`;

const Warning = styled.div`
  display: flex;
  font-size: 12px;
  .anticon {
    margin-top: 5px;
    margin-right: 10px;
  }
`;

const TeamCampaignSelectArea = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const FullWidth = { width: "100%" };

const ReadyForCall: FC<ReadyForCallProps> = ({
  agent,
  k2Intl,
  updatingAgent,
  onUpdateTeamAndCampaign,
  getNextRecord,
  isDataExhausted,
  setNextRecord,
}) => {
  const { theme } = useAppContext();
  const [agentSelectedTeamId, setAgentSelectedTeamId] = useState<string>(
    agent.team?.id,
  );
  const [
    agentSelectedcampaignId,
    setAgentSelectedcampaignId,
  ] = useState<string>(agent?.campaign?.id);
  const needsToSelectTeamAndCampaign =
    !agentSelectedTeamId || !agentSelectedcampaignId;

  const availableCampaignsList = useMemo(() => {
    return agentSelectedTeamId
      ? agent?.availableCampaigns?.filter((x) =>
          x.teamIds?.some((teamId) => teamId === agentSelectedTeamId),
        )
      : [];
  }, [agent, agentSelectedTeamId]);

  return (
    <TeamCampaignChooser>
      <TeamCampaignSelectArea>
        <div>
          <Select
            notFoundContent={
              <K2Message localeKey={"communication.cc.noTeam"} />
            }
            showSearch
            allowClear={true}
            showArrow
            placeholder={k2Intl.formatMessage({
              localeKey: "communication.cc.team.select",
            })}
            optionFilterProp={"children"}
            onChange={(e: string) => {
              setAgentSelectedTeamId(e);
              setAgentSelectedcampaignId(null);
            }}
            value={agentSelectedTeamId}
            style={FullWidth}
          >
            {agent.teamMemberships.map((team) => (
              <Select.Option key={team.team.id} value={team.team.id}>
                {team.team.name}
              </Select.Option>
            ))}
          </Select>
          <br />
          <br />
          <Select
            notFoundContent={
              <K2Message localeKey={"communication.cc.noCampaign"} />
            }
            showSearch
            allowClear={true}
            showArrow
            placeholder={k2Intl.formatMessage({
              localeKey: "communication.cc.campaign.select",
            })}
            value={agentSelectedcampaignId}
            optionFilterProp={"children"}
            onChange={(e: string) => {
              setAgentSelectedcampaignId(e);
              if (agentSelectedTeamId && e) {
                onUpdateTeamAndCampaign(agent.id, agentSelectedTeamId, e);
                setNextRecord(null);
              }
            }}
            style={FullWidth}
          >
            {availableCampaignsList.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>

          {!isDataExhausted && (
            <GreenButton
              onClick={() => getNextRecord()}
              htmlType={"submit"}
              loading={updatingAgent}
              type={"primary"}
              disabled={updatingAgent || needsToSelectTeamAndCampaign}
              size="large"
              style={{
                marginBottom: "30px",
              }}
            >
              <K2Message localeKey={"communication.cc.dialler.ready"} />
            </GreenButton>
          )}

          {isDataExhausted && (
            <GrayedButton size="large" disabled>
              <K2Message localeKey={"communication.cc.dialler.outOfData"} />
            </GrayedButton>
          )}
        </div>
      </TeamCampaignSelectArea>
      {isDataExhausted && (
        <Warning>
          <ExclamationCircleTwoTone twoToneColor={theme.warning_color} />
          <div>
            <K2Message localeKey={"communication.cc.dialler.outOfData.alert"} />
          </div>
        </Warning>
      )}
    </TeamCampaignChooser>
  );
};

export default injectK2Intl(ReadyForCall);
