import React, { FC, useCallback, useEffect, useState } from "react";

import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons/lib";
import {
  TaskStatus,
  TaskSummaryResultsPage,
} from "@n3oltd/karakoram.tasks.sdk.tasks";
import { Col, Divider, Spin } from "antd";
import styled from "styled-components";

import { _tasksClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { StringHelpers } from "common/helpers/strings";
import TaskListItem, {
  TaskRow,
} from "components/dashboard/tasksList/components/item";
import { K2Tooltip } from "components/k2Widgets";
import PaginationFooter from "components/k2Widgets/k2Grid/PaginationFooter";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

const TitleSection = styled.div`
  flex-grow: 1;
  text-align: center;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font_weight_bold};
  color: ${({ theme }) => theme.grey_7};
  .innerTitle {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-badge {
      margin-left: 8px;
    }
  }
`;

const TaskCol = styled(Col)`
  && {
    padding: 6px 0;
    display: flex;
    align-items: center;
  }
`;

const ExpandSection = styled.div`
  width: 40px;
  cursor: pointer;
  text-align: right;
  .anticon {
    margin: 10px;
  }
  &:hover {
    .anticon {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const TaskCountBadge = styled.span`
  && {
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.grey_6};
    color: ${({ theme }) => theme.grey_6};
    padding: 0 9px;
    margin-left: 8px;
  }
`;

const GreyDivider = styled(Divider)`
  margin: 0;
  background: ${({ theme }) => theme.grey_3};
`;

const LoadMoreRow = styled.div``;

interface ExpandableTaskGroupRowProps {
  listId: string;
  listTitle: string;
  k2Intl: InjectedK2Intl;
  searchValue: string;
  showDividerAfter: boolean;
}

const ExpandableTaskGroupRow: FC<ExpandableTaskGroupRowProps> = ({
  listId,
  listTitle,
  k2Intl,
  searchValue,
  showDividerAfter,
}) => {
  const [listTasks, setListTasks] = useState<TaskSummaryResultsPage>({
    items: [],
    continuationToken: "",
  });
  const [listTasksLoading, setListTasksLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(false);

  const fetchTasksForList = useCallback(
    async (listId: string, continuationToken?: string) => {
      setListTasksLoading(true);

      const resp: IApiResponse<TaskSummaryResultsPage> = await K2RestService.toResponse(
        _tasksClient.find({
          continuationToken,
          listId,
          status: [TaskStatus.Pending],
        }),
      );

      if (!resp.error) {
        if (continuationToken) {
          setListTasks({
            items: [...listTasks.items, ...resp.getResultOrDefault().items],
            continuationToken: resp.getResultOrDefault().continuationToken,
          });
        } else {
          setListTasks(resp.getResultOrDefault());
        }
      } else {
        UIUtils.handleServerError(k2Intl, resp.error);
      }

      setListTasksLoading(false);
    },
    [k2Intl, listTasks],
  );

  useEffect(() => {
    fetchTasksForList(listId);

    // eslint-disable-next-line
  }, [listId]);

  const showLoadMore = !!listTasks.continuationToken;

  return (
    <div>
      <TaskRow>
        <TaskCol span={24}>
          <TitleSection>
            <K2Tooltip
              titleKey={"tasks.relatedToList"}
              titleValues={{ listName: listTitle }}
            >
              <span className={"innerTitle"}>
                {StringHelpers.truncate(listTitle, 40)}
                {!listTasksLoading && (
                  <TaskCountBadge>
                    {listTasks.items.length}
                    {listTasks.continuationToken ? "+" : ""}{" "}
                    {k2Intl.formatMessage({
                      localeKey:
                        listTasks.items.length === 1
                          ? "tasks.task"
                          : "tasks.tasks",
                    })}
                  </TaskCountBadge>
                )}
              </span>
            </K2Tooltip>
          </TitleSection>
          <ExpandSection onClick={() => setExpanded(!expanded)}>
            {expanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
          </ExpandSection>
        </TaskCol>
      </TaskRow>

      <GreyDivider />

      {expanded && (
        <div>
          <Spin spinning={listTasksLoading}>
            {listTasks.items.map((task, i) => {
              return (
                <div key={task.id}>
                  <TaskListItem
                    isAutoGenerated={true}
                    task={task}
                    isOverdue={task.isOverdue}
                    searchValue={searchValue}
                    showEditOptions={true}
                    onCancelEdit={() => {
                      fetchTasksForList(listId);
                    }}
                  />
                  {(i !== listTasks.items.length - 1 ||
                    showDividerAfter ||
                    showLoadMore) && <GreyDivider />}
                </div>
              );
            })}
            {showLoadMore && (
              <>
                <LoadMoreRow>
                  <PaginationFooter
                    onLoadMore={() =>
                      fetchTasksForList(listId, listTasks.continuationToken)
                    }
                  />
                </LoadMoreRow>
                {showDividerAfter && <GreyDivider />}
              </>
            )}
          </Spin>
        </div>
      )}
    </div>
  );
};

export default ExpandableTaskGroupRow;
