import styled from "styled-components";
import { Layout } from "antd";

const { Sider } = Layout;

export const RouteHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-size: 12px;
    margin-bottom: -10px;
  }
`;

export const BookmarkCollapseWrapper = styled.div`
  cursor: pointer;
`;

export const BookmarksWrapper = styled.div`
  margin-right: 15px;
`;

export const SiderWrapper = styled(Sider)`
  border-right: 1px solid rgb(179, 176, 173);
  transition: unset !important;

  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;

  .ant-layout-sider-children {
    > .bookmark_collapse_wrapper {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 10px;

      .bookmark_text_with_icon {
        display: flex;
        align-items: center;

        .bookmark_text {
          font-size: 16px;
          font-weight: 600;
          color: rgb(37, 36, 35);
          font-family: "Segoe UI", wf_segoe-ui_normal, helvetica, arial,
            sans-serif;
        }
      }
    }
  }

  &.ant-layout-sider-collapsed {
    flex: 0 0 32px !important;
    max-width: 32px !important;
    min-width: 32px !important;
    width: 32px !important;

    .ant-layout-sider-children {
      > .bookmark_collapse_wrapper {
        flex-direction: column;
        margin-top: 20px;

        .bookmark_text_with_icon {
          flex-direction: column;
          justify-content: center;
          margin-top: 30px;

          .bookmark_text {
            transform: rotate(270deg);
            margin-top: 40px;
          }
        }
      }
    }
  }
`;

export const CaptureBookmarkButton = styled.div`
  border: 1px dashed ${({ theme }) => theme.grey_5};
  border-radius: ${({ theme }) => theme.btn_border_radius_sm};
`;
