export class StringHelpers {
  public static truncate(s: string, maxLength: number): string {
    if (!s || s?.length <= maxLength) return s;
    else return s?.slice(0, maxLength) + "...";
  }
  public static compareStringsIgnoreWhitespaceAndCase(
    str1: string,
    str2: string,
  ): boolean {
    // Normalize and remove whitespace
    const normalizedStr1 = str1.replace(/\s+/g, "").toLowerCase();
    const normalizedStr2 = str2.replace(/\s+/g, "").toLowerCase();

    // Compare normalized strings
    return normalizedStr1 === normalizedStr2;
  }
}
