import React, { FC, useEffect, useState } from "react";

import { NamedLookupRes } from "@n3oltd/k2.subscriptions.sdk.lookups/esm";
import { Col, Empty, Form, Input, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { usePrevious } from "hooks";
import _ from "lodash";

import { GeneralFormItemV4 } from "components/formItems";
import { K2Button, K2Card, K2Message, K2SelectV4 } from "components/k2Widgets";
import { GenericObject } from "components/utils/Utils";

export interface HeadersFields {
  clock: string;
}

interface IProps {
  isSubscription?: boolean;
  activeTabKey: string;
  apiUrls: GenericObject[];
  helpers: NamedLookupRes[];
  finalHelperData: Object;
  noDataHelpers: boolean;
  loadingHelpers: boolean;
  helperId: string;

  setHelperId: (helperId: string) => void;
  setFinalHelperData?: (data: Object) => void;
  onHelpersDataServiceChangeHandler: (service: string) => Promise<void>;
  onHelperRun: (values: HeadersFields) => Promise<void>;
}

const Helpers: FC<IProps> = (props) => {
  const {
    isSubscription,
    activeTabKey,
    apiUrls,
    helpers,
    finalHelperData,
    noDataHelpers,
    loadingHelpers,
    helperId,

    setHelperId,
    setFinalHelperData,
    onHelpersDataServiceChangeHandler,
    onHelperRun,
  } = props;

  const [serviceApiCalled, setServiceApiCalled] = useState<boolean>(false);
  const prevActiveTabKey = usePrevious(activeTabKey);

  useEffect(() => {
    if (
      (isSubscription && !serviceApiCalled) ||
      (prevActiveTabKey !== activeTabKey && activeTabKey === "1")
    ) {
      onHelpersDataServiceChangeHandler(
        apiUrls.find((x) => x.id === "subscriptions").id,
      );
      setServiceApiCalled(true);
    }
  }, [
    activeTabKey,
    apiUrls,
    isSubscription,
    onHelpersDataServiceChangeHandler,
    prevActiveTabKey,
    serviceApiCalled,
  ]);

  const [form] = useForm();

  return (
    <Form
      form={form}
      onFinish={(values) => {
        onHelperRun(values);
      }}
    >
      <Row gutter={[16, 16]} className={"n3o-px-1"}>
        {!isSubscription && (
          <Col span={24}>
            <K2SelectV4
              placeholderKey={"admin.testing.selectService"}
              dataSource={apiUrls.filter((x) => x.id !== "subscriptions")}
              idField={"id"}
              nameField={"name"}
              style={{ width: "100%" }}
              onChange={onHelpersDataServiceChangeHandler}
              focusInputOnFocus
              showSearch
              showArrow
            />
          </Col>
        )}
        {helpers?.length > 0 && (
          <Col span={24}>
            <K2SelectV4
              placeholderKey={"admin.testing.selectHelper"}
              dataSource={helpers}
              style={{ width: "100%" }}
              onChange={(value) => {
                setHelperId(value as string);
                setFinalHelperData?.({});
              }}
              showArrow
              focusInputOnFocus
              showSearch
              allowClear
            />
          </Col>
        )}

        <Col span={24}>
          <GeneralFormItemV4
            name={"clock"}
            label={"N3O-Clock-Adjustment Header"}
            labelCol={{ xs: 24 }}
            wrapperCol={{ xs: 24 }}
            extra={"Optional"}
          >
            <Input />
          </GeneralFormItemV4>
        </Col>

        {helperId && (
          <Col span={24}>
            <p>
              Helper ID: <pre>{helperId}</pre>
            </p>
          </Col>
        )}

        {!_.isNil(finalHelperData) && !_.isEmpty(finalHelperData) && (
          <Col span={24}>
            <K2Card
              bordered={false}
              shadow={true}
              localeKey={"common.helperResponse"}
            >
              <code>
                <pre className={"n3o-mb-0"}>
                  {JSON.stringify(finalHelperData, null, 4)}
                </pre>
              </code>
            </K2Card>
          </Col>
        )}
        {noDataHelpers && (
          <Col span={24}>
            <Empty
              description={<K2Message localeKey={"common.noRecordsFound"} />}
            />
          </Col>
        )}
        <Col span={24}>
          <K2Button
            loading={loadingHelpers}
            disabled={_.isEmpty(helperId)}
            htmlType={"submit"}
            localeKey={"common.run"}
            type={"primary"}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default Helpers;
