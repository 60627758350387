import AFormatProvider, { IK2FormatConfig } from "./AFormatProvider";

const numberFormats: IK2FormatConfig[] = [
  { key: "international", culture: "en-US" },
  { key: "eu1", culture: "fr" },
  { key: "eu2", culture: "de-DE" },
];

export default class NumberFormatProvider extends AFormatProvider<
  number,
  IK2IntlNumberFormatOptions
> {
  formatNative(formatConfig: IK2FormatConfig, value: number) {
    const df = Intl.NumberFormat(
      formatConfig.culture,
      this.getOptions(formatConfig.options),
    );
    return df.format(value);
  }

  formatIntl(formatConfig: IK2FormatConfig, value: number) {
    return this.intl.formatNumber(value, this.getOptions(formatConfig.options));
  }

  getFormatConfig(key: string) {
    return NumberFormatProvider.getConfig(key);
  }

  public static getConfig(formatKey: string) {
    if (!formatKey) return numberFormats[0];
    return numberFormats.find(
      (d) => d.key.toLowerCase() === formatKey.toLowerCase(),
    );
  }
}

export interface IK2IntlNumberFormatOptions extends Intl.NumberFormatOptions {
  numberFormat?: string;
}
