import React, { FC, useMemo } from "react";

import { useGetIsProdEnvironment, useRouter } from "hooks";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Redirect, Route, Switch, matchPath } from "react-router-dom";

import AppManager from "appRedux/AppManager";
import LocalizationHelpers from "appRedux/models/localization/helpers";
import {
  K2Routes,
  keysHiddenFromProduction,
} from "appRedux/models/routes/K2Routes";
import { FlatRoutes as Q1FlatRoutes } from "appRedux/models/routes/Q1RouteModel";
import { FlatRoutes as K2FlatRoutes } from "appRedux/models/routes/RouteModel";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { useEnvironmentContext } from "common/contexts/EnvironmentProvider/EnvironmentProvider";
import Dashboard from "components/dashboard";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { MainPageWrapper } from "components/layout/wrappers";
import MainApp from "containers/app/views/MainApp";
import NewVersion from "containers/otherPages/NewVersion";
import useWindowWidth from "hooks/useWindowWidth";

import _404Error from "../components/errors/404";
import { asyncComponent } from "../components/utils";
import { RequireAuth } from "../containers/app/views";
import Activities from "../containers/searchActivities";
import AccountsSubRouter from "./accounts";
import Admin from "./admin";
import AnalyticsSubRouter from "./analytics";
import CallbackSubRouter from "./callback";
import CommsSubRouter from "./communications";
import DonationsSubRouter from "./donations";
import PaymentsSubRouter from "./donations/payments";
import ErrorsSubRouter from "./errors";
import FeedbacksSubRouter from "./feedbacks";
import Preferences from "./preferences";
import SponsorshipsSubRouter from "./sponsorships";
import TasksSubRouter from "./tasks";
import Temp from "./temp";

const {
  main: {
    dashboard,
    accounts,
    sponsorships,
    data,
    analytics,
    admin,
    giving,
    communications,
    feedbacks,
  },
  payments,
  errors: { error },
  tasks,
  recentActivities,
  preferences,
  temporary,
  newVersion,
} = K2Routes;

const DataWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "DataDashboard" */ "./data"),
  ),
  "data",
);

interface IProps extends InjectedK2IntlProps, RouteComponentProps {}

const Routes: FC<IProps> = (props) => {
  const { location, k2Intl } = props;
  const { pathname } = location;

  const { appId } = useAppContext();
  const { region } = useEnvironmentContext();
  const isProd = useGetIsProdEnvironment();

  const router = useRouter();
  const width: number = useWindowWidth();

  const isOnDashboard =
    location.pathname === K2FlatRoutes.dashboard ||
    location.pathname === Q1FlatRoutes.q1Dashboard;

  const pageTitle = useMemo(() => {
    const appName = AppManager.getAppName();
    const route = Object.entries(
      appId === "k2" ? K2FlatRoutes : Q1FlatRoutes,
    ).find((r) => {
      if (
        matchPath(pathname, {
          path: r[1],
          exact: true,
          strict: false,
        })
      )
        return r;
      else return null;
    });

    if (
      keysHiddenFromProduction.includes(route?.[0] as keyof typeof K2Routes) &&
      isProd
    ) {
      router.replace(K2Routes.errors._404);
    }

    return route?.[0]
      ? k2Intl.formatMessage({ localeKey: route[0] }) + " - " + appName
      : appName;
  }, [pathname, appId, k2Intl, router, isProd]);

  const users = useSelector(
    (state: IApplicationState) => state.subscription.users,
  );
  const currentUser = users.k2User;

  // TODO: This needs to be done once, after the app is wrapped in IntlProvider, but this is not the best place for it.
  const currentUserLocalizationSettings = users.currentUserLocalizationSettings;

  moment.defineLocale(
    currentUserLocalizationSettings.language,
    LocalizationHelpers.getRelativeTimes(
      k2Intl,
      currentUserLocalizationSettings.language,
    ),
  );
  moment.defineLocale(
    `${currentUserLocalizationSettings.language}-short`,
    LocalizationHelpers.getRelativeShortTimes(
      k2Intl,
      currentUserLocalizationSettings.language,
    ),
  );

  moment.locale(currentUserLocalizationSettings.language);

  return (
    <Switch>
      <Route path={K2Routes.baseRoute}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Switch>
          {/* Full Page Components without the usual Navigation etc. */}
          <Route
            path={`/${region}/engage/callbacks`}
            component={CallbackSubRouter}
          />
          <Route path={newVersion} exact component={NewVersion} />

          <Route path={"/*"}>
            <MainApp>
              <MainPageWrapper
                id={"route-section"}
                $width={width}
                $appId={appId}
                $isOnDashboard={isOnDashboard}
              >
                <Switch>
                  <Route path={dashboard} component={Dashboard} />

                  <Route path={accounts} component={AccountsSubRouter} />

                  <Route
                    path={sponsorships}
                    component={SponsorshipsSubRouter}
                  />

                  <Route path={analytics} component={AnalyticsSubRouter} />

                  <Route path={payments} component={PaymentsSubRouter} />

                  <Route
                    path={admin}
                    render={(routeProps) => (
                      <Admin
                        {...routeProps}
                        {...props}
                        currentUser={currentUser}
                      />
                    )}
                  />

                  <Route path={tasks} component={TasksSubRouter} />

                  <Route path={recentActivities} component={Activities} />

                  <Route path={preferences} component={Preferences} />

                  <Route path={data} component={DataWithAuth} />

                  <Route path={communications} component={CommsSubRouter} />

                  <Route path={giving} component={DonationsSubRouter} />

                  <Route path={feedbacks} component={FeedbacksSubRouter} />

                  <Route path={error} component={ErrorsSubRouter} />

                  <Route path={temporary} component={Temp} />

                  <Redirect exact from={`/${region}/engage`} to={dashboard} />

                  <Route path={`/${region}/engage/*`} component={_404Error} />
                </Switch>
              </MainPageWrapper>
            </MainApp>
          </Route>
        </Switch>
      </Route>

      <Redirect exact from={`/${region}`} to={dashboard} />

      {!router.location.pathname.includes("/tally") && (
        <MainApp>
          <MainPageWrapper
            $width={width}
            id={"route-section"}
            $appId={appId}
            $isOnDashboard={isOnDashboard}
          >
            <Route path={"*"} component={_404Error} />
          </MainPageWrapper>
        </MainApp>
      )}
    </Switch>
  );
};

export default injectK2Intl(withRouter(Routes));
