import { K2RouteKeys } from "appRedux/models/routes/RouteModel";
import { Q1RouteKeys } from "appRedux/models/routes/Q1RouteModel";

interface QuickCreateLink {
  linkKey: K2RouteKeys | Q1RouteKeys;
  titleKey: string;
  icon: string;
  hideFromProd?: boolean;
}

export const q1QuickCreateLinks: QuickCreateLink[] = [];

export const k2QuickCreateLinks: QuickCreateLink[] = [
  {
    linkKey: "createAccount",
    titleKey: "account.createAccount",
    icon: "n3o-account-create",
  },
  {
    linkKey: "enterDonation",
    titleKey: "donation.enterDonation",
    icon: "n3o-enter-donation",
  },
  {
    linkKey: "createAnonymousPledge",
    titleKey: "pledges.create.pledge",
    icon: "n3o-pledge",
  },
  {
    linkKey: "createAnonymousSponsorship",
    titleKey: "sponsorships.createSponsorship",
    icon: "n3o-sponsorship-create",
  },
];
