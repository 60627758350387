import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const PledgeSearchIcon: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4324 11.4324C12.467 10.3979 13.8702 9.81665 15.3333 9.81665C16.7964 9.81665 18.1996 10.3979 19.2342 11.4324C20.2688 12.467 20.85 13.8702 20.85 15.3333C20.85 16.51 20.4741 17.648 19.7887 18.5866L22.601 21.3991C22.933 21.7311 22.933 22.2693 22.601 22.6012C22.269 22.9331 21.7309 22.9331 21.3989 22.6012L18.5866 19.7887C17.648 20.4741 16.51 20.85 15.3333 20.85C13.8702 20.85 12.467 20.2688 11.4324 19.2342C10.3979 18.1996 9.81665 16.7964 9.81665 15.3333C9.81665 13.8702 10.3979 12.467 11.4324 11.4324ZM15.3333 11.5167C14.3211 11.5167 13.3503 11.9188 12.6345 12.6345C11.9188 13.3503 11.5167 14.3211 11.5167 15.3333C11.5167 16.3456 11.9188 17.3164 12.6345 18.0321C13.3503 18.7479 14.3211 19.15 15.3333 19.15C16.3456 19.15 17.3164 18.7479 18.0321 18.0321C18.7479 17.3164 19.15 16.3456 19.15 15.3333C19.15 14.3211 18.7479 13.3503 18.0321 12.6345C17.3164 11.9188 16.3456 11.5167 15.3333 11.5167Z"
      fill={fill}
    />
    <path
      d="M11.125 2.625C11.125 2.14175 11.5168 1.75 12 1.75C12.4832 1.75 12.875 2.14175 12.875 2.625V4.29741C14.558 4.48723 16.1911 5.22765 17.4817 6.51825C18.0787 7.11521 18.5579 7.78545 18.9194 8.5C18.055 8.1 16.7758 7.83713 16.2443 7.7557C15.2953 6.80669 14.108 6.24221 12.875 6.06185V7.625C12.875 8.10825 12.4832 8.5 12 8.5C11.5168 8.5 11.125 8.10825 11.125 7.625V6.06232C9.89316 6.2432 8.70715 6.80752 7.75897 7.75569C6.80996 8.70472 6.24548 9.89199 6.06512 11.125H7.625C8.10825 11.125 8.5 11.5168 8.5 12C8.5 12.4832 8.10825 12.875 7.625 12.875H6.0656C6.24648 14.1068 6.8108 15.2928 7.75897 16.241C7.75897 17.3959 8.06809 18.1274 8.44291 18.8853C7.75097 18.5275 7.10165 18.0586 6.52153 17.4784C5.23176 16.1887 4.49146 14.5568 4.30106 12.875H2.625C2.14175 12.875 1.75 12.4832 1.75 12C1.75 11.5168 2.14175 11.125 2.625 11.125H4.30069C4.4905 9.44202 5.23092 7.80888 6.52153 6.51826C7.81131 5.22848 9.44317 4.48818 11.125 4.29778V2.625Z"
      fill={fill}
    />
  </svg>
);
