import React, { Component, ComponentType, FunctionComponent } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import K2IntlProvider from "appRedux/models/localization/K2IntlProvider";
import SubscriptionSelectors from "appRedux/store/selectors/SubscriptionSelectors";
import { IAppSettingsProps } from "appRedux/store/types/SubscriptionTypes";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

export default function injectK2Intl<P extends InjectedK2IntlProps>(
  WrappedComponent: ComponentType<P>,
) {
  let InjectedAppSettingsComponent;
  if (WrappedComponent instanceof Component) {
    InjectedAppSettingsComponent = injectK2AppSettings(
      class K2IntlInject extends Component<P & IAppSettingsProps> {
        render() {
          return (
            <K2IntlInjector
              WrappedComponent={WrappedComponent}
              {...this.props}
            />
          );
        }
      },
    );
  } else {
    const K2IntlInject: ComponentType<P & IAppSettingsProps> = (props) => {
      return <K2IntlInjector WrappedComponent={WrappedComponent} {...props} />;
    };
    InjectedAppSettingsComponent = injectK2AppSettings(K2IntlInject);
  }

  return class K2IntlInjectComponent extends Component<P> {
    render() {
      return <InjectedAppSettingsComponent {...this.props} />;
    }
  };
}

export function injectK2AppSettings<P extends IAppSettingsProps>(
  WrappedComponent: ComponentType<P>,
) {
  return connect(
    SubscriptionSelectors.getAppSettingsSelector(),
    {},
    // @ts-ignore
  )(WrappedComponent);
}

class FormatterInject extends Component<FormatterInjectProps> {
  render() {
    const { WrappedComponent, intl, appSettings, ...rest } = this.props;
    return (
      <WrappedComponent
        k2Intl={new K2IntlProvider(appSettings?.localizationKeys, intl)}
        {...rest}
      />
    );
  }
}

const K2IntlInjector = injectIntl(FormatterInject);

export { K2IntlInjector };

type FormatterInjectProps = IAppSettingsProps &
  InjectedIntlProps & {
    WrappedComponent: ComponentType<any> | FunctionComponent<any>;
  };
