import React, { FC, useCallback, useEffect, useState } from "react";

import { PinnedVisualEmbedRes } from "@n3oltd/karakoram.analytics.sdk.reports/esm";
import { Divider, Spin } from "antd";
import { usePrevious, useWindowWidth } from "hooks";
import _ from "lodash";
import * as pbi from "powerbi-client";
import { TokenType } from "powerbi-models";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import AnalyticsSelectors, {
  IGetPinnedReportsStateProps,
} from "appRedux/store/selectors/AnalyticsSelectors";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import PowerBiEmbedded from "components/powerBiEmbedded";
import { showConfirm } from "components/utils/Confirmation";
import { MOBILE_SIZE } from "constants/ThemeSetting";

import { K2Card } from "../../k2Widgets";

export const VisualFooter = styled.div`
  text-align: center;
  margin: 0 -24px;

  .ant-divider {
    margin: 0.5rem 0;
  }
`;

export const VisualTitle = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.grey_6};
  opacity: 0.67;
  padding: 0 24px;
`;

export const StatisticsCardWrapper = styled(K2Card)`
  background: #fff;
  border-radius: 8px;

  .ant-card-body {
    padding: 24px 24px 10px;
    border-radius: 8px;
  }
`;

interface IProps extends InjectedK2IntlProps, IGetPinnedReportsStateProps {
  visual: PinnedVisualEmbedRes;
  unpinnedVisualId?: string;
  unPinningVisual?: boolean;
  setUnPinningVisual?: (unpin: boolean) => void;
}

const SingleVisual: FC<IProps> = (props) => {
  const {
    visual,
    k2Intl,
    unpinnedVisualId,
    unPinningVisual,
    setUnPinningVisual,
    unpinVisualFromDashboard,
  } = props;

  const unpinCommandName = "unpinFromDashboard";
  const gotoReportCommandName = "gotoReport";

  const width: number = useWindowWidth();
  const isMobileScreen = width <= MOBILE_SIZE;

  const [gotoReportRedirect, setGotoReportRedirect] = useState<boolean>(false);
  const [embeddedVisual, setEmbeddedReport] = useState<pbi.Visual>(null);

  const prevEmbeddedVisual = usePrevious(embeddedVisual);

  const commandTriggered = useCallback(
    (command: pbi.service.ICustomEvent<any>) => {
      const reportDetails = command.detail;

      if (reportDetails?.command === gotoReportCommandName) {
        setGotoReportRedirect(true);
      } else if (reportDetails?.command === unpinCommandName) {
        showConfirm({
          titleKey: "common.areYouSure",
          contentKey: "reports.unpinFromDashboard.title",
          contentKeyValues: {
            visual: visual?.title,
          },
          onOk: async () => {
            setUnPinningVisual(true);
            unpinVisualFromDashboard(visual?.revisionId);
          },
          okTextKey: "reports.unpinToDashboard.unpin",
          okButtonProps: { danger: true },
          k2Intl,
        });
      }
    },
    [k2Intl, setUnPinningVisual, unpinVisualFromDashboard, visual],
  );

  useEffect(() => {
    if (prevEmbeddedVisual !== embeddedVisual && embeddedVisual !== null) {
      embeddedVisual.on("commandTriggered", (command) =>
        commandTriggered(command),
      );

      let filters = JSON.parse(visual?.filtersJson) as Array<any>;
      if (filters?.length > 0) {
        embeddedVisual.on("loaded", function () {
          filters = filters?.filter((x) => !_.isNil(x.target));

          embeddedVisual.setFilters(filters).catch((errors) => {
            console.error(errors);
          });
        });
      }
    }
  }, [commandTriggered, embeddedVisual, prevEmbeddedVisual, visual]);

  const unpinFromDashboardText = k2Intl?.formatMessage({
    localeKey: "reports.unpinFromDashboard",
  });

  const gotoReportText = k2Intl?.formatMessage({
    localeKey: "reports.gotoReport",
  });

  if (gotoReportRedirect) {
    return (
      <Redirect
        to={K2Routes.embedReport
          .replace(":category", visual.category)
          .replace(":reportId", visual.reportId)}
      />
    );
  }

  return (
    <StatisticsCardWrapper className="n3o-card-statistics">
      <Spin
        spinning={unPinningVisual && unpinnedVisualId === visual?.revisionId}
      >
        <PowerBiEmbedded
          key={visual.reportConfig.id}
          embedType={"visual"}
          mobile={isMobileScreen}
          tokenType={TokenType.Embed}
          accessToken={`${visual.reportConfig.accessToken}`}
          embedUrl={`${visual.reportConfig.embedUrl}`}
          id={`${visual.reportConfig.id}`}
          filterPaneEnabled={true}
          navContentPaneEnabled={true}
          pageName={`${visual.pageName}`}
          visualName={`${visual.visualName}`}
          style={{ height: !isMobileScreen ? 325 : 250 }}
          onEmbedded={setEmbeddedReport}
          extensions={[
            {
              command: {
                name: unpinCommandName,
                title: unpinFromDashboardText,
                icon:
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHCSURBVHgB1VRNboJAFJ6ZmhiINYAxQbrBG3CD6g28Qbvsri67A2+gJ7A3aDyB3oB4AtnVFTWpCXbD9L0ALRYGRm2a9EuU+fne983MezOE/DU0gDy362iaaVdxWL5jGJbLmOLXBSXi5oCxhs8Y3UD7TsqAkJjCnw1BSxmTBDwA/rPI5CrfiaL9SlFaYEJHlNJRs9laHA77XVmgqrZRcBDHZEwpWQN/CvwA+GtSB8MwPcPocfhtynaCR5nMm97PmKrjkjIpE/81E0ysSByhab1HXe/5iUnXwTEqYwI0F5pBHPMhfO3dbrsq8izgcC/rA7cPvKBRvhITK2mcEqeMcVyLi9WVmgjE+QSSvsIxFCfiVVt+slVznh3HOTkRAsSXEDgvGp+WEyEwGEw2sibkHJxiUqVzJZrAG6yq1zY0HxSlbUfR+yKbO+XGCw2SyiBPadfJm8AO3Dg+zChtfNSZMLH4d00DZpzzQe64+vDqemG49bA0ScUDyerF+SQMX8dgMEQTyMsLrPo2m5UxORJPk8fLyi9NPNwRsyBUm/hOx7yvEpdBpQkOXiJeYuJ/DWY3UtetN3gRR+RCoImm3ThHg4WB/4JPypDersTCG/gAAAAASUVORK5CYII=",
                extend: {
                  visualContextMenu: {
                    title: unpinFromDashboardText,
                  },
                  visualOptionsMenu: {
                    title: unpinFromDashboardText,
                  },
                },
              },
            },
            {
              command: {
                name: gotoReportCommandName,
                title: gotoReportText,
                icon:
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAElSURBVHgB3ZXfbcIwEMa/OxggslQJ+tRuwAjpBh0hI7BB2aDqBMAG3aDZoN2gPPKEMoGv54IQGDuY4wl+kuUo9+dzzj4HuHUIRpwbzXI279F23boNz0OYobechVnC1J4IVNXoKRWgq1nF77yX5yM5ojkR6thvL+Dc+F2nKTLL7RN17nEOSC1CP0QySQooryFOHT9wAbvkjY6liCz0S75yAv8L22zWMxiSa1yjJa5jH4aROHnOzySQTz7oeoLGv2GgILn6ifbBImUPJ7FSTALnkqcoLtH2GJ+veUxxJ3vPS2ZPmnwKC6V7kI4NpQsbf8oVd9EhUucs5j4o5fYF4j3QFnmY9HZkAr1FK71FuyIB5uE3LkZUBKteAf2BvDCjgRGRwSfukj+VW3CJNKO4YQAAAABJRU5ErkJggg==",
                extend: {
                  visualContextMenu: {
                    title: gotoReportText,
                  },
                  visualOptionsMenu: {
                    title: gotoReportText,
                  },
                },
              },
            },
          ]}
        />
        <VisualFooter>
          <Divider />
          <VisualTitle>{visual.title}</VisualTitle>
        </VisualFooter>
      </Spin>
    </StatisticsCardWrapper>
  );
};

export default injectK2Intl(
  connect(
    AnalyticsSelectors.getPinnedReportsSelector(),
    AnalyticsSelectors.getPinnedReportsDispatchers(),
  )(SingleVisual),
);
