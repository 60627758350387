import { useCallback, useEffect, useState } from "react";

import { _correspondenceClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { CorrespondenceResultsPage, CorrespondenceSummaryRes } from "@n3oltd/k2.communications.sdk.correspondence";

export default function useFindCorrespondence(
    k2Intl: InjectedK2Intl,
    entityId: string,
): {
    correspondence: CorrespondenceSummaryRes[];
    loading: boolean;
    errorLoading:boolean;
    refetchCorrespondence: () => void;
} {
    const [correspondence, setCorrespondence] = useState<CorrespondenceSummaryRes[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorLoading,setErrorLoading] = useState<boolean>(false);

    const findCorrespondence = useCallback(
        async (entityId: string) => {
            setLoading(true);
            const response: IApiResponse<CorrespondenceResultsPage> = await K2RestService.toResponse(
                _correspondenceClient.find({
                    entityId:entityId
                }),
            );
            if (!response.error) {
                setCorrespondence(response.getResultOrDefault().items);
                setErrorLoading(false);
            } else if (response.error) {
                UIUtils.handleServerError(k2Intl, response.error);
                setErrorLoading(true);
            }
            setLoading(false);
        },
        //TODO: Remove afer refactoring/updating  k2Intl and UIUtils
        // eslint-disable-next-line
        [],
    );

    const refetchCorrespondence = () => {
        findCorrespondence(entityId);
    };
    useEffect(() => {
        if (entityId) {
            findCorrespondence(entityId);
        }
    }, [entityId, findCorrespondence]);

    return {
        correspondence,
        loading,
        errorLoading,
        refetchCorrespondence,
    };
}
