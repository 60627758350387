import React, { FC, useEffect } from "react";

import { connect } from "react-redux";
import { Switch } from "react-router";
import { Route } from "react-router-dom";

import AuthManager from "appRedux/models/auth/AuthManager";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import AuthSelectors, {
  IK2ViewStateProps,
} from "appRedux/store/selectors/AuthSelectors";
import ErrorAccessRevoked from "components/errors/accessRevoked";

import { IAuth0 } from "./Auth0Wrapper";
import K2SubscriptionView from "./K2SubscriptionView";

interface IProps extends IK2ViewStateProps {
  auth0?: IAuth0;
  appInitialized: boolean;
}

const SubscriptionsHandler: FC<IProps> = (props) => {
  const {
    auth0,
    appInitialized,
    userSignedIn,
    usersManagement: { isLoading },
  } = props;

  useEffect(() => {
    if (appInitialized) {
      AuthManager.initialize(auth0).then((res) => {
        if (res) userSignedIn();
      });
    }
  }, [userSignedIn, appInitialized, auth0]);

  return (
    <Switch>
      <Route
        exact
        path={K2Routes.errors.accessRevoked}
        component={ErrorAccessRevoked}
      />
      <Route
        exact
        path={Q1Routes.errors.accessRevoked}
        component={ErrorAccessRevoked}
      />

      <Route path={"/"}>
        <K2SubscriptionView authenticated={appInitialized && !isLoading} />
      </Route>
    </Switch>
  );
};

export default connect(
  AuthSelectors.getK2ViewSelector(),
  AuthSelectors.getK2ViewDispatchers(),
)(SubscriptionsHandler);
