import React, { FC } from "react";
import ReactHtmlParser from "react-html-parser";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import {
  IK2MessageProps,
  InjectedK2IntlProps,
} from "components/k2Widgets/k2Localizations/types";

const K2Message: FC<IK2MessageProps> = (props) => {
  return <K2MessageInternal {...props} />;
};

const K2MessageInternal = injectK2Intl(
  (props: IK2MessageProps & InjectedK2IntlProps) => {
    const {
      withoutSpan,
      spanClassName,
      k2Intl,
      localizationSettings,
      children,
      style,
      ...k2MessageProps
    } = props;

    let renderedMessage = k2Intl?.formatHtmlMessage({
      ...k2MessageProps,
      withoutSpan,
    });

    // UPDATING THE LOCALIZED STRING FOR TESTING PURPOSES
    if (localizationSettings?.language === "xx") {
      renderedMessage = "__" + renderedMessage + "__";
    }

    if (withoutSpan) {
      return (
        <>
          {ReactHtmlParser(renderedMessage)}
          {children}
        </>
      );
    } else {
      return (
        <span className={spanClassName} style={style}>
          {ReactHtmlParser(renderedMessage)}
          {children}
        </span>
      );
    }
  },
);

export default K2Message;
