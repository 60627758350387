import AFormatProvider, { IK2FormatConfig } from "./AFormatProvider";

const timeFormats: IK2FormatConfig[] = [
  {
    key: "12",
    culture: "en-GB",
    timeFormat: "hh:mm:ss",
    timeFormatDisplay: "hh:mm A",
    options: { hour: "numeric", minute: "numeric", hour12: true },
  },
  {
    key: "24",
    culture: "en-US",
    timeFormat: "HH:mm:ss",
    timeFormatDisplay: "HH:mm",
    options: { hour: "numeric", minute: "numeric", hour12: false },
  },
];

export default class TimeFormatProvider extends AFormatProvider<
  number | Date,
  IK2IntlTimeFormatOptions
> {
  static serverTimeFormat: string = "HH:mm:ss";
  static defaultTimeFormat: string = "12";

  private static handleTimeHacks(value: string) {
    // TODO: It's hack, to convert 0:32 AM to 12:32 AM, update react-intl and fix this issue
    if (value[0] === "0" && value[1] === ":") {
      return `12${value.substring(1)}`;
    }

    // TODO: There is an issue with react-intl whereby 00:00 is set to 24:00. This is to do with
    // the `hourCycle` property and I believe has been fixed in a newer version of the package.
    // This hack fixes this issue but react-intl should ideally be updated.
    // Ref: https://github.com/formatjs/formatjs/issues/1577
    if (/^24:/.test(value)) {
      return `00${value.substring(2)}`;
    }

    return value;
  }

  formatNative(formatConfig: IK2FormatConfig, date: number | Date) {
    const df = Intl.DateTimeFormat(
      formatConfig.culture,
      this.getOptions(formatConfig.options),
    );
    const value = df.format(new Date(date));

    return TimeFormatProvider.handleTimeHacks(value);
  }

  formatIntl(formatConfig: IK2FormatConfig, date: number | Date) {
    const value = this.intl.formatTime(
      date,
      this.getOptions(formatConfig.options),
    );

    return TimeFormatProvider.handleTimeHacks(value);
  }

  getFormatConfig(key: string) {
    return TimeFormatProvider.getConfig(key);
  }

  public static getConfig(formatKey: string) {
    return timeFormats.find((d) => d.key === formatKey);
  }
}

export interface IK2IntlTimeFormatOptions extends Intl.DateTimeFormatOptions {
  timeFormat?: string;
}
