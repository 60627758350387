import React, { FC } from "react";

import { FileTextOutlined, MailOutlined } from "@ant-design/icons";
import { CustomCorrespondenceLinkRes } from "@n3oltd/k2.communications.sdk.data-entry";
import { useRouter } from "hooks";
import styled from "styled-components";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import { K2Message, K2Modal } from "components/k2Widgets";
import TitleDescriptionAndIconListItem from "components/lists/listItems/TitleDescriptionAndIconListItem";

const CorrespondenceButton = styled.button`
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;
  gap: 8px;

  background-color: ${({ theme }) => theme.white_color};
  border: 1px solid ${({ theme }) => theme.grey_4};
  border-radius: ${({ theme }) => theme.border_radius_base};
  font-size: ${({ theme }) => theme.h6_font_size};
  transition: ${({ theme }) => theme.transition_speed_default};

  & > span:first-child {
    font-size: 22px;
  }

  & > span:last-child {
    color: ${({ theme }) => theme.grey_6};
    margin-top: auto;
  }

  strong {
    font-weight: ${({ theme }) => theme.font_weight_bold};
    margin: 10px 0;
  }

  &:hover {
    border-color: ${({ theme }) => theme.primaryColor};

    strong {
      color: ${({ theme }) => theme.primaryColor};
    }
  }

  &:hover > span:first-child {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const CorrespondenceButtonContainer = styled.div`
display: flex;
flex-direction: row,
padding: 24px;
gap: 16px;
justify-content: flex-start;
flex-wrap: wrap;
`;

interface SendCorrespondenceProps {
  setCorrespondenceModalOpen: (value: boolean) => void;
  setDisplayCustomCorrespondence: (value: boolean) => void;
  customCorrespondenceLinks: CustomCorrespondenceLinkRes[];
  displayCustomCorrespondence: boolean;
  accountId: string;
}

const modalStyle = { minHeight: "180px" };
const bodyStyle = { paddingTop: "40px" };
const SendCorrespondence: FC<SendCorrespondenceProps> = (props) => {
  const {
    accountId,
    setCorrespondenceModalOpen,
    customCorrespondenceLinks,
    displayCustomCorrespondence,
    setDisplayCustomCorrespondence,
  } = props;

  const router = useRouter();

  return (
    <K2Modal
      titleKey={
        displayCustomCorrespondence
          ? "accounts.view.profile.selectCustom"
          : undefined
      }
      visible={true}
      width={"50%"}
      onCancel={() => setCorrespondenceModalOpen(false)}
      hideFooter
      style={modalStyle}
      bodyStyle={bodyStyle}
      withOutScroll={!displayCustomCorrespondence}
    >
      {displayCustomCorrespondence ? (
        <>
          {customCorrespondenceLinks?.map((item, i) => (
            <TitleDescriptionAndIconListItem
              key={i}
              icontype={item.icon}
              title={item.name}
              onSelect={() => {
                window.open(item.url);
              }}
              description={item.description}
            />
          ))}
        </>
      ) : (
        <CorrespondenceButtonContainer>
          <CorrespondenceButton
            type="button"
            onClick={() => {
              router.history.push(
                `${K2Routes.sendFromOutboxes}?accountId=${accountId}`,
              );
              setCorrespondenceModalOpen(false);
              setDisplayCustomCorrespondence(false);
            }}
          >
            <span>
              <MailOutlined />
            </span>
            <strong>
              <K2Message localeKey="accounts.view.profile.sendFromOutboxes" />
            </strong>
            <span>
              <K2Message localeKey="accounts.view.profile.chooseExistingOutbox" />
            </span>
          </CorrespondenceButton>
          <CorrespondenceButton
            type="button"
            onClick={() => setDisplayCustomCorrespondence(true)}
          >
            <span>
              <FileTextOutlined />
            </span>
            <strong>
              <K2Message localeKey="accounts.view.profile.sendCustom" />
            </strong>
            <span>
              <K2Message localeKey="accounts.view.profile.chooseExistingUmbraco" />
            </span>
          </CorrespondenceButton>
        </CorrespondenceButtonContainer>
      )}
    </K2Modal>
  );
};

export default SendCorrespondence;
