import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import {
  AssetTypesLookups,
  TemplateEnginesLookups,
} from "appRedux/modules/lookups/types";
import IApplicationState from "appRedux/types";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

import {
  clearEditStatus,
  clearPartialOrLayout,
  clearPartialOrLayoutError,
  clearTemporaryAssets,
  createPartialOrLayout,
  deletePartialOrLayout,
  fetchPartialOrLayout,
  removeTemporaryAsset,
  updateDirty,
  updatePartialOrLayout,
  updateTemporaryAssets,
  uploadAsset,
} from "./modules/actions";
import reducer from "./modules/reducer";
import saga from "./modules/saga";

const mapStateToProps = (state: IApplicationState) => {
  return state.partialOrLayout;
};

const mapDispatchToProps = {
  fetchPartialOrLayout,
  clearPartialOrLayout,
  clearPartialOrLayoutError,
  createPartialOrLayout,
  updatePartialOrLayout,
  clearEditStatus,
  updateTemporaryAssets,
  uploadAsset,
  removeTemporaryAsset,
  clearTemporaryAssets,
  updateDirty,
  deletePartialOrLayout,
};

const withSaga = injectSaga({ key: "partialOrLayout", saga });
const withReducer = injectReducer({ key: "partialOrLayout", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof withConnect>;

export type PartialOrLayoutProps = PropsFromRedux &
  InjectedK2IntlProps &
  RouteComponentProps<{ layoutId?: string; partialId?: string }> & {
    assetTypes: AssetTypesLookups;
    templateEngines: TemplateEnginesLookups;
  };

export default compose(withSaga, withReducer, withConnect);
