import styled from "styled-components";

const FileList = styled.div`
  border: 1px solid ${({ theme }) => theme.grey_4};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 20px;
  h3 {
    font-weight: ${({ theme }) => theme.font_weight_bold};
    font-size: 14px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .add-button {
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
  }
`;

export default FileList;
