import { useDispatch } from "react-redux";
import { BreadcrumbConfig } from "appRedux/modules/breadcrumbs/types";
import { setBreadcrumbs } from "appRedux/modules/breadcrumbs/actions";
import { useCallback } from "react";

function useBreadcrumb() {
  const dispatch = useDispatch();
  return useCallback(
    (crumbs: BreadcrumbConfig[]) => {
      dispatch(setBreadcrumbs(crumbs));
    },
    [dispatch],
  );
}

export default useBreadcrumb;
