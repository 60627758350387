import { RouteRes } from "@n3oltd/karakoram.navigation.sdk.routes/src/index";
import { notification } from "antd";
import { LOCATION_CHANGE } from "connected-react-router";
import _ from "lodash";
import { matchPath } from "react-router-dom";
import { all, put, takeEvery } from "redux-saga/effects";

import AppManager from "appRedux/AppManager";
import AuthManager from "appRedux/models/auth/AuthManager";
import { _routesClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { FlatRoutes as Q1FlatRoutes } from "appRedux/models/routes/Q1RouteModel";
import { FlatRoutes as K2FlatRoutes } from "appRedux/models/routes/RouteModel";

import { ILocationChangeAction } from "./action";

function* onLocationChange(values: ILocationChangeAction) {
  // Mark route as visited
  try {
    const {
      payload: {
        location: { pathname },
      },
    } = values;
    const appId = AppManager.getAppId();
    const subscriptionId = AuthManager.getSubscriptionId();

    const route = Object.entries(
      appId === "k2" ? K2FlatRoutes : Q1FlatRoutes,
    ).find((r) => {
      if (
        matchPath(pathname, {
          path: r[1],
          exact: true,
          strict: false,
        })
      )
        return r;
      else return null;
    });

    if (route && _routesClient) {
      if (!_.isNil(subscriptionId) && !_.isEmpty(subscriptionId)) {
        const visitedResponse: IApiResponse<RouteRes> = yield K2RestService.toResponse(
          _routesClient.visited(route[0]),
        );
      }
    }
  } catch (error) {
    console.log("error", error);
  }

  // Destroy any notifications
  notification.destroy();
}

export default function* watcherSaga() {
  yield all([takeEvery(LOCATION_CHANGE, onLocationChange)]);
}
