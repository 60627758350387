import { PaymentProcessor } from "@n3oltd/k2.donations.sdk.donations";

import { SearchEntityType } from "components/navigation/mainAppHeader/globalSearch/modules/types";

export const TALLY_PERMISSION = "tallyAccess";

export const API_VERSION = "v1.0";

export const TEMPLATE_ENGINE = "handlebars";

export const REDIRECT_AFTER_SAVE_MS = 1000;

export const DEFAULT_GLOBAL_SEARCH_OPTIONS: {
  [key: string]: SearchEntityType;
} = {
  q1: "payments",
  k2: "accounts",
};

export const MAX_ACTIVITIES_TO_SHOW = 8;

export const DEFAULT_DELAY = 500;

export const DEFAULT_PAGE_SIZE = 25;

export const API_POOL_DELAY = 3000;

export const LIST_PAGE_SIZE = 10;

export const NOTIFICATION_DURATION = 100;

export const PINNED_MEASURE_LENGTH = 4;

export const PAYMENT_PROCESSORS_USING_DD: PaymentProcessor[] = [
  PaymentProcessor.SmartDebit,
  PaymentProcessor.DirectDebitEU,
  PaymentProcessor.DirectDebitUK,
  PaymentProcessor.DirectDebitCA,
  PaymentProcessor.NetcashDebitOrder,
];

export const MINIMUM_REFUND_AMOUNT = 0.01;
