import React, { Ref, forwardRef } from "react";

import { Carousel, CarouselProps } from "antd";
import { CarouselRef } from "antd/lib/carousel";

export interface IK2CarouselProps extends CarouselProps {}

const K2Carousel = forwardRef(
  (props: IK2CarouselProps, ref: Ref<CarouselRef>) => {
    const { children, ...rest } = props;
    return (
      <Carousel ref={ref} {...rest}>
        {children}
      </Carousel>
    );
  },
);

export default K2Carousel;
