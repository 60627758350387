import React, { PureComponent } from "react";

import { Currency } from "@n3oltd/k2.donations.sdk.giving/esm";
import { Form } from "antd";
import { FormInstance, FormItemProps } from "antd/es/form";
import { NamePath } from "antd/es/form/interface";
import { Rule } from "antd/lib/form";
import _ from "lodash";

import { errorKeys } from "appRedux/models/common/Constants";
import { FieldErrorsList } from "appRedux/modules/sharedTypes";
import LabelWithHelp from "components/forms/labelHelp/LabelHelp";
import { K2MessageValue } from "components/k2Widgets/k2Localizations/types";

import K2CurrencyInput from "../k2Widgets/K2CurrencyInput";
import K2InputV4 from "../k2Widgets/k2Inputs/K2InputV4";
import K2NumberInput from "../k2Widgets/k2Inputs/K2NumberInput";
import injectK2Intl from "../k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "../k2Widgets/k2Localizations/types";
import GeneralFormItemV4 from "./GeneralFormItemV4";

export interface IInputFormItemV4Props
  extends Omit<FormItemProps, "children">,
    InjectedK2IntlProps {
  /**antd form form parent component props */
  form: FormInstance;
  /**name of the field for data binding */
  fieldName: string;
  /**concrete name of the field for data binding */
  fieldConcreteName?: string;
  /**index of the field for data binding */
  fieldIndex?: number;
  /**property of the field for data binding */
  fieldProperty?: string;
  /**locale key for label */
  labelKey?: string;
  /**substitution params for label key */
  labelParams?: { [key: string]: string };
  /**Position or className to align label */
  labelAlignment?: string;
  /**locale key for label tooltip */
  labelTooltipKey?: string;
  /**required */
  required?: boolean;
  /**disabled */
  disabled?: boolean;
  /**readOnly */
  readOnly?: boolean;
  /**Form.Item layout object */
  formItemLayout?: { wrapperCol: Object; labelCol?: Object };
  /**locale key for placeholder */
  placeholderKey?: string;
  /**locale key values for placeholder */
  placeholderKeyValues?: { [key: string]: K2MessageValue };
  /**Event to trigger the validation on
   * default: onBlur
   */
  validateTrigger?: string | string[];
  /** Set the focus on first render
   * default: false
   */
  autoFocus?: boolean;
  /**when Enter Key is pressed */
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  /**when any Key is pressed */
  onKeyUp?: any;
  /**when user lefts the control */
  onAfterBlur?: any;
  /**when user focus the control */
  onAfterFocus?: any;
  /**when user changes the value control */
  onAfterChange?: any;
  /**call back for parent to detect if data is clean or not */
  updateValidationFeedback?: (hasCleanData: boolean) => void;
  /**ReactNode to add after the input field */
  addonAfter?: React.ReactNode;
  /**ReactNode to add before the input field */
  addonBefore?: React.ReactNode;
  /**whether the field is used for email
   * will add email validation if true */
  isEmail?: boolean;
  /**field type */
  type?: "default" | "number" | "currency";
  /**Currency value */
  currency?: Currency;

  max?: number | string;
  maxLength?: number;
  min?: number | string;
  minLength?: number;

  /**form validation ran or not*/
  savingForm?: boolean;
  /**current section of form is validation or not*/
  isFormValid?: boolean;
  /**force validation to update*/
  dynamicItem?: boolean;
  /**forcing not to show error*/
  dontShowError?: boolean;
  /**forcing not to show feedback*/
  dontShowFeedback?: boolean;
  /**has Suggested Value or not*/
  hasSuggestedValue?: boolean;
  /**can clear the field*/
  allowClear?: boolean;
}

type ITexts = {
  label: React.ReactNode;
  placeholder: string;
  emailError: string;
  requiredError: string;
};

interface IState {
  hasCleanData: boolean;
  hasData: boolean;
}

class InputFormItemV4 extends PureComponent<IInputFormItemV4Props, IState> {
  constructor(props: IInputFormItemV4Props) {
    super(props);

    this.state = {
      hasCleanData: false,
      hasData: false,
    };
  }

  componentDidUpdate(prevProps: IInputFormItemV4Props) {
    const { dontShowError, savingForm } = this.props;

    if (
      !_.isUndefined(savingForm) &&
      savingForm &&
      savingForm !== prevProps.savingForm &&
      (_.isUndefined(dontShowError) || !dontShowError)
    ) {
      this.validateField();
    }

    this.handleValidationFeedback(true);
  }

  updateValidationFeedbackStatus = (hasCleanData: boolean) => {
    const { fieldName, form, updateValidationFeedback } = this.props;

    if (form?.isFieldTouched(fieldName)) {
      updateValidationFeedback?.(hasCleanData);
    }
  };

  handleValidationFeedback = (updateState: boolean) => {
    const { form, fieldName } = this.props;

    const { hasCleanData } = this.state;

    if (!hasCleanData) {
      const fieldValue = form?.getFieldValue(fieldName);
      const hasValue = !_.isEmpty(fieldValue);

      this.setState({ hasData: hasValue }, () => {
        if (updateState) {
          this.setState({ hasCleanData: hasValue });
        }
      });

      this.updateValidationFeedbackStatus(hasValue);
    }
  };

  clearValidationErrors = () => {
    this.setState(
      {
        hasCleanData: false,
        hasData: false,
      },
      () => {
        this.updateValidationFeedbackStatus(false);
      },
    );
  };

  validateField = () => {
    const {
      form,
      fieldName,
      fieldConcreteName,
      fieldIndex,
      fieldProperty,
      isFormValid,
      dynamicItem,
    } = this.props;

    const fieldValue = form?.getFieldValue(fieldName);
    const hasValue = !_.isNil(fieldValue) && !_.isEmpty(fieldValue);

    this.setState({ hasData: hasValue }, () => {
      if (
        (_.isUndefined(dynamicItem) || !dynamicItem) &&
        (_.isUndefined(isFormValid) || !isFormValid) &&
        hasValue
      ) {
        form?.validateFields([fieldName]).then(() => {
          const err = form?.getFieldsError([fieldName]);
          if (!_.isEmpty(err)) {
            let errorsList: FieldErrorsList = err[fieldName];

            if (!_.isEmpty(fieldConcreteName)) {
              errorsList = err[fieldConcreteName][fieldIndex][fieldProperty];
            }

            const noErrors = _.isEmpty(errorsList);

            this.setState({ hasCleanData: noErrors }, () => {
              this.updateValidationFeedbackStatus(noErrors);
            });
          }
        });
      }
    });
  };

  onBlur = (e) => {
    const { onAfterBlur } = this.props;

    this.validateField();
    onAfterBlur?.(e);
  };

  onFocus = (e) => {
    this.props.onAfterFocus?.(e);
  };

  onChange = (e) => {
    const { onAfterChange } = this.props;

    this.validateField();
    onAfterChange?.(e);
  };

  makeLabelNode = (label: string, labelTooltipKey: string): React.ReactNode => {
    return <LabelWithHelp label={label} labelTooltipKey={labelTooltipKey} />;
  };

  getLocalizedTexts = (): ITexts => {
    const {
      labelKey,
      labelParams,
      k2Intl,
      placeholderKey,
      placeholderKeyValues,
      fieldName,
    } = this.props;

    const label = k2Intl?.formatMessage({
      localeKey: labelKey,
      values: labelParams,
    });

    const labelNode = this.makeLabelNode(label, this.props.labelTooltipKey);

    const placeholder = k2Intl?.formatMessage({
      localeKey: placeholderKey,
      values: placeholderKeyValues,
    });

    const emailError = k2Intl?.formatMessage({
      localeKey: errorKeys.emailValidationError,
    });

    const requiredError = k2Intl?.formatMessage({
      localeKey: errorKeys.requiredValidationError,
      values: { fieldName: label || fieldName },
    });

    return {
      label: labelNode,
      placeholder,
      emailError,
      requiredError,
    };
  };

  onNumberValueChanged = (value: string) => {
    const { form, fieldName } = this.props;

    form?.setFieldsValue({
      [fieldName]: value,
    });

    return true;
  };

  getInputByType = ({ placeholder }: ITexts) => {
    const {
      type,
      addonAfter,
      addonBefore,
      autoFocus,
      onPressEnter,
      onKeyUp,
      disabled,
      readOnly,
      currency,
      max,
      maxLength,
      min,
      minLength,
      allowClear,
    } = this.props;

    const inputProps = {
      addonAfter,
      addonBefore,
      placeholder,
      autoFocus,
      autoComplete: "off",
      onPressEnter,
      onKeyUp: onKeyUp,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      onChange: this.onChange,
      disabled: disabled,
      readOnly,
      max: max,
      maxLength: maxLength,
      min: min,
      minLength: minLength,
      allowClear,
    };

    switch (type) {
      case "number":
        return (
          <K2NumberInput
            {...inputProps}
            onValueChanged={this.onNumberValueChanged.bind(this)}
          />
        );
      case "currency":
        return <K2CurrencyInput {...inputProps} currency={currency} />;
      default:
        return <K2InputV4 {...inputProps} />;
    }
  };

  getValidationRules = (texts: ITexts) => {
    const { isEmail, required } = this.props;

    let validationRules: Rule[] = [];
    if (isEmail) {
      validationRules.push({
        type: "email",
        message: texts.emailError,
      });
    }

    if (required) {
      validationRules.push({
        required: true,
        message: texts.requiredError,
        whitespace: true,
      });
    }

    return validationRules;
  };

  render() {
    const {
      form,
      labelAlignment,
      fieldName,
      formItemLayout,
      validateTrigger,
      className,
      hasSuggestedValue,
      dontShowError,
      dontShowFeedback,
      help,
    } = this.props;

    const { hasCleanData, hasData } = this.state;

    if (!fieldName) {
      throw new Error(
        `'fieldName' is required for the component to render! fieldName: ${fieldName}`,
      );
    }

    const texts = this.getLocalizedTexts();

    const fieldValidating = form?.isFieldValidating(fieldName);
    const fieldTouched = form?.isFieldTouched(fieldName);

    if (fieldValidating && fieldTouched) {
      this.handleValidationFeedback(false);
    }

    let labelAlignmentClassName: string;

    if (labelAlignment !== undefined) {
      labelAlignmentClassName =
        labelAlignment === "left"
          ? "k2-label-left"
          : "k2-label-left" + labelAlignment;
    }

    const formItemProps = this.getFormItemProps();

    return (
      <GeneralFormItemV4
        {...formItemLayout}
        label={texts.label}
        validateStatus={hasData && hasCleanData ? "success" : ""}
        help={!hasCleanData && hasSuggestedValue && help}
        className={`${className} ${labelAlignmentClassName}`}
        hasFeedback={
          hasData && hasCleanData && !dontShowError && !dontShowFeedback
        }
        rules={this.getValidationRules(texts)}
        validateTrigger={
          !validateTrigger ? ["onBlur", "onChange"] : validateTrigger
        }
        name={fieldName as NamePath}
        {...formItemProps}
      >
        {this.getInputByType(texts)}
      </GeneralFormItemV4>
    );
  }

  getFormItemProps = () => {
    const {
      form,
      labelKey,
      labelParams,
      labelAlignment,
      labelTooltipKey,
      fieldName,
      wrapperCol,
      disabled,
      formItemLayout,
      placeholderKey,
      placeholderKeyValues,
      validateTrigger,
      autoFocus,
      onPressEnter,
      onKeyUp,
      onAfterBlur,
      onAfterFocus,
      onAfterChange,
      addonAfter,
      addonBefore,
      k2Intl,
      isEmail,
      type,
      currency,
      readOnly,
      className,
      localizationSettings,
      savingForm,
      isFormValid,
      fieldConcreteName,
      fieldIndex,
      fieldProperty,
      dynamicItem,
      dontShowError,
      dontShowFeedback,
      hasSuggestedValue,
      updateValidationFeedback,
      allowClear,
      ...formItemProps
    } = this.props;

    return formItemProps;
  };
}

export default injectK2Intl(InputFormItemV4);
