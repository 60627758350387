import {
  CreateFragmentReq,
  FragmentRes,
} from "@n3oltd/karakoram.templates.sdk.fragments/esm";
import { RcFile } from "antd/lib/upload";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";
import { TemporaryUploadFile } from "routes/admin/communication/modules/types";

export enum ActionTypes {
  FETCH_PARTIAL_OR_LAYOUT = "@engage/admin/communication/partial-layout/request",
  FETCH_PARTIAL_OR_LAYOUT_SUCCESS = "@engage/admin/communication/partial-layout/success",
  FETCH_PARTIAL_OR_LAYOUT_ERROR = "@engage/admin/communication/partial-layout/error",
  CLEAR_PARTIAL_OR_LAYOUT = "@engage/admin/communication/partial-layout/clear",
  CLEAR_PARTIAL_OR_LAYOUT_ERROR = "@engage/admin/communication/partial-layout/clear-error",

  UPDATE_PARTIAL_OR_LAYOUT = "@engage/admin/communication/partial-layout/update/request",
  UPDATE_PARTIAL_OR_LAYOUT_SUCCESS = "@engage/admin/communication/partial-layout/update/success",
  UPDATE_PARTIAL_OR_LAYOUT_ERROR = "@engage/admin/communication/partial-layout/update/error",

  DELETE_PARTIAL_OR_LAYOUT = "@engage/admin/communication/partial-layout/delete/request",
  DELETE_PARTIAL_OR_LAYOUT_SUCCESS = "@engage/admin/communication/partial-layout/delete/success",
  DELETE_PARTIAL_OR_LAYOUT_ERROR = "@engage/admin/communication/partial-layout/delete/error",

  CREATE_PARTIAL_OR_LAYOUT = "@engage/admin/communication/partial-layout/create/request",
  CREATE_PARTIAL_OR_LAYOUT_SUCCESS = "@engage/admin/communication/partial-layout/create/success",
  CREATE_PARTIAL_OR_LAYOUT_ERROR = "@engage/admin/communication/partial-layout/create/error",

  CLEAR_PARTIAL_OR_LAYOUT_EDIT_STATUS = "@engage/admin/communication/partial-layout/clear-edit-status",

  UPLOAD_ASSET = "@engage/admin/communication/partial-layout/upload-asset",
  REMOVE_TEMPORARY_ASSET = "@engage/admin/communication/partial-layout/remove-asset",
  UPDATE_TEMPORARY_ASSETS = "@engage/admin/communication/partial-layout/update-temp-assets",
  CLEAR_TEMPORARY_ASSETS = "@engage/admin/communication/partial-layout/clear-temp-assets",

  UPDATE_DIRTY = "@engage/admin/communication/partial-layout/update-dirty",
}

export interface FetchPartialOrLayout {
  type: ActionTypes.FETCH_PARTIAL_OR_LAYOUT;
  id: string;
}

export interface FetchPartialOrLayoutSuccess {
  type: ActionTypes.FETCH_PARTIAL_OR_LAYOUT_SUCCESS;
  data: FragmentRes;
}

export interface FetchPartialOrLayoutError {
  type: ActionTypes.FETCH_PARTIAL_OR_LAYOUT_ERROR;
  error: ServerError;
}

export interface ClearPartialOrLayoutError {
  type: ActionTypes.CLEAR_PARTIAL_OR_LAYOUT_ERROR;
}

export interface ClearPartialOrLayout {
  type: ActionTypes.CLEAR_PARTIAL_OR_LAYOUT;
}

export interface UpdatePartialOrLayout {
  type: ActionTypes.UPDATE_PARTIAL_OR_LAYOUT;
  revisionId: string;
  data: FragmentRes;
}

export interface UpdatePartialOrLayoutSuccess {
  type: ActionTypes.UPDATE_PARTIAL_OR_LAYOUT_SUCCESS;
  editStatus: EditStatus<void>;
}

export interface UpdatePartialOrLayoutError {
  type: ActionTypes.UPDATE_PARTIAL_OR_LAYOUT_ERROR;
  editStatus: EditStatus<void>;
}

export interface DeletePartialOrLayout {
  type: ActionTypes.DELETE_PARTIAL_OR_LAYOUT;
  revisionId: string;
}

export interface DeletePartialOrLayoutSuccess {
  type: ActionTypes.DELETE_PARTIAL_OR_LAYOUT_SUCCESS;
  editStatus: EditStatus<void>;
}

export interface DeletePartialOrLayoutError {
  type: ActionTypes.DELETE_PARTIAL_OR_LAYOUT_ERROR;
  editStatus: EditStatus<void>;
}

export interface CreatePartialOrLayout {
  type: ActionTypes.CREATE_PARTIAL_OR_LAYOUT;
  data: CreateFragmentReq;
}

export interface CreatePartialOrLayoutSuccess {
  type: ActionTypes.CREATE_PARTIAL_OR_LAYOUT_SUCCESS;
  editStatus: EditStatus<FragmentRes>;
}

export interface CreatePartialOrLayoutError {
  type: ActionTypes.CREATE_PARTIAL_OR_LAYOUT_ERROR;
  editStatus: EditStatus<FragmentRes>;
}

export interface ClearEditStatus {
  type: ActionTypes.CLEAR_PARTIAL_OR_LAYOUT_EDIT_STATUS;
}

export interface UploadAsset {
  type: ActionTypes.UPLOAD_ASSET;
  upload: TemporaryUploadFile;
  originalFile: RcFile;
}

export interface UpdateTemporaryAssets {
  type: ActionTypes.UPDATE_TEMPORARY_ASSETS;
  upload: TemporaryUploadFile;
}

export interface RemoveTemporaryAsset {
  type: ActionTypes.REMOVE_TEMPORARY_ASSET;
  temporaryAsset: TemporaryUploadFile;
}

export interface ClearTemporaryAssets {
  type: ActionTypes.CLEAR_TEMPORARY_ASSETS;
}

export interface UpdateDirty {
  type: ActionTypes.UPDATE_DIRTY;
  dirty: boolean;
}

export type PartialOrLayoutAction =
  | FetchPartialOrLayout
  | FetchPartialOrLayoutSuccess
  | FetchPartialOrLayoutError
  | ClearPartialOrLayout
  | ClearPartialOrLayoutError
  | UpdatePartialOrLayout
  | UpdatePartialOrLayoutError
  | UpdatePartialOrLayoutSuccess
  | CreatePartialOrLayout
  | CreatePartialOrLayoutError
  | CreatePartialOrLayoutSuccess
  | DeletePartialOrLayout
  | DeletePartialOrLayoutError
  | DeletePartialOrLayoutSuccess
  | ClearEditStatus
  | UploadAsset
  | UpdateTemporaryAssets
  | RemoveTemporaryAsset
  | ClearTemporaryAssets
  | UpdateDirty;
