import React from "react";

import { generateUuid } from "@azure/ms-rest-js";
import { FragmentType } from "@n3oltd/karakoram.templates.sdk.fragments/esm";
import {
  MergeFieldsNodeRes,
  MergeFieldsRootRes,
} from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { CompositionType } from "@n3oltd/karakoram.templates.sdk.pdf";
import { DataNode } from "antd/es/tree";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import { PageType } from "routes/admin/communication/modules/types";

export default class CommunicationAdminHelpers {
  public static getFragmentType(path: string): FragmentType {
    if (path.includes("layouts")) return FragmentType.Layout;
    if (path.includes("partials")) return FragmentType.Partial;
  }

  public static getPageType(path: string): PageType {
    let p: PageType;
    if (path.includes("layouts")) p = FragmentType.Layout;
    if (path.includes("partials")) p = FragmentType.Partial;
    if (path.includes("templates")) p = "template";
    if (path.includes("stationery")) p = "stationery";
    return p;
  }

  public static getEntityType(path: string): CompositionType {
    if (path.includes("pdf")) return CompositionType.Pdf;
    if (path.includes("email")) return CompositionType.Email;
    if (path.includes("sms")) return CompositionType.Sms;
  }

  public static getK2ListRouteForEntityTypeAndPageType(
    pageType: PageType,
    entityType: CompositionType,
  ): string {
    switch (pageType) {
      case "layout":
        switch (entityType) {
          case "email":
            return K2Routes.communicationsEmailLayouts;
          case "pdf":
            return K2Routes.communicationsPdfLayouts;
          case "sms":
            return K2Routes.communicationsSMSLayouts;
        }
        break;
      case "partial":
        switch (entityType) {
          case "email":
            return K2Routes.communicationsEmailPartials;
          case "pdf":
            return K2Routes.communicationsPdfPartials;
          case "sms":
            return K2Routes.communicationsSMSPartials;
        }
    }
  }

  public static getK2RouteForEntityType(entityType: CompositionType): string {
    switch (entityType) {
      case "pdf":
        return K2Routes.communicationsPDF;
      case "email":
        return K2Routes.communicationsEmail;
      case "sms":
        return K2Routes.communicationsSMS;
    }
  }

  public static getK2EditRouteForEntityTypeAndPageType(
    pageType: PageType,
    entityType: CompositionType,
    editMode: boolean,
  ): string {
    switch (pageType) {
      case "partial":
        switch (entityType) {
          case "email":
            return editMode
              ? K2Routes.communicationsEditEmailPartial
              : K2Routes.communicationsNewEmailPartial;
          case "pdf":
            return editMode
              ? K2Routes.communicationsEditPdfPartial
              : K2Routes.communicationsNewPdfPartial;
          case "sms":
            return editMode
              ? K2Routes.communicationsEditSMSPartial
              : K2Routes.communicationsNewSMSPartial;
        }
        break;
      case "layout":
        switch (entityType) {
          case "email":
            return editMode
              ? K2Routes.communicationsEditEmailLayout
              : K2Routes.communicationsNewEmailLayout;
          case "pdf":
            return editMode
              ? K2Routes.communicationsEditPdfLayout
              : K2Routes.communicationsNewPdfLayout;
          case "sms":
            return editMode
              ? K2Routes.communicationsEditSMSLayout
              : K2Routes.communicationsNewSMSLayout;
        }
    }
  }

  public static getAliasName(name: string, maxLength: number): string {
    return name.slice(0, maxLength).split(" ").join("_");
  }

  private static fieldToNode(
    field: MergeFieldsNodeRes,
    renderChildNode: (node: MergeFieldsNodeRes) => React.ReactNode,
    searchTerm: string,
    parentMatchesSearch: boolean,
  ): DataNode {
    if (
      !searchTerm ||
      field.name.toLowerCase().includes(searchTerm) ||
      parentMatchesSearch
    ) {
      return {
        title: renderChildNode(field),
        key: generateUuid(),
        icon: () => null,
        selectable: false,
      };
    } else {
      return null;
    }
  }

  private static transformNodes(
    nodes: MergeFieldsNodeRes[],
    renderChildNode: (node: MergeFieldsNodeRes) => React.ReactNode,
    searchTerm: string,
    parentMatchesSearch: boolean,
  ): DataNode[] {
    return nodes
      ?.map?.((node) => {
        if (node.type === "field" || node.type === "dynamicField") {
          return this.fieldToNode(
            node,
            renderChildNode,
            searchTerm,
            parentMatchesSearch,
          );
        } else {
          const parentMatchesThisNode = node.name
            .toLowerCase()
            .includes(searchTerm);
          return {
            key: generateUuid(),
            icon: () => null,
            selectable: false,
            title: renderChildNode(node),
            children: this.transformNodes(
              node.nodes,
              renderChildNode,
              searchTerm,
              parentMatchesThisNode || parentMatchesSearch,
            ),
          };
        }
      })
      .filter((node) => {
        // Remove any nodes where all children did not match searchTerm
        if (node?.children) return node.children.length > 0;
        else return node;
      });
  }

  /**
   * Transforms merge fields data into nodes usable by Antd Tree
   * If searchTerm is supplied, filters out any tree nodes that do not match
   * @param nodes
   * @param renderRootNode
   * @param renderChildNode
   * @param searchTerm
   */
  public static createTreeFromNodes(
    nodes: MergeFieldsRootRes[],
    renderRootNode: (node: MergeFieldsRootRes) => React.ReactNode,
    renderChildNode: (node: MergeFieldsNodeRes) => React.ReactNode,
    searchTerm?: string,
  ): DataNode[] {
    if (searchTerm) searchTerm = searchTerm.toLowerCase();

    return nodes
      ?.map?.((node) => {
        const parentMatchesSearch = node.name
          .toLowerCase()
          .includes(searchTerm);

        const rootDataNode: DataNode = {
          key: generateUuid(),
          selectable: false,
          title: renderRootNode(node),
          icon: () => null,
          children: this.transformNodes(
            node.nodes,
            renderChildNode,
            searchTerm,
            parentMatchesSearch,
          ),
        };

        if (rootDataNode.children.length) {
          return rootDataNode;
        } else {
          return null;
        }
      })
      .filter((node) => {
        return !!node;
      });
  }
}
