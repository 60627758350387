export default {
  rootUrl: "https://support.n3o.ltd/",
  pages: {
    contactUs: "contact-us",
    docs: "docs",
  },
  localizationKeys: {
    en: "en-gb",
    es: "es",
    fr: "fr",
    xx: "en-gb",
  },
};
