import { createSelector } from "reselect";
import { CountryLookups } from "../../modules/lookups/types";
import IApplicationState from "../../types";
import LookupsSelectors from "./LookupsSelectors";

export default class AddressesSelectors {
  public static selectAddressesData = (state: IApplicationState) =>
    state.addresses;

  static getAddressFormSelector() {
    return createSelector(
      LookupsSelectors.selectLookups,
      (lookups): IAddressFormSelectorProps => {
        return {
          countryList: lookups.countries,
        };
      },
    );
  }
}

type IAddressFormSelectorProps = {
  countryList: CountryLookups;
};

type IAddressFormDispatchers = {};

export type IAddressFormStateProps = IAddressFormSelectorProps &
  IAddressFormDispatchers;
