import React, { FC, useState } from "react";
import { Menu } from "antd";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import { LockFilled } from "@ant-design/icons";
import { K2Permission } from "..";

interface IProps extends MenuItemProps {
  permission?: string;
  hideLock?: boolean;
}

const K2MenuItem: FC<IProps> = (props) => {
  const { permission, hideLock, children, disabled, ...remainingProps } = props;

  const [havePermission, setHavePermission] = useState<boolean>(true);

  return (
    <Menu.Item {...remainingProps} disabled={disabled || !havePermission}>
      <K2Permission permission={permission} checkPermission={setHavePermission}>
        {children}
        {!havePermission && !hideLock && <LockFilled className={"n3o-ml-2"} />}
      </K2Permission>
    </Menu.Item>
  );
};

export default K2MenuItem;
