import React, { FC, useCallback, useEffect, useState } from "react";

import { FragmentSummaryRes } from "@n3oltd/karakoram.templates.sdk.fragments/esm";
import { LetterheadRes } from "@n3oltd/karakoram.templates.sdk.pdf/esm";
import { LetterheadResultsPage } from "@n3oltd/karakoram.templates.sdk.pdf/src/index";
import { Select } from "antd";

import { _pdfCompositionsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { GeneralFormItemV4 } from "components/formItems";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { TEMPLATE_ENGINE } from "constants/appConstants";
import PdfTemplateHelpers from "routes/admin/communication/newEditPdfTemplate/helpers";

interface LetterheadSelectorProps {
  onLetterheadSelect: (letterhead: LetterheadRes) => void;
  onChange?: (e: string) => void;
  value?: string;
  k2Intl: InjectedK2Intl;
  initialLetterheadId?: string;
}

const LetterheadSelector: FC<LetterheadSelectorProps> = ({
  onChange,
  k2Intl,
  onLetterheadSelect,
  initialLetterheadId,
  value,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [stationery, setStationery] = useState<LetterheadResultsPage>({
    items: [],
  });

  useEffect(() => {
    if (initialLetterheadId) {
      getInitialLetterheadData(initialLetterheadId);
    }
    // eslint-disable-next-line
  }, [initialLetterheadId]);

  const getInitialLetterheadData = useCallback(
    async (id: string) => {
      const resp: IApiResponse<LetterheadRes> = await K2RestService.toResponse(
        _pdfCompositionsClient.getLetterheadById(id, TEMPLATE_ENGINE, null),
      );
      if (!resp.error) {
        onLetterheadSelect(resp.getResultOrDefault());
      }
    },
    [onLetterheadSelect],
  );

  const fetchLayouts = useCallback(
    async (searchTerm: string) => {
      setLoading(true);
      const resp: IApiResponse<LetterheadResultsPage> = await K2RestService.toResponse(
        _pdfCompositionsClient.findLetterheads(null, {
          name: searchTerm,
        }),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        setStationery(resp.getResultOrDefault());
      }

      setLoading(false);
    },
    [k2Intl],
  );

  useEffect(() => {
    fetchLayouts(searchTerm);
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <GeneralFormItemV4
      name={PdfTemplateHelpers.letterheadFieldName}
      labelCol={{
        xs: 24,
      }}
      wrapperCol={{
        xs: 24,
        sm: 18,
        md: 12,
      }}
      labelKey={"admin.communication.stationery"}
      errorLabelKey={"admin.communication.stationery"}
      required
    >
      <Select
        showArrow
        allowClear
        showSearch
        optionLabelProp={"children"}
        optionFilterProp={"children"}
        onSearch={(v: string) => setSearchTerm(v)}
        loading={loading}
        onChange={(id: string) => {
          onChange?.(id);
          onLetterheadSelect(stationery.items.find((s) => s.id === id));
        }}
        value={value}
        placeholder={k2Intl?.formatMessage({
          localeKey: "admin.communication.stationery.searchStationery",
        })}
      >
        {stationery?.items?.map?.((layout) => (
          <Select.Option key={layout.id} value={layout.id}>
            {layout.name}
          </Select.Option>
        ))}
      </Select>
    </GeneralFormItemV4>
  );
};

export default LetterheadSelector;
