import React, { FC, useCallback, useEffect, useState } from "react";

import { PlusOutlined, QuestionCircleTwoTone } from "@ant-design/icons/lib";
import {
  AssetRes,
  AssetType,
  FileResponse,
} from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { AssetTypeRes } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { Skeleton, Tooltip } from "antd";
import { RcFile } from "antd/lib/upload";

import { _assetsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import DownloadHelpers from "common/helpers/downloads";
import FileList from "components/communications/SelectedDocumentsList/FileList";
import { K2Message, K2Tooltip } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { TEMPLATE_ENGINE } from "constants/appConstants";
import {
  PageType,
  TemporaryUploadFile,
} from "routes/admin/communication/modules/types";
import CurrentAssetDisplay from "routes/admin/communication/newEditPdfTemplate/components/CurrentAssetDisplay";
import AddAssetModal from "routes/admin/communication/sharedComponents/AddAssetModal";

interface PrologueEpilogueSelectorProps extends InjectedK2IntlProps {
  type: "epilogue" | "prologue";
  pageType: PageType;
  assetTypes: AssetTypeRes[];
  tempAsset: AssetRes;
  initialAssetId?: string;
  setTempAsset: (tempAssets: AssetRes) => void;
  removeTemporaryAsset: (tempAsset: TemporaryUploadFile) => void;
  clearTemporaryAsset: () => void;
  temporaryUploadFile: TemporaryUploadFile;
  updateTemporaryAsset: (upload: TemporaryUploadFile) => void;
  onUpload: (upload: TemporaryUploadFile, originalFile: RcFile) => void;
  updateDirty: (dirty: boolean) => void;
}

const PrologueEpilogueSelector: FC<PrologueEpilogueSelectorProps> = ({
  tempAsset,
  setTempAsset,
  updateDirty,
  onUpload,
  type,
  pageType,
  assetTypes,
  updateTemporaryAsset,
  temporaryUploadFile,
  removeTemporaryAsset,
  clearTemporaryAsset,
  initialAssetId,
  k2Intl,
}) => {
  const [modalShowing, setModalShowing] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const removeAsset = useCallback(async () => {
    setTempAsset(null);
    updateDirty(true);
  }, [updateDirty, setTempAsset]);

  useEffect(() => {
    if (initialAssetId) {
      fetchInitialAsset(initialAssetId);
    }
    // eslint-disable-next-line
  }, [initialAssetId]);

  const fetchInitialAsset = useCallback(
    async (assetId: string) => {
      setLoading(true);

      const resp: IApiResponse<AssetRes> = await K2RestService.toResponse(
        _assetsClient.getAssetById(assetId, TEMPLATE_ENGINE, null),
      );

      if (!resp.error) {
        setTempAsset(resp.getResultOrDefault());
        setLoading(false);
      }
    },
    [setTempAsset],
  );

  const downloadPrologueEpilogue = useCallback(
    async (assetId: string, filename: string) => {
      const resp: IApiResponse<FileResponse> = await K2RestService.toResponse(
        _assetsClient.downloadFile(assetId, TEMPLATE_ENGINE, null),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        DownloadHelpers.downloadFile(resp, filename);
      }
    },
    [k2Intl],
  );

  return (
    <FileList>
      {modalShowing && (
        <AddAssetModal
          titleKey={`admin.communication.pdfTemplates.${type}.${
            tempAsset ? "change" : "add"
          }`}
          loading={assetTypes.length === 0 || loading}
          assetTypes={assetTypes.filter((type) => type.id === "pdf")}
          maxAssets={1}
          assetType={AssetType.Pdf}
          onSave={(
            newAssets: TemporaryUploadFile[],
            existingAssets: AssetRes[],
          ) => {
            setModalShowing(false);
            clearTemporaryAsset();
            if (newAssets.length) {
              setTempAsset(newAssets[0].savedAsset);
            } else if (existingAssets.length) {
              setTempAsset(existingAssets[0]);
            }
            updateDirty(true);
          }}
          onCancel={() => {
            setModalShowing(false);
            clearTemporaryAsset();
          }}
          pageType={pageType}
          onUpload={onUpload}
          updateTemporaryAssets={updateTemporaryAsset}
          removeTemporaryAsset={removeTemporaryAsset}
          temporaryUploadFiles={
            temporaryUploadFile ? [temporaryUploadFile] : []
          }
          currentlySelectedAssets={tempAsset ? [tempAsset] : []}
        />
      )}

      <Skeleton loading={loading} active>
        <div className={"header"}>
          <h3>
            <K2Message localeKey={`admin.communication.pdfTemplates.${type}`} />
          </h3>
          <K2Tooltip
            title={
              <K2Message
                localeKey={`admin.communication.pdfTemplates.${type}.explanation`}
              />
            }
          >
            <QuestionCircleTwoTone />
          </K2Tooltip>
        </div>

        <CurrentAssetDisplay
          asset={tempAsset}
          onRemove={removeAsset}
          onDownload={downloadPrologueEpilogue}
        />

        <FakeLink>
          <div className={"add-button"} onClick={() => setModalShowing(true)}>
            <PlusOutlined />
            <K2Message
              localeKey={`admin.communication.pdfTemplates.${type}.${
                tempAsset ? "change" : "add"
              }`}
            />
          </div>
        </FakeLink>
      </Skeleton>
    </FileList>
  );
};

export default injectK2Intl(PrologueEpilogueSelector);
