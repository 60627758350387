import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import RequireTallyAuth from "routes/Q1/containers/auth/RequireTallyAuth";
import { asyncComponent } from "components/utils";
import { K2Card, K2Link } from "components/k2Widgets";

const RefundsWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyPaymentsRefunds" */ "routes/Q1/routes/payments/refunds"
      ),
  ),
  "q1Refunds",
);

const ReconciliationWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyPaymentsReconciliation" */ "routes/Q1/routes/payments/reconciliation"
      ),
  ),
  "q1Reconciliation",
);

const PaymentsSearchWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyPaymentsSearch" */ "containers/paymentsSearch"
      ),
  ),
  "q1PaymentsSearch",
);

const ViewPaymentWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyViewPayment" */ "containers/viewPayment"
      ),
  ),
  "q1ViewPayment",
);

const PaymentsRoutes = () => {
  return (
    <Switch>
      <Route exact path={Q1Routes.q1Refunds} component={RefundsWithAuth} />
      <Route
        exact
        path={Q1Routes.q1PaymentsSearch}
        component={PaymentsSearchWithAuth}
      />
      <Route
        exact
        path={Q1Routes.q1Reconciliation}
        component={ReconciliationWithAuth}
      />
      <Route
        exact
        path={Q1Routes.q1ViewPayment}
        component={ViewPaymentWithAuth}
      />
      <Redirect to={Q1Routes.q1PaymentsSearch} />
    </Switch>
  );
};

export default PaymentsRoutes;
