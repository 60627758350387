import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const AccountData: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.80626 2.4729C5.6533 1.62586 6.80213 1.15 8.00002 1.15C9.19792 1.15 10.3468 1.62586 11.1938 2.4729C12.0408 3.31994 12.5167 4.46877 12.5167 5.66667C12.5167 6.86456 12.0408 8.01339 11.1938 8.86043C10.6276 9.42664 9.92654 9.82699 9.16612 10.0302C9.41114 10.2084 9.55277 10.5122 9.50885 10.8334C9.44525 11.2985 9.01664 11.624 8.55153 11.5604C8.36608 11.535 8.17931 11.5204 7.99217 11.5167C6.62914 11.5187 5.32239 12.0611 4.35842 13.0251C3.39261 13.9909 2.85002 15.3008 2.85002 16.6667C2.85002 17.1361 2.46947 17.5167 2.00002 17.5167C1.53058 17.5167 1.15002 17.1361 1.15002 16.6667C1.15002 14.8499 1.87172 13.1076 3.15634 11.823C4.10596 10.8734 5.30567 10.2314 6.60067 9.9611C5.93037 9.74266 5.31408 9.36825 4.80626 8.86043C3.95922 8.01339 3.48336 6.86456 3.48336 5.66667C3.48336 4.46877 3.95922 3.31994 4.80626 2.4729ZM8.00002 2.85C7.253 2.85 6.53657 3.14676 6.00834 3.67498C5.48011 4.20321 5.18336 4.91964 5.18336 5.66667C5.18336 6.41369 5.48011 7.13012 6.00834 7.65835C6.53657 8.18658 7.253 8.48333 8.00002 8.48333C8.74705 8.48333 9.46348 8.18658 9.99171 7.65835C10.5199 7.13012 10.8167 6.41369 10.8167 5.66667C10.8167 4.91964 10.5199 4.20321 9.99171 3.67498C9.46348 3.14676 8.74705 2.85 8.00002 2.85ZM10.4834 11.3333C10.4834 10.8639 10.8639 10.4833 11.3334 10.4833H22C22.4695 10.4833 22.85 10.8639 22.85 11.3333V22C22.85 22.4694 22.4695 22.85 22 22.85H11.3334C10.8639 22.85 10.4834 22.4694 10.4834 22V11.3333ZM12.1834 12.1833V21.15H21.15V12.1833H12.1834ZM13.15 14.6667C13.15 14.1972 13.5306 13.8167 14 13.8167H19.3334C19.8028 13.8167 20.1834 14.1972 20.1834 14.6667C20.1834 15.1361 19.8028 15.5167 19.3334 15.5167H14C13.5306 15.5167 13.15 15.1361 13.15 14.6667ZM13.15 17.3333C13.15 16.8639 13.5306 16.4833 14 16.4833H16C16.4695 16.4833 16.85 16.8639 16.85 17.3333C16.85 17.8028 16.4695 18.1833 16 18.1833H14C13.5306 18.1833 13.15 17.8028 13.15 17.3333Z"
      fill={fill}
    />
  </svg>
);
