import { initialState, AnalyticsReducer } from "./reducers";
import analyticsSagas from "./sagas";

import { createActionCreators, createReducerFunction } from "immer-reducer";

//Analytics Reducers combined
const analyticsReducer = createReducerFunction(AnalyticsReducer, initialState);

//Analytics create reducer actions
const analyticsActions = createActionCreators(AnalyticsReducer);

export { analyticsActions, analyticsSagas };

export default analyticsReducer;
