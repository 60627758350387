import React, { PureComponent } from "react";

import { SearchOutlined } from "@ant-design/icons/lib/icons";
import { Spin } from "antd";
import _ from "lodash";

import { DEFAULT_DELAY } from "constants/appConstants";

import K2Message from "../k2Localizations/K2Message";
import K2SelectV4 from "./K2SelectV4";

type IProps<T = Object> = {
  placeholder?: string | React.ReactNode;
  onSearch: (value: string) => void;
  onResultSelected: (value: string) => void;
  onFocus?: () => void;
  onRenderItem: (item: T) => JSX.Element;
  searchKey: string;
  dataSource: T[];
  isSearching: boolean;
  className?: string;
  addSuffixSearchIcon: boolean;
};

class K2AutoComplete<T = Object> extends PureComponent<IProps<T>> {
  constructor(props: IProps<T>) {
    super(props);

    this.onSearch = _.debounce(this.onSearch, DEFAULT_DELAY);
  }

  onSearch = (value: string) => {
    this.props.onSearch(value);
  };

  onChange = (id: string) => {
    if (id) {
      if (this.props.onResultSelected) {
        this.props.onResultSelected(id);
      }
    }
  };

  /** We could utilize this to display a different icon for different cases */
  getSuffixIcon = () => {
    const { addSuffixSearchIcon } = this.props;
    return addSuffixSearchIcon ? <SearchOutlined /> : null;
  };

  getDataSource = () => {
    const { dataSource } = this.props;
    return dataSource ? dataSource : [];
  };

  renderNoFoundContent() {
    const { isSearching, searchKey } = this.props;
    const dataSource = this.getDataSource();

    let noRecordFound =
      dataSource.length === 0 && searchKey && isSearching === false;

    if (isSearching) return <Spin size="small" />;

    return (
      <div className="n3o-media n3o-align-items-center n3o-flex-nowrap n3o-mb-0">
        <div className="n3o-media-body">
          <h4 className="n3o-mb-0 n3o-fs-md n3o-text-center">
            <K2Message
              localeKey={
                noRecordFound ? "common.noRecordsFound" : "common.searchMessage"
              }
            />
          </h4>
        </div>
      </div>
    );
  }

  onDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      this.onSearch("");
    }
  };

  render() {
    const {
      isSearching,
      placeholder,
      onFocus,
      onRenderItem,
      className,
      addSuffixSearchIcon,
    } = this.props;
    const dataSource = this.getDataSource();

    return (
      <K2SelectV4
        className={className}
        showSearch
        focusInputOnFocus
        value={undefined}
        placeholder={placeholder}
        style={{ width: "100%" }}
        filterOption={false}
        onSearch={this.onSearch}
        onChange={(val) => this.onChange(val as string)}
        onFocus={onFocus}
        suffixIcon={this.getSuffixIcon()}
        notFoundContent={this.renderNoFoundContent()}
        allowClear
        showArrow={addSuffixSearchIcon}
        dropdownMatchSelectWidth={false}
        defaultActiveFirstOption={false}
        onDropdownVisibleChange={this.onDropdownVisibleChange}
      >
        {!isSearching && dataSource.length > 0
          ? dataSource?.map?.(onRenderItem)
          : undefined}
      </K2SelectV4>
    );
  }
}

export default K2AutoComplete;
