import { createSelector } from "reselect";
import UsersSelectors from "appRedux/store/selectors/UsersSelectors";
import { NavigationSelectors } from "appRedux/store/selectors/NavigationSelectors";
import { TALLY_PERMISSION } from "constants/appConstants";

export default class AuthSelectors {
  public static k2UserPermissionsSelector() {
    return createSelector(UsersSelectors.getK2UserSelector(), (k2User) => {
      return k2User ? k2User.permissionIds : [];
    });
  }

  public static getRequireAuthSelector() {
    return createSelector(
      UsersSelectors.getK2UserSelector(),
      this.k2UserPermissionsSelector(),
      NavigationSelectors.getPermittedRouteKeysSelector(),
      (k2User, userPermissions, permittedRouteKeys): IRequireAuthStateProps => {
        return {
          authenticated: !!k2User,
          userPermissions,
          permittedRouteKeys,
          hasTallyPermission: k2User?.permissionIds?.includes?.(
            TALLY_PERMISSION,
          ),
        };
      },
    );
  }
}

export interface IRequireAuthStateProps {
  authenticated: boolean;
  userPermissions?: string[];
  permittedRouteKeys: string[];
  hasTallyPermission: boolean;
}
