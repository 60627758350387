import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const EmptyTask: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66669 2.8499C4.18488 2.8499 3.72281 3.0413 3.38211 3.38199C3.04142 3.72268 2.85002 4.18476 2.85002 4.66657V19.3332C2.85002 19.815 3.04142 20.2771 3.38211 20.6178C3.7228 20.9585 4.18488 21.1499 4.66669 21.1499H14.4834V17.9999C14.4834 17.0672 14.8539 16.1727 15.5134 15.5132C16.1729 14.8537 17.0673 14.4832 18 14.4832H21.15V4.66657C21.15 4.18476 20.9586 3.72268 20.6179 3.38199C20.2772 3.0413 19.8152 2.8499 19.3334 2.8499H4.66669ZM20.6146 16.1832H18C17.5182 16.1832 17.0561 16.3746 16.7154 16.7153C16.3748 17.056 16.1834 17.5181 16.1834 17.9999V20.6145L20.6146 16.1832ZM14.895 22.8499H4.66669C3.73401 22.8499 2.83954 22.4794 2.18003 21.8199C1.52053 21.1604 1.15002 20.2659 1.15002 19.3332V4.66657C1.15002 3.73389 1.52053 2.83941 2.18003 2.17991C2.83954 1.52041 3.73401 1.1499 4.66669 1.1499H19.3334C20.266 1.1499 21.1605 1.52041 21.82 2.17991C22.4795 2.83941 22.85 3.73389 22.85 4.66657V14.8949C22.85 14.8949 22.85 14.895 22.85 14.895C22.8501 15.357 22.7591 15.8144 22.5823 16.2412C22.4055 16.668 22.1464 17.0557 21.8196 17.3824L17.3825 21.8195C17.0559 22.1462 16.6681 22.4054 16.2413 22.5822C15.8145 22.759 15.3571 22.8499 14.8951 22.8499C14.8951 22.8499 14.8951 22.8499 14.895 22.8499Z"
      fill={fill}
    />
  </svg>
);
