import styled from "styled-components";
import React, { FC } from "react";

const CategoryTitle = styled.div`
  display: flex;
  margin-bottom: 14px;
  color: ${({ theme }) => theme.grey_7};
  .bordered {
    border-bottom: 1px solid ${({ theme }) => theme.grey_4};
    margin-bottom: 24px;
    display: inline-block;
    flex-grow: 1;
    margin-left: 9px;
    color: ${({ theme }) => theme.grey_7};
  }
`;

export const ListRowDivider: FC<{ text: string }> = ({ text }) => {
  return (
    <CategoryTitle>
      <p>{text}</p>
      <span className={"bordered"} />
    </CategoryTitle>
  );
};

export const VariableUsage = styled.span`
  padding: 0 8px;
`;
