import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const StatsUp: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.443 7.24122C22.6016 7.39562 22.7001 7.61143 22.7001 7.85024V12.9502C22.7001 13.4197 22.3195 13.8002 21.8501 13.8002C21.3806 13.8002 21.0001 13.4197 21.0001 12.9502V9.7513L15.7129 14.4819C14.8958 15.2135 13.6056 15.2138 12.7881 14.4827L10.1459 12.1187C9.97399 11.9648 9.65994 11.9645 9.48787 12.1183L3.41678 17.5504C3.06694 17.8634 2.52957 17.8335 2.21655 17.4837C1.90353 17.1338 1.93338 16.5965 2.28323 16.2835L8.35472 10.8511C9.17229 10.12 10.4625 10.1204 11.2796 10.8519L13.9208 13.2151C14.0929 13.369 14.4071 13.3692 14.579 13.2153L19.6253 8.70024H16.1501C15.6806 8.70024 15.3001 8.31969 15.3001 7.85024C15.3001 7.3808 15.6806 7.00024 16.1501 7.00024H21.8501C22.0807 7.00024 22.2899 7.0921 22.443 7.24122Z"
      fill={fill}
    />
  </svg>
);
