import {
  ReleaseNotesRes,
  SubscriptionRes,
} from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import {
  CurrentUserRes,
  LocalizationSettingsRes,
  SubscriptionProfile,
} from "@n3oltd/k2.users.sdk.users/esm";
import { createSelector } from "reselect";

import { ILocalizationData } from "../../modules/sharedTypes";
import { usersActions } from "../../modules/subscription";
import { IUsersManagement } from "../../modules/subscription/users/types";
import IApplicationState from "../../types";

const { getCurrentSubscriptionRelease } = usersActions;

export default class UsersSelectors {
  public static selectUsersData = ({ subscription }: IApplicationState) =>
    subscription.users;

  public static getUserLocalizationSelector() {
    return createSelector(
      this.selectUsersData,
      (users: IUsersManagement) => users.currentUserLocalizationSettings,
    );
  }

  public static getK2UserSelector() {
    return createSelector(
      this.selectUsersData,
      (users: IUsersManagement) => users.k2User,
    );
  }

  public static getK2SubscriptionSelector() {
    return createSelector(
      this.selectUsersData,
      (users: IUsersManagement) => users.k2Subscription,
    );
  }

  public static getK2UserRoutesSelector() {
    return createSelector(
      this.selectUsersData,
      (users: IUsersManagement) => users.k2UserRoutes,
    );
  }
}

//#region Interfaces

type UserProfileStateProps = {
  lookupsLoading: boolean;
  k2User: CurrentUserRes;
  k2Subscription: SubscriptionRes;

  isReleaseNotesLoading: boolean;
  k2SubscriptionReleaseNotes: ReleaseNotesRes;

  currentUserLocalization: LocalizationSettingsRes;
  currentUserSubscriptions: SubscriptionProfile[];
  taskCountForUser?: number;
} & ILocalizationData;

type UserProfileDispatchProps = {
  getCurrentSubscriptionRelease: typeof getCurrentSubscriptionRelease;
};

export type UserProfileProps = UserProfileStateProps & UserProfileDispatchProps;

//#endregion Interfaces
