import React, { FC } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { K2Routes } from "appRedux/models/routes/RouteModel";
import { RequireAuth } from "containers/app/views";
import { asyncComponent } from "components/utils";
import K2Breadcrumb from "components/breadcrumb";
import { useRouter } from "hooks";
import _404Error from "components/errors/404";

const {
  searchSponsorship,
  main: { sponsorships },
} = K2Routes;

const SponsorshipsDashboardWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "SponsorshipsDashboard" */ "./dashboard"),
  ),
  "sponsorships",
);

const SearchSponsorshipsWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "SponsorshipsSearch" */ "./search"),
  ),
  "searchSponsorship",
);

const SponsorshipsRoutes: FC<RouteComponentProps> = () => {
  const router = useRouter();
  const { match } = router;

  return (
    <>
      <K2Breadcrumb />
      <Switch>
        <Route
          exact
          path={sponsorships}
          component={SponsorshipsDashboardWithAuth}
        />
        <Route
          path={searchSponsorship}
          component={SearchSponsorshipsWithAuth}
        />
        <Route path={`${match.path}/*`} component={_404Error} />
      </Switch>
    </>
  );
};

export default RequireAuth(SponsorshipsRoutes, "sponsorships");
