import { ConnectedProps, connect } from "react-redux";

import {
  CallDispositionsLookups,
  CallEndedReasonsLookups,
  CountryLookups,
  SkipReasonsLookups,
  TimezoneLookups,
} from "appRedux/modules/lookups/types";
import IApplicationState from "appRedux/types";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import {
  agentResumeShift,
  agentResumeShiftClearEditStatus,
  agentResumeShiftClearError,
  agentSignOut,
  agentSignOutClearEditStatus,
  agentSignOutClearError,
  agentTakeBreak,
  agentTakeBreakClearEditStatus,
  agentTakeBreakClearError,
  agentUpdateClearError,
  agentUpdateTeamAndCampaign,
  fetchAgent,
} from "routes/callCenter/modules/actions";
import { CallCenterState } from "routes/callCenter/modules/types";

export interface DiallerProps
  extends ConnectedProps<typeof withConnect>,
    InjectedK2IntlProps {
  callSkipReasons?: SkipReasonsLookups;
  callEndedReasons?: CallEndedReasonsLookups;
  callDispositions?: CallDispositionsLookups;
  timezones?: TimezoneLookups;
  countries?: CountryLookups;
}
const mapStateToProps = (state: IApplicationState): CallCenterState => {
  return {
    ...state.callCenter,
  };
};

const mapDispatchToProps = {
  agentSignOut,
  agentSignOutClearEditStatus,
  agentSignOutClearError,
  agentUpdateTeamAndCampaign,
  agentUpdateClearError,
  agentTakeBreak,
  agentTakeBreakClearError,
  agentTakeBreakClearEditStatus,
  agentResumeShift,
  agentResumeShiftClearError,
  agentResumeShiftClearEditStatus,
  fetchAgent,
};

export const withConnect = connect(mapStateToProps, mapDispatchToProps);
