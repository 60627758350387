import {
  MeasureValueRes,
  MeasuresResultsPage,
  PinnedMeasureRes,
  PinnedMeasuresResultsList,
  PinningLocation,
} from "@n3oltd/karakoram.analytics.sdk.measures/esm";
import { all, put, takeLatest } from "redux-saga/effects";

import { _measuresClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";

import {
  ActionTypes,
  DeletePinnedMeasure,
  FetchMeasurePreview,
  FetchMeasures,
  PinMeasure,
} from "./actionTypes";
import { PINNED_MEASURE_LENGTH } from "constants/appConstants";

function* findMeasures({ category }: FetchMeasures) {
  const response: IApiResponse<MeasuresResultsPage> = yield K2RestService.toResponse(
    _measuresClient.findMeasures({ category: [category] }),
  );

  yield put({
    type: ActionTypes.FETCH_MEASURES_SUCCESS,
    data: response?.result?.items,
  });
}

function* findPinnedMeasures() {
  const response: IApiResponse<PinnedMeasuresResultsList> = yield K2RestService.toResponse(
    _measuresClient.findPinnedMeasures({pageSize:PINNED_MEASURE_LENGTH}),
  );

  yield put({
    type: ActionTypes.FETCH_PINNED_MEASURES_SUCCESS,
    data: response?.result?.items,
  });
}

function* pinMeasure({ measureId, options }: PinMeasure) {
  const response: IApiResponse<PinnedMeasureRes> = yield K2RestService.toResponse(
    _measuresClient.createPinnedMeasure(measureId, {
      pinnedTo: PinningLocation.Dashboard,
      options: {
        timePeriodType: options.periodType,
        currentPeriod: options.periodType === "current" ? options.period : null,
        rollingPeriod:
          options.periodType === "rolling"
            ? { unit: options.period, multiplier: options.multiplier }
            : null,
        aggregationFunction: options.aggregation,
      },
    }),
  );

  yield put({
    type: ActionTypes.PIN_MEASURE_SUCCESS,
  });

  yield put({
    type: ActionTypes.FETCH_PINNED_MEASURES,
  });
}

function* fetchMeasurePreview({ measureId, options }: FetchMeasurePreview) {
  const response: IApiResponse<MeasureValueRes> = yield K2RestService.toResponse(
    _measuresClient.getMeasureValue(measureId, {
      timePeriodType: options.periodType,
      currentPeriod: options.periodType === "current" ? options.period : null,
      rollingPeriod:
        options.periodType === "rolling"
          ? { unit: options.period, multiplier: options.multiplier }
          : null,
      aggregationFunction: options.aggregation,
    }),
  );

  yield put({
    type: ActionTypes.FETCH_MEASURES_PREVIEW_SUCCESS,
    data: response?.result,
  });
}

function* deletePinnedMeasure({ measureId }: DeletePinnedMeasure) {
  const response: IApiResponse<void> = yield K2RestService.toResponse(
    _measuresClient.deletePinnedMeasure(measureId),
  );

  yield put({
    type: ActionTypes.FETCH_PINNED_MEASURES,
  });
}

export default function* watcherSaga() {
  yield all([
    takeLatest(ActionTypes.FETCH_MEASURES, findMeasures),
    takeLatest(ActionTypes.FETCH_PINNED_MEASURES, findPinnedMeasures),
    takeLatest(ActionTypes.PIN_MEASURE, pinMeasure),
    takeLatest(ActionTypes.FETCH_MEASURE_PREVIEW, fetchMeasurePreview),
    takeLatest(ActionTypes.DELETE_PINNED_MEASURE, deletePinnedMeasure),
  ]);
}
