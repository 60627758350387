import React, { Component } from "react";

import Radio, { RadioGroupProps } from "antd/lib/radio";

import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

import { InjectedK2IntlProps } from "../k2Localizations/types";
import K2RadioGroup from "./K2RadioGroup";

interface K2RadioGroupYesNoProps extends RadioGroupProps, InjectedK2IntlProps {
  initialValue: boolean;
  value?: boolean;
  yesTextKey?: string;
  noTextKey?: string;
  fieldName?: string;
}

interface K2RadioGroupYesNoState {}
class K2RadioGroupYesNo extends Component<
  K2RadioGroupYesNoProps,
  K2RadioGroupYesNoState
> {
  constructor(props: K2RadioGroupYesNoProps) {
    super(props);
  }

  render() {
    const {
      k2Intl,
      initialValue,
      yesTextKey,
      noTextKey,
      className,
      fieldName,
      ...radioGroupProps
    } = this.props;
    const yesChildren = k2Intl?.formatMessage({
      localeKey: yesTextKey ? yesTextKey : "common.yes",
    });
    const noChildren = k2Intl?.formatMessage({
      localeKey: noTextKey ? noTextKey : "common.no",
    });

    if (fieldName) {
      return (
        <K2RadioGroup
          {...radioGroupProps}
          name={fieldName}
          defaultValue={initialValue}
        >
          <Radio value={true}>{yesChildren}</Radio>
          <Radio value={false}>{noChildren}</Radio>
        </K2RadioGroup>
      );
    } else {
      return (
        <K2RadioGroup {...radioGroupProps}>
          <Radio value={true}>{yesChildren}</Radio>
          <Radio value={false}>{noChildren}</Radio>
        </K2RadioGroup>
      );
    }
  }
}

export default injectK2Intl(K2RadioGroupYesNo);
