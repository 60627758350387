import * as actionTypes from "./actionTypes";
import { FetchAction } from "./types";

export function fetchGettingStartedProgress(): FetchAction {
  return {
    type: actionTypes.FETCH_GETTING_STARTED_PROGRESS,
  };
}

export function completeGettingStarted(): FetchAction {
  return {
    type: actionTypes.COMPLETE_GETTING_STARTED_,
  };
}
