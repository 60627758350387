import { InjectedIntl, MessageValue } from "react-intl";

import { ILocalizationKeys } from "appRedux/store/types/SubscriptionTypes";
import {
  IK2IntlDateTimeFormatOptions,
  IK2IntlProvider,
  IK2MessageProps,
} from "components/k2Widgets/k2Localizations/types";

import DateFormatProvider, {
  IK2IntlDateFormatOptions,
} from "./DateFormatProvider";
import NumberFormatProvider, {
  IK2IntlNumberFormatOptions,
} from "./NumberFormatProvider";
import TimeFormatProvider, {
  IK2IntlTimeFormatOptions,
} from "./TimeFormatProvider";

export default class K2IntlProvider implements IK2IntlProvider {
  constructor(
    public localizationKeys: ILocalizationKeys,
    private intl?: InjectedIntl,
  ) {}

  formatHtmlMessage = (input: IK2MessageProps): string => {
    const { localeKey, values } = input;
    return K2IntlUtils.formatHtmlMessage(this.intl, localeKey, values);
  };

  formatMessage = (input: IK2MessageProps) => {
    const { localeKey, values, uppercase, lowercase } = input;
    let formattedMessage = K2IntlUtils.formatMessage(
      this.intl,
      localeKey,
      values,
    );

    if (uppercase) {
      formattedMessage = formattedMessage.toUpperCase();
    } else if (lowercase) {
      formattedMessage = formattedMessage.toLowerCase();
    }

    return formattedMessage;
  };

  formatDate = (value: number | Date, options?: IK2IntlDateFormatOptions) => {
    options = this.ensureFormatOptions(options, "dateFormat");
    return K2IntlUtils.formatDate(value, options, this.intl);
  };

  formatTime = (value: number | Date, options?: IK2IntlTimeFormatOptions) => {
    options = this.ensureFormatOptions(options, "timeFormat");
    return K2IntlUtils.formatTime(value, options, this.intl);
  };

  formatDateTime = (
    value: number | Date,
    options?: IK2IntlDateTimeFormatOptions,
  ) => {
    return `${this.formatDate(value, options)} ${this.formatTime(
      value,
      options,
    )}`;
  };

  formatNumber = (value: number, options?: IK2IntlNumberFormatOptions) => {
    options = this.ensureFormatOptions(options, "numberFormat");
    return K2IntlUtils.formatNumber(value, options, this.intl);
  };

  formatCurrency = (
    value: number,
    currency?: string,
    options?: IK2IntlNumberFormatOptions,
  ) => {
    currency = currency ? currency : this.localizationKeys.baseCurrency;
    const currencyOptions = { style: "currency", currency };
    const formatOptions = options
      ? { ...options, ...currencyOptions }
      : currencyOptions;
    return this.formatNumber(value, formatOptions);
  };

  private ensureFormatOptions(
    options: IK2IntlAllFormatOptions,
    formatKeyName: "dateFormat" | "timeFormat" | "numberFormat",
  ) {
    if (!options) options = {};
    if (!options[formatKeyName])
      options[formatKeyName] = this.localizationKeys[formatKeyName];
    if (formatKeyName !== "numberFormat" && !options.timeZone)
      options.timeZone = this.localizationKeys.timeZone;
    return options;
  }

  //#endregion
}

export class K2IntlUtils {
  static formatMessage = (
    intl: InjectedIntl,
    key: string,
    values?: { [key: string]: MessageValue },
  ): string => {
    if (!key) {
      return "";
    }
    return intl.formatMessage({ id: key }, values);
  };

  static formatHtmlMessage = (
    intl: InjectedIntl,
    key: string,
    values?: { [key: string]: MessageValue },
  ): string => {
    if (!key) {
      return "";
    }
    return intl.formatHTMLMessage({ id: key }, values);
  };

  static formatDate = (
    value: number | Date,
    options?: IK2IntlDateFormatOptions,
    intl?: InjectedIntl,
  ) => {
    const dfp = new DateFormatProvider(intl, options);
    return dfp.format(options.dateFormat, value);
  };

  static formatTime = (
    value: number | Date,
    options?: IK2IntlTimeFormatOptions,
    intl?: InjectedIntl,
  ) => {
    const dfp = new TimeFormatProvider(intl, options);
    return dfp.format(options.timeFormat, value);
  };

  static formatNumber = (
    value: number,
    options?: IK2IntlNumberFormatOptions,
    intl?: InjectedIntl,
  ) => {
    const nfp = new NumberFormatProvider(intl, options);
    return nfp.format(options.numberFormat, value);
  };
}

type IK2IntlAllFormatOptions = {
  dateFormat?: string;
  timeFormat?: string;
  numberFormat?: string;
  timeZone?: string;
};
