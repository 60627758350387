import { SmsCompositionReq } from "@n3oltd/karakoram.templates.sdk.sms/esm";

export default class SmsTemplateHelpers {
  public static nameFieldName = ["name"];
  public static notesFieldName = ["notes"];

  public static markupFieldName = [
    "content",
    "markup",
  ];

  public static categoryFieldName = [
    "categoryId",
  ];

  public static senderFieldName = [
    "sender",
    "markup",
  ];

  public static templateModelTypesFieldName = [
    "modelType",
  ];

  public static layoutIdFieldName = [
    "layoutId",
  ];
}
