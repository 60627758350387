export const THEME_TYPE_LITE = "THEME_TYPE_LITE";
export const LAYOUT_TYPE = "LAYOUT_TYPE";

export const NAV_STYLE = "NAV_STYLE";

export const NAV_STYLE_FIXED = "NAV_STYLE_FIXED";
export const NAV_STYLE_MINI_SIDEBAR = "NAV_STYLE_MINI_SIDEBAR";
export const NAV_STYLE_DRAWER = "NAV_STYLE_DRAWER";
export const NAV_STYLE_NO_HEADER_MINI_SIDEBAR =
  "NAV_STYLE_NO_HEADER_MINI_SIDEBAR";
export const NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR =
  "NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR";
export const NAV_STYLE_BELOW_HEADER = "NAV_STYLE_BELOW_HEADER";

export const TAB_SIZE = 992; // Bootstrap Grid "Medium" breakpoint
export const MOBILE_SIZE = 575;
