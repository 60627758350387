import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const ExternalLink: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0958 2.47308C21.3167 2.47308 21.4958 2.65215 21.4958 2.87305L21.4964 9.77643C21.4964 9.99734 21.3173 10.1764 21.0964 10.1765L20.1964 10.1765C19.9755 10.1766 19.7964 9.99749 19.7964 9.77658L19.796 5.37554L11.5303 13.6412C11.3741 13.7974 11.1209 13.7974 10.9647 13.6412L10.3283 13.0048C10.172 12.8486 10.172 12.5953 10.3283 12.4391L18.5943 4.17309L14.3999 4.1731C14.179 4.1731 13.9999 3.99401 13.9999 3.7731L13.9999 2.8731C13.9999 2.65218 14.179 2.4731 14.3999 2.4731L21.0958 2.47308ZM4.24723 6.52666C4.02681 6.52667 3.84955 6.70498 3.84893 6.92539C3.83724 11.0926 3.84919 15.26 3.84994 19.4272C3.84998 19.6478 4.0286 19.8268 4.24922 19.8271C8.41611 19.834 12.5831 19.827 16.75 19.8265C16.9709 19.8265 17.15 19.6474 17.15 19.4265V14.648C17.15 14.427 17.3291 14.248 17.55 14.248H18.45C18.6709 14.248 18.85 14.427 18.85 14.648L18.85 19.8789C18.85 20.0621 18.85 20.2442 18.839 20.3923C18.8293 20.5229 18.8044 20.7972 18.6369 21.047C18.4222 21.3671 18.1093 21.4685 17.9059 21.5022C17.7557 21.5271 17.5851 21.5268 17.4751 21.5266C17.4655 21.5265 17.4563 21.5265 17.4477 21.5265H3.97565C3.74664 21.5265 3.53684 21.5266 3.36978 21.5163C3.22298 21.5073 2.95804 21.4863 2.71404 21.3516C2.37665 21.1652 2.23292 20.8628 2.18229 20.6112C2.14901 20.4457 2.14958 20.2598 2.14991 20.1519C2.14994 20.142 2.14997 20.1328 2.14997 20.1242V6.67653C2.14997 6.66481 2.14997 6.65305 2.14997 6.64127C2.14991 6.42247 2.14984 6.19314 2.1708 6.00454C2.19219 5.81204 2.25078 5.47364 2.52393 5.20049C2.79709 4.92733 3.13548 4.86874 3.32798 4.84735C3.51659 4.8264 3.74591 4.82646 3.96471 4.82652C3.9765 4.82652 3.98826 4.82653 3.99997 4.82653H8.59997C8.82089 4.82653 8.99997 5.00561 8.99997 5.22653V6.12653C8.99997 6.34744 8.82174 6.52653 8.60082 6.52653C7.20496 6.52654 5.11307 6.52663 4.24723 6.52666Z"
      fill={fill}
    />
  </svg>
);
