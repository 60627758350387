import React, { FC } from "react";

import { Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import K2Checkbox from "../K2Checkbox";
import K2Message from "../k2Localizations/K2Message";
import { MultiSelectorDataItem } from "./types";

interface MultiSelectorHeaderProps {
  dataSource: MultiSelectorDataItem[];
  selectedKeys: string[];
  name?: string;

  onSelectAllChanged: (e: CheckboxChangeEvent) => void;
}

const MultiSelectorHeader: FC<MultiSelectorHeaderProps> = ({
  dataSource,
  selectedKeys,
  name,
  onSelectAllChanged,
}) => {
  const selectedLength = selectedKeys.length;
  const dataSourceLength = dataSource?.filter((x) => x.isHeading !== true)
    .length;

  const intermediate =
    selectedLength > 0 && selectedLength !== dataSourceLength;
  const selectAll = selectedLength > 0 && selectedLength === dataSourceLength;

  return (
    <Row className="k2-multiselector-header">
      <Col span={24}>
        <K2Checkbox
          indeterminate={intermediate}
          checked={selectAll}
          onChange={onSelectAllChanged}
        >
          {intermediate ? (
            <span>
              <K2Message localeKey={"common.selected"} />{" "}
              {`${selectedLength}/${dataSourceLength} ${name || ""}`}
            </span>
          ) : (
            <span>
              <K2Message localeKey={"common.selectAll"} />{" "}
              {`${name ? `${name} ` : ""}(${dataSourceLength})`}
            </span>
          )}
        </K2Checkbox>
      </Col>
    </Row>
  );
};

export default MultiSelectorHeader;
