import React, { FC, ReactText } from "react";

import { Drawer, Skeleton } from "antd";
import { DrawerProps } from "antd/es/drawer";
import styled from "styled-components";

import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { CustomScrollbars } from "components/utils";

import { InjectedK2IntlProps } from "../k2Localizations/types";

const DrawerWrapper = styled.div<{ $wrapperWidth: string }>`
  &.has-sticky-header {
    .k2-drawer {
      display: flex;
      justify-content: center;
      .ant-drawer-content-wrapper {
        width: ${({ $wrapperWidth }) => $wrapperWidth};
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        position: relative;
        top: 16px;
        left: 6px;
      }

      .ant-drawer-content {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        .ant-drawer-close {
          display: none;
        }
        .ant-drawer-body {
          padding: 0;
          margin: 0 0 10px;
        }
      }
    }
  }
`;

const ScrollbarWrapper = styled.div`
  &.has-sticky-header {
    padding: 0 10vh;
  }
`;

export interface IDrawerProps extends DrawerProps {
  /** (required) locale key for Title */
  titleKey?: string;
  /** If content is loading then show skeleton */
  loading?: boolean;
  /** A sticky header to show if required */
  stickyHeader?: React.ReactNode;
  /** sticky bar container id */
  containerId?: string;
  /** Wrapper Id to calculate the Width of the drawer wrapper */
  wrapperId?: string;
  /** Auto Height Max Value*/
  autoHeightMax?: ReactText;
}

const K2Drawer: FC<IDrawerProps & InjectedK2IntlProps> = ({
  k2Intl,
  titleKey,
  loading,
  children,
  localizationSettings,
  wrapperId,
  stickyHeader,
  className,
  autoHeightMax,
  ...rest
}) => {
  const title: string = titleKey
    ? k2Intl?.formatMessage({ localeKey: titleKey })
    : "";

  const containerId = "has-sticky-header";
  const containerWidth = document.getElementById(wrapperId || "form-wrapper")
    ?.offsetWidth;
  const wrapperWidth = containerWidth ? containerWidth + 66 + "px" : "100%";

  return (
    <DrawerWrapper
      className={stickyHeader ? containerId : ""}
      $wrapperWidth={wrapperWidth}
    >
      {stickyHeader}
      <Drawer
        title={title}
        getContainer={document.getElementById(containerId)}
        className={`k2-drawer ${className}`}
        {...rest}
      >
        <CustomScrollbars
          autoHide
          autoHeight={true}
          autoHeightMin={300}
          autoHeightMax={autoHeightMax || "80vh"}
        >
          <ScrollbarWrapper className={stickyHeader ? containerId : ""}>
            {loading ? <Skeleton active /> : children}
          </ScrollbarWrapper>
        </CustomScrollbars>
      </Drawer>
    </DrawerWrapper>
  );
};

export default injectK2Intl(K2Drawer);
