import React, { FC } from "react";
import { Layout } from "antd";

const { Footer } = Layout;

const AppFooter: FC = ({ children }) => {
  return <Footer>{children}</Footer>;
};

export default AppFooter;
