import React, { FC, CSSProperties, useState } from "react";
import styled from "styled-components";
import { K2Permission } from "components/k2Widgets/index";

interface IProps {
  children: string | React.ReactNode;
  style?: CSSProperties;
  disabled?: boolean;
  onClick?: (any?) => any;
  permission?: string;
}

const Span = styled.span<{ $disabled: boolean }>`
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.grey_7 : theme.link_color};

  &:hover {
    color: ${({ theme, $disabled }) =>
      $disabled ? theme.grey_7 : theme.link_hover_color};
    text-decoration: ${({ $disabled }) => ($disabled ? "" : "underline")};
    cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
    pointer-events: ${({ $disabled }) => ($disabled ? "none" : "unset")};
  }
`;

const FakeLink: FC<IProps> = ({ children, disabled, permission, ...props }) => {
  const [havePermission, setHavePermission] = useState<boolean>(true);

  return (
    <K2Permission permission={permission} checkPermission={setHavePermission}>
      <Span {...props} $disabled={disabled || !havePermission}>
        {children}
      </Span>
    </K2Permission>
  );
};

export default FakeLink;
