import React, { FC } from "react";
import { CaptureBookmarkButton } from "./styled";
import { K2Button, K2Message } from "components/k2Widgets";
import { PlusCircleOutlined } from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { CSSProperties } from "styled-components";

interface IProps {
  localeKey: string;
  block?: boolean;
  size?: SizeType;
  style?: CSSProperties;

  onClick: () => void;
}

const CaptureButton: FC<IProps> = (props) => {
  const { block, size, localeKey, style, onClick } = props;

  return (
    <CaptureBookmarkButton style={style}>
      <K2Button block={block} size={size} type="link" onClick={onClick}>
        <PlusCircleOutlined style={{ marginRight: 5 }} />
        <K2Message localeKey={localeKey} />
      </K2Button>
    </CaptureBookmarkButton>
  );
};

export default CaptureButton;
