import React, { FC, useCallback, useState } from "react";

import { LockFilled } from "@ant-design/icons";
import { Button } from "antd";
import styled from "styled-components";

import K2Permission from "../K2Permission";
import K2Link from "../k2Link";
import K2Message from "../k2Localizations/K2Message";
import { K2ButtonProps } from "./types";

const StyledButton = styled(Button)<{
  $hideTextOnMobile: boolean;
  $dashed?: boolean;
}>`
  && {
    color: ${({ theme, type, $dashed }) =>
      $dashed
        ? theme.grey_7
        : type === "primary"
        ? theme.white_color
        : theme.primaryColor};
    background-color: ${({ theme, type }) =>
      type === "primary" ? theme.primaryColor : theme.white_color};
    border: 1px ${({ $dashed }) => ($dashed ? "dashed" : "solid")}
      ${({ theme, $dashed }) => ($dashed ? theme.grey_7 : theme.primaryColor)};
    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme, type }) =>
        type === "primary" ? theme.button_hover_color : theme.white_color};
      border-color: ${({ theme }) => theme.button_hover_color};
      color: ${({ theme, type }) =>
        type === "primary" ? theme.white_color : theme.button_hover_color};
    }

    ${({ $hideTextOnMobile }) =>
      $hideTextOnMobile &&
      `
      @media (max-width: 768px) {
         .buttonText {
            display: none;
         }
      }
    `}
  }
`;

//todo : Need to have disscusion with Ryan to check all type of button we use in the app
const K2Button: FC<K2ButtonProps> = ({
  localeKey,
  values,
  toUrl,
  external,
  linkDisabled,
  className,
  btnClassName,
  children,
  spanClassName,
  title,
  withoutSpan,
  hideTextOnMobile,
  checkAdmin,
  permission,
  hideLock,
  ...rest
}) => {
  const k2MessageProps = {
    localeKey,
    values,
    spanClassName,
    withoutSpan,
  };

  const [havePermission, setHavePermission] = useState<boolean>(true);

  const renderButton = useCallback(() => {
    return (
      <StyledButton
        {...rest}
        $dashed={rest.type === "dashed"}
        className={btnClassName}
        $hideTextOnMobile={hideTextOnMobile}
      >
        <span className={"buttonText"}>
          {children}
          {title ? title : localeKey && <K2Message {...k2MessageProps} />}
          {!havePermission && !hideLock && (
            <LockFilled className={"n3o-ml-2"} />
          )}
        </span>
      </StyledButton>
    );
  }, [
    btnClassName,
    children,
    havePermission,
    hideLock,
    hideTextOnMobile,
    k2MessageProps,
    localeKey,
    rest,
    title,
  ]);

  return (
    <K2Permission
      checkAdmin={checkAdmin}
      permission={permission}
      checkPermission={setHavePermission}
    >
      {toUrl ? (
        <K2Link
          to={toUrl}
          external={external}
          hideExternalIcon={true}
          disabled={linkDisabled}
        >
          {renderButton()}
        </K2Link>
      ) : (
        renderButton()
      )}
    </K2Permission>
  );
};

export default K2Button;
