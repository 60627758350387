import {
  DashboardTasksRes,
  TaskCriteria,
  TaskReq,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { ImmerReducer } from "immer-reducer";

import {
  IApiResponse,
  ServerError,
} from "appRedux/models/common/ApiResponseModel";

import { K2DashboardTasksManagement } from "./types";

const dashboardTasksInitialState: K2DashboardTasksManagement = {
  dashboardListData: { lists: [], tasks: [] },
  search: "",
  isLoading: false,
  serverError: null,
  isMarkingComplete: false,
  taskCountForUser: null,
  taskCreationServerError: null,
  isCreatingTask: false,
  filtersApplied: {
    highPriority: false,
    overdue: false,
    assignedToMe: false,
  },
};

class DashboardTasksReducer extends ImmerReducer<K2DashboardTasksManagement> {
  updateDashboardFilters(filter: string, value: boolean) {
    switch (filter) {
      case "overdue":
        this.setOverdueFilter(value);
        break;
      case "highPriority":
        this.setHighPriorityFilter(value);
        break;
      case "assignedToMe":
        this.setAssignedToMeFilter(value);
        break;
    }
  }

  setOverdueFilter(overdue: boolean) {
    this.draftState.filtersApplied.overdue = overdue;
  }

  setHighPriorityFilter(highPriority: boolean) {
    this.draftState.filtersApplied.highPriority = highPriority;
  }

  setAssignedToMeFilter(assignedToMe: boolean) {
    this.draftState.filtersApplied.assignedToMe = assignedToMe;
  }

  resetFilters() {
    this.draftState.filtersApplied = {
      highPriority: false,
      overdue: false,
      assignedToMe: false,
    };
  }

  updateSearch(search: string) {
    this.draftState.search = search;
  }

  tasksLoading(loading: boolean) {
    this.draftState.isLoading = loading;
  }

  clearDashboardTasks() {
    this.draftState.dashboardListData =
      dashboardTasksInitialState.dashboardListData;
  }

  fetchTaskCountForUser(criteria: TaskCriteria) {}

  setTaskCountForUser(response: IApiResponse<number>) {
    this.draftState.taskCountForUser = response.getResultOrDefault() || 0;
  }

  setDashboardTasks(tasksResponse: IApiResponse<DashboardTasksRes>) {
    this.draftState.dashboardListData = tasksResponse.getResultOrDefault(
      dashboardTasksInitialState.dashboardListData,
    );
    if (tasksResponse.error) {
      this.draftState.serverError = tasksResponse.error;
    }
    this.tasksLoading(false);
  }

  fetchDashboardTasks() {
    this.tasksLoading(true);
    this.clearServerError();
  }

  setDashboardTasksServerError(error: ServerError) {
    this.tasksLoading(false);
    this.draftState.serverError = error;
  }

  clearServerError() {
    this.draftState.serverError = null;
  }

  markComplete(taskId: string) {
    this.setIsMarkingComplete();
  }

  setIsMarkingComplete() {
    this.draftState.isMarkingComplete = true;
  }

  clearIsMarkingComplete() {
    this.draftState.isMarkingComplete = false;
  }

  setTaskComplete(taskId: string) {
    this.clearIsMarkingComplete();
    this.draftState.dashboardListData.tasks = this.draftState.dashboardListData.tasks.filter(
      (item) => item.id !== taskId,
    );
  }

  createTask(task: TaskReq) {
    this.draftState.isCreatingTask = true;
  }

  createTaskSuccess() {
    this.draftState.isCreatingTask = false;
    this.draftState.taskCreationServerError = null;
  }

  createTaskError(error: ServerError) {
    this.draftState.taskCreationServerError = error;
    this.draftState.isCreatingTask = false;
  }

  clearTaskCreationServerError() {
    this.draftState.taskCreationServerError = null;
  }
}

export { dashboardTasksInitialState, DashboardTasksReducer };
