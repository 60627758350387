import { all, fork, put, takeLatest } from "redux-saga/effects";
import { usersActions } from "../subscription";
import authActions from "./actions";

function* userSignedIn(action: ReturnType<typeof authActions.userSignedIn>) {
  yield put(usersActions.getCurrentUserSubscriptions());
}

//#region Watchers

function* authUserSignedIn() {
  yield takeLatest(authActions.userSignedIn.type, userSignedIn);
}

//#endregion

export default function* rootSaga() {
  yield all([fork(authUserSignedIn)]);
}
