import React, { FC } from "react";

import { IN3oIconProps } from "../types";

// Cannot choose colours for Unknown Card logo
export const UnknownCard: FC<IN3oIconProps> = ({ height, width, appTheme }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 160 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M160 0.466919H0V101.007H160V0.466919Z" fill="white" />
      <path d="M0 0H160V100H0V0Z" fill="#D1D3E5" />
      <path
        d="M160 0H0V36.5486C0 36.5486 20.2404 62.2679 86.5625 70.6606C142.187 77.6994 160 62.2679 160 62.2679V0Z"
        fill={appTheme.grey_6}
      />
      <path
        d="M0 0H160V11.0779C160 11.0779 139.375 38.6937 73.4375 45.1219C21.875 50.1488 0 36.746 0 36.746V0Z"
        fill="#838599"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="160" height="100" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
