import { AppId, AppName } from "appRedux/modules/sharedTypes";

export default class AppHelpers {
  public static renderAppName = (appId: AppId): AppName => {
    switch (appId) {
      case "q1":
        return "Tally";
      case "k2":
        return "Engage";
    }
  };
}
