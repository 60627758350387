import React, { FC } from "react";
import styled from "styled-components";

const Span = styled.span`
  color: ${({ color, theme }) => color || theme.grey_8};
  margin: 0;
  font-size: small;
`;

interface IProps {
  children: React.ReactNode | string;
  color?: string;
}

const FormItemTitle: FC<IProps> = ({ children, color }) => (
  <Span color={color}>{children}</Span>
);

export default FormItemTitle;
