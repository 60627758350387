import { useState, useCallback } from "react";

export interface ICustomScrolling {
  isScrolling: boolean;
  onScroll: (scrolling: boolean) => void;
}

export default function useScroll(
  initialState: boolean = false,
): ICustomScrolling {
  const [isScrolling, setIsScrolling] = useState<boolean>(initialState);

  const onScroll = useCallback(
    (scrolling: boolean) => setIsScrolling(scrolling),
    [],
  );

  return { isScrolling, onScroll };
}
