import React, { FC, useCallback, useEffect, useState } from "react";

import {
  FileResponse,
  NoteFileRes,
  NoteRes,
  TaskRes,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { UploadFile } from "antd/es/upload/interface";
import { connect } from "react-redux";
import styled from "styled-components";

import K2Client, { _tasksClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import DownloadHelpers from "common/helpers/downloads";
import UploadsListItem from "components/UploadsList";
import { fetchTaskSuccess } from "components/dashboard/editTask/modules/actions";
import FormItemTitle from "components/forms/formItemTitle";
import { K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { showConfirm, showError } from "components/utils/Confirmation";
import { showNotification } from "components/utils/Notification";
import { API_VERSION } from "constants/appConstants";

const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface FileUploadData extends NoteFileRes, UploadFile {
  noteId: string;
}

interface IProps extends InjectedK2IntlProps {
  notes: NoteRes[];
  taskId: string;
  taskRevisionId: string;
  subscriptionId: string;
  fetchTaskSuccess: typeof fetchTaskSuccess;
}

const TaskUploadsList: FC<IProps> = ({
  taskId,
  taskRevisionId,
  notes,
  fetchTaskSuccess,
  k2Intl,
}) => {
  const { theme } = useAppContext();

  const tasksBaseUrl: string = K2Client.baseUrlFor("tasks");

  const transformNotesIntoFileUploadData = (
    notes: NoteRes[],
  ): FileUploadData[] => {
    return notes.reduce((acc, note): FileUploadData[] => {
      const fileData = note.files?.map?.(
        (file): FileUploadData => {
          const url = `${tasksBaseUrl}/${API_VERSION}/${taskId}/notes/${note.id}/files/${file.id}/${file.attachment.name}`;
          return {
            noteId: note.id,
            uid: file.id,
            name: file.attachment?.name,
            fileName: file.attachment?.name,
            status: "done",
            id: file.id,
            attachment: file.attachment,
            size: file.attachment?.size?.bytes,
            type: file.attachment?.contentType,
            thumbUrl: url,
            url: url,
            originFileObj: null,
          };
        },
      );
      return acc.concat(fileData);
    }, []);
  };

  const [fileUploadData, setFileUploadData] = useState(
    transformNotesIntoFileUploadData(notes),
  );

  // Returning false here prevents the default "remove" behaviour from antd
  const handleFileRemove = (e: FileUploadData): false => {
    showConfirm({
      titleKey: "tasks.deleteAttachmentConfirm",
      k2Intl,
      okTextKey: "common.delete",
      okButtonProps: {
        danger: true,
      },
      onOk: () => {
        deleteAttachment(taskRevisionId, taskId, e.noteId, e.id);
      },
    });
    return false;
  };

  useEffect(() => {
    setFileUploadData(transformNotesIntoFileUploadData(notes));
    // eslint-disable-next-line
  }, [notes]);

  const deleteAttachment = useCallback(
    async (
      taskRevisionId: string,
      taskId: string,
      noteId: string,
      fileId: string,
    ) => {
      const response: IApiResponse<void> = await K2Service.toResponse(
        _tasksClient.removeNoteFile(taskRevisionId, noteId, fileId),
      );

      if (response.error) {
        if (response.error.status === K2StatusCodes.preconditionFailed) {
          const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
            response.error,
            "error",
          );
          if (nonFieldRelatedErrors.length) {
            showError({
              titleKey: "common.nonDismissableFormErrorTitle",
              error: nonFieldRelatedErrors?.map?.((e, i) => (
                <p key={i}>{e.error}</p>
              )),
              okTextKey: "common.ok",
              k2Intl,
            });
          }
        } else {
          UIUtils.handleServerError(k2Intl, response.error);
        }
      } else {
        showNotification({
          type: "success",
          k2Intl,
          titleKey: "common.success.title",
          messageKey: "tasks.attachmentDeleted",
        });

        const taskResp: IApiResponse<TaskRes> = await K2Service.toResponse(
          _tasksClient.getById(taskId),
        );
        if (!response.error) {
          fetchTaskSuccess(taskResp.getResultOrDefault());
        }
      }
    },
    [k2Intl, fetchTaskSuccess],
  );

  const downloadFile = useCallback(
    async (file: FileUploadData) => {
      const resp: IApiResponse<FileResponse> = await K2RestService.toResponse(
        _tasksClient.getNoteFile(taskId, file.noteId, file.id, file.fileName),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        DownloadHelpers.downloadFile(resp, file.fileName);
      }
    },
    [k2Intl, taskId],
  );

  return (
    <>
      <FormItemTitle color={theme.grey_6}>
        <K2Message localeKey="tasks.uploadedFiles" />
      </FormItemTitle>
      <Container>
        {fileUploadData.map((file) => {
          const url = `${tasksBaseUrl}/${API_VERSION}/${taskId}/notes/${file.noteId}/files/${file.id}/thumbnail`;
          return (
            <UploadsListItem
              key={file.id}
              imgThumbnailUrl={url}
              isImage={file.attachment.isImage}
              file={file}
              onDownload={downloadFile}
              onRemove={handleFileRemove}
            />
          );
        })}
      </Container>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  taskId: state.editViewTask.task?.id,
  taskRevisionId: state.editViewTask.task?.revisionId,
  notes: state.editViewTask.taskNotes,
  subscriptionId: state.subscription.users?.k2Subscription?.id,
});

const mapDispatchToProps = {
  fetchTaskSuccess: fetchTaskSuccess,
};

export default injectK2Intl(
  connect(mapStateToProps, mapDispatchToProps)(TaskUploadsList),
);
