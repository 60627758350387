import { AgentRes, SignInAgentReq } from "@n3oltd/k2.calls.sdk.agents/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

import { ActionTypes, CallCenterAction } from "./actionTypes";

export const fetchAgent = (): CallCenterAction => ({
  type: ActionTypes.FETCH_AGENT,
});

export const fetchAgentSuccess = (agent: AgentRes): CallCenterAction => ({
  type: ActionTypes.FETCH_AGENT_SUCCESS,
  agent,
});

export const fetchAgentError = (error: ServerError): CallCenterAction => ({
  type: ActionTypes.FETCH_AGENT_ERROR,
  error,
});

export const agentSignIn = (
  request: SignInAgentReq,
  agentId: string,
): CallCenterAction => ({
  type: ActionTypes.AGENT_SIGN_IN,
  request,
  agentId,
});

export const agentSignInSuccess = (
  agent: AgentRes,
  editStatus: EditStatus<any>,
): CallCenterAction => ({
  type: ActionTypes.AGENT_SIGN_IN_SUCCESS,
  agent,
  editStatus,
});

export const agentSignInError = (error: ServerError): CallCenterAction => ({
  type: ActionTypes.AGENT_SIGN_IN_ERROR,
  error,
});

export const agentSignInClearError = (): CallCenterAction => ({
  type: ActionTypes.AGENT_SIGN_IN_CLEAR_ERROR,
});

export const agentSignInClearEditStatus = (): CallCenterAction => ({
  type: ActionTypes.AGENT_SIGN_IN_CLEAR_EDIT_STATUS,
});

export const agentSignOut = (agentId: string): CallCenterAction => ({
  type: ActionTypes.AGENT_SIGN_OUT,
  agentId,
});

export const agentSignOutSuccess = (
  agent: AgentRes,
  editStatus: EditStatus<any>,
): CallCenterAction => ({
  type: ActionTypes.AGENT_SIGN_OUT_SUCCESS,
  agent,
  editStatus,
});

export const agentSignOutError = (error: ServerError): CallCenterAction => ({
  type: ActionTypes.AGENT_SIGN_OUT_ERROR,
  error,
});

export const agentSignOutClearError = (): CallCenterAction => ({
  type: ActionTypes.AGENT_SIGN_OUT_CLEAR_ERROR,
});

export const agentSignOutClearEditStatus = (): CallCenterAction => ({
  type: ActionTypes.AGENT_SIGN_OUT_CLEAR_EDIT_STATUS,
});

export const clearAgentError = (): CallCenterAction => ({
  type: ActionTypes.CLEAR_AGENT_ERROR,
});

export const clearAgent = (): CallCenterAction => ({
  type: ActionTypes.CLEAR_AGENT,
});

export const agentTakeBreak = (agentId: string): CallCenterAction => ({
  type: ActionTypes.AGENT_TAKE_BREAK,
  agentId,
});

export const agentTakeBreakSuccess = (
  updatedAgent: AgentRes,
  editStatus: EditStatus<any>,
): CallCenterAction => ({
  type: ActionTypes.AGENT_TAKE_BREAK_SUCCESS,
  updatedAgent,
  editStatus,
});

export const agentTakeBreakError = (error: ServerError): CallCenterAction => ({
  type: ActionTypes.AGENT_TAKE_BREAK_ERROR,
  error,
});

export const agentTakeBreakClearError = (): CallCenterAction => ({
  type: ActionTypes.AGENT_TAKE_BREAK_CLEAR_ERROR,
});

export const agentTakeBreakClearEditStatus = (): CallCenterAction => ({
  type: ActionTypes.AGENT_TAKE_BREAK_CLEAR_EDIT_STATUS,
});
export const agentResumeShift = (agentId: string): CallCenterAction => ({
  type: ActionTypes.AGENT_RESUME_SHIFT,
  agentId,
});

export const agentResumeShiftSuccess = (
  updatedAgent: AgentRes,
  editStatus: EditStatus<any>,
): CallCenterAction => ({
  type: ActionTypes.AGENT_RESUME_SHIFT_SUCCESS,
  updatedAgent,
  editStatus,
});

export const agentResumeShiftError = (
  error: ServerError,
): CallCenterAction => ({
  type: ActionTypes.AGENT_RESUME_SHIFT_ERROR,
  error,
});

export const agentResumeShiftClearError = (): CallCenterAction => ({
  type: ActionTypes.AGENT_RESUME_SHIFT_CLEAR_ERROR,
});

export const agentResumeShiftClearEditStatus = (): CallCenterAction => ({
  type: ActionTypes.AGENT_RESUME_SHIFT_CLEAR_EDIT_STATUS,
});

export const agentUpdateTeamAndCampaign = (
  agentId: string,
  teamId: string,
  campaignId: string,
): CallCenterAction => ({
  type: ActionTypes.AGENT_UPDATE_TEAM_AND_CAMPAIGN,
  agentId,
  teamId,
  campaignId,
});

export const agentUpdateTeamAndCampaignSuccess = (
  updatedAgent: AgentRes,
): CallCenterAction => ({
  type: ActionTypes.AGENT_UPDATE_TEAM_AND_CAMPAIGN_SUCCESS,
  updatedAgent,
});

export const agentUpdateTeamAndCampaignError = (
  error: ServerError,
): CallCenterAction => ({
  type: ActionTypes.AGENT_UPDATE_TEAM_AND_CAMPAIGN_ERROR,
  error,
});

export const agentUpdateClearError = (): CallCenterAction => ({
  type: ActionTypes.AGENT_UPDATE_CLEAR_ERROR,
});
