import {
  AggregationFunction,
  MeasureSummaryRes,
  MeasureValueRes,
  TimePeriodType,
  UnitOfTime,
} from "@n3oltd/karakoram.analytics.sdk.measures/esm";

export enum ActionTypes {
  FETCH_MEASURES = "@dashboard/measures/request",
  FETCH_MEASURES_SUCCESS = "@dashboard/measures/request/success",
  FETCH_MEASURE_PREVIEW = "@dashboard/measures/preview/request",
  FETCH_MEASURES_PREVIEW_SUCCESS = "@dashboard/measures/preview/request/success",
  FETCH_PINNED_MEASURES = "@dashboard/measures/pinned/request",
  FETCH_PINNED_MEASURES_SUCCESS = "@dashboard/measures/pinned/request/success",
  PIN_MEASURE = "@dashboard/measures/pin/request",
  PIN_MEASURE_SUCCESS = "@dashboard/measures/pin/request/success",
  DELETE_PINNED_MEASURE = "@dashboard/measures/delete/request",
  DELETE_PINNED_MEASUR = "@dashboard/measures/delete/request",
  TOGGLE_MODAL = "@dashboard/measures/modal/toggle",
}

export interface FetchMeasures {
  type: ActionTypes.FETCH_MEASURES;
  category: string;
}

export interface FetchMeasuresSuccess {
  type: ActionTypes.FETCH_MEASURES_SUCCESS;
  data: MeasureSummaryRes[];
}

export interface FetchMeasurePreview {
  type: ActionTypes.FETCH_MEASURE_PREVIEW;
  measureId: string;
  options: {
    periodType: TimePeriodType;
    period: UnitOfTime;
    aggregation: AggregationFunction;
    multiplier: number;
  };
}

export interface FetchMeasuresPreviewSuccess {
  type: ActionTypes.FETCH_MEASURES_PREVIEW_SUCCESS;
  data: MeasureValueRes;
}

export interface FetchPinnedMeasures {
  type: ActionTypes.FETCH_PINNED_MEASURES;
}

export interface FetchPinnedMeasuresSuccess {
  type: ActionTypes.FETCH_PINNED_MEASURES_SUCCESS;
  data: MeasureSummaryRes[];
}

export interface PinMeasure {
  type: ActionTypes.PIN_MEASURE;
  measureId: string;
  options: {
    periodType: TimePeriodType;
    period: UnitOfTime;
    aggregation: AggregationFunction;
    multiplier: number;
  };
}

export interface PinMeasureSuccess {
  type: ActionTypes.PIN_MEASURE_SUCCESS;
}

export interface DeletePinnedMeasure {
  type: ActionTypes.DELETE_PINNED_MEASURE;
  measureId: string;
}

export interface ToggleModal {
  type: ActionTypes.TOGGLE_MODAL;
}
