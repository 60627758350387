import React, { useCallback, useState } from "react";

import { CallNotesReq, CallRes } from "@n3oltd/k2.calls.sdk.calls";
import { Form, Input } from "antd";

import {
  _callsAgentsClient,
  _callsClient,
} from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { GeneralFormItemV4 } from "components/formItems";
import { K2Modal } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

import ModalAlert from "./ModalAlert";

interface Props {
  k2Intl: InjectedK2Intl;
  visible: boolean;
  setOpenNotesModal: Function;
  onSaved?: Function;
  callId: string;
}

function NotesModal({
  k2Intl,
  visible,
  setOpenNotesModal,
  callId,
  onSaved,
}: Props) {
  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState([]);

  const addNotes = useCallback(async () => {
    form?.validateFields().then(async (values: CallNotesReq) => {
      setProcessing(true);
      let callRes: IApiResponse<CallRes> = await K2RestService.toResponse(
        _callsClient.getCallById(callId),
      );

      if (callRes.error) {
        UIUtils.handleError(k2Intl, callRes.error);
        setProcessing(false);
        return;
      }
      let resp: IApiResponse<void> = await K2RestService.toResponse(
        _callsClient.updateNotes(callRes.getResultOrDefault().revisionId, {
          text: values.text,
        }),
      );

      if (resp.error) {
        if (resp.error.status === K2StatusCodes.preconditionFailed) {
          // Handles validation errors which can be matched to a specific field
          // and sets them on the form
          UIUtils.handleValidationErrors(resp.error, form);

          const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
            resp.error,
            "error",
            "nonFieldRelated",
          );

          if (nonFieldRelatedErrors.length) {
            // Show these errors at the foot of the form
            setErrors(nonFieldRelatedErrors.map((err) => err.error));
          }
        } else {
          UIUtils.handleServerError(k2Intl, resp.error);
        }
        setProcessing(false);
        return;
      }
      setOpenNotesModal(false);
      setProcessing(false);
      form?.resetFields();
      onSaved?.();
    });
  }, [callId, form, k2Intl, onSaved, setOpenNotesModal]);
  return (
    <K2Modal
      titleKey="communication.cc.addNotes"
      visible={visible}
      onOk={() => {
        form?.submit();
      }}
      okTextKey="communication.cc.saveNotes"
      cancelTextKey="common.cancel"
      onCancel={() => {
        setOpenNotesModal(false);
        setProcessing(false);
        setErrors(null);
      }}
      okButtonProps={{
        loading: processing,
        disabled: processing,
      }}
      cancelButtonProps={{
        loading: processing,
        disabled: processing,
      }}
      withOutScroll
      centered
    >
      <Form form={form} onFinish={addNotes}>
        <GeneralFormItemV4
          name={["text"]}
          required
        >
          <Input.TextArea
            rows={4}
            placeholder={k2Intl.formatMessage({ localeKey: "common.notes" })}
          />
        </GeneralFormItemV4>
        {errors?.length > 0 && <ModalAlert errors={errors} />}
      </Form>
    </K2Modal>
  );
}

export default NotesModal;
