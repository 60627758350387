import styled from "styled-components";
import { Row, Col, Alert } from "antd";
import { K2Button, K2Card } from "components/k2Widgets";
import { MOBILE_SIZE } from "constants/ThemeSetting";

export const SchemesWrapper = styled.div<{ width?: number }>`
  margin: 25px 0;
  padding: ${({ width }) => (width && width <= MOBILE_SIZE ? "15px" : "0")};
  border-radius: 8px;
  background: ${({ theme }) => theme.white_color};
`;

export const ListSchemesRowWrapper = styled(Row)`
  margin-top: 2rem;
`;

export const ListSchemesColWrapper = styled(Col)``;

export const AddEditSchemeCardBodyWrapper = styled.div`
  & .no-padding {
    & > .ant-card-body {
      padding: 0;
    }
  }

  & .add-edit-scheme-steps {
    border-bottom: 1px solid ${({ theme }) => theme.grey_5};
  }
`;

export const StepBody = styled.div`
  padding: 5vh 10vw;
`;

export const ComponentCardWrapper = styled(K2Card)`
  & > .ant-card-body {
    & > .ant-row {
      margin: -9px -6px !important;
    }
  }

  &.moreThen1Component {
    & > .ant-card-body {
      & > .ant-row {
        margin: -9px -6px 9px !important;
      }
    }
  }
`;

export const RuleCardWrapper = styled(K2Card)`
  & > .ant-card-body {
    & > .ant-row {
      // margin: -9px -6px !important;
    }
  }
`;

export const AddComponentButton = styled(K2Button)`
  background-color: ${({ theme }) => theme.grey_2} !important;
  border-radius: 4px !important;

  & > span {
    color: ${({ theme }) => theme.primaryColor};
    font-weight: ${({ theme }) => theme.font_weight_bold};
    font-size: 14px;
    line-height: 22px;
  }
`;

export const FlexAlignCenterWrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-form-item-explain {
    margin-bottom: -21px;
  }
`;

export const OverallFormErrors = styled(Alert)`
  && {
    margin-bottom: 20px;
  }
`;
