import React, { FC, ReactText } from "react";

import {
  DonationItemRes,
  DonationItemResultsPage,
  DonationItemSummaryRes,
} from "@n3oltd/k2.donations.sdk.donation-items/esm";
import { FundStructureRevisionRes } from "@n3oltd/k2.subscriptions.sdk.funds/esm";
import { Col } from "antd";
import { ColumnProps, TablePaginationConfig } from "antd/es/table";
import { SorterResult, TableCurrentDataSource } from "antd/es/table/interface";

import { K2Routes } from "appRedux/models/routes/RouteModel";
import { K2Card, K2RouteHeading } from "components/k2Widgets";
import { ActionDropDownItem } from "components/k2Widgets/k2Dropdowns/ActionsDropDown";
import ActionGridMultiSelect from "components/k2Widgets/k2Grid/actionGridMultiselect";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { FormsWrapper, MainFormsWrapper } from "components/layout/wrappers";

import AddNewLink from "../../../components/AddNewLink/addNewLink";

interface DonationItemsListViewProps<T, K> extends InjectedK2IntlProps {
  isLoading: boolean;
  onLoadMore: () => void;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, ReactText[]>,
    sorter: SorterResult<T>,
    extra: TableCurrentDataSource<T>,
  ) => void;
  columns: ColumnProps<DonationItemRes>[];
  dataSource: DonationItemResultsPage;
  gridActions: ActionDropDownItem[];
  onActionExecuted: (
    action: string,
    keys: string[],
    allPagesSelected: boolean,
    onDone: () => void,
  ) => void;
}

const DonationItemsListView: FC<
  DonationItemsListViewProps<DonationItemSummaryRes, FundStructureRevisionRes>
> = ({
  isLoading,
  dataSource,
  columns,
  onActionExecuted,
  k2Intl,
  gridActions,
  onLoadMore,
  onChange,
}) => {
  return (
    <FormsWrapper>
      <K2RouteHeading headingKey={"admin.donationItems"} />
      <MainFormsWrapper>
        <Col span={24}>
          <K2Card
            bordered={false}
            shadow={true}
            localeKey="admin.donationItems"
            extra={
              <AddNewLink
                titleKey={"admin.addDonationItem"}
                to={K2Routes.createDonationItem}
              />
            }
          >
            <ActionGridMultiSelect<DonationItemSummaryRes>
              keyField="id"
              isLoading={isLoading}
              dataSource={dataSource}
              columns={columns}
              onActionExecuted={onActionExecuted}
              k2Intl={k2Intl}
              gridActions={gridActions}
              onLoadMore={onLoadMore}
              onChange={onChange}
              scroll={{ x: true }}
            />
          </K2Card>
        </Col>
      </MainFormsWrapper>
    </FormsWrapper>
  );
};

export default injectK2Intl(DonationItemsListView);
