import {
  OfficeRes,
  OrganizationInformationRes,
} from "@n3oltd/k2.subscriptions.sdk.organization/esm";
import { LocalizationSettingsRes } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { all, put, takeEvery } from "redux-saga/effects";

import { settingsActions, usersActions } from "..";
import {
  _subscriptionOrganizationsClient,
  _subscriptionsClient,
} from "../../../models/base/K2RestClients";
import K2Service from "../../../models/base/K2RestService";
import { IApiResponse } from "../../../models/common/ApiResponseModel";
import { DataActions, EditStatus } from "../../sharedTypes";

//#region WORKERS

function* getSubscriptionOrganization(
  action: ReturnType<typeof settingsActions.fetchSubscriptionOrganization>,
) {
  const response: IApiResponse<OrganizationInformationRes> = yield K2Service.toResponse(
    _subscriptionOrganizationsClient.getOrganizationInformation(),
  );

  yield put(settingsActions.setSubscriptionOrganization(response));
}

function* updateSubscriptionOrganization(
  action: ReturnType<typeof settingsActions.updateSubscriptionOrganization>,
) {
  const response: IApiResponse<void> = yield K2Service.toResponse(
    _subscriptionOrganizationsClient.updateOrganizationInformation(
      action.payload[0],
      action.payload[1],
    ),
  );

  if (response.error) {
    yield put(
      settingsActions.setSubscriptionOrganizationEditStatus({
        action: DataActions.save,
        ...response,
      }),
    );
  } else {
    yield put(
      settingsActions.setSubscriptionOrganizationEditStatus({
        action: DataActions.save,
        ...response,
      }),
    );

    yield put(settingsActions.fetchSubscriptionOrganization());
  }
}

function* addSubscriptionOrganizationOffice(
  action: ReturnType<typeof settingsActions.addSubscriptionOrganizationOffice>,
) {
  const response: IApiResponse<OfficeRes> = yield K2Service.toResponse(
    _subscriptionOrganizationsClient.addOffice(
      action.payload[0],
      action.payload[1],
    ),
  );

  yield put(
    settingsActions.setSubscriptionOfficesEditStatus({
      action: DataActions.add,
      ...response,
    }),
  );

  if (!response.error) {
    yield put(settingsActions.fetchSubscriptionOrganization());
  } else {
    yield put(settingsActions.fetchSubscriptionOrganization());
  }
}

function* updateSubscriptionOrganizationOffice(
  action: ReturnType<
    typeof settingsActions.updateSubscriptionOrganizationOffice
  >,
) {
  const response: IApiResponse<void> = yield K2Service.toResponse(
    _subscriptionOrganizationsClient.updateOffice(
      action.payload[0],
      action.payload[1],
      action.payload[2],
    ),
  );

  yield put(
    settingsActions.setSubscriptionOfficesEditStatus({
      action: DataActions.save,
      ...response,
    }),
  );

  if (!response.error) {
    yield put(settingsActions.fetchSubscriptionOrganization());
  } else {
    yield put(settingsActions.fetchSubscriptionOrganization());
  }
}

function* deleteSubscriptionOrganizationOffice(
  action: ReturnType<
    typeof settingsActions.deleteSubscriptionOrganizationOffice
  >,
) {
  const response: IApiResponse<void> = yield K2Service.toResponse(
    _subscriptionOrganizationsClient.removeOffice(
      action.payload[0],
      action.payload[1],
    ),
  );

  if (!response.error) {
    yield put(settingsActions.fetchSubscriptionOrganization());
    yield put(
      settingsActions.updateSubscriptionOrganizationOfficesListAfterDelete(
        action.payload[1],
      ),
    );
  } else {
    yield put(settingsActions.fetchSubscriptionOrganization());
  }

  yield put(
    settingsActions.setSubscriptionOrganizationEditStatus({
      action: DataActions.save,
    }),
  );
}

function* getSubscriptionLocalizations(
  action: ReturnType<typeof settingsActions.fetchSubscriptionLocalizations>,
) {
  const response: IApiResponse<LocalizationSettingsRes> = yield K2Service.toResponse(
    _subscriptionsClient.getLocalizationSettings(),
  );
  yield put(settingsActions.setSubscriptionLocalizations(response));
}

function* updateSubscriptionLocalizations(
  action: ReturnType<typeof settingsActions.updateSubscriptionLocalizations>,
) {
  const response: IApiResponse<void> = yield K2Service.toResponse(
    _subscriptionsClient.updateLocalizationSettings(
      action.payload[0],
      action.payload[1],
    ),
  );

  const editStatus: EditStatus<void> = {
    action: DataActions.save,
    ...response,
  };

  yield put(settingsActions.setSubscriptionLocalizationsEditStatus(editStatus));

  if (!response.error) {
    // Get the current user and subscription - only by getting the user AND the subscription
    // can we trigger the re-calculation of the app locale, which is based on the subscription
    // and the user settings (with user settings taking precedence)
    yield put(usersActions.getAppResources());
  }
}

//#endregion

//#region WATCHERS

export default function* settingsSaga() {
  yield all([
    yield takeEvery(
      settingsActions.fetchSubscriptionOrganization.type,
      getSubscriptionOrganization,
    ),
    yield takeEvery(
      settingsActions.updateSubscriptionOrganization.type,
      updateSubscriptionOrganization,
    ),
    yield takeEvery(
      settingsActions.addSubscriptionOrganizationOffice.type,
      addSubscriptionOrganizationOffice,
    ),
    yield takeEvery(
      settingsActions.updateSubscriptionOrganizationOffice.type,
      updateSubscriptionOrganizationOffice,
    ),
    yield takeEvery(
      settingsActions.deleteSubscriptionOrganizationOffice.type,
      deleteSubscriptionOrganizationOffice,
    ),
    yield takeEvery(
      settingsActions.fetchSubscriptionLocalizations.type,
      getSubscriptionLocalizations,
    ),
    yield takeEvery(
      settingsActions.updateSubscriptionLocalizations.type,
      updateSubscriptionLocalizations,
    ),
  ]);
}

//#endregion
