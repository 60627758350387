import React from "react";

import { Form } from "antd";
import { FormInstance, FormItemProps, Rule } from "antd/es/form";
import { NamePath } from "antd/lib/form/interface";
import TextArea, { TextAreaProps } from "antd/lib/input/TextArea";
import { AutoSizeType } from "rc-textarea/lib/ResizableTextArea";

import { errorKeys } from "appRedux/models/common/Constants";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

import injectK2Intl from "../k2Widgets/k2Localizations/injectK2Intl";
import GeneralFormItemV4 from "./GeneralFormItemV4";

export interface TextAreaFormItemProps {
  /**antd form form parent component props */
  form: FormInstance<any>;
  /**name of the field for data binding */
  fieldName: NamePath;
  /**locale key for label */
  labelKey?: string;
  /**initial value for textarea */
  initialValue?: string;
  /**required */
  required?: boolean;
  /**disabled */
  disabled?: boolean;
  /**Form.Item layout object */
  formItemLayout?: { wrapperCol: Object; labelCol?: Object };
  /**locale key for placeholder */
  placeholderKey?: string;
  /**Event to trigger the validation on
   * default: onBlur
   */
  validateTrigger?: string[];
  /** Set the focus on first render
   * default: false
   */
  autoFocus?: boolean;
  /**auto size for text area */
  autosize?: boolean | AutoSizeType;
  /**extra for form item */
  extra?: React.ReactNode;

  rows?: number;

  /** will use this key for showing error message */
  requiredValidationFeildName?: string;
}

const TextAreaFormItem = ({
  form,
  labelKey,
  fieldName,
  initialValue,
  required,
  disabled,
  formItemLayout,
  placeholderKey,
  validateTrigger,
  k2Intl,
  requiredValidationFeildName,
  localizationSettings, //just added here to exclude from remaining props so console don't show error
  ...restProps
}: TextAreaFormItemProps &
  InjectedK2IntlProps &
  FormItemProps &
  Omit<TextAreaProps, "form">) => {
  if (!form || !fieldName) {
    throw new Error(
      `'form' and 'fieldName' are required for the component to render! form: ${form}, fieldName: ${fieldName}`,
    );
  }

  let placeholder: string = k2Intl?.formatMessage({
    localeKey: placeholderKey,
  });
  let label: string = k2Intl?.formatMessage({ localeKey: labelKey });

  let validationRules: Rule[] = [];
  if (required) {
    validationRules.push({
      required: true,
      message: k2Intl?.formatMessage({
        localeKey: errorKeys.requiredValidationError,
        values: { fieldName: requiredValidationFeildName || label },
      }),
      whitespace: true,
    });
  }

  if (!initialValue) {
    initialValue = "";
  }

  return (
    <GeneralFormItemV4
      {...formItemLayout}
      label={label}
      {...restProps}
      name={fieldName}
      initialValue={initialValue}
      rules={validationRules}
    >
      <TextArea
        placeholder={placeholder}
        autoComplete="off"
        disabled={disabled}
        {...restProps}
      />
    </GeneralFormItemV4>
  );
};

export default injectK2Intl(TextAreaFormItem);
