import React, { FC, useCallback, useEffect, useState } from "react";

import { CloseCircleOutlined } from "@ant-design/icons/lib";
import { AccountRes } from "@n3oltd/k2.accounts.sdk.accounts/esm";
import {
  GlobalSuggestionsEntity as AccountGlobalSuggestionsEntity,
  GlobalSuggestionsResultsList as AccountsGlobalSuggestionsResultsList,
  GlobalSuggestion,
} from "@n3oltd/k2.accounts.sdk.global-suggestions/esm";
import { DonationRes } from "@n3oltd/k2.donations.sdk.donations/esm";
import { GivingRes } from "@n3oltd/k2.donations.sdk.giving";
import {
  GlobalSuggestionsEntity as DonationGlobalSuggestionsEntity,
  GlobalSuggestionsResultsList as DonationsGlobalSuggestionsResultsList,
} from "@n3oltd/k2.donations.sdk.global-suggestions/esm";
import {
  GlobalSuggestionsEntity as PledgeGlobalSuggestionsEntity,
  GlobalSuggestionsResultsList as PledgesGlobalSuggestionsResultsList,
} from "@n3oltd/k2.pledges.sdk.global-suggestions/esm";
import { PledgeRes } from "@n3oltd/k2.pledges.sdk.pledges/esm";
import { FeedbackRes } from "@n3oltd/karakoram.feedbacks.sdk.feedbacks";
import {
  GlobalSuggestionsEntity as FeedbackGlobalSuggestionsEntity,
  GlobalSuggestionsResultsList as FeedbacksGlobalSuggestionsResultsList,
} from "@n3oltd/karakoram.feedbacks.sdk.global-suggestions/esm";
import {
  GlobalSuggestionsEntity as SponsorshipGlobalSuggestionsEntity,
  GlobalSuggestionsResultsList as SponsorshipsGlobalSuggestionsResultsList,
} from "@n3oltd/karakoram.sponsorships.sdk.global-suggestions/esm";
import { SponsorshipRes } from "@n3oltd/karakoram.sponsorships.sdk.sponsorships/esm";
import { LinkedEntityTypeRes } from "@n3oltd/karakoram.tasks.sdk.lookups/esm";
import { LinkedEntityType } from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Form, Select, Tooltip } from "antd";
import styled from "styled-components";

import {
  _accountsClient,
  _accountsGlobalSuggestionsClient,
  _donationsClient,
  _donationsGlobalSuggestionsClient,
  _feedbacksClient,
  _feedbacksGlobalSuggestionsClient,
  _givingClient,
  _pledgesClient,
  _pledgesGlobalSuggestionsClient,
  _sponsorshipsClient,
  _sponsorshipsGlobalSuggestionsClient,
} from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import variables from "common/themeVariables";
import { K2Link, K2Message } from "components/k2Widgets";
import { IN3OIconType } from "components/n3oIcon/types";
import { DEFAULT_DELAY } from "constants/appConstants";
import useDebounce from "hooks/useDebounce";

import { InjectedK2IntlProps } from "../../k2Widgets/k2Localizations/types";
import { N3oIcon } from "../../n3oIcon";
import { UIUtils } from "../../utils";
import "./styles.less";

const { viewAccount } = K2Routes;

const { Option } = Select;

const LinkTypeSelect = styled(Select)<{ $editMode: boolean }>`
  && {
    width: 20px;
    right: 20px;
    position: absolute !important;

    .ant-select-selection-item {
      padding-right: 13px;
      text-overflow: clip;
      .related-link-type-title {
        display: none;
      }
    }
    .ant-select-selector {
      border: none !important;
      height: 36px !important;
      margin: 1px 0px !important;
      box-shadow: none !important;
      min-height: unset !important;
    }
    .ant-select-selection {
      box-shadow: none !important;
      background-color: transparent;
    }
    .anticon {
      padding-right: 8px;
      width: 22px;
    }
  }
`;

export type ExtendedGlobalSuggestion = GlobalSuggestion & {
  linkedId?: string;
  type: LinkedEntityType;
};

interface IProps extends InjectedK2IntlProps {
  title?: JSX.Element;
  selectedValue?: ExtendedGlobalSuggestion;
  selectedType: LinkedEntityType;
  onChange: (entity?: ExtendedGlobalSuggestion) => void;
  linkedEntityTypes: LinkedEntityTypeRes[];
  defaultEntity?: ExtendedGlobalSuggestion;
  setFormTouched?: () => void;
  editMode: boolean;
}

const EditRelatedLink: FC<IProps> = (props) => {
  const {
    title,
    k2Intl,
    selectedType,
    selectedValue,
    defaultEntity,
    onChange,
    setFormTouched,
    linkedEntityTypes,
  } = props;

  const [
    fetchingDefaultSelected,
    setFetchingDefaultSelected,
  ] = useState<boolean>(false);
  const [valueChanged, setValueChanged] = useState(false);
  const [searchTerm, updateSearch] = useState("");
  const [searchResults, setSearchResults] = useState<
    ExtendedGlobalSuggestion[]
  >([]);
  const [searchResultsLoading, setSearchResultsLoading] = useState<boolean>(
    false,
  );
  const [searchType, updateSearchType] = useState(
    selectedType || LinkedEntityType.Account,
  );

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DELAY);

  const fetchSelectedAccountAsDefaultEntity = useCallback(
    async (accountId: string) => {
      setFetchingDefaultSelected(true);

      const response: IApiResponse<AccountRes> = await K2RestService.toResponse(
        _accountsClient.getAccountById(accountId),
      );
      if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else {
        const account = response.getResultOrDefault();
        const name =
          account.type === "organization"
            ? account.organization?.name
            : account.individual?.name?.fullName;
        setSearchResults([
          {
            id: account.id,
            title: `${account.reference?.text}${name ? `: ${name}` : ""}`,
            type: LinkedEntityType.Account,
          },
        ]);
        onChange({
          id: account.id,
          type: LinkedEntityType.Account,
          title: `${account.reference?.text}${name ? `: ${name}` : ""}`,
        });
      }

      setFetchingDefaultSelected(false);
    },
    [k2Intl, onChange],
  );

  const fetchSelectedSponsorshipAsDefaultEntity = useCallback(
    async (sponsorshipId: string) => {
      setFetchingDefaultSelected(true);

      const response: IApiResponse<SponsorshipRes> = await K2RestService.toResponse(
        _sponsorshipsClient.getSponsorshipById(sponsorshipId),
      );
      if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else {
        const sponsorship = response.getResultOrDefault();
        setSearchResults([
          {
            id: sponsorship.id,
            title: `${sponsorship.reference?.text}: ${sponsorship.beneficiary?.name}`,
            linkedId: sponsorship?.account?.id || undefined,
            type: LinkedEntityType.Sponsorship,
          },
        ]);
        onChange({
          id: sponsorship.id,
          type: LinkedEntityType.Sponsorship,
          title: `${sponsorship.reference?.text}: ${sponsorship.beneficiary?.name}`,
        });
      }

      setFetchingDefaultSelected(false);
    },
    [k2Intl, onChange],
  );

  const fetchSelectedDonationAsDefaultEntity = useCallback(
    async (donationId: string) => {
      setFetchingDefaultSelected(true);

      const response: IApiResponse<DonationRes> = await K2RestService.toResponse(
        _donationsClient.getDonationById(donationId),
      );
      if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else {
        const donation = response.getResultOrDefault();
        setSearchResults([
          {
            id: donation.id,
            title: `${donation.reference?.text}: ${donation.total?.quote?.text}`,
            linkedId: donation.account?.id,
            type: LinkedEntityType.Donation,
          },
        ]);
        onChange({
          id: donation.id,
          type: LinkedEntityType.Donation,
          title: `${donation.reference?.text}: ${donation.total?.quote?.text}`,
        });
      }

      setFetchingDefaultSelected(false);
    },
    [k2Intl, onChange],
  );

  const fetchSelectedGivingAsDefaultEntity = useCallback(
    async (givingId: string) => {
      setFetchingDefaultSelected(true);

      const response: IApiResponse<GivingRes> = await K2RestService.toResponse(
        _givingClient.getGivingById(givingId),
      );
      if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else {
        const giving = response.getResultOrDefault();
        setSearchResults([
          {
            id: giving.id,
            title: `${giving.reference?.text}: ${giving.value?.text}`,
            linkedId: giving.account?.id,
            type: LinkedEntityType.RegularGiving,
          },
        ]);
        onChange({
          id: giving.id,
          type: LinkedEntityType.RegularGiving,
          title: `${giving.reference?.text}: ${giving.value.text}`,
        });
      }

      setFetchingDefaultSelected(false);
    },
    [k2Intl, onChange],
  );

  const fetchSelectedPledgeAsDefaultEntity = useCallback(
    async (pledgeId: string) => {
      setFetchingDefaultSelected(true);

      const response: IApiResponse<PledgeRes> = await K2RestService.toResponse(
        _pledgesClient.getPledgeById(pledgeId),
      );
      if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else {
        const pledge = response.getResultOrDefault();
        setSearchResults([
          {
            id: pledge.id,
            title: `${pledge.reference?.text}: ${pledge.value?.quote?.text}`,
            linkedId: pledge.account?.id,
            type: LinkedEntityType.Pledge,
          },
        ]);
        onChange({
          id: pledge.id,
          type: LinkedEntityType.Pledge,
          title: `${pledge.reference?.text}: ${pledge.value?.quote?.text}`,
        });
      }

      setFetchingDefaultSelected(false);
    },
    [k2Intl, onChange],
  );

  const fetchSelectedFeedbackAsDefaultEntity = useCallback(
    async (feedbackId: string) => {
      setFetchingDefaultSelected(true);

      const response: IApiResponse<FeedbackRes> = await K2RestService.toResponse(
        _feedbacksClient.getFeedbackById(feedbackId),
      );
      if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else {
        const feedback = response.getResultOrDefault();
        setSearchResults([
          {
            id: feedback.id,
            title: feedback.reference?.text,
            linkedId: feedback?.account?.id || undefined,
            type: LinkedEntityType.Feedback,
          },
        ]);
        onChange({
          id: feedback.id,
          type: LinkedEntityType.Feedback,
          title: feedback.reference?.text,
        });
      }

      setFetchingDefaultSelected(false);
    },
    [k2Intl, onChange],
  );

  const handleChange = useCallback(
    (id?: string) => {
      setValueChanged(true);

      const selectedItem = searchResults.find((item) => item.id === id);
      if (!selectedItem) {
        setSearchResults([]);
        updateSearch("");
      }

      onChange(
        selectedItem
          ? {
              type: searchType as LinkedEntityType,
              id: selectedItem.id,
              title: selectedItem.title,
            }
          : null,
      );
      setFormTouched?.();
    },
    [onChange, setFormTouched, searchResults, searchType],
  );

  // If a default entity is selected, we should fetch that entity and render it as the initial value
  useEffect(() => {
    if (defaultEntity) {
      switch (defaultEntity.type) {
        case LinkedEntityType.Account:
          fetchSelectedAccountAsDefaultEntity(defaultEntity.id);
          break;
        case LinkedEntityType.Sponsorship:
          fetchSelectedSponsorshipAsDefaultEntity(defaultEntity.id);
          break;
        case LinkedEntityType.Donation:
          fetchSelectedDonationAsDefaultEntity(defaultEntity.id);
          break;
        case LinkedEntityType.Pledge:
          fetchSelectedPledgeAsDefaultEntity(defaultEntity.id);
          break;
        case LinkedEntityType.Feedback:
          fetchSelectedFeedbackAsDefaultEntity(defaultEntity.id);
          break;
        case LinkedEntityType.RegularGiving:
          fetchSelectedGivingAsDefaultEntity(defaultEntity.id);
          break;
      }

      handleChange(defaultEntity.id);
    }
    // eslint-disable-next-line
  }, [defaultEntity]);

  const search = useCallback(
    async (searchText: string) => {
      setSearchResultsLoading(true);

      let response: IApiResponse<
        | AccountsGlobalSuggestionsResultsList
        | DonationsGlobalSuggestionsResultsList
        | PledgesGlobalSuggestionsResultsList
        | SponsorshipsGlobalSuggestionsResultsList
        | FeedbacksGlobalSuggestionsResultsList
      > = null;

      switch (searchType) {
        case LinkedEntityType.Account:
          response = await K2RestService.toResponse(
            _accountsGlobalSuggestionsClient.suggest({
              searchText,
              entity: AccountGlobalSuggestionsEntity.Account,
            }),
          );
          break;
        case LinkedEntityType.Sponsorship:
          response = await K2RestService.toResponse(
            _sponsorshipsGlobalSuggestionsClient.suggest({
              searchText,
              entity: SponsorshipGlobalSuggestionsEntity.Sponsorship,
            }),
          );
          break;
        case LinkedEntityType.Donation:
          response = await K2RestService.toResponse(
            _donationsGlobalSuggestionsClient.suggest({
              searchText,
              entity: DonationGlobalSuggestionsEntity.Donation,
            }),
          );
          break;
        case LinkedEntityType.RegularGiving:
          response = await K2RestService.toResponse(
            _donationsGlobalSuggestionsClient.suggest({
              searchText,
              entity: DonationGlobalSuggestionsEntity.Giving,
            }),
          );
          break;
        case LinkedEntityType.Pledge:
          response = await K2RestService.toResponse(
            _pledgesGlobalSuggestionsClient.suggest({
              searchText,
              entity: PledgeGlobalSuggestionsEntity.Pledge,
            }),
          );
          break;
        case LinkedEntityType.Feedback:
          response = await K2RestService.toResponse(
            _feedbacksGlobalSuggestionsClient.suggest({
              searchText,
              entity: FeedbackGlobalSuggestionsEntity.Feedbacks,
            }),
          );
          break;
        default:
          break;
      }

      if (response?.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else {
        setSearchResults(
          (response.getResultOrDefault().items as Array<GlobalSuggestion>).map(
            (i) => ({
              ...i,
              type: searchType as LinkedEntityType,
            }),
          ),
        );
      }

      setSearchResultsLoading(false);
    },
    [k2Intl, searchType],
  );

  // This effect runs whenever debouncedSearchTerm changes
  useEffect(() => {
    if (debouncedSearchTerm) {
      if (debouncedSearchTerm.length >= 4) {
        search(debouncedSearchTerm);
      }
    }
  }, [debouncedSearchTerm, search]);

  return (
    <Form.Item
      label={title}
      className="n3o-pt-9px n3o-pb-8px"
      shouldUpdate
      labelCol={{ xs: 24 }}
      wrapperCol={{ xs: 24 }}
      style={{ paddingBottom: 0 }}
    >
      <div>
        <Select
          showSearch
          allowClear
          placeholder={
            <K2Message
              localeKey={`tasks.action.relatedLink.${searchType}.placeholder`}
            />
          }
          loading={searchResultsLoading}
          filterOption={false}
          onSearch={updateSearch}
          style={{ width: "100%" }}
          value={valueChanged ? selectedValue?.id : selectedValue?.title}
          onSelect={handleChange}
          optionLabelProp={"children"}
          clearIcon={<CloseCircleOutlined onClick={() => handleChange()} />}
          suffixIcon={null}
        >
          {searchResults?.map?.((item: ExtendedGlobalSuggestion) => {
            let link = "";

            switch (searchType) {
              case LinkedEntityType.Account:
                link = viewAccount.replace(":accountId", item.id);
                break;
              case LinkedEntityType.Feedback:
              case LinkedEntityType.Donation:
              case LinkedEntityType.Pledge:
              case LinkedEntityType.RegularGiving:
              case LinkedEntityType.Sponsorship:
                link = item.url;
                break;
              default:
                break;
            }

            return (
              <Option
                value={item.id}
                key={item.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="n3o-task-link-option-item"
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{item.title}</span>
                </span>
                <span className="n3o-no-display">
                  <K2Link
                    to={link}
                    style={{ fontSize: "smaller" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <K2Message localeKey="common.viewLink" />{" "}
                    <N3oIcon
                      icontype="n3o-external-link"
                      height={14}
                      width={14}
                    />
                  </K2Link>
                </span>
              </Option>
            );
          })}
          {searchResults.length === 0 && !searchResultsLoading && (
            <Option value={"fake-key"} key="fake-key" disabled>
              {searchTerm.length < 4 ? (
                <K2Message localeKey="common.keepTyping" />
              ) : (
                <K2Message
                  localeKey="common.noResults"
                  values={{ value: searchTerm }}
                />
              )}
            </Option>
          )}
        </Select>
        <Tooltip
          title={<K2Message localeKey={"tasks.action.relatedLink.select"} />}
          placement={"right"}
        >
          <LinkTypeSelect
            dropdownAlign={{
              points: ["bl", "tl"],
              offset: [-130, -4],
              overflow: {
                adjustX: 0,
                adjustY: 1,
              },
            }}
            $editMode={props.editMode}
            defaultValue={searchType}
            dropdownMatchSelectWidth={false}
            optionLabelProp={"children"}
            showArrow
            onChange={(value: string) => {
              setSearchResults([]);
              updateSearch("");
              updateSearchType(value as LinkedEntityType);
              onChange(null);
            }}
          >
            {linkedEntityTypes?.map?.((entityType) => (
              <Option value={entityType.id} key={entityType.id}>
                <N3oIcon
                  icontype={entityType.icon as IN3OIconType}
                  fill={variables.grey_7}
                  height={14}
                  width={14}
                />{" "}
                {
                  <K2Message
                    spanClassName={"related-link-type-title"}
                    localeKey={`common.entity.${entityType.id}`}
                  />
                }
              </Option>
            ))}
          </LinkTypeSelect>
        </Tooltip>
      </div>
    </Form.Item>
  );
};

export default EditRelatedLink;
