import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const EngageIcon: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5.4C0 2.41766 2.41766 0 5.4 0H30.6C33.5823 0 36 2.41766 36 5.4V30.6C36 33.5823 33.5823 36 30.6 36H5.4C2.41766 36 0 33.5823 0 30.6V5.4Z"
      fill="white"
    />
    <path
      d="M0 5.4C0 2.41766 2.41766 0 5.4 0H30.6C33.5823 0 36 2.41766 36 5.4V30.6C36 33.5823 33.5823 36 30.6 36H5.4C2.41766 36 0 33.5823 0 30.6V5.4Z"
      stroke="#37A0F4"
    />
    <path
      d="M20.4186 26.9995L15.2888 22.9396V9L20.4186 13.0599V26.9995Z"
      fill="#37A0F4"
      // fill={fill}
    />
    <path
      d="M20.418 26.9995L26.9979 21.5996V14.8098L20.418 20.4197V26.9995Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M9 14.1499V20.8697L15.2898 15.8298V9L9 14.1499Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="26.64"
        y1="18.7106"
        x2="21.5182"
        y2="24.0252"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37A0F4" />
        <stop offset="1" stopColor="#0071CC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="11.52"
        y1="16.29"
        x2="17.28"
        y2="10.53"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37A0F4" />
        <stop offset="1" stopColor="#0071CC" />
      </linearGradient>
    </defs>
  </svg>
);
