import React, { FC } from "react";
import styled from "styled-components";
import { Switch, Route, Redirect } from "react-router-dom";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { RequireAuth } from "containers/app/views";
import { asyncComponent } from "components/utils";

const { searchPayments, viewPayment } = K2Routes;

const Wrapper = styled.div`
  & .async-component {
    margin-top: 25px;
  }
`;

const PaymentsSearchWithAuth = RequireAuth(
  asyncComponent(() =>
    import(
      /* webpackChunkName: "PaymentsSearch" */ "containers/paymentsSearch"
    ),
  ),
  "searchPayments",
);

const ViewPaymentWithAuth = RequireAuth(
  asyncComponent(() =>
    import(/* webpackChunkName: "ViewPayment" */ "containers/viewPayment"),
  ),
  "viewPayment",
);

const PaymentsRoutes: FC = () => (
  <Wrapper>
    <Switch>
      <Route exact path={searchPayments} component={PaymentsSearchWithAuth} />
      <Route exact path={viewPayment} component={ViewPaymentWithAuth} />
      <Redirect to={searchPayments} />
    </Switch>
  </Wrapper>
);

export default PaymentsRoutes;
