import { UserReq, UserRes } from "@n3oltd/k2.users.sdk.users/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

export enum ActionTypes {
  FETCH_SELECTED_EDIT_USER = "@createEditUserAdmin/fetch-user/request",

  FETCH_SELECTED_EDIT_USER_SUCCESS = "@createEditUserAdmin/fetch-user/success",

  FETCH_SELECTED_EDIT_USER_ERROR = "@createEditUserAdmin/fetch-user/error",

  CLEAR_FETCH_SELECTED_EDIT_USER_ERROR = "@createEditUserAdmin/fetch-user/clear-error",

  CLEAR_SELECTED_EDIT_USER = "@createEditUserAdmin/clear-selected-user",

  CLEAR_EDIT_STATUS = "@createEditUserAdmin/clear-edit-status",
  UPDATE_SELECTED_EDIT_USER = "@createEditUserAdmin/update-user/request",

  UPDATE_SELECTED_EDIT_USER_SUCCESS = "@createEditUserAdmin/update-user/success",

  UPDATE_SELECTED_EDIT_USER_ERROR = "@createEditUserAdmin/update-user/error",

  CREATE_USER = "@createEditUserAdmin/create-user/request",

  CREATE_USER_SUCCESS = "@createEditUserAdmin/create-user/success",

  CREATE_USER_ERROR = "@createEditUserAdmin/create-user/error",

  ACTIVATE_USER = "@createEditUserAdmin/activate/request",

  ACTIVATE_USER_SUCCESS = "@createEditUserAdmin/activate/success",

  ACTIVATE_USER_ERROR = "@createEditUserAdmin/activate/error",

  DEACTIVATE_USER = "@createEditUserAdmin/deactivate/request",

  DEACTIVATE_USER_SUCCESS = "@createEditUserAdmin/deactivate/success",

  DEACTIVATE_USER_ERROR = "@createEditUserAdmin/deactivate/error",

  UPDATE_USER_STATUS = "@createEditUserAdmin/update-status",

  UPDATE_ADMIN_PRIVILEGE = "@createEditUserAdmin/update-admin/request",

  UPDATE_ADMIN_PRIVILEGE_SUCCESS = "@createEditUserAdmin/update-admin/success",

  UPDATE_ADMIN_PRIVILEGE_ERROR = "@createEditUserAdmin/update-admin/error",
}

export interface FetchSelectedEditUser {
  type: ActionTypes.FETCH_SELECTED_EDIT_USER;
  id: string;
  shouldUpdateGlobalUser?: boolean;
}

export interface FetchSelectedEditUserSuccess {
  type: ActionTypes.FETCH_SELECTED_EDIT_USER_SUCCESS;
  user: UserRes;
}

export interface FetchSelectedEditUserError {
  type: ActionTypes.FETCH_SELECTED_EDIT_USER_ERROR;
  error: ServerError;
}

export interface ClearSelectedEditUserError {
  type: ActionTypes.CLEAR_FETCH_SELECTED_EDIT_USER_ERROR;
}

export interface ClearSelectedEditUser {
  type: ActionTypes.CLEAR_SELECTED_EDIT_USER;
}

export interface clearEditStatus {
  type: ActionTypes.CLEAR_EDIT_STATUS;
}

export interface UpdateSelectedEditUser {
  type: ActionTypes.UPDATE_SELECTED_EDIT_USER;
  id: string;
  revisionId: string;
  user: UserReq;
  isCurrentUser: boolean;
}

export interface UpdateSelectedEditUserSuccess {
  type: ActionTypes.UPDATE_SELECTED_EDIT_USER_SUCCESS;
  editStatus: EditStatus;
}

export interface UpdateSelectedEditUserError {
  type: ActionTypes.UPDATE_SELECTED_EDIT_USER_ERROR;
  editStatus: EditStatus;
}

export interface CreateUser {
  type: ActionTypes.CREATE_USER;
  user: UserReq;
}

export interface CreateUserSuccess {
  type: ActionTypes.CREATE_USER_SUCCESS;
  editStatus: EditStatus;
  user: UserRes;
}

export interface CreateUserError {
  type: ActionTypes.CREATE_USER_ERROR;
  editStatus: EditStatus;
}

export interface ActivateUser {
  type: ActionTypes.ACTIVATE_USER;
  revisionId: string;
}

export interface ActivateUserSuccess {
  type: ActionTypes.ACTIVATE_USER_SUCCESS;
  editStatus: EditStatus;
}

export interface ActivateUserError {
  type: ActionTypes.ACTIVATE_USER_ERROR;
  editStatus: EditStatus;
}

export interface DeactivateUser {
  type: ActionTypes.DEACTIVATE_USER;
  revisionId: string;
}

export interface DeactivateUserSuccess {
  type: ActionTypes.DEACTIVATE_USER_SUCCESS;
  editStatus: EditStatus;
}

export interface DeactivateUserError {
  type: ActionTypes.DEACTIVATE_USER_ERROR;
  editStatus: EditStatus;
}

export interface UpdateAdminPrivilege {
  type: ActionTypes.UPDATE_ADMIN_PRIVILEGE;
  revisionId: string;
  admin: boolean;
}

export interface UpdateAdminPrivilegeSuccess {
  type: ActionTypes.UPDATE_ADMIN_PRIVILEGE_SUCCESS;
  isAdmin: boolean;
  editStatus: EditStatus;
}

export interface UpdateAdminPrivilegeError {
  type: ActionTypes.UPDATE_ADMIN_PRIVILEGE_ERROR;
  editStatus: EditStatus;
}

export type CreateEditUserAction =
  | FetchSelectedEditUser
  | FetchSelectedEditUserSuccess
  | FetchSelectedEditUserError
  | ClearSelectedEditUserError
  | ClearSelectedEditUser
  | clearEditStatus
  | UpdateSelectedEditUser
  | UpdateSelectedEditUserSuccess
  | UpdateSelectedEditUserError
  | CreateUser
  | CreateUserSuccess
  | CreateUserError
  | ActivateUser
  | ActivateUserSuccess
  | ActivateUserError
  | DeactivateUser
  | DeactivateUserSuccess
  | DeactivateUserError
  | UpdateAdminPrivilege
  | UpdateAdminPrivilegeSuccess
  | UpdateAdminPrivilegeError;
