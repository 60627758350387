import React from "react";

import {
  DeleteOutlined,
  EditOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { BookmarkRes } from "@n3oltd/karakoram.analytics.sdk.reports/esm";
import { Menu } from "antd";
import styled from "styled-components";

import { K2Message } from "components/k2Widgets";

const MenuWrapper = styled(Menu)`
  min-width: 125px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
`;
interface IProps {
  bookmark: BookmarkRes;
  handleEditBookmark: (bookmark: BookmarkRes) => void;
  handleShareBookmark: (bookmark: BookmarkRes) => void;
  handleDeleteBookmark: (
    bookmarkId: string,
    bookmarkRevisionId: string,
    bookmarkName: string,
  ) => void;
}

const Options = (props: IProps) => {
  const {
    bookmark,
    handleEditBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
  } = props;

  return (
    <MenuWrapper>
      <Menu.Item
        key="0"
        onClick={() => handleEditBookmark(bookmark)}
        disabled={!bookmark?.canEdit}
      >
        <EditOutlined />
        <K2Message localeKey={"common.edit"} />
      </Menu.Item>
      <Menu.Item key="1" onClick={() => handleShareBookmark(bookmark)}>
        <ShareAltOutlined />
        <K2Message localeKey={"common.share"} />
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() =>
          handleDeleteBookmark(bookmark.id, bookmark.revisionId, bookmark.name)
        }
        disabled={!bookmark?.canDelete}
      >
        <DeleteOutlined />
        <K2Message localeKey={"common.delete"} />
      </Menu.Item>
    </MenuWrapper>
  );
};

export default Options;
