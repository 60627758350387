import { ServerError } from "../models/common/ApiResponseModel";
import {
  LanguageLookups,
  TimezoneLookups,
  DateFormatLookups,
  TimeFormatLookups,
  NumberFormatLookups,
} from "./lookups/types";

export type GenericProps = {
  [key: string]: any;
};

export interface IEntity {
  id?: string;
}

export type K2FormMode = "new" | "edit";

export interface IItemManagement<T> {
  itemsList?: T[];
  selectedItem?: T;
  selectedItemId?: string;
  isLoading: boolean;
  editStatus?: EditStatus;
  serverError?: ServerError;
}

export interface IFindResultItem<T> {
  listData?: ResultsPage<T>;
}

interface IEditResult {
  id?: string;
  //temp fix for auto rest client response (for create account)
  body?: {
    id?: string;
  };
}

export interface EditStatus<T = IEditResult, K = never> {
  action: DataActions;
  response?: T;
  result?: T;
  additionalData?: K;
  error?: ServerError;
}

export interface ResultsPage<T> {
  continuationToken?: string;
  items?: Array<T>;
}

export enum DataActions {
  save = 0,
  delete = 1,
  statusUpdate = 3,
  export = 4,
  import = 5,
  add = 8,
  update = 9,
  activate = 10,
  deactivate = 11,
  merge = 12,
}

interface ICriteria {
  sort?: any; // "name asc, date desc"
}

export interface IPagingCriteria extends ICriteria {
  pageSize: number;
  continuationToken: string;
}

export interface IFundDimensionsData<T> {
  dimension1: T;
  dimension2: T;
  dimension3: T;
  dimension4: T;
}

export interface IResultsPage<T> {
  continuationToken?: string;
  items?: Array<T>;
}

export interface ILocalizationData {
  languages?: LanguageLookups;
  timezones?: TimezoneLookups;
  dateFormats?: DateFormatLookups;
  timeFormats?: TimeFormatLookups;
  numberFormats?: NumberFormatLookups;
}

export interface IRangeFilter<T> {
  from: T;
  to: T;
}

export type IValidationStatus =
  | ""
  | "success"
  | "warning"
  | "error"
  | "validating";

export interface FieldErrorBody {
  field?: string;
  message?: string;
}

export interface FieldErrorsList {
  errors?: Array<FieldErrorBody>;
}

export interface FeatureFlags {
  // We are currently not using LaunchDarkly so this is commented to ensure we do not attempt to use these feature flags
  // gdpr?: boolean;
  // movePaymentsBetweenDeposits?: boolean;
}

export interface K2MenuItem {
  principalPath: string; // Option to be shown in the side menu
  matchingPaths: string[]; // All possible paths (e.g. edit/create paths) which should also match
  titleKey: string;
  children?: K2MenuItem[];
  hideFromProd?: boolean;
}

export type K2Menu<T extends string> = {
  [key in T]: K2MenuItem[];
};

export type AppId = "q1" | "k2";
export type AppName = "Tally" | "Engage";
