import React, { FC, useCallback } from "react";
import FakeLink from "components/k2Widgets/k2FakeLink";
import { DownloadOutlined, FileTwoTone } from "@ant-design/icons/lib";
import DeleteIcon from "components/deleteIcon";
import styled from "styled-components";
import { UploadFile } from "antd/es/upload/interface";

const CustomFileItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.grey_4};
  border-radius: 4px;
  padding: 14px 10px;
  margin-bottom: 10px;
  .imageAndName {
    display: flex;
    align-items: center;
  }
  .preview {
    margin-right: 10px;
    img {
      display: block;
      width: 30px;
      height: 30px;
      overflow: hidden;
    }
  }
`;

interface UploadsListItemProps {
  file: UploadFile;
  onDownload?: (file: UploadFile) => void;
  onRemove?: (file: UploadFile) => void;
  imgThumbnailUrl?: string;
  isImage?: boolean;
}

const UploadsListItem: FC<UploadsListItemProps> = ({
  imgThumbnailUrl,
  file,
  onDownload,
  onRemove,
  isImage,
}) => {
  return (
    <CustomFileItem>
      <div className={"imageAndName"}>
        <div className={"preview"}>
          {isImage && imgThumbnailUrl ? (
            <img src={imgThumbnailUrl} alt={file.fileName} />
          ) : (
            <FileTwoTone style={{ fontSize: 27 }} />
          )}
        </div>
        <div>
          <FakeLink onClick={() => onDownload(file)}>{file.fileName}</FakeLink>
        </div>
      </div>
      <div>
        {onDownload && (
          <DownloadOutlined
            onClick={() => onDownload(file)}
            style={{ marginRight: 10 }}
          />
        )}
        {onRemove && <DeleteIcon onClick={() => onRemove(file)} />}
      </div>
    </CustomFileItem>
  );
};

export default UploadsListItem;
