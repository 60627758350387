import {
  EmailCompositionReq,
  EmailCompositionRes,
} from "@n3oltd/karakoram.templates.sdk.email/esm";
import { RcFile } from "antd/lib/upload";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";
import { TemporaryUploadFile } from "routes/admin/communication/modules/types";
import { AssetType } from "routes/admin/communication/newEditEmailTemplate/modules/types";

export enum ActionTypes {
  FETCH_EMAIL_TEMPLATE = "@engage/admin/communication/email-template/request",
  FETCH_EMAIL_TEMPLATE_SUCCESS = "@engage/admin/communication/email-template/success",
  FETCH_EMAIL_TEMPLATE_ERROR = "@engage/admin/communication/email-template/error",
  CLEAR_EMAIL_TEMPLATE = "@engage/admin/communication/email-template/clear",
  CLEAR_EMAIL_TEMPLATE_ERROR = "@engage/admin/communication/email-template/clear-error",

  UPDATE_EMAIL_TEMPLATE = "@engage/admin/communication/email-template/update/request",
  UPDATE_EMAIL_TEMPLATE_SUCCESS = "@engage/admin/communication/email-template/update/success",
  UPDATE_EMAIL_TEMPLATE_ERROR = "@engage/admin/communication/email-template/update/error",

  CREATE_EMAIL_TEMPLATE = "@engage/admin/communication/email-template/create/request",
  CREATE_EMAIL_TEMPLATE_SUCCESS = "@engage/admin/communication/email-template/create/success",
  CREATE_EMAIL_TEMPLATE_ERROR = "@engage/admin/communication/email-template/create/error",

  DELETE_EMAIL_TEMPLATE = "@engage/admin/communication/email-template/delete/request",
  DELETE_EMAIL_TEMPLATE_SUCCESS = "@engage/admin/communication/email-template/delete/success",
  DELETE_EMAIL_TEMPLATE_ERROR = "@engage/admin/communication/email-template/delete/error",

  CLEAR_EMAIL_TEMPLATE_EDIT_STATUS = "@engage/admin/communication/email-template/clear-edit-status",

  UPLOAD_ASSET = "@engage/admin/communication/email-template/upload-asset",
  REMOVE_TEMPORARY_ASSET = "@engage/admin/communication/email-template/remove-asset",
  UPDATE_TEMPORARY_ASSETS = "@engage/admin/communication/email-template/update-temp-assets",
  CLEAR_TEMPORARY_ASSETS = "@engage/admin/communication/email-template/clear-temp-assets",

  UPDATE_DIRTY = "@engage/admin/communication/email-template/update-dirty",
}

export interface FetchEmailTemplate {
  type: ActionTypes.FETCH_EMAIL_TEMPLATE;
  id: string;
}

export interface FetchEmailTemplateSuccess {
  type: ActionTypes.FETCH_EMAIL_TEMPLATE_SUCCESS;
  data: EmailCompositionRes;
}

export interface FetchEmailTemplateError {
  type: ActionTypes.FETCH_EMAIL_TEMPLATE_ERROR;
  error: ServerError;
}

export interface ClearEmailTemplateError {
  type: ActionTypes.CLEAR_EMAIL_TEMPLATE_ERROR;
}

export interface ClearEmailTemplate {
  type: ActionTypes.CLEAR_EMAIL_TEMPLATE;
}

export interface UpdateEmailTemplate {
  type: ActionTypes.UPDATE_EMAIL_TEMPLATE;
  revisionId: string;
  data: EmailCompositionReq;
  scopeId?: string;
  ignoreWarning?: boolean;
}

export interface UpdateEmailTemplateSuccess {
  type: ActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS;
  editStatus: EditStatus<void>;
}

export interface UpdateEmailTemplateError {
  type: ActionTypes.UPDATE_EMAIL_TEMPLATE_ERROR;
  editStatus: EditStatus<void>;
}

export interface DeleteEmailTemplate {
  type: ActionTypes.DELETE_EMAIL_TEMPLATE;
  revisionId: string;
}

export interface DeleteEmailTemplateSuccess {
  type: ActionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS;
  editStatus: EditStatus<void>;
}

export interface DeleteEmailTemplateError {
  type: ActionTypes.DELETE_EMAIL_TEMPLATE_ERROR;
  editStatus: EditStatus<void>;
}

export interface CreateEmailTemplate {
  type: ActionTypes.CREATE_EMAIL_TEMPLATE;
  data: EmailCompositionReq;
  scopeId?: string;
  ignoreWarning?: boolean;
}

export interface CreateEmailTemplateSuccess {
  type: ActionTypes.CREATE_EMAIL_TEMPLATE_SUCCESS;
  editStatus: EditStatus;
}

export interface CreateEmailTemplateError {
  type: ActionTypes.CREATE_EMAIL_TEMPLATE_ERROR;
  editStatus: EditStatus;
}

export interface ClearEditStatus {
  type: ActionTypes.CLEAR_EMAIL_TEMPLATE_EDIT_STATUS;
}

export interface UploadAsset {
  type: ActionTypes.UPLOAD_ASSET;
  upload: TemporaryUploadFile;
  originalFile: RcFile;
  assetType: AssetType;
}

export interface UpdateTemporaryAssets {
  type: ActionTypes.UPDATE_TEMPORARY_ASSETS;
  upload: TemporaryUploadFile;
  assetType: AssetType;
}

export interface RemoveTemporaryAsset {
  type: ActionTypes.REMOVE_TEMPORARY_ASSET;
  temporaryAsset: TemporaryUploadFile;
  assetType: AssetType;
}

export interface ClearTemporaryAssets {
  type: ActionTypes.CLEAR_TEMPORARY_ASSETS;
  assetType: AssetType;
}

export interface UpdateDirty {
  type: ActionTypes.UPDATE_DIRTY;
  dirty: boolean;
}

export type EmailTemplateAction =
  | FetchEmailTemplate
  | FetchEmailTemplateError
  | FetchEmailTemplateSuccess
  | ClearEmailTemplate
  | ClearEmailTemplateError
  | ClearEditStatus
  | CreateEmailTemplate
  | CreateEmailTemplateError
  | CreateEmailTemplateSuccess
  | UpdateEmailTemplate
  | UpdateEmailTemplateError
  | UpdateEmailTemplateSuccess
  | DeleteEmailTemplate
  | DeleteEmailTemplateError
  | DeleteEmailTemplateSuccess
  | UploadAsset
  | UpdateTemporaryAssets
  | RemoveTemporaryAsset
  | ClearTemporaryAssets
  | UpdateDirty;
