import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  useCallback,
  CSSProperties,
} from "react";
import * as pbi from "powerbi-client";
import { IVisualEmbedConfiguration } from "embed";
import { TokenType, Extensions, CommandDisplayOption } from "powerbi-models";
import styled from "styled-components";
import { usePrevious } from "hooks";
import { generateUuid } from "@azure/ms-rest-js";
import _ from "lodash";

const PowerBiWrapper = styled.div`
  iframe {
    border: none;
  }
`;

const powerbi = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory,
);

type EmbedType = "report" | "dashboard" | "tile" | "visual";

interface IProps extends IOnEmbedded {
  id: string;
  accessToken: string;
  tokenType?: TokenType;
  embedType?: EmbedType;
  embedUrl: string;
  mobile?: boolean;
  filterPaneEnabled?: boolean;
  navContentPaneEnabled?: boolean;
  pageName?: string;
  visualName?: string;
  extensions?: Extensions;
  style?: CSSProperties;
}

interface IOnEmbedded {
  onEmbedded?: any;
}

interface IEmbeddedState extends IVisualEmbedConfiguration, IOnEmbedded {}

const PowerBiEmbedded: FC<IProps> = (props) => {
  const {
    id,
    style,
    pageName,
    filterPaneEnabled,
    navContentPaneEnabled,
    mobile,
    extensions,
    embedType,
    visualName,
    onEmbedded,
  } = props;

  const [component, setComponent] = useState<pbi.Embed>(null);
  const [bootstrapped, setBootstrapped] = useState<boolean>(false);
  const [rootElement, setRootElement] = useState<HTMLElement>(null);
  const [embeddedState, setEmbeddedState] = useState<IEmbeddedState>({
    type: "visual",
    visualName: "Sample Report",
  });

  const prevId = usePrevious(id);

  const config: IVisualEmbedConfiguration = useMemo(() => {
    return {
      pageName: pageName,
      settings: {
        filterPaneEnabled: filterPaneEnabled,
        navContentPaneEnabled: navContentPaneEnabled,
        layoutType: mobile ? pbi.models.LayoutType.MobilePortrait : undefined,
        commands: [
          {
            // exportData: {
            //   displayOption: CommandDisplayOption.Hidden,
            // },
            spotlight: {
              displayOption: CommandDisplayOption.Hidden,
            },
            seeData: {
              displayOption: CommandDisplayOption.Hidden,
            },
          },
        ],
        extensions: extensions,
      },
      type: embedType || "report",
      visualName: visualName,
    };
  }, [
    embedType,
    extensions,
    filterPaneEnabled,
    mobile,
    navContentPaneEnabled,
    pageName,
    visualName,
  ]);

  const updateState = useCallback(() => {
    const nextState: IEmbeddedState = Object.assign(
      {},
      embeddedState,
      props,
      config,
    );

    /**
     * This property must be removed from the state object so that it doesn't get used in the embedConfig.
     * This would be passed to `powerbi.embed(element, embedConfig)` and attempted to be sent over postMessage;
     * however, functions cannot be cloned and it will fail.
     */
    delete nextState.onEmbedded;
    setEmbeddedState(nextState);
  }, [config, embeddedState, props]);

  useEffect(() => {
    updateState();

    return () => {
      if (rootElement) {
        powerbi.reset(rootElement);
        setComponent(null);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootElement]);

  useEffect(() => {
    if (prevId !== id) {
      updateState();
    }
  }, [id, prevId, updateState]);

  useEffect(() => {
    if (!bootstrapped && !_.isNil(rootElement)) {
      const bootstrappedComponent = powerbi.bootstrap(rootElement, config);
      setComponent(bootstrappedComponent);
      setBootstrapped(true);
    }
  }, [bootstrapped, config, rootElement]);

  const validateConfig = useCallback((config: IVisualEmbedConfiguration) => {
    const errors = pbi.models.validateReportLoad(config);
    return errors === undefined;
  }, []);

  const embed = useCallback(
    (config: IVisualEmbedConfiguration) => {
      const embeddedComponent = powerbi.embed(rootElement, config);
      setComponent(embeddedComponent);
      onEmbedded?.(embeddedComponent);

      return embeddedComponent;
    },
    [onEmbedded, rootElement],
  );

  useEffect(() => {
    if (validateConfig(embeddedState) && bootstrapped) {
      embed(embeddedState);
    }
  }, [bootstrapped, embed, embeddedState, validateConfig]);

  return (
    <PowerBiWrapper
      id={generateUuid()}
      className="powerbi-frame"
      ref={setRootElement}
      style={style}
    />
  );
};

export default PowerBiEmbedded;
