import React, { FC, useCallback, useEffect, useState } from "react";

import { generateUuid } from "@azure/ms-rest-js";
import { UserProfileRes } from "@n3oltd/k2.users.sdk.users/src/index";
import {
  FileResponse,
  NoteFileReq,
  NoteFileRes,
  SecurityPrincipalProfile,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Comment, Skeleton, Tooltip, Upload } from "antd";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { connect } from "react-redux";
import styled from "styled-components";

import { _tasksClient, _usersClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
  ServerError,
} from "appRedux/models/common/ApiResponseModel";
import { Attachment } from "appRedux/modules/tasks/types";
import IApplicationState from "appRedux/types";
import DownloadHelpers from "common/helpers/downloads";
import UserAvatar from "components/avatars/userAvatar";
import CancelSaveOpts from "components/cancelSaveOpts";
import {
  clearDeleteTaskNoteError,
  clearSaveNewNoteError,
  clearUpdateNoteError,
  deleteTaskNoteRequest,
  saveNewNoteRequest,
  updateNoteRequest,
} from "components/dashboard/editTask/modules/actions";
import {
  TaskNote,
  editableSectionName,
} from "components/dashboard/editTask/modules/types";
import DeleteIcon from "components/deleteIcon";
import EditLinkIcon from "components/editLinkIcon";
import { K2Button, K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import MarkupEditorPreviewer from "components/tasks/markupEditorPreviewer";
import { UIUtils } from "components/utils";
import { showConfirm, showError } from "components/utils/Confirmation";
import { showNotification } from "components/utils/Notification";
import usePrevious from "hooks/usePrevious";

const EditableComment = styled(Comment)`
  && {
    border-bottom: 1px solid ${({ theme }) => theme.grey_5};
    margin-bottom: 10px;

    .ant-comment-content-author-time {
      display: block;
      width: 100%;
    }

    .edit-delete-opts {
      padding-left: 4px;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s ease-out, opacity 0.3s ease-out;
    }

    &:hover .edit-delete-opts {
      padding-left: 4px;
      visibility: visible;
      opacity: 1;
      transition: visibility 0.15s ease-in, opacity 0.15s ease-in;
    }
  }
`;

const EditDeleteOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -42px;
  z-index: 5;
  span:first-of-type {
    margin-right: 8px;
  }
`;

const SaveButtonContainer = styled.div`
  text-align: right;
  padding: 10px 0;
`;

const createInitialEditorState = (html: string) => {
  const blocksFromHTML = convertFromHTML(html);
  let state;
  if (blocksFromHTML && blocksFromHTML.contentBlocks) {
    const stateFromBlocks = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    if (stateFromBlocks) {
      state = EditorState.createWithContent(stateFromBlocks);
    } else {
      state = EditorState.createEmpty();
    }
  } else {
    state = EditorState.createEmpty();
  }
  return state;
};

interface IProps extends InjectedK2IntlProps {
  note?: TaskNote;
  subscriptionId: string;
  taskId: string;
  taskRevisionId: string;
  savingNote: boolean;
  errorSavingNote?: ServerError;
  deleteNote: typeof deleteTaskNoteRequest;
  updateNote: typeof updateNoteRequest;
  saveNewNote: typeof saveNewNoteRequest;
  clearUpdateNoteError: typeof clearUpdateNoteError;
  clearDeleteTaskNoteError: typeof clearDeleteTaskNoteError;
  clearSaveNewNoteError: typeof clearSaveNewNoteError;
  setFormTouched?: (formName: editableSectionName) => void;
  clearFormTouched?: (formName: editableSectionName) => void;
}

const NoteView: FC<IProps> = ({
  note,
  subscriptionId,
  taskId,
  taskRevisionId,
  deleteNote,
  updateNote,
  saveNewNote,
  savingNote,
  errorSavingNote,
  clearSaveNewNoteError,
  setFormTouched,
  clearFormTouched,
  clearDeleteTaskNoteError,
  clearUpdateNoteError,
  k2Intl,
}) => {
  const [editing, setEditing] = useState<boolean>(!note);
  const [touched, setTouched] = useState<boolean>(false);
  const [showingAttachments, setShowingAttachments] = useState<boolean>(false);
  const [editorState, setEditorState] = useState(
    note ? createInitialEditorState(note.text.html) : EditorState.createEmpty(),
  );
  const prevUpdating = usePrevious(note ? note.updating : false);
  const prevSavingNote = usePrevious(savingNote);

  const noteId = note?.id;

  const noteFiles = note?.files;
  const getNoteFilesAsAttachments = useCallback(
    (noteFiles: NoteFileRes[]): Attachment[] => {
      return noteFiles
        ? noteFiles?.map?.(
            (file): Attachment => {
              return {
                name: file.attachment.name,
                status: "done",
                uid: file.id,
                size: file.attachment.size?.bytes,
                type: file.attachment.contentType,
                originFileObj: null,
              };
            },
          )
        : [];
    },
    [],
  );

  const downloadFile = useCallback(
    async (file: Attachment) => {
      const resp: IApiResponse<FileResponse> = await K2RestService.toResponse(
        _tasksClient.getNoteFile(taskId, noteId, file.uid, file.name),
      );

      if (resp.error) {
        UIUtils.handleServerError(k2Intl, resp.error);
      } else {
        DownloadHelpers.downloadFile(resp, file.name);
      }
    },
    [k2Intl, taskId, noteId],
  );

  // All attachments are considered temporary until the note is Saved, as they can be discarded and we can set back to the original list of attachments
  const [temporaryAttachments, setTemporaryAttachments] = useState<
    Attachment[]
  >(note ? getNoteFilesAsAttachments(noteFiles) : []);

  const deleting = note ? note.deleting : false;
  const updating = note ? note.updating : false;

  useEffect(() => {
    if (noteFiles) {
      setTemporaryAttachments(getNoteFilesAsAttachments(noteFiles));
    }
  }, [noteFiles, getNoteFilesAsAttachments]);

  const cancelEditToExistingNote = () => {
    clearFormTouched?.(note ? `note-${note.id}` : "newNote");
    setEditorState(createInitialEditorState(note.text.html));
    setEditing(false);

    // Discard any changes to attachments, resetting to original attachment list
    setTemporaryAttachments(getNoteFilesAsAttachments(noteFiles));
  };

  // Necessary because setState is async, so we need to check whether an item has already been added before we add it again
  const addTempAttachmentIfNotExists = (
    attachments: Attachment[],
    updatedAttachment: Attachment,
  ): Attachment[] => {
    if (!attachments.find((a) => a.uid === updatedAttachment.uid)) {
      return [...attachments, updatedAttachment];
    } else {
      return attachments?.map?.((a) => {
        if (a.uid === updatedAttachment.uid) {
          return updatedAttachment;
        } else {
          return a;
        }
      });
    }
  };

  const uploadAttachment = async (info) => {
    const tempAttachment: Attachment = {
      status: "uploading",
      name: info.file.name,
      uid: generateUuid(),
      size: info.file.size,
      type: info.file.type,
      originFileObj: info.file,
    };
    setTemporaryAttachments([...temporaryAttachments, tempAttachment]);

    try {
      const response: IApiResponse<string> = await K2Service.toResponse(
        _tasksClient.uploadFile(info.file),
      );
      if (!response.error) {
        const attachmentToUpdate =
          temporaryAttachments.find((a) => a.uid === tempAttachment.uid) ||
          tempAttachment;

        attachmentToUpdate.status = "done";
        attachmentToUpdate.storageToken = response.getResultOrDefault();

        const updatedAttachments = addTempAttachmentIfNotExists(
          temporaryAttachments,
          attachmentToUpdate,
        );
        setTemporaryAttachments(updatedAttachments);
      } else {
        const attachmentToUpdate =
          temporaryAttachments.find((a) => a.uid === tempAttachment.uid) ||
          tempAttachment;

        attachmentToUpdate.status = "error";

        const updatedAttachments = addTempAttachmentIfNotExists(
          temporaryAttachments,
          attachmentToUpdate,
        );
        setTemporaryAttachments(updatedAttachments);
      }
    } catch (e) {
      // Non-API Related error
      console.log(e);
    }
  };

  const deleteAttachment = (file: Attachment): boolean => {
    // This is not a full delete.
    // The user can still cancel the note edit at this point
    // Only when save is clicked do we delete any attachments that were removed.
    setTemporaryAttachments(
      temporaryAttachments.filter((a) => a.uid !== file.uid),
    );
    return false;
  };

  /**
   * This function will either update an existing note, or create a new note.
   */
  const handleSave = () => {
    if (note) {
      // If any of the original attachments are not in the tempAttachments list, they have been deleted
      const attachmentsToDelete: Attachment[] = getNoteFilesAsAttachments(
        noteFiles,
      ).filter((originalAttachment: Attachment) => {
        const attachmentFromTempList = temporaryAttachments.find(
          (a) => a.uid === originalAttachment.uid,
        );
        if (!attachmentFromTempList) return true;
        // Was deleted
        else return false;
      });

      // If there are any temp attachments that are not in the original list, they need properly saving
      const attachmentsToSave: Attachment[] = temporaryAttachments.filter(
        (a) => {
          const attachmentFromOriginalList = note.files
            ? note.files.find((file) => file.id === a.uid)
            : null;

          if (!attachmentFromOriginalList) return true;
          // Is new
          else return false;
        },
      );

      // Get the note text in markup format
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const markupToSave = draftToHtml(rawContentState);
      updateNote(
        taskId,
        taskRevisionId,
        note.id,
        markupToSave,
        attachmentsToSave,
        attachmentsToDelete,
      );
    } else {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const noteText: string = draftToHtml(rawContentState);
      const noteFiles: NoteFileReq[] = temporaryAttachments?.map?.((a) => ({
        storageToken: a.storageToken,
      }));
      saveNewNote(taskId, noteText, noteFiles.length ? noteFiles : null);
    }
  };

  const handleDelete = (taskRevisionId: string, noteId: string) => {
    showConfirm({
      titleKey: "tasks.deleteTaskNoteConfirm",
      k2Intl,
      okTextKey: "common.delete",
      okButtonProps: {
        danger: true,
      },
      centered: true,
      onOk: () => {
        deleteNote(taskId, taskRevisionId, noteId);
      },
    });
  };

  // Handling any errors updating a note
  const errorsUpdating = note?.errorsUpdating;
  useEffect(() => {
    if (errorsUpdating && errorsUpdating.length) {
      errorsUpdating.forEach((error) => {
        if (error.status === K2StatusCodes.preconditionFailed) {
          const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
            error,
            "error",
          );

          if (nonFieldRelatedErrors.length) {
            showError({
              titleKey: "common.nonDismissableFormErrorTitle",
              error: nonFieldRelatedErrors?.map?.((e) => <p>{e.error}</p>),
              okTextKey: "common.ok",
              k2Intl,
              onOk: () => {
                clearUpdateNoteError(noteId);
              },
              onCancel: () => {
                clearUpdateNoteError(noteId);
              },
            });
          }
        } else {
          UIUtils.handleServerError(k2Intl, error, () =>
            clearUpdateNoteError(noteId),
          );
        }
      });
    }
  }, [errorsUpdating, k2Intl, clearUpdateNoteError, noteId]);

  const errorDeleting = note?.errorDeleting;

  // Handling any errors deleting a note
  useEffect(() => {
    if (errorDeleting) {
      if (errorDeleting.status === K2StatusCodes.preconditionFailed) {
        const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
          errorDeleting,
          "error",
        );

        if (nonFieldRelatedErrors.length) {
          showError({
            titleKey: "common.nonDismissableFormErrorTitle",
            error: nonFieldRelatedErrors?.map?.((e) => <p>{e.error}</p>),
            okTextKey: "common.ok",
            k2Intl,
            onOk: () => {
              clearDeleteTaskNoteError(noteId);
            },
            onCancel: () => {
              clearDeleteTaskNoteError(noteId);
            },
          });
        }
      } else {
        UIUtils.handleServerError(k2Intl, errorDeleting, () =>
          clearDeleteTaskNoteError(noteId),
        );
      }
    }
  }, [errorDeleting, clearDeleteTaskNoteError, k2Intl, noteId]);

  // after new note is saved, set component state back to empty
  useEffect(() => {
    if (prevSavingNote && !savingNote && !errorSavingNote) {
      clearFormTouched?.(note ? `note-${note.id}` : "newNote");
      setEditorState(EditorState.createEmpty());
      setTemporaryAttachments([]);
    }
    // eslint-disable-next-line
  }, [savingNote]);

  // Handle any errors saving the new note
  useEffect(() => {
    if (errorSavingNote) {
      UIUtils.handleServerError(k2Intl, errorSavingNote, clearSaveNewNoteError);
    }
    // eslint-disable-next-line
  }, [errorSavingNote]);

  // After saving changes to a note is successful
  useEffect(() => {
    if (note) {
      const hasErrors = note.errorsUpdating && note.errorsUpdating.length;
      if (prevUpdating && !updating && !hasErrors) {
        clearFormTouched?.(`note-${note.id}`);
        setEditing(false);
        setShowingAttachments(false);
        setEditorState(createInitialEditorState(note.text.html));
        showNotification({
          type: "success",
          k2Intl,
          titleKey: "common.success.title",
          messageKey: "tasks.noteUpdatedSuccess",
        });
      }
    }
    // eslint-disable-next-line
  }, [updating]);

  // Gets the note author's user profile
  const [
    noteAuthorProfile,
    setNoteAuthorProfile,
  ] = useState<SecurityPrincipalProfile | null>(null);

  useEffect(() => {
    if (
      note &&
      note.modifiedInfo.created &&
      note.modifiedInfo.created.principal
    ) {
      async function fetchUserProfile() {
        const response: IApiResponse<UserProfileRes> = await K2Service.toResponse(
          _usersClient.getUserProfileById(
            note.modifiedInfo.created.principal.principalId,
          ),
        );
        if (!response.error) {
          setNoteAuthorProfile(response.getResultOrDefault());
        }
      }
      fetchUserProfile();
    }
    // eslint-disable-next-line
  }, []);

  const NotEditingNoteView = note ? (
    <>
      <EditableComment
        datetime={`${moment(note.modifiedInfo.created.timestamp).format(
          "MMM D, hh:mm A",
        )}${
          note.modifiedInfo.created.timestamp !==
          note.modifiedInfo.updated.timestamp
            ? ` (${k2Intl
                .formatMessage({
                  localeKey: "common.edited",
                })
                .toLowerCase()})`
            : ""
        }`}
        author={note.modifiedInfo.created.name}
        avatar={
          noteAuthorProfile && (
            <UserAvatar
              user={noteAuthorProfile}
              presetId="small"
              subscriptionId={subscriptionId}
            />
          )
        }
        content={
          <div>
            <EditDeleteOptions>
              {note.canEdit && (
                <span>
                  <Tooltip title={<K2Message localeKey="common.clickToEdit" />}>
                    <EditLinkIcon onClick={() => setEditing(!editing)} />
                  </Tooltip>
                </span>
              )}
              {note.canDelete && (
                <span>
                  <DeleteIcon
                    tooltipKey={"common.clickToDelete"}
                    onClick={() => handleDelete(taskRevisionId, note.id)}
                  />
                </span>
              )}
            </EditDeleteOptions>

            <div dangerouslySetInnerHTML={{ __html: note.text.html }} />
            <Upload
              fileList={getNoteFilesAsAttachments(noteFiles)}
              onDownload={downloadFile}
              showUploadList={{
                showRemoveIcon: false,
                showPreviewIcon: false,
                showDownloadIcon: true,
              }}
            />
          </div>
        }
      />
    </>
  ) : null;

  const anyFileIsUploading = temporaryAttachments.some(
    (a) => a.status === "uploading",
  );

  const EditableNote = (
    <>
      <MarkupEditorPreviewer
        showAttachments={showingAttachments}
        editorState={editorState}
        onEditorStateChange={(editorState: EditorState) => {
          setTouched(true);
          setFormTouched?.(note ? `note-${note.id}` : "newNote");
          setEditorState(editorState);
        }}
        handleUpload={(options: RcCustomRequestOptions) => {
          setTouched(true);
          setFormTouched?.(note ? `note-${note.id}` : "newNote");
          uploadAttachment(options);
        }}
        attachments={temporaryAttachments}
        deleteAttachment={(file: Attachment): boolean => {
          setTouched(true);
          setFormTouched?.(note ? `note-${note.id}` : "newNote");
          return deleteAttachment(file);
        }}
        onToggleAttachments={() => {
          setShowingAttachments(!showingAttachments);
        }}
      />
      {note ? (
        <CancelSaveOpts
          loading={updating}
          onCancel={cancelEditToExistingNote}
          onSave={handleSave}
        />
      ) : (
        <SaveButtonContainer>
          <K2Button
            type="primary"
            size="small"
            loading={savingNote}
            onClick={handleSave}
            disabled={!touched || anyFileIsUploading}
          >
            <K2Message localeKey="tasks.note.add" />
          </K2Button>
        </SaveButtonContainer>
      )}
    </>
  );

  if (editing) {
    if (note) {
      return (
        <Comment
          avatar={
            noteAuthorProfile && (
              <UserAvatar
                user={noteAuthorProfile}
                presetId="small"
                subscriptionId={subscriptionId}
              />
            )
          }
          content={EditableNote}
        />
      );
    } else {
      return EditableNote;
    }
  } else {
    return deleting || updating ? <Skeleton active /> : NotEditingNoteView;
  }
};

const mapStateToProps = (state: IApplicationState) => ({
  subscriptionId: state.subscription.users?.k2Subscription?.id,
  taskId: state.editViewTask.task?.id,
  taskRevisionId: state.editViewTask.task?.revisionId,
  savingNote: state.editViewTask.savingNote,
  errorSavingNote: state.editViewTask.errorSavingNote,
});

const mapDispatchToProps = {
  deleteNote: deleteTaskNoteRequest,
  updateNote: updateNoteRequest,
  saveNewNote: saveNewNoteRequest,
  clearSaveNewNoteError: clearSaveNewNoteError,
  clearDeleteTaskNoteError: clearDeleteTaskNoteError,
  clearUpdateNoteError: clearUpdateNoteError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectK2Intl(NoteView));
