import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { K2Routes } from "appRedux/models/routes/RouteModel";
import { asyncComponent } from "components/utils";

const { goCardlessPaymentCallbackUrl } = K2Routes;

const CallbackRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Route
        exact
        path={goCardlessPaymentCallbackUrl}
        component={asyncComponent(
          () =>
            import(
              /* webpackChunkName: "GoCardlessCallbackComponent" */ "../../containers/callbackPages/goCardless"
            ),
        )}
      />
    </Switch>
  );
};

export default CallbackRoutes;
