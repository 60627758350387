import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  CaretDownFilled,
  QuestionCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { generateUuid } from "@azure/ms-rest-js";
import { GlobalSuggestion } from "@n3oltd/k2.accounts.sdk.global-suggestions/esm";
import { Badge, Select, Skeleton, Tooltip } from "antd";
import { useRouter } from "hooks";
import _ from "lodash";
import styled from "styled-components";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { useEnvironmentContext } from "common/contexts/EnvironmentProvider/EnvironmentProvider";
import { K2Message, K2Tooltip } from "components/k2Widgets";
import { MIN_GLOBAL_SEARCH_LENGTH } from "components/navigation/mainAppHeader/globalSearch/constants";
import GlobalSearchResultOption from "components/navigation/mainAppHeader/globalSearch/globalSearchResultOption";
import "components/navigation/mainAppHeader/globalSearch/styles.less";
import { DEFAULT_DELAY } from "constants/appConstants";
import useDebounce from "hooks/useDebounce";

import { GlobalSearchFetchers } from "./modules/fetchers";
import { GlobalSearchProps, SearchEntityType } from "./modules/types";

const BadgeRibbon = styled(Badge.Ribbon)`
  right: 0 !important;
  border-radius: 4px 4px 0 4px !important;
`;

const SearchContainer = styled.div`
  .ant-select-selection-selected-value {
    .n3o-select-search-autocomplete {
      padding: 0;

      & > div {
        display: none;

        &:first-child {
          display: block;
          margin: 0;
        }
      }
    }
  }
`;

const CustomSelect = styled(Select)`
  &.n3o-global-search-query {
    &.ant-select-open {
      .anticon {
        color: ${({ theme }) => theme.grey_6} !important;
      }
    }
  }

  && {
    .ant-select-selection-selected-value {
      display: none !important;
    }
    .ant-select-selection {
      border-radius: 25px !important;
    }
  }
`;

const SearchTypeSelect = styled(Select)`
  && {
    li {
      padding: 8px;
    }
    .ant-select-selector {
      border: none !important;
      height: 36px !important;
      margin: 1px 0px !important;
      box-shadow: none !important;
      min-height: unset !important;
    }
    .ant-select-selection {
      box-shadow: none !important;
      background-color: transparent;
    }
    .anticon {
      padding-right: 8px;
    }

    right: 10px;
    position: absolute !important;
  }
`;

const EmptySearchContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const { Option } = Select;

const GlobalSearch: FC<GlobalSearchProps> = (props) => {
  const {
    isMobileVersion,
    searchOptions,
    className,
    width,
    initialEntitySelection,
    onSearchResultSelect,
  } = props;
  const router = useRouter();
  const { appId } = useAppContext();

  const [searchType, updateSearchType] = useState(initialEntitySelection);
  const [searchTerm, updateSearch] = useState<string>("");
  const [results, setResults] = useState<GlobalSuggestion[]>([]);
  const [error, setError] = useState<ServerError>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  const [showGlobalSearch, setShowGlobalSearch] = useState<boolean>(false);
  const [searchFocused, setSearchFocused] = useState<boolean>(false);
  const [typeFocused, setTypeFocused] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DELAY);

  const fetchResults = useCallback(
    async (debouncedSearchTerm: string, searchType: SearchEntityType) => {
      setFetching(true);
      setError(null);
      setResults([]);

      const response = await GlobalSearchFetchers.search({
        searchText: debouncedSearchTerm,
        searchType: searchType,
      });

      if (response && response.error) {
        setError(response.error);
      } else if (response) {
        if (searchType === "navigation") {
          setResults(
            response
              .getResultOrDefault()
              .items.map((item) => ({ id: generateUuid(), ...item })),
          );
        } else {
          setResults(response.getResultOrDefault().items);
        }
      }

      setFetching(false);
    },
    [],
  );

  // This effect runs whenever debouncedSearchTerm changes
  useEffect(() => {
    if (
      debouncedSearchTerm &&
      debouncedSearchTerm.length >= MIN_GLOBAL_SEARCH_LENGTH
    ) {
      fetchResults(debouncedSearchTerm, searchType);
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm, searchType]);

  let noDataMessage: ReactElement;
  if (searchTerm.length < MIN_GLOBAL_SEARCH_LENGTH) {
    noDataMessage = <K2Message localeKey="common.keepTyping" />;
  } else if (error !== null) {
    //Error from Server
    noDataMessage = <K2Message localeKey="common.unableToLoadSearchResults" />;
  } else if (!results?.length && !fetching) {
    noDataMessage = (
      <K2Message localeKey="common.noResults" values={{ value: searchTerm }} />
    );
  }

  const { region } = useEnvironmentContext();

  const followLink = useCallback(
    (selectedItem: GlobalSuggestion, type: SearchEntityType) => {
      const url = selectedItem.url;
      const split = url?.split(`/${region}/`);
      switch (type) {
        case "navigation":
          return router.history.push(
            appId === "k2"
              ? `/${region}/engage` + selectedItem.url
              : `/${region}/tally` + selectedItem.url,
          );
        default:
          return router.history.push(`/${region}/${split?.[1]}`);
      }
    },
    [appId, region, router.history],
  );

  const selectRef = useRef(null);
  const searchContainerId = `searchTypeContainer${
    isMobileVersion ? "_mobile" : ""
  }`;

  return (
    <SearchContainer className={`n3o-global-search-container ${className}`}>
      {!showGlobalSearch ? (
        <EmptySearchContainer
          className={"n3o-text-white"}
          onClick={() => setShowGlobalSearch(true)}
        >
          <SearchOutlined style={{ fontSize: 18 }} />
          <span style={{ marginLeft: 10 }}>
            <K2Message localeKey={"common.searchFor"} />
          </span>
        </EmptySearchContainer>
      ) : (
        <>
          <CustomSelect
            autoFocus
            showSearch
            ref={selectRef}
            className="n3o-global-search-box n3o-global-search-query"
            dropdownClassName={"custom-scrollbar-style"}
            showAction={["focus", "click"]}
            defaultActiveFirstOption={false}
            showArrow={false}
            notFoundContent={
              fetching ? (
                <Skeleton
                  active
                  paragraph={{ rows: 1 }}
                  className={"n3o-mb-2"}
                />
              ) : (
                noDataMessage
              )
            }
            filterOption={false}
            onFocus={() => setSearchFocused(true)}
            onBlur={() => {
              setResults([]);
              updateSearch("");
              setSearchFocused(false);
            }}
            onSearch={(value) => {
              updateSearch(value.toLowerCase().trim());
              setSearchFocused(false);
            }}
            style={{ width: width || "100%" }}
            getPopupContainer={(triggerNode: HTMLElement) => {
              const element = document.getElementById("n3o-second-header-area");
              return !_.isNull(element) ? element : triggerNode;
            }}
            onSelect={(id: string) => {
              const selectedItem = results.find((r) => r.id === id);
              updateSearch("");
              onSearchResultSelect?.(id, searchType);
              setResults([]);
              setShowGlobalSearch(false);
              setSearchFocused(false);

              // If it's a link, programmatically follow it
              const shouldLink = searchType !== "tasks";
              if (shouldLink) followLink(selectedItem, searchType);
            }}
          >
            {results?.map?.((result: GlobalSuggestion) => {
              const isInactive = searchType === "accounts" && !result.isActive;
              const redacted = searchType === "accounts" && result.redacted;

              return (
                <Select.Option key={result.id} value={result.id}>
                  {isInactive ? (
                    <BadgeRibbon
                      color={"red"}
                      text={
                        <>
                          <K2Message
                            localeKey={"accounts.view.profile.closed"}
                          />
                          {redacted && (
                            <K2Tooltip
                              placement={"rightTop"}
                              titleKey={"accounts.view.profile.redacted"}
                            >
                              <QuestionCircleTwoTone
                                style={{ marginLeft: 10 }}
                                className={"n3o-pointer"}
                              />
                            </K2Tooltip>
                          )}
                        </>
                      }
                    >
                      <GlobalSearchResultOption
                        item={result}
                        shouldLink={searchType !== "tasks"}
                        isInactive={isInactive}
                      />
                    </BadgeRibbon>
                  ) : (
                    <GlobalSearchResultOption
                      item={result}
                      shouldLink={searchType !== "tasks"}
                      isInactive={isInactive}
                    />
                  )}
                </Select.Option>
              );
            })}
          </CustomSelect>
          <Tooltip
            title={<K2Message localeKey={"common.selectSearchType"} />}
            placement={"left"}
          >
            <SearchTypeSelect
              id={searchContainerId}
              className="n3o-global-search-box"
              defaultValue={searchOptions[searchType].apiRoot}
              dropdownMatchSelectWidth={false}
              onFocus={() => setTypeFocused(true)}
              onBlur={() => setTypeFocused(false)}
              onChange={(value: SearchEntityType) => {
                updateSearchType(value);
                selectRef?.current?.focus?.();
                setResults([]);

                setTypeFocused(false);
              }}
              showSearch={false}
              suffixIcon={
                <CaretDownFilled className={"ant-select-arrow-icon"} />
              }
              optionLabelProp={"children"}
            >
              {Object.keys(searchOptions)?.map?.((key) => (
                <Option
                  value={searchOptions[key].apiRoot}
                  key={searchOptions[key].apiRoot}
                >
                  {searchOptions[key].icon}
                  <K2Message localeKey={searchOptions[key].titleLocaleKey} />
                </Option>
              ))}
            </SearchTypeSelect>
          </Tooltip>
        </>
      )}
    </SearchContainer>
  );
};

export default GlobalSearch;
