import React, { Component } from "react";
import { Spin, Row, Col } from "antd";
import { K2Message, K2Card } from "components/k2Widgets";
import { usersActions } from "appRedux/modules/subscription";
import styled from "styled-components";

const { getCurrentSubscription } = usersActions;

interface SeedingSubscriptionViewProps {
  getCurrentSubscription: typeof getCurrentSubscription;
}

const Container = styled.div`
  height: 100vh;
  padding-bottom: 32px;
`;

const CenterRow = styled(Row)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`;

class SeedingSubscriptionView extends Component<SeedingSubscriptionViewProps> {
  private timerId: NodeJS.Timeout;

  componentDidMount() {
    // repeat with the interval of 2 seconds
    this.timerId = setInterval(() => {
      this.props.getCurrentSubscription();
    }, 100000);

    // after 10 minutes stop
    setTimeout(() => {
      clearInterval(this.timerId);
    }, 600000);
  }

  UNSAFE_componentWillUpdate() {
    clearInterval(this.timerId);
  }

  render() {
    return (
      <Container>
        <CenterRow>
          <Col md={12} sm={20}>
            <K2Card shadow>
              <div className="n3o-page-error-container n3o-mt-5">
                <div className="n3o-page-error-content n3o-text-center">
                  <Spin size="large" />
                  <h2 className="n3o-text-center">
                    <span>
                      <K2Message localeKey={"subscription.seeding"} />
                    </span>
                  </h2>
                </div>
              </div>
            </K2Card>
          </Col>
        </CenterRow>
      </Container>
    );
  }
}

export default SeedingSubscriptionView;
