import produce from "immer";
import * as actionTypes from "./actionTypes";
import { AddEditDonationItemState } from "./types";
import { SharedActionTypes as AdminActionTypes } from "routes/admin/modules/actionTypes";

const initialState: AddEditDonationItemState = {
  selectedDonationItem: null,
  editStatus: null,
  loading: false,
  serverError: null,
  dirty: false,
  fundDimensions: {
    data: {},
    loading: false,
    error: null,
  },
};

const createEditDonationItemReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case AdminActionTypes.FETCH_FUND_DIMENSIONS:
        draft.fundDimensions.loading = true;
        break;
      case AdminActionTypes.FETCH_FUND_DIMENSIONS_SUCCESS:
        draft.fundDimensions.loading = false;
        draft.fundDimensions.data = action.fundDimensionsStructure;
        break;
      case AdminActionTypes.FETCH_FUND_DIMENSIONS_ERROR:
        draft.fundDimensions.loading = false;
        draft.fundDimensions.error = action.error;
        break;
      case actionTypes.CREATE_DONATION_ITEM:
        draft.loading = true;
        break;
      case actionTypes.CREATE_DONATION_ITEM_SUCCESS:
        draft.loading = false;
        draft.editStatus = action.editStatus;
        break;
      case actionTypes.CREATE_DONATION_ITEM_ERROR:
        draft.loading = false;
        draft.editStatus = action.editStatus;
        break;
      case actionTypes.CLEAR_UPDATE_DONATION_ITEM_ERROR:
      case actionTypes.CLEAR_CREATE_DONATION_ITEM_ERROR:
        draft.serverError = null;
        break;
      case actionTypes.CLEAR_DONATION_ITEM_EDIT_STATUS:
        draft.editStatus = null;
        break;
      case actionTypes.FETCH_SELECTED_DONATION_ITEM:
        draft.loading = true;
        break;
      case actionTypes.FETCH_SELECTED_DONATION_ITEM_SUCCESS:
        draft.loading = false;
        draft.selectedDonationItem = action.donationItem;
        break;
      case actionTypes.FETCH_SELECTED_DONATION_ITEM_ERROR:
        draft.loading = false;
        draft.serverError = action.error;
        break;
      case actionTypes.CLEAR_FETCH_SELECTED_DONATION_ITEM_ERROR:
        draft.serverError = null;
        break;
      case actionTypes.CLEAR_SELECTED_DONATION_ITEM:
        draft.selectedDonationItem = null;
        break;
      case actionTypes.UPDATE_DONATION_ITEM:
        draft.loading = true;
        break;
      case actionTypes.UPDATE_DONATION_ITEM_SUCCESS:
        draft.editStatus = action.editStatus;
        draft.loading = false;
        break;
      case actionTypes.UPDATE_DONATION_ITEM_ERROR:
        draft.loading = false;
        draft.editStatus = action.editStatus;
        break;
      case actionTypes.DELETE_DONATION_ITEM:
        draft.loading = true;
        break;
      case actionTypes.DELETE_DONATION_ITEM_SUCCESS:
        draft.loading = false;
        draft.editStatus = action.editStatus;
        draft.selectedDonationItem = null;
        break;
      case actionTypes.DELETE_DONATION_ITEM_ERROR:
        draft.loading = false;
        draft.editStatus = action.editStatus;
        break;
      case actionTypes.SET_FORM_DIRTY:
        draft.dirty = true;
        break;
      case actionTypes.CLEAR_FORM_DIRTY:
        draft.dirty = false;
        break;
    }
  });

export default createEditDonationItemReducer;
