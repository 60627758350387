import React, { FC, useEffect, useRef, useState } from "react";

import { EyeOutlined } from "@ant-design/icons/lib";
import { Col, Dropdown, Row, Tabs, Tag, Tooltip } from "antd";
import { connect } from "react-redux";
import styled from "styled-components";

import { DataActions } from "appRedux/modules/sharedTypes";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import DeleteTaskIcon from "components/dashboard/editTask/components/deleteTaskIcon";
import MarkTaskCompleteButton from "components/dashboard/editTask/components/markTaskCompleteButton";
import NoteView from "components/dashboard/editTask/components/noteView";
import PriorityEditor from "components/dashboard/editTask/components/priorityEditor";
import RelatedLink from "components/dashboard/editTask/components/relatedLink";
import SelectEditAssignees from "components/dashboard/editTask/components/selectEditAssignees";
import SetDueDateForm from "components/dashboard/editTask/components/setDueDate";
import TaskTitleForm from "components/dashboard/editTask/components/taskTitle";
import TaskUploadsList from "components/dashboard/editTask/components/taskUploadsList";
import VisibleUsersList from "components/dashboard/editTask/components/visibleUsersList";
import * as actions from "components/dashboard/editTask/modules/actions";
import { EditViewTaskState } from "components/dashboard/editTask/modules/types";
import "components/dashboard/editTask/styles.less";
import FormItemTitle from "components/forms/formItemTitle";
import { K2Message, K2Modal, K2Tabs } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import K2Spin from "components/k2Widgets/k2Spin";
import { UIUtils } from "components/utils";
import { showNotification } from "components/utils/Notification";
import usePrevious from "hooks/usePrevious";

import DirtyDataGuard from "../../../components/utils/DirtyDataGuard";

const { TabPane } = Tabs;

interface IProps
  extends StateProps,
    IEditViewTaskDispatchProps,
    InjectedK2IntlProps {
  onCancel: () => void;
  taskId: string;
  defaultPosition?: "notes";
}

const ModalTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const DivWithRightSpace = styled.div`
  margin-right: 24px;
`;
const TaskRefContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 8px;
`;

const ClickableTag = styled(Tag)`
  cursor: pointer;
`;

const PriorityCol = styled(Col)`
  && {
    margin-top: 17px;
    margin-bottom: 24px;
  }
`;

const DateCol = styled(Col)`
  && {
    padding: 0;
    min-width: 180px;
    height: 55px;
    margin-bottom: 24px;
  }
`;

const EditTask: FC<IProps> = (props) => {
  const {
    onCancel,
    taskId,
    task,
    fetchTask,
    loading,
    taskNotes,
    errorFetchingTask,
    clearFetchingTaskError,
    k2Intl,
    defaultPosition,
    clearNoteEditStatus,
    clearAllFormsTouched,
    deleting,
    saving,
    dirty,
  } = props;
  const { theme } = useAppContext();

  const [tabShowing, setTabShowing] = useState<"notes" | "activities">("notes");
  const prevLoading = usePrevious(loading);
  const notesSection = useRef(null);

  useEffect(() => {
    fetchTask(taskId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (prevLoading && !loading && defaultPosition) {
      notesSection?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (errorFetchingTask) {
      UIUtils.handleServerError(
        k2Intl,
        errorFetchingTask,
        clearFetchingTaskError,
      );
    }
    // eslint-disable-next-line
  }, [errorFetchingTask]);

  // After successfully deleting a note or an attachment
  useEffect(() => {
    const deletedNotes = taskNotes.filter((n) => {
      return n.editStatus && n.editStatus.action === DataActions.delete;
    });
    if (deletedNotes) {
      deletedNotes.forEach((n) => {
        showNotification({
          type: "success",
          k2Intl,
          titleKey: "common.success.title",
          messageKey: "tasks.deleteTaskNoteSuccess",
        });
        clearNoteEditStatus(n.id);
      });
    }
    // eslint-disable-next-line
  }, [taskNotes]);

  const taskHasUploads: boolean =
    !!task &&
    !!taskNotes &&
    taskNotes.reduce((count, note) => {
      return count + (note.files ? note.files.length : 0);
    }, 0) > 0;

  const taskHasNotes: boolean = !!task && !!taskNotes && taskNotes.length > 0;

  const sharedWithUsersCount =
    task && task.sharedWith ? task.sharedWith.length : 0;

  const anyFormIsDirty = Object.values(dirty).some(Boolean);

  return (
    <DirtyDataGuard dirty={anyFormIsDirty}>
      <K2Modal
        requiresCloseConfirmation={anyFormIsDirty}
        visible={true}
        loading={loading}
        onCancel={() => {
          clearAllFormsTouched();
          onCancel();
        }}
        title={
          task &&
          !loading &&
          !saving && (
            <ModalTitle className="ant-modal-title">
              <TaskRefContainer>
                <DivWithRightSpace>
                  <K2Message localeKey="tasks.task" /> {task.reference.text}
                </DivWithRightSpace>
                <MarkTaskCompleteButton
                  size="small"
                  disabled={loading || deleting}
                />
              </TaskRefContainer>
              {!loading && !deleting && (
                <DivWithRightSpace>
                  <Dropdown
                    trigger={["click"]}
                    overlay={() => (
                      <VisibleUsersList users={task.sharedWith || []} />
                    )}
                  >
                    <Tooltip
                      title={
                        <K2Message
                          localeKey={
                            sharedWithUsersCount === 1
                              ? "tasks.sharedWithCount.singular"
                              : "tasks.sharedWithCount.plural"
                          }
                          values={{ count: sharedWithUsersCount }}
                        />
                      }
                    >
                      <ClickableTag>
                        <EyeOutlined /> {sharedWithUsersCount}
                      </ClickableTag>
                    </Tooltip>
                  </Dropdown>
                </DivWithRightSpace>
              )}
            </ModalTitle>
          )
        }
        footer={
          task && (
            <FooterContainer>
              <div>
                {task.canDelete && (
                  <DeleteTaskIcon
                    afterDelete={() => {
                      clearAllFormsTouched();
                      onCancel();
                    }}
                  />
                )}
              </div>
              <div>
                <MarkTaskCompleteButton disabled={loading || deleting} />
              </div>
            </FooterContainer>
          )
        }
      >
        {task && (
          <K2Spin spinning={saving || deleting}>
            <TaskTitleForm
              setFormTouched={props.setFormTouched}
              clearFormTouched={props.clearFormTouched}
            />
            <div>
              <FormItemTitle color={theme.grey_6}>
                <K2Message localeKey="tasks.dueDate" />
              </FormItemTitle>
            </div>
            <Row style={{ margin: 0 }}>
              <DateCol xs={10} sm={8} md={7}>
                <SetDueDateForm
                  setFormTouched={props.setFormTouched}
                  clearFormTouched={props.clearFormTouched}
                />
              </DateCol>
              <PriorityCol xs={24} sm={14} md={14}>
                <PriorityEditor />
              </PriorityCol>
            </Row>
            <SelectEditAssignees
              setFormTouched={props.setFormTouched}
              clearFormTouched={props.clearFormTouched}
            />
            <RelatedLink
              setFormTouched={props.setFormTouched}
              clearFormTouched={props.clearFormTouched}
              closeTask={() => {
                clearAllFormsTouched();
                onCancel();
              }}
            />
            {taskHasUploads && <TaskUploadsList />}
            <K2Tabs defaultActiveKey={tabShowing}>
              <TabPane tab="Notes" key="notes">
                {taskHasNotes &&
                  taskNotes?.map?.((note) => (
                    <NoteView
                      note={note}
                      key={note.id}
                      setFormTouched={props.setFormTouched}
                      clearFormTouched={props.clearFormTouched}
                    />
                  ))}

                {/* Component for adding a note */}
                <div ref={notesSection}>
                  <NoteView
                    setFormTouched={props.setFormTouched}
                    clearFormTouched={props.clearFormTouched}
                  />
                </div>
              </TabPane>
            </K2Tabs>
          </K2Spin>
        )}
      </K2Modal>
    </DirtyDataGuard>
  );
};

interface StateProps extends EditViewTaskState {
  saving: boolean;
}

const mapStateToProps = (state: IApplicationState): StateProps => {
  return {
    ...state.editViewTask,
    saving:
      state.editViewTask.savingNote ||
      state.editViewTask.updatingAssignees ||
      state.editViewTask.updatingDueDate ||
      state.editViewTask.updatingLinkedEntity ||
      state.editViewTask.updatingPriority ||
      state.editViewTask.updatingStatus ||
      state.editViewTask.updatingTitle ||
      state.editViewTask.deletingLinkedEntity ||
      state.editViewTask.deleting,
  };
};

interface IEditViewTaskDispatchProps {
  fetchTask: typeof actions.fetchTask;
  clearFetchingTaskError: typeof actions.clearFetchingTaskError;
  clearNoteEditStatus: typeof actions.clearNoteEditStatus;
  setFormTouched: typeof actions.setFormTouched;
  clearFormTouched: typeof actions.clearFormTouched;
  clearAllFormsTouched: typeof actions.clearAllFormsTouched;
}

const mapDispatchToProps: IEditViewTaskDispatchProps = {
  fetchTask: actions.fetchTask,
  clearFetchingTaskError: actions.clearFetchingTaskError,
  clearNoteEditStatus: actions.clearNoteEditStatus,
  setFormTouched: actions.setFormTouched,
  clearFormTouched: actions.clearFormTouched,
  clearAllFormsTouched: actions.clearAllFormsTouched,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectK2Intl(EditTask));
