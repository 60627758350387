import React, { FC } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { K2Routes } from "appRedux/models/routes/RouteModel";
import { asyncComponent } from "components/utils";

const {
  errors: { error, _401, _404, _500, unresponsive },
} = K2Routes;

const ErrorRoutes: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={error}
        component={asyncComponent(
          () =>
            import(/* webpackChunkName: "Errors" */ "../../containers/errors"),
        )}
      />
      <Route
        exact
        path={_401}
        component={asyncComponent(
          () =>
            import(
              /* webpackChunkName: "Error401" */ "../../components/errors/401"
            ),
        )}
      />
      <Route
        exact
        path={_404}
        component={asyncComponent(
          () =>
            import(
              /* webpackChunkName: "Error404" */ "../../components/errors/404"
            ),
        )}
      />
      <Route
        exact
        path={_500}
        component={asyncComponent(
          () =>
            import(
              /* webpackChunkName: "Error500" */ "../../components/errors/500"
            ),
        )}
      />
      <Route
        exact
        path={unresponsive}
        component={asyncComponent(
          () =>
            import(
              /* webpackChunkName: "ErrorUnresponsive" */ "../../components/errors/unresponsive"
            ),
        )}
      />
    </Switch>
  );
};

export default ErrorRoutes;
