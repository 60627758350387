import _ from "lodash";
import { FieldData } from "rc-field-form/lib/interface";

import AppManager from "appRedux/AppManager";
import { IValidationStatus } from "appRedux/modules/sharedTypes";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { showNotification } from "components/utils/Notification";

import {
  IValidationErrorWithSeverity,
  K2StatusCodes,
  ProblemDetails,
  ServerError,
} from "./ApiResponseModel";

export default class ServerErrorHandler {
  constructor(private k2intl: InjectedK2Intl, private error: ServerError) {}

  showServerErrorDetails(data: ProblemDetails, isProduction: boolean) {
    showNotification({
      type: "error",
      title: data?.title,
      message: isProduction
        ? UIUtils.messageToHtml(this.k2intl, "common.error.production", {
            ref: data?.instance,
          })
        : data?.detail,
    });
  }

  showGenericError() {
    showNotification({
      type: "error",
      k2Intl: this.k2intl,
      titleKey: "common.operationFailTitle",
      messageKey: "common.operationFailMessage",
    });
  }

  handleGeneric() {
    const isProductionEnv = AppManager.isProductionEnv;
    if (!this.error) return;
    switch (this.error.status) {
      case K2StatusCodes.internalServerError:
        this.showServerErrorDetails(this.error.data, isProductionEnv);
        break;
      case K2StatusCodes.forbidden:
        showNotification({
          type: "error",
          title: this.error.data?.title,
          duration: 100,
        });
        break;
      case K2StatusCodes.notFound:
        showNotification({
          type: "error",
          title: this.k2intl.formatMessage({ localeKey: "common.404error" }),
          message: isProductionEnv
            ? this.error.data?.title
            : `${this.error.data?.title}: ${this.error.data?.detail}`,
        });
        break;
      case K2StatusCodes.preconditionFailed:
      case K2StatusCodes.badRequest:
        if (!this.error.data) this.showGenericError();
        else this.showServerErrorDetails(this.error.data, isProductionEnv);
        break;
      default:
        break;
    }
  }
}

interface IErrorExtended {
  help?: string;
  validateStatus?: IValidationStatus;
  hasFeedback?: boolean;
}

export interface IValidationErrors {
  [key: string]: IErrorExtended & { errors: Error[] };
}

export interface IValidationErrorV4 extends FieldData, IErrorExtended {}

export class ServerErrorModel {
  public static getV4ValidationErrors(
    error: ServerError,
  ): IValidationErrorV4[] {
    const validationErrs = ServerErrorModel.getValidationErrors(error);
    if (!validationErrs) return null;

    return Object.keys(validationErrs).reduce(
      (acc: IValidationErrorV4[], fieldName: string): IValidationErrorV4[] => {
        // Create an error with the fieldName as it is returned by the server
        acc.push({
          name: fieldName,
          errors: validationErrs[fieldName].errors?.map?.((e) => e.message),
          help: validationErrs[fieldName].help,
          validateStatus: validationErrs[fieldName].validateStatus,
          hasFeedback: validationErrs[fieldName].hasFeedback,
        });

        // If field is nested, we will also provide an alternative fieldName, in the format of a NamePath
        // As we don't know whether the form uses strings or NamePaths for field names.
        // eslint-disable-next-line
        if (/[\[\]\.]/.test(fieldName)) {
          acc.push({
            name: ServerErrorModel.getPathFromFieldName(fieldName),
            errors: validationErrs[fieldName].errors?.map?.((e) => e.message),
            help: validationErrs[fieldName].help,
            validateStatus: validationErrs[fieldName].validateStatus,
            hasFeedback: validationErrs[fieldName].hasFeedback,
          });
        }
        return acc;
      },
      [],
    );
  }

  /**
   * This function returns a NamePath when passed a fieldName such as "email[0].type"
   * @param fieldName String
   */
  public static getPathFromFieldName(fieldName: string) {
    return (
      fieldName
        // eslint-disable-next-line
        .split(/[\[\]\.]/)
        .filter(Boolean)
        ?.map?.((elem) => {
          if (/^\d+$/.test(elem)) {
            return Number(elem);
          } else {
            return elem;
          }
        })
    );
  }

  public static getValidationErrors(error: ServerError): IValidationErrors {
    if (
      !error ||
      !error.data ||
      error.status !== K2StatusCodes.preconditionFailed
    )
      return null;

    const { errors } = error.data;
    if (!_.isEmpty(errors)) {
      let fieldsToSet: IValidationErrors = {};
      if (errors instanceof Array) {
        let errorsToProcess = errors as Array<IValidationErrorWithSeverity>;
        errorsToProcess.forEach((errorItem, i) => {
          let field = errorItem.property;
          if (!fieldsToSet[field])
            fieldsToSet[field] = {
              errors: [],
            };
          fieldsToSet[field].errors.push(new Error(errorItem.error));
          fieldsToSet[field].help = errorItem.error;
          fieldsToSet[
            field
          ].validateStatus = errorItem.severity as IValidationStatus;
          fieldsToSet[field].hasFeedback = true;
        });
      } else {
        for (let item in errors) {
          fieldsToSet[item] = {
            errors: errors[item]?.map?.((e) => new Error(e)),
          };
        }
      }

      return fieldsToSet;
    }

    return null;
  }
}
