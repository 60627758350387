import React from "react";

import { Timeline } from "antd";
import styled from "styled-components";

import { InjectedK2Intl } from "../k2Localizations/types";

interface IK2TimelineProps {
  k2Intl: InjectedK2Intl;
  style?: React.CSSProperties;
  items: {
    date?: string;
    title: string | JSX.Element;
    color: string;
    items?: {
      label: string;
      value: string;
    }[];
  }[];
}

const Container = styled(Timeline)`
  .ant-timeline-item {
    padding-bottom: 35px;
  }

  .ant-timeline-item-label {
    color: ${({ theme }) => theme.grey_6};
  }

  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    width: calc(30% - 12px);
  }

  .ant-timeline-item-head:after {
    content: "";
    display: block;
    background-color: ${({ theme }) => theme.white_color};
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-radius: 100%;
    z-index: -1;
  }

  .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
    left: 31%;
  }

  .ant-timeline.ant-timeline-alternate
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label
    .ant-timeline-item-left
    .ant-timeline-item-content {
    left: calc(30% - 4px);
    width: calc(70% - 14px);
  }

  .ant-timeline-item-tail {
    z-index: -2;
  }
`;

const TimelineBold = styled.div`
  font-weight: ${({ theme }) => theme.font_weight_heavy};
`;

const TimelineRegular = styled.div``;

function K2Timeline({ k2Intl, items, style }: IK2TimelineProps) {
  return (
    <Container style={style}>
      <Timeline mode="left">
        {items.map((item, i) => (
          <Timeline.Item
            key={i}
            label={item.date ? k2Intl.formatDate(new Date(item.date)) : null}
            color={item.color}
          >
            <TimelineBold>{item.title}</TimelineBold>
            {item?.items?.map?.((field, j) => (
              <p key={j}>
                <TimelineRegular>
                  {field.label}: {field.value}
                </TimelineRegular>
              </p>
            ))}
          </Timeline.Item>
        ))}
      </Timeline>
    </Container>
  );
}

export default K2Timeline;
