import {
  ActivityLogCriteria,
  ActivityLogResultsPage,
} from "@n3oltd/karakoram.activities.sdk.activities/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";

export enum ActionTypes {
  SEARCH_ACTIVITIES = "@activities/search/find",
  SEARCH_ACTIVITIES_COMPLETE = "@activities/search/complete",
  CLEAR_SEARCH_ACTIVITIES = "@activities/search/clear",
  CLEAR_SERVER_ERROR = "@activities/search/clear/server-error",
}
//** ACTION RETURN TYPES */
export interface SearchActivities {
  type: ActionTypes.SEARCH_ACTIVITIES;
  loadMore: boolean;
  criteria: ActivityLogCriteria;
}

export interface SearchActivitiesComplete {
  type: ActionTypes.SEARCH_ACTIVITIES_COMPLETE;
  loadMore: boolean;
  results: ActivityLogResultsPage;
  serverError?: ServerError;
}

export interface ClearSearch {
  type: ActionTypes.CLEAR_SEARCH_ACTIVITIES;
}

export interface ClearError {
  type: ActionTypes.CLEAR_SERVER_ERROR;
}
