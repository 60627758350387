import React, { FC } from "react";

import { LocalizationSettingsRes } from "@n3oltd/k2.users.sdk.users/esm";
import { ActivityLogRes } from "@n3oltd/karakoram.activities.sdk.activities/esm";
import { List, Skeleton } from "antd";
import _ from "lodash";
import styled from "styled-components";

import { K2Card } from "components/k2Widgets";
import { IK2CardProps } from "components/k2Widgets/k2Card";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import ActivityItem from "components/recentActivities/ActivityItem";
import { CustomScrollbars } from "components/utils";
import { MAX_ACTIVITIES_TO_SHOW } from "constants/appConstants";

interface RecentActivityListProps {
  data: ActivityLogRes[];
  currentUserLocalization: LocalizationSettingsRes;
  cardProps?: IK2CardProps;
  loading?: boolean;
  maxHeight?: number;
  k2Intl: InjectedK2Intl;
}

const K2CardWrapper = styled(K2Card)`
  && {
    .ant-card-body {
      padding-right: 10px;
    }
    border-radius: 8px;
    margin: 24px 0 0;

    .ant-card-extra {
      padding: 24px 0 !important;
    }

    .ant-card-actions {
      padding: 14px 24px;
      margin-bottom: 0;

      li {
        list-style-type: none;
        text-align: center;
      }
    }
  }
`;

const ListWithStyle = styled(List)`
  && {
    padding-right: 24px;

    .ant-list-item-meta-description {
      font-size: small;
      color: ${({ theme }) => theme.grey_7};
    }
  }
`;

const RecentActivityList: FC<RecentActivityListProps> = ({
  data,
  currentUserLocalization,
  cardProps = {},
  loading,
  maxHeight,
  k2Intl,
}) => {
  const recentActivityData: ActivityLogRes[] = data?.length
    ? _.orderBy(data, "timeStamp", "asc")
    : [];

  const Content: FC = () => (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <ListWithStyle
          itemLayout="horizontal"
          dataSource={
            maxHeight === undefined
              ? recentActivityData.slice(0, MAX_ACTIVITIES_TO_SHOW)
              : recentActivityData
          }
          renderItem={(item) => (
            <ActivityItem
              k2Intl={k2Intl}
              item={item}
              currentUserLocalization={currentUserLocalization}
            />
          )}
        />
      )}
    </>
  );

  return (
    <K2CardWrapper {...cardProps}>
      {" "}
      {maxHeight === undefined ? (
        <Content />
      ) : (
        <CustomScrollbars
          autoHide={false}
          autoHeight={true}
          autoHeightMin={maxHeight}
          autoHeightMax={maxHeight}
        >
          <Content />
        </CustomScrollbars>
      )}
    </K2CardWrapper>
  );
};

export default RecentActivityList;
