import React, { ReactText } from "react";

import { SearchOutlined } from "@ant-design/icons/lib";
import {
  DonationItemCriteriaSortProperty,
  DonationItemStatus,
  DonationItemSummaryRes,
  Reference,
} from "@n3oltd/k2.donations.sdk.donation-items/esm";
import { FundStructureRevisionRes } from "@n3oltd/k2.subscriptions.sdk.funds/esm";
import _ from "lodash";

import { IK2GridColumn } from "appRedux/models/grid/K2GridColumn";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import BaseListModel from "appRedux/models/subscription/BaseListModel";
import { NamedLookup } from "appRedux/modules/lookups/types";
import EditLinkIcon from "components/editLinkIcon";
import { K2Link, K2Message } from "components/k2Widgets";
import { ActionDropDownItem } from "components/k2Widgets/k2Dropdowns/ActionsDropDown";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import DonationItemStatusTag from "components/statusTag/DonationItemStatusTag";
import ColumnSearch from "components/tableComponents/columnSearch";
import ModifiedInfo from "components/tableComponents/columns/modifiedInfo";
import { Utils } from "components/utils";

import { DonationItemsListProps } from "./connect";

export default class DonationItemListModel extends BaseListModel {
  constructor(props: DonationItemsListProps) {
    super(props);
  }

  getFundDimensionsColumns(
    fundDimensions: FundStructureRevisionRes,
    filtersInfo: Record<string, ReactText[]>,
  ): IK2GridColumn<DonationItemSummaryRes>[] {
    const sortedFundDimensions = Utils.sortDimensions(fundDimensions);

    const dimensionColumns: IK2GridColumn<DonationItemSummaryRes>[] = [];

    sortedFundDimensions.forEach((dim) => {
      if (!dim || !dim.fundDimension || !dim.fundDimension.isActive) {
        return;
      }

      const key = dim.key;

      dimensionColumns.push({
        dataIndex: dim.key,
        title: dim.fundDimension.name,
        showInActionModal: false,
        render: (_text, item: DonationItemSummaryRes) => {
          if (item.fundDimensionOptions) {
            const ordered = _.orderBy(
              item.fundDimensionOptions[key],
              null,
              "asc",
            );
            const twoToShow = ordered.slice(0, 2);
            return `${twoToShow.join(", ")}${
              ordered.length > 2
                ? " " +
                  this.config.k2Intl?.formatMessage({
                    localeKey: "common.andMore",
                    values: {
                      number: ordered.length - 2,
                    },
                  })
                : ""
            }`;
          }
        },
        filters: [
          ...dim.fundDimension.restrictedOptions,
          dim.fundDimension.unrestrictedOption,
        ]
          .filter(Boolean)
          ?.map?.((d) => ({
            text: d,
            value: d,
          })),
        filterMultiple: false,
        filteredValue: filtersInfo?.[dim.key] || null,
      });
    });

    return dimensionColumns;
  }

  getColumns(
    fundDimensions: FundStructureRevisionRes,
    donationItemStatuses: NamedLookup[],
    k2Intl: InjectedK2Intl,
    filtersInfo: Record<string, ReactText[]>,
  ) {
    return [
      {
        title: this.config.k2Intl.formatMessage({ localeKey: "common.ref" }),
        width: "120px",
        dataIndex: DonationItemCriteriaSortProperty.Reference,
        key: "reference",
        sorter: true,
        render: (ref: Reference, record: DonationItemSummaryRes) => (
          <K2Link
            to={K2Routes.editDonationItem.replace(":donationItemId", record.id)}
          >
            {ref.text}
          </K2Link>
        ),
      },
      {
        width: "180px",
        dataIndex: DonationItemCriteriaSortProperty.Name,
        key: "name",
        sorter: true,
        title: <K2Message localeKey={"common.name"} />,
        filterDropdown: (props) => {
          return (
            <ColumnSearch
              {...props}
              placeholderFieldName={k2Intl?.formatMessage({
                localeKey: "common.name",
              })}
            />
          );
        },
        filterIcon: (filtered: boolean) => <SearchOutlined />,
        filteredValue: filtersInfo.name || null,
        showInActionModal: true,
      },
      ...this.getFundDimensionsColumns(fundDimensions, filtersInfo),
      {
        dataIndex: "status",
        key: "status",
        title: <K2Message localeKey={"common.status"} />,
        filterMultiple: true,
        filteredValue: filtersInfo.status || null,
        width: "10%",
        filters: donationItemStatuses.map((s) => ({
          text: s.name,
          value: s.id,
        })),
        render: (_text, item: DonationItemSummaryRes) => {
          return (
            <DonationItemStatusTag
              status={item.status}
              donationItemStatuses={donationItemStatuses}
            />
          );
        },
      },
      {
        dataIndex: DonationItemCriteriaSortProperty.CreatedAt,
        key: "created",
        title: this.config.k2Intl.formatMessage({
          localeKey: "common.createdAt",
        }),
        width: "180px",
        sorter: true,
        render: (_, item: DonationItemSummaryRes) => {
          return (
            <ModifiedInfo
              modifiedInfo={item.modifiedInfo}
              k2Intl={this.config.k2Intl}
              mode={"createdAt"}
            />
          );
        },
      },
      {
        dataIndex: "id",
        title: "",
        width: "5%",
        render: (_text, item: DonationItemSummaryRes) => {
          return (
            <div style={{ textAlign: "right" }}>
              <K2Link
                to={K2Routes.editDonationItem.replace(
                  ":donationItemId",
                  item.id,
                )}
              >
                <EditLinkIcon />
              </K2Link>
            </div>
          );
        },
      },
    ];
  }

  getGridActions(): ActionDropDownItem[] {
    return [
      { key: "markActive", showConfirm: true },
      { key: "markInactive", showConfirm: true },
    ];
  }
}
