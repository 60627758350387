import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { generateUuid } from "@azure/ms-rest-js";
import { Divider, Select, Space } from "antd";
import { RefSelectProps, SelectProps, SelectValue } from "antd/lib/select";
import { usePrevious } from "hooks";
import _ from "lodash";
import styled from "styled-components";

import FakeLink from "components/k2Widgets/k2FakeLink";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

import K2InputV4 from "../k2Inputs/K2InputV4";
import K2Message from "../k2Localizations/K2Message";
import { InjectedK2IntlProps, K2MessageValue } from "../k2Localizations/types";
import K2Spin from "../k2Spin";

const SelectAllSpan = styled.span`
  display: block;
  margin-top: -22px;
  padding-right: 5px;
  text-align: right;
`;

const P = styled.p`
  margin: 10px;
  span {
    color: ${({ theme }) => theme.link_color};
    &:hover {
      color: ${({ theme }) => theme.primaryColorDarkened};
    }
  }
`;

const LoadMoreContainer = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.grey_3};
  }
`;

/**
 * The reason for K2SelectV4 is basically it can handle
 * that situation for your where you need to focus
 * the input and open the dropdown automatically
 * plus can also handle localizations etc for you. Makes your life easier.
 */
export interface K2SelectProps<T = SelectValue>
  extends SelectProps<T>,
    InjectedK2IntlProps {
  /**locale key for placeholder */
  placeholderKey?: string;

  placeholderValues?: { [key: string]: K2MessageValue };

  /**data source, {idField: string, nameField: string}[] */
  dataSource?: Array<Object>;
  /**Name of the id field property in data source
   * default: id
   */
  idField?: string;
  /**Name of the name field property in data source
   * default: name
   */
  nameField?: string;

  optionsList?: JSX.Element | JSX.Element[];

  /**when user changes the value control */
  onAfterChange?: any;

  /**to show country icons*/
  showFlagIcon?: boolean;

  /**show Add custom values section */
  showAddCustomValues?: boolean;

  /**Add custom values button key */
  addCustomValueButtonKey?: string;

  /**Add custom values place holder key */
  addCustomValuePlaceholderKey?: string;

  /**To open on focus */
  focusInputOnFocus?: boolean;

  /**Add an option to allow user to quickly select all values (if multi)*/
  allowQuickSelectAll?: boolean;

  /*Show Spinner at the bottom when loading more*/
  loadingMore?: boolean;

  /**This options show load more at the bottom */
  showOnLoadMore?: boolean;

  /**LoadMore Callback*/
  onLoadMore?: () => void;

  customOnChange?: (v: string | string[]) => void;
}

const getOptions = (
  idField: string,
  nameField: string,
  dataSource: Array<Object>,
  optionsList?: JSX.Element | JSX.Element[],
  showFlagIcon?: boolean,
) => {
  if (!idField) idField = "id";
  if (!nameField) nameField = "name";

  if (!dataSource && !optionsList)
    console.error(
      "Please provide one of the two props, dataSource or options, in order for the select content to render",
    );
  if (dataSource) {
    return dataSource?.map?.((item) => {
      return (
        <Select.Option
          key={item[idField]}
          value={item[idField]}
          title={item[showFlagIcon ? "name" : nameField]}
        >
          {showFlagIcon && (
            <i
              className={`flag flag-24 n3o-mr-2 flag-${item[
                idField
              ].toLowerCase()}`}
            />
          )}
          {item[nameField]}
        </Select.Option>
      );
    });
  } else {
    return optionsList;
  }
};

const K2SelectV4 = <T extends SelectValue>(
  props: PropsWithChildren<K2SelectProps<T>>,
) => {
  const {
    mode,
    className,
    dropdownClassName,
    placeholderKey,
    placeholderValues,
    k2Intl,
    localizationSettings,
    placeholder,
    idField,
    nameField,
    dataSource,
    optionsList,
    showAddCustomValues,
    addCustomValueButtonKey,
    addCustomValuePlaceholderKey,
    focusInputOnFocus,
    showOnLoadMore,
    loadingMore,
    onSelect,
    onChange,
    customOnChange,
    onBlur,
    onFocus,
    onLoadMore,
    onAfterChange,
    showFlagIcon,
    allowQuickSelectAll,
    ...rest
  } = props;

  if (allowQuickSelectAll && mode !== "multiple") {
    throw new Error("allowQuickSelectAll can only be used in multiple mode");
  }

  const selectRef = useRef<RefSelectProps>(null);

  const [openDropdownOnFocus, setOpenDropdownOnFocus] = useState<boolean>(
    false,
  );
  const [customValue, setCustomValue] = useState("");
  const [dataSourceList, setDataSourceList] = useState(dataSource);
  const prevDataSourceList = usePrevious(dataSource);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const handleInputDomNode = useCallback(
    (focus: boolean = false) => {
      if (focusInputOnFocus) {
        const currentSelect = selectRef?.current;

        setTimeout(() => {
          if (!focus && openDropdownOnFocus) {
            currentSelect?.blur?.();
            currentSelect?.["getInputDOMNode"]?.()?.blur();
          } else if (focus && !openDropdownOnFocus) {
            currentSelect?.["getInputDOMNode"]?.()?.focus();
          }
        }, 1);
      }
    },
    [focusInputOnFocus, openDropdownOnFocus],
  );

  const onSelectHandler = useCallback(
    (e, o) => {
      if (_.isNil(mode)) {
        setOpenDropdownOnFocus(false);
        handleInputDomNode();
      } else {
        handleInputDomNode(true);
      }
      onSelect?.(e, o);
    },
    [mode, handleInputDomNode, onSelect],
  );

  const onChangeHandler = useCallback(
    (e, o) => {
      if (_.isNil(mode)) {
        setOpenDropdownOnFocus(false);
      }

      // Allow the "Select All" option to re-appear if the user has not selected all possible options
      let dataSourceLength = dataSource
        ? dataSource.length
        : dataSourceList
        ? dataSourceList.length
        : Array.isArray(optionsList) && optionsList.length;
      if (Array.isArray(e) && e?.length !== dataSourceLength) {
        setAllSelected(false);
      }
      if (_.isNil(mode)) {
        handleInputDomNode();
      } else {
        handleInputDomNode(true);
      }
      onChange?.(e, o);
      customOnChange?.(e);
      onAfterChange?.(e);
    },
    [
      mode,
      onAfterChange,
      optionsList,
      dataSourceList,
      dataSource,
      customOnChange,
      handleInputDomNode,
      onChange,
    ],
  );

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      setOpenDropdownOnFocus(false);

      handleInputDomNode();

      onBlur?.(event);
    },
    [handleInputDomNode, onBlur],
  );

  const handleOnFocus = useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      setOpenDropdownOnFocus(true);

      handleInputDomNode(true);

      onFocus?.(event);
    },
    [handleInputDomNode, onFocus],
  );

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const code = e.keyCode || e.which;

    if (code === 9 || code === 27) {
      setOpenDropdownOnFocus(false);

      setTimeout(() => {
        const currentSelect = selectRef?.current;

        currentSelect?.["getInputDOMNode"]?.()?.blur();
        currentSelect?.blur();
      }, 1);
    }
  };

  useEffect(() => {
    if (!_.isEqual(prevDataSourceList, dataSource)) {
      setDataSourceList(dataSource);
    }
  }, [dataSource, dataSourceList, prevDataSourceList]);

  // If initial values are all selected, don't show the Select All option
  useEffect(() => {
    [dataSource, dataSourceList, optionsList].forEach((source) => {
      if (source) {
        if (
          Array.isArray(rest.value) &&
          Array.isArray(source) &&
          rest.value.length === source.length
        ) {
          setAllSelected(true);
        }
      }
    });
  }, [dataSource, dataSourceList, optionsList, rest.value]);

  const addCustomItem = useCallback(() => {
    if (customValue?.length > 0) {
      let dataSourceListUpdated = dataSourceList;
      dataSourceListUpdated.push({
        [idField]: customValue,
        [nameField]: customValue,
      });

      setDataSourceList(dataSourceListUpdated);
      setCustomValue("");
    }
  }, [customValue, dataSourceList, idField, nameField]);

  const pHolder = useMemo(
    () =>
      k2Intl && placeholderKey
        ? k2Intl?.formatHtmlMessage({
            localeKey: placeholderKey,
            values: placeholderValues,
          })
        : placeholder,
    [k2Intl, placeholder, placeholderKey, placeholderValues],
  );

  const selectId = useMemo(() => generateUuid(), []);
  const selectElements = useMemo(
    () => document.getElementsByClassName(selectId),
    [selectId],
  );

  const onClickEvent = useCallback(
    (event: MouseEvent) => {
      if (!openDropdownOnFocus) {
        handleOnFocus(null);
      }
    },
    [handleOnFocus, openDropdownOnFocus],
  );

  useEffect(() => {
    selectElements?.[0]?.addEventListener("click", onClickEvent);

    return () => {
      selectElements?.[0]?.removeEventListener("click", onClickEvent);
    };
  });

  const handleLoadMoreClick = (e) => {
    onLoadMore?.();
  };
  return (
    <>
      {allowQuickSelectAll && !allSelected && (
        <SelectAllSpan>
          <FakeLink
            onClick={() => {
              onChangeHandler(
                dataSource?.map((item) => item[idField]),
                [],
              );
              setAllSelected(true);
            }}
          >
            <K2Message localeKey="common.selectAll" />
          </FakeLink>
        </SelectAllSpan>
      )}
      <Select<T>
        ref={selectRef}
        mode={mode}
        placeholder={pHolder}
        className={`k2-select ${selectId} ${className}`}
        showArrow
        dropdownClassName={`custom-scrollbar-style ${dropdownClassName}`}
        getPopupContainer={(trigger: HTMLElement) => {
          const element = document.getElementById("route-section");
          return !_.isNull(element)
            ? element
            : (trigger.parentNode as HTMLElement);
        }}
        {...rest}
        optionFilterProp={"children"}
        optionLabelProp={"children"}
        onSelect={onSelectHandler}
        onBlur={handleOnBlur}
        onChange={onChangeHandler}
        onFocus={handleOnFocus}
        open={openDropdownOnFocus}
        onInputKeyDown={onInputKeyDown}
        dropdownRender={
          showAddCustomValues || showOnLoadMore
            ? (menu) => (
                <div
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {menu}
                  {showOnLoadMore && (
                    <LoadMoreContainer
                      aria-selected="false"
                      className="ant-select-item ant-select-item-option n3o-text-center"
                      title={k2Intl?.formatMessage({
                        localeKey: "common.loadMore",
                      })}
                      onClick={handleLoadMoreClick}
                    >
                      <div className="ant-select-item-option-content">
                        <Space>
                          <P>
                            <K2Message localeKey="common.loadMore" />
                          </P>
                          <K2Spin
                            spinning={loadingMore}
                            indicator={<LoadingOutlined />}
                            size="small"
                          />
                        </Space>
                      </div>
                    </LoadMoreContainer>
                  )}
                  {showAddCustomValues && (
                    <>
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <K2InputV4
                          style={{ flex: "auto" }}
                          placeholderKey={addCustomValuePlaceholderKey}
                          value={customValue}
                          onChange={(e) => setCustomValue(e.target.value)}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={addCustomItem}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          <PlusOutlined />{" "}
                          <K2Message
                            localeKey={"common.add"}
                            spanClassName={"n3o-mr-2"}
                          />
                          <K2Message localeKey={addCustomValueButtonKey} />
                        </a>
                      </div>
                    </>
                  )}
                </div>
              )
            : undefined
        }
      >
        {(!_.isUndefined(dataSourceList) || !_.isUndefined(optionsList)) &&
          getOptions(
            idField,
            nameField,
            dataSourceList,
            optionsList,
            showFlagIcon,
          )}
      </Select>
    </>
  );
};

const K2SelectInjected = injectK2Intl(K2SelectV4);

export const K2MultiSelectV4Styled = styled(K2SelectInjected)`
  &.ant-select-multiple {
    .ant-select-selector {
      .ant-select-selection-item {
        border-radius: 8px;
        height: 32px;
        display: flex;
        align-items: center;
      }
    }
  }
`;

export default K2SelectInjected;
