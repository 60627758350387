import { notification } from "antd";
import { ArgsProps, ConfigProps } from "antd/es/notification";
import _ from "lodash";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import React from "react";
import CustomScrollbars from "components/utils/CustomScrollbars";

// Set to 100 so that it is only destroyed by a page navigation change
const DEFAULT_ERROR_DURATION = 100;
const DEFAULT_SUCCESS_DURATION = 3;
const DEFAULT_WARNING_DURATION = 3;
const DEFAULT_INFO_DURATION = 3;

// What Antd calls "message" we call "title".
// What Antd calls "description" we call "message".
// We should probably change this, it's confusing.
interface NotificationLocalizedProps
  extends Omit<ArgsProps, "message" | "description"> {
  type: "success" | "error" | "info" | "warning";
  k2Intl?: InjectedK2Intl;
  titleKey?: string;
  messageKey?: string;
  messageValues?: { [key: string]: string };
  duration?: number | null;
  title?: React.ReactNode;
  message?: React.ReactNode;
}

const getCustomScrollbars = (description: React.ReactNode) => {
  return (
    <CustomScrollbars
      autoHide
      autoHeight={true}
      autoHeightMin={10}
      autoHeightMax={250}
    >
      {description}
    </CustomScrollbars>
  );
};

/**
 * The most generic notification function.
 * You can pass it localized messages or keys, and it will render correctly
 * @param options NotificationLocalizedProps
 */
export const showNotification = (options: NotificationLocalizedProps) => {
  if (options.messageKey && !options.k2Intl) {
    throw new Error("k2Intl is required when passing messageKey");
  }
  if (options.titleKey && !options.k2Intl) {
    throw new Error("titleKey is required when passing messageKey");
  }

  // If we are trying to show a localized notification but k2Intl not passed, throw error
  if (
    options.hasOwnProperty("messageKey") ||
    options.hasOwnProperty("titleKey")
  ) {
    if (!options.k2Intl) {
      throw new Error("k2Intl must be passed");
    }

    if (options.type === "error") {
      showErrorNotificationLocalized(options);
    } else if (options.type === "warning") {
      showWarningNotificationLocalized(options);
    } else if (options.type === "info") {
      showInfoNotificationLocalized(options);
    } else if (options.type === "success") {
      showSuccessNotificationLocalized(options);
    }
  } else {
    // Message is not localized, show custom scrollbars in case it is long
    options = {
      ...options,
      message: getCustomScrollbars(options.message),
    };

    if (options.type === "error") {
      showErrorNotification({
        ...options,
        message:
          options.title ||
          options.k2Intl?.formatMessage?.({
            localeKey: "common.notificationTitle.error",
          }),
        description: options.message,
      });
    } else if (options.type === "warning") {
      showWarningNotification({
        ...options,
        message:
          options.title ||
          options.k2Intl?.formatMessage?.({
            localeKey: "common.notificationTitle.warning",
          }),
        description: options.message,
      });
    } else if (options.type === "info") {
      showInfoNotification({
        ...options,
        message:
          options.title ||
          options.k2Intl?.formatMessage?.({
            localeKey: "common.notificationTitle.info",
          }),
        description: options.message,
      });
    } else if (options.type === "success") {
      showSuccessNotification({
        ...options,
        message:
          options.title ||
          options.k2Intl?.formatMessage?.({
            localeKey: "common.notificationTitle.success",
          }),
        description: options.message,
      });
    }
  }
};

const showSuccessNotification = (options: ArgsProps) => {
  notification.open({
    duration: DEFAULT_SUCCESS_DURATION,
    type: "success",
    ...options,
  });
};

const showSuccessNotificationLocalized = (
  options: NotificationLocalizedProps,
) => {
  showNotificationLocalized({
    type: "success",
    duration: DEFAULT_SUCCESS_DURATION,
    ...options,
  });
};

export const showSuccessNotificationCommon = (
  options: Omit<NotificationLocalizedProps, "titleKey" | "messageKey" | "type">,
) => {
  showSuccessNotificationLocalized({
    ...options,
    titleKey: "common.success.title",
    messageKey: "common.changesSaved",
    type: "success",
  });
};

const showErrorNotification = (options: ArgsProps) => {
  notification.open({
    type: "error",
    duration: DEFAULT_ERROR_DURATION,
    ...options,
  });
};

const showErrorNotificationLocalized = (
  options: NotificationLocalizedProps,
) => {
  showNotificationLocalized({
    type: "error",
    duration: DEFAULT_ERROR_DURATION,
    ...options,
  });
};

const showInfoNotification = (options: ArgsProps) => {
  notification.open({
    type: "info",
    duration: DEFAULT_INFO_DURATION,
    ...options,
  });
};

const showInfoNotificationLocalized = (options: NotificationLocalizedProps) => {
  showErrorNotificationLocalized({
    type: "info",
    duration: DEFAULT_INFO_DURATION,
    ...options,
  });
};

const showWarningNotification = (options: ArgsProps) => {
  notification.open({
    duration: DEFAULT_WARNING_DURATION,
    type: "warning",
    ...options,
  });
};

const showWarningNotificationLocalized = (
  options: NotificationLocalizedProps,
) => {
  showNotificationLocalized({
    duration: DEFAULT_WARNING_DURATION,
    type: "warning",
    ...options,
  });
};

export const closeNotification = (key: string) => {
  notification.close(key);
};

export const clearNotifications = () => {
  notification.destroy();
};

const showNotificationLocalized = (options: NotificationLocalizedProps) => {
  const {
    type,
    k2Intl,
    titleKey,
    messageKey,
    messageValues,
    duration,
    title,
    message,
  } = options;
  const notificationTitle =
    title || k2Intl?.formatMessage({ localeKey: titleKey });
  const notificationMessage =
    message ||
    k2Intl?.formatMessage({
      localeKey: messageKey,
      values: messageValues,
    });

  notification.open({
    type,
    message: notificationTitle,
    description: getCustomScrollbars(notificationMessage),
    duration,
  });
};

export const initializeDefaultNotification = (options?: ConfigProps) => {
  if (!_.isUndefined(options) && !_.isEmpty(options)) {
    notification.config(options);
  } else {
    notification.config({
      placement: "bottomRight",
    });
  }
};
