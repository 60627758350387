import React, { Component, ComponentType, FC } from "react";

import { Typography } from "antd";
import _ from "lodash";
import { IntlProvider } from "react-intl";

import NumberFormatProvider from "appRedux/models/localization/NumberFormatProvider";
import { IAppSettingsProps } from "appRedux/store/types/SubscriptionTypes";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

import { K2IntlInjector, injectK2AppSettings } from "./injectK2Intl";

const { Text } = Typography;

export interface K2NumberProps {
  value?: number;
  options?: Intl.NumberFormatOptions;
  disabled?: boolean;
}
const K2Number: FC<K2NumberProps> = (props) => {
  return <K2DateInternalInjected {...props} />;
};

const K2NumberInternal: FC<InjectedK2IntlProps & K2NumberProps> = ({
  value,
  options,
  k2Intl,
  localizationSettings,
  ...rest
}) => {
  return (
    <Text {..._.omit(rest, "dispatch")}>
      {k2Intl?.formatNumber(value, options)}
    </Text>
  );
};

const K2DateInternalInjected = injectNumberFormat(K2NumberInternal);

function injectNumberFormat<P>(
  WrappedComponent: ComponentType<InjectedK2IntlProps & K2NumberProps>,
) {
  class NumberFormatInject extends Component<
    IAppSettingsProps & InjectedK2IntlProps & K2NumberProps
  > {
    render() {
      const { appSettings, ...rest } = this.props;
      const { localizationKeys } = appSettings;
      const config = NumberFormatProvider.getConfig(
        localizationKeys.numberFormat,
      );

      let options = this.props.options;
      if (options && options.style === "currency") {
        options.currency = options.currency
          ? options.currency
          : localizationKeys.baseCurrency;
      }

      return (
        <IntlProvider locale={config.culture}>
          <K2IntlInjector
            WrappedComponent={WrappedComponent}
            appSettings={appSettings}
            {...rest}
          />
        </IntlProvider>
      );
    }
  }

  return injectK2AppSettings(NumberFormatInject);
}

export default K2Number;
