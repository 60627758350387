import React, { FC, useEffect, useState } from "react";

import { generateUuid } from "@azure/ms-rest-js";
import {
  ContentVisibility,
  LetterheadReq,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";
import { Skeleton } from "antd";
import { useForm } from "antd/es/form/Form";
import { useRouter } from "hooks";
import styled from "styled-components";

import { K2StatusCodes } from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { DataActions } from "appRedux/modules/sharedTypes";
import NotFoundWrapper from "components/NotFoundWrapper";
import { K2RouteHeading } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { FormsWrapper } from "components/layout/wrappers";
import { UIUtils } from "components/utils";
import { showNotification } from "components/utils/Notification";
import { REDIRECT_AFTER_SAVE_MS } from "constants/appConstants";
import useBreadcrumb from "hooks/useBreadcrumb";
import UploadOverlayModal from "routes/admin/communication/newEditPdfStationery/components/UploadOverlayModal";
import {
  LetterheadReqWithScreenOnly,
  OverlayResWithScreenOnlyAndUUID,
} from "routes/admin/communication/newEditPdfStationery/modules/types";

import CreateEditStationeryForm from "./components/CreateEditStationeryForm";
import { NewEditPdfStationeryProps } from "./connect";

const CardWrapper = styled.div`
  margin-top: 2rem;
`;

const NewEditPdfStationery: FC<NewEditPdfStationeryProps> = (props) => {
  const {
    updateLetterhead,
    createLetterhead,
    data,
    fetchLetterhead,
    loading,
    error,
    clearLetterheadError,
    clearLetterhead,
    k2Intl,
    editStatus,
    updateDirty,
    clearEditStatus,
  } = props;
  const stationeryId = props.match.params.stationeryId;
  const editMode = !!stationeryId;
  const router = useRouter();

  // These are the overlays (layers) that are currently visible on the list of overlays,
  // including new uploads that have been added
  const [tempOverlays, setTempOverlays] = useState<
    OverlayResWithScreenOnlyAndUUID[]
  >([]);

  const [overallErrors, setOverallErrors] = useState<string[]>([]);

  const [modalShowing, toggleModalShowing] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const [form] = useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
      clearLetterhead();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setTempOverlays(
        data.overlays?.map?.((o) => ({
          ...o,
          screenOnly: o.visibility === "screen",
          uuid: generateUuid(),
        })),
      );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      if (error.status === K2StatusCodes.notFound) {
        setNotFound(true);
        clearLetterheadError();
      } else {
        UIUtils.handleServerError(k2Intl, error, clearLetterheadError);
      }
    }
  }, [error, clearLetterheadError, k2Intl]);

  const setBreadcrumbs = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbs([
      {
        path: K2Routes.main.admin,
        titleKey: "mainNav.admin",
        active: false,
      },
      {
        path: K2Routes.communicationsPdfStationery,
        titleKey: `admin.communication.pdfStationery`,
        active: false,
      },
      {
        path: editMode
          ? K2Routes.communicationsEditPdfStationery
          : K2Routes.communicationsNewPdfStationery,
        title: editMode && data ? data.name : null,
        titleKey: `admin.communication.stationery.${editMode ? "edit" : "new"}`,
        active: true,
      },
    ]);
  }, [setBreadcrumbs, editMode, data]);

  useEffect(() => {
    if (editStatus) {
      switch (editStatus.action) {
        case DataActions.add:
        case DataActions.update:
          if (editStatus.error) {
            if (editStatus.error.status === K2StatusCodes.preconditionFailed) {
              // Handle field-level validation errors and set them on the form
              UIUtils.handleValidationErrors(editStatus.error, form);

              const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
                editStatus.error,
                "error",
              );

              // Errors take precedence, show them at the foot of the form
              if (nonFieldRelatedErrors.length) {
                setOverallErrors(
                  nonFieldRelatedErrors?.map?.((err) => err.error),
                );
              }
            } else {
              UIUtils.handleServerError(k2Intl, editStatus.error);
            }
          } else {
            showNotification({
              type: "success",
              titleKey: "common.success.title",
              messageKey: `admin.communication.stationery.${
                editStatus.action === DataActions.update ? "updated" : "created"
              }`,
              k2Intl,
            });

            updateDirty(false);

            setTimeout(() => {
              // Redirect back to main page
              router.history.push(K2Routes.communicationsPdfStationery);
            }, REDIRECT_AFTER_SAVE_MS);
          }
          clearEditStatus();
          break;
      }
    }
  }, [editStatus, form, k2Intl, router, updateDirty, clearEditStatus]);

  useEffect(() => {
    setNotFound(false);
    if (stationeryId) {
      fetchLetterhead(stationeryId);
    }
  }, [stationeryId, fetchLetterhead]);

  const handleCreateEditLetterhead = (values: LetterheadReqWithScreenOnly) => {
    setOverallErrors(null);

    const req: LetterheadReq = {
      ...values,
      overlays: tempOverlays?.map?.((overlay, i) => ({
        storageToken: overlay.attachment.storageToken,
        visibility: values.overlays[i].screenOnly
          ? ContentVisibility.Screen
          : ContentVisibility.Both,
      })),
    };

    if (editMode) {
      updateLetterhead(data.revisionId, req);
    } else {
      createLetterhead(req);
    }
  };

  return (
    <FormsWrapper>
      <NotFoundWrapper
        showNotFound={notFound}
        entityNameKey={"common.entity.action.stationery"}
      >
        <K2RouteHeading
          headingKey={`admin.communication.stationery.${
            editMode ? "edit" : "new"
          }`}
        />
        {modalShowing && (
          <UploadOverlayModal
            k2Intl={k2Intl}
            form={form}
            onSave={(newOverlay: OverlayResWithScreenOnlyAndUUID) => {
              toggleModalShowing(false);
              updateDirty(true);
              setTempOverlays([...tempOverlays, newOverlay]);
            }}
            onCancel={() => toggleModalShowing(false)}
          />
        )}
        <CardWrapper>
          <Skeleton active loading={loading}>
            {!loading && (
              <CreateEditStationeryForm
                form={form}
                toggleModalShowing={toggleModalShowing}
                errors={overallErrors}
                onSubmit={handleCreateEditLetterhead}
                setTempOverlays={setTempOverlays}
                tempOverlays={tempOverlays}
                letterheadId={stationeryId}
                {...props}
              />
            )}
          </Skeleton>
        </CardWrapper>
      </NotFoundWrapper>
    </FormsWrapper>
  );
};

export default injectK2Intl(NewEditPdfStationery);
