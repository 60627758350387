import {
  SmsCompositionReq,
  SmsCompositionRes,
} from "@n3oltd/karakoram.templates.sdk.sms/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

import { ActionTypes, SMSTemplateAction } from "./actionTypes";

export const fetchSMSTemplate = (id: string): SMSTemplateAction => ({
  type: ActionTypes.FETCH_SMS_TEMPLATE,
  id,
});

export const fetchSMSTemplateSuccess = (
  data: SmsCompositionRes,
): SMSTemplateAction => ({
  type: ActionTypes.FETCH_SMS_TEMPLATE_SUCCESS,
  data,
});

export const fetchSMSTemplateError = (
  error: ServerError,
): SMSTemplateAction => ({
  type: ActionTypes.FETCH_SMS_TEMPLATE_ERROR,
  error,
});

export const clearSMSTemplateError = (): SMSTemplateAction => ({
  type: ActionTypes.CLEAR_SMS_TEMPLATE_ERROR,
});

export const clearSMSTemplate = (): SMSTemplateAction => ({
  type: ActionTypes.CLEAR_SMS_TEMPLATE,
});

export const updateSMSTemplate = (
  revisionId: string,
  data: SmsCompositionReq,
  scopeId?: string,
  ignoreWarning?: boolean,
): SMSTemplateAction => ({
  type: ActionTypes.UPDATE_SMS_TEMPLATE,
  revisionId,
  data,
  scopeId,
  ignoreWarning,
});

export const updateSMSTemplateSuccess = (
  editStatus: EditStatus<void>,
): SMSTemplateAction => ({
  type: ActionTypes.UPDATE_SMS_TEMPLATE_SUCCESS,
  editStatus,
});

export const updateSMSTemplateError = (
  editStatus: EditStatus<void>,
): SMSTemplateAction => ({
  type: ActionTypes.UPDATE_SMS_TEMPLATE_ERROR,
  editStatus,
});

export const deleteSMSTemplate = (revisionId: string): SMSTemplateAction => ({
  type: ActionTypes.DELETE_SMS_TEMPLATE,
  revisionId,
});

export const deleteSMSTemplateSuccess = (
  editStatus: EditStatus<void>,
): SMSTemplateAction => ({
  type: ActionTypes.DELETE_SMS_TEMPLATE_SUCCESS,
  editStatus,
});

export const deleteSMSTemplateError = (
  editStatus: EditStatus<void>,
): SMSTemplateAction => ({
  type: ActionTypes.DELETE_SMS_TEMPLATE_ERROR,
  editStatus,
});

export const createSMSTemplate = (
  data: SmsCompositionReq,
  scopeId?: string,
  ignoreWarning?: boolean,
): SMSTemplateAction => ({
  type: ActionTypes.CREATE_SMS_TEMPLATE,
  data,
  scopeId,
  ignoreWarning,
});

export const createSMSTemplateSuccess = (
  editStatus: EditStatus,
): SMSTemplateAction => ({
  type: ActionTypes.CREATE_SMS_TEMPLATE_SUCCESS,
  editStatus,
});

export const createSMSTemplateError = (
  editStatus: EditStatus,
): SMSTemplateAction => ({
  type: ActionTypes.CREATE_SMS_TEMPLATE_ERROR,
  editStatus,
});

export const clearEditStatus = (): SMSTemplateAction => ({
  type: ActionTypes.CLEAR_SMS_TEMPLATE_EDIT_STATUS,
});

export const updateDirty = (dirty: boolean): SMSTemplateAction => ({
  type: ActionTypes.UPDATE_DIRTY,
  dirty,
});
