import React from "react";

import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";

import configureStore from "appRedux/store";
import EnvironmentProvider, {
  useEnvironmentContext,
} from "common/contexts/EnvironmentProvider/EnvironmentProvider";

import App from "./App";
import auth_config from "./config/authConfig.json";
import { Auth0Wrapper } from "./containers/app/views";
import * as serviceWorker from "./util/RegisterServiceWorker";

const store = configureStore();
const Inner = () => {
  const { environment } = useEnvironmentContext();

  if (environment) {
    return (
      <Auth0Wrapper
        {...auth_config[environment]}
        redirect_uri={window.location.origin}
      >
        <CookiesProvider>
          <App store={store} />
        </CookiesProvider>
      </Auth0Wrapper>
    );
  } else {
    return null;
  }
};

const render = () => {
  return ReactDOM.render(
    <EnvironmentProvider>
      <Inner />
    </EnvironmentProvider>,
    document.getElementById("root"),
  );
};

// Do this once
serviceWorker.unregister();

// Render once
render();
