import React from "react";
import _, { omit } from "lodash";
import { InjectedK2IntlProps } from "../k2Localizations/types";
import { K2MessageValue } from "../k2Localizations/types";
import { Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons/lib";
import { InputProps } from "antd/es/input";
import styled from "styled-components";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

interface K2SearchProps extends InputProps, InjectedK2IntlProps {
  /**locale key for placeholder */
  placeholderKey?: string;
  /**locale key values for placeholder */
  placeholderValues?: { [key: string]: K2MessageValue };
  /** search callback delay */
  delay?: number;

  loading?: boolean;
}

const InputStyled = styled(Input)`
  && {
    .ant-input-affix-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

const K2Search = ({
  placeholderKey,
  placeholderValues,
  delay,
  k2Intl,
  loading,
  onChange,
  placeholder,
  ...rest
}: K2SearchProps) => {
  if (delay) {
    onChange = _.debounce(onChange, delay);
  }
  const ph =
    placeholder ||
    k2Intl?.formatMessage({
      localeKey: placeholderKey,
      values: placeholderValues,
    });
  return (
    <InputStyled
      suffix={loading && <LoadingOutlined />}
      allowClear
      placeholder={ph}
      onChange={(e) => {
        e.persist();
        onChange(e);
      }}
      {...omit(rest, ["localizationSettings"])}
    />
  );
};

export default injectK2Intl(K2Search);
