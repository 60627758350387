import React, { Component, ComponentType, FC } from "react";

import moment from "moment";
import { IntlProvider } from "react-intl";

import DateFormatProvider from "appRedux/models/localization/DateFormatProvider";
import { IAppSettingsProps } from "appRedux/store/types/SubscriptionTypes";
import {
  IK2IntlDateTimeFormatOptions,
  InjectedK2IntlProps,
} from "components/k2Widgets/k2Localizations/types";

import { K2IntlInjector, injectK2AppSettings } from "./injectK2Intl";

interface K2DateTimeProps extends IK2IntlDateTimeFormatOptions {
  value: string | Date;
  mode?: "date" | "time" | "dateAndTime";
  className?: string;
}

const K2DateTime: FC<K2DateTimeProps> = (props) => {
  return <K2DateTimeInternalInjected {...props} />;
};

const K2DateTimeInternal: FC<InjectedK2IntlProps & K2DateTimeProps> = ({
  value,
  mode,
  k2Intl,
  className,
  ...options
}) => {
  let formattedValue = "";
  if (value) {
    // Handle if a time in format "HH:mm:ss" is passed
    if (typeof value === "string" && mode === "time" && value.length === 8) {
      value = new Date(`2020-01-01T${value}`);
    } else if (typeof value === "string") {
      let hasTimezone = /Z$/i.test(value);
      value = new Date(value);
      if (!hasTimezone) {
        const momentDate = moment(value);
        const offsetInMinutes = momentDate.utcOffset() * -1;
        value = momentDate.utc().add(offsetInMinutes, "m").toDate();
      }
    }
    switch (mode) {
      case "time":
        formattedValue = k2Intl?.formatTime(value, options);
        break;
      case "dateAndTime":
        formattedValue = k2Intl?.formatDateTime(value, options);
        break;
      default:
        formattedValue = k2Intl?.formatDate(value, options);
        break;
    }
  }
  return <span className={className}>{formattedValue}</span>;
};

const K2DateTimeInternalInjected = injectDateTimeFormat(K2DateTimeInternal);

function injectDateTimeFormat<P>(
  WrappedComponent: ComponentType<P & InjectedK2IntlProps>,
) {
  class DataFormatInject extends Component<IAppSettingsProps> {
    render() {
      const { appSettings, ...rest } = this.props;
      const config = DateFormatProvider.getConfig(
        appSettings.localizationKeys.dateFormat,
      );

      return (
        <IntlProvider locale={config.culture}>
          <K2IntlInjector
            WrappedComponent={WrappedComponent}
            appSettings={appSettings}
            {...rest}
          />
        </IntlProvider>
      );
    }
  }

  return injectK2AppSettings(DataFormatInject);
}

export default K2DateTime;
