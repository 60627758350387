import { ReactText } from "react";

import { SubscriptionRes } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { RouteIdResultsList } from "@n3oltd/karakoram.navigation.sdk.routes";
import { createSelector } from "reselect";

import { dashboardTasksActions } from "appRedux/modules/tasks";
import { fetchAgent } from "routes/callCenter/modules/actions";

import {
  onLayoutTypeChange,
  onNavStyleChange,
  toggleCollapsedSideNav,
  updateWindowWidth,
} from "../../modules/settings/actions";
import IApplicationState from "../../types";
import TaskSelectors from "./TasksSelectors";
import UsersSelectors from "./UsersSelectors";

const { fetchTaskCountForUser } = dashboardTasksActions;

export default class MenuSelectors {
  public static selectSettings = ({ settings }: IApplicationState) => settings;

  //#region SIDE BAR

  public static getSidebarSelector() {
    return createSelector(
      this.selectSettings,
      (settings): ISidebarSelectorProps => {
        const {
          navStyle,
          themeType,
          locale,
          pathname,
          width,
          navCollapsed,
        } = settings;
        return {
          navStyle,
          themeType,
          locale: locale.locale,
          pathname,
          width,
          navCollapsed,
        };
      },
    );
  }

  public static getSidebarDispatchers(): ISidebarDispatchers {
    return {
      toggleCollapsedSideNav,
      updateWindowWidth,
      onNavStyleChange,
    };
  }

  //#endregion

  //#region MAIN APP

  public static getMainAppSelector() {
    return createSelector(
      this.selectSettings,
      UsersSelectors.getK2SubscriptionSelector(),
      UsersSelectors.getK2UserSelector(),
      UsersSelectors.getK2UserRoutesSelector(),
      TaskSelectors.getTaskCountForUserSelector(),
      (
        settings,
        subscription,
        user,
        k2UserRoutes,
        taskCountForUser: number,
      ): IMainAppSelectorProps => {
        const { width, navStyle } = settings;
        return {
          width: width,
          navStyle,
          k2Subscription: subscription,
          k2User: user,
          taskCountForUser,
          K2UserRouteList: k2UserRoutes,
        };
      },
    );
  }

  public static getMainAppDispatchers(): IMainAppDispatchers {
    return {
      onNavStyleChange,
      onLayoutTypeChange,
      fetchTaskCountForUser,
      fetchAgent,
    };
  }

  //#endregion
}

type IMainAppSelectorProps = {
  width: ReactText;
  navStyle: string;
  k2Subscription: SubscriptionRes;
  k2User: CurrentUserRes;
  taskCountForUser: number;
  K2UserRouteList: RouteIdResultsList;
};

type IMainAppDispatchers = {
  onNavStyleChange: typeof onNavStyleChange;
  onLayoutTypeChange: typeof onLayoutTypeChange;
  fetchTaskCountForUser: typeof fetchTaskCountForUser;
  fetchAgent: typeof fetchAgent;
};

export type IMainAppStateProps = IMainAppSelectorProps & IMainAppDispatchers;

type IAppVisualProps = {
  navCollapsed: boolean;
  width: ReactText;
  navStyle: string;
};

type ISidebarSelectorProps = IAppVisualProps & {
  pathname: string;
  locale: string;
  themeType: string;
};

type ISidebarDispatchers = {
  updateWindowWidth: typeof updateWindowWidth;
  onNavStyleChange: typeof onNavStyleChange;
  toggleCollapsedSideNav: typeof toggleCollapsedSideNav;
};

export type ISidebarStateProps = ISidebarSelectorProps & ISidebarDispatchers;
