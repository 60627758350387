import React, { FC, useEffect, useMemo, useState } from "react";

import { QuestionCircleTwoTone } from "@ant-design/icons";
import { FundDimensionOptionsReq } from "@n3oltd/karakoram.sponsorships.beneficiarytypes.child.sdk.child/esm";
import { Radio, Skeleton } from "antd";
import { FormInstance } from "antd/lib/form";

import { GeneralFormItemV4 } from "components/formItems";
import {
  K2Message,
  K2RadioGroup,
  K2SelectV4,
  K2Tooltip,
} from "components/k2Widgets";
import SchemeFormHelpers from "routes/admin/sponsorships/schemes/helpers";

import { IFeedFieldsDataState, SchemeReqType } from "../../modules/types";
import { FlexAlignCenterWrapper } from "../styled";

const { Button } = Radio;

interface IProps {
  form: FormInstance;
  dimensionKey: keyof FundDimensionOptionsReq;
  dimensionName: string;
  dimensionsUpdated: boolean;
  beneficiaryFields?: IFeedFieldsDataState;
  feedFieldInitialValue?: string;
}

const BeneficiaryFields: FC<IProps> = ({
  form,
  dimensionKey,
  dimensionName,
  dimensionsUpdated,
  beneficiaryFields: { fetching, feedFieldsRes },
  feedFieldInitialValue,
}) => {
  const getDefaultFeedField = useMemo(() => {
    return (
      feedFieldInitialValue ||
      form?.getFieldValue([
        "feed.fundDimensionFields",
        dimensionKey,
      ])
    );
  }, [dimensionKey, form, feedFieldInitialValue]);

  const [autoPopulate, setAutoPopulate] = useState<boolean>(
    !!getDefaultFeedField,
  );

  useEffect(() => {
    if (dimensionName && dimensionsUpdated) {
      form?.setFieldsValue({
        [`${"feed.fundDimensionFields"}.${dimensionKey}}`]: dimensionName,
      });
    }
  }, [dimensionKey, dimensionName, dimensionsUpdated, form]);

  return (
    <div>
      <FlexAlignCenterWrapper>
        <GeneralFormItemV4
          labelKey={"admin.sponsorship.schemes.form.autoPopulate"}
          labelAdditionalChildren={
            <K2Tooltip
              placement={"rightTop"}
              titleKey={"admin.sponsorship.schemes.form.beneficiaryTypeTooltip"}
            >
              <QuestionCircleTwoTone
                style={{ paddingLeft: 10, paddingBottom: 8 }}
                className={"n3o-pointer"}
              />
            </K2Tooltip>
          }
        >
          <K2RadioGroup
            buttonStyle="solid"
            size="small"
            onChange={(e) => setAutoPopulate(e?.target?.value)}
            defaultValue={autoPopulate}
          >
            <Button value={true}>
              <K2Message localeKey={"common.yes"} />
            </Button>
            <Button value={false}>
              <K2Message localeKey={"common.no"} />
            </Button>
          </K2RadioGroup>
        </GeneralFormItemV4>
      </FlexAlignCenterWrapper>
      {autoPopulate ? (
        fetching ? (
          <Skeleton active paragraph={{ rows: 1 }} />
        ) : (
          <GeneralFormItemV4
            name={SchemeFormHelpers.getFundDimensionForFeedFieldName(
              dimensionKey,
            )}
            initialValue={getDefaultFeedField}
            required
          >
            <K2SelectV4
              placeholderKey={
                "admin.sponsorship.schemes.form.selectBeneficiaryField"
              }
              dataSource={feedFieldsRes?.items}
              focusInputOnFocus
              showArrow
              allowClear
              showSearch
            />
          </GeneralFormItemV4>
        )
      ) : null}
    </div>
  );
};

export default BeneficiaryFields;
