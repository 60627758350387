import { AddressesReducer, initialState } from "./reducers";
import addressesSagas from "./sagas";
import { createActionCreators, createReducerFunction } from "immer-reducer";

const addressesReducer = createReducerFunction(AddressesReducer, initialState);

//Individual reducer actions
const addressesActions = createActionCreators(AddressesReducer);

export { addressesActions, addressesSagas };

export default addressesReducer;
