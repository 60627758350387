import { createReducerFunction } from "immer-reducer";
import { initialState, SecurityPrincipalsReducer } from "./reducers";
import securityPrincipalsSagas from "./sagas";

const securityPrincipalsReducer = createReducerFunction(
  SecurityPrincipalsReducer,
  initialState,
);

export { securityPrincipalsSagas };

export default securityPrincipalsReducer;
