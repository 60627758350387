import React, { Component } from "react";

import { CameraOutlined, LoadingOutlined } from "@ant-design/icons/lib";
import {
  CurrentUserRes,
  None,
  UserReq,
  UserRes,
} from "@n3oltd/k2.users.sdk.users/esm";
import { Spin, Upload } from "antd";
import { FormInstance } from "antd/es/form";
import { RcFile } from "antd/es/upload";
import { connect } from "react-redux";
import styled from "styled-components";

import { _usersClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import ApplicationState from "appRedux/types";
import UserAvatar from "components/avatars/userAvatar";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import { showError } from "components/utils/Confirmation";
import { showNotification } from "components/utils/Notification";

interface UserImageUploadProps extends InjectedK2IntlProps {
  k2User?: UserRes;
  subscriptionId?: string;
  afterUploadSuccess?: () => void;
  form: FormInstance;
  currentUserPage?: boolean;
  currentUser?: CurrentUserRes;
}

interface UserImageUploadState {
  imageUrl: string;
  tempImageUrl: string;
  saving: boolean;
}

const UploadContainer = styled(Upload)`
  && {
    cursor: pointer;
    display: inline-block;
    width: fit-content;

    &:hover {
      opacity: 0.8;
    }

    .ant-upload.ant-upload-select-picture-card {
      border-radius: 50%;
    }
  }
`;

/**
 * This component is used on the Edit User page (part of the admin route)
 * and on the Current User Preferences page.
 *
 * As such, it will either update the selected current user's profile picture via
 * and admin endpoint, or the current user's profile picture via an open endpoint.
 */
class UserImageUpload extends Component<
  UserImageUploadProps,
  UserImageUploadState
> {
  private static acceptTypes: string = ".jpg,.jpeg,.png";
  constructor(props: UserImageUploadProps) {
    super(props);

    this.state = { imageUrl: "", tempImageUrl: "", saving: false };
  }

  isValidFile(fileName: string) {
    return /\.(jpg|jpeg|png)$/i.test(fileName);
  }

  uploadNewProfileImage = async (data: RcFile) => {
    this.setState({
      saving: true,
    });
    const uploadImageResponse = await K2Service.toResponse(
      _usersClient.uploadPicture({ data: data, fileName: data.name }),
    );

    if (!uploadImageResponse.error) {
      const storageToken: string = uploadImageResponse.result;
      if (this.props.k2User || this.props.currentUser) {
        // Update user's photo
        let updatePhotoResponse: IApiResponse<None>;
        if (this.props.currentUserPage) {
          updatePhotoResponse = await K2Service.toResponse(
            _usersClient.updateCurrentUserPicture({
              storageToken: storageToken,
            }),
          );
        } else {
          updatePhotoResponse = await K2Service.toResponse(
            _usersClient.updateUserPicture(this.props.k2User.revisionId, {
              storageToken: storageToken,
            }),
          );
        }

        if (!updatePhotoResponse.error) {
          // Show success notification
          showNotification({
            type: "success",
            k2Intl: this.props.k2Intl,
            titleKey: "common.success.title",
            messageKey: "admin.user.profilePictureUpdated",
          });

          this.setState({
            tempImageUrl: "",
            imageUrl: this.state.tempImageUrl,
          });

          this.props.afterUploadSuccess?.();
        } else {
          // Could not update the image of the user

          // Handle possible 412 Error
          if (
            updatePhotoResponse.error.status ===
            K2StatusCodes.preconditionFailed
          ) {
            const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
              updatePhotoResponse.error,
              "error",
            );

            // Alternatively, if the error occurred on a non-form screen:
            if (nonFieldRelatedErrors.length) {
              showError({
                titleKey: "common.nonDismissableFormErrorTitle",
                error: nonFieldRelatedErrors.map((e) => <p>{e.error}</p>),
                okTextKey: "common.ok",
                k2Intl: this.props.k2Intl,
              });
            }
          } else {
            UIUtils.handleServerError(
              this.props.k2Intl,
              updatePhotoResponse.error,
            );
          }
        }

        this.props.form?.setFieldsValue({
          ["storageToken"]: undefined,
        });
      } else {
        // Save the storage token to be sent with request to create a new user
        this.props.form?.setFieldsValue({
          ["storageToken"]: storageToken,
        });

        this.setState({
          tempImageUrl: "",
          imageUrl: this.state.tempImageUrl,
        });
      }
    } else {
      // Image failed to upload
      if (
        uploadImageResponse.error.status === K2StatusCodes.preconditionFailed
      ) {
        UIUtils.showValidationErrorsNotification(
          this.props.k2Intl,
          uploadImageResponse.error,
        );
      } else {
        UIUtils.handleServerError(this.props.k2Intl, uploadImageResponse.error);
      }
    }

    this.setState({
      saving: false,
    });
  };

  beforeUpload = (file: RcFile): boolean => {
    const { k2Intl } = this.props;

    if (!this.isValidFile(file.name)) {
      showNotification({
        type: "error",
        k2Intl,
        titleKey: "common.invalidFileTitle",
        messageKey: "common.invalidFileMessage",
        messageValues: { filetypes: UserImageUpload.acceptTypes },
      });

      return false;
    }

    const reader = new FileReader();

    reader.addEventListener("load", () => {
      const imageUrl = reader.result as string;

      this.setState({ tempImageUrl: imageUrl });
    });

    this.uploadNewProfileImage(file);
    reader.readAsDataURL(file);

    return false;
  };

  onPhotoLoadError() {
    this.setState({ imageUrl: "" });

    return true;
  }

  render() {
    const { imageUrl } = this.state;
    const { k2User, subscriptionId, currentUser, currentUserPage } = this.props;

    let userIcon;
    if (currentUserPage) {
      userIcon = currentUser?.picture?.file?.name;
    } else {
      userIcon = k2User?.picture?.file?.name;
    }
    return (
      <UploadContainer
        accept={UserImageUpload.acceptTypes}
        beforeUpload={this.beforeUpload}
        showUploadList={false}
        listType="picture-card"
      >
        <Spin
          spinning={this.state.saving}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <UserAvatar
            subscriptionId={subscriptionId}
            icon={userIcon ? null : <CameraOutlined />}
            user={currentUserPage ? currentUser : k2User}
            presetId={"small"}
            size={100}
            imageUrl={imageUrl}
            onError={() => this.onPhotoLoadError()}
          />
        </Spin>
      </UploadContainer>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  subscriptionId: state.subscription.users?.k2Subscription?.id,
});

export default connect(mapStateToProps)(injectK2Intl(UserImageUpload));
