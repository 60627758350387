import { Currency } from "@n3oltd/k2.subscriptions.sdk.lookups/esm";
import { ConnectedProps, connect } from "react-redux";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import { DonationItemStatusesLookups } from "appRedux/modules/lookups/types";
import ApplicationState from "appRedux/types";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

import {
  clearFetchDonationItemsError,
  fetchDonationItems,
  updateDonationItemsStatus,
} from "./modules/actions";
import reducer from "./modules/reducer";
import saga from "./modules/saga";

const mapStateToProps = (state: ApplicationState) => {
  return {
    ...state.donationItems,
    baseCurrency: state.subscription.users.k2Subscription.baseCurrency,
  };
};

const mapDispatchToProps = {
  fetchDonationItems,
  clearFetchDonationItemsError,
  updateDonationItemsStatus,
};

const withSaga = injectSaga({ key: "donationItems", saga });
const withReducer = injectReducer({ key: "donationItems", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof withConnect>;

export interface DonationItemsListProps
  extends InjectedK2IntlProps,
    PropsFromRedux {
  baseCurrency: Currency;
  donationItemStatuses: DonationItemStatusesLookups;
}

export default compose(withSaga, withReducer, withConnect);
