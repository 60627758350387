import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const AppSuite: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3632 5.23108C12.3632 6.36545 11.5741 7.11893 10.0504 7.11893C8.43304 7.11893 7.70638 6.35716 7.65169 5.02408H9.22221C9.26127 5.47948 9.4488 5.77756 9.94105 5.77756C10.3708 5.77756 10.5739 5.56228 10.5739 5.1814C10.5739 4.78396 10.3708 4.57696 9.91761 4.57696H9.47224V3.4426H9.89417C10.3552 3.4426 10.5739 3.22732 10.5739 2.84644C10.5739 2.54008 10.3864 2.33308 10.0114 2.33308C9.62851 2.33308 9.41755 2.58148 9.35504 3.0286H7.79233C7.87047 1.81144 8.60494 1 10.0583 1C11.4178 1 12.2304 1.62928 12.2304 2.63944C12.2304 3.30184 11.871 3.7324 11.2694 3.91456C11.9179 4.03876 12.3632 4.52728 12.3632 5.23108ZM3 7.01129H1V1.09108H3L5 4V1.09108H7.03212V7.01129H5L3 3.96586V7.01129ZM12.2277 23.4375C18.4206 23.4375 23.4409 18.417 23.4409 12.2239C23.4409 6.63835 19.3573 2.00664 14.0129 1.15159C13.4428 1.06038 12.9538 1.52196 12.9538 2.09934V6.24232C12.9538 6.74076 13.3255 7.15298 13.8003 7.30465C15.8827 7.96983 17.3906 9.92075 17.3906 12.2239C17.3906 15.0754 15.0791 17.387 12.2277 17.387C9.4696 17.387 7.21661 15.2241 7.07221 12.5018C7.07214 12.5006 7.07111 12.4996 7.06987 12.4996C7.06857 12.4996 7.06752 12.4985 7.06752 12.4973V12.3915C7.06575 12.3358 7.06485 12.28 7.06485 12.2239C7.06485 12.1678 7.06575 12.1119 7.06752 12.0563L7.06549 8.77379C7.06515 8.22174 6.61753 7.77441 6.06549 7.77441H2.01256C1.46004 7.77441 1.01223 8.22249 1.01256 8.775L1.01462 12.2048L1.0146 12.2239L1.01462 12.2429V12.3847C1.01462 12.385 1.01487 12.3852 1.01518 12.3852C1.01549 12.3852 1.01574 12.3855 1.01575 12.3858C1.10235 18.5042 6.08896 23.4375 12.2277 23.4375Z"
      fill={fill}
    />
  </svg>
);
