import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const TallyIcon: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25749 2.54815C10.3217 0.483951 13.6684 0.483951 15.7326 2.54815L7.50997 10.7708L3.77242 7.03321L8.25749 2.54815Z"
      fill={fill}
    />
    <path
      d="M2.54815 15.7954C0.483951 13.7312 0.483951 10.3844 2.54815 8.32024L10.7708 16.5429L7.03322 20.2804L2.54815 15.7954Z"
      fill={fill}
    />
    <path
      d="M15.7326 21.5048C13.6684 23.569 10.3217 23.569 8.25749 21.5048L16.4801 13.2821L20.2177 17.0197L15.7326 21.5048Z"
      fill={fill}
    />
    <path
      d="M21.6994 8.02475C23.7636 10.0889 23.7636 13.4357 21.6994 15.4999L13.4767 7.27724L17.2143 3.53968L21.6994 8.02475Z"
      fill={fill}
    />
  </svg>
);
