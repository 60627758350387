import React from "react";

import { Alert } from "antd";
import styled from "styled-components";

interface IModalAlertProps {
  errors: any[];
}

const NewAlert = styled(Alert)`
  &&:last-child {
    margin-bottom: 0;
  }
`;

function ModalAlert({ errors }: IModalAlertProps) {
  return (
    <NewAlert
      showIcon
      style={{ marginTop: 20, marginBottom: 20 }}
      type="error"
      message={errors?.map?.((e, i) => (
        <p key={i}>{e}</p>
      ))}
    />
  );
}

export default ModalAlert;
