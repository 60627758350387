import React, { FC, useEffect, useRef, useState } from "react";

import { generateUuid } from "@azure/ms-rest-js";
import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { PdfCompositionRes } from "@n3oltd/karakoram.templates.sdk.pdf";
import { PdfClient } from "@n3oltd/karakoram.templates.sdk.pdf";
import {
  PdfCompositionReq,
  PreviewPdfCompositionReq,
} from "@n3oltd/karakoram.templates.sdk.pdf/esm";
import { FileResponse } from "@n3oltd/karakoram.templates.sdk.pdf/src/index";
import { Skeleton } from "antd";
import { FormInstance } from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import { useRouter } from "hooks";
import styled from "styled-components";

import K2RestClients, {
  _pdfCompositionsClient,
} from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import {
  AssetTypesLookups,
  BatchQueuesLookups,
  CategoriesLookups,
  PageOrientationsLookups,
  PageSizedLookups,
} from "appRedux/modules/lookups/types";
import { DataActions } from "appRedux/modules/sharedTypes";
import DownloadHelpers from "common/helpers/downloads";
import NotFoundWrapper from "components/NotFoundWrapper";
import { K2RouteHeading } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { FormsWrapper } from "components/layout/wrappers";
import { UIUtils } from "components/utils";
import { showConfirm, showError } from "components/utils/Confirmation";
import { showNotification } from "components/utils/Notification";
import {
  REDIRECT_AFTER_SAVE_MS,
  TEMPLATE_ENGINE,
} from "constants/appConstants";
import useBreadcrumb from "hooks/useBreadcrumb";
import PdfTemplateHelpers from "routes/admin/communication/newEditPdfTemplate/helpers";

import CreateEditPdfTemplateForm from "./components/CreateEditPdfTemplateForm";
import { PropsFromRedux } from "./connect";

const CardWrapper = styled.div`
  margin-top: 2rem;
`;

interface PdfTemplatesProps extends PropsFromRedux, InjectedK2IntlProps {
  assetTypes: AssetTypesLookups;
  categories: CategoriesLookups;
  queues: BatchQueuesLookups;
  scopeId?: string;
  onSaveSuccess?: (action: DataActions) => void;
  setForm?: (form: FormInstance) => void;
  initialData?: PdfCompositionRes;
  pageOrientations: PageOrientationsLookups;
  pageSizes: PageSizedLookups;
}

const NewEditPdfTemplate: FC<PdfTemplatesProps> = (props) => {
  const {
    updatePdfTemplate,
    createPdfTemplate,
    data,
    fetchPdfTemplate,
    loading,
    error,
    clearPdfTemplateError,
    k2Intl,
    editStatus,
    updateDirty,
    clearEditStatus,
    clearPdfTemplate,
    scopeId,
    onSaveSuccess,
    setForm,
    initialData,
  } = props;
  const router = useRouter<{ templateId?: string }>();
  const templateId = router.match.params.templateId;
  const editMode = !!templateId || !!initialData;

  const templateData = initialData || data;

  // These are the assets that are currently visible on the Media Assets list,
  // including new assets that have been added
  const [tempAssets, setTempAssets] = useState<AssetRes[]>([]);

  const [tempPrologue, setTempPrologue] = useState<AssetRes>(null);
  const [tempEpilogue, setTempEpilogue] = useState<AssetRes>(null);

  const [overallErrors, setOverallErrors] = useState<string[]>([]);
  const ignoreWarningsRef = useRef(false);

  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const [form] = useForm();
  if (setForm) {
    setForm(form);
  }

  useEffect(() => {
    if (error) {
      if (error.status === K2StatusCodes.notFound) {
        setNotFound(true);
        clearPdfTemplateError();
      } else {
        UIUtils.handleServerError(k2Intl, error, clearPdfTemplateError);
      }
    }
  }, [error, clearPdfTemplateError, k2Intl]);

  const setBreadcrumbs = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbs([
      {
        path: K2Routes.main.admin,
        titleKey: "mainNav.admin",
        active: false,
      },
      {
        path: K2Routes.communicationsPdfTemplates,
        titleKey: `admin.communication.pdf.templates`,
        active: false,
      },
      {
        path: editMode
          ? K2Routes.communicationsEditPdfTemplate
          : K2Routes.communicationsNewPdfTemplate,
        title: editMode && templateData ? templateData.name : null,
        titleKey: `admin.communication.templates.${editMode ? "edit" : "new"}`,
        active: true,
      },
    ]);
  }, [setBreadcrumbs, editMode, templateData]);

  useEffect(() => {
    if (editStatus) {
      switch (editStatus.action) {
        case DataActions.add:
        case DataActions.update:
          if (editStatus.error) {
            if (editStatus.error.status === K2StatusCodes.preconditionFailed) {
              // Handle field-level validation errors and set them on the form
              UIUtils.handleValidationErrors(editStatus.error, form);

              const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
                editStatus.error,
                "error",
                "all",
              );
              const nonFieldRelatedWarnings = UIUtils.getOverallValidationErrors(
                editStatus.error,
                "warning",
                "all",
              );

              if (
                nonFieldRelatedErrors.length === 0 &&
                nonFieldRelatedWarnings.length === 1
              ) {
                showConfirm({
                  titleKey: "common.warning",
                  content: nonFieldRelatedWarnings[0].error,
                  centered: true,
                  okTextKey: "common.proceed",
                  onOk: () => {
                    ignoreWarningsRef.current = true;
                    form?.submit();
                  },
                  k2Intl,
                });
              }

              // Errors take precedence, show them at the foot of the form
              if (nonFieldRelatedErrors.length) {
                setOverallErrors(
                  nonFieldRelatedErrors?.map?.((err) => err.error),
                );
              }
            } else {
              UIUtils.handleServerError(k2Intl, editStatus.error);
            }
          } else {
            if (onSaveSuccess) {
              onSaveSuccess(editStatus.action);
            } else {
              showNotification({
                type: "success",
                titleKey: "common.success.title",
                messageKey: `admin.communication.pdfTemplates.${
                  editStatus.action === DataActions.update
                    ? "updated"
                    : "created"
                }`,
                k2Intl,
              });

              updateDirty(false);

              setTimeout(() => {
                // Redirect back to main page
                router.history.push(K2Routes.communicationsPdfTemplates);
              }, REDIRECT_AFTER_SAVE_MS);
            }
          }

          clearEditStatus();
          break;
        case DataActions.delete:
          if (editStatus.error?.status === K2StatusCodes.preconditionFailed) {
            const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
              editStatus.error,
              "error",
            );
            if (nonFieldRelatedErrors.length) {
              showError({
                titleKey: "common.nonDismissableFormErrorTitle",
                error: nonFieldRelatedErrors.map((e) => <p>{e.error}</p>),
                okTextKey: "common.ok",
                k2Intl,
                onOk: () => {
                  clearEditStatus();
                },
                onCancel: () => {
                  clearEditStatus();
                },
              });
            }
          } else {
            UIUtils.handleDataUpdateResult({
              k2Intl,
              status: editStatus,
              successTitle: "common.success.title",
              successMessage: "admin.communication.templates.delete.success",
              onSuccess: () => {
                updateDirty(false);

                if (editStatus.result) {
                  setTimeout(() => {
                    router.history.push(K2Routes.communicationsPdfTemplates);
                  }, REDIRECT_AFTER_SAVE_MS);
                }
              },
            });
          }
          break;
      }
    }
  }, [
    editStatus,
    form,
    k2Intl,
    router,
    updateDirty,
    clearEditStatus,
    onSaveSuccess,
  ]);

  useEffect(() => {
    setNotFound(false);
    if (templateId) {
      fetchPdfTemplate(templateId);
    }
  }, [templateId, fetchPdfTemplate]);

  useEffect(() => {
    return () => {
      form.resetFields();
      clearPdfTemplate();
    };
    // eslint-disable-next-line
  }, []);

  const handleCreateEditTemplate = (values) => {
    const req: PdfCompositionReq = {
      ...values,
      filename: {
        markup: PdfTemplateHelpers.ensureEndsWithPdf(values.filename.markup),
      },
      mediaAssetIds: tempAssets?.map?.((asset) => asset.id),
      prologueAssetId: tempPrologue?.id,
      epilogueAssetId: tempEpilogue?.id,
      printQueue:
        values.printQueue === "n3o__default" ? undefined : values.printQueue,
    };

    if (editMode) {
      updatePdfTemplate(
        templateData.revisionId,
        req,
        scopeId,
        ignoreWarningsRef.current,
      );
    } else {
      createPdfTemplate(req, scopeId, ignoreWarningsRef.current);
    }
  };

  const handleInvalidPreviewRequest = () => {
    showError({
      titleKey: "common.nonDismissableFormErrorTitle",
      k2Intl,
      okTextKey: "common.ok",
      error: k2Intl.formatMessage({
        localeKey: "admin.communication.previewValidationError",
      }),
    });
  };

  const handlePreview = async (values) => {
    setPreviewLoading(true);

    const req: PreviewPdfCompositionReq = {
      composition: {
        ...values,
        filename: {
          markup: PdfTemplateHelpers.ensureEndsWithPdf(values.filename.markup),
        },
        mediaAssetIds: tempAssets?.map?.((asset) => asset.id),
        prologueAssetId: tempPrologue?.id,
        epilogueAssetId: tempEpilogue?.id,
        // Backend validates that template names are unique, so create guid
        name: generateUuid(),
      },
    };

    try {
      let client: PdfClient = K2RestClients.initRestClient(
        PdfClient,
        "templates",
        {
          getN3OIgnoreValidationWarnings: () => "true",
        },
      );
      const resp: IApiResponse<FileResponse> = await K2RestService.toResponse(
        client.previewPdf(TEMPLATE_ENGINE, null, req),
      );

      if (resp.error) {
        if (resp.error.status === K2StatusCodes.preconditionFailed) {
          showError({
            titleKey: "common.nonDismissableFormErrorTitle",
            error: resp.error.data.errors?.map?.((err, i) => (
              <p key={i}>{err.error}</p>
            )),
            okTextKey: "common.ok",
            k2Intl,
          });
        } else {
          UIUtils.handleServerError(k2Intl, resp.error);
        }
      } else {
        DownloadHelpers.downloadFile(
          resp,
          k2Intl?.formatMessage({
            localeKey: "admin.communication.pdf.preview.filename",
            values: {
              name:
                values.name ||
                k2Intl?.formatMessage({ localeKey: "common.unnamed" }),
            },
          }),
        );
      }
    } catch (e) {
      showError({
        titleKey: "common.nonDismissableFormErrorTitle",
        k2Intl,
        okTextKey: "common.ok",
        error: UIUtils.messageToHtml(
          k2Intl,
          "admin.communication.pdf.previewError",
        ),
      });
    }
    setPreviewLoading(false);
  };

  return (
    <>
      {/* If scopeId is provided, then this form is being used within a modal to create a new template for an Outbox */}
      {scopeId ? (
        <CreateEditPdfTemplateForm
          form={form}
          editMode={!!templateId}
          errors={overallErrors}
          onSubmit={handleCreateEditTemplate}
          onPreview={handlePreview}
          onInvalidPreviewRequest={handleInvalidPreviewRequest}
          setTempAssets={setTempAssets}
          tempAssets={tempAssets}
          tempPrologue={tempPrologue}
          tempEpilogue={tempEpilogue}
          setTempPrologue={setTempPrologue}
          setTempEpilogue={setTempEpilogue}
          previewLoading={previewLoading}
          showCancelSaveOpts={false}
          {...props}
        />
      ) : (
        <FormsWrapper>
          <NotFoundWrapper
            showNotFound={notFound}
            entityNameKey={"common.entity.action.template"}
          >
            <K2RouteHeading
              headingKey={`admin.communication.templates.${
                editMode ? "edit" : "new"
              }`}
            />
            <CardWrapper>
              <Skeleton active loading={loading}>
                {!loading && (
                  <CreateEditPdfTemplateForm
                    form={form}
                    scopeId={scopeId}
                    editMode={!!templateId}
                    errors={overallErrors}
                    onSubmit={handleCreateEditTemplate}
                    onPreview={handlePreview}
                    onInvalidPreviewRequest={handleInvalidPreviewRequest}
                    setTempAssets={setTempAssets}
                    tempAssets={tempAssets}
                    tempPrologue={tempPrologue}
                    tempEpilogue={tempEpilogue}
                    setTempPrologue={setTempPrologue}
                    setTempEpilogue={setTempEpilogue}
                    previewLoading={previewLoading}
                    {...props}
                  />
                )}
              </Skeleton>
            </CardWrapper>
          </NotFoundWrapper>
        </FormsWrapper>
      )}
    </>
  );
};

export default injectK2Intl(NewEditPdfTemplate);
