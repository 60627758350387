import { DateFormat } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { Moment } from "moment";

import TimeFormatProvider from "appRedux/models/localization/TimeFormatProvider";

import AFormatProvider, { IK2FormatConfig } from "./AFormatProvider";

const dataFormats: IK2FormatConfig[] = [
  {
    oldKey: "d/m/y",
    key: "dmy_slashes",
    format: "DD/MM/YYYY",
    culture: "en-GB",
    formatter: (strDate: string) => strDate,
  },
  {
    oldKey: "d-m-y",
    key: "dmy_dashes",
    format: "DD-MM-YYYY",
    culture: "en-GB",
    formatter: (strDate: string) => strDate.replace(/\//g, "-"),
  },
  {
    oldKey: "m/d/y",
    key: "mdy_slashes",
    format: "MM/DD/YYYY",
    culture: "en-US",
    formatter: (strDate: string) => strDate,
  },
  {
    oldKey: "m-d-y",
    key: "mdy_dashes",
    format: "MM-DD-YYYY",
    culture: "en-US",
    formatter: (strDate: string) => strDate.replace(/\//g, "-"),
  },
  {
    oldKey: "y/m/d",
    key: "ymd_slashes",
    format: "YYYY/MM/DD",
    culture: "en-ZA",
    formatter: (strDate: string) => strDate,
  },
  {
    oldKey: "y-m-d",
    key: "ymd_dashes",
    format: "YYYY-MM-DD",
    culture: "en-ZA",
    formatter: (strDate: string) => strDate.replace(/\//g, "-"),
  },
];

export default class DateFormatProvider extends AFormatProvider<
  number | Date,
  IK2IntlDateFormatOptions
> {
  static serverDateFormat: string = "YYYY-MM-DD";
  static serverDateTimeFormat: string = "YYYY-MM-DDTHH:mm:ss"; // 2021-04-28T12:00:00
  static defaultDateFormat: DateFormat = DateFormat.Dmy_dashes;

  formatNative(formatConfig: IK2FormatConfig, date: number | Date) {
    const df = Intl.DateTimeFormat(
      formatConfig.culture,
      this.getOptions(formatConfig.options),
    );
    return formatConfig.formatter(df.format(date));
  }

  formatIntl(formatConfig: IK2FormatConfig, date: number | Date) {
    return formatConfig.formatter(
      this.intl.formatDate(date, this.getOptions(formatConfig.options)),
    );
  }

  getFormatConfig(key: string) {
    return DateFormatProvider.getConfig(key);
  }

  public static getConfig(formatKey: string) {
    return dataFormats.find(
      (d) => d.key === formatKey || d.oldKey === formatKey,
    );
  }

  /**
   * Receives a local date and formats it in the format the backend requires: a LocalDateTime
   * This is a date in the user's date/time which the backend then resolves to UTC.
   * This is, in general, the format that we use when providing dates to the backend in search queries.
   * @param date
   * @param mode
   */
  public static getLocalDateTime(
    date: Moment,
    mode: "date" | "dateAndTime" | "time",
  ): string {
    return date
      ? date.format(
          mode === "date"
            ? DateFormatProvider.serverDateFormat
            : mode === "time"
            ? TimeFormatProvider.serverTimeFormat
            : `${DateFormatProvider.serverDateFormat}T${TimeFormatProvider.serverTimeFormat}`,
        )
      : null;
  }
}

export interface IK2IntlDateFormatOptions extends Intl.DateTimeFormatOptions {
  dateFormat?: string;
}
