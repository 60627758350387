import {
  TaskCriteria,
  TaskSummaryResultsPage,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";

export enum ActionTypes {
  UPDATE_TASK_DRAWER_ENTITY_TYPE = "@engage/tasks-drawer/update-entity-type",
  UPDATE_TASK_DRAWER_ENTITY_ID = "@engage/tasks-drawer/update-entity-id",
  UPDATE_TASK_DRAWER_WRAPPER_ID = "@engage/tasks-drawer/update-wrapper-id",
  UPDATE_TASK_DRAWER_TASKS = "@engage/tasks-drawer/update-tasks/request",
  UPDATE_TASK_DRAWER_TASKS_SUCCESS = "@engage/tasks-drawer/update-tasks/success",
  UPDATE_TASK_DRAWER_TASKS_ERROR = "@engage/tasks-drawer/update-tasks/error",
  CLEAR_TASK_DRAWER_ERROR = "@engage/tasks-drawer/clear-error",
}

export interface UpdateTaskDrawerEntityType {
  type: ActionTypes.UPDATE_TASK_DRAWER_ENTITY_TYPE;
  entityType: "sponsorship" | "account";
}

export interface UpdateTaskDrawerEntityId {
  type: ActionTypes.UPDATE_TASK_DRAWER_ENTITY_ID;
  entityId: string;
}

export interface UpdateTaskDrawerWrapperId {
  type: ActionTypes.UPDATE_TASK_DRAWER_WRAPPER_ID;
  wrapperId: string;
}

export interface UpdateTaskDrawerTasks {
  type: ActionTypes.UPDATE_TASK_DRAWER_TASKS;
  criteria: TaskCriteria;
}

export interface UpdateTaskDrawerTasksSuccess {
  type: ActionTypes.UPDATE_TASK_DRAWER_TASKS_SUCCESS;
  data: TaskSummaryResultsPage;
}

export interface UpdateTaskDrawerTasksError {
  type: ActionTypes.UPDATE_TASK_DRAWER_TASKS_ERROR;
  error: ServerError;
}

export interface ClearTaskDrawerError {
  type: ActionTypes.CLEAR_TASK_DRAWER_ERROR;
}

export type TaskDrawerAction =
  | UpdateTaskDrawerEntityId
  | UpdateTaskDrawerEntityType
  | UpdateTaskDrawerWrapperId
  | UpdateTaskDrawerTasks
  | UpdateTaskDrawerTasksSuccess
  | UpdateTaskDrawerTasksError
  | ClearTaskDrawerError;
