import React, { FC, useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons/lib";
import {
  DataScope,
  ReportingPeriodReq,
  ReportingPeriodRes,
} from "@n3oltd/karakoram.analytics.sdk.attribution/esm";
import { Col, Row, Skeleton, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import styled from "styled-components";

import { K2StatusCodes } from "appRedux/models/common/ApiResponseModel";
import DateFormatProvider from "appRedux/models/localization/DateFormatProvider";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { DataActions } from "appRedux/modules/sharedTypes";
import { K2Button, K2Message, K2RouteHeading } from "components/k2Widgets";
import AdminActionGrid from "components/k2Widgets/k2Grid/actionGrid";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { FormsWrapper } from "components/layout/wrappers";
import { UIUtils } from "components/utils";
import { showError } from "components/utils/Confirmation";
import { showNotification } from "components/utils/Notification";
import useBreadcrumb from "hooks/useBreadcrumb";
import ReportingPeriodsListModel from "routes/admin/analytics/reportingPeriods/Model";
import AddEditReportingPeriodModal from "routes/admin/analytics/reportingPeriods/components/AddEditReportingPeriodModal";

import { ReportingPeriodProps } from "./connect";

const CardWrapper = styled.div`
  margin-top: 2rem;
`;

const ReportingPeriods: FC<ReportingPeriodProps> = (props) => {
  const {
    loading,
    selectedReportingPeriod,
    savingNew,
    savingSortOrder,
    data,
    editStatus,
    k2Intl,
    reportingInfo = { reportingScope: DataScope.Shared },
    findReportingPeriods,
    setSelectedReportingPeriod,
    clearEditStatus,
    clearSelectedEditStatus,
    updateSortOrder,
    createReportingPeriod,
    updateReportingPeriod,
    deleteReportingPeriod,
  } = props;
  const [modalShowing, setModalShowing] = useState<boolean>(false);
  const [overallFormErrors, setOverallFormErrors] = useState<string[]>([]);
  const [form] = useForm();

  const listModel = new ReportingPeriodsListModel(props);
  const columns = listModel.getColumns(setSelectedReportingPeriod);

  const {
    reportingScope,
    showAddReportingModal,
    setShowAddReportingModal,
    refetch,
  } = reportingInfo;

  const isPrivateReproting = reportingScope === DataScope.Private;

  const setBreadcrumbs = useBreadcrumb();
  useEffect(() => {
    if (!isPrivateReproting) {
      setBreadcrumbs([
        {
          path: K2Routes.main.admin,
          titleKey: "mainNav.admin",
          active: false,
        },
        {
          path: K2Routes.analyticsReportingPeriods,
          titleKey: "admin.reportingPeriods",
          active: true,
        },
      ]);
    }
  }, [setBreadcrumbs, isPrivateReproting]);

  useEffect(() => {
    findReportingPeriods({ scope: [reportingScope] });
  }, [findReportingPeriods, reportingScope]);

  useEffect(() => {
    if (selectedReportingPeriod.data) setModalShowing(true);
  }, [selectedReportingPeriod]);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const dataSource: ReportingPeriodRes[] = data.items;
    const movedItem = dataSource.find((x, i) => i === oldIndex);
    if (oldIndex !== newIndex) {
      updateSortOrder(movedItem.revisionId, newIndex);
    }
  };

  useEffect(() => {
    if (editStatus) {
      switch (editStatus.action) {
        // Sort order was updated
        case DataActions.update: {
          if (editStatus.error) {
            UIUtils.handleServerError(
              k2Intl,
              editStatus.error,
              clearEditStatus,
            );
          } else {
            showNotification({
              type: "success",
              k2Intl,
              titleKey: "common.success.title",
              messageKey: "admin.reportingPeriods.orderUpdated",
            });

            findReportingPeriods({ scope: [reportingScope] });
            refetch?.();
            setSelectedReportingPeriod?.(null);
          }
          clearEditStatus();
          break;
        }

        case DataActions.add: {
          if (editStatus.error) {
            if (editStatus.error.status === K2StatusCodes.preconditionFailed) {
              // Handle field-level validation errors and set them on the form
              UIUtils.handleValidationErrors(editStatus.error, form);

              const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
                editStatus.error,
                "error",
              );

              // Errors take precedence, show them at the foot of the form
              if (nonFieldRelatedErrors.length) {
                setOverallFormErrors(
                  nonFieldRelatedErrors?.map?.((err) => err.error),
                );
              }
            } else {
              UIUtils.handleServerError(k2Intl, editStatus.error);
            }
          } else {
            showNotification({
              type: "success",
              k2Intl: k2Intl,
              titleKey: "common.success.title",
              messageKey: "admin.reportingPeriods.created",
            });
            setOverallFormErrors([]);
            setModalShowing(false);
            setShowAddReportingModal?.(false);
            form?.resetFields();
            findReportingPeriods({ scope: [reportingScope] });
            refetch?.();
            setSelectedReportingPeriod?.(null);
          }
          clearEditStatus();
          break;
        }
      }
    }
    // eslint-disable-next-line
  }, [
    editStatus,
    form,
    refetch,
    setSelectedReportingPeriod,
    findReportingPeriods,
  ]);

  const selectedEditStatus = selectedReportingPeriod.editStatus;
  useEffect(() => {
    if (selectedEditStatus) {
      switch (selectedEditStatus.action) {
        case DataActions.update:
          if (selectedEditStatus.error) {
            // Handle field-level validation errors and set them on the form
            UIUtils.handleValidationErrors(selectedEditStatus.error, form);

            const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
              selectedEditStatus.error,
              "error",
            );

            if (nonFieldRelatedErrors.length) {
              setOverallFormErrors(
                nonFieldRelatedErrors?.map?.((err) => err.error),
              );
            }
          } else {
            showNotification({
              type: "success",
              k2Intl: k2Intl,
              titleKey: "common.success.title",
              messageKey: "admin.reportingPeriods.updated",
            });
            setOverallFormErrors([]);
            setModalShowing(false);
            setShowAddReportingModal?.(false);
            findReportingPeriods({ scope: [reportingScope] });
            refetch?.();
            setSelectedReportingPeriod(null);
            form?.resetFields();
          }
          break;

        case DataActions.delete:
          if (selectedEditStatus.error) {
            if (
              selectedEditStatus.error.status ===
              K2StatusCodes.preconditionFailed
            ) {
              // Handle field-level validation errors and set them on the form
              UIUtils.handleValidationErrors(selectedEditStatus.error, form);

              const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
                selectedEditStatus.error,
                "error",
                "all"
              );

              if (nonFieldRelatedErrors.length) {
                showError({
                  titleKey: "common.nonDismissableFormErrorTitle",
                  error: nonFieldRelatedErrors?.map?.((e, i) => (
                    <p key={i}>{e.error}</p>
                  )),
                  okTextKey: "common.ok",
                  k2Intl,
                });
              }
            } else {
              UIUtils.handleServerError(k2Intl, selectedEditStatus.error);
            }
          } else {
            showNotification({
              type: "success",
              k2Intl: k2Intl,
              titleKey: "common.success.title",
              messageKey: "admin.reportingPeriods.deleted",
            });
            setOverallFormErrors([]);
            setModalShowing(false);
            setShowAddReportingModal?.(false);
            setSelectedReportingPeriod(null);
            form?.resetFields();
            refetch?.();
          }
      }

      clearSelectedEditStatus();
    }
  }, [
    selectedEditStatus,
    k2Intl,
    form,
    reportingScope,
    clearSelectedEditStatus,
    findReportingPeriods,
    setSelectedReportingPeriod,
    refetch,
    setShowAddReportingModal,
  ]);

  const handleCreateEdit = (values, revisionId: string) => {
    const req: ReportingPeriodReq = {
      name: values["name"],
      startsOn: values["startsOn"]?.format?.(
        DateFormatProvider.serverDateFormat,
      ),
      endsOn: values["endsOn"]?.format?.(
        DateFormatProvider.serverDateFormat,
      ),
      scope: reportingScope,
    };
    if (revisionId) {
      updateReportingPeriod(revisionId, req);
    } else {
      createReportingPeriod(req);
    }
  };

  return (
    <FormsWrapper>
      {!isPrivateReproting && (
        <K2RouteHeading headingKey={"admin.reportingPeriods"}>
          <K2Button
            type={"primary"}
            onClick={() => {
              setModalShowing(true);
            }}
          >
            <Space size={"small"}>
              <PlusOutlined />
              <K2Message localeKey={"admin.reportingPeriods.add"} />
            </Space>
          </K2Button>
        </K2RouteHeading>
      )}

      <CardWrapper>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {(modalShowing || showAddReportingModal) && (
              <AddEditReportingPeriodModal
                reportingPeriodId={selectedReportingPeriod.data?.id}
                saving={
                  selectedReportingPeriod?.saving ||
                  savingNew ||
                  selectedReportingPeriod?.deleteing
                }
                onCancel={() => {
                  setOverallFormErrors([]);
                  setModalShowing(false);
                  setShowAddReportingModal?.(false);
                  setSelectedReportingPeriod(null);
                }}
                onDelete={deleteReportingPeriod}
                form={form}
                errors={overallFormErrors}
                onSave={handleCreateEdit}
                isPrivateReproting={isPrivateReproting}
              />
            )}
            <Row gutter={18}>
              <Col span={24}>
                <AdminActionGrid<ReportingPeriodRes>
                  sortable={true}
                  onSortEnd={onSortEnd}
                  columns={columns}
                  isLoading={loading || savingSortOrder}
                  keyField={"id"}
                  dataSource={data}
                  scroll={{ y: 500 }}
                />
              </Col>
            </Row>
          </>
        )}
      </CardWrapper>
    </FormsWrapper>
  );
};

export default injectK2Intl(ReportingPeriods);
