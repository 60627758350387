import React, { FC } from "react";
import { Empty } from "antd";
import { K2Link, K2Message } from "components/k2Widgets";
import { K2Routes } from "appRedux/models/routes/RouteModel";
import { Q1Routes } from "appRedux/models/routes/Q1RouteModel";
import { N3oIcon } from "components/n3oIcon";
import styled from "styled-components";
import { PlusCircleOutlined } from "@ant-design/icons";
import { StatisticsCardWrapper } from "../singleVisual";
import { IK2CardProps } from "components/k2Widgets/k2Card";
import { useWindowWidth } from "hooks";
import { MOBILE_SIZE } from "constants/ThemeSetting";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";

const {
  main: { analytics },
} = K2Routes;

const {
  main: { q1Analytics },
} = Q1Routes;

const K2LinkWrapper = styled(K2Link)`
  &:hover {
    .n3o-card-statistics-empty {
      border: 0.5px ${({ theme }) => theme.dark_3} dashed;
      margin: -1px;
    }
  }
`;

const EmptyStaticsCardWrapper = styled(StatisticsCardWrapper)<{
  $isMobileScreen;
}>`
  &.n3o-card-statistics-empty {
    box-shadow: ${({ theme }) => theme.card_shadow} !important;

    .ant-card-body {
      padding: ${({ $isMobileScreen }) =>
        $isMobileScreen ? "68px 24px" : "105px 24px"};
      border-radius: 8px;
      // border: 0.5px ${({ theme }) => theme.dark_4} dashed;

      .description_text {
        font-size: 16px;
        line-height: 22px;
      }

      .ant-empty-description {
        font-size: 18px;
        line-height: 24px;
        color: ${({ theme }) => theme.primaryColor};
      }

      .ant-empty-footer {
        margin-top: 33px;
      }
    }
  }
`;

interface IProps extends IK2CardProps {
  key: number;
}

const EmptyVisual: FC<IProps> = (props) => {
  const { key } = props;
  const width: number = useWindowWidth();
  const { appId } = useAppContext();

  const isMobileScreen = width <= MOBILE_SIZE;

  return (
    <K2LinkWrapper to={appId === "k2" ? analytics : q1Analytics}>
      <EmptyStaticsCardWrapper
        $isMobileScreen={isMobileScreen}
        key={"empty_visual_" + key}
        className={"n3o-card-statistics n3o-card-statistics-empty"}
      >
        <Empty
          image={
            <N3oIcon icontype="n3o-analytics-graph" width={100} height={100} />
          }
          description={
            <>
              <PlusCircleOutlined className={"n3o-mr-2"} />
              <K2Message localeKey={"dashboardTitle.statistics.addStatistic"} />
            </>
          }
        >
          <K2Message
            localeKey={"dashboardTitle.statistics.description"}
            spanClassName={"n3o-text-grey-7 description_text"}
          />
        </Empty>
      </EmptyStaticsCardWrapper>
    </K2LinkWrapper>
  );
};

export default EmptyVisual;
