import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const Sendy: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 78.4 78.4"
  >
    <path
      d="M39.2 0C17.6 0 0 17.6 0 39.2s17.6 39.2 39.2 39.2 39.2-17.6 39.2-39.2S60.8 0 39.2 0zM11.6 18.9 29 37 11.6 55.2V18.9zM14.5 58l18.9-20.3H45L63.9 58H14.5zm52.3-2.8L49.4 37l17.4-18.1v36.3z"
      fill={fill}
    />
  </svg>
);
