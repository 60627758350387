import {
  FundDimensionRes,
  FundStructureRevisionRes,
} from "@n3oltd/k2.subscriptions.sdk.funds/esm";
import _ from "lodash";
import { all, put, takeLatest } from "redux-saga/effects";

import { _subscriptionFundsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { SharedActionTypes as AdminActionTypes } from "routes/admin/modules/actionTypes";

import {
  fetchFundDimensionsError,
  fetchFundDimensionsSuccess,
} from "./actions";
import { Dimension } from "./types";

//#region WORKERS
export function* getFundDimensions() {
  const response: IApiResponse<FundStructureRevisionRes> = yield K2RestService.toResponse(
    _subscriptionFundsClient.getFundStructure(),
  );

  if (response.error) {
    yield put(fetchFundDimensionsError(response.error));
  } else {
    const dimensionsStructure: FundStructureRevisionRes = response.getResultOrDefault();
    const fundStructureRevisionId = dimensionsStructure.revisionId;

    const dimensions = Object.values(
      _.omit(dimensionsStructure, ["revisionId", "baseCurrency"]),
    )
      ?.map?.((dim: FundDimensionRes, index) => ({
        dimNum: index + 1,
        saving: false,
        editStatus: null,
        ...dim,
      }))
      .sort((a, b) => a.order - b.order) as Dimension[];

    yield put(
      fetchFundDimensionsSuccess(
        fundStructureRevisionId,
        dimensions,
        dimensionsStructure, // this one is to be used by other forms
      ),
    );
  }
}
//#endregion

//#region WATCHERS

export default function* fundDimensionsSaga() {
  yield all([
    takeLatest(AdminActionTypes.FETCH_FUND_DIMENSIONS, getFundDimensions),
  ]);
}
//#endregion
