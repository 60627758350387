import React, { FC } from "react";

import { Currency } from "@n3oltd/k2.subscriptions.sdk.subscriptions";
import { connect } from "react-redux";

import IApplicationState from "appRedux/types";

import K2Number, { K2NumberProps } from "./K2Number";

interface K2CurrencyProps extends K2NumberProps {
  currency?: Currency;
  defaultCurrency?: Currency;
  className?: string;
}

const K2Currency: FC<K2CurrencyProps> = ({
  currency,
  defaultCurrency,
  options,
  ...rest
}) => {
  const currencyOptions = {
    style: "currency",
    currency: currency || (defaultCurrency as string),
  };

  let formatOptions = options
    ? { ...options, ...currencyOptions }
    : currencyOptions;
  return <K2Number {...rest} options={formatOptions} />;
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    defaultCurrency: state.subscription?.users?.k2Subscription?.baseCurrency,
  };
};

export default connect(mapStateToProps)(K2Currency);
