import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import reducer from "./modules/reducer";
import saga from "./modules/saga";
import {
  fetchPdfTemplate,
  clearEditStatus,
  clearPdfTemplateError,
  updatePdfTemplate,
  createPdfTemplate,
  clearPdfTemplate,
  updateTemporaryAssets,
  uploadAsset,
  removeTemporaryAsset,
  clearTemporaryAssets,
  removeEpilogueOrPrologue,
  clearEpilogueOrPrologue,
  updateDirty,
  updateEpilogueOrPrologue,
  uploadEpilogueOrPrologue,
  deletePdfTemplate,
} from "./modules/actions";
import IApplicationState from "appRedux/types";

const mapStateToProps = (state: IApplicationState) => {
  return state.pdfTemplate;
};

const mapDispatchToProps = {
  fetchPdfTemplate,
  clearEditStatus,
  clearPdfTemplateError,
  updatePdfTemplate,
  createPdfTemplate,
  clearPdfTemplate,
  updateTemporaryAssets,
  uploadAsset,
  removeTemporaryAsset,
  clearTemporaryAssets,
  clearEpilogueOrPrologue,
  updateEpilogueOrPrologue,
  removeEpilogueOrPrologue,
  uploadEpilogueOrPrologue,
  updateDirty,
  deletePdfTemplate,
};

const withSaga = injectSaga({ key: "pdfTemplate", saga });
const withReducer = injectReducer({ key: "pdfTemplate", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof withConnect>;

export default compose(withSaga, withReducer, withConnect);
