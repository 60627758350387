import { Form, InputNumber } from "antd";
import React from "react";
import { errorKeys } from "appRedux/models/common/Constants";
import injectK2Intl from "../k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "../k2Widgets/k2Localizations/types";
import { InputNumberProps } from "antd/lib/input-number";
import { Rule } from "antd/lib/form";
import { NamePath } from "rc-field-form/lib/interface";

export interface IInputNumberItemV4Props extends InputNumberProps {
  /**name of the field for data binding */
  fieldName: string;
  /**path of the field for data binding */
  fieldNamePath: NamePath;
  /**locale key for label */
  labelKey?: string;
  /**required or not */
  required?: boolean;
  /**Form.Item layout object */
  formItemLayout?: { wrapperCol: Object; labelCol?: Object };
  /**Event to trigger the validation on
   * default: onBlur
   */
  validateTrigger?: string; //default onBlur
  /**React.ReactNode for label */
  label?: React.ReactNode;

  errorTitle?: string;
}

const InputNumberFormItemV4 = ({
  labelKey,
  fieldName,
  fieldNamePath,
  required,
  formItemLayout,
  validateTrigger,
  k2Intl,
  errorTitle,
  ...rest
}: IInputNumberItemV4Props & InjectedK2IntlProps) => {
  if (!fieldName) {
    throw new Error("fieldName' is required for the component to render!");
  }

  let label: string = k2Intl?.formatMessage({ localeKey: labelKey });

  let validationRules: Rule[] = [];

  if (required) {
    validationRules.push({
      required: true,
      message: k2Intl?.formatMessage({
        localeKey: errorKeys.requiredValidationError,
        values: { fieldName: errorTitle || label || fieldName },
      }),
      whitespace: true,
    });
  }

  if (!validateTrigger) {
    validateTrigger = "onBlur";
  }

  return (
    <Form.Item
      {...formItemLayout}
      label={rest.label || label}
      name={fieldNamePath}
      rules={validationRules}
      validateTrigger={validateTrigger}
      required={required}
    >
      <InputNumber {...rest} />
    </Form.Item>
  );
};

export default injectK2Intl(InputNumberFormItemV4);
