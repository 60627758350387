import React, { FC } from "react";
import Error404 from "components/errors/404";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

interface IProps extends InjectedK2IntlProps {
  showNotFound: boolean;
  entityName?: string;
  entityNameKey?: string;
}

const NotFoundWrapper: FC<IProps> = ({
  showNotFound,
  entityName,
  entityNameKey,
  k2Intl,
  children,
}) => {
  if (!entityName && !entityNameKey)
    throw new Error("Please provide entityName or entityNameKey");
  if (showNotFound) {
    return (
      <Error404
        entity={
          entityName
            ? entityName
            : k2Intl.formatMessage({
                localeKey: entityNameKey,
              })
        }
      />
    );
  } else {
    return <>{children}</>;
  }
};

export default injectK2Intl(NotFoundWrapper);
