import { K2Routes } from "appRedux/models/routes/K2Routes";
import { Q1Routes } from "appRedux/models/routes/Q1Routes";

interface QuickFindLink {
  link: string;
  key: string;
  titleKey: string;
  hideFromProd?: boolean;
  hideFromAdmin?: boolean;
  isAntIcon?: boolean;
}

export const q1QuickFindLinks: QuickFindLink[] = [
  {
    key: "payment",
    link: Q1Routes.q1PaymentsSearch,
    titleKey: "common.entity.payment",
  },
];

export const k2QuickFindLinks: QuickFindLink[] = [
  {
    key: "account",
    link: K2Routes.searchAccounts,
    titleKey: "entities.accounts",
  },
  {
    key: "activities",
    link: K2Routes.recentActivities,
    titleKey: "entities.activities",
    isAntIcon: true,
    hideFromAdmin: true,
  },
  {
    key: "donation",
    link: K2Routes.searchDonations,
    titleKey: "common.entity.donation.plural",
  },
  {
    key: "feedbacks",
    link: K2Routes.searchFeedback,
    titleKey: "feedbacks",
  },
  {
    key: "giving",
    link: K2Routes.searchGivings,
    titleKey: "common.entity.giving.plural",
  },

  {
    key: "payment",
    link: K2Routes.searchPayments,
    titleKey: "common.entity.payment.plural",
  },
  {
    key: "pledge",
    link: K2Routes.searchPledges,
    titleKey: "entities.pledges",
  },
  {
    key: "sponsorship",
    link: K2Routes.searchSponsorship,
    titleKey: "entities.sponsorships",
  },
];
