import styled from "styled-components";
import { DatePicker } from "antd";

const DatePickerWithoutYearSkip = styled(DatePicker)`
  && {
    .ant-calendar-header .ant-calendar-next-year-btn {
      display: none;
    }

    .ant-calendar-header .ant-calendar-prev-year-btn {
      display: none;
    }

    .ant-calendar-header .ant-calendar-next-month-btn {
      right: 13px;
    }

    .ant-calendar-header .ant-calendar-prev-month-btn {
      left: 13px;
    }
  }
`;

export default DatePickerWithoutYearSkip;
