import React, { FC, useEffect, useState } from "react";

import { QuickNavigationGroupRes } from "@n3oltd/karakoram.navigation.sdk.quick-navigation/esm";
import { Col, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { _quickNavigationClient } from "appRedux/models/base/K2RestClients";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import TasksList from "components/dashboard/tasksList";
import Greetings from "components/navigation/mainNavigation/Greetings";
import QuickNavigation from "components/navigation/quickNavigation";
import GettingStartedAndRecentActivities from "containers/gettingStartedAndRecentActivities";
import StatisticsGraphs from "containers/statisticsGraphs";
import StatisticsOverview from "containers/statisticsOverview";

const { Title } = Typography;

export const TitleWrapper = styled(Title)`
  margin-bottom: 0 !important;
  color: ${({ theme }) => theme.grey_8} !important;
  font-weight: ${({ theme }) => theme.font_weight_heavy} !important;
  font-size: ${({ theme }) => theme.h2_font_size} !important;
  line-height: ${({ theme }) => theme.line_height_xl} !important;
`;

const StyledRow = styled(Row)`
  && {
    margin: 0 0 12px !important;
    @media screen and (max-width: 575px) {
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (min-width: ${({ theme }) => theme.screen_tab}) {
      margin-top: -25px !important;
    }
  }
`;

const Dashboard: FC = () => {
  const { k2User } = useAppContext();
  const [fetchingQuickLinks, setFetchingQuickLinks] = useState<boolean>(false);
  const [quickLinks, setQuickLinks] = useState<QuickNavigationGroupRes[]>(null);
  const taskCountForUser = useSelector(
    (state: IApplicationState) => state.tasks.dashboardTasks.taskCountForUser,
  );

  useEffect(() => {
    let isCancelled = false;
    setFetchingQuickLinks(true);

    const fetchQuickLinks = async () => {
      try {
        const response = await _quickNavigationClient.get();
        if (!isCancelled) {
          setFetchingQuickLinks(false);
          setQuickLinks(response.groups);
        }
      } catch (e) {
        if (!isCancelled) {
          setFetchingQuickLinks(false);
        }
      }
    };

    fetchQuickLinks();

    return () => {
      isCancelled = true;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <StyledRow gutter={[24, 24]} justify={"center"}>
      {k2User && (
        <Col xl={12} lg={12} md={24} sm={24} xs={24} style={{ padding: 0 }}>
          <Greetings user={k2User} taskCountForUser={taskCountForUser} />
        </Col>
      )}
      <QuickNavigation loading={fetchingQuickLinks} quickLinks={quickLinks} />
      <StatisticsOverview />
      <StatisticsGraphs />
      <Col span={24} style={{ padding: 0 }}>
        <Row gutter={24}>
          <TasksList />
          <GettingStartedAndRecentActivities />
        </Row>
      </Col>
    </StyledRow>
  );
};

export default Dashboard;
