import React, { FC } from "react";

import {
  EllipsisOutlined,
  LockOutlined,
  MenuOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { BookmarkRes } from "@n3oltd/karakoram.analytics.sdk.reports/esm";
import { Col, Divider, Dropdown, List, Radio, Row } from "antd";
import _ from "lodash";
import styled from "styled-components";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import UserAvatar from "components/avatars/userAvatar";
import { K2RadioGroup, K2Tooltip } from "components/k2Widgets";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { CustomScrollbars } from "components/utils";

import CaptureButton from "./captureButton";
import Options from "./options";

const UserAvatarWrapper = styled(UserAvatar)`
  &.ant-avatar {
    width: 25px !important;
    height: 25px !important;
  }
`;

const ListWrapper = styled(List)`
  .ant-radio-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .text {
      color: ${({ theme }) => theme.grey_8};
      margin-bottom: 0;
    }

    &.ant-radio-wrapper-checked,
    &:hover {
      .text {
        color: ${({ theme }) => theme.primaryColor};
      }
    }
  }
`;

interface IProps extends InjectedK2IntlProps {
  bookmarks: BookmarkRes[];
  bookmarkId: string;
  setBookmark: (bookmarkId: string, stateJson: string) => void;
  handleCaptureBookmark: () => void;
  handleUpdateBookmark: (bookmark: BookmarkRes) => void;
  handleShareBookmark: (bookmark: BookmarkRes) => void;
  handleDeleteBookmark: (
    bookmarkId: string,
    bookmarkRevisionId: string,
    bookmarkName: string,
  ) => void;
}

const BookmarksList: FC<IProps> = (props) => {
  const {
    bookmarks,
    bookmarkId,
    setBookmark,
    handleCaptureBookmark,
    handleUpdateBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
  } = props;

  const { k2User, k2Subscription } = useAppContext();

  return (
    <div style={{ marginTop: 20 }}>
      <CaptureButton
        block
        size={"large"}
        localeKey={"reports.captureBookmark.addNew"}
        onClick={handleCaptureBookmark}
      />
      <Divider style={{ margin: "20px -5%", width: "110%" }} />
      <CustomScrollbars
        autoHide
        autoHeight={true}
        autoHeightMin={300}
        autoHeightMax={"100"}
      >
        <K2RadioGroup
          value={
            !_.isNil(bookmarkId)
              ? bookmarks?.find((x) => x.id === bookmarkId)
              : undefined
          }
          onChange={(e) => {
            const item: BookmarkRes = e.target.value;
            setBookmark(item.id, item.stateJson);
          }}
          style={{ width: "100%" }}
        >
          <ListWrapper
            size="default"
            dataSource={bookmarks}
            renderItem={(item: BookmarkRes) => {
              const sharedAccess = item.sharedAccess;
              const sharedWithEveryone = sharedAccess?.everyone;
              const limitedTo = sharedAccess?.limitedTo;
              const limitedToCount = limitedTo?.length;
              const sharedWithCurrentUser =
                limitedTo?.findIndex((x) => x.principalId === k2User?.id) !==
                -1;

              return (
                <List.Item>
                  <Row gutter={[12, 0]} className={"n3o-w-100"}>
                    <Col span={3}>
                      <MenuOutlined className={"n3o-pointer"} />
                    </Col>
                    <Col
                      span={15}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Radio value={item}>
                        <K2Tooltip title={item.name}>
                          <span className={"text"}>{item.name}</span>
                        </K2Tooltip>
                      </Radio>
                    </Col>
                    <Col span={3}>
                      {!item.isShared ? (
                        <K2Tooltip titleKey="reports.bookmarks.private.tooltip">
                          <LockOutlined />
                        </K2Tooltip>
                      ) : (
                        <K2Tooltip
                          titleKey={`reports.bookmarks.shared${
                            sharedWithCurrentUser ? ".by" : ""
                          }.${sharedWithEveryone ? "all" : "others"}${
                            limitedToCount === 1 ? ".one" : ""
                          }.tooltip`}
                          titleValues={{
                            first: sharedWithCurrentUser
                              ? item?.modifiedInfo?.created?.name
                              : limitedTo?.[0]?.name,
                            count:
                              limitedToCount === 1
                                ? undefined
                                : sharedWithCurrentUser
                                ? limitedToCount - 1
                                : limitedToCount,
                          }}
                        >
                          {sharedWithCurrentUser ? (
                            <UserAvatarWrapper
                              profile={item?.modifiedInfo?.created?.principal}
                              presetId="small"
                              subscriptionId={k2Subscription?.id}
                            />
                          ) : (
                            <ShareAltOutlined />
                          )}
                        </K2Tooltip>
                      )}
                    </Col>
                    <Col span={3}>
                      <K2Tooltip titleKey="common.options">
                        <Dropdown
                          trigger={["click"]}
                          overlay={
                            <Options
                              bookmark={item}
                              handleEditBookmark={handleUpdateBookmark}
                              handleShareBookmark={handleShareBookmark}
                              handleDeleteBookmark={handleDeleteBookmark}
                            />
                          }
                          getPopupContainer={(triggerNode: HTMLElement) => {
                            const element = document.getElementById(
                              "route-section",
                            );
                            return !_.isNull(element) ? element : triggerNode;
                          }}
                        >
                          <EllipsisOutlined
                            onClick={(e) => e.preventDefault()}
                          />
                        </Dropdown>
                      </K2Tooltip>
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
          />
        </K2RadioGroup>
      </CustomScrollbars>
    </div>
  );
};

export default BookmarksList;
