import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const CreateTask: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66669 2.8499C4.18488 2.8499 3.72281 3.0413 3.38211 3.38199C3.04142 3.72268 2.85002 4.18476 2.85002 4.66657V19.3332C2.85002 19.815 3.04142 20.2771 3.38211 20.6178C3.7228 20.9585 4.18488 21.1499 4.66669 21.1499H14.4833V17.9997C14.4833 17.0671 14.8538 16.1726 15.5133 15.5131C16.1728 14.8536 17.0673 14.4831 18 14.4831H21.15V4.66657C21.15 4.18476 20.9586 3.72268 20.6179 3.38199C20.2772 3.0413 19.8152 2.8499 19.3334 2.8499H4.66669ZM20.6148 16.1831H18C17.5182 16.1831 17.0561 16.3745 16.7154 16.7152C16.3747 17.0559 16.1833 17.5179 16.1833 17.9997V20.6145L20.6148 16.1831ZM14.895 22.8499H4.66669C3.73401 22.8499 2.83954 22.4794 2.18003 21.8199C1.52053 21.1604 1.15002 20.2659 1.15002 19.3332V4.66657C1.15002 3.73389 1.52053 2.83941 2.18003 2.17991C2.83954 1.52041 3.73401 1.1499 4.66669 1.1499H19.3334C20.266 1.1499 21.1605 1.52041 21.82 2.17991C22.4795 2.83941 22.85 3.73389 22.85 4.66657V14.8949C22.85 14.8949 22.85 14.895 22.85 14.895C22.8501 15.357 22.7591 15.8144 22.5823 16.2412C22.4055 16.668 22.1464 17.0557 21.8196 17.3824L17.3825 21.8195C17.0559 22.1462 16.6681 22.4054 16.2413 22.5822C15.8145 22.759 15.3571 22.8499 14.8951 22.8499C14.8951 22.8499 14.8951 22.8499 14.895 22.8499ZM12 5.81644C12.4695 5.81644 12.85 6.197 12.85 6.66644V9.15009H15.3334C15.8028 9.15009 16.1834 9.53065 16.1834 10.0001C16.1834 10.4695 15.8028 10.8501 15.3334 10.8501H12.85V13.3331C12.85 13.8026 12.4695 14.1831 12 14.1831C11.5306 14.1831 11.15 13.8026 11.15 13.3331V10.8501H8.66673C8.19729 10.8501 7.81673 10.4695 7.81673 10.0001C7.81673 9.53065 8.19729 9.15009 8.66673 9.15009H11.15V6.66644C11.15 6.197 11.5306 5.81644 12 5.81644Z"
      fill={fill}
    />
  </svg>
);
