import {
  DonationItemCriteria,
  DonationItemResultsPage,
  DonationItemStatus,
} from "@n3oltd/k2.donations.sdk.donation-items/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";

import * as actionTypes from "./actionTypes";
import { UpdateDonationItemStatusOpts } from "./types";

export function fetchDonationItems(
  criteria: DonationItemCriteria,
  shouldAppendToList?: boolean,
) {
  return {
    type: actionTypes.FETCH_DONATION_ITEMS,
    criteria,
    shouldAppendToList,
  };
}

export function fetchDonationItemsSuccess(
  result: DonationItemResultsPage,
  shouldAppendToList?: boolean,
) {
  return {
    type: actionTypes.FETCH_DONATION_ITEMS_SUCCESS,
    result,
    shouldAppendToList,
  };
}

export function fetchDonationItemsError(error: ServerError) {
  return {
    type: actionTypes.FETCH_DONATION_ITEMS_ERROR,
    error,
  };
}

export function clearFetchDonationItemsError() {
  return {
    type: actionTypes.CLEAR_FETCH_DONATION_ITEMS_ERROR,
  };
}

export function updateDonationItemsStatus(
  options: UpdateDonationItemStatusOpts,
) {
  return {
    type: actionTypes.UPDATE_DONATION_ITEMS_STATUS,
    options,
  };
}

export function updateDonationItemsStatusSuccess(
  ids: string[],
  activeStatus: DonationItemStatus,
  allSelected: boolean,
  editStatus: EditStatus<void>,
) {
  return {
    type: actionTypes.UPDATE_DONATION_ITEMS_STATUS_SUCCESS,
    ids,
    activeStatus,
    allSelected,
    editStatus,
  };
}

export function updateDonationItemsStatusError(editStatus: EditStatus<void>) {
  return {
    type: actionTypes.UPDATE_DONATION_ITEMS_STATUS_ERROR,
    editStatus,
  };
}

export function clearEditStatus() {
  return {
    type: actionTypes.CLEAR_EDIT_STATUS,
  };
}
