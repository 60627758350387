import { useEffect, useLayoutEffect, useRef } from "react";

export default function useInterval(funcToCallBack: Function, delay?: number) {
  const savedCallback = useRef<Function>();

  useLayoutEffect(() => {
    savedCallback.current = funcToCallBack;
  }, [funcToCallBack]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);
}
