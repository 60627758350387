import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import { editViewTaskReducer as editViewTask } from "components/dashboard/editTask/modules/reducer";
import editViewTaskSaga from "components/dashboard/editTask/modules/sagas";
import callCenter from "routes/callCenter/modules/reducer";
import callCenterSaga from "routes/callCenter/modules/saga";

import history from "../utils/history";
import addresses, { addressesSagas } from "./addresses";
import analytics, { analyticsSagas } from "./analytics";
import auth, { authSagas } from "./auth";
import breadcrumbs from "./breadcrumbs";
import locationSagas from "./location";
import lookups, { lookupSagas } from "./lookups";
import activities, { activitySagas } from "./recentActivities";
import securityPrincipals, {
  securityPrincipalsSagas,
} from "./securityPrincipals";
import settings from "./settings";
import subscription, { subscriptionSagas } from "./subscription";
import tasks, { tasksSagas } from "./tasks";

export function* rootSaga() {
  yield all([
    authSagas(),
    addressesSagas(),
    activitySagas(),
    subscriptionSagas(),
    lookupSagas(),
    tasksSagas(),
    analyticsSagas(),
    securityPrincipalsSagas(),
    editViewTaskSaga(),
    locationSagas.notificationSaga(),
    locationSagas.trackingSaga(),
    callCenterSaga(),
  ]);
}

const createReducer = (injectedReducers = {}) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    activities,
    settings,
    addresses,
    subscription,
    lookups,
    analytics,
    tasks,
    securityPrincipals,
    editViewTask,
    breadcrumbs,
    callCenter,
    ...injectedReducers,
  });

export default createReducer;
