import { RoleResultsPage } from "@n3oltd/k2.users.sdk.roles/src/index";
import { all, put, takeLatest } from "redux-saga/effects";

import { _rolesClient } from "appRedux/models/base/K2RestClients";
import K2Service from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { DataActions } from "appRedux/modules/sharedTypes";

import { ActionTypes, DeleteRole, FetchRoles } from "./actionTypes";
import * as actions from "./actions";

export function* fetchRoles(action: FetchRoles) {
  const response: IApiResponse<RoleResultsPage> = yield K2Service.toResponse(
    _rolesClient.find(action.criteria),
  );
  if (response.error) {
    yield put(actions.fetchRolesError(response.error));
  } else {
    yield put(
      actions.fetchRolesSuccess(
        response.getResultOrDefault(),
        action.shouldAppendToList,
      ),
    );
  }
}

export function* deleteRole(action: DeleteRole) {
  const response: IApiResponse<void> = yield K2Service.toResponse(
    _rolesClient.deleteRole(action.revisionId),
  );

  if (!response.error) {
    yield put(
      actions.deleteRoleSuccess(action.id, {
        action: DataActions.delete,
        ...response,
      }),
    );
  } else {
    yield put(
      actions.deleteRoleError({
        action: DataActions.delete,
        ...response,
      }),
    );
  }
}

export default function* rolesSaga() {
  yield all([
    yield takeLatest(ActionTypes.FETCH_ROLES, fetchRoles),
    yield takeLatest(ActionTypes.DELETE_ROLE, deleteRole),
  ]);
}
