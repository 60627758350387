import { FundStructureRevisionRes } from "@n3oltd/k2.subscriptions.sdk.funds/esm";
import { IconType } from "antd/lib/notification";
import { JSONSchema6Type } from "json-schema";
import _ from "lodash";
import moment, { Moment } from "moment";

import {
  IFundDimensionsFilter,
  ISubscriptionFundDimension,
} from "appRedux/modules/fundDimensions/types";
import { IValidationStatus } from "appRedux/modules/sharedTypes";
import {
  closeNotification,
  showNotification,
} from "components/utils/Notification";

import { InjectedK2Intl } from "../k2Widgets/k2Localizations/types";

export interface GenericObject {
  id: string;
  name: string;
}

const convertStringArrayToKeyValueArray = (
  stringArray?: string[] | Object,
): GenericObject[] => {
  if (typeof stringArray === "object") {
    return Object.keys(stringArray)?.map?.((key) => {
      return {
        id: stringArray[key],
        name: stringArray[key],
      };
    });
  } else {
    const newStringArray = stringArray as string[];

    return newStringArray?.map?.((item) => {
      return {
        id: item,
        name: item,
      };
    });
  }
};

const convertObjectToKeyValueArray = (
  obj: Object,
  capitalizeFirstCharOfKey?: boolean,
  capitalizeKey?: boolean,
): GenericObject[] => {
  return Object.keys(obj)?.map?.((key) => {
    return {
      id: capitalizeFirstCharOfKey
        ? key.charAt(0).toUpperCase() + key.slice(1)
        : capitalizeKey
        ? key.toUpperCase()
        : key,
      name: obj[key],
    };
  });
};

const convertObjectToKeyValue = (
  obj: Object,
  capitalizeFirstCharOfKey?: boolean,
  capitalizeKey?: boolean,
): GenericObject => {
  return Object.keys(obj)?.map?.((key) => {
    return {
      id: capitalizeFirstCharOfKey
        ? key.charAt(0).toUpperCase() + key.slice(1)
        : capitalizeKey
        ? key.toUpperCase()
        : key,
      name: obj[key],
    };
  })?.[0];
};

const convertObjectToJSONSchema6TypeArray = (
  obj: Object,
  capitalizeKey?: boolean,
): JSONSchema6Type[] => {
  var res = Object.keys(obj)?.map?.((key) => {
    var schema: JSONSchema6Type = {
      [capitalizeKey ? key.toUpperCase() : key]: obj[key],
    };

    return schema;
  });

  return res;
};

const sortDimensions = (
  fundDimensionsData: FundStructureRevisionRes,
): Array<IFundDimensionsFilter> => {
  if (!_.isEmpty(fundDimensionsData)) {
    const fundDimensionKeys = Object.keys(fundDimensionsData);

    const sortedDimensions: Array<IFundDimensionsFilter> = fundDimensionKeys
      ?.map?.((key: string) => {
        const fundDimension: ISubscriptionFundDimension = {
          ...fundDimensionsData[key],
        };
        if (!_.isEmpty(fundDimension)) {
          fundDimension.fundDimensionKey = key;

          const sortedDimension: IFundDimensionsFilter = {
            key: key,
            order: fundDimension.order,
            fundDimension: fundDimension,
          };

          return sortedDimension;
        }

        return null;
      })
      .filter((item) => item);

    return _.orderBy(sortedDimensions, "order", "asc"); // Use Lodash to sort array by 'order';
  } else {
    return [];
  }
};

const getTitle = (localeKey: string, k2Intl: InjectedK2Intl) => {
  return !_.isEmpty(localeKey)
    ? k2Intl?.formatMessage({ localeKey: localeKey })
    : "";
};

const showValidationMessage = (
  k2Intl: InjectedK2Intl,
  uniqueId: string,
  message: string,
  existingNotificationKey: string,
  validationStatus: IValidationStatus,
  error?: string,
  messageKey?: string,
  description?: JSX.Element,
  descriptionKey?: string,
  onCloseMethod?: () => void,
) => {
  let notificationKey = existingNotificationKey
    ? existingNotificationKey
    : _.uniqueId(uniqueId);
  //display popup Error Message
  showNotification({
    type: validationStatus as IconType,
    key: notificationKey,
    title: !_.isEmpty(message) ? message : getTitle(messageKey, k2Intl),
    message: !_.isEmpty(error)
      ? error
      : !_.isNull(description)
      ? description
      : getTitle(descriptionKey, k2Intl),
    onClose: () => {
      onCloseMethod();
      closeNotification(notificationKey);
    },
  });

  return notificationKey;
};

const isValidNumber = (value: any) => {
  const reg = /^([0-9]*)?$/;
  return (!Number.isNaN(value) && reg.test(value)) || value === "";
};

const getFixedDateRanges = (
  k2Intl: InjectedK2Intl,
): Record<
  string,
  Exclude<RangeValue<Moment>, null> | (() => Exclude<RangeValue<Moment>, null>)
> => {
  return {
    [`${k2Intl?.formatMessage({ localeKey: "common.today" })}`]: [
      moment().startOf("day"),
      moment(),
    ],
    [`${k2Intl?.formatMessage({ localeKey: "common.thisWeek" })}`]: [
      moment().startOf("week"),
      moment(),
    ],
    [`${k2Intl?.formatMessage({ localeKey: "common.thisMonth" })}`]: [
      moment().startOf("month"),
      moment(),
    ],
    [`${k2Intl?.formatMessage({ localeKey: "common.thisYear" })}`]: [
      moment().startOf("year"),
      moment(),
    ],
  };
};

const getFutureFixedDateRanges = (
  k2Intl: InjectedK2Intl,
): Record<
  string,
  Exclude<RangeValue<Moment>, null> | (() => Exclude<RangeValue<Moment>, null>)
> => {
  return {
    [`${k2Intl?.formatMessage({ localeKey: "common.today" })}`]: [
      moment(),
      moment().endOf("day"),
    ],
    [`${k2Intl?.formatMessage({ localeKey: "common.thisWeek" })}`]: [
      moment(),
      moment().endOf("week"),
    ],
    [`${k2Intl?.formatMessage({ localeKey: "common.thisMonth" })}`]: [
      moment(),
      moment().endOf("month"),
    ],
    [`${k2Intl?.formatMessage({ localeKey: "common.thisYear" })}`]: [
      moment(),
      moment().endOf("year"),
    ],
  };
};

const havePermissions = (
  permissionIds: string[],
  permissions: string[],
  mustHaveAll: boolean = false,
) => {
  if (mustHaveAll) {
    return permissionIds.every((p) => permissions.includes(p));
  } else {
    return permissionIds.some((p) => permissions.includes(p));
  }
};

const capitalizeFirstLetter = (input: string) => {
  if (!input) {
    return "";
  }

  return input.charAt(0).toUpperCase() + input.slice(1);
};

export default {
  getFixedDateRanges,
  getFutureFixedDateRanges,
  isValidNumber,
  convertStringArrayToKeyValueArray,
  convertObjectToKeyValueArray,
  convertObjectToKeyValue,
  convertObjectToJSONSchema6TypeArray,
  sortDimensions,
  getTitle,
  showValidationMessage,
  havePermissions,
  capitalizeFirstLetter,
};
