import { Tag } from "antd";
import styled from "styled-components";

interface TagWithAvatarProps {
  isLink?: boolean;
}

export const TagWithAvatar = styled(Tag)<TagWithAvatarProps>`
  && {
    align-items: center;
    margin-bottom: 0;
    margin-right: 0;
    display: inline-block;
    border-radius: 4px;
    border: 0;
    background: ${({ theme }) => theme.grey_3};

    span.name {
      margin-left: 4px;
    }
    .ant-avatar {
      margin-right: 4px;
    }
    .ant-avatar.ant-avatar-icon {
      font-size: 18px;
    }
    &:hover {
      cursor: ${({ isLink }) => (isLink ? "pointer" : "default")};
      opacity: ${({ isLink }) => (isLink ? 0.7 : 1)};
    }
  }
`;

export const SmallTagWithAvatar = styled(TagWithAvatar)<TagWithAvatarProps>`
  && {
    .ant-avatar-sm {
      width: 19px;
      height: 19px;
      line-height: 19px;
    }
    span.name {
      margin-top: 1px;
      margin-left: 4px;
      display: block;
      float: right;
      white-space: nowrap;
      color: ${({ theme }) => theme.black_color};
    }
    padding: 2px 2px 0px 0px;
  }
`;
