import React, { FC, useEffect } from "react";

import { TaskPriority } from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Switch } from "antd";
import { connect } from "react-redux";
import styled from "styled-components";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import IApplicationState from "appRedux/types";
import {
  clearTaskPriorityError,
  updateTaskPriorityRequest,
} from "components/dashboard/editTask/modules/actions";
import { K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import K2Spin from "components/k2Widgets/k2Spin";
import { UIUtils } from "components/utils";
import { showNotification } from "components/utils/Notification";
import usePrevious from "hooks/usePrevious";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SwitchContainer = styled.span`
  align-items: center;
  display: flex;
  padding-right: 6px;
  .ant-switch-checked {
    background-color: ${({ theme }) => theme.error_color};
  }
`;

interface IProps extends InjectedK2IntlProps {
  priority: TaskPriority;
  updatePriority: typeof updateTaskPriorityRequest;
  taskId: string;
  updating: boolean;
  error?: ServerError;
  clearError: typeof clearTaskPriorityError;
}

const PriorityEditor: FC<IProps> = ({
  priority,
  updatePriority,
  taskId,
  error,
  updating,
  clearError,
  k2Intl,
}) => {
  const previouslyUpdating = usePrevious(updating);

  useEffect(() => {
    if (previouslyUpdating && !updating && !error) {
      showNotification({
        type: "success",
        k2Intl: k2Intl,
        titleKey: "common.success.title",
        messageKey: "tasks.taskUpdatedSuccess",
      });
    }
    // eslint-disable-next-line
  }, [updating]);

  useEffect(() => {
    if (error) {
      UIUtils.handleServerError(k2Intl, error, clearError);
    }
    // eslint-disable-next-line
  }, [error]);

  return (
    <Container>
      <SwitchContainer>
        {updating ? (
          <K2Spin size={"small"} />
        ) : (
          <>
            <span style={{ paddingRight: "8px" }}>
              <K2Message localeKey="tasks.priority" />
            </span>
            <Switch
              checkedChildren={<K2Message localeKey={"tasks.high"} />}
              unCheckedChildren={<K2Message localeKey={"tasks.low"} />}
              checked={priority === "1-high"}
              onChange={() =>
                updatePriority(
                  priority === TaskPriority._3Low
                    ? TaskPriority._1High
                    : TaskPriority._3Low,
                  taskId,
                )
              }
            />{" "}
          </>
        )}
      </SwitchContainer>
    </Container>
  );
};

const mapStateToProps = ({ editViewTask }: IApplicationState) => ({
  priority:
    editViewTask.task && editViewTask.task.priority
      ? editViewTask.task.priority
      : null,
  taskId: editViewTask.task ? editViewTask.task.id : null,
  updating: editViewTask.updatingPriority,
  error: editViewTask.errorUpdatingPriority,
});

const mapDispatchToProps = {
  updatePriority: updateTaskPriorityRequest,
  clearError: clearTaskPriorityError,
};

export default injectK2Intl(
  connect(mapStateToProps, mapDispatchToProps)(PriorityEditor),
);
