import React, { useState } from "react";

import { Form, Input, Select } from "antd";

import {
  _callsAgentsClient,
  _callsClient,
} from "appRedux/models/base/K2RestClients";
import { SkipReasonsLookups } from "appRedux/modules/lookups/types";
import { K2Modal } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { showNotification } from "components/utils/Notification";

import ModalAlert from "./ModalAlert";

interface Props {
  k2Intl: InjectedK2Intl;
  visible: boolean;
  setOpenSkipModal: Function;
  onSkipped: Function;
  skipReasons: SkipReasonsLookups;
}

function SkipRecordModal({
  k2Intl,
  visible,
  setOpenSkipModal,
  onSkipped,
  skipReasons,
}: Props) {
  const [form] = Form.useForm();
  const [skipping, setSkipping] = useState(false);
  const [errors, setErrors] = useState([]);

  return (
    <K2Modal
      titleKey="communication.cc.dialler.skip"
      visible={visible}
      onOk={() => {
        form?.submit();
      }}
      okTextKey="common.skip"
      cancelTextKey="common.cancel"
      onCancel={() => {
        form?.resetFields();
        setOpenSkipModal(false);
        setSkipping(false);
      }}
      okButtonProps={{
        danger: true,
        loading: skipping,
        disabled: skipping,
      }}
      cancelButtonProps={{
        loading: skipping,
        disabled: skipping,
      }}
      centered
      withOutScroll
    >
      <Form
        form={form}
        onFinish={async (values) => {
          setSkipping(true);
          _callsAgentsClient
            .skipRecord(values)
            .then(() => {
              showNotification({
                type: "success",
                titleKey: "common.success.title",
                messageKey: "communication.cc.dialler.skip.success",
                k2Intl: k2Intl,
              });
              setOpenSkipModal(false);
              setSkipping(false);
              form?.resetFields();
              onSkipped();
            })
            .catch((e) => {
              const errors = e.errors?.map?.((e) => e.error);
              setErrors(errors);
              setSkipping(false);
            });
        }}
      >
        <Form.Item name="reason" required>
          <Select
            placeholder={k2Intl.formatMessage({
              localeKey: "communication.cc.dialler.skipReason",
            })}
            getPopupContainer={(trigger: HTMLElement) => {
              return trigger.parentNode as HTMLElement;
            }}
          >
            {skipReasons?.items?.map((reason) => (
              <Select.Option key={reason.id} value={reason.id}>
                {reason.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="notes">
          <Input.TextArea
            rows={4}
            placeholder={k2Intl.formatMessage({ localeKey: "common.notes" })}
          />
        </Form.Item>
        {errors?.length > 0 && <ModalAlert errors={errors} />}
      </Form>
    </K2Modal>
  );
}

export default SkipRecordModal;
