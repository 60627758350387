import React, { FC, useCallback, useEffect, useMemo } from "react";

import { SearchOutlined } from "@ant-design/icons";
import {
  ActivityLogCriteria,
  ActivityLogRes,
} from "@n3oltd/karakoram.activities.sdk.activities/esm";
import { Col, DatePicker, Divider, Form, Input, Row, Tag } from "antd";
import styled from "styled-components";

import { IK2GridColumn } from "appRedux/models/grid/K2GridColumn";
import DateFormatProvider from "appRedux/models/localization/DateFormatProvider";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import K2Breadcrumb from "components/breadcrumb";
import {
  K2CollapseGhost,
  K2DateTime,
  K2Grid,
  K2Link,
  K2RouteHeading,
} from "components/k2Widgets";
import { K2Button } from "components/k2Widgets";
import PaginationFooter from "components/k2Widgets/k2Grid/PaginationFooter";
import { N3oIcon } from "components/n3oIcon";
import { IN3OIconType } from "components/n3oIcon/types";
import { Utils } from "components/utils";
import ActivityTypes from "containers/searchActivities/components/ActivityTypes";
import ActivitiesSearchHelpers from "containers/searchActivities/helpers";
import useBreadcrumb from "hooks/useBreadcrumb";

import { ActivitiesProps } from "./connect";

const { RangePicker } = DatePicker;

const colorsObj = {
  "#F5222D": "red",
  "#52C41A": "green",
  "#2F54EB": "blue",
  "#13C2C2": "cyan",
  "#FA8C16": "orange",
  "#722ED1": "purple",
};

const FiltersForm = styled(Form)`
  border: 1px solid ${({ theme }) => theme.grey_4};
  border-radius: 8px;
  padding: 24px !important;

  .n3o-text-grey-8 {
    font-size: 14px;
    line-height: 22px;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .ant-divider-horizontal {
    margin: 14px 0 !important;
  }

  .ant-btn {
    margin-bottom: 14px;
  }
`;

const SearchActivities: FC<ActivitiesProps> = (props) => {
  const { search, fetching, results, k2Intl, localizationSettings } = props;
  const dateConfig = DateFormatProvider.getConfig(
    localizationSettings?.dateFormat || DateFormatProvider.defaultDateFormat,
  );

  const dateTimeFormatToDisplay = `${dateConfig?.format}`;

  const [form] = Form.useForm();

  useEffect(() => {
    search(false, {});
  }, [search]);

  const setBreadcrumbs = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbs([
      {
        titleKey: "activities.search",
        path: K2Routes.recentActivities,
        active: true,
      },
    ]);
  }, [setBreadcrumbs]);

  const mapValues: (values: any) => ActivityLogCriteria = useCallback(
    (values: any) => {
      return {
        fullText: values.fullText,
        type: values.type,
        timestamp: {
          from: values.timestamp?.[0]
            ? DateFormatProvider.getLocalDateTime(
                values.timestamp[0],
                "dateAndTime",
              ) || null
            : undefined,
          to: values.timestamp?.[1]
            ? DateFormatProvider.getLocalDateTime(
                values.timestamp[1],
                "dateAndTime",
              ) || null
            : undefined,
        },
      };
    },
    [],
  );

  const columns: IK2GridColumn<ActivityLogRes>[] = useMemo(
    () => [
      {
        width: 120,
        title: k2Intl.formatMessage({ localeKey: "common.ref" }),
        dataIndex: "reference",
        key: "reference",
        render: (_, record: ActivityLogRes) => {
          return <span>{record.reference.text}</span>;
        },
      },
      {
        title: k2Intl.formatMessage({ localeKey: "common.type" }),
        dataIndex: "type",
        key: "type",
        render: (_, record: ActivityLogRes) => {
          return (
            <Tag
              color={
                colorsObj.hasOwnProperty(record.type.color)
                  ? colorsObj[record.type.color]
                  : record.type.color
              }
            >
              <N3oIcon
                icontype={record.type.icon as IN3OIconType}
                width={12}
                height={12}
                fill={"#fff"}
                className={"n3o-mr-1"}
              />
              <span style={{ marginLeft: 3 }}>{record.type.name}</span>
            </Tag>
          );
        },
      },
      {
        title: k2Intl.formatMessage({ localeKey: "common.summary" }),
        dataIndex: "summary",
        key: "summary",
        render: (_, record: ActivityLogRes) => {
          if (record.link) {
            return <K2Link to={record.link}>{record.summary}</K2Link>;
          } else {
            return record.summary;
          }
        },
      },
      {
        title: k2Intl.formatMessage({ localeKey: "common.date" }),
        dataIndex: "timestamp",
        key: "time",
        render: (_, record: ActivityLogRes) => {
          return <K2DateTime value={record.timestamp} mode={"dateAndTime"} />;
        },
      },
    ],
    [k2Intl],
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <K2Breadcrumb />
      </Col>
      <Col span={24}>
        <K2RouteHeading headingKey="activities.search" />
      </Col>
      <Col span={6}>
        <FiltersForm
          form={form}
          layout="vertical"
          onFinish={(values: any) => {
            const filters = mapValues(values);
            search(false, filters);
          }}
        >
          <Form.Item
            name={ActivitiesSearchHelpers.keywordFieldName}
            wrapperCol={{ xs: 24 }}
          >
            <Input
              allowClear={true}
              placeholder={k2Intl?.formatMessage({
                localeKey: "common.searchByKeyword",
              })}
            />
          </Form.Item>
          <Divider />

          <Form.Item
            name={ActivitiesSearchHelpers.activityType}
            rules={[
              {
                type: "array",
              },
            ]}
          >
            <ActivityTypes />
          </Form.Item>

          <Divider />

          <K2CollapseGhost
            ghost
            expandIconPosition={"right"}
            titleKey={"common.date"}
          >
            <Form.Item name={ActivitiesSearchHelpers.timestamp}>
              <RangePicker
                style={{ width: "100%" }}
                allowClear
                placeholder={[
                  k2Intl.formatMessage({ localeKey: "common.fromDate" }),
                  k2Intl.formatMessage({ localeKey: "common.toDate" }),
                ]}
                allowEmpty={[true, true]}
                dropdownClassName={"custom-scrollbar-style"}
                format={dateTimeFormatToDisplay}
                ranges={Utils.getFixedDateRanges(k2Intl)}
                getPopupContainer={(triggerNode: HTMLElement) => {
                  const element = document.getElementById("route-section");
                  return element || triggerNode;
                }}
                showTime={false}
              />
            </Form.Item>
          </K2CollapseGhost>
          <Divider />

          <Form.Item>
            <K2Button
              loading={fetching}
              disabled={fetching}
              htmlType="submit"
              type="primary"
              style={{ width: "100%" }}
              size="large"
              icon={<SearchOutlined />}
            >
              {k2Intl.formatMessage({ localeKey: "common.search" })}
            </K2Button>
          </Form.Item>

          <Form.Item>
            <K2Button
              disabled={fetching}
              style={{ width: "100%" }}
              size="large"
              onClick={() => {
                form.resetFields();
                search(false, {});
              }}
            >
              {k2Intl.formatMessage({ localeKey: "common.resetAllFilters" })}
            </K2Button>
          </Form.Item>
        </FiltersForm>
      </Col>
      <Col span={18}>
        <K2Grid
          loading={fetching}
          dataSource={results.items}
          pagination={false}
          columns={columns}
          scroll={{ y: 600 }}
          footer={
            !!results?.continuationToken
              ? () => (
                  <PaginationFooter
                    onLoadMore={() => {
                      const filters = mapValues(form.getFieldsValue());

                      search(!!results.continuationToken, {
                        ...filters,
                        continuationToken: results.continuationToken,
                      });
                    }}
                  />
                )
              : null
          }
        />
      </Col>
    </Row>
  );
};

export default SearchActivities;
