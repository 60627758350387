import React, { FC } from "react";
import { K2Link, K2Message } from "../k2Widgets";

import styled from "styled-components";
import { PlusCircleOutlined } from "@ant-design/icons/lib";

interface AddNewLinkProps {
  titleKey?: string;
  onClick?: () => void;
  to?: string;
  customIcon?: JSX.Element;
  hideIcon?: boolean;
}

const LinkText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.link_color};

  &:hover {
    color: ${({ theme }) => theme.link_hover_color};
    cursor: pointer;
  }

  span:first-of-type {
    margin-right: 7px;
  }
`;

const AddNewLinkShared: FC<AddNewLinkProps> = ({
  titleKey,
  customIcon,
  hideIcon,
}) => {
  return (
    <>
      {hideIcon ? null : !customIcon ? <PlusCircleOutlined /> : customIcon}
      <K2Message localeKey={titleKey || "common.addNew"} />
    </>
  );
};

const AddNewLink: FC<AddNewLinkProps> = (props) => {
  const { onClick, to } = props;

  if (to) {
    return (
      <K2Link to={to}>
        <LinkText className={"new-link"}>
          <AddNewLinkShared {...props} />
        </LinkText>
      </K2Link>
    );
  } else {
    return (
      <LinkText className={"new-link"} onClick={onClick}>
        <AddNewLinkShared {...props} />
      </LinkText>
    );
  }
};

export default AddNewLink;
