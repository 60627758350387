import React, { FC } from "react";
import { CustomScrollbars } from "components/utils";
import styled from "styled-components";

const SidebarContainer = styled.div`
  padding-top: 8px;
  .anticon {
    line-height: inherit;
    font-size: 20px;
  }
`;

const SidebarMenu: FC = ({ children }) => {
  return (
    <SidebarContainer>
      <CustomScrollbars
        autoHide
        autoHeight
        autoHeightMax={"calc(100vh - 70px)"}
      >
        {children}
      </CustomScrollbars>
    </SidebarContainer>
  );
};

export default SidebarMenu;
