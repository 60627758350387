import { ActivityLogResultsPage } from "@n3oltd/karakoram.activities.sdk.activities/src/index";
import { all, put, takeLatest } from "redux-saga/effects";

import { _activitiesClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import {
  ActionTypes,
  SearchActivities,
} from "containers/searchActivities/modules/actionTypes";
import * as actions from "containers/searchActivities/modules/actions";

function* search(action: SearchActivities) {
  try {
    const response: IApiResponse<ActivityLogResultsPage> = yield K2RestService.toResponse(
      _activitiesClient.find({ ...action.criteria, pageSize: 25 }),
    );
    const loadMore = action.loadMore;

    yield put(
      actions.searchActivitiesComplete(
        loadMore,
        response.getResultOrDefault(),
        response.error,
      ),
    );

    const element = loadMore
      ? document.getElementsByClassName("ant-table-tbody")[0]
      : document.getElementById("advancedSearchGrid");

    element?.scrollIntoView({
      behavior: "smooth",
      block: loadMore ? "end" : "start",
      inline: loadMore ? "end" : "nearest",
    });
  } catch (error) {
    console.log("error", error);
  }
}

export default function* watcherSaga() {
  yield all([takeLatest(ActionTypes.SEARCH_ACTIVITIES, search)]);
}
