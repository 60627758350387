import React, { FC, useEffect, useState } from "react";

import { NamedLookupResLookupResultsList } from "@n3oltd/karakoram.activities.sdk.activities/src/index";
import { Select } from "antd";

import { _activitiesClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";

interface IProps extends InjectedK2IntlProps {
  value?: string;
  onChange?: (value: string) => void;
}

const ActivityTypes: FC<IProps> = (props) => {
  const { value, onChange, k2Intl } = props;
  const [types, setTypes] = useState([]);
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    const fetch = async () => {
      const response: IApiResponse<NamedLookupResLookupResultsList> = await K2RestService.toResponse(
        _activitiesClient.getActivityTypes(),
      );

      if (!response.error) {
        setTypes(response?.result?.items);
      } else {
        UIUtils.handleServerError(k2Intl, response.error);
      }
    };

    fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Select
      placeholder={k2Intl.formatMessage({
        localeKey: "activities.filters.type.placeholder",
      })}
      mode={"multiple"}
      value={selectedValue}
      onChange={(option) => {
        onChange(option);
        setSelectedValue(option);
      }}
    >
      {types.map((type) => (
        <Select.Option value={type.id}>{type.name}</Select.Option>
      ))}
    </Select>
  );
};

export default injectK2Intl(ActivityTypes);
