import React from "react";

import { MenuOutlined } from "@ant-design/icons/lib";
import { OverlayRes } from "@n3oltd/karakoram.templates.sdk.pdf/esm";
import { FormInstance } from "antd";
import { SortableHandle } from "react-sortable-hoc";

import { IK2GridColumn } from "appRedux/models/grid/K2GridColumn";
import BaseListModel from "appRedux/models/subscription/BaseListModel";
import DeleteIcon from "components/deleteIcon";
import { GeneralFormItemV4 } from "components/formItems";
import { K2Checkbox, K2Message } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import PdfStationeryHelpers from "routes/admin/communication/newEditPdfStationery/helpers";

import { NewEditPdfStationeryProps } from "../connect";
import { OverlayResWithScreenOnlyAndUUID } from "../modules/types";

export interface NewEditPdfStationeryFormProps
  extends NewEditPdfStationeryProps {
  form: FormInstance;
  onSubmit: (values) => void;
  errors?: string[];
  tempOverlays: OverlayResWithScreenOnlyAndUUID[];
  setTempOverlays: (tempOverlays: OverlayResWithScreenOnlyAndUUID[]) => void;
  toggleModalShowing: (b: boolean) => void;
  letterheadId: string;
}

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

export default class OverlaysListModel extends BaseListModel {
  constructor(config: NewEditPdfStationeryFormProps) {
    super(config);
  }

  getColumns(
    onDelete: (index: number) => void,
    downloadOverlayFile: (filename: string) => void,
  ): IK2GridColumn<OverlayRes>[] {
    const columns: IK2GridColumn<OverlayRes>[] = [
      {
        title: <K2Message localeKey={"common.order"} />,
        dataIndex: "sort",
        width: "150px",
        className: "drag-visible",
        render: () => <DragHandle />,
      },
      {
        dataIndex: "name",
        key: "name",
        width: "250px",
        title: <K2Message localeKey={"common.filename"} />,
        render: (_, item: OverlayRes) => (
          <FakeLink
            onClick={() => {
              downloadOverlayFile(item.attachment?.name);
            }}
          >
            {item.attachment.name}
          </FakeLink>
        ),
      },
      {
        dataIndex: "size",
        key: "size",
        width: "150px",
        title: <K2Message localeKey={"common.fileSize"} />,
        render: (_, item: OverlayRes) => (
          <span>{item.attachment.size.text}</span>
        ),
      },
      {
        dataIndex: "visibility",
        key: "visibility",
        width: "150px",
        title: (
          <K2Message localeKey={"admin.communication.stationery.screenOnly"} />
        ),
        render: (_, item: OverlayRes, index: number) => (
          <span>
            <GeneralFormItemV4
              name={PdfStationeryHelpers.getScreenOnlyFieldName(index)}
              valuePropName={"checked"}
            >
              <K2Checkbox />
            </GeneralFormItemV4>
          </span>
        ),
      },
      {
        dataIndex: "delete",
        key: "delete",
        width: "50px",
        render: (_, item: OverlayRes, index: number) => (
          <div style={{ textAlign: "right" }}>
            <DeleteIcon onClick={() => onDelete(index)} />
          </div>
        ),
      },
    ];

    return columns;
  }
}
