import React, { FC } from "react";

import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { Col, Row } from "antd";
import { useRouter } from "hooks";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";

import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import { K2Menu, K2MenuItem } from "appRedux/modules/sharedTypes";
import K2Breadcrumb from "components/breadcrumb";
import _401Error from "components/errors/401";
import _404Error from "components/errors/404";
import { K2SidebarMenu } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

import TaxSchemeRoutes from "./taxScheme";
import { TallyAdminSideMenu } from "./types";

interface IProps extends InjectedK2IntlProps {
  currentUser: CurrentUserRes;
}

const { q1AdminViewTaxSchemes, q1AdminEditTaxSchemes } = Q1Routes;

const AdminWrapper = styled.div`
  & .async-component {
    margin-top: 25px;
  }
`;

const taxSchemeMenuItems: K2MenuItem[] = [
  {
    principalPath: q1AdminViewTaxSchemes,
    matchingPaths: [q1AdminViewTaxSchemes, q1AdminEditTaxSchemes],
    titleKey: "admin.q1.viewTaxSchemes",
  },
];
let menu: K2Menu<TallyAdminSideMenu> = {
  q1TaxScheme: taxSchemeMenuItems,
};

// sidebarMenuTitle.generalSettings
const Q1AdminRoutes: FC<IProps> = (props) => {
  const router = useRouter<{}>();
  const { match } = router;

  const { currentUser, ...remainingProps } = props;

  return (
    <AdminWrapper>
      <K2Breadcrumb />
      <Row gutter={18}>
        <Col xxl={4} lg={5} xs={24}>
          <K2SidebarMenu<TallyAdminSideMenu>
            {...remainingProps}
            menuOptions={menu}
          />
        </Col>
        <Col xxl={20} lg={19} xs={24}>
          <Switch>
            <Route path={Q1Routes.main.q1admin} component={TaxSchemeRoutes} />
            <Redirect
              exact
              from={match.path}
              to={Q1Routes.q1AdminViewTaxSchemes}
            />
            <Route path={`${match.path}/*`} component={_404Error} />
          </Switch>
        </Col>
      </Row>
    </AdminWrapper>
  );
};

export default injectK2Intl(Q1AdminRoutes);
