import { connect } from "react-redux";
import { injectReducer, injectSaga } from "redux-injectors";
import ApplicationState from "appRedux/types";

import reducer from "./modules/reducers";
import saga from "./modules/saga";

import {
  activateUser,
  clearEditStatus,
  clearSelectedEditUser,
  clearSelectedEditUserError,
  createUser,
  deactivateUser,
  fetchSelectedEditUser,
  updateAdminPrivilege,
  updateSelectedEditUser,
} from "./modules/actions";
import { settingsActions, usersActions } from "appRedux/modules/subscription";
import { fetchRoles } from "routes/admin/rolesList/modules/actions";
import { compose } from "redux";

const { fetchSubscriptionOrganization } = settingsActions;
const { getAppResources, getSubscriptionUserRoutes } = usersActions;

const mapStateToProps = (state: ApplicationState) => ({
  offices:
    state.subscription.settings.organization?.organizationDetails?.offices ||
    [],
  allSubscriptionRoles: state.createEditUser.allSubscriptionRoles,
  allSubscriptionRolesLoading: state.createEditUser.allSubscriptionRolesLoading,
  user: state.createEditUser.user,
  loading: state.createEditUser.loading,
  saving: state.createEditUser.saving,
  serverError: state.createEditUser.serverError,
  editStatus: state.createEditUser.editStatus,
  currentUser: state.subscription.users.k2User,
  lookupsLoadingComplete: !state.lookups.userStatuses?.loading,
});

const mapDispatchToProps = {
  clearSelectedEditUserError,
  fetchSelectedEditUser,
  clearSelectedEditUser,
  fetchRoles,
  fetchSubscriptionOrganization,
  clearEditStatus,
  updateSelectedEditUser,
  createUser,
  activateUser,
  deactivateUser,
  updateAdminPrivilege,
  getAppResources,
  getSubscriptionUserRoutes,
};

const withSaga = injectSaga({ key: "createEditUser", saga });
const withReducer = injectReducer({ key: "createEditUser", reducer });
export const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withSaga, withReducer, withConnect);
