import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const AccountPreference: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 1.5332C9.06952 1.5332 7.53774 2.16769 6.40836 3.29707C5.27897 4.42646 4.64449 5.95824 4.64449 7.55543C4.64449 9.15262 5.27897 10.6844 6.40836 11.8138C7.08666 12.4921 7.9101 12.9919 8.80569 13.2829C7.67791 13.518 6.60154 13.9649 5.63497 14.6033C4.37509 15.4353 3.34105 16.5666 2.62528 17.896C1.90952 19.2254 1.53435 20.7115 1.53333 22.2213C1.5329 22.8472 2.03996 23.355 2.66589 23.3554C3.29181 23.3558 3.79957 22.8488 3.79999 22.2228C3.80077 21.0876 4.08286 19.9702 4.62106 18.9706C5.15926 17.971 5.93678 17.1203 6.88411 16.4947C7.83143 15.8691 8.91904 15.4879 10.0497 15.3854C11.1803 15.2828 12.3187 15.4621 13.3632 15.9071C13.939 16.1524 14.6047 15.8845 14.85 15.3087C15.0954 14.7328 14.8275 14.0671 14.2516 13.8218C13.6951 13.5847 13.1185 13.4043 12.5305 13.282C13.425 12.9909 14.2475 12.4914 14.9251 11.8138C16.0545 10.6844 16.6889 9.15262 16.6889 7.55543C16.6889 5.95824 16.0545 4.42646 14.9251 3.29707C13.7957 2.16769 12.2639 1.5332 10.6667 1.5332ZM8.01113 4.89985C8.71544 4.19554 9.67068 3.79987 10.6667 3.79987C11.6627 3.79987 12.618 4.19554 13.3223 4.89985C14.0266 5.60415 14.4223 6.55939 14.4223 7.55543C14.4223 8.55147 14.0266 9.50671 13.3223 10.211C12.618 10.9153 11.6627 11.311 10.6667 11.311C9.67068 11.311 8.71544 10.9153 8.01113 10.211C7.30683 9.50671 6.91116 8.55146 6.91116 7.55543C6.91116 6.55939 7.30683 5.60415 8.01113 4.89985Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8667 25.3333C18.8667 24.7074 19.3741 24.2 20 24.2H28C28.626 24.2 29.1334 24.7074 29.1334 25.3333C29.1334 25.9592 28.626 26.4666 28 26.4666H20C19.3741 26.4666 18.8667 25.9592 18.8667 25.3333Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 23.8001C15.1531 23.8001 14.4666 24.4866 14.4666 25.3334C14.4666 26.1803 15.1531 26.8668 16 26.8668C16.8468 26.8668 17.5333 26.1803 17.5333 25.3334C17.5333 24.4866 16.8468 23.8001 16 23.8001ZM12.2 25.3334C12.2 23.2348 13.9013 21.5334 16 21.5334C18.0986 21.5334 19.8 23.2348 19.8 25.3334C19.8 27.4321 18.0986 29.1334 16 29.1334C13.9013 29.1334 12.2 27.4321 12.2 25.3334Z"
      fill="#1890FF"
    />
    <path
      d="M28 18.6667L26.6666 18.6667"
      stroke="#1890FF"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2 18.6668C12.2 18.0409 12.7074 17.5334 13.3333 17.5334H21.3333C21.9592 17.5334 22.4666 18.0409 22.4666 18.6668C22.4666 19.2927 21.9592 19.8001 21.3333 19.8001H13.3333C12.7074 19.8001 12.2 19.2927 12.2 18.6668Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 17.1334C23.1531 17.1334 22.4666 17.8199 22.4666 18.6667C22.4666 19.5135 23.1531 20.2 24 20.2C24.8468 20.2 25.5333 19.5135 25.5333 18.6667C25.5333 17.8199 24.8468 17.1334 24 17.1334ZM20.2 18.6667C20.2 16.568 21.9013 14.8667 24 14.8667C26.0986 14.8667 27.8 16.568 27.8 18.6667C27.8 20.7654 26.0986 22.4667 24 22.4667C21.9013 22.4667 20.2 20.7654 20.2 18.6667Z"
      fill={fill}
    />
  </svg>
);
