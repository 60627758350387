import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const PledgeAllocation: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8334 3.49998C14.8334 2.85565 15.3557 2.33331 16 2.33331C16.6444 2.33331 17.1667 2.85565 17.1667 3.49998V5.72987C19.4107 5.98295 21.5882 6.97018 23.309 8.69098C24.1049 9.48693 24.7439 10.3806 25.2259 11.3333C23.8716 11.3333 22.2267 11.3333 22.2267 11.3333C21.6287 9.35734 18.8107 8.32293 17.1667 8.08244V10.1666C17.1667 10.811 16.6444 11.3333 16 11.3333C15.3557 11.3333 14.8334 10.811 14.8334 10.1666V8.08307C13.1909 8.32425 11.6096 9.07667 10.3453 10.3409C9.07998 11.6063 8.32735 13.1893 8.08686 14.8333H10.1667C10.811 14.8333 11.3334 15.3556 11.3334 16C11.3334 16.6443 10.811 17.1666 10.1667 17.1666H8.08751C8.32868 18.8091 9.0811 20.3904 10.3453 21.6547C10.3453 23.1945 10.3453 23.4991 10.3453 24.4332C9.42274 23.9562 9.46891 24.0781 8.69542 23.3046C6.97572 21.5849 5.98865 19.4091 5.73478 17.1666H3.50004C2.85571 17.1666 2.33337 16.6443 2.33337 16C2.33337 15.3556 2.85571 14.8333 3.50004 14.8333H5.73429C5.98737 12.5893 6.9746 10.4118 8.69541 8.69099C10.4151 6.97128 12.5909 5.98422 14.8334 5.73035V3.49998Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8 17.1333V25.5333H28.2V18.8175H22.202C21.8502 18.8162 21.4992 18.7119 21.2003 18.5077L21.1976 18.5059L19.2007 17.1333H15.8ZM14.0789 15.3298C14.412 15.0238 14.8447 14.8666 15.2778 14.8666H19.3536C19.7054 14.868 20.0564 14.9723 20.3552 15.1764L20.3579 15.1783L22.3549 16.5508H28.7222C29.1553 16.5508 29.588 16.708 29.9211 17.014C30.2574 17.323 30.4667 17.7636 30.4667 18.2456V26.1684C30.4667 26.6336 30.2646 27.0582 29.9412 27.3553C29.621 27.6495 29.2058 27.8 28.791 27.8H15.2778C14.8447 27.8 14.412 27.6428 14.0789 27.3368C13.7426 27.0279 13.5333 26.5872 13.5333 26.1052V16.5614C13.5333 16.0794 13.7426 15.6387 14.0789 15.3298Z"
      fill={fill}
    />
  </svg>
);
