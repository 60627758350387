import React, { FC } from "react";

import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { K2Routes } from "appRedux/models/routes/RouteModel";
import { asyncComponent } from "components/utils";
import { RequireAuth } from "containers/app/views";

const ViewOutboxesWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "AdminViewOutboxes" */ "./viewOutboxes"),
  ),
  "outboxes",
);

const CreateEditOutboxWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "AdminCreateEditOutboxes" */ "./createEditOutbox"
      ),
  ),
  "outboxes",
);

const OutboxesRoutes: FC<RouteComponentProps> = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={K2Routes.outboxes}
          component={ViewOutboxesWithAuth}
        />
        <Route
          exact
          path={K2Routes.createOutbox}
          component={CreateEditOutboxWithAuth}
        />
        <Route
          exact
          path={K2Routes.editOutbox}
          component={CreateEditOutboxWithAuth}
        />
      </Switch>
    </>
  );
};

export default OutboxesRoutes;
