import React, { FC } from "react";

import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { Col, Row } from "antd";
import { useRouter } from "hooks";
import { Redirect, Route, Switch } from "react-router-dom";
import { compose } from "redux";
import { injectSaga } from "redux-injectors";
import styled from "styled-components";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import { K2Menu, K2MenuItem } from "appRedux/modules/sharedTypes";
import { useEnvironmentContext } from "common/contexts/EnvironmentProvider/EnvironmentProvider";
import K2Breadcrumb from "components/breadcrumb";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { asyncComponent } from "components/utils";
import { RequireAuth } from "containers/app/views";
import AnalyticsSubRouter from "routes/admin/analytics";
import CallsSubRouter from "routes/admin/callCenter";
import CommunicationSubRouter from "routes/admin/communication";
import DataSubRouter from "routes/admin/data";

import _404Error from "../../components/errors/404";
import { K2SidebarMenu } from "../../components/k2Widgets";
import AddEditDonationItems from "./donationItemsCreateEdit";
import DonationItemsList from "./donationItemsList";
import saga from "./modules/sagas";
import Testing from "./testing";
import { AdminSideMenu } from "./types";
import AddEditUser from "./userCreateEdit";

const AdminWrapper = styled.div`
  & .async-component {
    margin-top: 25px;
  }
`;

const {
  organizationDetails,
  localization,
  users,
  createUser,
  editUser,
  roles,
  createRole,
  editRole,
  fundDimensions,
  attributionDimensions,
  donationItems,
  donationsStatement,
  editDonationItem,
  createDonationItem,
  paymentMethods,
  sponsorshipSchemes,
  sponsorshipNewScheme,
  sponsorshipEditScheme,
  sponsorshipEndedReasons,
  feedbackSchemes,
  feedbackNewScheme,
  feedbackEditScheme,
  feedbackCancellationReasons,
  pledgeCancellationReasons,
  pledgeSources,
  attachmentTypes,
  accountTitles,
  organizationTypes,
  closureReasons,
  communicationsEmail,
  communicationsEmailLayouts,
  communicationsNewEmailPartial,
  communicationsEditEmailLayout,
  communicationsEmailPartials,
  communicationsEditEmailPartial,
  communicationsNewEmailLayout,
  communicationsPdfLayouts,
  communicationsPdfPartials,
  communicationsEditPdfPartial,
  communicationsEditPdfLayout,
  communicationsNewPdfLayout,
  communicationsNewPdfPartial,
  communicationsEmailTemplates,
  communicationsNewEmailTemplate,
  communicationsEditEmailTemplate,
  communicationsPdfTemplates,
  communicationsNewPdfTemplate,
  communicationsEditPdfTemplate,
  communicationsPdfStationery,
  communicationsNewPdfStationery,
  communicationsEditPdfStationery,
  communicationsPDF,
  communicationsSMS,
  communicationsSMSLayouts,
  communicationsNewSMSPartial,
  communicationsEditSMSLayout,
  communicationsSMSPartials,
  communicationsEditSMSPartial,
  communicationsNewSMSLayout,
  communicationsSMSTemplates,
  communicationsNewSMSTemplate,
  communicationsEditSMSTemplate,
  communicationsPrintQueues,
  sharedAssets,
  privacyStatements,
  outboxes,
  createOutbox,
  editOutbox,
  communicationsActions,
  communicationsActionCreate,
  communicationsActionEdit,
  recordAbandonedReasons,
  recordOnHoldReasons,
  viewWebhooks,
  createWebhook,
  editWebhook,
  analyticsReportingPeriods,
  analyticsReports,
  testing,
  touchpoints,
  campaigns,
  callPurposes,
  callDispositions,
  skipReasons,
  jobRoles,
  compliance,
  givingCancellationReasons,
  fundraisers,
  givingScheduled,
  customForms,
  editCustomForms,
} = K2Routes;

const organizationDetailsAdmin = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "OrganizationAdmin" */ "./organizationAdmin/Organization"
      ),
  ),
  "organizationDetails",
);

const localizationSettings = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "LocalizationsAdmin" */ "./localizationAdmin/Localizations"
      ),
  ),
  "localization",
);

const usersList = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "UsersListAdmin" */ "./usersList"),
  ),
  "users",
);

const RolesList = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "RolesListAdmin" */ "./rolesList"),
  ),
  "roles",
);

const AddEditRoles = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "RoleCreateEditAdmin" */ "./roleCreateEdit"),
  ),
  "createRole",
);

const fundDimensionsAdmin = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "FundDimensionsAdmin" */ "./fundDimensions"),
  ),
  "fundDimensions",
);

const payments = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "PaymentsAdmin" */ "./payments"),
  ),
  "paymentMethods",
);

const givingScheduledWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "PaymentsAdmin" */ "./giving/scheduled/ScheduledGiving"
      ),
  ),
  "givingScheduled",
);

const PledgeCancellationReasons = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "pledgeCancellationReasonsAdmin" */ "./pledges/cancellationReasons"
      ),
  ),
  "pledgeCancellationReasons",
);

const PledgeSources = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "pledgeSourcesAdmin" */ "./pledges/sources"),
  ),
  "pledgeSources",
);

const Schemes = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "SponsorshipSchemesAdmin" */ "./sponsorships/schemes"
      ),
  ),
  "sponsorshipSchemes",
);

const SponsorshipEndedReasons = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "sponsorshipEndedReasonsAdmin" */ "./sponsorships/endedReasons"
      ),
  ),
  "sponsorshipEndedReasons",
);

const FeedbackSchemes = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "feedbackSchemesAdmin" */ "./feedbacks/schemes"
      ),
  ),
  "feedbacks",
);

const FeedbackCancellationReasons = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "feedbackCancellationReasonsAdmin" */ "./feedbacks/cancellationReasons"
      ),
  ),
  "feedbacks",
);

const AttachmentTypes = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "AccountAttachmentsAdmin" */ "./accountAttachmentTypes"
      ),
  ),
  "attachmentTypes",
);

const AccountTitles = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "AccountTitlesAdmin" */ "./accountTitles"),
  ),
  "accountTitles",
);

const OrganizationTypes = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "OrganizationTypesAdmin" */ "./organizationTypes"
      ),
  ),
  "organizationTypes",
);

const ClosureReasons = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "ClosureReasonsAdmin" */ "./closureReasons"),
  ),
  "closureReasons",
);

const DonationsStatement = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "ClosureReasonsAdmin" */ "./donationsStatement"
      ),
  ),
  "donationsStatement",
);

const GivingCancellationReasonsAdmin = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "GivingCancelReasonsAdmin" */ "./giving/cancellationReasons"
      ),
  ),
  "givingCancellationReasons",
);

const generalSettingsMenuItems: K2MenuItem[] = [
  {
    principalPath: organizationDetails,
    matchingPaths: [organizationDetails],
    titleKey: "admin.organizationDetails",
  },
  {
    principalPath: localization,
    matchingPaths: [localization],
    titleKey: "admin.localization",
  },
];

const usersAndRolesMenuItems: K2MenuItem[] = [
  {
    principalPath: users,
    matchingPaths: [users, createUser, editUser],
    titleKey: "admin.users",
  },
  {
    principalPath: roles,
    matchingPaths: [roles, createRole, editRole],
    titleKey: "common.entity.role.plural",
  },
];

const sponsorshipsMenuItems: K2MenuItem[] = [
  {
    principalPath: sponsorshipSchemes,
    matchingPaths: [
      sponsorshipSchemes,
      sponsorshipNewScheme,
      sponsorshipEditScheme,
    ],
    titleKey: "admin.sponsorshipSchemes",
  },
  {
    principalPath: sponsorshipEndedReasons,
    matchingPaths: [sponsorshipEndedReasons],
    titleKey: "admin.sponsorshipEndedReasons",
  },
];

const feedbacksMenuItems: K2MenuItem[] = [
  {
    principalPath: feedbackSchemes,
    matchingPaths: [feedbackSchemes, feedbackNewScheme, feedbackEditScheme],
    titleKey: "admin.feedbackSchemes",
  },
  {
    principalPath: feedbackCancellationReasons,
    matchingPaths: [feedbackCancellationReasons],
    titleKey: "admin.feedbackCancellationReasons",
  },
];

const givingMenuItems: K2MenuItem[] = [
  {
    principalPath: givingCancellationReasons,
    matchingPaths: [givingCancellationReasons],
    titleKey: "admin.givingCancellationReasons",
  },
  {
    principalPath: fundDimensions,
    matchingPaths: [fundDimensions],
    titleKey: "admin.fundDimensions",
  },
  {
    principalPath: donationItems,
    matchingPaths: [donationItems, createDonationItem, editDonationItem],
    titleKey: "admin.donationItems",
  },
  {
    principalPath: paymentMethods,
    matchingPaths: [paymentMethods],
    titleKey: "admin.paymentMethods",
  },
  {
    principalPath: donationsStatement,
    matchingPaths: [donationsStatement],
    titleKey: "admin.donationsStatement",
  },
  {
    principalPath: givingScheduled,
    matchingPaths: [givingScheduled],
    titleKey: "admin.giving.scheduled.route",
    hideFromProd: true,
  },
];

const pledgesMenuItems: K2MenuItem[] = [
  {
    principalPath: pledgeCancellationReasons,
    matchingPaths: [pledgeCancellationReasons],
    titleKey: "admin.pledgeCancellationReasons",
  },
  {
    principalPath: pledgeSources,
    matchingPaths: [pledgeSources],
    titleKey: "admin.pledgeSources",
  },
];

const callCenterMenuItems: K2MenuItem[] = [
  {
    principalPath: jobRoles,
    matchingPaths: [jobRoles],
    titleKey: "admin.jobRoles",
  },
  {
    principalPath: callDispositions,
    matchingPaths: [callDispositions],
    titleKey: "admin.callDispositions",
  },
  {
    principalPath: callPurposes,
    matchingPaths: [callPurposes],
    titleKey: "admin.callPurposes",
  },
  {
    principalPath: skipReasons,
    matchingPaths: [skipReasons],
    titleKey: "admin.skipReasons",
  },
  {
    principalPath: compliance,
    matchingPaths: [compliance],
    titleKey: "admin.compliance",
  },
];

const communicationMenuItems: K2MenuItem[] = [
  {
    principalPath: communicationsEmail,
    matchingPaths: [
      communicationsEmail,
      communicationsEmailLayouts,
      communicationsNewEmailPartial,
      communicationsEditEmailLayout,
      communicationsEmailPartials,
      communicationsEditEmailPartial,
      communicationsNewEmailLayout,
      communicationsEmailTemplates,
      communicationsNewEmailTemplate,
      communicationsEditEmailTemplate,
    ],
    titleKey: "admin.communicationEmail",
  },
  {
    principalPath: communicationsPDF,
    matchingPaths: [
      communicationsPDF,
      communicationsPdfLayouts,
      communicationsPdfPartials,
      communicationsEditPdfPartial,
      communicationsEditPdfLayout,
      communicationsNewPdfLayout,
      communicationsNewPdfPartial,
      communicationsPdfTemplates,
      communicationsNewPdfTemplate,
      communicationsEditPdfTemplate,
      communicationsPdfStationery,
      communicationsNewPdfStationery,
      communicationsEditPdfStationery,
    ],
    titleKey: "admin.communicationPDF",
  },
  {
    principalPath: communicationsSMS,
    matchingPaths: [
      communicationsSMS,
      communicationsSMSLayouts,
      communicationsNewSMSPartial,
      communicationsEditSMSLayout,
      communicationsSMSPartials,
      communicationsEditSMSPartial,
      communicationsNewSMSLayout,
      communicationsSMSTemplates,
      communicationsNewSMSTemplate,
      communicationsEditSMSTemplate,
    ],
    titleKey: "admin.communicationSMS",
  },
  {
    principalPath: sharedAssets,
    matchingPaths: [sharedAssets],
    titleKey: "admin.sharedAssets",
  },
  {
    principalPath: communicationsActions,
    matchingPaths: [
      communicationsActions,
      communicationsActionCreate,
      communicationsActionEdit,
    ],
    titleKey: "admin.communicationActions",
  },
  {
    principalPath: outboxes,
    matchingPaths: [outboxes, createOutbox, editOutbox],
    titleKey: "admin.outboxes",
  },
  {
    principalPath: privacyStatements,
    matchingPaths: [privacyStatements],
    titleKey: "admin.privacyStatements",
  },

  {
    principalPath: communicationsPrintQueues,
    matchingPaths: [communicationsPrintQueues],
    titleKey: "admin.communication.printQueues",
  },
];

const dataMenuItems: K2MenuItem[] = [
  {
    principalPath: recordAbandonedReasons,
    matchingPaths: [recordAbandonedReasons],
    titleKey: "admin.abandonedReasons",
  },
  {
    principalPath: customForms,
    matchingPaths: [customForms, editCustomForms],
    titleKey: "admin.customForms",
  },
  {
    principalPath: recordOnHoldReasons,
    matchingPaths: [recordOnHoldReasons],
    titleKey: "admin.onHoldReasons",
  },
  {
    principalPath: viewWebhooks,
    matchingPaths: [viewWebhooks, createWebhook, editWebhook],
    titleKey: "admin.webHooks",
  },
];

const accountsMenuItems: K2MenuItem[] = [
  {
    principalPath: attachmentTypes,
    matchingPaths: [attachmentTypes],
    titleKey: "admin.attachmentTypes",
  },
  {
    principalPath: accountTitles,
    matchingPaths: [accountTitles],
    titleKey: "admin.accountTitles",
  },
  {
    principalPath: organizationTypes,
    matchingPaths: [organizationTypes],
    titleKey: "admin.organizationTypes",
  },
  {
    principalPath: closureReasons,
    matchingPaths: [closureReasons],
    titleKey: "admin.closureReasons",
  },
];

const analyticsMenuItems: K2MenuItem[] = [
  {
    principalPath: attributionDimensions,
    matchingPaths: [attributionDimensions],
    titleKey: "admin.attributionDimensions",
  },
  {
    principalPath: fundraisers,
    matchingPaths: [fundraisers],
    titleKey: "admin.fundraisers",
  },
  {
    principalPath: touchpoints,
    matchingPaths: [touchpoints],
    titleKey: "admin.touchpoints",
  },
  {
    principalPath: analyticsReportingPeriods,
    matchingPaths: [analyticsReportingPeriods],
    titleKey: "admin.reportingPeriods",
  },
  {
    principalPath: analyticsReports,
    matchingPaths: [analyticsReports],
    titleKey: "admin.reports",
  },
  {
    principalPath: campaigns,
    matchingPaths: [campaigns],
    titleKey: "admin.campaigns",
  },
];

const testingMenuItems: K2MenuItem[] = [
  {
    principalPath: testing,
    matchingPaths: [testing],
    titleKey: "admin.testing",
  },
];

let menu: K2Menu<AdminSideMenu> = {
  generalSettings: generalSettingsMenuItems,
  accounts: accountsMenuItems,
  analytics: analyticsMenuItems,
  callCenter: callCenterMenuItems,
  communication: communicationMenuItems,
  data: dataMenuItems,
  feedbacks: feedbacksMenuItems,
  giving: givingMenuItems,
  pledges: pledgesMenuItems,
  sponsorships: sponsorshipsMenuItems,
  usersAndRoles: usersAndRolesMenuItems,
  testing: testingMenuItems,
};

interface IProps extends InjectedK2IntlProps {
  currentUser: CurrentUserRes;
}

const AdminRoutes: FC<IProps> = (props) => {
  const { currentUser, ...remainingProps } = props;

  const env = useEnvironmentContext();

  const router = useRouter<{}>();
  const { match } = router;
  const currentUserEmail = currentUser?.email;
  const isCompanyUser =
    currentUserEmail?.endsWith("@n3o.ltd") ||
    currentUserEmail?.endsWith("@n3o.dev");

  if (!isCompanyUser) {
    delete menu["testing"];
  }

  const { region } = env;

  return (
    <AdminWrapper>
      <K2Breadcrumb />
      <Row gutter={18}>
        <Col xxl={4} lg={5} xs={24}>
          <K2SidebarMenu<AdminSideMenu>
            {...remainingProps}
            menuOptions={menu}
          />
        </Col>
        <Col xxl={20} lg={19} xs={24}>
          <Switch>
            <Route
              exact
              path={organizationDetails}
              component={organizationDetailsAdmin}
            />
            <Route exact path={localization} component={localizationSettings} />

            <Route exact path={users} component={usersList} />
            <Route exact path={createUser} component={AddEditUser} />
            <Route exact path={editUser} component={AddEditUser} />
            <Route exact path={roles} component={RolesList} />
            <Route exact path={createRole} component={AddEditRoles} />
            <Route exact path={editRole} component={AddEditRoles} />

            <Route
              exact
              path={givingCancellationReasons}
              component={GivingCancellationReasonsAdmin}
            />
            <Route
              exact
              path={fundDimensions}
              component={fundDimensionsAdmin}
            />
            <Route exact path={donationItems} component={DonationItemsList} />
            <Route
              exact
              path={editDonationItem}
              component={AddEditDonationItems}
            />
            <Route
              exact
              path={createDonationItem}
              component={AddEditDonationItems}
            />
            <Route
              exact
              path={donationsStatement}
              component={DonationsStatement}
            />

            {isCompanyUser ? (
              <Route
                exact
                path={testing}
                render={(routeProps) => (
                  <Testing
                    {...routeProps}
                    {...props}
                    isCompanyUser={isCompanyUser}
                  />
                )}
              />
            ) : null}

            <Route exact path={paymentMethods} component={payments} />

            <Route
              exact
              path={givingScheduled}
              component={givingScheduledWithAuth}
            />

            <Route
              exact
              path={pledgeCancellationReasons}
              component={PledgeCancellationReasons}
            />
            <Route exact path={pledgeSources} component={PledgeSources} />

            <Route path={sponsorshipSchemes} component={Schemes} />
            <Route
              exact
              path={sponsorshipEndedReasons}
              component={SponsorshipEndedReasons}
            />

            <Route path={feedbackSchemes} component={FeedbackSchemes} />
            <Route
              exact
              path={feedbackCancellationReasons}
              component={FeedbackCancellationReasons}
            />

            <Route
              path={`/${region}/engage/admin/communications`}
              component={CommunicationSubRouter}
            />

            <Route
              path={`/${region}/engage/admin/data`}
              component={DataSubRouter}
            />

            <Route
              path={`/${region}/engage/admin/analytics`}
              component={AnalyticsSubRouter}
            />

            <Route
              path={`/${region}/engage/admin/callcenter`}
              component={CallsSubRouter}
            />

            <Route exact path={attachmentTypes} component={AttachmentTypes} />
            <Route exact path={accountTitles} component={AccountTitles} />
            <Route
              exact
              path={organizationTypes}
              component={OrganizationTypes}
            />
            <Route exact path={closureReasons} component={ClosureReasons} />

            <Redirect exact from={match.path} to={organizationDetails} />

            <Redirect
              exact
              from={`${match.path}/general`}
              to={organizationDetails}
            />
            <Redirect
              exact
              from={`${match.path}/giving`}
              to={givingCancellationReasons}
            />
            <Redirect
              exact
              from={`${match.path}/sponsorships`}
              to={sponsorshipSchemes}
            />
            <Redirect
              exact
              from={`${match.path}/feedbacks`}
              to={feedbackSchemes}
            />
            <Redirect
              exact
              from={`${match.path}/accounts`}
              to={attachmentTypes}
            />
            <Route path={`${match.path}/*`} component={_404Error} />
          </Switch>
        </Col>
      </Row>
    </AdminWrapper>
  );
};

const withSaga = injectSaga({ key: "admin", saga });

export default compose(withSaga)(injectK2Intl(AdminRoutes));
