import {
  TaskCriteria,
  TaskSummaryResultsPage,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";

import { ActionTypes, TaskDrawerAction } from "./actionTypes";

export const updateTaskDrawerEntityType = (
  entityType: "sponsorship" | "account",
): TaskDrawerAction => ({
  type: ActionTypes.UPDATE_TASK_DRAWER_ENTITY_TYPE,
  entityType,
});

export const updateTaskDrawerEntityId = (
  entityId: string,
): TaskDrawerAction => ({
  type: ActionTypes.UPDATE_TASK_DRAWER_ENTITY_ID,
  entityId,
});

export const updateTaskDrawerWrapperId = (
  wrapperId: string,
): TaskDrawerAction => ({
  type: ActionTypes.UPDATE_TASK_DRAWER_WRAPPER_ID,
  wrapperId,
});

export const updateTaskDrawerTasks = (
  criteria: TaskCriteria,
): TaskDrawerAction => ({
  type: ActionTypes.UPDATE_TASK_DRAWER_TASKS,
  criteria,
});

export const updateTaskDrawerTasksSuccess = (
  data: TaskSummaryResultsPage,
): TaskDrawerAction => ({
  type: ActionTypes.UPDATE_TASK_DRAWER_TASKS_SUCCESS,
  data,
});

export const updateTaskDrawerTasksError = (
  error: ServerError,
): TaskDrawerAction => ({
  type: ActionTypes.UPDATE_TASK_DRAWER_TASKS_ERROR,
  error,
});

export const clearTaskDrawerError = (): TaskDrawerAction => ({
  type: ActionTypes.CLEAR_TASK_DRAWER_ERROR,
});
