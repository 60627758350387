import styled from "styled-components";

export const PreviewLink = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 20px;
  padding-right: 8px;
  z-index: 100;
`;
