import React, { FC } from "react";

import { ReleaseNotesRes } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { CurrentUserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { Col, Divider, Menu, Row, Skeleton, Typography } from "antd";
import { Interweave } from "interweave";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Q1Routes } from "appRedux/models/routes/Q1Routes";
import { K2Routes } from "appRedux/models/routes/RouteModel";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import AppHelpers from "common/helpers";
import { K2Link, K2Message } from "components/k2Widgets";
import { N3oIcon } from "components/n3oIcon";
import { CustomScrollbars } from "components/utils";
import { TALLY_PERMISSION } from "constants/appConstants";

import "./styles.less";

const { Title, Text } = Typography;

const AppLink = styled.div``;

const ReleaseNotesColWrapper = styled(Col)`
  background: ${({ theme }) =>
    `linear-gradient(35.87deg, ${theme.primaryColor} 24.91%, ${theme.primaryColor} 100%)`};
`;

const AppSuiteWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface IProps {
  k2User: CurrentUserRes;
  isReleaseNotesLoading: boolean;
  k2SubscriptionReleaseNotes: ReleaseNotesRes;
}

const SubscriptionMenu: FC<IProps> = ({
  k2User,
  isReleaseNotesLoading,
  k2SubscriptionReleaseNotes,
}) => {
  const { appId, theme } = useAppContext();

  const subscription = useSelector(
    (state: IApplicationState) => state.subscription.users.k2Subscription,
  );

  const renderTallyLink = () => {
    return (
      k2User.permissionIds?.includes(TALLY_PERMISSION) && (
        <AppLink>
          <K2Link
            app={"q1"}
            to={Q1Routes.main.q1Dashboard}
            disabled={appId === "q1"}
          >
            {AppHelpers.renderAppName("q1")}
          </K2Link>
        </AppLink>
      )
    );
  };

  const renderEngageLink = () => {
    return (
      <AppLink>
        <K2Link
          app={"k2"}
          to={K2Routes.main.dashboard}
          disabled={appId === "k2"}
        >
          {AppHelpers.renderAppName("k2")}
        </K2Link>
      </AppLink>
    );
  };

  return (
    <Menu prefixCls={"ant-dropdown-menu"} style={{ borderRadius: "11.2203px" }}>
      <Menu.Item disabled className={"subs-menu-item"}>
        <Row className="n3o-subs-menu">
          <Col span={9} className="app-list n3o-m-0 n3o-pr-0">
            <AppSuiteWrapper className={"n3o-mb-2"}>
              <N3oIcon
                icontype="n3o-app-suite"
                width={23}
                height={23}
                className={"n3o-mr-2"}
                fill={theme.grey_6}
              />
              <Text type="secondary">
                <K2Message localeKey={"subscriptionPicker.AppSuite"} />
              </Text>
            </AppSuiteWrapper>
            <CustomScrollbars
              autoHide
              autoHeight={true}
              autoHeightMin={100}
              autoHeightMax={150}
              className={"subs-custom-scrollbar"}
            >
              {appId === "k2" ? (
                <>
                  {renderEngageLink()}
                  {renderTallyLink()}
                </>
              ) : (
                <>
                  {renderTallyLink()}
                  {renderEngageLink()}
                </>
              )}
              <AppLink>
                <K2Link external to={subscription.headlessUrl}>
                  <K2Message
                    localeKey={
                      "admin.sponsorship.schemes.scheme.feed.type.forms"
                    }
                  />
                </K2Link>
              </AppLink>
            </CustomScrollbars>

            {k2User.admin && (
              <div>
                <Text className="manage-subs n3o-pointer">
                  <K2Message
                    spanClassName={"temp-display-none"}
                    localeKey={"subscriptionPicker.ManageSubscriptions"}
                  />
                </Text>
              </div>
            )}
          </Col>
          <ReleaseNotesColWrapper span={15} className="release-notes n3o-pt-3">
            <Title level={4}>
              N3O <Divider type="vertical" style={{ borderColor: "white" }} />{" "}
              {AppHelpers.renderAppName(appId)}
            </Title>
            {isReleaseNotesLoading ? (
              <Skeleton active />
            ) : (
              <>
                <p>{k2SubscriptionReleaseNotes?.releaseName}</p>

                <Title level={4}>{k2SubscriptionReleaseNotes?.title}</Title>
                <CustomScrollbars
                  autoHide
                  autoHeight={true}
                  autoHeightMin={75}
                  autoHeightMax={100}
                  className={"release-note-custom-scrollbar"}
                >
                  <div className={"notes n3o-text-white n3o-mb-3"}>
                    <Interweave
                      content={k2SubscriptionReleaseNotes?.notes}
                      tagName={"fragment"}
                    />
                  </div>
                </CustomScrollbars>
                <p className={"n3o-text-right n3o-mb-0"}>
                  <a
                    target={"_bank"}
                    rel="noopener noreferrer"
                    className={"n3o-text-light-grey"}
                    href={k2SubscriptionReleaseNotes?.readMoreUrl}
                  >
                    <K2Message
                      localeKey={"subscriptionPicker.ReadMore"}
                      style={{ marginRight: 5 }}
                    />
                    <N3oIcon
                      icontype="n3o-external-link"
                      height={14}
                      width={14}
                      fill={theme.grey_5}
                    />
                  </a>
                </p>
              </>
            )}
          </ReleaseNotesColWrapper>
        </Row>
      </Menu.Item>
    </Menu>
  );
};

export default SubscriptionMenu;
