import { omit } from "lodash";

import { K2Routes } from "./K2Routes";

const omittedRoutes = omit(
  K2Routes,
  "baseRouteEmpty",
  "baseRoute",
  "errors",
  "internal",
  "logout",
  "main",
  "testing",
);

const FlatRoutes = { ...omittedRoutes, ...K2Routes.main }; // Did it like this instead of object.assign as this one gives the right typescript definition upon hovering.

const InternalRoutes: InternalRouteKey[] = [
  {
    routePath: K2Routes.internal["searchSponsorshipsViewAccountSponsorship"],
    apiRouteKey: "searchSponsorship",
  },
  {
    routePath: K2Routes.internal["searchSponsorshipsViewAnonymousSponsorship"],
    apiRouteKey: "searchSponsorship",
  },
];

export { K2Routes, FlatRoutes, InternalRoutes };

export type K2RouteKeys = keyof typeof FlatRoutes;
export type K2ErrorRouteKeys = keyof typeof K2Routes.errors;
export type K2InternalRouteKeys = keyof typeof K2Routes.internal;
export type K2MainRouteKeys = keyof typeof K2Routes.main;

/**
 * 'Key':
 *    Actual Internal Route Key which you want to find
 * 'ApiRouteKey':
 *    The key of an alternative route which
 *    you want to pass to the backend api
 */
export type InternalRouteKey = {
  routePath: string;
  apiRouteKey: K2RouteKeys;
};
