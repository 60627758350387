import React, { FC } from "react";

import { Col, Row } from "antd";
import _ from "lodash";
import styled from "styled-components";

import K2Card from "../k2Card";
import K2Message from "../k2Localizations/K2Message";

export interface K2TypePickerData<T = string> {
  key: T;
  icon: React.ReactNode;
  link?: string;
  titleKey?: string;
  title?: string;
}

const Container = styled(Row)`
  && {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    .ant-card-body .ant-col .ant-card {
      padding: 14px 32px;
      margin: 12px 0px;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .ant-card-head {
      padding: 8px;
    }
  }
`;

const Items = styled(Row)`
  && {
    padding: 1px 12px 12px 12px;
    display: flex;
    align-items: stretch;
  }
`;

interface K2TypePickerProps {
  titleKey: string;
  items: K2TypePickerData[];
  onItemSelected: (item: K2TypePickerData) => void;
}

const K2TypePicker: FC<K2TypePickerProps> = ({
  items,
  onItemSelected,
  titleKey,
}) => {
  return (
    <Container>
      <Col md={12} sm={20} style={{ padding: 12 }}>
        <K2Card
          shadow
          title={<K2Message localeKey={titleKey} />}
          loading={_.isEmpty(items)}
        >
          <Items gutter={[24, 24]}>
            {items?.map?.((item) => (
              <K2TypePickerItem
                key={item.key}
                item={item}
                onItemSelected={onItemSelected}
              />
            ))}
          </Items>
        </K2Card>
      </Col>
    </Container>
  );
};

interface K2TypePickerItemProps {
  item: K2TypePickerData;
  onItemSelected: (item: K2TypePickerData) => void;
}

const HoverableCard = styled(K2Card)`
  && {
    text-align: center;
    &:hover {
      cursor: pointer;
      box-shadow: 6px 3px 10px ${({ theme }) => theme.grey_4};
      border-radius: 4px;
    }
  }
`;

const K2TypePickerItem = ({ item, onItemSelected }: K2TypePickerItemProps) => (
  <Col md={12} sm={24}>
    <HoverableCard shadow onClick={() => onItemSelected(item)}>
      {item.icon}
      <h4 className="n3o-mt-2">
        {item.titleKey && <K2Message localeKey={item.titleKey} />}
        {!item.titleKey && item.title && <span>{item.title}</span>}
      </h4>
    </HoverableCard>
  </Col>
);

export default K2TypePicker;
