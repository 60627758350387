import { LookupType as TemplateLookupTypes } from "@n3oltd/karakoram.templates.sdk.lookups";

import injectLookups from "appRedux/utils/injectLookups";

import PartialOrLayout from "./PartialOrLayout";
import composed from "./connect";

const withLookups = injectLookups(PartialOrLayout, [
  TemplateLookupTypes.AssetTypes,
  TemplateLookupTypes.TemplateEngines,
]);

export default composed(withLookups);
