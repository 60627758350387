import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const DoubleArrowBoxUp: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.35455 11.5531C5.66003 11.9096 6.19663 11.9509 6.55309 11.6454L12 6.97765L17.4469 11.6454C17.8033 11.9509 18.3399 11.9096 18.6454 11.5531C18.9509 11.1967 18.9095 10.6601 18.5531 10.3546L12.8362 5.45546C12.7157 5.35204 12.5793 5.27555 12.4376 5.22519C12.2959 5.17483 12.1475 5.15002 12 5.15002C11.8525 5.15002 11.7041 5.17483 11.5624 5.22519C11.4207 5.27555 11.2843 5.35198 11.1638 5.4554L5.44687 10.3546C5.09041 10.6601 5.04908 11.1967 5.35455 11.5531Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.35455 17.7031C5.66003 18.0595 6.19663 18.1009 6.55309 17.7954L12 13.1276L17.4469 17.7954C17.8033 18.1009 18.3399 18.0595 18.6454 17.7031C18.9509 17.3466 18.9095 16.81 18.5531 16.5046L12.8362 11.6054C12.7157 11.502 12.5793 11.4255 12.4376 11.3752C12.2959 11.3248 12.1475 11.3 12 11.3C11.8525 11.3 11.7041 11.3248 11.5624 11.3752C11.4207 11.4255 11.2843 11.5019 11.1638 11.6054L5.44687 16.5046C5.09041 16.81 5.04908 17.3466 5.35455 17.7031Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 21.3H5C3.72975 21.3 2.7 20.2703 2.7 19V5C2.7 3.72975 3.72975 2.7 5 2.7H19C20.2703 2.7 21.3 3.72975 21.3 5V19C21.3 20.2703 20.2703 21.3 19 21.3ZM5 23C2.79086 23 1 21.2091 1 19V5C1 2.79086 2.79086 1 5 1H19C21.2091 1 23 2.79086 23 5V19C23 21.2091 21.2091 23 19 23H5Z"
      fill={fill}
    />
  </svg>
);
