import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

import { Form, Select } from "antd";
import { NamePath } from "antd/es/form/interface";
import { FormInstance, FormItemProps, Rule } from "antd/lib/form";
import { SelectProps, SelectValue } from "antd/lib/select";
import { usePrevious } from "hooks";
import _ from "lodash";

import { errorKeys } from "appRedux/models/common/Constants";
import { FieldErrorsList } from "appRedux/modules/sharedTypes";
import { K2SelectV4 } from "components/k2Widgets";
import { UIUtils } from "components/utils";

import injectK2Intl from "../k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "../k2Widgets/k2Localizations/types";
import GeneralFormItemV4 from "./GeneralFormItemV4";

export interface ISelectFormItemV4Props<T = SelectValue>
  extends SelectProps<T>,
    Omit<FormItemProps, "children">,
    InjectedK2IntlProps {
  /**antd form form parent component props */
  form: FormInstance;
  /**name of the field for data binding */
  fieldName: NamePath;
  /**index of the field for data binding */
  fieldIndex?: number;
  /**property of the field for data binding */
  fieldProperty?: string;
  /**locale key for label */
  labelKey?: string;
  /**React.ReactNode for label */
  label?: React.ReactNode;
  /**Position or className to align label */
  labelAlignment?: string;
  /**Initial value for select */
  initialValue?: SelectValue;
  /**required or not */
  required?: boolean;
  /**Form.Item layout object */
  formItemLayout?: { wrapperCol: Object; labelCol?: Object };
  /**locale key for placeholder */
  placeholderKey?: string;
  /**Event to trigger the validation on
   * default: onBlur
   */
  validateTrigger?: string | string[];
  /**data source, {idField: string, nameField: string}[] */
  dataSource: Array<Object>;
  /**Name of the id field property in data source
   * default: id
   */
  idField?: string;
  /**Name of the name field property in data source
   * default: name
   */
  nameField?: string;
  errorTitle?: string;
  /** If true, a Form.Item wrapper will not be rendered, but only Select with field decorator */
  renderFieldOnly?: boolean;
  /** If true, the field won't be rendered, but field decorator will be create in order to set the form field. */
  decorateOnly?: boolean;
  /**when user lefts the control */
  onAfterBlur?: any;
  /**when user changes the value control */
  onAfterChange?: any;
  /**call back for parent to detect if data is clean or not */
  updateValidationFeedback?: (hasCleanData: boolean) => void;
  /**form validation ran or not*/
  savingForm?: boolean;
  /**current section of form is validation or not*/
  isFormValid?: boolean;
  /**force validation to update*/
  dynamicItem?: boolean;
  /**to check if to display social media icons or not*/
  socialMedia?: boolean;
  /**to show country icons*/
  showFlagIcon?: boolean;
  /**forcing not to show error*/
  dontShowError?: boolean;
  /**to not set the first item as default value*/
  dontSetFirstItem?: boolean;

  /*this will not show any success class*/
  dontShowCleanIcon?: boolean;
}

type ITexts = {
  label: string;
  placeholder: string;
  validationMessage: string;
};

const SelectFormItemV4 = <T extends SelectValue>(
  props: PropsWithChildren<ISelectFormItemV4Props<T>>,
) => {
  let {
    form,
    labelKey,
    fieldName,
    initialValue,
    required,
    formItemLayout,
    placeholderKey,
    validateTrigger,
    dataSource,
    idField,
    nameField,
    mode,
    k2Intl,
    errorTitle,
    renderFieldOnly,
    decorateOnly,
    className,
    labelAlignment,
    socialMedia,
    showFlagIcon,
    dontShowError,
    dontSetFirstItem,
    help,
    savingForm,
    onAfterBlur,
    onAfterChange,
    dynamicItem,
    isFormValid,
    fieldIndex,
    fieldProperty,
    validateStatus,
    style,
    hasFeedback,
    tooltip,
    dontShowCleanIcon,
    updateValidationFeedback,
    ...restProps
  } = props;

  if (!idField) idField = "id";
  if (!nameField) nameField = "name";

  const [hasCleanData, setHasCleanData] = useState<boolean>(
    !_.isNil(initialValue) || false,
  );
  const prevSavingForm = usePrevious(savingForm);

  const getRules = useCallback(
    (texts: ITexts): Rule[] => {
      const dataType =
        mode === "multiple" || mode === "tags" ? "array" : "string";

      if (required) {
        return [
          {
            required: true,
            message: texts.validationMessage,
            type: dataType,
          },
        ];
      }

      return [];
    },
    [mode, required],
  );

  const dataSourceHasValue = useCallback(
    (fieldValue?: string) => {
      const valueToSearch =
        !_.isNil(fieldValue) && !_.isEmpty(fieldValue)
          ? fieldValue
          : initialValue;

      return (
        dataSource.findIndex((item) => item[idField] === valueToSearch) !== -1
      );
    },
    [dataSource, idField, initialValue],
  );

  const updateValidationFeedbackStatus = useCallback(
    (hasCleanData: boolean) => {
      if (form?.isFieldTouched(fieldName)) {
        updateValidationFeedback?.(hasCleanData);
      }
    },
    [fieldName, form, updateValidationFeedback],
  );

  const handleValidationFeedback = useCallback(
    (updateState: boolean) => {
      if (!hasCleanData) {
        const fieldValue = form?.getFieldValue(fieldName);
        const hasValue = dataSourceHasValue(fieldValue);

        if (updateState && hasCleanData !== hasValue) {
          setHasCleanData(hasValue);
        }
        updateValidationFeedbackStatus(hasValue);
      }
    },
    [
      dataSourceHasValue,
      fieldName,
      form,
      hasCleanData,
      updateValidationFeedbackStatus,
    ],
  );

  const validateField = useCallback(() => {
    const fieldValue = form?.getFieldValue(fieldName);
    const hasValue = dataSourceHasValue(fieldValue);

    if (
      (_.isUndefined(dynamicItem) || !dynamicItem) &&
      (_.isUndefined(isFormValid) || !isFormValid) &&
      hasValue
    ) {
      form?.validateFields([fieldName]).then(() => {
        const err = form?.getFieldError(fieldName);
        if (_.isEmpty(err)) {
          setHasCleanData(true);
          updateValidationFeedbackStatus(true);

          form?.setFields([
            {
              name: fieldName,
              value: fieldValue,
              errors: undefined,
              touched: true,
              validating: false,
            },
          ]);
        }
      });
    }
  }, [
    dataSourceHasValue,
    dynamicItem,
    fieldName,
    form,
    isFormValid,
    updateValidationFeedbackStatus,
  ]);

  useEffect(() => {
    if (
      !_.isUndefined(savingForm) &&
      savingForm &&
      savingForm !== prevSavingForm &&
      (_.isUndefined(dontShowError) || !dontShowError)
    ) {
      validateField();
    }

    handleValidationFeedback(true);
  }, [
    dontShowError,
    handleValidationFeedback,
    prevSavingForm,
    savingForm,
    validateField,
  ]);

  const clearValidationErrors = useCallback(() => {
    setHasCleanData(false);
    updateValidationFeedbackStatus(false);
  }, [updateValidationFeedbackStatus]);

  const onBlur = useCallback(
    (e) => {
      validateField();
      onAfterBlur?.(e, hasCleanData);
    },
    [hasCleanData, onAfterBlur, validateField],
  );

  const onChange = useCallback(
    (e) => {
      validateField();
      onAfterChange?.(e);
    },
    [onAfterChange, validateField],
  );

  const onSelect = useCallback(
    (e) => {
      validateField();
      onAfterChange?.(e);
    },
    [onAfterChange, validateField],
  );

  const getLocalizedTexts = useCallback((): ITexts => {
    const label = k2Intl?.formatMessage({ localeKey: labelKey });
    const placeholder =
      k2Intl?.formatMessage({ localeKey: placeholderKey }) || label;
    const validationMessage = k2Intl?.formatMessage({
      localeKey: errorKeys.requiredValidationError,
      values: { fieldName: errorTitle || label },
    });

    return {
      label,
      placeholder,
      validationMessage,
    };
  }, [errorTitle, k2Intl, labelKey, placeholderKey]);

  if (!fieldName || !dataSource) {
    throw new Error(
      "'fieldName', 'dataSource', are required for the component to render!, fieldName=" +
        fieldName +
        ", dataSource=" +
        dataSource,
    );
  }

  const texts = getLocalizedTexts();

  const fieldValidating = form?.isFieldValidating(fieldName);
  const fieldTouched = form?.isFieldTouched(fieldName);

  if (fieldValidating && fieldTouched) {
    handleValidationFeedback(false);
  }

  let labelAlignmentClassName: string;

  if (labelAlignment !== undefined) {
    labelAlignmentClassName =
      labelAlignment === "left"
        ? "k2-label-left"
        : "k2-label-left" + labelAlignment;
  }

  const options = dataSource?.map?.((item) => {
    return (
      <Select.Option
        key={item[idField]}
        value={item[idField]}
        title={item[showFlagIcon ? "name" : nameField]}
      >
        {socialMedia ? (
          <span className={"n3o-mr-2"}>
            {UIUtils.getSocialIcon(item[idField])}
          </span>
        ) : (
          showFlagIcon && (
            <i
              className={`flag flag-24 n3o-mr-2 flag-${item[
                idField
              ].toLowerCase()}`}
            />
          )
        )}
        {showFlagIcon ? `+(${item[nameField]})` : item[nameField]}
      </Select.Option>
    );
  });

  const selectComponent = decorateOnly ? (
    <></>
  ) : (
    <K2SelectV4
      focusInputOnFocus
      className={className}
      style={style}
      mode={mode}
      placeholder={restProps.placeholder || texts.placeholder}
      getPopupContainer={(triggerNode: HTMLElement) => {
        const element = document.getElementById("route-section");
        return !_.isNull(element) ? element : triggerNode;
      }}
      optionFilterProp="children"
      {...restProps}
      onBlur={onBlur}
      onAfterChange={onChange}
      onSelect={onSelect}
      optionsList={options}
    />
  );

  if (decorateOnly) {
    renderFieldOnly = true;
  }

  if (renderFieldOnly) {
    return <>{selectComponent}</>;
  }

  const hasCleanDataAndShowCleanIcon = !dontShowCleanIcon && hasCleanData;
  return (
    <GeneralFormItemV4
      {...formItemLayout}
      label={texts.label || restProps.label}
      initialValue={initialValue}
      // {...getSelectFormItemV4Props}
      validateStatus={hasCleanDataAndShowCleanIcon ? "success" : ""}
      help={
        !hasCleanDataAndShowCleanIcon && !dontSetFirstItem
          ? "Invalid Data Provided"
          : help
      }
      extra={restProps.extra}
      className={`${className} ${labelAlignmentClassName}`}
      hasFeedback={hasCleanDataAndShowCleanIcon && !dontShowError}
      rules={getRules(texts)}
      validateTrigger={
        !validateTrigger ? ["onBlur", "onChange"] : validateTrigger
      }
      tooltip={tooltip}
      name={fieldName}
    >
      {selectComponent}
    </GeneralFormItemV4>
  );
};

export default injectK2Intl(SelectFormItemV4);
