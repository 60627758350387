import React, { FC, ReactText, useEffect, useState } from "react";

import {
  DonationItemCriteria,
  DonationItemCriteriaSortProperty,
  DonationItemRes,
  DonationItemSorts,
  DonationItemStatus,
  DonationItemSummaryRes,
  FundDimensionOptionsCriteria,
  TextMatchingMode,
} from "@n3oltd/k2.donations.sdk.donation-items/esm";
import { FundStructureRevisionRes } from "@n3oltd/k2.subscriptions.sdk.funds/esm";
import {
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from "antd/es/table/interface";

import { _subscriptionFundsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { UIUtils } from "components/utils";
import useBreadcrumb from "hooks/useBreadcrumb";

import DonationItemsListView from "./DonationItemsListView";
import DonationItemListModel from "./Model";
import { DonationItemsListProps } from "./connect";

const DonationItemsList: FC<DonationItemsListProps> = (props) => {
  const {
    fetchDonationItems,
    data,
    fetching: fetchingDonationItems,
    errorFetchingItems,
    clearFetchDonationItemsError,
    updateDonationItemsStatus,
    k2Intl,
  } = props;

  const [fetchingFundDimensions, setFetchingFundDimensions] = useState<boolean>(
    false,
  );
  const [
    fundDimensions,
    setFundDimensions,
  ] = useState<FundStructureRevisionRes>({});
  const [filters, setFilters] = useState<Record<string, ReactText[]>>({
    status: [DonationItemStatus.Active],
  });
  const [sort, setSort] = useState<SorterResult<DonationItemSummaryRes>>(null);
  const listModel = new DonationItemListModel(props);
  const pageSize = listModel.getDefaultGridInfo().pageSize;

  const setBreadcrumbs = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbs([
      {
        path: K2Routes.main.admin,
        titleKey: "mainNav.admin",
        active: false,
      },
      {
        path: K2Routes.donationItems,
        titleKey: "admin.donationItems",
        active: true,
      },
    ]);
  }, [setBreadcrumbs]);

  const getFundDimensions = (): FundDimensionOptionsCriteria => {
    const fd: FundDimensionOptionsCriteria = {};
    if (filters.dimension1) {
      fd.dimension1 = filters.dimension1 as string[];
    }
    if (filters.dimension2) {
      fd.dimension2 = filters.dimension2 as string[];
    }
    if (filters.dimension3) {
      fd.dimension3 = filters.dimension3 as string[];
    }
    if (filters.dimension4) {
      fd.dimension4 = filters.dimension4 as string[];
    }

    return Object.values(fd).length === 0 ? null : fd;
  };

  const getDonationItemCriteria = (fresh: boolean): DonationItemCriteria => {
    const criteria: DonationItemCriteria = {
      name: filters.name
        ? {
            value: (filters.name?.[0] as string) || "",
            mode: TextMatchingMode.Contains,
          }
        : null,
      status: (filters.status as DonationItemStatus[]) || undefined,
      pageSize,
      continuationToken: fresh ? "" : data.continuationToken,
      fundDimensionOptions: getFundDimensions(),
    };
    if (sort?.column && sort?.order) {
      const sortProperty = Object.keys(DonationItemCriteriaSortProperty).find(
        (key) =>
          DonationItemCriteriaSortProperty[key] === sort.column.dataIndex,
      );
      criteria.sort =
        DonationItemSorts[
          `${sortProperty}_${sort.order === "descend" ? "desc" : "asc"}`
        ];
    }
    return criteria;
  };

  const onLoadMoreClicked = () => {
    fetchDonationItems(getDonationItemCriteria(false), true);
  };

  // Initial mount
  useEffect(() => {
    fetchDonationItems(getDonationItemCriteria(true));

    fetchFundDimensions();
    // eslint-disable-next-line
  }, []);

  // When there is an error fetching items
  useEffect(() => {
    if (errorFetchingItems) {
      UIUtils.handleServerError(
        k2Intl,
        errorFetchingItems,
        clearFetchDonationItemsError,
      );
    }
    // eslint-disable-next-line
  }, [errorFetchingItems]);

  const fetchFundDimensions = async () => {
    setFetchingFundDimensions(true);

    const response: IApiResponse<FundStructureRevisionRes> = await K2RestService.toResponse(
      _subscriptionFundsClient.getFundStructure(),
    );

    setFetchingFundDimensions(false);

    if (response.error) {
      UIUtils.handleServerError(k2Intl, response.error);
    } else {
      const fundDimensionRes = response.getResultOrDefault();
      setFundDimensions(fundDimensionRes);
    }
  };

  const handleAction = (
    action: string,
    keys: string[],
    allPagesSelected: boolean,
  ) => {
    switch (action) {
      case "markActive":
        updateDonationItemsStatus({
          status: DonationItemStatus.Active,
          ids: keys,
          all: allPagesSelected,
        });
        break;
      case "markInactive":
        updateDonationItemsStatus({
          status: DonationItemStatus.Inactive,
          ids: keys,
          all: allPagesSelected,
        });
        break;
    }
  };

  const onFilterOrSortChange = (
    _: TablePaginationConfig,
    filters: Record<string, ReactText[]>,
    sorter: SorterResult<DonationItemRes>,
    __: TableCurrentDataSource<DonationItemRes>,
  ): void => {
    setFilters(filters);
    setSort(sorter);
  };

  useEffect(() => {
    fetchDonationItems(getDonationItemCriteria(true));
    // eslint-disable-next-line
  }, [filters, sort]);

  const fullyLoaded = !fetchingFundDimensions && !fetchingDonationItems;

  return (
    <DonationItemsListView
      columns={
        fundDimensions
          ? listModel.getColumns(
              fundDimensions,
              props.donationItemStatuses.items,
              k2Intl,
              filters,
            )
          : []
      }
      dataSource={data}
      isLoading={!fullyLoaded}
      onActionExecuted={handleAction}
      gridActions={listModel.getGridActions()}
      onLoadMore={onLoadMoreClicked}
      onChange={onFilterOrSortChange}
    />
  );
};

export default injectK2Intl(DonationItemsList);
