import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const GivingData: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.32517 6.65584C3.3624 8.67213 3.7521 11.111 5.25601 12.672L5.26074 12.6769L11.4876 19.2186C11.9191 19.672 11.9014 20.3893 11.4481 20.8209C10.9947 21.2524 10.2773 21.2348 9.84576 20.7814L3.62117 14.2421C1.44744 11.9836 0.919025 8.52352 2.28209 5.6742L2.2854 5.66728C4.09247 1.95462 8.46156 0.430965 12.0535 2.38827C12.6973 2.73905 13.2852 3.18855 13.7987 3.72031L14.6665 4.55752L15.5343 3.72018C18.3863 0.768462 22.9861 0.81433 25.7843 3.82326C26.2919 4.36915 26.717 4.99097 27.0463 5.66696L27.0492 5.67299C27.716 7.06255 28.0815 8.55103 27.7818 10.2024C27.67 10.8183 27.0801 11.2269 26.4642 11.1151C25.8484 11.0033 25.4398 10.4134 25.5515 9.79756C25.7414 8.75178 25.5345 7.75688 25.0072 6.65685C24.7746 6.18013 24.4766 5.74552 24.1244 5.36686C22.2065 3.30444 19.1008 3.27905 17.1535 5.30651L17.1385 5.32206L14.6665 7.70722L12.1942 5.32191L12.1791 5.3062C11.8202 4.93219 11.4119 4.61995 10.969 4.37862C8.57852 3.07604 5.60053 4.0401 4.32517 6.65584ZM13.9733 13.7733C13.9733 13.1474 14.4808 12.64 15.1067 12.64H29.3289C29.9548 12.64 30.4622 13.1474 30.4622 13.7733V27.9955C30.4622 28.6215 29.9548 29.1289 29.3289 29.1289H15.1067C14.4808 29.1289 13.9733 28.6215 13.9733 27.9955V13.7733ZM16.24 14.9067V26.8622H28.1956V14.9067H16.24ZM17.5333 18.2267C17.5333 17.6007 18.0407 17.0933 18.6667 17.0933H25.7778C26.4037 17.0933 26.9111 17.6007 26.9111 18.2267C26.9111 18.8526 26.4037 19.36 25.7778 19.36H18.6667C18.0407 19.36 17.5333 18.8526 17.5333 18.2267ZM17.5333 21.7733C17.5333 21.1474 18.0407 20.64 18.6667 20.64H21.3333C21.9592 20.64 22.4667 21.1474 22.4667 21.7733C22.4667 22.3992 21.9592 22.9067 21.3333 22.9067H18.6667C18.0407 22.9067 17.5333 22.3992 17.5333 21.7733Z"
      fill={fill}
    />
  </svg>
);
