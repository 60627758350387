import { Language } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { matchPath } from "react-router";
import { RouteProps, match } from "react-router-dom";

import { IEnvironmentContext } from "common/contexts/EnvironmentProvider/EnvironmentProvider";
import AppHelpers from "common/helpers";
import { initializeDefaultNotification } from "components/utils/Notification";
import apiConfig, { ApiConfig } from "config/apiConfig";

import { AppId, AppName } from "./modules/sharedTypes";

export default class AppManager {
  public static readonly DefaultLocaleKey = "defaultLocalLanguage";
  public static defaultLocale: Language = Language.En;
  public static isProductionEnv: boolean = true;
  public static baseUrls: {};
  private static appId: AppId = "k2";
  private static appName: AppName = "Engage";

  private static constructBaseUrls(apiConfig: ApiConfig, basePath: string) {
    const baseUrls = {};
    Object.entries(apiConfig.endpoints).forEach(([key, value]) => {
      if (value.match(/localhost/)) {
        baseUrls[key] = value;
      } else {
        // e.g. "https://beta.n3o.cloud" + "/eu1/" + "api/accounts"
        baseUrls[key] = `${apiConfig.host}${basePath}${value}`;
      }
    });

    return baseUrls;
  }

  public static initialize(env: IEnvironmentContext) {
    this.defaultLocale = (navigator?.languages?.[1] as Language) || Language.En;

    initializeDefaultNotification();

    const environmentName = env.environment;
    if (environmentName !== "prod") {
      this.isProductionEnv = false;
    }

    this.baseUrls = this.constructBaseUrls(
      apiConfig[environmentName],
      env.basePath,
    );
  }

  public static setAppId(appId: AppId) {
    this.appId = appId;
    this.appName = AppHelpers.renderAppName(appId);
  }

  public static getAppId() {
    return this.appId;
  }

  public static getAppName() {
    return this.appName;
  }

  public static findMatchPath<T extends { [K in keyof T]?: string }>(
    pathname: string,
    props: string | string[] | RouteProps,
    parent?: match<T> | null,
  ) {
    return matchPath<T>(pathname, props, parent);
  }
}
