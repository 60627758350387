import React, { FC, useCallback, useEffect } from "react";

import { LockFilled } from "@ant-design/icons/lib";
import { Typography } from "antd";
import styled from "styled-components";

import { FlatRoutes as Q1FlatRoutes } from "appRedux/models/routes/Q1RouteModel";
import { FlatRoutes as K2FlatRoutes } from "appRedux/models/routes/RouteModel";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Link, K2Tooltip } from "components/k2Widgets";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";
import { IN3OIconType } from "components/n3oIcon/types";
import { DashboardLink } from "components/pageDashboard";

const { Text } = Typography;

interface QuickNavTileProps {
  link: DashboardLink;
  k2Intl: InjectedK2Intl;
}

export const QuickNavTileStyled = styled(K2Link)`
  > div {
    font-size: 12px;
  }

  && {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;

    text-align: center;
    font-size: x-small;
    color: ${({ theme }) => theme.grey_7};
    background: #fafafd;
    border-radius: 4px;

    min-height: 64px;
    max-height: 80px;
    padding: 8px;

    transition: all 200ms ease-out !important;

    user-select: none;
    * {
      color: ${({ theme }) => theme.grey_7};
      font-weight: ${({ theme }) => theme.font_weight_bold};
    }
    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      background: ${({ theme }) => theme.tile_hover_color};
      box-shadow: ${({ theme }) => theme.white_color};
      box-shadow: ${({ theme }) => theme.n3o_card_shadow};
      transition: all 100ms ease-in !important;
    }

    &:active {
      color: ${({ theme }) => theme.white_color} !important;
      background: linear-gradient(
        167.28deg,
        ${({ theme }) => theme.tile_hover_color} 0%,
        ${({ theme }) => theme.primary_4} 86.12%
      );
      transition: all 50ms ease-out !important;

      .anticon {
        color: ${({ theme }) => theme.white_color} !important;

        path {
          fill: ${({ theme }) => theme.white_color} !important;
        }
      }
    }
  }
`;

const K2TooltipWithStyle = styled(K2Tooltip)`
  display: block;
  .n3o-not-allowed,
  .n3o-pointer-events-none {
    display: block;
  }
`;

const QuickNavTile: FC<QuickNavTileProps> = ({ link, k2Intl }) => {
  const { theme, appId } = useAppContext();

  useEffect(() => {
    if (!link.title && !link.titleKey) {
      throw new Error(
        "Link passed to QuickNavTile requires either a title or titleKey",
      );
    }
  }, [link]);

  const renderTile = useCallback(
    (link: DashboardLink) => {
      return (
        <QuickNavTileStyled
          to={
            appId === "k2"
              ? K2FlatRoutes[link.routeKey] || K2FlatRoutes.dashboard
              : Q1FlatRoutes[link.routeKey] || Q1FlatRoutes.q1Dashboard
          }
          disabled={!link.havePermission}
        >
          {link.havePermission ? (
            <N3oIcon
              icontype={link.icon as IN3OIconType}
              width={link.width ?? 21}
              height={21}
              fill={theme.grey_7}
            />
          ) : (
            <LockFilled style={{ fontSize: 21, color: theme.grey_7 }} />
          )}
          <Text ellipsis style={{ width: "100%" }} >
            {link.title || k2Intl?.formatMessage({ localeKey: link.titleKey })}
          </Text>
        </QuickNavTileStyled>
      );
    },
    [k2Intl, theme.grey_7, appId],
  );

  return link.havePermission ? (
    renderTile(link)
  ) : (
    <K2TooltipWithStyle titleKey={"common.generalPermission"}>
      {renderTile(link)}
    </K2TooltipWithStyle>
  );
};

export default QuickNavTile;
