import { Form, Transfer } from "antd";
import { FormInstance, Rule } from "antd/es/form";
import React from "react";
import { errorKeys } from "appRedux/models/common/Constants";
import {
  InjectedK2Intl,
  InjectedK2IntlProps,
} from "../k2Widgets/k2Localizations/types";
import "./TransferFormItem.less";
import { RenderResult, TransferItem, TransferProps } from "antd/es/transfer";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";

export interface ITransferItemProps extends TransferProps<any> {
  /**antd form form parent component props */
  form: FormInstance<any>;
  /**name of the field for data binding */
  fieldName: string;
  /**data source, {idField: string, nameField: string}[] */
  dataSource: TransferItem[];
  /**locale key for label */
  labelKey?: string;
  /**Initial values for the component */
  initialValues?: string[];
  /**required */
  required?: boolean;
  /**custom render function for each item */
  render?: (item: TransferItem) => RenderResult;
  /**width on lists */
  listWidth?: number | string;
  /**Form.Item layout object */
  formItemLayout?: { wrapperCol: Object; labelCol?: Object };
  /**React.ReactNode for label */
  label?: React.ReactNode;
  /**Whether to display colon after label */
  colon?: boolean;
}

const getLocale = (k2Intl: InjectedK2Intl) => {
  return {
    itemUnit: k2Intl?.formatMessage({ localeKey: "common.selected" }),
    itemsUnit: k2Intl?.formatMessage({ localeKey: "common.available" }),
    notFoundContent: k2Intl?.formatMessage({ localeKey: "common.notFound" }),
    searchPlaceholder: k2Intl?.formatMessage({ localeKey: "common.search" }),
  };
};

const TransferFormItem = ({
  form,
  dataSource,
  labelKey,
  fieldName,
  initialValues,
  required,
  render,
  listWidth,
  k2Intl,
  formItemLayout,
  colon,
  ...transferProps
}: ITransferItemProps & InjectedK2IntlProps) => {
  if (!form || !dataSource || !fieldName) {
    throw new Error(
      "'form', 'dataSource' and 'fieldName' are required for the component to render!",
    );
  }

  const filterOption = (inputValue, option) =>
    option.title.toLowerCase().indexOf(inputValue) > -1;

  let locale = getLocale(k2Intl);
  let label: string = k2Intl?.formatMessage({ localeKey: labelKey });

  let validationRules: Rule[] = [];
  if (required) {
    validationRules.push({
      required: true,
      message: k2Intl?.formatMessage({
        localeKey: errorKeys.requiredValidationError,
        values: { fieldName: label || fieldName },
      }),
      type: "array",
    });
  }

  if (!render) {
    render = (item) => item.title;
  }

  if (!listWidth) {
    listWidth = 200;
  }

  if (!initialValues) {
    initialValues = [];
  }

  const onChange = (targetKeys) => {
    let obj = {};
    obj[fieldName] = targetKeys;
    form?.setFieldsValue(obj);
  };

  return (
    <Form.Item
      {...formItemLayout}
      label={transferProps.label || label}
      colon={colon}
      name={fieldName}
      initialValue={initialValues}
      valuePropName={"targetKeys"}
      rules={validationRules}
    >
      <Transfer
        className="k2-transfer"
        listStyle={{ width: listWidth }}
        dataSource={dataSource}
        showSearch
        filterOption={filterOption}
        onChange={onChange}
        render={render}
        locale={locale}
        {...transferProps}
      />
    </Form.Item>
  );
};

export default injectK2Intl(TransferFormItem);
