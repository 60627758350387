import React, { FC, useState } from "react";

import { Drawer, Tabs } from "antd";
import styled from "styled-components";

import {
  CallDispositionsLookups,
  CallEndedReasonsLookups,
  TimezoneLookups,
} from "appRedux/modules/lookups/types";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { CustomScrollbars } from "components/utils";

import Appointments from "./Appointments";
import RecentCalls from "./RecentCalls";

const CallsListDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding-top: 10px;
    overflow: hidden;
  }

  .ant-tabs.ant-tabs-top {
    overflow: hidden;
  }
`;

enum CallListTabs {
  CALL = "call",
  APPOINTMENT = "Appointment",
}

interface CallListProps extends InjectedK2IntlProps {
  agentId: string;
  callEndedReasons?: CallEndedReasonsLookups;
  callDispositions?: CallDispositionsLookups;
  openCallList: boolean;
  timezones?: TimezoneLookups;
  setOpenCallList: (value: boolean) => void;
}
const CallList: FC<CallListProps> = ({
  agentId,
  k2Intl,
  callEndedReasons,
  callDispositions,
  openCallList,
  timezones,
  setOpenCallList,
}) => {
  const [tab, setTab] = useState<string>(CallListTabs.CALL);
  return (
    <CallsListDrawer
      placement="right"
      onClose={() => setOpenCallList(false)}
      visible={openCallList}
      width={400}
    >
      <Tabs activeKey={tab} onChange={(tab) => setTab(tab)}>
        <Tabs.TabPane
          tab={k2Intl.formatMessage({
            localeKey: "communication.cc.recentCalls",
          })}
          key={CallListTabs.CALL}
        >
          <CustomScrollbars
            autoHeightMax={"90vh"}
            autoHeight={true}
            autoHide={true}
          >
            <RecentCalls
              k2Intl={k2Intl}
              callDispositions={callDispositions}
              callEndedReasons={callEndedReasons}
              openCallList={openCallList}
            />
          </CustomScrollbars>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={k2Intl.formatMessage({
            localeKey: "communication.cc.calls.appointments",
          })}
          key={CallListTabs.APPOINTMENT}
        >
          <CustomScrollbars
            autoHeightMax={"90vh"}
            autoHeight={true}
            autoHide={false}
          >
            <Appointments
              agentId={agentId}
              k2Intl={k2Intl}
              openCallList={openCallList}
              timezones={timezones}
            />
          </CustomScrollbars>
        </Tabs.TabPane>
      </Tabs>
    </CallsListDrawer>
  );
};

export default injectK2Intl(CallList);
