import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const DueDate: FC<IN3oIconProps> = ({
  height = "1em",
  width = "1em",
  fill = "#000",
  style,
}) => (
  <span style={style}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.625 4.3125H16.6875V2.8125C16.6875 2.70937 16.6031 2.625 16.5 2.625H15.1875C15.0844 2.625 15 2.70937 15 2.8125V4.3125H9V2.8125C9 2.70937 8.91563 2.625 8.8125 2.625H7.5C7.39687 2.625 7.3125 2.70937 7.3125 2.8125V4.3125H3.375C2.96016 4.3125 2.625 4.64766 2.625 5.0625V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H8.6C8.82091 21.375 9 21.1959 9 20.975V20.0875C9 19.8666 8.82091 19.6875 8.6 19.6875H4.3125V9.84254H19.6875V11.1C19.6875 11.3209 19.8666 11.5 20.0875 11.5H20.975C21.1959 11.5 21.375 11.3209 21.375 11.1V5.0625C21.375 4.64766 21.0398 4.3125 20.625 4.3125ZM4.3125 6V8.24879H19.6875V6H16.6875V7.125C16.6875 7.22813 16.6031 7.3125 16.5 7.3125H15.1875C15.0844 7.3125 15 7.22813 15 7.125V6H9V7.125C9 7.22813 8.91563 7.3125 8.8125 7.3125H7.5C7.39687 7.3125 7.3125 7.22813 7.3125 7.125V6H4.3125Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 22.3C18.3748 22.3 20.3 20.3748 20.3 18C20.3 15.6252 18.3748 13.7 16 13.7C13.6252 13.7 11.7 15.6252 11.7 18C11.7 20.3748 13.6252 22.3 16 22.3ZM16 24C19.3137 24 22 21.3137 22 18C22 14.6863 19.3137 12 16 12C12.6863 12 10 14.6863 10 18C10 21.3137 12.6863 24 16 24Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.73 19.86C17.5975 20.0367 17.3467 20.0725 17.17 19.94L15.15 18.425V15.4C15.15 15.1791 15.3291 15 15.55 15H16.45C16.6709 15 16.85 15.1791 16.85 15.4V17.575L18.19 18.58C18.3667 18.7125 18.4025 18.9633 18.27 19.14L17.73 19.86Z"
        fill={fill}
      />
    </svg>
  </span>
);
