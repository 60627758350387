import React, { FC } from "react";

import { IN3oIconProps } from "../types";

// Cannot choose colours for JCB brand logo
export const JCBCard: FC<IN3oIconProps> = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 160 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M160 0H0V100H160V0Z" fill="#034693" />
      <path
        d="M24 100C37.5 28.5 52.5 0 160 0V100H24Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M129.006 72.2644C129.006 80.5931 122.18 87.3462 113.76 87.3462H30.9932V27.7357C30.9932 19.4064 37.8193 12.6538 46.2407 12.6538H129.006V72.2644Z"
        fill="white"
      />
      <path
        d="M102.021 56.9917H108.325C108.523 56.9917 108.898 56.9608 109.077 56.9217C110.287 56.6627 111.294 55.5996 111.294 54.1526C111.294 52.7044 110.287 51.6387 109.077 51.3804C108.899 51.3419 108.523 51.3104 108.325 51.3104H102.021V56.9917Z"
        fill="#00A551"
      />
      <path
        d="M107.588 17.6807C101.574 17.6807 96.6975 22.5042 96.6975 28.4527V39.6419H112.108C112.465 39.6419 112.902 39.6664 113.174 39.6803C116.661 39.8536 119.233 41.6257 119.233 44.7011C119.233 47.1192 117.476 49.1843 114.276 49.6494V49.7711C117.811 50.0137 120.516 51.9371 120.516 54.9689C120.516 58.2479 117.546 60.3565 113.588 60.3565H96.6975V82.3175H112.671C118.685 82.3175 123.561 77.4944 123.561 71.545V17.6807H107.588Z"
        fill="#00A551"
      />
      <path
        d="M110.548 45.4812C110.548 44.0582 109.54 43.1041 108.355 42.9056C108.244 42.8866 107.943 42.8582 107.735 42.8582H102.021V48.1034H107.735C107.943 48.1034 108.245 48.0738 108.355 48.0555C109.54 47.8564 110.548 46.9035 110.548 45.4812Z"
        fill="#00A551"
      />
      <path
        d="M47.3291 17.6807C41.3139 17.6807 36.4384 22.5042 36.4384 28.4527V55.0571C39.4925 56.5211 42.6479 57.4746 45.8594 57.4746C49.6244 57.4746 51.693 55.2014 51.693 52.1412V39.6431H61.0592V52.1419C61.0592 57.0303 58.0255 60.9514 47.6157 60.9514C41.3508 60.9514 36.4377 59.6147 36.4377 59.6147V82.3181H52.4116C58.4262 82.3181 63.3026 77.495 63.3026 71.5456V17.6807H47.3291Z"
        fill="#0071BB"
      />
      <path
        d="M77.4586 17.6807C71.4442 17.6807 66.5686 22.5042 66.5686 28.4527V42.5565C69.3274 40.2153 74.0992 38.752 81.8517 39.0974C86.0017 39.2801 90.4023 40.3811 90.4023 40.3811V44.9424C88.1998 43.8384 85.5586 42.8156 82.1555 42.5603C76.2724 42.1204 72.7317 44.9651 72.7317 49.9998C72.7317 55.0332 76.2724 57.8773 82.1555 57.4355C85.5586 57.1828 88.2355 56.1329 90.4023 55.0565V59.6154C90.4023 59.6154 86.0017 60.7157 81.8517 60.9022C74.0992 61.2469 69.3274 59.7842 66.5686 57.4431V82.3175H82.5411C88.5548 82.3175 93.4311 77.4944 93.4311 71.545V17.6807H77.4586Z"
        fill="#EC1C24"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(160.372 100.25) scale(136.052 100.037)"
      >
        <stop offset="0.9" />
        <stop offset="1" />
      </radialGradient>
      <clipPath id="clip0">
        <rect width="160" height="100" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
