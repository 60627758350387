import React from "react";

import { Redirect, Route, Switch } from "react-router";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import { asyncComponent } from "components/utils";
import { RequireAuth } from "containers/app/views";

const {
  attributionDimensions,
  analyticsReportingPeriods,
  analyticsReports,
  touchpoints,
  fundraisers,
  campaigns,
  createCampaign,
  updateCampaign,
} = K2Routes;

const attributionDimensionsAdmin = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "AttributionDimensionsAdmin" */ "./attributionDimensions"
      ),
  ),
  "attributionDimensions",
);

const AnalyticsReportingPeriods = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "AnalyticsReportingPeriodsAdmin" */ "./reportingPeriods"
      ),
  ),
  "analyticsReportingPeriods",
);

const AnalyticsReports = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "AnalyticsReportsAdmin" */ "./reports"),
  ),
  "analyticsReports",
);

const Touchpoints = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "TouchpointsAdmin" */ "./touchpoints"),
  ),
  "touchpoints",
);

const Campaigns = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "CampaignsAdmin" */ "./campaigns"),
  ),
  "campaigns",
);

const CreateCampaign = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "CreateCampaignAdmin" */ "./createEditCampaign"
      ),
  ),
  "createCampaign",
);

const UpdateCampaign = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "UpdateCampaignAdmin" */ "./createEditCampaign"
      ),
  ),
  "updateCampaign",
);

const Fundraisers = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "fundraisersAdmin" */ "./fundraisers"),
  ),
  "fundraisers",
);

const AnalyticsSubRouter = () => (
  <Switch>
    <Route
      exact
      path={attributionDimensions}
      component={attributionDimensionsAdmin}
    />
    <Route
      exact
      path={analyticsReportingPeriods}
      component={AnalyticsReportingPeriods}
    />

    <Route exact path={analyticsReports} component={AnalyticsReports} />
    <Route exact path={touchpoints} component={Touchpoints} />
    <Route exact path={fundraisers} component={Fundraisers} />
    <Route exact path={campaigns} component={Campaigns} />
    <Route exact path={createCampaign} component={CreateCampaign} />
    <Route exact path={updateCampaign} component={UpdateCampaign} />

    <Redirect to={attributionDimensions} />
  </Switch>
);

export default AnalyticsSubRouter;
