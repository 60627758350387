import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const DataExport: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.125 7.3125V6.1875C16.125 6.08437 16.0406 6 15.9375 6H6.9375C6.83437 6 6.75 6.08437 6.75 6.1875V7.3125C6.75 7.41563 6.83437 7.5 6.9375 7.5H15.9375C16.0406 7.5 16.125 7.41563 16.125 7.3125ZM6.9375 9.375C6.83437 9.375 6.75 9.45937 6.75 9.5625V10.6875C6.75 10.7906 6.83437 10.875 6.9375 10.875H11.25C11.3531 10.875 11.4375 10.7906 11.4375 10.6875V9.5625C11.4375 9.45937 11.3531 9.375 11.25 9.375H6.9375ZM10.3125 19.9688H4.875V3.46875H18V11.5312C18 11.6344 18.0844 11.7188 18.1875 11.7188H19.5C19.6031 11.7188 19.6875 11.6344 19.6875 11.5312V2.53125C19.6875 2.11641 19.3523 1.78125 18.9375 1.78125H3.9375C3.52266 1.78125 3.1875 2.11641 3.1875 2.53125V20.9062C3.1875 21.3211 3.52266 21.6562 3.9375 21.6562H10.3125C10.4156 21.6562 10.5 21.5719 10.5 21.4688V20.1562C10.5 20.0531 10.4156 19.9688 10.3125 19.9688Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 16.4V13.4C17 13.1791 17.1791 13 17.4 13H18.3C18.5209 13 18.7 13.1791 18.7 13.4V16.4H21.0319C21.2167 16.4 21.3026 16.6291 21.1634 16.7507L17.9815 19.5287C17.9062 19.5945 17.7938 19.5945 17.7185 19.5287L14.5365 16.7507C14.3973 16.6291 14.4833 16.4 14.6681 16.4H17ZM14.6 21.3C14.3791 21.3 14.2 21.1209 14.2 20.9V19.55C14.2 19.3291 14.0209 19.15 13.8 19.15H12.9C12.6791 19.15 12.5 19.3291 12.5 19.55V22.6C12.5 22.8209 12.6791 23 12.9 23L22.6 23C22.8209 23 23 22.821 23 22.6V19.55C23 19.3291 22.8209 19.15 22.6 19.15H21.7C21.4791 19.15 21.3 19.3291 21.3 19.55V20.9C21.3 21.121 21.1209 21.3 20.9 21.3L14.6 21.3Z"
      fill={fill}
    />
  </svg>
);
