import React, { FC, useEffect } from "react";

import { ReactCookieProps, useCookies } from "react-cookie";

import AppManager from "appRedux/AppManager";
import AuthManager from "appRedux/models/auth/AuthManager";
import FullPageSpin from "components/fullPageSpin";

const Logout: FC<void> = () => {
  const [_, __, removeCookie] = useCookies();

  useEffect(() => {
    const options = AuthManager.getCookieSetOptions();

    removeCookie(AuthManager.keys.cookies.subscriptionId, options);
    removeCookie(AppManager.DefaultLocaleKey, options);

    AuthManager.logout();
  }, [removeCookie]);

  return <FullPageSpin tipKey={"common.loggingOut"} />;
};

export default Logout;
