import produce from "immer";
import * as actionTypes from "./actionTypes";

import { GettingStartedState } from "./types";

const initialState: GettingStartedState = {
  fetching: true,
  currentStep: null,
  totalSteps: 0,
  completedSteps: 0,
  image: null,
  isAllCompleted: false,
};

const gettingStartedReducer = (
  state: GettingStartedState = initialState,
  action,
): GettingStartedState =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.FETCH_GETTING_STARTED_PROGRESS:
        draft.fetching = true;
        break;

      case actionTypes.FETCH_GETTING_STARTED_PROGRESS_SUCCESS:
        draft.currentStep = action.currentStep;
        draft.totalSteps = action.totalSteps;
        draft.completedSteps = action.completedSteps;
        draft.image = action.image;
        draft.fetching = false;
        draft.isAllCompleted = action.isAllCompleted;
        break;
    }
  });

export default gettingStartedReducer;
