import React, { FC } from "react";

import { CloseCircleTwoTone } from "@ant-design/icons";
import { SubscriptionStatus } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { Result, Typography } from "antd";
import { ResultProps, ResultStatusType } from "antd/lib/result";
import { useRouter } from "hooks";
import { StatusCodes } from "http-status-codes";
import _ from "lodash";
import styled from "styled-components";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import { NamedLookup } from "appRedux/modules/lookups/types";
import variables from "common/themeVariables";
import { K2Button, K2Message } from "components/k2Widgets";
import { CustomScrollbars } from "components/utils";

const { Paragraph, Text } = Typography;

export type K2ErrorKey =
  | StatusCodes
  | SubscriptionStatus
  | "no-subscription"
  | "unresponsive";

const ErrorDivWrapper = styled.div`
  max-width: 768px;
  margin: 0 auto;
  background-color: #fafafa;
  padding: 25px;
`;

interface IProps extends ResultProps {
  errorKey?: K2ErrorKey;
  errorCode: StatusCodes;
  serverError?: ServerError;
  onClick?: () => void;
  subscriptionStatuses: NamedLookup[];
}

const ErrorPage: FC<IProps> = ({
  errorKey,
  errorCode,
  serverError,
  onClick,
  subscriptionStatuses,
}) => {
  const router = useRouter();

  // Because Antd only allows statuses of 404 | 403 | 500
  let status: ResultStatusType;
  if (
    errorCode === StatusCodes.FORBIDDEN ||
    errorCode === StatusCodes.INTERNAL_SERVER_ERROR ||
    errorCode === StatusCodes.NOT_FOUND
  ) {
    status = errorCode;
  } else if (errorCode === StatusCodes.UNAUTHORIZED) {
    status = StatusCodes.FORBIDDEN;
  } else {
    status = "error";
  }

  const subscriptionStatusesLoaded = subscriptionStatuses.length > 0;

  const errorIsDueToSubscriptionStatus =
    isNaN(Number(errorKey)) &&
    errorKey !== "no-subscription" &&
    errorKey !== "unresponsive";

  const subscriptionStatusesById = subscriptionStatuses.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  // 403 statuses will automatically be redirected to Access Revoked page in a second
  if (errorCode === StatusCodes.FORBIDDEN) {
    return null;
  } else {
    return (
      <Result
        style={{ textAlign: "center" }}
        status={status}
        title={
          errorIsDueToSubscriptionStatus ? (
            <K2Message
              localeKey={`extraPages.subscriptionIs`}
              values={{
                state: subscriptionStatusesLoaded
                  ? subscriptionStatusesById[errorKey].name
                  : "",
              }}
            />
          ) : errorKey === "unresponsive" || errorKey === "no-subscription" ? (
            <K2Message localeKey={`extraPages.${errorKey}.title`} />
          ) : (
            errorKey
          )
        }
        subTitle={
          <K2Message
            localeKey={`extraPages.${
              errorIsDueToSubscriptionStatus
                ? "otherStatus"
                : !!errorKey
                ? errorKey
                : "401"
            }Msg`}
          />
        }
        extra={
          errorIsDueToSubscriptionStatus || errorKey === "no-subscription" ? (
            <K2Button
              className="ant-btn ant-btn-primary"
              onClick={() => router.history.push(K2Routes.logout)}
            >
              <K2Message localeKey="userPicker.Logout" />
            </K2Button>
          ) : (
            <K2Button className="ant-btn ant-btn-primary" onClick={onClick}>
              <K2Message localeKey="extraPages.goHome" />
            </K2Button>
          )
        }
      >
        {!_.isEmpty(serverError) ? (
          <ErrorDivWrapper className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                <K2Message localeKey={"extraPages.errorTitle"} />
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleTwoTone twoToneColor={variables.error_color} />
              {serverError?.data?.title}
            </Paragraph>
            <CustomScrollbars autoHeight={true} autoHeightMax={300}>
              {serverError?.data?.detail}
            </CustomScrollbars>
          </ErrorDivWrapper>
        ) : (
          <></>
        )}
      </Result>
    );
  }
};

export default ErrorPage;
