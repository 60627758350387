import { useState } from "react";

import { SchemeOptionsRes } from "@n3oltd/karakoram.taxrelief.sdk.data-entry/esm";

import { _taxReliefDataEntryClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";

function useTaxReliefSchemeOptions(): [SchemeOptionsRes, boolean] {
  const [data, setData] = useState<SchemeOptionsRes>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function load() {
    setLoading(true);
    try {
      const response: IApiResponse<SchemeOptionsRes> = await K2RestService.toResponse(
        _taxReliefDataEntryClient.getTaxReliefSchemeOptions(),
      );
      if (!response.error) {
        setData(response.getResultOrDefault());
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (!data && !loading) {
    load();
  }

  return [data, loading];
}

export default useTaxReliefSchemeOptions;
