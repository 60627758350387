import React, { FC, useState } from "react";

import { PaperClipOutlined } from "@ant-design/icons/lib";
import {
  SecurityPrincipalReq,
  TaskSummaryRes,
} from "@n3oltd/karakoram.tasks.sdk.tasks/esm";
import { Badge, Col, Row, Tooltip } from "antd";
import { useWindowWidth } from "hooks";
import styled from "styled-components";

import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import SecurityPrincipalAvatar from "components/avatars/securityPrincipalAvatar";
import EditTask from "components/dashboard/editTask/editTask";
import TaskItemOptions from "components/dashboard/tasksList/components/itemOptions";
import TaskDescription from "components/dashboard/tasksList/components/taskDescription";
import DateBadge from "components/dateBadge";
import K2Message from "components/k2Widgets/k2Localizations/K2Message";
import { N3oIcon } from "components/n3oIcon";
import { MOBILE_SIZE } from "constants/ThemeSetting";

interface StyledIcons {
  isMobile: boolean;
}

export const TaskRow = styled(Row)<{ $isAutoGenerated?: boolean }>`
  && {
    padding: 7px 5px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: ${({ theme, $isAutoGenerated }) =>
      $isAutoGenerated ? theme.grey_3 : "none"};
  }
`;

const DescriptionCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  && {
    padding: 11px 0;
  }
`;

const DatePriorityArea = styled.div`
  && {
    min-width: 125px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const IconsCol = styled(Col)`
  && {
    display: flex;
    align-items: center;
    text-align: right;
    padding-right: 0;
    justify-content: flex-end;
  }
`;

const AttachmentSection = styled.span<StyledIcons>`
  margin: ${({ isMobile }) =>
    isMobile ? "10px 10px 10px 0" : "10px 20px 10px 10px"};
  width: 20px;
  display: inline-block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NotesSection = styled.span<StyledIcons>`
  margin: ${({ isMobile }) =>
    isMobile ? "10px 10px 10px 0" : "10px 20px 10px 10px"};
  width: 20px;
  display: inline-block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const AssigneesSection = styled.span<StyledIcons>`
  display: inline-block;
  width: 40px;
  margin: ${({ isMobile }) => (isMobile ? "0 10px 0 0" : "0 20px 0 10px")};
  @media (max-width: 768px) {
    display: none;
  }
`;

const OptionsSection = styled.span<StyledIcons>`
  margin: ${({ isMobile }) => (isMobile ? "0" : "10px")};
  display: inline-block;
  position: relative;
`;

interface IProps {
  task: TaskSummaryRes;
  isOverdue: boolean;
  searchValue?: string;
  showEditOptions?: boolean;
  onCancelEdit?: () => void;
  isAutoGenerated?: boolean;
}

const TaskListItem: FC<IProps> = (props: IProps) => {
  const {
    task,
    isOverdue,
    onCancelEdit,
    showEditOptions,
    isAutoGenerated,
  } = props;

  const isHighPriority = task.priority === "1-high";
  const hasNotes = task.hasNotes;
  const hasAttachments = task.hasFiles;

  const avatarAssignee: SecurityPrincipalReq | null = task.firstAssignee
    ? task.firstAssignee
    : task.additionalAssignees && task.additionalAssignees.length
    ? task.additionalAssignees[0]
    : null;

  const [editing, setEditing] = useState<boolean>(false);
  const [defaultEditPosition, setDefaultEditPosition] = useState<
    "notes" | null
  >(null);

  const { theme } = useAppContext();
  const width = useWindowWidth();
  const isMobile = width <= MOBILE_SIZE;

  return (
    <>
      {editing && (
        <EditTask
          defaultPosition={defaultEditPosition}
          onCancel={() => {
            onCancelEdit?.();
            setEditing(false);
          }}
          taskId={task.id}
        />
      )}
      <TaskRow
        align="middle"
        gutter={[0, 0]}
        $isAutoGenerated={isAutoGenerated}
      >
        <DescriptionCol xs={20} sm={20} md={16}>
          <DatePriorityArea>
            <span>
              {task.dueDate && (
                <DateBadge overdue={isOverdue} timestamp={task.dueDate} />
              )}
            </span>
            {isHighPriority && (
              <Tooltip title={<K2Message localeKey="tasks.priority.high" />}>
                <Badge color="red" style={{ height: "19px" }} />
              </Tooltip>
            )}
          </DatePriorityArea>
          <div>
            <TaskDescription
              title={task.title}
              searchValue={props.searchValue}
              onClick={() => setEditing(true)}
            />
          </div>
        </DescriptionCol>
        <IconsCol xs={4} sm={4} md={8}>
          <AttachmentSection isMobile={isMobile}>
            {hasAttachments && (
              <Tooltip
                title={<K2Message localeKey="tasks.hasAttachmentsTooltip" />}
              >
                <PaperClipOutlined style={{ color: theme.icon_grey_light }} />
              </Tooltip>
            )}
          </AttachmentSection>
          <NotesSection isMobile={isMobile}>
            {hasNotes && (
              <Tooltip title={<K2Message localeKey="tasks.hasNotesTooltip" />}>
                <N3oIcon
                  icontype="n3o-task-note"
                  width="16px"
                  height="16px"
                  fill={theme.icon_grey_light}
                />
              </Tooltip>
            )}
          </NotesSection>
          <AssigneesSection isMobile={isMobile}>
            {avatarAssignee && (
              <SecurityPrincipalAvatar
                presetId="small"
                size="default"
                count={
                  task.additionalAssignees ? task.additionalAssignees.length : 0
                }
                profile={avatarAssignee}
              />
            )}
          </AssigneesSection>
          {showEditOptions && (
            <OptionsSection isMobile={isMobile}>
              <TaskItemOptions
                task={task}
                onAddNotesClick={() => {
                  setDefaultEditPosition("notes");
                  setEditing(true);
                }}
              />
            </OptionsSection>
          )}
        </IconsCol>
      </TaskRow>
    </>
  );
};

export default TaskListItem;
