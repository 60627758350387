import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const SendStatement: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_211_446)">
      <path
        d="M16.125 7.17188V6.04688C16.125 5.94375 16.0406 5.85938 15.9375 5.85938H6.9375C6.83437 5.85938 6.75 5.94375 6.75 6.04688V7.17188C6.75 7.275 6.83437 7.35938 6.9375 7.35938H15.9375C16.0406 7.35938 16.125 7.275 16.125 7.17188ZM6.9375 9.23438C6.83437 9.23438 6.75 9.31875 6.75 9.42188V10.5469C6.75 10.65 6.83437 10.7344 6.9375 10.7344H11.25C11.3531 10.7344 11.4375 10.65 11.4375 10.5469V9.42188C11.4375 9.31875 11.3531 9.23438 11.25 9.23438H6.9375ZM11.25 19.9688H4.875V3.46875H18V10.4062C18 10.5094 18.0844 10.5938 18.1875 10.5938H19.5C19.6031 10.5938 19.6875 10.5094 19.6875 10.4062V2.53125C19.6875 2.11641 19.3523 1.78125 18.9375 1.78125H3.9375C3.52266 1.78125 3.1875 2.11641 3.1875 2.53125V20.9062C3.1875 21.3211 3.52266 21.6562 3.9375 21.6562H11.25C11.3531 21.6562 11.4375 21.5719 11.4375 21.4688V20.1562C11.4375 20.0531 11.3531 19.9688 11.25 19.9688Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.893 13.1711C10.066 13.0059 10.2964 12.8999 10.5485 12.8848L21.5721 12.2358C22.1442 12.2044 22.628 12.6393 22.6676 13.2033L22.6687 13.2187L22.6692 13.2341C22.6757 13.4281 22.6289 13.6495 22.4952 13.8493L16.407 23.0473C16.0939 23.5114 15.4581 23.6541 14.9747 23.3363C14.7581 23.1949 14.6138 22.9844 14.548 22.7598L13.351 18.6498C13.2878 18.4386 13.2959 18.2233 13.3605 18.0292C13.1545 17.9871 12.965 17.8828 12.8187 17.7302L9.86029 14.6399C9.46136 14.2227 9.48169 13.5666 9.89079 13.1732L9.893 13.1711ZM12.0776 14.4977L13.7961 16.2928L17.6795 15.5343L15.083 18.5169L15.7809 20.9133L20.3497 14.0107L12.0776 14.4977Z"
        fill={fill}
      />
    </g>

    <defs>
      <clipPath id="clip0_211_446">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
