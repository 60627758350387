// These are the required entities that may be searched at the moment.
import React from "react";

import { CheckSquareOutlined, FileOutlined } from "@ant-design/icons/lib";

import { N3oIcon } from "components/n3oIcon";
import { SearchOptions } from "components/navigation/mainAppHeader/globalSearch/modules/types";

export const searchOptions: SearchOptions = {
  accounts: {
    titleLocaleKey: "entities.accounts",
    apiRoot: "accounts",
    icon: (
      <N3oIcon
        width={16}
        height={16}
        icontype={"n3o-account-search"}
        fill={"currentColor"}
      />
    ),
  },
  donations: {
    titleLocaleKey: "common.entity.donation.plural",
    apiRoot: "donations",
    icon: (
      <N3oIcon
        width={16}
        height={16}
        icontype={"n3o-donation-search"}
        fill={"currentColor"}
      />
    ),
  },
  feedbacks: {
    titleLocaleKey: "entities.feedbacks",
    apiRoot: "feedbacks",
    icon: (
      <N3oIcon
        width={16}
        height={16}
        icontype={"n3o-feedbacks-search"}
        fill={"currentColor"}
      />
    ),
  },
  givings: {
    titleLocaleKey: "common.entity.giving.plural",
    apiRoot: "givings",
    icon: (
      <N3oIcon
        width={16}
        height={16}
        icontype={"n3o-giving-search"}
        fill={"currentColor"}
      />
    ),
  },
  navigation: {
    titleLocaleKey: "entities.pages",
    apiRoot: "navigation",
    icon: <FileOutlined />,
  },
  pledges: {
    titleLocaleKey: "common.entity.pledge.plural",
    apiRoot: "pledges",
    icon: (
      <N3oIcon
        width={16}
        height={16}
        icontype={"n3o-pledge-search"}
        fill={"currentColor"}
      />
    ),
  },
  sponsorships: {
    titleLocaleKey: "entities.sponsorships",
    apiRoot: "sponsorships",
    icon: (
      <N3oIcon
        width={16}
        height={16}
        icontype={"n3o-sponsorship-search"}
        fill={"currentColor"}
      />
    ),
  },
  tasks: {
    titleLocaleKey: "entities.tasks",
    apiRoot: "tasks",
    icon: <CheckSquareOutlined />,
  },
};
