import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { FC } from "react";
import styled from "styled-components";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { Attachment } from "appRedux/modules/tasks/types";
import { K2Message, K2Dragger } from "components/k2Widgets";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import MultiFileUploadExplanation from "../../forms/uploading/multiFileUploadExplanation";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons/lib";

const AddFilesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  margin: -32px 0px 0 0px;
  background-color: ${({ theme }) => theme.grey_2};
  z-index: 99;
  padding: 8px 8px 0 0;
  border: 1px solid ${({ theme }) => theme.grey_5};
  border-radius: 0 0 6px 6px;
  border-top: 0;

  & .file-count {
    font-size: smaller;
    color: ${({ theme }) => theme.primaryColor};
    margin-right: 8px;
  }
`;

const AttachmentsList = styled.div`
  margin-top: 20px;
  && {
    a[title="Download file"] {
      display: none;
    }
  }
`;

const AddFileMessage = styled.span`
  margin-right: 8px;
`;

const AttachmentDetails = styled.div`
  cursor: pointer;
  display: flex;
`;

const Container = styled.div`
  && {
    .n3o-markup-editor-area.ant-input {
      padding: 16px;
      min-height: 234px;
      margin-bottom: 0px;
    }

    .n3o-notes-toolbar {
      background-color: ${({ theme }) => theme.grey_2};
      border: 0px;
    }

    .n3o-task-details-wrapper {
      border: 1px solid ${({ theme }) => theme.grey_5};
      border-radius: 6px 6px 0 0;
      display: flex;
      flex-direction: column-reverse;
    }

    .n3o-task-details-editor {
      padding: 0 16px;
      min-height: 200px;
    }

    .rdw-option-wrapper {
      border: 0;
      color: @grey-8;

      min-width: 25px;
      height: 20px;
      border-radius: 6px;

      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      cursor: pointer;
      background: none;
      text-transform: capitalize;
    }

    .rdw-option-active {
      background-color: @primary-4;
      border-radius: 4px;
      box-shadow: none;
      transition: ease-in 0.1s;
    }

    .rdw-option-wrapper:active {
      background-color: @primary-5;
      box-shadow: none;
      transition: ease-in 0.2s;
    }

    .rdw-option-wrapper:hover {
      background-color: @grey-4;
      border-radius: 4px;
      box-shadow: none;
      transition: ease-in 0.2s;
    }

    .rdw-editor-toolbar {
      padding: 6px 5px 0;
      border-radius: 0px 0px 5px 5px;
      border-top: 1px solid #f1f1f1;
      margin-bottom: 0px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: start;
      justify-content: flex-start;
      background: @grey-2;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
`;

interface IMarkupEditorPreviewerProps {
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
  handleUpload: (options: RcCustomRequestOptions) => void;
  deleteAttachment: (file: Attachment) => boolean;
  onToggleAttachments: () => void;
  attachments: Attachment[];
  showAttachments?: boolean;
}

const editorToolbarOptions = {
  options: ["inline", "list"],
  inline: {
    options: ["bold", "italic", "underline"],
  },
  list: { options: ["unordered", "ordered"] },
};

const MarkupEditorPreviewer: FC<IMarkupEditorPreviewerProps> = (
  props: IMarkupEditorPreviewerProps,
) => {
  const {
    onToggleAttachments,
    editorState,
    onEditorStateChange,
    showAttachments,
    handleUpload,
    deleteAttachment,
    attachments,
  } = props;
  return (
    <Container>
      <Editor
        toolbarClassName="n3o-notes-toolbar"
        wrapperClassName="n3o-task-details-wrapper"
        editorClassName="n3o-task-details-editor"
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={editorToolbarOptions}
        handlePastedText={() => false}
      />
      <AddFilesContainer>
        <span className="file-count">
          <K2Message
            localeKey={
              props.attachments.length === 1
                ? "tasks.fileAdded"
                : "tasks.filesAdded"
            }
            values={{ count: props.attachments.length }}
          />
        </span>
        <AttachmentDetails onClick={onToggleAttachments}>
          <AddFileMessage>
            <K2Message localeKey="tasks.action.addFile" />
          </AddFileMessage>
          {!showAttachments && (
            <span>
              <PlusSquareOutlined />
            </span>
          )}
          {showAttachments && (
            <span onClick={onToggleAttachments}>
              <MinusSquareOutlined />
            </span>
          )}
        </AttachmentDetails>
      </AddFilesContainer>
      {showAttachments && (
        <AttachmentsList>
          <K2Dragger
            name="file"
            multiple
            customRequest={handleUpload}
            fileList={attachments}
            onRemove={deleteAttachment}
          >
            <MultiFileUploadExplanation />
          </K2Dragger>
        </AttachmentsList>
      )}
    </Container>
  );
};

export default MarkupEditorPreviewer;
