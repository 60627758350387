// When adding a route here, ensure n3o.dev and
// the K2.Routes service have also been populated with
// the corresponding route keys and paths.
// See Confluence for more information.
const env = process.env;
const prefix = env.REACT_APP_REGION_PREFIX;

export const K2Routes = {
  baseRouteEmpty: "/",
  baseRoute: `/${prefix}/engage`,

  // ========== Used for testing purposes ==================
  temporary: `/${prefix}/engage/temporary`,
  temporaryErrorsList: `/${prefix}/engage/temporary/errors`,

  // =================== Error Routes ======================
  errors: {
    error: `/${prefix}/engage/error`,
    _401: `/${prefix}/engage/error/401`,
    _404: `/${prefix}/engage/error/404`,
    _500: `/${prefix}/engage/error/500`,
    unresponsive: `/${prefix}/engage/error/unresponsive`,
    accessRevoked: `/${prefix}/engage/error/access-revoked`,
  },

  // ========== Main Routes for Main Navigation =============
  // To change their order on the top menu, rearrange them here
  main: {
    dashboard: `/${prefix}/engage/dashboard`,
    accounts: `/${prefix}/engage/accounts`,
    giving: `/${prefix}/engage/giving`,
    communications: `/${prefix}/engage/communications`,
    sponsorships: `/${prefix}/engage/sponsorships`,
    feedbacks: `/${prefix}/engage/feedbacks`,
    data: `/${prefix}/engage/data`,
    analytics: `/${prefix}/engage/analytics`,
    admin: `/${prefix}/engage/admin`,
  },

  // ========== Internal Routes for handling different situations =============
  // these are temporary and will be removed once routing issue is fixed with
  // the sponsorship details modal

  internal: {
    searchSponsorshipsViewAccountSponsorship: `/${prefix}/engage/sponsorships/search/:accountId/sponsorships/:sponsorshipId`,
    searchSponsorshipsViewAnonymousSponsorship: `/${prefix}/engage/sponsorships/search/anonymous/sponsorships/:sponsorshipId`,
  },

  // =================== Other routes =====================

  //#region ACCOUNTS
  createAccount: `/${prefix}/engage/accounts/new`,
  viewAccount: `/${prefix}/engage/accounts/:accountId/profile`,
  viewAccountGiving: `/${prefix}/engage/accounts/:accountId/giving`,
  viewAccountCredentials: `/${prefix}/engage/accounts/:accountId/credentials`,
  viewAccountEditDonation: `/${prefix}/engage/accounts/:accountId/giving/:donationId`,
  viewAccountEditRegularDonation: `/${prefix}/engage/accounts/:accountId/giving/:givingId/regular`,
  viewAccountEditScheduledGiving: `/${prefix}/engage/accounts/:accountId/giving/:givingId/scheduled`,
  viewAccountFeedbacks: `/${prefix}/engage/accounts/:accountId/feedbacks`,
  viewAccountFeedback: `/${prefix}/engage/accounts/:accountId/feedbacks/:feedbackId`,
  viewAccountSponsorships: `/${prefix}/engage/accounts/:accountId/sponsorships`,
  viewAnonymousSponsorships: `/${prefix}/engage/accounts/anonymous/sponsorships`,
  viewAccountSponsorship: `/${prefix}/engage/accounts/:accountId/sponsorships/:sponsorshipId`,
  viewAnonymousSponsorship: `/${prefix}/engage/accounts/anonymous/sponsorships/:sponsorshipId`,
  viewAccountPledges: `/${prefix}/engage/accounts/:accountId/pledges`,
  viewAccountPledge: `/${prefix}/engage/accounts/:accountId/giving/pledges/:pledgeId`,
  viewAccountComms: `/${prefix}/engage/accounts/:accountId/comms`,
  viewAccountCalls: `/${prefix}/engage/accounts/:accountId/calls`,
  viewAccountInsights: `/${prefix}/engage/accounts/:accountId/insights`,
  viewAccountTaxRelief: `/${prefix}/engage/accounts/:accountId/taxRelief`,
  viewAccountDeclaration: `/${prefix}/engage/accounts/:accountId/taxRelief/:declarationId`,
  viewAccountAttachments: `/${prefix}/engage/accounts/:accountId/attachments`,
  viewAccountHistory: `/${prefix}/engage/accounts/:accountId/history`,
  searchAccounts: `/${prefix}/engage/accounts/search`,
  viewAccountWithoutProfile: `/${prefix}/engage/accounts/:accountId`,
  //#endregion

  //#region COMMUNICATIONS
  emailsAdmin: `/${prefix}/engage/communications/emails`,
  printing: `/${prefix}/engage/communications/printing`,
  viewBatch: `/${prefix}/engage/communications/printing/batch/:batchId`,
  viewOutboxes: `/${prefix}/engage/communications/outboxes`,
  viewInboxes: `/${prefix}/engage/communications/inboxes`,
  sendFromOutboxes: `/${prefix}/engage/communications/outboxes/send`,
  callCenter: `/${prefix}/engage/communications/callcenter`,
  callCenterTeamPerformance: `/${prefix}/engage/communications/callcenter/performance/teams`,
  callCenterTeams: `/${prefix}/engage/communications/callcenter/teams`,
  createCallsTeam: `/${prefix}/engage/communications/callcenter/teams/new`,
  editCallsTeam: `/${prefix}/engage/communications/callcenter/teams/:teamId`,
  callCenterCampaigns: `/${prefix}/engage/communications/callcenter/campaigns`,
  createCallsCampaign: `/${prefix}/engage/communications/callcenter/campaigns/new`,
  editCallsCampaign: `/${prefix}/engage/communications/callcenter/campaigns/:campaignId`,
  callCenterCallsAndAppointments: `/${prefix}/engage/communications/callcenter/calls-and-appointments`,
  //#endregion

  //#region SPONSORSHIPS
  createSponsorship: `/${prefix}/engage/accounts/:accountId/sponsorships/new`,
  createAnonymousSponsorship: `/${prefix}/engage/accounts/anonymous/sponsorships/new`,
  searchSponsorship: `/${prefix}/engage/sponsorships/search`,
  //#endregion

  //#region FEEDBACKS
  createFeedback: `/${prefix}/engage/accounts/:accountId/feedbacks/new`,
  createAnonymousFeedback: `/${prefix}/engage/accounts/anonymous/feedbacks/new`,
  searchFeedback: `/${prefix}/engage/feedbacks/search`,
  viewFeedback: `/${prefix}/engage/feedbacks/:feedbackId`,
  //#endregion

  //#region PLEDGES
  createPledge: `/${prefix}/engage/giving/accounts/:accountId/pledges/new`,
  createAnonymousPledge: `/${prefix}/engage/giving/accounts/anonymous/pledges/new`,
  searchPledges: `/${prefix}/engage/giving/pledges/search`,
  viewPledge: `/${prefix}/engage/giving/pledges/:pledgeId`,
  //#endregion

  //#region DONATIONS
  enterDonation: `/${prefix}/engage/giving/donations/new`,
  searchDonations: `/${prefix}/engage/giving/donations/search`,
  viewDonation: `/${prefix}/engage/giving/donations/:donationId`,
  searchGivings: `/${prefix}/engage/giving/givings/search`,
  //#endregion

  //#region PAYMENTS
  payments: `/${prefix}/engage/giving/payments`,
  searchPayments: `/${prefix}/engage/giving/payments/search`,
  viewPayment: `/${prefix}/engage/giving/payments/:paymentId`,
  //#endregion

  //#region ADMIN
  organizationDetails: `/${prefix}/engage/admin/general/organization-details`,
  localization: `/${prefix}/engage/admin/general/localization`,

  roles: `/${prefix}/engage/admin/roles`,
  createRole: `/${prefix}/engage/admin/roles/new`,
  editRole: `/${prefix}/engage/admin/roles/:roleId/edit`,
  users: `/${prefix}/engage/admin/users`,
  createUser: `/${prefix}/engage/admin/users/new`,
  editUser: `/${prefix}/engage/admin/users/:userId/edit`,

  fundDimensions: `/${prefix}/engage/admin/giving/fund-dimensions`,
  donationItems: `/${prefix}/engage/admin/giving/donation-items`,
  editDonationItem: `/${prefix}/engage/admin/giving/donation-items/:donationItemId/edit`,
  createDonationItem: `/${prefix}/engage/admin/giving/donation-items/new`,
  paymentMethods: `/${prefix}/engage/admin/giving/payment-methods`,
  donationsStatement: `/${prefix}/engage/admin/giving/donation-statement`,
  givingCancellationReasons: `/${prefix}/engage/admin/giving/cancellation-reasons`,
  givingScheduled: `/${prefix}/engage/admin/giving/scheduled`,

  sponsorshipSchemes: `/${prefix}/engage/admin/sponsorships/schemes`,
  sponsorshipNewScheme: `/${prefix}/engage/admin/sponsorships/schemes/:beneficiaryType/new`,
  sponsorshipEditScheme: `/${prefix}/engage/admin/sponsorships/schemes/:beneficiaryType/:schemeId/edit`,
  sponsorshipEndedReasons: `/${prefix}/engage/admin/sponsorships/ended-reasons`,

  feedbackSchemes: `/${prefix}/engage/admin/feedbacks/schemes`,
  feedbackNewScheme: `/${prefix}/engage/admin/feedbacks/schemes/new`,
  feedbackEditScheme: `/${prefix}/engage/admin/feedbacks/schemes/:schemeId/edit`,
  feedbackCancellationReasons: `/${prefix}/engage/admin/feedbacks/cancellation-reasons`,

  pledgeCancellationReasons: `/${prefix}/engage/admin/pledges/cancellation-reasons`,
  pledgeSources: `/${prefix}/engage/admin/pledges/sources`,

  communicationsEmail: `/${prefix}/engage/admin/communications/email`,
  communicationsEmailPartials: `/${prefix}/engage/admin/communications/email/partials`,
  communicationsEmailLayouts: `/${prefix}/engage/admin/communications/email/layouts`,
  communicationsEmailTemplates: `/${prefix}/engage/admin/communications/email/templates`,
  communicationsNewEmailTemplate: `/${prefix}/engage/admin/communications/email/templates/new`,
  communicationsEditEmailTemplate: `/${prefix}/engage/admin/communications/email/templates/:templateId`,
  communicationsNewEmailPartial: `/${prefix}/engage/admin/communications/email/partials/new`,
  communicationsEditEmailPartial: `/${prefix}/engage/admin/communications/email/partials/:partialId`,
  communicationsNewEmailLayout: `/${prefix}/engage/admin/communications/email/layouts/new`,
  communicationsEditEmailLayout: `/${prefix}/engage/admin/communications/email/layouts/:layoutId`,
  communicationsPDF: `/${prefix}/engage/admin/communications/pdf`,
  communicationsPdfPartials: `/${prefix}/engage/admin/communications/pdf/partials`,
  communicationsNewPdfPartial: `/${prefix}/engage/admin/communications/pdf/partials/new`,
  communicationsEditPdfPartial: `/${prefix}/engage/admin/communications/pdf/partials/:partialId`,
  communicationsPdfLayouts: `/${prefix}/engage/admin/communications/pdf/layouts`,
  communicationsNewPdfLayout: `/${prefix}/engage/admin/communications/pdf/layouts/new`,
  communicationsEditPdfLayout: `/${prefix}/engage/admin/communications/pdf/layouts/:layoutId`,
  communicationsNewPdfStationery: `/${prefix}/engage/admin/communications/pdf/stationery/new`,
  communicationsEditPdfStationery: `/${prefix}/engage/admin/communications/pdf/stationery/:stationeryId`,
  communicationsPdfStationery: `/${prefix}/engage/admin/communications/pdf/stationery`,
  communicationsPdfTemplates: `/${prefix}/engage/admin/communications/pdf/templates`,
  communicationsNewPdfTemplate: `/${prefix}/engage/admin/communications/pdf/templates/new`,
  communicationsEditPdfTemplate: `/${prefix}/engage/admin/communications/pdf/templates/:templateId`,
  communicationsSMS: `/${prefix}/engage/admin/communications/sms`,
  communicationsSMSPartials: `/${prefix}/engage/admin/communications/sms/partials`,
  communicationsNewSMSPartial: `/${prefix}/engage/admin/communications/sms/partials/new`,
  communicationsEditSMSPartial: `/${prefix}/engage/admin/communications/sms/partials/:partialId`,
  communicationsSMSLayouts: `/${prefix}/engage/admin/communications/sms/layouts`,
  communicationsNewSMSLayout: `/${prefix}/engage/admin/communications/sms/layouts/new`,
  communicationsEditSMSLayout: `/${prefix}/engage/admin/communications/sms/layouts/:layoutId`,
  communicationsSMSTemplates: `/${prefix}/engage/admin/communications/sms/templates`,
  communicationsNewSMSTemplate: `/${prefix}/engage/admin/communications/sms/templates/new`,
  communicationsEditSMSTemplate: `/${prefix}/engage/admin/communications/sms/templates/:templateId`,
  communicationsActions: `/${prefix}/engage/admin/communications/actions`,
  communicationsActionCreate: `/${prefix}/engage/admin/communications/actions/new`,
  communicationsActionEdit: `/${prefix}/engage/admin/communications/actions/:actionId`,
  communicationsPrintQueues: `/${prefix}/engage/admin/communications/print-queues`,
  sharedAssets: `/${prefix}/engage/admin/communications/assets`,
  privacyStatements: `/${prefix}/engage/admin/communications/privacystatements`,
  outboxes: `/${prefix}/engage/admin/communications/outboxes`,
  createOutbox: `/${prefix}/engage/admin/communications/outboxes/new`,
  editOutbox: `/${prefix}/engage/admin/communications/outboxes/:outboxId`,

  callPurposes: `/${prefix}/engage/admin/callcenter/callpurposes`,
  callDispositions: `/${prefix}/engage/admin/callcenter/calldispositions`,
  jobRoles: `/${prefix}/engage/admin/callcenter/agentroles`,
  skipReasons: `/${prefix}/engage/admin/callcenter/skipreasons`,
  compliance: `/${prefix}/engage/admin/callcenter/compliance`,

  recordAbandonedReasons: `/${prefix}/engage/admin/data/abandonedreasons`,
  recordOnHoldReasons: `/${prefix}/engage/admin/data/onholdreasons`,
  viewWebhooks: `/${prefix}/engage/admin/data/webhooks`,
  editWebhook: `/${prefix}/engage/admin/data/webhooks/:webhookId/edit`,
  createWebhook: `/${prefix}/engage/admin/data/webhooks/new`,
  customForms: `/${prefix}/engage/admin/data/forms`,
  editCustomForms: `/${prefix}/engage/admin/data/forms/:formId`,

  attachmentTypes: `/${prefix}/engage/admin/accounts/attachment-types`,
  accountTitles: `/${prefix}/engage/admin/accounts/account-titles`,
  organizationTypes: `/${prefix}/engage/admin/accounts/organization-types`,
  closureReasons: `/${prefix}/engage/admin/accounts/closure-reasons`,

  attributionDimensions: `/${prefix}/engage/admin/analytics/attribution-dimensions`,
  analyticsReportingPeriods: `/${prefix}/engage/admin/analytics/reporting-periods`,
  analyticsReports: `/${prefix}/engage/admin/analytics/reports`,
  touchpoints: `/${prefix}/engage/admin/analytics/touchpoints`,
  campaigns: `/${prefix}/engage/admin/analytics/campaigns`,
  createCampaign: `/${prefix}/engage/admin/analytics/campaigns/new`,
  updateCampaign: `/${prefix}/engage/admin/analytics/campaigns/:campaignId`,
  fundraisers: `/${prefix}/engage/admin/analytics/fundraisers`,

  testing: `/${prefix}/engage/admin/testing`,
  //#endregion

  //#region ANALYTICS
  analyticsReportDashboard: `/${prefix}/engage/analytics/reports`,
  reportCategories: `/${prefix}/engage/analytics/reports/categories`,
  reports: `/${prefix}/engage/analytics/reports/:category`,
  embedReport: `/${prefix}/engage/analytics/reports/:category/:reportId`,
  analyticsAnalysis: `/${prefix}/engage/analytics/analysis`,
  donationIncomeAnalysis: `/${prefix}/engage/analytics/analysis/donation-income`,

  //#endregion

  //#region TASKS
  tasks: `/${prefix}/engage/tasks`,
  viewTask: `/${prefix}/engage/tasks/:taskId`,
  //#endregion

  //#region RECENT ACTIVITIES
  recentActivities: `/${prefix}/engage/recent-activities`,
  //#endregion

  //#region PREFERENCES
  preferences: `/${prefix}/engage/preferences`,
  //#endregion

  //#region DATA
  dataImport: `/${prefix}/engage/data/import`,
  viewImportQueues: `/${prefix}/engage/data/import/queues`,
  searchRecords: `/${prefix}/engage/data/import/records/search`,
  queueRecords: `/${prefix}/engage/data/import/queues/:queueId/records`,
  viewImportQueue: `/${prefix}/engage/data/import/queues/:queueId`,
  viewImportQueueTransforms: `/${prefix}/engage/data/import/queues/:queueId/transforms`,
  viewImportQueueTransform: `/${prefix}/engage/data/import/queues/:queueId/transforms/:transformId`,
  createFeed: `/${prefix}/engage/data/import/queues/:queueId/feeds/new`,
  editFeed: `/${prefix}/engage/data/import/queues/:queueId/feeds/:feedId/edit`,
  uploadToFeed: `/${prefix}/engage/data/import/queues/:queueId/feeds/:feedId/upload`,
  viewImportRecord: `/${prefix}/engage/data/import/queues/:queueId/records/:recordId`,

  dataExport: `/${prefix}/engage/data/export`,
  dataExportLists: `/${prefix}/engage/data/export/lists`,
  dataExportCreateList: `/${prefix}/engage/data/export/lists/new`,
  dataExportViewAllDownloads: `/${prefix}/engage/data/export/lists/downloads`,
  dataExportViewAllConnections: `/${prefix}/engage/data/export/lists/connections`,
  dataExportViewListDownloads: `/${prefix}/engage/data/export/lists/:listId/downloads`,
  dataExportNewConnection: `/${prefix}/engage/data/export/lists/:listId/connection/new`,
  dataExportEditConnection: `/${prefix}/engage/data/export/lists/:listId/connection/:connectionId`,
  dataExportViewList: `/${prefix}/engage/data/export/lists/:listId`,
  //#endregion

  //#region CALLBACK URLS
  goCardlessPaymentCallbackUrl: `/${prefix}/engage/callbacks/go-cardless`,
  //#endregion

  newVersion: `/${prefix}/engage/new-version-available`,

  logout: `/${prefix}/logout`,
};

export const keysHiddenFromProduction: (keyof typeof K2Routes)[] = [
  "givingScheduled",
];

export const redirectRouteStorageKey: string = "redirect_url_after_callback";
