import { ClientConfigurationRes } from "@n3oltd/karakoram.mail.sdk.data-entry/esm";
import { all, put, select, takeLatest } from "redux-saga/effects";

import { _mailDataEntryClient } from "../../models/base/K2RestClients";
import K2Service from "../../models/base/K2RestService";
import { IApiResponse } from "../../models/common/ApiResponseModel";
import AddressesSelectors from "../../store/selectors/AddressesSelectors";
import { addressesActions } from "../addresses";
import { AddressesManagement } from "./types";

//#region WORKER FUNCTIONS

function* getAddressSchema(
  action: ReturnType<typeof addressesActions.fetchAddressSchema>,
) {
  const response: IApiResponse<ClientConfigurationRes> = yield K2Service.toResponse(
    _mailDataEntryClient.getClientConfiguration(),
  );

  const schema = response
    .getResultOrDefault()
    .countrySchemas.find((schema) => schema.countries.includes(action.payload));

  yield put(
    addressesActions.setAddressSchema(
      schema || response.getResultOrDefault().defaultSchema,
    ),
  );
}

function* getSuggestionsConfigurations() {
  const addresses: AddressesManagement = yield select(
    AddressesSelectors.selectAddressesData,
  );
  if (!addresses.suggestionsConfig) {
    const response: IApiResponse<ClientConfigurationRes> = yield K2Service.toResponse(
      _mailDataEntryClient.getClientConfiguration(),
    );
    yield put(addressesActions.setSuggestionsConfigurations(response));
  }
}

//#endregion

export default function* addressesSaga() {
  yield all([
    yield takeLatest(
      addressesActions.fetchAddressSchema.type,
      getAddressSchema,
    ),
    yield takeLatest(
      addressesActions.fetchSuggestionsConfigurations.type,
      getSuggestionsConfigurations,
    ),
  ]);
}
