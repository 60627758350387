import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const PledgeIcon: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.75C11.5168 1.75 11.125 2.14175 11.125 2.625V4.29778C9.44317 4.48818 7.81131 5.22848 6.52153 6.51826C5.23092 7.80888 4.4905 9.44202 4.30069 11.125H2.625C2.14175 11.125 1.75 11.5168 1.75 12C1.75 12.4832 2.14175 12.875 2.625 12.875H4.30106C4.49146 14.5568 5.23176 16.1887 6.52153 17.4784C7.81132 18.7682 9.44318 19.5085 11.125 19.6989V21.375C11.125 21.8582 11.5168 22.25 12 22.25C12.4832 22.25 12.875 21.8582 12.875 21.375V19.6993C14.558 19.5095 16.1911 18.7691 17.4817 17.4784C18.7715 16.1887 19.5118 14.5568 19.7022 12.875H21.375C21.8582 12.875 22.25 12.4832 22.25 12C22.25 11.5168 21.8582 11.125 21.375 11.125H19.7026C19.5128 9.44202 18.7723 7.80887 17.4817 6.51825C16.1911 5.22765 14.558 4.48723 12.875 4.29741V2.625C12.875 2.14175 12.4832 1.75 12 1.75ZM12.875 7.625V6.06185C14.108 6.24221 15.2953 6.80669 16.2443 7.7557C17.1933 8.70471 17.7578 9.89198 17.9381 11.125H16.375C15.8918 11.125 15.5 11.5168 15.5 12C15.5 12.4832 15.8918 12.875 16.375 12.875H17.9376C17.7568 14.1068 17.1924 15.2928 16.2443 16.241C15.2953 17.19 14.108 17.7545 12.875 17.9349V16.375C12.875 15.8918 12.4832 15.5 12 15.5C11.5168 15.5 11.125 15.8918 11.125 16.375V17.9344C9.89316 17.7535 8.70715 17.1892 7.75897 16.241C6.8108 15.2928 6.24648 14.1068 6.0656 12.875H7.625C8.10825 12.875 8.5 12.4832 8.5 12C8.5 11.5168 8.10825 11.125 7.625 11.125H6.06512C6.24548 9.89199 6.80996 8.70472 7.75897 7.75569C8.70715 6.80752 9.89316 6.2432 11.125 6.06232V7.625C11.125 8.10825 11.5168 8.5 12 8.5C12.4832 8.5 12.875 8.10825 12.875 7.625Z"
      fill={fill}
    />
  </svg>
);
