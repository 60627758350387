import React, { FC, useCallback, useState } from "react";

import { CaretDownOutlined, PlusCircleOutlined } from "@ant-design/icons/lib";
import { Dropdown } from "antd";
import { useGetIsProdEnvironment } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { Q1MainRouteKeys, Q1Routes } from "appRedux/models/routes/Q1RouteModel";
import { K2MainRouteKeys, K2Routes } from "appRedux/models/routes/RouteModel";
import { toggleQuickNavVisibility } from "appRedux/modules/settings/actions";
import IApplicationState from "appRedux/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { K2Button, K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import MainNavLink from "components/navigation/mainNavigation/NavLink";
import QuickCreateMenu from "components/navigation/mainNavigation/quickActions/QuickCreateMenu";
import QuickFindMenu from "components/navigation/mainNavigation/quickActions/QuickFindMenu";
import { q1QuickCreateLinks } from "components/navigation/mainNavigation/quickActions/quickCreateConfig";

const MainNavigationContainer = styled.div<{ $isDashboard?: boolean }>`
  &.n3o-navbar-nav {
    height: 62px;
    box-shadow: 0 10px 32px rgba(174, 184, 194, 0.2);
    background-color: ${({ theme }) => theme.white_color};
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const LinksSection = styled.ul`
  &.n3o-sidebar-list {
    list-style: none;
    padding: 0;
  }
  &.n3o-navbar-list {
    list-style: none;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 20px 0 20px;
    width: 80%;
    margin-bottom: 0;
  }
`;

const QuickLinksSection = styled.div`
  &.n3o-sidebar-list {
    display: none;
  }
  &.n3o-navbar-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
    margin-right: 32px;
  }
`;

const FindButton = styled.div`
  && {
    font-weight: ${({ theme }) => theme.font_weight_semi_bold};
    color: ${({ theme }) => theme.grey_7};
    border: none;
    cursor: pointer;
    padding: 4px 15px;
    box-shadow: none;
  }
`;

interface IProps extends RouteComponentProps, InjectedK2IntlProps {
  className?: string;
  sidebarMode?: boolean;
  isOnDashboard?: boolean;
}

type PermittedK2Routes = {
  [key in K2MainRouteKeys]: string;
};

type PermittedQ1Routes = {
  [key in Q1MainRouteKeys]: string;
};

// TODO: Remove when these pages are fully tested etc
const temporarilyHiddenRoutes: (Q1MainRouteKeys | K2MainRouteKeys)[] = [
  "q1TaxRelief",
];

const MainNavigation: FC<IProps> = (props) => {
  const isProdEnv = useGetIsProdEnvironment();

  // TODO:  need to remove after the demo
  const k2User = useSelector(
    (state: IApplicationState) => state.subscription.users.k2User,
  );
  const { location, sidebarMode } = props;
  const [quickFindVisibility, setQuickFindVisibility] = useState<boolean>(
    false,
  );
  const [quickCreateVisibility, setQuickCreateVisibility] = useState<boolean>(
    false,
  );

  const currentPathname = location.pathname;
  const dispatch = useDispatch();
  const { appId } = useAppContext();

  const extractPermittedMainNavRoutes = useCallback(
    (permittedRoutes: string[]): PermittedK2Routes | PermittedQ1Routes => {
      const permitted = Object.assign(
        {},
        appId === "k2" ? K2Routes.main : Q1Routes.main,
      );

      Object.keys(permitted).forEach((routeKey) => {
        if (!permittedRoutes?.includes(routeKey)) {
          delete permitted[routeKey];
        }
      });
      return permitted;
    },
    [appId],
  );

  const getPopupContainer = useCallback((triggerNode: HTMLElement) => {
    const element = document.getElementById("n3o-navbar-nav");
    return element !== null ? element : triggerNode;
  }, []);

  const permittedRoutesArray = useSelector(
    (state: IApplicationState) => state.subscription.users.k2UserRoutes.items,
  );

  const permittedRoutes = extractPermittedMainNavRoutes(permittedRoutesArray);

  const toggleQuickNavFadeInFadeOutClass = useCallback(
    (visible: boolean) => {
      dispatch(toggleQuickNavVisibility(!visible));
    },
    [dispatch],
  );

  return (
    <MainNavigationContainer
      $isDashboard={props.isOnDashboard}
      className={sidebarMode ? "n3o-sidebar-nav" : "n3o-navbar-nav"}
    >
      <LinksSection
        className={sidebarMode ? "n3o-sidebar-list" : "n3o-navbar-list"}
      >
        {/*TODO:  need to remove email after the demo */}
        {Object.keys(permittedRoutes)
          ?.filter(
            (routeKey: Q1MainRouteKeys | K2MainRouteKeys) =>
              k2User.email === "rehan.salim@n3o.ltd" ||
              !temporarilyHiddenRoutes.includes(routeKey) ||
              (isProdEnv && routeKey === "q1DirectDebits"),
          )
          ?.map?.((routKey) => (
            <MainNavLink
              key={"route_item_" + routKey}
              route={permittedRoutes[routKey]}
              currentPathname={currentPathname}
              routeKey={
                appId === "k2" ? `mainNav.${routKey}` : `q1.mainNav.${routKey}`
              }
            />
          ))}
      </LinksSection>
      <QuickLinksSection
        className={sidebarMode ? "n3o-sidebar-list" : "n3o-navbar-list"}
      >
        <Dropdown
          overlay={
            <QuickFindMenu
              k2Intl={props.k2Intl}
              onMenuItemClick={() => {
                setQuickFindVisibility(false);
                toggleQuickNavFadeInFadeOutClass(false);
              }}
            />
          }
          placement="bottomRight"
          getPopupContainer={getPopupContainer}
          trigger={["click"]}
          visible={quickFindVisibility}
          onVisibleChange={(v: boolean) => {
            setQuickFindVisibility(!quickFindVisibility);
            toggleQuickNavFadeInFadeOutClass(v);
          }}
        >
          <FindButton>
            <K2Message localeKey={"common.search"} />
            <CaretDownOutlined style={{ marginLeft: 10 }} />
          </FindButton>
        </Dropdown>
        {(appId === "k2" || q1QuickCreateLinks.length > 0) && (
          <div>
            <Dropdown
              overlay={
                <QuickCreateMenu
                  k2Intl={props.k2Intl}
                  onMenuItemClick={() => {
                    setQuickFindVisibility(false);
                    toggleQuickNavFadeInFadeOutClass(false);
                  }}
                />
              }
              placement="bottomRight"
              getPopupContainer={getPopupContainer}
              trigger={["click"]}
              visible={quickCreateVisibility}
              onVisibleChange={(v: boolean) => {
                setQuickCreateVisibility(!quickCreateVisibility);
                toggleQuickNavFadeInFadeOutClass(v);
              }}
            >
              <K2Button type={"primary"} icon={<PlusCircleOutlined />}>
                <K2Message localeKey={"common.create"} />
              </K2Button>
            </Dropdown>
          </div>
        )}
      </QuickLinksSection>
    </MainNavigationContainer>
  );
};

export default injectK2Intl(withRouter(MainNavigation));
