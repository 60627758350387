import { all, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import { notification } from "antd";
import { ILocationChangeAction } from "./action";

function onLocationChange(values: ILocationChangeAction) {
  // Destroy any notifications
  notification.destroy();
}

export default function* watcherSaga() {
  yield all([takeEvery(LOCATION_CHANGE, onLocationChange)]);
}
