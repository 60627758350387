import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const SearchPayments: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4324 11.2838C12.467 10.2492 13.8702 9.66797 15.3333 9.66797C16.7964 9.66797 18.1996 10.2492 19.2342 11.2838C20.2688 12.3183 20.85 13.7215 20.85 15.1846C20.85 16.3614 20.4741 17.4993 19.7887 18.4379L22.6011 21.2505C22.933 21.5824 22.933 22.1206 22.601 22.4526C22.2691 22.7845 21.7309 22.7845 21.399 22.4525L18.5866 19.64C17.648 20.3254 16.51 20.7013 15.3333 20.7013C13.8702 20.7013 12.467 20.1201 11.4324 19.0855C10.3979 18.0509 9.81665 16.6478 9.81665 15.1846C9.81665 13.7215 10.3979 12.3183 11.4324 11.2838ZM15.3333 11.368C14.3211 11.368 13.3503 11.7701 12.6345 12.4858C11.9188 13.2016 11.5167 14.1724 11.5167 15.1846C11.5167 16.1969 11.9188 17.1677 12.6345 17.8834C13.3503 18.5992 14.3211 19.0013 15.3333 19.0013C16.3456 19.0013 17.3164 18.5992 18.0321 17.8834C18.7479 17.1677 19.15 16.1969 19.15 15.1846C19.15 14.1724 18.7479 13.2016 18.0321 12.4858C17.3164 11.7701 16.3456 11.368 15.3333 11.368Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.25 1.75H1.75C1.33516 1.75 1 2.08516 1 2.5V17.5C1 17.9148 1.33516 18.25 1.75 18.25H8.2C8.64183 18.25 9 17.8918 9 17.45V17.3625C9 16.9207 8.64183 16.5625 8.2 16.5625H2.6875V8.3125H20.3125V9.7C20.3125 10.1418 20.6707 10.5 21.1125 10.5H21.2C21.6418 10.5 22 10.1418 22 9.7V2.5C22 2.08516 21.6648 1.75 21.25 1.75ZM20.3125 3.4375H2.6875V6.25H20.3125V3.4375Z"
      fill={fill}
    />
  </svg>
);
