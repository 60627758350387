import React, { FC, useCallback, useState } from "react";

import {
  DeleteTwoTone,
  EllipsisOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons/lib";
import { DonationItemRes } from "@n3oltd/k2.donations.sdk.donation-items/esm";
import { Currency } from "@n3oltd/k2.subscriptions.sdk.subscriptions";
import { Col, Dropdown, Menu, Row, Spin } from "antd";

import { _donationItemsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { NamedLookup } from "appRedux/modules/lookups/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import ItemSummaryRow from "components/admin/shared/ItemSummaryRow";
import {
  K2Card,
  K2Currency,
  K2DateTime,
  K2Message,
} from "components/k2Widgets";
import { K2Button } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import DonationItemStatusTag from "components/statusTag/DonationItemStatusTag";
import { UIUtils } from "components/utils";
import { showConfirm } from "components/utils/Confirmation";
import { showNotification } from "components/utils/Notification";

interface DonationItemDetailsPanelProps extends InjectedK2IntlProps {
  donationItem: DonationItemRes;
  onDelete: () => void;
  fetchSelectedDonationItem: (id: string) => void;
  donationItemStatuses: NamedLookup[];
  baseCurrency: Currency;
}

const DonationItemDetailsPanel: FC<DonationItemDetailsPanelProps> = ({
  donationItem,
  onDelete,
  k2Intl,
  donationItemStatuses,
  fetchSelectedDonationItem,
  baseCurrency,
}) => {
  const [saving, setSaving] = useState<boolean>(false);
  const { theme } = useAppContext();

  const updateStatus = useCallback(
    async (status: "active" | "inactive", donationItem: DonationItemRes) => {
      setSaving(true);
      let response: IApiResponse<void>;

      if (status === "active") {
        response = await K2RestService.toResponse(
          _donationItemsClient.activateDonationItem(donationItem.revisionId),
        );
      } else {
        response = await K2RestService.toResponse(
          _donationItemsClient.deactivateDonationItem(donationItem.revisionId),
        );
      }

      if (response.error) {
        UIUtils.handleServerError(k2Intl, response.error);
      } else {
        showNotification({
          type: "success",
          titleKey: "common.success.title",
          messageKey: "admin.statusUpdateSuccessMessage",
          k2Intl,
        });
        setSaving(false);
        fetchSelectedDonationItem(donationItem.id);
      }
    },
    [k2Intl, fetchSelectedDonationItem],
  );

  const confirmUpdateStatus = useCallback(
    (status: "active" | "inactive", donationItem: DonationItemRes) => {
      showConfirm({
        titleKey:
          status === "active"
            ? "admin.editDonationItem.activate.title"
            : "admin.editDonationItem.deactivate.title",
        contentKey: `admin.donationItem.${
          status === "active" ? "activate" : "deactivate"
        }.confirm`,
        k2Intl,
        okTextKey: "common.yes",
        cancelTextKey: "common.cancel",
        onOk: () => {
          updateStatus(status, donationItem);
          return Promise.resolve();
        },
      });
    },
    [k2Intl, updateStatus],
  );

  return (
    <K2Card
      bordered={false}
      shadow={true}
      localeKey={"common.details"}
      style={{ marginBottom: 25 }}
      extra={
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              {donationItem.status === "active" && (
                <Menu.Item
                  onClick={() => confirmUpdateStatus("inactive", donationItem)}
                >
                  <PauseCircleOutlined />
                  <K2Message localeKey={"common.deactivate"} />
                </Menu.Item>
              )}
              {donationItem.status === "inactive" && (
                <Menu.Item
                  onClick={() => confirmUpdateStatus("active", donationItem)}
                >
                  <PlayCircleOutlined />
                  <K2Message localeKey={"common.activate"} />
                </Menu.Item>
              )}
              {donationItem.canDelete && (
                <Menu.Item onClick={onDelete}>
                  <DeleteTwoTone twoToneColor={theme.error_color} />
                  <K2Message localeKey={"common.delete"} />
                </Menu.Item>
              )}
            </Menu>
          }
          placement={"bottomRight"}
          disabled={!donationItem || saving}
        >
          <K2Button>
            <EllipsisOutlined />
          </K2Button>
        </Dropdown>
      }
    >
      <Spin spinning={saving}>
        <Row>
          <Col xs={24}>
            <ItemSummaryRow
              labelKey={"common.created"}
              value={
                <>
                  <K2DateTime
                    value={donationItem.modifiedInfo?.created?.timestamp}
                  />{" "}
                  {k2Intl
                    .formatMessage({
                      localeKey: "common.by",
                    })
                    .toLowerCase()}
                  <span className="n3o-ml-1">
                    {donationItem.modifiedInfo?.created?.name}
                  </span>
                </>
              }
            />
            {donationItem.modifiedInfo?.updated && (
              <ItemSummaryRow
                labelKey={"common.lastUpdated"}
                value={
                  <>
                    <K2DateTime
                      value={donationItem.modifiedInfo?.updated?.timestamp}
                    />{" "}
                    {k2Intl
                      .formatMessage({
                        localeKey: "common.by",
                      })
                      .toLowerCase()}
                    <span className="n3o-ml-1">
                      {donationItem.modifiedInfo?.updated?.name}
                    </span>
                  </>
                }
              />
            )}
            <ItemSummaryRow
              labelKey={"admin.totalReceived"}
              value={
                <K2Currency
                  value={donationItem.statistics?.totalDonated?.amount || 0}
                  currency={
                    donationItem.statistics?.totalDonated?.currency ||
                    baseCurrency
                  }
                />
              }
            />
            <ItemSummaryRow
              labelKey={"admin.lastGiven"}
              value={
                donationItem.statistics?.lastDonationOn ? (
                  <K2DateTime value={donationItem.statistics.lastDonationOn} />
                ) : (
                  <K2Message localeKey={"common.never"} />
                )
              }
            />
            <ItemSummaryRow
              labelKey={"common.status"}
              value={
                <DonationItemStatusTag
                  status={donationItem.status}
                  donationItemStatuses={donationItemStatuses}
                />
              }
            />
          </Col>
        </Row>
      </Spin>
    </K2Card>
  );
};

export default injectK2Intl(DonationItemDetailsPanel);
