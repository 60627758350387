import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const DoubleArrowBoxDown: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.35455 12.4469C5.66003 12.0904 6.19663 12.0491 6.55309 12.3546L12 17.0224L17.4469 12.3546C17.8033 12.0491 18.3399 12.0904 18.6454 12.4469C18.9509 12.8033 18.9095 13.3399 18.5531 13.6454L12.8362 18.5445C12.7157 18.648 12.5793 18.7245 12.4376 18.7748C12.2959 18.8252 12.1475 18.85 12 18.85C11.8525 18.85 11.7041 18.8252 11.5624 18.7748C11.4207 18.7245 11.2843 18.648 11.1638 18.5446L5.44687 13.6454C5.09041 13.3399 5.04908 12.8033 5.35455 12.4469Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.35455 6.29691C5.66003 5.94045 6.19663 5.89912 6.55309 6.20459L12 10.8724L17.4469 6.20459C17.8033 5.89912 18.3399 5.94045 18.6454 6.29691C18.9509 6.65337 18.9095 7.18997 18.5531 7.49544L12.8362 12.3946C12.7157 12.498 12.5793 12.5745 12.4376 12.6248C12.2959 12.6752 12.1475 12.7 12 12.7C11.8525 12.7 11.7041 12.6752 11.5624 12.6248C11.4207 12.5745 11.2843 12.4981 11.1638 12.3946L5.44687 7.49544C5.09041 7.18997 5.04908 6.65337 5.35455 6.29691Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 2.7H5C3.72975 2.7 2.7 3.72975 2.7 5V19C2.7 20.2703 3.72975 21.3 5 21.3H19C20.2703 21.3 21.3 20.2703 21.3 19V5C21.3 3.72975 20.2703 2.7 19 2.7ZM5 1C2.79086 1 1 2.79086 1 5V19C1 21.2091 2.79086 23 5 23H19C21.2091 23 23 21.2091 23 19V5C23 2.79086 21.2091 1 19 1H5Z"
      fill={fill}
    />
  </svg>
);
