import React, { FC, useCallback, useMemo, useState } from "react";

import { QuestionCircleTwoTone } from "@ant-design/icons";
import { PricingReq } from "@n3oltd/karakoram.sponsorships.beneficiarytypes.child.sdk.child/esm";
import { Col, Radio, Row } from "antd";
import _ from "lodash";

import { GeneralFormItemV4 } from "components/formItems";
import CurrencyInput from "components/formItems/InputCurrencyFormItem";
import {
  K2Checkbox,
  K2InputV4,
  K2Message,
  K2NumberInputV4,
  K2RadioGroup,
  K2Tooltip,
} from "components/k2Widgets";
import { Utils } from "components/utils";
import SchemeFormHelpers from "routes/admin/sponsorships/schemes/helpers";

import { PricingRules } from ".";
import { IComponentsFormBodyProps, SchemeReqType } from "../../modules/types";
import { FlexAlignCenterWrapper } from "../styled";

const { Button } = Radio;

type PricingType = "simple" | "multiple";

const ComponentBody: FC<IComponentsFormBodyProps> = (props) => {
  const { form, defaultCurrency, moreThan1Component, fieldIndex } = props;
  const { getFieldValue, setFields } = form;

  const rulesFieldName = SchemeFormHelpers.getComponentRulesFieldName(
    fieldIndex,
  );

  const rulesFieldValue: PricingReq[] = useMemo(() => {
    return getFieldValue([
      "components",
      ...SchemeFormHelpers.getComponentRulesFieldName(fieldIndex),
    ]);
  }, [fieldIndex, getFieldValue]);

  const defaultPriceFieldValue = useMemo(() => {
    return getFieldValue([
      "components",
      ...SchemeFormHelpers.getComponentAmountFieldName(fieldIndex),
    ]);
  }, [fieldIndex, getFieldValue]);

  const lockedFieldValue = useMemo(() => {
    getFieldValue([
      "components",
      ...SchemeFormHelpers.getComponentLockedFieldName(fieldIndex),
    ]);
  }, [fieldIndex, getFieldValue]);

  const [pricingType, setPricingType] = useState<PricingType>(
    !_.isEmpty(rulesFieldValue) ? "multiple" : "simple",
  );

  const handleChangeMaxSponsorships = useCallback((value: number) => {
    if (!Utils.isValidNumber(value)) {
      return;
    }
  }, []);

  const handlePricingType = useCallback(
    (pricingType: PricingType) => {
      setFields([
        {
          name: rulesFieldName,
          value:
            pricingType === "simple"
              ? undefined
              : _.isNil(rulesFieldValue) || _.isEmpty(rulesFieldValue)
              ? [{ locked: false }]
              : rulesFieldValue,
        },
      ]);

      setPricingType(pricingType);
    },
    [rulesFieldName, rulesFieldValue, setFields],
  );

  return (
    <Row gutter={[12, 18]} style={{ paddingTop: 20 }}>
      <Col span={24}>
        <GeneralFormItemV4
          name={SchemeFormHelpers.getComponentNameFieldName(fieldIndex)}
          labelKey={"admin.sponsorship.schemes.form.nameThisComponent"}
          validateTrigger={["onBlur", "onChange"]}
          initialValue={
            moreThan1Component
              ? props.k2Intl.formatMessage({
                  localeKey: "sponsorships.search.filters.componentNo",
                  values: { number: fieldIndex + 1 },
                })
              : props.k2Intl.formatMessage({
                  localeKey: "common.general",
                })
          }
          required
        >
          <K2InputV4
            placeholderKey={
              "admin.sponsorship.schemes.form.nameThisComponent.placeholder"
            }
          />
        </GeneralFormItemV4>
      </Col>
      {moreThan1Component && (
        <Col span={24}>
          <GeneralFormItemV4
            name={SchemeFormHelpers.getComponentRequiredFieldName(fieldIndex)}
          >
            <K2RadioGroup buttonStyle="solid" size="small">
              <Button value={true}>
                <K2Message localeKey={"common.mandatory"} />
              </Button>
              <Button value={false}>
                <K2Message localeKey={"common.optional"} />
              </Button>
            </K2RadioGroup>
          </GeneralFormItemV4>
        </Col>
      )}
      <Col span={24}>
        <FlexAlignCenterWrapper>
          <GeneralFormItemV4
            name={SchemeFormHelpers.getComponentCapacityFieldName(fieldIndex)}
            labelKey={"admin.sponsorship.schemes.form.maxSponsors"}
            validateTrigger={["onBlur", "onChange"]}
            className={"n3o-mb-0"}
            required
          >
            <K2NumberInputV4
              min={1}
              placeholder={"1"}
              style={{ width: 150 }}
              type={"number"}
              onChange={handleChangeMaxSponsorships}
            />
          </GeneralFormItemV4>
          <K2Tooltip
            placement={"rightTop"}
            titleKey={"admin.sponsorship.schemes.form.maxSponsorsTooltip"}
          >
            <QuestionCircleTwoTone
              style={{ paddingLeft: 10, paddingTop: 21 }}
              className={"n3o-pointer"}
            />
          </K2Tooltip>
        </FlexAlignCenterWrapper>
      </Col>
      <Col span={24}>
        <GeneralFormItemV4
          labelKey={"common.price"}
          className={"n3o-mb-0"}
          name={[fieldIndex, "pricingType"]}
        >
          <K2RadioGroup
            buttonStyle="solid"
            size="small"
            value={pricingType}
            defaultValue={pricingType}
            onChange={(e) => handlePricingType(e?.target?.value)}
          >
            <Button value={"simple"}>
              <K2Message localeKey={"common.single"} />
            </Button>
            <Button value={"multiple"}>
              <K2Message localeKey={"common.multiple"} />
            </Button>
          </K2RadioGroup>
        </GeneralFormItemV4>
      </Col>
      <Col span={24}>
        <FlexAlignCenterWrapper>
          <CurrencyInput
            labelKey={"admin.sponsorship.schemes.form.pricing.defaultAmount"}
            extraLabelKey={
              "admin.sponsorship.schemes.form.pricing.defaultAmount.extra"
            }
            initialValue={defaultPriceFieldValue}
            defaultValue={defaultPriceFieldValue}
            currentForm={form}
            currency={defaultCurrency}
            formItemProps={{
              className: "n3o-mb-0",
              name: SchemeFormHelpers.getComponentAmountFieldName(fieldIndex),
            }}
          />
          <GeneralFormItemV4
            name={SchemeFormHelpers.getComponentLockedFieldName(fieldIndex)}
            valuePropName={"checked"}
            className={"n3o-mb-0"}
            style={{ paddingLeft: 20, paddingTop: 25 }}
            initialValue={lockedFieldValue}
          >
            <K2Checkbox>
              <K2Message
                localeKey={"admin.sponsorship.schemes.form.pricing.fixedPrice"}
              />
            </K2Checkbox>
          </GeneralFormItemV4>
          <K2Tooltip
            placement={"rightTop"}
            titleKey={
              "admin.sponsorship.schemes.form.pricing.fixedPrice.tooltip"
            }
          >
            <QuestionCircleTwoTone
              style={{ paddingLeft: 10, paddingTop: 21 }}
              className={"n3o-pointer"}
            />
          </K2Tooltip>
        </FlexAlignCenterWrapper>
        {pricingType === "multiple" && (
          <PricingRules
            {...props}
            componentFieldIndex={fieldIndex}
            rulesFieldValue={rulesFieldValue}
          />
        )}
      </Col>
    </Row>
  );
};

export default ComponentBody;
