import styled from "styled-components";
import { Steps } from "antd";

const K2Steps = styled(Steps)`
  && {
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      background: ${({ theme }) => theme.primaryColor} !important;
      border-color: ${({ theme }) => theme.primaryColor} !important;
    }
    .ant-steps-item.ant-steps-item-active::before {
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }
    .ant-steps-item-finish .ant-steps-item-icon {
      border-color: ${({ theme }) => theme.primaryColor} !important;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: ${({ theme }) => theme.primaryColor} !important;
    }

    .ant-steps-item-finish
      .ant-steps-item-icon
      > .ant-steps-icon
      .ant-steps-icon-dot {
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      background-color: ${({ theme }) => theme.primaryColor} !important;
    }
  }
`;

export default K2Steps;
