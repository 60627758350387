import {
  ClientConfigurationRes,
  Country,
  SchemaRes,
} from "@n3oltd/karakoram.mail.sdk.data-entry/esm";
import { ImmerReducer, setPrefix } from "immer-reducer";

import { IApiResponse } from "../../models/common/ApiResponseModel";
import { AddressesManagement } from "./types";

//Set ActionCreators Name Prefix
setPrefix("K2Web");

export const initialState: AddressesManagement = {
  addressSchema: null,
  isLoading: false,
  suggestionsConfig: null,
};

export class AddressesReducer extends ImmerReducer<AddressesManagement> {
  addressesLoading(loading: boolean) {
    this.draftState.isLoading = loading;
  }

  fetchAddressSchema(_countryCode: Country) {
    this.addressesLoading(true);
  }

  setAddressSchema(_addressSchema: SchemaRes) {
    this.draftState.addressSchema = _addressSchema;
    this.addressesLoading(false);
  }

  fetchSuggestionsConfigurations() {
    if (!this.draftState.suggestionsConfig) this.addressesLoading(true);
  }

  clearSuggestionsConfigurations() {
    this.draftState.suggestionsConfig = null;
  }

  setSuggestionsConfigurations(response: IApiResponse<ClientConfigurationRes>) {
    this.draftState.suggestionsConfig = response.getResultOrDefault().suggestions;
    this.addressesLoading(false);
  }
}
