import React, { FC, useCallback, useEffect, useState } from "react";

import { QuestionCircleTwoTone } from "@ant-design/icons/lib";
import { AssetRes } from "@n3oltd/karakoram.templates.sdk.assets/esm";
import { FragmentType } from "@n3oltd/karakoram.templates.sdk.fragments";
import { TemplateModelType } from "@n3oltd/karakoram.templates.sdk.lookups/esm";
import { CompositionType } from "@n3oltd/karakoram.templates.sdk.pdf";
import { Alert, Col, Form, Input, Row, Skeleton, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FormInstance } from "antd/lib/form";
import { RcFile } from "antd/lib/upload";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { GeneralFormItemV4 } from "components/formItems";
import ModifiedInfoDetailsPanel from "components/forms/ModifiedInfoDetailsPanel";
import { K2Button, K2Message, K2Tooltip } from "components/k2Widgets";
import FakeLink from "components/k2Widgets/k2FakeLink";
import { UIUtils } from "components/utils";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import { TEMPLATE_ENGINE } from "constants/appConstants";
import CommunicationAdminHelpers from "routes/admin/communication/helpers";
import { TemporaryUploadFile } from "routes/admin/communication/modules/types";
import LayoutExplanation from "routes/admin/communication/newEditPartialOrLayout/components/LayoutExplanation";
import { PreviewLink } from "routes/admin/communication/sharedComponents/PreviewLink";
import MergeModelsSelector from "routes/admin/communication/sharedComponents/TemplateModelsSelector";
import StylesheetSelector from "routes/admin/communication/sharedComponents/markup/StylesheetSelector";
import VariableList from "routes/admin/communication/sharedComponents/markup/VariableList";
import MediaAssetsSelector from "routes/admin/communication/sharedComponents/markup/assets/MediaAssetsSelector";

import { PartialOrLayoutProps } from "../connect";
import PartialOrLayoutHelpers from "../helpers";

const MAX_ALIAS_LENGTH = 40;

const layout = {
  labelCol: {
    xs: 24,
  },
  wrapperCol: {
    xs: 24,
    sm: 18,
    md: 12,
  },
};

interface IProps extends PartialOrLayoutProps {
  form: FormInstance;
  editMode: boolean;
  entityType: CompositionType;
  fragmentType: FragmentType;
  onSubmit: (values) => void;
  onPreview: (values) => void;
  errors?: string[];
  dirty: boolean;
  onValuesChange: () => void;
  tempAssets: AssetRes[];
  setTempAssets: (tempAssets: AssetRes[]) => void;
}

const CreateEditPartialLayoutForm: FC<IProps> = ({
  saving,
  loading,
  data,
  form,
  onSubmit,
  errors,
  onValuesChange,
  dirty,
  entityType,
  k2Intl,
  fragmentType,
  assetTypes,
  updateTemporaryAssets,
  uploadAsset,
  removeTemporaryAsset: deleteAssetAndRemove,
  temporaryUploadFiles,
  clearTemporaryAssets,
  updateDirty,
  tempAssets,
  setTempAssets,
  editMode,
  templateEngines,
  onPreview,
  deletePartialOrLayout,
}) => {
  const [aliasValue, setAliasValue] = useState<string>(
    form?.getFieldValue(PartialOrLayoutHelpers.getAliasFieldName()) || "",
  );
  const [aliasTouched, setAliasTouched] = useState<boolean>(false);
  const [
    selectedTemplateModelType,
    setSelectedTemplateModelType,
  ] = useState<TemplateModelType>();

  const onUpload = useCallback(
    (upload: TemporaryUploadFile, originalFile: RcFile) => {
      uploadAsset(upload, originalFile);
      updateDirty(true);
    },
    [uploadAsset, updateDirty],
  );

  const onUpdateAsset = useCallback(
    (upload: TemporaryUploadFile) => {
      updateTemporaryAssets(upload);
      updateDirty(true);
    },
    [updateTemporaryAssets, updateDirty],
  );

  const removeTemporaryAsset = useCallback(
    (tempAsset: TemporaryUploadFile) => deleteAssetAndRemove(tempAsset),
    [deleteAssetAndRemove],
  );

  useEffect(() => {
    form?.setFields([
      {
        name: PartialOrLayoutHelpers.getAliasFieldName(),
        value: aliasValue,
      },
    ]);
  }, [aliasValue, form]);

  useEffect(() => {
    if (data?.partial) {
      setAliasValue(data.partial.alias);
    }
    if (data) {
      setSelectedTemplateModelType(data[data.type].template.modelType);
      setAliasTouched(true);
    }
  }, [data]);

  const selectedTemplate = templateEngines.items.find(
    (item) => item.id === TEMPLATE_ENGINE,
  );

  return (
    <DirtyDataGuard dirty={dirty} onNavigate={() => updateDirty(false)}>
      <Skeleton loading={loading} active>
        <Spin spinning={saving}>
          {!loading && (editMode ? !!data : true) && (
            <>
              {editMode && (
                <ModifiedInfoDetailsPanel
                  modifiedInfo={data?.[data?.type]?.template?.modifiedInfo}
                  onDelete={deletePartialOrLayout}
                  saving={saving}
                  canDelete={data?.[data?.type]?.template?.canDelete}
                  revisionId={data?.[data?.type]?.template?.revisionId}
                  confirmationTitleKey={`admin.communication.${data?.type}.delete`}
                />
              )}

              <Form
                onFinish={(values) => {
                  onSubmit(values);
                }}
                onFinishFailed={(err: ValidateErrorEntity) => {
                  UIUtils.scrollToFirstErrorWithOffset(form, err);
                }}
                form={form}
                onValuesChange={onValuesChange}
                initialValues={data}
              >
                <GeneralFormItemV4
                  name={PartialOrLayoutHelpers.getNameFieldName(fragmentType)}
                  {...layout}
                  labelKey={"common.name"}
                  errorLabelKey={"common.name"}
                  required
                >
                  <Input
                    onChange={(e) => {
                      if (!aliasTouched)
                        setAliasValue(
                          CommunicationAdminHelpers.getAliasName(
                            e.target.value,
                            MAX_ALIAS_LENGTH,
                          ),
                        );
                    }}
                  />
                </GeneralFormItemV4>

                {fragmentType === "partial" && (
                  <GeneralFormItemV4
                    shouldUpdate={true}
                    name={PartialOrLayoutHelpers.getAliasFieldName()}
                    {...layout}
                    rules={[
                      {
                        max: MAX_ALIAS_LENGTH,
                        message: k2Intl?.formatMessage({
                          localeKey: "admin.communication.alias.maxLength",
                          values: {
                            max: MAX_ALIAS_LENGTH,
                          },
                        }),
                      },
                    ]}
                    label={
                      <>
                        <span>
                          {k2Intl?.formatMessage({
                            localeKey: "admin.communication.alias",
                          })}
                        </span>
                        <span style={{ paddingLeft: 5 }}>
                          <K2Tooltip
                            title={
                              aliasValue
                                ? k2Intl?.formatMessage({
                                    localeKey: `admin.communication.${entityType}.shortName.explanation`,
                                    values: {
                                      name: `{{>${CommunicationAdminHelpers.getAliasName(
                                        aliasValue,
                                        MAX_ALIAS_LENGTH,
                                      )}}}`,
                                    },
                                  })
                                : k2Intl?.formatMessage({
                                    localeKey: `admin.communication.empty.shortName.explanation`,
                                  })
                            }
                          >
                            <QuestionCircleTwoTone />
                          </K2Tooltip>
                        </span>
                      </>
                    }
                    errorLabelKey={"admin.communication.alias"}
                    required
                  >
                    <Input
                      onChange={(e) => {
                        setAliasValue(e.target.value);
                        setAliasTouched(true);
                      }}
                    />
                  </GeneralFormItemV4>
                )}

                <GeneralFormItemV4
                  name={PartialOrLayoutHelpers.getModelTypeFieldName(
                    fragmentType,
                  )}
                  {...layout}
                  labelKey={"admin.communication.mergeModelTypes"}
                  errorLabelKey={"admin.communication.mergeModelTypes"}
                >
                  <MergeModelsSelector
                    onChange={setSelectedTemplateModelType}
                  />
                </GeneralFormItemV4>

                {entityType !== "sms" && (
                  <GeneralFormItemV4
                    name={PartialOrLayoutHelpers.getStylesheetAssetsFieldName(
                      fragmentType,
                    )}
                    {...layout}
                    labelKey={"admin.communication.stylesheets"}
                    errorLabelKey={"admin.communication.stylesheets"}
                  >
                    <StylesheetSelector />
                  </GeneralFormItemV4>
                )}

                <PreviewLink>
                  <FakeLink onClick={() => onPreview(form?.getFieldsValue())}>
                    <K2Message localeKey={"common.preview"} />
                  </FakeLink>
                </PreviewLink>
                <GeneralFormItemV4
                  name={PartialOrLayoutHelpers.getContentFieldName(
                    fragmentType,
                  )}
                  labelCol={layout.labelCol}
                  wrapperCol={{ xs: 24 }}
                  errorLabelKey={"admin.communication.content"}
                  required
                  labelKey={"admin.communication.content"}
                  extraLabelKey={
                    entityType === "sms"
                      ? "admin.communication.content.help.sms"
                      : "admin.communication.content.help"
                  }
                >
                  <TextArea rows={14} />
                </GeneralFormItemV4>

                {fragmentType === "layout" && (
                  <LayoutExplanation
                    template={selectedTemplate}
                    loading={!selectedTemplate}
                    k2Intl={k2Intl}
                  />
                )}

                <VariableList
                  templateModelTypeId={selectedTemplateModelType}
                  k2Intl={k2Intl}
                  pageType={fragmentType}
                  entityType={entityType}
                />

                {entityType !== "sms" && (
                  <MediaAssetsSelector
                    templateId={data ? data[data.type].template.id : undefined}
                    pageType={fragmentType}
                    assetTypes={assetTypes.items}
                    onUpload={onUpload}
                    updateTemporaryAssets={onUpdateAsset}
                    removeTemporaryAsset={removeTemporaryAsset}
                    temporaryUploadFiles={temporaryUploadFiles}
                    clearTemporaryAssets={clearTemporaryAssets}
                    updateDirty={updateDirty}
                    tempAssets={tempAssets}
                    setTempAssets={setTempAssets}
                    originalAssetIds={
                      data ? data[data.type].template.mediaAssetIds : []
                    }
                  />
                )}

                <GeneralFormItemV4
                  name={PartialOrLayoutHelpers.getNotesFieldName(fragmentType)}
                  labelCol={layout.labelCol}
                  wrapperCol={{ xs: 24 }}
                  labelKey={"common.notes"}
                  errorLabelKey={"common.notes"}
                >
                  <Input />
                </GeneralFormItemV4>

                {errors && errors.length > 0 && (
                  <Row style={{ marginBottom: 20 }}>
                    <Alert
                      showIcon
                      type="error"
                      message={errors?.map?.((e, i) => (
                        <p key={i}>{e}</p>
                      ))}
                    />
                  </Row>
                )}

                {errors && errors.length > 0 && (
                  <Row style={{ marginBottom: 20 }}>
                    <Col span={24}>
                      <Alert
                        showIcon
                        type="error"
                        message={errors?.map?.((e, i) => (
                          <p key={i}>{e}</p>
                        ))}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={24}>
                    <K2Button
                      type={"primary"}
                      htmlType={"submit"}
                      disabled={!dirty}
                    >
                      <K2Message localeKey={"common.save"} />
                    </K2Button>
                    <K2Button
                      toUrl={CommunicationAdminHelpers.getK2RouteForEntityType(
                        entityType,
                      )}
                    >
                      <K2Message localeKey={"common.cancel"} />
                    </K2Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Spin>
      </Skeleton>
    </DirtyDataGuard>
  );
};

export default CreateEditPartialLayoutForm;
