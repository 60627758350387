import React from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import K2Card from "../k2Card";
import Catch from "./functional-error-boundary";
import { AppId } from "appRedux/modules/sharedTypes";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import ErrorResult from "components/k2Widgets/k2ErrorBoundary/ErrorResult";

const Container = styled.div`
  height: 100vh;
  margin: 50px 0;
`;

const CenterRow = styled(Row)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`;

type Props = {
  children: React.ReactNode;
  appId: AppId;
  k2Intl: InjectedK2Intl;
};

const K2ErrorBoundary = Catch(function K2ErrorBoundary(
  props: Props,
  error?: Error,
) {
  if (error) {
    return (
      <Container>
        <CenterRow>
          <Col span={22}>
            <K2Card shadow>
              <div className="n3o-page-error-container n3o-mt-5">
                <div className="n3o-page-error-content n3o-text-center">
                  <ErrorResult
                    error={error}
                    k2Intl={props.k2Intl}
                    appId={props.appId}
                  />
                </div>
              </div>
            </K2Card>
          </Col>
        </CenterRow>
      </Container>
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
});

export default K2ErrorBoundary;
