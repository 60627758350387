import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const TaskNote: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14556 3.5999C3.13669 3.61235 3.12499 3.63697 3.12499 3.6749V16.6249C3.12499 16.6628 3.13669 16.6875 3.14556 16.6999H8.19392C8.66336 16.6999 9.04392 17.0805 9.04392 17.5499V19.0602L10.9488 16.9764C11.1098 16.8002 11.3375 16.6999 11.5762 16.6999H20.8523C20.8612 16.6875 20.8729 16.6628 20.8729 16.6249V3.6749C20.8729 3.63697 20.8612 3.61235 20.8523 3.5999H3.14556ZM1.42499 3.6749C1.42499 2.76804 2.11389 1.8999 3.12055 1.8999H20.8773C21.884 1.8999 22.5729 2.76805 22.5729 3.6749V16.6249C22.5729 17.5318 21.884 18.3999 20.8773 18.3999H11.9508L8.8213 21.8234C8.58465 22.0823 8.21329 22.1692 7.88632 22.0423C7.55935 21.9154 7.34392 21.6006 7.34392 21.2499V18.3999H3.12055C2.11389 18.3999 1.42499 17.5318 1.42499 16.6249V3.6749ZM5.65308 7.3749C5.65308 6.90546 6.03363 6.5249 6.50308 6.5249H17.4954C17.9648 6.5249 18.3454 6.90546 18.3454 7.3749C18.3454 7.84434 17.9648 8.2249 17.4954 8.2249H6.50308C6.03363 8.2249 5.65308 7.84434 5.65308 7.3749ZM5.65308 11.9999C5.65308 11.5305 6.03363 11.1499 6.50308 11.1499H17.4954C17.9648 11.1499 18.3454 11.5305 18.3454 11.9999C18.3454 12.4693 17.9648 12.8499 17.4954 12.8499H6.50308C6.03363 12.8499 5.65308 12.4693 5.65308 11.9999Z"
      fill={fill}
    />
  </svg>
);
