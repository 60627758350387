import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import {
  DonationItemStatusesLookups,
  DonationTypesLookups,
  TaxReliefRatesLookups,
} from "appRedux/modules/lookups/types";
import ApplicationState from "appRedux/types";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import {
  clearFundDimensions,
  fetchFundDimensions,
} from "routes/admin/modules/actions";

import * as actions from "./modules/actions";
import reducer from "./modules/reducer";
import saga from "./modules/sagas";

const mapStateToProps = (state: ApplicationState) => ({
  baseCurrency: state.subscription.users.k2Subscription.baseCurrency,
  fundDimensions: state.addEditDonationItem.fundDimensions.data,
  loadingFundDimensions: state.addEditDonationItem.fundDimensions.loading,
  selectedDonationItem: state.addEditDonationItem.selectedDonationItem,
  editStatus: state.addEditDonationItem.editStatus,
  loading: state.addEditDonationItem.loading,
  serverError: state.addEditDonationItem.serverError,
  dirty: state.addEditDonationItem.dirty,
});

const mapDispatchToProps = {
  deleteDonationItem: actions.deleteDonationItem,
  updateDonationItem: actions.updateDonationItem,
  fetchSelectedDonationItem: actions.fetchSelectedDonationItem,
  clearDonationItemEditStatus: actions.clearDonationItemEditStatus,
  createDonationItem: actions.createDonationItem,
  clearCreateDonationItemError: actions.clearCreateDonationItemError,
  clearSelectedDonationItem: actions.clearSelectedDonationItem,
  fetchSubscriptionFundDimensions: fetchFundDimensions,
  clearSubscriptionFundDimensions: clearFundDimensions,
  setFormDirty: actions.setFormDirty,
  clearFormDirty: actions.clearFormDirty,
};

const withSaga = injectSaga({ key: "addEditDonationItem", saga });
const withReducer = injectReducer({ key: "addEditDonationItem", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof withConnect>;

export interface DonationItemProps
  extends PropsFromRedux,
    RouteComponentProps<{ donationItemId?: string }>,
    InjectedK2IntlProps {
  taxReliefRates: TaxReliefRatesLookups;
  donationItemStatuses: DonationItemStatusesLookups;
  donationTypes: DonationTypesLookups;
}

export default compose(withSaga, withReducer, withConnect);
