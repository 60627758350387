import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const RegularGiving: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.37114 2.90406C7.02687 2.79774 7.69849 2.84879 8.33063 3.053C8.96276 3.25722 9.53767 3.6091 10.0073 4.07896L11.3993 5.47009C11.7312 5.80178 12.2691 5.80178 12.601 5.47009L13.9934 4.0786C14.463 3.60874 15.0375 3.25722 15.6696 3.053C16.3018 2.84879 16.9734 2.79774 17.6291 2.90406C18.2849 3.01038 18.906 3.27102 19.4412 3.6645C19.9764 4.05795 20.4104 4.57294 20.7075 5.16704C21.0009 5.75434 21.1526 6.4023 21.1501 7.05884C21.1477 7.71539 20.9913 8.3622 20.6934 8.9473C20.4804 9.36566 20.647 9.87745 21.0653 10.0904C21.4837 10.3034 21.9955 10.1369 22.2084 9.71851C22.6269 8.89642 22.8467 7.98762 22.8501 7.06516C22.8535 6.14269 22.6404 5.23212 22.2281 4.40694C21.8105 3.57181 21.2004 2.84787 20.4481 2.29482C19.6958 1.74176 18.8229 1.37541 17.9012 1.22597C16.9795 1.07654 16.0355 1.14829 15.147 1.43532C14.2587 1.7223 13.4509 2.21662 12.7909 2.87685L12.0001 3.66716L11.209 2.87649C10.549 2.21626 9.74156 1.7223 8.85322 1.43532C7.96472 1.14829 7.02073 1.07654 6.09906 1.22597C5.17738 1.37541 4.30442 1.74176 3.55214 2.29482C2.79985 2.84787 2.18977 3.57182 1.7722 4.40694C1.21902 5.51325 1.02798 6.76557 1.22615 7.9865C1.42382 9.2043 1.99872 10.3293 2.86968 11.2029L8.02901 16.5877C8.35378 16.9267 8.89185 16.9382 9.23081 16.6134C9.56978 16.2886 9.58129 15.7506 9.25652 15.4116L4.09112 10.0204L4.07835 10.0074C3.45598 9.38514 3.0452 8.58283 2.90419 7.71413C2.76319 6.84542 2.89913 5.95436 3.29273 5.16721C3.58982 4.57304 4.02386 4.05799 4.55909 3.6645C5.09432 3.27102 5.7154 3.01038 6.37114 2.90406Z"
      fill={fill}
    />
    <path
      d="M13.1079 18.8502H14.6641C15.1335 18.8502 15.5141 18.4696 15.5141 18.0002C15.5141 17.5307 15.1335 17.1502 14.6641 17.1502H11.9507C11.9398 17.15 11.9288 17.15 11.9178 17.1502H11.3307C10.8612 17.1502 10.4807 17.5307 10.4807 18.0002V21.3336C10.4807 21.803 10.8612 22.1836 11.3307 22.1836C11.8001 22.1836 12.1807 21.803 12.1807 21.3336V20.5685C12.4098 20.8687 12.669 21.1476 12.9555 21.4003C13.9137 22.2454 15.1241 22.7507 16.3988 22.8377C17.6735 22.9247 18.9413 22.5885 20.0055 21.8814C21.0696 21.1743 21.8707 20.1358 22.2843 18.9269C22.4363 18.4828 22.1995 17.9995 21.7553 17.8475C21.3112 17.6955 20.8279 17.9324 20.6759 18.3766C20.3834 19.2313 19.817 19.9656 19.0646 20.4655C18.3122 20.9655 17.4158 21.2031 16.5146 21.1416C15.6133 21.0801 14.7575 20.7229 14.08 20.1253C13.6726 19.766 13.3434 19.3319 13.1079 18.8502Z"
      fill={fill}
    />
    <path
      d="M14.2644 12.8682C15.0168 12.3682 15.9132 12.1306 16.8145 12.1921C17.7158 12.2536 18.5715 12.6109 19.249 13.2084C19.6564 13.5677 19.9856 14.0017 20.2211 14.4835H18.6641C18.1947 14.4835 17.8141 14.864 17.8141 15.3335C17.8141 15.8029 18.1947 16.1835 18.6641 16.1835H21.3761C21.3885 16.1838 21.401 16.1838 21.4135 16.1835H21.9975C22.4669 16.1835 22.8475 15.8029 22.8475 15.3335V12.0001C22.8475 11.5307 22.4669 11.1501 21.9975 11.1501C21.528 11.1501 21.1475 11.5307 21.1475 12.0001V12.7641C20.9186 12.4643 20.6597 12.1858 20.3735 11.9334C19.4153 11.0883 18.2049 10.583 16.9302 10.496C15.6555 10.409 14.3877 10.7452 13.3236 11.4523C12.2594 12.1594 11.4584 13.1979 11.0447 14.4068C10.8927 14.8509 11.1296 15.3342 11.5737 15.4862C12.0179 15.6382 12.5012 15.4013 12.6531 14.9572C12.9456 14.1025 13.512 13.3682 14.2644 12.8682Z"
      fill={fill}
    />
  </svg>
);
