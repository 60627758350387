import produce from "immer";
import { PdfTemplateState } from "./types";
import { ActionTypes, PdfTemplateAction } from "./actionTypes";

const initialState: PdfTemplateState = {
  data: null,
  editStatus: null,
  error: null,
  loading: false,
  saving: false,
  temporaryMediaUploadFiles: [],
  dirty: false,
  temporaryEpilogueUploadFile: null,
  temporaryPrologueUploadFile: null,
};

const emailTemplatesReducer = (
  state = initialState,
  action: PdfTemplateAction,
) =>
  produce(state, (draft: PdfTemplateState) => {
    switch (action.type) {
      case ActionTypes.FETCH_PDF_TEMPLATE:
        draft.loading = true;
        break;
      case ActionTypes.FETCH_PDF_TEMPLATE_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case ActionTypes.FETCH_PDF_TEMPLATE_SUCCESS:
        draft.loading = false;
        draft.data = action.data;
        break;
      case ActionTypes.CLEAR_PDF_TEMPLATE:
        return { ...initialState };

      case ActionTypes.CLEAR_PDF_TEMPLATE_EDIT_STATUS:
        draft.editStatus = null;
        break;
      case ActionTypes.CLEAR_PDF_TEMPLATE_ERROR:
        draft.error = null;
        break;

      case ActionTypes.CREATE_PDF_TEMPLATE:
        draft.saving = true;
        break;
      case ActionTypes.CREATE_PDF_TEMPLATE_ERROR:
      case ActionTypes.CREATE_PDF_TEMPLATE_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;
      case ActionTypes.UPDATE_PDF_TEMPLATE:
        draft.saving = true;
        break;
      case ActionTypes.UPDATE_PDF_TEMPLATE_ERROR:
      case ActionTypes.UPDATE_PDF_TEMPLATE_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.DELETE_PDF_TEMPLATE:
        draft.saving = true;
        break;
      case ActionTypes.DELETE_PDF_TEMPLATE_ERROR:
      case ActionTypes.DELETE_PDF_TEMPLATE_SUCCESS:
        draft.saving = false;
        draft.editStatus = action.editStatus;
        break;

      case ActionTypes.UPDATE_TEMPORARY_ASSETS:
        if (
          draft.temporaryMediaUploadFiles.find(
            (u) => u.uid === action.upload.uid,
          )
        ) {
          draft.temporaryMediaUploadFiles = draft.temporaryMediaUploadFiles?.map?.(
            (u) => {
              if (u.uid === action.upload.uid) return action.upload;
              else return u;
            },
          );
        } else {
          draft.temporaryMediaUploadFiles = [
            ...draft.temporaryMediaUploadFiles,
            action.upload,
          ];
        }
        break;
      case ActionTypes.REMOVE_TEMPORARY_ASSET:
        draft.temporaryMediaUploadFiles = draft.temporaryMediaUploadFiles.filter(
          (f) => f.uid !== action.temporaryAsset.uid,
        );
        break;

      case ActionTypes.UPDATE_EPILOGUE_OR_PROLOGUE:
        if (action.logueType === "epilogue") {
          draft.temporaryEpilogueUploadFile = action.upload;
        } else if (action.logueType === "prologue") {
          draft.temporaryPrologueUploadFile = action.upload;
        }
        break;
      case ActionTypes.CLEAR_EPILOGUE_OR_PROLOGUE:
      case ActionTypes.REMOVE_EPILOGUE_OR_PROLOGUE:
        if (action.logueType === "epilogue") {
          draft.temporaryEpilogueUploadFile = null;
        } else if (action.logueType === "prologue") {
          draft.temporaryPrologueUploadFile = null;
        }
        break;

      case ActionTypes.CLEAR_TEMPORARY_ASSETS:
        draft.temporaryMediaUploadFiles = [];
        break;

      case ActionTypes.UPDATE_DIRTY:
        draft.dirty = action.dirty;
        break;
    }
  });

export default emailTemplatesReducer;
