import React, { FC, useEffect, useState } from "react";

import { DonationItemRes } from "@n3oltd/k2.donations.sdk.donation-items/esm";
import { FundStructureRevisionRes } from "@n3oltd/k2.subscriptions.sdk.funds/esm";
import { Currency } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { Alert, Switch } from "antd";
import { FormInstance } from "antd/es/form";

import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { DonationItemAdminHelpers } from "routes/admin/donationItemsCreateEdit/helpers";

import { AdvancePricingFormItem, InputCurrencyFormItem } from "../../formItems";
import GeneralFormItemV4 from "../../formItems/GeneralFormItemV4";
import { K2Card, K2Checkbox, K2Message } from "../../k2Widgets";

interface Props extends InjectedK2IntlProps {
  initialValues?: DonationItemRes;
  fundDimensionsData: FundStructureRevisionRes;
  form: FormInstance;
  baseCurrency: Currency;
  pricingRuleErrors?: string[];
}

const PricingForm: FC<Props> = ({
  initialValues,
  form,
  fundDimensionsData,
  baseCurrency,
  k2Intl,
  pricingRuleErrors,
}) => {
  const priceSelected = !!initialValues?.pricing;
  const [providePrice, setProvidePrice] = useState<boolean>(priceSelected);

  useEffect(() => {
    setProvidePrice(priceSelected);
  }, [priceSelected]);

  return (
    <K2Card
      bordered={false}
      shadow={true}
      localeKey={"admin.pricing"}
      style={{ marginBottom: 25 }}
    >
      <div>
        <GeneralFormItemV4
          shouldUpdate
          name={DonationItemAdminHelpers.priceProvidedFieldName}
          valuePropName={"checked"}
        >
          <K2Checkbox
            onChange={(e) => setProvidePrice(e.target.checked)}
            checked={true}
          >
            <K2Message localeKey={"admin.donationItem.suggestPrice"} />
          </K2Checkbox>
        </GeneralFormItemV4>

        {!providePrice ? (
          <Alert
            showIcon={true}
            message={k2Intl?.formatMessage({
              localeKey: "admin.donationItem.anyPrice.explanation",
            })}
          />
        ) : (
          <>
            <InputCurrencyFormItem
              min={0}
              errorTitle={k2Intl?.formatMessage({
                localeKey: "admin.donationItemsAmount",
              })}
              currentForm={form}
              formItemProps={{
                name: DonationItemAdminHelpers.priceAmountFieldName,
              }}
              labelKey="admin.donationItemsAmount"
              initialValue={initialValues?.pricing?.price?.amount || 0}
              formItemLayout={{
                labelCol: { span: 24 },
                wrapperCol: { xs: 12, md: 6 },
              }}
              currency={baseCurrency}
            />

            <div className={"ant-form-item-label"}>
              <K2Message localeKey={"admin.advancedPricingRules"} />
              {":"}
            </div>

            <AdvancePricingFormItem
              form={form}
              fieldName={DonationItemAdminHelpers.pricingRulesFieldName}
              dataSource={fundDimensionsData}
              initialValues={initialValues?.pricing?.rules}
              currency={baseCurrency}
            />

            <GeneralFormItemV4
              labelCol={{ span: 24 }}
              wrapperCol={{ xs: 12, md: 6 }}
              name={DonationItemAdminHelpers.priceLockedFieldName}
              colon
              valuePropName={"checked"}
              labelKey={"admin.fixPrice"}
              labelTooltipKey={"admin.fixPriceInfoMessage"}
            >
              <Switch
                checkedChildren={k2Intl?.formatMessage({
                  localeKey: "admin.fixed",
                })}
                unCheckedChildren={k2Intl?.formatMessage({
                  localeKey: "admin.notFixed",
                })}
              />
            </GeneralFormItemV4>
          </>
        )}

        {pricingRuleErrors?.length > 0 && (
          <Alert
            type="error"
            showIcon
            message={pricingRuleErrors?.map?.((e, i) => (
              <p key={i}>{e}</p>
            ))}
          />
        )}
      </div>
    </K2Card>
  );
};

export default injectK2Intl(PricingForm);
