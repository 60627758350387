import React from "react";

import { Redirect, Route, Switch } from "react-router";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import { asyncComponent } from "components/utils";
import { RequireAuth } from "containers/app/views";

import NewEditEmailTemplate from "./newEditEmailTemplate";
import NewEditPartialOrLayout from "./newEditPartialOrLayout";
import NewEditPdfStationery from "./newEditPdfStationery";
import NewEditPdfTemplate from "./newEditPdfTemplate";
import NewEditSMSTemplate from "./newEditSMSTemplate";
import OutboxesSubRouter from "./outboxes";

const {
  communicationsPDF,
  communicationsEmail,
  sharedAssets,
  communicationsActions,
  communicationsActionEdit,
  communicationsActionCreate,
  privacyStatements,
  communicationsSMS,
  communicationsPrintQueues,
} = K2Routes;

const emailCommunicationSettings = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "EmailCommunicationAdmin" */ "./email"),
  ),
  "communicationsEmail",
);

const pdfCommunicationSettings = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "PdfCommunicationAdmin" */ "./pdf"),
  ),
  "communicationsPDF",
);

const SMSCommunicationSettings = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "SMSCommunicationAdmin" */ "./sms"),
  ),
  "communicationsSMS",
);

const communicationActionsManagement = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "CommunicationActions" */ "./communicationActions"
      ),
  ),
  "communicationsActions",
);

const CreateCommunicationAction = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "CreateCommunicationAction" */ "./newEditCommunicationAction"
      ),
  ),
  "communicationsActionCreate",
);

const EditCommunicationAction = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "EditCommunicationAction" */ "./newEditCommunicationAction"
      ),
  ),
  "communicationsActionEdit",
);

const sharedAssetsManagement = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "SharedAssets" */ "./sharedAssets"),
  ),
  "sharedAssets",
);

const privacyStatementsManagement = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "PrivacyStatements" */ "./privacyStatements"),
  ),
  "privacyStatements",
);

const PrintQueues = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "PrintQueues" */ "./printQueues"),
  ),
  "communicationsPrintQueues",
);
const CommunicationSubRouter = () => (
  <Switch>
    <Route path={K2Routes.outboxes} component={OutboxesSubRouter} />
    <Route
      exact
      path={K2Routes.communicationsNewEmailPartial}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsEditEmailPartial}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsNewEmailLayout}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsEditEmailLayout}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsNewEmailTemplate}
      component={NewEditEmailTemplate}
    />
    <Route
      exact
      path={K2Routes.communicationsEditEmailTemplate}
      component={NewEditEmailTemplate}
    />
    <Route
      exact
      path={K2Routes.communicationsEmailTemplates}
      component={emailCommunicationSettings}
    />
    <Route path={communicationsEmail} component={emailCommunicationSettings} />
    <Route
      exact
      path={K2Routes.communicationsNewPdfPartial}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsEditPdfPartial}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsNewPdfLayout}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsEditPdfLayout}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsNewPdfStationery}
      component={NewEditPdfStationery}
    />
    <Route
      exact
      path={K2Routes.communicationsEditPdfStationery}
      component={NewEditPdfStationery}
    />
    <Route
      exact
      path={K2Routes.communicationsNewPdfTemplate}
      component={NewEditPdfTemplate}
    />
    <Route
      exact
      path={K2Routes.communicationsEditPdfTemplate}
      component={NewEditPdfTemplate}
    />
    <Route path={communicationsPDF} component={pdfCommunicationSettings} />

    <Route
      exact
      path={K2Routes.communicationsNewSMSPartial}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsEditSMSPartial}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsNewSMSLayout}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsEditSMSLayout}
      component={NewEditPartialOrLayout}
    />
    <Route
      exact
      path={K2Routes.communicationsNewSMSTemplate}
      component={NewEditSMSTemplate}
    />
    <Route
      exact
      path={K2Routes.communicationsEditSMSTemplate}
      component={NewEditSMSTemplate}
    />
    <Route path={communicationsSMS} component={SMSCommunicationSettings} />
    <Route exact path={sharedAssets} component={sharedAssetsManagement} />
    <Route
      exact
      path={privacyStatements}
      component={privacyStatementsManagement}
    />
    <Route
      exact
      path={communicationsActions}
      component={communicationActionsManagement}
    />
    <Route
      exact
      path={communicationsActionCreate}
      component={CreateCommunicationAction}
    />
    <Route
      exact
      path={communicationsActionEdit}
      component={EditCommunicationAction}
    />
    <Route exact path={communicationsPrintQueues} component={PrintQueues} />
    <Redirect to={communicationsEmail} />
  </Switch>
);

export default CommunicationSubRouter;
