import { LookupType as DonationLookups } from "@n3oltd/k2.donations.sdk.lookups/esm";

import injectLookups from "appRedux/utils/injectLookups";

import DonationItemsList from "./DonationItemsList";
import composed from "./connect";

const withLookups = injectLookups(DonationItemsList, [
  DonationLookups.DonationItemStatuses,
]);

export default composed(withLookups);
