import { ClockInStatus } from "@n3oltd/k2.calls.sdk.agents";
import produce from "immer";

import { ActionTypes, CallCenterAction } from "./actionTypes";
import { CallCenterState } from "./types";

const initialState: CallCenterState = {
  agentLoading: false,
  agentError: null,
  agent: null,
  signedIn: false,

  signingIn: false,
  signInError: null,
  signInEditStatus: null,

  signingOut: false,
  signOutError: null,
  signOutEditStatus: null,

  takingBreak: false,
  takeBreakError: null,
  takeBreakEditStatus: null,

  resumingShift: false,
  resumeShiftError: null,
  resumeShiftEditStatus: null,

  updatingAgent: false,
  updatingAgentError: null,
};

const callCenterReducer = (
  state = initialState,
  action: CallCenterAction,
): CallCenterState =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.FETCH_AGENT:
        draft.agentLoading = true;
        break;

      case ActionTypes.FETCH_AGENT_SUCCESS:
        draft.agentLoading = false;
        draft.agent = action.agent;
        draft.signedIn =
          action.agent.status === ClockInStatus.In || action.agent.isOnBreak;
        break;

      case ActionTypes.FETCH_AGENT_ERROR:
        draft.agentLoading = false;
        draft.agentError = action.error;
        break;

      case ActionTypes.AGENT_SIGN_IN:
        draft.signingIn = true;
        break;

      case ActionTypes.AGENT_SIGN_IN_SUCCESS:
        draft.signingIn = false;
        draft.signedIn = true;
        draft.agent = action.agent;
        draft.signInEditStatus = action.editStatus;
        break;

      case ActionTypes.AGENT_SIGN_IN_ERROR:
        draft.signingIn = false;
        draft.signInError = action.error;
        break;

      case ActionTypes.AGENT_SIGN_IN_CLEAR_ERROR:
        draft.signInError = null;
        break;

      case ActionTypes.AGENT_SIGN_IN_CLEAR_EDIT_STATUS:
        draft.signInEditStatus = null;
        break;

      case ActionTypes.AGENT_SIGN_OUT:
        draft.signingOut = true;
        break;

      case ActionTypes.AGENT_SIGN_OUT_SUCCESS:
        draft.signingOut = false;
        // Don't set the agent to Signed Out until acknowledged, otherwise call center components close
        // before we can show the success message
        draft.agent = {
          ...action.agent,
          status: ClockInStatus.In,
          isSignedIn: true,
        };
        draft.signOutEditStatus = action.editStatus;
        break;

      case ActionTypes.AGENT_SIGN_OUT_ERROR:
        draft.signingOut = false;
        draft.signOutError = action.error;
        break;

      case ActionTypes.AGENT_SIGN_OUT_CLEAR_ERROR:
        draft.signOutError = null;
        break;

      case ActionTypes.AGENT_SIGN_OUT_CLEAR_EDIT_STATUS:
        draft.signOutEditStatus = null;
        // Only setting the agent to Signed Out once acknowledged, otherwise call center components close
        // before we can show the success message
        draft.agent.status = ClockInStatus.Out;
        draft.agent.isSignedIn = false;
        draft.signedIn = false;
        break;

      case ActionTypes.CLEAR_AGENT:
        draft.agent = null;
        break;

      case ActionTypes.CLEAR_AGENT_ERROR:
        draft.agentError = null;
        break;

      case ActionTypes.AGENT_TAKE_BREAK:
        draft.takingBreak = true;
        break;
      case ActionTypes.AGENT_TAKE_BREAK_ERROR:
        draft.takingBreak = false;
        draft.takeBreakError = action.error;
        break;
      case ActionTypes.AGENT_TAKE_BREAK_SUCCESS:
        draft.takingBreak = false;
        draft.agent = action.updatedAgent;
        draft.takeBreakEditStatus = action.editStatus;
        break;
      case ActionTypes.AGENT_TAKE_BREAK_CLEAR_EDIT_STATUS:
        draft.takeBreakEditStatus = null;
        break;

      case ActionTypes.AGENT_RESUME_SHIFT:
        draft.resumingShift = true;
        break;
      case ActionTypes.AGENT_RESUME_SHIFT_ERROR:
        draft.resumingShift = false;
        draft.resumeShiftError = action.error;
        break;
      case ActionTypes.AGENT_RESUME_SHIFT_SUCCESS:
        draft.resumingShift = false;
        draft.agent = action.updatedAgent;
        draft.resumeShiftEditStatus = action.editStatus;
        break;
      case ActionTypes.AGENT_RESUME_SHIFT_CLEAR_EDIT_STATUS:
        draft.resumeShiftEditStatus = null;
        break;

      case ActionTypes.AGENT_UPDATE_TEAM_AND_CAMPAIGN:
        draft.updatingAgent = true;
        break;

      case ActionTypes.AGENT_UPDATE_TEAM_AND_CAMPAIGN_ERROR:
        draft.updatingAgent = false;
        draft.updatingAgentError = action.error;
        break;

      case ActionTypes.AGENT_UPDATE_TEAM_AND_CAMPAIGN_SUCCESS:
        draft.updatingAgent = false;
        draft.agent = action.updatedAgent;
        break;

      case ActionTypes.AGENT_UPDATE_CLEAR_ERROR:
        draft.updatingAgentError = null;
        break;
    }
  });

export default callCenterReducer;
