import React, { FC } from "react";

import { ButtonProps } from "antd/es/button/button";

import { K2Button, K2Message } from "components/k2Widgets/index";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { showConfirm } from "components/utils/Confirmation";

interface IProps extends InjectedK2IntlProps {
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  /** (required) locale key for Ok button, default 'common.ok' */
  okTextKey?: string;
  /** (required) locale key for Cancel button, default 'common.cancel' */
  cancelTextKey?: string;
  requiresCloseConfirmation?: boolean;
  confirmationTitleKey?: string;
  confirmationContentKey?: string;
  hideCancel?: boolean;
}

const CustomButtons: FC<IProps> = ({
  okButtonProps,
  okTextKey,
  cancelButtonProps,
  cancelTextKey,
  requiresCloseConfirmation,
  confirmationContentKey,
  confirmationTitleKey,
  k2Intl,
  hideCancel = false,
}) => {
  const cancelWithConfirm = () => {
    showConfirm({
      titleKey: confirmationTitleKey || "common.areYouSure",
      contentKey: confirmationContentKey || "common.changesNotSaved",
      onOk: cancelButtonProps.onClick,
      okTextKey: "common.yes",
      k2Intl,
    });
  };

  return (
    <div className={"footer-custom-buttons"}>
      {!hideCancel && (
        <K2Button
          {...cancelButtonProps}
          onClick={
            cancelButtonProps.onClick
              ? requiresCloseConfirmation
                ? cancelWithConfirm
                : cancelButtonProps.onClick
              : undefined
          }
        >
          {!cancelButtonProps.title && (
            <K2Message localeKey={cancelTextKey || "common.cancel"} />
          )}
        </K2Button>
      )}

      <K2Button type={"primary"} {...okButtonProps}>
        {!okButtonProps.title && (
          <K2Message localeKey={okTextKey || "common.ok"} />
        )}
      </K2Button>
    </div>
  );
};

export default injectK2Intl(CustomButtons);
