import { DonationItemRes } from "@n3oltd/k2.donations.sdk.donation-items/esm";
import { all, put, takeLatest } from "redux-saga/effects";

import { _donationItemsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { DataActions, EditStatus } from "appRedux/modules/sharedTypes";

import * as actionTypes from "./actionTypes";
import * as actions from "./actions";

function* createDonationItem(
  action: ReturnType<typeof actions.createDonationItem>,
) {
  try {
    const response: IApiResponse<DonationItemRes> = yield K2RestService.toResponse(
      _donationItemsClient.createDonationItem(action.donationItem),
    );

    const editStatus: EditStatus = {
      action: DataActions.save,
      ...response,
    };
    if (response.error) {
      yield put(actions.createDonationItemError(editStatus));
    } else {
      yield put(actions.createDonationItemSuccess(editStatus));
    }
  } catch (e) {
    // Non-API related error
    console.log(e);
  }
}

function* updateDonationItem(
  action: ReturnType<typeof actions.updateDonationItem>,
) {
  const response: IApiResponse<DonationItemRes> = yield K2RestService.toResponse(
    _donationItemsClient.updateDonationItem(
      action.revisionId,
      action.donationItem,
    ),
  );

  const editStatus: EditStatus = {
    action: DataActions.save,
    ...response,
  };
  if (response.error) {
    yield put(actions.updateDonationItemError(editStatus));
  } else {
    yield put(actions.updateDonationItemSuccess(editStatus));
  }
}

function* fetchSelectedDonationItem(
  action: ReturnType<typeof actions.fetchSelectedDonationItem>,
) {
  const response: IApiResponse<DonationItemRes> = yield K2RestService.toResponse(
    _donationItemsClient.getDonationItemById(action.id),
  );

  if (response.error) {
    yield put(actions.fetchSelectedDonationItemError(response.error));
  } else {
    yield put(
      actions.fetchSelectedDonationItemSuccess(response.getResultOrDefault()),
    );
  }
}

function* deleteDonationItem(
  action: ReturnType<typeof actions.deleteDonationItem>,
) {
  const response: IApiResponse<void> = yield K2RestService.toResponse(
    _donationItemsClient.deleteDonationItem(action.id),
  );

  const editStatus: EditStatus<void> = {
    action: DataActions.delete,
    ...response,
  };
  if (response.error) {
    yield put(actions.deleteDonationItemError(editStatus));
  } else {
    yield put(actions.deleteDonationItemSuccess(action.id, editStatus));
  }
}

export default function* donationItemsAdminSaga() {
  yield all([
    takeLatest(actionTypes.CREATE_DONATION_ITEM, createDonationItem),
    takeLatest(actionTypes.UPDATE_DONATION_ITEM, updateDonationItem),
    takeLatest(
      actionTypes.FETCH_SELECTED_DONATION_ITEM,
      fetchSelectedDonationItem,
    ),
    takeLatest(actionTypes.DELETE_DONATION_ITEM, deleteDonationItem),
  ]);
}
