import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const ViewDonation: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.24388 4.99185C2.5218 6.50406 2.81408 8.33322 3.94201 9.504L3.94555 9.50768L8.61567 14.4139C8.93933 14.7539 8.92607 15.292 8.58604 15.6156C8.24601 15.9393 7.70799 15.926 7.38432 15.586L2.71588 10.6815C1.08558 8.98766 0.689269 6.39261 1.71157 4.25562L1.71405 4.25043C3.06935 1.46593 6.34617 0.323193 9.04015 1.79117C9.52296 2.05426 9.96392 2.39138 10.349 2.7902L10.9998 3.41811L11.6507 2.7901C13.7897 0.576316 17.2396 0.610717 19.3382 2.86742C19.719 3.27683 20.0377 3.7432 20.2847 4.25019L20.2869 4.25471C20.787 5.29688 21.0612 6.41324 20.8363 7.65178C20.7525 8.11368 20.3101 8.42014 19.8482 8.3363C19.3863 8.25245 19.0798 7.81004 19.1637 7.34814C19.306 6.5638 19.1509 5.81763 18.7554 4.99261C18.581 4.63507 18.3574 4.30911 18.0933 4.02512C16.6549 2.4783 14.3256 2.45925 12.8651 3.97985L12.8539 3.99151L10.9998 5.78038L9.14566 3.9914L9.13435 3.97962C8.86513 3.69911 8.55889 3.46493 8.22673 3.28393C6.43389 2.307 4.2004 3.03005 3.24388 4.99185ZM10.48 10.33C10.48 9.86052 10.8606 9.47996 11.33 9.47996H21.9967C22.4661 9.47996 22.8467 9.86052 22.8467 10.33V20.9966C22.8467 21.4661 22.4661 21.8466 21.9967 21.8466H11.33C10.8606 21.8466 10.48 21.4661 10.48 20.9966V10.33ZM12.18 11.18V20.1466H21.1467V11.18H12.18ZM13.15 13.67C13.15 13.2005 13.5306 12.82 14 12.82H19.3333C19.8028 12.82 20.1833 13.2005 20.1833 13.67C20.1833 14.1394 19.8028 14.52 19.3333 14.52H14C13.5306 14.52 13.15 14.1394 13.15 13.67ZM13.15 16.33C13.15 15.8605 13.5306 15.48 14 15.48H16C16.4694 15.48 16.85 15.8605 16.85 16.33C16.85 16.7994 16.4694 17.18 16 17.18H14C13.5306 17.18 13.15 16.7994 13.15 16.33Z"
      fill={fill}
    />
  </svg>
);
