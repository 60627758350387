import React, { FC, useCallback, useEffect, useState } from "react";

import { LocalizationSettingsRes } from "@n3oltd/k2.subscriptions.sdk.subscriptions/esm";
import { CurrentUserReq, None } from "@n3oltd/k2.users.sdk.users/esm";
import { LocalizationSettingsReq } from "@n3oltd/k2.users.sdk.users/esm";
import { useForm } from "antd/es/form/Form";
import { connect } from "react-redux";

import { _usersClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import * as lookupActions from "appRedux/modules/lookups/actions";
import { usersActions } from "appRedux/modules/subscription";
import IApplicationState from "appRedux/types";
import { UIUtils } from "components/utils";
import { showNotification } from "components/utils/Notification";
import PreferencesView from "routes/preferences/components/PreferencesView";
import { PreferencesStateProps } from "routes/preferences/modules/types";

interface IProps extends PreferencesStateProps, DispatchProps {
  currentUserLocalizationSettings: LocalizationSettingsRes;
}

const Preferences: FC<IProps> = (props) => {
  const { k2Intl, updateGlobalUser } = props;
  const [form] = useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const [overallFormErrors, setOverallFormErrors] = useState<string[]>([]);
  const [resetPasswordUrl, setResetPasswordUrl] = useState<string>(null);

  const updateCurrentUser = useCallback(
    async (req: CurrentUserReq) => {
      setSaving(true);

      const { jobTitle, ...localizationFields } = req;
      const response: IApiResponse<None> = await K2RestService.toResponse(
        _usersClient.updateCurrentUser({
          jobTitle: jobTitle,
          localizationSettings: localizationFields as LocalizationSettingsReq,
        }),
      );

      if (response.error) {
        if (response.error.status === K2StatusCodes.preconditionFailed) {
          const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
            response.error,
            "error",
          );
          if (nonFieldRelatedErrors.length) {
            setOverallFormErrors(
              nonFieldRelatedErrors?.map?.((err) => err.error),
            );
          }

          UIUtils.handleValidationErrors(response.error, form);
        } else {
          UIUtils.handleServerError(k2Intl, response.error);
        }
      } else {
        showNotification({
          type: "success",
          k2Intl: k2Intl,
          titleKey: "common.success.title",
          messageKey: "admin.preferencesUpdated",
        });
        setTouched(false);

        updateGlobalUser();
      }

      setSaving(false);
    },
    [form, updateGlobalUser, k2Intl],
  );

  const language = props.currentUserLocalizationSettings.language;
  const invalidateLookups = props.invalidateLookups;

  const handleSubmit = useCallback(async () => {
    form
      .validateFields()
      .then((values) => {
        updateCurrentUser(values);

        if (values.language !== language) {
          invalidateLookups();
        }
      })
      .catch(console.log);
  }, [language, invalidateLookups, form, updateCurrentUser]);

  const getPreferencesUrl = useCallback(async () => {
    const resp: IApiResponse<string> = await K2RestService.toResponse(
      _usersClient.getPasswordResetUrlForCurrentUser(),
    );

    if (!resp.error) {
      setResetPasswordUrl(resp.getResultOrDefault());
    }
  }, []);

  useEffect(() => {
    getPreferencesUrl();
    // eslint-disable-next-line
  }, []);

  return (
    <PreferencesView
      form={form}
      saving={saving}
      handleSubmit={handleSubmit}
      overallFormErrors={overallFormErrors}
      saveDisabled={!touched}
      dirty={touched}
      setDirty={setTouched}
      resetPasswordUrl={resetPasswordUrl}
      {...props}
    />
  );
};
const mapStateToProps = (state: IApplicationState) => ({
  currentUserLocalizationSettings:
    state.subscription?.users?.currentUserLocalizationSettings,
});

interface DispatchProps {
  updateGlobalUser: typeof usersActions.getAppResources;
  invalidateLookups: typeof lookupActions.invalidateLookupsAll;
}

const mapDispatchToProps: DispatchProps = {
  updateGlobalUser: usersActions.getAppResources,
  invalidateLookups: lookupActions.invalidateLookupsAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
