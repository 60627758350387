import React, { ChangeEvent, FC } from "react";
import { Row, Col, Dropdown, Badge } from "antd";
import TaskSearch from "components/dashboard/tasksList/components/search";
import FilterMenu from "components/dashboard/tasksList/components/filterMenu";
import { dashboardTasksActions } from "appRedux/modules/tasks";
import { FilterCriteria } from "appRedux/modules/tasks/dashboard/types";
import NewTask from "components/dashboard/newTask";
import { K2Button, K2Message } from "components/k2Widgets";
import styled from "styled-components";
import { FilterTwoTone } from "@ant-design/icons/lib";
import { InjectedK2Intl } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";

const N3oDashboardTaskSearchColWrapper = styled(Col)`
  flex-grow: 1;
  padding-right: 10px;
  padding-left: 10px;
`;

interface IProps {
  updateFilters: typeof dashboardTasksActions.updateDashboardFilters;
  canCreateTask: boolean;
  searchValue: string;
  filtersApplied: FilterCriteria;
  updateSearch(event: ChangeEvent<HTMLInputElement>): void;
  showModal(): void;
  closeModal(): void;
  modalVisible: boolean;
  k2Intl: InjectedK2Intl;
}

const TopSection: FC<IProps> = (props: IProps): JSX.Element => {
  const { filtersApplied } = props;

  const { theme } = useAppContext();

  return (
    <Row align={"middle"}>
      {props.canCreateTask && (
        <Col xs={4} sm={3} md={5} lg={6} xl={5} xxl={5} order={1}>
          <K2Button
            style={{ marginTop: 2 }}
            type="primary"
            block
            onClick={props.showModal}
            size={"large"}
            icon={
              <N3oIcon
                icontype={"n3o-task-create"}
                width={16}
                height={16}
                fill={"white"}
              />
            }
            hideTextOnMobile
          >
            <K2Message localeKey="tasks.action.newTask" />
          </K2Button>
          {props.modalVisible && (
            <NewTask onCancel={props.closeModal} visible={props.modalVisible} />
          )}
        </Col>
      )}
      <N3oDashboardTaskSearchColWrapper
        xs={16}
        sm={18}
        md={16}
        lg={16}
        xl={17}
        xxl={17}
        order={2}
      >
        <TaskSearch
          searchValue={props.searchValue}
          onChange={props.updateSearch}
          width={props.canCreateTask ? 18 : 22}
          height={theme.dataEntryHeight}
          placeholder={props.k2Intl?.formatMessage({
            localeKey: "tasks.search.placeholder",
          })}
        />
      </N3oDashboardTaskSearchColWrapper>

      <Col
        xs={4}
        sm={3}
        md={3}
        lg={2}
        xl={2}
        order={3}
        style={{ textAlign: "right", paddingRight: 10 }}
      >
        <Dropdown
          overlay={() => (
            <FilterMenu
              filtersApplied={props.filtersApplied}
              updateFilters={props.updateFilters}
            />
          )}
          trigger={["click"]}
        >
          {Object.values(filtersApplied).filter((v) => v).length ? (
            <div>
              <Badge color={theme.primaryColor} offset={[-37, 8]}>
                <FilterTwoTone
                  twoToneColor={theme.primaryColor}
                  className={"n3o-pointer"}
                  style={{ fontSize: 24 }}
                />
              </Badge>
            </div>
          ) : (
            <div>
              <FilterTwoTone
                twoToneColor={theme.primaryColor}
                className={"n3o-pointer"}
                style={{ fontSize: 24 }}
              />
            </div>
          )}
        </Dropdown>
      </Col>
    </Row>
  );
};

export default TopSection;
