import React, { FC, useEffect, useState } from "react";

import { SecurityPrincipalType } from "@n3oltd/k2.users.sdk.roles";
import { SecurityPrincipalProfile } from "@n3oltd/k2.users.sdk.users";
import { Select } from "antd";
import { useGetSecurityPrincipals } from "hooks";
import _ from "lodash";
import styled from "styled-components";

import { _securityPrincipalsClient } from "appRedux/models/base/K2RestClients";
import SecurityPrincipalAvatar from "components/avatars/securityPrincipalAvatar";
import { K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { SmallTagWithAvatar } from "components/tagWithAvatar";
import { DEFAULT_DELAY } from "constants/appConstants";
import useDebounce from "hooks/useDebounce";

interface UsersAndRolesSelectProps extends InjectedK2IntlProps {
  selectedValues: SecurityPrincipalProfile[];
  onValueChange: (value: SecurityPrincipalProfile[]) => void;
  disabled?: boolean;
  mode?: "roles" | "users" | "rolesAndUsers";
  permissionsRequired?: string[];
  omittedIds?: string[];
  style?: React.CSSProperties;

  // props passed from ant design
  onChange?: (values: string[]) => void;
}

const CustomSelect = styled(Select)`
  && {
    .ant-select-selection--multiple > ul > li,
    .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
      height: 32px;
    }
    .ant-select-selection-item {
      height: 29px;
    }
    .ant-select-item-option {
      .ant-select-item-option-content .ant-tag {
        background: none !important;
      }
    }
  }
`;

const UsersAndRolesSelect: FC<UsersAndRolesSelectProps> = ({
  selectedValues,
  onValueChange,
  k2Intl,
  onChange,
  disabled,
  mode = "rolesAndUsers",
  omittedIds,
  permissionsRequired,
  style,
}) => {
  const [
    securityPrincipals,
    principalsLoading,
    fetchSecurityPrincipals,
    setSecurityPrincipals,
  ] = useGetSecurityPrincipals(k2Intl);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [searchTerm, updateSearch] = useState<string>("");

  const debouncedSearchTerm = useDebounce(searchTerm, DEFAULT_DELAY);

  const selectedValueKeys = selectedValues?.map?.((p) => p.principalId);

  useEffect(() => {
    fetchSecurityPrincipals({
      criteria: {
        name: debouncedSearchTerm,
        type:
          mode === "roles"
            ? [SecurityPrincipalType.Role]
            : mode === "users"
            ? [SecurityPrincipalType.User]
            : undefined,
      },
      omittedIds: omittedIds,
      permissionsRequired: permissionsRequired,
    });
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (omittedIds) {
      if (securityPrincipals.length) {
        setSecurityPrincipals(
          securityPrincipals.filter((x) => !omittedIds.includes(x.principalId)),
        );
      }
    }

    // eslint-disable-next-line
  }, [omittedIds]);

  const handleSelect = (id: string): void => {
    // Add to list of selected values
    const updatedValue = [...selectedValues];
    const principal = securityPrincipals.find((p) => p.principalId === id);
    if (principal) {
      updatedValue.push(principal);
    }
    onValueChange(updatedValue);

    if (securityPrincipals.length === 1) {
      setSelectOpen(false);
    }
  };

  const handleDeselect = (id: string): void => {
    // Remove from list of selected values
    const updatedValue = [...selectedValues];
    const i = updatedValue.findIndex((p) => p.principalId === id);
    if (i > -1) {
      updatedValue.splice(i, 1);
    }
    onValueChange(updatedValue);

    if (securityPrincipals.length === 1) {
      setSelectOpen(false);
    }
  };

  const selectedItemsNotInSearchResults =
    selectedValues?.filter?.((p) => {
      const alreadyInSearchResults = securityPrincipals.find(
        (sp) => sp.principalId === p.principalId,
      );
      return !alreadyInSearchResults;
    }) || [];

  return (
    <CustomSelect
      showSearch
      loading={principalsLoading}
      value={selectedValueKeys}
      dropdownClassName={principalsLoading ? "n3o-dropdown-hidden" : ""}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onChange={onChange}
      mode="multiple"
      onFocus={() => setSelectOpen(true)}
      onBlur={() => setSelectOpen(false)}
      open={selectOpen}
      defaultActiveFirstOption={false}
      filterOption={false}
      disabled={disabled}
      onSearch={updateSearch}
      placeholder={k2Intl.formatMessage({
        localeKey: "common.access.placeholder",
      })}
      style={style}
      getPopupContainer={(triggerNode: HTMLElement) => {
        const element = document.getElementById("route-section");
        return !_.isNull(element) ? element : triggerNode;
      }}
    >
      {securityPrincipals?.map?.((p: SecurityPrincipalProfile) => (
        <Select.Option key={p.principalId} value={p.principalId}>
          <SmallTagWithAvatar>
            <SecurityPrincipalAvatar
              presetId="small"
              size="small"
              profile={p}
            />{" "}
            <span className={"name"}>{p.name}</span>
          </SmallTagWithAvatar>
        </Select.Option>
      ))}

      {securityPrincipals.length === 0 && !principalsLoading && (
        <Select.Option key="fake-key" value="fake-key" disabled>
          {searchTerm.length < 4 ? (
            <K2Message localeKey="common.keepTyping" />
          ) : (
            <K2Message
              localeKey="common.noResults"
              values={{ value: searchTerm }}
            />
          )}
        </Select.Option>
      )}

      {/* Here we create 2 "fake" hidden options so that the initially selected entities display correctly. */}
      {selectedItemsNotInSearchResults?.map?.((p) => (
        <Select.Option
          key={p.principalId}
          value={p.principalId}
          style={{ display: "none" }}
        >
          <SmallTagWithAvatar>
            <SecurityPrincipalAvatar
              presetId="small"
              size="small"
              profile={p}
            />{" "}
            <span className={"name"}>{p.name}</span>
          </SmallTagWithAvatar>
        </Select.Option>
      ))}
    </CustomSelect>
  );
};

export default injectK2Intl(UsersAndRolesSelect);
