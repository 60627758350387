import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { compose } from "redux";
import { injectReducer, injectSaga } from "redux-injectors";

import {
  AssetTypesLookups,
  PageOrientationsLookups,
  PageSizedLookups,
} from "appRedux/modules/lookups/types";
import IApplicationState from "appRedux/types";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";

import {
  clearEditStatus,
  clearLetterhead,
  clearLetterheadError,
  createLetterhead,
  createLetterheadError,
  fetchLetterhead,
  updateDirty,
  updateLetterhead,
} from "./modules/actions";
import reducer from "./modules/reducer";
import saga from "./modules/saga";

const mapStateToProps = (state: IApplicationState) => {
  return state.pdfStationery;
};

const mapDispatchToProps = {
  fetchLetterhead,
  clearEditStatus,
  clearLetterheadError,
  updateLetterhead,
  createLetterhead,
  createLetterheadError,
  updateDirty,
  clearLetterhead,
};

const withSaga = injectSaga({ key: "pdfStationery", saga });
const withReducer = injectReducer({ key: "pdfStationery", reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof withConnect>;

export type NewEditPdfStationeryProps = PropsFromRedux &
  RouteComponentProps<{ stationeryId?: string }> &
  InjectedK2IntlProps & {
    assetTypes: AssetTypesLookups;
    pageOrientations: PageOrientationsLookups;
    pageSizes: PageSizedLookups;
  };

export default compose(withSaga, withReducer, withConnect);
