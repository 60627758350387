import { EmailCompositionReq } from "@n3oltd/karakoram.templates.sdk.email/esm";

export default class EmailTemplateHelpers {
  public static nameFieldName = ["name"];
  public static notesFieldName = ["notes"];

  public static markupFieldName = [
    "content",
    "markup",
  ];

  public static stylesheetIdsFieldName = [
    "stylesheetAssetIds",
  ];

  public static categoryFieldName = [
    "categoryId",
  ];

  public static subjectFieldName = [
    "subject",
    "markup",
  ];

  public static senderNameFieldName = [
    "sender",
    "name",
    "markup",
  ];

  public static senderEmailFieldName = [
    "sender",
    "email",
    "markup",
  ];

  public static templateModelTypesFieldName = [
    "modelType",
  ];

  public static layoutIdFieldName = [
    "layoutId",
  ];
}
