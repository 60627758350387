import React from "react";
import { ActionDropDownItem } from "components/k2Widgets/k2Dropdowns/ActionsDropDown";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { UIUtils } from "components/utils";
import {
  DataActions,
  EditStatus,
  IPagingCriteria,
} from "../../modules/sharedTypes";
import { Dropdown, Menu } from "antd";
import {
  DeleteTwoTone,
  DownOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons/lib";
import variables from "../../../common/themeVariables";
import { K2Message } from "../../../components/k2Widgets";
import { DEFAULT_PAGE_SIZE } from "constants/appConstants";

export type ICommonListProps = InjectedK2IntlProps;

export type RowEditOption = "delete" | "activate" | "deactivate";

export interface RowEditConfiguration {
  itemId?: string;
  type: RowEditOption;
  onClick: () => void;
}

export default class BaseListModel {
  protected defaultPageSize: number = DEFAULT_PAGE_SIZE;
  protected config: ICommonListProps;
  constructor(props: ICommonListProps) {
    this.config = props;
  }

  getDefaultGridInfo(): IPagingCriteria {
    return {
      pageSize: this.defaultPageSize,
      continuationToken: "",
    };
  }

  getGridActions(): ActionDropDownItem[] {
    return [
      { key: "markActive", showConfirm: true },
      { key: "markInactive", showConfirm: true },
    ];
  }

  getGridEditOptions(opts: RowEditConfiguration[]) {
    const renderIcon = (type: RowEditOption) => {
      switch (type) {
        case "delete":
          return <DeleteTwoTone twoToneColor={variables.danger_color} />;
        case "activate":
          return <PlayCircleOutlined />;
        case "deactivate":
          return <PauseCircleOutlined />;
      }
    };

    const renderText = (type: RowEditOption) => {
      switch (type) {
        case "delete":
          return (
            <span style={{ color: variables.danger_color }}>
              <K2Message localeKey={"common.delete"} />
            </span>
          );
        case "deactivate":
          return <K2Message localeKey={"common.deactivate"} />;
        case "activate":
          return <K2Message localeKey={"common.activate"} />;
      }
    };

    return (
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu>
            {opts?.map?.((option, index) => (
              <Menu.Item
                key={"menu_item_" + index + "_" + option.itemId}
                onClick={option.onClick}
              >
                {renderIcon(option.type)}
                {renderText(option.type)}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <DownOutlined
          style={{ color: variables.link_color, cursor: "pointer" }}
        />
      </Dropdown>
    );
  }

  handleEditStatus(editStatus: EditStatus) {
    if (editStatus) {
      switch (editStatus.action) {
        case DataActions.statusUpdate:
          this.handleDataUpdateResult(
            editStatus,
            "admin.statusUpdateSuccessTitle",
            "admin.statusUpdateSuccessMessage",
          );
          break;
      }
    }
  }

  private handleDataUpdateResult(editStatus: EditStatus, titleKey, messageKey) {
    UIUtils.handleDataUpdateResult({
      k2Intl: this.config.k2Intl,
      successTitle: titleKey,
      successMessage: messageKey,
      status: editStatus,
    });
  }
}
