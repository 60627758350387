import React from "react";
import { Route, Switch } from "react-router-dom";
import { RequireAuth } from "containers/app/views";
import { asyncComponent } from "components/utils";
import K2Breadcrumb from "components/breadcrumb";
import { K2Routes } from "appRedux/models/routes/K2Routes";

const TasksWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "TasksSearch" */ "containers/tasksSearch"),
  ),
  "tasks",
);

const TasksSubRouter = () => {
  return (
    <>
      <K2Breadcrumb />
      <Switch>
        <Route exact path={K2Routes.tasks} component={TasksWithAuth} />
      </Switch>
    </>
  );
};

export default TasksSubRouter;
