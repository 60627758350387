import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { Q1Routes } from "appRedux/models/routes/Q1RouteModel";
import { asyncComponent } from "components/utils";
import K2Breadcrumb from "components/breadcrumb";
import { PinVisuals } from "components/statisticsGraphs";
import RequireTallyAuth from "routes/Q1/containers/auth/RequireTallyAuth";

const {
  main: { q1Analytics },
  q1Reports,
  q1ReportCategories,
  q1EmbedReport,
  q1AnalyticsReportDashboard,
  q1AnalyticsAnalysis,
  q1DonationIncomeAnalysis,
} = Q1Routes;

const analyticsWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "TallyAnalytics" */ "containers/analytics"),
  ),
  "q1Analytics",
);

const reportsCategoriesWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyPinnedReportCategories" */ "containers/analytics/pinnedReportsCategories"
      ),
  ),
  "q1ReportCategories",
);

const reportsWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyPinnedReports" */ "containers/analytics/pinnedReports"
      ),
  ),
  "q1Reports",
);

const analyticReportDashboardWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyAnalyticsReportDashboard" */ "containers/analytics/reports"
      ),
  ),
  "q1AnalyticsReportDashboard",
);

const analyticAnalysisDashboardWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyAnalyticsAnalysisDashboard" */ "containers/analytics/analysis"
      ),
  ),
  "q1AnalyticsAnalysis",
);

const donationIncomeAnalysisWithAuth = RequireTallyAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "TallyDonationIncomeAnalysisReport" */ "containers/analytics/analysis/searchForms"
      ),
  ),
  "q1DonationIncomeAnalysis",
);

const AnalyticsRoutes = ({ match }: RouteComponentProps) => (
  <>
    <K2Breadcrumb />
    <Switch>
      <Route exact path={q1Analytics} component={analyticsWithAuth} />
      <Route
        exact
        path={q1AnalyticsReportDashboard}
        component={analyticReportDashboardWithAuth}
      />
      <Route
        exact
        path={q1AnalyticsAnalysis}
        component={analyticAnalysisDashboardWithAuth}
      />
      <Route
        exact
        path={q1DonationIncomeAnalysis}
        component={donationIncomeAnalysisWithAuth}
      />
      <Route
        exact
        path={q1ReportCategories}
        component={reportsCategoriesWithAuth}
      />
      <Route exact path={q1Reports} component={reportsWithAuth} />
      <Route exact path={q1EmbedReport} component={PinVisuals} />
    </Switch>
  </>
);

export default AnalyticsRoutes;
