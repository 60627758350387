import produce from "immer";
import moment from "moment";

import { LookupAction } from "appRedux/modules/lookups/actionTypes";

import { ActionTypes } from "./actionTypes";
import {
  AccountLookups,
  AnalyticsLookups,
  CallsLookups,
  CommunicationsLookups,
  DonationLookups,
  EmailAdminLookups,
  FeedbackLookups,
  FormsLookups,
  HooksLookups,
  ImportsLookups,
  ListsLookups,
  Lookups,
  MailLookups,
  PaymentLookups,
  PledgesLookups,
  PrintLookups,
  ReportingLookups,
  SmsLookups,
  SponsorshipLookups,
  SubscriptionLookups,
  TasksLookups,
  TaxReliefLookups,
  TemplatesLookups,
  TimeclockLookups,
  UserLookups,
} from "./types";

export const subscriptionLookupsInitialState: SubscriptionLookups = {
  aggregationFunctions: { items: [], loading: false, token: undefined },
  allocationTypes: { items: [], loading: false, token: undefined },
  applications: { items: [], loading: false, token: undefined },
  countries: { items: [], loading: false, token: undefined },
  dataSharingPreferences: { items: [], loading: false, token: undefined },
  dataTypes: { items: [], loading: false, token: undefined },
  dateCriterionOptions: { items: [], loading: false, token: undefined },
  dateFormats: { items: [], loading: false, token: undefined },
  daysOfMonth: { items: [], loading: false, token: undefined },
  daysOfWeek: { items: [], loading: false, token: undefined },
  decimalCriterionOptions: { items: [], loading: false, token: undefined },
  integerCriterionOptions: { items: [], loading: false, token: undefined },
  languages: { items: [], loading: false, token: undefined },
  lookupCriterionOptions: { items: [], loading: false, token: undefined },
  markupLanguages: { items: [], loading: false, token: undefined },
  numberFormats: { items: [], loading: false, token: undefined },
  onlinePresenceTypes: { items: [], loading: false, token: undefined },
  phoneNumberTypes: { items: [], loading: false, token: undefined },
  retrospectiveOptions: { items: [], loading: false, token: undefined },
  subscriptionStatuses: { items: [], loading: false, token: undefined },
  subscriberTypes: { items: [], loading: false, token: undefined },
  textMatchingModes: { items: [], loading: false, token: undefined },
  timeFormats: { items: [], loading: false, token: undefined },
  timezones: { items: [], loading: false, token: undefined },
  unitsOfTime: { items: [], loading: false, token: undefined },
  validities: { items: [], loading: false, token: undefined },
  workbookFormats: { items: [], loading: false, token: undefined },
  timePeriodTypes: { items: [], loading: false, token: undefined },
};

export const accountLookupsInitialState: AccountLookups = {
  accountStatuses: { items: [], loading: false, token: undefined },
  accountTypes: { items: [], loading: false, token: undefined },
  attachmentTypes: { items: [], loading: false, token: undefined },
  organizationTypes: { items: [], loading: false, token: undefined },
  titles: { items: [], loading: false, token: undefined },
  closureReasons: { items: [], loading: false, token: undefined },
  householdStatuses: { items: [], loading: false, token: undefined },
};

export const sponsorshipLookupsInitialState: SponsorshipLookups = {
  beneficiaryAssignments: { items: [], loading: false, token: undefined },
  beneficiaryStatuses: { items: [], loading: false, token: undefined },
  beneficiaryTypes: { items: [], loading: false, token: undefined },
  feedTypes: { items: [], loading: false, token: undefined },
  genders: { items: [], loading: false, token: undefined },
  schemeStatuses: { items: [], loading: false, token: undefined },
  sponsorshipCommitmentDurations: {
    items: [],
    loading: false,
    token: undefined,
  },
  sponsorshipCommitmentTypes: { items: [], loading: false, token: undefined },
  sponsorshipEndedReasons: { items: [], loading: false, token: undefined },
  sponsorshipNeedsAttentionReasons: {
    items: [],
    loading: false,
    token: undefined,
  },
  sponsorshipStatuses: { items: [], loading: false, token: undefined },
  sponsorshipSchemes: { items: [], loading: false, token: undefined },
  sponsorshipDeactivationStatuses: {
    items: [],
    loading: false,
    token: undefined,
  },
};

export const callsLookupsInitialState: CallsLookups = {
  callAppointmentStatuses: { items: [], loading: false, token: undefined },
  callAssigneeTypes: { items: [], loading: false, token: undefined },
  callDirections: { items: [], loading: false, token: undefined },
  callDispositions: { items: [], loading: false, token: undefined },
  callEndedReasons: { items: [], loading: false, token: undefined },
  callOutcomeStatuses: { items: [], loading: false, token: undefined },
  callPurposes: { items: [], loading: false, token: undefined },
  callStatuses: { items: [], loading: false, token: undefined },
  callCampaignStatuses: { items: [], loading: false, token: undefined },
  contributionLevels: { items: [], loading: false, token: undefined },
  callDialingModes: { items: [], loading: false, token: undefined },
  nextRecordTypes: { items: [], loading: false, token: undefined },
  callRecordStatuses: { items: [], loading: false, token: undefined },
  CallSkipReasons: { items: [], loading: false, token: undefined },
  callTeamRoles: { items: [], loading: false, token: undefined },
  callTeamStatuses: { items: [], loading: false, token: undefined },
  callContributionLevels: { items: [], loading: false, token: undefined },
};

export const timesclockInitialState: TimeclockLookups = {
  clockInStatuses: { items: [], loading: false, token: undefined },
  shiftStatuses: { items: [], loading: false, token: undefined },
  timesheetStatuses: { items: [], loading: false, token: undefined },
  jobRoles: { items: [], loading: false, token: undefined },
};

export const importLookupsInitialState: ImportsLookups = {
  dataImportBooleanValues: { items: [], loading: false, token: undefined },
  dataImportDatePatterns: { items: [], loading: false, token: undefined },
  dataImportDecimalSeparators: { items: [], loading: false, token: undefined },
  dataImportEntityActions: { items: [], loading: false, token: undefined },
  dataImportEntityStages: { items: [], loading: false, token: undefined },
  dataImportEntityStatuses: { items: [], loading: false, token: undefined },
  importEntityTypes: { items: [], loading: false, token: undefined },
  dataImportFeedStatuses: { items: [], loading: false, token: undefined },
  dataImportFetchIntervals: { items: [], loading: false, token: undefined },
  dataImportFieldTypes: { items: [], loading: false, token: undefined },
  dataImportGiveWpApis: { items: [], loading: false, token: undefined },
  dataImportHttpAuthenticationMethods: {
    items: [],
    loading: false,
    token: undefined,
  },
  dataImportHttpMethods: { items: [], loading: false, token: undefined },
  dataImportMatchBys: { items: [], loading: false, token: undefined },
  dataImportMatchingFailedActions: {
    items: [],
    loading: false,
    token: undefined,
  },
  dataImportMediaTypes: { items: [], loading: false, token: undefined },
  dataImportMissingPropertyActions: {
    items: [],
    loading: false,
    token: undefined,
  },
  dataImportModifySubmissionActions: {
    items: [],
    loading: false,
    token: undefined,
  },
  dataImportQueryTypes: { items: [], loading: false, token: undefined },
  dataImportQueueStatuses: { items: [], loading: false, token: undefined },
  dataImportRecordAbandonedReasons: {
    items: [],
    loading: false,
    token: undefined,
  },
  dataImportRecordOnHoldReasons: {
    items: [],
    loading: false,
    token: undefined,
  },
  dataImportRecordStatuses: { items: [], loading: false, token: undefined },
  dataImportRecordTypeGroups: { items: [], loading: false, token: undefined },
  dataImportRecordTypes: { items: [], loading: false, token: undefined },
  dataImportRunStages: { items: [], loading: false, token: undefined },
  dataImportRunStatuses: { items: [], loading: false, token: undefined },
  dataImportSourceTypes: { items: [], loading: false, token: undefined },
  dataImportTextEncodings: { items: [], loading: false, token: undefined },
  dataImportTextActions: { items: [], loading: false, token: undefined },
  dataImportTransformStatuses: { items: [], loading: false, token: undefined },
  dataImportUrlTemplatePlaceholders: {
    items: [],
    loading: false,
    token: undefined,
  },
  dataImportValueOrigins: { items: [], loading: false, token: undefined },
};

export const paymentsLookupsInitialState: PaymentLookups = {
  directDebitFileFields: { items: [], loading: false, token: undefined },
  directDebitFileNameMergeFields: {
    items: [],
    loading: false,
    token: undefined,
  },
  directDebitReportStatuses: { items: [], loading: false, token: undefined },
  directDebitReportTypes: { items: [], loading: false, token: undefined },
  directDebitSubmissionTypes: { items: [], loading: false, token: undefined },
  directDebitSubmissionStatuses: {
    items: [],
    loading: false,
    token: undefined,
  },
  balanceTransactionTypes: { items: [], loading: false, token: undefined },
  bankingOptions: { items: [], loading: false, token: undefined },
  cardBrands: { items: [], loading: false, token: undefined },
  processingDayRestrictions: { items: [], loading: false, token: undefined },
  credentialStatuses: { items: [], loading: false, token: undefined },
  deductionTypes: { items: [], loading: false, token: undefined },
  depositStatuses: { items: [], loading: false, token: undefined },
  displayOptions: { items: [], loading: false, token: undefined },
  leadTimeTypes: { items: [], loading: false, token: undefined },
  paymentStatuses: { items: [], loading: false, token: undefined },
  payoutStatuses: { items: [], loading: false, token: undefined },
  refundMethods: { items: [], loading: false, token: undefined },
  refundReasons: { items: [], loading: false, token: undefined },
  refundStatuses: { items: [], loading: false, token: undefined },
  refundTypes: { items: [], loading: false, token: undefined },
  paymentMethods: { items: [], loading: false, token: undefined },
};

export const taxReliefLookups: TaxReliefLookups = {
  allowanceFilters: { items: [], loading: false, token: undefined },
  allowanceStatuses: { items: [], loading: false, token: undefined },
  declarationStatuses: { items: [], loading: false, token: undefined },
  ineligibleReasons: { items: [], loading: false, token: undefined },
  submissionPartStatuses: { items: [], loading: false, token: undefined },
  taxReliefSubmissionStatuses: { items: [], loading: false, token: undefined },
  taxReliefRates: { items: [], loading: false, token: undefined },
  taxStatusSources: { items: [], loading: false, token: undefined },
  taxYears: { items: [], loading: false, token: undefined },
};

export const donationLookupsInitialState: DonationLookups = {
  donationItems: { items: [], loading: false, token: undefined },
  donationItemStatuses: { items: [], loading: false, token: undefined },
  donationStatuses: { items: [], loading: false, token: undefined },
  donationTypes: { items: [], loading: false, token: undefined },
  givingStatuses: { items: [], loading: false, token: undefined },
  regularGivingFrequencies: { items: [], loading: false, token: undefined },
  givingCancellationReasons: { items: [], loading: false, token: undefined },
  givingTypes: { items: [], loading: false, token: undefined },
  scheduleStatuses: { items: [], loading: false, token: undefined },
};

export const printLookupsInitialState: PrintLookups = {
  batchStatuses: { items: [], loading: false, token: undefined },
  queues: { items: [], loading: false, token: undefined },
};

export const pledgesLookupsInitialState: PledgesLookups = {
  pledgeCancellationReasons: { items: [], loading: false, token: undefined },
  pledgeSources: { items: [], loading: false, token: undefined },
  pledgeStatuses: { items: [], loading: false, token: undefined },
  pledgeTransactionTypes: { items: [], loading: false, token: undefined },
  pledgeResolveActions: { items: [], loading: false, token: undefined },
  pledgeBalanceTransferTypes: { items: [], loading: false, token: undefined },
};

export const emailAdminLookupsInitialState: EmailAdminLookups = {
  emailStatuses: { items: [], loading: false, token: undefined },
  emailTypes: { items: [], loading: false, token: undefined },
  amazonRegions: { items: [], loading: false, token: undefined },
  deliveryServices: { items: [], loading: false, token: undefined },
};

export const templateLookupsInitialState: TemplatesLookups = {
  assetTypes: { items: [], loading: false, token: undefined },
  compositionTypes: { items: [], loading: false, token: undefined },
  contentVisibilities: { items: [], loading: false, token: undefined },
  fragmentTypes: { items: [], loading: false, token: undefined },
  letterheadStatuses: { items: [], loading: false, token: undefined },
  media: { items: [], loading: false, token: undefined },
  outputFormats: { items: [], loading: false, token: undefined },
  pageOrientations: { items: [], loading: false, token: undefined },
  pageSizes: { items: [], loading: false, token: undefined },
  templateEngines: { items: [], loading: false, token: undefined },
  templateModelTypes: { items: [], loading: false, token: undefined },
  templateStatuses: { items: [], loading: false, token: undefined },
};

export const analyticsLookupsInitialState: AnalyticsLookups = {
  pinningLocations: { items: [], loading: false, token: undefined },
  reportCategories: { items: [], loading: false, token: undefined },
  reportingPeriods: { items: [], loading: false, token: undefined },
  measureCategories: { items: [], loading: false, token: undefined },
  reportTypes: { items: [], loading: false, token: undefined },
  trends: { items: [], loading: false, token: undefined },
  touchpointStatuses: { items: [], loading: false, token: undefined },
  touchpoints: { items: [], loading: false, token: undefined },
  fundraisers: { items: [], loading: false, token: undefined },
  fundraiserStatuses: { items: [], loading: false, token: undefined },
};

export const communicationsLookupsInitialState: CommunicationsLookups = {
  categories: { items: [], loading: false, token: undefined },
  categoryGroups: { items: [], loading: false, token: undefined },
  channels: { items: [], loading: false, token: undefined },
  consentSources: { items: [], loading: false, token: undefined },
  correspondenceStatuses: { items: [], loading: false, token: undefined },
  correspondenceTypes: { items: [], loading: false, token: undefined },
  preferences: { items: [], loading: false, token: undefined },
  presets: { items: [], loading: false, token: undefined },
  privacyStatementStatuses: { items: [], loading: false, token: undefined },
  signalGroups: { items: [], loading: false, token: undefined },
  signals: { items: [], loading: false, token: undefined },
  triggerStatuses: { items: [], loading: false, token: undefined },
};

export const smsLookupsInitialState: SmsLookups = {
  smsDeliveryServices: { items: [], loading: false, token: undefined },
  smsStatuses: { items: [], loading: false, token: undefined },
  smsTrackingEvents: { items: [], loading: false, token: undefined },
  templatePlaceholders: { items: [], loading: false, token: undefined },
  textMarketerEnvironments: { items: [], loading: false, token: undefined },
};

export const usersLookupsInitialState: UserLookups = {
  userStatuses: { items: [], loading: false, token: undefined },
  permissions: { items: [], loading: false, token: undefined },
};

export const listsLookupsInitialState: ListsLookups = {
  connectionStatuses: { items: [], loading: false, token: undefined },
  entityCategories: { items: [], loading: false, token: undefined },
  listEntityTypes: { items: [], loading: false, token: undefined },
  exportFormats: { items: [], loading: false, token: undefined },
  listStatuses: { items: [], loading: false, token: undefined },
  removeActions: { items: [], loading: false, token: undefined },
  priorityOrders: { items: [], loading: false, token: undefined },
  listPermissions: { items: [], loading: false, token: undefined },
  listViews: { items: [], loading: false, token: undefined },
};

export const mailLookupsInitialState: MailLookups = {
  addressFields: { items: [], loading: false, token: undefined },
};

export const tasksLookupsInitialState: TasksLookups = {
  linkedEntityTypes: { items: [], loading: false, token: undefined },
  taskPriorities: { items: [], loading: false, token: undefined },
  taskStatuses: { items: [], loading: false, token: undefined },
};

export const hooksLookupsInitialState: HooksLookups = {
  endpointStatuses: { items: [], loading: false, token: undefined },
  eventTypeCategories: { items: [], loading: false, token: undefined },
  eventTypeGroups: { items: [], loading: false, token: undefined },
  eventTypes: { items: [], loading: false, token: undefined },
};

export const formsLookupsInitalState: FormsLookups = {
  formFieldTypes: { items: [], loading: false, token: undefined },
  formSchemas: { items: [], loading: false, token: undefined },
  formStatuses: { items: [], loading: false, token: undefined },
  formTypes: { items: [], loading: false, token: undefined },
  formFieldLookupOptions: { items: [], loading: false, token: undefined },
};

export const feedbackLookupsInitialState: FeedbackLookups = {
  feedbackCancellationReasons: { items: [], loading: false, token: undefined },
  feedbackCustomFieldTypes: { items: [], loading: false, token: undefined },
  feedbackProjectStatuses: { items: [], loading: false, token: undefined },
  feedbackReportRejectionReasons: {
    items: [],
    loading: false,
    token: undefined,
  },
  feedbackReportStatuses: { items: [], loading: false, token: undefined },
  feedbackReportSubmissionTypes: {
    items: [],
    loading: false,
    token: undefined,
  },
  feedbackSchemes: { items: [], loading: false, token: undefined },
  feedbackSchemeStatuses: { items: [], loading: false, token: undefined },
  feedbackTransactionTypes: { items: [], loading: false, token: undefined },
  feedbackStatuses: { items: [], loading: false, token: undefined },
  feedbackMilestones: { items: [], loading: false, token: undefined },
};

export const reportingLookupsInitialState: ReportingLookups = {
  inboxStatuses: { items: [], loading: false, token: undefined },
  reportCancellationReasons: {
    items: [],
    loading: false,
    token: undefined,
  },
  reportRejectionReasons: {
    items: [],
    loading: false,
    token: undefined,
  },
  reportStatuses: { items: [], loading: false, token: undefined },
  reportSubmissionTypes: {
    items: [],
    loading: false,
    token: undefined,
  },
  reportTypeStatuses: {
    items: [],
    loading: false,
    token: undefined,
  },
  schemeReportingRequirementStatuses: {
    items: [],
    loading: false,
    token: undefined,
  },
};

export const lookupsInitialState: Lookups = {
  ...subscriptionLookupsInitialState,
  ...accountLookupsInitialState,
  ...sponsorshipLookupsInitialState,
  ...callsLookupsInitialState,
  ...timesclockInitialState,
  ...importLookupsInitialState,
  ...paymentsLookupsInitialState,
  ...donationLookupsInitialState,
  ...printLookupsInitialState,
  ...pledgesLookupsInitialState,
  ...emailAdminLookupsInitialState,
  ...templateLookupsInitialState,
  ...analyticsLookupsInitialState,
  ...communicationsLookupsInitialState,
  ...smsLookupsInitialState,
  ...usersLookupsInitialState,
  ...taxReliefLookups,
  ...listsLookupsInitialState,
  ...mailLookupsInitialState,
  ...tasksLookupsInitialState,
  ...hooksLookupsInitialState,
  ...feedbackLookupsInitialState,
  ...reportingLookupsInitialState,
  ...formsLookupsInitalState,
  serverError: null,
};

export const lookupsReducer = (
  state = lookupsInitialState,
  action: LookupAction,
) =>
  produce(state, (draft: Lookups) => {
    switch (action.type) {
      case ActionTypes.SET_LOOKUPS_LOADING:
        action.lookups.forEach((keyToSet) => {
          draft[keyToSet] = {
            ...draft[keyToSet],
            loading: action.loading,
          };
        });
        break;
      case ActionTypes.SET_LOOKUPS:
        action.lookups.forEach((l) => {
          const result = l.getResultOrDefault();

          if (result) {
            Object.keys(result).forEach((key) => {
              let keyToSet = key[0].toLowerCase() + key.slice(1);
              draft[keyToSet] = {
                items: result[key].items,
                token: result[key].token,
                loading: false,
                expiryTime: moment(new Date())
                  .add(5, "m") // 5 minutes in future
                  .toDate(),
              };
            });
          }
        });
        break;
      case ActionTypes.CLEAR_LOOKUPS_ERROR:
        draft.serverError = null;
        break;
      case ActionTypes.INVALIDATE_LOOKUPS_FEW:
        action.lookupTypes.forEach((key) => {
          draft[key] = {
            items: [],
            loading: false,
            token: undefined,
            expiryTime: undefined,
          };
        });
        break;
      case ActionTypes.INVALIDATE_LOOKUPS_All:
        Object.keys(draft)
          .filter((x) => x !== "isLoading" && x !== "serverError")
          .forEach((key) => {
            draft[key] = {
              items: [],
              loading: false,
              token: undefined,
              expiryTime: undefined,
            };
          });
        break;
    }
  });
