import React, { FC } from "react";

import { Form, FormInstance, Input } from "antd";

import { GeneralFormItemV4 } from "components/formItems";

interface INameFormProps {
  form: FormInstance;
  handleSubmit: (values) => void;
  onValuesChange: () => void;
}
const NameForm: FC<INameFormProps> = (props) => {
  const { form, handleSubmit, onValuesChange } = props;

  return (
    <Form form={form} onFinish={handleSubmit} onValuesChange={onValuesChange}>
      <GeneralFormItemV4
        name="name"
        labelKey="common.name"
        labelCol={{
          xs: 24,
          sm: 6,
          md: 4,
        }}
        wrapperCol={{
          xs: 24,
          sm: 18,
          md: 20,
        }}
        required
      >
        <Input />
      </GeneralFormItemV4>
    </Form>
  );
};

export default NameForm;
