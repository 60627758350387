import { DashboardActivityLogResultsList } from "@n3oltd/karakoram.activities.sdk.activities/src/index";
import { all, put, takeLatest } from "redux-saga/effects";

import { _activitiesClient } from "../../models/base/K2RestClients";
import K2Service from "../../models/base/K2RestService";
import { IApiResponse } from "../../models/common/ApiResponseModel";
import { ActionTypes, FetchDashboardActivities } from "./actionTypes";
import {
  fetchDashboardActivitiesError,
  fetchDashboardActivitiesSuccess,
} from "./actions";

function* fetchActivityResults(action: FetchDashboardActivities) {
  const response: IApiResponse<DashboardActivityLogResultsList> = yield K2Service.toResponse(
    _activitiesClient.dashboardFind(action.criteria),
  );

  if (response.error) {
    yield put(fetchDashboardActivitiesError(response.error));
  } else {
    yield put(fetchDashboardActivitiesSuccess(response.getResultOrDefault()));
  }
}

export default function* activitySagas() {
  yield all[
    yield takeLatest(
      ActionTypes.FETCH_DASHBOARD_ACTIVITIES,
      fetchActivityResults,
    )
  ];
}
