import React, { FC } from "react";

import {
  DeleteTwoTone,
  EllipsisOutlined,
  MinusCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  RedoOutlined,
  StopTwoTone,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons/lib";
import { UserRes } from "@n3oltd/k2.users.sdk.users/esm";
import { Col, Dropdown, Menu, Row } from "antd";

import { NamedLookup } from "appRedux/modules/lookups/types";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import ItemSummaryRow from "components/admin/shared/ItemSummaryRow";
import { K2Button, K2Card, K2DateTime, K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import UserStatusTag from "components/statusTag/UserStatusTag";

interface CurrentUserStatusProps extends InjectedK2IntlProps {
  user: UserRes;
  onUnblockUser: () => void;
  onBlockUser: () => void;
  onUnlockUser: () => void;
  onActivateUser?: () => void;
  onDeactivateUser?: () => void;
  onMakeAdmin: () => void;
  onRevokeAdmin: () => void;
  onSendPasswordResetEmail: () => void;
  onDeleteUser: () => void;
  userStatuses: NamedLookup[];
  isCurrentUser: boolean;
}

const CurrentUserStatus: FC<CurrentUserStatusProps> = ({
  user,
  k2Intl,
  isCurrentUser,
  onActivateUser,
  onBlockUser,
  onDeactivateUser,
  onUnblockUser,
  onMakeAdmin,
  onRevokeAdmin,
  onUnlockUser,
  onSendPasswordResetEmail,
  onDeleteUser,
  userStatuses,
}) => {
  const { theme } = useAppContext();

  const canChangeStatus = !!onDeactivateUser && !!onActivateUser;

  return (
    <K2Card
      bordered={false}
      shadow={true}
      localeKey={"common.details"}
      style={{ marginBottom: 25 }}
      extra={
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              {user.status !== "blocked" &&
                user.status !== "invited" &&
                canChangeStatus && (
                  <Menu.Item
                    disabled={isCurrentUser}
                    onClick={
                      user.status === "active"
                        ? onDeactivateUser
                        : onActivateUser
                    }
                  >
                    {user.status === "active" ? (
                      <PauseCircleOutlined />
                    ) : (
                      <PlayCircleOutlined />
                    )}
                    <K2Message
                      localeKey={
                        user.status === "active"
                          ? "common.deactivate"
                          : "common.activate"
                      }
                    />
                  </Menu.Item>
                )}
              <Menu.Item
                disabled={isCurrentUser}
                onClick={user.admin ? onRevokeAdmin : onMakeAdmin}
              >
                <UserOutlined />
                <K2Message
                  localeKey={
                    user.admin
                      ? "admin.users.revokeAdmin"
                      : "admin.users.makeAdmin"
                  }
                />
              </Menu.Item>
              {!!onUnlockUser && (
                <Menu.Item disabled={isCurrentUser} onClick={onUnlockUser}>
                  <UnlockOutlined />
                  <span>
                    <K2Message localeKey={"admin.users.unlock"} />
                  </span>
                </Menu.Item>
              )}
              {!!onUnblockUser && (
                <Menu.Item disabled={isCurrentUser} onClick={onUnblockUser}>
                  <MinusCircleOutlined />
                  <K2Message localeKey={"admin.users.unblock"} />
                </Menu.Item>
              )}
              {!!onBlockUser && (
                <Menu.Item disabled={isCurrentUser} onClick={onBlockUser}>
                  <StopTwoTone twoToneColor={theme.danger_color} />
                  <span style={{ color: theme.danger_color }}>
                    <K2Message localeKey={"admin.users.block"} />
                  </span>
                </Menu.Item>
              )}
              {user.canDelete && (
                <Menu.Item disabled={isCurrentUser} onClick={onDeleteUser}>
                  <DeleteTwoTone twoToneColor={theme.danger_color} />
                  <span style={{ color: theme.danger_color }}>
                    <K2Message localeKey={"admin.users.delete"} />
                  </span>
                </Menu.Item>
              )}
              {!!onSendPasswordResetEmail && (
                <Menu.Item onClick={onSendPasswordResetEmail}>
                  <RedoOutlined />
                  <K2Message localeKey={"admin.users.resetPassword"} />
                </Menu.Item>
              )}
            </Menu>
          }
          placement={"bottomRight"}
          disabled={!user}
        >
          <K2Button>
            <EllipsisOutlined />
          </K2Button>
        </Dropdown>
      }
    >
      <Row>
        <Col xs={22}>
          <ItemSummaryRow
            labelKey={"admin.users.lastLogin"}
            value={
              <K2DateTime
                value={user?.statistics?.lastLogin}
                mode={"dateAndTime"}
              />
            }
          />
          <ItemSummaryRow
            labelKey={"admin.users.lastIpAddress"}
            value={user?.statistics?.lastIpAddress}
          />
          <ItemSummaryRow
            labelKey={"common.created"}
            value={
              <>
                <K2DateTime value={user?.modifiedInfo?.created?.timestamp} />{" "}
                {k2Intl
                  .formatMessage({
                    localeKey: "common.by",
                  })
                  .toLowerCase()}
                <span className="n3o-ml-1">
                  {user.modifiedInfo?.created?.name}
                </span>
              </>
            }
          />
          {user.modifiedInfo?.updated && (
            <ItemSummaryRow
              labelKey={"common.lastUpdated"}
              value={
                <>
                  <K2DateTime value={user.modifiedInfo?.updated?.timestamp} />{" "}
                  {k2Intl
                    .formatMessage({
                      localeKey: "common.by",
                    })
                    .toLowerCase()}
                  <span className="n3o-ml-1">
                    {user.modifiedInfo?.updated?.name}
                  </span>
                </>
              }
            />
          )}
          <ItemSummaryRow
            labelKey={"common.status"}
            value={
              <UserStatusTag status={user.status} userStatuses={userStatuses} />
            }
          />
          <ItemSummaryRow
            labelKey={"admin.users.isAdmin"}
            value={
              <K2Message localeKey={user.admin ? "common.yes" : "common.no"} />
            }
          />
        </Col>
      </Row>
    </K2Card>
  );
};

export default injectK2Intl(CurrentUserStatus);
