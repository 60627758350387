import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { K2Routes } from "appRedux/models/routes/RouteModel";
import { asyncComponent } from "components/utils";
import { RequireAuth } from "containers/app/views";
import K2Breadcrumb from "components/breadcrumb";
import { PinVisuals } from "components/statisticsGraphs";
const {
  main: { analytics },
  reports,
  reportCategories,
  embedReport,
  analyticsReportDashboard,
  analyticsAnalysis,
  donationIncomeAnalysis,
} = K2Routes;

const analyticsWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "AnalyticsDashboard" */ "containers/analytics"
      ),
  ),
  "analytics",
);

const reportsCategoriesWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "PinnedReportsCategories" */ "containers/analytics/pinnedReportsCategories"
      ),
  ),
  "reportCategories",
);

const reportsWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "PinnedReports" */ "containers/analytics/pinnedReports"
      ),
  ),
  "reports",
);

const analyticReportDashboardWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "AnalyticsReportDashboard" */ "containers/analytics/reports"
      ),
  ),
  "analyticsReportDashboard",
);

const analyticAnalysisDashboardWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "AnalyticsAnalysisDashboard" */ "containers/analytics/analysis"
      ),
  ),
  "analyticsAnalysis",
);

const donationIncomeAnalysisWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(
        /* webpackChunkName: "DonationIncomeAnalysis" */ "containers/analytics/analysis/searchForms"
      ),
  ),
  "donationIncomeAnalysis",
);

const AnalyticsRoutes = ({ match }: RouteComponentProps) => (
  <>
    <K2Breadcrumb />
    <Switch>
      <Route exact path={analytics} component={analyticsWithAuth} />
      <Route
        exact
        path={analyticsReportDashboard}
        component={analyticReportDashboardWithAuth}
      />
      <Route
        exact
        path={analyticsAnalysis}
        component={analyticAnalysisDashboardWithAuth}
      />
      <Route
        exact
        path={donationIncomeAnalysis}
        component={donationIncomeAnalysisWithAuth}
      />
      <Route
        exact
        path={reportCategories}
        component={reportsCategoriesWithAuth}
      />
      <Route exact path={reports} component={reportsWithAuth} />
      <Route exact path={embedReport} component={PinVisuals} />
    </Switch>
  </>
);

export default AnalyticsRoutes;
