import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const DeleteTask: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66669 2.8499C4.18488 2.8499 3.72281 3.0413 3.38211 3.38199C3.04142 3.72268 2.85002 4.18476 2.85002 4.66657V19.3332C2.85002 19.815 3.04142 20.2771 3.38211 20.6178C3.7228 20.9585 4.18488 21.1499 4.66669 21.1499H14.4834V17.9998C14.4834 17.0671 14.8539 16.1727 15.5134 15.5132C16.1729 14.8537 17.0673 14.4832 18 14.4832H21.15V4.66657C21.15 4.18476 20.9586 3.72268 20.6179 3.38199C20.2772 3.0413 19.8152 2.8499 19.3334 2.8499H4.66669ZM20.6147 16.1832H18C17.5182 16.1832 17.0561 16.3746 16.7154 16.7152C16.3748 17.0559 16.1834 17.518 16.1834 17.9998V20.6145L20.6147 16.1832ZM22.85 14.895C22.8501 15.8274 22.4799 16.7217 21.8208 17.3812L17.3825 21.8195C16.7231 22.4791 15.8288 22.8497 14.8962 22.8499H4.66669C3.73401 22.8499 2.83954 22.4794 2.18003 21.8199C1.52053 21.1604 1.15002 20.2659 1.15002 19.3332V4.66657C1.15002 3.73389 1.52053 2.83941 2.18003 2.17991C2.83954 1.52041 3.73401 1.1499 4.66669 1.1499H19.3334C20.266 1.1499 21.1605 1.52041 21.82 2.17991C22.4795 2.83941 22.85 3.73389 22.85 4.66657V14.895C22.85 14.895 22.85 14.895 22.85 14.895ZM8.73232 6.73211C9.06426 6.40017 9.60245 6.40017 9.9344 6.73211L12 8.79774L14.0657 6.73211C14.3976 6.40017 14.9358 6.40017 15.2677 6.73211C15.5997 7.06406 15.5997 7.60225 15.2677 7.9342L13.2021 9.99982L15.2677 12.0654C15.5997 12.3974 15.5997 12.9356 15.2677 13.2675C14.9358 13.5995 14.3976 13.5995 14.0657 13.2675L12 11.2019L9.9344 13.2675C9.60245 13.5995 9.06426 13.5995 8.73232 13.2675C8.40037 12.9356 8.40037 12.3974 8.73232 12.0654L10.7979 9.99982L8.73232 7.9342C8.40037 7.60225 8.40037 7.06406 8.73232 6.73211Z"
      fill={fill}
    />
  </svg>
);
