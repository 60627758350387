import React from "react";
import {
  CheckSquareOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  RedoOutlined,
  StopOutlined,
  SwapOutlined,
} from "@ant-design/icons/lib";
import variables from "../../../common/themeVariables";

export default class ActionGridModel {
  static reconcileActions(actions: K2GridAction[]): K2GridAction[] {
    actions.forEach((item) => {
      const defAction = defaultActions.find((act) => act.key === item.key);
      if (defAction) {
        for (const prop in defAction) {
          if (!item[prop] && !!defAction[prop]) {
            item[prop] = defAction[prop];
          }
        }
      }
    });

    return actions;
  }
}

export type K2GridActionKey =
  | "delete"
  | "markActive"
  | "markInactive"
  | "retry"
  | "cancel"
  | "move"
  | "refund"
  | "reprocess";

const defaultActions: K2GridAction[] = [
  {
    key: "delete",
    titleKey: "common.delete",
    icon: <DeleteOutlined style={{ color: variables.danger_color }} />,
  },
  {
    key: "markActive",
    titleKey: "admin.markActive",
    icon: <CheckSquareOutlined />,
  },
  {
    key: "markInactive",
    titleKey: "admin.markInactive",
    icon: <PauseCircleOutlined />,
  },
  {
    key: "retry",
    titleKey: "common.retry",
    icon: <RedoOutlined />,
  },
  {
    key: "cancel",
    titleKey: "common.cancel",
    icon: <StopOutlined />,
  },
  {
    key: "move",
    titleKey: "common.move",
    icon: <SwapOutlined />,
  },
  {
    key: "refund",
    titleKey: "common.refund",
    icon: <RedoOutlined />,
  },
];

export interface K2GridAction {
  /**Unique key/ID for the item */
  key: K2GridActionKey;

  /** locale key for title to be displayed */
  titleKey?: string;

  icon?: JSX.Element;

  /** If true, a horizontal divider line will be added instead of a dropdown item*/
  isDivider?: boolean;

  /** If true a confirmation Modal will be displayed before executing the callback */
  showConfirm?: boolean;

  //A custom confirmation message locale key, if not provide a generic confirmation message will be displayed
  confirmMessage?: React.ReactNode;

  onConfirmOpen?: () => void;

  onConfirmOK?: () => void;

  onConfirmCancel?: () => void;
}
