import React, { FC } from "react";
import styled from "styled-components";
import { K2MessageValue } from "../k2Localizations/types";
import useWindowWidth from "hooks/useWindowWidth";
import { Col, Row, Skeleton } from "antd";
import { ColProps } from "antd/es/col";
import { MOBILE_SIZE } from "constants/ThemeSetting";
import { K2Message } from "components/k2Widgets/index";

const HeadingCardContainer = styled.div<{ width: number }>`
  min-height: 64px;
  display: ${({ width }) => (width <= MOBILE_SIZE ? "flex" : "flex")};
  justify-content: space-between;
  align-items: center;
  padding: ${({ width }) => (width <= MOBILE_SIZE ? "15px 25px" : "0 25px")};
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.grey_2} 0%,
    rgba(247, 247, 250, 0) 100%
  );
  border-radius: 8px;

  h2 {
    font-weight: ${({ theme }) => theme.font_weight_heavy} !important;
    font-size: ${({ theme }) => theme.font_size_xl};
    color: ${({ theme }) => theme.primary_10};
  }

  .goBack {
    display: block;
    font-size: 12px;
    margin-left: -15px;
    font-weight: ${({ theme }) => theme.font_weight_semi_bold};

    .anticon {
      margin-right: 3px;
    }
  }
`;

const LinkCol = styled(Col)`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 576px) {
      //justify-content: flex-start;
    }
  }
`;

export interface IK2RouteHeadingProps {
  loading?: boolean;
  headingKey?: string;
  headingValues?: { [key: string]: K2MessageValue };
  heading?: string | JSX.Element | React.ReactNode;
  backToLink?: React.ReactNode;
  className?: string;
  linkColProps?: ColProps;
  headingColProps?: ColProps;
}

const K2RouteHeading: FC<IK2RouteHeadingProps> = ({
  loading,
  headingKey,
  headingValues,
  children,
  heading,
  backToLink,
  className,
  linkColProps = {},
  headingColProps = {},
}) => {
  const width: number = useWindowWidth();

  return (
    <HeadingCardContainer width={width} className={className}>
      {loading ? (
        <Skeleton active paragraph={{ rows: 0 }} />
      ) : (
        <Row style={{ width: "100%" }}>
          <Col xs={24} sm={18} {...headingColProps}>
            {backToLink && <span className={"goBack"}>{backToLink}</span>}
            <h2
              className={`n3o-mb-${width <= MOBILE_SIZE ? "3" : "0"} n3o-mr-3`}
            >
              {heading ? (
                heading
              ) : (
                <K2Message
                  withoutSpan
                  localeKey={headingKey}
                  values={headingValues}
                />
              )}
            </h2>
          </Col>
          <LinkCol xs={24} sm={6} {...linkColProps}>
            {children}
          </LinkCol>
        </Row>
      )}
    </HeadingCardContainer>
  );
};

export default K2RouteHeading;
